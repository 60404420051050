import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { ApplicationStatus } from 'types';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Payload = {
  applicationId: string;
  message: string;
  status: ApplicationStatus;
  requestedDocumentId: string;
  files: File[];
};

const usePatchApplicationStatus = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      let fileIds = [];
      for (let i = 0; i < payload.files.length; i++) {
        let formData = new FormData();
        formData.append('documentType', 'APPLICATION_REPLY');
        formData.append('file', payload.files[i]);
        const { data } = await client!.ApplicationApi_uploadReplyFile(payload.applicationId, formData);

        fileIds.push(data.id);
      }
      return client!.ApplicationApi_addApplicationStatusUpdate(
        {
          applicationId: payload.applicationId,
        },
        { ...payload, fileIds },
      );
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.University.Application);
        queryCache.invalidateQueries(CacheKeys.General.Application);
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default usePatchApplicationStatus;
