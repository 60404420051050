import { Box, Fade } from '@material-ui/core';
import { useProgramFilters, useStudentNames } from 'api';
import { UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import FiltersDrawerActions from 'components/_drawers/filtersDrawer/FiltersDrawerActions';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import { attendanceTypeOptions } from 'constants/programMaps';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';
import { useDrawers } from 'store';
import { Nationality, OptionType } from 'types';
import { getMetaRole } from 'utils';
import { useAuth } from '../../../../../store';
interface Props {}

const queryToArray = (value?: string | string[]) => {
  if (value) {
    return [value].flat(1);
  } else return undefined;
};

export default function FilterProgramsForm({}: Props): ReactElement {
  const { t: tInputs } = useTranslation('inputs');
  const { closeDrawer } = useDrawers();
  const { queries, replaceQueriesToUrl } = useQueryStringParser<ProgramsFiltersQueries>();
  const [filtersState, setFiltersState] = useState<ProgramsFiltersQueries>(queries);
  const { role } = useAuth(state => state.profile);
  const { isStudent, isConsultant, isAdmin, isMaster } = getMetaRole(role!);
  const { data: filters, isLoading: isLoadingFilters } = useProgramFilters();
  const clearFilters = queries?.clear;

  // Handle universities filter field props
  const universityOptions = filters?.universityNames;
  const universityValue = universityOptions?.filter(({ value }) => value && filtersState?.universityId?.includes(value));
  const onChangeUniversities = (options: MultiValue<OptionType>) => {
    const universityId = options.map(option => option.value);
    setFiltersState(prev => ({ ...prev, universityId }));
  };

  // Handle students filter field props
  const showStudentField = isConsultant || isAdmin || isMaster;
  const { data: students } = useStudentNames({ enabled: showStudentField });
  const studentOptions = students;
  const studentValue = studentOptions?.filter(({ value }) => value && filtersState?.studentId === value);
  const onChangeStudent = (option: SingleValue<OptionType>) => {
    const studentId = option?.value;
    setFiltersState(prev => ({ ...prev, studentId }));
  };

  // Handle countries filter field props
  const countryOptions = filters?.countries.map(({ code, name }) => ({ label: name, value: code }));
  const countryValue = countryOptions?.filter(({ value }) => value && filtersState?.country?.includes(value as Nationality));
  const onChangeCountry = (options: MultiValue<OptionType>) => {
    const country = options.map(option => option.value);
    setFiltersState(prev => ({ ...prev, country }));
  };

  // Handle cities filter field props
  const showCityField = !!filtersState.country?.length;
  const cityOptions = queryToArray(filtersState?.country)
    ?.map(key => filters?.citiesMap[key])
    .flat(1);
  const cityValue = cityOptions?.filter(({ value }) => value && filtersState?.city?.includes(value));
  const onChangeCity = (options: MultiValue<OptionType>) => {
    const city = options.map(option => option.value);
    setFiltersState(prev => ({ ...prev, city }));
  };

  // Handle languages filter field props
  const languageOptions = filters?.langaugesOfInstruction.map(({ code, name }) => ({ label: name, value: code }));
  const languageValue = languageOptions?.filter(({ value }) => value && filtersState?.language?.includes(value as any));
  const onChangeLanguage = (options: MultiValue<OptionType>) => {
    const language = options.map(option => option.value);
    setFiltersState(prev => ({ ...prev, language }));
  };

  // Handle levelOfStudy filter field props
  const levelOfStudyOptions = filters?.levelsOfStudy;
  const levelOfStudyValue = levelOfStudyOptions?.filter(({ value }) => value && filtersState?.levelOfStudy?.includes(value));
  const onChangeLevelOfStudy = (options: MultiValue<OptionType>) => {
    const levelOfStudy = options.map(option => option.value);
    setFiltersState(prev => ({ ...prev, levelOfStudy }));
  };

  // Handle discipline filter field props
  const disciplineOptions = filters?.subjects.map(({ value, text }) => ({ label: text, value }));
  const disciplineValue = disciplineOptions?.filter(({ value }) => value && filtersState?.discipline?.includes(value as any));
  const onChangeDiscipline = (options: MultiValue<OptionType>) => {
    const discipline = options.map(option => option.value);
    setFiltersState(prev => ({ ...prev, discipline }));
  };

  // Handle attendanceType filter field props
  const attendanceTypeValue = attendanceTypeOptions?.filter(({ value }) => value && filtersState?.attendanceType?.includes(value as any));
  const onChangeAttendanceType = (options: MultiValue<OptionType>) => {
    const attendanceType = options.map(option => option.value);
    setFiltersState(prev => ({ ...prev, attendanceType }));
  };

  const onSubmit = () => {
    if (filtersState) replaceQueriesToUrl(filtersState);
    closeDrawer();
  };

  useEffect(() => {
    if (clearFilters) {
      setFiltersState({});
      replaceQueriesToUrl({ clear: undefined });
    }
  }, [clearFilters]);

  return (
    <>
      <UniModalDrawerContent>
        <Fade in={showStudentField} unmountOnExit>
          <>
            <Box mb={2} pt={1}>
              <UniTypography weight='medium' color='secondary100'>
                {tInputs('subtitles.by_student')}
              </UniTypography>
            </Box>
            <UniSelect
              options={studentOptions}
              value={studentValue}
              onChange={onChangeStudent}
              placeholder={tInputs('placeholders.student')}
              label={tInputs('labels.student')}
            />
          </>
        </Fade>
        <Box mb={2} pt={1}>
          <UniTypography weight='medium' color='secondary100'>
            {tInputs('subtitles.by_school')}
          </UniTypography>
        </Box>
        <UniSelect
          isMulti
          options={universityOptions}
          value={universityValue}
          onChange={onChangeUniversities}
          placeholder={tInputs('placeholders.university')}
          label={tInputs('labels.university')}
        />
        <Box mb={2} pt={1}>
          <UniTypography weight='medium' color='secondary100'>
            {tInputs('subtitles.by_location')}
          </UniTypography>
        </Box>
        <UniSelect
          isMulti
          options={countryOptions}
          value={countryValue}
          onChange={onChangeCountry}
          placeholder={tInputs('placeholders.country')}
          label={tInputs('labels.country')}
        />
        <Fade in={showCityField} unmountOnExit>
          <UniSelect
            isMulti
            options={cityOptions}
            value={cityValue}
            onChange={onChangeCity}
            placeholder={tInputs('placeholders.city')}
            label={tInputs('labels.city')}
          />
        </Fade>
        <Box mb={2} pt={1}>
          <UniTypography weight='medium' color='secondary100'>
            {tInputs('subtitles.by_program_details')}
          </UniTypography>
        </Box>
        <UniSelect
          isMulti
          options={languageOptions}
          value={languageValue}
          onChange={onChangeLanguage}
          placeholder={tInputs('placeholders.language')}
          label={tInputs('labels.language')}
        />
        <UniSelect
          isMulti
          options={levelOfStudyOptions}
          value={levelOfStudyValue}
          onChange={onChangeLevelOfStudy}
          placeholder={tInputs('placeholders.levelOfStudy')}
          label={tInputs('labels.levelOfStudy')}
        />
        <UniSelect
          isMulti
          options={disciplineOptions}
          value={disciplineValue}
          onChange={onChangeDiscipline}
          placeholder={tInputs('placeholders.discipline')}
          label={tInputs('labels.discipline')}
        />
        <UniSelect
          isMulti
          options={attendanceTypeOptions}
          value={attendanceTypeValue}
          onChange={onChangeAttendanceType}
          placeholder={tInputs('placeholders.attendanceType')}
          label={tInputs('labels.attendanceType')}
        />
      </UniModalDrawerContent>
      <FiltersDrawerActions handleSubmit={onSubmit} />
    </>
  );
}
