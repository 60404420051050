import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Components } from 'client/UniClient';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import useErrorHandler from 'hooks/useErrorHandler';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { default as React, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDrawers } from 'store';
import { useFacultyPrograms, useGetProgramsNames } from '../../../../../api';
import { ReactComponent as AddIcon } from '../../../../../assets/svg/Icons/Add.svg';
import {
  ProgramsList,
  ProgramsListItem,
  StickyListFilterBar,
  UniButton,
  UniIconWrapper,
  UniPage,
  UniTypography,
} from '../../../../../components';
import { BrowserRoutes } from '../../../../../constants';
interface Props {
  facultyId: string;
}

export default function FacultyPrograms({ facultyId }: Props): ReactElement {
  const { t } = useTranslation('universityFacultyPage');
  const history = useHistory();
  const [query, setQuery] = useState<string>();
  const [sortType, setSortType] = useState(1);
  const [selectedPrograms, setSelectedPrograms] = useState<Components.Schemas.ProgramResponse[]>([]);
  const { queries } = useQueryStringParser<ProgramsFiltersQueries>();
  const { openDrawer } = useDrawers();

  const {
    data: programs,
    status: programsStatus,
    isFetching,
    fetchMore,
    canFetchMore,
    error: programsError,
  } = useFacultyPrograms({
    query,
    filters: queries,
    sortType,
    facultyId: facultyId || '',
  });
  const fetchError = useErrorHandler(programsError!);

  const programsLoading = isFetching;
  const programsFlat = useMemo(() => {
    return programs?.map(programPage => programPage.results).flat() || [];
  }, [programs]);
  const programsTotal = useMemo(() => (programs ? programs[0].total : 0), [programs]);
  const totalResultsLabel = useMemo(
    () => (programsTotal === 1 ? programsTotal + ' ' + t('result.ifOne') : programsTotal + ' ' + t('result.ifMore')),
    [programs],
  );

  const { data: programNames, status: programNamesStatus } = useGetProgramsNames();

  const onSort = (type: number) => setSortType(type);

  const selectProgram = (program: Components.Schemas.ProgramResponse) => {
    setSelectedPrograms(prevSelectedPrograms => {
      const index = prevSelectedPrograms?.findIndex(item => item.id === program.id);
      if (index === -1) {
        return [...prevSelectedPrograms, program];
      } else {
        return prevSelectedPrograms.filter(item => item.id !== program.id);
      }
    });
  };

  const selectAllPrograms = (value: boolean) => {
    if (value) {
      setSelectedPrograms(programsFlat);
    } else {
      setSelectedPrograms([]);
    }
  };

  const openFilterProgramsDrawer = () => openDrawer('filter-faculty-programs', { size: 'small' });

  const openCreateProgramDrawer = () => openDrawer('create-program', { size: 'main' });

  const openEditProgramsDrawer = (program?: Components.Schemas.ProgramResponse) => openDrawer('create-program', { size: 'main', program });

  return (
    <UniPage>
      <StickyListFilterBar
        listTitle={t('list.title')}
        options={programNames?.map(item => item.label) || []}
        searchHint={t('list.search_hint')}
        handleSearch={setQuery}
        tooltip={t('list.tooltip')}
        totalResultsLabel={totalResultsLabel}
        onClickFilter={openFilterProgramsDrawer}
        ActionButton={
          <UniButton
            fullWidth={false}
            color='primary'
            onClick={openCreateProgramDrawer}
            startIcon={
              <UniIconWrapper color='white100'>
                <AddIcon />
              </UniIconWrapper>
            }
          >
            {t('buttons.add_program')}
          </UniButton>
        }
      />
      {fetchError.message && <UniTypography color='stateAlert100'>{fetchError.message}</UniTypography>}
      <ProgramsList
        hasMore={!!canFetchMore}
        fetchMore={fetchMore}
        hasStatus={false}
        length={programsTotal}
        onSort={onSort}
        isLoading={programsLoading}
        selectAllPrograms={selectAllPrograms}
        selectedPrograms={selectedPrograms?.length}
      >
        {programsFlat.map((program: any, index: any) => (
          <ProgramsListItem
            onClick={() => history.push(BrowserRoutes.University.Program.Default.replace(':programId', program.id!))}
            program={program}
            selectProgram={selectProgram}
            selected={selectedPrograms.findIndex(itm => program.id === itm.id) !== -1}
            ActionButtons={
              <UniIconWrapper onClick={() => openEditProgramsDrawer(program)} color='primary100' withBackground>
                <EditOutlinedIcon />
              </UniIconWrapper>
            }
            stretch={false}
            key={index}
          />
        ))}
      </ProgramsList>
    </UniPage>
  );
}
