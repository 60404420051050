import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  id?: string;
  _page?: number;
};

export default function useExtendedProfile(params?: Params, config?: QueryConfig<Components.Schemas.FullStudentProfileDto>) {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Profile.Extended, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.UserApi_getFullStudentProfile({
        studentId: params.id as string,
      });

      return data;
    },
    {
      ...config,
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
