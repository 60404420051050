import styled from 'styled-components';
import containerFluidBackground from '../../../assets/landing/world-dots.png';

export const StyledFluidContainer = styled.div`
  background: url(${containerFluidBackground});
  background-size: contain;

  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme }) => theme.spacing(-5)}px;
  }
`;

export const StyledUnderline = styled.span`
  position: relative;

  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 15px;
    width: 100%;
    background-color: #d12e71;
    z-index: -1;
  }
`;
