import { Box, Fade } from '@material-ui/core';
import { Components } from 'client/UniClient';
import UniIconWrapper from 'components/_common/uniIconWrapper/UniIconWrapper';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { Error, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import React from 'react';
import { Controller } from 'react-hook-form';
import { ReactComponent as CheckIcon } from '../../../../assets/svg/Icons/Check.svg';
import { ReactComponent as ErrorSvg } from '../../../../assets/svg/Icons/Error.svg';
import { StyledPackageWrapper } from '../EventRegistration.style';

interface Props {
  control: any;
  errors: any;
  event?: Components.Schemas.EventResponse;
}

const Step2: React.FC<Props> = ({ control, event, errors }) => {
  return (
    <>
      <Box mb={2}>
        {!!event?.attendancePackages?.length && (
          <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
            Please select the type of ticket you want to buy
          </UniTypography>
        )}
        {!!event?.attendancePackages?.length && (
          <Controller
            name='attendancePackageId'
            control={control}
            rules={{ required: 'Please select a package' }}
            render={({ onChange, value }) => (
              <>
                {event?.attendancePackages.map((packageItem, index) => (
                  <StyledPackageWrapper size='S' border key={index} hoverable onClick={() => onChange(packageItem.id)}>
                    {value === packageItem.id && (
                      <Box position='absolute' top={8} right={16}>
                        <UniIconWrapper withBackground size='main' color='otherGreen100'>
                          <CheckIcon />
                        </UniIconWrapper>
                      </Box>
                    )}
                    <UniTypography variant='body1' weight='medium'>
                      {packageItem.name}
                    </UniTypography>
                    <UniTypography variant='subheader' color='typographyGray100'>
                      {packageItem.description}
                    </UniTypography>
                    <Box mt={2} />
                    <UniTypography
                      color='secondary100'
                      variant='body2'
                      weight='semi-bold'
                    >{`${packageItem.currency.amount} ${packageItem.currency.currencyIso}`}</UniTypography>
                  </StyledPackageWrapper>
                ))}
              </>
            )}
          />
        )}
        <Box mt={2} />
        <Fade in={!!errors?.attendancePackageId?.message} unmountOnExit>
          <Error>
            <StyledAlertIcon>
              <ErrorSvg />
            </StyledAlertIcon>
            <UniTypography variant='footnote' component='span' color='stateAlert100'>
              {errors?.attendancePackageId?.message}
            </UniTypography>
          </Error>
        </Fade>
      </Box>
    </>
  );
};

export default Step2;
