import { AxiosError } from 'axios';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export default function useGetProgramsNames(config?: QueryConfig<Error>) {
  //   const agencyId = useAuth((state) => state.profile.agentProfile?.agency?.id);
  const { client } = useClient();

  return useQuery(
    [CacheKeys.General.ProgramNames],
    async (_key: string) => {
      return client!.ProgramApi_getProgramNames().then(({ data }) => data);
    },
    {
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
