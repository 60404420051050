import React from 'react';
import { StyledContinentTileButton } from './ContinentTileButton.style';
import { ReactComponent as ArrowRight } from 'assets/svg/Icons/Arrow/ArrowRight.svg';

interface Props {
  targetUrl: string;
}

export const ContinentTileButton: React.FC<Props> = ({ targetUrl }) => {
  return <StyledContinentTileButton>
    <span className='action'>Find out more</span>
    <ArrowRight className='arrow-right' stroke='white' fill='white' />
  </StyledContinentTileButton>;
};

export default ContinentTileButton;
