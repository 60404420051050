import { Paths } from 'client/UniClient';
import { useMutation } from 'react-query';
import { useClient } from '../../store';

const usePostRequestsProgram = () => {
  const { client } = useClient();

  return useMutation(async (payload: Paths.ProgramApiRequestAdoptProgram.RequestBody) => {
    return client!.ProgramApi_requestAdoptProgram(undefined, payload);
  });
};

export default usePostRequestsProgram;
