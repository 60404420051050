import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { getBaseURL } from 'constants/browserRoutes';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getUserHomeScreen } from 'utils/getHomeForRole';
import { BrowserRoutes } from '../../constants';
import { useAuth, useClient } from '../../store';

type Payload = {
  email: string;
  password: string;
};

const checkPersonalInfo = ({ email, firstName, lastName, studentProfile }: Components.Schemas.UserDto) => {
  if (
    !email ||
    !firstName ||
    !lastName ||
    !studentProfile?.dateOfBirth ||
    !studentProfile?.gender ||
    !studentProfile?.countryOfBirth ||
    !studentProfile?.address?.addressLine1 ||
    !studentProfile?.address?.addressLine2 ||
    !studentProfile?.address?.city ||
    !studentProfile?.address?.country ||
    !studentProfile?.address?.postalCode
  ) {
    return false;
  }
  return true;
};

const useLogin = () => {
  const history = useHistory();
  const { client, setSession, resetSession } = useClient();
  const { setProfile, afterAuthPath, setAfterAuthPath } = useAuth();
  return useMutation(
    async (payload: Payload) => {
      resetSession();
      return client!
        .AuthApi_login(undefined, undefined, {
          auth: {
            username: payload.email,
            password: payload.password,
          },
          withCredentials: false,
        })
        .then(({ data }) => {
          setSession(data);
          return client!.UserApi_getMyUser(undefined, undefined, { headers: { Authorization: `Bearer ${data.token}` } });
        })
        .then(({ data }) => {
          // const canLogin = data.role !== 'STUDENT' || checkPersonalInfo(data);
          const canLogin = true;

          if (!!data?.emailConfirmed || true) {
            if (canLogin) {
              setProfile(data);
              if (afterAuthPath) {
                history.push(afterAuthPath.replace(':base', getBaseURL(data?.role)));
                setAfterAuthPath(undefined);
              } else history.push(getUserHomeScreen(data.role)!);
            } else {
              history.push(BrowserRoutes.Default.Onboard.Student.replace(':type', 'patch').replace(':token', 'student'));
            }
          }
          return data;
        });
    },
    {
      onError: (error: AxiosError) => {},
    },
  );
};
export default useLogin;
