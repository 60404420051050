import React from 'react';
import { StyledTutorsWrapper } from './UniTutorList.style';
import UniTutorCard from '../tutorCard/UniTutorCard';
import { tutors } from 'constants/tutors';

interface Props {
  tutorsToDisplay?: string[];
}

export const UniTutorList: React.FC<Props> = ({ tutorsToDisplay }) => {
  const tutorList = tutorsToDisplay != null
    ? tutorsToDisplay.map(tutor => tutors.find(t => t.name === tutor)!).filter(t => t != null)
    : tutors;
  const tutorImages = require.context('assets/landing/tutors', true, /\.(jpg|png)$/);
  const tutorImageArray = Array.from(tutorImages.keys()).map(path => ({
    path,
    image: tutorImages(path).default,
  }));

  return (
    <StyledTutorsWrapper>
      {tutorList.map(tutor => (
        <UniTutorCard {...tutor} imagePath={tutorImageArray.find(img => img.path === tutor.image)?.image} />
      ))}
    </StyledTutorsWrapper>
  );
};

export default UniTutorList;
