import { Box } from '@material-ui/core';
import HomepageFooter from 'components/_common/homepageFooter/HomepageFooter';
import { useBreakpoints } from 'hooks';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import bestIcon from '../../../assets/landing/best-seller.svg';
import charityIcon from '../../../assets/landing/charity.svg';
import customerIcon from '../../../assets/landing/customer-service.svg';
import dreamIcon from '../../../assets/landing/dream.svg';
import howItWorks1 from '../../../assets/landing/Group 3432.svg';
import honestIcon from '../../../assets/landing/honest.svg';
import shapeElipse from '../../../assets/landing/shapeElipse.svg';
import shapes2Icon from '../../../assets/landing/shapes2.svg';
import studentsBackground from '../../../assets/landing/students-background.svg';
import wavesIcon from '../../../assets/landing/waves.svg';
import { UniScrollToTop } from '../../../components';
import { StyledFluidContainer, StyledUnderline } from './LandingAboutUs.style';

export default function LandingAboutUs(): ReactElement {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isMobile } = useBreakpoints();
  const history = useHistory();
  const { t } = useTranslation('landingAboutUs');

  return (
    <>
      <StyledFluidContainer
        className='container-fluid'
        style={{
          backgroundColor: '#F7F6F4',
          backgroundImage: `url(${shapes2Icon})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '800px, contain',
          backgroundPosition: 'left top',
        }}
      >
        <UniScrollToTop />
        {/* <HeaderNavigation /> */}
        <div
          className='row about-us-hero'
          style={{
            background: `url(${studentsBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: `400px`,
            backgroundPosition: 'right center',
            maxWidth: '1365px',
            margin: 'auto',
          }}
        >
          <div
            style={{
              padding: '100px 0',
              width: 'min(700px, 90vw)',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <h1 className='page-title'>
              <StyledUnderline>{t('text.section_1.styled_title')}</StyledUnderline>
            </h1>
            <div className='page-subtitle'>{t('text.section_1.description')}</div>
          </div>
        </div>
        <div
          className='row'
          style={{
            padding: '100px 25px 50px 25px',
            backgroundImage: `url(${wavesIcon}),url(${shapeElipse}) `,
            backgroundRepeat: 'no-repeat,no-repeat',
            backgroundColor: '#fff',
            backgroundPosition: 'right 70%, 80% -15px',
          }}
        >
          <div
            className='row'
            style={{
              width: '100%',
              maxWidth: '1365px',
              margin: 'auto',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div className='col-lg-8'>
              <h2 className='section-subtitle' style={{ fontWeight: 'bold' }}>
                {t('text.section_2.title')}
              </h2>
              <div className='page-subtitle'>{t('text.section_2.description')}</div>
              <Box display='flex' justifyContent='center'>
                <img alt='howItWorks1' src={howItWorks1} />
              </Box>
            </div>
          </div>
        </div>
        <div
          className='row page-section'
          style={{
            backgroundImage: `url(${shapes2Icon})`,
            backgroundRepeat: 'no-repeat',
            //   backgroundColor: "#d12e71",
            backgroundSize: '1000px',
            backgroundPosition: 'right top',
          }}
        >
          <div
            className='row page-section'
            style={{
              maxWidth: '1365px',
              margin: 'auto',
              padding: '0 25px',
              backgroundColor: 'transparent',
            }}
          >
            <div className='col-12 col-md-6 col-lg-4' style={{ padding: '0 10px' }}>
              {/* <h2 className="section-title">Loreum ipsum</h2> */}
              <h3 className='section-subtitle'>{t('text.section_3.title')}</h3>
            </div>
            <div className='col-12 col-md-6 col-lg-4' style={{ padding: '0 10px' }}>
              <Box display='flex' px={1} pb={2} justifyContent='flex-start'>
                <div
                  className='image-box'
                  style={{
                    margin: 0,
                    height: '82px',
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#3B9B9410',
                  }}
                >
                  <img alt='charityIcon' src={charityIcon} />
                </div>
              </Box>
              <Box display='flex' flexDirection='column'>
                <h3 className='section-subtitle' style={{ flex: 1, maxWidth: '293px', marginBottom: '20px' }}>
                  {t('text.section_3.we_care.title')}
                </h3>
                <div className='page-subtitle' style={{ flex: 9, marginTop: 0 }}>
                  {t('text.section_3.we_care.description')}
                </div>
              </Box>
            </div>
            <div className='col-12 col-md-6 col-lg-4' style={{ padding: '0 10px' }}>
              <Box display='flex' px={1} pb={2} justifyContent='flex-start'>
                <div
                  className='image-box'
                  style={{
                    margin: 0,
                    height: '82px',
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#4962C910',
                  }}
                >
                  <img alt='honestIcon' src={honestIcon} />
                </div>
              </Box>
              <Box display='flex' flexDirection='column'>
                <h3 className='section-subtitle' style={{ flex: 1, maxWidth: '293px', marginBottom: '20px' }}>
                  {t('text.section_3.we_are_honest.title')}
                </h3>
                <div className='page-subtitle' style={{ flex: 9, marginTop: 0 }}>
                  {t('text.section_3.we_are_honest.description')}
                </div>
              </Box>
            </div>
            <div className='col-12 col-md-6 col-lg-4' style={{ padding: '0 10px' }}>
              <Box display='flex' px={1} pb={2} justifyContent='flex-start'>
                <div
                  className='image-box'
                  style={{
                    margin: 0,
                    height: '82px',
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#4E58C610',
                  }}
                >
                  <img alt='customerIcon' src={customerIcon} />
                </div>
              </Box>
              <Box display='flex' flexDirection='column'>
                <h3 className='section-subtitle' style={{ flex: 1, maxWidth: '293px', marginBottom: '20px' }}>
                  {t('text.section_3.we_help.title')}
                </h3>
                <div className='page-subtitle' style={{ flex: 9, marginTop: 0 }}>
                  {t('text.section_3.we_help.description')}
                </div>
              </Box>
            </div>
            <div className='col-12 col-md-6 col-lg-4' style={{ padding: '0 10px' }}>
              <Box display='flex' px={1} pb={2} justifyContent='flex-start'>
                <div
                  className='image-box'
                  style={{
                    margin: 0,
                    height: '82px',
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#4F99E210',
                  }}
                >
                  <img alt='dreamIcon' src={dreamIcon} />
                </div>
              </Box>
              <Box display='flex' flexDirection='column'>
                <h3 className='section-subtitle' style={{ flex: 1, maxWidth: '293px', marginBottom: '20px' }}>
                  {t('text.section_3.we_dream_big.title')}
                </h3>
                <div className='page-subtitle' style={{ flex: 9, marginTop: 0 }}>
                  {t('text.section_3.we_dream_big.description')}
                </div>
              </Box>
            </div>
            <div className='col-12 col-lg-4' style={{ padding: '0 10px' }}>
              <Box display='flex' px={1} pb={2} justifyContent='flex-start'>
                <div
                  className='image-box'
                  style={{
                    margin: 0,
                    height: '82px',
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#314E4E10',
                  }}
                >
                  <img alt='bestIcon' src={bestIcon} />
                </div>
              </Box>
              <Box display='flex' flexDirection='column'>
                <h3 className='section-subtitle' style={{ flex: 1, maxWidth: '293px', marginBottom: '20px' }}>
                  {t('text.section_3.we_are_best.title')}
                </h3>
                <div className='page-subtitle' style={{ flex: 9, marginTop: 0 }}>
                  {t('text.section_3.we_are_best.description')}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </StyledFluidContainer>
      {!isMobile && <HomepageFooter />}
    </>
  );
}
