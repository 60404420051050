import UniCardWrapper from 'components/_common/uniCardWrapper/UniCardWrapper';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import styled from 'styled-components';

export const SuggestProgramWrapper = styled(UniCardWrapper)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 13)};
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacing(3)}px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme }) => theme.spacing(2)}px;

  > *:nth-child(3),
  > *:nth-child(4),
  > *:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
    column-gap: ${({ theme }) => theme.spacing(1)}px;
    > *:nth-child(3),
    > *:nth-child(4),
    > *:nth-child(5) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
`;

export const StyledTypography = styled(UniTypography)`
  text-align: center;
  margin: ${({ theme }) => theme.spacing(1, 0, 3)};
`;

export const ActionWrapper = styled.div<{ sent: boolean }>`
  width: 100%;
  display: flex;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  justify-content: space-between;
  flex-direction: ${({ sent }) => (sent ? 'column' : 'row')};
  align-items: center;
  text-align: center;
  gap: ${({ theme }) => theme.spacing(3)}px;

  > *:nth-child(1) {
    flex: 1;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;
