import { Box } from '@material-ui/core';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import FiltersDrawerActions from 'components/_drawers/filtersDrawer/FiltersDrawerActions';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { DefaultFormValues } from 'constants/defaultFilters';
import { subjects } from 'constants/subjects';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { default as React, ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { UniAutocomplete, UniTypography } from '../../../..';
import { useProgramFilters } from '../../../../../api';
import { useErrorHandler } from '../../../../../hooks';
import { Nationality, TLanguage } from '../../../../../types';
interface Props {
  close: Function;
  universityId?: string;
}

export default function FilterUniversityProgramsFormForProgramsPage({ close, universityId }: Props): ReactElement {
  const location = useLocation();
  const { t } = useTranslation('filterProgramsPage');
  const { replaceQueriesToUrl, queries } = useQueryStringParser<ProgramsFiltersQueries>();
  const [fieldReset, setFieldReset] = useState(false);
  const { data: filters, status: filtersStatus, error: progamFilterError } = useProgramFilters();
  const filtersError = useErrorHandler(progamFilterError!);
  const { handleSubmit, reset, control } = useForm<ProgramsFiltersQueries>({
    defaultValues: { ...DefaultFormValues, ...queries },
  });
  const [selectedCountries, setSelectedCountries] = useState<any[]>();

  useEffect(() => {
    if (location.search === '' || !!queries?.clear) {
      reset({
        country: [],
        city: [],
        facultyId: [],
        levelOfStudy: [],
        discipline: [],
        language: [],
      });
      replaceQueriesToUrl({});
      setFieldReset(prevFieldReset => !prevFieldReset);
    } else {
      reset(queries);
    }
  }, []);

  const onSubmit = (formData: ProgramsFiltersQueries) => {
    close();
    replaceQueriesToUrl(formData);
  };

  const isLoading = filtersStatus === 'loading';

  return (
    <>
      <UniModalDrawerContent>
        <Box mb={2} pt={1}>
          <UniTypography weight='medium' color='secondary100'>
            {t('labels.subtitles.by_location')}
          </UniTypography>
        </Box>
        <Box mb={1}>
          <Controller
            multiple
            control={control}
            name='country'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                value={filters?.countries.filter(item => !!value?.includes(item.code as Nationality)) || []}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.code) || []);
                  setSelectedCountries(value?.map((item: any) => item.code) || []);
                }}
                name='country'
                isLoading={isLoading}
                options={filters?.countries || []}
                getOptionLabel={option => option.name}
                label={t('labels.country')}
                labelColor='typographyBlack100'
              />
            )}
          />
          {filtersError.message && (
            <Box>
              <UniTypography color='stateAlert100'>{filtersError.message}</UniTypography>
            </Box>
          )}
        </Box>
        <Box mb={2}>
          <Controller
            multiple
            control={control}
            name='city'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.value) || []);
                }}
                name='city'
                isLoading={isLoading}
                key={`city-${selectedCountries}`}
                value={
                  !!selectedCountries && selectedCountries.length > 0 && !!filters?.citiesMap
                    ? selectedCountries
                        ?.map(country => filters?.citiesMap![country])
                        .flat(1)
                        .filter(item => value?.includes(item))
                    : filters?.cities?.filter(item => value?.includes(item.value))
                }
                options={
                  !!selectedCountries && selectedCountries.length > 0 && !!filters?.citiesMap
                    ? selectedCountries?.map(country => filters?.citiesMap![country])?.flat(1) || []
                    : filters?.cities || []
                }
                getOptionLabel={option => option.label}
                label={t('labels.city')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={2} pt={1}>
          <UniTypography weight='medium' color='secondary100'>
            {t('labels.subtitles.by_program_details')}
          </UniTypography>
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='levelOfStudy'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                value={filters?.levelsOfStudy.filter(item => !!value?.includes(item.value)) || []}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map(item => item.value) || []);
                }}
                name='levelOfStudy'
                isLoading={isLoading}
                options={filters?.levelsOfStudy || []}
                getOptionLabel={option => option.label}
                label={t('labels.level_of_study.title')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            multiple
            name='discipline'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                value={subjects.filter(item => !!value?.includes(item.value as any)) || []}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.value) || []);
                }}
                name='discipline'
                isLoading={isLoading}
                options={subjects}
                getOptionLabel={option => option.text}
                label={t('labels.discipline')}
                labelColor='typographyBlack100'
              />
            )}
          />
          {filtersError.message && (
            <Box>
              <UniTypography color='stateAlert100'>{filtersError.message}</UniTypography>
            </Box>
          )}
        </Box>
        <Box mb={2}>
          <Controller
            multiple
            control={control}
            name='language'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                value={filters?.langaugesOfInstruction.filter(item => !!value?.includes(item.code as TLanguage)) || []}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.code) || []);
                }}
                name='language'
                isLoading={isLoading}
                options={filters?.langaugesOfInstruction || []}
                getOptionLabel={option => option.name}
                label={t('labels.language')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
      </UniModalDrawerContent>
      <FiltersDrawerActions handleSubmit={handleSubmit(onSubmit)} />
    </>
  );
}
