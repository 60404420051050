import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniAutocomplete, UniIconWrapper, UniOptionsField, UniTextField } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { UniDate } from 'components/_inputs/uniDate/UniDate';
import { countries } from 'constants/countries';
import { graduationLevel } from 'constants/levelOfStudy';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { useUpdateEducationHistoryItem } from '../../../api';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/Close.svg';
import { EducationDrawerState } from '../addEducation/AddEducationDrawer';

interface Props {
  close: Function;
  educationItem?: Components.Schemas.EducationHistoryItemDto;
}

const EditEducationDrawer: React.FC<Props> = ({ close, educationItem }) => {
  const { t } = useTranslation('editEducationDrawer');
  const { profile } = useAuth();
  const params = useParams<{ id?: string }>();
  const { isStudent } = getMetaRole(profile.role!);
  const [schoolName, setSchoolName] = useState(educationItem?.schoolName);
  const [futureYears, setFutureYears] = useState(!educationItem?.didGraduate);

  const [updateEducationHistory] = useUpdateEducationHistoryItem();

  const { handleSubmit, control, errors, register } = useForm<EducationDrawerState>();
  const onSubmit = (formData: EducationDrawerState) => {
    formData.didGraduate = formData.didGraduate.toString() === t('labels.yes');
    updateEducationHistory(
      {
        body: {
          ...formData,
          schoolAddress: { country: formData?.country, city: formData?.city },
        },
        params: {
          agencyId: isStudent ? profile.studentProfile?.agent.agentProfile.agency.id! : profile.agentProfile?.agency.id!,
          educationHistoryItemId: educationItem?.id!,
          studentId: isStudent ? profile.id! : params.id!,
        },
      },
      {
        onSuccess() {
          close();
        },
      },
    );
  };
  return (
    <>
      <UniModalDrawerContent>
        <Box mb={3.75}>
          <Controller
            control={control}
            name='levelOfStudy'
            rules={{ required: `${t('errors.study_level')}` }}
            defaultValue={graduationLevel.filter(item => item.value === educationItem?.levelOfStudy)[0]?.value}
            render={({ onChange, value }) => (
              <UniAutocomplete
                helperText={errors?.graduationLevel?.message}
                error={!!errors?.graduationLevel?.message}
                fullWidth
                width='350px'
                handleChange={(event: any, value: any) => {
                  onChange(value?.value || '');
                }}
                defaultValue={graduationLevel.filter(item => item.value === educationItem?.levelOfStudy)[0]}
                name='levelOfStudy'
                options={graduationLevel}
                getOptionLabel={option => option.label}
                placeholder={t('placeholders.study_level')}
                label={t('labels.diploma_type')}
              />
            )}
          />
        </Box>
        <Controller
          fullWidth
          control={control}
          name='country'
          rules={{ required: `${t('errors.country')}` }}
          defaultValue={countries.filter(item => item.code === educationItem?.schoolAddress?.country)[0]?.code}
          render={({ onChange, value }) => (
            <UniAutocomplete
              helperText={errors?.country?.message}
              error={!!errors?.country?.message}
              fullWidth
              placeholder={t('placeholders.country')}
              handleChange={(event: any, value: any) => onChange(value?.code || '')}
              label={t('labels.country')}
              defaultValue={countries.filter(item => item.code === educationItem?.schoolAddress!.country)[0]}
              name='country'
              options={countries}
              getOptionLabel={option => option.name}
            />
          )}
        />
        <UniTextField
          defaultValue={educationItem?.schoolAddress?.city}
          helperText={errors?.city?.message}
          error={!!errors?.city?.message}
          name='city'
          label={t('labels.city')}
          placeholder={t('placeholders.city')}
          fullWidth
          inputRef={register({ required: `${t('errors.city')}` })}
        />
        <Box mt={3.75} mb={5.25}>
          <Controller
            name='didGraduate'
            control={control}
            rules={{ required: `${t('errors.has_diploma')}` }}
            defaultValue={educationItem?.didGraduate ? t('labels.yes') : t('labels.no')}
            render={({ onChange, value }) => {
              setFutureYears(value !== t('labels.yes'));
              return (
                <UniOptionsField
                  options={[
                    { label: t('labels.yes'), value: t('labels.yes') },
                    { label: t('labels.no'), value: t('labels.no') },
                  ]}
                  value={value}
                  label={t('labels.diploma_obtained')}
                  onChange={onChange}
                  errorMsg={errors?.didGraduate?.message}
                />
              );
            }}
          />
        </Box>
        <Controller
          control={control}
          name='graduationDate'
          rules={{ required: `${t('errors.date')}` }}
          defaultValue={educationItem?.graduationDate}
          render={({ onChange, value }) => (
            <UniDate
              fullWidth
              showFutureYears={futureYears}
              value={value}
              onChange={onChange}
              label={t('labels.diploma_date')}
              error={!!errors?.graduationDate?.message}
              helperText={errors?.graduationDate?.message}
            ></UniDate>
          )}
        />
        <Box mt={3.75}>
          <UniTextField
            defaultValue={educationItem?.schoolName}
            helperText={errors?.schoolName?.message}
            error={!!errors?.schoolName?.message}
            name='schoolName'
            value={schoolName}
            label={t('labels.school_name')}
            placeholder={t('placeholders.school_name')}
            fullWidth
            inputRef={register({ required: `${t('errors.school_name')}` })}
            onChange={e => setSchoolName(e.target.value)}
            endAdornment={
              <UniIconWrapper
                color='typographyGray70'
                disableHover
                disableRipple
                onClick={() => {
                  setSchoolName('');
                }}
              >
                <CloseIcon />
              </UniIconWrapper>
            }
          />
        </Box>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: close, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: t('buttons.save_changes'),
        }}
      />
    </>
  );
};

export default EditEducationDrawer;
