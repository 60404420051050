import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

const usePostApplicationDocument = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: FormData) => {
      return client!.FileApi_uploadDocument(undefined, payload as unknown as Components.Schemas.UploadDocumentRequest);
    },
    {
      onSuccess: ({ data }) => {
        queryCache.invalidateQueries(CacheKeys.Profile.Extended);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         }); 
         */
      },
    },
  );
};

export default usePostApplicationDocument;
