import React, { ReactElement, useState } from 'react';
import { StyledTab } from './UniNavigationTab.style';
import { useBreakpoints } from 'hooks';
import { Box, TabProps } from '@material-ui/core';

export type Size = 'L' | 'S';

interface Props extends TabProps {
  label: string;
  value?: any;
  disabled?: boolean;
  size?: Size;
}

export default function UniNavigationTab({ label, value, disabled = false, size = 'L', ...props }: Props): ReactElement {
  const { isMobile } = useBreakpoints();
  return (
    <Box justifyContent='center'>
      <StyledTab
        isMobile={isMobile}
        size={isMobile ? 'S' : size}
        value={value}
        label={label}
        disabled={disabled}
        style={{ flex: isMobile ? 1 : 'none' }}
        {...props}
      ></StyledTab>
    </Box>
  );
}
