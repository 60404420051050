import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniButton, UniTypography } from 'components';
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { StyledQRCodeImage } from '../CreateEvent.style';

interface Step6Props {
  data?: Components.Schemas.QRTokenDto;
}

const Step6: React.FC<Step6Props> = ({ data }) => {
  const [code, setCode] = useState('');
  const generateQR = async (token: string) => {
    try {
      const url = `${window.origin}/app/qr/achievement/${token}`;
      const codeUrl = await QRCode.toDataURL(url);
      setCode(codeUrl);
    } catch (err) {
      console.error(err);
    }
  };

  const downloadQR = async () => {
    const url = `${window.origin}/app/qr/achievement/${data?.token}`;
    const codeUrl = await QRCode.toDataURL(url);
    var link = document.createElement('a');
    link.download = 'QR_Code';
    link.href = codeUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (data?.token) generateQR(data.token);
  }, [data?.token]);

  return (
    <>
      <UniTypography variant='body1' weight='medium' color='secondary100'>
        Congratulations, you succesfully created an event{data && ', bellow you can find the QR Code related to it'}.
      </UniTypography>
      {data && (
        <Box my={5} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <StyledQRCodeImage src={code} />
          <UniButton onClick={downloadQR}>Download QR Code</UniButton>
        </Box>
      )}
    </>
  );
};

export default Step6;
