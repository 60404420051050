import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  studentId: string;
};

const useNotes = (params: Params) => {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Student.Notes, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.StudentApi_getStudentNotes(params.studentId);
      return await data;
    },
    {
      onError(error: AxiosError) {},
    },
  );
};

export default useNotes;
