import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export type UsePostTodoPayload = Components.Schemas.CreateToDoRequest & { documents?: File[] };

const usePostTodo = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: UsePostTodoPayload) => {
      const links = payload.links?.filter(item => item.trim() !== '');
      const { data: toDo } = await client!.ToDoApi_createToDo(undefined, { ...payload, links });
      if (!payload.documents) return toDo;
      const documents = [];

      for (let i = 0; i < payload.documents.length; i++) {
        let formData = new FormData();
        formData.append('documentType', 'ToDo_DOCUMENT');
        formData.append('file', payload.documents[i]);
        const { data } = await client!.ToDoApi_uploadDocument({ toDoId: toDo.id }, formData);
        documents.push(data);
      }

      return { ...toDo, documents };
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.ToDo.Default);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default usePostTodo;
