import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  token: string;
  _page?: number;
};

export default function useNotOnboardedUser(params?: Params, config?: QueryConfig<Components.Schemas.UserDto>) {
  //   const agencyId = useAuth((state) => state.profile.agentProfile?.agency?.id);
  const { client } = useClient();

  return useQuery(
    [CacheKeys.General.Programs, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.AuthApi_getOnboardingUser({
        token: params.token,
      });

      return data;
    },
    {
      ...config,
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
