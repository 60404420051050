import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { StyledSuccessIllustration } from 'components/_drawers/assignAgent/AssignAgent.style';
import React from 'react';

interface Inputs {}

interface Props {
  event?: Components.Schemas.EventResponse;
}

const Step5: React.FC<Props> = ({ event }) => {
  return (
    <>
      <Box mb={2}>
        <UniTypography variant='header3' color='secondary100' weight='semi-bold'>
          Your seat is confirmed for {event?.name}!
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='body2' color='typographyGray100' weight='regular'>
          You will receive an email shortly with all the necessary information. See you soon!
        </UniTypography>
        <StyledSuccessIllustration />
      </Box>
    </>
  );
};

export default Step5;
