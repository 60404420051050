export const currencyIso = [
  { value: "AED", text: "AED" },
  { value: "AFN", text: "AFN" },
  { value: "ALL", text: "ALL" },
  { value: "AMD", text: "AMD" },
  { value: "ANG", text: "ANG" },
  { value: "AOA", text: "AOA" },
  { value: "ARS", text: "ARS" },
  { value: "AUD", text: "AUD" },
  { value: "AWG", text: "AWG" },
  { value: "AZN", text: "AZN" },
  { value: "BAM", text: "BAM" },
  { value: "BBD", text: "BBD" },
  { value: "BDT", text: "BDT" },
  { value: "BGN", text: "BGN" },
  { value: "BHD", text: "BHD" },
  { value: "BIF", text: "BIF" },
  { value: "BMD", text: "BMD" },
  { value: "BND", text: "BND" },
  { value: "BOB", text: "BOB" },
  { value: "BOV", text: "BOV" },
  { value: "BRL", text: "BRL" },
  { value: "BSD", text: "BSD" },
  { value: "BTN", text: "BTN" },
  { value: "BWP", text: "BWP" },
  { value: "BYR", text: "BYR" },
  { value: "BZD", text: "BZD" },
  { value: "CAD", text: "CAD" },
  { value: "CDF", text: "CDF" },
  { value: "CHE", text: "CHE" },
  { value: "CHF", text: "CHF" },
  { value: "CHW", text: "CHW" },
  { value: "CLF", text: "CLF" },
  { value: "CLP", text: "CLP" },
  { value: "CNY", text: "CNY" },
  { value: "COP", text: "COP" },
  { value: "COU", text: "COU" },
  { value: "CRC", text: "CRC" },
  { value: "CUC", text: "CUC" },
  { value: "CUP", text: "CUP" },
  { value: "CVE", text: "CVE" },
  { value: "CZK", text: "CZK" },
  { value: "DJF", text: "DJF" },
  { value: "DKK", text: "DKK" },
  { value: "DOP", text: "DOP" },
  { value: "DZD", text: "DZD" },
  { value: "EGP", text: "EGP" },
  { value: "ERN", text: "ERN" },
  { value: "ETB", text: "ETB" },
  { value: "EUR", text: "EUR" },
  { value: "FJD", text: "FJD" },
  { value: "FKP", text: "FKP" },
  { value: "GBP", text: "GBP" },
  { value: "GEL", text: "GEL" },
  { value: "GHS", text: "GHS" },
  { value: "GIP", text: "GIP" },
  { value: "GMD", text: "GMD" },
  { value: "GNF", text: "GNF" },
  { value: "GTQ", text: "GTQ" },
  { value: "GYD", text: "GYD" },
  { value: "HKD", text: "HKD" },
  { value: "HNL", text: "HNL" },
  { value: "HRK", text: "HRK" },
  { value: "HTG", text: "HTG" },
  { value: "HUF", text: "HUF" },
  { value: "IDR", text: "IDR" },
  { value: "ILS", text: "ILS" },
  { value: "INR", text: "INR" },
  { value: "IQD", text: "IQD" },
  { value: "IRR", text: "IRR" },
  { value: "ISK", text: "ISK" },
  { value: "JMD", text: "JMD" },
  { value: "JOD", text: "JOD" },
  { value: "JPY", text: "JPY" },
  { value: "KES", text: "KES" },
  { value: "KGS", text: "KGS" },
  { value: "KHR", text: "KHR" },
  { value: "KMF", text: "KMF" },
  { value: "KPW", text: "KPW" },
  { value: "KRW", text: "KRW" },
  { value: "KWD", text: "KWD" },
  { value: "KYD", text: "KYD" },
  { value: "KZT", text: "KZT" },
  { value: "LAK", text: "LAK" },
  { value: "LBP", text: "LBP" },
  { value: "LKR", text: "LKR" },
  { value: "LRD", text: "LRD" },
  { value: "LSL", text: "LSL" },
  { value: "LTL", text: "LTL" },
  { value: "LYD", text: "LYD" },
  { value: "MAD", text: "MAD" },
  { value: "MDL", text: "MDL" },
  { value: "MGA", text: "MGA" },
  { value: "MKD", text: "MKD" },
  { value: "MMK", text: "MMK" },
  { value: "MNT", text: "MNT" },
  { value: "MOP", text: "MOP" },
  { value: "MRO", text: "MRO" },
  { value: "MUR", text: "MUR" },
  { value: "MVR", text: "MVR" },
  { value: "MWK", text: "MWK" },
  { value: "MXN", text: "MXN" },
  { value: "MXV", text: "MXV" },
  { value: "MYR", text: "MYR" },
  { value: "MZN", text: "MZN" },
  { value: "NAD", text: "NAD" },
  { value: "NGN", text: "NGN" },
  { value: "NIO", text: "NIO" },
  { value: "NOK", text: "NOK" },
  { value: "NPR", text: "NPR" },
  { value: "NZD", text: "NZD" },
  { value: "OMR", text: "OMR" },
  { value: "PAB", text: "PAB" },
  { value: "PEN", text: "PEN" },
  { value: "PGK", text: "PGK" },
  { value: "PHP", text: "PHP" },
  { value: "PKR", text: "PKR" },
  { value: "PLN", text: "PLN" },
  { value: "PYG", text: "PYG" },
  { value: "QAR", text: "QAR" },
  { value: "RON", text: "RON" },
  { value: "RSD", text: "RSD" },
  { value: "RUB", text: "RUB" },
  { value: "RWF", text: "RWF" },
  { value: "SAR", text: "SAR" },
  { value: "SBD", text: "SBD" },
  { value: "SCR", text: "SCR" },
  { value: "SDG", text: "SDG" },
  { value: "SEK", text: "SEK" },
  { value: "SGD", text: "SGD" },
  { value: "SHP", text: "SHP" },
  { value: "SLL", text: "SLL" },
  { value: "SOS", text: "SOS" },
  { value: "SRD", text: "SRD" },
  { value: "SSP", text: "SSP" },
  { value: "STD", text: "STD" },
  { value: "SYP", text: "SYP" },
  { value: "SZL", text: "SZL" },
  { value: "THB", text: "THB" },
  { value: "TJS", text: "TJS" },
  { value: "TMT", text: "TMT" },
  { value: "TND", text: "TND" },
  { value: "TOP", text: "TOP" },
  { value: "TRY", text: "TRY" },
  { value: "TTD", text: "TTD" },
  { value: "TWD", text: "TWD" },
  { value: "TZS", text: "TZS" },
  { value: "UAH", text: "UAH" },
  { value: "UGX", text: "UGX" },
  { value: "USD", text: "USD" },
  { value: "USN", text: "USN" },
  { value: "USS", text: "USS" },
  { value: "UYI", text: "UYI" },
  { value: "UYU", text: "UYU" },
  { value: "UZS", text: "UZS" },
  { value: "VEF", text: "VEF" },
  { value: "VND", text: "VND" },
  { value: "VUV", text: "VUV" },
  { value: "WST", text: "WST" },
  { value: "XAF", text: "XAF" },
  { value: "XAG", text: "XAG" },
  { value: "XAU", text: "XAU" },
  { value: "XBA", text: "XBA" },
  { value: "XBB", text: "XBB" },
  { value: "XBC", text: "XBC" },
  { value: "XBD", text: "XBD" },
  { value: "XBT", text: "XBT" },
  { value: "XCD", text: "XCD" },
  { value: "XDR", text: "XDR" },
  { value: "XFU", text: "XFU" },
  { value: "XOF", text: "XOF" },
  { value: "XPD", text: "XPD" },
  { value: "XPF", text: "XPF" },
  { value: "XPT", text: "XPT" },
  { value: "XSU", text: "XSU" },
  { value: "XUA", text: "XUA" },
  { value: "YER", text: "YER" },
  { value: "ZAR", text: "ZAR" },
  { value: "ZMW", text: "ZMW" },
  { value: "ZWD", text: "ZWD" },
];
