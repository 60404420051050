import create from 'zustand';
import { FooterbarState } from './types';

const useFooterbar = create<FooterbarState>(set => ({
  footer: 'default',
  setFooter: props => set(({ setFooter, clearFooter }) => ({ ...props, setFooter, clearFooter, footer: 'predefined' })),
  clearFooter: () => set(state => ({ ...state, showOn: 'mobile', footer: 'default' })),
  showOn: 'mobile',
}));

export default useFooterbar;
