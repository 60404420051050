import { Components } from 'client/UniClient';
import { UniAvatar, UniIconWrapper } from 'components';
import { countries } from 'constants/countries';
import { useBreakpoints } from 'hooks';
import ReactCountryFlag from 'react-country-flag';
import { Color, Size, UniAvatarProps } from '../uniAvatar/UniAvatar';
import { EllipsisTypography, GapWrapper } from './UniStudentAvatar.style';

interface UniStudentAvatarProps extends UniAvatarProps {
  studentName: string;
  studentInfo?: Partial<Components.Schemas.AddressDto>;
  avatarSrc?: string;
  online?: boolean;
  size?: Size;
  color?: Color;
}

const UniStudentAvatar: React.FC<UniStudentAvatarProps> = ({
  studentName,
  studentInfo,
  avatarSrc,
  online,
  size,
  color,
  children,
  ...rest
}) => {
  const { isMobile } = useBreakpoints();
  return (
    <GapWrapper gap={2}>
      <UniAvatar online={online} size={size} color={color} label={studentName} src={avatarSrc} />
      <GapWrapper flexDirection='column' gap={1}>
        <EllipsisTypography variant='body2' weight='semi-bold' color='typographyBlack100' maxWidth={isMobile ? 20 : 22}>
          {studentName}
        </EllipsisTypography>
        {!!studentInfo && (
          <GapWrapper alignItems='center' gap={1}>
            {!!studentInfo.country && (
              <UniIconWrapper>
                <ReactCountryFlag
                  countryCode={studentInfo.country}
                  svg
                  style={{
                    width: '22px',
                    height: '22px',
                  }}
                />
              </UniIconWrapper>
            )}
            {!!studentInfo && (
              <EllipsisTypography variant='subheader' weight='medium' color='typographyBlack100' maxWidth={isMobile ? 16 : 18}>
                {studentInfo.city + ', ' + countries.find(country => country.code === studentInfo.country)?.name}
              </EllipsisTypography>
            )}
          </GapWrapper>
        )}
      </GapWrapper>
    </GapWrapper>
  );
};

export default UniStudentAvatar;
