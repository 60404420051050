import { AnimatePresence } from 'framer-motion';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDrawers } from 'store';
import AddEducationItem from './AddEducationDrawerItem';
import AddEducationItemStep1, { AddEdItemStep1Inputs } from './_steps/AddEducationItemStep1';
import AddEducationItemStep2, { AddEdItemStep2Inputs } from './_steps/AddEducationItemStep2';
import AddEducationItemStep3, { AddEdItemStep3Inputs } from './_steps/AddEducationItemStep3';

export type EducationDrawerState = AddEdItemStep1Inputs & AddEdItemStep2Inputs & AddEdItemStep3Inputs;

interface Props {
  close: Function;
}

export default function AddEducationDrawer({ close }: Props): ReactElement {
  const [currentTab, setCurrentTab] = useState(0);
  const [drawerState, setDrawerState] = useState<EducationDrawerState>();
  const { setProgress } = useDrawers();

  const setEducationForm = (formData: EducationDrawerState) => {
    setDrawerState(prevForm => {
      return { ...prevForm, ...formData };
    });
  };

  useEffect(() => {
    setProgress(currentTab * 25 + 25);
  }, [currentTab]);
  return (
    <>
      <AnimatePresence>
        <AddEducationItem
          currentItem={0}
          direction={0}
          active={currentTab === 0}
          Content={
            <AddEducationItemStep1
              nextStep={() => setCurrentTab(prevStep => prevStep + 1)}
              drawerState={drawerState!}
              setDrawerState={setEducationForm}
              close={close}
            />
          }
        />
        <AddEducationItem
          currentItem={1}
          direction={0}
          active={currentTab === 1}
          Content={
            <AddEducationItemStep2
              nextStep={() => setCurrentTab(prevStep => prevStep + 1)}
              prevStep={() => setCurrentTab(prevStep => prevStep - 1)}
              drawerState={drawerState!}
              setDrawerState={setEducationForm}
            />
          }
        />
        <AddEducationItem
          currentItem={2}
          direction={0}
          active={currentTab === 2}
          Content={
            <AddEducationItemStep3
              prevStep={() => setCurrentTab(prevStep => prevStep - 1)}
              drawerState={drawerState!}
              setDrawerState={setEducationForm}
              close={close}
            />
          }
        />
      </AnimatePresence>
    </>
  );
}
