import React from 'react';
import { Box } from '@material-ui/core';
import { UniTypography } from '../index';
import { StyledPackagesListHeader } from './PackagesList.style';

interface Props {}

export const ListHeader: React.FC<Props> = () => {
  return (
    <StyledPackagesListHeader>
      <Box />
      <UniTypography weight='medium' variant='subheader'>
        Product
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Sessions
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Price
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Full price
      </UniTypography>
    </StyledPackagesListHeader>
  );
};

export default ListHeader;
