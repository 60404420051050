import { useStudentFavoritePrograms } from 'api';
import { Components } from 'client/UniClient';
import { ApplicationCard, InfiniteWindowedGrid } from 'components';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAuth } from 'store';
import { BrowserRoutes } from '../../../../constants';

interface Props {}

const SavedPrograms: React.FC<Props> = ({ children, ...rest }) => {
  const history = useHistory();
  const { profile } = useAuth();
  const { pathname } = useLocation();
  const studentId = pathname.split('/')[4];
  const {
    data: savedPrograms,
    status: savedProgramsStatus,
    fetchMore: fetchMoreSavedPrograms,
    isFetching,
  } = useStudentFavoritePrograms({ studentId, agencyId: profile.agentProfile?.agency.id! });

  const savedProgramsLoading = savedProgramsStatus === 'loading';
  const savedProgramsFlat = useMemo(() => {
    return savedPrograms?.map(progs => progs.results).flat() || [];
  }, [savedPrograms, savedProgramsStatus]);
  const savedProgramsTotal = useMemo(() => (savedPrograms ? savedPrograms[0].total : 0), [savedPrograms]);
  const savedProgramsLeft = useMemo(() => savedProgramsTotal - savedProgramsFlat.length, [savedProgramsFlat, savedProgramsTotal]);
  const goToProgramPage = (program: Components.Schemas.ProgramResponse) => {
    if (program?.application?.id) {
      return BrowserRoutes.Agent.Application.General(program.application.id);
    } else {
      return BrowserRoutes.Agent.StudentProgram.General.replace(':programId', program.id!).replace(':studentId', studentId);
    }
  };
  return (
    <InfiniteWindowedGrid
      fetchMore={fetchMoreSavedPrograms}
      emptyStateType='saved'
      isFetching={isFetching}
      isLoading={savedProgramsLoading}
      hasMore={savedProgramsLeft > 0}
      remainingResults={savedProgramsLeft}
      minHeight={250}
      results={savedProgramsFlat?.map((program, index: any) => (
        <ApplicationCard
          key={`${index}-program`}
          variant='candidateApplicationsPage'
          studentName={`${program.application?.student?.firstName || ''} ${program.application?.student?.lastName || ''}`}
          studentAddress={program.application?.student?.studentProfile.address}
          // avatarSrc={}
          programName={program.name}
          universityName={program.universityName}
          programAddress={program.address}
          status={program.application?.status}
          path={goToProgramPage(program)}
        />
      ))}
    />
  );
};

export default SavedPrograms;
