import styled from 'styled-components';
import { theme } from 'theme';

export const StyledServiceItemWrapper = styled.div<{ minified?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & > *:nth-child(1) {
    flex: 1;
  }

  ${({ minified }) =>
    minified &&
    `
    display: grid;
      grid-template-columns: 1fr 1fr;
      &>*:nth-child(2),
      &>*:nth-child(4){
        justify-self: end;
      }
  `}
  ${({ theme, minified }) =>
    !minified &&
    `${theme.breakpoints.down('sm')}{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 60px 60px;
    justify-items: start;

    & > *:nth-child(3){
      align-self:end;
    }
    & > *:nth-child(4){
      align-self:start;
    }
  }`}
`;
export const StyledExpandableWrapper = styled.div<{ minified?: boolean }>`
  max-width: 360px;
  ${({ minified }) =>
    minified &&
    `
    align-self:start;
  `}
  ${({ theme, minified }) =>
    !minified &&
    `${theme.breakpoints.down('sm')}{
    width: 100%;
  max-width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
  }`}
`;
export const StyledExpandableHeader = styled.div<{ minified?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;

  gap: ${theme.spacing(3)}px;

  ${({ theme, minified }) =>
    !minified &&
    `${theme.breakpoints.down('sm')}{
    width: 100%;
  }`}
`;

export const StyledSelectWrapper = styled.div<{ minified?: boolean }>`
  & > * {
    max-width: 80px;
  }
  ${({ theme, minified }) =>
    !minified &&
    `${theme.breakpoints.down('sm')}{
    grid-row-start: 2;
    grid-row-end: 4;
    justify-self: center;
  }`}
`;

export const StyledExpandableContent = styled.div<{ expanded: boolean }>`
  overflow: hidden;
  max-height: 0;
  transition: 0.2s all;
  ${({ theme }) => `${theme.breakpoints.down('sm')}{
    padding-top: ${theme.spacing(2)}px;
  }`}
  ${({ expanded }) => expanded && `max-height: 200px`}
`;
