import styled from 'styled-components';

export const StyledPrepCenterDashboard = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;
