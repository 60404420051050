import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components, Paths } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export type UsePatchToDoPayload = Components.Schemas.UpdateToDoRequest & Paths.ToDoApiUpdateToDo.PathParameters & { documents?: File[] };

const usePatchTodo = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async ({ toDoId, documents, ...body }: UsePatchToDoPayload) => {
      const links = body.links?.filter(item => item.trim() !== '');
      const { data: ToDo } = await client!.ToDoApi_updateToDo({ toDoId }, { ...body, links });
      if (!documents?.length) return ToDo;
      const docs = [];

      for (let i = 0; i < documents.length; i++) {
        let formData = new FormData();
        formData.append('documentType', 'ToDo_DOCUMENT');
        formData.append('file', documents[i]);
        const { data } = await client!.ToDoApi_uploadDocument({ toDoId: ToDo.id }, formData);
        docs.push(data);
      }

      return { ...ToDo, documents: docs };
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.ToDo.Default);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default usePatchTodo;
