import styled from 'styled-components';
import { FormLabel } from '@material-ui/core';

export const StyledFluidContainer = styled.div`
  background-color: white;
  width: 100%;
`;

export const StyledHeroSection = styled.div`
  max-width: 1365px;
  margin: auto;
`;

export const StyledTitleContainer = styled.div`
  padding: 60px 0;
  width: min(960px, 90%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledFormSection = styled.div`
  width: min(960px, 90%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
`;

export const FormLabelText = styled.div`
  margin-top: 40px;
  font-size: 20px;
  font-weight: bold;
`;
