export type CandidateStatusNew =
  | 'NEW_LEAD'
  | 'CONTRACT_SIGNED'
  | 'RESEARCHING_PROGRAMS'
  | 'PROGRAMS_SELECTED'
  | 'ESSAY_PREP'
  | 'COLLECTING_DOCUMENTS'
  | 'APPLYING'
  | 'CLOSED_WON'
  | 'CLOSED_LOST';

export const candidateStatus = [
  {
    text: 'New Lead',
    value: 'NEW_LEAD',
  },
  {
    text: 'Contract Signed',
    value: 'CONTRACT_SIGNED',
  },
  {
    text: 'Ongoing Program Research',
    value: 'RESEARCHING_PROGRAMS',
  },
  {
    text: 'Programs Selected',
    value: 'PROGRAMS_SELECTED',
  },
  {
    text: 'Essay Prep',
    value: 'ESSAY_PREP',
  },
  {
    text: 'Collecting Documents',
    value: 'COLLECTING_DOCUMENTS',
  },
  {
    text: 'Application',
    value: 'APPLYING',
  },
  {
    text: 'Closed Won',
    value: 'CLOSED_WON',
  },
  {
    text: 'Closed Lost',
    value: 'CLOSED_LOST',
  },
];
