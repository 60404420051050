import { Box } from '@material-ui/core';
import { ReactComponent as HeartEmptyIcon } from 'assets/svg/Icons/Heart.svg';
import { ReactComponent as HeartFullIcon } from 'assets/svg/Icons/HeartFull.svg';
import { UniButton, UniChip, UniIconWrapper } from 'components';
import { activitiesCategoryMap } from 'constants/activities';
import { startCase, toLower } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import mediaPlaceholder from '../../../assets/images/media-placeholder.png';
import { Components } from '../../../client/UniClient';
import UniTypography from '../uniTypography/UniTypography';
import { StyledFooterBox, StyledLink, StyledMedia, StyledUniCardWrapper } from './UniEventCard.style';

interface Props extends Partial<Components.Schemas.EventResponse> {
  onClick?: () => void;
  pathname?: string;
  onToggleInterest?: (value: boolean) => void;
}

const UniEventCard = ({
  onClick,
  category,
  name,
  pathname,
  type,
  images,
  startDate,
  endDate,
  attendance,
  isInterested,
  onToggleInterest,
}: Props) => {
  const [interested, setInterested] = useState<boolean>(!!isInterested);
  const history = useHistory();
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile?.role);

  const isUpcoming = moment(startDate).isAfter(moment());
  const isToday = moment(startDate).isSame(moment(), 'day');

  const statusMap = {
    GOING: 'Going',
    INTERESTED: 'Interested',
  };

  const onUnmarkInterested = () => {
    setInterested(false);
    onToggleInterest && onToggleInterest(false);
  };

  const onMarkInterested = () => {
    setInterested(true);
    onToggleInterest && onToggleInterest(true);
  };

  const onClickView = () => {
    if (pathname) {
      history.push(pathname);
      return;
    }
    onClick && onClick();
  };

  return (
    <StyledUniCardWrapper size='L' border hoverable fit onClick={onClickView}>
      <StyledLink to={pathname || '#'}>
        <StyledMedia>
          <img src={images?.[0]?.imageFile?.location || mediaPlaceholder} alt={name} />
        </StyledMedia>
        <Box position='absolute' pl={1} pt={1} top='0' display='flex'>
          {category && <UniChip label={activitiesCategoryMap[category]} color='grey' />}
          <Box ml={1} />
          <UniChip label={isToday ? 'Today' : isUpcoming ? 'Upcoming' : 'Past'} color='primary' />
        </Box>
        <Box pt={4} pl={3} pr={3} display='grid' alignItems='start'>
          <UniTypography variant='body2' color='typographyBlack100' weight='semi-bold'>
            {name}
          </UniTypography>
          <Box mb={2}>
            <UniTypography variant='subheader' color='secondary100' weight='medium'>
              {startCase(toLower(type))}
            </UniTypography>
          </Box>
          <Box display='grid' mb={1}>
            <UniTypography variant='footnote' color='typographyGray100' weight='regular'>
              Start date
            </UniTypography>
            <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
              {moment(startDate).format('MMM DD, YYYY - h:mm a')}
            </UniTypography>
          </Box>
          <Box display='grid' mb={2}>
            <UniTypography variant='footnote' color='typographyGray100' weight='regular'>
              End date
            </UniTypography>
            <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
              {moment(endDate).format('MMM DD, YYYY - h:mm a')}
            </UniTypography>
          </Box>
        </Box>
        <Box flex={1} />
      </StyledLink>
      <StyledFooterBox>
        {isStudent && (
          <Box display='flex' style={{ alignItems: 'center' }}>
            {!interested && (
              <UniIconWrapper
                disableRipple
                disableHover
                clearMargins
                size='medium'
                onClick={onMarkInterested}
                color={interested ? 'primary100' : 'typographyGray100'}
              >
                <HeartEmptyIcon />
              </UniIconWrapper>
            )}
            {!!interested && (
              <UniIconWrapper
                disableRipple
                disableHover
                clearMargins
                size='medium'
                onClick={onUnmarkInterested}
                color={interested ? 'primary100' : 'typographyGray100'}
              >
                <HeartFullIcon />
              </UniIconWrapper>
            )}
            <Box ml={1}>
              {!!attendance?.feeInvoice?.isPaid && <UniChip color='green' label={statusMap['GOING']} />}
              {!!attendance?.feeInvoice && !attendance?.feeInvoice?.isPaid && <UniChip color='grey' label={'Unconfirmed'} />}
            </Box>
          </Box>
        )}
        <Box display='flex' style={{ alignItems: 'center' }}>
          <UniButton variant='main' color='primaryFade' size='small' onClick={onClickView}>
            View
          </UniButton>
        </Box>
      </StyledFooterBox>
    </StyledUniCardWrapper>
  );
};

export default UniEventCard;
