import { AxiosError } from 'axios';
import { Components, Paths } from 'client/UniClient';
import { InfiniteQueryConfig, useInfiniteQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Params extends Paths.EventApiGetEvents.QueryParameters {}

export default function useEvents(params?: Params, config?: InfiniteQueryConfig<Components.Schemas.EventResponse[]>) {
  const { client } = useClient();

  return useInfiniteQuery(
    [CacheKeys.Events.All, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.EventApi_getEvents({
        pageSize: 8,
        page,
      });

      try {
        const events: Components.Schemas.EventResponse[] = [];
        for (let i = 0; i < data.length; i++) {
          const event = data[i];

          const images: Components.Schemas.EventImageDto[] = [];
          for (let j = 0; j < event.images.length; j++) {
            const image = event.images[j];
            const { data: media } = await client!.FileApi_getDownloadUrl(image.imageFile.id);
            images.push({ ...image, imageFile: { ...image.imageFile, location: media.url } });
          }

          events.push({ ...event, images });
        }
        return events as Components.Schemas.EventResponse[];
      } catch (err) {
        return data as Components.Schemas.EventResponse[];
      }
    },
    {
      // ...config,
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
