import useAddServiceOffering from 'api/agency/useAddServiceOffering';
import { Components } from 'client/UniClient';
import { AnimatedTabPanel, UniModalDrawerButtons } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { AnimatePresence } from 'framer-motion';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import Step1 from './_steps/Step1';
import Step2 from './_steps/Step2';
import Step3 from './_steps/Step3';

interface Props {
  service?: Components.Schemas.ServiceOptionDto;
}

export default function AddServiceOffering({ service }: Props): ReactElement {
  const { t } = useTranslation('partnerServicesDrawers');
  const { closeDrawer } = useDrawers();
  const [step, setStep] = useState(0);
  const [addService, { isLoading }] = useAddServiceOffering({ serviceOptionId: service?.id! });
  const { register, control, handleSubmit, reset, setValue, errors } = useForm<Components.Schemas.CurrencyRequest>();

  const prevStep = () => {
    setStep(prev => prev - 1);
  };

  const nextStep = () => {
    setStep(prev => prev + 1);
  };

  const onAdd = (formData: Components.Schemas.CurrencyRequest) => {
    addService({ price: { amount: Number(formData?.amount), currencyIso: formData?.currencyIso } }, { onSuccess: () => nextStep() });
  };

  const onSubmit = () => {
    handleSubmit(onAdd)();
  };

  const buttonTexts = [
    {
      secondaryCallback: closeDrawer,
      primaryCallback: () => nextStep(),
      secondary: t('buttons.cancel'),
      primary: t('buttons.proceed'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: onSubmit,
      secondary: t('buttons.back'),
      primary: t('buttons.add'),
      loading: isLoading,
    },
    {
      primaryCallback: closeDrawer,
      primary: t('buttons.back_to_services'),
    },
  ];

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel Content={<Step1 service={service!} />} index={0} currentItem={step} />
          <AnimatedTabPanel
            Content={<Step2 control={control} setValue={setValue} register={register} errors={errors} />}
            index={1}
            currentItem={step}
          />
          <AnimatedTabPanel Content={<Step3 />} index={2} currentItem={step} />
        </AnimatePresence>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={
          buttonTexts[step]?.secondaryCallback
            ? {
                onClick: buttonTexts[step].secondaryCallback,
                label: buttonTexts[step].secondary,
              }
            : undefined
        }
        PrimaryButton={{
          onClick: buttonTexts[step]?.primaryCallback,
          label: buttonTexts[step]?.primary,
          loading: !!buttonTexts[step]?.loading,
        }}
      />
    </>
  );
}
