import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useApplicationUniversityCredentials from 'api/applications/useApplicationUniversityCredentials';
import usePostApplicationUniversityCredentials from 'api/applications/usePostApplicationUniversityCredentials';
import { UniButton, UniTextField } from '../../index';

interface Props {
  applicationId: string;
}

interface FormData {
  username: string;
  password: string;
}

export const ProgramCredentials: React.FC<Props> = ({ applicationId }) => {
  const { data: initialCredentials } = useApplicationUniversityCredentials({ applicationId });
  const [addCredentials] = usePostApplicationUniversityCredentials();

  const [status, setStatus] = useState('IDLE');

  const { register, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      username: initialCredentials?.username ?? '',
      password: initialCredentials?.password ?? '',
    },
  });

  useEffect(() => {
    setValue('username', initialCredentials?.username ?? '');
    setValue('password', initialCredentials?.password ?? '');
  }, [initialCredentials]);

  function saveCredentials(username: string, password: string) {
    setStatus('PENDING');
    addCredentials({ applicationId, username, password } as any, {
      onSuccess() {
        setStatus('SUCCESS');
        setTimeout(() => setStatus('IDLE'), 2000);
      },
      onError() {
        setStatus('ERROR');
        setTimeout(() => setStatus('IDLE'), 2000);
      },
    }).then();
  }

  function onSubmit({ username, password }: FormData) {
    saveCredentials(username, password);
  }

  return (
    <div style={{ width: '100%', maxWidth: 400 }}>
      {status === 'ERROR' && <div style={{ color: 'red' }}>Error saving credentials</div>}
      {status === 'SUCCESS' && <div style={{ color: 'green' }}>Credentials saved successfully</div>}
      <UniTextField
        fullWidth
        name='username'
        label='Username'
        placeholder='Enter username'
        type='text'
        inputRef={register()}
        labelColor='typographyGray70'
        backgroundColor='white'
      />
      <UniTextField
        fullWidth
        name='password'
        label='Password'
        placeholder='Enter password'
        type='password'
        inputRef={register()}
        labelColor='typographyGray70'
        backgroundColor='white'
        endAdornment
      />
      <UniButton
        color='primary'
        loading={status === 'PENDING'}
        onClick={handleSubmit(onSubmit)}
      >
        Save credentials
      </UniButton>
    </div>
  );
};

export default ProgramCredentials;
