import { Box } from '@material-ui/core';
import React from 'react';
import { UniTextField, UniTypography } from '../..';
import { TComponentColors } from '../../../constants/componentColors';
import { MetaItem } from '../../../views/_agent/candidateProfile/AgencyCandidateProfile.style';
import { UniTextWithIcon } from '../uniMetaData/UniMetaData';

export const EditableUniText = (props: {
  isEditing: boolean;
  inputRef: React.Ref<any>;
  name: string;
  label: string;
  value: string;
  iconColor?: TComponentColors;
  Icon?: React.ReactNode;
  fullWidth?: boolean;
}) => {
  if (props.isEditing) {
    return (
      <MetaItem>
        <Box width='80%'>
          <UniTypography color='typographyGray70' variant='footnote'>
            {props.label}
          </UniTypography>
          <UniTextField name={props.name} defaultValue={props.value} error={false} inputRef={props.inputRef} fullWidth={props.fullWidth} />
        </Box>
      </MetaItem>
    );
  }
  return <UniTextWithIcon iconColor={props.iconColor} Icon={props.Icon} label={props.label} value={props.value} />;
};
