import { Box } from '@material-ui/core';
import { useStudentOrders } from 'api';
import usePostServiceOrderSubmission from 'api/service-orders/usePostServiceOrderSubmission';
import { Components } from 'client/UniClient';
import { UniButton, UniPageTitle, UniTypography } from 'components';
import ServiceOrder from 'components/serviceOrder/ServiceOrder';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { useTranslation } from 'react-i18next';
import { useAuth, useDrawers } from 'store';
import { ConsultancyWrapper } from '../ConsultancyPage.style';

interface Props {
  orders: Components.Schemas.ServiceOrderDto[];
}

const Orders: React.FC<Props> = ({ children, ...rest }) => {
  const { profile: student } = useAuth();
  const { openDrawer, closeDrawer } = useDrawers();
  const { t } = useTranslation('consultancyPage');
  const studentId = useAuth(state => state.profile.id || '');
  const { data: orders, isLoading } = useStudentOrders({ studentId });
  const [submitOrder, { isLoading: isLoadingSubmitOrder }] = usePostServiceOrderSubmission();

  const onOrderCreated = async (data: any) => {
    if (!data.id) return;
    submitOrder(
      {
        serviceOrderId: data.id,
      },
      {
        onSuccess(data) {
          const paymentLink = data?.stripePaymentLink;
          if (!paymentLink) return;
          window.open(paymentLink);
          closeDrawer();
        },
      },
    );
  };

  if (isLoading || !orders) {
    return <UniListLoader />;
  }

  return (
    <ConsultancyWrapper>
      <Box display='flex' justifyContent='space-between'>
        <UniPageTitle title='Received orders' size='M' />
        <UniButton onClick={() => openDrawer('create-service-order', { studentId: student?.id!, onSuccess: onOrderCreated })}>
          Create new order
        </UniButton>
      </Box>
      <Box mt={3} mb={3}>
        <UniTypography variant='subheader' weight='medium' color='typographyGray100'>
          {t('orders.description')}
        </UniTypography>
      </Box>

      {orders.map((order, index) => (
        <>
          <ServiceOrder {...order} key={index} />
          <Box mt={2} />
        </>
      ))}
    </ConsultancyWrapper>
  );
};

export default Orders;
