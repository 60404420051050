import { AnimatePresence } from 'framer-motion';
import useOnScreen from 'hooks/useOnScreen';
import { useRef } from 'react';
import { StyledMotionWrapper, StyledWindowWrapper } from './InfiniteWindowedGrid.style';

interface Props {
  minHeight?: number;
}

const WindowedWrapper: React.FC<Props> = ({ minHeight = 368, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  return (
    <StyledWindowWrapper minHeight={minHeight} ref={ref}>
      <AnimatePresence>
        {isVisible && (
          <StyledMotionWrapper minHeight={minHeight} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            {children}
          </StyledMotionWrapper>
        )}
      </AnimatePresence>
    </StyledWindowWrapper>
  );
};

export default WindowedWrapper;
