import React from 'react';
import { Box } from '@material-ui/core';
import { useBreakpoints } from 'hooks';
import { StyledGradientWrapper, StyledHeader } from './UniContinentHeader.style';
import { UniIconWrapper, UniTypography } from 'components';
import Navigation from './UniContinentHeaderNavigation';
import { ReactComponent as EarthIcon } from '../../../assets/svg/Icons/Earth.svg';

interface Props {
  title: string;
  thirdBtnLink?: string;
  thirdBtnLabel?: string;
}

export const UniContinentHeader: React.FC<Props> = ({ title, thirdBtnLink, thirdBtnLabel }) => {
  const { isMobile } = useBreakpoints();

  return <StyledGradientWrapper>
    <Box width='100%'>
      <StyledHeader mobile={isMobile}>
        <Box display='flex' style={{ gap: '16px' }}>
          <Box display='flex' justifyContent='center' style={{ backgroundColor: '#a891ae', padding: '0.6rem', borderRadius: '15px' }}>
            <UniIconWrapper size='large' color='white100'>
              <EarthIcon />
            </UniIconWrapper>
          </Box>
          <Box display='grid' alignItems='center' justifyItems={isMobile && 'start'}>
            <UniTypography color='white100' weight='bold' variant='header1'>
              {title}
            </UniTypography>
            <UniTypography color='white100' weight='medium' variant='header2'>
              browse countries:
            </UniTypography>
          </Box>
        </Box>
        <Navigation thirdBtnLink={thirdBtnLink} thirdBtnLabel={thirdBtnLabel} />
      </StyledHeader>
    </Box>
  </StyledGradientWrapper>
}

export default UniContinentHeader;
