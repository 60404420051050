import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { theme } from '../../../theme';
import { UniAvatar, UniButton, UniIconWrapper, UniTypography } from '../../index';
import { ReactComponent as HomeIcon } from '../../../assets/svg/Icons/Home-solid.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/Icons/Magnifyingglass.svg';
import { ReactComponent as EarthIcon } from '../../../assets/svg/Icons/Earth.svg';
import { BrowserRoutes } from '../../../constants';

interface Props {
  thirdBtnLink?: string;
  thirdBtnLabel?: string;
}

export const Navigation: React.FC<Props> = ({ thirdBtnLink = '', thirdBtnLabel }) => {
  const history = useHistory();
    return (
    <Box display='flex' flexDirection='row' alignItems='center' style={{ gap: theme.spacing(1) }}>
      <UniButton
        startIcon={
          <UniIconWrapper size='main' color='primary100'>
            <HomeIcon />
          </UniIconWrapper>
        }
        size='small'
        color='white'
        borderRadius={[50, 50, 50, 50]}
        onClick={() => history.push(BrowserRoutes.Default.Homepage)}
      >
        Home
      </UniButton>
      <UniTypography color='white100'>/</UniTypography>
      <UniButton
        startIcon={
          <UniIconWrapper size='main' color='primary100'>
            <SearchIcon />
          </UniIconWrapper>
        }
        size='small'
        color='white'
        borderRadius={[50, 50, 50, 50]}
        onClick={() => history.push(BrowserRoutes.Default.Programs)}
      >
        Back to Search
      </UniButton>
      <UniTypography color='white100'>/</UniTypography>
      <UniButton
        startIcon={
          <UniIconWrapper size='main' color='primary100'>
            <EarthIcon />
          </UniIconWrapper>
        }
        size='small'
        color='white'
        borderRadius={[50, 50, 50, 50]}
        onClick={() => history.push(thirdBtnLink)}
      >
        {thirdBtnLabel}
      </UniButton>
    </Box>
  );

};

export default Navigation;
