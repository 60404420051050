import { useAnsweredPrograms } from 'api';
import { Components } from 'client/UniClient';
import { ApplicationCard, InfiniteWindowedGrid } from 'components';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { BrowserRoutes } from '../../../../constants';
interface Props {}

const AnsweredPrograms: React.FC<Props> = ({ children, ...rest }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const studentId = pathname.split('/')[4];
  const {
    data: answeredPrograms,
    status: answeredProgramsStatus,
    fetchMore: fetchMoreansweredPrograms,
    isFetching,
  } = useAnsweredPrograms({ studentId });

  const answeredProgramsLoading = answeredProgramsStatus === 'loading';
  const answeredProgramsFlat = useMemo(() => {
    return answeredPrograms?.map(progs => progs.results).flat() || [];
  }, [answeredPrograms, answeredProgramsStatus]);
  const answeredProgramsTotal = useMemo(() => (answeredPrograms ? answeredPrograms[0].total : 0), [answeredPrograms]);
  const answeredProgramsLeft = useMemo(
    () => answeredProgramsTotal - answeredProgramsFlat.length,
    [answeredProgramsFlat, answeredProgramsTotal],
  );
  const goToProgramPage = (program: Components.Schemas.ProgramResponse) => {
    if (program?.application?.id) {
      return BrowserRoutes.Agent.Application.General(program.application.id);
    } else {
      return BrowserRoutes.Agent.StudentProgram.General.replace(':programId', program.id!).replace(':studentId', studentId);
    }
  };
  return (
    <InfiniteWindowedGrid
      fetchMore={fetchMoreansweredPrograms}
      emptyStateType='programs'
      isFetching={isFetching}
      isLoading={answeredProgramsLoading}
      hasMore={answeredProgramsLeft > 0}
      remainingResults={answeredProgramsLeft}
      minHeight={250}
      results={answeredProgramsFlat?.map((program: any, index: any) => (
        <ApplicationCard
          key={`${index}-program`}
          variant='candidateApplicationsPage'
          studentName={`${program.application?.student?.firstName || ''} ${program.application?.student?.lastName || ''}`}
          studentAddress={program.application?.student?.studentProfile.address}
          // avatarSrc={}
          programName={program.name}
          universityName={program.universityName}
          programAddress={program.address}
          status={program.application?.status}
          path={goToProgramPage(program)}
        />
      ))}
    />
  );
};

export default AnsweredPrograms;
