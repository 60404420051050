import React, { ReactElement } from 'react';
import { StyledProgressBar } from './UniProgressBar.style';

export type Size = 's' | 'm';

interface Props {
  size?: Size;
  progress?: number;
}

export default function UniProgressBar({ size = 'm', progress = 0 }: Props): ReactElement {
  return <StyledProgressBar size={size} variant='determinate' value={progress}></StyledProgressBar>;
}
