import { useAllStudentPrograms } from 'api';
import { InfiniteWindowedGrid, UniProgramCard } from 'components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import { parseLevelOfStudy } from 'utils/programUtils';
interface Props {}

const AllPrograms: React.FC<Props> = ({ children, ...rest }) => {
  const { profile } = useAuth();
  const { t } = useTranslation('programsList');
  const {
    data: allPrograms,
    status: allProgramsStatus,
    fetchMore: fetchMoreAllPrograms,
    isFetching,
  } = useAllStudentPrograms({ studentId: profile.id! });

  const allProgramsLoading = allProgramsStatus === 'loading';
  const allProgramsFlat = useMemo(() => {
    return allPrograms?.map(progs => progs.results).flat() || [];
  }, [allPrograms, allProgramsStatus]);
  const allProgramsTotal = useMemo(() => (allPrograms ? allPrograms[0].total : 0), [allPrograms]);
  const allProgramsLeft = useMemo(() => allProgramsTotal - allProgramsFlat.length, [allProgramsFlat, allProgramsTotal]);

  return (
    <InfiniteWindowedGrid
      fetchMore={fetchMoreAllPrograms}
      emptyStateType='programs'
      isFetching={isFetching}
      isLoading={allProgramsLoading}
      hasMore={allProgramsLeft > 0}
      remainingResults={allProgramsLeft}
      results={allProgramsFlat?.map((program: any, index: any) => (
        <UniProgramCard
          showUniName
          key={`${index}-allPrograms`}
          fit
          showStatus
          program={program}
          metricsArr={[
            {
              label: t('headers.level_of_study'),
              value: parseLevelOfStudy(program.levelOfStudy),
            },
            {
              label: t('program.tuition_fee'),
              value: program?.tuitionFee?.currency
                ? `${program.tuitionFee?.currency?.amount} ${program.tuitionFee?.currency?.currencyIso} / ${program.tuitionFee?.lecturePeriod}`
                : 'Unspecified',
            },
            {
              label: t('program.application_fee'),
              value: 'Unspecified',
            },
          ]}
        />
      ))}
    />
  );
};

export default AllPrograms;
