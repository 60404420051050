import axios from 'axios';
import { useMutation } from 'react-query';

export type EventType = 'SEARCH' | 'CLICK' | 'SELECT_CATEGORY' | 'SELECT_COUNTRY';

interface Payload {
  event: EventType;
  data: any;
}

const baseUrl = 'https://ai-search.younichoice.com/events';

const usePostEvent = () => {
  return useMutation(async (payload: Payload) => {
    return axios.post(baseUrl, payload);
  });
};

export default usePostEvent;
