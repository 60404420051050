import { Grid } from '@material-ui/core';
import { useBreakpoints } from 'hooks';
import styled from 'styled-components';
import { Sizes } from '../../../constants';
import HomepageFooter from '../homepageFooter/HomepageFooter';

const UniAuthPageStyled = styled(({ children, ...rest }) => {
  const { isMobile } = useBreakpoints();
  return (
    <>
      <Grid container spacing={2} {...rest}>
        {children}
      </Grid>
      {!isMobile && <HomepageFooter />}
    </>
  );
})`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  align-content: flex-start;
  min-height: ${({ theme }) => `calc(100vh - ${theme.sizes.authNavbarHeight}px - ${theme.spacing(5)}px)`};
  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: ${Sizes.MaxWidthWrapper}px;
  }
`;

export default UniAuthPageStyled;
