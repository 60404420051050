import { Grid } from '@material-ui/core';
import { useUniversityApplications } from 'api';
import { ApplicationCard, InfiniteWindowedGrid } from 'components';
import { ApplicationsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ApplicationsFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useAuth, useDrawers } from 'store';
import { UniHeader, UniHerosection, UniPage } from '../../../components';
import { BrowserRoutes } from '../../../constants';

const Applications = () => {
  const { openDrawer } = useDrawers();
  const { queries } = useQueryStringParser<ApplicationsFiltersQueries>();
  const { profile } = useAuth();
  const openFiltersDrawer = () => openDrawer('filter-applications');
  const history = useHistory();
  const universityId = profile.facultyMemberProfile?.universityId;

  const {
    data: applications,
    status: appsStatus,
    fetchMore,
    isFetching,
    canFetchMore,
  } = useUniversityApplications({
    universityId,
    filters: queries,
  });

  const appsLoading = appsStatus === 'loading';
  const applicationsTotal = applications ? applications[0].total : 0;
  const applicationsFlat = applications?.map(page => page.results)?.flat() || [];
  const applicationsLeft = useMemo(() => applicationsTotal - applicationsFlat.length, [applicationsFlat, applicationsTotal]);

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='title'
          title='Applications'
          filterbarHeadline='Find applications'
          onClickFilters={openFiltersDrawer}
          options={[]}
          withFilterBar
        />
      </UniHeader>
      <UniPage>
        <Grid item xs={12}>
          <InfiniteWindowedGrid
            isFetching={isFetching}
            emptyStateType='applications'
            fetchMore={fetchMore}
            isLoading={appsLoading}
            hasMore={applicationsLeft > 0}
            totalResults={applicationsTotal}
            remainingResults={applicationsLeft}
            minHeight={330}
            results={applicationsFlat?.map((application: any, index: any) => (
              <ApplicationCard
                variant='universityPage'
                studentName={`${application?.student?.firstName + ' ' + application?.student?.lastName}`}
                studentAddress={application?.student?.studentProfile?.address}
                programName={application?.program?.name}
                programAddress={application?.program?.address}
                status={application?.status}
                path={BrowserRoutes.University.Application.Student(application?.id)}
              />
            ))}
          />
        </Grid>
      </UniPage>
    </>
  );
};

export default Applications;
