import { Box } from '@material-ui/core';
import { useBreakpoints } from 'hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LocationIcon } from '../../assets/svg/Icons/Location.svg';
import { ReactComponent as ProgramIcon } from '../../assets/svg/Icons/Nav/Programs.svg';
import { ReactComponent as ProfileIcon } from '../../assets/svg/Icons/Profile.svg';
import { ReactComponent as FeeIcon } from '../../assets/svg/Icons/Program/Fee.svg';
import { Components } from '../../client/UniClient';
import UniIconWrapper from '../../components/_common/uniIconWrapper/UniIconWrapper';
import UniTypography from '../../components/_common/uniTypography/UniTypography';
import { theme } from '../../theme';
import { MetaItem, UniversityMeta } from './UniversityPage.style';

interface Props {
  university: Components.Schemas.UniversityResponse;
}

export default function UniversityPageHeaderMeta({ university }: Props): ReactElement {
  const { t } = useTranslation('universityPage');
  const { isMobile } = useBreakpoints();

  if (isMobile) {
    return <></>;
  }

  return (
    <UniversityMeta>
      <Box display='flex' justifyContent='flex-start' style={{ gap: `${theme.spacing(4)}px` }}>
        <MetaItem>
          <UniIconWrapper color='secondary100' withBackground>
            <LocationIcon />
          </UniIconWrapper>
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('header.meta_items.location')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              {` ${university?.address?.city ? university?.address?.city : '-'} / ${
                university?.address?.country ? university?.address?.country : '-'
              }`}
            </UniTypography>
          </Box>
        </MetaItem>
        <MetaItem>
          <UniIconWrapper color='secondary100' withBackground>
            <ProgramIcon />
          </UniIconWrapper>
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('header.meta_items.no_programs')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              {university?.programCount}
            </UniTypography>
          </Box>
        </MetaItem>
        <MetaItem>
          <UniIconWrapper color='secondary100' withBackground>
            <ProfileIcon />
          </UniIconWrapper>
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('header.meta_items.total_students')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              {university?.studentCount}
            </UniTypography>
          </Box>
        </MetaItem>
        <MetaItem>
          <UniIconWrapper color='secondary100' withBackground>
            <ProfileIcon />
          </UniIconWrapper>
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('header.meta_items.int_students')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              {university?.internationalStudentCount}
            </UniTypography>
          </Box>
        </MetaItem>
      </Box>
      <Box display='flex' justifyContent='flex-start' style={{ gap: `${theme.spacing(4)}px` }}>
        <MetaItem>
          <UniIconWrapper color='secondary100' withBackground>
            <FeeIcon />
          </UniIconWrapper>
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('header.meta_items.tuition')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              {`${university?.averageTuitionFee.currency.amount || '-'} ${university?.averageTuitionFee.currency.currencyIso || ''} `}
            </UniTypography>
          </Box>
        </MetaItem>
        {/* <MetaItem>
          <UniIconWrapper color='secondary100' withBackground>
            <FeeIcon />
          </UniIconWrapper>
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('header.meta_items.living_cost')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              $12 000 Eur
            </UniTypography>
          </Box>
        </MetaItem> */}
      </Box>
    </UniversityMeta>
  );
}
