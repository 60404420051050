import styled from 'styled-components';

export const StyledApplicationRequirments = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)}px;
`;

export const StyledApplicationRequirmentsQuestions = styled.div``;
export const StyledApplicationRequirmentsQuestionsItem = styled.div``;
