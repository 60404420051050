import { AxiosError } from 'axios';
import { queryCache, useMutation, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  programInfoRequestId: string;
};

export default function useCompleteProgramInfoRequest(params: Params) {
  const { client } = useClient();
  return useMutation(
    async () => {
      return client!.ProgramApi_completeProgramInfoRequest(params, undefined);
    },
    {
      onSuccess() {
        queryCache.invalidateQueries([CacheKeys.Admin.ProgramRequests, params]);
      },
    },
  );
}
