import LayoutRoute from 'components/_layouts/layoutRoute/LayoutRoute';
import React, { lazy } from 'react';
import { BrowserRoutes } from '../constants';
const AgencyAdminAgents = lazy(() =>
  import('../views/_agency/agents/AdminAgentsList').then(({ AgencyAdminAgents }) => ({ default: AgencyAdminAgents })),
);
const AgencyAdminUniversities = lazy(() =>
  import('../views/_agency/universities/AgencyAdminUniversities').then(({ AgencyAdminUniversities }) => ({
    default: AgencyAdminUniversities,
  })),
);
const AgencyCandidates = lazy(() => import('../views').then(({ AgencyCandidates }) => ({ default: AgencyCandidates })));
const UniversityPage = lazy(() => import('views/universityPage/UniversityPage'));
const AgentProfile = lazy(() => import('../views/_agent/profile/AgentProfile'));
const AgencyAdminHome = lazy(() => import('../views/_agency/home/AgencyAdminHome'));
// const AgencyAdminHome = lazy(() => import('../views/_agent/home/AgencyHome'));
const AgencyApplications = lazy(() => import('../views').then(({ AgencyApplications }) => ({ default: AgencyApplications })));
const AgencyCandidateProfile = lazy(() => import('../views').then(({ AgencyCandidateProfile }) => ({ default: AgencyCandidateProfile })));
const StudentProgramPage = lazy(() => import('../views').then(({ AgencyStudentProgramPage }) => ({ default: AgencyStudentProgramPage })));
const AgencyPrograms = lazy(() => import('../views').then(({ AgencyPrograms }) => ({ default: AgencyPrograms })));
const ApplicationPage = lazy(() => import('../views').then(({ AgencyApplication }) => ({ default: AgencyApplication })));
const PartnerServices = lazy(() => import('../views').then(({ PartnerServices }) => ({ default: PartnerServices })));
const AgencyProgramPage = lazy(() => import('../views').then(({ AgencyProgramPage }) => ({ default: AgencyProgramPage })));

const AgencyAdminRoutes = () => {
  return [
    <LayoutRoute exact path={BrowserRoutes.Agent.Default} key={BrowserRoutes.Agent.Default} component={AgencyAdminHome} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.Student.Default}
      key={BrowserRoutes.Agent.Student.Default}
      component={AgencyCandidateProfile}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.StudentProgram.Default}
      key={BrowserRoutes.Agent.StudentProgram.Default}
      component={StudentProgramPage}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.Application.Default}
      key={BrowserRoutes.Agent.Application.Default}
      component={ApplicationPage}
    />,
    <LayoutRoute path={BrowserRoutes.Agent.Applications} key={BrowserRoutes.Agent.Applications} component={AgencyApplications} />,
    <LayoutRoute path={BrowserRoutes.Agent.Students.Default} key={BrowserRoutes.Agent.Students.Default} component={AgencyCandidates} />,
    <LayoutRoute path={BrowserRoutes.Agent.Profile} key={BrowserRoutes.Agent.Profile} component={AgentProfile} />,
    // this is for The universities Page
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.Universities}
      key={BrowserRoutes.Agent.Universities}
      component={AgencyAdminUniversities}
    />,
    <LayoutRoute path={BrowserRoutes.Agent.Programs} key={BrowserRoutes.Agent.Programs} exact component={AgencyPrograms} />,
    <LayoutRoute path={BrowserRoutes.Agent.Team} key={BrowserRoutes.Agent.Team} component={AgencyAdminAgents} />,
    <LayoutRoute path={BrowserRoutes.Agent.Agent()} key={BrowserRoutes.Agent.Agent()} component={AgentProfile} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.Program.General}
      key={BrowserRoutes.Agent.Program.General}
      component={AgencyProgramPage}
    />,
    <LayoutRoute path={BrowserRoutes.Agency.UniversityProfile} key={BrowserRoutes.Agency.UniversityProfile} component={UniversityPage} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.Services.Default}
      key={BrowserRoutes.Agent.Services.Default}
      component={PartnerServices}
    />,
  ];
};

export default AgencyAdminRoutes;
