import ComponentColors, { TComponentColors } from 'constants/componentColors';
import styled from 'styled-components';
import { Colors } from '../../../constants';

export const StyledUniListItem = styled.div<{
  type: 'label' | 'twoLabels' | 'iconed';
  size?: 'L' | 'M' | 'S';
  autoHeight?: boolean;
  withBackground?: boolean;
  backgroundColor?: TComponentColors;
}>`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(2)}px;
  justify-content: flex-start;
  ${({ withBackground, backgroundColor }) =>
    withBackground
      ? !!backgroundColor
        ? `background-color:${ComponentColors[backgroundColor!]}`
        : `background-color:${Colors.Gray2._40}`
      : ''};
  min-height: ${({ size, theme }) => (size === 'L' ? theme.spacing(7.375) : size === 'M' ? theme.spacing(7) : theme.spacing(6.625))}px;
  padding: ${({ theme }) => theme.spacing(2, 2, 2, 2)};
  width: 100%;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ autoHeight }) => autoHeight && 'height:auto;'}
  }
`;

export const StyledDiv = styled.div<{ size?: 'L' | 'M' | 'S' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: ${({ theme }) => theme.spacing(-1.5)}px;
`;
