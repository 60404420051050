import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// EN
import addAgencyPageEn from './static/locales/en/addAgencyPage.json';
import addAgentPageEn from './static/locales/en/addAgentPage.json';
import addCandidatePageEn from './static/locales/en/addCandidatePage.json';
import addEducationDrawerEn from './static/locales/en/addEducationDrawer.json';
import addActivityItemDrawerEn from './static/locales/en/drawers/addActivityItemDrawer.json';
import additionalDocumentsEn from './static/locales/en/additionalDocuments.json';
import adminAgencyProfileEn from './static/locales/en/adminAgencyProfile.json';
import adminProgramsEn from './static/locales/en/adminPrograms.json';
import agencyAdminAgentsEn from './static/locales/en/agencyAdminAgents.json';
import agencyAdminCandidatesEn from './static/locales/en/agencyAdminCandidates.json';
import agencyAdminHomeEn from './static/locales/en/agencyAdminHome.json';
import agencyAdminProgramsEn from './static/locales/en/agencyAdminPrograms.json';
import agencyAdminUniversitiesEn from './static/locales/en/agencyAdminUniversities.json';
import agencyCandidatesEn from './static/locales/en/agencyCandidates.json';
import agencyHomeEn from './static/locales/en/agencyHome.json';
import agencyProfileEn from './static/locales/en/agencyProfile.json';
import applicationReplyHistoryItemEn from './static/locales/en/applicationReplyHistoryItem.json';
import applicationRequirmentsEn from './static/locales/en/applicationRequirments.json';
import applicationsListEn from './static/locales/en/applicationsList.json';
import authDropdownEn from './static/locales/en/authentication/authDropdown.json';
import emailSentEn from './static/locales/en/authentication/emailSent.json';
import forgotPasswordEn from './static/locales/en/authentication/forgotPassword.json';
import authLoginEn from './static/locales/en/authentication/login.json';
import oAuthButtonsEn from './static/locales/en/authentication/oAuthButtons.json';
import resetPasswordEn from './static/locales/en/authentication/resetPassword.json';
import authSignupEn from './static/locales/en/authentication/signup.json';
import buttonsEn from './static/locales/en/buttons.json';
import candidateQuickViewEn from './static/locales/en/candidateQuickView.json';
import cardsEN from './static/locales/en/cards.json';
import applicationCardEn from './static/locales/en/components/applicationCard.json';
import taskCardEn from './static/locales/en/components/taskCard.json';
import herosectionEn from './static/locales/en/components/herosection.json';
import serviceCardEn from './static/locales/en/components/serviceCard.json';
import serviceItemEn from './static/locales/en/components/serviceItem.json';
import serviceOrderEn from './static/locales/en/components/serviceOrder.json';
import consultancyPageEn from './static/locales/en/consultancyPage.json';
import deleteEducationDrawerEn from './static/locales/en/deleteEducationDrawer.json';
import applicationDrawerEn from './static/locales/en/drawers/applicationDrawer.json';
import createProgramEn from './static/locales/en/drawers/createProgram.json';
import partnerServicesDrawersEn from './static/locales/en/drawers/partnerServicesDrawers.json';
import replyToUniversityEn from './static/locales/en/drawers/replyToUniversity.json';
import updateCandidateStatusEn from './static/locales/en/drawers/updateCandidateStatus.json';
import editEducationDrawerEn from './static/locales/en/editEducationDrawer.json';
import emptyStateEN from './static/locales/en/emptyState.json';
import filterAgenciesPageEn from './static/locales/en/filterAgenciesPage.json';
import filterApplicationPageEN from './static/locales/en/filterApplicationsPage.json';
import filterBarEn from './static/locales/en/filterBar.json';
import filterCandidatesPageEn from './static/locales/en/filterCandidatesPage.json';
import filterProgramsPageEn from './static/locales/en/filterProgramsPage.json';
import filtersDrawerEn from './static/locales/en/filtersDrawer.json';
import filterUniversitiesEn from './static/locales/en/filterUniversities.json';
import formsEn from './static/locales/en/forms.json';
import homepageFooterEn from './static/locales/en/homepageFooter.json';
import inputsEn from './static/locales/en/inputs.json';
import landingAboutUsEn from './static/locales/en/landingAboutUs.json';
import landingAgencyEn from './static/locales/en/landingAgency.json';
import landingHomeEn from './static/locales/en/landingHome.json';
import landingProgramsEn from './static/locales/en/landingPrograms.json';
import landingStudentEn from './static/locales/en/landingStudent.json';
import landingUniversityEn from './static/locales/en/landingUniversity.json';
import { default as metricsEn, default as metricsRo } from './static/locales/en/metrics.json';
import partnerServicesEn from './static/locales/en/partnerServices.json';
import prepCenterEn from './static/locales/en/prepCenter.json';
import profileRoleEn from './static/locales/en/profileRole.json';
import programGeneralInfoEn from './static/locales/en/program-page/programGeneralInfo.json';
import programStatusRepliseEn from './static/locales/en/program-page/programStatusReplise.json';
import programPageEn from './static/locales/en/programPage.json';
import programsListEn from './static/locales/en/programsList.json';
import promotedUniversityEn from './static/locales/en/promotedUniversity.json';
import snackbarEN from './static/locales/en/snackbar.json';
import stepperEn from './static/locales/en/stepper.json';
import studentAppTrackerEn from './static/locales/en/studentAppTracker.json';
import studentApplicationsEn from './static/locales/en/studentApplications.json';
import studentCardEn from './static/locales/en/studentCard.json';
import studentDashboardEn from './static/locales/en/studentDashboard.json';
import studentDocumentsEn from './static/locales/en/studentDocuments.json';
import studentEducationEn from './static/locales/en/studentEducation.json';
import studentEligibilityEn from './static/locales/en/studentEligibility.json';
import studentEnrollmentDrawerEn from './static/locales/en/studentEnrollmentDrawer.json';
import studentHomeEn from './static/locales/en/studentHome.json';
import studentPreferencesEn from './static/locales/en/studentPreferences.json';
import studentProfileEn from './static/locales/en/studentProfile.json';
import studentProgramsEn from './static/locales/en/studentPrograms.json';
import superAdminAgencyEn from './static/locales/en/superAdminAgency.json';
import superAdminHomeEn from './static/locales/en/superAdminHome.json';
import uniFormsEn from './static/locales/en/uniForms.json';
import uniListHeaderEn from './static/locales/en/uniListHeader.json';
import { default as universityApplicationsEn, default as universityApplicationsRo } from './static/locales/en/universityApplications.json';
import universityFacultiesPageEn from './static/locales/en/universityFacultiesPage.json';
import universityFacultyPageEn from './static/locales/en/universityFacultyPage.json';
import universityPageEn from './static/locales/en/universityPage.json';
import universityProgramPageEn from './static/locales/en/universityProgramPage.json';
import universityReplyFormEn from './static/locales/en/universityReplyForm.json';
import updatePreferencesPopupEn from './static/locales/en/updatePreferencesPopup.json';
// RO
import addAgencyPageRo from './static/locales/ro/addAgencyPage.json';
import addAgentPageRo from './static/locales/ro/addAgentPage.json';
import addCandidatePageRo from './static/locales/ro/addCandidatePage.json';
import addEducationDrawerRo from './static/locales/ro/addEducationDrawer.json';
import additionalDocumentsRo from './static/locales/ro/additionalDocuments.json';
import adminAgencyProfileRo from './static/locales/ro/adminAgencyProfile.json';
import adminProgramsRo from './static/locales/ro/adminPrograms.json';
import agencyAdminAgentsRo from './static/locales/ro/agencyAdminAgents.json';
import agencyAdminCandidatesRo from './static/locales/ro/agencyAdminCandidates.json';
import agencyAdminHomeRo from './static/locales/ro/agencyAdminHome.json';
import agencyAdminProgramsRo from './static/locales/ro/agencyAdminPrograms.json';
import agencyAdminUniversitiesRo from './static/locales/ro/agencyAdminUniversities.json';
import agencyCandidatesRo from './static/locales/ro/agencyCandidates.json';
import agencyHomeRo from './static/locales/ro/agencyHome.json';
import agencyProfileRo from './static/locales/ro/agencyProfile.json';
import applicationReplyHistoryItemRo from './static/locales/ro/applicationReplyHistoryItem.json';
import applicationRequirmentsRo from './static/locales/ro/applicationRequirments.json';
import applicationsListRo from './static/locales/ro/applicationsList.json';
import authDropdownRo from './static/locales/ro/authentication/authDropdown.json';
import emailSentRo from './static/locales/ro/authentication/emailSent.json';
import forgotPasswordRo from './static/locales/ro/authentication/forgotPassword.json';
import authLoginRo from './static/locales/ro/authentication/login.json';
import oAuthButtonsRo from './static/locales/ro/authentication/oAuthButtons.json';
import resetPasswordRo from './static/locales/ro/authentication/resetPassword.json';
import authSignupRo from './static/locales/ro/authentication/signup.json';
import buttonsRo from './static/locales/ro/buttons.json';
import candidateQuickViewRo from './static/locales/ro/candidateQuickView.json';
import cardsRO from './static/locales/ro/cards.json';
import applicationCardRo from './static/locales/ro/components/applicationCard.json';
import herosectionRo from './static/locales/ro/components/herosection.json';
import serviceCardRo from './static/locales/ro/components/serviceCard.json';
import serviceItemRo from './static/locales/ro/components/serviceItem.json';
import serviceOrderRo from './static/locales/ro/components/serviceOrder.json';
import consultancyPageRo from './static/locales/ro/consultancyPage.json';
import deleteEducationDrawerRo from './static/locales/ro/deleteEducationDrawer.json';
import applicationDrawerRo from './static/locales/ro/drawers/applicationDrawer.json';
import createProgramRo from './static/locales/ro/drawers/createProgram.json';
import partnerServicesDrawersRo from './static/locales/ro/drawers/partnerServicesDrawers.json';
import replyToUniversityRo from './static/locales/ro/drawers/replyToUniversity.json';
import updateCandidateStatusRo from './static/locales/ro/drawers/updateCandidateStatus.json';
import editEducationDrawerRo from './static/locales/ro/editEducationDrawer.json';
import emptyStateRO from './static/locales/ro/emptyState.json';
import filterAgenciesPageRo from './static/locales/ro/filterAgenciesPage.json';
import filterApplicationPageRO from './static/locales/ro/filterApplicationsPage.json';
import filterBarRo from './static/locales/ro/filterBar.json';
import filterCandidatesPageRo from './static/locales/ro/filterCandidatesPage.json';
import filterProgramsPageRo from './static/locales/ro/filterProgramsPage.json';
import filtersDrawerRo from './static/locales/ro/filtersDrawer.json';
import filterUniversitiesRo from './static/locales/ro/filterUniversities.json';
import formsRo from './static/locales/ro/forms.json';
import homepageFooterRo from './static/locales/ro/homepageFooter.json';
import inputsRo from './static/locales/ro/inputs.json';
import landingAboutUsRo from './static/locales/ro/landingAboutUs.json';
import landingAgencyRo from './static/locales/ro/landingAgency.json';
import landingHomeRo from './static/locales/ro/landingHome.json';
import landingProgramsRo from './static/locales/ro/landingPrograms.json';
import landingStudentRo from './static/locales/ro/landingStudent.json';
import landingUniversityRo from './static/locales/ro/landingUniversity.json';
import partnerServicesRo from './static/locales/ro/partnerServices.json';
import prepCenterRo from './static/locales/ro/prepCenter.json';
import profileRoleRo from './static/locales/ro/profileRole.json';
import programGeneralInfoRo from './static/locales/ro/program-page/programGeneralInfo.json';
import programStatusRepliseRo from './static/locales/ro/program-page/programStatusReplise.json';
import programPageRo from './static/locales/ro/programPage.json';
import programsListRo from './static/locales/ro/programsList.json';
import promotedUniversityRo from './static/locales/ro/promotedUniversity.json';
import snackbarRO from './static/locales/ro/snackbar.json';
import stepperRo from './static/locales/ro/stepper.json';
import studentApplicationsRo from './static/locales/ro/studentApplications.json';
import studentCardRo from './static/locales/ro/studentCard.json';
import studentDocumentsRo from './static/locales/ro/studentDocuments.json';
import studentEducationRo from './static/locales/ro/studentEducation.json';
import studentEligibilityRo from './static/locales/ro/studentEligibility.json';
import studentEnrollmentDrawerRo from './static/locales/ro/studentEnrollmentDrawer.json';
import studentHomeRo from './static/locales/ro/studentHome.json';
import studentPreferencesRo from './static/locales/ro/studentPreferences.json';
import studentProfileRo from './static/locales/ro/studentProfile.json';
import studentProgramsRo from './static/locales/ro/studentPrograms.json';
import superAdminAgencyRo from './static/locales/ro/superAdminAgency.json';
import superAdminHomeRo from './static/locales/ro/superAdminHome.json';
import uniFormsRo from './static/locales/ro/uniForms.json';
import uniListHeaderRo from './static/locales/ro/uniListHeader.json';
import universityFacultiesPageRo from './static/locales/ro/universityFacultiesPage.json';
import universityFacultyPageRo from './static/locales/ro/universityFacultyPage.json';
import universityPageRo from './static/locales/ro/universityPage.json';
import universityProgramPageRo from './static/locales/ro/universityProgramPage.json';
import universityReplyFormRo from './static/locales/ro/universityReplyForm.json';
import updatePreferencesPopupRo from './static/locales/ro/updatePreferencesPopup.json';
// UA
import addAgencyPageUa from './static/locales/ua/addAgencyPage.json';
import addAgentPageUa from './static/locales/ua/addAgentPage.json';
import addCandidatePageUa from './static/locales/ua/addCandidatePage.json';
import addEducationDrawerUa from './static/locales/ua/addEducationDrawer.json';
import additionalDocumentsUa from './static/locales/ua/additionalDocuments.json';
import adminAgencyProfileUa from './static/locales/ua/adminAgencyProfile.json';
import agencyAdminAgentsUa from './static/locales/ua/agencyAdminAgents.json';
import agencyAdminCandidatesUa from './static/locales/ua/agencyAdminCandidates.json';
import agencyAdminHomeUa from './static/locales/ua/agencyAdminHome.json';
import agencyAdminProgramsUa from './static/locales/ua/agencyAdminPrograms.json';
import agencyAdminUniversitiesUa from './static/locales/ua/agencyAdminUniversities.json';
import agencyCandidatesUa from './static/locales/ua/agencyCandidates.json';
import agencyHomeUa from './static/locales/ua/agencyHome.json';
import agencyProfileUa from './static/locales/ua/agencyProfile.json';
import applicationReplyHistoryItemUa from './static/locales/ua/applicationReplyHistoryItem.json';
import applicationRequirmentsUa from './static/locales/ua/applicationRequirments.json';
import applicationsListUa from './static/locales/ua/applicationsList.json';
import authDropdownUa from './static/locales/ua/authentication/authDropdown.json';
import emailSentUa from './static/locales/ua/authentication/emailSent.json';
import forgotPasswordUa from './static/locales/ua/authentication/forgotPassword.json';
import authLoginUa from './static/locales/ua/authentication/logIn.json';
import oAuthButtonsUa from './static/locales/ua/authentication/oAuthButtons.json';
import resetPasswordUa from './static/locales/ua/authentication/resetPassword.json';
import authSignupUa from './static/locales/ua/authentication/signup.json';
import candidateQuickViewUa from './static/locales/ua/candidateQuickView.json';
import deleteEducationDrawerUa from './static/locales/ua/deleteEducationDrawer.json';
import applicationDrawerUa from './static/locales/ua/drawers/applicationDrawer.json';
import createProgramUa from './static/locales/ua/drawers/createProgram.json';
import replyToUniversityUa from './static/locales/ua/drawers/replyToUniversity.json';
import editEducationDrawerUa from './static/locales/ua/editEducationDrawer.json';
import emptyStateUa from './static/locales/ua/emptyState.json';
import filterAgenciesPageUa from './static/locales/ua/filterAgenciesPage.json';
import filterApplicationPageUa from './static/locales/ua/filterApplicationsPage.json';
import filterBarUa from './static/locales/ua/filterBar.json';
import filterCandidatesPageUa from './static/locales/ua/filterCandidatesPage.json';
import filterProgramsPageUa from './static/locales/ua/filterProgramsPage.json';
import filtersDrawerUa from './static/locales/ua/filtersDrawer.json';
import filterUniversitiesUa from './static/locales/ua/filterUniversities.json';
import formsUa from './static/locales/ua/forms.json';
import homepageFooterUa from './static/locales/ua/homepageFooter.json';
import landingAboutUsUa from './static/locales/ua/landingAboutUs.json';
import landingAgencyUa from './static/locales/ua/landingAgency.json';
import landingHomeUa from './static/locales/ua/landingHome.json';
import landingProgramsUa from './static/locales/ua/landingPrograms.json';
import landingStudentUa from './static/locales/ua/landingStudent.json';
import landingUniversityUa from './static/locales/ua/landingUniversity.json';
import profileRoleUa from './static/locales/ua/profileRole.json';
import programGeneralInfoUa from './static/locales/ua/program-page/programGeneralInfo.json';
import programStatusRepliseUa from './static/locales/ua/program-page/programStatusReplise.json';
import programPageUa from './static/locales/ua/programPage.json';
import programsListUa from './static/locales/ua/programsList.json';
import studentApplicationsUa from './static/locales/ua/studentApplications.json';
import studentDocumentsUa from './static/locales/ua/studentDocuments.json';
import studentEducationUa from './static/locales/ua/studentEducation.json';
import studentEligibilityUa from './static/locales/ua/studentEligibility.json';
import studentEnrollmentDrawerUa from './static/locales/ua/studentEnrollmentDrawer.json';
import studentHomeUa from './static/locales/ua/studentHome.json';
import studentPreferencesUa from './static/locales/ua/studentPreferences.json';
import studentProfileUa from './static/locales/ua/studentProfile.json';
import studentProgramsUa from './static/locales/ua/studentPrograms.json';
import superAdminAgencyUa from './static/locales/ua/superAdminAgency.json';
import superAdminHomeUa from './static/locales/ua/superAdminHome.json';
import uniFormsUa from './static/locales/ua/uniForms.json';
import uniListHeaderUa from './static/locales/ua/uniListHeader.json';
import { default as universityApplicationsUa } from './static/locales/ua/universityApplications.json';
import universityFacultiesPageUa from './static/locales/ua/universityFacultiesPage.json';
import universityFacultyPageUa from './static/locales/ua/universityFacultyPage.json';
import universityPageUa from './static/locales/ua/universityPage.json';
import universityProgramPageUa from './static/locales/ua/universityProgramPage.json';
import universityReplyFormUa from './static/locales/ua/universityReplyForm.json';
import updatePreferencesPopupUa from './static/locales/ua/updatePreferencesPopup.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        applicationsList: applicationsListEn,
        buttons: buttonsEn,
        inputs: inputsEn,
        superAdminHome: superAdminHomeEn,
        superAdminAgency: superAdminAgencyEn,
        agencyAdminHome: agencyAdminHomeEn,
        agencyAdminAgents: agencyAdminAgentsEn,
        agencyAdminCandidates: agencyAdminCandidatesEn,
        agencyCandidates: agencyCandidatesEn,
        agencyAdminUniversities: agencyAdminUniversitiesEn,
        agencyAdminPrograms: agencyAdminProgramsEn,
        programsList: programsListEn,
        filterBar: filterBarEn,
        snackbar: snackbarEN,
        cards: cardsEN,
        emptyState: emptyStateEN,
        agencyHome: agencyHomeEn,
        metrics: metricsEn,
        agencyProfile: agencyProfileEn,
        universityApplications: universityApplicationsEn,
        studentHome: studentHomeEn,
        studentPrograms: studentProgramsEn,
        studentProfile: studentProfileEn,
        additionalDocuments: additionalDocumentsEn,
        programPage: programPageEn,
        landingHome: landingHomeEn,
        landingAboutUs: landingAboutUsEn,
        uniForms: uniFormsEn,
        landingStudent: landingStudentEn,
        landingAgency: landingAgencyEn,
        landingUniversity: landingUniversityEn,
        addAgencyPage: addAgencyPageEn,
        addCandidatePage: addCandidatePageEn,
        filterCandidatesPage: filterCandidatesPageEn,
        filterApplicationsPage: filterApplicationPageEN,
        filterProgramsPage: filterProgramsPageEn,
        candidateQuickView: candidateQuickViewEn,
        adminAgencyProfile: adminAgencyProfileEn,
        studentPreferences: studentPreferencesEn,
        studentEligibility: studentEligibilityEn,
        filterAgenciesPage: filterAgenciesPageEn,
        profileRole: profileRoleEn,
        applicationRequirments: applicationRequirmentsEn,
        landingPrograms: landingProgramsEn,
        universityReplyForm: universityReplyFormEn,
        updatePreferencesPopup: updatePreferencesPopupEn,
        addAgentPage: addAgentPageEn,
        filterUniversities: filterUniversitiesEn,
        universityPage: universityPageEn,
        universityProgramPage: universityProgramPageEn,
        universityFacultiesPage: universityFacultiesPageEn,
        universityFacultyPage: universityFacultyPageEn,
        filtersDrawer: filtersDrawerEn,
        studentEnrollmentDrawer: studentEnrollmentDrawerEn,
        forms: formsEn,
        studentDocuments: studentDocumentsEn,
        oAuthButtons: oAuthButtonsEn,
        authLogin: authLoginEn,
        homepageFooter: homepageFooterEn,
        authSignup: authSignupEn,
        forgotPassword: forgotPasswordEn,
        resetPassword: resetPasswordEn,
        emailSent: emailSentEn,
        studentEducation: studentEducationEn,
        addEducationDrawer: addEducationDrawerEn,
        activityItemDrawer: addActivityItemDrawerEn,
        applicationDrawer: applicationDrawerEn,
        uniListHeader: uniListHeaderEn,
        programGeneralInfo: programGeneralInfoEn,
        editEducationDrawer: editEducationDrawerEn,
        deleteEducationDrawer: deleteEducationDrawerEn,
        applicationReplyHistoryItem: applicationReplyHistoryItemEn,
        programStatusReplise: programStatusRepliseEn,
        studentDashboard: studentDashboardEn,
        studentAppTracker: studentAppTrackerEn,
        studentApplications: studentApplicationsEn,
        stepper: stepperEn,
        replyToUniversity: replyToUniversityEn,
        authDropdown: authDropdownEn,
        studentCard: studentCardEn,
        serviceItem: serviceItemEn,
        serviceOrder: serviceOrderEn,
        promotedUniversity: promotedUniversityEn,
        createProgram: createProgramEn,
        serviceCard: serviceCardEn,
        consultancyPage: consultancyPageEn,
        herosection: herosectionEn,
        partnerServicesDrawers: partnerServicesDrawersEn,
        partnerServices: partnerServicesEn,
        adminPrograms: adminProgramsEn,
        updateCandidateStatus: updateCandidateStatusEn,
        applicationCard: applicationCardEn,
        taskCard: taskCardEn,
        prepCenter: prepCenterEn,
      },
      ro: {
        applicationsList: applicationsListRo,
        buttons: buttonsRo,
        inputs: inputsRo,
        authLogin: authLoginRo,
        superAdminHome: superAdminHomeRo,
        superAdminAgency: superAdminAgencyRo,
        agencyAdminHome: agencyAdminHomeRo,
        agencyAdminAgents: agencyAdminAgentsRo,
        agencyAdminCandidates: agencyAdminCandidatesRo,
        agencyAdminUniversities: agencyAdminUniversitiesRo,
        agencyCandidates: agencyCandidatesRo,
        uniForms: uniFormsRo,
        cards: cardsRO,
        metrics: metricsRo,
        snackbar: snackbarRO,
        emptyState: emptyStateRO,
        agencyAdminPrograms: agencyAdminProgramsRo,
        universityApplications: universityApplicationsRo,
        programsList: programsListRo,
        agencyHome: agencyHomeRo,
        filterBar: filterBarRo,
        agencyProfile: agencyProfileRo,
        studentHome: studentHomeRo,
        studentPrograms: studentProgramsRo,
        studentProfile: studentProfileRo,
        additionalDocuments: additionalDocumentsRo,
        programPage: programPageRo,
        landingHome: landingHomeRo,
        landingAboutUs: landingAboutUsRo,
        landingStudent: landingStudentRo,
        landingAgency: landingAgencyRo,
        landingUniversity: landingUniversityRo,
        addAgencyPage: addAgencyPageRo,
        addCandidatePage: addCandidatePageRo,
        filterCandidatesPage: filterCandidatesPageRo,
        filterApplicationsPage: filterApplicationPageRO,
        filterProgramsPage: filterProgramsPageRo,
        candidateQuickView: candidateQuickViewRo,
        adminAgencyProfile: adminAgencyProfileRo,
        studentPreferences: studentPreferencesRo,
        studentEligibility: studentEligibilityRo,
        filterAgenciesPage: filterAgenciesPageRo,
        profileRole: profileRoleRo,
        applicationRequirments: applicationRequirmentsRo,
        landingPrograms: landingProgramsRo,
        universityReplyForm: universityReplyFormRo,
        updatePreferencesPopup: updatePreferencesPopupRo,
        addAgentPage: addAgentPageRo,
        filterUniversities: filterUniversitiesRo,
        universityPage: universityPageRo,
        universityProgramPage: universityProgramPageRo,
        universityFacultiesPage: universityFacultiesPageRo,
        universityFacultyPage: universityFacultyPageRo,
        filtersDrawer: filtersDrawerRo,
        studentEnrollmentDrawer: studentEnrollmentDrawerRo,
        forms: formsRo,
        studentDocuments: studentDocumentsRo,
        oAuthButtons: oAuthButtonsRo,
        homepageFooter: homepageFooterRo,
        authSignup: authSignupRo,
        forgotPassword: forgotPasswordRo,
        resetPassword: resetPasswordRo,
        emailSent: emailSentRo,
        studentEducation: studentEducationRo,
        addEducationDrawer: addEducationDrawerRo,
        applicationDrawer: applicationDrawerRo,
        uniListHeader: uniListHeaderRo,
        programGeneralInfo: programGeneralInfoRo,
        editEducationDrawer: editEducationDrawerRo,
        deleteEducationDrawer: deleteEducationDrawerRo,
        applicationReplyHistoryItem: applicationReplyHistoryItemRo,
        programStatusReplise: programStatusRepliseRo,
        studentApplications: studentApplicationsRo,
        stepper: stepperRo,
        replyToUniversity: replyToUniversityRo,
        authDropdown: authDropdownRo,
        studentCard: studentCardRo,
        serviceItem: serviceItemRo,
        serviceOrder: serviceOrderRo,
        serviceCard: serviceCardRo,
        consultancyPage: consultancyPageRo,
        herosection: herosectionRo,
        partnerServicesDrawers: partnerServicesDrawersRo,
        partnerServices: partnerServicesRo,
        adminPrograms: adminProgramsRo,
        promotedUniversity: promotedUniversityRo,
        createProgram: createProgramRo,
        updateCandidateStatus: updateCandidateStatusRo,
        applicationCard: applicationCardRo,
        prepCenter: prepCenterRo,
      },
      ua: {
        applicationsList: applicationsListUa,
        // buttons: buttonsUa,
        superAdminHome: superAdminHomeUa,
        superAdminAgency: superAdminAgencyUa,
        agencyAdminHome: agencyAdminHomeUa,
        agencyAdminAgents: agencyAdminAgentsUa,
        agencyAdminCandidates: agencyAdminCandidatesUa,
        agencyCandidates: agencyCandidatesUa,
        agencyAdminUniversities: agencyAdminUniversitiesUa,
        agencyAdminPrograms: agencyAdminProgramsUa,
        programsList: programsListUa,
        filterBar: filterBarUa,
        emptyState: emptyStateUa,
        agencyHome: agencyHomeUa,
        agencyProfile: agencyProfileUa,
        universityApplications: universityApplicationsUa,
        studentHome: studentHomeUa,
        studentPrograms: studentProgramsUa,
        studentProfile: studentProfileUa,
        additionalDocuments: additionalDocumentsUa,
        programPage: programPageUa,
        landingHome: landingHomeUa,
        landingAboutUs: landingAboutUsUa,
        uniForms: uniFormsUa,
        landingStudent: landingStudentUa,
        landingAgency: landingAgencyUa,
        landingUniversity: landingUniversityUa,
        addAgencyPage: addAgencyPageUa,
        addCandidatePage: addCandidatePageUa,
        filterCandidatesPage: filterCandidatesPageUa,
        filterApplicationsPage: filterApplicationPageUa,
        filterProgramsPage: filterProgramsPageUa,
        candidateQuickView: candidateQuickViewUa,
        adminAgencyProfile: adminAgencyProfileUa,
        studentPreferences: studentPreferencesUa,
        studentEligibility: studentEligibilityUa,
        filterAgenciesPage: filterAgenciesPageUa,
        profileRole: profileRoleUa,
        applicationRequirments: applicationRequirmentsUa,
        landingPrograms: landingProgramsUa,
        universityReplyForm: universityReplyFormUa,
        updatePreferencesPopup: updatePreferencesPopupUa,
        addAgentPage: addAgentPageUa,
        filterUniversities: filterUniversitiesUa,
        universityPage: universityPageUa,
        universityProgramPage: universityProgramPageUa,
        universityFacultiesPage: universityFacultiesPageUa,
        universityFacultyPage: universityFacultyPageUa,
        filtersDrawer: filtersDrawerUa,
        studentEnrollmentDrawer: studentEnrollmentDrawerUa,
        forms: formsUa,
        studentDocuments: studentDocumentsUa,
        oAuthButtons: oAuthButtonsUa,
        authLogin: authLoginUa,
        homepageFooter: homepageFooterUa,
        authSignup: authSignupUa,
        forgotPassword: forgotPasswordUa,
        resetPassword: resetPasswordUa,
        emailSent: emailSentUa,
        studentEducation: studentEducationUa,
        addEducationDrawer: addEducationDrawerUa,
        applicationDrawer: applicationDrawerUa,
        uniListHeader: uniListHeaderUa,
        programGeneralInfo: programGeneralInfoUa,
        editEducationDrawer: editEducationDrawerUa,
        deleteEducationDrawer: deleteEducationDrawerUa,
        applicationReplyHistoryItem: applicationReplyHistoryItemUa,
        programStatusReplise: programStatusRepliseUa,
        studentApplications: studentApplicationsUa,
        replyToUniversity: replyToUniversityUa,
        authDropdown: authDropdownUa,
        createProgram: createProgramUa,
        studentCard: studentCardEn,
        serviceItem: serviceItemEn,
        serviceOrder: serviceOrderEn,
      },
    },
  });
export default i18n;
