import { useBreakpoints, useScroll } from 'hooks';
import { ReactElement, useMemo } from 'react';
import { useAuth, useHeader } from 'store';
import HeaderNavigationDesktop from './HeaderNavigationDesktop';
import HeaderNavigationMobile from './HeaderNavigationMobile';

interface Props {}

export default function HeaderNavigation({}: Props): ReactElement {
  const { isTablet } = useBreakpoints();
  const { profile } = useAuth();
  const isLoggedIn = !!profile?.id;
  const { scrollDirection, scrollY } = useScroll();
  const { header, primaryButton, secondaryButton, ...headerProps } = useHeader();

  const isSticky = useMemo(() => {
    if (scrollDirection === 'down' && scrollY > 150) return [false, false];
    else if (scrollY > 150) return [true, true];
    else return [true, false];
  }, [scrollDirection, scrollY]);

  return (
    <>
      {isTablet ? (
        <HeaderNavigationMobile isLoggedIn={isLoggedIn} profile={profile} isSticky={isSticky} />
      ) : (
        <HeaderNavigationDesktop isLoggedIn={isLoggedIn} profile={profile} isSticky={isSticky} />
      )}
    </>
  );
}
