import { UniTextArea, UniTextField } from 'components';
import UniCreatableSelect from 'components/_common/uniCreatableSelect/UniCreatableSelect';
import { activitiesOptions } from 'constants/activities';
import { Controller } from 'react-hook-form';

interface Props {
  control: any;
}

const Step1: React.FC<Props> = ({ control, ...rest }) => (
  <>
    <Controller
      control={control}
      name='title'
      rules={{
        required: 'Field must not be empty',
      }}
      render={field => <UniTextField fullWidth label='Task Title' placeholder='Give a title to your item' {...field} />}
    />
    <Controller
      control={control}
      name='category'
      // rules={{
      //   required: 'Field must not be empty',
      // }}
      render={({ value, onChange }) => (
        <UniCreatableSelect
          label='Task category'
          placeholder='Give a title to your item'
          options={activitiesOptions}
          getOptionLabel={option => option.label}
          value={activitiesOptions.find(item => item.value === value)}
          onChange={option => onChange(option?.value)}
        />
      )}
    />
    <Controller
      control={control}
      name='description'
      // rules={{
      //   required: 'Field must not be empty',
      // }}
      render={field => <UniTextArea label='Description' placeholder='Write a couple of sentences about this item' withBorder {...field} />}
    />
  </>
);

export default Step1;
