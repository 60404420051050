import { Box } from '@material-ui/core';
import { UniButton, UniIconWrapper, UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import { Colors } from '../../../constants/uiConfig';
import { Weight } from '../uniTypography/types';

interface Props {
  title: string;
  icon?: React.ReactElement;
  iconLabel?: string;
  onClick?: Function;
  weight?: Weight;
}
const UniListTitle: React.FC<Props> = ({ title, icon, onClick, weight, iconLabel }) => {
  const { isMobile } = useBreakpoints();
  const handleClick = () => {
    onClick && onClick();
  };
  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      height='80px'
      padding='16px'
      style={{ backgroundColor: Colors.Secondary._100 }}
    >
      <UniTypography variant='body1' weight={weight} color='white100'>
        {title}
      </UniTypography>
      {!!icon && (
        <UniButton
          onClick={handleClick}
          fullWidth={false}
          variant='text'
          color='white'
          endIcon={
            <UniIconWrapper color='white100' size='medium' disableHover>
              {icon}
            </UniIconWrapper>
          }
        >
          {isMobile ? '' : iconLabel}
        </UniButton>
      )}
    </Box>
  );
};

export default UniListTitle;
