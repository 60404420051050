import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UniCheckbox, UniTypography } from '..';
import { ReactComponent as DownArrow } from '../../assets/svg/Icons/Arrow/Down.svg';
import { ReactComponent as UpArrow } from '../../assets/svg/Icons/Arrow/Up.svg';
import { AgentsQueryParams } from '../agentsList/AgentsList';
import UniIconWrapper from '../_common/uniIconWrapper/UniIconWrapper';

const StyledAgentsListHeader = styled.div<{ isProgramPage?: boolean }>`
  display: grid;
  // grid-template-columns: 0.5fr repeat(6, 2fr) 1.5fr;
  grid-template-columns: ${({ isProgramPage }) => (!isProgramPage ? '0.5fr 2fr 1fr 1fr 3fr 2fr 2fr 1.5fr;' : ' 0.5fr repeat(4, 2fr)')};
  margin: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;

type HeaderProps = {
  params?: AgentsQueryParams;
  onSort?: (type: number) => void;

  selectAllApplications?: Function;
  allSelected?: boolean;
  isProgramPage?: boolean;
};

export const ApplicationListHeader: React.FC<HeaderProps> = ({
  params,
  onSort,

  selectAllApplications,
  allSelected,
  isProgramPage,
}) => {
  const { t } = useTranslation('universityApplications');
  const [sortType, setSortType] = useState(-1);
  const order = params?._order ? params?._order : 'ASC';
  // const byUsername = params?._sort === 'levelOfStudy';
  // const byNationality = params._sort === "nationality";
  // const byStudy = params._sort === "levelOfStudy";

  return (
    <StyledAgentsListHeader isProgramPage={isProgramPage}>
      <UniCheckbox
        checked={allSelected}
        onClick={(e: any) => e.stopPropagation()}
        onChange={(e: any) => {
          e.stopPropagation();
          selectAllApplications && selectAllApplications(e.target.checked);
        }}
      />
      {onSort ? (
        <Box
          display='flex'
          onClick={() => {
            setSortType(prevSortType => (prevSortType === 1 ? -1 : 1));
            onSort(sortType);
          }}
        >
          <UniTypography weight='medium' variant='subheader' color='typographyGray70'>
            {t('list.headers.name')}
          </UniTypography>
          <UniIconWrapper>{sortType === 1 ? <DownArrow /> : <UpArrow />}</UniIconWrapper>
        </Box>
      ) : (
        <UniTypography weight='medium' variant='subheader' color='typographyGray70'>
          {t('list.headers.name')}
        </UniTypography>
      )}
      <UniTypography weight='medium' variant='subheader' color='typographyGray70'>
        {t('list.headers.dateApplied')}
      </UniTypography>
      {!isProgramPage && (
        <>
          <UniTypography weight='medium' variant='subheader' color='typographyGray70'>
            {t('list.headers.study')}
          </UniTypography>
          <UniTypography weight='medium' variant='subheader' color='typographyGray70'>
            {t('list.headers.programName')}
          </UniTypography>
          <UniTypography weight='medium' variant='subheader' color='typographyGray70'>
            {t('list.headers.facultyName')}
          </UniTypography>{' '}
        </>
      )}
      <UniTypography weight='medium' variant='subheader' color='typographyGray70'>
        {t('list.headers.status')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader' color='typographyGray70'>
        {t('list.headers.actions')}
      </UniTypography>
    </StyledAgentsListHeader>
  );
};
