import { Box } from '@material-ui/core';
import styled from 'styled-components';
import UniTypography from '../../_common/uniTypography/UniTypography';

export const GapWrapper = styled(Box)<{ gap: number }>`
  display: flex;
  gap: ${({ theme, gap }) => theme.spacing(gap)}px;
`;

export const EllipsisTypography = styled(UniTypography)<{ maxWidth: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${({ theme, maxWidth }) => theme.spacing(maxWidth)}px;
`;
