import { Box } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { UniButton, UniIconWrapper, UniTextField } from '../..';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/Close.svg';
import UniDeadlineItem, { DeadlinePayload, defaultDeadlineItem } from './UniDeadlineItem';
import { StyledStartDateWrapper } from './UniStartingDate.style';

export interface Payload {
  index: number;
  startingDate: string;
  deadline: DeadlinePayload[];
}

export const defaultStartingDateItem: Payload = {
  index: 0,
  startingDate: '',
  deadline: [defaultDeadlineItem],
};

interface Props {
  showDelete?: boolean;
  onDelete?: Function;
  defaultValue: Payload;
  onChange: (value: Payload) => void;
}

export default function UniStartingDateItem({ defaultValue, onChange, showDelete, onDelete }: Props): ReactElement {
  const [ableToAdd, setAbleToAdd] = useState(false);

  const handleChange = (payload: DeadlinePayload) => {
    const index = defaultValue.deadline.findIndex(itm => itm.index === payload.index);
    if (index === -1) {
      onChange({ ...defaultValue, deadline: [...defaultValue.deadline, payload] });
    } else {
      let aux = defaultValue.deadline;
      aux[index] = payload;
      onChange({ ...defaultValue, deadline: [...aux] });
    }
  };

  const addItem = () => {
    onChange({ ...defaultValue, deadline: [...defaultValue.deadline, { ...defaultDeadlineItem, index: defaultValue.deadline.length }] });
  };

  const deleteItem = (index: number) => {
    onChange({ ...defaultValue, deadline: [...defaultValue.deadline.filter(item => item.index !== index)] });
  };

  useEffect(() => {
    if (
      !!defaultValue.deadline.length &&
      (defaultValue.deadline[defaultValue.deadline.length - 1].date || defaultValue.deadline[defaultValue.deadline.length - 1].undefined)
    ) {
      setAbleToAdd(true);
    } else {
      setAbleToAdd(false);
    }
  }, [defaultValue.deadline]);

  return (
    <>
      <StyledStartDateWrapper>
        <Box flex='1'>
          <UniTextField
            fullWidth
            defaultValue={defaultValue.startingDate}
            onChange={e => onChange({ ...defaultValue, startingDate: e.target.value })}
            name='length'
            placeholder='Label'
            label='Starting date'
            labelColor='typographyGray70'
            type='date'
            key={defaultValue.startingDate}
          />
        </Box>
        {showDelete && (
          <Box mt={2}>
            <UniIconWrapper withBackground onClick={() => onDelete && onDelete(defaultValue.index)}>
              <CloseIcon />
            </UniIconWrapper>
          </Box>
        )}
      </StyledStartDateWrapper>
      {defaultValue.deadline.length === 0 && <UniDeadlineItem defaultValue={defaultDeadlineItem} onChange={handleChange} />}
      {defaultValue.deadline.map((item, index) => (
        <UniDeadlineItem
          defaultValue={{ ...item, index }}
          showDelete={defaultValue.deadline.length > 1}
          onDelete={deleteItem}
          onChange={handleChange}
          key={index}
        />
      ))}
      {ableToAdd && (
        <Box mt={-3}>
          <UniButton fullWidth={false} variant='text' color='primaryFade' onClick={() => addItem()}>
            Add new deadline
          </UniButton>
        </Box>
      )}
    </>
  );
}
