import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { CandidatesFiltersOptions } from 'components/_drawers/filtersDrawer/_types/CandidatesFilters';
import { applicationStatusMap, candidateStatusMap } from 'constants/applicaiton';
import { countries } from 'constants/countries';
import { GraduationLevel, graduationLevelMap } from 'constants/levelOfStudy';
import { QueryConfig, useQuery } from 'react-query';
import { ApplicationStatus, CandidateStatus, OptionType } from 'types';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

const parseApplicationStatus = (filterApplicationStatus: ApplicationStatus[]): OptionType[] =>
  filterApplicationStatus
    ?.filter(item => filterApplicationStatus.includes(item))
    ?.map(item => ({ label: applicationStatusMap[item], value: item })) || [];
const parseCandidateStatus = (filterCandidateStatus: CandidateStatus[]): OptionType[] =>
  filterCandidateStatus
    ?.filter(item => filterCandidateStatus.includes(item))
    ?.map(item => ({ label: candidateStatusMap[item], value: item })) || [];
const parseGraduationLevel = (filterGradutionLevel: GraduationLevel[]): OptionType[] =>
  filterGradutionLevel.map(item => ({ label: graduationLevelMap[item], value: item }));

const parseCountries = (filterCountries: string[]): OptionType[] =>
  countries.filter(item => filterCountries.includes(item.code)).map(item => ({ label: item.name, value: item.code }));

const parseAgencies = (filterAgencies: Components.Schemas.FilterDto[]): OptionType[] =>
  filterAgencies.map(item => ({ label: item.label, value: item.value! }));

export default function useCandidateAdminFilters(config?: QueryConfig<Error>) {
  const { client } = useClient();
  return useQuery(
    CacheKeys.Filters.Candidates,
    async (_key: string) => {
      let filters: Partial<CandidatesFiltersOptions>;

      const { data } = await client!.SearchApi_getStudentFilters();
      const { data: agencies } = await client!.AgencyApi_getAgencyNames();

      filters = {
        status: parseCandidateStatus(data.status),
        graduationLevel: parseGraduationLevel(data.graduationLevel),
        nationality: parseCountries(data.nationality),
        country: parseCountries(data.residency),
        countryOfStudy: parseCountries(data.residency),
        agencies: parseAgencies(agencies),
      };

      return filters;
    },
    { refetchOnWindowFocus: false, onError(error: AxiosError) {} },
  );
}
