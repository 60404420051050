import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

export default function usePrepPacks(productId?: string) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.PrepPacks.All, productId],
    async (_key: string) => {
      const response = await client!.PrepPackApi_getPrepPacks();
      const prepPacks = response.data ?? [];
      if (productId != null) {
        return prepPacks.filter(prepPack => prepPack.product.id === productId);
      }
      return prepPacks;
    },
    {
      onError(error: AxiosError) {
        console.error(error);
      }
    },
  );
}
