import { Box } from '@material-ui/core';
import { useMagicLinkLogin } from 'api';
import { UniButton, UniIconWrapper, UniOAuth, UniTextField } from 'components';
import { AuthForm, StyledChildrenWrapperTop } from 'components/_layouts/authLayout/AuthLayout.style';
import { useBreakpoints } from 'hooks';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EmailIcon } from '../../../assets/svg/Icons/Candidates.svg';
import { BrowserRoutes } from '../../../constants';

interface Inputs {
  email: string;
}

const MagicLinkLogin = () => {
  const { isMobile } = useBreakpoints();
  const { register, handleSubmit, errors } = useForm<Inputs>();
  const [magicLogin, { isLoading }] = useMagicLinkLogin();
  const history = useHistory();

  const onSubmit = (formData: Inputs) => magicLogin(formData);

  const onBackToLogin = () => history.push(BrowserRoutes.Default.Auth.Login);
  const onLogin = handleSubmit(onSubmit);
  const onCreateAccount = () => history.push(BrowserRoutes.Default.Auth.MagicLinkSignup);

  return (
    <>
      <StyledChildrenWrapperTop>
        <UniOAuth google facebook login />
        <AuthForm onSubmit={onLogin}>
          <UniTextField
            startAdornment={
              <UniIconWrapper size='medium' color='typographyGray100'>
                <EmailIcon />
              </UniIconWrapper>
            }
            name='email'
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            label={!isMobile ? 'Email' : undefined}
            placeholder='Enter email'
            inputRef={register({ required: 'Email is required!' })}
            fullWidth
          />
        </AuthForm>
        <Box mb={3}>
          <UniButton disablePadding variant='text' fullWidth={false} onClick={onBackToLogin}>
            Use password instead
          </UniButton>
        </Box>
        {!isMobile && (
          <UniButton variant='main' size='large' fullWidth={isMobile} onClick={onLogin}>
            Login
          </UniButton>
        )}
      </StyledChildrenWrapperTop>
      <Box display='flex' flexDirection='column' alignItems='center'>
        {isMobile && (
          <UniButton variant='main' size='large' fullWidth={isMobile} onClick={onLogin} loading={isLoading}>
            Login
          </UniButton>
        )}
        <Box display='flex' alignItems='center' mt={2}>
          <Box mr={1}>Don’t have an account?</Box>
          <UniButton disablePadding variant='text' fullWidth={false} onClick={onCreateAccount}>
            Create account
          </UniButton>
        </Box>
      </Box>
    </>
  );
};

export default MagicLinkLogin;
