import { Box, Tabs } from '@material-ui/core';
import { useGetFaculty } from 'api';
import { UniButton, UniIconWrapper, UniNavigationTab } from 'components';
import FacultyProfileHeader from 'components/facultyHeader/FacultyHeader';
import { BrowserRoutes } from 'constants/browserRoutes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ReactComponent as LeftArrowIcon } from '../../../../assets/svg/Icons/Arrow/ArrowLeft.svg';
import { StyledHeader, StyledInnerHeader } from './FacultyPage.style';
import FacultyPrograms from './programs/FacultyPrograms';
type Params = {
  facultyId?: string;
};
const FacultyPage = () => {
  const { t } = useTranslation('universityFacultyPage');
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { facultyId } = useParams<{ facultyId?: string }>();
  const { data: faculty, status: facultyStatus, error: uniError } = useGetFaculty({ facultyId: facultyId });
  const handleChange = (event: any, value: string) => {
    history.push(value);
  };
  return (
    <>
      <StyledHeader>
        <StyledInnerHeader>
          <FacultyProfileHeader
            faculty={faculty}
            BackButton={
              <UniButton
                fullWidth={false}
                variant='text'
                color='neutral'
                onClick={() => history.push(BrowserRoutes.University.Faculties)}
                startIcon={
                  <UniIconWrapper color='typographyGray70'>
                    <LeftArrowIcon />
                  </UniIconWrapper>
                }
              >
                {t('buttons.back')}
              </UniButton>
            }
            changeNavbar
            isOwner={true}
          ></FacultyProfileHeader>

          <Tabs
            value={history.location.pathname}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            style={{ minHeight: 0 }}
          >
            <UniNavigationTab
              label={t('tabs.programs')}
              value={`${BrowserRoutes.University.Faculty.Programs.replace(':facultyId', facultyId || '')}`}
            />
            <UniNavigationTab
              label={t('tabs.general')}
              value={`${BrowserRoutes.University.Faculty.General.replace(':facultyId', facultyId || '')}`}
            />
          </Tabs>
        </StyledInnerHeader>
      </StyledHeader>
      <Box>
        <Switch>
          <Route path={`${BrowserRoutes.University.Faculty.Programs.replace(':facultyId', facultyId || '')}`}>
            <FacultyPrograms facultyId={facultyId || ''} />
          </Route>
          <Route
            path={`${BrowserRoutes.University.Faculty.General.replace(':facultyId', facultyId || '')}`}
            render={() => <p>General</p>}
          />
        </Switch>
      </Box>
    </>
  );
};
export default FacultyPage;
