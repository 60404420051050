import { UniCardWrapper, UniPageTitle } from 'components';
import styled from 'styled-components';

export const StyledGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.between('sm', 'md')} {
    grid-template-columns: 1fr 1fr;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`;

export const StyledSectionWrapper = styled(UniCardWrapper)`
  padding: ${({ theme }) => theme.spacing(4, 3)};
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(3, 2)};
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const StyledSectionTitle = styled(UniPageTitle)`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const ChipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;
