import { AxiosError } from 'axios';
import { queryCache, useMutation } from 'react-query';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';
import { getMetaRole } from '../../utils';

interface Payload {
  agencyId: string;
  studentId: string;
  educationHistoryItemId: string;
  documentId: string;
}

const useDeleteEducationHistoryItemDocument = () => {
  const {
    profile: { role },
  } = useAuth();
  const { isStudent } = getMetaRole(role!);
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AgencyApi_deleteEducationHistoryItemDocument(payload);
    },
    {
      onSuccess: async response => {
        queryCache.invalidateQueries(CacheKeys.Profile.Extended);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default useDeleteEducationHistoryItemDocument;
