import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../constants';

export const StyledApplicationListItem = styled.div<{ isProgramPage?: boolean }>`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  box-shadow: ${Shadows.shadow1};

  display: grid;
  // grid-template-columns: 0.5fr repeat(6, 2fr) 1.5fr;
  grid-template-columns: ${({ isProgramPage }) => (!isProgramPage ? '0.5fr 2fr 1fr 1fr 3fr 2fr 2fr 1.5fr;' : ' 0.5fr repeat(4, 2fr)')};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
  min-height: ${({ theme }) => theme.typography.pxToRem(73)};

  cursor: pointer;
`;
