import { Box } from '@material-ui/core';
import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import styled from 'styled-components';
import { getMetaRole } from 'utils';
import { UniSortButton, UniTypography } from '../../components';
import UniCheckbox from '../_inputs/uniCheckbox/UniCheckbox';
import { CandidatesQueryParams, CandidatesSort, OnCandidatesSort } from './CandidatesList';

const StyledCandidatesListHeader = styled.div<{ isMaster?: boolean }>`
  display: grid;
  grid-template-columns: 0.5fr 3fr ${({ isMaster }) => (isMaster ? 'auto repeat(4, 2fr)' : 'auto repeat(3, 2fr)')} 3fr;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;

type HeaderProps = {
  params?: CandidatesQueryParams;
  onSort?: OnCandidatesSort;

  selectAllCandidates?: Function;
  allSelected?: boolean;
  order?: OrderType;
  sortType?: CandidatesSort;
};

const CandidatesListHeader: React.FC<HeaderProps> = ({ params, onSort, selectAllCandidates, allSelected, order, sortType }) => {
  const { t } = useTranslation('agencyAdminHome');
  const { profile } = useAuth();
  const { isMaster } = getMetaRole(profile?.role);
  const ord = order ? order : 'ASC';
  const byName = sortType === 'NAME';
  const byDateAdded = sortType === 'DATE_ADDED';
  // const byNationality = params._sort === "nationality";
  // const byStudy = params._sort === "levelOfStudy";

  return (
    <StyledCandidatesListHeader isMaster={isMaster}>
      <UniCheckbox
        checked={allSelected}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          e.stopPropagation();
          selectAllCandidates && selectAllCandidates(e.target.checked);
        }}
      />
      {onSort ? (
        <UniSortButton onClick={() => onSort('NAME')} active={byName} order={ord}>
          {t('list.headers.name')}
        </UniSortButton>
      ) : (
        <UniTypography weight='medium' variant='subheader'>
          {t('list.headers.name')}
        </UniTypography>
      )}
      <UniTypography weight='medium' variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {t('list.headers.date_added')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {t('list.headers.study')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {t('list.headers.nationality')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        {t('list.headers.status')}
      </UniTypography>
      {isMaster && (
        <UniTypography weight='medium' variant='subheader'>
          {t('list.headers.agency')}
        </UniTypography>
      )}
      <Box display='flex' justifyContent='flex-end' mr={5}>
        <UniTypography weight='medium' variant='subheader'>
          {t('list.headers.actions')}
        </UniTypography>
      </Box>
    </StyledCandidatesListHeader>
  );
};

export default CandidatesListHeader;
