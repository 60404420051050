import { Box } from '@material-ui/core';
import { useExtendedProfile } from 'api';
import usePublishEducationHistoryItem from 'api/agency/usePublishEducationHistoryItem';
import { Components } from 'client/UniClient';
import { UniCard, UniPageTitle } from 'components';
import StudentEducationItem from 'components/candidateEducation/StudentEducationItem';
import ShowableItem from 'components/showableItem/ShowableItem';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { useEffect } from 'react';

interface Props {
  isPreview: boolean;
  studentId: string;
  agencyId?: string;
  setShowEducation: React.Dispatch<React.SetStateAction<boolean>>;
}

const Education: React.FC<Props> = ({ studentId, agencyId, isPreview, setShowEducation }) => {
  const { data: extendedUser } = useExtendedProfile({ id: studentId });
  const [publishEducationHistoryItem] = usePublishEducationHistoryItem();

  const educationHistory = extendedUser?.educationHistory;

  const toggleEducationHistoryItem = (item: Components.Schemas.EducationHistoryItemDto, value: boolean) => {
    if (!studentId || !agencyId) return;
    publishEducationHistoryItem({ educationHistoryItemId: item.id, agencyId, studentId, isPublished: value });
  };

  useEffect(() => {
    const showEducation = educationHistory?.some(item => item.isPublished);
    setShowEducation(!!showEducation);
  }, [educationHistory]);

  return (
    <UniCard size='L' border>
      <UniPageTitle size='M' title='Education'></UniPageTitle>
      <Box mt={2} />
      {!educationHistory && <UniListLoader />}
      {educationHistory?.map((item, index) => (
        <ShowableItem disabled={isPreview} show={item.isPublished} onChange={value => toggleEducationHistoryItem(item, value)} key={index}>
          <StudentEducationItem educationHistoryItem={item} isMobile={false} />
        </ShowableItem>
      ))}
    </UniCard>
  );
};

export default Education;
