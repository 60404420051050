import { Box, ClickAwayListener } from '@material-ui/core';
import React, { useState } from 'react';
import { StyledDropdown } from '../HeaderNavigation.style';
import { UniIconWrapper, UniTypography } from 'components';
import { ReactComponent as DownIcon } from '../../../../assets/svg/Icons/Arrow/Down.svg';

interface Props {
  children?: React.ReactNode;
  label: string;
}

export function LinkDropdown({ children, label }: Props) {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetAnchor = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const { currentTarget } = event;
    setAnchor(currentTarget);
  };

  return (
    <>
      <Box
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          handleSetAnchor(e);
          handleOpen();
        }}
      >
        <UniTypography variant='subheader' weight='medium' color='white100'>
          {label}
        </UniTypography>
        <UniIconWrapper color='primary100'>
          <DownIcon />
        </UniIconWrapper>
      </Box>
      <StyledDropdown open={open} anchorEl={anchor} placement='bottom'>
        <ClickAwayListener onClickAway={handleClose}>
          <Box display='flex' flexDirection='column'>
            {children}
          </Box>
        </ClickAwayListener>
      </StyledDropdown>
    </>
  );
}
