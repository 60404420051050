import { AxiosError } from 'axios';
import { Paths } from 'client/UniClient';
import { useMutation } from 'react-query';
import { useClient } from '../../store';

type Payload = Paths.AiApiGetCareerPath.RequestBody;

export default function useGetCareerPath() {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AiApi_getCareerPath(null, payload);
    },
    {
      onError: (error: AxiosError) => {
        console.log(error);
      },
    },
  );
}
