import { Box } from '@material-ui/core';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import UniAutocomplete from 'components/_inputs/uniAutocomplete/UniAutocomplete';
import { UniOptionsField } from 'components/_inputs/uniOptions/UniOptionsField';
import UniTextField from 'components/_inputs/uniTextField/UniTextField';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'types';
import { StyledRequiredFieldsWrapper } from '../ApplicationDrawer.style';

export interface TField {
  name: string;
  label: string;
  placeholder: string;
  type: 'yes/no' | 'multiselect' | 'singleselect' | 'long-form';
  options?: OptionType[];
  required?: string;
}
interface Props {
  formFields: TField[];
  control: Control<any>;
  register: any;
  errors: any;
}

const Step2 = ({ formFields, control, register, errors }: Props) => {
  const { t } = useTranslation('applicationDrawer');
  return (
    <>
      <Box mb={5} mt={2}>
        <UniTypography variant='body1' color='secondary100' weight='medium'>
          {t('texts.requirmentsTitle')}
        </UniTypography>
      </Box>
      <StyledRequiredFieldsWrapper>
        {formFields.map(({ type, label, name, options, placeholder, required }) => {
          switch (type) {
            case 'yes/no':
              return (
                <Controller
                  // rules={{ required: 'COMPLETEAZA_MA' }}
                  name={name}
                  control={control}
                  render={({ value, onChange }) => (
                    <UniOptionsField
                      errorMsg={errors[name]?.message}
                      value={value}
                      onChange={onChange}
                      label={label}
                      options={[
                        { label: 'Yes', value: 'yes' },
                        { label: 'No', value: 'no' },
                      ]}
                    />
                  )}
                />
              );
            case 'singleselect':
              return (
                <Controller
                  name={name}
                  control={control}
                  rules={{ required: required }}
                  render={({ value, onChange }) => (
                    <UniAutocomplete
                      placeholder={placeholder}
                      fullWidth
                      error={!!errors[name]?.message}
                      helperText={errors[name]?.message}
                      value={value}
                      handleChange={(e: any, item: any) => onChange(item.value)}
                      getOptionLabel={item => item.label}
                      label={label}
                      options={options}
                    />
                  )}
                />
              );
            case 'multiselect':
              return (
                <Controller
                  // rules={{ required: 'COMPLETEAZA_MA' }}
                  name={name}
                  control={control}
                  render={({ value, onChange }) => (
                    <UniAutocomplete
                      placeholder={placeholder}
                      fullWidth
                      error={!!errors[name]?.message}
                      helperText={errors[name]?.message}
                      value={value}
                      handleChange={onChange}
                      getOptionLabel={item => item.label}
                      label={label}
                      options={options}
                      multiple
                    />
                  )}
                />
              );
            case 'long-form':
              return (
                <UniTextField
                  placeholder={placeholder}
                  fullWidth
                  multiline
                  error={!!errors[name]?.message}
                  helperText={errors[name]?.message}
                  label={label}
                  name={name}
                  // inputRef={register({ required: 'COMPLETEAZA_ME' })}
                />
              );
          }
        })}
      </StyledRequiredFieldsWrapper>
    </>
  );
};

export default Step2;
