import { usePostEnrollment } from 'api';
import { Components } from 'client/UniClient';
import { AnimatePresence } from 'framer-motion';
import useShowModal from 'hooks/useShowModal';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UniModalDrawerButtons } from '../..';
import StudentEnrollmentDrawerStepItem from './StudentEnrollmentDrawerItem';
import StudentEnrollmentDrawerStep1 from './_steps/StudentEnrollmentDrawerStep1';
import StudentEnrollmentDrawerStep2 from './_steps/StudentEnrollmentDrawerStep2';

interface Props {
  program?: Components.Schemas.ProgramResponse;
  handleClose: Function;
  setShouldRender?: Function;
  shouldRender?: boolean;
}

export default function StudentEnrollmentDrawer({ program, handleClose, setShouldRender, shouldRender }: Props): ReactElement {
  const { t } = useTranslation('studentEnrollmentDrawer');
  const [animateDirection, setAnimateDirection] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const [enroll, { status: enrollStatus }] = usePostEnrollment();
  useShowModal({ isSuccess: currentTab === 1, shouldRender, setShouldRender });

  const handleStep = (step: number) => {
    if (step < currentTab) {
      setAnimateDirection(-1);
    } else {
      setAnimateDirection(1);
    }
    setCurrentTab(step);
  };

  const handleSubmit = () => {
    if (program?.application?.id)
      enroll(
        { applicationId: program.application?.id },
        {
          onSuccess() {
            handleStep(1);
          },
        },
      );
  };

  if (!program) {
    return <></>;
  }

  return (
    <>
      <AnimatePresence>
        <StudentEnrollmentDrawerStepItem
          currentItem={0}
          direction={animateDirection}
          active={currentTab === 0}
          Content={<StudentEnrollmentDrawerStep1 program={program} />}
        />
        <StudentEnrollmentDrawerStepItem
          currentItem={1}
          direction={animateDirection}
          active={currentTab === 1}
          Content={<StudentEnrollmentDrawerStep2 program={program} />}
        />
      </AnimatePresence>
      <UniModalDrawerButtons
        SecondaryButton={currentTab === 0 ? { onClick: handleClose, label: t('buttons.close') } : undefined}
        PrimaryButton={
          currentTab === 0
            ? { onClick: handleSubmit, label: t('buttons.confirm_enrollment') }
            : {
                onClick: () => {
                  handleStep(0);
                  handleClose();
                },
                label: t('buttons.back_to_programs'),
              }
        }
      />
    </>
  );
}
