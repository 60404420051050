import { Box } from '@material-ui/core';
import { default as React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUniversity } from '../../../api';
import { UniCardMetric } from '../../../components';
import { Colors } from '../../../constants/uiConfig';
import { useAuth } from '../../../store';
import { MetricItem, Metrics } from '../../_agent/home_old/AgencyMetrics.style';

const UniversityHomepageMetrics = React.memo(() => {
  const { t } = useTranslation('universityApplications');
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useAuth();
  const { data: metrics } = useGetUniversity({
    id: profile.facultyMemberProfile?.universityId,
  });
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    // Mock async request
    if (newValue !== value) {
      setIsLoading(() => true);
      setValue(newValue);
      setTimeout(() => {
        setIsLoading(false);
      }, 600);
    }
  };

  const getTrendingColor = (t: string) => {
    if (t === 'up') return 'secondary100';
    if (t === 'down') return 'primary100';
    return 'typographyGray100';
  };

  return (
    <Box>
      <Metrics>
        <MetricItem>
          <UniCardMetric
            percent={0}
            loading={isLoading}
            title={t('metrics.applications')}
            value={metrics?.applicationCount?.toString() || ''}
            trending={'flat'}
            background={Colors.Primary._100}
            mainTextColor='white100'
            secondaryTextColor='white100'
            trendingColor='white100'
            leftIcon='students'
          />
        </MetricItem>
        <MetricItem>
          <UniCardMetric
            percent={0}
            loading={isLoading}
            title={t('metrics.unprocessed')}
            value={metrics?.unprocessedCount?.toString() || ''}
            trending={'flat'}
            background={Colors.Secondary._100}
            mainTextColor='white100'
            secondaryTextColor='white100'
            trendingColor='white100'
            leftIcon='applications'
          />
        </MetricItem>
        <MetricItem>
          <UniCardMetric
            percent={0}
            loading={isLoading}
            title={t('metrics.accepted')}
            value={metrics?.admittedCount?.toString() || ''}
            trending={'flat'}
            background={Colors.White._100}
            mainTextColor='primary100'
            secondaryTextColor='typographyGray100'
            rightIcon='accepted'
            trendingColor={getTrendingColor('up')}
          />
        </MetricItem>
      </Metrics>
    </Box>
  );
});

export default UniversityHomepageMetrics;
