import styled from 'styled-components';
import { Colors } from '../../constants';

export const CandidateProgramWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 2)};
  border-bottom: 3px solid ${Colors.Gray1._100};
`;

export const MetaItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;
