import { UniTypography } from 'components';
import { UniDate } from 'components/_inputs/uniDate/UniDate';
import { Controller } from 'react-hook-form';
import { ActivitiesType } from 'types/activities';

interface Props {
  type?: ActivitiesType;
  control: any;
}

const Step2: React.FC<Props> = ({ type, control }) => {
  if (type === 'ACHIEVEMENT') {
    return (
      <Controller
        name='ended'
        control={control}
        render={field => <UniDate fullWidth label='When has candidate completed this achievement?' {...field} />}
      />
    );
  }

  if (type === 'TODO') {
    return (
      <Controller
        name='deadline'
        control={control}
        render={field => <UniDate fullWidth label='Select a deadline for this item' showFutureYears {...field} />}
      />
    );
  }

  return (
    <>
      <UniTypography variant='body2' color='stateAlert100'>
        Something went wrong, please go back and try again
      </UniTypography>
    </>
  );
};

export default Step2;
