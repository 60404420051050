import { Grid } from '@material-ui/core';
import useEvents from 'api/events/useEvents';
import UniEventCard from 'components/_common/uniEventCard/UniEventCard';
import { UniversalRoutes } from 'constants/browserRoutes';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'store';
import { ReactComponent as CalendarIcon } from '../../../assets/svg/Icons/Calendar.svg';
import { InfiniteWindowedGrid, UniHeader, UniHerosection, UniIconWrapper, UniPage } from '../../../components';
import { BrowserRoutes } from '../../../constants';

const LandingEvents = () => {
  const { data: events, isLoading, isFetching, canFetchMore, fetchMore } = useEvents({});
  const history = useHistory();
  const { setAfterAuthPath } = useAuth();
  const eventsFlat = events?.flat(1) || [];
  const upcomingEvents = eventsFlat.filter(event => (event?.startDate ? new Date(event?.startDate).getTime() > new Date().getTime() : 0));
  const pastEvents = eventsFlat.filter(event => (event?.startDate ? new Date(event?.startDate).getTime() < new Date().getTime() : 0));

  const onJoinEvent = (eventId: string) => {
    setAfterAuthPath(UniversalRoutes(':base').Event.Info(eventId));
    history.push(BrowserRoutes.Default.Auth.Login);
  };

  return (
    <>
      <UniHeader>
        <UniHerosection
          title='Events'
          headerVariant='baseNoAvatar'
          metrics={[
            {
              label: 'Total events',
              value: eventsFlat.length.toString(),
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <CalendarIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: 'Upcoming',
              value: upcomingEvents.length.toString(),
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <CalendarIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: 'Past',
              value: pastEvents.length.toString(),
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <CalendarIcon />
                </UniIconWrapper>
              ),
            },
          ]}
        />
      </UniHeader>
      <UniPage>
        <Grid item xs={12}>
          <InfiniteWindowedGrid
            isFetching={isFetching}
            emptyStateType='programs'
            totalResults={eventsFlat.length}
            fetchMore={() => fetchMore()}
            isLoading={isLoading}
            hasMore={canFetchMore}
            minHeight={350}
            results={eventsFlat?.map((event, index) => (
              <UniEventCard {...event} key={index} onClick={() => onJoinEvent(event?.id || '')} />
            ))}
          />
        </Grid>
      </UniPage>
    </>
  );
};

export default LandingEvents;
