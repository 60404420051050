import { UniTypography } from 'components';
import React from 'react';
import { CenteredPageLayout } from './ErrorPages.style';
export const SmallResolutions = () => {
  return (
    <CenteredPageLayout>
      <UniTypography color='typographyGray70' variant='xl' weight='semi-bold'>
        Oops!
      </UniTypography>
      <UniTypography color='typographyGray100' variant='header3'>
        The platform is not yet optimized for small resolutions
      </UniTypography>
    </CenteredPageLayout>
  );
};
