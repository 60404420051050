import styled from 'styled-components';
import { Colors } from '../../../constants';
import { ReactComponent as OnboardIllustration } from '../../../assets/svg/Illustrations/agentInviteIllustration.svg';

export const StyledOnboardIllustration = styled(OnboardIllustration)`
  width: 100%;
  height: 100%;
  max-width: 510px;
  max-height: 440px;
`;

export const StyledOnboardWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const StyledLeftSide = styled.div`
  flex: 4;
  background-color: ${Colors.White._100};
  padding: ${({ theme }) => theme.spacing(0, 3)};
  min-width: 400px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLeftSideInner = styled.div`
  flex: 1;
  height: 100%;

  padding: ${({ theme }) => theme.spacing(6, 6, 6, 14)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledRightSide = styled.div<{ step: number }>`
  flex: 6;
  background-color: ${({ step }) => (step === 0 ? Colors.Other.Purple_100 : Colors.Gray1._100)};

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
`;
export const StyledRightSideInner = styled.div`
  flex: 1;

  padding: ${({ theme }) => theme.spacing(3, 6, 6, 10)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const CustomTab = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const CustomTabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)}px;
  margin-top: ${({ theme }) => theme.spacing(15)}px;
`;

export const StyledFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(5)}px;

  & > * {
    flex: 1;
    min-width: 150px;
  }
`;
