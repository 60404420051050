import { Button, ButtonProps } from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Colors, Shape } from '../../../constants';
import { Size } from './ClearFiltersButton';
// Will get error if we try to pass a boolean to a extended Material component
type ZeroOrOne = 0 | 1;
type StyledButtonProps = {
  start: ZeroOrOne;
  end: ZeroOrOne;
  size: Size;
  loading: ZeroOrOne;
  children?: React.ReactNode;
};

const getPosition = (start: ZeroOrOne, end: ZeroOrOne, loading: ZeroOrOne) => {
  if (loading) return 'align-items: center';
  if (!!start) return 'justify-content: start';
  if (!!end) return 'justify-content: space-between';
};

export const StyledButton = styled(
  ({ start, end, size, loading, children, ...rest }: StyledButtonProps & Omit<ButtonProps, keyof StyledButtonProps>) => (
    <Button {...rest}> {children} </Button>
  ),
)`
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  padding-right: ${({ theme }) => theme.spacing(1.5)}px;
  background-color: rgba(132, 131, 131, 0.1);
  position: relative;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  justify-content: center;
  gap: 12px;
  height: ${({ size }) => (size === 'large' ? '48px' : '40px')};
  border-radius: 10%/50%;
  ${({ start, end, loading }) => getPosition(start, end, loading)}
`;

type ContentProps = {
  loading: boolean;
};
export const Content = styled(({ loading, ...rest }: ContentProps & Omit<HTMLAttributes<HTMLDivElement>, keyof ContentProps>) => (
  <div {...rest} />
))`
  opacity: ${({ loading }) => (loading ? 0 : 1)};
`;

export const Loader = styled.div`
  display: grid;
  place-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const StyledToolTip = styled(motion.div)<{ backgroundColor?: string }>`
  position: absolute;
  left: 0;
  top: -50px;

  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : Colors.Typography.Gray_30)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  border-radius: ${Shape.BorderRadius.md}px;
  white-space: nowrap;

  z-index: 999;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 10px;
    bottom: -13px;

    border: 7px solid transparent;
    border-top: 7px solid ${Colors.Typography.Gray_30};
  }
`;
