import { Box, Zoom } from '@material-ui/core';
import { UniDivider } from 'components/_common/uniDivider/UniDivider';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationItem } from 'types';
import { UniAvatar } from '../../..';
import { StyledBadge } from '../../../_common/uniAvatar/UniAvatar.style';
import { StyledMenuItem } from './AuthDropdown.style';

export const Notification = ({
  isSeen,
  title,
  body,
  isVisible,
  createdAt,
  action,
  id,
  handleClick,
}: NotificationItem & { isVisible: boolean; handleClick: (id: string) => void }) => {
  const history = useHistory();
  const username = body.split(' ');
  const label = username[0] + ' ' + username[1];
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, translateY: '-50%' }}
          animate={{ opacity: 1, translateY: '0%' }}
          exit={{ opacity: 0, translateY: '-50%' }}
        >
          <UniDivider />
          <StyledMenuItem
            TouchRippleProps={{ style: { color: 'rgba(60, 155, 149, 0.1)' } }}
            onClick={() => {
              handleClick(id);
              action && history.push(action.url);
            }}
          >
            <UniAvatar size='S' alt='username' label={label} color='secondary100' />
            <Box ml={1} display='grid' alignItems='center' width='calc(100% - 55px)'>
              <UniTypography color='typographyBlack100' weight='regular' variant='subheader'>
                {body}
              </UniTypography>
              <Box display='flex' alignItems='center'>
                <UniTypography variant='footnote' color='typographyGray70' weight='regular'>
                  {moment(createdAt).fromNow()}
                </UniTypography>
              </Box>
            </Box>
            <Zoom in={!isSeen}>
              <StyledBadge style={{ position: 'relative' }} size='XS' />
            </Zoom>
          </StyledMenuItem>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
