import styled from 'styled-components';

export const StyledTitleWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;
