import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { useParams } from 'react-router';
import usePrepPack from 'api/preppacks/usePrepPack';
import { StyledQRCodeImage } from 'components/_drawers/createEvent/CreateEvent.style';
import { useAuth } from 'store';
import { BrowserRoutes } from '../../../constants';

interface Props {}

export const StudentPrepCenterCheckIn: React.FC<Props> = () => {
  const { profile } = useAuth();
  const studentId = profile.id!;
  const { prepPackId } = useParams<{ prepPackId: string }>();
  const [code, setCode] = useState('');
  const { data: prepPack } = usePrepPack(prepPackId);
  const loading = prepPack == null || code == '';
  const checkInUrl = BrowserRoutes.Admin.PrepCenter.StudentCheckIn
    .replace(':studentId', studentId)
    .replace(':prepPackId', prepPackId);
  const fullUrl = `https://${window.location.hostname}${checkInUrl}`;

  useEffect(() => {
    QRCode.toDataURL(fullUrl).then(urlCode => {
      setCode(urlCode);
    });
  }, []);

  return loading ? (
    <div>Loading QR code...</div>
  ) : (
    <>
      <div>Check in for {prepPack?.product.name} by presenting this code to your tutor:</div>
      <StyledQRCodeImage src={code} />
    </>
  );
};

export default StudentPrepCenterCheckIn;
