import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniButton, UniDivider, UniIconWrapper, UniListHeader, UniTypography } from 'components';
import ServiceCard from 'components/serviceCard/ServiceCard';
import { useBreakpoints } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useDrawers } from 'store';
import { theme } from 'theme';
import { getMetaRole } from 'utils';
import { ReactComponent as PlusIcon } from '../../assets/svg/Icons/Plus.svg';
import { StyledServiceList } from './OurServices.style';

interface Props {
  services: Components.Schemas.ServiceOptionDto[];
}

const AllServices: React.FC<Props> = ({ services }) => {
  const { t } = useTranslation('partnerServices');
  const { profile } = useAuth();
  const { isAgency } = getMetaRole(profile?.role);
  const { isMobile } = useBreakpoints();
  const { openDrawer } = useDrawers();

  const onAddService = (service: Components.Schemas.ServiceOptionDto) => openDrawer('add-service-offering', { size: 'small', service });

  const onRequestService = () => openDrawer('request-service', { size: 'small' });

  return (
    <>
      <UniTypography
        variant='subheader'
        weight='regular'
        color='typographyGray100'
        style={{ marginBottom: theme.spacing(isMobile ? 2 : 3) }}
      >
        {t('description.all_services')}
      </UniTypography>
      {services?.length === 0 ? (
        <UniTypography color='typographyGray100'>No services found</UniTypography>
      ) : (
        <>
          {isAgency && !isMobile && (
            <Box mb={3} display='flex'>
              <UniButton
                color='primaryFade'
                style={{ minWidth: '253px' }}
                onClick={() => onRequestService()}
                endIcon={
                  <UniIconWrapper size='small' color='primary100'>
                    <PlusIcon />
                  </UniIconWrapper>
                }
              >
                {t('buttons.request_service')}
              </UniButton>

              <UniListHeader
                count={services?.length}
                options={[
                  { value: 0, label: 'By price' },
                  { value: 1, label: 'By name' },
                ]}
              />
            </Box>
          )}
          <StyledServiceList>
            {services?.map(item => (
              <ServiceCard
                id={item?.id}
                name={item?.name}
                description={item?.description}
                isOffered={item?.isOffered}
                onAdd={
                  isAgency && !item?.isOffered
                    ? () => {
                        onAddService(item);
                      }
                    : undefined
                }
                addButtonLabel={t('buttons.add_service')}
              />
            ))}
          </StyledServiceList>
          {isAgency && isMobile && (
            <Box mt={10} display='flex' flexDirection='column' alignItems='flex-end' style={{ gap: theme.spacing(2) }}>
              <UniDivider />
              <UniButton
                onClick={() => onRequestService()}
                color='primaryFade'
                endIcon={
                  <UniIconWrapper size='small' color='primary100'>
                    <PlusIcon />
                  </UniIconWrapper>
                }
              >
                {t('buttons.request_service')}
              </UniButton>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AllServices;
