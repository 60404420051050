import { Box, Fade } from '@material-ui/core';
import useDeleteAchievementFile from 'api/achievements/useDeleteAchievementFile';
import usePatchAchievement from 'api/achievements/usePatchAchievement';
import useDeleteTodoFile from 'api/todos/useDeleteTodoFile';
import usePatchTodo from 'api/todos/usePatchTodo';
import { Components } from 'client/UniClient';
import { UniButton, UniDragNDrop, UniFileViewer, UniIconWrapper, UniModalDrawerButtons, UniTextField, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { Error, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDrawers } from 'store';
import { isValidLinkRegex } from 'utils/validators';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/Close.svg';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import AddProofOfCompletionSuccess from './AddProofOfCompletionSuccess';

type Form = {
  links?: { value: string }[];
  documents?: File[];
};

export interface AddProofOfCompletionProps {
  todo?: Components.Schemas.ToDoDto;
  achievement?: Components.Schemas.AchievementDto;
}

const AddProofOfCompletion: React.FC<AddProofOfCompletionProps> = ({ achievement, todo }) => {
  const studentId = achievement?.student.id || todo?.student.id;
  const activityId = achievement?.id || todo?.id;
  const [defaultDocuments, setDefaultDocuments] = useState(achievement?.documents || todo?.documents);
  const { closeDrawer, setProgress } = useDrawers();
  const [deleteAchievementFile, { isLoading: deleteAchievementFileIsLoading }] = useDeleteAchievementFile();
  const [deleteTodoFile, { isLoading: deleteTodoFileIsLoading }] = useDeleteTodoFile();
  const [patchAchievement, { isLoading: patchAchievementIsLoading }] = usePatchAchievement();
  const [patchTodo, { isLoading: patchTodoIsLoading }] = usePatchTodo();
  const isLoading = patchTodoIsLoading || patchAchievementIsLoading;
  const [error, setError] = useState('');
  const [step, setStep] = useState(0);
  const { control, register, handleSubmit, errors, watch, reset } = useForm<Form>({
    defaultValues: { links: achievement?.links.map(item => ({ value: item })) || [{ value: '' }] },
  });
  const type = !!achievement ? 'ACHIEVEMENT' : 'TODO';
  const { fields, append, remove } = useFieldArray({
    name: 'links',
    control,
  });
  const { documents, links } = watch();

  const onNewLink = () => append({ name: '', description: '' });

  const onDeleteFile = (id: string) => {
    if (achievement?.id) {
      deleteAchievementFile(
        { achievementId: achievement.id, fileId: id },
        {
          onSuccess() {
            setDefaultDocuments(prev => prev?.filter(item => item.id !== id));
          },
        },
      );
    }
    if (todo?.id) {
      deleteTodoFile(
        { toDoId: todo.id, fileId: id },
        {
          onSuccess() {
            setDefaultDocuments(prev => prev?.filter(item => item.id !== id));
          },
        },
      );
    }
  };

  const onSubmit = (formData: Form) => {
    if (!documents?.length && !links?.length) {
      setError('You need to add at least one document or one link.');
      return;
    }
    if (!studentId || !activityId) return;

    if (type === 'TODO') {
      patchTodo(
        {
          ...formData,
          links: formData?.links?.map(item => item.value),
          toDoId: activityId,
          deadline: todo?.deadline || '',
        },
        {
          onSuccess() {
            setStep(1);
          },
        },
      );
    }
    if (type === 'ACHIEVEMENT') {
      patchAchievement(
        {
          ...formData,
          links: formData?.links?.map(item => item.value),
          achievementId: activityId,
        },
        {
          onSuccess() {
            setStep(1);
          },
        },
      );
    }
  };

  useEffect(() => {
    setProgress(step * 40 + 60);
  }, [step]);

  if (step === 1) {
    return (
      <>
        <UniModalDrawerContent>
          <AddProofOfCompletionSuccess />
        </UniModalDrawerContent>
        <UniModalDrawerButtons
          PrimaryButton={{
            onClick: closeDrawer,
            label: 'Back to Activities',
          }}
        />
      </>
    );
  }

  return (
    <>
      <UniModalDrawerContent>
        {fields.map((field, index) => {
          return (
            <>
              <UniTextField
                fullWidth
                label='Add Link'
                placeholder='Paste a link'
                key={field.id}
                helperText={errors.links?.[index]?.value?.message}
                error={!!errors.links?.[index]?.value?.message}
                defaultValue={field.value}
                name={`links[${index}].value`}
                inputRef={register({
                  pattern: {
                    value: isValidLinkRegex,
                    message: 'Link must be valid.',
                  },
                })}
                endAdornment={
                  fields.length > 1 && (
                    <UniIconWrapper onClick={() => remove(index)}>
                      <CloseIcon />
                    </UniIconWrapper>
                  )
                }
              />
            </>
          );
        })}
        <UniButton size='medium' variant='text' color='primary' onClick={onNewLink}>
          Add another link
        </UniButton>
        <Box mt={3} />
        <Controller
          control={control}
          name='documents'
          render={({ value, onChange }) => (
            <UniDragNDrop
              label='Add Documents'
              fullWidth
              multiple
              accept='application/pdf, image/jpg, image/png'
              value={value}
              onDelete={newFiles => onChange(newFiles)}
              handleChange={(files: File[]) => {
                if (files.length > 0) {
                  onChange(files);
                }
              }}
            >
              <UniTypography color='typographyGray100'>Drag & Drop or</UniTypography>
              <UniTypography color='primary100'>Upload JPG, PDF or PNG file</UniTypography>
            </UniDragNDrop>
          )}
        />
        {!!defaultDocuments?.length && <Box mt={2} />}
        {defaultDocuments?.map((document, index) => (
          <UniFileViewer label={document.originalName} file={document} key={index} onDelete={() => onDeleteFile(document.id)} />
        ))}
      </UniModalDrawerContent>
      <Box p={3}>
        <Fade in={!!error} unmountOnExit>
          <Error>
            <StyledAlertIcon>
              <ErrorSvg />
            </StyledAlertIcon>
            <UniTypography variant='footnote' component='span' color='stateAlert100'>
              {error}
            </UniTypography>
          </Error>
        </Fade>
      </Box>
      <UniModalDrawerButtons
        SecondaryButton={{
          onClick: closeDrawer,
          label: 'Cancel',
        }}
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: 'Save',
          loading: isLoading,
        }}
      />
    </>
  );
};

export default AddProofOfCompletion;
