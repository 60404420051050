import { Box } from '@material-ui/core';
import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UniSortButton, UniTypography } from '../../components';
import UniCheckbox from '../_inputs/uniCheckbox/UniCheckbox';
import { AgenciesQueryParams, AgenciesSort, OnAgenciesSort } from './AgenciesList';
const StyledAgenciesListHeader = styled.div`
  display: grid;
  grid-template-columns: 0.5fr repeat(8, 2fr) 6fr;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;

type HeaderProps = {
  params?: AgenciesQueryParams;
  onSort?: OnAgenciesSort;
  selectAllAgencies: Function;
  allSelected?: boolean;
  sortType?: AgenciesSort;
  order?: OrderType;
};

const AgenciesListHeader: React.FC<HeaderProps> = ({ onSort, selectAllAgencies, allSelected, params, order, sortType }) => {
  const { t } = useTranslation('superAdminHome');
  // const order = params?.order ? params.order : 'ASC';
  // const byName = params?.sort === 'NAME';
  // const byCandidates = params?.sort === 'CANDIDATES_COUNT';
  // const byAdmittedStudents = params?.sort === 'ADMITTED_COUNT';
  // const byEnrolledStudents = params?.sort === 'ENROLLED_COUNT';
  const ord = order ? order : 'ASC';
  const byName = sortType === 'NAME';
  const byCandidates = sortType === 'CANDIDATES_COUNT';
  const byAdmittedStudents = sortType === 'ADMITTED_COUNT';
  const byEnrolledStudents = sortType === 'ENROLLED_COUNT';
  const byDateAdded = sortType === 'DATE_ADDED';

  return (
    <StyledAgenciesListHeader>
      <UniCheckbox
        checked={allSelected}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          e.stopPropagation();
          selectAllAgencies(e.target.checked);
        }}
      />
      {onSort ? (
        <UniSortButton
          onClick={() => {
            onSort('NAME');
          }}
          active={byName}
          order={ord}
        >
          {t('list_header.name')}
        </UniSortButton>
      ) : (
        <UniTypography weight='medium' variant='subheader'>
          {t('list_header.name')}
        </UniTypography>
      )}
      {onSort ? (
        <UniSortButton
          onClick={() => {
            onSort('DATE_ADDED');
          }}
          active={byDateAdded}
          order={ord}
        >
          {t('list_header.date_added')}
        </UniSortButton>
      ) : (
        <UniTypography weight='medium' variant='subheader'>
          {t('list_header.date_added')}
        </UniTypography>
      )}
      <UniTypography weight='medium' variant='subheader'>
        {t('list_header.status')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        {t('list_header.agents')}
      </UniTypography>
      {onSort ? (
        <UniSortButton
          onClick={() => {
            onSort('CANDIDATES_COUNT');
          }}
          active={byCandidates}
          order={ord}
        >
          {t('list_header.students')}
        </UniSortButton>
      ) : (
        <UniTypography weight='medium' variant='subheader'>
          {t('list_header.students')}
        </UniTypography>
      )}
      <UniTypography weight='medium' variant='subheader'>
        {t('list_header.applications')}
      </UniTypography>

      {onSort ? (
        <>
          <UniSortButton
            onClick={() => {
              onSort('ADMITTED_COUNT');
            }}
            active={byAdmittedStudents}
            order={ord}
          >
            {t('list_header.admitted')}
          </UniSortButton>
          <UniSortButton
            onClick={() => {
              onSort('ENROLLED_COUNT');
            }}
            active={byEnrolledStudents}
            order={ord}
          >
            {t('list_header.enrolled')}
          </UniSortButton>
        </>
      ) : (
        <>
          <UniTypography weight='medium' variant='subheader'>
            {t('list_header.admitted')}
          </UniTypography>
          <UniTypography weight='medium' variant='subheader'>
            {t('list_header.enrolled')}
          </UniTypography>
        </>
      )}

      <Box display='flex' justifyContent='flex-end' mr={5}>
        <UniTypography weight='medium' variant='subheader'>
          {t('list_header.actions')}
        </UniTypography>
      </Box>
    </StyledAgenciesListHeader>
  );
};

export default AgenciesListHeader;
