import { Box } from '@material-ui/core';
import { useFile } from 'api';
import { ReactComponent as EditIcon } from 'assets/svg/Icons/Edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/Icons/Garbagev2.svg';
import { Components } from 'client/UniClient';
import { UniAvatar, UniDivider, UniIconWrapper, UniTypography } from 'components';
import moment from 'moment';
import React from 'react';

interface Props {
  id: string;
  author?: Components.Schemas.UserDto;
  createdAt: string;
  content: string;
  onEdit: Function;
  onDelete: Function;
}

const Note: React.FC<Props> = ({ id, author, createdAt, content, onEdit, onDelete }) => {
  const editHandler = () => {
    onEdit({ id, content });
  };
  const deleteHandler = () => {
    onDelete(id);
  };
  const profileImageSrc = useFile({ id: author?.profileImageFile?.id || '' }, { enabled: !!author?.profileImageFile });

  return (
    <Box width='100%'>
      <Box display='flex' flexDirection='column' gridRowGap='12px'>
        <Box display='flex' gridColumnGap='12px'>
          <UniAvatar size='S' src={profileImageSrc.data?.url} label={author?.firstName + ' ' + author?.lastName} alt='Agent avatar' />
          <Box display='flex' flexDirection='column' width='100%'>
            <Box display='flex' width='100%' justifyContent='space-between' maxHeight='24px'>
              <UniTypography color='typographyBlack100' variant='body2' weight='medium'>
                {author?.firstName + ' ' + author?.lastName}
              </UniTypography>
              <Box display='flex' gridColumnGap='8px'>
                <UniIconWrapper onClick={editHandler} size='main' marginClearFilter disableHover color='typographyGray100'>
                  <EditIcon />
                </UniIconWrapper>
                <UniIconWrapper onClick={deleteHandler} size='main' marginClearFilter disableHover color='typographyGray100'>
                  <DeleteIcon />
                </UniIconWrapper>
              </Box>
            </Box>
            <UniTypography color='typographyGray100' variant='footnote'>
              {moment(createdAt).format('D MMMM YYYY, k:mm')}
            </UniTypography>
          </Box>
        </Box>
        <UniTypography color='typographyBlack100' variant='subheader'>
          {content}
        </UniTypography>
      </Box>
      <Box paddingTop='11px' paddingBottom='12px' marginTop='8px' width='100%'>
        <UniDivider />
      </Box>
    </Box>
  );
};

export default Note;
