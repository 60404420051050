import { Box, Fade, InputProps } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { Autocomplete } from '@material-ui/lab';
import { countries, CountriesType } from 'constants/countries';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownArrowIcon } from '../../../assets/svg/Icons/Arrow/Down.svg';
import { ReactComponent as CloseX } from '../../../assets/svg/Icons/CloseX.svg';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import { Colors } from '../../../constants';
import ComponentColors, { TComponentColors } from '../../../constants/componentColors';
import UniIconWrapper from '../../_common/uniIconWrapper/UniIconWrapper';
import UniTypography from '../../_common/uniTypography/UniTypography';
import { Container, Error, Label, StyledAlertIcon } from '../uniTextField/UniTextField.style';
import { StyledInput, StyledTextPhoneField } from './styledPhoneTextfield.style';

interface UniPhoneInputBaseProps extends Omit<InputProps, 'color'> {
  optional?: boolean;
  label?: string;
  control: any;
  fixed?: 'small' | 'medium' | 'row';
  helperText?: string;
  labelColor?: TComponentColors;
  borderColor?: 'primary' | 'alert';
  backgroundColor?: any;
  variant?: 'small' | 'footnote' | 'subheader' | 'body2' | 'body1' | 'header3' | 'header2' | 'header1' | 'xl' | undefined;
  type?: string;
  phonePrefix?: string;
  setValue: Function;
  topRightArea?: React.ReactNode;
  adornmentColor?: TComponentColors;
  setIsPrefix?: Function;
  getValues?: Function;
  fullWidth?: boolean;
  error?: boolean;
}

const PopperMy = (props: any) => {
  return <Popper {...props} style={{ width: '280px', marginLeft: '-17px', marginBottom: '3px' }} placement='bottom-start' />;
};

const UniPhoneInput: React.FC<UniPhoneInputBaseProps> = ({
  label,
  helperText,
  endAdornment,
  borderColor,
  startAdornment,
  name,
  fixed = 'small',
  optional,
  control,
  setValue,
  labelColor = 'typographyBlack100',
  phonePrefix,
  backgroundColor,
  variant,
  topRightArea,
  type = 'text',
  adornmentColor,
  setIsPrefix,
  getValues,
  fullWidth,
  error,

  ...props
}) => {
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [showError, setShowError] = useState(true);
  const [prefix, setPrefix] = useState(phonePrefix ? phonePrefix : '');
  const { t } = useTranslation('forms');

  const filterOptions = (options: CountriesType[], { inputValue }: { inputValue: string }) =>
    matchSorter(options, inputValue, {
      keys: ['name', 'flag', 'code', 'dial_code'],
    });

  useEffect(() => {
    if (!!helperText) {
      setErrorMsg(() => helperText);
    }
  }, [helperText]);

  return (
    <Container backgroundColor={backgroundColor} hasLabel={!!label}>
      {label && (
        <Label>
          <UniTypography variant='subheader' color={labelColor}>
            {label}
          </UniTypography>
          {optional && (
            <UniTypography variant='footnote' color='typographyGray70'>
              Optional
            </UniTypography>
          )}
          {topRightArea && (
            <Box display='flex' alignItems='center'>
              {topRightArea}
            </Box>
          )}
        </Label>
      )}
      <StyledInput
        {...props}
        name={name}
        style={{
          border: `1px solid ${prefix ? Colors.Typography.Gray_50 : error ? Colors.State.Alert_100 : Colors.Typography.Gray_50}`,
          width: `${fullWidth ? '100%' : '280px'}`,
        }}
        type='number'
        endAdornment={
          <UniIconWrapper color='typographyGray70' size='small'>
            <CloseX
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setValue && setValue('phone', '');
                setValue && setValue(`${name}Prefix`, '');
                setIsPrefix && setIsPrefix(false);
                setPrefix('');
                setErrorMsg('');
                setShowError(false);
              }}
            />
          </UniIconWrapper>
        }
        startAdornment={
          <Controller
            fullWidth
            control={control}
            name={`${name}Prefix`}
            rules={{ required: `${t('form.errors.phone_2')}` }}
            render={() => (
              <Autocomplete
                style={{
                  display: 'grid',
                  justifyItems: 'start',
                  width: '125px',
                }}
                onChange={(event: any, value: CountriesType) => {
                  setPrefix(value?.dial_code || '');
                  setValue(`${name}Prefix`, value?.dial_code);
                  setIsPrefix && setIsPrefix(true);
                }}
                value={countries.find(item => item.dial_code === prefix)}
                disableClearable
                options={countries}
                PopperComponent={PopperMy}
                key={`${name}Prefix-${getValues && getValues(`${name}Prefix`)}`}
                getOptionLabel={option => option.dial_code}
                filterOptions={filterOptions}
                popupIcon={
                  <UniIconWrapper color='typographyGray50' size='medium'>
                    <DownArrowIcon />
                  </UniIconWrapper>
                }
                renderOption={(option: CountriesType) => (
                  <>
                    <UniIconWrapper>
                      <ReactCountryFlag
                        countryCode={option.code}
                        svg
                        style={{
                          width: '22px',
                          height: '22px',
                          marginLeft: '16px',
                          marginRight: '40px',
                        }}
                      />
                    </UniIconWrapper>
                    <UniTypography color='typographyGray100' variant='subheader' weight='regular'>
                      {option.name} ({option.dial_code})
                    </UniTypography>
                  </>
                )}
                renderInput={params => (
                  <StyledTextPhoneField
                    {...params}
                    style={{ width: 'inherit' }}
                    placeholder={'Prefix'}
                    variant='outlined'
                    backgroundColor={backgroundColor || ComponentColors['white100']}
                    InputProps={{
                      ...params.InputProps,

                      startAdornment: prefix && (
                        <UniIconWrapper>
                          <ReactCountryFlag
                            countryCode={countries
                              .filter(item => item.dial_code.includes(prefix))
                              .map(item => item.code as String)
                              .toString()}
                            svg
                          />
                        </UniIconWrapper>
                      ),
                    }}
                  />
                )}
              />
            )}
          />
        }
      />

      {showError && (
        <Fade in={!!helperText && !prefix} unmountOnExit>
          <Error>
            <StyledAlertIcon>
              <ErrorSvg />
            </StyledAlertIcon>
            <UniTypography variant='footnote' component='span' color='stateAlert100'>
              {errorMsg}
            </UniTypography>
          </Error>
        </Fade>
      )}
    </Container>
  );
};

export default UniPhoneInput;
