import { ReactComponent as Location } from 'assets/svg/Icons/Location.svg';

export const tutors = [
  {
    name: 'Ágota Ferenț',
    title: 'English Teacher & Educational Consultant',
    image: './Agota Ferent.jpg',
    achievements: [
      {
        Icon: Location,
        title: '300+',
        subtitle: 'Students with exceptional scores obtained in various English language examinations and certifications',
      },
      {
        Icon: Location,
        title: '5+',
        subtitle: 'Years of experience as a certified teacher and student counselor',
      },
    ],
  },
  {
    name: 'Denisa Stan',
    title: 'Educational Consultant',
    image: './Denisa Stan.jpg',
    achievements: [
      {
        Icon: Location,
        title: '2',
        subtitle: 'Tech internships at Microsoft',
      },
    ],
  },
  {
    name: 'Carolina Martinez Coss',
    title: 'English Teacher & Cambridge Examiner',
    image: './Carolina Martinez.jpg',
    achievements: [
      {
        Icon: Location,
        title: '1000+',
        subtitle: 'Students prepared for official English Examination and ESOL Certification',
      },
      {
        Icon: Location,
        title: '5+',
        subtitle: 'Years of experience',
      },
    ],
  },
  {
    name: 'Ana Crăciun',
    title: 'SAT English Tutor & Educational Consultant',
    image: './Ana Craciun.jpg',
    achievements: [
      {
        Icon: Location,
        title: '100+',
        subtitle: 'Students admitted to top universities (Harvard, Cambridge, Caltech, Bocconi)',
      },
      {
        Icon: Location,
        title: '100+',
        subtitle: 'Students who obtained scores > 750 in the English section',
      },
      {
        Icon: Location,
        title: '4+',
        subtitle: 'Years of consulting and SAT tutoring experience',
      },
    ],
  },
  {
    name: 'Raluca Pascale',
    title: 'Educational Consultant',
    image: './Raluca Pascale.jpg',
    achievements: [
      {
        Icon: Location,
        title: '30+',
        subtitle: 'Students from Kazakhstan and Uzbekistan admitted with scholarships to European and Asian universities',
      },
      {
        Icon: Location,
        title: '2+',
        subtitle: 'Years of educational experience, specialized in undergraduate and postgraduate applications and fully funded scholarships in Asia and Europe\n',
      },
    ],
  },
  {
    name: 'Gheorghe Petrus',
    title: 'Passion Project Coordinator',
    image: './Gheorghe Petrus.png',
    achievements: [
      {
        Icon: Location,
        title: '1000+',
        subtitle: 'Young people involved',
      },
      {
        Icon: Location,
        title: '15+',
        subtitle: 'Years of experience',
      },
      {
        Icon: Location,
        title: '100+',
        subtitle: 'Projects coordinated',
      },
    ],
  },
  {
    name: 'Miruna Mihalcea',
    title: 'Educational Consultant',
    image: './Miruna Mihalcea.jpg',
    achievements: [],
  },
  {
    name: 'Theodor Cristea',
    title: 'Math Tutor',
    image: './Theodor Cristea.jpg',
    achievements: [
      {
        Icon: Location,
        title: '100+',
        subtitle: 'Students tutored for admission exams (SAT, OMPTC, Baccalaureat)',
      },
      {
        Icon: Location,
        title: '50+',
        subtitle: 'Students tutored for national olympiads and contests',
      },
      {
        Icon: Location,
        title: '4+',
        subtitle: 'Years of experience as a tutor',
      },
    ],
  },
  {
    name: 'Andrei Nicolae',
    title: 'Math Tutor',
    image: './Andrei Nicolae.jpg',
    achievements: [
      {
        Icon: Location,
        title: '600+',
        subtitle: 'Students with a maximum score at the math SATs',
      },
      {
        Icon: Location,
        title: 'Top 5',
        subtitle: 'SAT tutors in the whole world in 2020 based on results.',
      },
    ],
  },
  {
    name: 'Elena Nicolae',
    title: 'Math Tutor',
    image: './Elena Nicolae.jpg',
    achievements: [
      {
        Icon: Location,
        title: '1000+',
        subtitle: 'Students prepared for national and international math competitions',
      },
      {
        Icon: Location,
        title: '30+',
        subtitle: 'Years of experience as a tutor',
      },
    ],
  },
  {
    name: 'Maria Nicolae',
    title: 'Math Tutor',
    image: './Maria Nicolae.jpg',
    achievements: [
      {
        Icon: Location,
        title: '100+',
        subtitle: 'Students prepared for admission to universities worldwide',
      },
    ],
  },
];

export const landingPageTutors = ['Ana Crăciun', 'Raluca Pascale', 'Theodor Cristea', 'Andrei Nicolae', 'Elena Nicolae', 'Maria Nicolae'];

export const mathTutors = ['Andrei Nicolae', 'Theodor Cristea'];

export const scienceTutors = ['Andrei Nicolae', 'Theodor Cristea'];

export const languagesTutors = ['Andrei Nicolae'];
