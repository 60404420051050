import { UniTypography } from 'components';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/Close.svg';
import { StyledCloseIcon, StyledTagItem } from './uniChip.style';

interface ChipProps {
  label: any;
  onDelete?: Function;
  arrow?: boolean;
  color?: 'grey' | 'green' | 'red' | 'primary';
}

const UniChip: React.FC<ChipProps> = ({ label, onDelete, arrow, color = 'grey' }) => {
  return (
    <StyledTagItem color={color}>
      <UniTypography
        variant='subheader'
        weight='regular'
        color={
          color === 'green' ? 'secondary100' : color === 'red' ? 'stateAlert100' : color === 'primary' ? 'primary100' : 'typographyGray70'
        }
        noSpaceWrap
      >
        {label}
        {arrow && <> &rarr;</>}
      </UniTypography>

      {!!onDelete && (
        <StyledCloseIcon
          color={color}
          onClick={e => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <CloseIcon />
        </StyledCloseIcon>
      )}
    </StyledTagItem>
  );
};

export default UniChip;
