import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { EmptyState, UniButton, UniDivider, UniIconWrapper, UniListHeader, UniTypography } from 'components';
import ServiceCard from 'components/serviceCard/ServiceCard';
import { useBreakpoints } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth, useDrawers } from 'store';
import { theme } from 'theme';
import { getMetaRole } from 'utils';
import { ReactComponent as PlusIcon } from '../../assets/svg/Icons/Plus.svg';
import { BrowserRoutes } from '../../constants/browserRoutes';
import { StyledServiceList } from './OurServices.style';

interface Props {
  serviceOfferings: Components.Schemas.ServiceOfferingDto[];
}

const OurServices: React.FC<Props> = ({ serviceOfferings }) => {
  const { t } = useTranslation('partnerServices');
  const { profile } = useAuth();
  const { isAgency } = getMetaRole(profile?.role);
  const history = useHistory();
  const { isMobile } = useBreakpoints();
  const { openDrawer } = useDrawers();

  const onEditService = (serviceOffering: Components.Schemas.ServiceOfferingDto) =>
    openDrawer('edit-service-offering', { size: 'small', serviceOffering });

  const onRequestService = () => openDrawer('request-service', { size: 'small' });

  const onDeleteService = (serviceOffering: Components.Schemas.ServiceOfferingDto) =>
    openDrawer('delete-service-offering', { size: 'small', serviceOffering });

  return (
    <>
      <UniTypography
        variant='subheader'
        weight='regular'
        color='typographyGray100'
        style={{ marginBottom: theme.spacing(isMobile ? 2 : 3) }}
      >
        {`${profile?.firstName + ' ' + profile?.lastName}${t('description.our_services')} `}
      </UniTypography>
      {serviceOfferings?.length === 0 ? (
        <EmptyState
          differentMessage={
            <Box display='flex' alignItems='center' flexDirection={isMobile ? 'column' : 'row'}>
              <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
                {t('empty_state')}
              </UniTypography>
              <UniButton size='small' variant='text' onClick={() => history.push(BrowserRoutes.Agent.Services.AllServices)}>
                {t('buttons.all_services')}
              </UniButton>
            </Box>
          }
        ></EmptyState>
      ) : (
        <>
          {isAgency && !isMobile && (
            <Box mb={3} display='flex'>
              <UniButton
                color='primaryFade'
                style={{ minWidth: '253px' }}
                onClick={() => onRequestService()}
                endIcon={
                  <UniIconWrapper size='small' color='primary100'>
                    <PlusIcon />
                  </UniIconWrapper>
                }
              >
                {t('buttons.request_service')}
              </UniButton>

              <UniListHeader
                count={serviceOfferings?.length}
                options={[
                  { value: 0, label: 'By price' },
                  { value: 1, label: 'By name' },
                ]}
              />
            </Box>
          )}

          <StyledServiceList>
            {serviceOfferings?.map(item => (
              <ServiceCard
                name={item?.serviceOption?.name}
                id={item?.id}
                description={item?.serviceOption?.description}
                price={item?.price}
                onDelete={
                  isAgency
                    ? () => {
                        onDeleteService(item);
                      }
                    : undefined
                }
                onEdit={isAgency ? () => onEditService(item) : undefined}
              />
            ))}
          </StyledServiceList>
          {isAgency && isMobile && (
            <Box mt={10} display='flex' flexDirection='column' alignItems='flex-end' style={{ gap: theme.spacing(2) }}>
              <UniDivider />
              <UniButton
                onClick={() => onRequestService()}
                color='primaryFade'
                endIcon={
                  <UniIconWrapper size='small' color='primary100'>
                    <PlusIcon />
                  </UniIconWrapper>
                }
              >
                {t('buttons.request_service')}
              </UniButton>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default OurServices;
