import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniPageTitle } from 'components';
import UniPartnerCard from 'components/partnerCard/UniPartnerCard';
import { useTranslation } from 'react-i18next';

interface Props {
  consultant?: Components.Schemas.UserDto;
}

const Consultant: React.FC<Props> = ({ children, consultant, ...rest }) => {
  const { t } = useTranslation('consultancyPage');
  return (
    <>
      <Box mb={3}>
        <UniPageTitle title={t('consultant.title')} />
      </Box>
      <UniPartnerCard
        name={`${consultant?.firstName} ${consultant?.lastName}`}
        src={consultant?.profileImageFile?.location}
        bio={consultant?.role}
        status={'Youni Partner'}
        phone={`${consultant?.phonePrefix} ${consultant?.phone}`}
        email={consultant?.email}
      />
    </>
  );
};

export default Consultant;
