import { TableRow } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../constants';

export const AgentHeader = styled.div<{
  changeNavbar?: boolean;
}>`
  position: 'relative';
  background-color: ${Colors.White._100};
  border-radius: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
  padding: 0px 16px 24px 16px;
`;

export const MetaData = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(10)}px;
`;

export const MetaItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledCollapsibleRow = styled(TableRow)`
  margin-bottom: 10px;
`;

export const AgentMeta = styled.div<{ onScrollCollapse: boolean }>`
  display: flex;
  justify-content: space-between;
  transition: 0.2s;
  overflow-y: hidden;
  max-height: ${({ onScrollCollapse }) => (onScrollCollapse ? 0 : 100)}px;
  margin: ${({ theme }) => theme.spacing(5, 0, 0, 0)};
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const AgentIdentity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const AgentActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const AgentStatus = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;
