import styled from 'styled-components';
import { Colors, Shape } from '../../../constants';

export const StyledDropArea = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 4)};
  border-radius: ${Shape.BorderRadius.md}px;
  background-color: ${Colors.Gray2._40};
  border: 1px dashed ${Colors.Typography.Gray_50};
  text-align: center;
  max-width: 100%;
  cursor: pointer;
`;

export const Alert = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;
