import { useMutation } from 'react-query';
import { Components } from 'client/UniClient';
import { useClient } from 'store';

type Payload = Components.Schemas.CreatePurchasedPrepPackRequest;

export default function usePostPurchasedPrepPack() {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      const { data } = await client!.PurchasedPrepPackApi_addPurchasedPrepPack(null, payload);
      return data;
    },
  );
}
