import { Box } from '@material-ui/core';
import { useApplication, useFile } from 'api';
import { ReactComponent as HourglassIcon } from 'assets/svg/Icons/Hourglass.svg';
import { ReactComponent as LocationIcon } from 'assets/svg/Icons/Location.svg';
import StudentDocuments from 'components/candidateDocuments/StudentDocuments';
import StudentEducation from 'components/candidateEducation/StudentEducation';
import StudentPersonalInfo from 'components/candidateProfileInformation/_profileTabs/StudentPersonalInfo';
import ProgramGeneralInfo from 'components/_templates/programGeneralInfo/ProgramGeneralInfo';
import ProgramStatusReplies from 'components/_templates/programSatusReplies/ProgramStatusReplies';
import { levelOfStudyMap } from 'constants/levelOfStudy';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { AnimatedTabPanel, EmptyState, UniHeader, UniHerosection, UniIconWrapper, UniPage, UniPageLoader } from '../../../components';
import { BrowserRoutes } from '../../../constants';
import ApplicationPageActions from './ApplicationPageActions';

interface URLParams {
  id: string;
}

const ApplicationPage = () => {
  const { t } = useTranslation('applicationPage');
  const { id: applicationId } = useParams<URLParams>();
  const { url } = useRouteMatch();

  const { data: application, isLoading: isLoadingApplication } = useApplication({ id: applicationId });
  const { data } = useFile({
    id: application?.student?.studentProfile?.passportDocument?.id ? application?.student?.studentProfile?.passportDocument?.id : '',
  });

  const generalPath = BrowserRoutes.University.Application.General(applicationId);
  const statusPath = BrowserRoutes.University.Application.Status(applicationId);
  const documentsPath = BrowserRoutes.University.Application.Documents(applicationId);
  const studentPath = BrowserRoutes.University.Application.Student(applicationId);

  const currentRoute = useMemo(() => {
    if (url === statusPath) return 0;
    if (url === documentsPath) return 1;
    if (url === studentPath) return 2;
    if (url === generalPath) return 3;
    return 0;
  }, [url]);

  if (!application || !application.program || isLoadingApplication) {
    return <UniPageLoader />;
  }

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='base'
          candidate={application?.student}
          title={application?.program.name || ''}
          universityName={application?.program?.universityName}
          universityId={application?.program?.universityId}
          profileImageSrc={application?.student?.profileImageFile?.location}
          Actions={<ApplicationPageActions application={application} />}
          metrics={[
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <LocationIcon />
                </UniIconWrapper>
              ),
              label: t('header.location'),
              value: `${application?.program?.address.city || '-'}, ${application?.program?.address.country || '-'}`,
            },
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <HourglassIcon />
                </UniIconWrapper>
              ),
              label: application?.program?.lengthOfStudy ? t('header.lengthOfStudy') : t('header.levelOfStudy'),
              value: application?.program?.lengthOfStudy
                ? `${application?.program?.lengthOfStudy?.value} ${application?.program?.lengthOfStudy?.unit}`
                : application.program?.levelOfStudy
                ? levelOfStudyMap[application.program.levelOfStudy]
                : 'Unspecified',
            },
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <HourglassIcon />
                </UniIconWrapper>
              ),
              label: t('header.dateApplied'),
              value: moment(application.createdAt).format('MMMM d, YYYY'),
            },
          ]}
          tabs={[
            { label: 'Status', path: statusPath },
            { label: 'Documents', path: documentsPath },
            { label: 'Candidate profile', path: studentPath },
            { label: 'Program info', path: generalPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={statusPath}>
              <AnimatedTabPanel
                Content={!!application ? <ProgramStatusReplies application={application} /> : <EmptyState type='status' />}
                index={0}
                currentItem={currentRoute}
              />
            </Route>
            <Route path={documentsPath}>
              <AnimatedTabPanel
                Content={<StudentDocuments documents={application?.applicationDocuments.map(({ file }) => file)} />}
                index={1}
                currentItem={currentRoute}
              />
            </Route>
            <Route path={studentPath}>
              <AnimatedTabPanel
                Content={
                  <>
                    <StudentPersonalInfo candidate={application?.student} />
                    <Box mt={3} />
                    <StudentEducation candidate={application?.student} />
                  </>
                }
                index={2}
                currentItem={currentRoute}
              />
            </Route>
            <Route path={generalPath}>
              <AnimatedTabPanel Content={<ProgramGeneralInfo program={application?.program} />} index={3} currentItem={currentRoute} />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default ApplicationPage;
