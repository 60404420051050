import { Box } from '@material-ui/core';
import { Shadows } from 'constants/uiConfig';
import React from 'react';
import styled from 'styled-components';
import ComponentColors from '../../constants/componentColors';

export const StyledStickyBar = styled(Box)<{
  ref?: React.MutableRefObject<null>;
  isSticky?: boolean;
  isLandingPage?: boolean;
}>`
  position: sticky;
  align-self: flex-start;
  top: -1px;
  z-index: 10;
  background-color: ${ComponentColors.gray1_100};
  height: auto;
  box-shadow: ${({ isSticky }) => (isSticky ? Shadows.shadow1 : '')};
  margin: 0 ${({ isLandingPage }) => (isLandingPage ? '-30%' : '-10%')};
  padding: ${({ theme }) => theme.spacing(2)}px ${({ isLandingPage }) => (isLandingPage ? '30%' : '10%')};

  }
`;
