import { Box } from '@material-ui/core';
import { useFile } from 'api';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';

interface Props {
  programName?: string;
  univeristyName?: string;
}

const Step1: React.FC<Props> = ({ programName = '', univeristyName = '' }) => {
  const { t } = useTranslation('applicationDrawer');
  const { profile } = useAuth();
  const { data } = useFile({ id: profile.studentProfile?.passportDocument?.id || '' });

  return (
    <>
      <Box mb={1} mt={2}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          {t('texts.youAreAboutToApply')}
        </UniTypography>
      </Box>
      <UniTypography variant='body1' color='typographyBlack100' weight='semi-bold'>
        {programName}
      </UniTypography>
      <UniTypography variant='body2' color='secondary100' weight='medium'>
        {univeristyName}
      </UniTypography>

      <Box mt={5}>
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          {t('texts.eligibilityText')}
          <br />
          <br />
          {t('texts.anyQuestions')}
        </UniTypography>
      </Box>
    </>
  );
};

export default Step1;
