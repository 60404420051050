import { StylesConfig } from 'react-select';
import { Colors } from '../../../constants';

const creatableSelectStyles: StylesConfig<any> = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    flex: 1,
    borderColor: state.isFocused ? Colors.Primary._100 : Colors.Border.Gray_100,
    fontFamily: 'Poppins',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: Colors.Typography.Gray_70,
    padding: '8px 16px',
    cursor: 'pointer',
    backgroundColor: state.isSelected ? Colors.Primary._10 : 'transparent',
    ':hover': {
      backgroundColor: Colors.Gray1._70,
    },
  }),
  control: (provided, state) => ({
    ...provided,
    height: 56,
    borderRadius: 8,
    borderColor: Colors.Border.Gray_100,
    boxShadow: 'none',
    ':hover': {
      borderColor: Colors.Border.Gray_100,
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  placeholder: provided => ({
    ...provided,
    textAlign: 'left',
    fontWeight: 400,
    color: Colors.Typography.Gray_50,
    fontFamily: 'Poppins',
  }),
  input: provided => ({ ...provided, textAlign: 'left' }),
  menuList: provided => ({ ...provided, textAlign: 'left' }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: Colors.Typography.Gray_10,
    borderRadius: '16px',
    color: Colors.Typography.Gray_70,
    padding: '4px 8px',
  }),
  valueContainer: provided => ({
    ...provided,
    height: 56,
    overflow: 'auto',
  }),
};
export default creatableSelectStyles;
