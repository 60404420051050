import { Box } from '@material-ui/core';
import { useErrorHandler } from 'hooks';
import React, { ReactElement, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import useNotOnboardedUser from '../../../../api/auth/useNotOnboardedUser';
import privacyDoc from '../../../../assets/docs/Youni Privacy Policy.pdf';
import termsDoc from '../../../../assets/docs/T&Cs Uniapply Platform.pdf';
import { ReactComponent as LeavesIllustration } from '../../../../assets/svg/Illustrations/candidateHeaderIllustration.svg';
import { UniBounceLoader, UniButton, UniLogo, UniTypography } from '../../../../components';
import UniCarousel from '../../../../components/_common/uniCarousel/UniCarousel';
import UniCarouselSlide from '../../../../components/_common/uniCarousel/uniCarouselSlide/UniCarouselSlide';
import { BrowserRoutes } from '../../../../constants';
import { CurrencyIso, Nationality } from '../../../../types';
import {
  StyledLeftSide,
  StyledLeftSideInner,
  StyledOnboardIllustration,
  StyledOnboardWrapper,
  StyledRightSide,
  StyledRightSideInner,
} from '../Onboard.style';
import OnboardStep3 from '../OnboardStep3';
import AgencyOnboardStep1 from './AgencyOnboardStep1';
import AgencyOnboardStep2 from './AgencyOnboardStep2';
import AgencyOnboardTabs from './AgencyOnboardTabs';

interface Params {
  agencyName: string;
  token: string;
}

export interface AgencyOnboardForm {
  companyName: string;
  taxCode: string;
  website: string;
  address: string;
  postalCode: string;
  country: Nationality;
  state: string;
  city: string;
  firstName: string;
  lastName: string;
  feeAmount: number;
  feeCurrency: CurrencyIso;
  feeType: 'UPFRONT' | 'FIFTY_FIFTY' | 'AFTER';
  username: string;
  password: string;
  email: string;
  token: string;
  terms: boolean;
}

export default function AgencyOnboard(): ReactElement {
  const history = useHistory();

  const [tabValue, setTabValue] = useState(0);
  const [maxTabIndex, setMaxTabIndex] = useState(0);

  const { token } = useParams<Params>();
  const [onboardForm, setOnboardForm] = useState<AgencyOnboardForm>({
    address: '',
    city: '',
    companyName: '',
    country: 'RO',
    postalCode: '',
    state: '',
    taxCode: '',
    website: '',
    token,
    firstName: '',
    lastName: '',
    feeAmount: 0,
    feeCurrency: 'RON',
    password: '',
    username: '',
    terms: false,
    feeType: 'UPFRONT',
    email: 'agency',
  });

  const { data: onboardingUser, status: onboardingUserStatus, error: onboardingError } = useNotOnboardedUser({ token });

  const onboardingUserError = useErrorHandler(onboardingError!);

  const setFormData = (formData: AgencyOnboardForm) => {
    setOnboardForm(prevOnboardForm => {
      return { ...prevOnboardForm, ...formData };
    });
  };

  const cancelForm = () => {
    setTabValue(0);
    setMaxTabIndex(0);
  };

  if (onboardingUserStatus === 'loading') {
    return <UniBounceLoader />;
  }

  if (onboardingUserStatus === 'error') {
    history.push(BrowserRoutes.Default.InvalidToken);
  }
  return (
    <StyledOnboardWrapper>
      {tabValue === 3 ? (
        <OnboardStep3 onboardForm={onboardForm} />
      ) : (
        <>
          <StyledLeftSide>
            {tabValue === 0 ? (
              <Box maxWidth='465px' width='100%' mr={2} maxHeight='500px'>
                <Box mb={10}>
                  <UniTypography variant='header2' weight='semi-bold'>
                    Hello and welcome to
                  </UniTypography>
                  <UniLogo size='L' />
                </Box>
                <Box minHeight='152px'>
                  <UniTypography>
                    You have been invited by <strong>Youni Admin</strong> to sign up to Youni, the modern way to apply to University.
                  </UniTypography>
                </Box>
                <Box display='flex' justifyContent='space-between' mt={4}>
                  <UniButton color='primary' style={{ maxWidth: '160px' }} onClick={() => setTabValue(1)}>
                    Get Started
                  </UniButton>
                </Box>
                <Box display='flex' mt={10} style={{ gap: '5px' }}>
                  <Link to={privacyDoc} target='_blank'>
                    <UniTypography style={{ textDecoration: 'underline' }} variant='subheader' color='typographyGray70'>
                      Privacy Policy
                    </UniTypography>
                  </Link>
                  <UniTypography variant='subheader' color='typographyGray70'>
                    and
                  </UniTypography>
                  <Link to={termsDoc} target='_blank'>
                    <UniTypography style={{ textDecoration: 'underline' }} variant='subheader' color='typographyGray70'>
                      Terms of Service
                    </UniTypography>
                  </Link>
                </Box>
              </Box>
            ) : (
              <StyledLeftSideInner>
                <UniLogo size='L' />
                <AgencyOnboardTabs maxTabIndex={maxTabIndex} currentIndex={tabValue} setCurrentIndex={setTabValue} />
                <Box maxHeight='400px'>
                  <Box display='flex' justifyContent='space-between' width='100%' mb={5}>
                    <Box />
                    <LeavesIllustration />
                  </Box>
                  <Box display='flex' mt={10} style={{ gap: '5px' }}>
                    <Link to={privacyDoc} target='_blank'>
                      <UniTypography style={{ textDecoration: 'underline' }} variant='subheader' color='typographyGray70'>
                        Privacy Policy
                      </UniTypography>
                    </Link>
                    <UniTypography variant='subheader' color='typographyGray70'>
                      and
                    </UniTypography>
                    <Link to={termsDoc} target='_blank'>
                      <UniTypography style={{ textDecoration: 'underline' }} variant='subheader' color='typographyGray70'>
                        Terms of Service
                      </UniTypography>
                    </Link>
                  </Box>
                </Box>
              </StyledLeftSideInner>
            )}
          </StyledLeftSide>
          <StyledRightSide step={tabValue}>
            <StyledRightSideInner>
              {tabValue === 0 ? (
                <>
                  <Box />
                  <Box maxWidth='600px'>
                    <UniCarousel>
                      <UniCarouselSlide
                        title='Double your
                        conversion rate'
                        content='Get access to European insititutions, get trained on how to succeed and increase your students portfolio to scale internationally.'
                        icon={<StyledOnboardIllustration />}
                      ></UniCarouselSlide>
                    </UniCarousel>
                  </Box>
                  <Box />
                </>
              ) : (
                <>
                  <AgencyOnboardStep1
                    show={tabValue === 1}
                    data={{
                      companyName: onboardingUser?.agentProfile.agency.name || 'Partner Name',
                      email: onboardingUser?.email || '',
                    }}
                    nextStep={() => {
                      setTabValue(2);
                      setMaxTabIndex(Math.max(maxTabIndex, 2));
                    }}
                    onCancel={cancelForm}
                    setFormData={setFormData}
                  />

                  <AgencyOnboardStep2
                    show={tabValue === 2}
                    onboardForm={onboardForm}
                    data={{
                      firstName: onboardingUser?.firstName || '',
                      lastName: onboardingUser?.lastName || '',
                    }}
                    nextStep={() => {
                      setTabValue(3);
                      setMaxTabIndex(Math.max(maxTabIndex, 3));
                    }}
                    onCancel={cancelForm}
                    previousStep={() => setTabValue(1)}
                    setFormData={setFormData}
                  />
                </>
              )}
            </StyledRightSideInner>
          </StyledRightSide>
        </>
      )}
    </StyledOnboardWrapper>
  );
}
