import { Divider } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../constants';

export const StyledAgents = styled.div`
  min-height: 750px;
  position: relative;
`;

export const CandidateDivider = styled(Divider)`
  width: 95%;
  margin-left: 5%;
`;

export const PaginationControls = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px auto 0 auto;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.4);
`;

export const SelectedAgentsWrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 30px;
  left: 50%;

  transform: translateX(-50%);
  z-index: 999;

  background-color: ${Colors.Primary._100};
  padding: ${({ theme }) => theme.spacing(1.5, 3)};
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;

  white-space: nowrap;
  max-width: 415px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)}px;

  transition: 0.2s;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  & > * {
    flex: 1;
  }
`;
