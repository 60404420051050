import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getUserHomeScreen, getUserToProfile } from 'utils/getHomeForRole';
import { BrowserRoutes } from '../../constants';
import { useAuth, useClient, useSnackbar } from '../../store';

const useVerifyNewEmail = () => {
  const { setProfile } = useAuth();
  const { client, session } = useClient();
  const { openSnackbar } = useSnackbar();
  const history = useHistory();

  return useMutation(
    async (payload: Components.Schemas.UpdateEmailRequest) => {
      return client!.AuthApi_emailUpdateVerifyNew(undefined, payload).then(() => client!.UserApi_getMyUser());
    },
    {
      onSuccess: ({ data }) => {
        setProfile(data);
        history.push(getUserToProfile(session?.user.role));
        openSnackbar('profile-email');
      },
      onError: (error: AxiosError) => {
        history.push(BrowserRoutes.Default.Unauthorized);
      },
    },
  );
};

export default useVerifyNewEmail;
