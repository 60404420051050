import styled from 'styled-components';

export const StyledProducts = styled.div`
  position: relative;
`;

export const StyledProductsListHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr 10fr 1fr;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;
