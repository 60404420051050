import UniTypography from 'components/_common/uniTypography/UniTypography';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { Colors, ComponentColors } from '../../../../constants';

export const NotificationCount = (props: { count: number }) => {
  return (
    <AnimatePresence>
      {!!props.count && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          style={{
            position: 'absolute',
            top: -0,
            right: -3,
            boxSizing: 'border-box',
            backgroundColor: ComponentColors['primary100'],
            width: 18,
            height: 17,
            borderRadius: 20,
            border: `1px solid ${Colors.White._100}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <UniTypography variant='small' color='white100' weight='regular'>
            {props.count}
          </UniTypography>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
