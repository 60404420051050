import { ReactComponent as Checklist } from 'assets/svg/Illustrations/invitationSentIllustration.svg';
import { UniModalDrawerButtons, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { useTranslation } from 'react-i18next';

interface Props {
  changeDrawer: Function;
}

const NotificationEmailSent: React.FC<Props> = ({ changeDrawer }) => {
  const { t } = useTranslation('forms');

  const clickHandler = () => {
    changeDrawer('contact-form');
  };

  return (
    <>
      <UniModalDrawerContent>
        <Checklist />
        <UniTypography color='secondary100' variant='header3'>
          {t('form.change_password.confirmation_sent.1')}
        </UniTypography>
        <UniTypography color='typographyGray100' variant='subheader'>
          {t('form.change_password.confirmation_sent.2')}
        </UniTypography>
      </UniModalDrawerContent>
      <UniModalDrawerButtons PrimaryButton={{ onClick: clickHandler, label: t('form.change_password.confirmation_sent.back') }} />
    </>
  );
};

export default NotificationEmailSent;
