import { TableRow } from '@material-ui/core';
import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../../constants';

export const CandidateHeader = styled.div`
  background-color: ${Colors.White._100};
  width: calc(100%+48px);
  margin: 0 -24px;
  border-radius: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
`;

export const MetaData = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(10)}px;
`;

export const MetaItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledCollapsibleRow = styled(TableRow)`
  margin-bottom: 10px;
`;

export const StyledHeader = styled.div`
  background-color: #fff;
  width: calc(100% + 48px);
  margin: -40px -24px 0 -24px;
  padding: 0 24px;
  box-shadow: ${Shadows.shadow2};
`;

export const CandidateMeta = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing(5, 0)};
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const CandidateIdentity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const CandidateActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const CandidateStatus = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledProgram = styled.div`
  background-color: ${Colors.White._100};
  box-shadow: ${Shadows.shadow1};
  border-radius: ${Shape.BorderRadius.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
export const StyledTableHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr repeat(3, 2fr) 1fr;
  padding: ${({ theme }) => theme.spacing(0, 2, 3, 2)};
`;

export const StyledProgramHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr repeat(3, 2fr) 1fr;
  padding: ${({ theme }) => theme.spacing(3, 2)};
`;
export const StyledProgramFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const StyledProgramDetails = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 2)};
  border-top: 1px solid ${Colors.Border.Gray_100};
`;
export const StyledProgramMetaInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

export const CandidateProfileDataWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledProfileDataTabs = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;

  gap: ${({ theme }) => theme.spacing(2)}px;
  flex-direction: row;
`;

export const StyledProfileDataPanels = styled.div`
  flex: 8;
`;

export const StyledInnerHeader = styled.div`
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(0, 5, 0, 10)};
`;

export const StyledFormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: ${({ theme }) => theme.spacing(5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;
