import { Grid } from '@material-ui/core';
import { UniversitiesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/UniversitiesFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getMetaRole } from 'utils';
import { useGetUniversities, useUniversityNames } from '../../../api';
import { StickyListFilterBar, UniPage } from '../../../components';
import UniversitiesList from '../../../components/universitiesList/universitiesList';
import UniversitiesListItem from '../../../components/universitiesList/universitiesListItem';
import { BrowserRoutes } from '../../../constants';
import { useAuth, useDrawers } from '../../../store';

export const AgencyAdminUniversities = () => {
  const { t } = useTranslation('agencyAdminUniversities');
  const { profile } = useAuth();
  const { isAgent } = getMetaRole(profile.role);
  const [query, setQuery] = useState<string>();
  const history = useHistory();
  const { queries } = useQueryStringParser<UniversitiesFiltersQueries>();
  const { openDrawer } = useDrawers();

  const {
    data: universities,
    status: universitiesStatus,
    fetchMore,
    canFetchMore,
    error: uniError,
  } = useGetUniversities({
    query: query,
    ...queries,
  });
  const universitiesLoading = universitiesStatus === 'loading';
  const universitiesFlat = universities?.map(programPage => programPage.results).flat() || [];
  const universitiesTotal = universities ? universities[0].total : 0;
  const totalResultsLabel =
    universitiesTotal === 1 ? universitiesTotal + ' ' + t('result.ifOne') : universitiesTotal + ' ' + t('result.ifMore');

  const { data: searchUniversities, error: searchError } = useUniversityNames();

  const openFiltersUniversitiesDrawer = () => openDrawer('filter-universities', { size: 'small' });

  return (
    <UniPage>
      <Grid xs={12} item>
        <StickyListFilterBar
          listTitle={t('list.title')}
          options={searchUniversities?.map(item => item.label) || []}
          searchHint={t('list.search_hint')}
          handleSearch={setQuery}
          tooltip={t('list.tooltip')}
          onClickFilter={openFiltersUniversitiesDrawer}
          totalResultsLabel={totalResultsLabel}
        />
        <UniversitiesList hasMore={!!canFetchMore} fetchMore={fetchMore} length={universitiesTotal} isLoading={universitiesLoading}>
          {universitiesFlat.map((university: any, index: any) => (
            <React.Fragment key={index}>
              <UniversitiesListItem
                university={university}
                onClick={() =>
                  isAgent
                    ? history.push(BrowserRoutes.Agent.UniversityProfile.replace(':id', university?.id!))
                    : history.push(BrowserRoutes.Agency.UniversityProfile.replace(':id', university?.id!))
                }
              />
            </React.Fragment>
          ))}
        </UniversitiesList>
      </Grid>
    </UniPage>
  );
};
