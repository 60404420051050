import { Box } from '@material-ui/core';
import DataConsent from 'components/startApplicationModal/DataConsent';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import UniCheckbox from 'components/_inputs/uniCheckbox/UniCheckbox';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledConsentDataWrapper, StyledConsentWrapper } from '../ApplicationDrawer.style';

interface Props {
  setAcceptTerms: Function;
  universityName?: string;
}

const Step3 = ({ setAcceptTerms, universityName }: Props) => {
  const { t } = useTranslation('applicationDrawer');

  return (
    <StyledConsentWrapper>
      <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
        {t('texts.beforeApplying')}
      </UniTypography>
      <StyledConsentDataWrapper>
        <DataConsent universityName={universityName || 'University'} />
      </StyledConsentDataWrapper>
      <Box display='flex' alignItems='center'>
        <UniCheckbox onChange={event => setAcceptTerms(event.target.checked)} />
        <UniTypography variant='subheader' color='typographyGray100'>
          {t('labels.iConsent')}
        </UniTypography>
      </Box>
    </StyledConsentWrapper>
  );
};

export default Step3;
