import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

const useDeleteServiceOrder = () => {
  const { client } = useClient();
  const { invalidateQueries } = useQueryCache();

  return useMutation(
    async (payload: string) => {
      return client!.ServiceOrderApi_deleteServiceOrder({ serviceOrderId: payload });
    },

    {
      onSuccess: response => {
        invalidateQueries(CacheKeys.Student.Orders);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default useDeleteServiceOrder;
