import { Box } from '@material-ui/core';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledApplicationSuccessIllustration } from '../ApplicationFee.style';

interface Props {
  programName?: string;
  studentName?: string;
}

const Step2: React.FC<Props> = ({ programName, studentName }) => {
  const { t } = useTranslation('replyToUniversity');

  return (
    <>
      <UniTypography variant='body1' weight='medium' color='secondary100'>
        {`You've sent an invoice to ${studentName} for their application at ${programName}.`}
      </UniTypography>
      <Box my={5}>
        <StyledApplicationSuccessIllustration />
      </Box>
    </>
  );
};

export default Step2;
