import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../constants';

export const StyledFacultiesListItem = styled.div`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  box-shadow: ${Shadows.shadow1};
  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
  min-height: ${({ theme }) => theme.typography.pxToRem(225)};
  cursor: pointer;
`;
