import { Box } from '@material-ui/core';
import moment from 'moment';
import { default as React, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UniIconWrapper } from '..';
import { useDeleteAdditionalDocument, useFile, usePostDocumentStatus } from '../../api';
import { ReactComponent as GarbageIcon } from '../../assets/svg/Icons/Garbage.svg';
import { Components } from '../../client/UniClient';
import { useErrorHandler } from '../../hooks';
import { useAuth } from '../../store';
import { theme } from '../../theme';
import { getMetaRole } from '../../utils';
import UniBounceLoader from '../_common/uniBounceLoader/UniBounceLoader';
import UniButton from '../_common/uniButton/UniButton';
import UniTypography from '../_common/uniTypography/UniTypography';
import { StyledDocumentsListItem } from './AdditionalDocuments.style';

interface Props {
  doc: Components.Schemas.FileDto;
  candidate: Partial<Components.Schemas.UserDto>;
}

export default function AdditionalDocumentsListItem({ doc, candidate }: Props): ReactElement {
  const { t } = useTranslation('additionalDocuments');
  const { profile } = useAuth();
  const { isAgency, isAgent } = getMetaRole(profile.role!);
  const isEditable = isAgency || isAgent || profile.id === candidate.id;
  const {
    status: openFileStatus,
    data: openFileData,
    error: fileError,
  } = useFile({
    id: doc.id,
  });
  const openFileError = useErrorHandler(fileError!);
  const [deleteFile, { status: deleteFileStatus, error: deleteFileError }] = useDeleteAdditionalDocument();
  const deleteError = useErrorHandler(deleteFileError!);
  const [updateValidation, { error: validateFileError }] = usePostDocumentStatus();
  const validateError = useErrorHandler(validateFileError!);

  const errorMessage = openFileError.message || deleteError.message || validateError.message;

  return (
    <StyledDocumentsListItem>
      {/* <Box>
        <UniTextField style={{ minWidth: '170px' }} fullWidth value={doc.label} withBorder disabled />
        {errorMessage && <UniTypography color='stateAlert100'>{errorMessage}</UniTypography>}
      </Box> */}
      <Box>
        <UniTypography weight='medium' color='typographyGray100'>
          {doc.label}
        </UniTypography>
      </Box>

      <UniTypography weight='medium' color='typographyGray100'>
        {moment(doc.createdAt).format('MMM Do YYYY')}
      </UniTypography>
      <Box display='flex' alignItems='center' style={{ gap: theme.spacing(0, 1) }} mb={!isEditable ? 2.5 : 0}>
        <UniButton
          loading={openFileStatus === 'loading'}
          fullWidth={false}
          variant='main'
          color='primaryFade'
          onClick={() => {
            openFileData && window.open(openFileData.url, '_blank');
          }}
        >
          {t('buttons.download')}
        </UniButton>
        {isEditable && (
          <UniIconWrapper
            size='medium'
            withBackground
            onClick={() => {
              deleteFile(doc.id);
            }}
          >
            {deleteFileStatus === 'loading' ? <UniBounceLoader /> : <GarbageIcon />}
          </UniIconWrapper>
        )}
      </Box>
    </StyledDocumentsListItem>
  );
}
