import React from 'react';
import { UniTypography } from '../../../components';
import { Arrow, StyledUniSortButton } from './UniSortButton.style';
import { ReactComponent as DownArrow } from '../../../assets/svg/Icons/Arrow/Down.svg';
import { ReactComponent as UpArrow } from '../../../assets/svg/Icons/Arrow/Up2.svg';

export type OrderType = 'ASC' | 'DESC' | undefined;
type UniSortButtonProps = {
  active: boolean;
  order: OrderType;
  onClick: () => void;
  style?: React.CSSProperties;
};

const UniSortButton: React.FC<UniSortButtonProps> = ({ active, order, children, onClick, style }) => {
  const arrow = order === 'ASC' ? <DownArrow /> : <UpArrow />;

  return (
    <StyledUniSortButton onClick={onClick} active={active} style={style}>
      <UniTypography weight='medium' variant='subheader'>
        {children}
      </UniTypography>{' '}
      <Arrow>{active && arrow}</Arrow>
    </StyledUniSortButton>
  );
};

export default UniSortButton;
