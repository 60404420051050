import { Box, Grid } from '@material-ui/core';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDrawers } from 'store';
import { useGetProgramsNames, usePrograms } from '../../../api';
import {
  ProgramsList,
  ProgramsListItem,
  ProgramsListItemAgentActions,
  StickyListFilterBar,
  UniPage,
  UniTypography,
} from '../../../components';
import { BrowserRoutes } from '../../../constants';
import { useErrorHandler } from '../../../hooks';
const AgencyPrograms = () => {
  const { t } = useTranslation('agencyAdminPrograms');
  const history = useHistory();
  const [query, setQuery] = useState<string>();
  const [sortType, setSortType] = useState(1);
  const { queries } = useQueryStringParser<ProgramsFiltersQueries>();
  const { openDrawer } = useDrawers();

  const {
    data: programs,
    isFetching,
    fetchMore,
    canFetchMore,
    error: programsError,
  } = usePrograms({
    query,
    filters: queries,
    sortType,
  });

  const programListError = useErrorHandler(programsError!);
  const programsLoading = isFetching;
  const programsFlat = useMemo(() => {
    return programs?.map(programPage => programPage.results).flat() || [];
  }, [programs]);
  const programsTotal = useMemo(() => (programs ? programs[0].total : 0), [programs]);
  const totalResultsLabel = useMemo(
    () => (programsTotal === 1 ? programsTotal + ' ' + t('result.ifOne') : programsTotal + ' ' + t('result.ifMore')),
    [programs],
  );

  const { data: programNames, status: programNamesStatus } = useGetProgramsNames();

  const onSort = (type: number) => setSortType(type);

  const openFiltersProgramsDrawer = () => openDrawer('filter-programs', { size: 'small' });

  return (
    <UniPage>
      <Grid xs={12} item>
        <StickyListFilterBar
          listTitle={t('list.title')}
          options={programNames?.map(item => item.label) || []}
          searchHint={t('list.search_hint')}
          handleSearch={setQuery}
          totalResultsLabel={totalResultsLabel}
          onClickFilter={openFiltersProgramsDrawer}
          tooltip={t('list.tooltip')}
        />
        {programListError.message && (
          <Box>
            <UniTypography color='stateAlert100'>{programListError.message}</UniTypography>
          </Box>
        )}
        <ProgramsList
          fetchMore={fetchMore}
          hasMore={!!canFetchMore}
          hasStatus={false}
          length={programsTotal}
          onSort={onSort}
          isLoading={programsLoading}
        >
          {programsFlat.map((program: any, index: any) => (
            <ProgramsListItem
              onClick={() => history.push(BrowserRoutes.Agent.Program.General.replace(':programId', program.id!))}
              program={program}
              ActionButtons={<ProgramsListItemAgentActions program={program} />}
              key={index}
            />
          ))}
        </ProgramsList>
      </Grid>
    </UniPage>
  );
};

export default AgencyPrograms;
