import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useClient } from '../../store';
import { CurrencyIso, Nationality } from '../../types';

type Payload = {
  companyName: string;
  taxCode: string;
  website: string;
  address: string;
  postalCode: string;
  country: Nationality;
  state: string;
  city: string;
  phone: string;

  firstName: string;
  lastName: string;
  wappId: string;
  feeAmount: number;
  feeCurrency: CurrencyIso;
  feeType: 'UPFRONT' | 'FIFTY_FIFTY' | 'AFTER';
  username: string;
  password: string;
  email: string;
  token: string;
  terms: boolean;
};

const useCompleteAgencyOnboarding = () => {
  const history = useHistory();
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AuthApi_completeAgencyOnboarding(undefined, {
        name: payload.companyName,
        fee: {
          amount: +payload.feeAmount,
          currencyIso: payload.feeCurrency,
        },
        feeType: payload.feeType,
        password: payload.password,
        token: payload.token,
        firstName: payload.firstName,
        lastName: payload.lastName,
        address: {
          addressLine1: payload.address,
          addressLine2: '',
          addressLine3: '',
          formattedAddress: '',
          lat: 0,
          lng: 0,
          postalCode: payload.postalCode,
          city: payload.city,
          state: payload.state,
          country: payload.country,
        },
        middleName: '',
        taxCode: payload.taxCode,
        virtualAddress: {
          email: payload.email,
          phone: payload.phone,
          website: payload.website,
        },
        whatsAppId: payload.wappId,
      });
    },

    {
      onSuccess: async response => {
        // setSession(response.data);
        // const { data } = await client!.UserApi_getMyUser();
        // setProfile(data);
      },
      onError: (error: AxiosError) => {
        // history.push(BrowserRoutes.Default.InvalidToken);
      },
    },
  );
};
export default useCompleteAgencyOnboarding;
