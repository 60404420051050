import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniButton, UniIconWrapper, UniTextField } from 'components';
import { StyledSubtitle } from 'components/_forms/Form.style';
import UniPhoneInput from 'components/_inputs/uniPhoneInput/uniPhoneInput';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import { countriesMap } from 'constants/countriesMap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LockIcon } from '../../../../assets/svg/Icons/Lock.svg';
import { StyledUniCardFooter } from '../StudentOnboard.style';

export type Step3FormData = {
  email: string;
  phonePrefix: string;
  phone: string;
  password: string;
  address: Components.Schemas.AddressDto;
};

type Props = {
  defaultValues: Partial<Step3FormData>;
  prevStep: () => void;
  nextStep: (formData: Step3FormData) => void;
  isByLink?: boolean;
  hasPassword?: boolean;
};

const Step3: React.FC<Props> = ({ defaultValues, isByLink, hasPassword, nextStep, prevStep }) => {
  const { register, control, setValue, errors, handleSubmit, watch } = useForm<Step3FormData>({ defaultValues });
  const { t: tButtons } = useTranslation('buttons');
  const { t } = useTranslation('forms');

  // const selectedCountry = watch('address.country') as Nationality;

  // console.log((postalCodeRegexMap as any)?.[selectedCountry]);
  // console.log(selectedCountry);

  useEffect(() => {
    localStorage.setItem('onboardFormDataStep3', JSON.stringify(watch()));
  }, [watch()]);

  return (
    <>
      <Box mb={4}>
        <StyledSubtitle variant='body1' color='secondary100' weight='medium'>
          {t('titles.address')}
        </StyledSubtitle>
        <UniTextField
          label={t('form.street')}
          inputRef={register({
            required: `${t('form.errors.street')}`,
            validate: (value: string) => value.trim() !== '' || `${t('form.errors.street')}`,
          })}
          name='address.addressLine1'
          error={!!errors.address?.addressLine1?.message}
          helperText={errors.address?.addressLine1?.message}
          fullWidth
        />
        <UniTextField
          label={t('form.number_and_info')}
          inputRef={register({
            required: `${t('form.errors.number_and_info')}`,
            validate: (value: string) => value.trim() !== '' || `${t('form.errors.number_and_info')}`,
          })}
          name='address.addressLine2'
          error={!!errors.address?.addressLine2?.message}
          helperText={errors.address?.addressLine2?.message}
          fullWidth
        />
        <UniTextField
          label={t('form.postcode')}
          inputRef={register({
            required: `${t('form.errors.postcode')}`,
            // pattern: {
            //   value: (postalCodeRegexMap as any)?.[selectedCountry],
            //   message: `${t('form.errors.postcode_valid')}`,
            // },
          })}
          name='address.postalCode'
          error={!!errors.address?.postalCode?.message}
          helperText={errors.address?.postalCode?.message}
          fullWidth
        />
        <UniTextField
          label={t('form.town_city')}
          inputRef={register({
            required: `${t('form.errors.town_city')}`,
            validate: (value: string) => value.trim() !== '' || `${t('form.errors.town_city')}`,
          })}
          name='address.city'
          error={!!errors.address?.city?.message}
          helperText={errors.address?.city?.message}
          fullWidth
        />
        <Controller
          control={control}
          name='address.country'
          rules={{ required: `${t('form.errors.country')}` }}
          render={({ value, onChange }) => (
            <UniSelect
              value={countriesMap.find(({ value: v }) => v === value)}
              onChange={value => onChange(value?.value)}
              label={t('form.country')}
              options={countriesMap}
              error={errors.address?.country?.message}
            />
          )}
        />
        <StyledSubtitle variant='body1' color='secondary100' weight='medium'>
          {t('titles.contact')}
        </StyledSubtitle>
        <UniPhoneInput
          name='phone'
          label={t('form.phone')}
          control={control}
          setValue={setValue}
          phonePrefix={defaultValues.phonePrefix}
          inputRef={register({
            required: `${t('form.errors.phone_1')}`,
            minLength: { value: 4, message: t('form.errors.phone_3') },
            maxLength: { value: 15, message: t('form.errors.phone_3') },
          })}
          error={!!errors.phone?.message || !!errors.phonePrefix?.message}
          helperText={errors.phone?.message || errors.phonePrefix?.message}
          fullWidth
        />
        <UniTextField
          label={t('form.email')}
          name='email'
          disabled={!isByLink}
          value={defaultValues.email}
          inputRef={
            isByLink
              ? register({
                  required: `Email is required`,
                })
              : undefined
          }
          fullWidth
        />
        {hasPassword && (
          <UniTextField
            inputRef={register({
              required: {
                value: true,
                message: t('form.errors.password_1'),
              },
            })}
            startAdornment={
              <UniIconWrapper size='medium' color='typographyGray100'>
                <LockIcon />
              </UniIconWrapper>
            }
            name='password'
            label={t('form.password')}
            placeholder={t('form.password')}
            type='password'
            fullWidth
            endAdornment
          />
        )}
      </Box>
      <StyledUniCardFooter
        variants='M'
        type='labeled'
        SecondaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            variant='text'
            size={'large'}
            color='neutral'
            fullWidth={false}
            onClick={prevStep}
          >
            {tButtons('previous')}
          </UniButton>
        }
        PrimaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            size={'large'}
            color='primary'
            fullWidth={false}
            onClick={handleSubmit(nextStep)}
          >
            {tButtons('next_step')}
          </UniButton>
        }
      />
    </>
  );
};

export default Step3;
