import LayoutRoute from 'components/_layouts/layoutRoute/LayoutRoute';
import React, { lazy } from 'react';
import { BrowserRoutes } from '../constants';
const FacultyPage = lazy(() => import('../views').then(({ FacultyPage }) => ({ default: FacultyPage })));
const UniversityApplication = lazy(() => import('../views').then(({ UniversityApplication }) => ({ default: UniversityApplication })));
const UniversityApplicationPage = lazy(() =>
  import('../views').then(({ UniversityApplicationPage }) => ({ default: UniversityApplicationPage })),
);
const UniversityFaculties = lazy(() => import('../views').then(({ UniversityFaculties }) => ({ default: UniversityFaculties })));
const UniversityHomepage = lazy(() => import('../views').then(({ UniversityHomepage }) => ({ default: UniversityHomepage })));
const UniversityProgramPage = lazy(() => import('../views').then(({ UniversityProgramPage }) => ({ default: UniversityProgramPage })));
const UniversityPrograms = lazy(() => import('../views').then(({ UniversityPrograms }) => ({ default: UniversityPrograms })));
const UniversityPage = lazy(() => import('../views/universityPage/UniversityPage'));
const UniversityCandidateProfile = lazy(() => import('../views/_university/candidateProfile/UniversityCandidateProfile'));

const UniversityRoutes = () => {
  return [
    <LayoutRoute exact path={BrowserRoutes.University.Default} key={BrowserRoutes.University.Default} component={UniversityHomepage} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.University.Student.Default}
      key={BrowserRoutes.University.Student.Default}
      component={UniversityCandidateProfile}
    />,
    <LayoutRoute exact path={BrowserRoutes.University.Programs} key={BrowserRoutes.University.Programs} component={UniversityPrograms} />,
    <LayoutRoute
      path={BrowserRoutes.University.Program.Default}
      key={BrowserRoutes.University.Program.Default}
      component={UniversityProgramPage}
    />,
    <LayoutRoute
      path={BrowserRoutes.University.Application.Default}
      key={BrowserRoutes.University.Application.Default}
      component={UniversityApplicationPage}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.University.Applications}
      key={BrowserRoutes.University.Applications}
      component={UniversityApplication}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.University.Profile.Default}
      key={BrowserRoutes.University.Profile.Default}
      component={UniversityPage}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.University.Faculties}
      key={BrowserRoutes.University.Faculties}
      component={UniversityFaculties}
    />,
    <LayoutRoute
      path={[BrowserRoutes.University.Faculty.Programs, BrowserRoutes.University.Faculty.General]}
      key={BrowserRoutes.University.Faculty.Programs}
      component={FacultyPage}
    />,
  ];
};

export default UniversityRoutes;
