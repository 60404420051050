import { Box } from '@material-ui/core';
import { UniIconWrapper, UniListItem, UniTypography } from 'components';
import { countries } from 'constants/countries';
import { graduationLevel, GraduationLevel } from 'constants/levelOfStudy';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import { ReactComponent as EditIcon } from '../../assets/svg/Icons/Edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/Icons/Garbagev2.svg';
import { Components } from '../../client/UniClient';
import { StyledTitleSection } from './StudentEducation.style';

interface Props {
  educationHistoryItem: Components.Schemas.EducationHistoryItemDto;
  isMobile?: boolean;
  hide?: boolean;
  editable?: boolean;
}

export default function StudentEducationItem({ educationHistoryItem, isMobile, hide, editable }: Props): ReactElement {
  const { t } = useTranslation('studentEducation');
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  const getDiplomaType = (studyLevel: GraduationLevel) => {
    const diploma = graduationLevel.filter(item => item.value === studyLevel)[0];
    return diploma?.label;
  };

  const { openDrawer } = useDrawers();

  const onEditEducation = (educationItem: Components.Schemas.EducationHistoryItemDto) =>
    openDrawer('edit-education', { size: 'small', educationItem });

  const onDeleteEducation = (educationItem: Components.Schemas.EducationHistoryItemDto) =>
    openDrawer('delete-education', { size: 'small', educationItem });

  const educationItemInfo = [
    {
      label: t('edItem.labels.study_level'),
      content: getDiplomaType(educationHistoryItem?.levelOfStudy),
    },
    {
      label: t('edItem.labels.diploma'),
      content: educationHistoryItem?.didGraduate ? 'Yes' : 'Not yet',
    },
    {
      label: t('edItem.labels.diploma_date'),
      content: educationHistoryItem?.graduationDate ? educationHistoryItem?.graduationDate : '-',
    },
    {
      label: t('edItem.labels.country'),
      content: countries.filter(item => item.code === educationHistoryItem?.schoolAddress?.country).map(item => item.name)[0] || '-',
    },
    {
      label: t('edItem.labels.city'),
      content: educationHistoryItem?.schoolAddress?.city || '-',
    },
    {
      label: t('edItem.labels.school_name'),
      content:
        (isMobile
          ? `${educationHistoryItem?.schoolName?.substring(0, 15)}${educationHistoryItem?.schoolName?.length > 14 ? '...' : ''}`
          : educationHistoryItem?.schoolName) || '-',
    },
  ];
  if (hide) return <> </>;
  return (
    <Box width='100%'>
      <StyledTitleSection>
        <UniTypography variant='body2' weight='medium' color='secondary100'>
          {getDiplomaType(educationHistoryItem?.levelOfStudy)}
        </UniTypography>
        {editable && (
          <Box display='flex'>
            <UniIconWrapper color='typographyGray100' onClick={() => onEditEducation(educationHistoryItem)}>
              <EditIcon />
            </UniIconWrapper>
            <UniIconWrapper color='typographyGray100' onClick={() => onDeleteEducation(educationHistoryItem)}>
              <DeleteIcon />
            </UniIconWrapper>
          </Box>
        )}
      </StyledTitleSection>
      {educationItemInfo?.map((item, index) => (
        <UniListItem
          size={isMobile ? 'S' : 'M'}
          type='twoLabels'
          label1={item.label}
          label2={item.content}
          withBackground={index % 2 !== 0}
        />
      ))}
    </Box>
  );
}
