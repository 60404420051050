import { Box } from '@material-ui/core';
import { ReactComponent as HeartIcon } from 'assets/svg/Icons/Heart.svg';
import { UniButton, UniChip, UniIconWrapper } from 'components';
import { useTranslation } from 'react-i18next';

interface Props {
  chipLabel: string;
  clickApply?: Function;
  clickFavorite?: Function;
}

export const HerosectionActions = ({ chipLabel, clickApply, clickFavorite }: Props) => {
  const { t } = useTranslation('programPage');

  return (
    <Box display='flex' gridGap='16px' alignItems='center'>
      <Box display='flex' alignItems='center' gridGap='16px'>
        <UniChip color='green' label={chipLabel} />
        <UniButton
          size='medium'
          variant='text'
          onClick={() => clickFavorite && clickFavorite()}
          startIcon={
            <UniIconWrapper color='typographyGray100'>
              <HeartIcon />
            </UniIconWrapper>
          }
        />
      </Box>
      <UniButton size='medium' variant='main' color='primary' onClick={() => clickApply && clickApply()}>
        {t('buttons.apply')}
      </UniButton>
    </Box>
  );
};
