//TODO remove this when adding real base url
const LOCAL_BASE_URL = 'http://localhost:3001';

export const ApiKeys = {
  PageSize: {
    Programs: 12,
    ActivitiesCards: 8,
    ActivitiesList: 8,
    Candidates: 9,
    Applications: 10,
    Universities: 12,
    GroupedPrograms: 3,
  },
} as const;
