import { Box, Fade } from '@material-ui/core';
import { useCompleteStudentOnboarding, useCompleteStudentOnboardingFromLink } from 'api';
import useCompleteStudentOnboardingPatch from 'api/auth/useCompleteStudentOnboardingPatch';
import useCompleteStudentOnboardingSocial from 'api/auth/useCompleteStudentOnboardingSocial';
import privacyDoc from 'assets/docs/Youni Privacy Policy.pdf';
import { Components } from 'client/UniClient';
import { UniButton, UniCheckbox, UniListItem, UniListTitle, UniTypography } from 'components';
import { Error, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import { englishExams } from 'constants/options/englishExams';
import { serviceDomains } from 'constants/options/serviceDomains';
import { studyInterests } from 'constants/options/studyInterests';
import { universityExams } from 'constants/options/universityExams';
import { useErrorHandler } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as ErrorSvg } from '../../../../assets/svg/Icons/Error.svg';
import { Colors, countries } from '../../../../constants';
import { BrowserRoutes } from '../../../../constants';
import { OnboardFormState, OnboardParams } from '../StudentOnboard';
import { StyledUniCardFooter } from '../StudentOnboard.style';
import { capitalize } from 'lodash';
import { useHistory } from 'react-router-dom';

type Props = {
  formState: Partial<OnboardFormState>;
  showTerms: boolean;
  prevStep: () => void;
  onSave: (data: Components.Schemas.UserResponse, newsletter: boolean) => void;
};

const Step8: React.FC<Props> = ({ formState, onSave, prevStep }) => {
  const { push } = useHistory();
  const { t } = useTranslation('forms');
  const { t: tButtons } = useTranslation('buttons');
  const [newsletter, setNewsletter] = useState(false);
  const [terms, setTerms] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const { type } = useParams<OnboardParams>();
  const [completeOnboarding, { status: completeOnboardingStatus, error: completeOnboardingError }] = useCompleteStudentOnboarding();
  const [patchStudent, { status: patchStudentStatus, error: patchStudentError }] = useCompleteStudentOnboardingPatch();
  const [patchStudentByLink, { status: patchStudentByLinkStatus, error: patchStudentByLinkError }] = useCompleteStudentOnboardingFromLink();
  const [completeOnboardingSocial, { status: completeOnboardingSocialStatus, error: completeOnboardingSocialError }] =
    useCompleteStudentOnboardingSocial();
  const onboardingError = useErrorHandler(
    completeOnboardingError || patchStudentError || completeOnboardingSocialError || patchStudentByLinkError!,
  );
  const showGuardianDetails =
    formState?.guardian?.name || formState?.guardian?.email || formState?.guardian?.phonePrefix || formState?.guardian?.phone;

  const isLoading =
    completeOnboardingStatus === 'loading' ||
    patchStudentStatus === 'loading' ||
    completeOnboardingSocialStatus === 'loading' ||
    patchStudentByLinkStatus === 'loading';

  const countryName = countries.find(country => country.code === formState?.countryOfBirth)?.name;
  const nationalityName = countries.find(country => country.code === formState?.nationality)?.name;
  const secondNationalityName = countries.find(country => country.code === formState?.secondNationality)?.name;

  const onSubmit = () => {
    if (!terms) {
      setShowTermsError(true);
      return;
    }
    const studyInterestArrayCheck = studyInterests.map(item => item.value);
    const studyInterestsRequest = formState?.studyInterests
      ?.map(({ value }) => value)
      .filter(item => studyInterestArrayCheck.includes(item));
    const otherStudyInterestsRequest = formState?.studyInterests
      ?.map(({ value }) => value)
      .filter(item => !studyInterestArrayCheck.includes(item));

    const serviceDomainsArrayCheck = serviceDomains.map(item => item.value);
    const stagesRequiringAssistance = formState?.serviceInterests
      ?.map(({ value }) => value)
      .filter(item => serviceDomainsArrayCheck.includes(item));
    const otherStagesRequiringAssistance = formState?.serviceInterests
      ?.map(({ value }) => value)
      .filter(item => !serviceDomainsArrayCheck.includes(item));

    const englishExamsArrayCheck = englishExams.map(item => item.value);
    const englishExamsRequest = formState?.englishExams?.map(({ value }) => value).filter(item => englishExamsArrayCheck.includes(item));
    const otherEnglishExamsRequest = formState?.englishExams
      ?.map(({ value }) => value)
      .filter(item => !englishExamsArrayCheck.includes(item));

    const universityExamsArrayCheck = universityExams.map(item => item.value);
    const universityExamsRequest = formState?.universityExams
      ?.map(({ value }) => value)
      .filter(item => universityExamsArrayCheck.includes(item));
    const otherUniversityExamsRequest = formState?.universityExams
      ?.map(({ value }) => value)
      .filter(item => !universityExamsArrayCheck.includes(item));

    const formRequest: Partial<Components.Schemas.CompleteStudentOnboardingRequest> = {
      // address: formState?.address!,
      phone: formState?.phone!,
      phonePrefix: formState?.phonePrefix!,
      // dateOfBirth: formState?.dateOfBirth,
      firstName: formState?.firstName,
      lastName: formState?.lastName,
      // gender: formState?.gender,
      // countryOfBirth: formState?.countryOfBirth!,
      // nationality: formState?.nationality,
      // secondNationality: formState?.secondNationality,
      // interestedSchoolName: formState?.particularSchool,
      // yearlyBudget: formState?.yearlyBudget?.value! as YearlyBudget,
      password: formState?.password || undefined,
      studyInterests: studyInterestsRequest,
      otherStudyInterests: otherStudyInterestsRequest,
      // stagesRequiringAssistance,
      // otherStagesRequiringAssistance,
      // englishExams: englishExamsRequest,
      // otherEnglishExams: otherEnglishExamsRequest,
      // universityExams: universityExamsRequest,
      // otherUniversityExams: otherUniversityExamsRequest,
      // highSchoolYear: formState?.highSchoolYear,
      token: formState?.token!,
    };
    switch (type) {
      case 'social':
        completeOnboardingSocial(formRequest, {
          onSuccess({ data }) {
            onSave(data, newsletter);
          },
        });
        break;
      case 'regular':
        completeOnboarding(formRequest, {
          onSuccess(data) {
            onSave(data, newsletter);
            push(BrowserRoutes.Student.Default);
          },
        });
        break;
      case 'invite':
        completeOnboarding(formRequest, {
          onSuccess(data) {
            onSave(data, newsletter);
          },
        });
        break;
      case 'patch':
        patchStudent(formRequest, {
          onSuccess({ data }) {
            onSave(data, newsletter);
          },
        });
        break;
      case 'link':
        patchStudentByLink(formRequest, {
          onSuccess({ data }) {
            onSave(data as Components.Schemas.UserResponse, newsletter);
          },
        });
        break;
    }
  };

  return (
    <>
      <Box mb={4}>
        <Box>
          <UniListTitle weight={'medium'} title='Personal info' onClick={() => {}} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='First name' label2={formState?.firstName} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Last name' label2={formState?.lastName} withBackground />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Gender' label2={capitalize(formState?.gender)} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Date of birth' label2={formState?.dateOfBirth} withBackground />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Country of birth' label2={countryName} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Nationality' label2={nationalityName} withBackground />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Second nationality' label2={secondNationalityName} />
        </Box>
        <Box>
          <UniListTitle weight={'medium'} title='Contact' onClick={() => {}} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Address line 1' label2={formState?.address?.addressLine1} />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Address line 2'
            label2={formState?.address?.addressLine2}
            withBackground
          />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Postcode' label2={formState?.address?.postalCode} />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Country'
            label2={countries.find(country => country.code === formState?.address?.country)?.name}
            withBackground
          />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='City' label2={formState?.address?.city} />
          {/* <UniListItem wordBreak autoHeight type='label' label1='Phone' withBackground backgroundColor='secondary10' weight='medium' /> */}
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Email' label2={formState?.email} withBackground />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Phone number'
            label2={formState?.phonePrefix! + ' ' + formState?.phone!}
          />
        </Box>
        <Box>
          <UniListTitle weight={'medium'} title='Educational Background' onClick={() => {}} />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Current level of study'
            label2={capitalize(formState?.levelOfStudy?.replace('_', ' '))}
          />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Graduation date' label2={formState?.graduationDate} withBackground />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Institution name' label2={formState?.schoolName} />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Institution country'
            label2={countries.find(country => country.code === formState?.educationCountry)?.name}
            withBackground
          />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Institution city' label2={formState?.educationCity} />
        </Box>
        <Box>
          <UniListTitle weight={'medium'} title='Educational Preferences' onClick={() => {}} />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Study interests'
            label2={formState?.studyInterests?.map(item => item.label)?.join(', ')}
          />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Country interests'
            label2={formState?.countryInterests?.map(item => item.name)?.join(', ')}
            withBackground
          />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Particular school' label2={formState?.particularSchool} />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Yearly budget'
            label2={formState?.yearlyBudget?.label}
            withBackground
          />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='You need help with:'
            label2={formState?.serviceInterests?.map(item => item.label)?.join(', ')}
          />
        </Box>
        <Box>
          <UniListTitle weight={'medium'} title='Accomplishments' onClick={() => {}} />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='English exams'
            label2={formState?.englishExams?.map(item => item.label)?.join(', ')}
          />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Other exams'
            label2={formState?.universityExams?.map(item => item.label)?.join(', ')}
            withBackground
          />
        </Box>
        {showGuardianDetails && (
          <Box>
            <UniListTitle weight={'medium'} title='Guardian details' onClick={() => {}} />
            <UniListItem wordBreak autoHeight type='twoLabels' label1='Name' label2={formState?.guardian?.name} />
            <UniListItem wordBreak autoHeight type='twoLabels' label1='Email' label2={formState?.guardian?.email} withBackground />
            <UniListItem
              wordBreak
              autoHeight
              type='twoLabels'
              label1='Phone'
              label2={formState?.guardian?.phonePrefix! + ' ' + formState?.guardian?.phone}
            />
          </Box>
        )}
      </Box>
      <Box display='flex' alignItems='flex-start'>
        <UniCheckbox
          onChange={e => {
            if (!!e.target.checked) setShowTermsError(false);
            setTerms(e.target.checked);
          }}
          checked={terms}
        />
        <UniTypography variant='subheader' color='typographyGray100' style={{ marginTop: '7px' }}>
          {t('terms.text_part1')}
          <a href={privacyDoc} target='_blank' style={{ color: Colors.Primary._100, textDecoration: 'none' }}>
            {t('terms.link')}
          </a>
          {t('terms.text_part2')}
        </UniTypography>
      </Box>
      <Fade in={showTermsError} unmountOnExit>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {t('form.errors.terms')}
          </UniTypography>
        </Error>
      </Fade>
      <Box display='flex' alignItems='flex-start'>
        <UniCheckbox
          onChange={e => {
            setNewsletter(e.target.checked);
          }}
          checked={newsletter}
        />
        <UniTypography variant='subheader' color='typographyGray100' style={{ marginTop: '7px' }}>
          {t('newsletter')}
        </UniTypography>
      </Box>
      <Box>
        <Fade in={!!onboardingError?.message} unmountOnExit>
          <Error>
            <StyledAlertIcon>
              <ErrorSvg />
            </StyledAlertIcon>
            <UniTypography variant='footnote' component='span' color='stateAlert100'>
              {onboardingError?.message}
            </UniTypography>
          </Error>
        </Fade>
      </Box>
      <StyledUniCardFooter
        variants='M'
        type='labeled'
        SecondaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            variant='text'
            size={'large'}
            color='neutral'
            fullWidth={false}
            onClick={prevStep}
          >
            {tButtons('previous')}
          </UniButton>
        }
        PrimaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            size={'large'}
            color='primary'
            fullWidth={false}
            loading={isLoading}
            onClick={onSubmit}
          >
            {tButtons('save')}
          </UniButton>
        }
      />
    </>
  );
};

export default Step8;
