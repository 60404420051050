import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import UniTab from '../uniTab/UniTab';

interface Tab {
  title: string;
  subtitle?: string;
  Icon?: React.ReactNode;
}

interface Props {
  tabs: Tab[];
  setTabValue: Function;
  tabValue: number;
}

export default function UniModalDrawerTabs({ setTabValue, tabValue, tabs }: Props): ReactElement {
  return (
    <Box display='flex' style={{ gap: '15px' }} pt={3} pl={3}>
      {tabs.map((item, index) => (
        <UniTab
          isActive={tabValue === index}
          setTab={() => setTabValue(index)}
          Icon={item?.Icon && item.Icon}
          title={item.title}
          size='large'
          direction='row'
          subtitle={item.subtitle}
          key={index}
        />
      ))}
    </Box>
  );
}
