import { UniButton, UniCardWrapper, UniIconWrapper, UniTypography } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as PhoneIcon } from 'assets/svg/Icons/PhoneIcon.svg';
import UniTutorList from 'components/tutorList/UniTutorList';
import { landingPageTutors } from 'constants/tutors';
import { Colors, Shadows } from 'constants/uiConfig';
import { useBreakpoints } from 'hooks';

const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const StyledCounsellorCard = styled(UniCardWrapper)`
  display: flex;
  flex-direction: column;
  box-shadow: ${Shadows.shadow1};
  max-width: ${({ theme }) => theme.spacing(76)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4.5)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-height: ${({ theme }) => theme.spacing(48)}px;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)}px;
  }
`;

const StyledInfoWrapper = styled.div<{ avatar?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 134px;
  align-items: ${({ avatar }) => (avatar ? 'center' : 'flex-start')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: ${({ avatar }) => (avatar ? 'row' : 'column')};
  }
`;

const StyledCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

const StyledCardFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${Colors.Promoted.Uni_70};
`;

interface Props {}

const Counsellors: React.FC<Props> = ({ children, ...rest }) => {
  const { t } = useTranslation('landingHome');
  const { isMobile } = useBreakpoints();
  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <a href='https://meetings.hubspot.com/daniel-posdarascu' style={{ textDecoration: 'none' }}>
          <UniButton
            startIcon={
              <UniIconWrapper size='main' color='white100'>
                <PhoneIcon />
              </UniIconWrapper>
            }
            color='primary'
          >
            {t('buttons.call')}
          </UniButton>
        </a>
      </div>
      <UniTutorList tutorsToDisplay={landingPageTutors} />
      {/*<StyledLayout>*/}
      {/*  {counsellors.map(counsellor => (*/}
      {/*    <StyledCounsellorCard size={isMobile ? 'S' : 'L'} border>*/}
      {/*      <StyledCardHeader>*/}
      {/*        <StyledInfoWrapper avatar>*/}
      {/*          <UniAvatar src={counsellor.image} alt={counsellor.name} size={isMobile ? 'M' : 'XL'} />*/}
      {/*          <UniTypography variant='header3' weight='bold' color='secondary100'>*/}
      {/*            {counsellor.name}*/}
      {/*          </UniTypography>*/}
      {/*          <UniTypography variant='body2' color='secondary100'>*/}
      {/*            {counsellor.title}*/}
      {/*          </UniTypography>*/}
      {/*        </StyledInfoWrapper>*/}
      {/*        <StyledInfoWrapper>*/}
      {/*          <UniTypography variant='footnote'>How can we help?</UniTypography>*/}
      {/*          <UniTypography variant='body2' weight='medium' style={{ paddingBottom: '7px' }}>*/}
      {/*            {counsellor.text1}*/}
      {/*          </UniTypography>*/}
      {/*          <UniTypography variant='footnote'>Recommendation about The Netherlands</UniTypography>*/}
      {/*          <UniTypography variant='body2' weight='medium'>*/}
      {/*            {counsellor.text2}*/}
      {/*          </UniTypography>*/}
      {/*        </StyledInfoWrapper>*/}
      {/*      </StyledCardHeader>*/}
      {/*      /!*<StyledCardFooter>*!/*/}
      {/*      /!*  <UniTypography color='primary100' weight='medium'>*!/*/}
      {/*      /!*    See our packages*!/*/}
      {/*      /!*  </UniTypography>*!/*/}
      {/*      /!*  <UniButton*!/*/}
      {/*      /!*    size='small'*!/*/}
      {/*      /!*    thin*!/*/}
      {/*      /!*    startIcon={*!/*/}
      {/*      /!*      <UniIconWrapper size='main' color='white100'>*!/*/}
      {/*      /!*        <PhoneIcon />*!/*/}
      {/*      /!*      </UniIconWrapper>*!/*/}
      {/*      /!*    }*!/*/}
      {/*      /!*  >*!/*/}
      {/*      /!*    Book a meeting*!/*/}
      {/*      /!*  </UniButton>*!/*/}
      {/*      /!*</StyledCardFooter>*!/*/}
      {/*    </StyledCounsellorCard>*/}
      {/*  ))}*/}
      {/*</StyledLayout>*/}
    </>
  );
};

export default Counsellors;
