import { ReactComponent as ArrowUp } from 'assets/svg/Icons/Arrow/Up.svg';
import { ReactComponent as CloseX } from 'assets/svg/Icons/CloseX.svg';
import { UniIconWrapper, UniStep } from 'components';
import { useBreakpoints } from 'hooks';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'store';
import InfoCard from './InfoCard';
import { MovingIconWrapper, StepperWrapper, StepsWrapper } from './UniStepper.style';

interface Step {
  title: string;
  completed: boolean;
  texts: string[];
  buttonText: string;
  route: any;
}

interface Props {
  steps: Step[];
  onClose: Function;
  completedPostOnboarding?: boolean;
  allStepsCompleted?: boolean;
}

const UniStepper: React.FC<Props> = ({ steps, onClose, completedPostOnboarding, allStepsCompleted, children, ...rest }) => {
  const { isMobile } = useBreakpoints();
  const [visibleInfoCard, setVisibleInfoCard] = useState<number | undefined>(undefined);
  const [collapsed, setCollapsed] = useState(completedPostOnboarding || false);
  const { closeSnackbar } = useSnackbar();

  const hideHandler = () => {
    !isMobile && setCollapsed(prevState => !prevState);
    setVisibleInfoCard(undefined);
  };

  const closeHandler = () => {
    hideHandler();
  };

  const clickHandler = (index: number) => {
    if (visibleInfoCard === index || collapsed === true) setVisibleInfoCard(undefined);
    else setVisibleInfoCard(index);
  };

  const variants = { open: { y: '-44px' } };

  useEffect(() => {
    allStepsCompleted && onClose() && closeSnackbar();
  }, [allStepsCompleted]);

  return (
    <StepperWrapper>
      {isMobile && visibleInfoCard !== undefined && (
        <InfoCard
          onHide={hideHandler}
          title={steps[visibleInfoCard].title}
          texts={steps[visibleInfoCard].texts}
          buttonText={steps[visibleInfoCard].buttonText}
          route={steps[visibleInfoCard].route}
          isVisible={true}
        />
      )}
      <StepsWrapper animate={collapsed ? 'closed' : 'open'} variants={isMobile ? undefined : variants}>
        {steps.map((step, index) => (
          <UniStep
            title={step.title}
            completed={step.completed}
            texts={step.texts}
            buttonText={step.buttonText}
            index={index + 1}
            route={step.route}
            onClick={() => clickHandler(index)}
            visibleInfoCard={visibleInfoCard === index}
            collapsed={collapsed}
            key={`${step.title}${step.completed}`}
          />
        ))}
        <MovingIconWrapper>
          <UniIconWrapper color='typographyGray100' size={isMobile ? 'main' : 'xsmall'} onClick={closeHandler}>
            {isMobile ? <CloseX /> : collapsed ? <ArrowUp /> : <CloseX />}
          </UniIconWrapper>
        </MovingIconWrapper>
      </StepsWrapper>
    </StepperWrapper>
  );
};

export default UniStepper;
