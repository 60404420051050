import { ListItem, ListItemProps } from '@material-ui/core';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import styled from 'styled-components';
import { Colors } from '../../../constants';
import { theme } from '../../../theme';

interface StyledListItemProps extends ListItemProps {
  component?: React.ElementType;
  open: boolean;
}

export const StyledListItem = styled(ListItem)<StyledListItemProps>`
  position: relative;
  justify-items: start;
  border-left: 1px solid transparent;
  transition: background 200ms ease-in-out;
  padding: ${({ theme }) => theme.spacing(0, 0, 0, 1.5)};
  width: calc(100% + ${theme.spacing(7.5)}px);
  margin: ${theme.spacing(0, -4.5)};
  border-left: 1px solid ${({ theme, selected }) => (selected ? Colors.White._100 : 'transparent')};

  &::after {
    content: '';

    transition: opacity 300ms ease-in-out;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => Colors.White._100};
    opacity: ${({ selected }) => (selected ? 0.1 : 0)};
  }

  &:hover {
    border-left: 1px solid ${Colors.White._100};
  }
`;

export const StyledUniModalDrawerContent = styled(UniModalDrawerContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
