import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { StyledSuccessIllustration } from 'components/_drawers/assignAgent/AssignAgent.style';
import React from 'react';

interface Inputs {}

interface Props {
  event?: Components.Schemas.EventResponse;
}

const Step6: React.FC<Props> = ({ event }) => {
  return (
    <>
      <Box mb={2}>
        <UniTypography variant='header3' color='secondary100' weight='semi-bold'>
          Your seat is confirmed for {event?.name}!
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='subheader' color='typographyBlack100' weight='regular'>
          Your participance has also been added as a to-do item in your Activity plan. After the event, it will turn into an achievement.
        </UniTypography>
      </Box>
      <Box width='100%' display='flex' justifyContent='center' mt={8}>
        <StyledSuccessIllustration />
      </Box>
    </>
  );
};

export default Step6;
