import { Box, Grow, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { ReactComponent as MenuDots } from '../../../assets/svg/Icons/MenuDots.svg';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import UniTypography from '../uniTypography/UniTypography';
import { StyledButton, StyledMenu } from './UniLink.style';

//TODO add external link, and maybe add some cool effect to link element?

export type Size = 'small' | 'main' | 'medium';

interface UniLinkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size: Size;
  shouldGrow?: boolean;
  IconLeft?: React.ReactNode;
  Options?: { onClick: Function; label: string }[];
  label?: string;
  selected?: boolean;
  alert?: boolean;
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const UniLink: React.FC<UniLinkProps> = ({
  size,
  IconLeft,
  Options,
  label,
  selected,
  alert,
  fullWidth,
  shouldGrow,
  onClick,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowOptions(false);
  };

  return (
    <StyledButton
      onClick={onClick}
      fullWidth={fullWidth}
      size={size}
      onMouseEnter={() => {
        Options && setShowOptions(true);
      }}
      onMouseLeave={() => setShowOptions(false)}
      disableRipple
      startIcon={
        !!IconLeft ? (
          <Box mr={0.5} display='flex' alignItems='center'>
            <UniIconWrapper
              size={size === 'small' ? 'main' : 'medium'}
              color={selected ? 'primary100' : alert ? 'stateAlert100' : 'typographyGray100'}
            >
              {IconLeft}
            </UniIconWrapper>
          </Box>
        ) : (
          <></>
        )
      }
      endIcon={
        <div style={{ visibility: `${showOptions ? 'visible' : 'hidden'}` }}>
          <UniIconWrapper size={size === 'small' ? 'main' : 'medium'} color='typographyGray50' onClick={handleClick}>
            <MenuDots />
          </UniIconWrapper>

          <StyledMenu
            open={!!anchorEl}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {Options?.map(item => (
              <MenuItem
                onClick={() => {
                  handleClose();
                  item.onClick();
                }}
              >
                <UniTypography color='typographyGray100' variant={size === 'small' ? 'subheader' : size === 'main' ? 'body2' : 'body1'}>
                  {item.label}
                </UniTypography>
              </MenuItem>
            ))}
          </StyledMenu>
        </div>
      }
    >
      {shouldGrow !== undefined ? (
        <Grow in={shouldGrow} timeout={500} unmountOnExit>
          <UniTypography
            color={selected ? 'primary100' : alert ? 'stateAlert100' : 'typographyGray100'}
            variant={size === 'small' ? 'subheader' : size === 'main' ? 'body2' : 'body1'}
            weight='regular'
          >
            {label}
          </UniTypography>
        </Grow>
      ) : (
        <UniTypography
          color={selected ? 'primary100' : alert ? 'stateAlert100' : 'typographyGray100'}
          variant={size === 'small' ? 'subheader' : size === 'main' ? 'body2' : 'body1'}
          weight='regular'
        >
          {label}
        </UniTypography>
      )}
    </StyledButton>
  );
};
