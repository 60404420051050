import { TComponentColors } from '../../../constants/componentColors';
import { UniTypographyProps } from '../uniTypography/UniTypography';
import { StyledUniTypography } from './UniTypographyHighlight.style';

interface Props extends UniTypographyProps {
  backgroundColor?: TComponentColors;
}

const UniTypographyHighlight: React.FC<Props> = ({ backgroundColor = 'otherYellow100', ...rest }) => {
  return <StyledUniTypography backgroundColor={backgroundColor} {...rest} />;
};

export default UniTypographyHighlight;
