import styled from 'styled-components';
import { Shadows } from '../../constants';
import { UniCardFooter } from '../_common/uniCardFooter/UniCardFooter';
import UniCardWrapper from '../_common/uniCardWrapper/UniCardWrapper';

export const StudentCardWrapper = styled(UniCardWrapper)`
  max-width: ${({ theme }) => theme.spacing(38)}px;
  max-height: ${({ theme }) => theme.spacing(24)}px;
  padding: ${({ theme }) => theme.spacing(4, 3, 0)};
  box-shadow: ${Shadows.shadow1};
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: ${({ theme }) => theme.spacing(32.75)}px;
    max-height: ${({ theme }) => theme.spacing(24)}px;
    padding: ${({ theme }) => theme.spacing(4, 2, 0)};
  }
`;

export const StudentCardFooter = styled(UniCardFooter)`
  margin-left: ${({ theme }) => theme.spacing(-3)}px;
  width: ${({ theme }) => `calc(100% + ${theme.spacing(6)}px)`};
  border-bottom-left-radius: ${({ theme }) => theme.spacing(1.5)}px;
  border-bottom-right-radius: ${({ theme }) => theme.spacing(1.5)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: ${({ theme }) => theme.spacing(-2)}px;
    width: ${({ theme }) => `calc(100% + ${theme.spacing(4)}px)`};
    border-bottom-left-radius: ${({ theme }) => theme.spacing(1)}px;
    border-bottom-right-radius: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const GapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)}px;
`;
