import React, { useEffect, useRef, useState } from 'react';
import useOrder from 'api/orders/useOrder';
import usePostOrder from 'api/orders/usePostOrder';
import { Components } from 'client/UniClient';
import { UniButton } from '../index';
import { useHistory } from 'react-router-dom';
import { BrowserRoutes } from '../../constants';

type PrepPackDto = Components.Schemas.PrepPackDto;
type OrderDto = Components.Schemas.OrderDto;

interface Props {
  prepPacks: PrepPackDto[];
}

const Order: React.FC<OrderDto> = ({ id, paymentLink }) => {
  const { refetch } = useOrder(id!);
  const [paymentStatus, setPaymentStatus] = useState('IDLE');
  const history = useHistory();
  const intervalRef = useRef<any>(null);

  function stopPolling() {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }

  function initializePayment() {
    if (intervalRef.current != null) {
      stopPolling();
    }
    window.open(paymentLink);
    setPaymentStatus('PENDING');
    intervalRef.current = setInterval(() => {
      refetch().then(data => {
        if (data?.status === 'PAYMENT_CONFIRMED') {
          setPaymentStatus('SUCCESS');
          stopPolling();
        } else if (data?.status === 'PAYMENT_FAILED') {
          setPaymentStatus('ERROR');
          stopPolling();
        }
      });
    }, 2000);
  }

  useEffect(() => {
    initializePayment();
    return () => {
      stopPolling();
    };
  }, []);

  if (paymentStatus === 'SUCCESS') {
    return (
      <>
        <div>Payment successful!</div>
        <UniButton color='primary' onClick={() => {
          history.push(BrowserRoutes.Student.PrepCenter.Dashboard);
        }}>
          Return to dashboard
        </UniButton>
      </>
    );
  }

  if (paymentStatus === 'ERROR') {
    return (
      <>
        <div>Payment failed</div>
        <UniButton color='secondary' onClick={() => initializePayment()}>
          Retry payment
        </UniButton>
      </>
    );
  }
  return (
    <>
      <div>Waiting for payment confirmation...</div>
      <div>If the payment page did not open, use the button below to open it manually</div>
      <UniButton color='secondary' onClick={() => initializePayment()}>
        Open payment link
      </UniButton>
    </>
  );
};

export const UniPrepCenterPurchaseOrder: React.FC<Props> = ({ prepPacks }) => {
  const [addOrder] = usePostOrder();
  const totalPrice = prepPacks
    .map(prepPack => prepPack.price.amount)
    .reduce((sum, value) => sum + value, 0);
  const [order, setOrder] = useState<OrderDto | null>(null);
  const [orderStatus, setOrderStatus] = useState('IDLE');

  function createOrder() {
    setOrderStatus('PENDING');
    addOrder({ prepPackIds: prepPacks.map(prepPack => prepPack.id!) }, {
      onSuccess(res) {
        setOrderStatus('SUCCESS');
        setOrder(res);
      },
      onError(err) {
        console.log(err);
        setOrderStatus('ERROR');
      }
    }).then();
  }


  return (
    <>
      <div>You are going to purchase the following packages:</div>
      <ul>
        {prepPacks.map(prepPack => (
          <li>{prepPack.sessions}x {prepPack.product?.name}: {prepPack.price.amount} {prepPack.price.currencyIso}</li>
        ))}
      </ul>
      <div>Total price: {totalPrice} {prepPacks[0].price.currencyIso}</div>
      {order == null ? (
        <UniButton
          color='primary'
          disabled={orderStatus !== 'IDLE'}
          onClick={createOrder}
        >
          Create order
        </UniButton>
      ) : (
        <Order {...order} />
      )}
    </>
  );
};

export default UniPrepCenterPurchaseOrder;
