import { QueryConfig, useQuery } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

const useIncompleteQuizzes = (config?: QueryConfig<any>) => {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Quizzes.Incomplete],
    async () => {
      if (!client) return;
      const { data } = await client.QuizApi_getIncompleteQuiz();
      return data;
    },
    { ...config, refetchOnWindowFocus: false },
  );
};

export default useIncompleteQuizzes;
