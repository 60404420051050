import styled from 'styled-components';
import { Colors, Shape } from '../../constants';

export const StyledPopup = styled.div<{ isSuccess?: boolean }>`
  padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(3)}px;
  background-color: ${Colors.Typography.Gray_10};
  position: relative;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  justify-content: center;
  border-radius: ${Shape.BorderRadius.md}px;
`;
