import { Box, Tabs, useMediaQuery } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import LandingPrograms from 'views/_landing/programs/LandingPrograms';
import { UniNavigationTab, UniTypography } from '../../../components';
import { BrowserRoutes } from '../../../constants';
import { OutlinedUniButton, StyledHeaderWrapper, StyledHomeWrapper, TimesLogo, UniLogo } from './Home.style';
import HomeLanding from './HomeLanding';

export default function Home(): ReactElement {
  return (
    <StyledHomeWrapper>
      <StyledHeaderWrapper>
        <Box display='flex' alignItems='center'>
          <UniLogo />
          <Box ml={2}>
            <TimesLogo />
          </Box>
        </Box>
        <NavActions />
      </StyledHeaderWrapper>
      <Switch>
        <Route path={BrowserRoutes.Partners.THE.Home}>
          <HomeLanding />
        </Route>
        <Route path={BrowserRoutes.Partners.THE.Programs}>
          <LandingPrograms hideNavigation />
        </Route>
      </Switch>
    </StyledHomeWrapper>
  );
}

function NavActions(): ReactElement {
  const history = useHistory();
  const { url } = useRouteMatch();
  const isMobile = useMediaQuery('(max-width: 800px)');

  const handleChange = (event: any, value: string) => {
    history.push(value);
  };
  return (
    <Box display='flex' alignItems='center' justifyContent='flex-end'>
      <Tabs value={url} onChange={handleChange} indicatorColor='primary' textColor='primary' style={{ minHeight: 0 }}>
        <UniNavigationTab label='Home' value={BrowserRoutes.Partners.THE.Home} key={`home-${url}`} />
        <UniNavigationTab label='Programs' value={BrowserRoutes.Partners.THE.Programs} key={`programs-${url}`} />
      </Tabs>
      {!isMobile && (
        <Box ml={2}>
          <OutlinedUniButton fullWidth={false} onClick={() => history.push(BrowserRoutes.Default.Auth.Login)}>
            <UniTypography color='primary100' weight='medium'>
              Get Started
            </UniTypography>
          </OutlinedUniButton>
        </Box>
      )}
    </Box>
  );
}
