import { FormControl, FormControlProps, TextareaAutosize, TextareaAutosizeProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../constants';

interface StyledFormControlProps {
  hasLabel: boolean;
  backgroundColor?: any;
}

export const Container = styled(
  ({ hasLabel, backgroundColor, ...rest }: StyledFormControlProps & Omit<FormControlProps, keyof StyledFormControlProps>) => (
    <FormControl {...rest} />
  ),
)`
  display: grid;
  justify-items: start;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  min-height: ${({ hasLabel }) => (hasLabel ? '104px' : '60px')};
  grid-template-rows: ${({ hasLabel }) => (hasLabel ? '24px 1fr' : '1fr')};
`;

export const Label = styled.div`
  max-height: 22px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledIcon = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  width: ${({ theme }) => theme.spacing(3)}px;
  height: ${({ theme }) => theme.spacing(3)}px;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};

  path {
    fill: ${Colors.Typography.Gray_30};
  }
  color: ${Colors.Typography.Gray_30};
`;

interface TextareaAutosizeCustomProps extends TextareaAutosizeProps {}

export const StyledTextareaAutosize = styled(({ ...props }: TextareaAutosizeCustomProps) => <TextareaAutosize {...props} />)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2, 3)};
  outline: none;
  font-family: Poppins, sans-serif;
  color: ${Colors.Typography.Gray_100};
  resize: vertical;
  height: auto;
  max-height: 500px;
  min-height: 150px;

  &::placeholder {
    color: ${Colors.Typography.Gray_50};
  }

  &:empty::before {
    content: attr(placeholder);
    color: ${Colors.Typography.Gray_50};
  }

  &:not(:empty) {
    min-height: auto;
  }
`;
