import styled from 'styled-components';

export const StyledContinentTileButton = styled.div<{}>`
  color: white;
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0;

  .action {
    margin-right: 10px;
  }

  .arrow-right {
    vertical-align: middle;
  }

  .arrow-right > path {
    fill: white;
  }
`;
