import { Box, Fade } from '@material-ui/core';
import { UniTypography } from 'components';
import { Container, Error, Label, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import { TComponentColors } from 'constants/componentColors';
import { useMemo } from 'react';
import Select, { GroupBase, Props } from 'react-select';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import { Colors } from '../../../constants';
import selectStyles from './UniSelect.style';
interface UniSelectProps {
  optional?: boolean;
  label?: string;
  backgroundColor?: any;
  topRightArea?: React.ReactNode;
  color?: TComponentColors;
  error?: string;
}

function UniSelect<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  optional,
  label,
  backgroundColor,
  topRightArea,
  color = 'typographyBlack100',
  error,
  ...props
}: Props<Option, IsMulti, Group> & UniSelectProps) {
  const styles = useMemo(
    () => ({
      ...(selectStyles as any),
      control: (provided: any) => ({
        ...provided,
        // height: 56,
        borderRadius: 8,
        borderColor: !!error ? Colors.State.Alert_100 : Colors.Border.Gray_100,
        boxShadow: 'none',
        ':hover': {
          borderColor: !!error ? Colors.State.Alert_100 : Colors.Border.Gray_100,
        },
      }),
      container: (provided: any, state: any) => ({
        ...provided,
        width: '100%',
        flex: 1,
        borderColor: !!error ? Colors.State.Alert_100 : state.isFocused ? Colors.Primary._100 : Colors.Border.Gray_100,
        fontFamily: 'Poppins',
      }),
    }),
    [error],
  );

  return (
    <Container backgroundColor={backgroundColor} hasLabel={!!label}>
      {!!label && (
        <Label>
          <UniTypography variant='subheader' color={color} weight='medium'>
            {label}
          </UniTypography>
          {optional && (
            <UniTypography variant='footnote' color='typographyGray70'>
              Optional
            </UniTypography>
          )}
          {topRightArea && (
            <Box display='flex' alignItems='center'>
              {topRightArea}
            </Box>
          )}
        </Label>
      )}
      <Select {...props} styles={styles} />
      <Fade in={!!error} unmountOnExit>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {error}
          </UniTypography>
        </Error>
      </Fade>
    </Container>
  );
}

export default UniSelect;
