import { Box } from '@material-ui/core';
import { Computer } from '@material-ui/icons';
import { useFavoriteProgram } from 'api';
import { Components } from 'client/UniClient';
import { UniButton, UniCardWrapper, UniChip, UniIconWrapper } from 'components';
import { useBreakpoints } from 'hooks';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { parseAddress } from 'utils/programUtils';
import { BrowserRoutes } from '../../../constants';
import UniTypography from '../uniTypography/UniTypography';
import { StyledUniCardFooter } from './UniProgramCard.style';

interface Props {
  program: Components.Schemas.ProgramResponse;
  showUniName?: boolean;
  showStatus?: boolean;
  flexGrouped?: boolean;
  noStatus?: boolean;
  fit?: boolean;
  isPromoted?: boolean;
  metricsArr: { label: string; value: string }[];
  onClick?: () => void;
}

const UniProgramCard = ({
  program,
  showUniName,
  fit = false,
  showStatus,
  flexGrouped = false,
  noStatus,
  isPromoted = false,
  metricsArr,
  onClick,
}: Props) => {
  const { t } = useTranslation('programsList');
  const { isMobile } = useBreakpoints();
  const [favoriteProgram] = useFavoriteProgram();
  const { role } = useAuth(state => state.profile);
  const { isStudent, isAgency, isMaster, isAdmin, isUniversity } = getMetaRole(role!);
  const onFavoriteProgram = () => favoriteProgram({ isFavorite: !!program?.isFavorite, programId: program.id! });
  const history = useHistory();

  const goToProgramPage = () => {
    if (onClick) {
      onClick();
      return;
    }
    isAgency
      ? history.push(BrowserRoutes.Agency.Program.General.replace(':programId', program.id as string))
      : isStudent
      ? history.push(BrowserRoutes.Student.Program.General.replace(':programId', program.id as string))
      : isAdmin
      ? history.push(BrowserRoutes.Admin.Program.Applications.replace(':programId', program.id as string))
      : isMaster
      ? history.push(BrowserRoutes.Admin.Program.ProgramInfo.replace(':programId', program.id as string))
      : isUniversity
      ? history.push(BrowserRoutes.University.Program.ProgramInfo.replace(':programId', program.id as string))
      : history.push(BrowserRoutes.Agent.Program.General.replace(':programId', program.id as string));
  };
  return (
    <UniCardWrapper size='L' border fit={fit} hoverable onClick={goToProgramPage} isPromoted={isPromoted}>
      {!!program?.blockAdmission && (
        <Box position='absolute' pl={1} top='-12px'>
          <UniChip label={'Applications closed'} color='red' />
        </Box>
      )}
      <Box
        pt={4}
        pl={3}
        pr={3}
        pb={10}
        display='grid'
        alignItems='start'
        width={flexGrouped && isMobile ? 'calc(100vw - 102px)' : 'inherit'}
      >
        <UniTypography
          variant='body2'
          color='typographyBlack100'
          weight='semi-bold'
          style={{
            height: '48px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
          }}
        >
          {program.name}
        </UniTypography>
        {showUniName && (
          <Box mt={1}>
            <UniTypography
              variant='subheader'
              color='secondary100'
              weight='medium'
              style={{
                height: '40px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
              }}
            >
              {program.universityName}
            </UniTypography>
          </Box>
        )}
        <Box mt={2} mb={3}>
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            {parseAddress(program?.address)}
          </UniTypography>
          <Box display='flex' alignItems='center'>
            {program?.address?.city?.includes('Online') && (
              <UniIconWrapper color='primary100'>
                <Computer />
              </UniIconWrapper>
            )}
            <UniIconWrapper>
              {program?.address?.country && <ReactCountryFlag countryCode={program.address.country} svg></ReactCountryFlag>}
            </UniIconWrapper>
          </Box>
        </Box>
        {metricsArr?.map(item => (
          <Box display='grid' mb={2}>
            <UniTypography variant='footnote' color='typographyGray100' weight='regular'>
              {item?.label}
            </UniTypography>
            <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
              {item?.value}
            </UniTypography>
          </Box>
        ))}
      </Box>
      <StyledUniCardFooter
        type='baseUnlabeledStatus'
        variants='XS'
        status={(showStatus || isStudent) && !noStatus && !!program.application?.status ? program.application?.status : undefined}
        isFavorite={isStudent ? program.isFavorite : undefined}
        onFavorite={isStudent ? onFavoriteProgram : undefined}
        isPromoted={isPromoted}
        PrimaryButton={
          <UniButton variant={isPromoted ? 'secondary' : 'main'} color='primaryFade' size='small' onClick={goToProgramPage}>
            {isMaster ? 'View programme' : t('headers.view')}
          </UniButton>
        }
      />
    </UniCardWrapper>
  );
};

export default UniProgramCard;
