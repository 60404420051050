import styled from "styled-components";

export const MetricTabs = styled.div`
  max-width: max(500px, calc(100% - 350px));
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)}px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    justify-content: space-between;
  }
`;
export const Metrics = styled.div`
  margin: ${({ theme }) => theme.spacing(4, 0)};
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;

  ${({ theme }) => theme.breakpoints.down(990)} {
    overflow-x: scroll;
  }
`;

export const MetricItem = styled.div`
  min-width: ${({ theme }) => theme.spacing(28)}px;
`;
