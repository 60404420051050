import { QueryConfig, useQuery } from "react-query";
import { CacheKeys } from "../../constants";
import { useClient } from "../../store";



export default function useGetStudentNames(
 
  config?: QueryConfig<Error>
) {
  //   const agencyId = useAuth((state) => state.profile.agentProfile?.agency?.id);
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Admin.Candidates],
    async (_key: string) => {
      const { data } = await client!.StudentApi_getStudentNames();

      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
