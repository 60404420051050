import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UniButton, UniHeader, UniHerosection, UniPage } from 'components';
import TaskList from 'components/taskList/TaskList';
import { useAuth, useDrawers } from 'store';

export const AgentHome: React.FC = () => {
  const { t } = useTranslation('studentDashboard');
  const { profile } = useAuth();
  const { openDrawer, progress }= useDrawers();
  useEffect(() => {
    if (progress === 100) {
      window.location.reload();
    }
  }, [progress]);
  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='title'
          title={t('title')}
          filterbarHeadline='Filter tasks'
          withFilterBar
          filterbarVariant='tasks'
          withCandidateFilter
          overflow='visible'
        />
      </UniHeader>
      <UniPage>
        <TaskList userType='agent' userId={profile.id!} applicationId="" />
      </UniPage>
    </>
  );
}

export default AgentHome;
