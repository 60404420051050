import ComponentColors, { TComponentColors } from 'constants/componentColors';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import { theme } from 'theme';
import { Colors } from '../../../constants';

export const StyledCarousel = styled(Slider)<{ fadeColor?: TComponentColors }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  max-width: 100%;

  .slick-list {
    width: 100%;
    order: -1;

    ${({ fadeColor }) =>
      fadeColor &&
      `&:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      width: ${theme.spacing(3)}px;
      top: 0;
      height: 100%;
      pointer-events: none; /*makes the linkes behind clickable.*/
    }
    &:before {
      left: 0;
      background: linear-gradient(
        to right,
        ${fadeColor ? ComponentColors[fadeColor] : Colors.Secondary._100},
        rgba(0, 0, 0, 0)
      );
    }
    &:after {
      right: 0;
      background: linear-gradient(
        to left,
        ${fadeColor ? ComponentColors[fadeColor] : Colors.Secondary._100},
        rgba(0, 0, 0, 0)
      );
    }`}
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }

  .slick-dots {
    order: -1;
    position: relative;
    display: flex;
    height: 100%;
    bottom: 0;
    ul {
      margin: 0;
      padding: 0;
      height: 100%;
    }
    li.slick-active {
      button {
        &:before {
          color: white;
          opacity: 1;
          border-radius: 20%;
        }
      }
    }
  }
  .slick-dots {
    li {
      button {
        &:before {
          color: white;
          opacity: 0.2;
          font-size: 8px;
        }
      }
    }
  }
  .slick-slide div {
    height: 100%;
  }
`;

export const StyledUniCarouselSlideWrapper = styled.div`
  padding: ${theme.spacing(3)}px;
  display: grid;
  max-height: 100%;
  grid-template-rows: 2fr 1fr;
`;
