import { useBreakpoints } from 'hooks';
import React, { useEffect, useState } from 'react';
import { FixedDrawerNavigation, FooterBarNavigation, HeaderNavigation } from '../../components';
import { useAuth } from '../../store';
import { getMetaRole } from '../../utils';
import { adminLinks, agencyLinks, agentLinks, highSchoolLinks, masterLinks, studentLinks, universityLinks } from './links';

export type NavigationLink = {
  path: string;
  name?: string;
  icon?: JSX.Element | string;
  isMobile?: boolean;
  isAvatar?: boolean;
  defaultPath?: string;
};

interface Props {
  setStretchContent: Function;
}

export const UniNavigation: React.FC<Props> = ({ setStretchContent }) => {
  const isLoggedIn = useAuth(state => !!state?.profile?.id);
  const { role } = useAuth(state => state.profile);
  const { isMobile } = useBreakpoints();
  const [links, setLinks] = useState<NavigationLink[]>([]);
  const { isMaster, isAdmin, isHighSchool, isAgency, isAgent, isStudent, isUniversity } = getMetaRole(role);

  useEffect(() => {
    if (isAgency) {
      setLinks(agencyLinks);
    } else if (isAgent) {
      setLinks(agentLinks);
    } else if (isStudent) {
      setLinks(studentLinks);
    } else if (isMaster) {
      setLinks(masterLinks);
    } else if (isAdmin) {
      setLinks(adminLinks);
    } else if (isHighSchool) {
      setLinks(highSchoolLinks);
    } else if (isUniversity) {
      setLinks(universityLinks);
    }
  }, [role]);

  const stretchHandler = (state: boolean) => {
    setStretchContent(state);
  };

  return (
    <>
      <HeaderNavigation />
      {!isMobile && isLoggedIn && <FixedDrawerNavigation setStretchContent={stretchHandler} links={links} />}
      {isMobile && isLoggedIn && <FooterBarNavigation variant='predefined' links={links} />}
    </>
  );
};
