import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import useOwnOrders from 'api/orders/useOwnOrders';
import useOwnPurchasedPrepPacks from 'api/preppacks/useOwnPurchasedPrepPacks';
import { AnimatedTabPanel, UniHeader, UniHerosection, UniPage } from 'components';
import UniPrepCenterOrders from 'components/prepCenterOrders/UniPrepCenterOrders';
import UniPrepCenterSessionsHistory from 'components/prepCenterSessionsHistory/UniPrepCenterSessionsHistory';
import UniPurchasedPrepPacksList from 'components/purchasedPrepPacksList/UniPurchasedPrepPacksList';
import { BrowserRoutes } from '../../../constants';

interface Props {}

export const StudentPrepCenterSessionsPage: React.FC<Props> = () => {
  const { data: purchasedPrepPacks } = useOwnPurchasedPrepPacks();
  const { data: orders } = useOwnOrders();
  const { url } = useRouteMatch();
  const { t } = useTranslation('prepCenter');
  const paidSessionsPath = BrowserRoutes.Student.PrepCenter.Sessions.Paid;
  const historyPath = BrowserRoutes.Student.PrepCenter.Sessions.History;
  const ordersPath = BrowserRoutes.Student.PrepCenter.Sessions.Orders;
  const tabsMap = [paidSessionsPath, historyPath, ordersPath];
  const currentIndex = tabsMap.findIndex(item => item === url);

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='title'
          title={t('sessions.name')}
          tabs={[
            { label: t('session_management.paid_sessions'), path: paidSessionsPath },
            { label: t('session_management.history'), path: historyPath },
            { label: t('session_management.orders'), path: ordersPath },
          ]}
        />
      </UniHeader>
      {purchasedPrepPacks && orders && (
        <UniPage>
          <AnimatePresence>
            <Switch>
              <Route path={paidSessionsPath}>
                <AnimatedTabPanel currentItem={currentIndex} index={0} Content={(
                  <UniPurchasedPrepPacksList purchasedPrepPacks={purchasedPrepPacks} />
                )} />
              </Route>
              <Route path={historyPath}>
                <AnimatedTabPanel currentItem={currentIndex} index={1} Content={(
                  <UniPrepCenterSessionsHistory purchasedPrepPacks={purchasedPrepPacks} />
                )} />
              </Route>
              <Route path={ordersPath}>
                <AnimatedTabPanel currentItem={currentIndex} index={2} Content={(
                  <UniPrepCenterOrders orders={orders} enableActions={true} />
                )} />
              </Route>
            </Switch>
          </AnimatePresence>
        </UniPage>
      )}
    </>
  );
};

export default StudentPrepCenterSessionsPage;
