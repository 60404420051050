import { QueryConfig, useInfiniteQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';
import { LevelOfStudy } from '../../constants/levelOfStudy';
import { useClient } from '../../store';
import { ApplicationStatus, Nationality } from '../../types';
import { AxiosError } from 'axios';

type Params = {
  pageSize?: number;
  newPageSize?: number;
  query?: string;
  nationality?: Nationality[];
  countryOfStudy?: Nationality[];
  levelOfStudy?: LevelOfStudy[];
  applicationStatus?: ApplicationStatus[];
  favorites?: boolean;
  agencyId?: string[];
  order?: 'ASC' | 'DESC';
  sort?: 'NAME' | 'DATE_ADDED';
};

export default function useGetAdminCandidatesNames(params: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  return useInfiniteQuery(
    [CacheKeys.Admin.Candidates, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.SearchApi_searchStudents({
        ...params,
        query: params?.query || undefined,
        pageSize: params?.newPageSize || ApiKeys.PageSize.Candidates,
        page,
      });
      return data;
    },

    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Candidates || lastPage.page * ApiKeys.PageSize.Candidates === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
