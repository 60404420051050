import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { TComponentColors } from '../../../constants/componentColors';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import UniTypography from '../uniTypography/UniTypography';
import { StyledTabWrapper } from './UniTab.style';

interface Props {
  Icon?: React.ReactNode;
  title: string;
  setTab: Function;
  subtitle?: string;
  isActive: boolean;
  size?: 'small' | 'main' | 'medium' | 'large';
  direction?: 'row' | 'column';
  disabled?: boolean;
  style?: any;
  colorInactive?: TComponentColors;
  isBold?: boolean;
}

export default function UniTab({
  Icon,
  title,
  subtitle,
  setTab,
  isActive,
  size = 'large',
  direction = 'column',
  disabled = false,
  colorInactive = 'typographyGray70',
  isBold = false,
  style,
}: Props): ReactElement {
  return (
    <StyledTabWrapper
      direction={direction}
      active={isActive}
      size={size}
      disabled={disabled}
      onClick={() => !disabled && setTab()}
      style={style}
    >
      <UniIconWrapper size={size} color={disabled ? 'typographyGray70' : isActive ? 'white100' : 'typographyGray30'}>
        {Icon}
      </UniIconWrapper>
      <Box textAlign={direction === 'row' ? 'left' : 'center'}>
        <UniTypography weight={isBold ? 'semi-bold' : 'medium'} variant='body2' color={isActive ? 'white100' : colorInactive}>
          {title}
        </UniTypography>
        <UniTypography variant='subheader' color={isActive ? 'white100' : 'typographyGray30'}>
          {subtitle}
        </UniTypography>
      </Box>
    </StyledTabWrapper>
  );
}
