import { Menu, MenuItem, MenuProps } from '@material-ui/core';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { theme } from 'theme';
import { Colors, Shadows } from '../../../../constants';

export const StyledAuthDropdown = styled(motion.div)<{ isSticky: boolean; isMobile: boolean }>`
  ${({ isMobile, isSticky }) =>
    isMobile &&
    `
  position:fixed;
  top:0;
  left:0;
  padding: ${theme.spacing(1, 2, 2, 2)};

  ${
    isSticky &&
    `
  background-color:${Colors.White._100};
  box-shadow: ${Shadows.shadow1};
  `
  }
  transition: background-color .2s;
  z-index:200;
`}

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

interface StyledMenuProps extends MenuProps {
  width?: number;
}

export const StyledMenu = styled(Menu)<StyledMenuProps>`
  margin-top: 8px;
  .MuiMenu-paper {
    width: ${({ width }) => (width ? `${width}px` : 'inherit')};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  white-space: normal;
  min-height: 78px;
  height: fit-content;
  padding: ${({ theme }) => theme.spacing(1.5, 1.375, 1.5, 2)};
  :hover {
    background-color: rgba(60, 155, 149, 0.05) !important;
  }
`;

export const ScrollableList = styled.div`
  flex: 1;
  overflow-y: scroll;
  max-height: calc(100vh - 214px);
`;

export const StyledNotificationDrawer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 0px;
`;
