import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDrawers, useFooterbar } from 'store';
import { AnimatedTabPanel, UniHeader, UniHerosection, UniPage } from '../../../components';
import { BrowserRoutes } from '../../../constants';
import AllServices from './_tabs/AllServices';
import Suggestions from './_tabs/Suggestions';

const Services = () => {
  const { url } = useRouteMatch();
  const { openDrawer } = useDrawers();
  const { setFooter, clearFooter } = useFooterbar();
  const allServicesPath = BrowserRoutes.Admin.Services.AllServices;
  const suggestionsPath = BrowserRoutes.Admin.Services.Suggestions;
  const currentRoute = url === allServicesPath ? 0 : url === suggestionsPath ? 1 : 2;
  const openAddServiceDrawer = () => openDrawer('add-new-service', { size: 'small' });

  useEffect(() => {
    setFooter({
      primaryButton: {
        label: 'Add Service',
        onClick: openAddServiceDrawer,
        color: 'primaryFade',
      },
    });
    return clearFooter;
  }, []);

  return (
    <>
      <UniHeader>
        <UniHerosection
          key={currentRoute}
          headerVariant='title'
          title='Services'
          tabs={[
            { label: 'All Services', path: allServicesPath },
            { label: 'Requests', path: suggestionsPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={BrowserRoutes.Admin.Services.AllServices}>
              <AnimatedTabPanel Content={<AllServices />} index={0} currentItem={currentRoute} absoluteDirection={-1} />
            </Route>
            <Route path={BrowserRoutes.Admin.Services.Suggestions}>
              <AnimatedTabPanel Content={<Suggestions />} index={1} currentItem={currentRoute} absoluteDirection={1} />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default Services;
