import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { useMutation, useQueryCache } from 'react-query';
import { useAuth, useClient } from 'store';
import { CacheKeys } from '../../constants';

type Payload = Components.Schemas.InviteHighSchoolStudentRequest;

const useInviteHighSchoolStudent = () => {
  const { client } = useClient();
  const { profile } = useAuth();
  const highSchoolId = profile?.highSchoolProfile?.highSchool?.id || '';
  const queryCache = useQueryCache();

  return useMutation(
    async (payload: Payload) => {
      if (!client) throw new Error('Client is not loaded');
      const { data } = await client.HighSchoolApi_inviteStudent({ highSchoolId }, payload);
      return data;
    },
    {
      onSuccess() {
        queryCache.invalidateQueries(CacheKeys.Search.Students);
      },
      onError(error: AxiosError) {},
    },
  );
};

export default useInviteHighSchoolStudent;
