import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import usePrepPacks from 'api/preppacks/usePrepPacks';
import useProduct from 'api/products/useProduct';
import { Components } from 'client/UniClient';
import UniPrepCenterProductView from 'components/prepCenterProductView/UniPrepCenterProductView';
import { BrowserRoutes } from '../../../constants';

type PrepPackDto = Components.Schemas.PrepPackDto;

interface Props {}

export const StudentPrepCenterProductPage: React.FC<Props> = () => {
  const history = useHistory();
  const { productId } = useParams<{ productId: string }>();
  const { data: product } = useProduct(productId);
  const { data: prepPacks } = usePrepPacks(productId);

  prepPacks?.sort((l, r) => {
    if (l.sessions < r.sessions) return -1;
    if (l.sessions > r.sessions) return 1;
    return 0;
  });

  function addToCart(prepPack: PrepPackDto) {
    console.log(`Add prep pack ${prepPack.id} to cart`);
    history.push(BrowserRoutes.Student.PrepCenter.CheckOut, { prepPack });
  }

  return (
    <>
      {product != null && prepPacks != null && (
        <UniPrepCenterProductView product={product} prepPacks={prepPacks} addToCart={addToCart}></UniPrepCenterProductView>
      )}
    </>
  );
};

export default StudentPrepCenterProductPage;
