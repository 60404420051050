import React from 'react';

import { UniPage, UniTypography } from '../../../components';

const AgencyApplications = () => {
  return (
    <UniPage>
      <UniTypography color='typographyBlack100' weight='semi-bold' variant='header1'>
        Agency Applications
      </UniTypography>
    </UniPage>
  );
};

export default AgencyApplications;
