import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Size } from './UniLink';
import { Colors } from '../../../constants/uiConfig';
import { Button, ButtonProps, Menu, MenuItem } from '@material-ui/core';

type StyledButtonProps = {
  size?: Size;
  children?: React.ReactNode;
};
const SizeMapHeight = {
  medium: 56,
  main: 48,
  small: 40,
} as const;

export const StyledButton = styled(({ size, children, ...rest }: StyledButtonProps & Omit<ButtonProps, keyof StyledButtonProps>) => (
  <Button {...rest}> {children} </Button>
))`
  position: relative;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing(0, 3)};
  height: ${({ size }) => SizeMapHeight[size!]}px;
  border-radius: 0;
  min-width: 200px;
  .MuiButton-endIcon {
    position: absolute;
    right: 1rem;
  }
  :hover {
    background-color: ${Colors.Gray2._40};
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    border-radius: 8px;
  }
  .MuiListItem-root {
    background-color: white;
    :hover {
      background-color: ${Colors.Gray2._40};
    }
  }
`;
