import React from 'react';
import YouniBounceLoader from '../youniBounceLoader/YouniBounceLoader';
import { StyledPageLoader } from './UniPageLoader.style';

const UniPageLoader = () => {
  return (
    <StyledPageLoader>
      <YouniBounceLoader />
    </StyledPageLoader>
  );
};
export default UniPageLoader;
