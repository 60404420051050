import { AvatarProps, Zoom } from '@material-ui/core';
import React from 'react';
import UniTypography from '../uniTypography/UniTypography';
import { AvatarContainer, StyledAvatar, StyledBadge } from './UniAvatar.style';
import UniAvatarUpload from './UniAvatarUpload';

export type Size = 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL';
export type Color = 'primary100' | 'secondary100' | 'typographyGray10';

export interface UniAvatarProps extends AvatarProps {
  online?: boolean;
  size?: Size;
  src?: string;
  alt?: string;
  color?: Color;
  label?: string;
  onUpload?: (file?: File) => void;
  customLabel?: string;
}

const UniAvatar: React.FC<UniAvatarProps> = ({
  online,
  size = 'M',
  src = '',
  alt = '',
  color = 'typographyGray10',
  variant = 'circle',
  label,
  onUpload,
  customLabel,
}) => {
  const getTextSize = (size: Size) => {
    switch (size) {
      case 'XS':
        return 'footnote';
      case 'S':
        return 'subheader';
      case 'M':
        return 'body1';
      case 'L':
        return 'header3';
      case 'XL':
        return 'header2';
    }
  };

  const getInitials = (label?: string) => {
    const names = label?.split(' ');
    if (names) {
      if (names?.length === 1) return names[0].slice(0, 2).toUpperCase();
      else {
        if (names[1] === 'of' || names[1] === 'the') return names[0].slice(0, 1).concat(names[2].slice(0, 1)).toUpperCase();
        else return names[0].slice(0, 1).concat(names[1].slice(0, 1)).toUpperCase();
      }
    }
  };

  return (
    <>
      <AvatarContainer>
        <StyledAvatar size={size} src={src} alt={alt} variant={variant} color={color}>
          {!src && (
            <UniTypography color={color === 'typographyGray10' ? 'typographyGray100' : 'white100'} variant={getTextSize(size)}>
              {getInitials(label)}
              {customLabel}
            </UniTypography>
          )}
        </StyledAvatar>
        {!!onUpload && <UniAvatarUpload onChange={onUpload} />}

        <Zoom in={online}>
          <StyledBadge size={size} />
        </Zoom>
      </AvatarContainer>
    </>
  );
};

export default UniAvatar;
