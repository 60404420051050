import { useStudentFavoritePrograms } from 'api';
import { InfiniteWindowedGrid, UniProgramCard } from 'components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import { parseLevelOfStudy } from 'utils/programUtils';
interface Props {}

const SavedPrograms: React.FC<Props> = ({ children, ...rest }) => {
  const { profile } = useAuth();
  const { t } = useTranslation('programsList');
  const {
    data: savedPrograms,
    status: savedProgramsStatus,
    fetchMore: fetchMoreSavedPrograms,
    isFetching,
  } = useStudentFavoritePrograms({ studentId: profile?.id!, agencyId: profile.studentProfile?.agent?.agentProfile?.agency?.id! });

  const savedProgramsLoading = savedProgramsStatus === 'loading';
  const savedProgramsFlat = useMemo(() => {
    return savedPrograms?.map(progs => progs.results).flat() || [];
  }, [savedPrograms, savedProgramsStatus]);
  const savedProgramsTotal = useMemo(() => (savedPrograms ? savedPrograms[0].total : 0), [savedPrograms]);
  const savedProgramsLeft = useMemo(() => savedProgramsTotal - savedProgramsFlat.length, [savedProgramsFlat, savedProgramsTotal]);

  return (
    <InfiniteWindowedGrid
      fetchMore={fetchMoreSavedPrograms}
      emptyStateType='saved'
      isFetching={isFetching}
      isLoading={savedProgramsLoading}
      hasMore={savedProgramsLeft > 0}
      remainingResults={savedProgramsLeft}
      results={savedProgramsFlat?.map((program: any, index: any) => (
        <UniProgramCard
          showUniName
          key={`${index}-program`}
          fit
          showStatus
          program={program}
          metricsArr={[
            {
              label: t('headers.level_of_study'),
              value: parseLevelOfStudy(program.levelOfStudy),
            },
            {
              label: t('program.tuition_fee'),
              value: program?.tuitionFee?.currency
                ? `${program.tuitionFee?.currency?.amount} ${program.tuitionFee?.currency?.currencyIso} / ${program.tuitionFee?.lecturePeriod}`
                : 'Unspecified',
            },
            {
              label: t('program.application_fee'),
              value: 'Unspecified',
            },
          ]}
        />
      ))}
    />
  );
};

export default SavedPrograms;
