import useVerifyNewEmail from 'api/auth/useVerifyNewEmail';
import { UniPageLoader } from 'components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface Params {
  token: string;
}

const VerifyNewEmail: React.FC = ({ children, ...rest }) => {
  const [verifyNewEmail, { error }] = useVerifyNewEmail();
  const { token } = useParams<Params>();

  useEffect(() => {
    verifyNewEmail({ token });
  }, []);

  return <UniPageLoader />;
};

export default VerifyNewEmail;
