import styled from 'styled-components';
import backgroundImage from '../../../assets/images/walk-in-the-city.png';

export const StyledUniversityImage = styled(({ ...rest }) => <img {...rest} src={backgroundImage} />)`
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
`;
