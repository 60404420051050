import styled from 'styled-components';

export const StyledPrepCenterProductCardWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: #fff;
  border: 1px solid rgba(208, 208, 208, .7);
  border-radius: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  overflow: hidden;
`;

export const StyledPrepCenterProductCardContentWrapper = styled.div`
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
`;

export const StyledPrepCenterProductCardTitle = styled.div`
  color: #373737;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
`;

export const StyledPrepCenterProductDescription = styled.div`
  color: #49275c;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

export const StyledPrepCenterProductCardFooter = styled.div`
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;

export const StyledPrepCenterProductCardActionBar = styled.div`
  width: 100%;
  height: 72px;
  background-color: #f7f6f4;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  display: flex;
  box-shadow: 0 -1px rgba(0, 0, 0, .15);
`;

export const StyledPrepCenterProductCategoryLabel = styled.div`
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: rgba(73, 39, 92, .1);
  border-radius: 24px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 7px 16px 6px;
  display: flex;
`;
