import React from 'react';
import { UniTypography } from '../index';
import { StyledPrepCenterOrdersHeader } from './UniPrepCenterOrders.style';

interface Props {}

export const UniPrepCenterOrdersHeader: React.FC<Props> = () => {
  return (
    <StyledPrepCenterOrdersHeader>
      <UniTypography weight='medium' variant='subheader'>
        Date
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Products
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Price
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Status
      </UniTypography>
    </StyledPrepCenterOrdersHeader>
  );
};

export default UniPrepCenterOrdersHeader;
