import styled from 'styled-components';

export const StyledCountrySelect = styled.div`
  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 580px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 380px) {
    grid-template-columns: 1fr;
  }

  grid-row-gap: 40px;
  grid-column-gap: 25px;
  margin-top: 40px;
  margin-bottom: 40px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  justify-items: center;
`;
