import { Box } from '@material-ui/core';
import { UniButton, UniPageTitle, UniTypography } from 'components';
import { StyledFooter } from 'components/_forms/Form.style';
import { useBreakpoints } from 'hooks';
import { useTranslation } from 'react-i18next';
import { OnboardWrapper, StyledOnboardTypography } from '../StudentOnboard.style';

interface Props {
  nextStep: Function;
  isReferral: boolean;
}

const StudentOnboardWelcome: React.FC<Props> = ({ nextStep, isReferral }) => {
  const { t } = useTranslation('forms');
  const { isMobile } = useBreakpoints();

  return (
    <Box>
      <OnboardWrapper>
        <UniPageTitle title={`${t(`introduction.${isReferral ? 'referral_' : ''}title`)}`} />
        <StyledOnboardTypography variant='body2' color='typographyBlack100'>
          {t(`introduction.${isReferral ? 'referral_' : ''}main`)}
        </StyledOnboardTypography>
        <UniTypography variant='subheader' color='typographyGray100'>
          {t(`introduction.${isReferral ? 'referral_' : ''}secondary`)}
        </UniTypography>
        {!isMobile ? (
          <Box mt={5}>
            <UniButton size='large' fullWidth={false} onClick={() => nextStep()}>
              {t('buttons.start')}
            </UniButton>
          </Box>
        ) : null}
      </OnboardWrapper>
      {isMobile && (
        <StyledFooter
          variants='M'
          type='basic'
          PrimaryButton={
            <UniButton
              style={{ margin: '16px 16px 16px auto' }}
              title='start'
              size='medium'
              color='primary'
              fullWidth={false}
              onClick={() => nextStep()}
            >
              {t('buttons.start')}
            </UniButton>
          }
        />
      )}
    </Box>
  );
};

export default StudentOnboardWelcome;
