import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

const useWorkshops = () => {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Workshops.All],
    async () => {
      const { data } = await client!.WorkshopApi_getWorkshops();
      return data;
    },
    {
      onError(error: AxiosError) {
        console.log(error);
      },
    },
  );
};

export default useWorkshops;
