import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import React from 'react';
import styled from 'styled-components';
import { UniSortButton, UniTypography } from '../../components';
import UniCheckbox from '../_inputs/uniCheckbox/UniCheckbox';
import { AgentsQueryParams, AgentsSort, OnAgentsSort } from './AgentsList';

const StyledAgentsListHeader = styled.div`
  display: grid;
  grid-template-columns: 0.5fr repeat(6, 2fr) 2fr;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;

type HeaderProps = {
  params?: AgentsQueryParams;
  onSort?: OnAgentsSort;

  selectAllAgents?: Function;
  allSelected?: boolean;
  order?: OrderType;
  sortType?: AgentsSort;
};

const AgentsListHeader: React.FC<HeaderProps> = ({ params, onSort, selectAllAgents, allSelected, order, sortType }) => {
  const ord = order ? order : 'ASC';
  const byName = sortType === 'NAME';
  const byDateAdded = sortType === 'DATE_ADDED';
  // const byNationality = params._sort === "nationality";
  // const byStudy = params._sort === "levelOfStudy";

  return (
    <StyledAgentsListHeader>
      <UniCheckbox
        checked={allSelected}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          e.stopPropagation();
          selectAllAgents && selectAllAgents(e.target.checked);
        }}
      />
      {onSort ? (
        <UniSortButton onClick={() => onSort('NAME')} active={byName} order={ord}>
          Name
        </UniSortButton>
      ) : (
        <UniTypography weight='medium' variant='subheader'>
          Name
        </UniTypography>
      )}
      {onSort ? (
        <UniSortButton onClick={() => onSort('DATE_ADDED')} active={byDateAdded} order={ord}>
          Date added
        </UniSortButton>
      ) : (
        <UniTypography weight='medium' variant='subheader'>
          Date added
        </UniTypography>
      )}
      <UniTypography weight='medium' variant='subheader'>
        Students
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Applications
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Accepted
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Conversion
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Actions
      </UniTypography>
    </StyledAgentsListHeader>
  );
};

export default AgentsListHeader;
