import { Box } from '@material-ui/core';
import { useDeleteServiceOrder } from 'api';
import useDeleteServiceOrderItem from 'api/service-orders/useDeleteServiceOrderItem';
import usePostServiceOrderSubmission from 'api/service-orders/usePostServiceOrderSubmission';
import useUpdateServiceOrderItem from 'api/service-orders/useUpdateServiceOrderItem';
import { ReactComponent as DeleteIcon } from 'assets/svg/Icons/Garbagev2.svg';
import { Components } from 'client/UniClient';
import { UniButton, UniCard, UniDivider, UniIconWrapper, UniTypography } from 'components';
import ServiceItem from 'components/serviceItem/ServiceItem';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { StyledActionsArea, StyledOrderFooter, StyledOrderHeader } from './ServiceOrder.style';

export type ServiceType = {
  description: string;
  name: string;
  price: Components.Schemas.CurrencyDto;
  quantity?: string;
};

export interface OrderType extends Components.Schemas.ServiceOrderDto {
  onSubmit?: (value: Inputs) => void;
}

interface Inputs {
  services: Components.Schemas.ServiceOrderItemDto[];
}

const ServiceOrder: React.FC<OrderType> = ({
  children,
  createdAt,
  id: orderId,
  items,
  number,
  status,
  student,
  totalGrossPrice,
  updatedAt,
  invoicedAt,
  stripePaymentLink,
  stripeTotalPrice,
  onSubmit,
}) => {
  const { t } = useTranslation('serviceOrder');
  const [deleteServiceOrder, {}] = useDeleteServiceOrder();
  const [submitOrder, { isLoading: isLoadingSubmitOrder }] = usePostServiceOrderSubmission();
  const [updateOrderItem, { isLoading: isLoadingOrderItem }] = useUpdateServiceOrderItem();
  const [deleteOrderItem, { isLoading: isLoadingDeleteOrderItem }] = useDeleteServiceOrderItem();
  const { profile } = useAuth();
  const { isAgency, isAgent, isStudent } = getMetaRole(profile.role);
  const hasSubmitAction = (isAgency || isAgent) && status === 'NEW';
  const onDelete = () => deleteServiceOrder(orderId);

  const onChangeItem = (quantity: number, id: string) => {
    updateOrderItem({
      serviceOrderId: orderId,
      serviceOffering: {
        quantity: quantity,
        serviceOfferingId: id,
      },
    });
  };

  const onDeleteItem = (id: string) => {
    deleteOrderItem({
      serviceOrderId: orderId,
      payload: {
        clearItems: true,
        serviceOfferingId: id,
      },
    });
  };

  const parseTotalPrice = () => {
    if (status === 'SUBMITTED' && stripeTotalPrice) {
      return `${stripeTotalPrice.amount} ${stripeTotalPrice.currencyIso}`;
    }
    if (status === 'SUBMITTED' && totalGrossPrice) {
      return `${totalGrossPrice.amount} ${totalGrossPrice.currencyIso}`;
    }

    const totalItemsPrice = items.reduce((prev, item) => prev + item.serviceOffering.price.amount * item.quantity, 0);
    return `${totalItemsPrice} ${items?.[0]?.serviceOffering?.price?.currencyIso}`;
  };

  const parseVATPrice = () => {
    if (status === 'SUBMITTED' && stripeTotalPrice) {
      return `${stripeTotalPrice.amount - totalGrossPrice.amount} ${stripeTotalPrice.currencyIso}`;
    }
  };

  return (
    <UniCard size='S' border>
      <StyledOrderHeader>
        <Box>
          <UniTypography variant='body1' color='typographyBlack100' weight='bold'>
            {t('texts.serviceOrder')}
            {number}
          </UniTypography>
          {!isStudent && (
            <Box display='flex'>
              <UniTypography variant='subheader' weight='regular' color='typographyBlack100'>
                {t('texts.for')}&nbsp;
              </UniTypography>
              <UniTypography variant='subheader' weight='regular' color='primary100'>
                {`${student.firstName} ${student.lastName}`}
              </UniTypography>
            </Box>
          )}
        </Box>
        <StyledActionsArea>
          <Box display='flex' flexDirection='column' justifyContent='flex-end'>
            {!!invoicedAt && (
              <UniTypography variant='subheader' weight='regular' color='typographyGray100'>
                {t('texts.offeredOn')}
                {moment(invoicedAt).format('DD.MM.YYYY HH:MM')}
              </UniTypography>
            )}
            {!!updatedAt && status === 'PAID' && (
              <Box mt={1}>
                <UniTypography variant='subheader' weight='regular' color='secondary100'>
                  {t('texts.paidOn')}
                  {moment(updatedAt).format('DD.MM.YYYY HH:MM')}
                </UniTypography>
              </Box>
            )}
          </Box>
          {!isStudent && (
            <Box display='flex' gridColumnGap='8px' ml={3}>
              <UniIconWrapper clearMargins onClick={onDelete} size='medium' disableHover color='typographyGray100'>
                <DeleteIcon />
              </UniIconWrapper>
            </Box>
          )}
        </StyledActionsArea>
      </StyledOrderHeader>
      {items.map((service, index) => (
        <React.Fragment key={index}>
          <UniDivider height={3} />
          <ServiceItem
            key={service?.serviceOffering?.id}
            value={service.quantity || 0}
            onChange={value => !isStudent && onChangeItem(value, service.serviceOffering.id)}
            onDelete={() => !isStudent && onDeleteItem(service.serviceOffering.id)}
            description={service?.serviceOffering?.serviceOption?.description}
            name={service?.serviceOffering?.serviceOption?.name}
            price={service?.serviceOffering?.price}
            editable={status === 'NEW'}
          />
          {index === items.length - 1 && <UniDivider height={3} />}
        </React.Fragment>
      ))}
      <StyledOrderFooter>
        <Box />
        <Box display='flex' flexDirection='column' alignItems='flex-end' textAlign='right'>
          <Box display='grid' gridTemplateColumns='1fr 1fr' gridTemplateRows='32px 24px' alignItems='center' gridColumnGap={16} mb={4}>
            <UniTypography variant='body2' color='typographyBlack100' weight='semi-bold'>
              {t('labels.total')}
            </UniTypography>

            <UniTypography variant='header3' color='secondary100' weight='semi-bold'>
              {parseTotalPrice()}
            </UniTypography>
            {stripeTotalPrice && (
              <>
                <UniTypography variant='subheader' color='otherGreen100' weight='semi-bold'>
                  {t('labels.includedTVA')}
                </UniTypography>

                <UniTypography variant='subheader' color='secondary100' weight='semi-bold'>
                  {parseVATPrice()}
                </UniTypography>
              </>
            )}
          </Box>
          {status === 'PAID' && (
            <UniTypography variant='body2' color='otherGreen100' weight='semi-bold'>
              Paid!
            </UniTypography>
          )}
          {isStudent &&
            status === 'SUBMITTED' &&
            (stripePaymentLink ? (
              <UniButton
                size='large'
                fullWidth={false}
                variant='main'
                color='primary'
                loading={isLoadingSubmitOrder}
                onClick={() => window.open(stripePaymentLink, '_blank')}
              >
                {`Pay ${parseTotalPrice()}`}
              </UniButton>
            ) : (
              <UniTypography variant='body2' color='primary100' weight='semi-bold'>
                Invoice sent to your email inbox.
              </UniTypography>
            ))}
          {isAgency && status === 'SUBMITTED' && (
            <UniTypography variant='body2' color='primary100' weight='semi-bold'>
              Invoice sent!
            </UniTypography>
          )}
          {hasSubmitAction && (
            <UniButton
              size='large'
              fullWidth={false}
              variant='main'
              color='primary'
              loading={isLoadingSubmitOrder}
              onClick={() => submitOrder({ serviceOrderId: orderId })}
            >
              {t('buttons.sendOrder')}
            </UniButton>
          )}
        </Box>
      </StyledOrderFooter>
    </UniCard>
  );
};

export default ServiceOrder;
