import { Grid } from '@material-ui/core';
import { UniHeader, UniHerosection } from 'components';
import { Route, Switch } from 'react-router';
import { useAuth, useDrawers } from 'store';
import { getMetaRole } from 'utils';
import { useStudentNames } from '../../../api';
import { UniPage } from '../../../components';
import { BrowserRoutes } from '../../../constants';
import AllStudents from './_tabs/AllStudents';
import MyStudents from './_tabs/MyStudents';

const AgencyCandidates = () => {
  const { openDrawer } = useDrawers();
  const { role } = useAuth(state => state.profile);
  const { isAgency } = getMetaRole(role);
  const { data: candidateNames, error: candidatesAgentError } = useStudentNames();

  const openFiltersCandidatesDrawer = () => openDrawer('filter-candidates', { size: 'small' });

  const tabs = isAgency
    ? [
        {
          label: 'All Candidates',
          path: BrowserRoutes.Agency.Students.All,
        },
        {
          label: 'My Candidates',
          path: BrowserRoutes.Agency.Students.My,
        },
      ]
    : undefined;

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='title'
          title='Candidates'
          filterbarHeadline='Find candidates'
          onClickFilters={openFiltersCandidatesDrawer}
          overflow='none'
          options={candidateNames?.map(item => item.label) || []}
          withFilterBar
          tabs={tabs}
        />
      </UniHeader>
      <UniPage>
        <Grid xs={12} item>
          <Switch>
            <Route path={BrowserRoutes.Agent.Students.All} component={AllStudents} />
            <Route path={BrowserRoutes.Agent.Students.My} component={MyStudents} />
          </Switch>
        </Grid>
      </UniPage>
    </>
  );
};

export default AgencyCandidates;
