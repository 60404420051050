import { Components } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export default function useGetAgencyNames(config?: QueryConfig<Components.Schemas.FilterDto[]>) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Agency.AgencyNames],
    async (_key: string) => {
      const { data } = await client!.AgencyApi_getAgencyNames();
      return data.map((item: any) => ({ label: item.label, value: item.value }));
    },
    { ...config },
  );
}
