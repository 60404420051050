import { AxiosError } from 'axios';
import { queryCache, useMutation } from 'react-query';
import { Components, Paths } from 'client/UniClient';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

type Params = Paths.ApplicationApiAddUniversityCredentials.PathParameters;
type Payload = Components.Schemas.CreateApplicationUniversityCredentialsRequest;

const usePostApplicationUniversityCredentials = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload & Params) => {
      const { applicationId, ...data } = payload;
      return client!.ApplicationApi_addUniversityCredentials({ applicationId }, data);
    },
    {
      onSuccess({ data }) {
        queryCache.invalidateQueries([
          CacheKeys.Applications.Application.Credentials,
          { applicationId: data.id },
        ]).then();
      },
      onError(error: AxiosError) {
        console.error(error);
      },
    },
  );
};

export default usePostApplicationUniversityCredentials;
