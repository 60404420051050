import { Box } from '@material-ui/core';
import { UniButton, UniIconWrapper } from 'components';
import { useBreakpoints } from 'hooks';
import { StyledRadio } from './UniOptions.style';

export interface UniOptionProps {
  label: string;
  value: string;
  setElementChecked: Function;
  elementChecked: any;
}

export const UniOptions: React.FC<UniOptionProps> = ({ label, value, setElementChecked, elementChecked }) => {
  const { isMobile } = useBreakpoints();
  return (
    <Box display='flex' width='fit-content'>
      <UniButton
        size={isMobile ? 'medium' : 'large'}
        variant='text'
        color='fadeGray'
        onClick={() => setElementChecked(value)}
        thin
        startIcon={
          <UniIconWrapper size='medium' color='primary100'>
            <StyledRadio disableRipple checked={elementChecked} />
          </UniIconWrapper>
        }
      >
        {label}
      </UniButton>
    </Box>
  );
};
