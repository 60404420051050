import { UniButton, UniTypography } from 'components';
import { useTranslation } from 'react-i18next';
import { StyledMoreWrapper } from './UniShowMore.style';

interface ShowMoreProps {
  nrPrograms: number;
  onClickMore: Function;
  isLoading: boolean;
  variant?: 'seeMore' | 'showMore';
}

export const UniShowMore = ({ nrPrograms, onClickMore, variant = 'seeMore', isLoading }: ShowMoreProps) => {
  const { t } = useTranslation('programsList');
  return (
    <StyledMoreWrapper footer={variant === 'showMore'}>
      <UniButton
        style={{ width: 'fit-content', margin: 'auto' }}
        size='small'
        loading={isLoading}
        variant='secondary'
        color='neutral'
        onClick={() => onClickMore()}
      >
        {variant === 'seeMore' ? t('buttons.see_more') : t('buttons.show_more')}
      </UniButton>
      <UniTypography variant='subheader' color='typographyGray100'>
        {`${nrPrograms} more results`}
      </UniTypography>
    </StyledMoreWrapper>
  );
};
