import { queryCache, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { BrowserRoutes } from '../../constants';
import { useAuth, useChat, useClient } from '../../store';

type Payload = {
  email: string;
  password: string;
};

const useLogout = () => {
  const { client, resetSession } = useClient();
  const { profile, resetProfile } = useAuth();
  const { socket } = useChat();
  const history = useHistory();
  const isLoggedIn = !!profile?.id;
  const isInviteLink = history.location.pathname
    .split('/')
    .some(item => ['invite', 'invite-link', 'agent-invite', 'agency-invite'].includes(item));

  const resetUserData = () => {
    resetProfile();
    resetSession();
    queryCache.clear();
    !isInviteLink && history.push(BrowserRoutes.Default.Auth.Login);
  };

  return useMutation(
    async (payload: Payload) => {
      if (isLoggedIn) {
        socket?.disconnect();
        return client!.AuthApi_logout(undefined, undefined);
      }
    },
    {
      onSettled() {
        resetUserData();
      },
    },
  );
};
export default useLogout;
