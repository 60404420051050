import { useRef } from 'react';
import { HiddenInput, StyledAvatarUploadIcon, StyledUniAvatarUpload } from './UniAvatar.style';

interface Props {
  onChange: (file: File) => void;
}

const UniAvatarUpload = ({ onChange }: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    ref.current?.click();
  };

  return (
    <StyledUniAvatarUpload>
      <HiddenInput type='file' ref={ref} onChange={e => e.target?.files?.length && onChange(e.target.files[0])} />
      <StyledAvatarUploadIcon onClick={handleClick} />
    </StyledUniAvatarUpload>
  );
};

export default UniAvatarUpload;
