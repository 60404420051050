import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const StyledNavbar = styled(Box)<{ ref?: React.MutableRefObject<null> }>`
  position: sticky;
  top: 40px;
  margin-top: ${({ theme }) => theme.spacing(5)}px;
  align-self: start;
  width: auto;
`;
