import { Box } from '@material-ui/core';
import { UniButton, UniDivider, UniIconWrapper, UniTextField } from 'components';
import { UniDate } from 'components/_inputs/uniDate/UniDate';
import { Controller, useFieldArray } from 'react-hook-form';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/Icons/Close.svg';

interface Props {
  control: any;
  register: any;
  errors: any;
}

const Step4: React.FC<Props> = ({ control, register, errors }) => {
  const { fields, append, remove } = useFieldArray({
    name: 'agendas',
    control,
  });

  const onNewLink = () => append({ name: '', description: '' });

  return (
    <>
      {fields.map((field, index) => {
        return (
          <Box key={`${field.id}-${index}`} mt={2}>
            <UniTextField
              fullWidth
              label='Activity title'
              placeholder='Enter your activity title'
              defaultValue={field.name}
              name={`agendas[${index}].name`}
              inputRef={register({
                required: 'Field must not be empty',
              })}
              error={!!errors?.agendas?.[index]?.name?.message}
              helperText={errors?.agendas?.[index]?.name?.message}
              endAdornment={
                <UniIconWrapper onClick={() => remove(index)}>
                  <CloseIcon />
                </UniIconWrapper>
              }
            />
            <UniTextField
              fullWidth
              label='Activity description'
              placeholder='Enter your activity description'
              defaultValue={field.description}
              name={`agendas[${index}].description`}
              inputRef={register({
                required: 'Field must not be empty',
              })}
              error={!!errors?.agendas?.[index]?.description?.message}
              helperText={errors?.agendas?.[index]?.description?.message}
            />
            <Controller
              name='date'
              control={control}
              rules={{
                required: 'Field must not be empty',
              }}
              render={field => (
                <UniDate
                  fullWidth
                  label='Select activity date'
                  showFutureYears
                  error={!!errors?.agendas?.[index]?.date?.message}
                  {...field}
                />
              )}
            />
            <Box display='grid' gridTemplateColumns='1fr 1fr' gridColumnGap={8}>
              <UniTextField
                fullWidth
                label='Start time'
                placeholder='Enter your activity starting time'
                defaultValue={field.startDate}
                type='time'
                name={`agendas[${index}].startTime`}
                inputRef={register({
                  required: 'Field must not be empty',
                })}
                error={!!errors?.agendas?.[index]?.startTime?.message}
                helperText={errors?.agendas?.[index]?.startTime?.message}
              />
              <UniTextField
                fullWidth
                label='Start time'
                placeholder='Enter your activity end time'
                defaultValue={field.endDate}
                type='time'
                name={`agendas[${index}].endTime`}
                inputRef={register({
                  required: 'Field must not be empty',
                })}
                error={!!errors?.agendas?.[index]?.endTime?.message}
                helperText={errors?.agendas?.[index]?.endTime?.message}
              />
            </Box>
            {index < fields.length - 1 && <UniDivider height={3} />}
          </Box>
        );
      })}
      <UniButton size='medium' variant='text' color='primary' onClick={onNewLink}>
        Add activity
      </UniButton>
    </>
  );
};

export default Step4;
