import React from 'react';
import { useHistory } from 'react-router-dom';
import { Components } from 'client/UniClient';
import {
  StyledPrepCenterProductCardActionBar,
  StyledPrepCenterProductCardContentWrapper,
  StyledPrepCenterProductCardFooter,
  StyledPrepCenterProductCardTitle,
  StyledPrepCenterProductCardWrapper, StyledPrepCenterProductCategoryLabel,
  StyledPrepCenterProductDescription
} from './UniPrepCenterProductCard.style';
import { getPrepPackPriceRange } from 'utils';
import { UniButton } from '../index';
import { BrowserRoutes } from '../../constants';

type ProductDto = Components.Schemas.ProductDto;
type PrepPackDto = Components.Schemas.PrepPackDto;

interface Props {
  product: ProductDto;
  prepPacks: PrepPackDto[];
}

export const UniPrepCenterProductCard: React.FC<Props> = ({ product: { id, name, logoFile, category }, prepPacks }) => {
  const history = useHistory();
  const priceRange = getPrepPackPriceRange(prepPacks);
  return (
    <StyledPrepCenterProductCardWrapper>
      <img src={logoFile?.location} alt={`Product ${id} logo`} style={{ width: '100%' }} />
      <StyledPrepCenterProductCardContentWrapper>
        <StyledPrepCenterProductCardTitle>
          {name}
        </StyledPrepCenterProductCardTitle>
        <StyledPrepCenterProductDescription>
          {priceRange}
        </StyledPrepCenterProductDescription>
      </StyledPrepCenterProductCardContentWrapper>
      <StyledPrepCenterProductCardFooter>
        <StyledPrepCenterProductCardActionBar>
          <UniButton onClick={() => history.push(BrowserRoutes.Student.PrepCenter.Product.replace(':productId', id!))}>
            Read more
          </UniButton>
          <StyledPrepCenterProductCategoryLabel>
            {category}
          </StyledPrepCenterProductCategoryLabel>
        </StyledPrepCenterProductCardActionBar>
      </StyledPrepCenterProductCardFooter>
    </StyledPrepCenterProductCardWrapper>
  );
};

export default UniPrepCenterProductCard;
