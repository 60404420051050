import { GA_TRACKING_ID } from 'constants/envConfig';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';
import { useAuth } from 'store';

const useAnalytics = () => {
  const { pathname } = useLocation();
  const { profile } = useAuth();
  const isTestMode = process.env.NODE_ENV === 'development' && false;

  const init = () => {
    ReactGA.initialize([{ trackingId: GA_TRACKING_ID, gaOptions: { userId: profile.id } }], { testMode: isTestMode });
    ReactGA.send('pageview');
  };

  const track = (category: string, action: string, label: string) => {
    ReactGA.event({ category, action, label });
  };

  useEffect(() => {
    if (profile && profile.role === 'STUDENT') {
      const age = profile.studentProfile?.dateOfBirth
        ? Math.floor((new Date().getTime() - new Date(profile.studentProfile?.dateOfBirth).getTime()) / 1000 / 60 / 60 / 24 / 365)
        : undefined;

      const userProperties = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phone: profile.phone,
        country: profile.studentProfile?.address?.country,
        city: profile.studentProfile?.address?.city,
        nationality: profile.studentProfile?.nationality,
        role: profile.role,
        age,
      };

      ReactGA.set({
        user_id: profile.id,
        user_properties: userProperties,
        userId: profile.id,
        userProperties: userProperties,
      });
    }
  }, [profile]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);

  return {
    init,
    track,
  };
};
export default useAnalytics;
