import React from 'react';
import { StyledContinentTile } from './ContinentTile.style';
import ContinentTileDescription from './ContinentTileDescription';
import ContinentTileButton from './ContinentTileButton';

export interface ContinentTileProps {
  title: string;
  description: string;
  image: string;
  continentPageUrl: string;
}

export const ContinentTile: React.FC<ContinentTileProps> = ({ title, description, image, continentPageUrl }) => {
  return (
    <StyledContinentTile continentImage={image} href={continentPageUrl}>
      <ContinentTileDescription title={title} description={description} />
      <ContinentTileButton targetUrl={continentPageUrl} />
    </StyledContinentTile>
  );
};

export default ContinentTile;
