import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniversitySectionHeader } from 'components/universitySectionHeader/UniversitySectionHeader';
import { useBreakpoints } from 'hooks';
import React from 'react';
import { StyledWr } from './UniCardGroup.style';

interface CardProps {
  university?: Components.Schemas.UniversityDto;
  withHeader?: boolean;
  children?: React.ReactNode;
}

export const UniCardGroup = ({ university, withHeader = false, children }: CardProps) => {
  const { isMobile } = useBreakpoints();
  return (
    <>
      {(withHeader || isMobile) && university && (
        <Box mb={2}>
          <UniversitySectionHeader university={university} />
        </Box>
      )}
      <StyledWr withHeader={withHeader} isMobile={isMobile}>
        {children}
      </StyledWr>
    </>
  );
};
