import { Box } from '@material-ui/core';
import usePostDocumentForStudent from 'api/files/usePostDocumentForStudent';
import { UniDragNDrop, UniTextField, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { useErrorHandler } from 'hooks';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { usePostDocument } from '../../../api';

interface Props {
  close: Function;
  prefill?: string;
}

export default function AddNewDocument({ close, prefill }: Props): ReactElement {
  const { t } = useTranslation('studentDocuments');
  const [fileLabel, setFileLabel] = useState<string>(prefill || '');
  const [files, setFiles] = useState<File[]>([]);
  const { profile } = useAuth();
  const { pathname } = useLocation();
  const [upload, { status: uploadStatus, error: uploadError }] = usePostDocument();
  const [uploadAgency, { status: uploadAgencyStatus, error: uploadAgencyError }] = usePostDocumentForStudent();
  const isLoading = uploadStatus === 'loading' || uploadAgencyStatus === 'loading';
  const { isAgency, isAgent, isStudent } = getMetaRole(profile?.role);
  const postError = useErrorHandler(uploadError || uploadAgencyError!);
  const onSave = () => {
    if (files.length > 0 && fileLabel.trim() !== '') {
      const formData = new FormData();
      formData.append('label', fileLabel);
      formData.append('documentType', 'OTHER');
      formData.append('file', files[0]);

      if (isStudent) {
        upload(formData, {
          onSuccess() {
            setFiles([]);
            setFileLabel('');
            close();
          },
        });
      }
      if (isAgency || isAgent) {
        const studentId = pathname.split('/')[4];
        formData.append('studentId', studentId);
        uploadAgency(formData, {
          onSuccess() {
            setFiles([]);
            setFileLabel('');
            close();
          },
        });
      }
    }
  };
  return (
    <>
      <UniModalDrawerContent>
        <Box mb={3.75}>
          <UniTextField
            label={t('fieldLabel')}
            variant='subheader'
            fullWidth
            placeholder={t('fieldPlaceholder')}
            value={fileLabel}
            onChange={e => setFileLabel(e.target.value)}
            helperText={postError.message}
            error={!!postError}
          ></UniTextField>
          <UniDragNDrop
            label={t('drag&dropLabel')}
            accept='application/pdf, image/jpg, image/png'
            handleChange={(files: File[]) => {
              if (files.length > 0) {
                fileLabel === '' && setFileLabel(files[0]?.name);
                setFiles(files);
              }
            }}
            onDelete={() => setFileLabel('')}
          >
            <UniTypography color='typographyGray70' weight='regular' variant='body2'>
              {t('drag&dropContent')}
            </UniTypography>
          </UniDragNDrop>
        </Box>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: close, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: () => {
            !isLoading && onSave();
          },
          loading: isLoading,
          label: t('buttons.add_doc'),
        }}
      />
    </>
  );
}
