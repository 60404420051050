import { Box } from '@material-ui/core';
import { useExtendedProfile, useFavoriteProgram } from 'api';
import { ReactComponent as HeartEmptyIcon } from 'assets/svg/Icons/Heart.svg';
import { ReactComponent as HeartFullIcon } from 'assets/svg/Icons/HeartFull.svg';
import { Components } from 'client/UniClient';
import { UniChip, UniStatus } from 'components';
import UniButton from 'components/_common/uniButton/UniButton';
import UniIconWrapper from 'components/_common/uniIconWrapper/UniIconWrapper';
import { applicationStatusMap } from 'constants/applicaiton';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactElement, useMemo } from 'react';
import { useAuth, useDrawers, useSnackbar } from 'store';
import { parseApplicationFeeInvoice } from 'utils/programUtils';
import { ReactComponent as GraduationHatIcon } from '../../assets/svg/Icons/GraduationHat.svg';

interface Props {
  program: Components.Schemas.ProgramResponse;
}

export default function ProgramsListItemStudentActions({ program }: Props): ReactElement {
  const [favoriteProgram, { status: favoriteProgramStatus }] = useFavoriteProgram();
  const { openDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const application = program.application;
  const hasApplicaitonInvoice = !!application?.feeInvoice;
  const applicationStatus = application?.status ? applicationStatusMap[application?.status] : 'Not applied';
  const hasApplied = !!application?.status;
  const canEnroll = application?.status === 'PASSED';
  const isFavorite = !!program?.isFavorite;

  const user = useAuth(state => state.profile);
  const { data: extendedProfile } = useExtendedProfile(user);
  const existsHistory = useMemo(() => (extendedProfile ? extendedProfile.educationHistory.length : 0), [extendedProfile]);

  const onFavoriteProgram = () => favoriteProgram({ isFavorite: !!program?.isFavorite, programId: program.id! });
  //START APPLICATION MODAL CONTROLLERS
  const openApplicationDrawer = () => {
    openDrawer('application-drawer', { size: 'small', program: program });
  };

  //ENROLL DRAWER CONTROLLERS
  const openEnrollmentDrawer = () => {
    openDrawer('student-enroll', { size: 'small', program: program });
  };

  return (
    <Box display='flex' gridGap='16px' alignItems='center'>
      <Box display='flex' alignItems='center' gridGap='16px'>
        {program.blockAdmission ? (
          <UniChip label='Application period is closed' color='red' />
        ) : (
          <UniChip color='green' label={applicationStatus} />
        )}
        {hasApplicaitonInvoice &&
          (application?.feeInvoice?.isPaid ? (
            <UniStatus specificColor='otherGreen100'>{`Fee paid: ${parseApplicationFeeInvoice(application?.feeInvoice)}`}</UniStatus>
          ) : (
            <UniButton
              fullWidth={false}
              color='primary'
              onClick={() => application?.feeInvoice?.paymentLink && window.open(application.feeInvoice.paymentLink, '_blank')}
            >
              {`Pay application fee (${parseApplicationFeeInvoice(application?.feeInvoice)})`}
            </UniButton>
          ))}
        <UniIconWrapper onClick={onFavoriteProgram} color={isFavorite ? 'primary100' : 'typographyGray100'}>
          <AnimatePresence>
            {!isFavorite && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <HeartEmptyIcon />
              </motion.div>
            )}
            {isFavorite && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <HeartFullIcon />
              </motion.div>
            )}
          </AnimatePresence>
        </UniIconWrapper>
      </Box>
      {!hasApplied && !canEnroll && (
        <UniButton
          fullWidth={false}
          color='primary'
          onClick={existsHistory ? openApplicationDrawer : () => openSnackbar('apply-program')}
          disabled={program.blockAdmission}
        >
          Apply
        </UniButton>
      )}
      {hasApplied && canEnroll && (
        <UniButton
          color='secondary'
          fullWidth={false}
          startIcon={
            <UniIconWrapper color='white100' size='medium'>
              <GraduationHatIcon />
            </UniIconWrapper>
          }
          onClick={openEnrollmentDrawer}
        >
          Enroll
        </UniButton>
      )}
    </Box>
  );
}
