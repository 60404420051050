import { AxiosError } from "axios";
import { useMutation, useQueryCache } from "react-query";
import { useAuth, useClient } from '../../store';
import { CacheKeys } from "../../constants";
import { Components } from "../../client/UniClient";

type Payload = Components.Schemas.UpdateAgencyRequest;

const useUpdateAgency = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();
  const agencyId = useAuth(state => state.profile.agentProfile?.agency?.id!);

  return useMutation(
    async (payload: Payload) => {
      return client!.AgencyApi_updateAgency({ agencyId }, payload);
    },
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(CacheKeys.Agency.All);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         });
         */
      },
    }
  );
};

export default useUpdateAgency;
