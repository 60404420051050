import usePatchServiceOptions from 'api/service-options/usePatchServiceOptions';
import usePostServiceOptions from 'api/service-options/usePostServiceOptions';
import { Components } from 'client/UniClient';
import { UniModalDrawerButtons } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import EditServiceForm, { EditServiceFormInputs } from 'components/_forms/EditServiceForm';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers, useSnackbar } from 'store';

interface Props {
  service?: Components.Schemas.ServiceOptionDto;
  editMode?: boolean;
}

export default function AddNewService({ service, editMode }: Props): ReactElement {
  const { t: tButtons } = useTranslation('buttons');
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const [postService, { isLoading: isLoadingPost }] = usePostServiceOptions();
  const [patchService, { isLoading: isLoadingPatch }] = usePatchServiceOptions();
  const { register, control, handleSubmit } = useForm<EditServiceFormInputs>({
    defaultValues: {
      name: service?.name,
      description: service?.description,
    },
  });
  const isLoading = isLoadingPost || isLoadingPatch;
  const primaryLabel = editMode ? tButtons('edit') : tButtons('save');

  const onClose = () => closeDrawer();

  const onSave = (formData: Components.Schemas.AddServiceOptionRequest) =>
    postService(formData, {
      onSuccess() {
        openSnackbar('create-service');
        closeDrawer();
      },
    });

  const onEdit = (formData: Components.Schemas.UpdateServiceOptionRequest) =>
    patchService(
      {
        serviceOptionId: service?.id || '',
        body: formData,
      },
      {
        onSuccess() {
          openSnackbar('edit-service');
          closeDrawer();
        },
      },
    );

  const onSubmit = () => handleSubmit(editMode ? onEdit : onSave)();

  return (
    <>
      <UniModalDrawerContent>
        <EditServiceForm register={register} control={control} />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: onClose, label: tButtons('cancel') }}
        PrimaryButton={{ onClick: onSubmit, loading: isLoading, label: primaryLabel }}
      />
    </>
  );
}
