import { Box } from '@material-ui/core';
import useStripeCurrencies from 'api/payments/useStripeCurrencies';
import { Components } from 'client/UniClient';
import { UniMetrics } from 'components/uniMetrics/UniMetrics';
import UniIconWrapper from 'components/_common/uniIconWrapper/UniIconWrapper';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { activitiesCategoryMap } from 'constants/activities';
import { startCase, toLower } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useDrawers } from 'store';
import { ReactComponent as HourglassIcon } from '../../../../assets/svg/Icons/Hourglass.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/svg/Icons/Location.svg';

interface Props {
  event: Components.Schemas.EventResponse;
}

const Step3: React.FC<Props> = ({ event }) => {
  const { closeDrawer } = useDrawers();
  const { data: currencies, isLoading } = useStripeCurrencies();
  const { category, type, startDate, name, attendancePackages, address } = event;
  const parsedAddress = `${address?.country}, ${address?.city}, ${address?.addressLine1}`;
  const onClickPay = () => {
    closeDrawer();
  };

  const values = useMemo(() => {
    const values = [
      {
        Icon: (
          <UniIconWrapper color='secondary100'>
            <LocationIcon />
          </UniIconWrapper>
        ),
        label: 'Category',
        value: activitiesCategoryMap[category] || '',
      },
      {
        Icon: (
          <UniIconWrapper color='secondary100'>
            <LocationIcon />
          </UniIconWrapper>
        ),
        label: 'Event type',
        value: type ? startCase(toLower(type)) : '',
      },
      {
        Icon: (
          <UniIconWrapper color='secondary100'>
            <HourglassIcon />
          </UniIconWrapper>
        ),
        label: 'Start date',
        value: moment(startDate).format('MMM DD, YYYY - h:mm a'),
      },
    ];

    if (address?.country && address?.city && address?.addressLine1) {
      values.push({
        Icon: (
          <UniIconWrapper color='secondary100'>
            <LocationIcon />
          </UniIconWrapper>
        ),
        label: 'Location',
        value: parsedAddress,
      });
    }

    return values;
  }, [event]);

  if (isLoading || !currencies) {
    return <UniListLoader />;
  }

  return (
    <>
      <Box mb={2}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          You are about to join the Youni Event {name}.{' '}
          {!!attendancePackages?.length && 'After completing your details you will be asked to pay a fee to confirm your seat.'}
        </UniTypography>
        <Box mt={3} />
        <UniMetrics vertical size='large' variant='iconed' values={values} />
      </Box>
    </>
  );
};

export default Step3;
