import { Box } from '@material-ui/core';
import { ReactComponent as HeartEmptyIcon } from 'assets/svg/Icons/Heart.svg';
import { ReactComponent as HeartFullIcon } from 'assets/svg/Icons/HeartFull.svg';
import { Components } from 'client/UniClient';
import { UniChip } from 'components';
import { applicationStatusMap } from 'constants/applicaiton';
import { TComponentColors } from 'constants/componentColors';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { ApplicationStatus } from 'types';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import UniStatus from '../uniStatus/UniStatus';
import { StyledBox, StyledFooterBox } from './UniCardFooter.style';

export interface UniCardFooterProps {
  isFavorite?: boolean;
  onFavorite?: (value: boolean) => void;
  PrimaryButton: React.ReactElement;
  SecondaryButton?: React.ReactElement;
  variants?: 'XS' | 'S' | 'M' | 'L';
  program?: Components.Schemas.ProgramResponse;
  status?: ApplicationStatus;
  type?: 'base' | 'baseUnlabeled' | 'baseStatus' | 'baseUnlabeledStatus' | 'labeled' | 'basic';
  style?: any;
  isPromoted?: boolean;
  icon?: React.ReactElement;
  onIconClick?: Function;
  iconColor?: TComponentColors;
  align?: 'left' | 'right';
}

export const UniCardFooter: React.FC<UniCardFooterProps> = ({
  onFavorite,
  isFavorite = false,
  variants = 'L',
  type = 'base',
  program,
  PrimaryButton,
  status,
  SecondaryButton,
  isPromoted,
  icon,
  onIconClick,
  iconColor,
  align = 'right',
  ...rest
}) => {
  const [showLabel1] = useState(type === 'base' || type === 'labeled' || type === 'baseStatus');
  const [showHeart] = useState(type === 'base' || type === 'baseUnlabeled' || type === 'baseStatus' || type === 'baseUnlabeledStatus');
  const [showStatus] = useState(type === 'baseStatus' || type === 'baseUnlabeledStatus');
  const [fullHeart, setFullHeart] = useState(isFavorite);

  const iconClickHandler = () => {
    if (!!onIconClick && !!icon) onIconClick();
  };

  useEffect(() => setFullHeart(isFavorite), [isFavorite]);

  return (
    <StyledFooterBox size={type} variants={variants} isPromoted={isPromoted} {...rest}>
      <Box display='flex' style={{ alignItems: 'center', justifyContent: align === 'right' ? 'flex-end' : 'flex-start' }}>
        {!!onFavorite && showHeart && (
          <UniIconWrapper
            onClick={() => {
              onFavorite && onFavorite(!fullHeart);
            }}
            color={fullHeart ? 'primary100' : 'typographyGray100'}
          >
            <AnimatePresence>
              {!fullHeart && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <HeartEmptyIcon />
                </motion.div>
              )}
              {fullHeart && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <HeartFullIcon />
                </motion.div>
              )}
            </AnimatePresence>
          </UniIconWrapper>
        )}
        {!!icon && (
          <UniIconWrapper color={iconColor} onClick={iconClickHandler}>
            {icon}
          </UniIconWrapper>
        )}
        {showStatus &&
          (status && status !== 'NEW' ? (
            <UniChip color='green' label={applicationStatusMap[status]} />
          ) : (
            !!program && <UniStatus program={program} />
          ))}
      </Box>
      <Box display='flex' style={{ alignItems: 'center' }}>
        {showLabel1 && !!SecondaryButton && <StyledBox variants={variants}>{SecondaryButton}</StyledBox>}
        {PrimaryButton}
      </Box>
    </StyledFooterBox>
  );
};
