import UniModalDrawerTabs from 'components/_common/uniModalDrawer/UniModalDrawerTabs';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CandidateIcon } from '../../../assets/svg/Icons/Profile.svg';
import { Components } from '../../../client/UniClient';
import InviteCandidateMultiple from './InviteCandidateMultiple';
import InviteCandidateSingle from './InviteCandidateSingle';
import InviteCandidateSuccess from './InviteCandidateSuccess';
type AgencyInvitationProps = {
  close: Function;
  setCandidateForResend?: Function;
  candidateForResend?: Components.Schemas.UserResponse;
};
export default function InviteCandidate({ close, setCandidateForResend, candidateForResend }: AgencyInvitationProps): ReactElement {
  const { t } = useTranslation('addCandidatePage');
  const [tabValue, setTabValue] = useState(0);
  const [invitationSent, setInvitationSent] = useState(false);
  const [invitationResent, setInvitationResent] = useState(false);
  const [studentId, setStudentId] = useState('');

  const handleResendSuccess = () => {
    setInvitationResent(true);
    setTimeout(() => {
      if (setCandidateForResend != undefined) {
        setCandidateForResend(undefined);
      }

      setInvitationResent(false);
    }, 10000);
  };

  const handleSuccess = (studentId: string) => {
    setInvitationSent(true);
    setStudentId(studentId);

    setTimeout(() => {
      setInvitationSent(false);
    }, 10000);
  };

  return (
    <>
      {invitationSent || invitationResent ? (
        <InviteCandidateSuccess
          isVisible={invitationSent || invitationResent}
          invitationResent={invitationResent}
          studentId={studentId}
          handleClose={close}
        />
      ) : (
        <>
          {!(candidateForResend != undefined) && (
            <UniModalDrawerTabs
              setTabValue={setTabValue}
              tabValue={tabValue}
              tabs={[
                { title: t('tabs.one.title'), subtitle: t('tabs.one.subtitle'), Icon: <CandidateIcon /> },
                { title: t('tabs.multiple.title'), subtitle: t('tabs.multiple.subtitle'), Icon: <CandidateIcon /> },
              ]}
            />
          )}
          {tabValue === 0 && (
            <InviteCandidateSingle
              candidateForResend={candidateForResend}
              handleResendSuccess={handleResendSuccess}
              handleClose={close}
              handleSuccess={handleSuccess}
            />
          )}
          {tabValue === 1 && <InviteCandidateMultiple handleClose={close} handleSuccess={handleSuccess} />}
        </>
      )}
    </>
  );
}
