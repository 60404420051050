import { ReactComponent as ChatIcon } from 'assets/svg/Icons/ChatMessage.svg';
import { ReactComponent as EmailIcon } from 'assets/svg/Icons/Mail.svg';
import { ReactComponent as ChatIconWithMargins } from 'assets/svg/Icons/Metrics/ChatWithMargins.svg';
import { ReactComponent as EmailIconWithMargins } from 'assets/svg/Icons/Metrics/EmailWithMargins.svg';
import { ReactComponent as PhoneIconWithMargins } from 'assets/svg/Icons/Metrics/PhoneWithMargins.svg';
import { ReactComponent as ProfileIconWithMargins } from 'assets/svg/Icons/Metrics/ProfileWithMargins.svg';
import { ReactComponent as PhoneIcon } from 'assets/svg/Icons/PhoneIcon.svg';
import { ReactComponent as ProfileIcon } from 'assets/svg/Icons/Profile.svg';
import { UniAvatar, UniButton, UniIconWrapper, UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import useTidioChat from 'hooks/useTidioChat';
import { InfoWrapper, MetricWrapper, PartnerCardWrapper } from './UniPartnerCard.style';

export interface PartnerCardType {
  name?: string;
  src?: string;
  bio?: string;
  status?: string;
  phone?: string;
  email?: string;
}

const UniPartnerCard: React.FC<PartnerCardType> = ({ children, name, src, bio, status, phone, email, ...rest }) => {
  const { isMobile } = useBreakpoints();
  const { open } = useTidioChat();
  const chatClickHandler = () => {
    open();
  };
  return (
    <PartnerCardWrapper size={isMobile ? 'S' : 'L'}>
      <InfoWrapper avatar>
        <UniAvatar label={name || '-'} src={src} size={isMobile ? 'M' : 'XL'} />
        <UniTypography
          variant={isMobile ? 'subheader' : 'header3'}
          weight={isMobile ? 'semi-bold' : 'medium'}
          style={{ textAlign: 'center' }}
        >
          {name || '-'}
        </UniTypography>
      </InfoWrapper>
      <InfoWrapper>
        <UniTypography variant='footnote' style={{ marginBottom: isMobile ? '8px' : 0 }}>
          {bio || '-'}
        </UniTypography>
        <MetricWrapper>
          <UniIconWrapper color='secondary100' size={isMobile ? 'medium' : 'main'} withBackground={!isMobile}>
            {isMobile ? <ProfileIconWithMargins /> : <ProfileIcon />}
          </UniIconWrapper>
          <UniTypography variant='footnote'>{status || '-'}</UniTypography>
        </MetricWrapper>
        <MetricWrapper>
          <UniIconWrapper color='secondary100' size={isMobile ? 'medium' : 'main'} withBackground={!isMobile}>
            {isMobile ? <PhoneIconWithMargins /> : <PhoneIcon />}
          </UniIconWrapper>
          <UniTypography variant='footnote'>{phone || '-'}</UniTypography>
        </MetricWrapper>
        <MetricWrapper>
          <UniIconWrapper color='secondary100' size={isMobile ? 'medium' : 'main'} withBackground={!isMobile}>
            {isMobile ? <EmailIconWithMargins /> : <EmailIcon />}
          </UniIconWrapper>
          <UniTypography variant='footnote'>{email || '-'}</UniTypography>
        </MetricWrapper>
        <div>
          <UniButton
            variant='text'
            size='xsmall'
            disablePadding
            thin
            startIcon={
              <UniIconWrapper color='primary100' size={isMobile ? 'medium' : 'main'} withBackground={!isMobile}>
                {isMobile ? <ChatIconWithMargins /> : <ChatIcon />}
              </UniIconWrapper>
            }
            onClick={chatClickHandler}
          >
            Chat with your agent
          </UniButton>
        </div>
      </InfoWrapper>
    </PartnerCardWrapper>
  );
};

export default UniPartnerCard;
