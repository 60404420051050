import { Fade, InputProps, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CurrencyIso } from 'types';
import { ReactComponent as DownArrowIcon } from '../../../assets/svg/Icons/Arrow/Down.svg';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import { Colors } from '../../../constants';
import { currencyIso } from '../../../constants/currencyIso';
import UniIconWrapper from '../../_common/uniIconWrapper/UniIconWrapper';
import UniTypography from '../../_common/uniTypography/UniTypography';
import { Container, Error, Label, StyledAlertIcon } from '../uniTextField/UniTextField.style';
import { StyledInput } from './UniFeeInput.style';

interface Props extends Omit<InputProps, 'color'> {
  optional?: boolean;
  label?: string;
  control?: any;
  helperText?: string;
  borderColor?: 'primary' | 'alert';
  defaultCurrency?: CurrencyIso;
  setValue?: Function;
  getValues?: Function;
  fullWidth?: boolean;
  error?: boolean;
}

const UniFeeInput: React.FC<Props> = ({
  label,
  helperText,
  endAdornment,
  borderColor,
  startAdornment,
  optional,
  control,
  setValue,
  defaultCurrency,
  getValues,
  fullWidth,
  error,
  value,

  ...props
}) => {
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [currency, setCurrency] = useState(defaultCurrency || '');
  const { t } = useTranslation('forms');

  const lowerCaseCurrency = (currency: CurrencyIso) => {
    return currency?.slice(0, 1) + currency?.slice(1, currency?.length).toLowerCase();
  };

  const options = currencyIso?.map(item => ({ value: item?.value, text: lowerCaseCurrency(item?.text as CurrencyIso) }));

  const filterOptions = (options: CurrencyIso[], { inputValue }: { inputValue: string }) =>
    matchSorter(options, inputValue, {
      keys: ['value'],
    });

  useEffect(() => {
    if (!!helperText) {
      setErrorMsg(() => helperText);
    }
  }, [helperText]);

  return (
    <Container hasLabel={!!label}>
      {label && (
        <Label>
          <UniTypography variant='subheader' color='typographyBlack100'>
            {label}
          </UniTypography>
          {optional && (
            <UniTypography variant='footnote' color='typographyGray70'>
              Optional
            </UniTypography>
          )}
        </Label>
      )}
      <StyledInput
        {...props}
        style={{
          border: `1px solid ${error ? Colors.State.Alert_100 : Colors.Typography.Gray_50}`,
          width: `${fullWidth ? '100%' : '280px'}`,
        }}
        type='number'
        endAdornment={
          <Controller
            fullWidth
            control={control}
            name='currencyIso'
            defaultValue={defaultCurrency}
            // rules={{ required: 'Currency cannot be empty' }}
            render={() => (
              <Autocomplete
                style={{
                  display: 'grid',
                  justifyItems: 'start',
                  width: '125px',
                }}
                onChange={(event: any, value: any) => {
                  setCurrency(value?.value || '');
                  setValue && setValue('currencyIso', value?.value);
                }}
                defaultValue={options.find(item => item?.value === defaultCurrency)}
                disableClearable
                options={options}
                getOptionLabel={option => option?.text}
                popupIcon={
                  <UniIconWrapper color='typographyGray50' size='medium'>
                    <DownArrowIcon />
                  </UniIconWrapper>
                }
                renderInput={params => (
                  <TextField {...params} style={{ width: 'inherit', backgroundColor: 'inherit' }} variant='outlined' />
                )}
              />
            )}
          />
        }
      />

      <Fade in={!!helperText} unmountOnExit>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {errorMsg}
          </UniTypography>
        </Error>
      </Fade>
    </Container>
  );
};

export default UniFeeInput;
