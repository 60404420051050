import { ReactComponent as Check } from 'assets/svg/Icons/Check.svg';
import { UniIconWrapper, UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import InfoCard from './InfoCard';
import { StepContainer, StyledCircle, StyledStepWrapper } from './UniStepper.style';

interface Props {
  title: string;
  completed: boolean;
  texts: string[];
  buttonText: string;
  active?: boolean;
  index: number;
  route: string;
  visibleInfoCard: boolean;
  onClick: Function;
  collapsed: boolean;
}

const UniStep: React.FC<Props> = ({
  title,
  completed,
  texts,
  buttonText,
  active,
  index,
  route,
  visibleInfoCard = false,
  onClick,
  collapsed,
  children,
  ...rest
}) => {
  const { isMobile } = useBreakpoints();
  const clickHandler = () => {
    onClick();
  };
  return (
    <StyledStepWrapper>
      {!isMobile && visibleInfoCard && (
        <InfoCard onHide={clickHandler} title={title} texts={texts} buttonText={buttonText} route={route} isVisible={visibleInfoCard} />
      )}
      <StepContainer
        whileTap={{ scale: collapsed ? 1 : 0.98 }}
        completed={completed}
        active={false}
        onClick={clickHandler}
        collapsed={collapsed}
      >
        <StyledCircle>
          {completed ? (
            <UniIconWrapper size='small' color='secondary100'>
              <Check />
            </UniIconWrapper>
          ) : (
            <UniTypography color='secondary100' variant='footnote' weight='medium'>
              {index}
            </UniTypography>
          )}
        </StyledCircle>
        <UniTypography style={{ width: 'min-content' }} color='white100' variant='footnote' weight='medium'>
          {title}
        </UniTypography>
      </StepContainer>
    </StyledStepWrapper>
  );
};

export default UniStep;
