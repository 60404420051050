import { useRequestSession } from 'api';
import { UniPageLoader } from 'components';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TokenConnect = () => {
  const { pathname } = useLocation();
  const pathArray = pathname.split('/');
  const token = pathArray[pathArray.length - 1];
  const [requestSession, { isLoading, data }] = useRequestSession();

  useEffect(() => {
    requestSession({ token });
  }, []);

  //   const {} = useAuthUser();
  if (isLoading) return <UniPageLoader />;

  // return <Redirect to={getUserHomeScreen(data?.data.user.role)} />;
  return <></>;
};

export default TokenConnect;
