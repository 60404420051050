import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniStatus } from 'components';
import UniButton from 'components/_common/uniButton/UniButton';
import UniIconWrapper from 'components/_common/uniIconWrapper/UniIconWrapper';
import React, { ReactElement } from 'react';
import { useDrawers } from 'store';
import { ReactComponent as CheckIcon } from '../../assets/svg/Icons/Check.svg';
import { actionType } from '../../components/programsList/ProgramsListItem';

interface Props {
  program?: Components.Schemas.ProgramResponse;
  candidate?: Components.Schemas.UserDto;
  fileIds?: Components.Schemas.AttachRequiredDocumentRequest[];
  handleStatusChange?: (type: actionType) => void;
}

export default function ProgramsListItemAgentActions({ program, candidate, fileIds, handleStatusChange }: Props): ReactElement {
  const { openDrawer } = useDrawers();
  const applicationStatus = program?.application?.status;
  const isRecommended = candidate?.recommendedProgramIds.includes(program?.id!);
  const studentName = (candidate?.firstName != null && candidate?.lastName != null) ? `${candidate.firstName} ${candidate.lastName}` : candidate?.email!;

  const openApplicationDrawer = () => {
    openDrawer('application-drawer', { size: 'small', program: program, studentId: candidate?.id! });
  };

  if (!applicationStatus && isRecommended) {
    return (
      <Box display='flex'>
        <UniButton
          fullWidth={false}
          color='secondary'
          onClick={openApplicationDrawer}
          startIcon={
            <UniIconWrapper color='white100'>
              <CheckIcon />
            </UniIconWrapper>
          }
        >
          {`Create application for ${studentName}`}
        </UniButton>
      </Box>
    );
  } else if (!applicationStatus) {
    return (
      <UniButton
        fullWidth={false}
        color='primary'
        variant='main'
        onClick={e => {
          openDrawer('suggest-programme', { program });
        }}
      >
        Suggest or apply
      </UniButton>
    );
  } else if (program?.application) {
    return <UniStatus application={program.application} />;
  }
  return <></>;
}
