import { TRole } from './../constants/role';

export default (role?: TRole) => {
  const isMaster = role === 'MASTER';
  const isAdmin = role === 'ADMIN';
  const isHighSchool = role === 'HIGH_SCHOOL';
  const isAgency = role === 'AGENCY' || role === 'AGENCY_HOLDING';
  const isAgent = role === 'AGENT';
  const isConsultant = role === 'AGENCY' || role === 'AGENCY_HOLDING' || role === 'AGENT';
  const isUniversity = role === 'UNIVERSITY';
  const isStudent = role === 'STUDENT';

  return {
    isMaster,
    isAdmin,
    isHighSchool,
    isAgent,
    isAgency,
    isUniversity,
    isConsultant,
    isStudent,
  };
};
