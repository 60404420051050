import { Box } from '@material-ui/core';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledApplicationSuccessIllustration } from '../ReplyToUniversity.style';

interface Props {
  universityName: string;
}

const Step2: React.FC<Props> = ({ universityName }) => {
  const { t } = useTranslation('replyToUniversity');

  return (
    <>
      <UniTypography variant='body1' weight='medium' color='secondary100'>
        {t('texts.congrats').replace('$university_name', universityName)}
      </UniTypography>
      <Box my={5}>
        <StyledApplicationSuccessIllustration />
      </Box>
      <UniTypography variant='subheader' weight='regular' color='typographyBlack100'>
        {t('texts.willBeContacted')}
      </UniTypography>
    </>
  );
};

export default Step2;
