import styled from 'styled-components';

export const Flag = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

export const CountryName = styled.div`
  color: white;
  font-family: Poppins, sans-serif;
  font-weight: 700;
`;

export const StyledCountryTile = styled.a`
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  width: 160px;
  padding: 20px;
  border-radius: 12px;
  background-image: linear-gradient(-45deg, rgb(209, 46, 113, 0.7) 0%, rgb(73, 39, 92, 0.7) 100%);
  transition: all .25s;

  :hover {
    box-shadow: inset 0 0 0 10rem rgba(79, 35, 95, .5);
  }
`;
