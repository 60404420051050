import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniTypography } from 'components';
import React from 'react';
import { StyledFacultiesListItem } from './FacultiesListItem.style';
import { ReactComponent as EditIcon } from '../../assets/svg/Icons/Edit.svg';
import UniIconWrapper from 'components/_common/uniIconWrapper/UniIconWrapper';
import { useTranslation } from 'react-i18next';
type FacultyProps = {
  faculty: Components.Schemas.FacultyDto;
  onClick?: Function;
};
const FacultiesListItem: React.FC<FacultyProps> = React.memo(({ faculty, onClick }: FacultyProps) => {
  const { t } = useTranslation('universityFacultiesPage');
  return (
    <StyledFacultiesListItem onClick={() => onClick && onClick()}>
      <Box height='100%' display='flex' flexDirection='column' justifyContent='space-between'>
        <Box mx={4} mt={4}>
          <Box display='flex' justifyContent='space-between'>
            <UniTypography variant='header3' color='primary100' weight='medium'>
              {faculty.name}
            </UniTypography>
            <UniIconWrapper size='main' color='gray2_100'>
              <EditIcon />
            </UniIconWrapper>
          </Box>
          <Box>
            <UniTypography variant='body2' color='secondary100'>
              400 Programs
            </UniTypography>
          </Box>
        </Box>

        <Box display='flex' mx={4} mb={0}>
          <Box>
            <UniTypography variant='subheader' color='typographyGray70'>
              {t('list_item.applications')}
            </UniTypography>
            200
          </Box>
          <Box ml={6}>
            <UniTypography variant='subheader' color='typographyGray70'>
              {t('list_item.unprocessed')}
            </UniTypography>
            200
          </Box>
          <Box ml={6}>
            <UniTypography variant='subheader' color='typographyGray70'>
              {t('list_item.admitted')}
            </UniTypography>
            200
          </Box>
        </Box>
      </Box>
    </StyledFacultiesListItem>
  );
});
export default FacultiesListItem;
