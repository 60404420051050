import React from 'react';
import { Box } from '@material-ui/core';
import { UniTypography } from '../index';
import { StyledProductsListHeader } from './ProductsList.style';

interface Props {}

export const ListHeader: React.FC<Props> = () => {
  return (
    <StyledProductsListHeader>
      <Box />
      <UniTypography weight='medium' variant='subheader'>
        Name
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Category
      </UniTypography>
    </StyledProductsListHeader>
  );
};

export default ListHeader;
