import { Tabs } from '@material-ui/core';
import { useGetUniversity } from 'api';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useHeader } from 'store';
import { ReactComponent as LeftArrowIcon } from '../../assets/svg/Icons/Arrow/Left.svg';
import { UniBounceLoader, UniButton, UniHeader, UniIconWrapper, UniNavigationTab, UniPage } from '../../components';
import { BrowserRoutes } from '../../constants';
import UniversityPageGeneral from './UniversityPageGeneral';
import UniversityPageHeader from './UniversityPageHeader';
import UniversityPagePrograms from './UniversityPagePrograms';

const UniversityPage = () => {
  const { t } = useTranslation('universityPage');
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const { data: university, status } = useGetUniversity({ id: id });
  const { setHeader, clearHeader } = useHeader();

  useEffect(() => {
    setHeader({
      primaryButton: { onClick: () => history.goBack() },
    });
    return clearHeader;
  }, []);

  const handleChange = (event: any, value: string) => {
    history.push(value);
  };
  if (status === 'loading') {
    return <UniBounceLoader />;
  } else
    return (
      <>
        <UniHeader>
          <UniversityPageHeader
            university={university!}
            BackButton={
              <UniButton
                fullWidth={false}
                variant='text'
                color='neutral'
                onClick={() => history.goBack()}
                startIcon={
                  <UniIconWrapper color='typographyBlack100'>
                    <LeftArrowIcon />
                  </UniIconWrapper>
                }
              >
                {t('buttons.back')}
              </UniButton>
            }
          />

          <Tabs
            value={history.location.pathname}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            style={{ minHeight: 0 }}
          >
            <UniNavigationTab
              label={t('tabs.general')}
              value={
                history.location.pathname.startsWith(`${url}${BrowserRoutes.University.Profile.General}`)
                  ? history.location.pathname
                  : `${url}${BrowserRoutes.University.Profile.General}`
              }
            />
            <UniNavigationTab label={t('tabs.programs')} value={`${url}${BrowserRoutes.University.Profile.Programs}`} />
          </Tabs>
        </UniHeader>
        <UniPage>
          <Switch>
            <Redirect exact path={url} to={`${url}${BrowserRoutes.University.Profile.General}`}></Redirect>
            <Route path={`${url}${BrowserRoutes.University.Profile.Programs}`} render={() => <UniversityPagePrograms id={id} />} />
            <Route
              path={`${url}${BrowserRoutes.University.Profile.General}`}
              render={() => <UniversityPageGeneral university={university!} />}
            />
            <Route render={() => <UniversityPageGeneral university={university!} />} />
          </Switch>
        </UniPage>
      </>
    );
};

export default UniversityPage;
