import { Box, Grid } from '@material-ui/core';
import { useSearchProgramsAi } from 'api';
import usePostEvent, { EventType } from 'api/ai/usePostEvent';
import { TAiProgram } from 'api/ai/useSearchProgramsAi';
import InfiniteWindowedGrid from 'components/infiniteWindowedGrid/InfiniteWindowedGrid';
import ProgramCardAi from 'components/_common/ProgramCardAI/ProgramCardAi';
import UniButton from 'components/_common/uniButton/UniButton';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import creatableSelectStyles from 'components/_common/uniCreatableSelect/UniCreatableSelect.style';
import UniIconWrapper from 'components/_common/uniIconWrapper/UniIconWrapper';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { countriesMap } from 'constants/countriesMap';
import { useBreakpoints } from 'hooks';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MultiValue } from 'react-select';
import { useAuth } from 'store';
import { OptionType } from 'types';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/Close.svg';
import { UniHeader, UniPage } from '../../../components';
import { BrowserRoutes } from '../../../constants/browserRoutes';
import { countries as countryIso } from '../../../constants/countries';
import { StyledCategoriesWrapper, StyledFieldWrappers } from './AiProgramsSearch.style';

interface QueryParams {
  query?: string;
  country?: string | string[];
}

const AiProgramsSearch = () => {
  const { push: navigate } = useHistory();
  const [selectedKey, setSelectedKey] = useState<string | undefined>();
  const [filteredPrograms, setFilteredPrograms] = useState<TAiProgram[]>([]);
  const { profile } = useAuth();
  const { isMobile, isTablet } = useBreakpoints();
  const [trackEvent] = usePostEvent();
  const [keys, setKeys] = useState<OptionType[]>([]);
  const keyStrings = keys.map(item => item.value);

  const [countries, setCountries] = useState<OptionType[]>([]);
  const countriesStrings = countries.map(item => item.value);

  const { queries, pushQueriesToUrl } = useQueryStringParser<QueryParams>();

  const { data: programs, status: programsStatus } = useSearchProgramsAi({ keys: keyStrings, countries: countriesStrings });

  const categories = programs?.map(item => item.key);
  const programsLoading = programsStatus === 'loading';
  const programsTotal = filteredPrograms?.length || 0;

  const onTrackEvent = (event: EventType, data: any) => {
    const trackData: any = {
      student: profile,
      search: keys.map(item => item.value).join(','),
      countries: countries.map(item => item.value),
      source: 'younichoice.com',
    };
    trackEvent({
      event,
      data: { ...trackData, ...data },
    });
  };

  const onChangeSearch = (keys: MultiValue<OptionType>) => {
    const values = keys.map(item => item.value);
    setKeys(keys as OptionType[]);
    setSelectedKey(undefined);
    const queryValues = values.join(',');
    onTrackEvent('SEARCH', { search: queryValues });
    pushQueriesToUrl({ query: queryValues });
  };

  const onChangeCountries = (countries: MultiValue<OptionType>) => {
    const values = countries.map(item => item.value);
    setCountries(countries as OptionType[]);
    onTrackEvent('SELECT_COUNTRY', { countries: values });
    setSelectedKey(undefined);
    pushQueriesToUrl({ country: values });
  };

  useEffect(() => {
    if (selectedKey && programs) {
      const categoryIndex = programs.findIndex(category => category.key === selectedKey);
      if (categoryIndex !== -1) {
        onTrackEvent('SELECT_COUNTRY', { category: programs[categoryIndex].key });
        setFilteredPrograms(programs[categoryIndex].programs);
      }
    } else if (programs) {
      setFilteredPrograms(programs.map(({ programs }) => programs).flat(1));
    }
  }, [selectedKey, programs]);

  useEffect(() => {
    if (queries?.query) {
      const searchKeys = queries.query.split(',').map(item => ({ value: item, label: item }));
      setKeys(searchKeys);
    }
    if (queries?.country) {
      const countries = [queries.country]
        .flat(1)
        .map(item => ({ value: item, label: countryIso.find(country => country.code === item)?.name || item }));
      setCountries(countries);
    }
  }, []);

  return (
    <>
      <UniHeader>
        <Box display='flex' flexWrap='wrap' justifyContent='space-between' alignItems='center'>
          <UniTypography color='secondary100' variant={isMobile ? 'header3' : 'xl'} weight={isMobile ? 'semi-bold' : 'bold'}>
            Search anything and find your dream programme
          </UniTypography>
          {!isTablet && (
            <UniButton variant='secondary' size='small' onClick={() => navigate(BrowserRoutes.Student.CardList.Programs)}>
              Classic search
            </UniButton>
          )}
        </Box>
        <Box mt={1}>
          <UniTypography color='secondary100' variant={isMobile ? 'subheader' : 'body1'} weight='medium'>
            Powered by Artificial Intelligence
          </UniTypography>
        </Box>
        {isTablet && (
          <Box width='100%' display='flex' justifyContent='flex-end' mt={2}>
            <UniButton disablePadding variant='text' size='xsmall' onClick={() => navigate(BrowserRoutes.Student.CardList.Programs)}>
              Go to classic search
            </UniButton>
          </Box>
        )}
        <StyledFieldWrappers>
          <Box mt={3} mb={2}>
            <CreatableSelect
              isMulti
              styles={creatableSelectStyles}
              onChange={onChangeSearch}
              placeholder='Search anything to find your desired programme...'
              value={keys}
              options={[
                { value: 'Social Media', label: 'Social Media' },
                { value: 'Marketing', label: 'Marketing' },
                { value: 'I like computers', label: 'I like computers' },
              ]}
              getOptionLabel={option => option.label}
            />
          </Box>
          <Box mt={3} mb={2}>
            <Select
              isMulti
              styles={creatableSelectStyles}
              onChange={onChangeCountries}
              placeholder='Select countries'
              value={countries}
              options={countriesMap}
              getOptionLabel={option => option.label}
            />
          </Box>
        </StyledFieldWrappers>
        {!selectedKey && (
          <StyledCategoriesWrapper>
            {categories?.map(category => (
              <div>
                <UniButton variant='secondary' size='xsmall' borderRadius={[50, 50, 50, 50]} onClick={() => setSelectedKey(category)}>
                  {category}
                </UniButton>
              </div>
            ))}
          </StyledCategoriesWrapper>
        )}
        {selectedKey && (
          <Box display='flex' alignItems='center'>
            <UniTypography variant='body2' color='typographyGray100'>
              Results for:&nbsp;
            </UniTypography>
            <UniTypography variant='body2' weight='medium' color='primary100'>
              {selectedKey}
            </UniTypography>
            <UniIconWrapper color='primary100' onClick={() => setSelectedKey(undefined)}>
              <CloseIcon />
            </UniIconWrapper>
          </Box>
        )}

        <Box pb={3} />
      </UniHeader>
      <UniPage>
        <Grid item xs={12}>
          <InfiniteWindowedGrid
            isFetching={false}
            emptyStateType='programs'
            isLoading={programsLoading}
            minHeight={250}
            totalResults={programsTotal}
            results={filteredPrograms?.map((program, index: any) => (
              <ProgramCardAi
                program={program}
                path={BrowserRoutes.Student.Program.General.replace(':programId', program.id)}
                onClick={() => {
                  onTrackEvent('CLICK', { program });
                }}
                key={index}
              />
            ))}
          />
        </Grid>
      </UniPage>
    </>
  );
};

export default AiProgramsSearch;
