import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';
import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';

type Params = {
  agencyId: string;
};

export default function useGetAgency(params: Params, config?: QueryConfig<Components.Schemas.AgencyResponse>) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Agency.All, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.AgencyApi_getAgency(params.agencyId);
      return data;
    },
    {
      ...config,
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
