import { useApplication } from 'api';
import { AnimatedTabPanel, EmptyState, UniHeader, UniIconWrapper, UniPage, UniPageLoader } from 'components';
import ProgramsListItemAgentActions from 'components/programsList/ProgramsListItemAdminActions';
import { UniHerosection } from 'components/_common/uniHerosection/UniHerosection';
import ProgramGeneralInfo from 'components/_templates/programGeneralInfo/ProgramGeneralInfo';
import ProgramStatusReplies from 'components/_templates/programSatusReplies/ProgramStatusReplies';
import { levelOfStudyMap } from 'constants/levelOfStudy';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import { useHeader } from 'store';
import { ReactComponent as HourglassIcon } from '../../../assets/svg/Icons/Hourglass.svg';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location.svg';
import { BrowserRoutes } from '../../../constants';
import { TaskList } from 'components/taskList/TaskList';
import { useDrawers } from 'store';
import { UniButton } from 'components';
import ProgramCredentials from 'components/_templates/programCredentials/ProgramCredentials';

const ApplicationPage: React.FC = () => {
  const { openDrawer } = useDrawers();
  const { t } = useTranslation('programPage');
  const { id: applicationId } = useParams<{ id: string }>();
  const { url } = useRouteMatch();
  const { goBack } = useHistory();
  const { setHeader, clearHeader } = useHeader();
  const generalPath = BrowserRoutes.Agent.Application.General(applicationId);
    const statusPath = BrowserRoutes.Agent.Application.Status(applicationId);
    const tasksPath = BrowserRoutes.Agent.Application.Tasks(applicationId);
    const credentialsPath = BrowserRoutes.Agent.Application.Credentials(applicationId);
    const tabs = [generalPath, statusPath, tasksPath, credentialsPath];
    const currentRoute = tabs.indexOf(url);

  const { data: application, status: applicationStatus } = useApplication({
    id: applicationId,
  });

  useEffect(() => {
    setHeader({
      primaryButton: { onClick: () => goBack() },
    });
    return clearHeader;
  }, []);

  if (applicationStatus === 'loading' || !application || !application?.program) {
    return <UniPageLoader />;
  }

  return (
    <>
      <UniHeader>
        <UniHerosection
          candidate={application?.student}
          headerVariant='base'
          title={application.program.name}
          universityName={application.program.universityName}
          universityId={application.program.universityId}
          Actions={<ProgramsListItemAgentActions application={application} candidate={application?.student} />}
          metrics={[
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <LocationIcon />
                </UniIconWrapper>
              ),
              label: t('header.location'),
              value: `${application.program.address?.city || '-'}, ${application.program.address?.country || '-'}`,
            },
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <HourglassIcon />
                </UniIconWrapper>
              ),
              label: application?.program?.lengthOfStudy ? t('header.lengthOfStudy') : t('header.levelOfStudy'),
              value: application?.program?.lengthOfStudy
                ? `${application?.program?.lengthOfStudy?.value} ${application?.program?.lengthOfStudy?.unit}`
                : application.program?.levelOfStudy
                ? levelOfStudyMap[application.program.levelOfStudy]
                : 'Unspecified',
            },
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <HourglassIcon />
                </UniIconWrapper>
              ),
              label: t('header.dateApplied'),
              value: moment(application.createdAt).format('MMMM d, YYYY'),
            },
          ]}
          tabs={[
              { label: t('tabs.status'), path: statusPath },
              { label: t('tabs.general'), path: generalPath },
              { label: t('tabs.tasks'), path: tasksPath},
              { label: t('tabs.credentials'), path: credentialsPath},
          ]}
        />
      </UniHeader>
      <UniPage>
        <UniButton color='primary' onClick={() => openDrawer('add-task', {studentId: application?.student?.id, applicationId: application?.id })} style={{ marginBottom: '1rem' }}>
         Add task
        </UniButton>
        <AnimatePresence>
          <Switch>
            <Route path={generalPath}>
              <AnimatedTabPanel
                Content={<ProgramGeneralInfo program={application?.program!} />}
                index={0}
                currentItem={currentRoute}
                absoluteDirection={-1}
              />
            </Route>
            <Route path={statusPath}>
              <AnimatedTabPanel
                Content={application ? <ProgramStatusReplies application={application} /> : <EmptyState type='status' />}
                index={1}
                currentItem={currentRoute}
                absoluteDirection={1}
              />
             </Route>
             <Route path={tasksPath}>
               <AnimatedTabPanel
                 Content={<TaskList userType="agent" userId="" applicationId={application?.id} />}
                 index={2}
                 currentItem={currentRoute}
                 absoluteDirection={1}
               />
            </Route>
             <Route path={credentialsPath}>
               <AnimatedTabPanel
                 Content={application ? <ProgramCredentials applicationId={application.id} /> : <EmptyState type='credentials' />}
                 index={3}
                 currentItem={currentRoute}
                 absoluteDirection={1}
               />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default ApplicationPage;
