import { Box } from '@material-ui/core';
import { useBreakpoints } from 'hooks';
import React from 'react';
import UniCardWrapper, { UniCardWrapperProps } from '../uniCardWrapper/UniCardWrapper';

const UniCard: React.FC<UniCardWrapperProps> = ({ children, padding, ...props }) => {
  const { isMobile } = useBreakpoints();

  const paddingX = padding ? padding[0] : isMobile ? 2 : 3;
  const paddingY = padding ? padding[1] : isMobile ? 3 : 4;

  return (
    <UniCardWrapper {...props}>
      <Box height='100%' px={paddingX} py={paddingY}>
        {children}
      </Box>
    </UniCardWrapper>
  );
};

export default UniCard;
