import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniButton } from 'components';
import ApplicationReplyHistoryItem from 'components/applicationReplyHistoryItem/ApplicationReplyHistoryItem';
import { useBreakpoints } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useDrawers, useFooterbar } from 'store';
import { getMetaRole } from 'utils';

interface Props {
  application: Components.Schemas.ApplicationDto;
}

const sortByDate = (a: any, b: any) => {
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);
  return dateB.getTime() - dateA.getTime();
};

const ProgramStatusReplies: React.FC<Props> = ({ application }) => {
  const { t } = useTranslation('programStatusReplise');
  const { role } = useAuth(state => state.profile);
  const { isAdmin, isStudent } = getMetaRole(role);
  const { setFooter, clearFooter } = useFooterbar();
  const { openDrawer } = useDrawers();
  const { isMobile } = useBreakpoints();
  const applicationReplies = application?.statusUpdateHistory.sort(sortByDate);
  const lastReply = applicationReplies[0];
  const canReply = ((lastReply?.status === 'CONDITIONAL_ADMISSION' || lastReply?.status === 'INCOMPLETE') && !lastReply?.reply) || isAdmin;
  const applicationFee = application.feeInvoice;

  useEffect(() => {
    if (application) {
      setFooter({
        primaryButton: {
          label: 'Reply',
          onClick: openReplyToUniversityDrawer,
          disabled: !canReply,
        },
        onFavorite: undefined,
        status: application.status,
      });
    }
    return clearFooter;
  }, [application]);

  const openReplyToUniversityDrawer = () => {
    if (isAdmin) {
      openDrawer('reply-to-student', { size: 'small', application });
      return;
    }
    openDrawer('reply-to-university', { size: 'small', application });
  };

  return (
    <>
      {!isMobile && isStudent && (
        <Box mb={3}>
          <UniButton fullWidth={false} disabled={!canReply} onClick={openReplyToUniversityDrawer}>
            {t('buttons.reply')}
          </UniButton>
        </Box>
      )}

      {applicationReplies?.map((item, index) => (
        <ApplicationReplyHistoryItem {...item} key={index} />
      ))}

      <ApplicationReplyHistoryItem createdAt={application?.createdAt} files={[]} id='not-in-db' message='' status='SUBMITTED' />
      {applicationFee?.isPaid && (
        <ApplicationReplyHistoryItem
          createdAt={application?.createdAt}
          files={[]}
          id='not-in-db'
          message={`Application fee paid: ${applicationFee.totalGrossPrice.amount} ${applicationFee.totalGrossPrice.currencyIso}`}
          status='SUBMITTED'
          feePaid
        />
      )}
    </>
  );
};

export default ProgramStatusReplies;
