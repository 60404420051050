import { Box } from '@material-ui/core';
import { UniTypography } from 'components';
import React from 'react';
import { ReactElement } from 'react';
import { FacultyHeader } from './FacultyHeader.style';
import { ReactComponent as Illustration } from '../../assets/svg/Illustrations/candidateHeaderIllustration.svg';
import { Components } from 'client/UniClient';
import { useTranslation } from 'react-i18next';
interface Props {
  isOwner: boolean;
  faculty?: Partial<Components.Schemas.FacultyDto>;
  inputRef?: React.Ref<any>;
  BackButton?: React.ReactNode;
  TopActions?: React.ReactNode;
  MiddleActions?: React.ReactNode;
  title?: string;
  changeNavbar?: boolean;
  isEditing?: boolean;
}
export default function FacultyProfileHeader({
  isOwner,
  faculty,
  inputRef,
  BackButton,
  TopActions,
  MiddleActions,
  title,
  changeNavbar,
  isEditing,
}: Props): ReactElement {
  const { t } = useTranslation('universityFacultyPage');
  return (
    <>
      <FacultyHeader changeNavbar={changeNavbar}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' justifyContent='space-between' alignItems='flex-start' flexDirection='column'>
            {BackButton}

            <Box mt={8}>
              <Box display='flex' justifyContent='space-between'>
                <UniTypography variant='header2' color='primary100' weight='semi-bold'>
                  {faculty?.name || ''}
                </UniTypography>
              </Box>
              <Box mb={3}>
                <UniTypography variant='body2' color='secondary100'>
                  400 {t('header.programs')}
                </UniTypography>
              </Box>
            </Box>

            <Box display='flex' mb={8}>
              <Box>
                <UniTypography variant='subheader' color='typographyGray70'>
                  {t('header.applications')}
                </UniTypography>
                <UniTypography variant='header2' weight='semi-bold'>
                  200
                </UniTypography>
              </Box>
              <Box ml={6}>
                <UniTypography variant='subheader' color='typographyGray70'>
                  {t('header.unprocessed')}
                </UniTypography>
                <UniTypography variant='header2' weight='semi-bold'>
                  200
                </UniTypography>
              </Box>
              <Box ml={6}>
                <UniTypography variant='subheader' color='typographyGray70'>
                  {t('header.admitted')}
                </UniTypography>
                <UniTypography variant='header2' weight='semi-bold'>
                  200
                </UniTypography>
              </Box>
            </Box>
          </Box>
          <Box width='25%'>
            <Box>
              <Illustration />
            </Box>
          </Box>
        </Box>
      </FacultyHeader>
    </>
  );
}
