import { Box } from '@material-ui/core';
import UniPhoneInput from 'components/_inputs/uniPhoneInput/uniPhoneInput';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCompleteAgentOnboarding } from '../../../../api';
import { UniButton, UniCheckbox, UniTextField, UniTypography } from '../../../../components';
import { Colors } from '../../../../constants';
import { useErrorHandler } from '../../../../hooks';
import { AgentOnboardForm, OnboardFormStep2 } from './AgentOnboard';

interface Props {
  show: boolean;
  data: { email: string; phone: string; phonePrefix: string };
  nextStep: Function;
  onCancel: Function;
  previousStep: Function;
  setFormData: Function;
  onboardForm: AgentOnboardForm;
}
export default function AgentOnboardStep2({ show, nextStep, previousStep, setFormData, onboardForm, data }: Props): ReactElement {
  const { register, handleSubmit, errors, control, setValue } = useForm<OnboardFormStep2>({
    defaultValues: {
      email: data.email,
      phone: data.phone,
      phonePrefix: data.phonePrefix,
      password: '',
      terms: false,
    },
  });
  const [completeOnboarding, { status: completeOnboardingStatus, error: completeOnboardingError }] = useCompleteAgentOnboarding();
  const onboardingError = useErrorHandler(completeOnboardingError!);
  const onSubmit = (formData: OnboardFormStep2) => {
    setFormData({ ...formData, username: data.email });

    completeOnboarding(
      {
        ...onboardForm,
        ...formData,
        username: data.email,
      },
      {
        onSuccess() {
          nextStep();
        },
      },
    );
  };

  const emailError = errors?.email?.message ?? '';
  const phoneError = errors?.phone?.message ?? '';
  const passwordError = errors?.password?.message ?? '';
  const termsError = errors?.terms?.message ?? '';

  return (
    <>
      <Box display={show ? 'inline' : 'none'} alignSelf='flex-start' width='100%' maxWidth='800px'>
        <Box mb={7}>
          <UniTypography variant='header1' weight='medium'>
            Contact & Password
          </UniTypography>
          {/* <UniTypography>Loreum ipsum situm dolores</UniTypography> */}
        </Box>
        {onboardingError.message && (
          <Box mb={2} mt={-2}>
            <UniTypography color='stateAlert100'>{onboardingError.message}</UniTypography>
          </Box>
        )}
        <UniTextField
          disabled
          helperText={emailError}
          error={!!emailError}
          name='email'
          fullWidth
          label='Email'
          placeholder='Enter email'
          type='email'
          defaultValue={data.email}
          inputRef={register({ required: 'Email must not be empty!' })}
          backgroundColor={Colors.Gray1._100}
          labelColor='typographyGray70'
        />
        <UniPhoneInput
          optional
          control={control}
          fixed={'row'}
          setValue={setValue}
          helperText={phoneError}
          phonePrefix={data.phonePrefix}
          error={!!phoneError}
          name='phone'
          fullWidth
          label='Phone'
          placeholder='Enter phone'
          type='text'
          inputRef={register()}
          backgroundColor={Colors.Gray1._100}
          labelColor='typographyGray70'
        />
        <UniTextField
          helperText={passwordError}
          error={!!passwordError}
          name='password'
          fullWidth
          type='password'
          placeholder='Enter password'
          label='Account password'
          inputRef={register({
            required: 'Password must not be empty!',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters.',
            },
          })}
          backgroundColor={Colors.Gray1._100}
          labelColor='typographyGray70'
          endAdornment
        />
        <Box display='flex' alignItems='center'>
          <Controller
            control={control}
            name='terms'
            rules={{
              required: 'You have to agree with our terms and conditions!',
            }}
            render={({ onChange, onBlur, value }) => (
              <UniCheckbox onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={value} />
            )}
          />
          <UniTypography>Accept Terms & Conditions</UniTypography>
        </Box>
        {termsError && <UniTypography color='stateAlert100'>{termsError}</UniTypography>}
      </Box>
      <Box display={show ? 'flex' : 'none'} justifyContent='space-between' mt={4} width='100%'>
        <UniButton
          variant='text'
          fullWidth={false}
          onClick={() => {
            previousStep();
          }}
        >
          Previous step
        </UniButton>
        <UniButton
          color='primary'
          loading={completeOnboardingStatus === 'loading'}
          style={{ maxWidth: '200px' }}
          onClick={handleSubmit(onSubmit)}
        >
          Create Account
        </UniButton>
      </Box>
    </>
  );
}
