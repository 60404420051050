import create from 'zustand';
import { DrawerProps, DrawersState } from './types';

const defaultProps: DrawerProps = {
  size: 'small',
};

const useDrawers = create<DrawersState>((set, get) => ({
  drawer: undefined,
  title: undefined,
  props: defaultProps,
  progress: undefined,
  setTitle: title => set({ title }),
  setProgress: progress => set(state => ({ ...state, progress })),
  openDrawer: (drawer, props) => set(state => ({ ...state, drawer, props: { ...defaultProps, ...props } })),
  closeDrawer: () => set(state => ({ ...state, drawer: undefined, props: { side: state.props.side }, progress: undefined })),
}));

export default useDrawers;
