import useReplyToUniversity from 'api/applications/useReplyToUniversity';
import { Components } from 'client/UniClient';
import { AddNewDocument } from 'components';
import AnimatedTabPanel from 'components/_common/animatedTabPanel/AnimatedTabPanel';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import Step1 from './_steps/Step1';
import Step2 from './_steps/Step2';

type Inputs = {
  uploaded: string[];
  toUpload: File[];
};

interface Props {
  application?: Components.Schemas.ApplicationDto;
}
const sortByDate = (a: any, b: any) => {
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);
  return dateB.getTime() - dateA.getTime();
};

const ReplyToUniversity: React.FC<Props> = ({ application }) => {
  const [step, setStep] = useState(0);
  const { setProgress, closeDrawer } = useDrawers();
  const { control, handleSubmit } = useForm<Inputs>();
  const { t } = useTranslation('replyToUniversity');
  const [postReply, { data: postReplyData, status: postReplyStatus }] = useReplyToUniversity();
  const applicationReplies = application?.statusUpdateHistory.sort(sortByDate);
  const lastReply = applicationReplies?.[0];
  const [showUploadDoc, setShowUploadDoc] = useState('');
  const nextStep = () => setStep(prevStep => prevStep + 1);

  const onSubmit = (formData: Inputs) => {
    if (!!formData?.toUpload?.length) {
      postReply(
        { applicationId: application?.id!, statusUpdateId: lastReply?.id!, files: formData.toUpload },
        {
          onSuccess() {
            nextStep();
          },
        },
      );
    } else if (formData?.uploaded?.length) {
      postReply(
        { applicationId: application?.id!, statusUpdateId: lastReply?.id!, fileIds: formData.uploaded },
        {
          onSuccess() {
            nextStep();
          },
        },
      );
    }
  };

  const buttonTexts = [
    {
      secondaryCallback: closeDrawer,
      primaryCallback: () => handleSubmit(onSubmit)(),
      secondary: t('buttons.cancel'),
      primary: t('buttons.reply'),
      loading: postReplyStatus === 'loading',
      disabled: false,
    },
    {
      secondaryCallback: () => {},
      primaryCallback: closeDrawer,
      primary: t('buttons.back'),
    },
  ];

  useEffect(() => setProgress(step * 60 + 40), [step]);

  const handleAddDocumentDrawer = (value: string) => {
    setShowUploadDoc(value);
  };

  if (showUploadDoc) {
    return <AddNewDocument close={() => setShowUploadDoc('')} prefill={showUploadDoc} />;
  }

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel
            Content={
              <Step1
                openAddDocumentDrawer={handleAddDocumentDrawer}
                control={control}
                programName={application?.program.name}
                universityName={application?.program.universityName}
              />
            }
            index={0}
            currentItem={step}
          />
          <AnimatedTabPanel
            Content={<Step2 universityName={application?.program.universityName || 'University'} />}
            index={1}
            currentItem={step}
          />
        </AnimatePresence>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={
          buttonTexts[step]?.secondary
            ? {
                onClick: () => buttonTexts[step]?.secondaryCallback(),
                label: buttonTexts[step].secondary,
              }
            : undefined
        }
        PrimaryButton={{
          onClick: buttonTexts[step]?.primaryCallback,
          label: buttonTexts[step]?.primary,
          disabled: !!buttonTexts[step]?.disabled,
          loading: !!buttonTexts[step]?.loading,
        }}
      />
    </>
  );
};

export default ReplyToUniversity;
