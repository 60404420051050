import LayoutRoute from 'components/_layouts/layoutRoute/LayoutRoute';
import { lazy } from 'react';
import { BrowserRoutes } from '../constants';

const AdminServices = lazy(() => import('../views').then(({ AdminServices }) => ({ default: AdminServices })));
const AdminUniversityPage = lazy(() => import('views/_admin/university/UniversityPage'));
const AdminCandidateProfile = lazy(() => import('views/_admin/candidateProfile/AdminCandidateProfile'));
const AdminUniversities = lazy(() => import('views/_admin/universities/AdminUniversities'));
const AdminAgency = lazy(() => import('../views/_admin/adminAgency/adminAgency'));
const AdminAgencies = lazy(() => import('../views/_admin/agency/AdminAgencies'));
const AgencyProfile = lazy(() => import('../views/_admin/agency/AgencyProfile'));
const AdminCandidates = lazy(() => import('../views/_admin/candidates/AdminCandidates'));
const AdminPrograms = lazy(() => import('../views/_admin/programs/adminPrograms'));
const AdminProgramPage = lazy(() => import('../views/_admin/program-page/ProgramPage'));
const AdminHighSchools = lazy(() => import('../views/_admin/highSchools/HighSchools'));
const EventsPage = lazy(() => import('../views/_admin/events/Events'));
const EventPage = lazy(() => import('../views/_admin/event/Event'));
const PrepCenterPage = lazy(() => import('../views/_admin/prep-center/AdminPrepCenter'));
const StudentCheckInPage = lazy(() => import('../views/_admin/prep-center/AdminStudentCheckIn'));

const MasterRoutes = () => {
  return [
    <LayoutRoute exact path={BrowserRoutes.Admin.Default} key={BrowserRoutes.Admin.Default} component={AdminAgency} />,
    <LayoutRoute exact path={BrowserRoutes.Admin.Services.Default} key={BrowserRoutes.Admin.Services.Default} component={AdminServices} />,
    <LayoutRoute exact path={BrowserRoutes.Admin.AgenciesForAdmin} key={BrowserRoutes.Admin.AgenciesForAdmin} component={AdminAgencies} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Admin.Students.Default}
      key={BrowserRoutes.Admin.Students.Default}
      component={AdminCandidates}
    />,
    <LayoutRoute path={BrowserRoutes.Admin.Student.Default} key={BrowserRoutes.Admin.Student.Default} component={AdminCandidateProfile} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Admin.AgentsForAgencyAdmin}
      key={BrowserRoutes.Admin.AgentsForAgencyAdmin}
      component={AgencyProfile}
    />,
    <LayoutRoute exact path={BrowserRoutes.Admin.CardList.Default} key={BrowserRoutes.Admin.CardList.Default} component={AdminPrograms} />,
    <LayoutRoute exact path={BrowserRoutes.Admin.Program.Default} key={BrowserRoutes.Admin.Program.Default} component={AdminProgramPage} />,
    <LayoutRoute exact path={BrowserRoutes.Admin.Universities} key={BrowserRoutes.Admin.Universities} component={AdminUniversities} />,
    <LayoutRoute exact path={BrowserRoutes.Admin.HighSchools} key={BrowserRoutes.Admin.HighSchools} component={AdminHighSchools} />,
    <LayoutRoute
      path={BrowserRoutes.Admin.University.Default}
      key={BrowserRoutes.Admin.University.Default}
      component={AdminUniversityPage}
    />,
    <LayoutRoute
      path={BrowserRoutes.Admin.PrepCenter.Default}
      key={BrowserRoutes.Admin.PrepCenter.Default}
      component={PrepCenterPage}
    />,
    <LayoutRoute
      path={BrowserRoutes.Admin.PrepCenter.StudentCheckIn}
      key={BrowserRoutes.Admin.PrepCenter.StudentCheckIn}
      component={StudentCheckInPage}
    />,
    <LayoutRoute exact path={BrowserRoutes.Admin.Events.Default()} key={BrowserRoutes.Admin.Events.Default()} component={EventsPage} />,
    <LayoutRoute exact path={BrowserRoutes.Admin.Event.Default()} key={BrowserRoutes.Admin.Event.Default()} component={EventPage} />,
    <LayoutRoute exact path={'/app/adminpath'} key={'/app/adminpath'} component={AdminAgency} />,
  ];
};

export default MasterRoutes;
