import { UniButton, UniTypography } from 'components';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import { ReactComponent as HeroIllustration } from '../../../assets/partners/times-higher-education/Group 5318.svg';
import HappyGuy from '../../../assets/partners/times-higher-education/students.png';
import TimesLogoPng from '../../../assets/partners/times-higher-education/TimesLogo.png';
import { ReactComponent as UniLogoSvg } from '../../../assets/svg/LogoDark.svg';
import { Colors, Sizes } from '../../../constants';

export const StyledHomeWrapper = styled.div`
  /* overflow-x: hidden; */
  margin: ${({ theme }) => theme.spacing(-5, -1.5)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: ${({ theme }) => theme.spacing(-5, -3)};
  }
`;

export const StyledHeaderWrapper = styled.div`
  max-width: ${Sizes.MaxWidthNavbar}px;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  padding: ${({ theme }) => theme.spacing(2, 3)};

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const OutlinedUniButton = styled(({ color, ...props }) => <UniButton {...props} variant='text' color={color || 'primary'} />)`
  border: 1px solid ${({ color }) => color || Colors.Primary._100};
`;

export const StyledSectionWrapper = styled.div`
  position: relative;
  /* min-height: 100vh; */
  padding: ${({ theme }) => theme.spacing(10, 3)};
  background-color: #fff;
`;
export const StyledSectionInnerWrapper = styled.div`
  position: relative;
  max-width: ${Sizes.MaxWidthWrapper}px;
  /* min-height: 70vh; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledShadowedTitle = styled(UniTypography)`
  font-size: 50px;
  font-weight: 900;
  line-height: 62px;
  text-shadow: 1px 1px 0 ${Colors.Primary._100};
`;
export const StyledUnderlinedTitle = styled(UniTypography)`
  display: inline-block;
  font-size: 42px;
  font-weight: 900;
  line-height: 62px;
  position: relative;
  z-index: 10;

  &::before {
    content: '';
    background: #d12e71 0% 0% no-repeat padding-box;
    position: absolute;
    bottom: 10px;
    height: 8px;
    width: 100%;
    z-index: -1;
  }
`;

export const StyledHighlightedTitle = styled(UniTypography)`
  display: inline-block;
  font-size: 50px;
  font-weight: 900;
  line-height: 62px;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  margin-left: ${({ theme }) => theme.spacing(-2)}px;
  background: transparent linear-gradient(180deg, var(--unnamed-color-ef7624) 0%, #d12e7180 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #d12e71 0%, #d12e7180 100%) 0% 0% no-repeat padding-box;
`;

var settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const StyledUniversityCarousel = styled(({ ...props }) => <Slider {...settings} {...props} />)`
  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;
      margin: ${({ theme }) => theme.spacing(2, 2, 5, 2)};
      & > div {
        width: 100%;
      }
    }
  }
`;

export const StyledUniversityCard = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 30px #9a9ebd33;
  border-radius: 12px;
  height: 100%;
  width: 100%;

  display: grid;
  flex-direction: column;
  text-align: center;

  padding: ${({ theme }) => theme.spacing(3, 4)};
`;

export const StyledGetStartedSection = styled.div`
  position: relative;
  background: transparent linear-gradient(180deg, var(--unnamed-color-ef7624) 0%, #d12e7180 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #d12e71 0%, #d12e7180 100%) 0% 0% no-repeat padding-box;
  padding: ${({ theme }) => theme.spacing(5, 0)};
  padding: ${({ theme }) => theme.spacing(3, 4)};
`;

export const StyledGetStartedSectionInner = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: ${Sizes.MaxWidthWrapper}px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledGetStartedSectionActions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledUniversityCardMedia = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const StyledHeroIllustration = styled(HeroIllustration)`
  height: 100%;
  flex: 1;
  max-height: 600px;
  /* margin-right: -200px; */
`;
export const StyledFooterSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(8, 0, 3, 0)};
  background-color: ${Colors.Typography.Black_100};
`;
export const StyledFooterSectionInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${Sizes.MaxWidthWrapper}px;
`;
export const StyledFooterSectionSocials = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)}px;
  margin: 0 auto;
  max-width: 600px;
`;
export const UniLogo = styled(UniLogoSvg)`
  @media (max-width: 400px) {
    width: 120px;
  }
`;

export const TimesLogo = styled(({ ...props }) => <img {...props} src={TimesLogoPng} />)`
  @media (max-width: 400px) {
    width: 80px;
  }
`;
export const StyledAbsolutePeopleIllustration = styled(({ ...props }) => <img src={HappyGuy} {...props} />)`
  position: absolute;
  bottom: 0;
  left: 55%;
  width: 40%;
  height: auto;
  @media (max-width: 895px) {
    /* display: none; */
    left: 65%;
    width: 30%;
  }
  @media (max-width: 690px) {
    display: none;
  }
`;
