import { useProgramApplications } from 'api';
import { Components } from 'client/UniClient';
import { ApplicationCard, InfiniteWindowedGrid, UniPageTitle } from 'components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { BrowserRoutes } from '../../../../constants';

interface Props {
  program: Components.Schemas.ProgramResponse;
}

const Applications: React.FC<Props> = ({ program }: Props) => {
  const { t } = useTranslation('programPage');
  const history = useHistory();
  const {
    data: applications,
    status: applicationsStatus,
    isFetching: isFetchingApplications,
    fetchMore: fetchMoreApplications,
  } = useProgramApplications({ programId: program?.id! });

  const applicationsLoading = applicationsStatus === 'loading';
  const applicationsFlat = useMemo(() => {
    return applications?.map(app => app.results).flat() || [];
  }, [applications]);
  const applicationsTotal = useMemo(() => (applications ? applications[0].total : 0), [applications]);
  const applicationsLeft = applicationsTotal - applicationsFlat.length;
  return (
    <InfiniteWindowedGrid
      isFetching={isFetchingApplications}
      emptyStateType='applications'
      totalResults={applicationsTotal}
      fetchMore={fetchMoreApplications}
      isLoading={applicationsLoading}
      hasMore={applicationsLeft > 0}
      remainingResults={applicationsLeft}
      minHeight={200}
      LeftComponent={
        <div style={{ minWidth: '200px' }}>
          <UniPageTitle title={t('labels.all_candidates')} size='S' />
        </div>
      }
      results={applicationsFlat?.map((application, index) => (
        <ApplicationCard
          key={index}
          variant='programmePage'
          studentName={`${application?.student?.firstName + ' ' + application?.student?.lastName}`}
          studentAddress={application?.student?.studentProfile?.address}
          status={application?.status}
          path={BrowserRoutes.University.Application.General(application.id)}
        />
      ))}
    />
  );
};

export default Applications;
