import React from 'react';
import AiProgramsSearch from 'components/_templates/aiProgramsSearch/AiProgramsSearch';

export const AiSearch: React.FC = () => {
  return (
    <>
      {/* <SuggestProgram /> */}
      <AiProgramsSearch />
    </>
  );
}

export default AiSearch;
