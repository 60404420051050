import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { uniq } from 'lodash';
import { QueryConfig, useQuery } from 'react-query';
import { onlyUnique } from 'utils/arrayUnique';
import { CacheKeys, languageOptions } from '../../constants';
import { countries } from '../../constants/countries';
import { LevelOfStudy, levelOfStudyMap } from '../../constants/levelOfStudy';
import { useClient } from '../../store';
import { Nationality, OptionType } from '../../types';

export type TDisciplines =
  | 'AGRICULTURE_FORESTRY'
  | 'APPLIED_SCIENCES'
  | 'ARTS_DESIGN_ARCHITECTURE'
  | 'BUSINESS_MANAGEMENT'
  | 'COMPUTER_SCIENCE_IT'
  | 'EDUCATION_TRAINING'
  | 'ENGINEERING_TECHNOLOGY'
  | 'ENVIRONMENTAL_EARTH_SCIENCES'
  | 'HOSPITALITY_LEISURE_SPORTS'
  | 'HUMANITIES'
  | 'JOURNALISM_MEDIA'
  | 'LAW'
  | 'MEDICINE_HEALTH'
  | 'NATURAL_SCIENCES_MATHS'
  | 'SOCIAL_SCIENCES'
  | 'OTHER';

export const disciplines = [
  { value: 'AGRICULTURE_FORESTRY', text: 'Agriculture Forestry' },
  { value: 'APPLIED_SCIENCES', text: 'Applied Sciences' },
  { value: 'ARTS_DESIGN_ARCHITECTURE', text: 'Arts Design Architecture' },
  { value: 'BUSINESS_MANAGEMENT', text: 'Business Management' },
  { value: 'COMPUTER_SCIENCE_IT', text: 'Computer Science It' },
  { value: 'EDUCATION_TRAINING', text: 'Education Training' },
  { value: 'ENGINEERING_TECHNOLOGY', text: 'Engineering Technology' },
  {
    value: 'ENVIRONMENTAL_EARTH_SCIENCES',
    text: 'Environmental Earth Sciences',
  },
  {
    value: 'HOSPITALITY_LEISURE_SPORTS',
    text: 'Hospitality Leisure Sports',
  },
  { value: 'HUMANITIES', text: 'Humanities' },
  { value: 'JOURNALISM_MEDIA', text: 'Journalism Media' },
  { value: 'LAW', text: 'Law' },
  { value: 'MEDICINE_HEALTH', text: 'Medicine Health' },
  { value: 'NATURAL_SCIENCES_MATHS', text: 'Natural Sciences Maths' },
  { value: 'SOCIAL_SCIENCES', text: 'Social Sciences' },
  { value: 'OTHER', text: 'Other' },
];

const applicationDeadlineMap = {
  THIS_MONTH: 'This month',
  NEXT_THREE_MONTHS: 'Next three months',
  NEXT_SIX_MONTHS: 'Next six months',
  NEXT_TWELVE_MONTHS: 'Next 12 months',
};
type applicationDeadline = 'THIS_MONTH' | 'NEXT_THREE_MONTHS' | 'NEXT_SIX_MONTHS' | 'NEXT_TWELVE_MONTHS';
const applicationDeadlineForUniversity = [
  'THIS_MONTH',
  'NEXT_THREE_MONTHS',
  'NEXT_SIX_MONTHS',
  'NEXT_TWELVE_MONTHS',
] as applicationDeadline[];
type Params = {
  _page?: number;
  universityId?: string;
};
const parseApplicationDeadline = (filterApplicationDeadline: applicationDeadline[]): OptionType[] =>
  filterApplicationDeadline
    ?.filter(item => filterApplicationDeadline.includes(item))
    ?.map(item => ({ label: applicationDeadlineMap[item], value: item })) || [];
const parseCities = (cities: any[]) => {
  const countryMap: any = {};
  cities.forEach(
    item =>
      (countryMap[item.country] = uniq(
        item.cities
          .filter((item: any) => !!item)
          .filter(onlyUnique)
          .map((item: any) => ({ value: item.trim(), label: item.trim() })),
      )),
  );
  return countryMap;
};

export default function useProgramFilters(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Agency.ProgramFilters, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.SearchApi_getProgramFilters();
      let faculties: Components.Schemas.FacultyDto[] = [];
      if (params?.universityId) {
        const { data: facultiesRes } = await client!.FacultyApi_getUniversityFaculties({ universityId: params.universityId });
        faculties = facultiesRes;
      }
      const { data: languages } = await client!.SearchApi_getLanguageFilters();

      return {
        countries: countries.filter(item => data.cities.map(item => item.country).includes(item.code as Nationality)),
        facultyNames: data.facultyNames.filter(item => !!item.label.trim()),
        faculties,
        levelsOfStudy: data.levelsOfStudy.map(item => ({ label: levelOfStudyMap[item as LevelOfStudy], value: item })),
        subjects: disciplines,
        universityNames: data.universityNames.filter(item => !!item.label.trim()),
        langaugesOfInstruction: languageOptions.filter(item => languages.includes(item.code)),
        // This should work when the API will
        cities: uniq(
          data.cities
            .map(item => item.cities)
            .flat(1)
            .map(item => ({ value: item, label: item })),
        ),
        citiesMap: parseCities(data.cities),
        applicationDeadline: parseApplicationDeadline(applicationDeadlineForUniversity),
      };
    },
    { refetchOnWindowFocus: false, onError(error: AxiosError) {} },
  );
}
