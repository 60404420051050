import { UniTypography } from 'components';
import { ReactComponent as SuccessIllustration } from '../../../../../assets/svg/Illustrations/invitationSentIllustration.svg';
import { useTranslation } from 'react-i18next';

const Step2: React.FC = () => {
  const { t } = useTranslation('partnerServicesDrawers');
  return (
    <>
      <SuccessIllustration />
      <UniTypography variant='header3' color='secondary100' weight='medium'>
        {t('request_service.request_sent')}
      </UniTypography>
      <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
        {t('request_service.verify')}
      </UniTypography>
    </>
  );
};

export default Step2;
