import LayoutRoute from 'components/_layouts/layoutRoute/LayoutRoute';
import React, { lazy } from 'react';
import { BrowserRoutes } from '../constants';
const TimesHigherEducationHome = lazy(() =>
  import('../views').then(({ TimesHigherEducationHome }) => ({ default: TimesHigherEducationHome })),
);

const Partners = () => {
  return [
    <LayoutRoute
      key={BrowserRoutes.Partners.THE.Default}
      path={BrowserRoutes.Partners.THE.Default}
      component={() => <TimesHigherEducationHome />}
    />,
  ];
};

export default Partners;
