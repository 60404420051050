import { AxiosError } from 'axios';
import { QueryConfig, useInfiniteQuery } from 'react-query';
import { AgencyFilters } from 'types';
import { ApiKeys, CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  _page?: number;
  _pageSize?: number;
  query?: string;
  sort?: 'NAME' | 'CANDIDATES_COUNT' | 'ADMITTED_COUNT' | 'ENROLLED_COUNT' | 'DATE_ADDED';
  order?: 'ASC' | 'DESC';
  filters?: AgencyFilters;
};

export default function useAgencies(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  return useInfiniteQuery(
    [CacheKeys.Agency.All, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.SearchApi_searchAgencies({
        ...params?.filters,
        query: params?.query || undefined,
        page,
        pageSize: ApiKeys.PageSize.Candidates,
        sort: params?.sort,
        order: params?.order,
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Candidates || lastPage.page * ApiKeys.PageSize.Candidates === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
