import usePatchAchievement from 'api/achievements/usePatchAchievement';
import usePatchTodo from 'api/todos/usePatchTodo';
import { Components } from 'client/UniClient';
import { AnimatedTabPanel } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDrawers } from 'store';
import ActionsFooter from './_components/ActionsFooter';
import Step1 from './_steps/Step1';
import Step2 from './_steps/Step2';
import Step3 from './_steps/Step3';

type Form = {
  title: string;
  description: string;
  // category: ActivitiesCategory;
  deadline?: string;
  ended?: string;
};

export interface EditActivityItemDrawerProps {
  achievement?: Components.Schemas.AchievementDto;
  todo?: Components.Schemas.ToDoDto;
}

const EditActivityItemDrawer: React.FC<EditActivityItemDrawerProps> = ({ achievement, todo }) => {
  const studentId = achievement?.student.id || todo?.student.id;
  const activityId = achievement?.id || todo?.id;
  const defaultValues: Form = {
    title: achievement?.title || todo?.title || '',
    description: achievement?.description || todo?.description || '',
    deadline: todo?.deadline || '',
    ended: achievement?.completedAt || '',
  };
  const [formState, setFormState] = useState<Partial<Form>>(defaultValues);
  const { control, handleSubmit, reset } = useForm<Form>({
    defaultValues,
  });
  const [patchAchievement, { isLoading: patchAchievementIsLoading }] = usePatchAchievement();
  const [patchTodo, { isLoading: patchTodoIsLoading }] = usePatchTodo();
  const isLoading = patchTodoIsLoading || patchAchievementIsLoading;
  const [step, setStep] = useState(0);
  const type = !!achievement ? 'ACHIEVEMENT' : 'TODO';
  const { setProgress } = useDrawers();

  const prevStep = () => {
    setStep(prev => prev - 1);
    handleSubmit(onSaveForm)();
  };

  const nextStep = () => {
    if (step === 1) {
      handleSubmit(onSubmit)();
      return;
    }
    setStep(prev => prev + 1);
    handleSubmit(onSaveForm)();
  };

  const onSaveForm = (formData: Form) => {
    setFormState(prev => ({ ...prev, ...formData }));
    reset({ ...formState, ...formData });
  };

  const onSubmit = (formData: Form) => {
    if (!type || !studentId || !activityId) return;
    const updateRequest = {
      ...formState,
      ...formData,
    };

    if (type === 'TODO') {
      patchTodo(
        {
          ...updateRequest,
          toDoId: activityId,
          deadline: formData?.deadline!,
        },
        {
          onSuccess() {
            setStep(2);
          },
        },
      );
    }
    if (type === 'ACHIEVEMENT') {
      patchAchievement(
        {
          ...updateRequest,
          achievementId: activityId,
          completedAt: formData?.ended!,
        },
        {
          onSuccess() {
            setStep(2);
          },
        },
      );
    }
  };

  useEffect(() => {
    setProgress(step * 30 + 40);
  }, [step]);

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel Content={<Step1 control={control} />} index={0} currentItem={step} />
          <AnimatedTabPanel Content={<Step2 type={type} control={control} />} index={1} currentItem={step} />
          <AnimatedTabPanel Content={<Step3 />} index={2} currentItem={step} />
        </AnimatePresence>
      </UniModalDrawerContent>
      <ActionsFooter loading={isLoading} step={step} nextStep={nextStep} prevStep={prevStep} />
    </>
  );
};

export default EditActivityItemDrawer;
