import React, { ReactElement } from 'react';
import { UniButton, UniCardWrapper } from 'components';
import { Box } from '@material-ui/core';
import { useBreakpoints } from 'hooks';
import UniTypography from '../uniTypography/UniTypography';
import { StyledUniCardFooter } from './UniInternshipCard.style';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  description?: string;
  steps: { title: string; checked: boolean; component: ReactElement }[];
  deadline?: string;
  link?: string;
  alertNotification?: string;
}

export const UniInternshipCard: React.FC<Props> = ({ title, description, steps, deadline, link }) => {
  const { t } = useTranslation('studentAppTracker');
  const { isMobile } = useBreakpoints();

  return (
    <UniCardWrapper size='L' border>
      <Box
        pt={4}
        pl={3}
        pr={3}
        pb={10}
        display='grid'
        alignItems='start'
        width={isMobile ? 'calc(100vw - 102px)' : 'inherit'}
      >
        <UniTypography
          variant='body2'
          color='typographyBlack100'
          weight='semi-bold'
          style={{
            height: '48px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
          }}
        >
          {title}
        </UniTypography>
        {description && (
          <Box mt={1}>
            <UniTypography
              variant='footnote'
              color='typographyGray100'
              weight='regular'
              style={{
                height: '40px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
              }}
            >
              {description}
            </UniTypography>
          </Box>
        )}
        {steps.map(step => (
          <Box display='grid' mb={2}>
            <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
              {step.title}
            </UniTypography>
            {step.component}
          </Box>
        ))}
        {deadline && (
          <Box display='grid' mb={2}>
            <UniTypography variant='footnote' color='typographyGray100' weight='regular'>
              {t('internships.cards.deadline')}
            </UniTypography>
            <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
              {deadline}
            </UniTypography>
          </Box>
        )}
        {link && (
          <Box display='grid' mb={2}>
            <UniTypography variant='footnote' color='typographyGray100' weight='regular'>
              {t('internships.cards.link')}
            </UniTypography>
            <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
              {link}
            </UniTypography>
          </Box>
        )}
      </Box>
      <StyledUniCardFooter
        type='baseUnlabeledStatus'
        variants='XS'
        PrimaryButton={
          <>
            <UniButton variant='main' color='primary' size='small' style={{ marginRight: '8px' }}>
              {t('internships.cards.apply')}
            </UniButton>
            <UniButton variant='main' color='primaryFade' size='small'>
              {t('internships.cards.reject')}
            </UniButton>
          </>
        }
      />
    </UniCardWrapper>
  );
}

export default UniInternshipCard;
