import { UniCard, UniPageTitle } from 'components';
import styled from 'styled-components';
import { theme } from 'theme';

export const StyledUniPageTitle = styled(UniPageTitle)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(3)}px;
    ${theme.breakpoints.down('sm')}{
        margin-bottom:${theme.spacing(2)}px;
     }   
    `}
`;

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-column-gap: ${theme.spacing(3)}px;
  align-items: start;
  width: 100%;
  margin-bottom: ${theme.spacing(5)}px;

  & > *:last-child {
    position: sticky;
    top: ${({ theme }) => theme.sizes.authNavbarHeight + 16}px;
  }

  @media (max-width: 1088px) {
    grid-template-columns: 1fr;
    & > *:last-child {
      grid-row: 1;
      position: relative;
      top: 0;
    }
  }
`;

export const StyledUniCard = styled(UniCard)`
  margin-bottom: ${theme.spacing(3)}px;
  ${({ theme }) => `${theme.breakpoints.down('sm')}{
     margin-bottom: ${theme.spacing(2)}px;
  }`}

  iframe {
    width: 100%;
    height: 500px;
    object-fit: contain;
    ${({ theme }) => `${theme.breakpoints.down('sm')}{
     height: 300px;
  }`}
  }
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;
