import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Payload = {
  applicationId: string;
  statusUpdateId: string;
  files?: File[];
  fileIds?: string[];
};

const useReplyToUniversity = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      if (!!payload.files) {
        let promises = payload.files.map(file => {
          let formData = new FormData();
          formData.append('documentType', 'APPLICATION_REPLY');
          formData.append('file', file);
          return client!.FileApi_uploadDocument(undefined, formData as unknown as Components.Schemas.UploadDocumentRequest);
        });
        return Promise.all(promises)
          .then(responses =>
            client!.ApplicationApi_replyToStatusUpdate(
              {
                applicationId: payload.applicationId,
                statusUpdateId: payload.statusUpdateId,
              },
              {
                fileIds: responses.map(item => item.data.id),
              },
            ),
          )
          .then(({ data }) => data);
      } else {
        return client!
          .ApplicationApi_replyToStatusUpdate(
            {
              applicationId: payload.applicationId,
              statusUpdateId: payload.statusUpdateId,
            },
            {
              fileIds: payload.fileIds!,
            },
          )
          .then(({ data }) => data);
      }
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.General.Program);
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default useReplyToUniversity;
