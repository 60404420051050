import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';

interface ErrorType {
  statusCode: number;
  message: string;
  error: string;
}
const useErrorHandler = (error?: Partial<AxiosError<any>>) => {
  const [errorHandler, setErrorHandler] = useState<ErrorType>({
    error: '',
    statusCode: 200,
    message: '',
  });
  useEffect(() => {
    if (error) {
      setErrorHandler({
        ...error,
        error: error.response?.data?.error || '',
        statusCode: error.response?.data?.statusCode || 200,
        message: error.response?.data?.message || '',
      });
    }
  }, [error]);
  return errorHandler;
};

export default useErrorHandler;
