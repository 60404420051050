import { devUrl, prodUrl } from 'constants/envConfig';
import io from 'socket.io-client';
import create from 'zustand';
import { NotificationItem } from '../../types';
import { NotificationsState } from './types';

const useNotificationsSocket = create<NotificationsState>((set, get) => ({
  socket: undefined,
  notifications: [],
  initialize: async token => {
    let socket = get().socket;
    if (!socket) {
      if (!token) {
        console.error('No session token to init notifications socket');
        return;
      }
      const socketUri = process.env.NODE_ENV === 'production' ? prodUrl : devUrl;
      socket = io(`${socketUri}/notifications`, {
        query: { token },
        path: '/socket',
      });

      socket.on('connect', () => {
        socket.emit('initRooms');
      });
      return set({
        socket,
      });
    }
  },

  subscribe: callback => {
    const socket = get().socket;
    if (!socket) return;

    socket.on('notification', (notification: NotificationItem, error: any) => {
      callback(notification);
    });
  },

  disconnect: () => {
    const socket = get().socket;

    if (socket) socket.disconnect();
  },
}));

export default useNotificationsSocket;
