import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components, Paths } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Payload = Partial<Components.Schemas.UpdateProgramRequest>;

type Queries = Paths.ProgramApiUpdateProgram.PathParameters;

interface Params {
  payload: Payload;
  pathParams: Queries;
}

const useUpdateProgram = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async ({ pathParams, payload }: Params) => {
      return client!.ProgramApi_updateProgram(pathParams, payload as Components.Schemas.UpdateProgramRequest);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Admin.Programs);
        queryCache.invalidateQueries(CacheKeys.General.Program);
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default useUpdateProgram;
