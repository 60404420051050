import { Box } from '@material-ui/core';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledQueueIllustration } from '../PublishPublicProfile.style';

interface Props {
  // program: Components.Schemas.ProgramResponse;
}

export default function Step1({}: Props): ReactElement {
  const { t } = useTranslation('studentEnrollmentDrawer');

  return (
    <>
      <Box mb={3}>
        <UniTypography variant='header3' color='secondary100' weight='medium'>
          Publish your Youni Profile
        </UniTypography>
        <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
          All information on your profile you decided to show will be visible by anyone with the link. Are you sure you want to publish?
        </UniTypography>
      </Box>
      <Box display='flex' justifyContent='center'>
        <StyledQueueIllustration />
      </Box>
    </>
  );
}
