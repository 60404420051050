import { Box, Fade } from '@material-ui/core';
import { useUpdateEmail } from 'api/auth/useUpdateEmail';
import { ReactComponent as ErrorSvg } from 'assets/svg/Icons/Error.svg';
import { UniModalDrawerButtons, UniTextField, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { Error, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import { Colors } from 'constants/uiConfig';
import { useErrorHandler } from 'hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';

interface ChangeEmailInputs {
  currentEmail: string;
  newEmail: string;
  confirmEmail: string;
  password: string;
}

interface Props {
  changeDrawer: Function;
}

const ChangeEmailForm: React.FC<Props> = ({ changeDrawer }) => {
  const { t } = useTranslation('forms');
  const { email } = useAuth(state => state.profile);
  const [updateEmail, { isLoading, error }] = useUpdateEmail();
  const updateEmailError = useErrorHandler(error || undefined);
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ChangeEmailInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { currentEmail: '', newEmail: '', confirmEmail: '', password: '' },
  });

  const onSubmit = (formData: ChangeEmailInputs) => {
    updateEmail(
      { email: formData.newEmail, password: formData.password },
      {
        onSuccess() {
          changeDrawer('confirmation-email-sent');
        },
        onError() {},
      },
    );
  };
  const onCancel = () => {
    changeDrawer('contact-form');
  };

  return (
    <>
      <UniModalDrawerContent>
        <Box mb={3}>
          <UniTypography color='typographyGray100' variant='subheader'>
            {t('form.change_email.text.1')}
          </UniTypography>
          <br />
          <UniTypography color='typographyGray100' variant='subheader'>
            {t('form.change_email.text.2.part1')}
            <span style={{ color: Colors.Primary._100 }}>
              <a href='mailto: support@uniapply.org'>{` ${t('form.change_email.text.2.link')} `}</a>
            </span>
            {t('form.change_email.text.2.part2')}
          </UniTypography>
        </Box>
        <UniTextField label={t('form.change_email.current_email')} type='email' value={email} fullWidth disabled />
        <UniTextField
          label={t('form.change_email.new_email')}
          inputRef={register({
            required: `${t('form.errors.change_email.2')}`,
            validate: {
              validEmail: (value: string) => /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/.test(value) || `${t('form.errors.email_2')}`,
              emailsAreNotEqual: (value: string) => value !== getValues('currentEmail') || `${t('form.errors.change_email.4')}`,
            },
          })}
          name='newEmail'
          type='email'
          error={!!errors.newEmail?.message}
          helperText={errors.newEmail?.message}
          fullWidth
        />
        <UniTextField
          label={t('form.change_email.confirm_email')}
          inputRef={register({
            required: `${t('form.errors.change_email.3')}`,
            validate: {
              validEmail: (value: string) => /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/.test(value) || `${t('form.errors.email_2')}`,
              emailsAreEqual: (value: string) => value === getValues('newEmail') || `${t('form.errors.change_email.5')}`,
            },
          })}
          name='confirmEmail'
          type='email'
          error={!!errors.confirmEmail?.message}
          helperText={errors.confirmEmail?.message}
          fullWidth
        />
        <UniTextField
          label={t('form.change_email.password')}
          inputRef={register({
            required: `${t('form.errors.change_email.password')}`,
            validate: (value: string) => value.trim() !== '' || `${t('form.errors.password')}`,
          })}
          name='password'
          type='password'
          error={!!errors.password?.message}
          helperText={errors.password?.message}
          fullWidth
        />
        <Fade in={!!updateEmailError.message} unmountOnExit>
          <Error>
            <StyledAlertIcon>
              <ErrorSvg />
            </StyledAlertIcon>
            <UniTypography variant='footnote' component='span' color='stateAlert100'>
              {updateEmailError.message}
            </UniTypography>
          </Error>
        </Fade>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: t('buttons.save_changes'),
          loading: isLoading,
        }}
        SecondaryButton={{ onClick: onCancel, label: t('buttons.cancel') }}
      />
    </>
  );
};
export default ChangeEmailForm;
