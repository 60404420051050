import React, { useState } from 'react';
import UniTaskCard from '../_common/uniTaskCard/UniTaskCard';
import { StyledTypeHeadline } from '../activitiesList/ActivitiesList.style';
import { InfiniteWindowedGrid, UniTypography } from '../index';
import { Box } from '@material-ui/core';
import useStudentTasks from '../../api/tasks/useStudentTasks';
import useAgentTasks from '../../api/tasks/useAgentTasks';
import useApplicationTasks from '../../api/tasks/useApplicationTasks';
import { Paths } from '../../client/UniClient';
import { useQueryStringParser } from '../../hooks/useQueryStringParser';
type Status = Paths.TaskApiSearchStudentTasks.Parameters.Status;
type Sort = Paths.TaskApiSearchStudentTasks.Parameters.Sort;
type Order = Paths.TaskApiSearchStudentTasks.Parameters.Order;
type TaskFilterQueries = Paths.TaskApiSearchAgentTasks.QueryParameters;

interface Props {
  userType: 'agent' | 'student';
  userId: string;
  applicationId: string;
}

export const TaskList: React.FC<Props> = ({ userType, userId, applicationId }) => {
  const { queries } = useQueryStringParser<TaskFilterQueries>();
  const statusFilter = queries?.status;
  const studentFilter = queries?.assigneeUserId;
  const [sortBy, setSortBy] = useState<Sort>('CHRONOLOGICAL');
  const [sortOrder, setSortOrder] = useState<Order>('DESC');

  const {
    data: taskPages,
    refetch,
    fetchMore,
    isFetching,
  } = userType === 'student' ? (applicationId === '' ? useStudentTasks({
    studentId: userId,
    status: statusFilter ?? undefined,
    sort: sortBy ?? undefined,
    order: sortOrder ?? undefined,
  }) : useApplicationTasks({
      applicationId: applicationId})) : applicationId === '' ? useAgentTasks({
    agentId: userId,
    status: statusFilter ?? undefined,
    studentId: studentFilter ?? undefined,
    sort: sortBy ?? undefined,
    order: sortOrder ?? undefined,
  }) : useApplicationTasks({
      applicationId: applicationId
  })
  const tasks = taskPages?.flatMap(page => page.results);
  const tasksTotal = taskPages?.[0].total ?? 0;
  const tasksLeft = tasksTotal - (tasks?.length ?? 0);

  return (
    <>
      <Box width='100%'>
        <StyledTypeHeadline>
          <UniTypography variant='body1' weight='medium' color='white100'>
            Tasks
          </UniTypography>
        </StyledTypeHeadline>
        <InfiniteWindowedGrid
          fetchMore={fetchMore}
          isFetching={isFetching}
          emptyStateType='tasks'
          hasMore={tasksLeft > 0}
          remainingResults={tasksLeft}
          results={(tasks ?? []).map(task => (
            <UniTaskCard {...task} userType={userType} onTaskUpdate={refetch} />
          ))}
        />
      </Box>
    </>
  );
};

export default TaskList;
