import styled from 'styled-components';
import { theme } from 'theme';
import { Colors, Shadows, Shape, Transitions } from '../../../../constants';

export const Wrapper = styled.div`
  padding: ${theme.spacing(3, 3)};
  border: 1px solid ${Colors.Primary._100};
  box-shadow: ${Shadows.shadow2};
  background-color: ${Colors.Primary._10};
  border-radius: ${Shape.BorderRadius.sm}px;
  transition: ${Transitions.duration.short};
  cursor: pointer;

  &:hover {
    transform: ${Transitions.transform.scale.sm};
  }
`;
