import { Colors } from '../../constants';
import styled from 'styled-components'

export const StyledIconWrapper = styled.div<{ size: 'large' | 'small' }>`
  display: flex;
  justify-content: center;
  width: ${({ theme, size }) => (size === 'large' ? theme.spacing(6) : theme.spacing(5))}px;
  height: ${({ theme, size }) => (size === 'large' ? theme.spacing(6) : theme.spacing(5))}px;
  background-color: ${Colors.Secondary._10};
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(1)}px;

  svg {
      fill: ${Colors.Secondary._100};
  }
`;