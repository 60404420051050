import { useMutation } from 'react-query';
import { Paths } from 'client/UniClient';
import { useClient } from 'store';

type Params = Paths.TaskApiUploadDocument.PathParameters;
type Payload = Params & { file: File };

const usePostTaskDocument = () => {
  const { client } = useClient();

  return useMutation(
    async ({ taskId, file }: Payload) => {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await client!.TaskApi_uploadDocument({ taskId }, formData);
      return data;
    },
  );
};

export default usePostTaskDocument;
