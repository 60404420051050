import { AxiosError } from 'axios';
import { UniversitiesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/UniversitiesFilters';
import { QueryConfig, useInfiniteQuery } from 'react-query';
import { Nationality } from 'types';
import { SubjectType } from 'types/programFilters';
import { ApiKeys, CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Params extends Partial<UniversitiesFiltersQueries> {
  query?: string;
  //sortType?: number;
  country?: Nationality[];
  city?: string[];
  subject?: SubjectType[];
}

export default function useGetUniversities(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  return useInfiniteQuery(
    [CacheKeys.General.ProgramNames, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.SearchApi_searchUniversities({
        //...params,
        country: params.country || [],
        city: params.city || [],
        subject: params.subject || [],
        levelOfStudy: params.levelOfStudy || [],
        query: params.query || undefined,
        pageSize: ApiKeys.PageSize.Universities,
        isPartner: params.isPartner || undefined,
        tagId: params?.tagId || undefined,
        page,
      });

      return data;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Universities || lastPage.page * ApiKeys.PageSize.Universities === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
