import React from 'react';
import { useTranslation } from 'react-i18next';
import { UniHeader, UniHerosection, UniPage } from 'components';
import TaskList from 'components/taskList/TaskList';
import { useAuth } from 'store';

export const StudentHome = () => {
  const { t } = useTranslation('studentDashboard');
  const { profile } = useAuth()

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='title'
          title={t('title')}
          filterbarHeadline='Filter tasks'
          withFilterBar
          filterbarVariant='tasks'
          overflow='visible'
        />
      </UniHeader>
      <UniPage>
        <TaskList userType="student" userId={profile.id!} applicationId="" />
      </UniPage>
    </>
  );
};

export default StudentHome;
