import { AxiosError } from 'axios';
import { Components, Paths } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

type Params = Paths.AchievementApiGetUpdateAchievementPleas.PathParameters;

const useAchievementPleas = (params: Params, config?: QueryConfig<Components.Schemas.UpdateAchievementPleaDto[]>) => {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Activities.Plea, params],
    async (_key: string, payload: Params) => {
      const { data } = await client!.AchievementApi_getUpdateAchievementPleas(payload);

      return data;
    },
    {
      ...config,
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
};

export default useAchievementPleas;
