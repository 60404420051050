import { AxiosResponse } from 'axios';
import { Components, Paths } from 'client/UniClient';
import { MutationConfig, useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Payload extends Paths.ServiceOptionApiAddServiceOffering.PathParameters {
  body: Components.Schemas.UpdateServiceOptionRequest;
}

export default function usePatchServiceOptions(config?: MutationConfig<AxiosResponse<Components.Schemas.ServiceOptionDto>>) {
  const { client } = useClient();
  const queryCache = useQueryCache();

  return useMutation(async ({ body, serviceOptionId }: Payload) => client!.ServiceOptionApi_updateServiceOption(serviceOptionId, body), {
    ...config,
    onSuccess() {
      queryCache.invalidateQueries(CacheKeys.ServiceOptions.All);
    },
  });
}
