import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniAvatar, UniButton, UniIconWrapper, UniTypography } from 'components';
import { countries } from 'constants/countries';
import { useBreakpoints } from 'hooks';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { ReactComponent as RightArrow } from '../../assets/svg/Icons/Arrow/ArrowRight.svg';
import { BrowserRoutes } from '../../constants';

interface Props {
  university?: Components.Schemas.UniversityDto;
  label?: string;
  actionLabel?: string;
  onClickMore?: Function;
  onlyHeader?: boolean;
}

export const UniversitySectionHeader = ({ university, label, actionLabel, onClickMore, onlyHeader = false }: Props) => {
  const { t } = useTranslation('universityPage');
  const { isMobile } = useBreakpoints();
  const { role, studentProfile } = useAuth(state => state.profile);
  const { isAgent, isAdmin, isStudent, isAgency, isMaster } = getMetaRole(role!);
  const history = useHistory();

  if (isMobile)
    return (
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        {onlyHeader === false ? (
          <UniTypography variant='body1' color='secondary100' weight='medium'>
            {label || university?.name}
          </UniTypography>
        ) : (
          <UniTypography variant='body1' color='typographyBlack100' weight='semi-bold'>
            {label || t('header.meta_items.universities')}
          </UniTypography>
        )}
        {/* This margin in set to -12 because iconWraperr has 12px paddings around */}
        <Box margin='-12px'>
          <UniIconWrapper
            size='medium'
            color='primary100'
            onClick={() => {
              onClickMore
                ? onClickMore()
                : isAgency
                ? history.push(BrowserRoutes.Agency.University.Program.replace(':universityId', university?.id as string))
                : isStudent
                ? history.push(BrowserRoutes.Student.University.Program.replace(':universityId', university?.id as string))
                : isMaster || isAdmin
                ? history.push(BrowserRoutes.Admin.University.Programmes.replace(':universityId', university?.id as string))
                : history.push(BrowserRoutes.Agent.University.Program.replace(':universityId', university?.id as string));
            }}
          >
            <RightArrow />
          </UniIconWrapper>
        </Box>
      </Box>
    );
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      {onlyHeader === false ? (
        <Box display='flex' alignItems='center'>
          <UniAvatar online={false} size='L' label={label || university?.name} />
          <Box ml={2} display='grid'>
            <UniTypography variant='header3' color='secondary100' weight='medium'>
              {label || university?.name}
            </UniTypography>
            <Box display='flex' alignItems='center' marginLeft='-3px'>
              <UniIconWrapper>
                <ReactCountryFlag countryCode={university?.address.country} svg></ReactCountryFlag>
              </UniIconWrapper>
              <UniTypography variant='subheader' color='typographyBlack100' weight='medium' style={{ marginLeft: '8px' }}>
                {`${university?.address.city ? university.address.city : '-'}, ${
                  university?.address.country
                    ? countries.filter(item => item.code === university.address.country).map(item => item.name)
                    : '-'
                }`}
              </UniTypography>
            </Box>
          </Box>
        </Box>
      ) : (
        <UniTypography variant='header3' color='typographyBlack100' weight='semi-bold'>
          {label || t('header.meta_items.universities')}
        </UniTypography>
      )}
      <UniButton
        variant='text'
        size='medium'
        style={{ width: 'fit-content' }}
        onClick={() => {
          !!onClickMore
            ? onClickMore()
            : isAgency
            ? history.push(BrowserRoutes.Agency.University.Program.replace(':universityId', university?.id as string))
            : isStudent
            ? history.push(BrowserRoutes.Student.University.Program.replace(':universityId', university?.id as string))
            : isMaster || isAdmin
            ? history.push(BrowserRoutes.Admin.University.Programmes.replace(':id', university?.id as string))
            : history.push(BrowserRoutes.Agent.University.Program.replace(':universityId', university?.id as string));
        }}
        endIcon={
          <UniIconWrapper size='main' color='primary100'>
            <RightArrow />
          </UniIconWrapper>
        }
      >
        {actionLabel || t('buttons.seeMore')}
      </UniButton>
    </Box>
  );
};
