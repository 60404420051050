import styled from 'styled-components';
import { ReactComponent as Illustration } from '../../../assets/svg/Illustrations/agentInviteIllustration.svg';
import { Colors } from '../../../constants';

export const CreateAgencyWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledCreateAgency = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 5)};
  background-color: ${Colors.White._100};

  display: flex;
  flex-direction: column;
`;

export const StyledCreateAgencyInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  & > p {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;
export const FormSection = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const StyledSuccessWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
`;

export const SuccessIllustration = styled(Illustration)`
  width: 100%;
  max-width: 420px;
  height: 60%;
  max-height: 300px;
  margin-bottom: ${({ theme }) => theme.spacing(10)}px;
`;
