import { Box } from '@material-ui/core';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { useBreakpoints } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useClient } from 'store';
import { StyledOAuthButton, StyledOAuthWrapper } from './UniOAuth.style';

interface Props {
  google?: boolean;
  facebook?: boolean;
  login?: boolean;
  signup?: boolean;
}

const UniOAuth: React.FC<Props> = ({ google, facebook, login, signup }) => {
  const { isMobile } = useBreakpoints();
  const { baseUrl } = useClient();
  const { t } = useTranslation('oAuthButtons');

  const labelGoogle = login ? t('login.google') : signup ? t('signup.google') : '';
  const labelFaebook = login ? t('login.facebook') : signup ? t('signup.facebook') : '';

  const onGoogleLogin = () => window.open(`${baseUrl}/api/v1/auth/google/login`);
  const onFacebookLogin = () => window.open(`${baseUrl}/api/v1/auth/facebook/login`);

  return (
    <StyledOAuthWrapper isMobile={isMobile}>
      {google && (
        <StyledOAuthButton onClick={onGoogleLogin} isMobile={isMobile} google variant='secondary'>
          {labelGoogle}
        </StyledOAuthButton>
      )}
      {facebook && (
        <StyledOAuthButton onClick={onFacebookLogin} isMobile={isMobile} facebook variant='secondary'>
          {labelFaebook}
        </StyledOAuthButton>
      )}
      {isMobile && (
        <Box width='100%' textAlign='center'>
          <UniTypography variant='footnote' weight='regular' color='typographyGray100'>
            {t('or')}
          </UniTypography>
        </Box>
      )}
    </StyledOAuthWrapper>
  );
};

export default UniOAuth;
