import { AxiosError } from "axios";
import { queryCache, useMutation } from "react-query";
import { CacheKeys } from "../../constants";
import { useClient } from "../../store";

type Payload = { programId: string; studentIds: string[] };

const useRecommendProgram = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      const suggestions = payload.studentIds.map((studentId) =>
        client!.ProgramApi_markProgramAsRecommended({
          programId: payload.programId,
          studentId: studentId,
        })
      );
      return Promise.all(suggestions);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(CacheKeys.Agency.Candidates);
        // queryCache.invalidateQueries(CacheKeys.General.Programs);
      },
      onError: (error: AxiosError) => {},
    }
  );
};

export default useRecommendProgram;
