import styled from 'styled-components';

export const StyledSessionHistory = styled.div`
`;

export const StyledSessionHistoryHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 3fr;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;
