import { usePatchCandidate } from 'api';
import useUpdateStudent from 'api/students/useUpdateStudent';
import { Components } from 'client/UniClient';
import { UniModalDrawerButtons } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import GuardianForm, { GuardianFormInputs } from 'components/_forms/GuardianForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth, useDrawers, useSnackbar } from 'store';
import { getMetaRole } from 'utils';

interface Props {
  student?: Components.Schemas.UserResponse;
}

const GuardianFormDrawer: React.FC<Props> = ({ student }) => {
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const profile = useAuth(state => state.profile);
  const { t } = useTranslation('forms');

  const defaultValues: Partial<GuardianFormInputs> = {
    guardian: student?.studentProfile.guardian,
  };
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<GuardianFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const { pathname } = useLocation();
  const [updateStudent, { status: updateStudentStatus, error: updateStudentError }] = useUpdateStudent();
  const [updateStudentAgency, { status: updateStudentAgencyStatus, error: updateStudentAgencyError }] = usePatchCandidate();
  const { isAgency, isAgent, isStudent } = getMetaRole(profile?.role);

  const isLoading = updateStudentStatus === 'loading' || updateStudentAgencyStatus === 'loading';

  const onSubmit = (formData: GuardianFormInputs) => {
    const requestForm: Components.Schemas.UpdateStudentRequest = {
      studentProfile: {
        guardian: {
          email: formData.guardian.email || '',
          name: formData.guardian.name || '',
          phone: `${formData.guardian.phonePrefix || ''} ${formData.guardian.phone || ''}`,
        },
      },
    };
    if (isStudent) {
      updateStudent(requestForm, {
        onSuccess() {
          closeDrawer();
          openSnackbar('personal-details');
        },
        onError() {},
      });
    }
    if (isAgency) {
      const studentId = pathname.split('/')[4];
      updateStudentAgency(
        { ...requestForm, studentId },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('personal-details');
          },
          onError() {},
        },
      );
    }
    if (isAgent) {
      const studentId = pathname.split('/')[4];
      updateStudentAgency(
        { ...requestForm, studentId },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('personal-details');
          },
          onError() {},
        },
      );
    }
  };

  return (
    <>
      <UniModalDrawerContent>
        <GuardianForm control={control} register={register} setValue={setValue} errors={errors} required />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: t('buttons.save_changes'),
          loading: isLoading,
        }}
        SecondaryButton={{ onClick: closeDrawer, label: t('buttons.cancel') }}
      />
    </>
  );
};

export default GuardianFormDrawer;
