import { Box } from '@material-ui/core';
import useGetMyServices from 'api/agency/useGetMyServices';
import usePostServiceOrder from 'api/service-orders/usePostServiceOrder';
import { Components } from 'client/UniClient';
import { UniCard, UniModalDrawerButtons } from 'components';
import ServiceItem from 'components/serviceItem/ServiceItem';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { useState } from 'react';
import { useDrawers } from 'store';

interface Props {
  studentId?: string;
  onSuccess?: (data: any) => Promise<void>;
}

type Services = Components.Schemas.ServiceOfferingDto & { selected: boolean; quantity: number };
interface Inputs {
  services: Services[];
}
interface SelectableService {
  quantity: number;
  selected: boolean;
}
interface SelectableServiceObject {
  [key: string]: SelectableService | undefined;
}

const CreateServiceOrder: React.FC<Props> = ({ children, studentId, onSuccess, ...rest }) => {
  const { closeDrawer } = useDrawers();
  const { data: serviceOfferings, status: serviceOfferingsStatus } = useGetMyServices();
  const [postServiceOrder, { isLoading }] = usePostServiceOrder();
  const [selected, setSelected] = useState<SelectableServiceObject>({});
  const selectedServices = Object.values(selected).reduce((prev, current) => (current?.selected ? prev + 1 : prev), 0);

  const onCreateOrder = () => {
    const serviceOfferings = Object.keys(selected)
      .filter(key => !!selected[key]?.selected)
      .map(key => ({
        quantity: selected[key]?.quantity || 1,
        serviceOfferingId: key,
      }));

    postServiceOrder(
      { studentId: studentId!, serviceOfferings },
      {
        onSuccess: async data => {
          if (onSuccess) await onSuccess(data);
          else closeDrawer();
        },
      },
    );
  };

  // const getTabColor = (value: number) => (currentTab === value ? 'primary' : 'primaryFade');

  const onUpdateService = (id: string, object: SelectableService) => setSelected(prev => ({ ...prev, [id]: object }));

  if (serviceOfferingsStatus === 'loading' || !serviceOfferings) {
    return <UniListLoader />;
  }

  return (
    <>
      <UniModalDrawerContent>
        {/* <Box display='flex' justifyContent='center' mb={2}>
          <UniButton color={getTabColor(0)} borderRadius={[1, 0, 0, 1]} onClick={() => setCurrentTab(0)}>
            UniCenter
          </UniButton>
          <UniButton color={getTabColor(1)} borderRadius={[0, 0, 0, 0]} onClick={() => setCurrentTab(1)}>
            My Partner
          </UniButton>
          <UniButton color={getTabColor(2)} borderRadius={[0, 1, 1, 0]} onClick={() => setCurrentTab(2)}>
            Other
          </UniButton>
        </Box> */}

        {serviceOfferings.map(service => {
          const selectedService = selected[service.id];

          return (
            <>
              <UniCard padding={[2, 2]} size='S' border key={service.id} overflow='none' hoverable>
                <ServiceItem
                  value={selectedService?.quantity || 1}
                  onChange={quantity => onUpdateService(service.id, { selected: !!selectedService?.selected, quantity })}
                  onCheck={selected => onUpdateService(service.id, { selected, quantity: selectedService?.quantity || 1 })}
                  description={service?.serviceOption?.description || ''}
                  name={service?.serviceOption?.name}
                  checked={!!selectedService?.selected}
                  price={service?.price}
                  editable
                  minified
                />
              </UniCard>
              <Box mt={2} />
            </>
          );
        })}
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ label: 'Cancel', onClick: closeDrawer }}
        PrimaryButton={{
          disabled: !selectedServices,
          label: 'Create Order',
          loading: isLoading,
          onClick: onCreateOrder,
        }}
      />
    </>
  );
};

export default CreateServiceOrder;
