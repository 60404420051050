import useSubmitApplicationFeeInvoice from 'api/applications/useSubmitApplicationFeeInvoice';
import { Components } from 'client/UniClient';
import AnimatedTabPanel from 'components/_common/animatedTabPanel/AnimatedTabPanel';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import Step1 from './_steps/Step1';
import Step2 from './_steps/Step2';

export type ApplicaitonFeeInputs = {
  applicationFee: Components.Schemas.CurrencyRequest;
};

interface Props {
  application?: Components.Schemas.ApplicationDto;
}

const ApplicaitonFee: React.FC<Props> = ({ application }) => {
  const studentName = `${application?.student.firstName} ${application?.student.lastName}`;
  const { t: tButtons } = useTranslation('buttons');
  const [submitApplicationFee, { status: submitApplicationFeeStatus }] = useSubmitApplicationFeeInvoice();
  const { control, handleSubmit, errors } = useForm<ApplicaitonFeeInputs>({
    shouldUnregister: false,
  });
  const applicationFeeError = (errors?.applicationFee as any)?.message;

  const { setProgress, closeDrawer } = useDrawers();
  const [step, setStep] = useState(0);

  const onSubmit = (formData: ApplicaitonFeeInputs) => {
    submitApplicationFee(
      {
        programId: application?.program.id!,
        currency: formData.applicationFee,
      },
      {
        onSuccess() {
          setStep(prevStep => prevStep + 1);
        },
      },
    );
  };

  const buttonTexts = [
    {
      secondaryCallback: closeDrawer,
      primaryCallback: handleSubmit(onSubmit),
      secondary: tButtons('cancel'),
      primary: tButtons('request_fee'),
      loading: submitApplicationFeeStatus === 'loading',
    },
    {
      secondaryCallback: () => {},
      primaryCallback: closeDrawer,
      primary: tButtons('back_to_application'),
    },
  ];

  useEffect(() => setProgress(step * 60 + 40), [step]);

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel
            Content={
              <Step1
                control={control}
                programName={application?.program.name}
                universityName={application?.program.universityName}
                studentName={studentName}
                error={applicationFeeError}
              />
            }
            index={0}
            currentItem={step}
          />

          <AnimatedTabPanel
            Content={<Step2 programName={application?.program.name} studentName={studentName} />}
            index={1}
            currentItem={step}
          />
        </AnimatePresence>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={
          buttonTexts[step]?.secondary
            ? {
                onClick: () => buttonTexts[step]?.secondaryCallback(),
                label: buttonTexts[step].secondary,
              }
            : undefined
        }
        PrimaryButton={{
          onClick: buttonTexts[step]?.primaryCallback,
          label: buttonTexts[step]?.primary,
          loading: !!buttonTexts[step]?.loading,
        }}
      />
    </>
  );
};

export default ApplicaitonFee;
