import { UniButton, UniTypography } from 'components';
import { useEffect, useState } from 'react';
import { StyledNotificationInnerWrapper } from './UniCookieNotification.style';

interface Props {}

const UniCookieNotification: React.FC<Props> = ({ children, ...rest }) => {
  const [accepted, setAccepted] = useState(true);
  const hasAcceptedCookies = localStorage.getItem('accepted_cookies');

  const onAcceptCookies = () => {
    localStorage.setItem('accepted_cookies', 'accepted');
    setAccepted(true);
  };

  useEffect(() => {
    if (hasAcceptedCookies === 'accepted') {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  }, [hasAcceptedCookies]);

  if (accepted) {
    return <></>;
  }
  return (
    <StyledNotificationInnerWrapper>
      <UniTypography variant='body2' weight='regular' color='white100'>
        By continuing to browse this site, you are agreeing to the use of cookies, whose purpose it is to provide web analytics and
        measurements of visitor traffic and browsing behaviour.
      </UniTypography>
      <UniButton variant='main' size='large' onClick={onAcceptCookies}>
        I agree
      </UniButton>
    </StyledNotificationInnerWrapper>
  );
};

export default UniCookieNotification;
