import { Box } from '@material-ui/core';
import useAchievements from 'api/achievements/useAchievements';
import useTodos from 'api/todos/useTodos';
import { Components } from 'client/UniClient';
import ActivitiesList from 'components/activitiesList/ActivitiesList';
import ActivityPlanFilterBox, { ActivitiesListType } from 'components/activityPlanFilterBox/ActivityPlanFilterBox';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { ActivitiesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ActivitiesFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { useMemo, useState } from 'react';

interface Props {
  student: Components.Schemas.UserDto;
}

const StudentActivityPlan: React.FC<Props> = ({ student, ...rest }) => {
  const studentId = student.id!;
  const [listType, setListType] = useState<ActivitiesListType>('cards');
  const { queries } = useQueryStringParser<ActivitiesFiltersQueries>();

  const {
    data: achievements,
    isLoading: achievementsIsLoading,
    isFetching: achievementsIsFetching,
    fetchMore: achievementsFetchMore,
  } = useAchievements({ ...queries, studentId });
  const {
    data: todos,
    isLoading: todosIsLoading,
    isFetching: todosIsFetching,
    fetchMore: todosFetchMore,
  } = useTodos({ ...queries, studentId });

  const achievementsFlat = useMemo(() => {
    return achievements?.map(programPage => programPage.results).flat() || [];
  }, [achievements]);
  const achievementsTotal = useMemo(() => (achievements ? achievements[0].total : 0), [achievements]);
  const achievementsLeft = achievementsTotal - achievementsFlat.length;

  const todosFlat = useMemo(() => {
    return todos?.map(programPage => programPage.results).flat() || [];
  }, [todos]);
  const todosTotal = useMemo(() => (todos ? todos[0].total : 0), [todos]);
  const todosLeft = todosTotal - todosFlat.length;

  if (!achievements || achievementsIsLoading || !todos || todosIsLoading) {
    return (
      <>
        <ActivityPlanFilterBox
          achievementsLeft={achievementsLeft}
          todosLeft={todosLeft}
          fetchMoreAchievements={achievementsFetchMore}
          achievementsIsFetching={achievementsIsFetching}
          fetchMoreTodos={todosFetchMore}
          todosIsFetching={todosIsFetching}
          todos={todosFlat}
          achievements={achievementsFlat}
          onChangeListType={setListType}
          studentId={studentId}
        />
        <UniListLoader />
      </>
    );
  }

  return (
    <>
      <ActivityPlanFilterBox
        achievementsLeft={achievementsLeft}
        todosLeft={todosLeft}
        fetchMoreAchievements={achievementsFetchMore}
        achievementsIsFetching={achievementsIsFetching}
        fetchMoreTodos={todosFetchMore}
        todosIsFetching={todosIsFetching}
        todos={todosFlat}
        achievements={achievementsFlat}
        onChangeListType={setListType}
        studentId={studentId}
      />
      <Box mb={3} />
      {listType === 'cards' && (
        <ActivitiesList
          achievementsFlat={achievementsFlat}
          achievementsLeft={achievementsLeft}
          todosFlat={todosFlat}
          todosLeft={todosLeft}
          achievementsFetchMore={achievementsFetchMore}
          achievementsIsFetching={achievementsIsFetching}
          todosFetchMore={todosFetchMore}
          todosIsFetching={todosIsFetching}
        />
      )}
    </>
  );
};

export default StudentActivityPlan;
