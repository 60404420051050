import { TComponentColors } from '../../../constants/componentColors';
import { StyledWrapper } from './UniCardWrapper.style';

export interface UniCardWrapperProps {
  size: 'S' | 'L';
  border?: boolean;
  shadow?: 'shadow1' | 'shadow2' | 'shadow3' | 'none';
  opacBorder?: boolean;
  hoverable?: boolean;
  grey?: boolean;
  onClick?: Function;
  fit?: boolean;
  isPromoted?: boolean;
  padding?: [number, number];
  fullSection?: boolean;
  backgroundColor?: TComponentColors;
  overflow?: 'auto' | 'hidden' | 'visible' | 'none';
}

const UniCardWrapper: React.FC<UniCardWrapperProps> = ({
  size,
  fullSection = false,
  fit,
  opacBorder = false,
  border = false,
  children,
  onClick,
  shadow,
  hoverable = false,
  grey,
  isPromoted,
  backgroundColor,
  overflow = 'hidden',
  ...props
}) => {
  return (
    <StyledWrapper
      fullSection={fullSection}
      onClick={() => onClick && onClick()}
      fit={fit}
      opac={opacBorder}
      size={size}
      border={border}
      shadow={shadow !== 'none' ? shadow : undefined}
      grey={grey}
      hoverable={hoverable}
      isPromoted={isPromoted}
      backgroundColor={backgroundColor}
      overflow={overflow}
      {...props}
    >
      {children}
    </StyledWrapper>
  );
};

export default UniCardWrapper;
