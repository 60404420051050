import { Colors } from '../../../constants/uiConfig';
import styled from 'styled-components';
import { Size } from './UniPageTitle';

export const StyledBar = styled.div<{ size: Size }>`
  width: ${({ theme }) => theme.spacing(3)}px;
  height: ${({ theme }) => theme.spacing(0.5)}px;
  background-color: ${Colors.Other.Yellow_100};
  margin-top: ${({ theme, size }) => (size === 'XS' ? theme.spacing(1) : theme.spacing(1.5))}px;
  border-radius: ${({ theme }) => theme.spacing(0.25)}px;
`;
