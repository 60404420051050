import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

const usePostCandidateMultiple = () => {
  const queryCache = useQueryCache();
  const agencyId = useAuth(state => state.profile.agentProfile?.agency?.id!);
  const { client } = useClient();

  return useMutation(
    async (payload: FormData) => {
      return client!.AgencyApi_importStudentsCsv({ agencyId }, payload as unknown as Components.Schemas.ImportStudentFormData);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Agency.Candidates);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         });
         */
      },
    },
  );
};

export default usePostCandidateMultiple;
