import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components } from '../../client/UniClient';
import { useClient } from '../../store';

type Payload = Partial<Components.Schemas.CreateQRTokenRequest>;

interface Params {
  payload: Payload;
}

const usePostQRAchievement = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async ({ payload }: Params) => {
      return client!.QRTokenApi_createQRToken(undefined, payload);
    },
    {
      onSuccess: response => {},
      onError: (error: AxiosError) => {},
    },
  );
};

export default usePostQRAchievement;
