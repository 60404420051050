import LayoutRoute from 'components/_layouts/layoutRoute/LayoutRoute';
import { lazy } from 'react';
import { Redirect, Route, useParams } from 'react-router-dom';
import ContinentPage from 'views/_landing/continent-page/ContinentPage.new';
import CountryPage from 'views/_landing/country-page/CountryPage';
import { BrowserRoutes } from '../constants';
import Partners from './Partners';
import AssessmentQuiz from '../views/_landing/assessment-quiz/AssessmentQuiz';
// const ResetPasswordView = lazy(() =>
//   import('../views/login/ResetPasswordView').then(({ ResetPasswordView }) => ({ default: ResetPasswordView })),
// );
const InvalidToken = lazy(() => import('../views/error-pages/InvalidToken'));
const AgencyOnboard = lazy(() => import('../views').then(({ AgencyOnboard }) => ({ default: AgencyOnboard })));
const AgentOnboard = lazy(() => import('../views').then(({ AgentOnboard }) => ({ default: AgentOnboard })));
const LandingAboutUs = lazy(() => import('../views').then(({ LandingAboutUs }) => ({ default: LandingAboutUs })));
const LandingHome = lazy(() => import('../views').then(({ LandingHome }) => ({ default: LandingHome })));
const LandingPrograms = lazy(() => import('../views').then(({ LandingPrograms }) => ({ default: LandingPrograms })));
const LandingEvents = lazy(() => import('../views').then(({ LandingEvents }) => ({ default: LandingEvents })));
const AuthPage = lazy(() => import('../views').then(({ AuthPage }) => ({ default: AuthPage })));
const StudentOnboard = lazy(() => import('../views').then(({ StudentOnboard }) => ({ default: StudentOnboard })));
const HighSchoolOnboard = lazy(() => import('../views').then(({ HighSchoolOnboard }) => ({ default: HighSchoolOnboard })));
const CssCheckDefault = lazy(() => import('../views').then(({ CssCheckDefault }) => ({ default: CssCheckDefault })));
const CssCheckThankYou = lazy(() => import('../views').then(({ CssCheckThankYou }) => ({ default: CssCheckThankYou })));

const DefaultRoutes = () => {
  return [
    <LayoutRoute key={BrowserRoutes.Default.Homepage} path={BrowserRoutes.Default.Homepage} exact component={LandingHome} />,
    <LayoutRoute key={BrowserRoutes.Default.About} path={BrowserRoutes.Default.About} exact component={LandingAboutUs} />,
    <LayoutRoute key={BrowserRoutes.Default.CssCheck.Default} path={BrowserRoutes.Default.CssCheck.Default} exact component={CssCheckDefault} />,
    <LayoutRoute key={BrowserRoutes.Default.CssCheck.ThankYou} path={BrowserRoutes.Default.CssCheck.ThankYou} exact component={CssCheckThankYou} />,
    <LayoutRoute key={BrowserRoutes.Default.AssessmentQuiz} path={BrowserRoutes.Default.AssessmentQuiz} exact component={AssessmentQuiz} />,
    <LayoutRoute key={BrowserRoutes.Default.Programs} path={BrowserRoutes.Default.Programs} exact component={LandingPrograms} />,
    <LayoutRoute
      key={BrowserRoutes.Default.Events.Default()}
      path={BrowserRoutes.Default.Events.Default()}
      component={LandingEvents}
    />,
    <LayoutRoute key={BrowserRoutes.Default.Auth.Base} path={BrowserRoutes.Default.Auth.Base} component={AuthPage} />,
    <LayoutRoute
      key={BrowserRoutes.Default.Onboard.Student}
      path={BrowserRoutes.Default.Onboard.Student}
      component={StudentOnboard}
    />,
    <LayoutRoute
      key={BrowserRoutes.Default.Onboard.HighSchool}
      path={BrowserRoutes.Default.Onboard.HighSchool}
      component={HighSchoolOnboard}
    />,
    <LayoutRoute
      key={BrowserRoutes.Default.InviteByLink}
      path={BrowserRoutes.Default.InviteByLink}
      component={RedirectToStudentOnboardLink}
    />,
    <LayoutRoute key={BrowserRoutes.Default.Continent()} path={BrowserRoutes.Default.Continent()} exact component={ContinentPage} />,
    <LayoutRoute
      key={BrowserRoutes.Default.Country.Country()}
      path={BrowserRoutes.Default.Country.Country()}
      component={CountryPage}
    />,
    <LayoutRoute key={BrowserRoutes.Default.Country.About()} path={BrowserRoutes.Default.Country.About()} exact component={CountryPage} />,
    <LayoutRoute
      key={BrowserRoutes.Default.Country.Counsellors()}
      path={BrowserRoutes.Default.Country.Counsellors()}
      component={CountryPage}
    />,
    <LayoutRoute key={BrowserRoutes.Default.AgentInvite} path={BrowserRoutes.Default.AgentInvite} exact component={AgentOnboard} />,
    <Route key={BrowserRoutes.Default.AgencyInvite} path={BrowserRoutes.Default.AgencyInvite} exact component={AgencyOnboard} />,
    <LayoutRoute key={BrowserRoutes.Default.InvalidToken} path={BrowserRoutes.Default.InvalidToken} exact component={InvalidToken} />,
    ...Partners(),
  ];
};

const RedirectToStudentOnboardLink = () => {
  const { token } = useParams<{ token: string }>();
  return <Redirect to={BrowserRoutes.Default.Onboard.Student.replace(':type', 'link').replace(':token', token)} />;
};

export default DefaultRoutes;
