import styled from 'styled-components';
import { UniCardContainer } from '../../../components';

export const ListContainer = styled(UniCardContainer)`
  padding: ${({ theme }) => theme.spacing(3)}px;
`;

export const ListTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ListActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
  align-items: center;
`;
