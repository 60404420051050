import { useProgram } from 'api';
import {
  AnimatedTabPanel,
  EmptyState,
  ProgramsListItemStudentActions,
  UniHeader,
  UniIconWrapper,
  UniPage,
  UniPageLoader,
} from 'components';
import { UniHerosection } from 'components/_common/uniHerosection/UniHerosection';
import ProgramCredentials from 'components/_templates/programCredentials/ProgramCredentials';
import ProgramGeneralInfo from 'components/_templates/programGeneralInfo/ProgramGeneralInfo';
import ProgramStatusReplies from 'components/_templates/programSatusReplies/ProgramStatusReplies';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import { useHeader } from 'store';
import { ReactComponent as HourglassIcon } from '../../../assets/svg/Icons/Hourglass.svg';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location.svg';
import { BrowserRoutes } from '../../../constants';
import { TaskList } from 'components/taskList/TaskList';

const StudentProgramPage: React.FC = () => {
  const { t } = useTranslation('programPage');
  const { programId } = useParams<{ programId: string }>();
  const { url } = useRouteMatch();
  const { goBack, go } = useHistory();
  const { setHeader, clearHeader } = useHeader();
  const generalPath = BrowserRoutes.Student.Program.General.replace(':programId', programId);
  const statusPath = BrowserRoutes.Student.Program.Status.replace(':programId', programId);
  const credentialsPath = BrowserRoutes.Student.Program.Credentials.replace(':programId', programId);
  const tasksPath = BrowserRoutes.Student.Program.Tasks.replace(':programId', programId);
  const tabs = [generalPath, statusPath, tasksPath, credentialsPath];
  const currentRoute = tabs.indexOf(url);

  const {
    data: program,
    status: programStatus,
    error: programsError,
  } = useProgram({
    id: programId,
  });

  useEffect(() => {
    setHeader({
      primaryButton: { onClick: () => goBack() },
    });
    return clearHeader;
  }, []);

  if (!program && programStatus === 'loading') {
    return <UniPageLoader />;
  }

  if (programsError && programStatus === 'error') {
    return <Redirect to={BrowserRoutes.Default.NotFound} />;
  }

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='base'
          title={program?.name || ''}
          universityName={program?.universityName}
          universityId={program?.universityId}
          Actions={<ProgramsListItemStudentActions program={program!} />}
          metrics={[
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <LocationIcon />
                </UniIconWrapper>
              ),
              label: t('header.location'),
              value: `${program?.address.city || '-'}, ${program?.address.country || '-'}`,
            },
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <HourglassIcon />
                </UniIconWrapper>
              ),
              label: t('header.lengthOfStudy'),
              value: program?.lengthOfStudy ? `${program?.lengthOfStudy?.value} ${program?.lengthOfStudy?.unit}` : 'Unspecified',
            },
          ]}
      tabs={[
            { label: t('tabs.status'), path: statusPath },
            { label: t('tabs.general'), path: generalPath },
            { label: t('tabs.tasks'), path: tasksPath},
            { label: t('tabs.credentials'), path: credentialsPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={generalPath}>
              <AnimatedTabPanel
                Content={<ProgramGeneralInfo program={program} />}
                index={0}
                currentItem={currentRoute}
                absoluteDirection={-1}
              />
            </Route>
            <Route path={statusPath}>
              <AnimatedTabPanel
                Content={
                  !!program?.application?.status ? <ProgramStatusReplies application={program.application} /> : <EmptyState type='status' />
                }
                index={1}
                currentItem={currentRoute}
                absoluteDirection={1}
             />
            </Route>
            <Route path={tasksPath}>
              <AnimatedTabPanel
                Content={
                    !!program?.application?.status ? <TaskList userType="student" userId="" applicationId={program.application.id} /> : <EmptyState type='tasks' />}
                index={2}
                currentItem={currentRoute}
                absoluteDirection={1}
              />
            </Route>
            <Route path={credentialsPath}>
              <AnimatedTabPanel
                Content={
                  !!program?.application?.status ? <ProgramCredentials applicationId={program.application.id} /> : <EmptyState type='credentials' />
                }
                index={3}
                currentItem={currentRoute}
                absoluteDirection={1}
              />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default StudentProgramPage;
