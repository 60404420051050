import { queryCache, useMutation } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

interface Params {
  serviceOrderId: string;
}

const usePostServiceOrderSubmission = () => {
  const { client } = useClient();

  return useMutation(
    async (params: Params) => {
      const serviceOrderResponse = await client?.ServiceOrderApi_submitServiceOrder(params);
      return serviceOrderResponse?.data;
    },
    {
      onSuccess() {
        queryCache.invalidateQueries(CacheKeys.Student.Orders);
      },
    },
  );
};

export default usePostServiceOrderSubmission;
