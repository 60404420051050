import styled from 'styled-components';
import { Shadows, Sizes } from '../../../constants';

export const StyledHeader = styled.div<{ hasDrawer: boolean }>`
  background-color: #fff;
  box-shadow: ${Shadows.shadow2};
  width: calc(100% + ${({ theme }) => theme.spacing(4)}px);
  margin: ${({ theme, hasDrawer }) => theme.spacing(-12, -2, 3, -2)};
  padding: ${({ theme, hasDrawer }) => theme.spacing(12, 2, 0, 2)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: calc(100% + ${({ theme }) => theme.spacing(6)}px);
    margin: ${({ theme }) => theme.spacing(-12, -3, 3, -3)};
    padding: ${({ theme }) => theme.spacing(12, 3, 0, 3)};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: calc(100% + ${({ theme }) => theme.spacing(12)}px);
    margin: ${({ theme }) => theme.spacing(-12, -6, 6, -6)};
    padding: ${({ theme }) => theme.spacing(12, 6, 0, 6)};
  }
`;

export const StyledInnerHeader = styled.div`
  width: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: ${Sizes.MaxWidthWrapper}px;
  }
`;
