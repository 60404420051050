import { Box } from '@material-ui/core';
import { UniTypography } from 'components';
import UniFeeInput from 'components/_inputs/uniFeeInput/UniFeeInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';

interface Props {
  control: any;
  setValue: any;
  register?: any;
  errors?: any;
}

const Step2: React.FC<Props> = ({ control, setValue, register, errors }) => {
  const { t } = useTranslation('partnerServicesDrawers');
  return (
    <Box display='flex' flexDirection='column' style={{ gap: theme.spacing(2) }}>
      <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
        {t('add_service.set_fee')}
      </UniTypography>
      <UniFeeInput
        name='amount'
        control={control}
        label={t('add_service.fee_input_label')}
        defaultCurrency='RON'
        setValue={setValue}
        error={!!errors?.amount?.message}
        helperText={errors?.amount?.message}
        inputRef={register({ required: t('add_service.amount_error') })}
      />
    </Box>
  );
};

export default Step2;
