import usePostAttendance from 'api/events/usePostAttendance';
import { Components, Paths } from 'client/UniClient';
import AnimatedTabPanel from 'components/_common/animatedTabPanel/AnimatedTabPanel';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ActionsFooter from './ActionsFooter';
import Step1 from './_steps/Step1';
import Step2 from './_steps/Step2';
import Step3 from './_steps/Step3';
import Step4 from './_steps/Step4';
import Step5 from './_steps/Step5';
import Step6 from './_steps/Step6';

interface Props {
  event?: Components.Schemas.EventResponse;
  feePaid?: boolean;
}
interface AttendanceInputs extends Paths.EventApiAttendEvent.RequestBody {
  phone: string;
  phonePrefix: string;
}

const EventRegistration: React.FC<Props> = ({ event, feePaid }) => {
  const [step, setStep] = useState(feePaid ? 4 : 0);
  const [attendEvent, { isLoading, data }] = usePostAttendance();
  const { control, register, setValue, handleSubmit, reset, errors } = useForm<AttendanceInputs>();
  const [formState, setFormState] = useState<Partial<AttendanceInputs>>();

  const prevStep = (formData: AttendanceInputs) => {
    setStep(prev => prev - 1);
    onSaveForm(formData);
  };

  const nextStep = (formData: AttendanceInputs) => {
    if (step === 2) {
      onSubmit(formData);
      return;
    }
    setStep(prev => prev + 1);
    onSaveForm(formData);
  };

  const onSaveForm = (formData: AttendanceInputs) => {
    setFormState(prev => {
      const newFormState = { ...prev, ...formData };
      reset({ ...newFormState, ...formData });
      return newFormState;
    });
  };

  const onSubmit = (formData: AttendanceInputs) => {
    const postData = { ...formState, ...formData };
    attendEvent(
      {
        eventId: event?.id || '',
        guardianEmail: postData.guardianEmail,
        guardianName: postData.guardianName,
        guardianPhone: postData.phone,
        guardianPhonePrefix: postData.phonePrefix,
        attendancePackageId: postData.attendancePackageId,
        couponCode: postData.couponCode,
      },
      {
        onSuccess: () => {
          setStep(3);
        },
      },
    );
  };

  if (!event)
    return (
      <UniModalDrawerContent>
        <UniTypography variant='subheader' color='stateAlert100'>
          Something went wrong, please reopen the drawer.
        </UniTypography>
      </UniModalDrawerContent>
    );

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel Content={<Step1 event={event} />} index={0} currentItem={step} />
          <AnimatedTabPanel Content={<Step2 control={control} event={event} errors={errors} />} index={1} currentItem={step} />
          <AnimatedTabPanel
            Content={<Step3 register={register} control={control} setValue={setValue} event={event} errors={errors} />}
            index={2}
            currentItem={step}
          />
          <AnimatedTabPanel Content={<Step4 feeInvoice={data?.data.feeInvoice} />} index={3} currentItem={step} />
          <AnimatedTabPanel Content={<Step5 event={data?.data.event} />} index={4} currentItem={step} />
          <AnimatedTabPanel Content={<Step6 event={data?.data.event} />} index={5} currentItem={step} />
        </AnimatePresence>
      </UniModalDrawerContent>
      <ActionsFooter
        nextStep={handleSubmit(nextStep)}
        prevStep={handleSubmit(prevStep)}
        loading={isLoading}
        step={step}
        feeInvoice={data?.data.feeInvoice}
      />
    </>
  );
};

export default EventRegistration;
