import { Box } from '@material-ui/core';
import useUpdateStudent from 'api/students/useUpdateStudent';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useDrawers, useSnackbar } from 'store';
import { getMetaRole } from 'utils';
import { UniModalDrawerButtons } from '../..';
import { StyledQueueIllustration } from '../publishPublicProfile/PublishPublicProfile.style';

interface Props {}

export default function UnpublishPublicProfile({}: Props): ReactElement {
  const { t: tButtons } = useTranslation('buttons');
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile.role);
  const [postPersoalInfo, { isLoading: personalInfoIsLoading }] = useUpdateStudent();

  const publishProfile = () => {
    let studentProfile = profile?.studentProfile;
    if (!studentProfile) return;
    const publicProfilePreferences = { ...studentProfile.publicProfilePreferences, isPublished: false };
    if (isStudent) {
      postPersoalInfo(
        { studentProfile: { publicProfilePreferences } },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('reject-service');
          },
        },
      );
    }
  };

  return (
    <>
      <UniModalDrawerContent>
        <Box mb={3}>
          <UniTypography variant='header3' color='secondary100' weight='medium'>
            Unpublish your public profile
          </UniTypography>
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            You are about to unpublish your public profile. You can publish it back online at anytime.
          </UniTypography>
        </Box>
        <Box display='flex' justifyContent='center'>
          <StyledQueueIllustration />
        </Box>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: closeDrawer, label: tButtons('cancel') }}
        PrimaryButton={{ onClick: publishProfile, label: tButtons('yes_unpublish'), loading: personalInfoIsLoading }}
      />
    </>
  );
}
