import { Menu, MenuProps } from '@material-ui/core';
import styled from 'styled-components';
import { Colors, Shape } from '../../../constants';

export const StyledDocumentCreatorPopup = styled.div`
  border-radius: ${Shape.BorderRadius.md}px;
  border: 1px solid ${Colors.Border.Gray_100};
  padding: ${({ theme }) => theme.spacing(2, 2)};
`;

interface StyledMenuProps extends MenuProps {
  width?: number;
}

export const StyledMenu = styled(Menu)<StyledMenuProps>`
  margin-top: 8px;
  .MuiMenu-paper {
    width: ${({ width }) => (width ? `${width}px` : 'inherit')};
  }
`;

export const StyledOptionActions = styled.div`
  opacity: 0;
  transition: 0.2s;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`;
