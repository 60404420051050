import { AxiosError } from 'axios';
import { QueryConfig, useInfiniteQuery, useQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

type Params = {
  facultyId?: string;
};
export default function useGetFaculty(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.General.University, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.FacultyApi_getFaculty(params.facultyId!);

      return data;
    },
    {
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
