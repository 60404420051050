import { Box } from '@material-ui/core';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { AuthDropdown, UniButton, UniIconWrapper, UniLogo, UniTypography } from '../..';
import { ReactComponent as LoginIcon } from 'assets/svg/Icons/Unlock.svg';
import { ReactComponent as SignupIcon } from 'assets/svg/Icons/Userplus.svg';
import { ReactComponent as WhatsappIcon } from 'assets/svg/Icons/WhatsAppIcon.svg';
import { LanguageDropdown } from './dropdown/LanguageDropdown';
import { LinkDropdown } from './dropdown/LinkDropdown';
import { useLogout } from 'api';
import { Components } from '../../../client/UniClient';
import { BrowserRoutes } from '../../../constants';
import { theme } from '../../../theme';
import { StyledAuthNavbar, StyledLink } from './HeaderNavigation.style';

interface Props {
  isLoggedIn: boolean;
  isSticky: boolean[];
  profile: Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse>;
}

export default function HeaderNavigationDesktop({ isLoggedIn, profile, isSticky }: Props): ReactElement {
  const { t } = useTranslation('landingHome');
  const history = useHistory();
  const resetProfile = useAuth(state => state.resetProfile);
  const [logoutCall] = useLogout();
  const { isMaster, isAgency, isAgent, isStudent, isUniversity } = getMetaRole(profile.role);

  const logout = useCallback(() => {
    // cleanup
    logoutCall();
    resetProfile();
  }, [history, resetProfile, logoutCall]);

  const goToProfile = () => {
    if (isAgency) {
      return history.push(BrowserRoutes.Agency.Profile);
    } else if (isAgent) {
      return history.push(BrowserRoutes.Agent.Profile);
    } else if (isStudent) {
      return history.push(BrowserRoutes.Student.Profile.PersonalInfo);
    } else if (isMaster) {
      return history.push(BrowserRoutes.Admin.Default);
    } else if (isUniversity) {
      return history.push(BrowserRoutes.University.Default);
    }
  };
  return (
    <>
      <StyledAuthNavbar isLoggedIn={isLoggedIn} isSticky={isSticky}>
        {!isLoggedIn ? (
          <>
            <Box display='flex' flexDirection='row' alignItems='center' style={{ gap: theme.spacing(3) }}>
              <Link to={BrowserRoutes.Default.Homepage}>
                <UniLogo size='M' light text />
              </Link>
              <LinkDropdown label={t('links.programs')}>
                <StyledLink to={BrowserRoutes.Default.Programs}>
                  <UniTypography variant='subheader' weight='medium' color='white100'>
                    {t('links.search')}
                  </UniTypography>
                </StyledLink>
                <a href='/#continents-section' style={{ textDecoration: 'none' }}>
                  <UniTypography variant='subheader' weight='medium' color='white100'>
                    {t('links.continents')}
                  </UniTypography>
                </a>
              </LinkDropdown>
              <LinkDropdown label={t('links.services')}>
                <StyledLink to={BrowserRoutes.Default.Programs}>
                  <UniTypography variant='subheader' weight='medium' color='white100'>
                    {t('links.platform')}
                  </UniTypography>
                </StyledLink>
                <a href='/#tutors' style={{ textDecoration: 'none' }}>
                  <UniTypography variant='subheader' weight='medium' color='white100'>
                    {t('links.tutoring')}
                  </UniTypography>
                </a>
                {/*<StyledLink to={BrowserRoutes.Default.Programs}>*/}
                {/*  <UniTypography variant='subheader' weight='medium' color='white100'>*/}
                {/*    {t('links.essay_review')}*/}
                {/*  </UniTypography>*/}
                {/*</StyledLink>*/}
              </LinkDropdown>
              <a href={BrowserRoutes.Default.About} style={{ textDecoration: 'none' }}>
                <UniTypography variant='subheader' weight='medium' color='white100'>
                  {t('links.about_us')}
                </UniTypography>
              </a>
              {/*<LinkDropdown label={t('links.about_us')}>*/}
              {/*  <StyledLink to={BrowserRoutes.Default.About}>*/}
              {/*    <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*      {t('links.who_we_are')}*/}
              {/*    </UniTypography>*/}
              {/*  </StyledLink>*/}
              {/*  <StyledLink to={BrowserRoutes.Default.Programs}>*/}
              {/*    <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*      {t('links.youni_news')}*/}
              {/*    </UniTypography>*/}
              {/*  </StyledLink>*/}
              {/*  <StyledLink to={BrowserRoutes.Default.Programs}>*/}
              {/*    <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*      {t('links.experts')}*/}
              {/*    </UniTypography>*/}
              {/*  </StyledLink>*/}
              {/*  <Box onClick={() => window.open('https://blog.uniapply.org', '_blank')} style={{ cursor: 'pointer' }}>*/}
              {/*    <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*      {t('links.blog')}*/}
              {/*    </UniTypography>*/}
              {/*  </Box>*/}
              {/*  <StyledLink to={BrowserRoutes.Default.Programs}>*/}
              {/*    <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*      {t('links.investors')}*/}
              {/*    </UniTypography>*/}
              {/*  </StyledLink>*/}
              {/*</LinkDropdown>*/}
              {/*<LinkDropdown label={t('links.resources')}>*/}
              {/*  <Box*/}
              {/*    onClick={() =>*/}
              {/*      window.open(*/}
              {/*        'https://docs.google.com/forms/d/e/1FAIpQLSc7KUmePPgvH6SNYPlTpXQlmgg3NqgSSFJaezw3mmTwFw3hvA/viewform',*/}
              {/*        '_blank',*/}
              {/*      )*/}
              {/*    }*/}
              {/*    style={{ cursor: 'pointer' }}*/}
              {/*  >*/}
              {/*    <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*      {t('links.ambassador')}*/}
              {/*    </UniTypography>*/}
              {/*  </Box>*/}
              {/*  <StyledLink to={BrowserRoutes.Default.Programs}>*/}
              {/*    <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*      {t('links.podcast')}*/}
              {/*    </UniTypography>*/}
              {/*  </StyledLink>*/}
              {/*  <StyledLink to={BrowserRoutes.Default.Programs}>*/}
              {/*    <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*      {t('links.youni_youth')}*/}
              {/*    </UniTypography>*/}
              {/*  </StyledLink>*/}
              {/*  <Box onClick={() => window.open('https://community.uniapply.org', '_blank')} style={{ cursor: 'pointer' }}>*/}
              {/*    <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*      {t('links.forum')}*/}
              {/*    </UniTypography>*/}
              {/*  </Box>*/}
              {/*  <StyledLink to={BrowserRoutes.Default.Events.All}>*/}
              {/*    <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*      {t('links.events')}*/}
              {/*    </UniTypography>*/}
              {/*  </StyledLink>*/}
              {/*</LinkDropdown>*/}
              {/*<a href='#footer-section' style={{ textDecoration: 'none' }}>*/}
              {/*  <UniTypography variant='subheader' weight='medium' color='white100'>*/}
              {/*    {t('links.contact')}*/}
              {/*  </UniTypography>*/}
              {/*</a>*/}
            </Box>
            <Box display='flex' flexDirection='row' alignItems='center' style={{ gap: theme.spacing(3) }}>
              <LanguageDropdown />
              <UniButton
                startIcon={
                  <UniIconWrapper size='main' color='white100'>
                    <WhatsappIcon />
                  </UniIconWrapper>
                }
                size='small'
                variant='secondary'
                color='white'
                onClick={() => {
                  window.open('https://wa.me/40774051652', '_blank');
                }}
              >
                {t('buttons.contact_us')}
              </UniButton>
              <UniButton
                startIcon={
                  <UniIconWrapper size='main' color='white100'>
                    <LoginIcon />
                  </UniIconWrapper>
                }
                size='small'
                variant='secondary'
                color='white'
                onClick={() => {
                  history.push(BrowserRoutes.Default.Auth.Login);
                }}
              >
                {t('buttons.log_in')}
              </UniButton>
              <UniButton
                startIcon={
                  <UniIconWrapper size='main' color='white100'>
                    <SignupIcon />
                  </UniIconWrapper>
                }
                size='small'
                style={{ whiteSpace: 'nowrap', minWidth: 'max-content' }}
                onClick={() => {
                  history.push(BrowserRoutes.Default.Auth.SignUp);
                }}
              >
                {t('buttons.sign_up')}
              </UniButton>
            </Box>
          </>
        ) : (
          <>
            <UniButton
              startIcon={
                <UniIconWrapper size='main' color='primary100'>
                  <WhatsappIcon />
                </UniIconWrapper>
              }
              size='small'
              variant='secondary'
              color='primary'
              onClick={() => {
                window.open('https://wa.me/40774051652', '_blank');
              }}
            >
              {t('buttons.contact_us')}
            </UniButton>
            <AuthDropdown logout={logout} gotoProfile={goToProfile}></AuthDropdown>
          </>
        )}
      </StyledAuthNavbar>
    </>
  );
}
