import { useCallback, useState } from 'react';

type SortFunction<T> = (a: T, b: T) => number;

interface UseSortableResult<T> {
  sortedData: T[];
  setSortedData: (data: T[]) => void;
  sort: (sortFunction: SortFunction<T>) => void;
}

export function useProgramsSort<T>(initialData: T[]): UseSortableResult<T> {
  const [sortedData, setSortedData] = useState(() => initialData);

  const sort = useCallback(
    (sortFunction: SortFunction<T>) => {
      const newData = [...sortedData].sort(sortFunction);
      setSortedData(newData);
    },
    [sortedData],
  );

  return { sortedData, setSortedData, sort };
}
