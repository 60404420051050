import styled from 'styled-components';
import { Colors } from '../../../constants/uiConfig';
import { Size } from './UniProgressCircle';

const SizeMap = {
  s: 32,
  m: 40,
  l: 48,
  xl: 56,
  xxl: 64,
};

export const StyledProgressCircle = styled.div<{ selected?: boolean; passed?: boolean; size: Size }>`
  border-radius: 50%;
  width: ${({ size }) => SizeMap[size]}px;
  height: ${({ size }) => SizeMap[size]}px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ selected, passed }) => (selected ? Colors.Secondary._100 : passed ? Colors.Secondary._70 : Colors.Primary._10)};
  transition: background-color 0.2s;
`;

export const StyledProgressLabel = styled.div`
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  position: absolute;
  left: -50%;
  right: -50%;
`;
