import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { queryCache, useMutation } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

const usePostPassportForStudent = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: FormData) => {
      return client!.FileApi_uploadPassportForStudent(undefined, payload as unknown as Components.Schemas.StudentIdRequest);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Agency.Candidate);
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default usePostPassportForStudent;
