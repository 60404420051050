import React from 'react';
import usePrepPacks from 'api/preppacks/usePrepPacks';
import useProducts from 'api/products/useProducts';
import UniPrepCenterProductsList from 'components/prepCenterProductsList/UniPrepCenterProductsList';
import useQuery from 'hooks/useQuery';

interface Props {}

export const StudentPrepCenterProductsList: React.FC<Props> = () => {
  const { category } = useQuery();
  const { data: products } = useProducts(category as 'Mathematics' | 'Science' | 'Language');
  const { data: prepPacks } = usePrepPacks();

  return (
    <>
      {products && prepPacks && <UniPrepCenterProductsList products={products} prepPacks={prepPacks} />}
    </>
  );
};

export default StudentPrepCenterProductsList;
