import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { queryCache, useMutation } from 'react-query';
import { useParams } from 'react-router';
import { getMetaRole } from 'utils';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

const useDeletePassport = () => {
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile.role!);
  const { id: studentId } = useParams<{ id?: string }>();
  const { client } = useClient();

  return useMutation(
    async (payload: string) => {
      return client!.AgencyApi_deleteStudentPassport({
        agencyId: isStudent ? profile.studentProfile?.agent.agentProfile.agency.id! : profile.agentProfile?.agency.id!,
        studentId: isStudent ? profile.id! : studentId!,
      });
    },
    {
      onMutate: () => {
        const queryKey = isStudent ? CacheKeys.Profile.Default : [CacheKeys.Agency.Candidate, { studentId }];

        queryCache.cancelQueries(queryKey);

        // Snapshot the previous value
        const previousProfile = queryCache.getQueryData<Components.Schemas.UserDto>(queryKey);

        // Optimistically update to the new value
        queryCache.setQueryData(queryKey, (old: any) => {
          let newProfile = {
            ...old,
            studentProfile: {
              ...old?.studentProfile,
              passportDocument: undefined,
            },
          };

          return newProfile;
        });

        // Return the snapshotted value
        return () => queryCache.setQueryData(queryKey, previousProfile);
      },
      onSettled: async response => {
        isStudent && queryCache.invalidateQueries(CacheKeys.Profile.Default);
        !isStudent && queryCache.invalidateQueries(CacheKeys.Agency.Candidate);
      },
      onError: (error: AxiosError, newProfile, rollback) => {
        rollback();
      },
    },
  );
};
export default useDeletePassport;
