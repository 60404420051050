import { UniDragNDrop, UniTypography } from 'components';
import { Controller } from 'react-hook-form';

interface Props {
  control: any;
}

const Step5: React.FC<Props> = ({ control }) => {
  return (
    <>
      <Controller
        control={control}
        name='documents'
        render={({ value, onChange }) => (
          <UniDragNDrop
            label='Add Documents'
            fullWidth
            multiple
            accept='application/pdf, image/jpg, image/png'
            value={value}
            onDelete={newFiles => onChange(newFiles)}
            handleChange={(files: File[]) => {
              if (files.length > 0) {
                onChange(files);
              }
            }}
          >
            <UniTypography color='typographyGray100'>Drag & Drop or</UniTypography>
            <UniTypography color='primary100'>Upload JPG, PDF or PNG file</UniTypography>
          </UniDragNDrop>
        )}
      />
    </>
  );
};

export default Step5;
