import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { useAuthUser } from 'api';
import useIncompleteQuizzes from 'api/quizzes/useIncompleteQuizzes';
import PermissionsProvider from 'components/_common/permissionsProvider/PermissionsProvider';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { useClient, useDrawers } from 'store';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { getMetaRole } from 'utils';
import App from './App';
import './i18n';
import { theme } from './theme';
import GlobalStyle from './theme/globalStyles';

// hotjar.initialize(2256347, 6);

const StyleLoader = () => {
  const { isClientLoaded, initClient, session } = useClient();
  const { openDrawer } = useDrawers();

  const enableAuthUser = isClientLoaded && session?.token;
  const { data: profile } = useAuthUser({ enabled: enableAuthUser });
  const { isStudent } = getMetaRole(profile?.role);
  const enableQuiz = isClientLoaded && isStudent;
  const { data: incompleteQuiz } = useIncompleteQuizzes({ enabled: enableQuiz });

  useEffect(() => {
    if (incompleteQuiz?.id) openDrawer('interests-quiz', { quiz: incompleteQuiz });
  }, [incompleteQuiz]);

  useEffect(() => {
    initClient();
  }, []);

  return (
    <StylesProvider injectFirst>
      <StyledThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <PermissionsProvider>
            <Router>
              <CssBaseline />
              <GlobalStyle />
              <App />
            </Router>
          </PermissionsProvider>
        </ThemeProvider>
      </StyledThemeProvider>
    </StylesProvider>
  );
};

ReactDOM.render(
  // <React.StrictMode>
  <StyleLoader />,
  // </React.StrictMode>,
  document.getElementById('root'),
);
