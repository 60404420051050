import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../constants';
import ComponentColors, { TComponentColors } from '../../constants/componentColors';

export const StyledProgramExtendedWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const StyledProgramExtendedData = styled.div`
  flex: 6;
`;
export const StyledProgramExtendedRequirements = styled.div`
  flex: 4;
  @media (max-width: 1000px) {
    order: -1;
  }
`;
export const StyledProgramDataCard = styled.div`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  box-shadow: ${Shadows.shadow2};
  padding: ${({ theme }) => theme.spacing(3, 4)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledNameRow = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1.3fr 1.2fr 1fr;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  @media (max-width: 1000px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const StyledRow = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  @media (max-width: 1000px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ProgeramGeneralMessageTypeAttachments = styled.div<{
  color: TComponentColors;
}>`
  background-color: ${({ color }) => ComponentColors[color]}11;
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-radius: ${Shape.BorderRadius.md}px;
`;

export const StyledDivider = styled.div`
  height: 3px;
  width: 100%;
  background-color: ${Colors.Gray1._100};
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;
