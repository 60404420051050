import { Box } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFile, useResendAgentInvite } from '../../../../api';
import { ReactComponent as CopyIcon } from '../../../../assets/svg/Icons/Copy.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/svg/Icons/Messages.svg';
import { ReactComponent as StarEmptyIcon } from '../../../../assets/svg/Icons/StarEmpty.svg';
import { ReactComponent as StarFullIcon } from '../../../../assets/svg/Icons/StarFull.svg';
import { Components } from '../../../../client/UniClient';
import { UniAvatar, UniButton, UniCheckbox, UniIconWrapper, UniTooltip, UniTypography } from '../../../../components';
import { BrowserRoutes } from '../../../../constants';
import { StyledMyAgentsListItem } from './MyAgentsListItem.style';

interface AgentWithStarred extends Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse> {
  starred?: boolean;
}

type AgentProps = {
  agent: AgentWithStarred & Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse>;
  onClick: Function;
  selectAgent: Function;
  selected: boolean;
  setAgentForResend?: Function;
  setModalOpen?: Function;
};

const MyAgentsListItem: React.FC<AgentProps> = React.memo(({ agent, onClick, selectAgent, selected, setAgentForResend, setModalOpen }) => {
  const { profileImageFile, firstName, lastName, agentMetadata, id, agentProfile, inviteLink, emailConfirmed } = agent || {};
  const [resendInvite, { data: resendResponse, isLoading, status, error: postAgentError }] = useResendAgentInvite();
  const { t } = useTranslation('agencyAdminAgents');
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const profileImageSrc = useFile({ id: profileImageFile?.id || '' }, { enabled: !!profileImageFile });
  return (
    <StyledMyAgentsListItem onClick={() => onClick(id)}>
      <UniCheckbox
        checked={selected}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          e.stopPropagation();
          selectAgent(agent);
        }}
      />
      <Box display='flex' alignItems='center'>
        <UniAvatar online={false} src={profileImageSrc.data?.url} size='S' label={firstName + ' ' + lastName} />
        <Box display='flex' flexDirection='column' ml={1}>
          <Link to={BrowserRoutes.Agent.Agent(agent.agentProfile?.agency.id, agent.id)}>
            <UniTypography color='primary100'>{firstName + ' ' + lastName}</UniTypography>
          </Link>
          <UniTypography variant='footnote' color='typographyGray100'>
            {agent.agentProfile?.fee.amount && agent.agentProfile?.fee.amount > 0 ? `Fee: ${agent.agentProfile?.fee.amount}` : 'Fee: -'}
          </UniTypography>
        </Box>
      </Box>
      <UniTypography variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        Agent
      </UniTypography>
      <UniTypography variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        -
      </UniTypography>
      <UniTypography variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {agentMetadata?.applicationCount || '-'}
      </UniTypography>
      <UniTypography variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {agentMetadata?.acceptedApplicationCount || '-'}
      </UniTypography>
      <UniTypography variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {`${agent?.phonePrefix} ${agent?.phone}` || '-'}
      </UniTypography>
      <Box display='flex' alignItems='center' justifyContent='flex-end'>
        <Box display='flex' alignItems='center' mr='-12px'>
          {!emailConfirmed && (
            <UniButton onClick={() => setAgentForResend && setAgentForResend(agent)} fullWidth={false} color='primaryFade' size='small'>
              {t('buttons.resend')}
            </UniButton>
          )}
          {!emailConfirmed && (
            <UniTooltip title={t('tooltips.copy_invitation')} placement='top' open={showCopyTooltip}>
              <UniIconWrapper
                disabled={emailConfirmed}
                color='primary100'
                withBackground
                onClick={e => {
                  e.stopPropagation();
                  copy(inviteLink || '');
                  setShowCopyTooltip(true);
                  setTimeout(() => {
                    setShowCopyTooltip(false);
                  }, 3000);
                }}
              >
                <CopyIcon />
              </UniIconWrapper>
            </UniTooltip>
          )}
        </Box>
        <UniIconWrapper
          color='primary100'
          withBackground
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <MessageIcon />
        </UniIconWrapper>
        <UniIconWrapper
          color={agent.starred ? 'primary100' : 'typographyGray30'}
          // withBackground
          onClick={e => {
            e.stopPropagation();
            agent.starred = agent.starred ? false : true;
          }}
        >
          {agent.starred ? <StarFullIcon /> : <StarEmptyIcon />}
        </UniIconWrapper>
      </Box>
    </StyledMyAgentsListItem>
  );
});
export default React.memo(MyAgentsListItem);
