import { UniModalDrawerButtons } from 'components';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';

interface Props {
  loading: boolean;
  step: number;
  prevStep: () => void;
  nextStep: () => void;
}

const ActionsFooter: React.FC<Props> = ({ step, loading, nextStep, prevStep }) => {
  const { closeDrawer } = useDrawers();
  const { t: buttonsIntl } = useTranslation('buttons');

  const buttonTexts = [
    {
      secondaryCallback: prevStep,
      primaryCallback: nextStep,
      secondary: buttonsIntl('previous'),
      primary: buttonsIntl('next'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: nextStep,
      secondary: buttonsIntl('previous'),
      primary: buttonsIntl('next'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: nextStep,
      secondary: buttonsIntl('previous'),
      primary: buttonsIntl('next'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: nextStep,
      secondary: buttonsIntl('previous'),
      primary: buttonsIntl('next'),
    },
    {
      primaryCallback: closeDrawer,
      primary: buttonsIntl('back_to_activity_plan'),
    },
  ];

  return (
    <UniModalDrawerButtons
      SecondaryButton={
        buttonTexts[step]?.secondary
          ? {
              onClick: buttonTexts[step]?.secondaryCallback,
              label: buttonTexts[step].secondary,
            }
          : undefined
      }
      PrimaryButton={{
        onClick: buttonTexts[step]?.primaryCallback,
        label: buttonTexts[step]?.primary,
        loading,
      }}
    />
  );
};

export default ActionsFooter;
