import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Profile } from '../../types/profile';
import { StyledProgramExtendedWrapper } from '../programExtended/ProgramExtended.style';
import { AgentDetailsAgency } from './AgentDetailsAgency';
import { AgentDetailsFinancial } from './AgentDetailsFinancial';
import { AgentDetailsPersonal } from './AgentDetailsPersonal';

export default function AgentDetails(props: {
  isOwner: boolean;
  setValue: Function;
  control: any;
  agentProfile?: Partial<Profile>;
  isEditing: boolean;
  inputRef: React.Ref<any>;
  hasError?: string;
}): ReactElement {
  const { isOwner, agentProfile, isEditing, inputRef, hasError, setValue, control } = props;

  return (
    <StyledProgramExtendedWrapper>
      <Box flex={6}>
        <AgentDetailsPersonal
          setValue={setValue}
          control={control}
          isOwner={isOwner}
          profile={agentProfile}
          isEditing={isEditing}
          inputRef={inputRef}
          hasError={hasError}
        />
        <AgentDetailsFinancial profile={agentProfile} isOwner={isOwner} />
      </Box>
      {agentProfile?.agentProfile?.agency?.id && (
        <Box flex={6}>
          <AgentDetailsAgency agencyId={agentProfile.agentProfile.agency?.id} />
        </Box>
      )}
    </StyledProgramExtendedWrapper>
  );
}
