import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UniTextArea, UniTypography } from '../../..';

interface Props {
  message?: string;
  setMessage: Function;
}

export default function UniversityReplyStep3({ message, setMessage }: Props): ReactElement {
  const { t } = useTranslation('universityReplyForm');

  return (
    <>
      <UniTypography variant='header3'>{t('step3.title')}</UniTypography>
      <UniTypography color='typographyGray70'>{t('step3.subtitle')}</UniTypography>
      <UniTextArea withBorder defaultValue={message} rowsMin={6} onChange={({ target }) => setMessage(target.value)} />
    </>
  );
}
