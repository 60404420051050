import styled from 'styled-components';

export const StyledPackages = styled.div`
  position: relative;
`;

export const StyledPackagesListHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 5fr 5fr 1fr;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;
