import React from 'react';
import { StyledContinentTileDescription } from './ContinentTileDescription.style';

interface Props {
  title: string;
  description: string;
}

export const ContinentTileDescription: React.FC<Props> = ({ title, description }) => {
  return <StyledContinentTileDescription>
    <h1>{title}</h1>
    <div className="continent-description">{description}</div>
  </StyledContinentTileDescription>;
};

export default ContinentTileDescription;
