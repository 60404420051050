import { QueryConfig, useInfiniteQuery } from 'react-query';
import { ApplicationsFiltersQueries } from '../../components/_drawers/filtersDrawer/_types/ApplicationsFilters';
import { ApiKeys, CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

type Params = {
  universityId?: string;
  filters?: ApplicationsFiltersQueries;
  sortType?: number;
  pageSize?: number;
};

export default function useUniversityApplications(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  const { profile } = useAuth();
  return useInfiniteQuery(
    [CacheKeys.Applications.All, params],
    async (_key: string, { filters, pageSize = ApiKeys.PageSize.Programs, universityId }: Params, page: number = 1) => {
      const { data } = await client!.UniversityApi_getUniversityApplications({
        universityId: universityId as string,
        page,
        pageSize,
        ...filters,
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Programs || lastPage.page * ApiKeys.PageSize.Programs === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
    },
  );
}
