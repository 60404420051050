import { useQueryStringParser } from 'hooks/useQueryStringParser';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UniIconWrapper } from '../..';
import { ReactComponent as GarbageIcon } from '../../../assets/svg/Icons/Garbage.svg';
import { StyledFiltersButton } from './FiltersDrawer.style';

interface Props {}

export default function FiltersDrawerClearFilters({}: Props): ReactElement {
  const { replaceQueriesToUrl } = useQueryStringParser<any>();
  const { t } = useTranslation('filtersDrawer');

  const onReset = () => {
    replaceQueriesToUrl({ clear: true });
  };

  return (
    <StyledFiltersButton
      variant='text'
      fullWidth={false}
      color='neutral'
      onClick={() => onReset()}
      startIcon={
        <UniIconWrapper size='medium' color='typographyGray70'>
          <GarbageIcon />
        </UniIconWrapper>
      }
    >
      {t('buttons.clear')}
    </StyledFiltersButton>
  );
}
