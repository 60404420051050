import create from 'zustand';
import { SnackbarState } from './types';

const useSnackbar = create<SnackbarState>((set, get) => ({
    snackbar: undefined,
    props: { mobileOver : 'navigation', hide: undefined},
    setHide: (id) => set(state => ({...state, props: { ...state.props, hide: id} })),
    openSnackbar: (snackbar, props) => set(state => ({ ...state, snackbar, props: { ...state.props, ...props, mobileOver: props?.mobileOver || 'navigation'}})),
    closeSnackbar: () => set(state => ({ ...state, snackbar: undefined, props: { mobileOver: state.props.mobileOver, hide: state.props.hide, action: undefined } })),
}));

export default useSnackbar;