import React, { useState } from 'react';
import { Box, ClickAwayListener } from '@material-ui/core';
import { StyledDropdown } from '../HeaderNavigation.style';
import { useTranslation } from 'react-i18next';
import { UniIconWrapper, UniTypography } from 'components';
import { ReactComponent as DownIcon } from '../../../../assets/svg/Icons/Arrow/Down.svg';
import { ReactComponent as GlobeIcon } from '../../../../assets/svg/Icons/Globe.svg';

export const LanguageDropdown: React.FC = () => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetAnchor = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const { currentTarget } = event;
    setAnchor(currentTarget);
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    handleClose();
  };

  return (
    <>
      <Box
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '4.3rem' }}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          handleSetAnchor(e);
          handleOpen();
        }}
      >
        <UniIconWrapper size='main' color='white100'>
          <GlobeIcon />
        </UniIconWrapper>
        <UniTypography variant='subheader' weight='medium' color='white100' transform='uppercase'>
          {i18n.language}
        </UniTypography>
        <UniIconWrapper color='primary100'>
          <DownIcon style={{ marginLeft: '-0.8rem' }} />
        </UniIconWrapper>
      </Box>
      {open && (
        <StyledDropdown open={open} anchorEl={anchor} placement='bottom'>
          <ClickAwayListener onClickAway={handleClose}>
            <Box display='flex' flexDirection='column'>
              <UniTypography
                variant='subheader'
                weight='medium'
                color='white100'
                onClick={() => handleLanguageChange('en')}
                style={{ cursor: 'pointer' }}
              >
                English
              </UniTypography>
              <UniTypography
                variant='subheader'
                weight='medium'
                color='white100'
                onClick={() => handleLanguageChange('ro')}
                style={{ cursor: 'pointer' }}
              >
                Romanian
              </UniTypography>
              <UniTypography
                variant='subheader'
                weight='medium'
                color='white100'
                onClick={() => handleLanguageChange('ua')}
                style={{ cursor: 'pointer' }}
              >
                Ukrainian
              </UniTypography>
            </Box>
          </ClickAwayListener>
        </StyledDropdown>
      )}
    </>
  );
};
