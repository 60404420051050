import { Box } from '@material-ui/core';
import usePostPreferences from 'api/users/usePostPrefferences';
import UniIconWrapper from 'components/_common/uniIconWrapper/UniIconWrapper';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import QueryString from 'query-string';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useAuth } from 'store';
import { SubjectType } from 'types/programFilters';
import { ReactComponent as CloseIcon } from '../../assets/svg/Icons/CloseFilters.svg';
import { BrowserRoutes } from '../../constants';
import { countries } from '../../constants/countries';
import { Nationality, ProgramFilters } from '../../types';
import { UniButtonCircular } from '../_common/uniButtonCircular/UniButtonCircular';
import { StyledPopup } from './UpdatePreferencesPopup.style';

interface Props {
  handleClose?: () => void;
}

const UpdatePreferencesPopup: React.FC<Props> = ({ handleClose }) => {
  const [clickedUpdate, setClickedUpdate] = useState(false);
  const { profile } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const queryFilters = QueryString.parse(location.search) as ProgramFilters;
  const preferences = profile?.studentProfile?.preferences;
  const [updatePreferences] = usePostPreferences();
  const { t } = useTranslation('updatePreferencesPopup');

  return (
    <StyledPopup>
      <Box position='absolute' top={clickedUpdate ? '-25%' : '-20%'} left='98%'>
        <UniButtonCircular
          iconColor='white100'
          hoverColor='white100'
          hasSize
          Icon={
            <UniIconWrapper size='main' color='typographyGray100'>
              <CloseIcon />
            </UniIconWrapper>
          }
          onClick={() => handleClose && handleClose()}
        />
      </Box>
      <Box>
        {!clickedUpdate ? (
          <Box>
            <UniTypography color='typographyGray100'>{t('change_detected')}</UniTypography>
            <Box display='flex' style={{ gap: '5px' }}>
              <UniTypography color='typographyGray100'>{t('want_update')}</UniTypography>
              <Box
                onClick={() => {
                  setClickedUpdate(true);
                  updatePreferences({
                    studentPreferences: {
                      countries:
                        [queryFilters?.country].flat(1)?.length! !== 0
                          ? countries
                              .filter(item => [queryFilters?.country].flat(1)!.includes(item.code))
                              .map(item => item.code as Nationality)
                          : preferences?.countries!,
                      subjects: (queryFilters?.subjects as SubjectType[]) || preferences?.subjects,
                      fee: preferences?.fee!,
                      livingExpenses: preferences?.livingExpenses!,
                    },
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                <UniTypography color='primary100'>{t('buttons.update_preferences')}</UniTypography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box display='flex' style={{ gap: '5px' }}>
            <UniTypography color='typographyGray100'>{t('update_success')}</UniTypography>
            <Box
              onClick={() => {
                history.push(
                  BrowserRoutes.Student.Profile.Default.replace(
                    ':tab',
                    BrowserRoutes.Student.Profile.Info.Default + BrowserRoutes.Student.Profile.Info.SubRoutes.Preferences,
                  ),
                );
              }}
              style={{ cursor: 'pointer' }}
            >
              <UniTypography color='primary100'>{t('buttons.profile')}</UniTypography>
            </Box>
          </Box>
        )}
      </Box>
    </StyledPopup>
  );
};

export default UpdatePreferencesPopup;
