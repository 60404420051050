import React, { ReactElement, useState } from 'react';
import { UniOptionsField, UniModalDrawerButtons } from 'components';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { EducationDrawerState } from '../AddEducationDrawer';

export type AddEdItemStep2Inputs = {
  didGraduate: boolean;
};

interface Props {
  nextStep: Function;
  prevStep: Function;
  drawerState: EducationDrawerState;
  setDrawerState: Function;
}

export default function AddEducationItemStep2({ nextStep, drawerState, setDrawerState, prevStep }: Props): ReactElement {
  const { t } = useTranslation('addEducationDrawer');
  const { handleSubmit, control, errors } = useForm<AddEdItemStep2Inputs>();
  const onSubmit = (formData: AddEdItemStep2Inputs) => {
    formData.didGraduate = formData.didGraduate.toString() === t('step2.yes');
    setDrawerState(formData);
    nextStep();
  };

  return (
    <>
      <UniModalDrawerContent>
        <Controller
          name='didGraduate'
          control={control}
          rules={{ required: `${t('errors.has_diploma')}` }}
          defaultValue={drawerState?.didGraduate === undefined ? undefined : drawerState?.didGraduate ? t('step2.yes') : t('step2.no')}
          render={({ onChange, value }) => (
            <UniOptionsField
              options={[
                { label: t('step2.yes'), value: t('step2.yes') },
                { label: t('step2.no'), value: t('step2.no') },
              ]}
              value={value}
              label={t('step2.has_diploma')}
              onChange={onChange}
              errorMsg={errors?.didGraduate?.message}
            />
          )}
        />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: () => prevStep(), label: t('buttons.back') }}
        PrimaryButton={{ onClick: handleSubmit(onSubmit), label: t('buttons.next') }}
      ></UniModalDrawerButtons>
    </>
  );
}
