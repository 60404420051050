import { AxiosError } from 'axios';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { QueryConfig, useInfiniteQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

type Params = {
  filters?: ProgramsFiltersQueries;
  query?: string;
  sortType?: number;
  _page?: number;
  universityId?: string;
};

export default function useMyPrograms(params?: Params, config?: QueryConfig<Error>) {
  const { facultyMemberProfile } = useAuth(state => state.profile);
  const { client } = useClient();

  return useInfiniteQuery(
    [CacheKeys.General.Programs, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.SearchApi_searchPrograms({
        ...params.filters,
        query: params?.query || undefined,
        // sortType: params.sortType || 1,
        universityId: [params?.universityId || facultyMemberProfile?.universityId || ''],
        pageSize: ApiKeys.PageSize.Programs,
        page,
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Programs || lastPage.page * ApiKeys.PageSize.Programs === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
