import { usePatchCandidate } from 'api';
import useUpdateStudent from 'api/students/useUpdateStudent';
import { Components } from 'client/UniClient';
import { ContactForm } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { ContactFormInputs } from 'components/_forms/ContactForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth, useDrawers, useSnackbar } from 'store';
import { getMetaRole } from 'utils';
import { OnboardFormState } from 'views/_student/onboard/StudentOnboard';

interface Props {
  student?: Components.Schemas.UserResponse;
  onboard?: boolean;
  changeDrawer?: Function;
}

const ContactFormDrawer: React.FC<Props> = ({ student, onboard, changeDrawer }) => {
  const { t } = useTranslation('forms');
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const { profile } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ContactFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { ...student, ...student?.studentProfile },
  });
  const { pathname } = useLocation();
  const [updateStudent, { status: updateStudentStatus, error: updateStudentError }] = useUpdateStudent();
  const [updateStudentAgency, { status: updateStudentAgencyStatus, error: updateStudentAgencyError }] = usePatchCandidate();
  const isLoading = updateStudentStatus === 'loading' || updateStudentAgencyStatus === 'loading';
  const { isAgency, isAgent, isStudent } = getMetaRole(profile?.role);

  const onSubmit = (formData: OnboardFormState) => {
    const contactInfo = {
      ...formData,
      studentProfile: {
        dateOfBirth: formData.dateOfBirth,
        countryOfBirth: formData.countryOfBirth!,
        nationality: formData.nationality,
        secondNationality: formData.secondNationality,
        gender: formData.gender,
        address: {
          addressLine1: formData.address?.addressLine1,
          addressLine2: formData.address?.addressLine2,
          postalCode: formData.address?.postalCode,
          city: formData.address?.city,
          country: formData.address?.country,
        },
      },
    };

    if (isStudent) {
      updateStudent(contactInfo, {
        onSuccess() {
          closeDrawer();
          openSnackbar('contact-details');
        },
        onError() {},
      });
    }
    if (isAgency) {
      const studentId = pathname.split('/')[4];
      updateStudentAgency(
        { ...contactInfo, studentId },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('contact-details');
          },
          onError() {},
        },
      );
    }
    if (isAgent) {
      const studentId = pathname.split('/')[4];
      updateStudentAgency(
        { ...contactInfo, studentId },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('contact-details');
          },
          onError() {},
        },
      );
    }
  };

  return (
    <>
      <UniModalDrawerContent>
        <ContactForm
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          student={student}
          onboard={onboard}
          changeDrawer={changeDrawer}
        />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: t('buttons.save_changes'),
          loading: isLoading,
        }}
        SecondaryButton={{ onClick: closeDrawer, label: t('buttons.cancel') }}
      />
    </>
  );
};

export default ContactFormDrawer;
