import { Components } from 'client/UniClient';
import { countries, tuitionFeeMap } from '../constants';
import { LevelOfStudy } from '../constants/levelOfStudy';

export const parseLevelOfStudy = (levelOfStudy?: LevelOfStudy) => {
  const levelOfStudyString = levelOfStudy?.toString();

  if (levelOfStudyString === 'SHORT_COURSE') {
    return 'Short Courses';
  } else if (levelOfStudy === 'ASSOCIATE') {
    return "Associate's Degree";
  } else if (levelOfStudy === 'BACHELOR') {
    return "Bachelor's Degree";
  } else if (levelOfStudy === 'DOCTORATE') {
    return "Doctorate's Degree";
  } else if (levelOfStudy === 'MASTER') {
    return "Master's Degree";
  } else {
    return 'Unspecified';
  }
};

export const parseAddress = (address?: Components.Schemas.AddressDto) => {
  const country = address?.country;
  const city = address?.city;
  const countryString = countries.find(item => item.code === country)?.name;

  if (city === 'Online') {
    return 'Online courses';
  } else if (city && countryString) {
    return `${city}, ${countryString}`;
  } else if (city) {
    return city;
  } else if (countryString) {
    return countryString;
  } else if (city) {
    return `${city}`;
  } else {
    return 'Unspecified';
  }
};

export const parseVirtualAddress = (address?: Components.Schemas.VirtualAddressDto) => {
  const phone = address?.phone;
  const prefix = address?.phonePrefix;
  const email = address?.email;

  if (email) return email;
  if (phone && prefix) return `${prefix} ${phone}`;
  if (phone) return phone;
  return 'Unspecified';
};

export const parseApplicationFee = (fee?: Components.Schemas.ApplicationFeeDto) => {
  const currencyIso = fee?.currency?.currencyIso;
  const amount = fee?.currency?.amount;

  if (currencyIso && amount) return `${amount} ${currencyIso}`;
  return 'Unspecified';
};

export const parseApplicationFeeInvoice = (fee?: Components.Schemas.ApplicationFeeInvoiceDto) => {
  let currencyIso = fee?.totalGrossPrice?.currencyIso;
  let amount = fee?.totalGrossPrice?.amount;

  if (fee?.stripeTotalPrice) {
    currencyIso = fee.stripeTotalPrice.currencyIso;
    amount = fee.stripeTotalPrice.amount;
  }

  if (currencyIso && amount) return `${amount} ${currencyIso}`;
  return 'Unspecified';
};

export const parseTuitionFee = (fee?: Components.Schemas.TuitionFeeDto) => {
  const currencyIso = fee?.currency?.currencyIso;
  const amount = fee?.currency?.amount;
  const unit = fee?.lecturePeriod;

  if (currencyIso && amount && unit) return `${amount} ${currencyIso} per ${tuitionFeeMap.unit[unit]}`;
  return 'Unspecified';
};
