import { AxiosError } from 'axios';
import { QueryConfig, useInfiniteQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

type Params = {
  programId: string;
};

export default function useProgramApplications(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  const { profile } = useAuth();

  return useInfiniteQuery(
    [CacheKeys.Applications.All, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.ProgramApi_getProgramApplications(
        {
          programId: params.programId,
          pageSize: ApiKeys.PageSize.Programs,
          page: page,
        },
        { programId: params.programId },
      );
      return data;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Applications || lastPage.page * ApiKeys.PageSize.Applications === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
