import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../constants';

export const StyledSessionHistoryItemLogo = styled.img`
  border-radius: 50%;
  max-height: 32px;
`;

export const StyledSessionHistoryItem = styled.div`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  box-shadow: ${Shadows.shadow1};

  display: grid;
  grid-template-columns: 1fr 3fr 3fr 3fr;
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
  min-height: ${({ theme }) => theme.typography.pxToRem(73)};
`;
