import useUpdateStudent from 'api/students/useUpdateStudent';
import AnimatedTabPanel from 'components/_common/animatedTabPanel/AnimatedTabPanel';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { AnimatePresence } from 'framer-motion';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth, useDrawers } from 'store';
import { getMetaRole } from 'utils';
import { UniModalDrawerButtons } from '../..';
import { BrowserRoutes } from '../../../constants';
import PublishPublicProfileStep1 from './_steps/Step1';
import PublishPublicProfileStep2 from './_steps/Step2';

interface Props {}

export default function PublishPublicProfile({}: Props): ReactElement {
  const { t: tButtons } = useTranslation('buttons');
  const { closeDrawer } = useDrawers();
  const history = useHistory();
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile.role);
  const [currentTab, setCurrentTab] = useState(0);
  const [postPersoalInfo, { isLoading: personalInfoIsLoading }] = useUpdateStudent();

  const nextStep = () => setCurrentTab(prev => prev + 1);

  const onBackToProfile = () => {
    closeDrawer();
    history.push(BrowserRoutes.Student.Profile.PersonalInfo);
  };

  const publishProfile = () => {
    let studentProfile = profile?.studentProfile;
    if (!studentProfile) return;
    const publicProfilePreferences = { ...studentProfile.publicProfilePreferences, isPublished: true };
    if (isStudent) {
      postPersoalInfo(
        { studentProfile: { publicProfilePreferences } },
        {
          onSuccess() {
            nextStep();
          },
        },
      );
    }
  };

  // if (!program) {
  //   return <></>;
  // }

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel index={0} currentItem={currentTab} Content={<PublishPublicProfileStep1 />} />
          <AnimatedTabPanel index={1} currentItem={currentTab} Content={<PublishPublicProfileStep2 />} />
        </AnimatePresence>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={currentTab === 0 ? { onClick: closeDrawer, label: tButtons('cancel') } : undefined}
        PrimaryButton={
          currentTab === 0
            ? { onClick: publishProfile, label: tButtons('yes_publish'), loading: personalInfoIsLoading }
            : {
                onClick: onBackToProfile,
                label: tButtons('back_to_profile'),
              }
        }
      />
    </>
  );
}
