import { Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { TComponentColors } from 'constants/componentColors';
import React from 'react';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import { StyledCarousel } from './UniCarousel.style';

interface Props {
  speed?: number;
  fadeColor?: TComponentColors;
  children: React.ReactNode;
}

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <Box>
      <UniIconWrapper onClick={onClick} withBackground color='white100' hasBorderRadius size='main'>
        <ArrowForwardIcon />
      </UniIconWrapper>
    </Box>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <Box>
      <UniIconWrapper onClick={onClick} withBackground color='white100' hasBorderRadius size='main'>
        <ArrowBackIcon />
      </UniIconWrapper>
    </Box>
  );
}

export default function UniCarousel({ speed = 300, children, fadeColor }: Props) {
  return (
    <StyledCarousel
      dots
      autoplay
      speed={speed}
      fadeColor={fadeColor}
      // adaptiveHeight
      nextArrow={<SampleNextArrow />}
      prevArrow={<SamplePrevArrow />}
      appendDots={dots => {
        return (
          <Box flex='8'>
            <ul> {dots} </ul>
          </Box>
        );
      }}
    >
      {children}
    </StyledCarousel>
  );
}
