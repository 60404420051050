import { Components } from 'client/UniClient';
import React from 'react';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { UniTypography } from 'components';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import useCompleteProgramInfoRequest from '../../../api/admin/useCompleteProgramInfoRequest';

interface Props {
  programRequest: Components.Schemas.ProgramInfoRequestDto;
  close: Function;
}

const UpdateProgramInfoRequest: React.FC<Props> = ({ programRequest, close }) => {
  const { t } = useTranslation('adminPrograms');
  const [completeRequest] = useCompleteProgramInfoRequest({ programInfoRequestId: programRequest?.id! });
  const onSubmit = () => {
    completeRequest(undefined, {
      onSuccess() {
        close();
      },
    });
  };

  return (
    <>
      <UniModalDrawerContent>
        <Box mb={3}>
          <UniTypography variant='body2' weight='regular' color='typographyBlack100'>
            {t('requests.update_drawer.description1')}
          </UniTypography>
        </Box>
        <UniTypography variant='body2' weight='regular' color='typographyBlack100'>
          {t('requests.update_drawer.description2')}
        </UniTypography>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: close, label: t('requests.buttons.cancel') }}
        PrimaryButton={{
          onClick: onSubmit,
          label: t('requests.buttons.okay'),
        }}
      />
    </>
  );
};

export default UpdateProgramInfoRequest;
