import { Box, Grid } from '@material-ui/core';
import { ApplicationCard, InfiniteWindowedGrid } from 'components';
import { ApplicationsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ApplicationsFilters';
import { BrowserRoutes } from 'constants/browserRoutes';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetUniversityStudentNames, useUniversityApplications } from '../../../api';
import { StickyListFilterBar, UniPage, UniTypography } from '../../../components';
import { useBreakpoints } from '../../../hooks';
import { useAuth, useDrawers } from '../../../store';
import { StyledUniversityImage } from './UniversityHomepage.style';
import UniversityHomepageMetrics from './UniversityHomepageMetrics';

const UniversityHomepage = () => {
  const history = useHistory();
  const { t } = useTranslation('universityApplications');
  const { profile } = useAuth();
  const [query, setQuery] = useState<string>();
  const { isTablet } = useBreakpoints();
  const { queries } = useQueryStringParser<ApplicationsFiltersQueries>();
  const { openDrawer } = useDrawers();

  const {
    data: aplications,
    status: appsStatus,
    fetchMore,
    isFetching,
  } = useUniversityApplications({
    universityId: profile.facultyMemberProfile?.universityId,
    filters: { ...queries, query },
  });
  const { data: namesForSearch } = useGetUniversityStudentNames({
    universityId: profile.facultyMemberProfile?.universityId || '',
  });
  const appsLoading = appsStatus === 'loading';
  const applicationsTotal = aplications ? aplications[0].total : 0;
  const applicationsFlat = aplications?.map(page => page.results)?.flat() || [];
  const totalResultsLabel =
    applicationsTotal === 1 ? applicationsTotal + ' ' + t('result.ifOne') : applicationsTotal + ' ' + t('result.ifMore');
  const applicationsLeft = useMemo(() => applicationsTotal - applicationsFlat.length, [applicationsFlat, applicationsTotal]);

  const openFilterApplicationsDrawer = () => openDrawer('filter-applications', { size: 'small' });

  return (
    <UniPage>
      <Grid md={12} lg={8} item>
        <Box mb={2}>
          <UniTypography color='typographyBlack100' variant='header2' component='h1'>
            {t('greeting')}, <strong>{profile.firstName}</strong>
          </UniTypography>
        </Box>
        <UniversityHomepageMetrics />
      </Grid>
      {!isTablet && (
        <Grid md={12} lg={4} item justify='flex-end' alignItems='center' style={{ display: 'flex' }}>
          <StyledUniversityImage />
        </Grid>
      )}

      <Grid xs={12}>
        <StickyListFilterBar
          listTitle={t('list.title')}
          options={namesForSearch?.map(item => item.label) || []}
          totalResultsLabel={totalResultsLabel}
          onClickFilter={openFilterApplicationsDrawer}
          handleSearch={setQuery}
          searchHint={t('list.search_hint')}
        />
        <InfiniteWindowedGrid
          isFetching={!!isFetching}
          emptyStateType='applications'
          fetchMore={fetchMore}
          isLoading={appsLoading}
          hasMore={applicationsLeft > 0}
          // totalResults={applicationsTotal}
          remainingResults={applicationsLeft}
          minHeight={330}
          results={applicationsFlat?.map((application: any, index: any) => (
            <ApplicationCard
              variant='universityPage'
              studentName={`${application?.student?.firstName + ' ' + application?.student?.lastName}`}
              studentAddress={application?.student?.studentProfile?.address}
              programName={application?.program?.name}
              programAddress={application?.program?.address}
              status={application?.status}
              path={BrowserRoutes.University.Application.General(application?.id)}
            />
          ))}
        />
      </Grid>
    </UniPage>
  );
};

export default UniversityHomepage;
