import { usePatchApplicationStatus } from 'api';
import useUpdateProgram from 'api/programs/useUpdateProgram';
import { Components } from 'client/UniClient';
import AnimatedTabPanel from 'components/_common/animatedTabPanel/AnimatedTabPanel';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import { ApplicationStatus } from 'types';
import Step1 from './_steps/Step1';
import Step3 from './_steps/Step3';
import Step4 from './_steps/Step4';
import Step5 from './_steps/Step5';

export type ReplyToStudentInputs = {
  applicationStatus: ApplicationStatus;
  applicationFee: Components.Schemas.CurrencyRequest;
  noApplicationFee: boolean;
  message: string;
  documents: File[];
};

interface Props {
  application?: Components.Schemas.ApplicationDto;
}
const sortByDate = (a: any, b: any) => {
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);
  return dateB.getTime() - dateA.getTime();
};

const ReplyToStudent: React.FC<Props> = ({ application }) => {
  const [formState, setFormState] = useState<Partial<ReplyToStudentInputs>>({});
  const studentName = `${application?.student.firstName} ${application?.student.lastName}`;
  const { t: tButtons } = useTranslation('buttons');
  const [postReply, { status: postReplyStatus }] = usePatchApplicationStatus();
  const [updateProgram, { status: updateProgramStatus }] = useUpdateProgram();
  const applicationReplies = application?.statusUpdateHistory.sort(sortByDate);
  const lastReply = applicationReplies?.[0];
  const { control, handleSubmit } = useForm<ReplyToStudentInputs>({
    defaultValues: {
      applicationStatus: lastReply?.status,
      ...formState,
    },
    shouldUnregister: false,
  });

  const { setProgress, closeDrawer } = useDrawers();
  const [step, setStep] = useState(0);

  const prevStep = () => {
    setStep(prevStep => prevStep - 1);
  };
  const nextStep = (formData: Partial<ReplyToStudentInputs>) => {
    setFormState(prev => ({ ...prev, ...formData }));
    setStep(prevStep => prevStep + 1);
  };

  const onSubmit = (formData: ReplyToStudentInputs) => {
    postReply(
      {
        applicationId: application?.id!,
        message: formState.message || '',
        status: formState.applicationStatus || 'NEW',
        requestedDocumentId: '',
        files: formState.documents || [],
      },
      {
        onSuccess() {
          setStep(prevStep => prevStep + 1);
        },
      },
    );
  };

  const buttonTexts = [
    {
      secondaryCallback: closeDrawer,
      primaryCallback: handleSubmit(nextStep),
      secondary: tButtons('cancel'),
      primary: tButtons('next'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: handleSubmit(nextStep),
      secondary: tButtons('go_back'),
      primary: tButtons('next'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: handleSubmit(onSubmit),
      secondary: tButtons('go_back'),
      primary: tButtons('reply'),
      loading: postReplyStatus === 'loading',
    },
    {
      secondaryCallback: () => {},
      primaryCallback: closeDrawer,
      primary: tButtons('cancel'),
    },
  ];

  useEffect(() => setProgress(step * 20 + 20), [step]);

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel
            Content={
              <Step1
                control={control}
                programName={application?.program.name}
                universityName={application?.program.universityName}
                studentName={studentName}
              />
            }
            index={0}
            currentItem={step}
          />
          <AnimatedTabPanel
            Content={
              <Step3
                control={control}
                programName={application?.program.name}
                universityName={application?.program.universityName}
                studentName={studentName}
              />
            }
            index={1}
            currentItem={step}
          />
          <AnimatedTabPanel
            Content={
              <Step4
                programName={application?.program.name}
                universityName={application?.program.universityName}
                studentName={studentName}
                formValues={formState}
              />
            }
            index={2}
            currentItem={step}
          />
          <AnimatedTabPanel
            Content={<Step5 programName={application?.program.name} studentName={studentName} />}
            index={3}
            currentItem={step}
          />
        </AnimatePresence>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={
          buttonTexts[step]?.secondary
            ? {
                onClick: () => buttonTexts[step]?.secondaryCallback(),
                label: buttonTexts[step].secondary,
              }
            : undefined
        }
        PrimaryButton={{
          onClick: buttonTexts[step]?.primaryCallback,
          label: buttonTexts[step]?.primary,
          loading: !!buttonTexts[step]?.loading,
        }}
      />
    </>
  );
};

export default ReplyToStudent;
