import useGetAgents from 'api/agency/useGetAgents';
import { ReactComponent as EmailIcon } from 'assets/svg/Icons/Mail.svg';
import { ReactComponent as HomeIcon } from 'assets/svg/Icons/Nav/Home.svg';
import { ReactComponent as PhoneIcon } from 'assets/svg/Icons/PhoneIcon.svg';
import { ReactComponent as ProfileIcon } from 'assets/svg/Icons/Profile.svg';
import { AnimatedTabPanel, UniHeader, UniHerosection, UniIconWrapper, UniPage } from 'components';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useAuth } from 'store';
import { BrowserRoutes } from '../../../constants';
import Consultant from './_tabs/Consultant';
import Orders from './_tabs/Orders';

interface Props {}

const ConsultancyPage: React.FC<Props> = ({ children, ...rest }) => {
  const {
    profile: { studentProfile },
  } = useAuth();
  const agentName = `${studentProfile?.agent.firstName} ${studentProfile?.agent.lastName}`;
  const agency = studentProfile?.agent.agentProfile.agency;
  const { data: agents } = useGetAgents({ agencyId: agency?.id! });
  const location = useLocation();
  const { t } = useTranslation('consultancyPage');

  const currentRoute = location.pathname.includes('orders') ? 0 : 1;

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='consultant'
          title={`${t('herosection.title')} ${agentName}`}
          universityName={agency?.name}
          agents={agents?.results}
          metrics={[
            {
              label: t('herosection.status'),
              value: 'Youni Partner',
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <ProfileIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: t('herosection.phone'),
              value: `${agency?.virtualAddress?.phonePrefix || '-'} ${agency?.virtualAddress?.phone || ''}`,
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <PhoneIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: t('herosection.email'),
              value: agency?.virtualAddress?.email || '-',
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <EmailIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: t('herosection.address'),
              value: `${agency?.address?.addressLine1 || '-'} ${agency?.address?.addressLine2 || ''}`,
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <HomeIcon />
                </UniIconWrapper>
              ),
            },
          ]}
          tabs={[
            {
              label: t('tabs.orders'),
              path: BrowserRoutes.Student.Consultancy.Orders,
            },
            {
              label: t('tabs.consultant'),
              path: BrowserRoutes.Student.Consultancy.Consultant,
            },
          ]}
        />
      </UniHeader>
      <UniPage>
        <Switch>
          <Route path={BrowserRoutes.Student.Consultancy.Orders}>
            <AnimatedTabPanel index={0} currentItem={currentRoute} Content={<Orders orders={[]} />} />
          </Route>
          <Route path={BrowserRoutes.Student.Consultancy.Consultant}>
            <AnimatedTabPanel index={1} currentItem={currentRoute} Content={<Consultant consultant={studentProfile?.agent} />} />
          </Route>
        </Switch>
      </UniPage>
    </>
  );
};

export default ConsultancyPage;
