import { UniButton, UniTypography } from 'components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'store';
import { getUserHomeScreen } from 'utils/getHomeForRole';
import { CenteredPageLayout } from './ErrorPages.style';
export const Unauthorized = () => {
  const {
    profile: { role },
  } = useAuth();
  const history = useHistory();

  return (
    <CenteredPageLayout>
      <UniTypography color='stateAlert100' variant='xl' weight='semi-bold'>
        Oops!
      </UniTypography>
      <UniTypography color='typographyGray100' variant='header3'>
        You are trying to do an unauthorized action!
      </UniTypography>
      <UniButton fullWidth={false} onClick={() => history.push(getUserHomeScreen(role)!)}>
        Go back
      </UniButton>
    </CenteredPageLayout>
  );
};
