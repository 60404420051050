import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryStatus } from 'react-query';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import useGetCareerPathJobs from 'api/ai/useGetCareerPathJobs';
import useGetCareerPath from 'api/ai/useGetCareerPath';
import { ReactComponent as PhoneIcon } from 'assets/svg/Icons/PhoneIcon.svg';
import { UniButton, UniIconWrapper, UniScrollToTop, UniTextField } from 'components';
import { useQuery } from 'hooks';
import {
  FormLabelText,
  StyledFluidContainer,
  StyledFormSection,
  StyledHeroSection,
  StyledTitleContainer,
} from './AssessmentQuiz.style';
import { getQuestionSet } from './Questions';

export const AssessmentQuiz: React.FC = () => {
  const {variant} = useQuery();
  const multipleChoiceQuestions = getQuestionSet(variant as string);
  const {t} = useTranslation('landingHome');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [answers, setAnswers] = useState(multipleChoiceQuestions.map(() => ''));
  const [jobName, setJobName] = useState('');

  const [submitForm, {data: jobsResult, status: jobsApiStatus}] = useGetCareerPathJobs();
  const [submitJobSelection, {data: assessmentResult, status: assessmentApiStatus}] = useGetCareerPath();

  const isEmailValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);

  const isPhoneValid = phone != null && phone !== ''; // TODO: implement proper check

  const answersRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (assessmentApiStatus === QueryStatus.Success && answersRef.current != null) {
      answersRef.current.scrollIntoView();
    }
  }, [assessmentApiStatus]);

  function handleAnswerChange(index: number, answer: string) {
    setAnswers(answers.map((a, i) => (i === index ? answer : a)));
  }

  function buildAnswerRequestBody() {
    return {
      email,
      firstName,
      lastName,
      phone,
      answers: multipleChoiceQuestions.map((q, index) => ({
        question: q.question,
        selectedOption: answers[index],
      })).filter(q => q.selectedOption != null && q.selectedOption !== ''),
    };
  }

  function buildJobAnswerRequestBody() {
    return {
      email,
      firstName,
      lastName,
      phone,
      jobName,
    };
  }

  const disableSubmit = jobsApiStatus === QueryStatus.Success || firstName === '' || lastName === '' || !isEmailValid || !isPhoneValid ||
    answers.some((a, index) => a === '' && !multipleChoiceQuestions[index].optional);

  const disableJobSubmit = jobName === '';

  function handleSubmit() {
    const body = buildAnswerRequestBody();
    submitForm(body).then();
  }

  function handleJobSubmit() {
    const body = buildJobAnswerRequestBody();
    submitJobSelection(body).then();
  }

  function displayJobRecommendations(jobRecommendations?: { name: string, description: string }[]) {
    return jobRecommendations && (
      <>
        <FormControl>
          <FormLabel>
            <FormLabelText>Select a job that best suits you:</FormLabelText>
          </FormLabel>
          <RadioGroup name={`radio-group-jobs`} onChange={event => setJobName(event.target.value)}>
            {jobRecommendations.map(({name, description}) => (
              <FormControlLabel
                control={<Radio/>}
                value={name}
                label={<span style={{fontSize: '16px'}}><strong>{name}</strong>: {description}</span>}
                style={{margin: 0}}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <UniButton color="primary" disabled={disableJobSubmit} style={{marginTop: '16px', width: '200px'}}
                   onClick={handleJobSubmit}>
          Submit
        </UniButton>
      </>
    );
  }

  function displayAssessmentLine(line: string) {
    const colonPos = line.indexOf(':');
    if (colonPos === -1) {
      return line;
    }
    return (
      <>
        <strong>{line.slice(0, colonPos + 1)}</strong>
        {line.slice(colonPos + 1)}
      </>
    );
  }

  function displayAssessment(assessment?: string) {
    if (assessment == null) return <div></div>;
    const lines = assessment.split('\n');
    return (
      <div ref={answersRef}>
        <h3 style={{marginTop: '12px'}}>Your assessment:</h3>
        {lines.map(line => (
          <div style={{marginTop: '8px'}}>{displayAssessmentLine(line)}</div>
        ))}
        <div style={{marginTop: '12px'}}>
          <a href="https://meetings.hubspot.com/daniel-posdarascu" style={{textDecoration: 'none'}}>
            <UniButton
              startIcon={
                <UniIconWrapper size="main" color="white100">
                  <PhoneIcon/>
                </UniIconWrapper>
              }
              color="primary"
            >
              {t('buttons.call')}
            </UniButton>
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <StyledFluidContainer className="container-fluid">
        <UniScrollToTop/>
        <StyledHeroSection>
          <StyledTitleContainer>
            <h1 className="page-title">Youni's AI career assessment test</h1>
            <div className="page-subtitle">
              Answer some questions in order to receive AI-powered recommendations tailored specifically for you.
            </div>
          </StyledTitleContainer>
        </StyledHeroSection>
        <StyledFormSection>
          <FormLabel>
            <FormLabelText style={{marginBottom: '10px'}}>Personal details</FormLabelText>
          </FormLabel>
          <FormControl>
            <UniTextField
              label="First name"
              fullWidth
              helperText={firstName !== '' ? undefined : 'Enter your first name'}
              onChange={event => setFirstName(event.target.value)}
            />
          </FormControl>
          <FormControl>
            <UniTextField
              label="Last name"
              fullWidth
              helperText={lastName !== '' ? undefined : 'Enter your last name'}
              onChange={event => setLastName(event.target.value)}
            />
          </FormControl>
          <FormControl>
            <UniTextField
              label="Email"
              fullWidth
              helperText={isEmailValid ? undefined : 'Enter a valid email'}
              onChange={event => setEmail(event.target.value)}
            />
          </FormControl>
          <FormControl>
            <UniTextField
              label="Phone"
              fullWidth
              helperText={isPhoneValid ? undefined : 'Enter a valid phone'}
              onChange={event => setPhone(event.target.value)}
            />
          </FormControl>
          {multipleChoiceQuestions.map((q, index) => (
            <FormControl>
              <FormLabel>
                <FormLabelText>{q.question}</FormLabelText>
              </FormLabel>
              {q.answers == null ? (
                <UniTextField
                  fullWidth
                  helperText={(q.optional === true || answers[index] !== '') ? undefined : 'An answer is required'}
                  onChange={event => handleAnswerChange(index, event.target.value)}
                />
              ) : (
                <RadioGroup name={`radio-group-${index}`}
                            onChange={event => handleAnswerChange(index, event.target.value)}>
                  {q.answers.map(ans => (
                    <FormControlLabel
                      control={<Radio/>}
                      value={ans}
                      label={<span style={{fontSize: '16px'}}>{ans}</span>}
                      style={{margin: 0}}
                    />
                  ))}
                </RadioGroup>
              )}
            </FormControl>
          ))}
          <UniButton color="primary" disabled={disableSubmit} style={{marginTop: '16px', width: '200px'}}
                     onClick={handleSubmit}>
            Submit
          </UniButton>
          {jobsApiStatus === QueryStatus.Loading && <div>Loading job recommendations...</div>}
          {jobsApiStatus === QueryStatus.Error && <div style={{color: 'red'}}>Error loading job recommendations</div>}
          {jobsApiStatus === QueryStatus.Success && displayJobRecommendations(jobsResult?.data?.aiJobs)}
          {assessmentApiStatus === QueryStatus.Loading && <div>Loading assessment...</div>}
          {assessmentApiStatus === QueryStatus.Error && <div style={{color: 'red'}}>Error generating assessment</div>}
          {assessmentApiStatus === QueryStatus.Success && displayAssessment(assessmentResult?.data.answer)}
        </StyledFormSection>
      </StyledFluidContainer>
    </>
  );
};

export default AssessmentQuiz;
