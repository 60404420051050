import { Box } from '@material-ui/core';
import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { UniBounceLoader, UniTypography } from '../../../../components';
import { AgentsQueryParams, AgentsSort } from '../../../../components/agentsList/AgentsList';
import { SelectedAgentsWrapper, StyledAgents } from '../../../../components/agentsList/AgentsList.style';
import { useIntersectionObserver } from '../../../../hooks';
import { MyAgentsListHeader } from './MyAgentsListHeader';

export type OnAgentsSort = (value: AgentsSort) => void;

interface Props {
  fetchMore: Function;
  hasMore: boolean;
  allSelected?: boolean;
  selectAllAgents?: Function;
  params?: AgentsQueryParams;
  onSort?: OnAgentsSort;
  length: number;
  selectedAgents?: number;
  isLoading?: boolean;
  children?: ReactElement[] | ReactElement;
  sortType?: AgentsSort;
  order?: OrderType;
}

export const MyAgentsList = ({
  allSelected,
  fetchMore,
  hasMore,
  selectAllAgents,
  selectedAgents,
  params,
  onSort,
  length,
  isLoading,
  children,
  sortType,
  order,
}: Props) => {
  const targetRef = useRef(null);
  const [showSelectedAgents, setShowSelectedAgents] = useState(selectedAgents! > 0);

  useEffect(() => {
    setShowSelectedAgents(selectedAgents! > 0);
  }, [selectedAgents]);

  useEffect(() => {
    if (showSelectedAgents) {
      const timeout = setTimeout(() => {
        setShowSelectedAgents(false);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showSelectedAgents]);

  useIntersectionObserver({
    target: targetRef,
    onIntersect: fetchMore,
    enabled: hasMore,
  });

  const showEndOfList = length > 4 && !hasMore && !isLoading;
  const showEmptyList = length === 0 && !isLoading;
  return (
    <>
      <MyAgentsListHeader
        allSelected={allSelected}
        onSort={onSort}
        params={params}
        selectAllAgents={selectAllAgents}
        sortType={sortType}
        order={order}
      />

      <StyledAgents>
        {children}
        {showEmptyList && (
          <Box mx={4}>
            <UniTypography color='primary100' weight='medium'>
              No agents found.
            </UniTypography>
          </Box>
        )}
        {isLoading && <UniBounceLoader />}
        {showEndOfList && (
          <Box>
            <UniTypography color='typographyGray70'>You have reached the end of the list.</UniTypography>
          </Box>
        )}
        <div ref={targetRef} />
      </StyledAgents>
      <SelectedAgentsWrapper isVisible={showSelectedAgents}>
        <UniTypography color='white100'>
          {selectedAgents === 1 ? `${selectedAgents} agent selected` : `${selectedAgents} agents selected`}
        </UniTypography>
        {/* <UniButton
          variant="text"
          fullWidth={false}
          color="primary"
          onClick={() => {}}
          startIcon={
            <UniIconWrapper size="large" color="white">
              <MessageIcon />
            </UniIconWrapper>
          }
        >
          <UniTypography color="white">Send message</UniTypography>
        </UniButton> */}
      </SelectedAgentsWrapper>
    </>
  );
};
