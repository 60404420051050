import { AxiosError } from "axios";
import { useMutation, useQueryCache } from "react-query";
import { useClient } from "../../store";
import { CacheKeys } from "../../constants";

type Payload = {
  applicationId: string;
};

const usePostApplication = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.ApplicationApi_validateApplication({
        applicationId: payload.applicationId,
      });
    },
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(CacheKeys.Applications.All);
      },
      onError: (error: AxiosError) => {},
    }
  );
};

export default usePostApplication;
