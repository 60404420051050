import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Paths } from '../../client/UniClient';
import { useClient } from '../../store';

interface Params extends Paths.QuizApiReplyToQuiz.PathParameters, Paths.QuizApiReplyToQuiz.RequestBody {}

const useReplyToQuiz = () => {
  const { client } = useClient();
  const queryCache = useQueryCache();

  return useMutation(
    async ({ quizId, ...payload }: Params) => {
      return client!.QuizApi_replyToQuiz({ quizId }, payload);
    },
    {
      onSuccess: response => {
        // queryCache.invalidateQueries('incompleteQuizzes');
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default useReplyToQuiz;
