import { Box } from '@material-ui/core';
import { UniTooltip } from 'components/_common/uniTooltip/UniTooltip';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useResendAgencyInvite } from '../../api';
import { ReactComponent as CopyIcon } from '../../assets/svg/Icons/Copy.svg';
import { ReactComponent as MesagesIcon } from '../../assets/svg/Icons/Messages.svg';
import { Components } from '../../client/UniClient';
import { UniCheckbox, UniStatus, UniTypography } from '../../components';
import { BrowserRoutes } from '../../constants';
import { countries } from '../../constants/countries';
import UniButton from '../_common/uniButton/UniButton';
import UniIconWrapper from '../_common/uniIconWrapper/UniIconWrapper';
import { StyledAgenciesListItem } from './AgenciesListItem.style';
type AgencyProps = {
  agency: Components.Schemas.AgencyResponse;

  selectAgency: Function;
  selected: boolean;
  setAgencyForResend?: Function;
  setModalOpen?: Function;
};

const AgenciesListItem: React.FC<AgencyProps> = React.memo(({ agency, selectAgency, selected, setAgencyForResend, setModalOpen }) => {
  const { t } = useTranslation('superAdminHome');
  const { name, address, virtualAddress, id, admin } = agency || {};
  const history = useHistory();
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const ctry = countries.filter(item => item.code === address?.country).map(item => item.name)[0];
  const [resendInvite, { data: resendResponse, isLoading, status, error: postAgencyError }] = useResendAgencyInvite();
  return (
    <StyledAgenciesListItem onClick={e => history.push(BrowserRoutes.Admin.AgentsForAgencyAdmin.replace(':id', agency.id as string))}>
      <UniCheckbox
        checked={selected}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          e.stopPropagation();
          selectAgency(agency);
        }}
      />
      <Box display='flex' alignItems='center'>
        {address?.country && (
          <UniTooltip title={countries.filter(item => item.code === address?.country).map(item => item.name)[0]}>
            <UniIconWrapper>
              <ReactCountryFlag
                countryCode={address?.country}
                svg
                style={{
                  width: '2em',
                  height: '1.1em',
                  marginTop: '-2em',
                }}
              ></ReactCountryFlag>
            </UniIconWrapper>
          </UniTooltip>
        )}
        <Box ml={1}>
          <Link to={BrowserRoutes.Admin.AgentsForAgencyAdmin.replace(':id', agency.id as string)}>
            <UniTypography color='primary100'>{name}</UniTypography>
          </Link>
          <UniTypography variant='footnote' color='typographyGray100'>
            {virtualAddress?.email ? `${virtualAddress.email?.substr(0, 17)}...` : '-'}
          </UniTypography>
        </Box>
      </Box>
      <UniTypography variant='subheader'>{agency?.createdAt ? agency?.createdAt.toString().slice(0, 10) : '-'}</UniTypography>
      <UniStatus candidate={agency}></UniStatus>
      <UniTypography variant='subheader'>{agency?.agentCount}</UniTypography>
      <UniTypography variant='subheader'>{agency?.studentCount}</UniTypography>
      <UniTypography variant='subheader'>{agency?.applicationCount}</UniTypography>

      <UniTypography variant='subheader'>{agency?.admittedStudentCount}</UniTypography>
      <UniTypography variant='subheader'>{agency?.enrolledStudentCount}</UniTypography>

      <Box display='flex' alignItems='center' justifyContent='flex-end' mr={4}>
        {!admin?.emailConfirmed && (
          <UniButton
            onClick={() => {
              setAgencyForResend && setAgencyForResend(agency);
            }}
            fullWidth={false}
            color='primaryFade'
          >
            {t('buttons.resend')}
          </UniButton>
        )}
        {!admin?.emailConfirmed && (
          <UniTooltip title={t('copy_invitation_tooltip')} placement='top' open={showCopyTooltip}>
            <UniIconWrapper
              disabled={admin?.emailConfirmed}
              color='primary100'
              withBackground
              onClick={e => {
                e.stopPropagation();
                copy(admin?.inviteLink || '');
                setShowCopyTooltip(true);
                setTimeout(() => {
                  setShowCopyTooltip(false);
                }, 3000);
              }}
            >
              <CopyIcon />
            </UniIconWrapper>
          </UniTooltip>
        )}

        <UniIconWrapper
          color='primary100'
          // {candidate.isFavorite ? "primary100" : "typographyGray30"}
          onClick={e => {
            e.stopPropagation();
            //   favoriteStudent({ studentId: id as string });
          }}
        >
          <MesagesIcon />
        </UniIconWrapper>
      </Box>
    </StyledAgenciesListItem>
  );
});

export default React.memo(AgenciesListItem);
