import { UniCardFooter } from 'components';
import { Colors } from '../../../constants/uiConfig';
import styled from 'styled-components';

export const StyledUniCardFooter = styled(UniCardFooter)`
  border-bottom-left-radius: ${({ theme }) => theme.spacing(1.5)}px;
  border-bottom-right-radius: ${({ theme }) => theme.spacing(1.5)}px;
  position: absolute;
  bottom: 0;
  left: 0;
`;
