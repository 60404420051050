import React from 'react';
import UniProgramFilterBarForm from './UniProgramFilterBarForm';
import UniFilterBar from '../uniFilterBar/UniFilterBar';

interface FilterBarProps {
  onClickFilter?: Function;
  options?: string[];
  noCountry?: boolean;
  showDiscipline?: boolean;
  title?: string;
  overflow?: 'hidden' | 'auto' | 'visible' | 'none';
}

const UniProgramFilterBar = ({
  overflow,
  options,
  noCountry = false,
  showDiscipline = false,
  onClickFilter,
  title = 'Find your dream program',
}: FilterBarProps) => {
  return (
    <UniFilterBar overflow={overflow} title={title}>
      <UniProgramFilterBarForm options={options} noCountry={noCountry} showDiscipline={showDiscipline} onClickFilter={onClickFilter} />
    </UniFilterBar>
  );
};

export default UniProgramFilterBar;
