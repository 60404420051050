import { Box } from '@material-ui/core';
import useReplyToQuiz from 'api/quizzes/useReplyToQuiz';
import { Components } from 'client/UniClient';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import InterestsQuizForm from 'components/_forms/InterestsQuizForm';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';

interface Props {
  quiz?: Components.Schemas.QuizResponse;
}

const fieldHeight = 104;
const wrapperYpadding = 64;

type Inputs = { [key: string]: string };

const InterestsQuiz: React.FC<Props> = ({ quiz }) => {
  const { t: tButtons } = useTranslation('buttons');
  const { closeDrawer } = useDrawers();
  const [formState, setFormState] = useState<Inputs>();
  const defaultValues = quiz?.answer?.answers.reduce((acc, { question, selectedOption }) => ({ ...acc, [question]: selectedOption }), {});
  const { control, errors, reset, setValue, handleSubmit } = useForm<Inputs>({
    defaultValues,
  });

  const [reply, { isLoading: isLoadingReply }] = useReplyToQuiz();
  const [step, setStep] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperHeight, setWrapperHeight] = useState<number>();
  const questions = quiz?.questions;
  const answer = quiz?.answer;
  const quizId = quiz?.id;

  const questionsPerPage = useMemo(() => {
    if (wrapperHeight) return Math.floor(wrapperHeight / fieldHeight);
  }, [wrapperHeight]);

  const hasNextPage = useMemo(() => {
    if (!questions?.length || !questionsPerPage) return;
    return !!questions && questionsPerPage * (step + 1) < questions.length;
  }, [questionsPerPage, step, questions?.length, wrapperHeight]);

  const isFirstPage = useMemo(() => {
    return step === 0;
  }, [step]);

  const currentPageQuestions = useMemo(() => {
    if (!questions?.length || !questionsPerPage) return;
    return questions.slice(step * questionsPerPage, (step + 1) * questionsPerPage);
  }, [questionsPerPage, step, questions?.length, wrapperHeight]);

  useEffect(() => {
    if (wrapperRef.current) {
      setWrapperHeight(wrapperRef.current.clientHeight - wrapperYpadding);
    }
  }, [wrapperRef.current, questions]);

  useEffect(() => {
    if (answer) {
      answer.answers.forEach(({ question, selectedOption }) => {
        setValue(question, selectedOption);
      });
    }
  }, []);

  const nextStep = (data: Inputs) => {
    setStep(prev => prev + 1);
    setFormState(prev => {
      const newState = { ...prev, ...data };
      reset(newState);
      return newState;
    });
  };

  const prevStep = (data: Inputs) => {
    setStep(prev => prev - 1);
    setFormState(prev => {
      const newState = { ...prev, ...data };
      reset(newState);
      return newState;
    });
  };

  const onSubmit = (data: Inputs) => {
    if (!quizId) return;
    const answers = Object.keys(data).reduce((acc, key) => {
      if (data[key]) acc.push({ question: key, selectedOption: data[key] });
      return acc;
    }, [] as Components.Schemas.QuizAnswerRequest[]);
    const payload = { quizId, answers };
    reply(payload, {
      onSuccess() {
        closeDrawer();
      },
    });
  };

  return (
    <>
      <UniModalDrawerContent ref={wrapperRef}>
        <UniTypography variant='body2' color='typographyGray100'>
          {quiz?.description}
        </UniTypography>
        <Box mt={2} />
        <InterestsQuizForm questions={currentPageQuestions} control={control} errors={errors} />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          onClick: hasNextPage ? handleSubmit(nextStep) : handleSubmit(onSubmit),
          label: hasNextPage ? tButtons('next_step') : tButtons('save_changes'),
          loading: isLoadingReply,
        }}
        SecondaryButton={{
          onClick: isFirstPage ? closeDrawer : handleSubmit(prevStep),
          label: isFirstPage ? tButtons('cancel') : tButtons('previous'),
        }}
      />
    </>
  );
};

export default InterestsQuiz;
