import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledApplicationSuccessIllustration } from '../AddEditProgram.style';

interface Props {
  programName: string;
}

const Step6: React.FC<Props> = ({ programName }) => {
  const { t } = useTranslation('createProgram');

  return (
    <>
      <UniTypography variant='body1' weight='medium' color='secondary100'>
        {t('texts.congrats').replace('$program_name', programName)}
      </UniTypography>
      <StyledApplicationSuccessIllustration />
    </>
  );
};

export default Step6;
