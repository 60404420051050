import { Box } from '@material-ui/core';
import useAgencyFilters from 'api/search/useAgencyFilters';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import FiltersDrawerActions from 'components/_drawers/filtersDrawer/FiltersDrawerActions';
import { AgenciesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/AgenciesFilters';
import UniAutocomplete from 'components/_inputs/uniAutocomplete/UniAutocomplete';
import UniSlider from 'components/_inputs/uniSlider/UniSlider';
import { DefaultFormValuesAgencies } from 'constants/defaultFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface Props {
  close: Function;
}

export default function FilterAgenciesForm({ close }: Props): ReactElement {
  const { t } = useTranslation('filterAgenciesPage');

  const { replaceQueriesToUrl, queries } = useQueryStringParser<AgenciesFiltersQueries>();
  const { data: agenciesFilters, status: agenciesFiltersStatus } = useAgencyFilters();
  const [queriesChange, setQueriesChange] = useState(false);
  const isLoading = agenciesFiltersStatus === 'loading';

  const { handleSubmit, reset, control } = useForm<AgenciesFiltersQueries>({ defaultValues: { ...DefaultFormValuesAgencies, ...queries } });
  const { search } = useLocation();

  const onSubmit = (formData: AgenciesFiltersQueries) => {
    close();
    replaceQueriesToUrl(formData);
  };

  useEffect(() => {
    if (search === '' || !!queries.clear) {
      setQueriesChange(prevQueriesChange => !prevQueriesChange);
      replaceQueriesToUrl({});
      reset({
        country: [],
        status: undefined,
        maxAgents: undefined,
        minAgents: undefined,
      });
    } else {
      reset(queries);
    }
  }, []);

  return (
    <>
      {/* TODO: Add react-date-picker */}
      <UniModalDrawerContent>
        <Box mb={1}>
          <Controller
            control={control}
            name='status'
            key={`status-${queriesChange}`}
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                width='350px'
                key={`status-${queriesChange}`}
                value={agenciesFilters?.status?.find(item => item.value === value)}
                handleChange={(event: any, value: any) => {
                  onChange(value?.value || undefined);
                }}
                name='status'
                options={agenciesFilters?.status}
                getOptionLabel={option => option.label}
                label={t('labels.status')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            key={`country-${queriesChange}`}
            name='country'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                key={`country-${queriesChange}`}
                value={agenciesFilters?.country?.filter(item => value?.includes(item.value))}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.value) || []);
                }}
                name='nationality'
                options={agenciesFilters?.country}
                getOptionLabel={option => option.label}
                label={t('labels.country')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            key={`agents-${queriesChange}`}
            name='agentCount'
            render={({ onChange, value }) => (
              <UniSlider
                fullWidth
                defaultValue={[queries?.minAgents || agenciesFilters?.minAgents!, queries?.maxAgents || agenciesFilters?.maxAgents!]}
                key={`agents-${queriesChange}`}
                marks={[
                  { value: agenciesFilters?.minAgents!, label: `${agenciesFilters?.minAgents}` },
                  { value: agenciesFilters?.maxAgents!, label: `${agenciesFilters?.maxAgents}` },
                ]}
                handleOnChange={(value: any) => {
                  onChange(value);
                }}
                label={t('labels.agent_count')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='studentCount'
            key={`students-${queriesChange}`}
            render={({ onChange, value }) => (
              <UniSlider
                fullWidth
                defaultValue={[
                  queries?.minStudents || agenciesFilters?.minStudents!,
                  queries?.maxStudents || agenciesFilters?.maxStudents!,
                ]}
                key={`students-${queriesChange}`}
                marks={[
                  { value: agenciesFilters?.minStudents!, label: `${agenciesFilters?.minStudents}` },
                  { value: agenciesFilters?.maxStudents!, label: `${agenciesFilters?.maxStudents}` },
                ]}
                handleOnChange={value => {
                  onChange(value);
                }}
                label={t('labels.student_count')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box m={10} />
      </UniModalDrawerContent>
      <FiltersDrawerActions handleSubmit={handleSubmit(onSubmit)} />
    </>
  );
}
