import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UniTextWithIcon, UniTypography } from '..';
import { ReactComponent as HatIcon } from '../../assets/svg/Icons/GraduationHat.svg';
import { ReactComponent as HourglassIcon } from '../../assets/svg/Icons/Program/Hourglass.svg';
import { Profile } from '../../types/profile';
import { StyledProgramDataCard, StyledRow } from '../programExtended/ProgramExtended.style';

export const AgentDetailsFinancial = (props: { isOwner: boolean; profile?: Partial<Profile> }) => {
  const { t } = useTranslation('agencyProfile');
  return (
    <StyledProgramDataCard>
      <Box mb={3}>
        <UniTypography color='primary100' weight='medium'>
          {t('labels.financial.title')}
        </UniTypography>
      </Box>
      <StyledRow>
        <UniTextWithIcon iconColor='secondary100' Icon={<HatIcon />} label={t('labels.financial.my_candidates')} value='Unmeasured' />
        <UniTextWithIcon
          iconColor='secondary100'
          Icon={<HourglassIcon />}
          label={t('labels.financial.enrolled_candidates')}
          value='Unmeasured'
        />
        <UniTextWithIcon
          iconColor='secondary100'
          Icon={<HourglassIcon />}
          label={t('labels.financial.applications_sent')}
          value='Unmeasured'
        />
      </StyledRow>
      {props.isOwner && (
        <StyledRow>
          <UniTextWithIcon iconColor='primary100' Icon={<HourglassIcon />} label={t('labels.financial.total_fees')} value='Unmeasured' />
          <UniTextWithIcon
            iconColor='primary100'
            Icon={<HourglassIcon />}
            label={t('labels.financial.predicted_income')}
            value='Unmeasured'
          />
        </StyledRow>
      )}
    </StyledProgramDataCard>
  );
};
