export const arrayEquals = (arr1?: any[], arr2?: any[]) => {
  return (
    !!arr1 &&
    !!arr2 &&
    Array.isArray(arr1) &&
    Array.isArray(arr2) &&
    arr1.length === arr2.length &&
    arr1.every((item, i) => item === arr2[i])
  );
};
