import { Box } from '@material-ui/core';
import { useExtendedProfile } from 'api';
import { UniButton, UniDivider, UniIconWrapper, UniPageTitle, UniTypography } from 'components';
import { GuidingState } from 'components/emptyState/GuidingState';
import { useBreakpoints } from 'hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers, useSnackbar } from 'store';
import { ReactComponent as AddIcon } from '../../assets/svg/Icons/Add.svg';
import { Components } from '../../client/UniClient';
import { StyledContainer } from './StudentEducation.style';
import StudentEducationItem from './StudentEducationItem';

interface Props {
  candidate?: Partial<Components.Schemas.UserDto>;
  editable?: boolean;
}

export default function StudentEducation({ candidate, editable }: Props): ReactElement {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation('studentEducation');
  const { data: extendedProfile } = useExtendedProfile(candidate);
  const educationHistory = extendedProfile?.educationHistory;
  const { openDrawer } = useDrawers();
  const { props } = useSnackbar();
  const onAddEducation = () => openDrawer('add-education', { size: 'small' });

  return (
    <StyledContainer isMobile={isMobile}>
      <UniPageTitle size={isMobile ? 'S' : 'M'} title={t('title')}></UniPageTitle>
      {educationHistory?.length === 0 && (
        <Box mt={isMobile ? 2 : 3}>
          <GuidingState type='education' />
        </Box>
      )}
      {editable && (
        <Box width='100%' justifyContent={isMobile ? 'center' : 'flex-start'} display='flex' marginTop='24px'>
          <UniButton
            size={isMobile ? 'medium' : 'large'}
            fullWidth={false}
            onClick={onAddEducation}
            startIcon={
              <UniIconWrapper color='white100'>
                <AddIcon />
              </UniIconWrapper>
            }
          >
            {t('buttons.add_education')}
          </UniButton>
        </Box>
      )}
      {educationHistory?.length != 0 && (
        <>
          <Box mt={isMobile ? 3.375 : 4.375} mb={isMobile ? 3.5 : 4.5} width='100%'>
            <UniDivider />
          </Box>
          <Box mb={3}>
            <UniTypography variant={isMobile ? 'footnote' : 'subheader'} color='typographyGray100' weight='regular'>
              {t('description')}
            </UniTypography>
          </Box>
        </>
      )}
      {educationHistory?.map((item, index) => (
        <StudentEducationItem hide={item.id === props.hide} educationHistoryItem={item} isMobile={isMobile} editable={editable} />
      ))}
    </StyledContainer>
  );
}
