import { Box } from '@material-ui/core';
import useServiceOptionsRequests from 'api/service-options/useServiceOptionsRequests';
import { Components } from 'client/UniClient';
import { UniButton, UniTypography } from 'components';
import ServiceCard from 'components/serviceCard/ServiceCard';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { useBreakpoints } from 'hooks';
import { useDrawers } from 'store';
import { StyledServicesList } from '../Services.style';

interface Props {}

const Suggestions: React.FC<Props> = ({ children, ...rest }) => {
  const { data: requests, isLoading } = useServiceOptionsRequests();
  const { isMobile } = useBreakpoints();
  const { openDrawer } = useDrawers();

  const openAddServiceDrawer = () => openDrawer('add-new-service', { size: 'small' });

  const onEditService = (serviceRequest: Components.Schemas.ServiceOptionRequestDto) => {
    openDrawer('edit-service-request', {
      size: 'small',
      serviceRequest,
    });
  };

  const onRejectService = (service: Components.Schemas.ServiceOptionRequestDto) =>
    openDrawer('reject-service', {
      size: 'small',
      serviceRequest: service,
    });

  return (
    <>
      <Box mb={4}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          A list of requests for new services from your partners.
        </UniTypography>
      </Box>
      {!isMobile && (
        <Box mb={5}>
          <UniButton color='primaryFade' onClick={openAddServiceDrawer}>
            Add Service
          </UniButton>
        </Box>
      )}
      <StyledServicesList>
        {isLoading && <UniListLoader />}
        {requests?.map(item => (
          <ServiceCard
            id={item?.id}
            type='REQUEST'
            name={item.name}
            partner={item?.agent?.agentProfile?.agency?.name || undefined}
            description={item.description}
            onAdd={() => onEditService(item)}
            onReject={() => onRejectService(item)}
          />
        ))}
      </StyledServicesList>
    </>
  );
};

export default Suggestions;
