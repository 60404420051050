import React from 'react';
import usePrepPacks from 'api/preppacks/usePrepPacks';
import UniAdminPrepCenterPackagesList from 'components/adminPrepCenterPackagesList/UniAdminPrepCenterPackagesList';

interface Props {}

export const Packages: React.FC<Props> = () => {
  const { data: prepPacks } = usePrepPacks();

  return (
    <>
      {prepPacks && (
        <UniAdminPrepCenterPackagesList prepPacks={prepPacks} />
      )}
    </>
  );
};

export default Packages;
