import { UniTypography } from 'components';
import { ReactComponent as SuccessIllustration } from '../../../../../assets/svg/Illustrations/invitationSentIllustration.svg';
import { useTranslation } from 'react-i18next';

const Step3: React.FC = () => {
  const { t } = useTranslation('partnerServicesDrawers');
  return (
    <>
      <SuccessIllustration />
      <UniTypography variant='header3' color='secondary100' weight='medium'>
        {t('add_service.service_added')}
      </UniTypography>
      <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
        {t('add_service.check_email')}
      </UniTypography>
    </>
  );
};

export default Step3;
