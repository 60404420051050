import styled from 'styled-components';
import { Colors } from '../../../constants';

export const AuthLayoutWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${Colors.White._100};
`;

export const StyledAuthHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFormWrapper = styled.div<{ isMobile: boolean }>`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(6, 8)};
  display: flex;
  flex-direction: column;
  max-width: 650px;
  overflow: auto;
  -webkit-user-drag: auto;
  margin: 0 auto;
  ${({ theme }) => `${theme.breakpoints.down('lg')}{
    // margin-bottom:${theme.spacing(8)}px;
    padding: ${theme.spacing(2, 2)};
  }`};
`;

export const StyledHeader = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  ${({ isMobile, theme }) =>
    isMobile &&
    `margin-bottom: ${theme.spacing(3)}px;
    justify-content: space-between;
  `}
`;

export const StyledIllustrationWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50%;
`;

export const StyledChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
export const StyledChildrenWrapperTop = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spacing(4)}px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const AuthForm = styled(({ children, ...props }) => (
  <form {...props}>
    {children}
    <input type='submit' style={{ position: 'absolute', left: '-9999px' }} />
  </form>
))`
  width: 100%;
`;
