import { Box, Divider } from '@material-ui/core';
import usePostPassportForStudent from 'api/files/usePostPassportForStudent';
import { Components } from 'client/UniClient';
import UniPhoneInput from 'components/_inputs/uniPhoneInput/uniPhoneInput';
import React, { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getMetaRole } from 'utils';
import { useDeletePassport, usePatchCandidate, usePostPassport } from '../../../api';
import useUpdateStudent from '../../../api/students/useUpdateStudent';
import {
  UniAutocomplete,
  UniBounceLoader,
  UniButton,
  UniDragNDrop,
  UniFileViewer,
  UniSectionTitle,
  UniTextField,
  UniTypography,
} from '../../../components';
import { languageOptions } from '../../../constants';
import { countries } from '../../../constants/countries';
import { useErrorHandler } from '../../../hooks';
import { useAuth } from '../../../store';
import { Nationality, TLanguage } from '../../../types';
import { StyledFormRow } from '../CandidateProfileInformation.style';

interface Props {
  candidate: Partial<Components.Schemas.UserDto>;
}

interface Inputs {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  nationality: Nationality;
  firstLanguage: TLanguage;
  gender: 'Male' | 'Female' | 'Other';
  maritalStatus: 'UNMARRIED' | 'MARRIED' | 'DIVORCED' | 'WIDOWED';
  addressLine1: string;
  city: string;
  country: Nationality;
  state: string;
  postalCode: string;
  passportId: string;
  phone: string;
  phonePrefix: string;
  date: string;
  email: string;
}

const genderMap = [
  { text: 'Male', value: 'Male' },
  { text: 'Female', value: 'Female' },
  { text: 'Other', value: 'Other' },
];

const maritalStatusMap = [
  { text: 'Married', value: 'MARRIED' },
  { text: 'Unmarried', value: 'UNMARRIED' },
  { text: 'Divorced', value: 'DIVORCED' },
  { text: 'Widowed', value: 'WIDOWED' },
];

export default function StudentPersonalInfo({ candidate }: Props): ReactElement {
  const { t } = useTranslation('studentProfile');
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile.role!);
  const [deletePassport] = useDeletePassport();
  const [alert, setAlert] = useState('');
  const { register, handleSubmit, setValue, getValues, errors, control } = useForm<Components.Schemas.UpdateStudentRequest>({
    defaultValues: candidate,
  });
  const studentId = candidate?.id || '';

  const [uploadPassport, { status: uploadPassportStatus, error: uploadPassportError }] = usePostPassport();
  const [uploadPassportForStudent, { status: uploadPassportForStudentStatus, error: uploadPassportForStudentError }] =
    usePostPassportForStudent();
  const uploadPassError = useErrorHandler(uploadPassportError! || uploadPassportForStudentError!);

  const [postPersoalInfo, { status: personalInfoStatus, error: personalInfoError }] = useUpdateStudent();
  const [patchCandidate, { status: patchCandidateStatus, error: patchCandidateError }] = usePatchCandidate();
  const postPersonalError = useErrorHandler(patchCandidateError || personalInfoError || undefined);
  const handleSave = (formData: Components.Schemas.UpdateStudentRequest) => {
    if (isStudent) {
      postPersoalInfo(formData);
    } else {
      patchCandidate({ studentId, ...formData });
    }
  };

  const updateProfileLoading = personalInfoStatus === 'loading' || patchCandidateStatus === 'loading';
  const updateProfileSuccess = personalInfoStatus === 'success' || patchCandidateStatus === 'success';
  return (
    <>
      <Box mb={3} display='flex' justifyContent='space-between'>
        <Box>
          <UniSectionTitle title={t('labels.personal_info.basic_info.title')} subtitle={t('labels.personal_info.basic_info.subtitle')} />
        </Box>
        <Box>
          <UniButton fullWidth={false} loading={updateProfileLoading} color='primary' onClick={handleSubmit(handleSave)}>
            {updateProfileSuccess ? t('buttons.saved') : t('buttons.save')}
          </UniButton>
        </Box>
      </Box>
      {postPersonalError.message && (
        <Box mb={2}>
          <UniTypography color='stateAlert100'>{postPersonalError.message}</UniTypography>
        </Box>
      )}
      <StyledFormRow>
        <Box flex={1} minWidth='215px'>
          <UniTextField
            fullWidth
            error={!!errors?.firstName?.message}
            helperText={errors?.firstName?.message}
            name='firstName'
            label={t('labels.personal_info.basic_info.first_name')}
            inputRef={register({ required: { value: true, message: t('labels.personal_info.basic_info.first_name_error') } })}
          />
        </Box>
        <Box flex={1} minWidth='215px'>
          <UniTextField
            fullWidth
            name='middleName'
            label={t('labels.personal_info.basic_info.middle_name')}
            inputRef={register({})}
            optional
          />
        </Box>
        <Box flex={1} minWidth='215px'>
          <UniTextField
            fullWidth
            name='lastName'
            error={!!errors?.lastName?.message}
            helperText={errors?.lastName?.message}
            label={t('labels.personal_info.basic_info.last_name')}
            inputRef={register({ required: { value: true, message: t('labels.personal_info.basic_info.last_name_error') } })}
          />
        </Box>
        <Box flex={1} minWidth='215px'>
          <UniTextField
            fullWidth
            name='studentProfile.dateOfBirth'
            label={t('labels.personal_info.basic_info.date_of_birth')}
            type='date'
            inputRef={register({})}
          />
        </Box>
      </StyledFormRow>
      <StyledFormRow>
        <Box flex={1} minWidth='215px'>
          <Controller
            control={control}
            name='studentProfile.gender'
            render={({ onChange, value }) => (
              <UniAutocomplete
                disableClearable
                getOptionLabel={(option: any) => option?.text}
                fullWidth
                placeholder='Select'
                label={t('labels.personal_info.basic_info.gender')}
                value={genderMap.find(item => item.value === value)}
                options={genderMap}
                handleChange={(e: any, value: any) => {
                  onChange(value?.value);
                }}
              />
            )}
          />
        </Box>
        <Box flex={1} minWidth='215px'>
          <Controller
            control={control}
            name='studentProfile.maritalStatus'
            render={({ value, onChange }) => (
              <UniAutocomplete
                disableClearable
                getOptionLabel={({ text }) => text}
                fullWidth
                placeholder='Select'
                label={t('labels.personal_info.basic_info.marital_status')}
                value={maritalStatusMap.find(item => item.value === value)}
                options={maritalStatusMap}
                handleChange={(e: any, value: any) => {
                  onChange(value?.value);
                }}
              />
            )}
          />
        </Box>
        <Box flex={1} minWidth='215px'>
          <Controller
            control={control}
            name='studentProfile.nationality'
            render={({ value, onChange }) => (
              <UniAutocomplete
                fullWidth
                disableClearable
                handleChange={(event: any, value: any) => {
                  onChange(value?.code);
                }}
                value={countries.find(itm => itm.code === value)}
                label={t('labels.personal_info.basic_info.nationality')}
                name='studentProfile.nationality'
                options={countries}
                getOptionLabel={option => option.name}
              />
            )}
          />
        </Box>
        <Box flex={1} minWidth='215px'>
          <Controller
            control={control}
            name='studentProfile.firstLanguage'
            render={({ value, onChange }) => (
              <UniAutocomplete
                fullWidth
                disableClearable
                handleChange={(event: any, value: any) => {
                  onChange(value?.code.toUpperCase());
                }}
                value={languageOptions.find(itm => itm.code === value)}
                label={t('labels.personal_info.basic_info.first_language')}
                name='studentProfile.firstLanguage'
                options={languageOptions}
                getOptionLabel={option => option.name}
              />
            )}
          />
        </Box>
      </StyledFormRow>

      {candidate.studentProfile?.passportDocument ? (
        <>
          <UniTypography>{t('labels.personal_info.basic_info.passport')}</UniTypography>
          <UniFileViewer
            deleteFile={id => deletePassport(id)}
            file={candidate.studentProfile.passportDocument}
            label={t('labels.personal_info.basic_info.passport')}
          />
        </>
      ) : (
        <UniDragNDrop
          alert={alert}
          style={{ width: '310px' }}
          label={t('labels.personal_info.basic_info.upload_passport')}
          accept='application/pdf, image/jpg, image/png'
          handleChange={(files: any) => {
            if (files.length > 0) {
              const formData = new FormData();
              formData.append('file', files[0]);
              formData.append('documentType', 'PASSPORT');
              if (isStudent) {
                uploadPassport(formData);
              } else {
                formData.append('studentId', studentId!);
                uploadPassportForStudent(formData);
              }
            }
          }}
        >
          {uploadPassportStatus === 'loading' || uploadPassportForStudentStatus === 'loading' ? (
            <Box height='48px' display='flex' justifyContent='center' alignItems='center'>
              <UniBounceLoader />
            </Box>
          ) : (
            <>
              <UniTypography color='typographyGray100'>Drag & Drop or</UniTypography>

              <UniTypography color='primary100'>Upload JPG, PDF or PNG file</UniTypography>
            </>
          )}
        </UniDragNDrop>
      )}
      {uploadPassError.message && (
        <Box>
          <UniTypography color='stateAlert100'>{uploadPassError.message}</UniTypography>
        </Box>
      )}
      <Box mt={5} mb={4}>
        <Divider />
      </Box>
      <Box mb={2}>
        <UniSectionTitle title={t('labels.personal_info.address.title')} />
      </Box>
      <StyledFormRow>
        <UniTextField
          fullWidth
          name='studentProfile.address.addressLine1'
          label={t('labels.personal_info.address.address')}
          inputRef={register({})}
        />
        <UniTextField fullWidth name='studentProfile.address.city' label={t('labels.personal_info.address.city')} inputRef={register({})} />
        <UniTextField
          fullWidth
          name='studentProfile.address.state'
          label={t('labels.personal_info.address.state')}
          inputRef={register({})}
        />
        <Controller
          control={control}
          name='studentProfile.address.country'
          render={({ value, onChange }) => (
            <UniAutocomplete
              fullWidth
              disableClearable
              handleChange={(event: any, value: any) => {
                onChange(value?.code);
              }}
              value={countries.find(itm => itm.code === value)}
              label={t('labels.personal_info.address.country')}
              name='country'
              options={countries}
              getOptionLabel={option => option.name}
            />
          )}
        />
        <UniTextField
          fullWidth
          name='studentProfile.address.postalCode'
          label={t('labels.personal_info.address.postal/zip_code')}
          inputRef={register({})}
        />
      </StyledFormRow>

      <Box mt={5} mb={4}>
        <Divider />
      </Box>
      <Box mb={2}>
        <UniSectionTitle title={t('labels.personal_info.contact.title')} />
      </Box>
      <StyledFormRow>
        <UniTextField disabled fullWidth name='email' label={t('labels.personal_info.contact.email')} inputRef={register({})} />
        <UniPhoneInput
          fullWidth
          name='phone'
          setValue={setValue}
          control={control}
          phonePrefix={candidate.phonePrefix}
          label={t('labels.personal_info.contact.phone_number')}
          inputRef={register({})}
        />
        <Box />
        <Box />
      </StyledFormRow>
    </>
  );
}
