import useGetMyServices from 'api/agency/useGetMyServices';
import useServiceOptions from 'api/service-options/useServiceOptions';
import { AnimatedTabPanel, UniHeader, UniHerosection, UniIconWrapper, UniPage } from 'components';
import AllServices from 'components/serviceTabs/AllServices';
import { AnimatePresence } from 'framer-motion';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ReactComponent as ServicesIcon } from '../../../assets/svg/Icons/Services2.svg';
import OurServices from '../../../components/serviceTabs/OurServices';
import { BrowserRoutes } from '../../../constants/browserRoutes';

interface Props {}

export default function PartnerServices({}: Props): ReactElement {
  const { t } = useTranslation('partnerServices');
  const { url } = useRouteMatch();
  const { data: services } = useServiceOptions();
  const { data: serviceOfferings } = useGetMyServices();

  const servicesMemo = useMemo(() => services, [services]);

  const ourServicesPath = BrowserRoutes.Agent.Services.OurServices;
  const allServicesPath = BrowserRoutes.Agent.Services.AllServices;
  const currentRoute = url === ourServicesPath ? 0 : 1;

  const metricsText = (length?: number) => {
    return length === 1 ? t('metrics.service') : t('metrics.services');
  };

  return (
    <>
      <>
        <UniHeader>
          <UniHerosection
            key={currentRoute}
            title={t('title')}
            headerVariant='title'
            metrics={[
              {
                label: t('metrics.your_services'),
                value: `${serviceOfferings?.length + ' ' + metricsText(serviceOfferings?.length)}`,
                Icon: (
                  <UniIconWrapper color='secondary100'>
                    <ServicesIcon />
                  </UniIconWrapper>
                ),
              },
              {
                label: t('metrics.all_services'),
                value: `${services?.length + ' ' + metricsText(services?.length)}`,
                Icon: (
                  <UniIconWrapper color='secondary100'>
                    <ServicesIcon />
                  </UniIconWrapper>
                ),
              },
            ]}
            tabs={[
              { label: t('tabs.our_services'), path: ourServicesPath },
              { label: t('tabs.all_services'), path: allServicesPath },
            ]}
          />
        </UniHeader>
        <UniPage>
          <AnimatePresence>
            <Switch>
              <Route path={BrowserRoutes.Agent.Services.OurServices}>
                <AnimatedTabPanel
                  Content={<OurServices serviceOfferings={serviceOfferings!} />}
                  index={0}
                  currentItem={currentRoute}
                  absoluteDirection={-1}
                />
              </Route>
              <Route path={BrowserRoutes.Agent.Services.AllServices}>
                <AnimatedTabPanel
                  Content={<AllServices services={servicesMemo!} />}
                  index={1}
                  currentItem={currentRoute}
                  absoluteDirection={1}
                />
              </Route>
            </Switch>
          </AnimatePresence>
        </UniPage>
      </>
    </>
  );
}
