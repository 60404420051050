import React from 'react';
import { Components } from 'client/UniClient';
import UniPrepCenterOrdersHeader from './UniPrepCenterOrdersHeader';
import UniPrepCenterOrdersItem from './UniPrepCenterOrdersItem';
import { StyledPrepCenterOrders } from './UniPrepCenterOrders.style';

type OrderDto = Components.Schemas.OrderDto;

interface Props {
  orders: OrderDto[];
  enableActions: boolean;
}

export const UniPrepCenterOrders: React.FC<Props> = ({ orders, enableActions }) => {
  const displayedOrders = [...orders];
  displayedOrders.sort((l, r) => {
    if (l.createdAt > r.createdAt) return -1;
    if (l.createdAt < r.createdAt) return 1;
    return 0;
  });

  return (
    <>
      <UniPrepCenterOrdersHeader />
      <StyledPrepCenterOrders>
        {displayedOrders.map(order => (
          <UniPrepCenterOrdersItem order={order} enableActions={enableActions} />
        ))}
      </StyledPrepCenterOrders>
    </>
  );
};

export default UniPrepCenterOrders;
