import styled from 'styled-components';
import { theme } from 'theme';

export const StyledFieldWrappers = styled.div`
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-column-gap: ${theme.spacing(2)}px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`;

export const StyledCategoriesWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(1)}px;
  padding: ${theme.spacing(0, 0, 1, 0)};

  > * {
    display: inline-block;

    p {
      white-space: nowrap;
    }
  }
`;
