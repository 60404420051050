import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import ComponentColors, { TComponentColors } from './../../../constants/componentColors';

export const CircularButton = styled(IconButton)<{ ccolor: TComponentColors; hoverColor: TComponentColors; hasSize?: boolean }>`
  height: ${({ hasSize }) => (hasSize ? '16' : '48')}px;
  width: ${({ hasSize }) => (hasSize ? '16' : '48')}px;
  background-color: ${({ ccolor }) => ComponentColors[ccolor]};
  :hover {
    background-color: ${({ hoverColor }) => ComponentColors[hoverColor]};
  }
`;
