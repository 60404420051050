import styled from 'styled-components';

export const StyledContinentSelect = styled.div<{}>`
  @media screen and (max-width: 479px) {
    grid-template-columns: 1fr;
  }

  grid-column-gap: 25px;
  grid-row-gap: 25px;
  margin-top: 40px;
  margin-bottom: 40px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
`;
