import { Components } from 'client/UniClient';

type PrepPackDto = Components.Schemas.PrepPackDto;

export default function getPrepPackPriceRange(prepPacks: PrepPackDto[]): string {
  const prices = prepPacks.map(prepPack => prepPack.price.amount);
  const minPrice = prices.length > 0 ? Math.min(...prices) : null;
  const maxPrice = prices.length > 0 ? Math.max(...prices) : null;
  return (minPrice == null || maxPrice == null)
    ? 'No packages available at the moment'
    : minPrice === maxPrice
      ? `${minPrice} ${prepPacks[0].price.currencyIso}`
      : `${minPrice} - ${maxPrice} ${prepPacks[0].price.currencyIso}`;
}
