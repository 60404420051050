import { Grid } from '@material-ui/core';
import useGetFaculties from 'api/university/useGetFaculties';
import { FacultiesList, FacultiesListItem, StickyListFilterBar, UniPage } from 'components';
import { BrowserRoutes } from 'constants/browserRoutes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
const UniversityFaculties = () => {
  const history = useHistory();
  const { t } = useTranslation('universityFacultiesPage');
  const { data: faculties, status: facultiesStatus, error: uniError } = useGetFaculties();
  const facultiesTotal = faculties ? faculties.length : 0;
  const totalResultsLabel = facultiesTotal === 1 ? facultiesTotal + ' ' + t('result.ifOne') : facultiesTotal + ' ' + t('result.ifMore');
  return (
    <>
      <UniPage>
        <Grid xs={12}>
          <StickyListFilterBar
            listTitle={t('list.title')}
            searchHint={t('list.search_hint')}
            totalResultsLabel={totalResultsLabel}
            // ActionButton={
            //   <UniButton
            //     fullWidth={false}
            //     color='primary'
            //     startIcon={
            //       <UniIconWrapper color='white100'>
            //         <AddIcon />
            //       </UniIconWrapper>
            //     }
            //   >
            //     <UniTypography weight='medium' variant='body2' color='white100'>
            //       {t('buttons.add_faculty')}
            //     </UniTypography>
            //   </UniButton>
            // }
          ></StickyListFilterBar>
          <FacultiesList length={faculties?.length || 0} isLoading={facultiesStatus === 'loading'}>
            {faculties?.map((faculty: any, index: any) => (
              <React.Fragment key={index}>
                <FacultiesListItem
                  onClick={() => history.push(BrowserRoutes.University.Faculty.Programs.replace(':facultyId', faculty.id))}
                  faculty={faculty}
                ></FacultiesListItem>
              </React.Fragment>
            ))}
          </FacultiesList>
        </Grid>
      </UniPage>
    </>
  );
};
export default UniversityFaculties;
