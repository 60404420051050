import { Components } from 'client/UniClient';
import { UniModalDrawerButtons } from 'components';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';

interface Props {
  loading: boolean;
  step: number;
  feeInvoice?: Components.Schemas.ApplicationFeeInvoiceDto;
  prevStep: () => void;
  nextStep: () => void;
}

const ActionsFooter: React.FC<Props> = ({ step, loading, nextStep, prevStep, feeInvoice }) => {
  const { closeDrawer } = useDrawers();
  const { t: buttonsIntl } = useTranslation('buttons');

  const onClickPay = () => {
    window.open(feeInvoice?.paymentLink, '_blank');
    localStorage['pendingPayment'] = 'true';
    closeDrawer();
  };

  const buttonTexts = [
    {
      secondaryCallback: closeDrawer,
      primaryCallback: nextStep,
      secondary: buttonsIntl('cancel'),
      primary: buttonsIntl('i_understand'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: nextStep,
      secondary: buttonsIntl('previous'),
      primary: buttonsIntl('next'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: nextStep,
      secondary: buttonsIntl('previous'),
      primary: buttonsIntl('next'),
    },
    {
      secondaryCallback: closeDrawer,
      secondary: buttonsIntl('not_now'),
      primaryCallback: onClickPay,
      primary: buttonsIntl('pay_now'),
    },
    {
      primaryCallback: nextStep,
      primary: buttonsIntl('great'),
    },
    {
      primaryCallback: closeDrawer,
      primary: buttonsIntl('got_it'),
    },
  ];

  return (
    <UniModalDrawerButtons
      SecondaryButton={
        buttonTexts[step]?.secondary
          ? {
              onClick: buttonTexts[step]?.secondaryCallback,
              label: buttonTexts[step].secondary,
            }
          : undefined
      }
      PrimaryButton={{
        onClick: buttonTexts[step]?.primaryCallback,
        label: buttonTexts[step]?.primary,
        loading,
      }}
    />
  );
};

export default ActionsFooter;
