import { AxiosError } from 'axios';
import { QueryConfig, useInfiniteQuery } from 'react-query';
import { ProgramsFiltersQueries } from '../../components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { ApiKeys, CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  filters?: ProgramsFiltersQueries;
  sort?: ProgramsFiltersQueries['sort'];
  pageSize?: number;
};

export default function useSearchGroupedPrograms(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  return useInfiniteQuery(
    [CacheKeys.Search.GroupedPrograms, params],
    (_key: string, { filters, pageSize = ApiKeys.PageSize.GroupedPrograms, sort }: Params, page: number = 1) =>
      client!
        .SearchApi_searchUniversityPrograms({
          ...filters,
          page,
          pageSize,
          sort,
        })
        .then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (
          lastPage.results.length < ApiKeys.PageSize.GroupedPrograms ||
          lastPage.page * ApiKeys.PageSize.GroupedPrograms === lastPage.total
        ) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
