import { Fade } from '@material-ui/core';
import { useUpdatePassword } from 'api/auth/useUpdatePassword';
import { ReactComponent as ErrorSvg } from 'assets/svg/Icons/Error.svg';
import { UniModalDrawerButtons, UniTextField, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { Error, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import { useErrorHandler } from 'hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ChangePasswordInputs {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface Props {
  changeDrawer: Function;
}

const ChangePasswordForm: React.FC<Props> = ({ changeDrawer }) => {
  const { t } = useTranslation('forms');
  const [updatePassword, { isLoading, error }] = useUpdatePassword();
  const changePasswordErorr = useErrorHandler(error || undefined);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ChangePasswordInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { currentPassword: '', newPassword: '', confirmPassword: '' },
  });

  const onSubmit = (formData: ChangePasswordInputs) => {
    updatePassword(
      { oldPassword: formData.currentPassword, newPassword: formData.newPassword },
      {
        onSuccess() {
          changeDrawer('notification-email-sent');
        },
        onError() {},
      },
    );
  };
  const onCancel = () => {
    changeDrawer('contact-form');
  };

  return (
    <>
      <UniModalDrawerContent>
        <UniTextField
          label={t('form.change_password.current_password')}
          inputRef={register({
            required: `${t('form.errors.change_password.1')}`,
            validate: (value: string) => value.trim() !== '' || `${t('form.errors.change_password.1')}`,
          })}
          name='currentPassword'
          type='password'
          error={!!errors.currentPassword?.message}
          helperText={errors.currentPassword?.message}
          fullWidth
        />
        <UniTextField
          label={t('form.change_password.new_password')}
          inputRef={register({
            required: `${t('form.errors.change_password.2')}`,
            validate: {
              emptyString: (value: string) => value.trim() !== '' || `${t('form.errors.change_password.2')}`,
              currentPassNotEqualWithNew: (value: string) =>
                value !== getValues('currentPassword') || `${t('form.errors.change_password.4')}`,
            },
            minLength: { value: 8, message: t('form.errors.password_2') },
          })}
          name='newPassword'
          type='password'
          error={!!errors.newPassword?.message}
          helperText={errors.newPassword?.message}
          fullWidth
        />
        <UniTextField
          label={t('form.change_password.confirm_password')}
          inputRef={register({
            required: `${t('form.errors.change_password.3')}`,
            validate: {
              emptyString: (value: string) => value.trim() !== '' || `${t('form.errors.change_password.3')}`,
              newPassEqualWithConfirm: (value: string) => value === getValues('newPassword') || `${t('form.errors.change_password.5')}`,
            },
            minLength: { value: 8, message: t('form.errors.password_2') },
          })}
          name='confirmPassword'
          type='password'
          error={!!errors.confirmPassword?.message}
          helperText={errors.confirmPassword?.message}
          fullWidth
        />
        <Fade in={!!changePasswordErorr.message} unmountOnExit>
          <Error>
            <StyledAlertIcon>
              <ErrorSvg />
            </StyledAlertIcon>
            <UniTypography variant='footnote' component='span' color='stateAlert100'>
              {changePasswordErorr.message}
            </UniTypography>
          </Error>
        </Fade>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: t('buttons.save_changes'),
          loading: isLoading,
        }}
        SecondaryButton={{ onClick: onCancel, label: t('buttons.cancel') }}
      />
    </>
  );
};
export default ChangePasswordForm;
