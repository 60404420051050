import { AxiosError } from 'axios';
import { queryCache, useMutation } from 'react-query';
import { Components } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Payload {
  params: {
    agencyId: string;
    studentId: string;
  };
  body: Components.Schemas.CreateStudentCertificationRequest;
}

const useAddCertificationsHistoryItem = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AgencyApi_addCertificationItem(payload.params, payload.body);
    },

    {
      onSuccess: async response => {
        queryCache.invalidateQueries(CacheKeys.Profile.Extended);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         }); 
         */
      },
    },
  );
};
export default useAddCertificationsHistoryItem;
