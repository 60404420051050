import { Box } from '@material-ui/core';
import useDeleteServiceOffering from 'api/agency/useDeleteServiceOffering';
import { Components } from 'client/UniClient';
import { UniModalDrawerButtons, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers, useSnackbar } from 'store';

interface Props {
  service?: Components.Schemas.ServiceOfferingDto;
}

export default function DeleteServiceOffering({ service }: Props): ReactElement {
  const { t } = useTranslation('partnerServicesDrawers');
  const { closeDrawer } = useDrawers();
  const [deleteService, { isLoading }] = useDeleteServiceOffering();
  const { openSnackbar } = useSnackbar();

  const onClose = () => closeDrawer();

  // const onDelete = () => {
  //   deleteService({ serviceOfferingId: service?.id || '' }, {
  //     onSuccess() {
  //       onClose
  //     }
  //   })
  // }

  return (
    <>
      <UniModalDrawerContent>
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          {t('delete_service.description')}
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='body1' color='secondary100' weight='medium'>
          {service?.serviceOption?.name}
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          {t('delete_service.proceed')}
        </UniTypography>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: onClose, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: () => {
            onClose();
            openSnackbar('delete-service-offering', {
              hide: service?.id,
              action: () => deleteService({ serviceOfferingId: service?.id || '' }),
            });
          },
          loading: isLoading,
          label: t('buttons.delete'),
        }}
      />
    </>
  );
}
