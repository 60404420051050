import { devUrl, prodUrl } from './envConfig';

export const eventTypesMap = { ONLINE: 'Online', OFFLINE: 'Offline', HYBRID: 'Hybrid' };
export const eventTypesOptions = [
  { value: 'ONLINE', label: 'Online' },
  { value: 'OFFLINE', label: 'Offline' },
  { value: 'HYBRID', label: 'Hybrid' },
];

export const mediaPlaceholderUrl = `${process.env.NODE_ENV === 'production' ? prodUrl : devUrl}/api/img/media-placeholder.png`;
