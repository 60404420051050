import styled from 'styled-components';

export const StyledTutorsWrapper = styled.div`
  min-width: 100%;
  position: relative;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    gap: ${({ theme }) => theme.spacing(2)}px;
  }
`;
