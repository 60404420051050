import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useClient } from '../../store';

const useDeleteFile = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: string) => {
      return client!.FileApi_deleteDocument({ fileId: payload });
    },

    {
      onSuccess: async response => {},
      onError: (error: AxiosError) => {},
    },
  );
};
export default useDeleteFile;
