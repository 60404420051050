import { Box } from '@material-ui/core';
import useResendConfirmationEmail from 'api/auth/useResendConfirmationEmail';
import { UniButton, UniTypography } from 'components';
import { StyledChildrenWrapperTop } from 'components/_layouts/authLayout/AuthLayout.style';
import { useBreakpoints } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { BrowserRoutes } from '../../../constants';

interface QueryParams {
  type: 'magic' | 'signup';
  email: string;
  token: string;
}

const EmailSent = () => {
  const { isMobile } = useBreakpoints();
  const history = useHistory();
  const { type, email, token } = useParams<QueryParams>();
  const { t } = useTranslation('emailSent');
  const [resendLabel, setResendLabel] = useState<string>(t('buttons.resend'));
  const [resendToken, setResendToken] = useState(token);

  const [resendEmail] = useResendConfirmationEmail();

  const onResendEmail = () => {
    resendEmail(
      { token: resendToken },
      {
        onSuccess(response) {
          setResendLabel(t('email_resent'));
          setTimeout(() => setResendLabel(t('buttons.resend')), 30000);
          setResendToken(response.data.token);
          history.push(
            BrowserRoutes.Default.Auth.EmailSent.replace(':type', 'signup').replace(':email', email).replace(':token', response.data.token),
          );
        },
      },
    );
  };

  return (
    <>
      <StyledChildrenWrapperTop>
        <UniTypography color='typographyBlack100' weight='regular' variant='body2'>
          {t('thankYou').replace('$email_address', email)}
        </UniTypography>

        <Box my={3} display='flex' width='100%' alignItems='center' flexWrap='wrap'>
          <Box mr={1}>
            <UniTypography color='typographyBlack100' weight='regular' variant='body2'>
              {t('noEmail')}
            </UniTypography>
          </Box>
          {resendLabel === t('email_resent') ? (
            <UniTypography color='primary100' weight='medium' variant='body2'>
              {resendLabel}
            </UniTypography>
          ) : (
            <UniButton disablePadding variant='text' fullWidth={false} onClick={onResendEmail}>
              {resendLabel}
            </UniButton>
          )}
        </Box>
      </StyledChildrenWrapperTop>
    </>
  );
};

export default EmailSent;
