import { useEffect} from 'react'


interface Props {
    isSuccess: boolean;
    shouldRender?: boolean;
    setShouldRender?: Function;
}

const useShowModal =({isSuccess,shouldRender,setShouldRender}: Props) => {
    useEffect (() => {
        if (isSuccess==true && shouldRender==false)
          setShouldRender && setShouldRender(true);
        else if (isSuccess==false && shouldRender==true)
          setShouldRender && setShouldRender(false);
      }, [isSuccess])
}

export default useShowModal;