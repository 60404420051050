import { Box } from '@material-ui/core';
import useCompleteHighSchoolOnboarding from 'api/auth/useCompleteHighSchoolOnboarding';
import { Components } from 'client/UniClient';
import { UniButton, UniIconWrapper, UniPageTitle, UniTextField, UniTypography } from 'components';
import { StyledFooter } from 'components/_forms/Form.style';
import { useBreakpoints, useErrorHandler } from 'hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as LockIcon } from '../../../../assets/svg/Icons/Lock.svg';
import { OnboardParams } from '../HighSchoolOnboard';
import { OnboardWrapper, StyledOnboardTypography } from '../HighSchoolOnboard.style';

interface Props {
  email?: string;
  nextStep: (userData: Components.Schemas.UserResponse) => void;
}

const StudentOnboardWelcome: React.FC<Props> = ({ nextStep, email }) => {
  const { t } = useTranslation('forms');
  const { isMobile } = useBreakpoints();
  const [completeOnboarding, { status: completeOnboardingStatus, error: completeOnboardingError }] = useCompleteHighSchoolOnboarding();
  const errorResponse = useErrorHandler(completeOnboardingError || undefined);
  const { token } = useParams<OnboardParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ password: boolean }>();

  const onSave = (payload: { password: string }) => {
    completeOnboarding(
      { ...payload, token },
      {
        onSuccess(data) {
          nextStep(data as Components.Schemas.UserResponse);
        },
      },
    );
  };

  return (
    <Box>
      <OnboardWrapper>
        <UniPageTitle title={`${t(`introduction.title`)}`} />
        <StyledOnboardTypography variant='body2' color='typographyBlack100'>
          {t(`introduction.main`)}
        </StyledOnboardTypography>
        <UniTypography variant='subheader' color='typographyGray100'>
          {t(`introduction.secondary`)}
        </UniTypography>
        <UniTypography variant='subheader' color='typographyGray100'>
          Please set up your password to continue.
        </UniTypography>

        <Box mt={2} />
        <UniTextField label={t('form.email')} name='email' disabled value={email} fullWidth />
        <UniTextField
          inputRef={register({
            required: {
              value: true,
              message: t('form.errors.password_1'),
            },
          })}
          error={!!errors?.password?.message}
          helperText={errors?.password?.message}
          startAdornment={
            <UniIconWrapper size='medium' color='typographyGray100'>
              <LockIcon />
            </UniIconWrapper>
          }
          name='password'
          label={t('form.password')}
          placeholder={t('form.password')}
          type='password'
          fullWidth
          endAdornment
        />
        {errorResponse && (
          <UniTypography variant='subheader' color='stateAlert100'>
            {errorResponse.message.toString()}
          </UniTypography>
        )}

        {!isMobile ? (
          <Box mt={2}>
            <UniButton size='large' fullWidth={false} onClick={handleSubmit(onSave)}>
              {t('buttons.start')}
            </UniButton>
          </Box>
        ) : null}
      </OnboardWrapper>
      {isMobile && (
        <StyledFooter
          variants='M'
          type='basic'
          PrimaryButton={
            <UniButton
              style={{ margin: '16px 16px 16px auto' }}
              title='start'
              size='medium'
              color='primary'
              fullWidth={false}
              onClick={handleSubmit(onSave)}
            >
              {t('buttons.start')}
            </UniButton>
          }
        />
      )}
    </Box>
  );
};

export default StudentOnboardWelcome;
