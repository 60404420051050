export const isValidEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;

export const isValidPhoneRegex = /^\+?\d*$/;

export const isValidLinkRegex = /^(|https?:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)$/;

export const postalCodeRegexMap = {
  GB: new RegExp(
    'GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(d[dA-Z]?[ ]?d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?d{1,4}',
  ),
  JE: new RegExp('JEd[dA-Z]?[ ]?d[ABD-HJLN-UW-Z]{2}'),
  GG: new RegExp('GYd[dA-Z]?[ ]?d[ABD-HJLN-UW-Z]{2}'),
  IM: new RegExp('IMd[dA-Z]?[ ]?d[ABD-HJLN-UW-Z]{2}'),
  US: new RegExp('d{5}([ -]d{4})?'),
  CA: new RegExp('[ABCEGHJKLMNPRSTVXY]d[ABCEGHJ-NPRSTV-Z][ ]?d[ABCEGHJ-NPRSTV-Z]d'),
  DE: new RegExp('d{5}'),
  JP: new RegExp('d{3}-d{4}'),
  FR: new RegExp('d{2}[ ]?d{3}'),
  AU: new RegExp('d{4}'),
  IT: new RegExp('d{5}'),
  CH: new RegExp('d{4}'),
  AT: new RegExp('d{4}'),
  ES: new RegExp('d{5}'),
  NL: new RegExp('d{4}[ ]?[A-Z]{2}'),
  BE: new RegExp('d{4}'),
  DK: new RegExp('d{4}'),
  SE: new RegExp('d{3}[ ]?d{2}'),
  NO: new RegExp('d{4}'),
  BR: new RegExp('d{5}[-]?d{3}'),
  PT: new RegExp('d{4}([-]d{3})?'),
  FI: new RegExp('d{5}'),
  AX: new RegExp('22d{3}'),
  KR: new RegExp('d{3}[-]d{3}'),
  CN: new RegExp('d{6}'),
  TW: new RegExp('d{3}(d{2})?'),
  SG: new RegExp('d{6}'),
  DZ: new RegExp('d{5}'),
  AD: new RegExp('ADd{3}'),
  AR: new RegExp('([A-HJ-NP-Z])?d{4}([A-Z]{3})?'),
  AM: new RegExp('(37)?d{4}'),
  AZ: new RegExp('d{4}'),
  BH: new RegExp('((1[0-2]|[2-9])d{2})?'),
  BD: new RegExp('d{4}'),
  BB: new RegExp('(BBd{5})?'),
  BY: new RegExp('d{6}'),
  BM: new RegExp('[A-Z]{2}[ ]?[A-Z0-9]{2}'),
  BA: new RegExp('d{5}'),
  IO: new RegExp('BBND 1ZZ'),
  BN: new RegExp('[A-Z]{2}[ ]?d{4}'),
  BG: new RegExp('d{4}'),
  KH: new RegExp('d{5}'),
  CV: new RegExp('d{4}'),
  CL: new RegExp('d{7}'),
  CR: new RegExp('d{4,5}|d{3}-d{4}'),
  HR: new RegExp('d{5}'),
  CY: new RegExp('d{4}'),
  CZ: new RegExp('d{3}[ ]?d{2}'),
  DO: new RegExp('d{5}'),
  EC: new RegExp('([A-Z]d{4}[A-Z]|(?:[A-Z]{2})?d{6})?'),
  EG: new RegExp('d{5}'),
  EE: new RegExp('d{5}'),
  FO: new RegExp('d{3}'),
  GE: new RegExp('d{4}'),
  GR: new RegExp('d{3}[ ]?d{2}'),
  GL: new RegExp('39d{2}'),
  GT: new RegExp('d{5}'),
  HT: new RegExp('d{4}'),
  HN: new RegExp('(?:d{5})?'),
  HU: new RegExp('d{4}'),
  IS: new RegExp('d{3}'),
  IN: new RegExp('d{6}'),
  ID: new RegExp('d{5}'),
  IL: new RegExp('d{5}'),
  JO: new RegExp('d{5}'),
  KZ: new RegExp('d{6}'),
  KE: new RegExp('d{5}'),
  KW: new RegExp('d{5}'),
  LA: new RegExp('d{5}'),
  LV: new RegExp('d{4}'),
  LB: new RegExp('(d{4}([ ]?d{4})?)?'),
  LI: new RegExp('(948[5-9])|(949[0-7])'),
  LT: new RegExp('d{5}'),
  LU: new RegExp('d{4}'),
  MK: new RegExp('d{4}'),
  MY: new RegExp('d{5}'),
  MV: new RegExp('d{5}'),
  MT: new RegExp('[A-Z]{3}[ ]?d{2,4}'),
  MU: new RegExp('(d{3}[A-Z]{2}d{3})?'),
  MX: new RegExp('d{5}'),
  MD: new RegExp('d{4}'),
  MC: new RegExp('980d{2}'),
  MA: new RegExp('d{5}'),
  NP: new RegExp('d{5}'),
  NZ: new RegExp('d{4}'),
  NI: new RegExp('((d{4}-)?d{3}-d{3}(-d{1})?)?'),
  NG: new RegExp('(d{6})?'),
  OM: new RegExp('(PC )?d{3}'),
  PK: new RegExp('d{5}'),
  PY: new RegExp('d{4}'),
  PH: new RegExp('d{4}'),
  PL: new RegExp('d{2}-d{3}'),
  PR: new RegExp('00[679]d{2}([ -]d{4})?'),
  RO: new RegExp('d{6}'),
  RU: new RegExp('d{6}'),
  SM: new RegExp('4789d'),
  SA: new RegExp('d{5}'),
  SN: new RegExp('d{5}'),
  SK: new RegExp('d{3}[ ]?d{2}'),
  SI: new RegExp('d{4}'),
  ZA: new RegExp('d{4}'),
  LK: new RegExp('d{5}'),
  TJ: new RegExp('d{6}'),
  TH: new RegExp('d{5}'),
  TN: new RegExp('d{4}'),
  TR: new RegExp('d{5}'),
  TM: new RegExp('d{6}'),
  UA: new RegExp('d{5}'),
  UY: new RegExp('d{5}'),
  UZ: new RegExp('d{6}'),
  VA: new RegExp('00120'),
  VE: new RegExp('d{4}'),
  ZM: new RegExp('d{5}'),
  AS: new RegExp('96799'),
  CC: new RegExp('6799'),
  CK: new RegExp('d{4}'),
  RS: new RegExp('d{6}'),
  ME: new RegExp('8d{4}'),
  CS: new RegExp('d{5}'),
  YU: new RegExp('d{5}'),
  CX: new RegExp('6798'),
  ET: new RegExp('d{4}'),
  FK: new RegExp('FIQQ 1ZZ'),
  NF: new RegExp('2899'),
  FM: new RegExp('(9694[1-4])([ -]d{4})?'),
  GF: new RegExp('9[78]3d{2}'),
  GN: new RegExp('d{3}'),
  GP: new RegExp('9[78][01]d{2}'),
  GS: new RegExp('SIQQ 1ZZ'),
  GU: new RegExp('969[123]d([ -]d{4})?'),
  GW: new RegExp('d{4}'),
  HM: new RegExp('d{4}'),
  IQ: new RegExp('d{5}'),
  KG: new RegExp('d{6}'),
  LR: new RegExp('d{4}'),
  LS: new RegExp('d{3}'),
  MG: new RegExp('d{3}'),
  MH: new RegExp('969[67]d([ -]d{4})?'),
  MN: new RegExp('d{6}'),
  MP: new RegExp('9695[012]([ -]d{4})?'),
  MQ: new RegExp('9[78]2d{2}'),
  NC: new RegExp('988d{2}'),
  NE: new RegExp('d{4}'),
  VI: new RegExp('008(([0-4]d)|(5[01]))([ -]d{4})?'),
  PF: new RegExp('987d{2}'),
  PG: new RegExp('d{3}'),
  PM: new RegExp('9[78]5d{2}'),
  PN: new RegExp('PCRN 1ZZ'),
  PW: new RegExp('96940'),
  RE: new RegExp('9[78]4d{2}'),
  SH: new RegExp('(ASCN|STHL) 1ZZ'),
  SJ: new RegExp('d{4}'),
  SO: new RegExp('d{5}'),
  SZ: new RegExp('[HLMS]d{3}'),
  TC: new RegExp('TKCA 1ZZ'),
  WF: new RegExp('986d{2}'),
  XK: new RegExp('d{5}'),
  YT: new RegExp('976d{2}'),
};

export const validatePassword = () => ({
  required: 'Password is required',
  minLength: {
    value: 8,
    message: 'Must be at least 8 characters long',
  },
  maxLength: {
    value: 100,
    message: 'Maximum allowed characters is 100',
  },
  pattern: {
    value: isValidPasswordRegex,
    message: 'Must containing at least 1 uppercase, 1 lowercase, 1 digit, 1 special character',
  },
});

export const validateEmail = () => ({
  required: 'Email is required',
  pattern: {
    value: isValidEmailRegex,
    message: 'Invalid email address',
  },
});

export const validatePhone = (required?: boolean) => ({
  required: required != undefined ? (required == true ? 'Phone is required' : false) : 'Phone is required',
  pattern: {
    value: isValidPhoneRegex,
    message: 'Invalid phone number',
  },
});
