import SuggestProgram from 'components/suggestProgram/SuggestProgram';
import { UniHerosection } from 'components/_common/uniHerosection/UniHerosection';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { AnimatePresence } from 'framer-motion';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { isEmpty } from 'lodash';
import { ReactElement, useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useDrawers } from 'store';
import useHeader from 'store/header';
import { useGetProgramsNames } from '../../../api';
import { AnimatedTabPanel, UniHeader, UniPage } from '../../../components';
import { BrowserRoutes } from '../../../constants';
import Grouped from './_tabs/Grouped';
import Programs from './_tabs/Programs';
import Universities from './_tabs/Universities';

export default function StudentPrograms(): ReactElement {
  const { openDrawer } = useDrawers();
  const { data: programsNames } = useGetProgramsNames();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { createUrlWithQueries, queries } = useQueryStringParser<ProgramsFiltersQueries>();
  const groupedPath = BrowserRoutes.Student.CardList.Grouped;
  const programsPath = BrowserRoutes.Student.CardList.Programs;
  const universitiesPath = BrowserRoutes.Student.CardList.Universities;
  const currentRoute = url === groupedPath ? 0 : url === programsPath ? 1 : 2;
  const { setHeader, clearHeader } = useHeader();

  const openProgramFilters = () => openDrawer('filter-programs', { size: 'small' });
  const openUniversityFilters = () => openDrawer('filter-universities', { size: 'small' });

  useEffect(() => {
    setHeader({});
    return clearHeader;
  }, []);

  useEffect(() => {
    if (!isEmpty(queries) && currentRoute === 0) {
      history.push(createUrlWithQueries(groupedPath, queries));
    }
    if (!isEmpty(queries) && currentRoute === 1) {
      history.push(createUrlWithQueries(programsPath, queries));
    }
    if (!isEmpty(queries) && currentRoute === 2) {
      history.push(createUrlWithQueries(universitiesPath, queries));
    }
  }, [currentRoute]);

  return (
    <>
      <UniHeader>
        <UniHerosection
          key={currentRoute}
          headerVariant='title'
          title='Programs'
          filterbarHeadline='Find your program'
          onClickFilters={currentRoute === 2 ? openUniversityFilters : openProgramFilters}
          overflow='none'
          options={programsNames?.map(item => item.label) || []}
          withFilterBar
          tabs={[
            { label: 'Grouped', path: groupedPath },
            { label: 'Programs', path: programsPath },
            { label: 'Universities', path: universitiesPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={BrowserRoutes.Student.CardList.Grouped}>
              <AnimatedTabPanel Content={<Grouped />} index={0} currentItem={currentRoute} absoluteDirection={-1} />
            </Route>
            <Route path={BrowserRoutes.Student.CardList.Programs}>
              <AnimatedTabPanel Content={<Programs />} index={1} currentItem={currentRoute} absoluteDirection={1} />
            </Route>
            <Route path={BrowserRoutes.Student.CardList.Universities}>
              <AnimatedTabPanel Content={<Universities />} index={2} currentItem={2} absoluteDirection={-1} />
            </Route>
          </Switch>
        </AnimatePresence>
        <SuggestProgram />
      </UniPage>
    </>
  );
}
