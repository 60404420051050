import { Box } from '@material-ui/core';
import useAchievements from 'api/achievements/useAchievements';
import usePublishAchievement from 'api/achievements/usePublishAchievement';
import { Components } from 'client/UniClient';
import ActivityPlanItem from 'components/activityPlanItem/ActivityPlanItem';
import ShowableItem from 'components/showableItem/ShowableItem';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { useEffect } from 'react';

interface Props {
  isPreview: boolean;
  studentId: string;
  setShowActivity: React.Dispatch<React.SetStateAction<boolean>>;
}

const Achievements: React.FC<Props> = ({ studentId, isPreview, setShowActivity }) => {
  const { data: achievements, isLoading } = useAchievements({ studentId });
  const [publishAchievement] = usePublishAchievement();

  const achievementsFlat = achievements?.map(item => item.results).flat(1);
  const achievementsTotal = achievementsFlat?.length;

  const toggleAchievementItem = (item: Components.Schemas.AchievementDto, value: boolean) => {
    publishAchievement({ achievementId: item.id, isPublished: value });
  };

  useEffect(() => {
    const showActivity = achievementsFlat?.some(item => item.isPublished);
    setShowActivity(!!showActivity);
  }, [achievementsFlat]);

  return (
    <>
      {isLoading && <UniListLoader />}
      {achievementsFlat?.map((achievement, index) => (
        <>
          <ShowableItem disabled={isPreview} show={achievement.isPublished} onChange={value => toggleAchievementItem(achievement, value)}>
            <ActivityPlanItem achievement={achievement} key={index} disabled />
          </ShowableItem>
          <Box mb={3} />
        </>
      ))}
    </>
  );
};

export default Achievements;
