import React from 'react';
import { Bounce, Container } from './UniBounceLoader.style';

export type Color = 'white' | 'primary' | 'secondary' | 'typographyGray70';

export type LoaderProps = {
  size: number;
  color: string | Color;
  pulseSpeed: number;
};

const UniBounceLoader: React.FC<Partial<LoaderProps>> = ({ color = 'primary', size = 20, pulseSpeed = 1 }) => {
  return (
    <Container color={color} size={size} pulseSpeed={pulseSpeed}>
      <Bounce delay={0.32} />
      <Bounce delay={0.16} />
      <Bounce />
    </Container>
  );
};

export default UniBounceLoader;
