import { devUrl, prodUrl } from 'constants/envConfig';
import create from 'zustand';
import { CacheKeys } from '../../constants';
import { getLocalStorage, setLocalStorage } from '../../utils';
import { AuthState } from './types';

const defaultState = {
  id: '',
  token: '',
  username: '',
  password: '',
  firstName: '',
  lastName: '',
  imageUrl: '',
  emailConfirmed: false,
  migratedUserId: '',
  role: undefined,
};

const useAuth = create<AuthState>((set, get) => ({
  profile: getLocalStorage(CacheKeys.Profile.Default) || defaultState,
  afterAuthPath: getLocalStorage(CacheKeys.Profile.AfterAuthPath),
  setAfterAuthPath: value =>
    set(prev => {
      if (value) setLocalStorage(CacheKeys.Profile.AfterAuthPath, value);
      else localStorage.removeItem(CacheKeys.Profile.AfterAuthPath);
      return { ...prev, afterAuthPath: value };
    }),
  isLoggedIn: () => !!get().profile.id,
  setProfile: profile => {
    return set(state => {
      setLocalStorage(CacheKeys.Profile.Default, {
        ...state.profile,
        ...profile,
      });
      return { ...state, profile: { ...state.profile, ...profile } };
    });
  },

  resetProfile: () => {
    return set(state => {
      localStorage.removeItem(CacheKeys.Profile.Default);
      return { ...state, profile: defaultState };
    });
  },

  getAgentInviteLink: profile => {
    const inviteToken = profile?.agentProfile?.inviteToken;
    return process.env.NODE_ENV === 'production' ? `${prodUrl}/app/invite-link/${inviteToken}` : `${devUrl}/app/invite-link/${inviteToken}`;
  },
  setStudentMetadata: callback => {
    return set(state => {
      let aux = state;
      aux.profile.studentMetadata = callback(aux.profile.studentMetadata!);
    });
  },
}));

export default useAuth;
