import React, { ReactElement } from 'react';
import { Box } from '@material-ui/core';
import UniCardWrapper from '../uniCardWrapper/UniCardWrapper';
import { UniFilterBarHeader } from './UniFilterBarHeader';

interface Props {
  title: string;
  children: ReactElement;
  options?: string[];
  noCountry?: boolean;
  showDiscipline?: boolean;
  overflow?: 'hidden' | 'auto' | 'visible' | 'none';
}

export const UniFilterBar: React.FC<Props> = ({ overflow, children, title }) => {
  return (
    <UniCardWrapper size={'S'} overflow={overflow} border grey>
      <UniFilterBarHeader title={title} />
      <Box display='flex' flexWrap='wrap' px={3} pb={3}>
        {children}
      </Box>
    </UniCardWrapper>
  );
};

export default UniFilterBar;
