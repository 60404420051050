import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniChip, UniPageTitle, UniTypography } from 'components';
import { applicationStatusMap } from 'constants/applicaiton';
import { useBreakpoints } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ApplicationHistoryItemWrapper, StyledDecisionsDateWrapper, StyledUniCard } from './ApplicationReplyHistoryItem.style';
import ApplicationReplyHistoryItemFile from './ApplicationReplyHistoryItemFile';

const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

interface Props
  extends Partial<
    Pick<Components.Schemas.ApplicationStatusUpdateDto, Exclude<keyof Components.Schemas.ApplicationStatusUpdateDto, 'reply'>>
  > {
  reply?: Components.Schemas.ApplicationStudentReplyDto;
  feePaid?: boolean;
}

const ApplicationReplyHistoryItem: React.FC<Props> = ({ createdAt, files, id, message, reply, status, feePaid, children, ...rest }) => {
  const { t } = useTranslation('applicationReplyHistoryItem');
  const { isMobile } = useBreakpoints();
  // const { openDrawer } = useDrawers();

  const historyItemTitleMap = {
    PASSED: t('status.PASSED'),
    FAILED: t('status.FAILED'),
    SUBMITTED: t('status.SUBMITTED'),
    NEW: t('status.NEW'),
    VALIDATED: t('status.VALIDATED'),
    IN_REVIEW: t('status.IN_REVIEW'),
    INCOMPLETE: t('status.INCOMPLETE'),
    CONDITIONAL_ADMISSION: t('status.CONDITIONAL_ADMISSION'),
    ENROLLED: t('status.ENROLLED'),
    PENDING_PAYMENT: 'Pending payment',
    PENDING_APPLICATION_FEE: 'Pending application fee',
    PAID: 'Application fee paid',
  };

  return (
    <>
      {!!reply && (
        <ApplicationHistoryItemWrapper {...rest}>
          {createdAt ? (
            <StyledDecisionsDateWrapper border shadow='none' size={isMobile ? 'S' : 'L'}>
              <UniTypography variant='subheader' weight='medium' color='typographyGray100'>
                {`${new Date(createdAt).getDate()} ${month[new Date(createdAt).getMonth()]}`}
              </UniTypography>
              <Box mx={1}>
                <UniTypography variant='footnote' color='typographyGray70'>
                  {new Date(createdAt).getFullYear()}
                </UniTypography>
              </Box>
            </StyledDecisionsDateWrapper>
          ) : (
            <Box />
          )}
          <StyledUniCard border size={isMobile ? 'S' : 'L'}>
            <UniPageTitle size={isMobile ? 'XS' : 'S'} title={`Candidate Reply`} />
            <Box mb={1.5} mt={3}>
              <UniTypography variant='subheader' weight='medium'>
                {t('labels.uploadedDocs')}
              </UniTypography>
            </Box>
            {reply.files?.map((file, index) => (
              <ApplicationReplyHistoryItemFile file={file} key={index} />
            ))}
          </StyledUniCard>
        </ApplicationHistoryItemWrapper>
      )}
      <ApplicationHistoryItemWrapper {...rest}>
        <StyledDecisionsDateWrapper border shadow='none' size={isMobile ? 'S' : 'L'}>
          <UniTypography variant='subheader' weight='medium' color='typographyGray100'>
            {createdAt ? `${new Date(createdAt).getDate()} ${month[new Date(createdAt).getMonth()]}` : ''}
          </UniTypography>
          <Box mx={1}>
            <UniTypography variant='footnote' color='typographyGray70'>
              {createdAt ? new Date(createdAt).getFullYear() : ''}
            </UniTypography>
          </Box>
        </StyledDecisionsDateWrapper>
        <StyledUniCard border size={isMobile ? 'S' : 'L'}>
          <UniPageTitle size={isMobile ? 'XS' : 'S'} title={feePaid ? historyItemTitleMap.PAID : historyItemTitleMap[status!]} />
          {!feePaid && (
            <>
              <Box mb={1} mt={3}>
                <UniTypography variant='subheader' weight='medium'>
                  {t('labels.applicationStatus')}
                </UniTypography>
              </Box>
              <UniChip color='green' label={applicationStatusMap[status!]} />
            </>
          )}

          {(!!files?.length || !!message) && !feePaid && (
            <Box mt={3}>
              <UniTypography variant='body1' color='secondary100' weight='medium'>
                {t('labels.institutionalQuestions')}
              </UniTypography>
            </Box>
          )}
          {!!files?.length && (
            <Box mb={1.5} mt={2}>
              <UniTypography variant='subheader' weight='medium'>
                {t('labels.attachedDocs')}
              </UniTypography>
            </Box>
          )}

          {files?.map((file, index) => (
            <ApplicationReplyHistoryItemFile file={file} key={index} />
          ))}
          {!!message && (
            <>
              <Box mb={1.5} mt={2}>
                <UniTypography variant='subheader' weight='medium'>
                  {t('labels.message')}
                </UniTypography>
              </Box>
              <UniTypography color='typographyGray100'>{message}</UniTypography>
            </>
          )}
        </StyledUniCard>
      </ApplicationHistoryItemWrapper>
    </>
  );
};

export default ApplicationReplyHistoryItem;
