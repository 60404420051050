import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Paths } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Payload = Paths.AchievementApiRequestAchievementUpdate.RequestBody & Paths.AchievementApiRequestAchievementUpdate.PathParameters;

const useRequestAchievementUpdate = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async ({ achievementId, ...body }: Payload) => {
      return client!.AchievementApi_requestAchievementUpdate({ achievementId }, body);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Achievement.Default);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default useRequestAchievementUpdate;
