import LayoutNoNavRoute from 'components/_layouts/layoutNoNavRoute/LayoutNoNavRoute';
import LayoutRoute from 'components/_layouts/layoutRoute/LayoutRoute';
import { lazy } from 'react';
import EditPublicProfile from 'views/_student/editPublicProfile/EditPublicProfile';
import { BrowserRoutes } from '../constants';
import StudentPrepCenterCheckIn from '../views/_student/prep-center/StudentPrepCenterCheckIn';

const AgentProfile = lazy(() => import('../views/_agent/profile/AgentProfile'));
const StudentUniversities = lazy(() =>
  import('views/_student/universities/StudentUniversities').then(({ StudentUniversities }) => ({ default: StudentUniversities })),
);
const StudentProgramPage = lazy(() => import('../views').then(({ StudentProgramPage }) => ({ default: StudentProgramPage })));
const StudentHome = lazy(() => import('../views').then(({ StudentHome }) => ({ default: StudentHome })));
const StudentAiSearch = lazy(() => import('../views').then(({ StudentAiSearch }) => ({ default: StudentAiSearch })));
const StudentAppTracker = lazy(() => import('../views').then(({ StudentAppTracker }) => ({ default: StudentAppTracker })));
const StudentProfile = lazy(() => import('../views').then(({ StudentProfile }) => ({ default: StudentProfile })));
const StudentPrograms = lazy(() => import('../views').then(({ StudentPrograms }) => ({ default: StudentPrograms })));
const StudentApplications = lazy(() => import('../views').then(({ StudentApplications }) => ({ default: StudentApplications })));
const StudentUniversityPage = lazy(() => import('../views').then(({ StudentUniversityPage }) => ({ default: StudentUniversityPage })));
const StudentPrepCenter = lazy(() => import('../views').then(({ StudentPrepCenter }) => ({ default: StudentPrepCenter })));
const StudentPrepCenterProductsList = lazy(() => import('../views').then(({ StudentPrepCenterProductsList }) => ({ default: StudentPrepCenterProductsList })));
const StudentPrepCenterProductPage = lazy(() => import('../views').then(({ StudentPrepCenterProductPage }) => ({ default: StudentPrepCenterProductPage })));
const StudentPrepCenterCheckOutPage = lazy(() => import('../views').then(({ StudentPrepCenterCheckOutPage }) => ({ default: StudentPrepCenterCheckOutPage })));
const StudentPrepCenterSessionsPage = lazy(() => import('../views').then(({ StudentPrepCenterSessionsPage }) => ({ default: StudentPrepCenterSessionsPage })));
const StudentPrepCenterProfessorsPage = lazy(() => import('../views').then(({ StudentPrepCenterProfessorsPage }) => ({ default: StudentPrepCenterProfessorsPage })));
const StudentOnboard = lazy(() => import('../views').then(({ StudentOnboard }) => ({ default: StudentOnboard })));
const ConsultantPage = lazy(() => import('../views').then(({ ConsultantPage }) => ({ default: ConsultantPage })));
const EventsPage = lazy(() => import('../views/_student/events/Events'));
const EventPage = lazy(() => import('../views/_student/event/Event'));

const StudentRoutes = () => {
  return [
    <LayoutRoute exact path={BrowserRoutes.Student.Default} key={BrowserRoutes.Student.Default} component={StudentHome} />,
    <LayoutRoute exact path={BrowserRoutes.Student.AiSearch} key={BrowserRoutes.Student.AiSearch} component={StudentAiSearch} />,
    <LayoutRoute path={BrowserRoutes.Student.Profile.Default} key={BrowserRoutes.Student.Profile.Default} component={StudentProfile} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Student.Program.Default}
      key={BrowserRoutes.Student.Program.Default}
      component={StudentProgramPage}
    />,
    <LayoutRoute path={BrowserRoutes.Student.CardList.Default} key={BrowserRoutes.Student.CardList.Default} component={StudentPrograms} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Student.Universities}
      key={BrowserRoutes.Student.Universities}
      component={StudentUniversities}
    />,
    <LayoutRoute exact path={BrowserRoutes.Student.Agent} key={BrowserRoutes.Student.Agent} component={AgentProfile} />,
    <LayoutRoute
      path={BrowserRoutes.Student.UniversityProfile}
      key={BrowserRoutes.Student.UniversityProfile}
      component={StudentUniversityPage}
    />,
    <LayoutRoute
      path={BrowserRoutes.Student.AppTracker.Default}
      key={BrowserRoutes.Student.AppTracker.Default}
      component={StudentAppTracker}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Student.PrepCenter.Dashboard}
      key={BrowserRoutes.Student.PrepCenter.Dashboard}
      component={StudentPrepCenter}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Student.PrepCenter.Products}
      key={BrowserRoutes.Student.PrepCenter.Products}
      component={StudentPrepCenterProductsList}
    />,
    <LayoutRoute
      path={BrowserRoutes.Student.PrepCenter.Product}
      key={BrowserRoutes.Student.PrepCenter.Product}
      component={StudentPrepCenterProductPage}
    />,
    <LayoutRoute
      path={BrowserRoutes.Student.PrepCenter.CheckOut}
      key={BrowserRoutes.Student.PrepCenter.CheckOut}
      component={StudentPrepCenterCheckOutPage}
    />,
    <LayoutRoute
      path={BrowserRoutes.Student.PrepCenter.CheckIn}
      key={BrowserRoutes.Student.PrepCenter.CheckIn}
      component={StudentPrepCenterCheckIn}
    />,
    <LayoutRoute
      path={BrowserRoutes.Student.PrepCenter.Sessions.Default}
      key={BrowserRoutes.Student.PrepCenter.Sessions.Default}
      component={StudentPrepCenterSessionsPage}
    />,
    <LayoutRoute
      path={BrowserRoutes.Student.PrepCenter.Professors.Default}
      key={BrowserRoutes.Student.PrepCenter.Professors.Default}
      component={StudentPrepCenterProfessorsPage}
    />,
    <LayoutRoute
      path={BrowserRoutes.Student.Applications.Default}
      key={BrowserRoutes.Student.Applications.Default}
      component={StudentApplications}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Student.Consultancy.Default}
      key={BrowserRoutes.Student.Consultancy.Default}
      component={ConsultantPage}
    />,
    <LayoutNoNavRoute
      exact
      path={BrowserRoutes.Student.Profile.PublicProfile()}
      key={BrowserRoutes.Student.Profile.PublicProfile()}
      component={EditPublicProfile}
    />,
    <LayoutRoute
      key={BrowserRoutes.Default.Onboard.Student}
      path={BrowserRoutes.Default.Onboard.Student}
      component={StudentOnboard}
    />,
    <LayoutRoute exact path={BrowserRoutes.Student.Events.Default()} key={BrowserRoutes.Student.Events.Default()} component={EventsPage} />,
    <LayoutRoute exact path={BrowserRoutes.Student.Event.Default()} key={BrowserRoutes.Student.Event.Default()} component={EventPage} />,
    // <LayoutRoute exact path={BrowserRoutes.Student.Tutorials} key={BrowserRoutes.Student.Tutorials} component={VideoTutorials} />,
  ];
};

export default StudentRoutes;
