const TrackingEvents = {
  categories: {
    AiSearch: 'AI Search',
    ApplicationProcess: 'Application Process',
    ApplicationFeePayment: 'Application Fee Payment',
  },
  actions: {
    initialized: 'Initialized',
    finalized: 'Finalized',
    canceled: 'Canceled',
    searchCountries: 'Search by Countries',
    searchKeys: 'Search by Keys',
    completedStep: (step: number) => `Completed Step ${step}`,
  },
};

export default TrackingEvents;
