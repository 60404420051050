import { AxiosError } from 'axios';
import { queryCache, useMutation } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Payload {
  id: string;
}

const useMarkNotifications = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.NotificationApi_seeNotification({ notificationId: payload.id });
    },
    {
      onSuccess: response => {},
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         }); 
         */
      },
    },
  );
};

export default useMarkNotifications;
