import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { useBreakpoints } from 'hooks';
import { useMemo } from 'react';
import { queryCache, useMutation } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { getMetaRole } from 'utils';
import { CacheKeys } from '../../constants';
import { useAuth, useClient, useSnackbar } from '../../store';

export type AppKey =
  | 'Applications.All'
  | 'Applications.Saved'
  | 'Applications.Ongoing'
  | 'Applications.Answered'
  | 'Applications.Suggested';

type Payload = {
  isFavorite: boolean;
  programId: string;
  type?: AppKey;
};

const useFavoriteProgram = () => {
  const { client } = useClient();
  const { openSnackbar } = useSnackbar();
  const { profile, setStudentMetadata } = useAuth();
  const { isStudent } = getMetaRole(profile.role!);
  const { url } = useRouteMatch();
  const { isMobile } = useBreakpoints();

  const appKey = useMemo(() => {
    if (url.includes('all-programs')) return CacheKeys.Applications.All;
    if (url.includes('saved')) return CacheKeys.Applications.Saved;
    if (url.includes('ongoing')) return CacheKeys.Applications.Ongoing;
    if (url.includes('answered')) return CacheKeys.Applications.Answered;
    if (url.includes('suggested')) return CacheKeys.Applications.Suggested;
    return CacheKeys.Applications.All;
  }, [url]);

  const updateIsFavorite = (key: string, updatedProgram: Payload) => {
    const previousApps = queryCache.getQueries(key);
    if (previousApps && previousApps.length > 0) {
      previousApps.sort((a, b) => b.state.updatedAt - a.state.updatedAt);
      queryCache.setQueryData(previousApps[0].queryKey, (oldQuery: any) => {
        let updatedApps = oldQuery?.map((item: any) => {
          let newItem = item;
          const index = item.results?.findIndex((prog: any) => prog?.id === updatedProgram.programId);
          if (index !== -1) {
            newItem.results[index].isFavorite = !newItem.results[index].isFavorite;
          }
          return newItem;
        });
        return updatedApps;
      });
    }
  };

  const wait = (ms: number) => {
    return new Promise<void>((resolve, reject) => {
      openSnackbar('delete-program', {
        onUndo: () => reject(new Error('Action was cancelled')),
        mobileOver: isMobile && url.includes('general') ? 'footerProgram' : 'navigation',
      });
      setTimeout(resolve, ms);
    });
  };

  return useMutation(
    async (payload: Payload) => {
      if (payload.isFavorite) {
        return wait(3000).then(() => client!.ProgramApi_unmarkProgramAsFavorite(payload.programId!));
      } else {
        return client!.ProgramApi_markProgramAsFavorite(payload.programId!);
      }
    },
    {
      onMutate: (updatedProgram: Payload) => {
        queryCache.cancelQueries(CacheKeys.Search.Programs);
        queryCache.cancelQueries(CacheKeys.General.Program);
        queryCache.cancelQueries(CacheKeys.Search.GroupedPrograms);
        queryCache.cancelQueries(CacheKeys.Applications.All);
        queryCache.cancelQueries(appKey);

        //optimistic update for programs
        const previousPrograms = queryCache.getQueries(CacheKeys.Search.Programs);
        if (previousPrograms && previousPrograms.length > 0) {
          previousPrograms.sort((a, b) => b.state.updatedAt - a.state.updatedAt);
          queryCache.setQueryData(previousPrograms[0].queryKey, (oldQuery: any) => {
            let updatedProgs = oldQuery?.map((item: Components.Schemas.ProgramResultPageDto) => {
              let newItem = item;
              const itemIndex = item.results.findIndex(prog => prog.id === updatedProgram.programId);
              if (itemIndex !== -1) {
                newItem.results[itemIndex].isFavorite = !newItem.results[itemIndex].isFavorite;
              }
              return newItem;
            });
            return updatedProgs;
          });
        }

        //optimistic update for grouped programs
        const previousGroupedPrograms = queryCache.getQueries(CacheKeys.Search.GroupedPrograms);

        if (previousGroupedPrograms && previousGroupedPrograms.length > 0) {
          previousGroupedPrograms.sort((a, b) => b.state.updatedAt - a.state.updatedAt);
          queryCache.setQueryData(previousGroupedPrograms[0].queryKey, (oldQuery: any) => {
            let updatedProgs = oldQuery?.map((item: Components.Schemas.UniversityProgramResultPageDto) => {
              let newItem = item;
              let progs = item?.results?.map(item => item?.programs).flat();
              const itemIndex = progs?.findIndex((prog: Components.Schemas.ProgramResponse) => prog.id === updatedProgram.programId);
              if (itemIndex !== -1) {
                progs[itemIndex].isFavorite = !progs[itemIndex].isFavorite;
              }
              return newItem;
            });

            return updatedProgs;
          });
        }

        //  optimistic update for student applications
        const previousApps = queryCache.getQueries(appKey);
        updateIsFavorite(updatedProgram?.type || appKey, updatedProgram);

        //optimistic update for program page
        const previousProg = queryCache.getQueryData([CacheKeys.General.Program, updatedProgram.programId]);
        if (previousProg) {
          queryCache.setQueryData([CacheKeys.General.Program, updatedProgram.programId], (olderQuery: any) => {
            return { ...olderQuery, isFavorite: olderQuery?.id === updatedProgram.programId ? true : olderQuery?.isFavorite };
          });
        }
        isStudent &&
          setStudentMetadata(metadata => {
            return {
              ...metadata,
              favoriteProgramsCount: updatedProgram?.isFavorite
                ? profile?.studentMetadata?.favoriteProgramsCount! - 1
                : profile?.studentMetadata?.favoriteProgramsCount! + 1,
            };
          });
        return () => {
          if (previousPrograms && previousPrograms.length > 0) queryCache.setQueryData(previousPrograms[0].queryKey, previousPrograms[0]);
          if (previousGroupedPrograms && previousGroupedPrograms.length > 0)
            queryCache.setQueryData(previousGroupedPrograms[0].queryKey, previousGroupedPrograms[0]);
          if (previousApps && previousApps.length > 0) queryCache.setQueryData(previousApps[0].queryKey, previousApps[0]);
          if (previousProg) queryCache.setQueryData(CacheKeys.General.Program, previousProg);
        };
      },
      onError: (error: AxiosError, updatedProgram, rollback: Function) => {
        // rollback();
        isStudent &&
          setStudentMetadata(metadata => {
            return {
              ...metadata,
              favoriteProgramsCount: updatedProgram?.isFavorite
                ? profile?.studentMetadata?.favoriteProgramsCount! + 1
                : profile?.studentMetadata?.favoriteProgramsCount! - 1,
            };
          });
        queryCache.invalidateQueries(CacheKeys.General.Programs);
        queryCache.invalidateQueries(CacheKeys.Search.Programs);
        queryCache.invalidateQueries(CacheKeys.Search.GroupedPrograms);
      },
      onSuccess: (response, payload) => {
        if (!payload.isFavorite) {
          openSnackbar('add-program', { mobileOver: isMobile && url.includes('general') ? 'footerProgram' : 'navigation' });
        }
      },
      onSettled: async response => {
        queryCache.invalidateQueries(CacheKeys.Applications.Saved);
        queryCache.invalidateQueries(CacheKeys.General.Program);
        queryCache.invalidateQueries(CacheKeys.General.Application);
        queryCache.invalidateQueries(appKey);
      },
    },
  );
};
export default useFavoriteProgram;
