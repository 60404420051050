import { Box } from '@material-ui/core';
import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import { useIntersectionObserver } from 'hooks';
import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MessageIcon } from '../../assets/svg/Icons/Messages.svg';
import { UniBounceLoader, UniIconWrapper } from '../../components';
import { AgencyFilters } from '../../types';
import UniButton from '../_common/uniButton/UniButton';
import UniTypography from '../_common/uniTypography/UniTypography';
import { SelectedAgenciesWrapper, StyledAgencies } from './AgenciesList.style';
import AgenciesListHeader from './AgenciesListHeader';
export type AgenciesSort = 'NAME' | 'CANDIDATES_COUNT' | 'ADMITTED_COUNT' | 'ENROLLED_COUNT' | 'DATE_ADDED' | undefined;

export type AgenciesQueryParams = {
  sort?: AgenciesSort;
  order?: 'ASC' | 'DESC' | undefined;
  query?: string;
  filters?: AgencyFilters;
};

export type OnAgenciesSort = (value: AgenciesSort) => void;

// TODO wait for new designer so we know if this will be a template for a list project wide,
// if so make this into a generic list

interface Props {
  hasMore: boolean;
  isLoading?: boolean;
  fetchMore: () => void;
  onSort?: OnAgenciesSort;
  params?: AgenciesQueryParams;
  allSelected?: boolean;
  selectAllAgencies: Function;
  selectedAgencies?: number;
  length: number;
  children?: ReactElement[] | ReactElement;
  order?: OrderType;
  sortType?: AgenciesSort;
}

const AgenciesList = ({
  onSort,
  hasMore,
  fetchMore,
  isLoading,
  params,
  allSelected,
  selectAllAgencies,
  selectedAgencies,
  length,
  children,
  order,
  sortType,
}: Props) => {
  const { t } = useTranslation('superAdminHome');
  const targetRef = useRef(null);

  useIntersectionObserver({
    target: targetRef,
    onIntersect: fetchMore,
    enabled: hasMore,
  });
  const showEndOfList = length > 4 && !hasMore && !isLoading;
  const showEmptyList = length === 0 && !isLoading;

  return (
    <>
      <AgenciesListHeader
        allSelected={allSelected}
        onSort={onSort}
        selectAllAgencies={selectAllAgencies}
        params={params}
        order={order}
        sortType={sortType}
      />

      <StyledAgencies>
        {children}
        {showEmptyList && (
          <Box mx={4}>
            <UniTypography color='primary100' weight='medium'>
              {t('no_agencies_found')}
            </UniTypography>
          </Box>
        )}
        {isLoading && <UniBounceLoader />}
        {showEndOfList && (
          <Box>
            <UniTypography color='typographyGray70'>You have reached the end of the list.</UniTypography>
          </Box>
        )}
        <div ref={targetRef} />
      </StyledAgencies>
      <SelectedAgenciesWrapper isVisible={selectedAgencies! > 0}>
        <UniTypography color='white100'>{`${selectedAgencies} ${t('agencies_selected')}`}</UniTypography>
        <UniButton
          variant='text'
          fullWidth={false}
          color='primary'
          onClick={() => {}}
          startIcon={
            <UniIconWrapper size='large' color='white100'>
              <MessageIcon />
            </UniIconWrapper>
          }
        >
          {t('send_message')}
        </UniButton>
      </SelectedAgenciesWrapper>
    </>
  );
};

export default AgenciesList;
