import { usePatchCandidate } from 'api';
import useUpdateStudent from 'api/students/useUpdateStudent';
import { Components } from 'client/UniClient';
import { UniModalDrawerButtons } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import AccomplishmentsForm, { AccomplishmentsFormInputs } from 'components/_forms/AccomplishmentsForm';
import { englishExams } from 'constants/options/englishExams';
import { universityExams } from 'constants/options/universityExams';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth, useDrawers, useSnackbar } from 'store';
import { getMetaRole } from 'utils';

interface Props {
  student?: Components.Schemas.UserResponse;
}

const AccomplishmentsFormDrawer: React.FC<Props> = ({ student }) => {
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const profile = useAuth(state => state.profile);
  const { t } = useTranslation('forms');
  const englishExamsArray = [...(student?.studentProfile?.englishExams || []), ...(student?.studentProfile?.otherEnglishExams || [])];
  const englishExamsOptions = englishExams.filter(item => englishExamsArray?.includes(item.value));

  const universityExamsArray = [
    ...(student?.studentProfile?.universityExams || []),
    ...(student?.studentProfile?.otherUniversityExams || []),
  ];
  const universityExamsOptions = universityExams.filter(item => universityExamsArray?.includes(item.value));

  const defaultValues: Partial<AccomplishmentsFormInputs> = {
    englishExams: englishExamsOptions,
    universityExams: universityExamsOptions,
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AccomplishmentsFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const { pathname } = useLocation();
  const [updateStudent, { status: updateStudentStatus, error: updateStudentError }] = useUpdateStudent();
  const [updateStudentAgency, { status: updateStudentAgencyStatus, error: updateStudentAgencyError }] = usePatchCandidate();
  const { isAgency, isAgent, isStudent } = getMetaRole(profile?.role);

  const isLoading = updateStudentStatus === 'loading' || updateStudentAgencyStatus === 'loading';

  const onSubmit = (formData: AccomplishmentsFormInputs) => {
    const englishExamsArrayCheck = englishExams.map(item => item.value);
    const englishExamsRequest = formData?.englishExams?.map(({ value }) => value).filter(item => englishExamsArrayCheck.includes(item));
    const otherEnglishExamsRequest = formData?.englishExams
      ?.map(({ value }) => value)
      .filter(item => !englishExamsArrayCheck.includes(item));

    const universityExamsArrayCheck = universityExams.map(item => item.value);
    const universityExamsRequest = formData?.universityExams
      ?.map(({ value }) => value)
      .filter(item => universityExamsArrayCheck.includes(item));
    const otherUniversityExamsRequest = formData?.universityExams
      ?.map(({ value }) => value)
      .filter(item => !universityExamsArrayCheck.includes(item));

    const requestForm: Components.Schemas.UpdateStudentRequest = {
      studentProfile: {
        englishExams: englishExamsRequest,
        otherEnglishExams: otherEnglishExamsRequest,
        universityExams: universityExamsRequest,
        otherUniversityExams: otherUniversityExamsRequest,
      },
    };
    if (isStudent) {
      updateStudent(requestForm, {
        onSuccess() {
          closeDrawer();
          openSnackbar('personal-details');
        },
        onError() {},
      });
    }
    if (isAgency) {
      const studentId = pathname.split('/')[4];
      updateStudentAgency(
        { ...requestForm, studentId },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('personal-details');
          },
          onError() {},
        },
      );
    }
    if (isAgent) {
      const studentId = pathname.split('/')[4];
      updateStudentAgency(
        { ...requestForm, studentId },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('personal-details');
          },
          onError() {},
        },
      );
    }
  };

  return (
    <>
      <UniModalDrawerContent>
        <AccomplishmentsForm control={control} errors={errors} />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: t('buttons.save_changes'),
          loading: isLoading,
        }}
        SecondaryButton={{ onClick: closeDrawer, label: t('buttons.cancel') }}
      />
    </>
  );
};

export default AccomplishmentsFormDrawer;
