import { createGlobalStyle } from 'styled-components';
import { Colors } from '../constants';

const GlobalStyle = createGlobalStyle`
  html,body,#root{
    width: 100%;
    height: 100%;
  }
  iframe#tidio-chat-iframe{
    z-index: 500 !important;
  }
  body{

   background-color: ${Colors.Gray1._100};

   *{
     ::-webkit-scrollbar {
      width: ${({ theme }) => theme.spacing(0.5)}px;
      height:  ${({ theme }) => theme.spacing(0.5)}px;
      }
    ::-webkit-scrollbar-thumb {
      background: ${Colors.Typography.Gray_30};    
      border-radius:  ${({ theme }) => theme.spacing(1)}px;
      }
    }
  }
  svg {
    vertical-align: unset;
  }
  button:focus {
    outline: 0;
  }`;

export default GlobalStyle;
