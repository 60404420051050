import { Box } from '@material-ui/core';
import { UniLink } from 'components';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownArrow } from '../../../assets/svg/Icons/Arrow/ArrowDown.svg';
import { ReactComponent as UpArrow } from '../../../assets/svg/Icons/Arrow/ArrowUp.svg';
import { ReactComponent as ChevronDown } from '../../../assets/svg/Icons/Arrow/Down.svg';
import UniButton from '../uniButton/UniButton';
import UniDropdown from '../uniDropdown/UniDropdown';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import UniTypography from '../uniTypography/UniTypography';
import { StyledListHeaderWrapper } from './UniListHeader.style';

interface Props {
  count?: number;
  options?: { value: any; label: string; hasIcon?: boolean }[];
  onChange?: (value: any) => void;
  hasLeftIcon?: boolean;
}

const UniListHeader: React.FC<Props> = ({ count = 0, options, onChange }) => {
  const { t } = useTranslation('uniListHeader');
  const [openMenu, setOpenMenu] = useState(false);
  const [sortByLabel, setSortByLabel] = useState<string | undefined>(undefined);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const { queries, replaceQueriesToUrl } = useQueryStringParser<{ sort: string; order?: string }>();

  const onSortBy = (value: string | string[]) => {
    if (Array.isArray(value)) {
      const [sort, order] = value;
      replaceQueriesToUrl({ ...queries, sort, order });
      return;
    }
    replaceQueriesToUrl({ ...queries, sort: value as string });
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <StyledListHeaderWrapper>
      {count !== undefined && (
        <Box mr={2}>
          <UniTypography variant='subheader' weight='medium' color='typographyGray100'>
            {count}
            {t('results')}
          </UniTypography>
        </Box>
      )}
      {!!options && (
        <UniDropdown
          handleOpen={() => setOpenMenu(true)}
          handleClose={() => setOpenMenu(false)}
          open={openMenu}
          ActionButton={
            <UniButton
              variant='secondary'
              color='fadeGray'
              endIcon={
                <UniIconWrapper color='typographyGray100'>
                  <ChevronDown />
                </UniIconWrapper>
              }
            >
              {sortByLabel ?? t('sort')}
            </UniButton>
          }
        >
          {options?.map(option => (
            <UniLink
              size='main'
              label={option.label}
              fullWidth
              IconLeft={
                option?.hasIcon && (
                  <UniIconWrapper size='medium' color='typographyGray100'>
                    {order === 'asc' ? <UpArrow /> : <DownArrow />}
                  </UniIconWrapper>
                )
              }
              onClick={(e: React.MouseEvent) => {
                setSortByLabel(option.label);
                onSortBy(option.value);
                setOpenMenu(false);
                setOrder(order === 'asc' ? 'desc' : 'asc');
              }}
            />
          ))}
        </UniDropdown>
      )}
    </StyledListHeaderWrapper>
  );
};

export default UniListHeader;
