import { Box } from '@material-ui/core';
import { useReplyTouniversity } from 'api';
import moment from 'moment';
import { default as React, ReactElement, useEffect, useState } from 'react';
import { ReactComponent as ApplicationIcon } from '../../assets/svg/Icons/Applications.svg';
import { Components } from '../../client/UniClient';
import { UniButton, UniFileViewer, UniIconWrapper, UniStatus } from '../../components';
import { Colors } from '../../constants';
import UniTypography from '../_common/uniTypography/UniTypography';
import { StyledProgramDataCard } from './ProgramExtended.style';
import { RequiredDocument } from './ProgramExtendedRequiredDocument';

interface Props {
  application?: Components.Schemas.ApplicationDto;
  studentProfile?: Components.Schemas.StudentProfileDto;
}

export default function ProgramExtendedReply({ application, studentProfile }: Props): ReactElement {
  const applicationReplies = application?.statusUpdateHistory;
  const [documentsToUpload, setDocumentsToUpload] = useState<File[]>([]);
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState<string[]>([]);
  const [lastReply, setLastReply] = useState(applicationReplies ? applicationReplies[applicationReplies?.length - 1] : undefined);
  const showUploadDocs = (application?.status === 'INCOMPLETE' || application?.status === 'CONDITIONAL_ADMISSION') && !lastReply?.reply;

  const [postReply, { data: postReplyData, status: postReplyStatus }] = useReplyTouniversity();

  const handleSubmit = () => {
    if (documentsToUpload.length > 0) {
      postReply({ applicationId: application?.id!, statusUpdateId: lastReply?.id!, files: documentsToUpload });
    } else if (uploadedDocumentIds) {
      postReply({ applicationId: application?.id!, statusUpdateId: lastReply?.id!, fileIds: uploadedDocumentIds });
    }
  };

  useEffect(() => {
    if (applicationReplies) {
      setLastReply(applicationReplies[applicationReplies?.length - 1]);
    }
    if (postReplyStatus === 'success' && postReplyData) {
      setLastReply(postReplyData);
    }
  }, [applicationReplies, postReplyStatus]);

  if (!lastReply) {
    return <></>;
  }

  return (
    <StyledProgramDataCard>
      <Box mb={3} pb={1} borderBottom={`2px solid ${Colors.Typography.Gray_10}`} display='flex' justifyContent='space-between'>
        <Box>
          <UniTypography color='primary100' weight='medium' variant='header3'>
            University Reply
          </UniTypography>
          <UniTypography color='typographyGray100'>{`On ${moment(lastReply.createdAt).format('Do MMMM YYYY')}`}</UniTypography>
          <Box mt={2}>
            <UniTypography color='typographyGray70'>
              Here you will find the response from university regarding your application
            </UniTypography>
          </Box>
        </Box>
        {showUploadDocs && (
          <UniButton fullWidth={false} onClick={handleSubmit} loading={postReplyStatus === 'loading'}>
            Update application
          </UniButton>
        )}
      </Box>
      <Box mb={2}>
        <UniTypography weight='medium'>Application Status</UniTypography>
      </Box>
      <UniStatus status={lastReply.status} />
      {!!lastReply.files?.length && (
        <Box mb={2} mt={2}>
          <UniTypography weight='medium'>Attachment</UniTypography>
        </Box>
      )}
      {lastReply.files?.map(file => (
        <Box display='flex' alignItems='center' mb={2}>
          <UniIconWrapper color='primary100' size='medium'>
            <ApplicationIcon />
          </UniIconWrapper>
          <Box ml={1}>
            <UniFileViewer file={file} label={`${file.originalName.substr(0, 20)}${file.originalName.length > 19 && '...'}`} />
          </Box>
        </Box>
      ))}
      {lastReply.message && (
        <Box mb={4} mt={2}>
          <UniTypography weight='medium'>Message</UniTypography>
          <Box mt={2}>
            <UniTypography color='typographyGray100'>{lastReply.message}</UniTypography>
          </Box>
        </Box>
      )}
      {!!lastReply?.reply && (
        <Box mt={2}>
          <UniTypography weight='medium' color='secondary100'>
            Reply was sent successfully!
          </UniTypography>
        </Box>
      )}
      {showUploadDocs && (
        <Box borderTop={`2px solid ${Colors.Typography.Gray_10}`}>
          <RequiredDocument
            multiple
            handleSaveFiles={() => {}}
            handleUploadDocuments={files => setDocumentsToUpload(files)}
            handleUploadedDocuments={ids => setUploadedDocumentIds(ids)}
            studentProfile={studentProfile}
          />
        </Box>
      )}
    </StyledProgramDataCard>
  );
}
