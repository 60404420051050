import styled from 'styled-components';

export const StyledContinentTileDescription = styled.div<{}>`
  color: white;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    text-align: center;
  }

  h1 {
    display: block;
    box-sizing: border-box;
    margin-block-start: .83em;
    margin-block-end: .83em;
    margin-top: 0;
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
  }

  div.continent-description {
    display: block;
    box-sizing: border-box;
    width: auto;
    margin-bottom: 0;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -.01em;
  }
`;
