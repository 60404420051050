import React, { ReactElement } from 'react';
import { UniIconWrapper, UniTypography } from '../..';
import { StyledLink } from './HomepageFooter.style';
import { ReactComponent as RightIcon } from '../../../assets/svg/Icons/Arrow/Right.svg';

interface Props {
  link: string;
  linkLabel?: string;
}

export function FooterLink({ link, linkLabel }: Props): ReactElement {
  return (
    <StyledLink href={link} rel='noopener noreferrer'>
      <UniIconWrapper color='primary70'>
        <RightIcon />
      </UniIconWrapper>
      <UniTypography color='white100' weight='regular' variant='subheader'>
        {linkLabel}
      </UniTypography>
    </StyledLink>
  );
}
