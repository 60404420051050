import { FormControl, FormControlProps, TextField } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../constants';
import UniIconWrapper from '../../_common/uniIconWrapper/UniIconWrapper';

interface StyledFormControlProps {
  hasLabel: boolean;
  backgroundColor?: any;
}

export const Container = styled(
  ({ hasLabel, backgroundColor, ...rest }: StyledFormControlProps & Omit<FormControlProps, keyof StyledFormControlProps>) => (
    <FormControl {...rest} />
  ),
)`
  display: grid;
  justify-items: start;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  min-height: ${({ hasLabel }) => (hasLabel ? '103px' : '82px')};
  grid-template-rows: ${({ hasLabel }) => (hasLabel ? '21px 1fr' : '56px 18px')};
  ${({ hasLabel }) => !hasLabel && `padding-top: 25px;`}
  .MuiInputBase-root {
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'white')};
  }
`;

export const StyledTextField = styled(TextField)<{
  backgroundColor: string;
}>`
  height: ${({ theme }) => theme.spacing(7)}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  /*DISABLE SCROLLBAR*/
  padding-left: ${({ theme }) => theme.spacing(1)}px;
  .MuiInputBase-root {
    position: relative;
    flex-wrap: wrap;
    background-color: transparent;
    padding: 0;
    max-width: 100%;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .MuiChip-root {
    background-color: ${Colors.Typography.Gray_100}10;

    .MuiChip-label {
      color: ${Colors.Typography.Gray_70};
    }
  }
  span.MuiAutocomplete-tag {
    background-color: ${Colors.Typography.Gray_100}10;
    border-radius: 16px;
    color: ${Colors.Typography.Gray_70};
    height: ${({ theme }) => theme.spacing(4)}px;
    padding: ${({ theme }) => theme.spacing(0, 1)};

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    padding-right: ${({ theme }) => theme.spacing(3)}px;
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  max-height: 18px;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const Label = styled.div`
  max-height: 21px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const StyledAutocompleteTagsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  height: 100%;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledUniIconWrapper = styled(UniIconWrapper)`
  height: 13px;
`;
