import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../constants';

export const StyledProgramListItem = styled.div`
  background-color: ${Colors.White._100};
  padding: ${({ theme }) => theme.spacing(2, 0)};
  border-radius: ${Shape.BorderRadius.md}px;
  box-shadow: ${Shadows.shadow2};
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  cursor: pointer;
  transition: 0.2s ease-out box-shadow;
  &:hover {
    box-shadow: 0px 10px 15px 5px rgba(25, 25, 25, 0.05);
  }
`;

export const StyledProgramsList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledProgramListHeader = styled.div<{ stretch: boolean }>`
  padding: ${({ theme }) => theme.spacing(0, 4)};
  display: grid;
  grid-template-columns: ${({ stretch }) => (!stretch ? '2fr repeat(2, 1fr) 1.5fr 1fr' : '2fr repeat(2, 1fr) 2fr')};
  column-gap: ${({ theme }) => theme.spacing(4)}px;

  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`;

export const StyledProgramListItemHeader = styled.div<{ stretch: boolean }>`
  padding: ${({ theme }) => theme.spacing(0, 4)};
  display: grid;

  grid-template-columns: ${({ stretch }) => (!stretch ? '2fr repeat(2, 1fr) 1fr 1.5fr' : '2fr repeat(2, 1fr) 2fr')};
  column-gap: ${({ theme }) => theme.spacing(4)}px;

  @media (max-width: 1000px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const StyledProgramListItemBody = styled.div`
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing(0, 4)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0, 3)};

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    /* justify-content: center; */
  }
`;
export const StyledProgramListItemBodyGrid = styled.div<{ stretch: boolean }>`
  padding: ${({ theme }) => theme.spacing(0, 4)};
  display: grid;

  grid-template-columns: ${({ stretch }) => (!stretch ? '2fr repeat(2, 1fr) 2.7fr' : '2fr repeat(2, 1fr) 2fr')};
  column-gap: ${({ theme }) => theme.spacing(2)}px;

  @media (max-width: 1000px) {
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing(4)}px;
  }
`;

export const StyledProgramMetaItem = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledProgramMetaItemContent = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledProgramListItemDivider = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${Colors.Gray1._100};
  margin: ${({ theme }) => theme.spacing(1, 0, 2, 0)};
`;

export const StyledSubmissionDatesHeader = styled.div`
  background-color: ${Colors.Gray1._100};

  padding: ${({ theme }) => theme.spacing(1, 4)};
  display: grid;

  grid-template-columns: 1.5fr repeat(2, 2fr);
  column-gap: ${({ theme }) => theme.spacing(4)}px;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;
export const StyledSubmissionDatesBody = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 4)};
  display: grid;

  grid-template-columns: 1.5fr repeat(2, 2fr);
  column-gap: ${({ theme }) => theme.spacing(4)}px;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDataCard = styled.div`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  box-shadow: ${Shadows.shadow2};
  padding: ${({ theme }) => theme.spacing(3, 4)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const SelectedProgramsWrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 30px;
  left: 50%;

  transform: translateX(-50%);
  z-index: 999;

  background-color: ${Colors.Primary._100};
  padding: ${({ theme }) => theme.spacing(1.5, 3)};
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;

  white-space: nowrap;
  max-width: 415px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)}px;

  transition: 0.2s;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  & > * {
    flex: 1;
  }
`;
