import React from 'react';
import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { StyledPackagesListItem } from './PackagesListItem.style';
import { UniTypography } from '../index';

type PrepPackDto = Components.Schemas.PrepPackDto;

interface Props {
  prepPack: PrepPackDto;
}

export const UniAdminPrepCenterPackagesListItem: React.FC<Props> = ({ prepPack }) => {
  return (
    <StyledPackagesListItem>
      <Box />
      <UniTypography variant='subheader'>{prepPack.product.name}</UniTypography>
      <UniTypography variant='subheader'>{prepPack.sessions}</UniTypography>
      <UniTypography variant='subheader'>
        {prepPack.price.amount} {prepPack.price.currencyIso}
      </UniTypography>
      <UniTypography variant='subheader'>
        {prepPack.fullPrice?.amount} {prepPack.fullPrice?.currencyIso}
      </UniTypography>
    </StyledPackagesListItem>
  );
};

export default UniAdminPrepCenterPackagesListItem;
