import { AxiosError } from 'axios';
import { CacheKeys } from '../../constants';
import { queryCache, useMutation } from 'react-query';
import { Components } from '../../client/UniClient';

import { useAuth, useClient } from '../../store';

type Payload = Components.Schemas.UpdateStudentRequest & { studentId: string };

const usePatchCandidate = () => {
  const { profile } = useAuth();
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AgencyApi_updateStudent(
        {
          agencyId: profile.agentProfile?.agency.id!,
          studentId: payload.studentId,
        },
        {
          ...payload,
        },
      );
    },
    {
      onSuccess: async response => {
        queryCache.invalidateQueries(CacheKeys.Agency.Candidate);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         }); 
         */
      },
    },
  );
};
export default usePatchCandidate;
