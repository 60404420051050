import styled from 'styled-components'

export const EmptyStateWrapper = styled.div<{isMobile:boolean, variant: 'M' | 'L';}>`
${({isMobile, theme, variant}) => isMobile ? 
`
    display:grid;
    margin: ${theme.spacing(2)}px;
    justify-content:center;
    text-align:center;`
    : 
    `
    display:flex;
    margin: ${ variant==='L' ?theme.spacing(4) : theme.spacing(0)}px;`
}`

export const EmptyIllustration = styled.div<{isMobile:boolean, variant: 'M' | 'L'}>`
${({isMobile, theme, variant})=> isMobile ? 
    `
    height: ${theme.spacing(6)}px; 
    width: ${theme.spacing(6)}px; 
    margin: 0 auto ${theme.spacing(3)}px auto;
    `
:
    `
    height: ${variant==='L' ? theme.spacing(12.5) : theme.spacing(7)}px; 
    width: ${variant==='L' ? theme.spacing(12.5) : theme.spacing(7)}px; 
    margin-right: ${theme.spacing(2)}px;
    `
}
`
