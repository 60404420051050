import { Box } from '@material-ui/core';
import { UniAvatar, UniButton, UniIconWrapper, UniTextField } from 'components';
import { Controller, useFieldArray } from 'react-hook-form';
import mediaPlaceholder from '../../../../assets/images/media-placeholder.png';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/Icons/Close.svg';
import { Components } from '../../../../client/UniClient';

interface Props {
  register: any;
  control: any;
  errors: any;
}

const Step2: React.FC<Props> = ({ control, register, errors }) => {
  const { fields, append, remove } = useFieldArray({
    name: 'speakers',
    control,
  });

  const onNewLink = () => append({ name: '', description: '' });

  const getPreviewUrl = (file?: File | Components.Schemas.FileDto) => {
    const uploadedFile = file as Components.Schemas.FileDto;
    if (!file) return mediaPlaceholder;
    if (uploadedFile?.location) return uploadedFile?.location;
    return URL.createObjectURL(file as File);
  };

  return (
    <>
      {fields.map((field, index) => {
        return (
          <Box key={`${field.id}-${index}`}>
            <Box display='flex' justifyContent='center'>
              <Controller
                control={control}
                name={`speakers[${index}].imageFile`}
                render={({ value, onChange }) => (
                  <UniAvatar
                    online={false}
                    variant='rounded'
                    size='XL'
                    src={getPreviewUrl(value)}
                    onUpload={(file: any) => onChange(file)}
                  />
                )}
              />
            </Box>
            <UniTextField
              fullWidth
              label='Speaker name'
              placeholder='Enter your speaker name'
              defaultValue={field.name}
              name={`speakers[${index}].name`}
              inputRef={register({
                required: 'Field must not be empty',
              })}
              error={!!errors?.speakers?.[index]?.name?.message}
              helperText={errors?.speakers?.[index]?.name?.message}
              endAdornment={
                <UniIconWrapper onClick={() => remove(index)}>
                  <CloseIcon />
                </UniIconWrapper>
              }
            />
            <UniTextField
              fullWidth
              label='Speaker description'
              placeholder='Enter your speaker description'
              defaultValue={field.description}
              name={`speakers[${index}].description`}
              inputRef={register({
                required: 'Field must not be empty',
              })}
              error={!!errors?.speakers?.[index]?.description?.message}
              helperText={errors?.speakers?.[index]?.description?.message}
            />
          </Box>
        );
      })}
      <UniButton size='medium' variant='text' color='primary' onClick={onNewLink}>
        Add speaker
      </UniButton>
    </>
  );
};

export default Step2;
