import { AxiosResponse } from 'axios';
import { Components, Paths } from 'client/UniClient';
import { MutationConfig, useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Payload extends Paths.ServiceOptionApiAddServiceOffering.PathParameters {
  body: Components.Schemas.RequestUpdateServiceOptionRequest;
}

export default function usePatchServiceOptionsRequests(config?: MutationConfig<AxiosResponse<Components.Schemas.ServiceOptionRequestDto>>) {
  const { client } = useClient();
  const queryCache = useQueryCache();

  return useMutation(
    async ({ body, serviceOptionId }: Payload) => client!.ServiceOptionApi_updateServiceOptionRequest(serviceOptionId, body),
    // .then(() => client!.ServiceOptionApi_approveServiceOptionRequest({ serviceOptionRequestId: serviceOptionId })),
    {
      ...config,
      onSuccess() {
        queryCache.invalidateQueries(CacheKeys.ServiceOptions.Requests);
      },
    },
  );
}
