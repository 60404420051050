import LayoutRoute from 'components/_layouts/layoutRoute/LayoutRoute';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { BrowserRoutes } from '../constants';

const HighSchoolHome = lazy(() => import('views/_highSchool/home/Home'));
const HighSchoolStudents = lazy(() => import('views/_highSchool/students/Students'));
const HighSchoolProfile = lazy(() => import('views/_highSchool/profile/Profile'));
const HighSchoolStudentProfile = lazy(() => import('views/_highSchool/studentProfile/StudentProfile'));

const HighSchoolRoutes = () => {
  return [
    // <LayoutRoute exact path={BrowserRoutes.HighSchool.Default} key={BrowserRoutes.HighSchool.Default} component={HighSchoolHome} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.HighSchool.Students.Default}
      key={BrowserRoutes.HighSchool.Students.Default}
      component={HighSchoolStudents}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.HighSchool.Student.Default}
      key={BrowserRoutes.HighSchool.Student.Default}
      component={HighSchoolStudentProfile}
    />,
    <LayoutRoute exact path={BrowserRoutes.HighSchool.Profile} key={BrowserRoutes.HighSchool.Profile} component={HighSchoolProfile} />,
    <Redirect to={BrowserRoutes.HighSchool.Students.All} />,
    <LayoutRoute exact path={BrowserRoutes.HighSchool.Profile} key={BrowserRoutes.HighSchool.Profile} component={HighSchoolProfile} />,
    // <LayoutRoute exact path={BrowserRoutes.HighSchool.Tutorials} key={BrowserRoutes.HighSchool.Tutorials} component={VideoTutorials} />,
  ];
};

export default HighSchoolRoutes;
