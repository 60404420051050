import { Components } from 'client/UniClient';
import { queryCache, useMutation } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

interface Params {
  serviceOrderId: string;
  payload: Components.Schemas.DeleteServiceOrderItemRequest;
}

const useDeleteServiceOrderItem = () => {
  const { client } = useClient();

  return useMutation(
    async ({ serviceOrderId, payload }: Params) => {
      const serviceOrderResponse = await client?.ServiceOrderApi_removeServiceOrderItem({ serviceOrderId }, payload);

      return serviceOrderResponse;
    },
    {
      onSuccess() {
        queryCache.invalidateQueries(CacheKeys.Student.Orders);
      },
    },
  );
};

export default useDeleteServiceOrderItem;
