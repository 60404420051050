import { Box } from '@material-ui/core';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { default as React, ReactElement, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePostCandidateMultiple } from '../../../api';
import multipleStudentsCsv from '../../../assets/docs/multipleCandidates.csv';
import { ReactComponent as CopyIcon } from '../../../assets/svg/Icons/Copy.svg';
import { UniDragNDrop, UniIconWrapper, UniTextField, UniTypography } from '../../../components';
import { currencyIso } from '../../../constants';
import { useErrorHandler } from '../../../hooks';
import { useAuth } from '../../../store';
import { CurrencyIso } from '../../../types';

interface Props {
  handleClose: Function;
  handleSuccess: Function;
}

interface Inputs {
  feeAmount: number;
  feeCurrency: CurrencyIso;
  feeType: 'UPFRONT' | 'FIFTY_FIFTY' | 'AFTER';
}

export default function InviteCandidateMultiple({ handleClose, handleSuccess }: Props): ReactElement {
  const { t } = useTranslation('addCandidatePage');
  const { profile, getAgentInviteLink } = useAuth();
  const [uploadCsv, { status: uploadCsvStatus, error: uploadCsvError }] = usePostCandidateMultiple();
  const postError = useErrorHandler(uploadCsvError!);

  const [csvFiles, setCsvFiles] = useState<File[]>([]);
  const copyRef = useRef<HTMLInputElement>(null);

  const copyToClipboard = () => {
    copyRef!.current!.select();
    document.execCommand('copy');
  };

  const onSubmit = (formData: Inputs) => {
    const formDataReq = new FormData();

    if (csvFiles?.length > 0) {
      formDataReq.append('amount', '0');
      formDataReq.append('currencyIso', 'EUR');
      formDataReq.append('feeType', formData.feeType || 'AFTER');
      formDataReq.append('file', csvFiles[0], 'candiadtes.csv');
      uploadCsv(formDataReq, {
        onSuccess(response) {
          handleSuccess(response);
        },
      });
    }
  };

  const { register, setValue, control, errors, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      feeAmount: profile.agentProfile?.fee.amount,
      feeType: profile.agentProfile?.feeType,
      feeCurrency: currencyIso[0].value as CurrencyIso,
    },
  });

  const feeAmountError = errors?.feeAmount?.message ?? '';

  return (
    <>
      <UniModalDrawerContent>
        <Box width='100%' marginTop='10px' mb={2}>
          <UniTypography variant='header3' weight='semi-bold'>
            {t('labels.multiple.link_subtitle')}
          </UniTypography>
          <UniTypography variant='subheader' weight='medium' color='typographyGray30'>
            {t('labels.multiple.link_description')}
          </UniTypography>
        </Box>
        <Box mb={2}>
          <UniTextField
            name='firstName'
            value={getAgentInviteLink && getAgentInviteLink(profile)}
            fullWidth
            readOnly={true}
            variant='subheader'
            inputRef={copyRef}
            adornmentColor='primary100'
            endAdornment={
              <UniIconWrapper
                color='primary100'
                onClick={() => {
                  copyToClipboard();
                }}
              >
                <CopyIcon />
              </UniIconWrapper>
            }
          />
        </Box>
        <Box>
          <UniDragNDrop
            label={t('labels.multiple.title')}
            handleChange={(files: any) => setCsvFiles(files)}
            variant='subheader'
            accept='text/csv'
            color='typographyGray30'
          >
            <Box display='flex' justifyContent='center'>
              <UniTypography color='typographyGray100'>
                Drag & Drop or{' '}
                <span style={{ color: '#D12E71' }}>
                  Upload <b>CSV</b> file
                </span>
              </UniTypography>
            </Box>
          </UniDragNDrop>
          <Box display='flex' flexDirection='column'>
            {uploadCsvError && <UniTypography color='stateAlert100'>{uploadCsvError.response?.data.message}</UniTypography>}
            <UniTypography color='typographyGray30' variant='subheader'>
              {t('labels.multiple.description')}
            </UniTypography>
            <Box
              style={{ cursor: 'pointer' }}
              mb={8}
              onClick={() => {
                window.open(multipleStudentsCsv, '_blank');
              }}
            >
              <UniTypography color='primary100' weight='medium' variant='subheader'>
                {t('buttons.download_template')}
              </UniTypography>
            </Box>
          </Box>
        </Box>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: handleClose, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: t('buttons.send_invitation'),
        }}
      />
    </>
  );
}
