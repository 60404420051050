import { Box } from '@material-ui/core';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useResendAgencyInvite } from '../../../api';
import { usePostAgency } from '../../../api/';
import { Components } from '../../../client/UniClient';
import { UniTextField, UniTypography } from '../../../components';
import { useErrorHandler } from '../../../hooks';
import { FormSection } from './AdminCreateAgency.style';
import AdminCreateAgencySuccess from './AdminCreateAgencySuccess';

interface AgencyCreationProps {
  close: Function;
  agencyForResend?: Components.Schemas.AgencyDto;
}

interface Inputs {
  name: string;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  agentCode: string;
  phone: string;
  phonePrefix: string;
}

export default function AdminCreateAgency({
  // onClose,
  close,
  agencyForResend,
}: AgencyCreationProps): ReactElement {
  const { t } = useTranslation('addAgencyPage');
  const [invitationSent, setInvitationSent] = useState(false);
  const [invitationResent, setInvitationResent] = useState(false);
  const { handleSubmit, register, setValue, errors } = useForm<Inputs>();

  const [addAgency, { isLoading, status, error: postAgencyError }] = usePostAgency();

  const postError = useErrorHandler(postAgencyError!);
  const [
    resendInvite,
    { data: resendResponse, isLoading: isLoadingResendInvite, status: statusResendInvite, error: postAgencyErrorResendInvite },
  ] = useResendAgencyInvite();
  const onSubmit = (payload: Inputs) => {
    if (agencyForResend != undefined) {
      resendInvite(agencyForResend?.id, {
        onSuccess() {
          setInvitationResent(true);
        },
      });
    } else {
      const agencyData = {
        name: payload.name,
        admin: {
          email: payload.email.trim(),
          username: payload.username,
          password: payload.password,
          phone: payload.phone,
          phonePrefix: payload.phonePrefix,
          firstName: payload.firstName,
          lastName: payload.lastName,
          agentProfile: {
            agentCode: payload.agentCode,
          },
        },
      };
      addAgency(agencyData, {
        onSuccess() {
          setInvitationSent(true);
        },
      });
    }
  };

  const nameError = errors?.name?.message ?? '';
  const firstNameError = errors?.firstName?.message ?? '';
  const lastNameError = errors?.lastName?.message ?? '';
  const emailError = errors?.email?.message ?? '';
  useEffect(() => {
    if (agencyForResend != undefined) {
      setValue('name', agencyForResend?.name || '');
      setValue('email', agencyForResend.admin?.email || '');
      setValue('firstName', agencyForResend.admin?.firstName || '');
      setValue('lastName', agencyForResend.admin?.lastName || '');
    } else {
      setValue('name', '');
      setValue('email', '');
      setValue('firstName', '');
      setValue('lastName', '');
    }
  }, [agencyForResend]);
  return invitationSent || invitationResent ? (
    <UniModalDrawerContent>
      <AdminCreateAgencySuccess isVisible={invitationSent || invitationResent} invitationResent={invitationResent} handleClose={close} />
    </UniModalDrawerContent>
  ) : (
    <>
      <UniModalDrawerContent>
        <Box width='100%' marginBottom='20px'>
          <UniTypography variant='header3' weight='semi-bold'>
            {t('labels.basic_info.title')}
          </UniTypography>
        </Box>
        <FormSection>
          <UniTextField
            helperText={nameError}
            error={!!nameError}
            readOnly={agencyForResend != undefined}
            name='name'
            label={t('labels.basic_info.hints.agency_name')}
            inputRef={register({
              required: 'Partner name is required',
            })}
            fullWidth
          />
          <UniTextField
            helperText={emailError}
            error={!!emailError}
            readOnly={agencyForResend != undefined}
            name='email'
            label={t('labels.basic_info.hints.admin_email')}
            inputRef={register({
              required: "Admin's email is required",
            })}
            fullWidth
          />
        </FormSection>
        <FormSection>
          <UniTextField
            helperText={firstNameError}
            error={!!firstNameError}
            readOnly={agencyForResend != undefined}
            name='firstName'
            label={t('labels.basic_info.hints.admin_first_name')}
            inputRef={register({
              required: "Agency admin's first name is required",
            })}
            fullWidth
          />
          <UniTextField
            helperText={lastNameError}
            error={!!lastNameError}
            readOnly={agencyForResend != undefined}
            name='lastName'
            label={t('labels.basic_info.hints.admin_last_name')}
            inputRef={register({
              required: "Agency admin's last name is required",
            })}
            fullWidth
          />
        </FormSection>
        <Box>
          {(status === 'success' || statusResendInvite === 'success') && (
            <UniTypography color='secondary100'>
              {agencyForResend != undefined ? t('labels.response_resend') : t('labels.response')}
            </UniTypography>
          )}
        </Box>
        {postError.message && (
          <Box>
            <UniTypography color='stateAlert100'>{postError.message}</UniTypography>
          </Box>
        )}
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: close, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: agencyForResend != undefined ? t('buttons.resend_invitation') : t('buttons.create_agency'),
        }}
      />
    </>
  );
}
