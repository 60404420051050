import { Box } from '@material-ui/core';
import { UniButton, UniDivider, UniIconWrapper, UniPageTitle } from 'components';
import { GuidingState } from 'components/emptyState/GuidingState';
import { useBreakpoints } from 'hooks';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import { ReactComponent as AddIcon } from '../../assets/svg/Icons/Add.svg';
import { Components } from '../../client/UniClient';
import { StyledContainer } from './StudentDocuments.style';
import StudentDocumentsListItem from './StudentDocumentsListItem';

interface Props {
  candidate?: Partial<Components.Schemas.UserDto>;
  documents?: Components.Schemas.FileDto[];
  editable?: boolean;
}

export default function StudentDocuments({ documents, candidate, editable }: Props): ReactElement {
  const { t } = useTranslation('studentDocuments');
  const mergedDocuments = useMemo(() => {
    if (documents) {
      return documents;
    } else {
      return candidate?.studentProfile?.documents;
    }
  }, [documents, candidate?.studentProfile?.documents]);
  const { isMobile } = useBreakpoints();
  const { openDrawer } = useDrawers();
  const onAddDoc = () => openDrawer('add-document', { size: 'small', side: 'right' });
  const onDeleteDoc = (document: Components.Schemas.FileDto) => openDrawer('delete-document', { size: 'small', document: document });

  return (
    <StyledContainer isMobile={isMobile}>
      <UniPageTitle size={isMobile ? 'S' : 'M'} title={t('title')}></UniPageTitle>
      {mergedDocuments?.length === 0 && (
        <Box mt={isMobile ? 2 : 3}>
          <GuidingState type='documents' />
        </Box>
      )}
      {editable && (
        <Box width='100%' justifyContent={isMobile ? 'center' : 'flex-start'} display='flex' marginTop='24px'>
          <UniButton
            size={isMobile ? 'medium' : 'large'}
            fullWidth={false}
            onClick={onAddDoc}
            startIcon={
              <UniIconWrapper color='white100'>
                <AddIcon />
              </UniIconWrapper>
            }
          >
            {t('buttons.add_new_doc')}
          </UniButton>
        </Box>
      )}
      <Box mt={isMobile ? 3.375 : 4.375} mb={isMobile ? 3.5 : 4.5} width='100%'>
        <UniDivider />
      </Box>
      {mergedDocuments?.map((document, index) => (
        <StudentDocumentsListItem
          document={document}
          index={index + 1}
          isMobile={isMobile}
          onDelete={() => {
            onDeleteDoc(document);
          }}
        />
      ))}
    </StyledContainer>
  );
}
