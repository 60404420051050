import { MaxWidth } from "./UniModal";

import styled from "styled-components";

type ModalContainerProps = {
  maxWidth: MaxWidth;
};

export const ModalContainer = styled.section<ModalContainerProps>`
  word-wrap: break-word;
  padding: ${({ theme, maxWidth }) => {
    if (maxWidth === "xs" || maxWidth === "sm") return theme.spacing(1.5);
    return theme.spacing(3);
  }}px;
`;

export const Close = styled.div`
  margin: ${({ theme }) => theme.spacing(1)}px;
  float: right;
`;

export const Footer = styled.footer`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`;
