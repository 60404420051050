import { Box } from '@material-ui/core';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import React, { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePostAgent, useResendAgentInvite } from '../../../api';
import { Components } from '../../../client/UniClient';
import { useErrorHandler } from '../../../hooks';
import { CreateAgentRequest } from '../../../types';
import { validateEmail, validatePhone } from '../../../utils';
import UniTypography from '../../_common/uniTypography/UniTypography';
import UniPhonePrefix from '../../_inputs/uniPhoneInput/uniPhoneInput';
import UniTextField from '../../_inputs/uniTextField/UniTextField';
import { FormSection } from './InviteAgent.style';

type Inputs = CreateAgentRequest;
interface AgentWithStarred extends Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse> {
  starred?: boolean;
}
interface Props {
  handleClose: Function;
  handleSuccess: Function;
  handleResendSuccess?: Function;
  agentForResend?: AgentWithStarred & Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse>;
}

export default function InviteAgentSingle({ handleClose, handleSuccess, handleResendSuccess, agentForResend }: Props): ReactElement {
  const [resendInvite, { data: resendResponse, isLoading: isLoadingResend, status, error: postAgentError }] = useResendAgentInvite();
  const { t } = useTranslation('addAgentPage');
  const { handleSubmit, register, errors, reset, setValue, control } = useForm<Inputs>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      phonePrefix: '',
    },
  });

  const [addAgent, { isLoading, error: addAgentError }] = usePostAgent();
  const postError = useErrorHandler(addAgentError!);

  const onSubmit = (payload: Inputs) => {
    if (agentForResend != undefined) {
      resendInvite(
        { agencyId: agentForResend.agentProfile?.agency.id || '', agentId: agentForResend.id || '' },
        {
          onSuccess(response) {
            if (handleResendSuccess != undefined) handleResendSuccess();
          },
        },
      );
    } else {
      addAgent(payload, {
        onSuccess(response) {
          handleSuccess(response.data.id);
        },
        onSettled() {
          reset();
          // onClose();
        },
      });
    }
  };

  const firstNameError = errors?.firstName?.message ?? '';
  const lastNameError = errors?.lastName?.message ?? '';
  const emailError = errors?.email?.message ?? '';
  const phoneError = errors?.phone?.message ?? '';

  useEffect(() => {
    if (agentForResend != undefined) {
      setValue('firstName', agentForResend.firstName);
      setValue('lastName', agentForResend.lastName);
      setValue('email', agentForResend.email);
      setValue('phone', agentForResend.phone);
    } else {
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('email', '');
      setValue('phone', '');
    }
  }, [agentForResend]);

  return (
    <>
      <UniModalDrawerContent>
        <Box width='100%' marginBottom='20px'>
          <UniTypography variant='header3' weight='semi-bold'>
            {t('labels.one.agent_basic_information.title')}
          </UniTypography>
        </Box>
        <FormSection>
          <UniTextField
            helperText={firstNameError}
            error={!!firstNameError}
            name='firstName'
            readOnly={agentForResend != undefined}
            placeholder={t('labels.one.agent_basic_information.first_name.hint')}
            inputRef={register({
              required: 'First name is required',
            })}
            fullWidth
            label='Agent first name'
            labelColor='typographyGray30'
            variant='subheader'
          />
          <UniTextField
            helperText={lastNameError}
            error={!!lastNameError}
            name='lastName'
            readOnly={agentForResend != undefined}
            inputRef={register({
              required: 'Last name is required',
            })}
            fullWidth
            label='Agent last name'
            labelColor='typographyGray30'
            variant='subheader'
          />
        </FormSection>
        <FormSection>
          <UniTextField
            helperText={emailError}
            error={!!emailError}
            name='email'
            readOnly={agentForResend != undefined}
            inputRef={register(validateEmail())}
            fullWidth
            placeholder={t('labels.one.agent_basic_information.email.hint')}
            label='Agent email'
            labelColor='typographyGray30'
            variant='subheader'
          />
        </FormSection>
        <FormSection>
          <UniPhonePrefix
            fixed='medium'
            control={control}
            setValue={setValue}
            helperText={phoneError}
            error={!!phoneError}
            name='phone'
            readOnly={agentForResend != undefined}
            inputRef={
              agentForResend != undefined
                ? register({
                    required: false,
                  })
                : register(validatePhone())
            }
            fullWidth
            type='tel'
            placeholder={t('labels.one.agent_basic_information.phone.hint')}
            label='Agent phone'
            labelColor='typographyGray30'
            variant='subheader'
          />
        </FormSection>
        <FormSection>{postError.message && <UniTypography color='stateAlert100'>{postError.message}</UniTypography>}</FormSection>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: handleClose, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: agentForResend != undefined ? t('buttons.resend_invitation') : t('buttons.send_invitation'),
        }}
      />
    </>
  );
}
