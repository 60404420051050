import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  studentId: string;
};

const useStudentOrders = (params: Params) => {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Student.Orders, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.StudentApi_getStudentServiceOrders(params.studentId);
      return await data;
    },
    {
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
};

export default useStudentOrders;
