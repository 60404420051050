import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { useBreakpoints } from 'hooks';
import React, { ReactElement } from 'react';
import { ReactComponent as CandidateHeaderIllustration } from '../../assets/svg/Illustrations/candidateHeaderIllustration.svg';
import { UniAvatar, UniTypography } from '../../components';
import { UniversityHeader } from './UniversityPage.style';
import UniversityHeaderMeta from './UniversityPageHeaderMeta';

interface Props {
  BackButton: React.ReactNode;
  title?: string;
  isSticky?: boolean;
  changeNavbar?: boolean;
  university: Components.Schemas.UniversityResponse;
}

export default function UniversityPageHeader({
  BackButton,
  title,
  isSticky = false,
  changeNavbar = false,
  university,
}: Props): ReactElement {
  const { isMobile } = useBreakpoints();

  return (
    <UniversityHeader isSticky={isSticky} changeNavbar={changeNavbar}>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' justifyContent='space-between' alignItems='flex-start' flexDirection='column'>
          <Box>{!isMobile && BackButton}</Box>
          <UniTypography variant='header3' weight='medium'>
            {title}
          </UniTypography>
          <Box display='flex' alignItems='center' mt={3}>
            <UniAvatar online={false} size='XL' label={university?.name} />
            <Box ml={3} width='auto' maxWidth='80%'>
              <UniTypography variant='header2' weight='semi-bold' color='primary100'>
                {university?.name}
              </UniTypography>
            </Box>
          </Box>
        </Box>
        {!isMobile && (
          <Box mt={7} mr={20}>
            <CandidateHeaderIllustration />
          </Box>
        )}
      </Box>
      <UniversityHeaderMeta university={university} />
    </UniversityHeader>
  );
}
