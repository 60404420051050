import axios from 'axios';
import CacheKeys from 'constants/cacheKeys';
import { useQuery } from 'react-query';

export interface UseSearchProgramsAiParams {
  keys: string[];
  countries: string[];
}

const baseUrl = 'https://ai-search.younichoice.com/search';

export type TAiProgram = {
  address_city: string;
  address_country: string;
  id: string;
  name: string;
  searchableName: string;
  universityName: string;
};

export interface UseSearchProgramsAiResponse {
  distance: number;
  key: string;
  programs: TAiProgram[];
}

const useSearchProgramsAi = (params: UseSearchProgramsAiParams) => {
  return useQuery([CacheKeys.Search.ProgramsAI, params], async () => {
    const { countries, keys } = params;

    let countryQuery = '';
    let searchQuery = '';
    if (countries.length) {
      countryQuery = countries.map(item => `country=${item}`).join('&');
    }
    searchQuery = `query=${keys.join(',')}`;

    let URLParams = '';
    if (countries && keys) URLParams = `${searchQuery}&${countryQuery}`;
    else URLParams = `${searchQuery}${countryQuery}`;

    const programsResponse = await axios.get(`${baseUrl}?${URLParams}`);

    return programsResponse.data as UseSearchProgramsAiResponse[];
  });
};

export default useSearchProgramsAi;
