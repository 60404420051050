import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniCard, UniLogoWithTitle, UniPageLoader, UniPageTitle } from 'components';
import UniProgressBar from 'components/_common/uniProgressBar/UniProgressBar';
import { motion } from 'framer-motion';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useNotOnboardedUser from '../../../api/auth/useNotOnboardedUser';
import { BrowserRoutes } from '../../../constants';
import Step1 from './_steps/Step1';
import { Step2FormData } from './_steps/Step2';
import Step5 from './_steps/Step5';

export type OnboardForm = Partial<
  Components.Schemas.CompleteHighSchoolOnboardingRequest & {
    passport: File[];
    passportURL: string;
    email: string;
    hasPassword: boolean;
    newsletter?: boolean;
  }
>;

export type OnboardParams = {
  type: 'social' | 'regular' | 'invite' | 'patch' | 'link';
  token: string;
};

export type OnboardFormState = Step2FormData & { token?: string };

export default function HighSchoolOnboard(): ReactElement {
  const { t } = useTranslation('forms');
  const history = useHistory();
  const [user, setUser] = useState<Components.Schemas.UserResponse>();
  const steps = ['', 'Personal info', 'Contact', 'Summary'];

  const [tabValue, setTabValue] = useState(0);

  const { token } = useParams<OnboardParams>();

  const showProgressSteps = tabValue > 0 && tabValue <= 7;

  const { data: onboardingUser, status: onboardingUserStatus, error } = useNotOnboardedUser({ token });
  const notOnboardedUserErrorCode = error?.response?.data?.message;

  const nextStep = (userData?: Components.Schemas.UserResponse) => {
    if (!userData) return;
    setUser(userData);
    setTabValue(4);
  };

  const setNotOnboarderUser = () => {
    if (!onboardingUser || onboardingUserStatus !== 'success') return;
    setUser(onboardingUser as Components.Schemas.UserResponse);
  };

  useEffect(() => setNotOnboarderUser(), [onboardingUserStatus]);

  if (onboardingUserStatus === 'loading') {
    return <UniPageLoader />;
  }

  if (onboardingUserStatus === 'error' || notOnboardedUserErrorCode === 400) {
    history.push(BrowserRoutes.Default.InvalidToken);
  }

  return (
    <Box mt={3}>
      <UniLogoWithTitle title={t('titles.onboard_title')} />

      <Box maxWidth='680px' mx='auto' mt={10}>
        <UniCard border size='L' overflow='none'>
          {showProgressSteps && (
            <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }}>
              {tabValue && (
                <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }}>
                  <UniPageTitle title={steps[tabValue]} size='M' disableBar />
                </motion.div>
              )}
              <Box mt={1} mb={2}>
                <UniProgressBar size='s' progress={tabValue * 10 + 30} />
              </Box>
            </motion.div>
          )}
          {tabValue === 0 && <Step1 email={user?.email} nextStep={nextStep} />}
          {/* {tabValue === 1 && <Step2 defaultValues={formState} nextStep={nextStep} />}
          {tabValue === 2 && <Step3 formState={formState} prevStep={prevStep} onSave={onSave} showTerms={!isRegular} />}
              */}
          {tabValue === 4 && <Step5 user={user} />}
        </UniCard>
      </Box>
    </Box>
  );
}
