import { useEffect, useMemo } from 'react';
import { useClient, useNotificationsSocket } from 'store';
import useAnalytics from './useAnalytics';
import useTidioChat from './useTidioChat';
const useInitAppData = () => {
  const { isClientLoaded, session } = useClient();
  const { initialize } = useNotificationsSocket();
  const { init: initAnalytics } = useAnalytics();
  const { load } = useTidioChat();

  useEffect(() => {
    initAnalytics();
    load();
  }, []);

  useEffect(() => {
    if (session?.token) {
      initialize(session.token);
    }
  }, [session]);

  const initialized = useMemo(() => {
    return isClientLoaded;
  }, [isClientLoaded, session]);

  return { initialized };
};

export default useInitAppData;
