import React from 'react';
import { Components } from 'client/UniClient';
import UniPrepCenterSessionsHistoryItem from './UniPrepCenterSessionsHistoryItem';
import SessionHistoryHeader from './SessionHistoryHeader';
import { StyledSessionHistory } from './SessionHistory.style';

type PurchasedPrepPackDto = Components.Schemas.PurchasedPrepPackDto;

interface Props {
  purchasedPrepPacks: PurchasedPrepPackDto[];
}

export const UniPrepCenterSessionsHistory: React.FC<Props> = ({ purchasedPrepPacks }) => {
  const sessions = purchasedPrepPacks.flatMap(ppp => ppp.checkinDates.map((date, index) => ({
    date: new Date(date),
    productName: ppp.prepPack.product.name,
    logoUrl: ppp.prepPack.product.logoFile?.location,
    sessionIndex: index + 1,
    sessionCount: ppp.prepPack.sessions,
  })));
  sessions.sort((l, r) => {
    if (l.date > r.date) return -1;
    if (l.date < r.date) return 1;
    return 0;
  });

  return (
    <>
      <SessionHistoryHeader />
      <StyledSessionHistory>
        {sessions.map(session => (
          <UniPrepCenterSessionsHistoryItem
            productName={session.productName}
            date={session.date?.toISOString().split('T')[0]}
            sessionCount={`${session.sessionIndex}/${session.sessionCount}`}
            logoUrl={session.logoUrl}
          />
        ))}
      </StyledSessionHistory>
    </>
  );
};

export default UniPrepCenterSessionsHistory;
