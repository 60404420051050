export const levelOfStudyMap = {
  ASSOCIATE: 'Associate',
  HIGH_SCHOOL: 'High school',
  BACHELOR: 'Bachelor',
  MASTER: 'Master',
  DOCTORATE: 'Doctorate',
  FOUNDATION: 'Foundation',
  DIPLOMA: 'Diploma',
  CERTIFICATE: 'Certificate',
  UNDERGRADUATE: 'Undergraduate',
  POSTGRADUATE: 'Postgraduate',
  VOCATIONAL: 'Vocational',
  OTHER: 'Other',
};

export const attendanceTypeOptions = [
  { value: 'FULL_TIME', label: 'Full Time' },
  { value: 'PART_TIME', label: 'Part Time' },
  { value: 'NO_ATTENDANCE', label: 'No attendance' },
  { value: 'ONLINE', label: 'Online' },
  { value: 'HYBRID', label: 'Hybrid' },
  { value: 'SANDWICH', label: 'Sandwich' },
  { value: 'OTHER', label: 'Other' },
];

export const attendanceTypeMap = {
  FULL_TIME: 'Full Time',
  PART_TIME: 'Part Time',
  NO_ATTENDANCE: 'No attendance',
  ONLINE: 'Online',
  HYBRID: 'Hybrid',
  SANDWICH: 'Sandwich',
  OTHER: 'Other',
};

export const tuitionFeeMap = {
  currency: {},
  unit: {
    HOUR: 'Hour',
    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month',
    TRIMESTER: 'Trimester',
    SEMESTER: 'Semester',
    YEAR: 'Year',
    TOTAL: 'Total',
  },
};

export const disciplinesMap = {
  AGRICULTURE_FORESTRY: 'Agriculture Forestry',
  APPLIED_SCIENCES: 'Applied Sciences',
  ARTS_DESIGN_ARCHITECTURE: 'Arts Design Architecture',
  BUSINESS_MANAGEMENT: 'Business Management',
  COMPUTER_SCIENCE_IT: 'Computer Science It',
  EDUCATION_TRAINING: 'Education Training',
  ENGINEERING_TECHNOLOGY: 'Engineering Technology',
  ENVIRONMENTAL_EARTH_SCIENCES: 'Environmental Earth Scienc',
  HOSPITALITY_LEISURE_SPORTS: 'Hospitality Leisure Spor',
  HUMANITIES: 'Humanities',
  JOURNALISM_MEDIA: 'Journalism Media',
  LAW: 'Law',
  MEDICINE_HEALTH: 'Medicine Health',
  NATURAL_SCIENCES_MATHS: 'Natural Sciences Maths',
  SOCIAL_SCIENCES: 'Social Sciences',
  OTHER: 'Other',
};
