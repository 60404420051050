import useApproveServiceOptionsRequests from 'api/service-options/useApproveServiceOptionsRequest';
import usePatchServiceOptionsRequests from 'api/service-options/usePatchServiceOptionsRequests';
import { Components } from 'client/UniClient';
import { UniModalDrawerButtons } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import EditServiceForm, { EditServiceFormInputs } from 'components/_forms/EditServiceForm';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers, useSnackbar } from 'store';

interface Props {
  service?: Components.Schemas.ServiceOptionRequestDto;
}

export default function EditServiceRequest({ service }: Props): ReactElement {
  const { t: tButtons } = useTranslation('buttons');
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const [patchService, { isLoading: isLoadingPatch }] = usePatchServiceOptionsRequests();
  const [approve] = useApproveServiceOptionsRequests();
  const { register, control, handleSubmit } = useForm<EditServiceFormInputs>({
    defaultValues: {
      name: service?.name,
      description: service?.description,
    },
  });
  const isLoading = isLoadingPatch;

  const onClose = () => closeDrawer();

  const onAddService = () => approve({ serviceOptionRequestId: service?.id || '' });

  const onEdit = (formData: Components.Schemas.RequestServiceOptionRequest) =>
    patchService(
      {
        serviceOptionId: service?.id || '',
        body: formData,
      },
      {
        onSuccess() {
          onAddService();
          openSnackbar('service-added-to-list');
          closeDrawer();
        },
      },
    );

  const onSubmit = () => handleSubmit(onEdit)();

  return (
    <>
      <UniModalDrawerContent>
        <EditServiceForm register={register} control={control} />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: onClose, label: tButtons('cancel') }}
        PrimaryButton={{ onClick: onSubmit, loading: isLoading, label: tButtons('save') }}
      />
    </>
  );
}
