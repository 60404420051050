import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniButton, UniPageTitle } from 'components';
import { StyledFooter } from 'components/_forms/Form.style';
import { useBreakpoints } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'store';
import { BrowserRoutes } from '../../../../constants';
import { StyledOnboardTypography } from '../HighSchoolOnboard.style';

interface Props {
  user?: Components.Schemas.UserResponse;
}

const Step5: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation('forms');
  const { isMobile } = useBreakpoints();
  const { setProfile } = useAuth();
  const { push } = useHistory();

  const onSubmit = () => {
    if (!user) return;
    setProfile(user);
    push(BrowserRoutes.HighSchool.Students.All);
  };

  return (
    <>
      <Box>
        <UniPageTitle title={`${t('details_saved.title')}`} />
        <StyledOnboardTypography variant='body2' color='typographyBlack100'>
          Your details have been saved. You can now start using Youni. Please click the button below and start adding your students!
        </StyledOnboardTypography>
        {!isMobile ? (
          <Box mt={5}>
            <UniButton size='large' fullWidth={false} onClick={onSubmit}>
              Great, let's go!
            </UniButton>
          </Box>
        ) : null}
      </Box>
      {isMobile && (
        <StyledFooter
          variants='M'
          type='basic'
          PrimaryButton={
            <UniButton
              style={{ margin: '16px 16px 16px auto' }}
              title='start'
              size='medium'
              color='primary'
              fullWidth={false}
              onClick={onSubmit}
            >
              Great, let's go!
            </UniButton>
          }
        />
      )}
    </>
  );
};

export default Step5;
