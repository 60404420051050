import { useStudentSuggestedPrograms } from 'api';
import { Components } from 'client/UniClient';
import { ApplicationCard, InfiniteWindowedGrid } from 'components';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAuth } from 'store';
import { BrowserRoutes } from '../../../../constants';

interface Props {}

const SuggestedPrograms: React.FC<Props> = ({ children, ...rest }) => {
  const history = useHistory();
  const { profile } = useAuth();
  const { pathname } = useLocation();
  const studentId = pathname.split('/')[4];
  const {
    data: suggestedPrograms,
    status: suggestedProgramsStatus,
    fetchMore: fetchMoreSuggestedPrograms,
    isFetching,
  } = useStudentSuggestedPrograms({ studentId, agencyId: profile.agentProfile?.agency.id! });

  const suggestedProgramsLoading = suggestedProgramsStatus === 'loading';
  const suggestedProgramsFlat = useMemo(() => {
    return suggestedPrograms?.map(progs => progs.results).flat() || [];
  }, [suggestedPrograms, suggestedProgramsStatus]);
  const suggestedProgramsTotal = useMemo(() => (suggestedPrograms ? suggestedPrograms[0].total : 0), [suggestedPrograms]);
  const suggestedProgramsLeft = useMemo(
    () => suggestedProgramsTotal - suggestedProgramsFlat.length,
    [suggestedProgramsFlat, suggestedProgramsTotal],
  );
  const goToProgramPage = (program: Components.Schemas.ProgramResponse) => {
    if (program?.application?.id) {
      return BrowserRoutes.Agent.Application.General(program.application.id);
    } else {
      return BrowserRoutes.Agent.StudentProgram.General.replace(':programId', program.id!).replace(':studentId', studentId);
    }
  };
  return (
    <InfiniteWindowedGrid
      fetchMore={fetchMoreSuggestedPrograms}
      emptyStateType='suggested'
      isFetching={isFetching}
      isLoading={suggestedProgramsLoading}
      hasMore={suggestedProgramsLeft > 0}
      remainingResults={suggestedProgramsLeft}
      minHeight={250}
      results={suggestedProgramsFlat?.map((program: any, index: any) => (
        <ApplicationCard
          key={`${index}-program`}
          variant='candidateApplicationsPage'
          studentName={`${program.application?.student?.firstName || ''} ${program.application?.student?.lastName || ''}`}
          studentAddress={program.application?.student?.studentProfile.address}
          programName={program.name}
          universityName={program.universityName}
          programAddress={program.address}
          status={program.application?.status}
          path={goToProgramPage(program)}
        />
      ))}
    />
  );
};

export default SuggestedPrograms;
