import { UniButton, UniHeader, UniIconWrapper, UniTypography } from 'components';
import { ReactComponent as PhoneIcon } from 'assets/svg/Icons/PhoneIcon.svg';
import { ReactComponent as PencilrulerIcon } from 'assets/svg/Icons/Pencilruler.svg';
import UniTutorList from 'components/tutorList/UniTutorList';
import { landingPageTutors } from 'constants/tutors';
import { Gradients } from 'constants/uiConfig';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import React from 'react';

interface Props {}

const StyledPrepsWrapper = styled(UniHeader)`
  background: ${Gradients.Primary._45dr};
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-top: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
  box-shadow: none;
  display: flex;
  align-items: center;
`;

const StyledTitleWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.authMaxPageWidth}px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const Preps: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation('landingHome');
  const tutorImages = require.context('assets/landing/tutors', true, /\.(jpg|png)$/);
  const tutorImageArray = Array.from(tutorImages.keys()).map(path => ({
    path,
    image: tutorImages(path).default,
  }));
  return (
    <StyledPrepsWrapper>
      <StyledTitleWrapper id='tutors'>
        <UniTypography variant='header1' color='white100'>
          {t('newtext.section_3.part_1')}
        </UniTypography>
        <UniTypography variant='header1' color='white100' weight='bold'>
          {t('newtext.section_3.part_2')}
        </UniTypography>
      </StyledTitleWrapper>
      <UniTutorList tutorsToDisplay={landingPageTutors} />
      <StyledActionsWrapper>
        <a href='https://meetings.hubspot.com/daniel-posdarascu' style={{ textDecoration: 'none' }}>
          <UniButton
            startIcon={
              <UniIconWrapper size='main' color='white100'>
                <PhoneIcon />
              </UniIconWrapper>
            }
            variant='secondary'
            color='white'
          >
            {t('buttons.call')}
          </UniButton>
        </a>
        <UniButton
          startIcon={
            <UniIconWrapper size='main' color='white100'>
              <PencilrulerIcon />
            </UniIconWrapper>
          }
        >
          {t('buttons.testing')}
        </UniButton>
      </StyledActionsWrapper>
    </StyledPrepsWrapper>
  );
};

export default Preps;
