import { Box } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownArrow } from '../../assets/svg/Icons/Arrow/Down.svg';
import { ReactComponent as UpArrow } from '../../assets/svg/Icons/Arrow/Up2.svg';
import { useAuth } from '../../store';
import { getMetaRole } from '../../utils';
import UniIconWrapper from '../_common/uniIconWrapper/UniIconWrapper';
import UniTypography from '../_common/uniTypography/UniTypography';
import UniCheckbox from '../_inputs/uniCheckbox/UniCheckbox';
import { StyledProgramListHeader } from './ProgramsList.style';

interface Props {
  stretch: boolean;
  hasStatus?: boolean;
  onSort?: (type: number) => void;
  selectAllPrograms?: Function;
  allSelected?: boolean;
}
export default function ProgramsListHeader({ stretch, hasStatus, selectAllPrograms, allSelected, onSort }: Props): ReactElement {
  const { t } = useTranslation('programsList');
  const [sortType, setSortType] = useState(-1);
  const { role } = useAuth(state => state.profile);
  const { isMaster, isUniversity } = getMetaRole(role!);

  return (
    <StyledProgramListHeader stretch={stretch}>
      {onSort ? (
        <Box
          display='flex'
          onClick={() => {
            setSortType(prevSortType => (prevSortType === 1 ? -1 : 1));
            onSort(sortType);
          }}
        >
          {(isMaster || isUniversity) && (
            <Box mt={-1.2}>
              <UniCheckbox
                checked={allSelected}
                onClick={e => e.stopPropagation()}
                onChange={e => {
                  e.stopPropagation();
                  selectAllPrograms && selectAllPrograms(e.target.checked);
                }}
              />
            </Box>
          )}
          <UniTypography color='typographyGray70'>{t('headers.name')}</UniTypography>
          <UniIconWrapper>{sortType === 1 ? <DownArrow /> : <UpArrow />}</UniIconWrapper>
        </Box>
      ) : (
        <UniTypography color='typographyGray70'>{t('headers.name')}</UniTypography>
      )}
      <UniTypography color='typographyGray70'>{t('headers.location')}</UniTypography>
      <UniTypography color='typographyGray70'>{t('headers.level_of_study')}</UniTypography>
      <UniTypography color='typographyGray70'>{hasStatus ? t('headers.status') : t('headers.deadline')}</UniTypography>
      {!stretch && (
        <Box textAlign='center'>
          <UniTypography color='typographyGray70'>{t('headers.actions')}</UniTypography>
        </Box>
      )}
    </StyledProgramListHeader>
  );
}
