import styled from 'styled-components';
import { theme } from 'theme';
import { Colors } from '../../constants';

interface IStyledItemWrapper {
  odd?: boolean;
}

export const StyledItemWrapper = styled.div<IStyledItemWrapper>`
  ${({ odd }) => odd && `background-color: ${Colors.Gray2._40};`}
  display: grid;
  grid-template-columns: 1fr 200px 160px 64px;
  padding: ${theme.spacing(2)}px;
  align-items: center;

  > *:first-child {
    cursor: pointer;
  }
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing(2)}px;
`;
