import { Box } from '@material-ui/core';
import { ReactComponent as EditIcon } from 'assets/svg/Icons/Edit.svg';
import { UniListItem, UniListTitle } from 'components';
import { serviceDomains } from 'constants/options/serviceDomains';
import { studyInterests } from 'constants/options/studyInterests';
import { useBreakpoints } from 'hooks';
import { ReactElement } from 'react';
import { useDrawers } from 'store';
import { Nationality } from 'types';
import { Components } from '../../client/UniClient';
import { countries } from '../../constants';
import { englishExams } from '../../constants/options/englishExams';
import highSchoolYear from '../../constants/options/highschoolYear';
import { universityExams } from '../../constants/options/universityExams';
import { StyledContainer } from './StudentPersonalInfo.style';
import { capitalize } from 'lodash';

interface Props {
  student?: Components.Schemas.UserResponse;
  editable?: boolean;
}

export default function StudentPersonalInfo({ student, editable }: Props): ReactElement {
  const { isMobile } = useBreakpoints();
  const { openDrawer } = useDrawers();

  const EditableIcon = editable ? <EditIcon /> : undefined;

  // const highSchoolYearName = highSchoolYear.find(item => item.value === student?.studentProfile?.highSchoolYear)?.label;
  const countryName = countries.find(country => country.code === student?.studentProfile?.countryOfBirth)?.name;
  const nationalityName = countries.find(country => country.code === student?.studentProfile?.nationality)?.name;
  const secondNationalityName = countries.find(country => country.code === student?.studentProfile?.secondNationality)?.name;
  const studyInterestsArray = [
    ...(student?.studentProfile?.preferences?.studyInterests || []),
    ...(student?.studentProfile?.preferences?.otherStudyInterests || []),
  ];
  const interestsOfStudy = studyInterests
    .filter(item => studyInterestsArray.includes(item.value))
    .map(item => item.label)
    .join(', ');
  const countryInterests = countries
    .filter(item => student?.studentProfile?.preferences?.countries?.includes(item.code as Nationality))
    .map(item => item.name)
    .join(', ');
  const serviceInterestsArray = [
    ...(student?.studentProfile?.preferences?.stagesRequiringAssistance || []),
    ...(student?.studentProfile?.preferences?.otherStagesRequiringAssistance || []),
  ];
  const serviceInterests = serviceDomains
    .filter(item => serviceInterestsArray?.includes(item.value as Nationality))
    .map(item => item.label)
    .join(', ');

  const englishExamsArray = [...(student?.studentProfile?.englishExams || []), ...(student?.studentProfile?.otherEnglishExams || [])];
  const englishExamsString = englishExams
    .filter(item => englishExamsArray?.includes(item.value))
    .map(item => item.label)
    .join(', ');

  const universityExamsArray = [
    ...(student?.studentProfile?.universityExams || []),
    ...(student?.studentProfile?.otherUniversityExams || []),
  ];
  const universityExamsString = universityExams
    .filter(item => universityExamsArray?.includes(item.value))
    .map(item => item.label)
    .join(', ');

  const openPersonalFormDrawer = () => openDrawer('personal-form', { size: 'small', candidate: student });
  const openContactFormDrawer = () => openDrawer('contact-form', { size: 'small', candidate: student });
  const openPreferencesFormDrawer = () => openDrawer('preferences-form', { size: 'small', candidate: student });
  const openAccomplishmentsFormDrawer = () => openDrawer('accomplishments-form', { size: 'small', candidate: student });
  const openGuardianFormDrawer = () => openDrawer('guardian-form', { size: 'small', candidate: student });

  return (
    <StyledContainer isMobile={isMobile}>
      <Box mb={4} width='100%'>
        <Box>
          <UniListTitle weight={'medium'} title='Personal info' onClick={openPersonalFormDrawer} icon={EditableIcon} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='First name' label2={student?.firstName} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Last name' label2={student?.lastName} withBackground />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Gender' label2={capitalize(student?.studentProfile?.gender)} />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Date of birth'
            label2={student?.studentProfile?.dateOfBirth}
            withBackground
          />

          {/* <UniListItem wordBreak autoHeight type='twoLabels' label1='High school year' label2={highSchoolYearName} /> */}
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Country of birth' label2={countryName} withBackground />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Nationality' label2={nationalityName} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Second nationality' label2={secondNationalityName} withBackground />
        </Box>
        <Box>
          <UniListTitle weight={'medium'} title='Contact' onClick={openContactFormDrawer} icon={EditableIcon} />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Address line 1'
            label2={student?.studentProfile?.address?.addressLine1}
          />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Address line 2'
            label2={student?.studentProfile?.address?.addressLine2}
            withBackground
          />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Postcode' label2={student?.studentProfile?.address?.postalCode} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='City' label2={student?.studentProfile?.address?.city} withBackground />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Country'
            label2={countries.find(country => country.code === student?.studentProfile?.address?.country)?.name}
          />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Email' label2={student?.email} withBackground />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Phone number' label2={student?.phonePrefix! + ' ' + student?.phone!} />
        </Box>
        <Box>
          <UniListTitle weight={'medium'} title='Preferences' onClick={openPreferencesFormDrawer} icon={EditableIcon} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Study interests' label2={interestsOfStudy} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Country interests' label2={countryInterests} withBackground />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Particular school'
            label2={student?.studentProfile?.preferences?.interestedSchoolName}
          />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Yearly budget'
            label2={capitalize(student?.studentProfile?.preferences?.yearlyBudget?.replace(/_/g, ' '))}
            withBackground
          />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Need help with:' label2={serviceInterests} />
        </Box>
        <Box>
          <UniListTitle weight={'medium'} title='Accomplishments' onClick={openAccomplishmentsFormDrawer} icon={EditableIcon} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='English exams' label2={englishExamsString} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Other exams' label2={universityExamsString} withBackground />
        </Box>
        <Box>
          <UniListTitle weight={'medium'} title='Guardian details' onClick={openGuardianFormDrawer} icon={EditableIcon} />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Name' label2={student?.studentProfile?.guardian?.name} />
          <UniListItem
            wordBreak
            autoHeight
            type='twoLabels'
            label1='Email'
            label2={student?.studentProfile?.guardian?.email}
            withBackground
          />
          <UniListItem wordBreak autoHeight type='twoLabels' label1='Phone' label2={student?.studentProfile?.guardian?.phone} />
        </Box>
      </Box>
    </StyledContainer>
  );
}
