import useConfirmEmail from 'api/auth/useConfirmEmail';
import { UniPageLoader } from 'components';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { useEffect } from 'react';

interface Params {
  token: string;
}

const ConfirmEmail: React.FC = ({ children, ...rest }) => {
  const [confirmEmail] = useConfirmEmail();
  const { queries } = useQueryStringParser<Params>();
  const token = queries.token;

  useEffect(() => {
    if (token) confirmEmail(token);
  }, [token]);

  return <UniPageLoader />;
};

export default ConfirmEmail;
