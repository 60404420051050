import { Box } from '@material-ui/core';
import { UniAutocomplete, UniModalDrawerButtons, UniTextField } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { countries } from 'constants/countries';
import { GraduationLevel, graduationLevel } from 'constants/levelOfStudy';
import { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Nationality } from 'types';
import { EducationDrawerState } from '../AddEducationDrawer';

export type AddEdItemStep1Inputs = {
  graduationLevel: GraduationLevel;
  country: Nationality;
  city: string;
};

interface Props {
  nextStep: Function;
  drawerState: EducationDrawerState;
  setDrawerState: Function;
  close: Function;
}

export default function AddEducationItemStep1({ nextStep, drawerState, setDrawerState, close }: Props): ReactElement {
  const { t } = useTranslation('addEducationDrawer');
  const { handleSubmit, control, errors } = useForm<AddEdItemStep1Inputs>();
  const onSubmit = (formData: AddEdItemStep1Inputs) => {
    setDrawerState(formData);
    nextStep();
  };

  return (
    <>
      <UniModalDrawerContent>
        <Box mb={0.75}>
          <Controller
            control={control}
            rules={{ required: `${t('errors.study_level')}` }}
            name='graduationLevel'
            defaultValue={graduationLevel.filter(item => item.value === drawerState?.graduationLevel)[0]?.value}
            render={({ onChange, value }) => (
              <UniAutocomplete
                helperText={errors?.graduationLevel?.message}
                error={!!errors?.graduationLevel?.message}
                fullWidth
                width='350px'
                defaultValue={graduationLevel.filter(item => item.value === drawerState?.graduationLevel)[0]}
                handleChange={(event: any, value: any) => onChange(value?.value || '')}
                name='graduationLevel'
                options={graduationLevel}
                getOptionLabel={option => option.label}
                placeholder={t('placeholders.study_level')}
                label={t('step1.study_level')}
              />
            )}
          />
        </Box>
        <Controller
          fullWidth
          control={control}
          name='country'
          defaultValue={countries.filter(item => item.code === drawerState?.country)[0]?.code}
          rules={{ required: `${t('errors.country')}` }}
          render={({ onChange, value }) => (
            <UniAutocomplete
              helperText={errors?.country?.message}
              error={!!errors?.country?.message}
              fullWidth
              defaultValue={countries.filter(item => item.code === drawerState?.country)[0]}
              placeholder={t('placeholders.country')}
              handleChange={(event: any, value: any) => onChange(value?.code || '')}
              label={t('step1.country')}
              name='country'
              options={countries}
              getOptionLabel={option => option.name}
            />
          )}
        />
        <Controller
          fullwidth
          control={control}
          name='city'
          rules={{ required: `${t('errors.city')}` }}
          defaultValue={drawerState?.city}
          render={({ onChange, value }) => (
            <UniTextField
              fullWidth
              helperText={errors?.city?.message}
              error={!!errors?.city?.message}
              label={t('labels.city')}
              placeholder={t('placeholders.city')}
              name='city'
              value={value}
              onChange={onChange}
            />
          )}
        />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: close, label: t('buttons.cancel') }}
        PrimaryButton={{ onClick: handleSubmit(onSubmit), label: t('buttons.next') }}
      />
    </>
  );
}
