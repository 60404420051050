import { OutlinedInput, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { Colors, Shape } from '../../../constants';

interface StyledFormControlProps {
  hasLabel: boolean;
  backgroundColor?: any;
}

export const StyledTextPhoneField = styled(TextField)<{
  backgroundColor: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  /*DISABLE SCROLLBAR*/

  .MuiInputBase-root {
    position: relative;
    flex-wrap: wrap;
    background-color: transparent;
    max-width: 100%;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    max-height: 46px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .MuiOutlinedInput-input {
    padding: 0px;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0px;
  }

  .MuiInputBase-input {
    max-width: 100%;
    margin-left: 10px;
    color: ${Colors.Typography.Gray_100};
    font-weight: 400;
  }
  .MuiAutocomplete-endAdornment {
    background-color: ${Colors.White._100};
    border-radius: ${Shape.BorderRadius.md}px;
  }

  .MuiChip-root {
    background-color: ${Colors.Typography.Gray_100}10;

    .MuiChip-label {
      color: ${Colors.Typography.Gray_70};
    }
  }
  span.MuiAutocomplete-tag {
    background-color: ${Colors.Typography.Gray_100}10;
    border-radius: 16px;
    color: ${Colors.Typography.Gray_70};
    height: ${({ theme }) => theme.spacing(4)}px;
    padding: ${({ theme }) => theme.spacing(0, 1)};

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledInput = styled(OutlinedInput)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
