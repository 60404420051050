import { Snackbar } from '@material-ui/core'
import styled from 'styled-components'


export const StyledSnackbar=styled(Snackbar)<{mobile:boolean, position:any}>`

    ${({mobile, theme, position}) => !mobile ?
        `
            right: ${theme.spacing(4)}px;
            bottom: ${position ==='navigation' ? theme.spacing(2) : theme.spacing(12)}px;
        `
    :
        `
            right: ${theme.spacing(1)}px;
            left: ${theme.spacing(1)}px;
            bottom:${ position ==='navigation' ? theme.spacing(7) : position === 'footerProgram' ? theme.spacing(11) : 0}px;
        `

}
`