import { Paths } from 'client/UniClient';
import { MutationConfig, useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export default function useDeleteServiceOptions(config?: MutationConfig<any>) {
  const { client } = useClient();
  const queryCache = useQueryCache();
  return useMutation(
    async (paths: Paths.ServiceOptionApiDeleteServiceOption.PathParameters) => client!.ServiceOptionApi_deleteServiceOption(paths),
    {
      ...config,
      onSuccess() {
        queryCache.invalidateQueries(CacheKeys.ServiceOptions.All);
      },
    },
  );
}
