import { useMarkNotifications } from 'api';
import useOldNotifications from 'api/notifications/useOldNotifications';
import { useEffect, useState } from 'react';
import { useClient, useNotificationsSocket } from '../store';
import { NotificationItem } from '../types';
import { createNotificationAction } from '../utils';

const useUserNotifications = () => {
  const token = useClient(state => state.session?.token || '');
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const { data: oldNotifications, status: oldNotificationsStatus } = useOldNotifications();
  const { subscribe } = useNotificationsSocket();
  const [markAsRead] = useMarkNotifications();
  const handleNewNotifications = (data: NotificationItem) => {
    setNotifications(prevState => [{ ...data, action: createNotificationAction(data) }, ...prevState]);
    setNotificationsCount(prevState => prevState + 1);
  };

  useEffect(() => {
    if (oldNotificationsStatus === 'success' && oldNotifications) {
      setNotifications([...notifications, ...oldNotifications]);
      setNotificationsCount(oldNotifications.filter(item => !item.isSeen).length);
    }
    setNotifications(notifications => notifications.sort((a, b) => (a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0)));
  }, [oldNotifications, oldNotificationsStatus]);

  useEffect(() => {
    subscribe((data, err) => handleNewNotifications(data));
  }, []);

  return {
    allNotifications: notifications.sort((a, b) => {
      const aTime = new Date(a.createdAt).getTime();
      const bTime = new Date(b.createdAt).getTime();
      return bTime - aTime;
    }),
    oldNotifications: notifications.filter(item => item.isSeen) || [],
    newNotifications: notifications.filter(item => !item.isSeen),
    count: notificationsCount,
    markAsRead: (id: string) => {
      markAsRead(
        { id },
        {
          onSuccess() {
            setNotifications(prevState => {
              const index = prevState.findIndex(item => item.id === id && !item.isSeen);
              let aux = [...prevState];
              index !== -1 && (aux[index].isSeen = true);
              return aux;
            });
          },
        },
      );
    },
    clearCount: () => setNotificationsCount(0),
  };
};

export default useUserNotifications;
