import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { Paths } from 'client/UniClient';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

type PathParams = Paths.TaskApiSearchStudentTasks.PathParameters;
type QueryParams = Paths.TaskApiSearchStudentTasks.QueryParameters;
type Params = PathParams & QueryParams;

const useStudentTasks = (params: Params) => {
  const { client } = useClient();
  const pageSize = 20;

  return useInfiniteQuery(
    [CacheKeys.Tasks.Student, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.TaskApi_searchStudentTasks({
        ...params,
        page,
        pageSize,
      });
      return data;
    },
    {
      getFetchMore: lastPage => {
        if (lastPage.results.length < pageSize || lastPage.page * pageSize === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {
        console.log(error);
      }
    },
  );
};

export default useStudentTasks;
