import { Box } from '@material-ui/core';
import * as React from 'react';
import { useFile } from '../../../api';
import { ReactComponent as EyeIcon } from '../../../assets/svg/Icons/Eye.svg';
import { ReactComponent as GarbageIcon } from '../../../assets/svg/Icons/Garbage.svg';
import { ReactComponent as PaperIcon } from '../../../assets/svg/Icons/Nav/Paper.svg';
import { Components } from '../../../client/UniClient';
import { useErrorHandler } from '../../../hooks';
import { theme } from '../../../theme';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import UniTypography from '../uniTypography/UniTypography';

interface UniFileViewerProps {
  label: string;
  file: Components.Schemas.FileDto;
  onDelete?: () => void;
  deleteFile?: (id: string) => void;
  disableDelete?: boolean;
}

const UniFileViewer: React.FC<UniFileViewerProps> = ({ label, file, deleteFile, onDelete, disableDelete = false }) => {
  const {
    status: openFileStatus,
    data: openFileData,
    error: fileError,
  } = useFile({
    id: file?.id || '',
  });
  const getFileError = useErrorHandler(fileError!);

  const removeDelete = disableDelete || !deleteFile;

  return (
    <Box display='grid' my={1} gridTemplateColumns='24px 1fr auto' alignItems='center' style={{ gap: theme.spacing(0, 1) }}>
      <UniIconWrapper size='medium' color='typographyGray100'>
        <PaperIcon />
      </UniIconWrapper>
      <UniTypography color='typographyGray100' variant='subheader' style={{ minWidth: '170px' }}>
        {file?.label || file?.originalName}
      </UniTypography>
      <Box display='flex' alignItems='center' style={{ gap: theme.spacing(0, 1) }}>
        <UniIconWrapper
          size='medium'
          clearMargins
          onClick={() => {
            openFileData && window.open(openFileData.url, '_blank');
          }}
        >
          <EyeIcon />
        </UniIconWrapper>
        {(!removeDelete || !!onDelete) && (
          <UniIconWrapper
            size='medium'
            clearMargins
            onClick={() => {
              deleteFile && deleteFile(file.id);
              onDelete && onDelete();
            }}
          >
            <GarbageIcon />
          </UniIconWrapper>
        )}
      </Box>
    </Box>
  );
};

export default UniFileViewer;
