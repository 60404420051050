import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../../../constants';

export const StyledMyAgentsListItem = styled.div<{ emailConfirmed?: boolean }>`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  box-shadow: ${Shadows.shadow1};
  display: grid;
  grid-template-columns: 0.5fr 2fr repeat(4, 1fr) 2fr 3.5fr;
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
  min-height: ${({ theme }) => theme.typography.pxToRem(73)};
  cursor: pointer;
`;
