import { Tooltip } from '@material-ui/core';
import React, { useRef } from 'react';
import { useOnClickOutside } from '../../../hooks';
import { TooltipProps } from '@material-ui/core';
interface Props extends TooltipProps {
  title: string;
  children: React.ReactElement;
  open?: boolean;
  placement?: TooltipProps['placement'];
}
//close: Function;

export const UniTooltip = ({ title, children, open, placement }: Props) => {
  // const ref = useRef(null);
  // useOnClickOutside(ref, () => close());
  return (
    <Tooltip title={title} open={open} placement={placement}>
      <span>{children}</span>
    </Tooltip>
  );
};
