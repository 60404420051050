import { Box, useMediaQuery } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import React, { ReactElement, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { usePrograms } from '../../../api';
import ElipseIcon from '../../../assets/partners/times-higher-education/Ellipse 357.svg';
import FacebookIcon, { ReactComponent as FacebookSvgIcon } from '../../../assets/partners/times-higher-education/facebook.svg';
import TriangleIcon from '../../../assets/partners/times-higher-education/Group 4992.svg';
import GridDots from '../../../assets/partners/times-higher-education/Group 4993.svg';
import BackgroundDots from '../../../assets/partners/times-higher-education/Image.png';
import InstagramIcon, { ReactComponent as InstagramSvgIcon } from '../../../assets/partners/times-higher-education/instagram.svg';
import LinkedInIcon, { ReactComponent as LinkedInSvgIcon } from '../../../assets/partners/times-higher-education/linkedin.svg';
import Shapes from '../../../assets/partners/times-higher-education/Shapes.svg';
import { ReactComponent as RightArrow } from '../../../assets/svg/Icons/Arrow/Right.svg';
import { ProgramsListItem, UniBounceLoader, UniButton, UniIconWrapper, UniTypography } from '../../../components';
import { BrowserRoutes, Colors } from '../../../constants';
import {
  OutlinedUniButton,
  StyledAbsolutePeopleIllustration,
  StyledFooterSection,
  StyledFooterSectionInner,
  StyledFooterSectionSocials,
  StyledGetStartedSection,
  StyledGetStartedSectionActions,
  StyledGetStartedSectionInner,
  StyledHeroIllustration,
  StyledHighlightedTitle,
  StyledSectionInnerWrapper,
  StyledSectionWrapper,
  StyledShadowedTitle,
  StyledUnderlinedTitle,
  StyledUniversityCard,
  StyledUniversityCardMedia,
  StyledUniversityCarousel,
} from './Home.style';
import universities from './universities.json';

interface Props {}

export default function HomeLanding({}: Props): ReactElement {
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 800px)');
  const hideTriangle = useMediaQuery('(max-width: 600px)');
  const { queries } = useQueryStringParser<ProgramsFiltersQueries>();
  const {
    data: programs,
    isFetching,
    status: programsStatus,
  } = usePrograms({
    pageSize: 4,
    filters: queries,
  });
  const programsLoading = isFetching;
  const programsFlat = useMemo(() => programs?.map(programPage => programPage.results).flat() || [], [programsStatus, programsLoading]);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Box position='fixed' right='15px' top='30%' zIndex='20'>
        <Box display='flex' alignItems='center' flexDirection='column' style={{ gap: '8px' }}>
          <a href='https://www.facebook.com/uniapply.org/' target='_blank'>
            <UniIconWrapper color='primary100' size='medium'>
              <FacebookSvgIcon />
            </UniIconWrapper>
          </a>
          <a href='https://www.linkedin.com/company/uniapply/' target='_blank'>
            <UniIconWrapper color='primary100' size='medium'>
              <LinkedInSvgIcon />
            </UniIconWrapper>
          </a>
          <a href='https://www.instagram.com/uniapply_org/' target='_blank'>
            <UniIconWrapper color='primary100' size='medium'>
              <InstagramSvgIcon />
            </UniIconWrapper>
          </a>
        </Box>
      </Box>
      <StyledSectionWrapper
        style={{
          backgroundColor: Colors.Gray1._100,
          backgroundImage: `url(${Shapes})`,
          backgroundSize: '660px 370px',
          backgroundPosition: 'left top',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <StyledSectionInnerWrapper>
          <img
            src={BackgroundDots}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: 'auto',
              opacity: 1,
            }}
          />
          <Box maxWidth='470px' height='100%'>
            <StyledShadowedTitle color='typographyBlack100'>Find your perfect</StyledShadowedTitle>
            <StyledHighlightedTitle color='white100'>university match</StyledHighlightedTitle>
            <Box mt={3}>
              <UniTypography color='white30'>
                The AI-powered tool that allows you to find your optimal educational match designed for students, universities and
                recruitment partners alike. Here’s everything you need to know to study abroad!
              </UniTypography>
            </Box>
            <Box mt={3}>
              {!isMobile && (
                <UniButton
                  fullWidth={false}
                  endIcon={
                    <UniIconWrapper size='medium' color='white100'>
                      <RightArrow />
                    </UniIconWrapper>
                  }
                  variant='main'
                  color='primary'
                  onClick={() => history.push(BrowserRoutes.Default.Auth.Login)}
                >
                  Apply Now
                </UniButton>
              )}
            </Box>
          </Box>
          <StyledHeroIllustration />
        </StyledSectionInnerWrapper>
      </StyledSectionWrapper>
      <StyledSectionWrapper
        style={{
          backgroundImage: `url(${Shapes})`,
          backgroundSize: '660px 370px',
          backgroundPosition: '90% top',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <StyledSectionInnerWrapper
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
          }}
        >
          <Box textAlign='center'>
            <Box display='flex' alignItems='center' justifyContent='center' flexWrap='wrap'>
              {!programsLoading ? (
                queries.universityId && (
                  <UniTypography variant='header1' weight='semi-bold' color='typographyBlack100'>
                    {programsFlat[0]?.universityName}
                  </UniTypography>
                )
              ) : (
                <UniBounceLoader />
              )}
              <Box mx={0.5} />
              <UniTypography variant='header1' weight='semi-bold' color='typographyBlack100'>
                Programmes
              </UniTypography>
            </Box>
            <Box mt={2} mb={5} maxWidth='670px' mx={'auto'}>
              <UniTypography color='white30'>
                Discover all the courses{!programsLoading && queries.universityId && ` offered by ${programsFlat[0]?.universityName}`} and
                choose your ideal programme. A wide-range selection of degrees suited for every academic need and compatible with any goal.
                Scroll down and find out more!
              </UniTypography>
            </Box>
          </Box>
          {programsLoading && (
            <Box display='flex' justifyContent='center'>
              <UniBounceLoader />
            </Box>
          )}
          {programsFlat.map((program: any, index: any) => (
            <Box mb={3}>
              <ProgramsListItem
                variant='list'
                onClick={() => history.push(BrowserRoutes.Default.Program.General.replace(':programId', program.id!))}
                program={program}
                hideBottomDates
                ActionButtons={
                  !isMobile && (
                    <UniButton fullWidth={false} onClick={() => history.push(BrowserRoutes.Default.Auth.Login)}>
                      Apply
                    </UniButton>
                  )
                }
                key={index}
              />
            </Box>
          ))}
          <Box mx={'auto'}>
            <OutlinedUniButton fullWidth={false} onClick={() => history.push(BrowserRoutes.Partners.THE.Programs)}>
              <UniTypography color='primary100' weight='medium'>
                See all programmes
              </UniTypography>
            </OutlinedUniButton>
          </Box>
        </StyledSectionInnerWrapper>
      </StyledSectionWrapper>
      <StyledSectionWrapper style={{}}>
        <img
          src={ElipseIcon}
          style={{
            position: 'absolute',
            top: '20%',
            right: '-100px',
            width: '200px',
            height: 'auto',
            zIndex: 10,
          }}
        />
        <StyledSectionInnerWrapper
          style={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
          }}
        >
          {!hideTriangle && (
            <img
              src={TriangleIcon}
              style={{
                position: 'absolute',
                top: '-70px',
                left: '55%',
                width: '92px',
                height: 'auto',
              }}
            />
          )}
          <img
            src={GridDots}
            style={{
              position: 'absolute',
              top: '-50px',
              right: 0,
              width: '210px',
              height: 'auto',
            }}
          />
          <Box>
            <UniTypography variant='header1' weight='semi-bold' color='typographyBlack100'>
              How does it work?
            </UniTypography>
            <Box maxWidth='550px'>
              <UniTypography color='white30'>
                You’re just a few simple steps away from applying to your dream university. Become a UniApplier today and your path towards
                academic success will become clear!
              </UniTypography>
            </Box>
          </Box>
          <Box
            py={5}
            px={8}
            mx={-8}
            mt={5}
            style={{ backgroundColor: Colors.Gray1._100, boxShadow: '0px 12px 30px #9A9EBD33', position: 'relative' }}
          >
            {/* <StyledShadowedTitle color='primary100'>How it works?</StyledShadowedTitle> */}
            <Box maxWidth='500px' mt={2} mb={4}>
              <UniTypography color='white30'>
                Click the “Apply Now” button, make an account and start browsing through our list of programmes. Next, find your best
                matches, submit the necessary documentation, and voilà: your application is sent.
              </UniTypography>
            </Box>
            {isMobile ? (
              <Box maxWidth='350px'>
                <UniTypography variant='footnote' color='primary100'>
                  Make sure you’re logging in from a desktop to successfully submit your documentation.
                </UniTypography>
              </Box>
            ) : (
              <OutlinedUniButton fullWidth={false} onClick={() => history.push(BrowserRoutes.Default.Auth.Login)}>
                <UniTypography color='primary100' weight='medium'>
                  Apply Now
                </UniTypography>
              </OutlinedUniButton>
            )}
            <StyledAbsolutePeopleIllustration />
          </Box>
        </StyledSectionInnerWrapper>
      </StyledSectionWrapper>
      <StyledSectionWrapper
        style={{
          maxHeight: '700px',
        }}
      >
        <img
          src={ElipseIcon}
          style={{
            position: 'absolute',
            bottom: '60px',
            left: '80px',
            width: '200px',
            height: 'auto',
            // zIndex: -1,
          }}
        />
        <StyledSectionInnerWrapper
          style={{
            display: 'block',
            // flexDirection: 'column',
            // justifyContent: 'center',
            // alignItems: 'stretch',
          }}
        >
          <Box textAlign='center' mb={5}>
            <UniTypography variant='header1' weight='semi-bold' color='typographyBlack100'>
              Other universities
            </UniTypography>
          </Box>
          <StyledUniversityCarousel autoplay nextArrow={<ArrowBackIcon />} prevArrow={<ArrowForwardIcon />}>
            {universities.map((university, index) => (
              <StyledUniversityCard>
                <Box height='90px'>
                  <StyledUniversityCardMedia src={university.logo} />
                </Box>
                <Box mt={5} mb={2} flex={1}>
                  <UniTypography variant='header2' weight='semi-bold' color='typographyBlack100'>
                    {university.name}
                  </UniTypography>
                </Box>
              </StyledUniversityCard>
            ))}
          </StyledUniversityCarousel>
        </StyledSectionInnerWrapper>
      </StyledSectionWrapper>
      <StyledGetStartedSection>
        <img
          src={ElipseIcon}
          style={{
            position: 'absolute',
            top: '-30px',
            right: '60px',
            width: '100px',
            height: 'auto',
          }}
        />
        <StyledGetStartedSectionInner>
          <Box flex={1}>
            <StyledUnderlinedTitle color='white100'>Start your future now!</StyledUnderlinedTitle>
            <UniTypography color='white100'>
              If you’re pursuing greatness but don’t know where to start, join our community and interact with peers, experts, alumni, and
              the Youni team.
            </UniTypography>
          </Box>
          <StyledGetStartedSectionActions>
            <Box width='100%' maxWidth='400px' display='flex' my={2}>
              <OutlinedUniButton color='white100' onClick={() => window.open('https://community.uniapply.org/forum', 'blank')}>
                <UniTypography color='white100' weight='medium'>
                  Join the community
                </UniTypography>
              </OutlinedUniButton>
            </Box>
          </StyledGetStartedSectionActions>
        </StyledGetStartedSectionInner>
      </StyledGetStartedSection>
      <StyledFooterSection>
        <StyledFooterSectionInner>
          <Box textAlign='center' mb={4}>
            <UniTypography variant='header1' weight='semi-bold' color='white100'>
              Where to find us
            </UniTypography>
          </Box>
          <StyledFooterSectionSocials>
            <a href='https://www.facebook.com/uniapply.org/' target='_blank'>
              <Box display='flex' alignItems='center'>
                <img
                  src={FacebookIcon}
                  style={{
                    width: '38px',
                    height: 'auto',
                  }}
                />
                <Box ml={2}>
                  <UniTypography weight='semi-bold' color='white100'>
                    UniApply
                  </UniTypography>
                </Box>
              </Box>
            </a>
            <a href='https://www.instagram.com/uniapply_org/' target='_blank'>
              <Box display='flex' alignItems='center'>
                <img
                  src={InstagramIcon}
                  style={{
                    width: '38px',
                    height: 'auto',
                  }}
                />
                <Box ml={2}>
                  <UniTypography weight='semi-bold' color='white100'>
                    UniApply
                  </UniTypography>
                </Box>
              </Box>
            </a>
            <a href='https://www.linkedin.com/company/uniapply/' target='_blank'>
              <Box display='flex' alignItems='center'>
                <img
                  src={LinkedInIcon}
                  style={{
                    width: '38px',
                    height: 'auto',
                  }}
                />
                <Box ml={2}>
                  <UniTypography weight='semi-bold' color='white100'>
                    UniApply
                  </UniTypography>
                </Box>
              </Box>
            </a>
          </StyledFooterSectionSocials>
          <Box textAlign='center' maxWidth='500px' mt={4}>
            <UniTypography color='white100'>
              We love connecting with you. Find us on social media and get access to exclusive content centered around successful admission
              to university.
            </UniTypography>
          </Box>
          <Box textAlign='center' mt={10}>
            <UniTypography variant='footnote' color='white100'>
              Copyright © Youni - 2021 - All rights reserved.
            </UniTypography>
          </Box>
        </StyledFooterSectionInner>
      </StyledFooterSection>
    </div>
  );
}
