import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useClient } from '../../store';

type Payload = {
  email: string;
};

const useMagicLinkLogin = () => {
  const { client } = useClient();
  return useMutation(
    async (payload: Payload) => {
      return client!.AuthApi_requestMagicLinkLogin(undefined, payload);
    },
    {
      onSuccess: async ({ data }) => {},
      onError: (error: AxiosError) => {},
    },
  );
};
export default useMagicLinkLogin;
