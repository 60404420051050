import UniStatus from 'components/_common/uniStatus/UniStatus';
import ComponentColors, { TComponentColors } from 'constants/componentColors';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ApplicationIcon } from '../../../assets/svg/Icons/Applications.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/CloseX.svg';
import { ReactComponent as Illustration } from '../../../assets/svg/Illustrations/invitationSentIllustration.svg';
import { Colors } from '../../../constants';

export const StyledUniversityReplyWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${Colors.White._100};
`;

export const StyledUniversityReplyFormHeader = styled.div`
  padding: ${({ theme }) => theme.spacing(8, 7, 3, 7)};
  background-color: ${Colors.Gray2._40};
`;

export const StyledUniversityReplyTabWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 7, 15, 7)};
  overflow-x: hidden;
`;

export const StyledUniversityReplyStepItemWrapper = styled(motion.div)`
  height: auto;
`;

export const StyledStatusSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(2)}px;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledUniStatus = styled(({ ...props }) => <UniStatus {...props} />)`
  cursor: pointer;
`;

export const SuccessIllustration = styled(Illustration)`
  width: 100%;
  max-width: 420px;
  height: 60%;
  max-height: 300px;
  margin-bottom: ${({ theme }) => theme.spacing(10)}px;
`;

export const StyledApplicationIcon = styled(ApplicationIcon)<{ color?: TComponentColors }>`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ color }) => (color ? ComponentColors[color] : Colors.Secondary._100)};
  }
`;

export const StyledDeleteIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  background-color: ${Colors.Secondary._100}10;
  padding: 4px;
  border-radius: 4px;

  path {
    fill: ${Colors.Secondary._100};
  }
`;
