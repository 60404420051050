import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  grid-gap: ${({ theme }) => theme.spacing(3, 3)};
  ${({ theme }) => `${theme.breakpoints.down('sm')}{
    grid-gap: ${theme.spacing(2, 2)};
  }`}
`;

export const StyledWindowWrapper = styled.div<{ minHeight: number }>`
  height: 100%;
  min-height: ${({ minHeight }) => minHeight}px;
  ${({ theme }) => `${theme.breakpoints.down('sm')}{
    min-width: 80%;
  }`}

  & > * {
    height: 100%;
  }
`;

export const StyledMotionWrapper = styled(motion.div)<{ minHeight: number }>`
  min-height: ${({ minHeight }) => minHeight}px;
  & > * {
    min-height: ${({ minHeight }) => minHeight}px;
  }
`;
