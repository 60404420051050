import { UniTextArea, UniTextField } from 'components';
import { UniDate } from 'components/_inputs/uniDate/UniDate';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import { activitiesOptions } from 'constants/activities';
import { eventTypesOptions } from 'constants/events';
import { Controller } from 'react-hook-form';

interface Props {
  control: any;
  errors: any;
}

const Step1: React.FC<Props> = ({ control, errors, ...rest }) => {
  return (
    <>
      <Controller
        control={control}
        name='name'
        rules={{
          required: 'Field must not be empty',
        }}
        render={field => (
          <UniTextField
            fullWidth
            label='Event title'
            placeholder='Give a title to your item'
            helperText={errors?.name?.message}
            error={!!errors?.name?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name='category'
        rules={{
          required: 'Field must not be empty',
        }}
        render={({ value, onChange }) => (
          <UniSelect
            label='Event category'
            placeholder='Give a category to your item'
            options={activitiesOptions}
            error={errors?.category?.message}
            getOptionLabel={option => option.label}
            value={activitiesOptions.find(item => item.value === value)}
            onChange={option => onChange(option?.value)}
          />
        )}
      />
      <Controller
        control={control}
        name='type'
        rules={{
          required: 'Field must not be empty',
        }}
        render={({ value, onChange }) => (
          <UniSelect
            label='Event type'
            placeholder='Give a type to your item'
            options={eventTypesOptions}
            error={errors?.type?.message}
            getOptionLabel={option => option.label}
            value={eventTypesOptions.find(item => item.value === value)}
            onChange={option => onChange(option?.value)}
          />
        )}
      />
      <Controller
        name='startDate'
        control={control}
        rules={{
          required: 'Field must not be empty',
        }}
        render={field => (
          <UniDate
            fullWidth
            label='Select starting date'
            showFutureYears
            error={!!errors?.startDate?.message}
            helperText={errors?.startDate?.message}
            {...field}
          />
        )}
      />
      <Controller
        name='endDate'
        control={control}
        rules={{
          required: 'Field must not be empty',
        }}
        render={field => (
          <UniDate
            fullWidth
            label='Select ending date'
            showFutureYears
            error={errors?.endDate?.message}
            helperText={errors?.endDate?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name='description'
        rules={{
          required: 'Field must not be empty',
        }}
        render={field => (
          <UniTextArea
            label='Description'
            placeholder='Write a couple of sentences about this item'
            withBorder
            helperText={errors?.description?.message}
            {...field}
          />
        )}
      />
    </>
  );
};

export default Step1;
