import React from 'react';
import { StyledCountrySelect } from './CountrySelect.style';
import CountryTile from './CountryTile';

interface Props {
  countries: { name: string; code: string; continent: string }[];
}

export const CountrySelect: React.FC<Props> = ({ countries }) => {
  return (
    <StyledCountrySelect>
      {countries.map(c => (
        <CountryTile {...c} />
      ))}
    </StyledCountrySelect>
  );
};

export default CountrySelect;
