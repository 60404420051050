import styled from 'styled-components';
import { Slider } from '@material-ui/core';
import { Colors } from '../../../constants';

export const StyledSlider = styled(Slider)<{ withBorder: boolean }>`
  transition: transform 1200ms ease-in;
  border: ${({ withBorder }) => (withBorder ? `1px solid ${Colors.Border.Gray_100}` : 'none')};
  .MuiSelect-select:focus {
    background: transparent;
  }
`;

export const DropdownIcon = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  cursor: pointer;
`;
