import styled from 'styled-components';
import { ComponentColors } from '../../../constants';
import { TComponentColors } from '../../../constants/componentColors';
import { StatusProps } from './UniStatus';

export const Status = styled.div<StatusProps>`
  display: ${({ RightIcon }) => (RightIcon ? 'flex' : 'inline-block')};
  align-items: center;
  background-color: ${({ color }) => ComponentColors[color as TComponentColors]}11;
  border-radius: ${({ theme }) => theme.spacing(3)}px;
  /* max-width: fit-content; */
  max-height: 33px;
  padding: ${({ theme }) => theme.spacing(0.75, 2)};
`;
