import { Box } from '@material-ui/core';
import { UniTypography } from 'components';
import { StyledApplicationSuccessIllustration } from '../AddActivityItemDrawer.style';

const Step6: React.FC = () => {
  return (
    <>
      <UniTypography variant='body1' weight='medium' color='secondary100'>
        Congratulations, you have added a new activity item
      </UniTypography>
      <Box my={5}>
        <StyledApplicationSuccessIllustration />
      </Box>
    </>
  );
};

export default Step6;
