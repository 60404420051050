import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LocationIcon } from '../../assets/svg/Icons/Location.svg';
import { ReactComponent as FeeIcon } from '../../assets/svg/Icons/Program/Fee.svg';
import { ReactComponent as CheckIcon } from '../../assets/svg/Icons/CheckRound.svg';
import { Components } from '../../client/UniClient';
import { UniAvatar, UniTypography } from '../../components';
import { StyledProgramListItemBody, StyledProgramMetaItem, StyledProgramMetaItemContent } from '../programsList/ProgramsList.style';
import UniIconWrapper from '../_common/uniIconWrapper/UniIconWrapper';
import { StyledUniversityListItem } from './UniversityListItem.style';

type CandidateProps = {
  university: Components.Schemas.UniversityResponse;
  onClick?: Function;
};

const UniversityListItem: React.FC<CandidateProps> = React.memo(({ university, onClick }) => {
  const { t } = useTranslation('agencyAdminUniversities');
  return (
    <StyledUniversityListItem onClick={() => onClick && onClick()}>
      <Box display='flex' alignItems='center' mx={4} my={4}>
        {/* <UniAvatar online={false} src={imageUrl} /> */}
        <UniAvatar online={false} size='L' src={university?.logoFile?.location} label={university.name} />
        <Box ml={2}>
          <UniTypography variant='header3' color='primary100' weight='medium'>
            {university.name}
          </UniTypography>
        </Box>
      </Box>

      <Box>
        <StyledProgramListItemBody>
          <StyledProgramMetaItem>
            <UniIconWrapper size='medium' color='secondary100' withBackground>
              <LocationIcon />
            </UniIconWrapper>
            <StyledProgramMetaItemContent>
              <UniTypography color='typographyGray70'>{t('list_item.location')}</UniTypography>
              <UniTypography color='typographyGray100' weight='semi-bold'>
                {` ${university.address.city ? university.address.city : '-'} / ${university.address.country ? university.address.country : '-'
                  }`}
              </UniTypography>
            </StyledProgramMetaItemContent>
          </StyledProgramMetaItem>
          {/* <StyledProgramMetaItem>
            <UniIconWrapper size='medium' color='secondary100' withBackground>
              <ProgramsIcon />
            </UniIconWrapper>
            <StyledProgramMetaItemContent>
              <UniTypography color='typographyGray70'>{t('list_item.no_programs')}</UniTypography>
              <UniTypography color='typographyGray100' weight='semi-bold'>
                {specialization || '-'}
              </UniTypography>
            </StyledProgramMetaItemContent>
          </StyledProgramMetaItem> */}
          <StyledProgramMetaItem>
            <UniIconWrapper size='medium' color='secondary100' withBackground>
              <FeeIcon />
            </UniIconWrapper>
            <StyledProgramMetaItemContent>
              <UniTypography color='typographyGray70'>{t('list_item.average_fee')}</UniTypography>
              <UniTypography color='typographyGray100' weight='semi-bold'>
                -
              </UniTypography>
            </StyledProgramMetaItemContent>
          </StyledProgramMetaItem>
          <StyledProgramMetaItem>
            <UniIconWrapper size='medium' color='secondary100' withBackground>
              <CheckIcon />
            </UniIconWrapper>
            <StyledProgramMetaItemContent>
              <UniTypography color='typographyGray70'>{t('list_item.is_partner')}</UniTypography>
              <UniTypography color='typographyGray100' weight='semi-bold'>
                {university.isPartner ? 'Yes' : "No"}
              </UniTypography>
            </StyledProgramMetaItemContent>
          </StyledProgramMetaItem>
        </StyledProgramListItemBody>
      </Box>
    </StyledUniversityListItem>
  );
});

export default UniversityListItem;
