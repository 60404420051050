import { Fade } from '@material-ui/core';
import useInviteHighSchoolStudent from 'api/highSchools/useInviteHighSchoolStudent';
import { ReactComponent as ErrorSvg } from 'assets/svg/Icons/Error.svg';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import UniPhoneInput from 'components/_inputs/uniPhoneInput/uniPhoneInput';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import UniTextField from 'components/_inputs/uniTextField/UniTextField';
import { Error, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import hishSchoolYear from 'constants/options/highschoolYear';
import { useErrorHandler } from 'hooks';
import { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import { Components } from '../../../client/UniClient';
import InviteCandidateSuccess from './InviteHighSchoolStudentSuccess';
type InviteHighSchoolStudentProps = {};
export default function InviteHighSchoolStudent({}: InviteHighSchoolStudentProps): ReactElement {
  const { t } = useTranslation('forms');
  const { t: tButtons } = useTranslation('buttons');
  const [inviteStudent, { isLoading, isSuccess, data, error }] = useInviteHighSchoolStudent();
  const { closeDrawer } = useDrawers();
  const { register, control, errors, handleSubmit, setValue } = useForm<Components.Schemas.InviteHighSchoolStudentRequest>();
  const inviteError = useErrorHandler(error || undefined);

  const onSubmit = (payload: Components.Schemas.InviteHighSchoolStudentRequest) => {
    inviteStudent(payload);
  };
  if (isSuccess && data) {
    const studentName = `${data.firstName} ${data.lastName}`;

    return (
      <>
        <UniModalDrawerContent>
          <InviteCandidateSuccess studentName={studentName} />
        </UniModalDrawerContent>
        <UniModalDrawerButtons
          PrimaryButton={{
            label: 'Back to highschools',
            onClick: closeDrawer,
          }}
        />
      </>
    );
  }
  return (
    <>
      <UniModalDrawerContent>
        <UniTextField
          fullWidth
          name='firstName'
          label={t('form.first_name')}
          placeholder={t('form.first_name')}
          inputRef={register({ required: 'Admin first name is required!' })}
          helperText={errors?.firstName?.message}
          error={!!errors?.firstName?.message}
        />
        <UniTextField
          fullWidth
          name='lastName'
          label={t('form.last_name')}
          placeholder={t('form.last_name')}
          inputRef={register({ required: 'Admin last name is required!' })}
          helperText={errors?.lastName?.message}
          error={!!errors?.lastName?.message}
        />
        <UniPhoneInput
          name='phone'
          label={t('form.phone')}
          placeholder={t('form.phone')}
          control={control}
          setValue={setValue}
          inputRef={register({
            required: `${t('form.errors.phone_1')}`,
            minLength: { value: 4, message: t('form.errors.phone_3') },
            maxLength: { value: 15, message: t('form.errors.phone_3') },
          })}
          error={!!errors?.phone?.message || !!errors?.phonePrefix?.message}
          helperText={errors?.phone?.message || errors?.phonePrefix?.message}
          fullWidth
        />
        <UniTextField
          fullWidth
          label={t('form.email')}
          placeholder={t('form.email')}
          name='email'
          inputRef={register({
            required: `Email is required`,
          })}
        />
        <Controller
          control={control}
          name='highSchoolYear'
          rules={{ required: `${t('form.errors.highSchoolYear')}` }}
          render={({ value, onChange }) => (
            <UniSelect
              label={t('form.highSchoolYear')}
              value={hishSchoolYear.find(item => item.value === value)}
              onChange={value => onChange(value?.value)}
              options={hishSchoolYear}
              error={errors.highSchoolYear?.message}
            />
          )}
        />
        <Fade in={!!inviteError?.message} unmountOnExit>
          <Error>
            <StyledAlertIcon>
              <ErrorSvg />
            </StyledAlertIcon>
            <UniTypography variant='footnote' component='span' color='stateAlert100'>
              {inviteError?.message}
            </UniTypography>
          </Error>
        </Fade>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          label: tButtons('invite_student'),
          loading: isLoading,
          onClick: handleSubmit(onSubmit),
        }}
        SecondaryButton={{ label: 'Cancel', onClick: closeDrawer }}
      />
    </>
  );
}
