import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { EmptyState, UniButton, UniTypography } from 'components';
import ActivityPlanItem from 'components/activityPlanItem/ActivityPlanItem';
import { ActivitiesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ActivitiesFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { StyledTypeHeadline } from './ActivitiesList.style';

type ActivityType = Components.Schemas.AchievementDto | Components.Schemas.ToDoDto;

interface Props {
  achievementsFlat: Components.Schemas.AchievementDto[];
  todosFlat: Components.Schemas.ToDoDto[];
  achievementsLeft: number;
  todosLeft: number;
  achievementsIsFetching: boolean;
  achievementsFetchMore: () => void;
  todosIsFetching: boolean;
  todosFetchMore: () => void;
  hideTodo?: boolean;
}

const ActivitiesList: React.FC<Props> = ({
  achievementsFlat,
  achievementsLeft,
  todosFlat,
  todosLeft,
  achievementsFetchMore,
  achievementsIsFetching,
  todosFetchMore,
  todosIsFetching,
  hideTodo,
}) => {
  const { queries } = useQueryStringParser<ActivitiesFiltersQueries>();
  const { type } = queries;

  const showAchievements = !type?.includes('TODO') && !!achievementsFlat.length;
  const showTodos = !type?.includes('ACHIEVEMENT') && !!todosFlat.length;

  const sortByTime = (a: ActivityType, b: ActivityType) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateA - dateB;
  };

  return (
    <>
      {!achievementsFlat.length && !todosFlat.length && <EmptyState />}
      {showAchievements && !hideTodo && (
        <StyledTypeHeadline>
          <UniTypography variant='body1' weight='medium' color='white100'>
            Achievements
          </UniTypography>
        </StyledTypeHeadline>
      )}
      {showAchievements &&
        achievementsFlat.sort(sortByTime).map((achievement, index) => (
          <>
            <ActivityPlanItem achievement={achievement} key={index} />
            <Box mb={3} />
          </>
        ))}
      {showAchievements && achievementsLeft > 0 && (
        <Box width='100%' display='flex' justifyContent='center'>
          <UniButton variant='secondary' color='fadeGray' loading={achievementsIsFetching} onClick={() => achievementsFetchMore()}>
            Show more
          </UniButton>
        </Box>
      )}

      {showTodos && !hideTodo && !!todosFlat.length && (
        <StyledTypeHeadline>
          <UniTypography variant='body1' weight='medium' color='white100'>
            Todos
          </UniTypography>
        </StyledTypeHeadline>
      )}
      {showTodos &&
        !hideTodo &&
        todosFlat.sort(sortByTime).map((todo, index) => (
          <>
            <ActivityPlanItem todo={todo} key={index} />
            <Box mb={3} />
          </>
        ))}
      {showTodos && !hideTodo && todosLeft > 0 && (
        <Box width='100%' display='flex' justifyContent='center'>
          <UniButton variant='secondary' color='fadeGray' loading={todosIsFetching} onClick={() => todosFetchMore()}>
            Show more
          </UniButton>
        </Box>
      )}
    </>
  );
};

export default ActivitiesList;
