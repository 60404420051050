import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

export default function usePurchasedPrepPacks() {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.PurchasedPrepPacks.All],
    async (_key: string) => {
      const response = await client!.PurchasedPrepPackApi_getPurchasedPrepPacks();
      return response.data;
    },
    {
      onError(error: AxiosError) {
        console.error(error);
      }
    },
  );
}
