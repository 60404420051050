import { AxiosError } from 'axios';
import { Paths } from 'client/UniClient';
import { useMutation } from 'react-query';
import { useClient } from '../../store';

interface Params {
  studentId: Paths.FileApiUploadStudentProfileImage.Parameters.StudentId;
  image: FormData;
}

//this is for uploading the image to a student
const useUploadStudentProfileImage = () => {
  const { client } = useClient();

  return useMutation(
    async ({ studentId, image }: Params) => {
      return client!.FileApi_uploadStudentProfileImage({ studentId: studentId }, image);
    },
    {
      onError: (error: AxiosError) => {
        console.error(error);
      },
    },
  );
};

export default useUploadStudentProfileImage;
