import Slider from 'react-slick';
import styled from 'styled-components';

export const StyledSlider = styled(Slider)<{ customWidth?: number }>`
  .slick-slide {
    padding: 0 ${({ theme }) => theme.spacing(0.67)}px;
    height: inherit !important;
    ${({ customWidth, theme }) => customWidth && `width: calc(${customWidth}px + ${theme.spacing(2)}px) !important;`}
  }
  .slick-slide > div {
    height: 100%;
    ${({ customWidth }) => customWidth && `width: ${customWidth}px;`}
  }
  .slick-list {
    overflow: visible;
    clip-path: inset(-100vw -100vw -100vw 0);
  }
  .slick-track {
    display: flex !important;
  }
`;

export const StyledElement = styled.div<{ itemId: string; cardWidth: number; cardHeight?: number; dragging: boolean }>`
  cursor: grab;
  height: 100%;
  ${({ dragging }) => dragging && `pointer-events: none;`}
  ${({ cardWidth }) => !!cardWidth && `width:${cardWidth}px;`}
  ${({ cardHeight }) => !!cardHeight && `height:${cardHeight}px;`}
  ${({ itemId, theme }) => itemId > '0' && `margin-left:${theme.spacing(1.5)}px;`}
`;
