import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Payload = {
  programId: string;
  currency: Components.Schemas.CurrencyDto;
};

const useSubmitApplicationFeeInvoice = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async ({ programId, currency }: Payload) => client!.ProgramApi_updateProgram({ programId }, { applicationFee: { currency } }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(CacheKeys.General.Application);
        queryCache.invalidateQueries(CacheKeys.General.Program);
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default useSubmitApplicationFeeInvoice;
