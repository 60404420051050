import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniAvatar, UniAvatarGroup, UniButton, UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { BrowserRoutes } from '../../../constants';
import { ConsultantsWrapper, StyledHeader } from './UniHerosection.style';

interface Props {
  title: string;
  universityName?: string;
  variant: 'base' | 'baseNoAvatar' | 'title' | 'avatarTitle' | 'consultant';
  universityId?: string;
  studentId?: string;
  studentName?: string;
  profileImageSrc?: string;
  avatarVariant?: any;
  onProfileImageUpload?: (file?: File) => void;
  agents?: Components.Schemas.UserResponse[];
}

export const HerosectionHeader = ({
  title,
  universityName,
  variant,
  universityId,
  profileImageSrc,
  avatarVariant = 'circle',
  onProfileImageUpload,
  studentName,
  studentId,
  agents = [],
}: Props) => {
  const [withAvatar] = useState<boolean>(variant === 'avatarTitle' || variant === 'base');
  const [withSubheader] = useState<boolean>(variant === 'base' || variant === 'baseNoAvatar');
  const { isMobile } = useBreakpoints();
  const { profile } = useAuth();
  const { isStudent, isConsultant } = getMetaRole(profile.role);
  const history = useHistory();
  const { t } = useTranslation('herosection');
  const withAgents = variant === 'consultant';

  const clickHandler = () => {
    if (!universityId) return;
    !!isStudent && history.push(BrowserRoutes.Student.University.General.replace(':universityId', universityId));
    !!isConsultant && history.push(BrowserRoutes.Agency.University.General.replace(':universityId', universityId));
  };

  return (
    <StyledHeader mobile={isMobile}>
      <Box display='flex' alignItems='center' style={{ gap: '16px' }}>
        {withAvatar && (
          <Box display='flex' justifyContent='center'>
            <UniAvatar
              online={false}
              variant={avatarVariant}
              label={universityName}
              size='XL'
              src={profileImageSrc}
              onUpload={onProfileImageUpload}
            />
          </Box>
        )}
        <Box ml={withAvatar && !isMobile ? 2 : 0} display='grid' alignItems='center' justifyItems={isMobile && 'start'}>
          {studentName && (
            <Box
              display='flex'
              style={{ cursor: studentId ? 'pointer' : 'initial' }}
              onClick={() => studentId && history.push(BrowserRoutes.Agent.Student.Personal(studentId))}
            >
              <UniTypography variant='body1' weight='medium' color='primary100'>
                {studentName}
              </UniTypography>
              <UniTypography variant='body1' weight='medium' color='typographyBlack100'>
                's application at
              </UniTypography>
            </Box>
          )}
          <UniTypography
            color='typographyBlack100'
            weight={variant === 'consultant' ? (isMobile ? 'semi-bold' : 'regular') : 'semi-bold'}
            variant={variant === 'consultant' ? (isMobile ? 'subheader' : 'body1') : isMobile ? 'body1' : 'header1'}
          >
            {title}
          </UniTypography>
          {withSubheader && (
            <Box>
              {variant === 'consultant' ? (
                <UniTypography variant={isMobile ? 'body2' : 'header1'} weight='semi-bold' color='typographyBlack100'>
                  {universityName}
                </UniTypography>
              ) : (
                <UniButton size='medium' variant='text' disablePadding color='secondary' onClick={clickHandler}>
                  {universityName}
                </UniButton>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {withAgents && (
        <ConsultantsWrapper>
          <UniTypography variant='subheader'>
            {t('text.has')} {agents?.length} {agents?.length === 1 ? t('text.consultant') : t('text.consultants')}
          </UniTypography>
          <UniAvatarGroup
            avatars={agents!.map(agent => {
              return { label: `${agent.firstName} ${agent.lastName}`, src: agent.profileImageFile?.location };
            })}
          />
        </ConsultantsWrapper>
      )}
    </StyledHeader>
  );
};
