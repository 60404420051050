import { Box, ClickAwayListener } from '@material-ui/core';
import { UniButton, UniIconWrapper, UniPageTitle, UniTypography } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory } from 'react-router';
import { StyledInfoWrapper, StyledList, StyledListItem } from './UniStepper.style';
import { ReactComponent as RightArrow } from 'assets/svg/Icons/Arrow/ArrowRight.svg';

interface Props {
  title: string;
  texts: string[];
  buttonText: string;
  route: string;
  isVisible: boolean;
  onHide: Function;
}

const InfoCard: React.FC<Props> = ({ title, texts, buttonText, route, isVisible, children, onHide, ...rest }) => {
  const history = useHistory();
  const clickHandler = () => {
    onHide();
    history.push(route);
  };
  const clickAwayHandler = () => {
    onHide();
  };
  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <Box>
        {/* this box is here because it holds the ref. without it the clickAway will not fire */}
        <StyledInfoWrapper size='L' shadow='shadow2' onClick={clickHandler}>
          <AnimatePresence>
            {isVisible && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Box display='flex' flexDirection='column'>
                  <Box pl={2} pb={2}>
                    <UniPageTitle title={title} size='XS' color='white100' />
                  </Box>
                  <StyledList>
                    {texts.map(text => (
                      <StyledListItem>
                        <UniTypography variant='subheader' weight='medium' color='white100'>
                          {text}
                        </UniTypography>
                      </StyledListItem>
                    ))}
                  </StyledList>
                </Box>
                <Box display='flex' justifyContent='flex-end'>
                  <UniButton
                    variant='text'
                    color='white'
                    size='small'
                    disabled
                    fullWidth={false}
                    endIcon={
                      <UniIconWrapper size='main' color='white100'>
                        <RightArrow />
                      </UniIconWrapper>
                    }
                  >
                    {buttonText}
                  </UniButton>
                </Box>
              </motion.div>
            )}
          </AnimatePresence>
        </StyledInfoWrapper>
      </Box>
    </ClickAwayListener>
  );
};

export default InfoCard;
