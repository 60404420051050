import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Components } from '../../client/UniClient';
import { getBaseURL } from '../../constants/browserRoutes';
import { useAuth, useClient } from '../../store';

interface Payload extends Partial<Components.Schemas.CompleteHighSchoolOnboardingRequest> {
  token: string;
}

const useCompleteHighSchoolOnboarding = () => {
  const history = useHistory();
  const { client, setSession } = useClient();
  const { afterAuthPath, setAfterAuthPath } = useAuth();

  return useMutation(
    async ({ token, ...payload }: Payload) => {
      return client!
        .AuthApi_completeHighSchoolOnboarding({ token }, payload as Components.Schemas.CompleteHighSchoolOnboardingRequest)
        .then(session => {
          setSession(session.data);
          if (afterAuthPath) {
            history.push(afterAuthPath.replace(':base', getBaseURL(session.data.user.role)));
            setAfterAuthPath(undefined);
          }
          return client!.UserApi_getMyUser(undefined, undefined, { headers: { Authorization: `Bearer ${session.data.token}` } });
        })
        .then(({ data }) => data);
    },
    {
      onSuccess: data => {
        // setSession(response.data);
        //
        // const { data } = await client!.UserApi_getMyUser();
        //
        // setProfile(data);
      },
      onError: (error: AxiosError) => {
        // history.push(BrowserRoutes.Default.InvalidToken);
      },
    },
  );
};
export default useCompleteHighSchoolOnboarding;
