import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { TComponentColors } from '../../../constants/componentColors';
import { MetaItem } from '../../../views/_agent/candidateProfile/AgencyCandidateProfile.style';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import UniTypography from '../uniTypography/UniTypography';

export const UniFileView = () => {
  return <div>asd</div>;
};

interface UniTextWithIconProps {
  Icon?: React.ReactNode;
  iconColor?: TComponentColors;
  label: string;
  value?: string;
  ValueComponent?: React.ReactNode;
  valueColor?: TComponentColors;
}

export const UniTextWithIcon = ({
  Icon,
  ValueComponent,
  iconColor = 'typographyGray30',
  label,
  value,
  valueColor = 'typographyGray70',
}: UniTextWithIconProps) => {
  return (
    <MetaItem>
      {Icon && (
        <UniIconWrapper color={iconColor} withBackground>
          {Icon}
        </UniIconWrapper>
      )}
      <Box>
        <UniTypography color='typographyGray70' variant='footnote'>
          {label}
        </UniTypography>
        {ValueComponent ? (
          ValueComponent
        ) : (
          <UniTypography color={valueColor} weight='semi-bold' variant='body2'>
            {value || '-'}
          </UniTypography>
        )}
      </Box>
    </MetaItem>
  );
};

interface UniSectionTitleProps {
  title: string;
  subtitle?: string;
  titleVariant?: 'small' | 'footnote' | 'subheader' | 'body2' | 'body1' | 'header3' | 'header2' | 'header1' | 'xl' | undefined;
}

export const UniSectionTitle = ({ title, subtitle, titleVariant = 'body2' }: UniSectionTitleProps): ReactElement => {
  return (
    <Box>
      <UniTypography color='primary100' variant={titleVariant} weight='medium'>
        {title}
      </UniTypography>
      <UniTypography color='typographyGray70' variant='footnote'>
        {subtitle}
      </UniTypography>
    </Box>
  );
};
