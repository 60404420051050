import { Paths } from 'client/UniClient';
import { MutationConfig, useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export default function useDeleteServiceOptionsRequests(config?: MutationConfig<any>) {
  const { client } = useClient();
  const queryCache = useQueryCache();
  return useMutation(
    async (paths: Paths.ServiceOptionApiApproveServiceOptionRequest.PathParameters) =>
      client!.ServiceOptionApi_rejectServiceOptionRequest(paths),
    {
      ...config,
      onSuccess() {
        queryCache.invalidateQueries(CacheKeys.ServiceOptions.Requests);
      },
    },
  );
}
