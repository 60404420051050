interface IParagraph {
  label: string;
  content: string;
  icon?: string;
}

interface IVocabularyEntry {
  word: string;
  description: string;
}

interface ICountryDetails {
  paragraphs: IParagraph[];
  usefulVocabulary: IVocabularyEntry[];
}

const germany: ICountryDetails = {
  paragraphs: [
    {
      label: 'Financial: Understanding Financial Aid in Germany',
      content: 'How do I apply for financial aid in Germany?\n' +
        'To apply for financial aid (BAföG) in Germany, you\'ll need to submit an application to the local student services organization (Studentenwerk). This application requires various documents, such as proof of income, assets, and academic performance. Even if you\'re not eligible for full BAföG, you may still receive partial assistance.\n' +
        'Are there tuition-free universities available for international students?\n' +
        'Yes, many public universities in Germany offer tuition-free education for both domestic and international students. However, students might be required to pay a semester fee (Semesterbeitrag) which covers administrative costs and may include public transportation tickets and other services. Our counsellors can further guide on obtaining financial aid for studying in Germany.',
      icon: 'money',
    },
    {
      label: 'Admission Process: Navigating the German Admission Landscape',
      content: 'Do German universities require the TestAS or TestDaF?\n' +
        'Not all, but some German universities might require international students to take the TestAS (Test for Academic Studies) to assess their aptitude. If the course is taught in German, you\'ll often need to prove your proficiency in the language, for which the TestDaF (Test of German as a Foreign Language) or DSH are commonly accepted. Join our Exam Prep sessions and ace your exams to get into your dream university!\n' +
        'How significant are the "Abitur" or equivalent high school leaving certificates?\n' +
        'The "Abitur" or its recognized equivalent is crucial for university admissions in Germany. International students typically need to have their school-leaving certificates assessed to determine if they\'re equivalent to the Abitur. If not, they may need to attend a Studienkolleg (preparatory course) first.',
      icon: 'school',
    },
    {
      label: 'Visa and Immigration: Deconstructing the German Study Visa Process',
      content: 'What type of visa do I require to study in Germany?\n' +
        'International students from outside the European Union (EU) or European Economic Area (EEA) typically require a national visa (student visa) to study in Germany. It\'s crucial to apply for the right category and provide all required documents, including proof of financial sustenance and a university admission letter.\n' +
        'Can I work part-time while studying in Germany?\n' +
        'Yes, international students can work part-time while studying. Non-EU/EEA students can work up to 120 full days or 240 half days per year without needing a work permit. However, students should be aware that these limits are strictly monitored, and exceeding them could impact their visa status.',
      icon: 'passport',
    },
    {
      label: 'Campus Life',
      content: 'How prominent are student fraternities and sororities (Burschenschaften) in Germany?\n' +
        'Burschenschaften (student fraternities) have a long tradition in Germany, especially in some regions and universities. While they can provide social and networking opportunities, their prominence varies by university, and not all students choose to participate.\n' +
        'What are the popular extracurricular activities and clubs in German universities?\n' +
        'German universities offer a wide range of extracurricular activities. From music and art clubs to debate societies and sports (Hochschulsport), there\'s something for everyone. These activities provide a great opportunity for students to integrate, develop new skills, and make friends.',
      icon: 'graduation-hat',
    },
  ],
  usefulVocabulary: [
    {
      word: 'BAföG',
      description: 'The Federal Training Assistance Act in Germany that provides state funding for students.',
    },
    {
      word: 'Studienkolleg',
      description: 'Preparatory courses for international students who don’t meet the direct university admission requirements.',
    },
    {
      word: 'Studentenwerk',
      description: 'Organizations responsible for student services, including dormitories, cafeterias, and counseling.',
    },
    {
      word: 'Aufenthaltstitel',
      description: 'A residence permit issued to foreign students studying in Germany.',
    },
    {
      word: 'Burschenschaften',
      description: 'Traditional student associations that can be found in many German cities.',
    },
    {
      word: 'Semesterbeitrag',
      description: 'Semester fee that students pay to the university. It often includes public transportation ticket, student services, and more.',
    },
    {
      word: 'Hochschulsport',
      description: 'University sports programs where students can participate in various sports activities.',
    },
  ],
}

const italy: ICountryDetails = {
  paragraphs: [
    {
      label: 'Financial: Understanding Financial Aid in Italy',
      content: 'How do I finance my studies in Italy?\n' +
        'Many Italian universities offer affordable tuition fees compared to other Western European countries. Moreover, you might be eligible for scholarships, both from the institutions and external organizations.\n' +
        'Are there scholarships specifically for international students in Italy?\n' +
        'Yes, numerous Italian universities and regional governments offer scholarships for international students. The Italian government also provides scholarships for foreign students to study in Italy. Our counsellors can further guide on obtaining financial aid for studying in Italy.',
      icon: 'money',
    },
    {
      label: 'Admission Process: Navigating the Italian Admission Landscape',
      content: 'Do I need to take an entrance exam to study in Italy?\n' +
        'It depends on the university and the course. Many undergraduate courses in Italy require an entrance exam, especially for courses like Medicine, Engineering, and Architecture. Join our Exam Prep sessions and ace your exams to get into your dream university!\n' +
        'How vital is the language proficiency for courses taught in Italian?\n' +
        'If you\'re enrolling in a course taught in Italian, you\'ll typically need a B2 level certification in the Italian language. However, many universities offer courses in English, especially at the postgraduate level.',
      icon: 'school',
    },
    {
      label: 'Visa and Immigration: Deconstructing the Italian Study Visa Process',
      content: 'What type of visa is required to study in Italy?\n' +
        'If you are a European citizen, you do not have to apply for either a study visa or for a Residence Permit. Otherwise, you\'ll need a Type D visa for study purposes. It\'s essential to apply for this visa well in advance of your intended travel date.\n' +
        'Can I work part-time while studying in Italy?\n' +
        'Yes, international students in Italy can work up to 20 hours a week during term time, provided it doesn\'t interfere with their studies.',
      icon: 'passport',
    },
    {
      label: 'Campus Life',
      content: 'How diverse are Italian campuses?\n' +
        'Italian universities attract students from all over the world. Campuses in cities like Rome, Milan, and Florence are particularly multicultural and offer a vibrant student life. Events celebrating international cultures, languages, and foods are common, and you\'ll likely discover student clubs and organizations dedicated to global interactions.\n' +
        'What\'s unique about student life in Italian universities compared to other countries?\n' +
        'One distinct feature of Italian student life is the integration of universities within their cities. Unlike many campuses worldwide that are self-contained, many Italian university buildings and departments are scattered throughout the city. This layout immerses students in city life, allowing them to explore and engage with local communities seamlessly. Moreover, with the deep-rooted history of many Italian cities, it\'s not uncommon to attend lectures in historic buildings, palaces, or even former monasteries. This integration offers students a unique blend of contemporary student life and rich cultural immersion.',
      icon: 'graduation-hat',
    },
  ],
  usefulVocabulary: [
    {
      word: 'Università',
      description: 'Italian word for "university".',
    },
    {
      word: 'Laurea',
      description: 'The equivalent of a Bachelor\'s degree in Italy.',
    },
    {
      word: 'Laurea Magistrale',
      description: 'Equivalent to a Master\'s degree.',
    },
    {
      word: 'Permesso di Soggiorno',
      description: 'Residence permit required for staying in Italy for more than 90 days.',
    },
    {
      word: 'CIMEA',
      description: 'The Information Center on Academic Mobility and Equivalence, helpful for credential evaluations.',
    },
    {
      word: 'DSU (Diritto allo Studio Universitario)',
      description: 'Refers to the right to study at university. Often associated with financial benefits or scholarships.',
    },
    {
      word: 'Erasmus',
      description: 'A popular student exchange program in Europe, including Italy.',
    },
  ],
}

const romania: ICountryDetails = {
  paragraphs: [
    {
      label: 'Financial: Understanding Financial Aid in Romania',
      content: 'How do I apply for financial aid in Romanian universities?\n' +
        'In Romania, financial aid is primarily available through scholarships granted by universities or the Ministry of Education. Students should apply during the admission process, providing necessary documents that demonstrate merit or financial need.\n' +
        'Are there scholarships available for international and national students in Romania?\n' +
        'Yes, the Romanian government offers scholarships to international students through the Ministry of Education. Universities may also have specific scholarship programs for outstanding international and national students. Our counsellors can further guide on obtaining financial aid for studying in Romania.',
      icon: 'money',
    },
    {
      label: 'Admission Process: Understanding the Romanian University Admission Process',
      content: 'Do Romanian universities require specific entrance examinations?\n' +
        'It depends on the course and university. While some universities require entrance exams, especially for medicine, art, and architecture, others might base admissions on high school grades or previous academic achievements. Join our Exam Prep sessions and ace your exams to get into your dream university!\n' +
        'How essential is the Letter of Intent when applying to Romanian institutions?\n' +
        'The Letter of Intent is crucial for many Romanian universities, especially for postgraduate studies. It outlines your reasons for choosing a specific course and university and showcases your passion and commitment to your studies.',
      icon: 'school',
    },
    {
      label: 'Visa and Immigration: Deconstructing the Romanian Study Visa Process',
      content: 'What type of visa is required to study in Romania?\n' +
        'Non-EU/EEA international students need a "Viză de lungă ședere pentru studii" or long-stay visa for studies. It is granted based on the Letter of Acceptance to study at a Romanian institution. Students arriving from the EU countries, the European Economic Area, as well as from Canada, Croatia, Japan, Moldova, South Korea, Switzerland, USA and Serbia do not need visa to study in Romania.\n' +
        'As an international student, am I allowed to work part-time in Romania?\n' +
        'Yes, international students can work part-time up to 4 hours a day, or full-time during vacations, without needing a work permit. However, the primary purpose of the stay should remain studies.',
      icon: 'passport',
    },
    {
      label: 'Campus Life',
      content: 'What are student organizations and clubs like in Romanian universities?\n' +
        'Student organizations, known as "Asociația Studenților," are active in Romanian universities. They play vital roles in campus life, organizing events, representing student rights, and facilitating integration for international students.\n' +
        'How popular are sports and extracurricular activities in Romanian institutions?\n' +
        'Sports and extracurricular activities are an integral part of Romanian university life. Most universities offer a variety of sports facilities and encourage participation in both competitive and non-competitive sports. Clubs and societies related to arts, culture, and other hobbies are also popular.',
      icon: 'graduation-hat',
    },
  ],
  usefulVocabulary: [
    {
      word: 'Bursă',
      description: 'A scholarship or grant provided to students based on merit or financial need in Romania.',
    },
    {
      word: 'Admitere',
      description: 'The university admission process in Romania.',
    },
    {
      word: 'Viză de lungă ședere pentru studii',
      description: 'A long-stay visa for studies in Romania. Required for non-EU/EEA international students.',
    },
    {
      word: 'Facultate',
      description: 'Refers to a faculty or college within a Romanian university.',
    },
    {
      word: 'Cămin',
      description: 'University dormitories or student hostels in Romania.',
    },
    {
      word: 'Asociația Studenților',
      description: 'Student associations that often organize events, activities, and represent students\' interests.',
    },
    {
      word: 'Sporturi universitare',
      description: 'University sports programs in Romania. Popular sports include football (soccer), handball, and basketball.',
    },
  ],
}

const spain: ICountryDetails = {
  paragraphs: [
    {
      label: 'Financial: Understanding Financial Aid in Spain',
      content: 'How do I apply for financial aid in Spain?\n' +
        'In Spain, financial aid is primarily offered through the Ministry of Education, Culture, and Sport. Students need to fill out an online application form available on the ministry\'s official website. The application typically requires information on the student\'s economic situation, academic record, and the chosen program of study.\n' +
        'Are there scholarships available for international students studying in Spain?\n' +
        'Yes, there are scholarships available for international students in Spain. These can be offered by the Spanish government, specific universities, or private institutions. The "Becas MAEC-AECID" is a notable scholarship program for foreign students. Additionally, individual universities often have scholarship programs dedicated to attracting international talent. Our counsellors can further guide on obtaining financial aid for studying in Spain.',
      icon: 'money',
    },
    {
      label: 'Admission Process: Demystifying the Admission Process in Spain',
      content: 'Do Spanish universities require a specific entrance exam?\n' +
        'Spanish students typically take the "Selectividad (PAU)" after their Spanish Baccalaureate to enter university. However, for international students, the process can vary. Some universities may accept equivalent exams or qualifications from the student\'s home country, while others might require additional entrance exams or evaluations. Join our Exam Prep sessions and ace your exams to get into your dream university!\n' +
        'How vital is the personal statement or motivation letter in the application?\n' +
        'The personal statement or motivation letter plays a crucial role, especially for competitive programs or universities. It\'s an opportunity for students to highlight their passion, experiences, and reasons for choosing a particular program or institution.',
      icon: 'school',
    },
    {
      label: 'Visa and Immigration: Deconstructing the Spanish Visa Process',
      content: 'What type of visa do I need to study in Spain?\n' +
        'European Union citizens need to be in possession of a valid European Union identification card or passport; there is no additional visa requirement. International students from non-EU countries wishing to study in Spain for more than three months require a student visa. This visa can be applied for at the Spanish consulate or embassy in the student\'s home country.\n' +
        'Can I work as an international student in Spain?\n' +
        'Yes, international students with a valid student visa can work up to 20 hours per week during term time. However, they\'ll need to get a work permit, and the job shouldn\'t interfere with their studies.',
      icon: 'passport',
    },
    {
      label: 'Campus Life',
      content: 'How popular are student organizations and clubs in Spanish universities?\n' +
        'Student organizations and clubs are popular and diverse in Spanish universities. They range from academic and cultural societies to sports clubs and volunteer groups. Engaging in these activities is a great way for international students to integrate and experience the Spanish culture firsthand.\n' +
        'How integral are sports and cultural activities to campus life in Spain?\n' +
        'Sports and cultural activities are integral to Spanish university life. Many universities have dedicated facilities for a variety of sports, and cultural events like festivals, music, and dance performances are regularly organized. The "Tuna" groups, traditional student music bands, are a unique cultural aspect of Spanish campuses.',
      icon: 'graduation-hat',
    },
  ],
  usefulVocabulary: [
    {
      word: 'Título de Bachiller',
      description: 'A diploma obtained after successfully completing the Spanish Baccalaureate, a requirement for university entrance.',
    },
    {
      word: 'Selectividad (PAU)',
      description: 'University entrance exams taken after the Spanish Baccalaureate.',
    },
    {
      word: 'NIE (Número de Identidad de Extranjero)',
      description: 'Foreigner\'s identity number, a requirement for all international students.',
    },
    {
      word: 'Homologación',
      description: 'The process of validating foreign academic credentials in Spain.',
    },
    {
      word: 'Residencia',
      description: 'The student residence card, required for stays over 6 months.',
    },
    {
      word: 'Tuna',
      description: 'Traditional student music groups known for serenading and their medieval-inspired costumes.',
    },
    {
      word: 'Deportes Universitarios',
      description: 'University sports, including popular ones like football (soccer), basketball, and handball.',
    },
  ],
}

const netherlands: ICountryDetails = {
  paragraphs: [
    {
      label: 'Financial: Understanding Financial Aid in The Netherlands',
      content: 'How do I apply for tuition fee loans in The Netherlands?\n' +
        'You can apply for tuition fee loans through DUO (Dienst Uitvoering Onderwijs). Register and log in to \'Mijn DUO\' to apply online. You\'ll need to provide details about your study program and personal financial situation.\n' +
        'Are there scholarships or grants available for international students in Dutch universities?\n' +
        'Yes, many Dutch universities offer scholarships specifically for international students. Additionally, there are grants such as the Holland Scholarship, Erasmus+, and Orange Tulip Scholarship. Check with your chosen university\'s financial aid office for specific opportunities. Our counsellors can further guide on obtaining financial aid for studying in The Netherlands.',
      icon: 'money',
    },
    {
      label: 'Admission Process: Demystifying the Admission Process in Dutch Universities',
      content: 'Do Dutch universities require entrance examinations for admissions?\n' +
        'It depends on the program and the university. While many bachelor\'s programs have open admission, some, known as "Numerus Fixus", have a selection procedure that might include exams, interviews, or other criteria. Join our Exam Prep sessions and ace your exams to get into your dream university!\n' +
        'How vital is the motivation letter when applying to Dutch universities?\n' +
        'The motivation letter is crucial, especially for competitive programs. It provides an opportunity for you to showcase your enthusiasm, goals, and alignment with the program\'s objectives.',
      icon: 'school',
    },
    {
      label: 'Visa and Immigration: Navigating Dutch Visa and Immigration Procedures',
      content: 'What type of visa is needed for non-EU/EEA students to study in The Netherlands?\n' +
        'Non-EU/EEA students generally require an MVV (Machtiging tot Voorlopig Verblijf) to enter The Netherlands and a residence permit to stay and study. The university often assists in the application process.\n' +
        'Can international students work part-time while studying in The Netherlands?\n' +
        'Yes, with a valid residence permit, international students can work up to 16 hours a week during the academic year and full-time during summer months. However, their employer needs to have a work permit (TWV) on their behalf.',
      icon: 'passport',
    },
    {
      label: 'Campus Life',
      content: 'What are "studentenverenigingen" (student associations) in Dutch universities?\n' +
        '"Studentenverenigingen" are traditional student societies focused on social activities, networking, and personal development. Membership often involves an initiation period, and these societies play a vibrant role in Dutch student life.\n' +
        'How popular are cycling and other sports activities among students in The Netherlands?\n' +
        'Cycling is an integral part of Dutch culture and is the primary mode of transportation for many students. Additionally, universities often have sports centers offering various activities, and many students participate in sports clubs or associations.',
      icon: 'graduation-hat',
    },
  ],
  usefulVocabulary: [
    {
      word: 'Studiefinanciering',
      description: 'A Dutch student finance system that includes loans, supplementary grants, and student travel products.',
    },
    {
      word: 'Numerus Fixus',
      description: 'Limited enrollment programs in Dutch universities where the number of available spots is restricted.',
    },
    {
      word: 'MVV (Machtiging tot Voorlopig Verblijf)',
      description: 'A provisional residence permit required for many foreigners who wish to stay in The Netherlands for longer than three months.',
    },
    {
      word: 'DUO (Dienst Uitvoering Onderwijs)',
      description: 'The executive agency responsible for several tasks related to education, including student finance and the recognition of foreign diplomas.',
    },
    {
      word: 'Studentenvereniging',
      description: 'Traditional Dutch student associations which are distinct from study associations and are more focused on social activities and networking.',
    },
    {
      word: 'Study Associations',
      description: 'Organizations focused on specific academic fields, providing academic and career support, and organizing related activities.',
    },
    {
      word: 'Hogeschool',
      description: 'Universities of Applied Sciences in The Netherlands offering professional bachelor\'s and master\'s programs.',
    },
    {
      word: 'BSN',
      description: 'A citizen service number (BSN) is the personal number you use in your dealings with the Dutch authorities. Even if you live abroad or do not hold Dutch nationality, it is still possible to have a BSN.',
    },
  ],
}

const unitedKingdon: ICountryDetails = {
  paragraphs: [
    {
      label: 'Financial: Understanding Financial Aid in the UK',
      content: 'How do I apply for a student loan in the UK?\n' +
        'In the UK, domestic students typically apply for student loans through the Student Loans Company (SLC). For England, it\'s through Student Finance England, for Scotland through the Student Awards Agency Scotland, for Wales through Student Finance Wales, and for Northern Ireland through Student Finance NI. International students may not be eligible for these loans and should explore scholarships, bursaries, or private loans available in their home country or from UK institutions.\n' +
        'Are there bursaries or scholarships available for international students in the UK?\n' +
        'Yes, many UK universities offer scholarships and bursaries specifically for international students. The criteria can vary and might be based on academic merit, country of origin, or field of study. Websites like the British Council\'s Study UK and the UKCISA offer databases and information on available scholarships. Our counsellors can further guide on obtaining financial aid for studying in The United Kingdom.',
      icon: 'money',
    },
    {
      label: 'Admission Process: Demystifying the UK University Admission Process',
      content: 'Do UK universities require the UCAS tariff points system?\n' +
        'Not all UK universities use the UCAS tariff points system, but many do. The UCAS tariff assigns points to qualifications. Instead of universities asking for specific grades, they might ask for a specific number of tariff points. However, always check the specific entry requirements of each course on the university\'s official website.' +
        'How essential is the personal statement for UK university applications?\n' +
        'The personal statement is a crucial part of UK university applications. It allows students to showcase their passion, motivations for studying a particular course, and any experiences or skills that make them a suitable candidate. Universities use the personal statement to assess the applicant\'s suitability and commitment to the subject.',
      icon: 'school',
    },
    {
      label: 'Visa and Immigration: Navigating UK Visa and Immigration Procedures',
      content: 'What type of visa do I need to study in the UK?\n' +
        'International students typically need a Student Visa (formerly known as Tier 4 General Student Visa) to study in the UK. The university or institution where the student has been accepted will issue a Confirmation of Acceptance for Studies (CAS) which is required for the visa application.\n' +
        'Can I work part-time while studying in the UK?\n' +
        'Yes, with a Student Visa, most students can work up to 20 hours per week during term time. However, it\'s essential to check the specific conditions of your visa and the type of course you\'re enrolled in, as some courses may have restrictions.',
      icon: 'passport',
    },
    {
      label: 'Campus Life',
      content: 'What are student unions in UK universities?\n' +
        'Student unions are organizations within UK universities that represent the interests of students. They play a key role in campus life, organizing events, offering support and advice, and representing students in university decision-making processes.\n' +
        'How significant are societies and clubs in UK campus life?\n' +
        'Societies and clubs are a vital aspect of UK campus life. They offer students opportunities to meet people with similar interests, develop new skills, and engage in various activities, from sports and arts to academic and cultural groups. Joining societies can greatly enhance the university experience and help students build a network.',
      icon: 'graduation-hat',
    },
  ],
  usefulVocabulary: [
    {
      word: 'UCAS (Universities and Colleges Admissions Service)',
      description: 'The UK\'s centralised university application system.',
    },
    {
      word: 'Tariff Points',
      description: 'A score system used by UCAS to report grades and qualifications in a consistent manner.',
    },
    {
      word: 'Tier 4 (General) Student Visa',
      description: 'Required by international students who want to study in the UK.',
    },
    {
      word: 'CAS (Confirmation of Acceptance for Studies)',
      description: 'A virtual document with a unique reference number provided by the university to support a student\'s visa application.',
    },
    {
      word: 'Student Union',
      description: 'An organization at a university or college in the UK that aims to protect and represent students\' interests.',
    },
    {
      word: 'Bursary',
      description: 'A monetary award made by an institution to individuals or groups of people who cannot afford to pay full fees.',
    },
    {
      word: 'Red Brick University',
      description: 'A term originally used to describe six civic universities in major industrial cities in England, but now often used to refer to other older universities.',
    },
  ],
}

const usa: ICountryDetails = {
  paragraphs: [
    {
      label: 'Financial: Understanding Financial Aid in the USA',
      content: 'How do I apply for financial aid in the USA?\n' +
        'To apply for financial aid, international students often complete the CSS Profile. But remember, always consult with the specific university\'s financial aid office for their requirements.\n' +
        'Are there scholarships available for international students?\n' +
        'Absolutely! Many U.S. universities offer scholarships tailored for international students. It\'s advisable to check each university\'s scholarship page or get in touch with us so we can guide you through the process of financial application.',
      icon: 'money',
    },
    {
      label: 'Admission Process: Demystifying the Admission Process',
      content: 'Do all U.S. universities require SAT or ACT scores?\n' +
        'While a large number do, there are also numerous test-optional schools. It\'s best to review a university\'s specific requirements on their admissions page. Want to be prepared regardless? Join our SAT Prep sessions and ace your exams to get into your dream university!\n' +
        'How important is the application essay?\n' +
        'The essay is an integral part of the U.S. college admissions process. It offers students an opportunity to present their personality, experiences, and writing skills, adding depth to their application. We can best assist you on this creative journey, so book a free call and discuss your ideas with one of our Essay Prep specialists.',
      icon: 'school',
    },
    {
      label: 'Visa and Immigration: Navigating U.S. Visa and Immigration Procedures',
      content: 'What type of visa do I need to study in the USA?\n' +
        'Most students will require an F-1 visa designated for academic studies. After being admitted, the university will issue an I-20 form, which will be used to apply for the visa.\n' +
        'Can I work while studying in the USA?\n' +
        'Yes, F-1 visa holders are permitted to work up to 20 hours a week on-campus during academic terms and can work full-time during breaks. Additionally, OPT provides an opportunity to work off-campus in their field of study post-graduation.',
      icon: 'passport',
    },
    {
      label: 'Campus Life',
      content: 'What is Greek life in U.S. colleges?\n' +
        'Greek life involves fraternities and sororities, which are student-driven social organizations. They often engage in community service, host social events, and might have their own housing facilities on or close to campus.\n' +
        'What are college sports like in the USA?\n' +
        'College sports are an essential part of campus life in many U.S. universities. From football to basketball, these sports events are often significant community gatherings, fostering school spirit and camaraderie.',
      icon: 'graduation-hat',
    },
  ],
  usefulVocabulary: [
    {
      word: 'CSS Profile',
      description: 'A financial aid application form used by many colleges to determine aid for non-federal programs.',
    },
    {
      word: 'Test-Optional',
      description: 'Refers to universities that don\'t require students to submit SAT or ACT scores as part of their admission process.',
    },
    {
      word: 'F-1 Visa',
      description: 'A non-immigrant visa for those wishing to study in the U.S. at an accredited university or college.',
    },
    {
      word: 'I-20 Form',
      description: 'A certificate of eligibility for non-immigrant student status. It\'s issued by the university and is required for the F-1 Visa application.',
    },
    {
      word: 'Greek Life',
      description: 'Social organizations on U.S. college campuses known as fraternities (for men) and sororities (for women).',
    },
    {
      word: 'Optional Practical Training (OPT)',
      description: 'Temporary employment related to an F-1 international student\'s major. It allows students to work off-campus in their field of study post-graduation.',
    },
    {
      word: 'College Sports',
      description: 'Athletic programs offered by many U.S. universities. Popular sports include football, basketball, and baseball.',
    },
  ],
}

const canada: ICountryDetails = {
  paragraphs: [
    {
      label: 'Financial: Decoding Financial Aid in Canada',
      content: 'How do I apply for financial aid in Canada?\n' +
        'International students can apply for financial aid through specific scholarships and grants provided by Canadian universities. Additionally, some provinces offer financial assistance to eligible students.\n' +
        'Are there scholarships available for international students in Canada?\n' +
        'Yes, many Canadian universities offer scholarships and grants specifically for international students. It\'s best to check each university\'s financial aid page or get in touch with us so we can guide you through the process of financial application.',
      icon: 'money',
    },
    {
      label: 'Admission Process: Navigating the Canadian Admission Landscape',
      content: 'Do all Canadian universities require standardized test scores?\n' +
        'While many Canadian universities consider SAT or ACT scores, especially for international students, others may place more emphasis on high school grades and other application materials. Want to be prepared regardless? Join our SAT Prep sessions and ace your exams to get into your dream university!\n' +
        'How crucial are personal statements or essays for Canadian university applications?\n' +
        'Personal statements or essays can be pivotal in Canadian university applications, providing an opportunity for students to present themselves beyond academic scores, showcasing their passions, experiences, and aspirations. We can best assist you on this creative journey, so book a free call and discuss your ideas with one of our Essay Prep specialists.',
      icon: 'school',
    },
    {
      label: 'Visa and Immigration: Setting the Path to Your Canadian Adventure',
      content: 'What type of visa do I need to study in Canada?\n' +
        'International students typically need a Study Permit to study in Canada. After receiving an acceptance letter from a designated learning institution, one can apply for the Study Permit.\n' +
        'Can I work while studying in Canada?\n' +
        'Yes, Study Permit holders in Canada can work up to 20 hours a week off-campus during academic sessions and full-time during breaks without an additional work permit.',
      icon: 'passport',
    },
    {
      label: 'Campus Life',
      content: 'How are student societies and clubs organized in Canadian universities?\n' +
        'Canadian universities host a plethora of student societies, clubs, and organizations centered around various interests, academic disciplines, and cultural backgrounds, fostering community and leadership.\n' +
        'What is the significance of intercollegiate sports in Canada?\n' +
        'Intercollegiate sports, governed by U Sports in Canada, play a pivotal role in campus life. Popular sports include hockey, basketball, and football, with fierce rivalries and passionate fan bases.',
      icon: 'graduation-hat',
    },
  ],
  usefulVocabulary: [
    {
      word: 'Study Permit',
      description: 'Authorization for foreign nationals to study at designated learning institutions in Canada.',
    },
    {
      word: 'Designated Learning Institution (DLI)',
      description: 'A school approved by a provincial or territorial government to host international students.',
    },
    {
      word: 'Canadian Bureau for International Education (CBIE)',
      description: 'A national organization dedicated to promoting Canada\'s international relations through education.',
    },
    {
      word: 'U Sports',
      description: 'The national governing body of university sport in Canada.',
    },
    {
      word: 'Student Societies',
      description: 'Organizations at Canadian universities representing the interests of the student population at large or specific faculties.',
    },
    {
      word: 'Intercollegiate Sports',
      description: 'Athletic competitions between universities.',
    },
    {
      word: 'Co-op Programs',
      description: 'Work-integrated learning programs that allow students to gain practical work experience in their field of study during their academic training.',
    },
  ],
}

const australia: ICountryDetails = {
  paragraphs: [
    {
      label: 'Financial: Understanding Financial Aid in Australia',
      content: 'How do I apply for financial aid in Australia?\n' +
        'Financial aid for international students in Australia primarily comes in the form of scholarships and grants. Each university has its process for scholarship applications. Generally, students need to apply for scholarships at the same time or after they apply for admission. Research the specific scholarships available at your chosen institution and follow their application guidelines.\n' +
        'Are there scholarships available for international students in Australia?\n' +
        'Yes, numerous scholarships are available for international students in Australia. These range from university-specific scholarships to government-funded ones like the Australia Awards. The eligibility and award amount vary, so it\'s essential to research and apply for as many as relevant.',
      icon: 'money',
    },
    {
      label: 'Admission Process: Demystifying the Admission Process in Australia',
      content: 'Do all Australian universities require the ATAR for admission?\n' +
        'While the ATAR (Australian Tertiary Admission Rank) is commonly used for domestic students, international students might not always need it. Instead, international students are often assessed based on their home country\'s equivalent qualifications and possibly English language proficiency tests. However, requirements can vary by institution and course, so it\'s crucial to check with the specific university. Join our Exam Prep sessions and ace your exams to get into your dream university!\n' +
        'How significant is the personal statement for Australian university applications?\n' +
        'The importance of the personal statement varies depending on the course and university. For highly competitive courses or those that require a demonstration of passion and commitment (like the arts or medicine), a personal statement can be a crucial part of the application. It\'s an opportunity to showcase your dedication, experiences, and reasons for choosing the course.',
      icon: 'school',
    },
    {
      label: 'Visa and Immigration: Navigating Australian Visa and Immigration Procedures',
      content: 'What type of visa do I need to study in Australia?\n' +
        'International students need the Student Visa (subclass 500) to study in Australia. This visa allows you to stay in Australia for the duration of your course, provided you maintain adequate health insurance and meet other visa conditions.\n' +
        'Can I work in Australia while studying?\n' +
        'Yes, with a subclass 500 visa, you are permitted to work up to 40 hours per fortnight while your course is in session and unlimited hours during scheduled course breaks. However, work limitations might differ if you\'re partaking in a masters by research or doctoral degree.',
      icon: 'passport',
    },
    {
      label: 'Campus Life',
      content: 'What are university societies like in Australian institutions?\n' +
        'University societies in Australia are diverse and cater to various interests, from academic and career-focused groups to cultural and hobby-based societies. They play a significant role in the social and cultural life of students, offering opportunities for networking, skill development, and recreation.\n' +
        'How popular are sports and recreational activities in Australian universities?\n' +
        'Sports and recreation are integral to Australian university life. Many institutions have state-of-the-art sports facilities and offer a range of sports clubs and teams that students can join. Popular sports include OzTag, footy (Australian Rules Football), cricket, rugby, and soccer. Additionally, universities often participate in inter-university competitions.',
      icon: 'graduation-hat',
    },
  ],
  usefulVocabulary: [
    {
      word: 'HELP (Higher Education Loan Programme)',
      description: 'A loan scheme for eligible students in Australia.',
    },
    {
      word: 'ATAR (Australian Tertiary Admission Rank)',
      description: 'The primary criterion for entry into most undergraduate university courses in Australia.',
    },
    {
      word: 'Student Visa (subclass 500)',
      description: 'The visa required for international students wishing to study in Australia.',
    },
    {
      word: 'CoE (Confirmation of Enrolment)',
      description: 'A document issued by an Australian education provider to support your visa application.',
    },
    {
      word: 'University Societies',
      description: 'Student-run clubs and organizations focusing on academic and social interests.',
    },
    {
      word: 'Post-Study Work Visa',
      description: 'Allows international students to live, study, and work in Australia temporarily after completing their studies.',
    },
    {
      word: 'OzTag and Footy',
      description: 'Popular sports in Australia, alongside cricket, rugby, and soccer.',
    },
  ],
}

const fallback: ICountryDetails = {
  paragraphs: [
    {
      label: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati maiores',
      content:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati maiores consequatur saepe veritatis aliquid commodi. Atque ' +
        'nesciunt ipsum animi repudiandae, tenetur iusto, sapiente sunt voluptate molestiae ducimus quibusdam omnis recusandae!',
      icon: 'language',
    },
    {
      label: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati maiores',
      content:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati maiores consequatur saepe veritatis aliquid commodi. Atque ' +
        'nesciunt ipsum animi repudiandae, tenetur iusto, sapiente sunt voluptate molestiae ducimus quibusdam omnis recusandae!',
      icon: 'trophy',
    },
    {
      label: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati maiores',
      content:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati maiores consequatur saepe veritatis aliquid commodi. Atque ' +
        'nesciunt ipsum animi repudiandae, tenetur iusto, sapiente sunt voluptate molestiae ducimus quibusdam omnis recusandae!',
      icon: 'money',
    },
    {
      label: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati maiores',
      content:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati maiores consequatur saepe veritatis aliquid commodi. Atque ' +
        'nesciunt ipsum animi repudiandae, tenetur iusto, sapiente sunt voluptate molestiae ducimus quibusdam omnis recusandae!',
      icon: 'money',
    },
  ],
  usefulVocabulary: [
    {
      word: 'Citizen Service Number (BSN)',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae, ab iusto accusantium animi quod optio nihil! Veniam aperiam',
    },
    {
      word: 'Studielink',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Labore temporibus nobis facere optio expedita ' +
        'quod esse! Hic repellendus consectetur voluptate deleniti reprehenderit ex, nulla odio esse ad, nam at dolorum.',
    },
    {
      word: 'Studielink',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Labore temporibus nobis facere optio expedita ' +
        'quod esse! Hic repellendus consectetur voluptate deleniti reprehenderit ex, nulla odio esse ad, nam at dolorum.',
    },
  ],
};

export const countryDetails: { fallback: ICountryDetails, [key: string]: ICountryDetails } = {
  fallback,
  germany,
  italy,
  romania,
  spain,
  'the-netherlands': netherlands,
  'united-kingdom': unitedKingdon,
  'united-states': usa,
  canada,
  australia,
};
