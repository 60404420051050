import { AxiosError } from "axios";
import { queryCache, useMutation } from "react-query";
import { Components } from "../../client/UniClient";
import { CacheKeys } from "../../constants";
import { useAuth, useClient } from "../../store";

interface Payload {
  id: string;
  validationStatus: "NONE" | "VALID" | "CONFLICT";
}

const usePostDocumentStatus = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.FileApi_updateDocumentValidation(payload.id, payload);
    },
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(CacheKeys.Agency.Candidate);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         }); 
         */
      },
    }
  );
};

export default usePostDocumentStatus;
