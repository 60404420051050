import { UniOptionsField, UniTextField } from 'components';
import { UniDate } from 'components/_inputs/uniDate/UniDate';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import { countriesMap } from 'constants/countriesMap';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Nationality } from 'types';

export interface PersonalFormInputs {
  firstName: string;
  lastName: string;
  gender: 'Male' | 'Female' | 'Other';
  dateOfBirth: string;
  countryOfBirth: Nationality;
  nationality: Nationality;
  secondNationality: Nationality;
}

interface Props {
  register: any;
  control: any;
  errors: any;
}

const PersonalForm: React.FC<Props> = ({ register, control, errors }) => {
  const { t } = useTranslation('forms');

  return (
    <>
      <Controller
        control={control}
        name='firstName'
        rules={{ required: `${t('form.errors.first_name')}` }}
        render={({ value, onChange }) => (
          <UniTextField
            label={t('form.first_name')}
            value={value}
            onChange={onChange}
            error={!!errors.firstName?.message}
            helperText={errors.firstName?.message}
            fullWidth
          />
        )}
      />

      <Controller
        control={control}
        name='lastName'
        rules={{ required: `${t('form.errors.last_name')}` }}
        render={({ value, onChange }) => (
          <UniTextField
            label={t('form.last_name')}
            value={value}
            onChange={onChange}
            error={!!errors.lastName?.message}
            helperText={errors.lastName?.message}
            fullWidth
          />
        )}
      />

      <Controller
        control={control}
        name='gender'
        rules={{ required: `${t('form.errors.gender')}` }}
        render={({ value, onChange }) => (
          <UniOptionsField
            label={t('form.gender.title')}
            options={[
              { label: t('form.gender.male'), value: 'Male' },
              { label: t('form.gender.female'), value: 'Female' },
              { label: t('form.gender.other'), value: 'Other' },
            ]}
            value={value}
            onChange={onChange}
            errorMsg={errors.gender?.message}
          />
        )}
      />

      <Controller
        control={control}
        name='dateOfBirth'
        rules={{
          required: `${t('form.errors.date_of_birth')}`,
          validate: (value: string) =>
            value.split('-').every(Boolean)
              ? moment(value, 'YYYY-M-D').isValid() || `${t('form.errors.date_of_birth')}`
              : `${t('form.errors.date_of_birth_2')}`,
        }}
        render={({ value, onChange }) => (
          <UniDate
            label={t('form.date_of_birth.title')}
            value={value}
            onChange={(val: string) => {
              onChange(val);
            }}
            error={!!errors.dateOfBirth?.message}
            helperText={errors.dateOfBirth?.message}
            fullWidth
          />
        )}
      />

      <Controller
        control={control}
        name='countryOfBirth'
        rules={{ required: `${t('form.errors.country_of_birth')}` }}
        render={({ value, onChange }) => (
          <UniSelect
            value={countriesMap.find(({ value: v }) => v === value)}
            onChange={value => onChange(value?.value)}
            label={t('form.country_of_birth')}
            options={countriesMap}
            error={errors.countryOfBirth?.message}
          />
        )}
      />

      <Controller
        control={control}
        name='nationality'
        rules={{ required: `${t('form.errors.nationality')}` }}
        render={({ value, onChange }) => (
          <UniSelect
            value={countriesMap.find(({ value: v }) => v === value)}
            onChange={value => onChange(value?.value)}
            label={t('form.nationality.title1')}
            options={countriesMap}
            error={errors.nationality?.message}
          />
        )}
      />

      <Controller
        control={control}
        name='secondNationality'
        render={({ value, onChange }) => (
          <UniSelect
            value={countriesMap.find(({ value: v }) => v === value)}
            onChange={value => onChange(value?.value)}
            label={t('form.nationality.title2')}
            options={countriesMap}
          />
        )}
      />
    </>
  );
};

export default PersonalForm;
