import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { UniButton, UniTextField, UniTypography } from '../../../../components';
import { Colors } from '../../../../constants';
import { OnboardFormStep1 } from './AgentOnboard';

interface Props {
  show: boolean;
  data: {
    firstName: string;
    lastName: string;
  };
  nextStep: Function;
  onCancel: Function;
  setFormData: Function;
}

export default function AgentOnboardStep1({ show, nextStep, onCancel, setFormData, data }: Props): ReactElement {
  const { register, handleSubmit, errors } = useForm<OnboardFormStep1>({
    defaultValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      feeAmount: 0,
      feeCurrency: 'EUR',
      feeType: 'UPFRONT',
    },
  });

  const onSubmit = (formData: OnboardFormStep1) => {
    setFormData(formData);
    nextStep();
  };

  const firstNameError = errors?.firstName?.message ?? '';
  const lastNameError = errors?.lastName?.message ?? '';
  // const feeAmountError = errors?.feeAmount?.message ?? "";
  // const feeCurrencyError = errors?.feeCurrency?.message ?? "";

  return (
    <>
      <Box display={show ? 'inline' : 'none'} alignSelf='flex-start' width='100%' maxWidth='800px'>
        <Box mb={7}>
          <UniTypography variant='header1' weight='medium'>
            Basic Info
          </UniTypography>
          {/* <UniTypography>Loreum ipsum situm dolores</UniTypography> */}
        </Box>
        <UniTextField
          helperText={firstNameError}
          error={!!firstNameError}
          defaultValue={data.firstName}
          name='firstName'
          fullWidth
          label='First name'
          placeholder='First name!'
          inputRef={register({ required: 'First name must not be empty!' })}
          backgroundColor={Colors.Gray1._100}
          labelColor='typographyGray70'
        />
        <UniTextField
          helperText={lastNameError}
          error={!!lastNameError}
          name='lastName'
          defaultValue={data.lastName}
          fullWidth
          label='Last name'
          placeholder='Last name!'
          backgroundColor={Colors.Gray1._100}
          labelColor='typographyGray70'
          inputRef={register({ required: 'Last name must not be empty!' })}
        />
        <Box />
      </Box>
      <Box display={show ? 'flex' : 'none'} justifyContent='space-between' mt={4} width='100%'>
        <UniButton
          variant='text'
          style={{ maxWidth: '140px' }}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </UniButton>
        <UniButton color='primary' style={{ maxWidth: '160px' }} onClick={handleSubmit(onSubmit)}>
          Next Step
        </UniButton>
      </Box>
    </>
  );
}
