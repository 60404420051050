import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useRecommendProgram, useSearchCandidates } from 'api';
import { Components } from 'client/UniClient';
import { UniAvatar, UniCheckbox, UniTextField, UniTypography } from 'components';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { useDrawers } from 'store';
import { OptionType } from 'types';
import { UniModalDrawerContent } from '../../_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from '../../_common/uniModalDrawer/UniModalDrawerActions';
import { BrowserRoutes } from '../../../constants';

interface Props {
  program?: Components.Schemas.ProgramResponse;
}

interface SelectableStudentType extends OptionType {
  selected?: boolean;
  value: string;
}

const SuggestProgram: React.FC<Props> = ({ program }) => {
  const history = useHistory();
  const [query, setQuery] = useState('');
  const { closeDrawer } = useDrawers();
  const [selectableStudents, setSelectableStudents] = useState<SelectableStudentType[]>([]);
  const { data: students, status: studentsStatus, error: searchCandidatesError } = useSearchCandidates({});
  const [suggest, { status: suggestStatus, error: recommnedErrorProgram }] = useRecommendProgram();
  const [isLoadingStudents, setLoadingStudents] = useState(false);
  const [isLoadingSuggest, setLoadingSuggest] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const selectedStudents = selectableStudents.filter(item => item.selected);

  let timeout: any;

  const studentsList = matchSorter(selectableStudents, query, { keys: ['label'] });

  const onChangeSearch = (value: string) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => setQuery(value), 1000);
  };

  const onEnterSearch = (value: string) => {
    clearTimeout(timeout);
    setQuery(value);
  };

  const onChangeCheck = (value: boolean, id: string) => {
    setSelectableStudents(prev => {
      let aux = prev;
      const index = aux.findIndex(item => item.value === id);
      if (index !== -1) aux[index].selected = value;
      return [...aux];
    });
  };

  const onSubmit = () => {
    if (selectedStudents.length === 0) {
      setErrorMessage('Please select at least one student.');
    } else {
      const studentIds = selectedStudents.map(({ value }) => value);
      const programId = program?.id || '';
      setLoadingSuggest(true);
      suggest(
        {
          programId,
          studentIds,
        },
        {
          onSuccess() {
            closeDrawer();
            if (selectedStudents.length === 1) {
              const studentId = selectedStudents[0].value;
              history.push(BrowserRoutes.Agent.StudentProgram.General
                .replace(':programId', programId)
                .replace(':studentId', studentId));
            }
          },
        },
      ).then();
    }
  };

  useEffect(() => {
    setLoadingStudents(studentsStatus === 'loading');
    students && setSelectableStudents(students);
  }, [students, studentsStatus]);

  return (
    <>
      <UniModalDrawerContent>
        <UniTextField
          fullWidth
          placeholder='Search students...'
          onChange={e => onChangeSearch(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && onEnterSearch(e.currentTarget.value)}
        />
        {isLoadingStudents && <UniListLoader />}
        {!isLoadingStudents && (
          <Box flex={1}>
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={60}
                  rowCount={studentsList.length}
                  rowRenderer={({ key, index, style }) => {
                    const { label, value, selected } = studentsList[index];
                    return (
                      <Box
                        display='grid'
                        gridTemplateColumns='40px auto 40px'
                        gridColumnGap={2}
                        alignItems='center'
                        key={key}
                        mt={2}
                        style={style}
                      >
                        <UniAvatar size='S' label={label} />
                        <UniTypography>{label}</UniTypography>
                        <UniCheckbox checked={selected} onChange={e => onChangeCheck(e.target.checked, value)} />
                      </Box>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </Box>
        )}
        {errorMessage && <UniTypography color='stateAlert100'>{errorMessage}</UniTypography>}
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          label: selectedStudents.length === 1 ? 'Apply' : 'Suggest',
          disabled: selectedStudents.length === 0,
          loading: isLoadingSuggest,
          onClick: onSubmit,
        }}
        SecondaryButton={{ label: 'Cancel', onClick: closeDrawer }}
      />
    </>
  );
};

export default SuggestProgram;
