import UniModalDrawerTabs from 'components/_common/uniModalDrawer/UniModalDrawerTabs';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CandidateIcon } from '../../../assets/svg/Icons/Profile.svg';
import { Components } from '../../../client/UniClient';
import InviteAgentMultiple from './InviteAgentMultiple';
import InviteAgentSingle from './InviteAgentSingle';
import InviteAgentSuccess from './InviteAgentSuccess';

interface AgentWithStarred extends Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse> {
  starred?: boolean;
}
type AgencyInvitationProps = {
  close: Function;
  setAgentForResend?: Function;
  agentForResend?: AgentWithStarred & Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse>;
};
export default function InviteAgent({ close, setAgentForResend, agentForResend }: AgencyInvitationProps): ReactElement {
  const { t } = useTranslation('addAgentPage');
  const [tabValue, setTabValue] = useState(0);
  const [invitationSent, setInvitationSent] = useState(false);
  const [agentId, setAgentId] = useState('');
  const [invitationResent, setInvitationResent] = useState(false);
  const handleSuccess = (agentId: string) => {
    setInvitationSent(true);
    setAgentId(agentId);

    setTimeout(() => {
      setInvitationSent(false);
    }, 10000);
  };
  const handleResendSuccess = () => {
    setInvitationResent(true);
    setTimeout(() => {
      if (setAgentForResend != undefined) {
        setAgentForResend(undefined);
      }

      setInvitationResent(false);
    }, 10000);
  };
  return (
    <>
      {invitationSent || invitationResent ? (
        <InviteAgentSuccess
          isVisible={invitationSent || invitationResent}
          invitationResent={invitationResent}
          agentId={agentId}
          handleClose={close}
        />
      ) : (
        <>
          {!agentForResend && (
            <UniModalDrawerTabs
              setTabValue={setTabValue}
              tabValue={tabValue}
              tabs={[
                { title: t('tabs.one.title'), subtitle: t('tabs.one.subtitle'), Icon: <CandidateIcon /> },
                { title: t('tabs.multiple.title'), subtitle: t('tabs.multiple.subtitle'), Icon: <CandidateIcon /> },
              ]}
            />
          )}
          {tabValue === 0 && (
            <InviteAgentSingle
              agentForResend={agentForResend}
              handleResendSuccess={handleResendSuccess}
              handleClose={close}
              handleSuccess={handleSuccess}
            />
          )}
          {tabValue === 1 && <InviteAgentMultiple handleClose={close} handleSuccess={handleSuccess} />}
        </>
      )}
    </>
  );
}
