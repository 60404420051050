import { StyledTitleWrapper } from './uniLogo.style';
import { UniLogo, UniTypography } from 'components';
import { useBreakpoints } from 'hooks';

interface Props {
  title: string;
}
const UniLogoWithTitle: React.FC<Props> = ({ title }) => {
  const { isMobile } = useBreakpoints();
  return (
    <StyledTitleWrapper isMobile={isMobile}>
      <UniLogo size={isMobile ? 'S' : 'L'} stretched={true} />
      <UniTypography color='typographyBlack100' variant={isMobile ? 'body2' : 'header1'} weight='semi-bold'>
        {title}
      </UniTypography>
    </StyledTitleWrapper>
  );
};

export default UniLogoWithTitle;
