const CacheKeys = {
  Activities: {
    Default: 'Activities.Default',
    Plea: 'Activities.Plea',
  },
  Tasks: {
    Agent: 'Tasks.Agent',
    Student: 'Tasks.Student',
    Task: 'Tasks.Task',
  },
  Quizzes: {
    All: 'Quizzes.All',
    Incomplete: 'Quizzes.Incomplete',
  },
  Events: {
    All: 'Events.All',
    Interested: 'Events.Interested',
    Going: 'Events.Going',
    Page: 'Events.Page',
    InterestedCount: 'Events.InterestedCount',
    AttendingCount: 'Events.AttendingCount',
    GoingCount: 'Events.GoingCount',
    QRCode: 'Events.QRCode',
  },
  Orders: {
    All: 'Orders.All',
    Order: 'Orders.Order',
    Own: 'Orders.Own',
  },
  Products: {
    All: 'Products.All',
    Product: 'Products.Product',
  },
  PrepPacks: {
    PrepPack: 'PrepPacks.PrepPack',
    All: 'PrepPacks.All',
  },
  PurchasedPrepPacks: {
    All: 'PurchasedPrepPacks.All',
  },
  Tutorials: {
    All: 'Tutorials.All',
  },
  Workshops: {
    All: 'Workshops.All',
  },
  Internships: {
    All: 'Internships.All',
  },
  Achievement: {
    Default: 'Achievement.Default',
    Plea: 'Achievement.Plea',
  },
  ToDo: {
    Default: 'ToDo.Default',
    Plea: 'ToDo.Plea',
  },
  Admin: {
    Candidates: 'Admin.Candidates',
    CandidatesTEST: 'Admin.CandidatesTEST',
    All: 'Admin.All',
    Programs: 'Admin.Programs',
    AgencyFilters: 'Admin.AgencyFilters',
    Services: 'Admin.Services',
    Request: 'Admin.Request',
    ProgramRequests: 'Admin.ProgramRequests',
  },
  Agency: {
    All: 'Agency.All',
    Candidate: 'Agency.Candidate',
    Candidates: 'Agency.Candidates',
    CandidateNames: 'Agency.CandidateNames',
    SearchCandidates: 'Agency.SearchCandidates',
    Applications: 'Agency.Applications',
    University: 'Agency.University',
    Agent: 'Agency.Agent',
    Agents: 'Agency.Agents',
    CandidateFilters: 'Agency.CandidateFilters',
    ProgramFilters: 'Agency.ProgramFilters',
    AgencyNames: 'Agency.AgencyNames',
    Services: 'Agency.Services',
    Service: 'Agency.Service',
  },
  Agent: {
    All: 'Agent.All',
    Candidate: 'Agent.Candidate',
    Candidates: 'Agent.Candidates',
    CandidateNames: 'Agent.CandidateNames',
    Applications: 'Agent.Applications',
    University: 'Agent.University',
    Agent: 'Agent.Agent',
    Agents: 'Agent.Agents',
    CandidateFilters: 'Agent.CandidateFilters',
    ProgramFilters: 'Agent.ProgramFilters',
    AgencyNames: 'Agent.AgencyNames',
  },
  Universities: {
    ApplicationFilters: 'Universities.ApplicationFilters',
  },
  ServiceOptions: {
    All: 'ServiceOptions.All',
    Offerings: 'ServiceOptions.Offerings',
    Requests: 'ServiceOptions.Requests',
  },
  Applications: {
    All: 'Applications.All',
    Saved: 'Applications.Saved',
    Ongoing: 'Applications.Ongoing',
    Answered: 'Applications.Answered',
    Suggested: 'Applications.Suggested',
    Application: {
      Application: 'Applications.Application.Application',
      Credentials: 'Applications.Application.Credentials',
      Documents: 'Applications.Application.Documents',
      Export: 'Applications.Application.Export',
    }
  },
  Profile: {
    Default: 'Profile.User',
    File: 'Profile.User.File',
    AfterAuthPath: 'Profile.AfterLogin.Path',
    Session: 'Profile.Session',
    Extended: 'Profile.Extended',
  },
  Payments: { StripeCurrencies: 'Payments.StripeCurrencies' },
  General: {
    Program: 'General.Program',
    Application: 'General.Application',
    Programs: 'General.Programs',
    ProgramNames: 'General.ProgramNames',
    Filters: 'General.Filters',
    NewSession: 'General.NewSession',
    Notifications: 'General.Notifications',
    University: 'General.University',
    UniversityPromoted: 'General.UniversityPromoted',
  },
  Chat: {
    CreateRoom: 'Chat.CreateRoom',
    GetRoom: 'Chat.GetRoom',
    GetRooms: 'Chat.GetRooms',
    GetChatMessages: 'Chat.GetChatMessages',
  },
  Students: {
    Names: 'Students.Names',
  },
  Student: {
    Notes: 'Student.Notes',
    Orders: 'Student.Orders',
  },
  University: {
    Application: 'University.Application',
    Applications: 'University.Applications',
  },
  Search: {
    Students: 'Search.Students',
    Programs: 'Search.Programs',
    ProgramsAI: 'Search.ProgramsAI',
    Universities: 'Search.Universities',
    GroupedPrograms: 'Search.GroupedPrograms',
  },
  Filters: {
    Universities: 'Filters.Universties',
    Candidates: 'Filters.Candidates',
    Programs: 'Filters.Programs',
  },
  Programs: {
    SuggestProgram: 'Programs.SuggestProgram',
  },
} as const;

export default CacheKeys;
