import { Box } from '@material-ui/core';
import UniLogo from 'components/_common/uniLogo/uniLogo';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { useBreakpoints } from 'hooks';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AuthIllustration } from '../../../assets/svg/Illustrations/loginIllustration2.svg';
import { BrowserRoutes } from '../../../constants';
import { AuthLayoutWrapper, StyledChildrenWrapper, StyledFormWrapper, StyledHeader, StyledIllustrationWrapper } from './AuthLayout.style';

interface Props {
  title: string;
}

const AuthLayout: React.FC<Props> = ({ title, children }) => {
  const history = useHistory();
  const { isMobile, isTablet } = useBreakpoints();
  const onGoToHome = () => history.push(BrowserRoutes.Default.Homepage);

  return (
    <AuthLayoutWrapper>
      <StyledFormWrapper isMobile={isMobile}>
        <StyledHeader isMobile={isMobile}>
          <UniLogo size={isMobile ? 'S' : 'L'} onClick={onGoToHome} />
          <Box my={isMobile ? 0 : 5}>
            <UniTypography
              weight='semi-bold'
              variant={isMobile ? 'header3' : 'xl'}
              color={isMobile ? 'typographyGray100' : 'typographyGray30'}
            >
              {title}
            </UniTypography>
          </Box>
        </StyledHeader>
        <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
      </StyledFormWrapper>
      {!isTablet && (
        <StyledIllustrationWrapper>
          <AuthIllustration />
        </StyledIllustrationWrapper>
      )}
    </AuthLayoutWrapper>
  );
};

export default AuthLayout;
