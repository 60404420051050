import { Box } from '@material-ui/core';
import { EmptyState, ListFooter, UniListHeader } from 'components';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import React, { ReactElement } from 'react';
import { StyledGrid } from './InfiniteWindowedGrid.style';
import InfiniteWindowedGridItem from './WindowedWrapper';

interface Props {
  fetchMore?: Function;
  emptyStateType: 'programs' | 'suggested' | 'saved' | 'applications' | 'status' | 'students' | 'tasks';
  hasMore?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  results: ReactElement[];
  remainingResults?: number;
  totalResults?: number;
  sortOptions?: { value: any; label: string; hasIcon?: boolean }[];
  minHeight?: number;
  LeftComponent?: React.ReactNode;
}

const InfiniteWindowedGrid: React.FC<Props> = ({
  fetchMore,
  sortOptions,
  hasMore,
  totalResults,
  isLoading,
  isFetching,
  remainingResults,
  results,
  minHeight,
  LeftComponent,
  emptyStateType,
}) => {
  const showEmptyList = results.length === 0 && !isLoading;
  const showListFooter = !isLoading && !showEmptyList && hasMore;

  return (
    <>
      <Box display='flex'>
        {LeftComponent}
        {(!!sortOptions || totalResults !== undefined) && !showEmptyList && <UniListHeader options={sortOptions} count={totalResults} />}
      </Box>
      <Box mt={2} />
      <StyledGrid>
        {results.map((item, index) => (
          <InfiniteWindowedGridItem minHeight={minHeight} key={index}>
            {item}
          </InfiniteWindowedGridItem>
        ))}
        {results.length < 4 &&
          !showEmptyList &&
          Array(4 - results.length)
            .fill(0)
            .map((_, index) => <Box key={index} />)}
      </StyledGrid>

      {isLoading && <UniListLoader />}
      {showEmptyList && <EmptyState type={emptyStateType} />}
      <Box mt={3} />
      {showListFooter && remainingResults && fetchMore && (
        <ListFooter onClickMore={fetchMore} nrPrograms={remainingResults} isLoading={!!isFetching} />
      )}
    </>
  );
};

export default InfiniteWindowedGrid;
