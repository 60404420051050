import React from 'react';
import styled from 'styled-components';
import { FormControl, FormControlProps } from '@material-ui/core';

export const UniCurrencyWrapper = styled.div`
  display: flex;
`;
interface StyledFormControlProps {
  hasLabel: boolean;
  backgroundColor?: any;
}

export const Container = styled(
  ({ hasLabel, backgroundColor, ...rest }: StyledFormControlProps & Omit<FormControlProps, keyof StyledFormControlProps>) => (
    <FormControl {...rest} />
  ),
)`
  display: grid;
  justify-items: start;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  min-height: ${({ hasLabel }) => (hasLabel ? '104px' : '60px')};
  grid-template-rows: ${({ hasLabel }) => (hasLabel ? '24px 1fr' : '1fr')};
  .MuiInputBase-root {
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'white')};
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

export const Label = styled.div`
  max-height: 22px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;
