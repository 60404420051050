import { Box, useMediaQuery } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniTextWithIcon } from 'components/_common/uniMetaData/UniMetaData';
import { countries } from 'constants/countries';
import { useBreakpoints } from 'hooks';
import { default as React, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { ReactComponent as FeeIcon } from '../../assets/svg/Icons/Program/Fee.svg';
import { ReactComponent as GraduationHatIcon } from '../../assets/svg/Icons/Program/GraduationHat.svg';
import { ReactComponent as HourglassIcon } from '../../assets/svg/Icons/Program/Hourglass.svg';
import UniIconWrapper from '../_common/uniIconWrapper/UniIconWrapper';
import UniStatus from '../_common/uniStatus/UniStatus';
import UniTypography from '../_common/uniTypography/UniTypography';
import UniCheckbox from '../_inputs/uniCheckbox/UniCheckbox';
import {
  StyledProgramListItem,
  StyledProgramListItemBody,
  StyledProgramListItemBodyGrid,
  StyledProgramListItemDivider,
  StyledProgramListItemHeader,
  StyledProgramMetaItem,
  StyledProgramMetaItemContent,
  StyledSubmissionDatesBody,
  StyledSubmissionDatesHeader,
} from './ProgramsList.style';

interface Props {
  program: Components.Schemas.ProgramResponse;
  stretch?: boolean;
  ActionButtons: React.ReactNode;
  onClick?: Function;
  selected?: boolean;
  selectProgram?: Function;
  candidate?: Components.Schemas.UserDto;
  hideBottomDates?: boolean;
  variant?: 'table' | 'list';
}

export type actionType =
  | 'FAVORITE'
  | 'NEW'
  | 'VALIDATED'
  | 'SUBMITTED'
  | 'IN_REVIEW'
  | 'INCOMPLETE'
  | 'CONDITIONAL_ADMISSION'
  | 'PASSED'
  | 'FAILED'
  | undefined;
function ProgramsListItem({
  ActionButtons,
  program,
  stretch = false,
  selectProgram,
  selected,
  onClick,
  hideBottomDates = false,
  variant = 'table',
  candidate,
}: Props): ReactElement {
  const hideSubmissonDates = useMediaQuery('(max-width: 1000px)') || hideBottomDates;
  const { t } = useTranslation('programsList');
  const {
    lengthOfStudy,
    levelOfStudy,
    address,
    name,
    universityName,
    admissionStartDate,
    admissionEndDate,
    application,
    languageOfInstruction,
    lectureStartDate,
    isFavorite,
  } = program;

  const discipline = program?.discipline?.replaceAll('_', ' ').toLocaleLowerCase();
  const hasStatus = application?.status || isFavorite;
  const { isMobile } = useBreakpoints();

  const { role } = useAuth(state => state.profile);
  const { isMaster, isUniversity } = getMetaRole(role!);

  if (variant === 'list') {
    return (
      <StyledProgramListItem onClick={() => onClick && onClick()}>
        <StyledProgramListItemHeader stretch={stretch}>
          <Box display='flex'>
            {(isMaster || isUniversity) && (
              <Box mt={-1}>
                <UniCheckbox
                  checked={selected}
                  onClick={e => e.stopPropagation()}
                  onChange={e => {
                    e.stopPropagation();
                    selectProgram && selectProgram(program);
                  }}
                />
              </Box>
            )}
            <Box>
              <UniTypography weight='semi-bold' color='primary100'>
                {name}
              </UniTypography>
            </Box>
          </Box>
          <UniTextWithIcon
            label={'Location'}
            value={`${address?.city ?? '-'}, ${countries?.find(item => item.code === address?.country)?.name ?? '-'}`}
          />
          <UniTextWithIcon label={'Level of Study'} value={levelOfStudy} />
          <UniTextWithIcon label={'Submission Deadline'} value={admissionEndDate} />
          {!isMobile && (
            <Box
              display='flex'
              alignItems='flex-start'
              justifyContent='flex-end'
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {ActionButtons}
            </Box>
          )}
        </StyledProgramListItemHeader>
        <StyledProgramListItemDivider />
        <StyledProgramListItemBodyGrid stretch={stretch}>
          <Box>
            <UniTypography weight='semi-bold' color='secondary100'>
              {universityName}
            </UniTypography>
          </Box>
          <UniTextWithIcon
            Icon={
              <UniIconWrapper size='medium' color='secondary100' withBackground>
                <GraduationHatIcon />
              </UniIconWrapper>
            }
            label={'Language of Instruction'}
            value={countries?.find(item => item.code === languageOfInstruction)?.name}
          />
          <UniTextWithIcon
            Icon={
              <UniIconWrapper size='medium' color='otherYellow100' withBackground>
                <HourglassIcon />
              </UniIconWrapper>
            }
            label={'Start Date'}
            value={lectureStartDate}
          />
          <UniTextWithIcon
            Icon={
              <UniIconWrapper size='medium' color='otherPurple100' withBackground>
                <FeeIcon />
              </UniIconWrapper>
            }
            label={'Tuition fee'}
            ValueComponent={
              !!program?.tuitionFee?.currency?.amount ? (
                <UniTypography color='typographyGray100' weight='semi-bold'>
                  {`${program.tuitionFee?.currency.amount} ${program.tuitionFee?.currency.currencyIso}`}
                </UniTypography>
              ) : program.tuitionFeeDescription?.includes('http') ? (
                <a href={program.tuitionFeeDescription} target='_blank'>
                  <UniTypography color='primary100' weight='semi-bold'>
                    See here
                  </UniTypography>
                </a>
              ) : (
                program.tuitionFeeDescription?.split('**')?.map(item => (
                  <UniTypography color='typographyGray100' weight='semi-bold'>
                    {item}
                  </UniTypography>
                )) || '-'
              )
            }
          />
        </StyledProgramListItemBodyGrid>
        {isMobile && (
          <Box
            mr={4}
            display='flex'
            alignItems='flex-start'
            justifyContent='flex-end'
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {ActionButtons}
          </Box>
        )}
      </StyledProgramListItem>
    );
  }

  return (
    <StyledProgramListItem onClick={() => onClick && onClick()}>
      <StyledProgramListItemHeader stretch={stretch}>
        {isMobile && (
          <Box display='flex' justifyContent='flex-end' mb={2}>
            {hasStatus ? (
              <UniStatus program={program} />
            ) : (
              <UniTypography color='typographyGray100' weight='semi-bold' variant='body2'>
                {admissionEndDate || '-'}
              </UniTypography>
            )}
          </Box>
        )}
        <Box display='flex'>
          {(isMaster || isUniversity) && (
            <Box mt={-1}>
              <UniCheckbox
                checked={selected}
                onClick={e => e.stopPropagation()}
                onChange={e => {
                  e.stopPropagation();
                  selectProgram && selectProgram(program);
                }}
              />
            </Box>
          )}
          <Box>
            <UniTypography weight='semi-bold' color='primary100'>
              {name}
            </UniTypography>
            <UniTypography weight='semi-bold' color='secondary100'>
              {universityName}
            </UniTypography>
          </Box>
        </Box>
        <UniTypography color='typographyGray100' weight='semi-bold' variant='body2'>
          {`${address?.city ?? '-'}, ${address?.country ?? '-'}`}
        </UniTypography>
        <UniTypography color='typographyGray100' weight='semi-bold' variant='body2'>
          {levelOfStudy || '-'}
        </UniTypography>
        {!isMobile &&
          (hasStatus ? (
            <UniStatus program={program} />
          ) : (
            <UniTypography color='typographyGray100' weight='semi-bold' variant='body2'>
              {admissionEndDate || '-'}
            </UniTypography>
          ))}
        {!isMobile && (
          <Box
            display='flex'
            alignItems='flex-start'
            justifyContent='flex-end'
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {ActionButtons}
          </Box>
        )}
      </StyledProgramListItemHeader>
      <StyledProgramListItemDivider />
      <StyledProgramListItemBody>
        <StyledProgramMetaItem>
          <UniIconWrapper size='medium' color='secondary100' withBackground>
            <HourglassIcon />
          </UniIconWrapper>
          <StyledProgramMetaItemContent>
            <UniTypography color='typographyGray70'>{t('program.length')}</UniTypography>
            <UniTypography color='typographyGray100' weight='semi-bold'>
              {`${lengthOfStudy?.value || '-'} ${lengthOfStudy?.unit || ''}`}
            </UniTypography>
          </StyledProgramMetaItemContent>
        </StyledProgramMetaItem>
        <StyledProgramMetaItem>
          <UniIconWrapper size='medium' color='secondary100' withBackground>
            <GraduationHatIcon />
          </UniIconWrapper>
          <StyledProgramMetaItemContent>
            <UniTypography color='typographyGray70'>{t('program.discipline')}</UniTypography>
            <UniTypography color='typographyGray100' weight='semi-bold'>
              {discipline || '-'}
            </UniTypography>
          </StyledProgramMetaItemContent>
        </StyledProgramMetaItem>
        <StyledProgramMetaItem>
          <UniIconWrapper size='medium' color='otherPurple100' withBackground>
            <FeeIcon />
          </UniIconWrapper>
          <StyledProgramMetaItemContent>
            <UniTypography color='typographyGray70'>{t('program.tuition_fee')}</UniTypography>
            {!!program?.tuitionFee?.currency?.amount ? (
              <UniTypography color='typographyGray100' weight='semi-bold'>
                {`${program.tuitionFee?.currency.amount} ${program.tuitionFee?.currency.currencyIso}`}
              </UniTypography>
            ) : program.tuitionFeeDescription?.includes('http') ? (
              <a href={program.tuitionFeeDescription} target='_blank'>
                <UniTypography color='primary100' weight='semi-bold'>
                  See here
                </UniTypography>
              </a>
            ) : (
              program.tuitionFeeDescription?.split('**')?.map(item => (
                <UniTypography color='typographyGray100' weight='semi-bold'>
                  {item}
                </UniTypography>
              )) || '-'
            )}
          </StyledProgramMetaItemContent>
        </StyledProgramMetaItem>
      </StyledProgramListItemBody>
      {!hideSubmissonDates && !stretch && (
        <Box>
          <StyledSubmissionDatesHeader>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('program.starting_dates')}
            </UniTypography>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('program.deadlines')}
            </UniTypography>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('program.status')}
            </UniTypography>
          </StyledSubmissionDatesHeader>
          <StyledSubmissionDatesBody>
            <UniTypography color='typographyGray100' variant='footnote'>
              {admissionStartDate}
            </UniTypography>
            <UniTypography color='typographyGray100' variant='footnote'>
              {admissionEndDate}
            </UniTypography>
            <UniStatus></UniStatus>
          </StyledSubmissionDatesBody>
        </Box>
      )}
      {isMobile && (
        <Box
          mb={2}
          mt={2}
          mr={4}
          display='flex'
          alignItems='flex-start'
          justifyContent='flex-end'
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {ActionButtons}
        </Box>
      )}
    </StyledProgramListItem>
  );
}

export default React.memo(ProgramsListItem);
