import { AxiosError } from 'axios';
import { CacheKeys } from '../../constants';
import { queryCache, useMutation } from 'react-query';
import { useAuth, useClient } from '../../store';

const useDeleteAdditionalDocument = () => {
  const { setProfile } = useAuth();
  const { client } = useClient();

  return useMutation(
    async (payload: string) => {
      return client!.FileApi_deleteDocument({ fileId: payload }).then(() => client!.UserApi_getMyUser());
    },

    {
      onSuccess: async ({ data }) => {
        setProfile(data);
        queryCache.invalidateQueries(CacheKeys.Agency.Candidate);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default useDeleteAdditionalDocument;
