import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Paths } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Payload = Paths.ToDoApiDeleteToDoDocument.PathParameters;

const useDeleteTodoFile = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.ToDoApi_deleteToDoDocument(payload);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.ToDo.Default);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default useDeleteTodoFile;
