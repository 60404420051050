import { useQuery } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

const useStripeCurrencies = () => {
  const { client } = useClient();
  return useQuery(CacheKeys.Payments.StripeCurrencies, async () => {
    const { data } = await client!.PaymentApi_getStripeCurrencies();

    return (data as string[]).map(item => ({ text: item, value: item }));
  });
};

export default useStripeCurrencies;
