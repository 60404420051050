import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniTypography, UniIconWrapper } from 'components';
import { default as React, ReactElement } from 'react';
import { ReactComponent as LocationIcon } from '../../assets/svg/Icons/Location.svg';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { countries } from '../../constants/countries';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from 'hooks';

interface Props {
  university?: Components.Schemas.UniversityResponse;
}

export default function UniversityPageGeneralLocation({ university }: Props): ReactElement {
  const { t } = useTranslation('universityPage');
  const { isMobile } = useBreakpoints();
  const containerStyle = {
    width: '100%',
    height: isMobile ? '240px' : '480px',
    borderRadius: '10px',
  };

  return (
    <>
      {university?.address?.lat && university?.address?.lng ? (
        <>
          <Box display='flex' mb={isMobile ? 2 : 3} mt={isMobile ? 2 : 3}>
            <Box mr={2}>
              <UniIconWrapper color='typographyBlack100'>
                <LocationIcon />
              </UniIconWrapper>
            </Box>
            <UniTypography color='typographyBlack100' variant='subheader'>{`${
              university?.address?.city ? university?.address?.city : '-'
            }, ${university?.address?.state ? university?.address?.state : '-'}, ${
              university?.address?.country
                ? countries.filter(item => item.code === university?.address?.country).map(item => item.name)[0]
                : '-'
            }`}</UniTypography>
          </Box>
          <LoadScript googleMapsApiKey='AIzaSyAwpj0EopHdk06NzHxgIlOAGCl1WEGx6Gc'>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ lat: university?.address?.lat, lng: university?.address?.lng }}
              zoom={15}
            >
              <Marker position={{ lat: university?.address?.lat, lng: university?.address?.lng }}></Marker>
            </GoogleMap>
          </LoadScript>
        </>
      ) : (
        <Box textAlign='center'>
          <UniTypography color='typographyGray100' variant={isMobile ? 'body2' : 'body1'}>
            {t('general.sections.no_location')}
          </UniTypography>
        </Box>
      )}
    </>
  );
}
