import useUpdateStudent from 'api/students/useUpdateStudent';
import { Components } from 'client/UniClient';
import { UniIconWrapper, UniMetrics } from 'components';
import ShowableItem from 'components/showableItem/ShowableItem';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location24x24.svg';
import { ReactComponent as MailIcon } from '../../../assets/svg/Icons/Mail.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/svg/Icons/PhoneNumber.svg';

interface Props {
  isPreview: boolean;
  user: Components.Schemas.UserDto;
}

const EditPublicProfileMetrics: React.FC<Props> = ({ user, isPreview }) => {
  const { t } = useTranslation('studentProfile');
  const [postPersoalInfo, { status: personalInfoStatus }] = useUpdateStudent();

  const toggleMetrics = (value: boolean) => {
    let studentProfile = user?.studentProfile;
    if (!studentProfile) return;
    const publicProfilePreferences = { ...studentProfile.publicProfilePreferences, publishMetrics: value };
    postPersoalInfo({ studentProfile: { publicProfilePreferences } });
  };

  const metricsStatus = !!user?.studentProfile?.publicProfilePreferences?.publishMetrics;

  const metrics = [
    {
      label: t('labels.header.country'),
      value: user?.studentProfile?.address?.country || '-',
      Icon: (
        <UniIconWrapper color='secondary100'>
          <LocationIcon />
        </UniIconWrapper>
      ),
    },
    {
      label: t('labels.header.phone_number'),
      value: user?.phone || '-',
      Icon: (
        <UniIconWrapper color='secondary100'>
          <PhoneIcon />
        </UniIconWrapper>
      ),
    },
    {
      label: t('labels.header.email'),
      value: user?.email || '-',
      Icon: (
        <UniIconWrapper color='secondary100'>
          <MailIcon />
        </UniIconWrapper>
      ),
    },
  ];

  return (
    <ShowableItem disabled={isPreview} show={metricsStatus} onChange={toggleMetrics}>
      <UniMetrics variant='iconed' size='large' values={metrics} />
    </ShowableItem>
  );
};

export default EditPublicProfileMetrics;
