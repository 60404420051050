import { Box } from '@material-ui/core';
import { UniBounceLoader, UniTypography } from 'components';
import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledFaculties } from './FacultiesList.style';

interface Props {
  length: number;

  isLoading?: boolean;
  children?: ReactElement[] | ReactElement;
}
const FacultiesList = ({ length, isLoading, children }: Props) => {
  const { t } = useTranslation('universityFacultiesPage');
  const showEndOfList = true;
  const showEmptyList = length === 0 && !isLoading;
  return (
    <>
      <StyledFaculties>
        {children}
        {showEmptyList && (
          <Box mx={4}>
            <UniTypography color='primary100' weight='medium'>
              {t('list.no_faculties')}
            </UniTypography>
          </Box>
        )}
        {isLoading && <UniBounceLoader />}
      </StyledFaculties>
      {showEndOfList && (
        <Box mt={3}>
          <UniTypography color='typographyGray70'>You have reached the end of the list.</UniTypography>
        </Box>
      )}
    </>
  );
};
export default FacultiesList;
