import React, { useState } from 'react';
import Select from 'react-select';
import { ReactComponent as ShoppingCart } from 'assets/svg/Icons/Payments.svg';
import { Components } from 'client/UniClient';
import { languagesTutors, mathTutors, scienceTutors } from 'constants/tutors';
import { getPrepPackPriceRange } from 'utils';
import {
  StyledContentSection, StyledCurriculumItem, StyledDescription,
  StyledNavigationSection,
  StyledPrepCenterProductDetailsWrapper, StyledPrepCenterProductDropdownLabel,
  StyledPrepCenterProductHeading,
  StyledPrepCenterProductTitleSection, StyledPrepCenterProductViewLogo,
  StyledPrepCenterProductViewPrice,
  StyledPrepCenterProductViewTitle,
  StyledPrepCenterProductWrapper,
} from './UniPrepCenterProductView.style';
import { UniButton, UniIconWrapper } from '../index';
import UniTutorList from '../tutorList/UniTutorList';
import { useTranslation } from 'react-i18next';

type ProductDto = Components.Schemas.ProductDto;
type PrepPackDto = Components.Schemas.PrepPackDto;

interface Props {
  product: ProductDto;
  prepPacks: PrepPackDto[];
  addToCart?: (prepPack: PrepPackDto) => any;
}

export const UniPrepCenterProductView: React.FC<Props> = ({ product, prepPacks, addToCart }) => {
  const { t } = useTranslation('prepCenter');
  const priceRange = getPrepPackPriceRange(prepPacks);
  const prepPackOptions = prepPacks.map(prepPack => ({
    value: prepPack.id!,
    label: prepPack.sessions === 1 ? '1 session' : `${prepPack.sessions} sessions`,
  }));
  const [selectedPrepPackOption, setSelectedPrepPackOption] = useState(prepPackOptions.length > 0 ? prepPackOptions[0] : null);
  const selectedPrepPack = selectedPrepPackOption && prepPacks.find(prepPack => prepPack.id === selectedPrepPackOption.value);
  const fullPrice = selectedPrepPack?.fullPrice == null ? null : `${selectedPrepPack.fullPrice.amount} ${selectedPrepPack.fullPrice.currencyIso}`;
  const price = selectedPrepPack == null ? '' : `${selectedPrepPack.price.amount} ${selectedPrepPack.price.currencyIso}`;
  const [activeView, setActiveView] = useState(0);
  const tutorsToDisplay = product.teachers?.length > 0
    ? product.teachers.map(t => t.name)
    : product.category === 'Mathematics'
      ? mathTutors
      : product.category === 'Science'
        ? scienceTutors
        : languagesTutors;

  return (
    <StyledPrepCenterProductWrapper>
      <StyledPrepCenterProductHeading>
        <StyledPrepCenterProductViewLogo src={selectedPrepPack?.logoFile?.location ?? product.logoFile?.location} alt={`Product ${product.id} logo`}/>
        <StyledPrepCenterProductTitleSection>
          <StyledPrepCenterProductViewTitle>{product.name}</StyledPrepCenterProductViewTitle>
          <StyledPrepCenterProductViewPrice>{priceRange}</StyledPrepCenterProductViewPrice>
          <div style={{ color: 'orange', fontSize: 24, fontWeight: 600, marginBottom: 6 }}>__</div>
          <StyledPrepCenterProductDropdownLabel>Amount</StyledPrepCenterProductDropdownLabel>
          <Select
            name='Amount'
            value={selectedPrepPackOption}
            options={prepPackOptions}
            onChange={value => setSelectedPrepPackOption(value)}
            isDisabled={prepPackOptions.length === 0}
          />
          <StyledPrepCenterProductViewPrice>
            {fullPrice && (
              <span>
                <span style={{ color: 'red', textDecoration: 'line-through' }}>{fullPrice}</span>
                &nbsp;
              </span>
            )}
            {price}
          </StyledPrepCenterProductViewPrice>
          {addToCart && (
            <UniButton
              startIcon={(
                <UniIconWrapper color='white100'>
                  <ShoppingCart />
                </UniIconWrapper>
              )}
              disabled={!selectedPrepPack}
              onClick={() => {
                if (selectedPrepPack != null) {
                  addToCart(selectedPrepPack);
                }
              }}
              style={{ marginTop: 10 }}
            >
              Purchase
            </UniButton>
          )}
        </StyledPrepCenterProductTitleSection>
      </StyledPrepCenterProductHeading>
      <StyledPrepCenterProductDetailsWrapper>
        <StyledNavigationSection>
          <UniButton
            color={activeView === 0 ? 'primary' : 'neutral'}
            onClick={() => setActiveView(0)}
            style={{ marginBottom: 8, width: 220 }}
          >
            {t('product_page.description')}
          </UniButton>
          <UniButton
            color={activeView === 1 ? 'primary' : 'neutral'}
            onClick={() => setActiveView(1)}
            style={{ marginBottom: 8, width: 220 }}
          >
            {t('product_page.curriculum')}
          </UniButton>
          <UniButton
            color={activeView === 2 ? 'primary' : 'neutral'}
            onClick={() => setActiveView(2)}
            style={{ marginBottom: 8, width: 220 }}
          >
            {t('product_page.tutors')}
          </UniButton>
        </StyledNavigationSection>
        <StyledContentSection>
          {activeView === 0 && <StyledDescription>{selectedPrepPack?.description ?? product.description}</StyledDescription>}
          {activeView === 1 && product.curriculum?.map(item => (
            <StyledCurriculumItem>{item}</StyledCurriculumItem>
          ))}
          {activeView === 2 && (
            <UniTutorList tutorsToDisplay={tutorsToDisplay} />
          )}
        </StyledContentSection>
      </StyledPrepCenterProductDetailsWrapper>
    </StyledPrepCenterProductWrapper>
  );
};

export default UniPrepCenterProductView;
