import { List, ListItem, ListItemProps } from '@material-ui/core';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Colors, Shadows } from '../../../constants';

export const StyledMobileWrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${Colors.Gray2._40};
  box-shadow: ${Shadows.shadow4};
  overflow-y: hidden;
  transition: all 1s;
  z-index: 100;
  padding-right: 60px;

  * {
    z-index: 1;
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${Colors.White._100};
    z-index: 0;
    top: 0;
    left: 0;
  }
`;

interface StyledListProps extends ListItemProps {
  component?: React.ElementType;
}

export const StyledList = styled(List)<StyledListProps>`
  height: ${({ theme }) => theme.spacing(7)}px;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
`;

interface StyledListItemProps extends ListItemProps {
  component?: React.ElementType;
}

export const StyledListItem = styled(ListItem)<StyledListItemProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`;
