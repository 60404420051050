import { Box } from '@material-ui/core';
import { motion, useAnimation, Variants } from 'framer-motion';
import { useBreakpoints } from 'hooks';
import useOnScreen from 'hooks/useOnScreen';
import { ReactElement, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import privacyDoc from 'assets/docs/Youni Privacy Policy.pdf';
import termsDoc from 'assets/docs/T&Cs Uniapply Platform.pdf';
import { ReactComponent as DiscordIcon } from 'assets/svg/Icons/DiscordIcon.svg';
import { ReactComponent as FacebookIcon } from 'assets/svg/Icons/FacebookIcon.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/Icons/InstagramIcon.svg';
import { ReactComponent as LinkedinIcon } from 'assets/svg/Icons/LinkedinIcon.svg';
import { ReactComponent as WhatsappIcon } from 'assets/svg/Icons/WhatsAppIcon.svg';
import { UniLogo, UniTypography } from 'components';
import { BrowserRoutes } from '../../../constants';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import UniTypographyHighlight from '../uniTypographyHighlight/UniTypographyHighlight';
import {
  StyledBottomFooter,
  StyledFooterContainer,
  StyledIllustration,
  StyledLink,
  StyledUpperFooter,
  StyledYouniLogoBadge,
} from './HomepageFooter.style';
import FeedbackForm from '../../_forms/FeedbackForm';

interface Props {}

export default function HomepageFooter({ ...props }: Props): ReactElement {
  const { t } = useTranslation('homepageFooter');
  const controls = useAnimation();
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  const { isMobile } = useBreakpoints();

  const headlineVariants: Variants = {
    hidden: { translateX: -10, opacity: 0 },
    visible: {
      translateX: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  useMemo(() => {
    if (isVisible) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [isVisible]);

  return (
    <StyledFooterContainer id='footer-section' {...props}>
      <FeedbackForm />
      <StyledUpperFooter>
        <Box maxWidth={isMobile ? '100%' : 456} mr={8}>
          <motion.div ref={ref} variants={headlineVariants} initial='hidden' animate={controls}>
            <UniTypographyHighlight variant={isMobile ? 'header2' : 'xl'} weight='semi-bold'>
              Join our global community
            </UniTypographyHighlight>
          </motion.div>
          <Box mt={1} />
          <UniTypography variant='body1'>Interact with peers, experts, tutors, professors, alumni, and the Youni team.</UniTypography>
          <Box mt={5} />
          <Box display='flex' ml={-1}>
            <UniIconWrapper
              size='medium2'
              color='secondary70'
              onClick={() => window.open('https://www.instagram.com/younichoice/', '_blank')}
            >
              <InstagramIcon />
            </UniIconWrapper>
            <UniIconWrapper
              size='medium2'
              color='secondary70'
              onClick={() => window.open('https://www.facebook.com/younichoice/', '_blank')}
            >
              <FacebookIcon />
            </UniIconWrapper>
            <UniIconWrapper
              size='medium2'
              color='secondary70'
              onClick={() => window.open('https://www.linkedin.com/company/younichoice/mycompany/', '_blank')}
            >
              <LinkedinIcon />
            </UniIconWrapper>
            <UniIconWrapper size='medium2' color='secondary70' onClick={() => window.open('https://discord.gg/vdpQ2rN7VJ', '_blank')}>
              <DiscordIcon />
            </UniIconWrapper>
            <UniIconWrapper size='medium2' color='secondary70' onClick={() => window.open('https://wa.me/40774051652', '_blank')}>
              <WhatsappIcon />
            </UniIconWrapper>
          </Box>
        </Box>
        <Link to={BrowserRoutes.Default.Homepage}>
          <StyledYouniLogoBadge />
        </Link>
        <StyledIllustration />
      </StyledUpperFooter>
      <StyledBottomFooter>
        <Box ml={3} display='flex' alignItems='center'>
          <Link to={BrowserRoutes.Default.Homepage}>
            <UniLogo light text size='L' />
          </Link>
          <Box ml={3} />
          <UniTypography weight='regular' variant='subheader' color='white100'>
            Youni &#169; 2024 Made with Love for Education
          </UniTypography>
        </Box>
        <Box display='flex' alignItems='center'>
          <StyledLink href={termsDoc} target='_blank' rel='noopener noreferrer'>
            <UniTypography weight='regular' variant='subheader' color='white100'>
              {t('terms')}
            </UniTypography>
          </StyledLink>
          <UniTypography weight='regular' variant='subheader' color='white100'>
            •
          </UniTypography>
          <StyledLink href={privacyDoc} target='_blank' rel='noopener noreferrer'>
            <UniTypography weight='regular' variant='subheader' color='white100'>
              {t('privacy')}
            </UniTypography>
          </StyledLink>
          <Box ml={3} />
          <UniIconWrapper size='medium' color='white100' onClick={() => window.open('https://www.instagram.com/uniapply_org/', '_blank')}>
            <InstagramIcon />
          </UniIconWrapper>
          <UniIconWrapper color='white100' onClick={() => window.open('https://www.facebook.com/uniapply.org/', '_blank')}>
            <FacebookIcon />
          </UniIconWrapper>
          <UniIconWrapper color='white100' onClick={() => window.open('https://www.linkedin.com/company/uniapply/mycompany/', '_blank')}>
            <LinkedinIcon />
          </UniIconWrapper>
          <UniIconWrapper color='white100' onClick={() => window.open('https://discord.gg/vdpQ2rN7VJ', '_blank')}>
            <DiscordIcon />
          </UniIconWrapper>
          <UniIconWrapper color='white100' onClick={() => window.open('https://wa.me/40774051652', '_blank')}>
            <WhatsappIcon />
          </UniIconWrapper>
        </Box>
      </StyledBottomFooter>
    </StyledFooterContainer>
  );
}
