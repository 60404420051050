import React from 'react';
import UniFilterBar from '../uniFilterBar/UniFilterBar';
import UniTaskFilterBarForm from './UniTaskFilterBarForm';

interface Props {
  onClickFilter?: Function;
  options?: string[];
  title?: string;
  overflow?: 'hidden' | 'auto' | 'visible' | 'none';
  withCandidateFilter?: boolean;
}

export const UniTaskFilterBar: React.FC<Props> = ({ onClickFilter, options, overflow, title = 'Filter tasks', withCandidateFilter }) => {
  return (
    <UniFilterBar overflow={overflow} title={title}>
      <UniTaskFilterBarForm onClickFilter={onClickFilter} options={options} withCandidateFilter={withCandidateFilter} />
    </UniFilterBar>
  );
};

export default UniTaskFilterBar;
