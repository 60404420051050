import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  //agencyId: string;
};

export default function useGetMyServices(params?: Params) {
  const { client } = useClient();
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile.role);
  const agencyId = (isStudent ? profile.studentProfile?.agent.agentProfile.agency.id : profile.agentProfile?.agency?.id) || '';

  return useQuery(
    CacheKeys.Agency.Services,
    async (_key: string, params: Params) => {
      const response = await client!.AgencyApi_getServiceOfferings({ agencyId });
      return response.data;
    },
    { onError(error: AxiosError) {}, refetchOnWindowFocus: false },
  );
}
