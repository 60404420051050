import { AxiosError } from 'axios';
import { QueryConfig, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

type Params = {
  studentId: string;
  agencyId?: string;
};

export default function useCandidate(params: Params, config?: QueryConfig<Error>) {
  const history = useHistory();
  const agencyId = useAuth(state => state.profile.agentProfile?.agency?.id);
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Agency.Candidate, params],
    async (_key: string, params: Params) => {
      //Swap promise with getSingleStudent
      if (params.studentId) {
        const { data } = await client!.AgencyApi_getStudent({
          agencyId: params?.agencyId! || agencyId!,
          studentId: params.studentId,
        });
        return data;
      }
    },
    {
      refetchOnWindowFocus: false,
      onError(err: AxiosError) {
        // if (err.response?.status === 404) {
        //   history.push(BrowserRoutes.Default.Unauthorized);
        // }
      },
    },
  );
}
