import { Box } from '@material-ui/core';
import ProgramsListItemStudentActions from 'components/programsList/ProgramsListItemStudentActions';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { getMetaRole } from 'utils';
import { useProgram } from '../../api';
import { ReactComponent as BackArrow } from '../../assets/svg/Icons/Arrow/Left.svg';
import { ReactComponent as GraduationHatIcon } from '../../assets/svg/Icons/GraduationHat.svg';
import { ReactComponent as LocationIcon } from '../../assets/svg/Icons/Location.svg';
import { Components } from '../../client/UniClient';
import {
  ProgramExtended,
  ProgramsListItemAgentActions,
  UniBounceLoader,
  UniButton,
  UniHeader,
  UniIconWrapper,
  UniPage,
  UniStatus,
  UniTypography,
} from '../../components';
import { BrowserRoutes } from '../../constants';
import { useBreakpoints, useErrorHandler } from '../../hooks';
import { useAuth } from '../../store';

export default function ProgramPage(): ReactElement {
  const { t } = useTranslation('programPage');
  const [fileIds, setFileIds] = useState<Components.Schemas.AttachRequiredDocumentRequest[]>([]);
  const { role, studentProfile } = useAuth(state => state.profile);
  const history = useHistory();
  const { programId } = useParams<{ programId: string }>();
  const { isAgent, isStudent, isAgency, isMaster } = getMetaRole(role!);
  const { isMobile, isTablet } = useBreakpoints();

  const {
    data: program,
    status: programStatus,
    error: programsError,
  } = useProgram({
    id: programId,
  });
  const getProgramError = useErrorHandler(programsError!);

  if (programStatus === 'loading') {
    return (
      <UniPage>
        <UniBounceLoader />
      </UniPage>
    );
  }
  return (
    <>
      <UniHeader>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <UniButton
            fullWidth={false}
            variant='text'
            startIcon={
              <UniIconWrapper>
                <BackArrow />
              </UniIconWrapper>
            }
            onClick={() => history.goBack()}
          >
            {t('buttons.back')}
          </UniButton>
          {isMobile && (
            <Box display='flex' alignItems='center' style={{ gap: '16px' }}>
              {isStudent && <ProgramsListItemStudentActions program={program!} />}
              {(isAgency || isAgent) && <ProgramsListItemAgentActions program={program!} />}
            </Box>
          )}
        </Box>
        {getProgramError.message && (
          <Box>
            <UniTypography color='stateAlert100'>{getProgramError.message}</UniTypography>
          </Box>
        )}
        <Box mt={3} display='flex' alignItems='flex-start' justifyContent='space-between'>
          <Box maxWidth={'530px'}>
            <UniTypography variant='header2' color='primary100' weight='semi-bold'>
              {program?.name}
            </UniTypography>
          </Box>
          {!isMobile && (
            <Box display='flex' alignItems='center' style={{ gap: '16px' }}>
              {isStudent && <ProgramsListItemStudentActions program={program!} />}
              {(isAgency || isAgent) && <ProgramsListItemAgentActions program={program!} />}
            </Box>
          )}
        </Box>
        <Box pb={3} display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
          <Box
            style={{ cursor: 'pointer' }}
            onClick={() => {
              isAgency
                ? history.push(BrowserRoutes.Agency.UniversityProfile.replace(':id', program?.universityId as string))
                : isStudent
                ? history.push(BrowserRoutes.Student.UniversityProfile.replace(':id', program?.universityId as string))
                : isMaster
                ? history.push(BrowserRoutes.Admin.University.Applications.replace(':id', program?.universityId as string))
                : history.push(BrowserRoutes.Agent.UniversityProfile.replace(':id', program?.universityId as string));
            }}
          >
            <UniTypography variant='header3' color='secondary100' weight='semi-bold'>
              {program?.universityName}
            </UniTypography>
          </Box>
          <Box display='flex' style={{ gap: '16px' }}>
            <Box display='flex' alignItems='center' style={{ gap: '8px' }}>
              <UniIconWrapper size='medium' withBackground color='primary100'>
                <LocationIcon />
              </UniIconWrapper>
              <UniTypography variant='body2' color='typographyGray100'>
                {`${program?.address?.city || '-'}, ${program?.address?.country || '-'}`}
              </UniTypography>
            </Box>
            <Box display='flex' alignItems='center' style={{ gap: '8px' }}>
              <UniIconWrapper size='medium' withBackground color='secondary100'>
                <GraduationHatIcon />
              </UniIconWrapper>
              <UniTypography variant='body2' color='typographyGray100'>
                {`${program?.levelOfStudy}’s, ${
                  program?.yearsOfStudy
                    ? `${program.yearsOfStudy} Years`
                    : !!program?.lengthOfStudy
                    ? `${program?.lengthOfStudy?.value} ${program?.lengthOfStudy?.unit}/s`
                    : 'Length unspecified'
                }`}
              </UniTypography>
              {isStudent && <UniStatus program={program} />}
            </Box>
            {!!program?.virtualAddress?.website && (
              <UniButton fullWidth={false} variant='text' onClick={() => window.open(program?.virtualAddress?.website, '_blank')}>
                Go to Website
              </UniButton>
            )}
          </Box>
          <Box />
        </Box>
      </UniHeader>
      <UniPage>
        <ProgramExtended
          studentProfile={isStudent ? studentProfile : undefined}
          program={program!}
          handleFileIds={(fileIds: Components.Schemas.AttachRequiredDocumentRequest[]) => {
            setFileIds(fileIds);
          }}
        />
      </UniPage>
    </>
  );
}
