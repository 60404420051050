import { Box } from '@material-ui/core';
import UniDropdown from 'components/_common/uniDropdown/UniDropdown';
import { UniLink } from 'components/_common/uniLink/UniLink';
import UniLogo from 'components/_common/uniLogo/uniLogo';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useDrawers } from 'store';
import useHeader from 'store/header';
import { theme } from 'theme';
import { getMetaRole } from 'utils';
import { UniButton } from '../..';
import { ReactComponent as ArrowLeft } from 'assets/svg/Icons/Arrow/ArrowLeft.svg';
import { ReactComponent as FilterIcon } from 'assets/svg/Icons/FilterV2.svg';
import { ReactComponent as MenuDots } from 'assets/svg/Icons/MenuDots.svg';
import { ReactComponent as WhatsappIcon } from 'assets/svg/Icons/WhatsAppIcon.svg';
import { Components } from '../../../client/UniClient';
import { BrowserRoutes } from '../../../constants';
import UniIconWrapper from '../../_common/uniIconWrapper/UniIconWrapper';
import { Notifications } from '../headerNavigation/auth/Notifications';
import { SpecialCaseWrapper, StyledAuthNavbar } from './HeaderNavigation.style';

interface Props {
  isLoggedIn: boolean;
  isSticky: boolean[];
  profile: Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse>;
}

export default function HeaderNavigationMobile({ isLoggedIn, profile, isSticky }: Props): ReactElement {
  const history = useHistory();
  const { t } = useTranslation('landingHome');
  const { openDrawer } = useDrawers();
  const { isStudent, isAgency, isAgent } = getMetaRole(profile?.role);
  const { header, primaryButton, secondaryButton, ...headerProps } = useHeader();
  const [openMenu, setOpenMenu] = useState(false);
  const showChat = isStudent || isAgency || isAgent;

  const onClickAvatar = () => openDrawer('mobile-navigation', { size: 'small', side: 'right' });
  const onClickNotification = () => openDrawer('mobile-notifications', { size: 'small', side: 'right' });

  if (header === 'predefined')
    return (
      <SpecialCaseWrapper isSticky={!primaryButton && !secondaryButton ? [false, false] : isSticky}>
        {primaryButton && (
          <UniIconWrapper onClick={() => primaryButton?.onClick()}>
            <ArrowLeft />
          </UniIconWrapper>
        )}
        <Box />
        {secondaryButton && (
          <UniDropdown
            ActionButton={
              <UniIconWrapper>
                <MenuDots />
              </UniIconWrapper>
            }
            handleOpen={() => setOpenMenu(true)}
            open={openMenu}
            handleClose={() => setOpenMenu(false)}
          >
            {secondaryButton.options?.map(item => (
              <UniLink
                onClick={() => {
                  item?.onClick();
                  setOpenMenu(false);
                }}
                size='main'
                alert={item?.labelColor === 'stateAlert100'}
                label={item?.label}
              ></UniLink>
            ))}
          </UniDropdown>
        )}
      </SpecialCaseWrapper>
    );
  return (
    <StyledAuthNavbar isLoggedIn={isLoggedIn} isSticky={isSticky!}>
      {isLoggedIn ? (
        <>
          <UniLogo size='S' light text />
          <Box display='flex' alignItems='center' style={{ marginRight: -theme.spacing(1.5) }}>
            <UniButton
              size='small'
              variant='secondary'
              startIcon={
                <UniIconWrapper size='main' color='primary100'>
                  <WhatsappIcon />
                </UniIconWrapper>
              }
              onClick={() => {
                window.open('https://wa.me/40774051652', '_blank');
              }}
            >
              {''}
            </UniButton>
            <Box style={{ marginRight: -theme.spacing(1.5) }}>
              <Notifications profile={profile} onClick={onClickNotification} />
            </Box>
            {/* {showChat && <Chat />} */}
          </Box>
        </>
      ) : (
        <>
          <Box ml={2}>
            <Link to={BrowserRoutes.Default.Homepage}>
              <UniLogo size='S' light text />
            </Link>
          </Box>
          <Box display='flex' flexDirection='row' alignItems='center' style={{ gap: theme.spacing(1), marginRight: -theme.spacing(1.5) }}>
            <UniButton
              size='small'
              variant='secondary'
              startIcon={
                <UniIconWrapper size='main' color='white100'>
                  <WhatsappIcon />
                </UniIconWrapper>
              }
              onClick={() => {
                window.open('https://wa.me/40774051652', '_blank');
              }}
            >
              {''}
            </UniButton>
            <UniButton
              size='small'
              variant='main'
              onClick={() => {
                history.push(BrowserRoutes.Default.Auth.Login);
              }}
            >
              {t('buttons.sign_up')}
            </UniButton>
            <UniIconWrapper color='borderGray100' size='medium' onClick={onClickAvatar}>
              <FilterIcon />
            </UniIconWrapper>
          </Box>
        </>
      )}
    </StyledAuthNavbar>
  );
}
