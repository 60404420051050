import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { UniButton } from 'components';
import PreferencesForm, { PreferencesFormInputs } from 'components/_forms/PreferencesForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledUniCardFooter } from '../StudentOnboard.style';

export type Step5FormData = PreferencesFormInputs;

type Props = {
  defaultValues: Partial<Step5FormData>;
  prevStep: () => void;
  nextStep: (formData: Step5FormData) => void;
};

const Step5: React.FC<Props> = ({ defaultValues, nextStep, prevStep }) => {
  const { t: tButtons } = useTranslation('buttons');
  const { t } = useTranslation('forms');
  const { control, register, errors, watch, handleSubmit } = useForm<Step5FormData>({ defaultValues });

  useEffect(() => {
    localStorage.setItem('onboardFormDataStep5', JSON.stringify(watch()));
  }, [watch()]);

  return (
    <>
      <Box mb={4}>
        <PreferencesForm control={control} errors={errors} register={register} />
      </Box>
      <StyledUniCardFooter
        variants='M'
        type='labeled'
        SecondaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            variant='text'
            size={'large'}
            color='neutral'
            fullWidth={false}
            onClick={prevStep}
          >
            {tButtons('previous')}
          </UniButton>
        }
        PrimaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            size={'large'}
            color='primary'
            fullWidth={false}
            onClick={handleSubmit(nextStep)}
          >
            {tButtons('next_step')}
          </UniButton>
        }
      />
    </>
  );
};

export default Step5;
