import QueryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useFiltersCount = () => {
  const location = useLocation();
  const [filtersCount, setFiltersCount] = useState(0);
  const filters = QueryString.parse(location.search);

  useEffect(() => {
    const count = Object.keys(filters).filter(item => item !== 'sortType' && item !== 'query' && item !== 'clear').length;
    setFiltersCount(!!filters ? count : 0);
  }, [filters]);

  return filtersCount;
};

export default useFiltersCount;
