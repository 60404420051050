import { Box } from '@material-ui/core';
import useMarkAchievementForReview from 'api/achievements/useMarkAchievementForReview';
import useValidateAchievement from 'api/achievements/useValidateAchievement';
import useMarkTodoForReview from 'api/todos/useMarkTodoForReview';
import useValidateTodo from 'api/todos/useValidateTodo';
import { Components } from 'client/UniClient';
import { UniButton, UniCard, UniChip, UniDivider, UniIconWrapper, UniLink, UniMetrics, UniTypography } from 'components';
import UniDropdown from 'components/_common/uniDropdown/UniDropdown';
import { activitiesCategoryMap, activitiesStatusMap, activitiesTypeMap } from 'constants/activities';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useAuth, useDrawers } from 'store';
import { getMetaRole } from 'utils';
import { ReactComponent as CandidateIcon } from '../../assets/svg/Icons/Candidates.svg';
import { ReactComponent as HourglassIcon } from '../../assets/svg/Icons/Hourglass.svg';
import { ReactComponent as LinkIcon } from '../../assets/svg/Icons/Link.svg';
import { ReactComponent as MenuDots } from '../../assets/svg/Icons/MenuDots.svg';
import { ReactComponent as OngoingIcon } from '../../assets/svg/Icons/Ongoing.svg';
import { UniFileViewer } from '../../components';

interface Props {
  achievement?: Components.Schemas.AchievementDto;
  todo?: Components.Schemas.ToDoDto;
  disabled?: boolean;
}

const ActivityPlanItem: React.FC<Props> = ({ achievement, todo, disabled }) => {
  const { openDrawer, closeDrawer } = useDrawers();
  const [dropdown, setDropdown] = useState(false);
  const { profile } = useAuth();
  const { isStudent, isAgency, isAgent } = getMetaRole(profile.role);
  const [markAchievementForReview, { isLoading: isLoadingMarkAchievementForReview }] = useMarkAchievementForReview();
  const [markTodoForReview, { isLoading: isLoadingMarkTodoForReview }] = useMarkTodoForReview();
  const [validateTodo, { isLoading: isLoadingValidateTodo }] = useValidateTodo();
  const [validateAchievement, { isLoading: isLoadingValidateAchievement }] = useValidateAchievement();
  const isConsultant = isAgency || isAgent;
  const isLoadingPrimaryAction =
    isLoadingMarkAchievementForReview || isLoadingMarkTodoForReview || isLoadingValidateTodo || isLoadingValidateAchievement;

  const { title, category, creator, description, status, type, links, documents, parsedDate } = useMemo(() => {
    const activity = achievement || todo!;
    const date = !!achievement ? moment(achievement?.completedAt).format('DD.MM.YYYY') : moment(todo?.deadline).format('DD.MM.YYYY');
    const dateLabel = !!achievement ? 'Completed at' : 'Deadline';

    return {
      ...activity,
      type: (!!achievement ? 'ACHIEVEMENT' : 'TODO') as 'ACHIEVEMENT' | 'TODO',
      parsedDate: {
        label: dateLabel,
        value: date,
      },
    };
  }, [todo, achievement]);

  const { showRequestUpdates, showEdit, showSecondaryAction, showPrimaryAction, showTopActions, showBottomActions } = useMemo(() => {
    const activity = achievement || todo!;
    let showRequestUpdates = true;
    let showEdit = true;
    let showSecondaryAction = true;
    let showPrimaryAction = true;
    let showTopActions = true;
    let showBottomActions = true;

    if (isStudent || activity.status === 'NOT_STARTED') showRequestUpdates = false;
    if (isStudent && activity.status === 'IN_REVIEW') showPrimaryAction = false;
    if (disabled) {
      showRequestUpdates = false;
      showEdit = false;
      showSecondaryAction = false;
      showPrimaryAction = false;
      showTopActions = false;
      showBottomActions = false;
    }
    return {
      showEdit,
      showRequestUpdates,
      showSecondaryAction,
      showPrimaryAction,
      showTopActions,
      showBottomActions,
    };
  }, [todo, achievement, disabled]);

  const parsedCreator = creator.id === profile.id ? 'Me' : `${creator.firstName} ${creator.lastName}`;
  const parsedStatus = status || 'NOT_STARTED';

  const onValidateActivity = () => {
    if (type === 'ACHIEVEMENT') {
      if (!achievement?.id) return;
      validateAchievement(
        { achievementId: achievement?.id },
        {
          onSuccess() {
            closeDrawer();
          },
        },
      );
    }
    if (type === 'TODO') {
      if (!todo?.id) return;
      validateTodo(
        { toDoId: todo?.id },
        {
          onSuccess() {
            closeDrawer();
          },
        },
      );
    }
  };
  const onMarkForReview = () => {
    if (type === 'ACHIEVEMENT') {
      if (!achievement?.id) return;
      markAchievementForReview(
        { achievementId: achievement?.id },
        {
          onSuccess() {
            closeDrawer();
          },
        },
      );
    }
    if (type === 'TODO') {
      if (!todo?.id) return;
      markTodoForReview(
        { toDoId: todo?.id },
        {
          onSuccess() {
            closeDrawer();
          },
        },
      );
    }
  };
  const onPreviewRequiredUpdates = () => {
    if (!isStudent || status !== 'UPDATES_REQUIRED') return;
    openDrawer('required-updates-preview', { achievement, todo });
  };

  return (
    <UniCard size='L' border>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <UniTypography variant='body1' color='typographyBlack100' weight='semi-bold'>
            {title}
          </UniTypography>
          <Box ml={2.5} />
          <Box
            onClick={onPreviewRequiredUpdates}
            style={parsedStatus === 'UPDATES_REQUIRED' && isStudent ? { cursor: 'pointer' } : undefined}
          >
            <UniChip
              label={activitiesStatusMap[parsedStatus]}
              color={parsedStatus === 'UPDATES_REQUIRED' && isStudent ? 'primary' : 'green'}
              arrow={parsedStatus === 'UPDATES_REQUIRED' && isStudent}
            />
          </Box>
        </Box>
        {(showTopActions || isConsultant) && (
          <UniDropdown
            open={dropdown}
            handleOpen={() => setDropdown(true)}
            handleClose={() => setDropdown(false)}
            ActionButton={
              <UniIconWrapper color='typographyGray100'>
                <MenuDots />
              </UniIconWrapper>
            }
          >
            {showRequestUpdates && (
              <UniLink
                size='medium'
                label='Request updates'
                fullWidth
                onClick={() => {
                  setDropdown(false);
                  openDrawer('request-activity-update', { achievement, todo });
                }}
              />
            )}
            {showEdit && (
              <UniLink
                size='medium'
                label='Edit'
                fullWidth
                onClick={() => {
                  setDropdown(false);
                  openDrawer('edit-activity-item', { achievement, todo });
                }}
              />
            )}
            <UniLink
              size='medium'
              label='Remove'
              alert
              fullWidth
              onClick={() => {
                setDropdown(false);
                openDrawer('delete-activity-item', { achievement, todo });
              }}
            />
          </UniDropdown>
        )}
      </Box>
      <Box mb={5} />
      <UniMetrics
        variant='iconed'
        size='large'
        values={[
          { Icon: <OngoingIcon />, label: 'Category', value: activitiesCategoryMap[category] },
          { Icon: <OngoingIcon />, label: 'Type', value: activitiesTypeMap[type] },
          { Icon: <CandidateIcon />, label: 'Added by', value: parsedCreator },
          { Icon: <HourglassIcon />, ...parsedDate },
        ]}
      />
      <Box mb={5} />
      <UniTypography variant='subheader' color='typographyBlack100'>
        {description}
      </UniTypography>

      {(!!achievement?.links?.length || !!achievement?.documents?.length) && (
        <Box my={2}>
          <UniDivider height={3} />
        </Box>
      )}

      {!!links?.length && (
        <>
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            Links
          </UniTypography>
          {links.map((link, index) => (
            <Box mb={1} display='flex' onClick={() => window.open(link, '_blank')} key={index}>
              <UniIconWrapper>
                <LinkIcon />
              </UniIconWrapper>
              <Box ml={1} />
              <UniTypography variant='subheader'>{link}</UniTypography>
            </Box>
          ))}
        </>
      )}

      {!!documents?.length && (
        <>
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            Documents
          </UniTypography>
          {documents.map((document, index) => (
            <UniFileViewer label={document.originalName} file={document} key={index} />
          ))}
        </>
      )}

      {showBottomActions && (
        <>
          <Box my={2}>
            <UniDivider height={3} />
          </Box>
          <Box display='flex' width='100%' justifyContent='flex-end'>
            {showSecondaryAction && (
              <UniButton size='medium' variant='text' onClick={() => openDrawer('add-proof-of-completion', { achievement, todo })}>
                Add proof of completion
              </UniButton>
            )}
            {showPrimaryAction &&
              (isConsultant ? (
                <UniButton size='medium' color='primaryFade' loading={isLoadingPrimaryAction} onClick={onValidateActivity}>
                  Validate
                </UniButton>
              ) : (
                <UniButton size='medium' color='primaryFade' loading={isLoadingPrimaryAction} onClick={onMarkForReview}>
                  Mark for Review
                </UniButton>
              ))}
          </Box>
        </>
      )}
    </UniCard>
  );
};

export default ActivityPlanItem;
