import { Box } from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/Icons/Arrow/ChevronDown.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/svg/Icons/Arrow/ChevronUp.svg';
import { ReactComponent as WritingIcon } from 'assets/svg/Icons/Writing.svg';
import { Components } from 'client/UniClient';
import { UniAutocomplete, UniButton, UniIconWrapper, UniTypography } from 'components';
import UniCheckbox from 'components/_inputs/uniCheckbox/UniCheckbox';
import { useBreakpoints } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import { OptionType } from 'types';
import {
  StyledExpandableContent,
  StyledExpandableHeader,
  StyledExpandableWrapper,
  StyledSelectWrapper,
  StyledServiceItemWrapper,
} from './ServiceItem.style';

interface Props {
  name: string;
  description: string;
  value?: number;
  checked?: boolean;
  onDelete?: () => void;
  onChange?: (value: number) => void;
  onCheck?: (value: boolean) => void;
  price: Components.Schemas.CurrencyDto;
  minified?: boolean;
  editable?: boolean;
}

const ServiceItem: React.FC<Props> = ({ name, description, editable, value, price, checked, minified, onDelete, onCheck, onChange }) => {
  const { t } = useTranslation('serviceItem');
  const [expanded, setExpanded] = useState(false);
  const { isMobile } = useBreakpoints();
  const { profile } = useAuth();

  return (
    <Box width='100%'>
      <StyledServiceItemWrapper minified={minified}>
        <StyledExpandableWrapper minified={minified}>
          <StyledExpandableHeader>
            {!editable && (
              <UniTypography variant='body2' weight='medium' color='typographyGray100'>
                {value}x&nbsp;
              </UniTypography>
            )}
            {!minified && (
              <UniIconWrapper color='typographyBlack100' size='large'>
                <WritingIcon />
              </UniIconWrapper>
            )}
            <UniTypography variant={minified ? 'body2' : 'body1'} weight={minified ? 'medium' : 'semi-bold'} color='typographyBlack100'>
              {name}
            </UniTypography>
            {!minified && (
              <Box flex={1} display='flex' justifyContent='flex-end' alignItems='center'>
                {!expanded && (
                  <UniIconWrapper color='typographyGray100' size='medium' onClick={() => setExpanded(prev => !prev)}>
                    <ArrowDownIcon />
                  </UniIconWrapper>
                )}
                {expanded && (
                  <UniIconWrapper color='typographyGray100' size='medium' onClick={() => setExpanded(prev => !prev)}>
                    <ArrowUpIcon />
                  </UniIconWrapper>
                )}
              </Box>
            )}
          </StyledExpandableHeader>
          {isMobile && !minified && (
            <StyledExpandableContent expanded={expanded}>
              <UniTypography variant='subheader' weight='regular' color='typographyGray100'>
                {description}
              </UniTypography>
            </StyledExpandableContent>
          )}
        </StyledExpandableWrapper>

        <StyledSelectWrapper minified={minified}>
          {onChange && editable && (
            <UniAutocomplete
              disableClearable
              getOptionLabel={option => option.label}
              options={[...Array(10)].map((_, index) => ({ label: (index + 1).toString(), value: index + 1 }))}
              label='Quantity'
              value={value ? { label: value.toString(), value: value } : undefined}
              handleChange={(e: any, option: OptionType) => onChange(option.value)}
            />
          )}
        </StyledSelectWrapper>

        {!!onDelete && editable && (
          <UniButton fullWidth={false} disablePadding variant='text' color='primary' onClick={onDelete}>
            {t('buttons.removeItem')}
          </UniButton>
        )}

        <UniTypography variant='body1' weight='semi-bold' color='secondary100'>{`${price?.amount} ${price?.currencyIso}`}</UniTypography>

        {!!onCheck && <UniCheckbox checked={checked} onChange={e => onCheck(e.target.checked)} />}
      </StyledServiceItemWrapper>
      {!isMobile && !minified && (
        <StyledExpandableWrapper>
          <StyledExpandableContent expanded={expanded}>
            <Box mt={2} />
            <UniTypography variant='subheader' weight='regular' color='typographyGray100'>
              {description}
            </UniTypography>
          </StyledExpandableContent>
        </StyledExpandableWrapper>
      )}
    </Box>
  );
};

export default ServiceItem;
