import { Box } from '@material-ui/core';
import { TDisciplines } from 'api/search/useProgramFilters';
import { Components } from 'client/UniClient';
import { UniTypography } from 'components';
import { disciplinesMap } from 'constants/programMaps';
import { default as React, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';
import { StyledDisciplineCard } from './UniversityPage.style';

interface Props {
  university: Components.Schemas.UniversityResponse;
}

export default function UniversityPageGeneralDisciplines({ university }: Props): ReactElement {
  const { t } = useTranslation('universityPage');
  //to be replaced with university.disciplines
  const hasDisciplines = false;

  return (
    <>
      {university?.disciplines?.length > 0 ? (
        <Box display='flex' flexWrap='wrap' style={{ gap: `${theme.spacing(1)}px` }}>
          {university?.disciplines.map((item: TDisciplines) => (
            <StyledDisciplineCard>
              <UniTypography color='typographyGray100'>{disciplinesMap[item]}</UniTypography>
            </StyledDisciplineCard>
          ))}
        </Box>
      ) : (
        <Box height='151px' pt={7.5} textAlign='center'>
          <UniTypography color='typographyGray100' variant='header3'>
            {t('general.sections.no_disciplines')}
          </UniTypography>
        </Box>
      )}
    </>
  );
}
