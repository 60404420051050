import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCandidates } from 'api';
import usePostTask from 'api/tasks/usePostTask';
import { Components } from 'client/UniClient';
import { UniModalDrawerContent } from '../../_common/uniModalDrawer/UniModalDrawer.style';
import { UniTextField } from '../../index';
import { useDrawers } from '../../../store';
import { Button } from '@material-ui/core';
import UniSelect from '../../_inputs/uniSelect/UniSelect';

type TaskEssayRequest = Components.Schemas.TaskEssayRequest;

interface Props {
    userId: string;
    applicationId: string;
}

interface FormType {
  title: string;
  description: string;
  deadline: string;
  link: string;
  assigneeUserId?: string;
  applicationId?: string;
}

const defaultFormState: FormType = {
  title: '',
  description: '',
  deadline: '',
  link: 'www.example.com',
  assigneeUserId: undefined,
  applicationId: undefined,
};

export const AddTaskDrawer: React.FC<Props> = ({ userId, applicationId }) => {
  const { data: candidatePages, fetchMore, canFetchMore } = useCandidates({ sort: 'NAME', order: 'ASC' });
  const [candidateOptions, setCandidateOptions] = useState<{ value: string, label: string}[] | null>(null);
  const { control, register, errors, handleSubmit } = useForm<FormType>({
    defaultValues: defaultFormState,
  });

  const [postTask] = usePostTask();
  const { setProgress, closeDrawer } = useDrawers();

  useEffect(() => {
    if (candidatePages != null) {
      if (canFetchMore === true) {
        fetchMore().then();
      } else {
        const c = candidatePages.flatMap(page => page.results);
        setCandidateOptions(c.map(candidate => ({
          value: candidate.id!,
          label: `${candidate.firstName} ${candidate.lastName} (${candidate.username})`
        })));
      }
    }
  }, [candidatePages, canFetchMore]);

    const createTask = ({ title, deadline, description, link}: FormType) => {
    const fullFormData = {
      title,
      deadline,
      description,
      type: applicationId! ? 'APPLICATION' : 'OTHER' as 'ESSAY' | 'APPLICATION' | 'OTHER',
      assigneeUserId: userId,
      applicationId: applicationId,
      essay: {wordCount: 0, title: 'Not a essay', topic: 'Not a topic', link: link} as TaskEssayRequest,
      links: [link],
    };

    console.log(fullFormData);
    postTask(fullFormData, {
      onSuccess() {
        setProgress(100);
        closeDrawer();
      }
    }).then();
  }

  const onSubmit = () => {
    handleSubmit(formData => {
      createTask(formData);
    })();
  };

  return candidateOptions != null ? (
    <>
      <UniModalDrawerContent>
        <UniTextField
          label='Task title'
          placeholder='Add a title for the task'
          inputRef={register({
            required: 'Title is required',
            validate: (value: string) => value.trim() !== '',
          })}
          name='title'
          error={!!errors.title?.message}
          helperText={errors.title?.message}
          fullWidth
        />
        <UniTextField
          label='Task description'
          placeholder='Add a description for the task'
          inputRef={register()}
          name='description'
          error={!!errors.description?.message}
          helperText={errors.description?.message}
          fullWidth
        />
        <UniTextField
          label='Deadline'
          placeholder='YYYY-MM-DD'
          inputRef={register({
            required: 'Deadline must be a valid date',
            validate: (value: string) => value.length === 10 && value[4] === '-' && value[7] === '-',
          })}
          name='deadline'
          error={!!errors.deadline?.message}
          helperText={errors.deadline?.message}
          fullWidth
        />
        <UniTextField
          label='Link'
          placeholder='www.example.com'
          inputRef={register()}
          name='link'
          error={!!errors.link?.message}
          helperText={errors.link?.message}
          fullWidth
        />

      </UniModalDrawerContent>
      <Button onClick={onSubmit}>Create task</Button>
    </>
  ) : (
    <></>
  );
};

export default AddTaskDrawer;
