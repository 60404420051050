import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useClient } from '../../store';

//This is for uploading the image to the current user
const useUploadProfileImage = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: FormData) => {
      return client!.FileApi_uploadProfileImage(undefined, payload);
    },
    {
      onError: (error: AxiosError) => {
        console.error(error);
      },
    },
  );
};

export default useUploadProfileImage;
