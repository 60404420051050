import { AxiosError } from 'axios';
import { QueryConfig, useInfiniteQuery, useQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

type Params = {
  universityId?: string;
};
export default function useGetFaculties(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  const profil = useAuth(state => state.profile);
  return useQuery(
    [CacheKeys.General.University, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.FacultyApi_getUniversityFaculties(
        profil.facultyMemberProfile?.universityId || params.universityId || undefined,
      );

      return data;
    },
    {
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
