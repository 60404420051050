import { Box } from '@material-ui/core';
import { ReactComponent as CheckIcon } from 'assets/svg/Icons/Check.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/Icons/Close.svg';
import { ReactComponent as EditIcon } from 'assets/svg/Icons/Edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/Icons/Garbagev2.svg';
import { Components } from 'client/UniClient';
import { UniButton, UniCard, UniIconWrapper, UniStatus, UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import { useSnackbar } from 'store';
import { StyledActionsArea, StyledDataArea, StyledDescriptionArea, StyledHeaderArea } from './ServiceCard.style';

export interface ServiceCardProps {
  id: string;
  type?: 'SERVICE' | 'REQUEST';
  name: string;
  description: string;
  partner?: string;
  price?: Components.Schemas.CurrencyDto;
  status?: string;
  usedBy?: number;
  onReject?: () => void;
  onAdd?: () => void;
  onRemove?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  addButtonLabel?: string;
  isOffered?: boolean;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  id,
  type = 'SERVICE',
  description,
  name,
  status = 'In My Services',
  onAdd,
  onRemove,
  onDelete,
  onEdit,
  onReject,
  partner,
  price,
  usedBy,
  addButtonLabel = 'Add',
  isOffered,
}) => {
  const { isMobile } = useBreakpoints();
  const { props } = useSnackbar();
  if (props.hide === id) return <></>;

  return (
    <UniCard size='S' border padding={isMobile ? [2, 2] : [3, 3]}>
      <StyledHeaderArea>
        <StyledDataArea>
          <UniTypography variant='body1' weight='medium' color='typographyBlack100'>
            {name}
          </UniTypography>
          {!!partner && (
            <>
              <UniTypography variant='subheader' weight='regular' color='typographyBlack100'>
                {type === 'REQUEST' ? 'from' : 'for'}
              </UniTypography>
              <UniTypography variant='subheader' weight='medium' color='primary100'>
                {partner}
              </UniTypography>
            </>
          )}
          {type === 'SERVICE' && (
            <>
              {!!price && (
                <>
                  <Box mt={1} />
                  <UniTypography variant='body2' weight='semi-bold' color='secondary100'>
                    {`${price.amount} ${price.currencyIso?.slice(0, 1) + price?.currencyIso?.slice(1, 3).toLowerCase()}`}
                  </UniTypography>
                </>
              )}
              {!!usedBy && (
                <>
                  <Box mt={1} />
                  <UniTypography variant='body2' weight='regular' color='secondary100'>
                    Used by {usedBy} partner{usedBy > 2 && 's'}
                  </UniTypography>
                </>
              )}
              {isOffered && (
                <>
                  <Box mt={1} />
                  <UniStatus>{status}</UniStatus>
                </>
              )}
            </>
          )}
        </StyledDataArea>
        <StyledActionsArea>
          {!!onRemove && (
            <UniButton onClick={onAdd || onRemove} size='small' color='primaryFade'>
              Remove
            </UniButton>
          )}
          {!!onAdd &&
            (isMobile ? (
              <UniIconWrapper onClick={onAdd} size='main' clearMargins disableHover color='typographyGray100'>
                <CheckIcon />
              </UniIconWrapper>
            ) : (
              <UniButton onClick={onAdd} size='small' color='primaryFade'>
                {addButtonLabel}
              </UniButton>
            ))}
          {!!onReject &&
            (isMobile ? (
              <UniIconWrapper onClick={onReject} size='medium' clearMargins disableHover color='typographyGray100'>
                <CloseIcon />
              </UniIconWrapper>
            ) : (
              <UniButton onClick={onReject} size='small' variant='text' color='primaryFade'>
                Reject
              </UniButton>
            ))}
          {!!onEdit && (
            <UniIconWrapper onClick={onEdit} size='medium' clearMargins disableHover color='typographyGray100'>
              <EditIcon />
            </UniIconWrapper>
          )}
          {!!onDelete && (
            <UniIconWrapper onClick={onDelete} size='medium' clearMargins disableHover color='typographyGray100'>
              <DeleteIcon />
            </UniIconWrapper>
          )}
        </StyledActionsArea>
      </StyledHeaderArea>
      <StyledDescriptionArea>
        <UniTypography variant='subheader' weight='regular' color='typographyGray100'>
          {description}
        </UniTypography>
      </StyledDescriptionArea>
    </UniCard>
  );
};

export default ServiceCard;
