import React from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';

interface Props {
  id: string;
}

interface PropsArrow {
  id: string;
  onClick: VoidFunction;
}

export const Arrow = ({ onClick, id }: PropsArrow) => {
  return <button id={id} onClick={onClick} style={{ display: 'none' }} />;
};

export const LeftArrow = ({ id }: Props) => {
  const { scrollPrev } = React.useContext<any>(VisibilityContext);
  return <Arrow onClick={() => scrollPrev()} id={id} />;
};

export const RightArrow = ({ id }: Props) => {
  const { scrollNext } = React.useContext<any>(VisibilityContext);
  return <Arrow onClick={() => scrollNext()} id={id} />;
};
