import { Tabs } from '@material-ui/core';
import { Gradients, Sizes, Shadows } from 'constants/uiConfig';
import { UniHeader } from 'components';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  background-color: #fff;
  box-shadow: ${Shadows.shadow2};
  width: calc(100% + ${({ theme }) => theme.spacing(4)}px);
  margin: ${({ theme }) => theme.spacing(-12, -2, 3, -2)};
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  position: relative;
  z-index: 1;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: calc(100% + ${({ theme }) => theme.spacing(6)}px);
    margin: ${({ theme }) => theme.spacing(-12, -3, 3, -3)};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: calc(100% + ${({ theme }) => theme.spacing(12)}px);
    margin: ${({ theme }) => theme.spacing(-12, -6, 6, -6)};
  }
`;

export const StyledGradientWrapper = styled(UniHeader)`
  background: ${Gradients.Primary._45dr};
  padding-bottom: ${({ theme }) => theme.spacing(6)}px;
  position: relative;
`;

export const StyledInnerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: ${Sizes.MaxWidthWrapper}px;
  }
`;

export const StyledHeader = styled.div<{ mobile: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  align-items: center;
  ${({ mobile, theme }) =>
    mobile
      ? `
        display:grid;
        margin-bottom: ${theme.spacing(4)}px;
        justify-content: center;
        text-align: center;
        gap: ${theme.spacing(1)}px;
    `
      : `
        display:flex;
        justify-content:space-between;
        margin-bottom: ${theme.spacing(5)}px;
    `}
`;

export const StyledTabs = styled(Tabs)`
  min-height: 0;
  margin-top: 0;
  ${({ theme }) => `${theme.breakpoints.down('sm')}{
    margin-right: ${theme.spacing(-2)}px;
    margin-left: ${theme.spacing(-2)}px;
    margin-top:${-theme.spacing(1)}px;
  }`}
`;
