import { devUrl, prodUrl } from 'constants/envConfig';
import io from 'socket.io-client';
import create from 'zustand';
import { Components } from '../../client/UniClient';
import { ChatState, IChatMessage, IChatRoom, IChatRooms } from './types';

const useChat = create<ChatState>((set, get) => {
  return {
    // stores the socket
    socket: undefined,
    // stores the userId of the current logged in user
    currentUserId: undefined,
    currentUserRole: undefined,
    // an object that holds all the chat state
    chatRooms: {},
    // an array of whose length is used as a useEffect dependency that triggers re-renderings
    chatRoomIds: undefined,
    // an index that is used as a useEffect dependency for re-renderings
    newMessageIndex: 0,
    ownMessagesIndex: 0,
    //
    recentChatUserIds: [],
    // an array that holds created room ids by other users, in which the current user is a participant.
    // is used as a useEffect dependency
    // when a new id is pushed, useGetChatRoom is called
    incomingCreatedChatRoomsIds: [],
    // a boolean used as a useEffect dependency that triggers a re-rendering to display the unread count bubble next to a contact
    newMessageNotification: false,
    // a boolean used as a useEffect dependency that triggers a re-rendering to display the read indicator for a chat bubble
    newSeenMessages: false,
    unreadMessagesCount: 0,
    usersByOnlineStatus: {},
    onlineStatusesRefreshCounter: 0,
    initSocketIo: async (token: string) => {
      console.group('SOCKET INIT PROCCESS');
      console.log('Start socket init');
      if (get().socket === undefined) {
        console.log('   No Socket');
        if (!token) {
          console.error('No session token to init socket');
          return;
        }
        const socketUri = process.env.NODE_ENV === 'production' ? prodUrl : devUrl;
        console.log('   SocketUri', socketUri);
        const socket = io(`${socketUri}/chat`, {
          query: { token },
          path: '/socket',
        });

        socket.io.on('error', (error: any) => console.log(error));

        socket.on('connect_error', (error: any) => console.log(error));

        socket.on('exception', (error: any) => console.dir(error));

        socket.on('connect', () => {
          console.log('     onConnect');
          socket.emit('initClient');
          set({
            socket,
          });
        });
        socket.on('message', (data: IChatMessage) => {
          console.log('     onMessage');
          const { roomId, ...payload } = data;
          let { chatRooms, currentUserId, newMessageIndex, newMessageNotification, setUnreadMessagesCount } = get();
          if (currentUserId !== payload.senderId) {
            chatRooms[roomId] = { ...(data as any) };
            if (!chatRooms[roomId]?.messages) {
              chatRooms[roomId].messages = [];
            }
            chatRooms[roomId]?.messages.push({ ...payload, seen: false });
            chatRooms[roomId].unreadMessageCount++;
            newMessageIndex++;
            newMessageNotification = true;
            chatRooms[roomId].messageAuthorEmittedSee = false;
          }
          chatRooms[roomId].newSeenMessages = false;
          set({ chatRooms, newMessageIndex, newMessageNotification });
          setUnreadMessagesCount();
        });

        socket.on('chatroom', (chatroomId: string) => {
          console.log('     onChatroom');
          const { incomingCreatedChatRoomsIds } = get();
          set({ incomingCreatedChatRoomsIds: [...incomingCreatedChatRoomsIds, chatroomId] });
        });

        socket.on('seen', ({ roomId, userId }: { roomId: string; userId: string }) => {
          console.log('     onSeen');
          const { chatRooms, currentUserId, setUnreadMessagesCount } = get();
          if (currentUserId !== userId) {
            const { messages } = chatRooms[roomId];
            chatRooms[roomId].messages = messages.map(message => ({ ...message, seen: message.senderId !== userId ? true : message.seen }));
            chatRooms[roomId].newSeenMessages = true;
            set({ chatRooms });
          }
          setUnreadMessagesCount();
        });

        socket.on('request-availability', ({ userId }: { userId: string }) => {
          console.log('     onRequestAvailability');
          //
          socket.emit('availability', { requesterId: userId, status: 'ONLINE' });
        });

        socket.on('availability', ({ userId, status }: { userId: string; status: 'ONLINE' | 'OFFLINE' }) => {
          console.log('     onAvailability');
          //
          const { currentUserId, usersByOnlineStatus, onlineStatusesRefreshCounter } = get();
          if (userId === currentUserId) return;
          //
          if (status === 'ONLINE') {
            console.log('       Is Online');
            usersByOnlineStatus[userId] = 'ONLINE';
          } else {
            console.log('       Is Offline');
            delete usersByOnlineStatus[userId];
          }
          set({ usersByOnlineStatus, onlineStatusesRefreshCounter: onlineStatusesRefreshCounter + 1 });
        });
      }

      console.groupEnd();
    },
    sendMessage: (message: string, roomId: string) => {
      const { socket, chatRooms, currentUserId, newMessageIndex } = get();
      if (message === '') return;
      const payload = {
        id: `${newMessageIndex}`,
        message,
        senderId: currentUserId,
        createdAt: new Date(),
        seen: false,
      };
      chatRooms[roomId]?.messages.push(payload);
      // if (roomId) chatRooms[roomId].lastMessage.createdAt = new Date().toISOString();
      set({ chatRooms, newMessageIndex: newMessageIndex + 1 });
      socket?.emit('sendMessage', { roomId, message });
    },
    setChatRooms: (rooms: Components.Schemas.ChatRoomDto[]) => {
      const chatRooms = <IChatRooms>{};
      const chatRoomIds = [];
      const { setUnreadMessagesCount } = get();
      for (const room of rooms) {
        chatRooms[room.id] = {
          ...room,
          messages: [],
          newSeenMessages: false,
          messageAuthorEmittedSee: true,
        };
        chatRoomIds.push(room.id);
      }
      set({ chatRooms, chatRoomIds });
      setUnreadMessagesCount();
    },
    setCurrentUserId: (id?: string) => {
      set({ currentUserId: id });
    },
    setCurrentUserRole: (role?: string) => {
      set({ currentUserRole: role });
    },
    saveChatRoom: (room: Components.Schemas.ChatRoomDto) => {
      if (!room) return;
      const chat = <IChatRoom>{
        ...room,
        messages: [],
        newSeenMessages: false,
        messageAuthorEmittedSee: true,
        unreadMessageCount: 0,
      };
      const chatRooms = get().chatRooms;
      chatRooms[room.id] = chat;
      set({ chatRooms, chatRoomIds: [...(get().chatRoomIds || []), room.id] });
    },
    emitSeeRoomMessages: (roomId: string) => {
      const { socket, chatRooms } = get();
      if (chatRooms[roomId]) {
        socket?.emit('see', { roomId });
        chatRooms[roomId].unreadMessageCount = 0;
        chatRooms[roomId].messageAuthorEmittedSee = true;
        set({ chatRooms, newMessageNotification: false });
      }
    },
    removeIncomingCreatedChatRoomById: (roomId: string) =>
      set(state => {
        let { incomingCreatedChatRoomsIds } = get();
        incomingCreatedChatRoomsIds = incomingCreatedChatRoomsIds.filter(id => id !== roomId);
        return { ...state, incomingCreatedChatRoomsIds };
      }),
    setUnreadMessagesCount: () => {
      const { chatRooms } = get();
      //
      const unreadMessagesCount = Object.keys(chatRooms).reduce((totalAcc, roomId) => {
        const room = chatRooms[roomId];
        const unread = room.unreadMessageCount || 0;
        return totalAcc + unread;
      }, 0);
      set({ unreadMessagesCount });
    },
  };
});
export default useChat;
