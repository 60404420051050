import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePatchTask from 'api/tasks/usePatchTask';
import { ReactComponent as LinkIcon } from 'assets/svg/Icons/Link.svg';
import { Components } from 'client/UniClient';
import { UniButton, UniCard, UniChip, UniDivider, UniFileViewer, UniIconWrapper, UniTypography } from 'components';
import { useDrawers } from 'store';
import { StyledUniCardFooter } from '../uniProgramCard/UniProgramCard.style';

interface TaskProps {
  id: string;
  title: string;
  description: string;
  status: string;
  deadline: string;
  links: string[];
  documents: Components.Schemas.FileDto[];
  userType: 'student' | 'agent';
  applicationId: string;
  onTaskUpdate?: () => any;
}

export const UniTaskCard: React.FC<TaskProps> = ({ userType, id, title, description, status, deadline, links, documents, applicationId, onTaskUpdate }) => {
  const { t } = useTranslation('taskCard');
  const { openDrawer, progress } = useDrawers();
  const [patchTask, { isLoading }] = usePatchTask();

  useEffect(() => {
    if (progress === 100 && onTaskUpdate != null) {
      onTaskUpdate();
    }
  }, [progress]);

  const buttonText = () => {
    switch (status) {
      case 'NOT_STARTED':
        return 'Start task';
      case 'IN_PROGRESS':
        return 'Send to review';
      case 'IN_REVIEW':
        return userType === 'student' ? 'Back to "In progress"' : 'Mark as done';
      default:
        return userType === 'student' ? undefined : 'Back to review';
    }
  }

  const nextStatus: () => 'NOT_STARTED' | 'IN_PROGRESS' | 'IN_REVIEW' | 'DONE' = () => {
    switch (status) {
      case 'NOT_STARTED':
        return 'IN_PROGRESS';
      case 'IN_PROGRESS':
        return 'IN_REVIEW';
      case 'IN_REVIEW':
        return userType === 'student' ? 'IN_PROGRESS' : 'DONE';
      case 'DONE':
        return 'IN_REVIEW';
      default:
        return 'NOT_STARTED';
    }
  }

  const advanceStatus = () => {
    const body = {
      title,
      description,
      deadline,
      links,
      applicationId,
      status: nextStatus(),
    };
    patchTask({
      taskId: id,
      ...body,
    }).then(() => {
      onTaskUpdate ? onTaskUpdate() : window.location.reload();
    });
  };

  return (
    <UniCard size='L' border>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <UniTypography variant='body1' color='typographyBlack100' weight='semi-bold'>
            {title}
          </UniTypography>
          <Box ml={2.5} />
          <Box>
            <UniChip
              label={t(`status.${status}`)}
              color='primary'
            />
          </Box>
        </Box>
      </Box>
      <Box mb={5} />
      <UniTypography variant='subheader' color='typographyBlack100'>
        {description}
      </UniTypography>
      {(links?.length > 0 || documents?.length > 0) && (
        <Box my={2}>
          <UniDivider height={3} />
        </Box>
      )}
      {links.length > 0 && (
        <>
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            {t('links')}
          </UniTypography>
          {links.map((link, index) => (
            <Box mb={1} display='flex' onClick={() => window.open(link, '_blank')} key={index}>
              <UniIconWrapper>
                <LinkIcon />
              </UniIconWrapper>
              <Box ml={1} />
              <UniTypography variant='subheader'>{link}</UniTypography>
            </Box>
          ))}
        </>
      )}
      {documents?.length > 0 && (
        <>
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            {t('documents')}
          </UniTypography>
          {documents.map((document, index) => (
            <UniFileViewer label={document.originalName} file={document} key={index} />
          ))}
        </>
      )}
      {/* Insert box to prevent buttons from covering content */}
      {buttonText() && (
        <Box paddingBottom={'80px'} />
      )}
      {buttonText() && (
        <StyledUniCardFooter
          PrimaryButton={
            <UniButton color='primary' onClick={advanceStatus} disabled={isLoading}>
              {buttonText()}
            </UniButton>
          }
          SecondaryButton={
            (userType === 'student' && ['IN_REVIEW', 'COMPLETED'].includes(status)) ? undefined : (
              <UniButton color='secondary' onClick={() => openDrawer('edit-task', { userType, taskId: id })}>
                Edit
              </UniButton>
            )
          }
       />
      )}
    </UniCard>
  );
}

export default UniTaskCard;
