import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import UniTutorList from 'components/tutorList/UniTutorList';
import { AnimatedTabPanel, UniHeader, UniHerosection, UniPage } from 'components';
import { languagesTutors, mathTutors, scienceTutors } from 'constants/tutors';
import { BrowserRoutes } from '../../../constants';

interface Props {}

export const StudentPrepCenterProfessorsPage: React.FC<Props> = () => {
  const { url } = useRouteMatch();
  const { t } = useTranslation('prepCenter');
  const mathTutorsPath = BrowserRoutes.Student.PrepCenter.Professors.Math;
  const scienceTutorsPath = BrowserRoutes.Student.PrepCenter.Professors.Science;
  const languagesTutorsPath = BrowserRoutes.Student.PrepCenter.Professors.Languages;
  const tabsMap = [mathTutorsPath, scienceTutorsPath, languagesTutorsPath];
  const currentIndex = tabsMap.findIndex(item => item === url);

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='title'
          title={t('professors.name')}
          tabs={[
            { label: t('professors.math'), path: mathTutorsPath },
            { label: t('professors.science'), path: scienceTutorsPath },
            { label: t('professors.languages'), path: languagesTutorsPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={mathTutorsPath}>
              <AnimatedTabPanel currentItem={currentIndex} index={0} Content={(
                <UniTutorList tutorsToDisplay={mathTutors} />
              )} />
            </Route>
            <Route path={mathTutorsPath}>
              <AnimatedTabPanel currentItem={currentIndex} index={1} Content={(
                <UniTutorList tutorsToDisplay={scienceTutors} />
              )} />
            </Route>
            <Route path={languagesTutorsPath}>
              <AnimatedTabPanel currentItem={currentIndex} index={2} Content={(
                <UniTutorList tutorsToDisplay={languagesTutors} />
              )} />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default StudentPrepCenterProfessorsPage;
