import { Box } from '@material-ui/core';
import { useFile } from 'api';
import { Components } from 'client/UniClient';
import { UniIconWrapper, UniTypography } from 'components';
import { ReactComponent as ApplicationIcon } from '../../assets/svg/Icons/Applications.svg';
import { ReactComponent as EyeIcon } from '../../assets/svg/Icons/Eye.svg';
import { StyledOverflowText } from './ApplicationReplyHistoryItem.style';

interface Props {
  file: Components.Schemas.FileDto;
}

const ApplicationReplyHistoryItemFile: React.FC<Props> = ({ file, ...rest }) => {
  const { data: openFileData, error: fileError } = useFile({
    id: file?.id!,
  });
  const fileName = file?.label || file?.originalName || 'Document';

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <Box display='flex'>
        <UniIconWrapper color='typographyGray70' size='medium'>
          <ApplicationIcon />
        </UniIconWrapper>
        <Box ml={1.5}>
          <UniTypography color='typographyGray100' variant='subheader'>
            <StyledOverflowText>{fileName}</StyledOverflowText>
          </UniTypography>
        </Box>
      </Box>
      <Box display='flex'>
        <UniIconWrapper
          color='typographyGray100'
          size='medium'
          onClick={() => {
            window.open(openFileData?.url, '_blank');
          }}
        >
          <EyeIcon />
        </UniIconWrapper>
      </Box>
    </Box>
  );
};

export default ApplicationReplyHistoryItemFile;
