import { TableRow } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../constants';
export const FacultyHeader = styled.div<{
  changeNavbar?: boolean;
}>`
  position: 'relative';
  background-color: ${Colors.White._100};
  border-radius: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
  padding: ${({ theme }) => theme.spacing(9)}px 16px 24px 16px;
  margin-top: -${({ theme }) => theme.sizes.authNavbarHeight + 40}px;
`;
