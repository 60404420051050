import { Paths } from 'client/UniClient';
import { MutationConfig, useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export default function useDeleteServiceOffering(config?: MutationConfig<any>) {
  const { client } = useClient();
  const queryCache = useQueryCache();

  return useMutation(
    async (paths: Paths.ServiceOptionApiDeleteServiceOffering.PathParameters) => client!.ServiceOptionApi_deleteServiceOffering(paths),
    {
      ...config,
      onSuccess() {
        queryCache.invalidateQueries(CacheKeys.Agency.Services);
      },
    },
  );
}
