export const activitiesCategoryMap = {
  INTERNSHIPS_WORK: 'Internships work',
  VOLUNTEER_WORK: 'Volunteer work',
  ARTS: 'Arts',
  COURSES: 'Courses',
  PUBLICATIONS: 'Publications',
  SPECIAL_EXAMS: 'Special exams',
  SUMMER_CAMPS: 'Summer camps',
  DEBATE: 'Debate',
  LEADERSHIP: 'Leadership',
  CONFERENCES: 'Conferences',
  WORKSHOP: 'Workshop',
  AWARDS: 'Awards',
  SPORTS: 'Sports',
  EVENT: 'Event',
  OTHER: 'Other',
};

export const activitiesStatusMap = {
  NOT_STARTED: 'Not started',
  IN_REVIEW: 'In review',
  UPDATES_REQUIRED: 'Updates required',
  VALIDATED: 'Validated',
};

export const activitiesTypeMap = {
  ACHIEVEMENT: 'Achievement',
  TODO: 'Todo',
};

export const activitiesOptions = [
  { value: 'INTERNSHIPS_WORK', label: 'Internships work' },
  { value: 'VOLUNTEER_WORK', label: 'Volunteer work' },
  { value: 'ARTS', label: 'Arts' },
  { value: 'COURSES', label: 'Courses' },
  { value: 'PUBLICATIONS', label: 'Publications' },
  { value: 'SPECIAL_EXAMS', label: 'Special exams' },
  { value: 'SUMMER_CAMPS', label: 'Summer camps' },
  { value: 'DEBATE', label: 'Debate' },
  { value: 'LEADERSHIP', label: 'Leadership' },
  { value: 'CONFERENCES', label: 'Conferences' },
  { value: 'AWARDS', label: 'Awards' },
  { value: 'SPORTS', label: 'Sports' },
  { value: 'OTHER', label: 'Other' },
];

export const applicationStatusOptions = [
  { value: 'NOT_STARTED', label: 'Not started' },
  { value: 'IN_REVIEW', label: 'In review' },
  { value: 'UPDATES_REQUIRED', label: 'Updates required' },
  { value: 'VALIDATED', label: 'Validated' },
];
