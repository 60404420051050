import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { Paths } from '../../client/UniClient';
import { useClient } from '../../store';

type Payload = Paths.StudentApiSubscribeToMarketing.PathParameters;

const useSubscribeToMarketing = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      if (!client) return;
      const { data } = await client.StudentApi_subscribeToMarketing(payload);
      return data;
    },
    {
      onSuccess: data => {},
      onError: (error: AxiosError) => {},
    },
  );
};
export default useSubscribeToMarketing;
