export const languageOptions = [
  {
    code: 'AB',
    name: 'Abkhaz',
  },
  {
    code: 'AA',
    name: 'Afar',
  },
  {
    code: 'AF',
    name: 'Afrikaans',
  },
  {
    code: 'AK',
    name: 'Akan',
  },
  {
    code: 'SQ',
    name: 'Albanian',
  },
  {
    code: 'AM',
    name: 'Amharic',
  },
  {
    code: 'AR',
    name: 'Arabic',
  },
  {
    code: 'AN',
    name: 'Aragonese',
  },
  {
    code: 'HY',
    name: 'Armenian',
  },
  {
    code: 'AS',
    name: 'Assamese',
  },
  {
    code: 'AV',
    name: 'Avaric',
  },
  {
    code: 'AE',
    name: 'Avestan',
  },
  {
    code: 'AY',
    name: 'Aymara',
  },
  {
    code: 'AZ',
    name: 'Azerbaijani',
  },
  {
    code: 'BM',
    name: 'Bambara',
  },
  {
    code: 'BA',
    name: 'Bashkir',
  },
  {
    code: 'EU',
    name: 'Basque',
  },
  {
    code: 'BE',
    name: 'Belarusian',
  },
  {
    code: 'BN',
    name: 'Bengali; Bangla',
  },
  {
    code: 'BH',
    name: 'Bihari',
  },
  {
    code: 'BI',
    name: 'Bislama',
  },
  {
    code: 'BS',
    name: 'Bosnian',
  },
  {
    code: 'BR',
    name: 'Breton',
  },
  {
    code: 'BG',
    name: 'Bulgarian',
  },
  {
    code: 'MY',
    name: 'Burmese',
  },
  {
    code: 'CA',
    name: 'Catalan; Valencian',
  },
  {
    code: 'CH',
    name: 'Chamorro',
  },
  {
    code: 'CE',
    name: 'Chechen',
  },
  {
    code: 'NY',
    name: 'Chichewa; Chewa; Nyanja',
  },
  {
    code: 'ZH',
    name: 'Chinese',
  },
  {
    code: 'CV',
    name: 'Chuvash',
  },
  {
    code: 'KW',
    name: 'Cornish',
  },
  {
    code: 'CO',
    name: 'Corsican',
  },
  {
    code: 'CR',
    name: 'Cree',
  },
  {
    code: 'HR',
    name: 'Croatian',
  },
  {
    code: 'CS',
    name: 'Czech',
  },
  {
    code: 'DA',
    name: 'Danish',
  },
  {
    code: 'DV',
    name: 'Divehi; Dhivehi; Maldivian;',
  },
  {
    code: 'NL',
    name: 'Dutch',
  },
  {
    code: 'DZ',
    name: 'Dzongkha',
  },
  {
    code: 'EN',
    name: 'English',
  },
  {
    code: 'EO',
    name: 'Esperanto',
  },
  {
    code: 'ET',
    name: 'Estonian',
  },
  {
    code: 'EE',
    name: 'Ewe',
  },
  {
    code: 'FO',
    name: 'Faroese',
  },
  {
    code: 'FJ',
    name: 'Fijian',
  },
  {
    code: 'FI',
    name: 'Finnish',
  },
  {
    code: 'FR',
    name: 'French',
  },
  {
    code: 'FF',
    name: 'Fula; Fulah; Pulaar; Pular',
  },
  {
    code: 'GL',
    name: 'Galician',
  },
  {
    code: 'KA',
    name: 'Georgian',
  },
  {
    code: 'DE',
    name: 'German',
  },
  {
    code: 'EL',
    name: 'Greek, Modern',
  },
  {
    code: 'GN',
    name: 'GuaranÃ',
  },
  {
    code: 'GU',
    name: 'Gujarati',
  },
  {
    code: 'HT',
    name: 'Haitian; Haitian Creole',
  },
  {
    code: 'HA',
    name: 'Hausa',
  },
  {
    code: 'HE',
    name: 'Hebrew (modern)',
  },
  {
    code: 'HZ',
    name: 'Herero',
  },
  {
    code: 'HI',
    name: 'Hindi',
  },
  {
    code: 'HO',
    name: 'Hiri Motu',
  },
  {
    code: 'HU',
    name: 'Hungarian',
  },
  {
    code: 'IA',
    name: 'Interlingua',
  },
  {
    code: 'ID',
    name: 'Indonesian',
  },
  {
    code: 'IE',
    name: 'Interlingue',
  },
  {
    code: 'GA',
    name: 'Irish',
  },
  {
    code: 'IG',
    name: 'Igbo',
  },
  {
    code: 'IK',
    name: 'Inupiaq',
  },
  {
    code: 'IO',
    name: 'Ido',
  },
  {
    code: 'IS',
    name: 'Icelandic',
  },
  {
    code: 'IT',
    name: 'Italian',
  },
  {
    code: 'IU',
    name: 'Inuktitut',
  },
  {
    code: 'JA',
    name: 'Japanese',
  },
  {
    code: 'JV',
    name: 'Javanese',
  },
  {
    code: 'KL',
    name: 'Kalaallisut, Greenlandic',
  },
  {
    code: 'KN',
    name: 'Kannada',
  },
  {
    code: 'KR',
    name: 'Kanuri',
  },
  {
    code: 'KS',
    name: 'Kashmiri',
  },
  {
    code: 'KK',
    name: 'Kazakh',
  },
  {
    code: 'KM',
    name: 'Khmer',
  },
  {
    code: 'KI',
    name: 'Kikuyu, Gikuyu',
  },
  {
    code: 'RW',
    name: 'Kinyarwanda',
  },
  {
    code: 'KY',
    name: 'Kyrgyz',
  },
  {
    code: 'KV',
    name: 'Komi',
  },
  {
    code: 'KG',
    name: 'Kongo',
  },
  {
    code: 'KO',
    name: 'Korean',
  },
  {
    code: 'KU',
    name: 'Kurdish',
  },
  {
    code: 'KJ',
    name: 'Kwanyama, Kuanyama',
  },
  {
    code: 'LA',
    name: 'Latin',
  },
  {
    code: 'LB',
    name: 'Luxembourgish, Letzeburgesch',
  },
  {
    code: 'LG',
    name: 'Ganda',
  },
  {
    code: 'LI',
    name: 'Limburgish, Limburgan, Limburger',
  },
  {
    code: 'LN',
    name: 'Lingala',
  },
  {
    code: 'LO',
    name: 'Lao',
  },
  {
    code: 'LT',
    name: 'Lithuanian',
  },
  {
    code: 'LU',
    name: 'Luba-Katanga',
  },
  {
    code: 'LV',
    name: 'Latvian',
  },
  {
    code: 'GV',
    name: 'Manx',
  },
  {
    code: 'MK',
    name: 'Macedonian',
  },
  {
    code: 'MG',
    name: 'Malagasy',
  },
  {
    code: 'MS',
    name: 'Malay',
  },
  {
    code: 'ML',
    name: 'Malayalam',
  },
  {
    code: 'MT',
    name: 'Maltese',
  },
  {
    code: 'MI',
    name: 'MÄori',
  },
  {
    code: 'MR',
    name: 'Marathi (MarÄá¹hÄ«)',
  },
  {
    code: 'MH',
    name: 'Marshallese',
  },
  {
    code: 'MN',
    name: 'Mongolian',
  },
  {
    code: 'NA',
    name: 'Nauru',
  },
  {
    code: 'NV',
    name: 'Navajo, Navaho',
  },
  {
    code: 'NB',
    name: 'Norwegian BokmÃ¥l',
  },
  {
    code: 'ND',
    name: 'North Ndebele',
  },
  {
    code: 'NE',
    name: 'Nepali',
  },
  {
    code: 'NG',
    name: 'Ndonga',
  },
  {
    code: 'NN',
    name: 'Norwegian Nynorsk',
  },
  {
    code: 'NO',
    name: 'Norwegian',
  },
  {
    code: 'II',
    name: 'Nuosu',
  },
  {
    code: 'NR',
    name: 'South Ndebele',
  },
  {
    code: 'OC',
    name: 'Occitan',
  },
  {
    code: 'OJ',
    name: 'Ojibwe, Ojibwa',
  },
  {
    code: 'CU',
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
  },
  {
    code: 'OM',
    name: 'Oromo',
  },
  {
    code: 'OR',
    name: 'Oriya',
  },
  {
    code: 'OS',
    name: 'Ossetian, Ossetic',
  },
  {
    code: 'PA',
    name: 'Panjabi, Punjabi',
  },
  {
    code: 'PI',
    name: 'PÄli',
  },
  {
    code: 'FA',
    name: 'Persian (Farsi)',
  },
  {
    code: 'PL',
    name: 'Polish',
  },
  {
    code: 'PS',
    name: 'Pashto, Pushto',
  },
  {
    code: 'PT',
    name: 'Portuguese',
  },
  {
    code: 'QU',
    name: 'Quechua',
  },
  {
    code: 'RM',
    name: 'Romansh',
  },
  {
    code: 'RN',
    name: 'Kirundi',
  },
  {
    code: 'RO',
    name: 'Romanian',
  },
  {
    code: 'RU',
    name: 'Russian',
  },
  {
    code: 'SA',
    name: 'Sanskrit',
  },
  {
    code: 'SC',
    name: 'Sardinian',
  },
  {
    code: 'SD',
    name: 'Sindhi',
  },
  {
    code: 'SE',
    name: 'Northern Sami',
  },
  {
    code: 'SM',
    name: 'Samoan',
  },
  {
    code: 'SG',
    name: 'Sango',
  },
  {
    code: 'SR',
    name: 'Serbian',
  },
  {
    code: 'GD',
    name: 'Scottish Gaelic; Gaelic',
  },
  {
    code: 'SN',
    name: 'Shona',
  },
  {
    code: 'SI',
    name: 'Sinhala, Sinhalese',
  },
  {
    code: 'SK',
    name: 'Slovak',
  },
  {
    code: 'SL',
    name: 'Slovene',
  },
  {
    code: 'SO',
    name: 'Somali',
  },
  {
    code: 'ST',
    name: 'Southern Sotho',
  },
  {
    code: 'AZ',
    name: 'South Azerbaijani',
  },
  {
    code: 'ES',
    name: 'Spanish; Castilian',
  },
  {
    code: 'SU',
    name: 'Sundanese',
  },
  {
    code: 'SW',
    name: 'Swahili',
  },
  {
    code: 'SS',
    name: 'Swati',
  },
  {
    code: 'SV',
    name: 'Swedish',
  },
  {
    code: 'TA',
    name: 'Tamil',
  },
  {
    code: 'TE',
    name: 'Telugu',
  },
  {
    code: 'TG',
    name: 'Tajik',
  },
  {
    code: 'TH',
    name: 'Thai',
  },
  {
    code: 'TI',
    name: 'Tigrinya',
  },
  {
    code: 'BO',
    name: 'Tibetan Standard, Tibetan, Central',
  },
  {
    code: 'TK',
    name: 'Turkmen',
  },
  {
    code: 'TL',
    name: 'Tagalog',
  },
  {
    code: 'TN',
    name: 'Tswana',
  },
  {
    code: 'TO',
    name: 'Tonga (Tonga Islands)',
  },
  {
    code: 'TR',
    name: 'Turkish',
  },
  {
    code: 'TS',
    name: 'Tsonga',
  },
  {
    code: 'TT',
    name: 'Tatar',
  },
  {
    code: 'TW',
    name: 'Twi',
  },
  {
    code: 'TY',
    name: 'Tahitian',
  },
  {
    code: 'UG',
    name: 'Uyghur, Uighur',
  },
  {
    code: 'UK',
    name: 'Ukrainian',
  },
  {
    code: 'UR',
    name: 'Urdu',
  },
  {
    code: 'UZ',
    name: 'Uzbek',
  },
  {
    code: 'VE',
    name: 'Venda',
  },
  {
    code: 'VI',
    name: 'Vietnamese',
  },
  {
    code: 'VO',
    name: 'VolapÃ¼k',
  },
  {
    code: 'WA',
    name: 'Walloon',
  },
  {
    code: 'CY',
    name: 'Welsh',
  },
  {
    code: 'WO',
    name: 'Wolof',
  },
  {
    code: 'FY',
    name: 'Western Frisian',
  },
  {
    code: 'XH',
    name: 'Xhosa',
  },
  {
    code: 'YI',
    name: 'Yiddish',
  },
  {
    code: 'YO',
    name: 'Yoruba',
  },
  {
    code: 'ZA',
    name: 'Zhuang, Chuang',
  },
  {
    code: 'ZU',
    name: 'Zulu',
  },
];
