import { Avatar } from '@material-ui/core';
import styled from 'styled-components';
import { ReactComponent as AvatarUploadIcon } from '../../../assets/svg/Icons/AvatarUpload.svg';
import { Colors } from '../../../constants/uiConfig';
import { Color, Size } from './UniAvatar';

type CommonProps = {
  size: Size;
  color?: Color;
};

const SizeMap = {
  XXS: 18,
  XS: 32,
  S: 40,
  M: 56,
  L: 64,
  XL: 80,
};
const BadgeSizeMap = {
  XXS: 6,
  XS: 8,
  S: 14,
  M: 16,
  L: 18,
  XL: 20,
};

export const AvatarContainer = styled.div`
  position: relative;
`;

export const StyledAvatar = styled(Avatar)<CommonProps>`
  position: relative;
  width: ${({ size }) => SizeMap[size]}px;
  height: ${({ size }) => SizeMap[size]}px;
  background-color: ${({ color }) =>
    color === 'primary100' ? Colors.Primary._100 : color === 'secondary100' ? Colors.Secondary._100 : '#f2f2f2'};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledBadge = styled.div<CommonProps>`
  position: absolute;
  /* border-radius: 50%; */
  background: ${Colors.Secondary._100};
  border: ${({ size }) => (size === 'XS' ? undefined : '2px solid ' + Colors.Other.Green_100)};
  width: ${({ size }) => BadgeSizeMap[size]}px;
  height: ${({ size }) => BadgeSizeMap[size]}px;
  svg {
    font-size: 12px;
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const StyledAvatarUploadIcon = styled(AvatarUploadIcon)`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const StyledUniAvatarUpload = styled.div`
  position: absolute;
  bottom: -7px;
  right: -5px;
  width: 32px;
  height: 32px;
  z-index: 10;
`;
