import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { useMutation } from 'react-query';
import { useClient } from '../../store';

export const useUpdateEmail = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Components.Schemas.RequestUpdateEmailRequest) => {
      return client!.AuthApi_requestEmailUpdate(undefined, payload);
    },
    {
      onError: (error: AxiosError) => {
        console.error(error.message);
      },
    },
  );
};
