import { Box, Grid } from '@material-ui/core';
import useMyPrograms from 'api/university/useMyPrograms';
import { Components } from 'client/UniClient';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import useErrorHandler from 'hooks/useErrorHandler';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth, useDrawers } from 'store';
import { getMetaRole } from 'utils';
import { useGetProgramsNames } from '../../api';
import {
  ProgramsList,
  ProgramsListItem,
  ProgramsListItemAgentActions,
  ProgramsListItemStudentActions,
  StickyListFilterBar,
  UniTypography,
} from '../../components';
import { BrowserRoutes } from '../../constants';

interface Props {
  id: string;
}

export default function UniversityPagePrograms({ id }: Props): ReactElement {
  const { t } = useTranslation('agencyAdminPrograms');
  const history = useHistory();
  const { profile } = useAuth();
  const { isAgency, isStudent, isAgent } = getMetaRole(profile.role);
  const [query, setQuery] = useState('');
  const [sortType, setSortType] = useState(1);
  const [selectedPrograms, setSelectedPrograms] = useState<Components.Schemas.ProgramResponse[]>([]);
  const { queries } = useQueryStringParser<ProgramsFiltersQueries>();
  const { openDrawer } = useDrawers();

  const {
    data: programs,
    isFetching,
    fetchMore,
    canFetchMore,
    error: programsError,
  } = useMyPrograms({
    query,
    filters: queries,
    sortType,
    universityId: id,
  });

  const programsLoading = isFetching;
  const programsFlat = useMemo(() => {
    return programs?.map(programPage => programPage.results).flat() || [];
  }, [programs]);
  const programsTotal = useMemo(() => (programs ? programs[0].total : 0), [programs]);
  const totalResultsLabel = useMemo(
    () => (programsTotal === 1 ? programsTotal + ' ' + t('result.ifOne') : programsTotal + ' ' + t('result.ifMore')),
    [programs],
  );

  const fetchError = useErrorHandler(programsError!);
  const { data: programNames, status: programNamesStatus } = useGetProgramsNames();

  const onSort = (type: number) => setSortType(type);

  const selectProgram = (program: Components.Schemas.ProgramResponse) => {
    setSelectedPrograms(prevSelectedPrograms => {
      const index = prevSelectedPrograms?.findIndex(item => item.id === program.id);
      if (index === -1) {
        return [...prevSelectedPrograms, program];
      } else {
        return prevSelectedPrograms.filter(item => item.id !== program.id);
      }
    });
  };

  const selectAllPrograms = (value: boolean) => {
    if (value) {
      setSelectedPrograms(programsFlat);
    } else {
      setSelectedPrograms([]);
    }
  };

  const openFilterProgramsDrawer = () =>
    openDrawer('filter-universitiy-programs', { size: 'small', universityId: profile.facultyMemberProfile?.universityId });

  return (
    <Grid xs={12}>
      <StickyListFilterBar
        listTitle={t('list.title')}
        options={programNames?.map(item => item.label) || []}
        searchHint={t('list.search_hint')}
        handleSearch={setQuery}
        tooltip={t('list.tooltip')}
        totalResultsLabel={totalResultsLabel}
        onClickFilter={openFilterProgramsDrawer}
      />
      {fetchError.message && <UniTypography color='stateAlert100'>{fetchError.message}</UniTypography>}
      <ProgramsList
        hasMore={!!canFetchMore}
        fetchMore={fetchMore}
        hasStatus={false}
        length={programsTotal}
        onSort={onSort}
        isLoading={programsLoading}
        selectAllPrograms={selectAllPrograms}
        selectedPrograms={selectedPrograms.length}
      >
        {programsFlat?.map((program: any, index: any) => (
          <ProgramsListItem
            onClick={() =>
              history.push(
                BrowserRoutes[isAgency ? 'Agency' : isStudent ? 'Student' : 'Agency'].Program.General.replace(':programId', program.id!),
              )
            }
            program={program}
            selectProgram={selectProgram}
            selected={selectedPrograms.findIndex(itm => program.id === itm.id) !== -1}
            ActionButtons={
              isStudent ? (
                <ProgramsListItemStudentActions program={program} />
              ) : isAgency ? (
                <ProgramsListItemAgentActions program={program} />
              ) : isAgent ? (
                <ProgramsListItemAgentActions program={program} />
              ) : (
                <Box />
              )
            }
            stretch={false}
            key={index}
          />
        ))}
      </ProgramsList>
    </Grid>
  );
}
