import { Menu, MenuProps, Popper, PopperProps } from '@material-ui/core';
import { Colors, Shadows } from 'constants/uiConfig';
import styled from 'styled-components';

interface StyledMenuProps extends MenuProps {
  width?: number;
}

export const StyledMenu = styled(Menu)<StyledMenuProps>`
  .MuiMenu-paper {
    width: ${({ width }) => (width ? `${width}px` : 'inherit')};
    border-radius: 8px;
  }
`;

export const StyledDropdown = styled(Popper)`
  background-color: ${Colors.White._100};
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  padding: ${({ theme }) => theme.spacing(1.5, 0)};
  box-shadow: ${Shadows.shadow2};
  border: 1px solid ${Colors.Border.Gray_100};
  border-radius: ${({ theme }) => theme.spacing(0.75)}px;
  z-index: 999;
`;
