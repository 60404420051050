import React from 'react';
import { TComponentColors } from '../../../constants/componentColors';
import { Transform, Variant, Weight } from './types';
import { StyledSpan, StyledTypography } from './UniTypography.style';

export type UniTypographyProps = {
  ref?: React.RefObject<HTMLSpanElement>;
  component?: React.ElementType;
  variant?: Variant;
  weight?: Weight;
  color?: TComponentColors;
  transform?: Transform;
  noSpaceWrap?: boolean;
  style?: React.CSSProperties;
  dangerouslySetInnerHTML?: string;
  keepStyles?: boolean;
  onClick?: () => void;
};
const VariantMap = {
  xl: 'h1',
  header1: 'h2',
  header2: 'h3',
  header3: 'h4',
  body1: 'body1',
  body2: 'body2',
  subheader: 'subtitle1',
  footnote: 'caption',
  small: 'overline',
} as const;

const UniTypography: React.FC<UniTypographyProps> = ({
  variant = 'body2',
  color = 'typographyBlack100',
  weight = 'regular',
  noSpaceWrap,
  children,
  transform = 'none',
  style,
  dangerouslySetInnerHTML,
  keepStyles,
  ...props
}) => {
  if (dangerouslySetInnerHTML && keepStyles) {
    return (
      <StyledTypography
        transform={transform}
        component='p'
        {...props}
        unicolor={color}
        weight={weight}
        variant={VariantMap[variant]}
        style={style}
        noSpaceWrap={noSpaceWrap}
        dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }}
      />
    );
  }

  if (dangerouslySetInnerHTML) {
    return <StyledSpan dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }} {...props} />;
  }

  return (
    <StyledTypography
      transform={transform}
      component='p'
      {...props}
      unicolor={color}
      weight={weight}
      variant={VariantMap[variant]}
      style={style}
      noSpaceWrap={noSpaceWrap}
    >
      {children}
    </StyledTypography>
  );
};

export default UniTypography;
