import React, { ReactElement, useState } from 'react';
import { UniTypography } from 'components';
import { useTranslation } from 'react-i18next';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { useDeleteAdditionalDocument } from '../../../api';
import { Components } from '../../../client/UniClient';
import { theme } from 'theme';
import { useSnackbar } from 'store';

interface Props {
  close: Function;
  document: Components.Schemas.FileDto;
}

export default function DeleteDocument({ close, document }: Props): ReactElement {
  const { t } = useTranslation('studentDocuments');
  const [deleteFile] = useDeleteAdditionalDocument();
  const { openSnackbar } = useSnackbar();

  return (
    <>
      <UniModalDrawerContent>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular' style={{ marginBottom: theme.spacing(1) }}>
          {t('delete_doc_description')}
        </UniTypography>
        <UniTypography variant='body2' color='typographyBlack100' weight='semi-bold' style={{ marginBottom: theme.spacing(1) }}>
          {document?.label}
        </UniTypography>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          {t('are_you_sure')}
        </UniTypography>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: close, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: () => {
            close();
            openSnackbar('delete-document', { hide: document.id, action: () => deleteFile(document?.id) });
          },
          label: t('buttons.delete_doc'),
        }}
      />
    </>
  );
}
