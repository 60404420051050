import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import UniPhoneInput from 'components/_inputs/uniPhoneInput/uniPhoneInput';
import UniTextField from 'components/_inputs/uniTextField/UniTextField';
import React from 'react';

interface Props {
  control: any;
  register: any;
  setValue: any;
  errors: any;
  event?: Components.Schemas.EventResponse;
}

const Step3: React.FC<Props> = ({ control, register, setValue, errors }) => {
  return (
    <>
      <Box mb={2}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          Please enter your guardians contact details bellow:
        </UniTypography>
        <Box mb={2} />
        <UniPhoneInput
          control={control}
          inputRef={register({
            required: 'Phone number is required.',
            minLength: { value: 4, message: 'Phone number is too short.' },
            maxLength: { value: 15, message: 'Phone number must be less than 15 digits.' },
          })}
          error={!!errors.phone?.message || !!errors.phonePrefix?.message}
          helperText={errors.phone?.message || errors.phonePrefix?.message}
          setValue={setValue}
          name='phone'
          label='Parent/Guardian Phone Number'
          fullWidth
        />
        <UniTextField
          name='guardianEmail'
          inputRef={register({ required: 'Email is required.' })}
          helperText={errors?.guardianEmail?.message}
          fullWidth
          label='Parent/Guardian email'
          placeholder='jhon@email.com'
        />
        <UniTextField
          name='guardianName'
          inputRef={register({ required: 'Name is required.' })}
          helperText={errors?.guardianName?.message}
          fullWidth
          label='Parent/Guardian name'
          placeholder='Jhon Doe'
        />
      </Box>
    </>
  );
};

export default Step3;
