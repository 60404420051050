import { useSearchUniversities } from 'api';
import { InfiniteWindowedGrid, UniversityCard } from 'components';
import { UniversitiesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/UniversitiesFilters';
import { countries } from 'constants/countries';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CardSVG } from '../../../../assets/svg/Icons/Card.svg';
import { ReactComponent as LocationSVG } from '../../../../assets/svg/Icons/Location.svg';

interface Props {}

const Universities: React.FC<Props> = ({ ...rest }) => {
  const { t } = useTranslation('programsList');

  const { queries } = useQueryStringParser<UniversitiesFiltersQueries>();

  const {
    data: universities,
    status: universitiesStatus,
    isFetching: isFetchingUniversities,
    fetchMore: fetchMoreUniversities,
    error: universitiesError,
  } = useSearchUniversities({
    filters: { ...queries, query: queries.query || undefined },
  });
  const universitiesLoading = universitiesStatus === 'loading';
  const universitiesFlat = useMemo(() => {
    return universities?.map(universityPage => universityPage.results).flat() || [];
  }, [universities]);
  const universitiesTotal = useMemo(() => (universities ? universities[0].total : 0), [universities]);
  const universitiesLeft = universitiesTotal - universitiesFlat.length;

  return (
    <InfiniteWindowedGrid
      isFetching={isFetchingUniversities}
      emptyStateType='programs'
      totalResults={universitiesTotal}
      fetchMore={fetchMoreUniversities}
      isLoading={universitiesLoading}
      hasMore={universitiesLeft > 0}
      remainingResults={universitiesLeft}
      sortOptions={[
        { value: 0, label: 'By price' },
        { value: 1, label: 'By name' },
      ]}
      results={universitiesFlat?.map((university: any, index: any) => (
        <UniversityCard
          key={`${index}-university`}
          fit
          university={university}
          metricsArr={[
            {
              Icon: <LocationSVG />,
              label: t('headers.location'),
              value: `${university?.address?.city ? university?.address?.city : '-'}, ${
                university?.address?.country
                  ? countries.filter(item => item.code === university.address.country).map(item => item.name)
                  : '-'
              }`,
            },
            {
              Icon: <CardSVG />,
              label: t('program.tuition_fee'),
              value: `${university.averageTuitionFee?.currency?.currencyIso === 'EUR' ? '€' : '$'} ${
                university.averageTuitionFee?.currency?.amount != null ? university.averageTuitionFee.currency.amount?.toFixed(2) : '-'
              }`,
            },
          ]}
        />
      ))}
    />
  );
};

export default Universities;
