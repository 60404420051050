import { Box } from '@material-ui/core';
import { UniTypography } from 'components';
import ItemTypeCard from '../_components/ItemTypeCard';

interface Props {
  onClickTodo: () => void;
  onClickAchievement: () => void;
}

const Step1: React.FC<Props> = ({ onClickAchievement, onClickTodo }) => {
  return (
    <>
      <UniTypography variant='body2' weight='medium'>
        Select the activity type you want to add:
      </UniTypography>
      <Box mt={3} />
      <ItemTypeCard label='To-do Item' description='Candidate needs to complete this task' onClick={onClickTodo} />
      <Box mt={3} />
      <ItemTypeCard label='Achievement' description='Candidate has already completed this task' onClick={onClickAchievement} />
    </>
  );
};

export default Step1;
