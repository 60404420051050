import React from 'react';
import { StyledSessionHistoryItem, StyledSessionHistoryItemLogo } from './SessionHistoryItem.style';
import { UniTypography } from '../index';

interface Props {
  productName: string;
  date: string;
  logoUrl?: string;
  sessionCount: string;
}

export const UniPrepCenterSessionsHistoryItem: React.FC<Props> = ({ productName, date, logoUrl, sessionCount }) => {
  return (
    <StyledSessionHistoryItem>
      {logoUrl ? <StyledSessionHistoryItemLogo src={logoUrl} /> : <div></div>}
      <UniTypography variant='subheader'>{productName}</UniTypography>
      <UniTypography variant='subheader'>{date}</UniTypography>
      <UniTypography variant='subheader'>{sessionCount}</UniTypography>
    </StyledSessionHistoryItem>
  );
};

export default UniPrepCenterSessionsHistoryItem;
