import { createMuiTheme } from '@material-ui/core/styles';
import { Colors, Shadows, Shape, Sizes } from '../constants';
import themeOverrides from './themeOverrides';
import themeProps from './themeProps';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 600,
      lg: 1023,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: Shape.BorderRadius.md,
  },
  uniShadows: {
    shadow1: Shadows.shadow1,
  },
  palette: {
    primary: {
      contrastText: Colors.White._100,
      main: Colors.Primary._100,
    },
    secondary: {
      contrastText: Colors.White._100,
      main: Colors.Secondary._100,
    },
  },

  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightBold: 600,
  },
  sizes: {
    authMaxPageWidth: Sizes.AuthMaxPageWidth,
    authNavbarHeight: Sizes.AuthNavbarHeight,
    drawerWidth: Sizes.DrawerWidth,
    drawerWidthCollapsed: Sizes.DrawerWidthCollapsed,
  },
});

theme.overrides = themeOverrides;
theme.props = themeProps;

export default theme;
