import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { Box } from '@material-ui/core';
import { Paths } from 'client/UniClient';
import { useBreakpoints } from 'hooks';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { OptionType } from '../../../types';
import UniSelect from '../../_inputs/uniSelect/UniSelect';
import { FilterBarActions } from '../uniFilterBar/FilterBarActions';
import { useCandidates } from '../../../api';

interface Props {
  onClickFilter?: Function;
  options?: string[];
  withCandidateFilter?: boolean;
}

type TaskFilterQueries = Paths.TaskApiSearchAgentTasks.QueryParameters;
type StatusType = Paths.TaskApiSearchAgentTasks.Parameters.Status;

export const UniTaskFilterBarForm: React.FC<Props> = ({ onClickFilter, options, withCandidateFilter = false }) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation('taskCard');
  const { queries, pushQueriesToUrl } = useQueryStringParser<TaskFilterQueries>();
  const statusOptions = (['NOT_STARTED', 'IN_PROGRESS', 'IN_REVIEW', 'DONE'] as StatusType).map(
    s => ({ value: s, label: t(`status.${s}`) }),
  );
  const onChangeStatus = (options: MultiValue<OptionType>) => {
    const status: StatusType = options.map(option => option.value);
    pushQueriesToUrl({ status });
  };
  const statusValue = statusOptions.filter(({ value }) => queries?.status?.includes(value))
  const { data: candidatePages, fetchMore, canFetchMore } = useCandidates({ sort: 'NAME', order: 'ASC' });
  const [candidateOptions, setCandidateOptions] = useState<MultiValue<OptionType<string>> | null>(null);
  const candidateValue = candidateOptions?.filter(({ value }) => queries?.assigneeUserId?.includes(value));
  const onChangeCandidate = (options: MultiValue<OptionType<string>>) => {
    const assigneeUserId = options.map(option => option.value);
    pushQueriesToUrl({ assigneeUserId });
  };

  useEffect(() => {
    if (withCandidateFilter && candidatePages != null) {
      if (canFetchMore === true) {
        fetchMore().then();
      } else {
        const c = candidatePages.flatMap(page => page.results);
        setCandidateOptions(c.map(candidate => ({
          value: candidate.id!,
          label: `${candidate.firstName} ${candidate.lastName} (${candidate.username})`
        })));
      }
    }
  }, [candidatePages, canFetchMore]);

  return (
    <>
      <Box
        flex={1}
        minWidth={isMobile ? 'auto' : 450}
        display='flex'
        flexWrap='wrap'
        px={isMobile ? 2 : 0}
        pr={isMobile ? 0 : 3}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {withCandidateFilter && candidateOptions && (
          <Box flex={1} mr={isMobile ? 0 : 1}>
            <UniSelect
              isMulti
              options={candidateOptions}
              value={candidateValue}
              onChange={onChangeCandidate}
              placeholder='Assignee'
              label='Assignee'
            />
          </Box>
        )}
        <Box flex={1} mr={isMobile ? 0 : 1}>
          <UniSelect
            isMulti
            options={statusOptions}
            value={statusValue}
            onChange={onChangeStatus}
            placeholder='Status'
            label='Status'
          />
        </Box>
      </Box>
      <FilterBarActions onClickFilter={() => onClickFilter && onClickFilter()} />
    </>
  );
};

export default UniTaskFilterBarForm;
