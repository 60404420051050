import React from 'react';
import { Components } from 'client/UniClient';
import { StyledPurchasedPrepPacksListWrapper } from './UniPurchasedPrepPacksList.style';
import UniPurchasedPrepPackCard from './UniPurchasedPrepPackCard';

type PurchasedPrepPackDto = Components.Schemas.PurchasedPrepPackDto;

interface Props {
  purchasedPrepPacks: PurchasedPrepPackDto[];
}

export const UniPurchasedPrepPacksList: React.FC<Props> = ({ purchasedPrepPacks }) => {
  return (
    <StyledPurchasedPrepPacksListWrapper>
      {purchasedPrepPacks.filter(pp => pp.remainingSessions > 0).map(pp => (
        <UniPurchasedPrepPackCard purchasedPrepPack={pp} />
      ))}
    </StyledPurchasedPrepPacksListWrapper>
  );
};

export default UniPurchasedPrepPacksList;
