import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components, Paths } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Payload
  extends Paths.EventApiUpdateEvent.PathParameters,
    Omit<Paths.EventApiUpdateEvent.RequestBody, 'images' | 'speakers' | 'agendas'> {
  images: File[];
  speakers: Omit<Components.Schemas.EventSpeakerDto, 'imageFile'> & { imageFile: File }[];
  agendas: (Components.Schemas.EventAgendaDto & { date: string; startTime: string; endTime: string })[];
}

interface Params extends Payload {}

const usePatchEvent = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async ({ eventId, ...payload }: Params) => {
      const requestBody: any = { ...payload };

      requestBody.images = [];
      if (payload?.images?.length) {
        for (let i = 0; i < payload?.images?.length; i++) {
          const image = payload.images[i];
          const uploadedImage = (payload.images[i] as any)?.imageFile as Components.Schemas.FileDto;
          console.log(uploadedImage);
          if (uploadedImage?.id) {
            requestBody.images.push({
              fileId: uploadedImage.id,
            });
            continue;
          }
          let formData = new FormData();
          formData.append('documentType', 'EVENT_IMAGE');
          formData.append('file', image);
          const imgResponse = await client!.FileApi_uploadEventImage(undefined, formData);
          requestBody.images.push({ fileId: imgResponse.data.id });
        }
      }

      requestBody.speakers = [];
      if (payload?.speakers?.length) {
        for (let i = 0; i < payload?.speakers?.length; i++) {
          const speaker = payload.speakers[i];
          const uploadedImage = payload.speakers[i]?.imageFile as any as Components.Schemas.FileDto;
          if (uploadedImage?.id) {
            requestBody.speakers.push(speaker);
            continue;
          }
          let formData = new FormData();
          formData.append('documentType', 'EVENT_SPEAKER_IMAGE');
          formData.append('file', speaker.imageFile);
          const imgResponse = await client!.FileApi_uploadSpeakerImage(undefined, formData);
          requestBody.speakers.push({ ...speaker, imageFileId: imgResponse.data.id });
        }
      }

      if (payload?.agendas?.length) {
        const agendas = payload.agendas.map(agenda => {
          return {
            ...agenda,
            startDate: `${(payload as any).date} ${agenda.startTime}`,
            endDate: `${(payload as any).date} ${agenda.endTime}`,
          };
        });
        requestBody.agendas = agendas;
      }

      return client!.EventApi_updateEvent({ eventId }, requestBody);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Events.All);
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default usePatchEvent;
