import { UniButton, UniIconWrapper, UniTextField } from 'components';
import { useFieldArray } from 'react-hook-form';
import { isValidLinkRegex } from 'utils/validators';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/Icons/Close.svg';

interface Props {
  register: any;
  control: any;
  errors: any;
}

const Step4: React.FC<Props> = ({ control, register, errors }) => {
  const { fields, append, remove } = useFieldArray({
    name: 'links',
    control,
  });

  const onNewLink = () => append({ name: '', description: '' });

  return (
    <>
      {fields.map((field, index) => (
        <>
          <UniTextField
            fullWidth
            label='Add Link'
            placeholder='Paste a link'
            key={field.id}
            helperText={errors?.[index]?.value?.message}
            error={!!errors?.[index]?.value?.message}
            defaultValue={field.value}
            name={`links[${index}].value`}
            inputRef={register({
              pattern: {
                value: isValidLinkRegex,
                message: 'Link must be valid.',
              },
            })}
            endAdornment={
              fields.length > index + 1 && (
                <UniIconWrapper onClick={() => remove(index)}>
                  <CloseIcon />
                </UniIconWrapper>
              )
            }
          />
        </>
      ))}
      <UniButton size='medium' variant='text' color='primary' onClick={onNewLink}>
        Add another link
      </UniButton>
    </>
  );
};

export default Step4;
