import { AxiosError } from 'axios';
import { queryCache, useMutation } from 'react-query';
import { Components } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useAuth, useClient, useSnackbar } from '../../store';

interface Payload {
  params: {
    agencyId: string;
    studentId: string;
  };
  body: Components.Schemas.CreateEducationHistoryItemRequest;
}

const useAddEducationHistoryItem = () => {
  const {
    setProfile,
    profile: { role },
  } = useAuth();
  const { client } = useClient();
  const { openSnackbar } = useSnackbar();

  return useMutation(
    (payload: Payload) => {
      return client!.AgencyApi_addEducationHistoryItem(payload.params, payload.body);
    },
    {
      onSuccess: async () => {
        queryCache.invalidateQueries(CacheKeys.Profile.Extended);
        const { data } = await client!.UserApi_getMyUser();
        setProfile(data);
        openSnackbar('add-education');
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         }); 
         */
      },
    },
  );
};
export default useAddEducationHistoryItem;
