import React from 'react';
import { Components } from 'client/UniClient';
import { Box } from '@material-ui/core';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { useTranslation } from 'react-i18next';
import { UniTypography } from 'components';
import { diplomaTypes } from 'constants/levelOfStudy';
import { useDeleteEducationHistoryItem } from '../../../api';
import { useParams } from 'react-router';
import { useAuth, useSnackbar } from 'store';
import { getMetaRole } from 'utils';

interface Props {
  close: Function;
  educationItem?: Components.Schemas.EducationHistoryItemDto;
}

const EditEducationDrawer: React.FC<Props> = ({ close, educationItem }) => {
  const { t } = useTranslation('deleteEducationDrawer');
  const { openSnackbar } = useSnackbar();
  const { profile } = useAuth();
  const params = useParams<{ id?: string }>();
  const { isStudent } = getMetaRole(profile.role!);

  const diplomaEntry = diplomaTypes.filter(item => item.value === educationItem?.levelOfStudy).map(item => item.text)[0];

  const [deleteEducationHistory] = useDeleteEducationHistoryItem();

  const deleteDoc = () => {
    deleteEducationHistory(
      {
        params: {
          agencyId: isStudent ? profile.studentProfile?.agent.agentProfile.agency.id! : profile.agentProfile?.agency.id!,
          studentId: isStudent ? profile.id! : params.id!,
          educationHistoryItemId: educationItem?.id!,
        },
      },
      {
        onSuccess() {
          close();
        },
      },
    );
  };

  return (
    <>
      <UniModalDrawerContent>
        <Box mb={1.25}>
          <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
            {t('description')}
          </UniTypography>
        </Box>
        <Box mb={1.25}>
          <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
            {diplomaEntry}
          </UniTypography>
        </Box>
        <Box mb={1.25}>
          <UniTypography variant='header3' color='secondary100' weight='semi-bold'>
            {educationItem?.schoolName}
          </UniTypography>
        </Box>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          {t('are_you_sure')}
        </UniTypography>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: close, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: () => { 
            close();
            openSnackbar('delete-education', { hide : educationItem?.id, action: deleteDoc });
          },
          label: t('buttons.delete'),
        }}
      />
    </>
  );
};

export default EditEducationDrawer;
