import styled from 'styled-components';
import { Shadows, Sizes } from '../../../constants';
import { UniHeader } from '../uniHeader/UniHeader';
import { Gradients } from '../../../constants/uiConfig';

export const StyledGradientWrapper = styled(UniHeader)`
  background: ${Gradients.Primary._45dr};
  padding-bottom: ${({ theme }) => theme.spacing(6)}px;
  position: relative;
`;

export const StyledInnerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: ${Sizes.MaxWidthWrapper}px;
  }
`;

export const StyledHeader = styled.div<{ mobile: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  align-items: center;
  ${({ mobile, theme }) =>
    mobile
      ? `
        display:grid;
        margin-bottom: ${theme.spacing(4)}px;
        justify-content: center;
        text-align: center;
        gap: ${theme.spacing(1)}px;
    `
      : `
        display:flex;
        justify-content:space-between;
        margin-bottom: ${theme.spacing(5)}px;
    `}
`;
