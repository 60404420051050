import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import {
  StyledPrepCenterDashboardItem,
  StyledPrepCenterDashboardItemTextWrapper,
  StyledPrepCenterDashboardItemIconWrapper,
  StyledPrepCenterDashboardItemContentWrapper,
  StyledPrepCenterDashboardItemTitle,
  StyledPrepCenterDashboardItemDescription,
} from './UniPrepCenterDashboardItem.style';
import { UniButton } from '../index';

interface Props {
  title: string;
  description: string;
  icon: ReactElement;
  link: string;
  linkName: string;
  textColor?: string;
  background?: string;
}

export const UniPrepCenterDashboardItem: React.FC<Props> = ({ title, description, icon, link, linkName, textColor = '#373737', background = 'white' }) => {
  const history = useHistory();

  return (
    <StyledPrepCenterDashboardItem background={background}>
      <StyledPrepCenterDashboardItemContentWrapper textColor={textColor}>
        <StyledPrepCenterDashboardItemTextWrapper>
          <StyledPrepCenterDashboardItemTitle>
            {title}
          </StyledPrepCenterDashboardItemTitle>
          <StyledPrepCenterDashboardItemDescription>
            {description}
          </StyledPrepCenterDashboardItemDescription>
          <div>
            <UniButton color={'secondary'} onClick={() => history.push(link)}>
              {linkName}
            </UniButton>
          </div>
        </StyledPrepCenterDashboardItemTextWrapper>
        <StyledPrepCenterDashboardItemIconWrapper>
          {icon}
        </StyledPrepCenterDashboardItemIconWrapper>
      </StyledPrepCenterDashboardItemContentWrapper>
    </StyledPrepCenterDashboardItem>
  );
};

export default UniPrepCenterDashboardItem;
