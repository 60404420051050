import { Box } from '@material-ui/core';
import { useAgentNames } from 'api';
import useUpdateStudentAgent from 'api/students/useUpdateStudentAgent';
import { Components } from 'client/UniClient';
import { UniAutocomplete, UniTypography } from 'components';
import React, { useState } from 'react';
import { useDrawers } from 'store';
import { OptionType } from 'types';
import { UniModalDrawerContent } from '../../_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from '../../_common/uniModalDrawer/UniModalDrawerActions';
import { StyledSuccessIllustration } from './AssignAgent.style';

interface Props {
  student?: Components.Schemas.UserResponse;
}

const AssignAgentDrawer: React.FC<Props> = ({ student }) => {
  const { data: agents, isLoading } = useAgentNames();
  const [currentTab, setCurrentTab] = useState(0);
  const [updateAgent, { isLoading: isLoadingUpdate }] = useUpdateStudentAgent();
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedAgent, setSelectedAgent] = useState<OptionType>();
  const { closeDrawer } = useDrawers();
  const studentId = student?.id || '';
  const studentName = `${student?.firstName} ${student?.lastName}`;

  const onSelectAgent = (option: OptionType) => {
    setErrorMessage('');
    setSelectedAgent(option);
  };

  const onCancel = () => closeDrawer();

  const onSubmit = () => {
    if (selectedAgent) {
      updateAgent(
        {
          body: {
            agentId: selectedAgent.value,
          },
          params: {
            studentId,
          },
        },
        {
          onSuccess() {
            setCurrentTab(1);
          },
        },
      );
    } else {
      setErrorMessage('You need to select an agent');
    }
  };

  return (
    <>
      <UniModalDrawerContent>
        {currentTab === 0 && (
          <>
            <UniTypography variant='subheader' weight='regular' color='typographyGray100'>
              You are about to assign candidate to a different consultant:
            </UniTypography>
            <Box mt={1} />
            <UniTypography variant='body2' weight='regular' color='secondary100'>
              {studentName}
            </UniTypography>
            <Box mt={5} />
            <UniAutocomplete
              fullWidth
              value={selectedAgent}
              handleChange={(_: any, value: OptionType) => {
                onSelectAgent(value);
              }}
              isLoading={isLoading}
              options={agents}
              getOptionLabel={option => option.label}
              label='Assign Consultant'
              placeholder='Select consultant'
              labelColor='typographyBlack100'
              helperText={errorMessage}
              error={!!errorMessage}
            />
          </>
        )}
        {currentTab === 1 && (
          <>
            <UniTypography variant='body1' weight='medium' color='secondary100'>
              {studentName} has been assigned to {selectedAgent?.label}
            </UniTypography>
            <Box mt={5} />
            <StyledSuccessIllustration />
          </>
        )}
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={
          currentTab === 0
            ? {
                loading: isLoadingUpdate,
                label: 'Assign',
                onClick: onSubmit,
              }
            : {
                loading: isLoadingUpdate,
                label: 'Close',
                onClick: onCancel,
              }
        }
        SecondaryButton={currentTab === 0 ? { label: 'Cancel', onClick: onCancel } : undefined}
      />
    </>
  );
};
export default AssignAgentDrawer;
