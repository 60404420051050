export type AppStatus =
  | 'NEW'
  | 'VALIDATED'
  | 'SUBMITTED'
  | 'IN_REVIEW'
  | 'INCOMPLETE'
  | 'CONDITIONAL_ADMISSION'
  | 'PASSED'
  | 'ENROLLED'
  | 'FAILED';

export const appStatus = [
  { label: 'Unprocessed', value: 'SUBMITTED' },
  { label: 'Admitted', value: 'PASSED' },
  { label: 'Incomplete', value: 'INCOMPLETE' },
  { label: 'Rejected', value: 'FAILED' },
  { label: 'Conditional offer', value: 'CONDITIONAL_ADMISSION' },
  { label: 'Enrolled', value: 'ENROLLED' },
];
