import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Paths } from 'client/UniClient';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

type Params = Paths.TaskApiGetTask.PathParameters;

const useTask = (params: Params) => {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Tasks.Task, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.TaskApi_getTask(params);
      return data;
    },
    {
      onError(error: AxiosError) {
        console.log(error);
      },
    },
  );
};

export default useTask;
