import useRequestService from 'api/agency/useRequestService';
import { Components } from 'client/UniClient';
import { AnimatedTabPanel, UniModalDrawerButtons } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { AnimatePresence } from 'framer-motion';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import Step1 from './_steps/Step1';
import Step2 from './_steps/Step2';

interface Props {}

export default function RequestService({}: Props): ReactElement {
  const { t } = useTranslation('partnerServicesDrawers');
  const { closeDrawer } = useDrawers();
  const [step, setStep] = useState(0);
  const { register, control, handleSubmit, reset } = useForm();
  const [requestService, { isLoading }] = useRequestService();

  const nextStep = () => {
    setStep(prev => prev + 1);
  };

  const onSave = (formData: Components.Schemas.RequestServiceOptionRequest) => {
    requestService(formData, {
      onSuccess() {
        nextStep();
      },
    });
  };

  const onSubmit = () => {
    handleSubmit(onSave)();
  };

  const buttonTexts = [
    {
      secondaryCallback: closeDrawer,
      primaryCallback: onSubmit,
      secondary: t('buttons.cancel'),
      primary: t('buttons.request'),
      loading: isLoading,
    },
    {
      primaryCallback: closeDrawer,
      primary: t('buttons.back_to_services'),
    },
  ];

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel Content={<Step1 control={control} register={register} />} index={0} currentItem={step} />
          <AnimatedTabPanel Content={<Step2 />} index={1} currentItem={step} />
        </AnimatePresence>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={
          buttonTexts[step]?.secondary
            ? {
                onClick: buttonTexts[step].secondaryCallback,
                label: buttonTexts[step].secondary,
              }
            : undefined
        }
        PrimaryButton={{
          onClick: buttonTexts[step]?.primaryCallback,
          label: buttonTexts[step]?.primary,
          loading: !!buttonTexts[step]?.loading,
        }}
      />
    </>
  );
}
