import { UniButton, UniIconWrapper, UniTypography } from 'components/index';
import { useBreakpoints, useFiltersCount } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIconSvg } from '../../../assets/svg/Icons/Filter24x24.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/Icons/Magnifyingglass.svg';
import { StyledActionsWrapper } from './UniFilterBar.style';

interface Props {
  onClickFilter?: Function;
  onClickSearch?: Function;
}

export const FilterBarActions = ({ onClickFilter, onClickSearch }: Props) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation('filterBar');
  const filtersCount = useFiltersCount();

  return (
    <StyledActionsWrapper mobile={isMobile} style={isMobile ? { marginTop: '25px' } : undefined}>
      <UniButton
        onClick={() => onClickFilter && onClickFilter()}
        size={isMobile ? 'medium' : 'large'}
        variant='main'
        color='primaryFade'
        style={isMobile ? { flex: '1' } : undefined}
        startIcon={
          !isMobile && (
            <UniIconWrapper size='medium' color='primary100'>
              <FilterIconSvg />
            </UniIconWrapper>
          )
        }
        filter={
          <UniTypography variant='footnote' color='primary100'>
            {filtersCount}
          </UniTypography>
        }
      >
        {t('buttons.filter')}
      </UniButton>
      <UniButton
        size={isMobile ? 'medium' : 'large'}
        onClick={() => onClickSearch && onClickSearch()}
        variant='main'
        color='primary'
        style={isMobile ? { flex: '1' } : undefined}
        startIcon={
          !isMobile && (
            <UniIconWrapper size='main' color='white100'>
              <SearchIcon />
            </UniIconWrapper>
          )
        }
      >
        {t('buttons.search')}
      </UniButton>
    </StyledActionsWrapper>
  );
};
