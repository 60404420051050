import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';

interface Props {
  handleSubmit: Function;
}

export default function FiltersDrawerActions({ handleSubmit }: Props): ReactElement {
  const { t } = useTranslation('filtersDrawer');
  const { closeDrawer } = useDrawers();

  return (
    <UniModalDrawerButtons
      SecondaryButton={{ onClick: closeDrawer, label: t('buttons.close') }}
      PrimaryButton={{ onClick: handleSubmit, label: t('buttons.set') }}
    />
  );
}
