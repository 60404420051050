import { Box } from '@material-ui/core';
import { applicationStatusMap } from 'constants/applicaiton';
import React, { useEffect, useState } from 'react';
import { ApplicationStatus } from 'types';
import { Components } from '../../../client/UniClient';
import { TComponentColors } from '../../../constants/componentColors';
import UniTypography from '../uniTypography/UniTypography';
import { Status } from './UniStatus.style';

export type Color = 'secondary' | 'success' | 'error' | 'warning';

export type StatusProps = {
  program?: Components.Schemas.ProgramResponse;
  application?: Partial<Components.Schemas.ApplicationDto>;
  candidate?: Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse>;
  status?: ApplicationStatus;
  children?: React.ReactNode;
  specificColor?: TComponentColors;
  RightIcon?: React.ReactNode;
  compact?: boolean;
};

const UniStatus: React.FC<StatusProps> = ({
  candidate,
  program,
  application,
  status,
  children,
  specificColor,
  RightIcon,
  compact = false,
  ...rest
}) => {
  const [statusMessage, setStatusMessage] = useState('');
  const [statusColor, setStatusColor] = useState<TComponentColors>('secondary100');
  useEffect(() => {
    if (!!candidate) {
      const candidateStatus = candidate?.studentProfile?.candidateStatus || 'NEW_LEAD';
      switch (candidateStatus) {
        case 'NEW_LEAD':
          setStatusMessage('New Lead');
          return;
        case 'CONTRACT_SIGNED':
          setStatusMessage('Contract Signed');
          return;
        case 'RESEARCHING_PROGRAMS':
          setStatusMessage('Ongoing Program Research');
          return;
        case 'PROGRAMS_SELECTED':
          setStatusMessage('Programs Selected');
          return;
        case 'ESSAY_PREP':
          setStatusMessage('Essay Prep');
          return;
        case 'COLLECTING_DOCUMENTS':
          setStatusMessage('Collecting Documents');
          return;
        case 'APPLYING':
          setStatusMessage('Application');
          return;
        case 'CLOSED_WON':
          setStatusMessage('Closed Won');
          return;
        case 'CLOSED_LOST':
          setStatusMessage('Closed Lost');
          return;
      }
    } else if (!!program?.application?.status || status) {
      const appStatus = program?.application?.status || status;
      appStatus && setStatusMessage(applicationStatusMap[appStatus]);
      if (!!program?.application?.enrollment) {
        setStatusColor('secondary100');
      } else {
        setStatusMessage(applicationStatusMap[appStatus!]);
        return;
      }
    } else if (program?.isFavorite) {
      setStatusMessage('Program selected');
      setStatusColor('secondary100');
    } else if (!!application?.status) {
      setStatusMessage(applicationStatusMap[application.status]);
      return;
    } else if (!!program) {
      setStatusMessage('Not Applied');
      setStatusColor('secondary100');
    }
  }, [candidate, program, program?.application?.status]);

  if (!statusMessage) {
    if (!!children) {
      return (
        <div {...rest}>
          <Status color={specificColor || statusColor}>
            <UniTypography color={specificColor || statusColor} variant='subheader'>
              {children}
            </UniTypography>
            {!!RightIcon && (
              <Box ml={1} display='flex' alignItems='center'>
                {RightIcon}
              </Box>
            )}
          </Status>
        </div>
      );
    } else {
      return <Box />;
    }
  }

  return (
    <div {...rest}>
      <Status color={specificColor || statusColor} RightIcon={RightIcon}>
        <UniTypography color={specificColor || statusColor} variant='subheader'>
          {compact ? `${statusMessage.substring(0, 15)}${statusMessage.length > 15 ? '...' : ''}` : statusMessage}
        </UniTypography>
        {!!RightIcon && (
          <Box ml={1} display='flex' alignItems='center'>
            {RightIcon}
          </Box>
        )}
      </Status>
    </div>
  );
};

export default UniStatus;
