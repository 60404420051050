import { Box } from '@material-ui/core';
import { useFile } from 'api';
import { useBreakpoints } from 'hooks';
import { useTranslation } from 'react-i18next';
import { getMetaRole } from 'utils';
import { UniTypography } from '../../..';
import { useAuth } from '../../../../store';
import { theme } from '../../../../theme';
import UniAvatar from '../../../_common/uniAvatar/UniAvatar';
import { Notifications } from './Notifications';

interface Props {}

export const AuthUser = ({}: Props) => {
  const { t } = useTranslation('profileRole');
  const profile = useAuth(state => state.profile);
  const { isMobile } = useBreakpoints();
  const { isAgency, isAgent, isStudent } = getMetaRole(profile.role);
  const showChat = isAgency || isAgent || isStudent;
  const profileImageSrc = useFile({ id: profile.profileImageFile?.id || '' }, { enabled: !!profile.profileImageFile });

  return (
    <Box display='flex' width='100%' justifyContent={isMobile ? 'space-between' : 'flex-end'}>
      <Box
        display='flex'
        flexDirection={isMobile ? 'row-reverse' : 'row'}
        style={{ gap: theme.spacing(isMobile ? 0.5 : 1) }}
        alignItems='center'
      >
        <Box display='flex' flexDirection='column' textAlign={isMobile ? 'left' : 'right'} mx={0.5}>
          <UniTypography color='typographyBlack100' weight='medium' variant='subheader'>
            {profile.firstName} {profile.lastName}
          </UniTypography>
          {!isMobile && (
            <UniTypography variant='footnote' color='typographyGray100' weight='regular'>
              {profile.role ? t(profile.role) : ''}
            </UniTypography>
          )}
        </Box>
        <UniAvatar size='S' src={profileImageSrc.data?.url} alt={profile.username} label={`${profile.firstName} ${profile.lastName}`} />
      </Box>
      <Box display='flex' alignItems='center'>
        <Notifications profile={profile} />
        {/* {showChat && isMobile && <Chat />} */}
      </Box>
    </Box>
  );
};
