import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

export default function usePrepPack(prepPackId: string) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.PrepPacks.PrepPack, prepPackId],
    async (_key: string) => {
      const response = await client!.PrepPackApi_getPrepPack({ prepPackId });
      return response.data;
    },
    {
      onError(error: AxiosError) {
        console.error(error);
      }
    },
  );
}
