import styled from 'styled-components';
import { ReactComponent as Illustration } from '../../../assets/svg/Illustrations/invitationSentIllustration.svg';
import { Colors } from '../../../constants';

export const StyledAgencyInvitation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  & > p {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const FormSection = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
  /* margin-bottom: ${({ theme }) => theme.spacing(2.6)}px; */
  margin-top: ${({ theme }) => theme.spacing(2.25)}px;
  & > * {
    width: 100%;
    margin-right: ${({ theme }) => theme.spacing(2)}px;
    font-size: small;
  }
`;

export const FormSectionWithButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(2.25)}px;
  gap: ${({ theme }) => theme.spacing(2)}px;

  & > * {
    width: 100%;
  }
`;

export const InviteCandidateHeader = styled.div`
  padding: ${({ theme }) => theme.spacing(4, 5)};
  background-color: ${Colors.Gray2._40};
`;

export const InviteCandidateBody = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2, 5)};
`;

export const InviteCandidateWrapper = styled.div`
  height: 100%;
  overflow: auto;
  background-color: ${Colors.White._100};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledSuccessWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2, 5)};
  overflow-x: hidden;
`;

export const SuccessIllustration = styled(Illustration)`
  width: 100%;
  max-width: 420px;
  height: 60%;
  max-height: 300px;
  margin-bottom: ${({ theme }) => theme.spacing(10)}px;
`;
