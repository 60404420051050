import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UniCheckbox, UniSortButton, UniTypography } from '../../../../components';
import { AgentsQueryParams, AgentsSort, OnAgentsSort } from '../../../../components/agentsList/AgentsList';

const StyledAgentsListHeader = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 2fr repeat(4, 1fr) 2fr 3.5fr;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;

type HeaderProps = {
  onSort?: OnAgentsSort;
  params?: AgentsQueryParams;
  selectAllAgents?: Function;
  allSelected?: boolean;
  sortType?: AgentsSort;
  order?: OrderType;
};

export const MyAgentsListHeader: React.FC<HeaderProps> = ({ onSort, selectAllAgents, allSelected, sortType, order, params }) => {
  const { t } = useTranslation('agencyAdminAgents');
  const ord = order ? order : 'ASC';
  const byName = sortType === 'NAME';
  const byDateAdded = sortType === 'DATE_ADDED';
  // const byNationality = params._sort === "nationality";
  // const byStudy = params._sort === "levelOfStudy";

  return (
    <StyledAgentsListHeader>
      <UniCheckbox
        checked={allSelected}
        onClick={(e: any) => e.stopPropagation()}
        onChange={(e: any) => {
          e.stopPropagation();
          selectAllAgents && selectAllAgents(e.target.checked);
        }}
      />
      {onSort ? (
        <UniSortButton onClick={() => onSort('NAME')} active={byName} order={ord}>
          {t('list.headers.name')}
        </UniSortButton>
      ) : (
        <UniTypography weight='medium' variant='subheader'>
          {t('list.headers.name')}
        </UniTypography>
      )}
      <UniTypography weight='medium' variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {t('list.headers.role')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {t('list.headers.unprocessed')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {t('list.headers.applicants')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {t('list.headers.enrolled')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {t('list.headers.phone_number')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader' style={{ display: 'flex', justifyContent: 'center' }}>
        {t('list.headers.actions')}
      </UniTypography>
    </StyledAgentsListHeader>
  );
};
