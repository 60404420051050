import { AxiosError } from 'axios';
import { Components, Paths } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  id: string;
};

export default function useFile(params: Params, config?: QueryConfig<Components.Schemas.UrlDto>) {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Profile.File, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.FileApi_getDownloadUrl({
        fileId: params.id,
      });
      return data;
    },
    {
      ...config,
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
