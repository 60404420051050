import { Box } from '@material-ui/core';
import { EditableUniTextForPhone } from 'components/_common/editableUniText/EditableUniTextForPhone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UniTypography } from '..';
import { ReactComponent as HourglassIcon } from '../../assets/svg/Icons/Program/Hourglass.svg';
import { Profile } from '../../types/profile';
import { StyledProgramDataCard, StyledNameRow } from '../programExtended/ProgramExtended.style';
import { EditableUniText } from '../_common/editableUniText/EditableUniText';

export const AgentDetailsPersonal = (props: {
  setValue: Function;
  control: any;
  isOwner: boolean;
  profile?: Partial<Profile>;
  isEditing: boolean;
  inputRef: React.Ref<any>;
  hasError?: string;
}) => {
  const { t } = useTranslation('agencyProfile');
  return (
    <StyledProgramDataCard>
      <Box mb={3}>
        <UniTypography color='primary100' weight='medium'>
          {t('labels.personal_details.title')}
        </UniTypography>
      </Box>
      {props.isOwner && (
        <StyledNameRow>
          <EditableUniText
            name='firstName'
            isEditing={props.isEditing}
            inputRef={props.inputRef}
            label={t('labels.personal_details.first_name')}
            value={props.profile?.firstName ?? ''}
            iconColor='secondary100'
            Icon={<HourglassIcon />}
          />
          <EditableUniText
            name='middleName'
            isEditing={props.isEditing}
            inputRef={props.inputRef}
            label={t('labels.personal_details.middle_name')}
            value={props.profile?.middleName ?? '_'}
          />
          <EditableUniText
            name='lastName'
            isEditing={props.isEditing}
            inputRef={props.inputRef}
            label={t('labels.personal_details.last_name')}
            value={props.profile?.lastName ?? ''}
          />
        </StyledNameRow>
      )}

      <Box display='flex' flexDirection='row'>
        <Box flex={1} mr={2}>
          <EditableUniTextForPhone
            setValue={props.setValue}
            control={props.control}
            name='phoneNumber'
            isEditing={props.isEditing}
            inputRef={props.inputRef}
            label={t('labels.personal_details.phone_number')}
            value={props.profile?.phone || ''}
            valuePrefix={props.profile?.phonePrefix || ''}
            iconColor='secondary100'
            Icon={<HourglassIcon />}
          />
        </Box>
        <Box flex={2}>
          <EditableUniText
            name='email'
            isEditing={props.isEditing}
            inputRef={props.inputRef}
            label={t('labels.personal_details.email')}
            value={props.profile?.email ?? ''}
            iconColor='secondary100'
            Icon={<HourglassIcon />}
            fullWidth
          />
        </Box>
      </Box>
      {props.hasError && (
        <Box mt={2}>
          <UniTypography color='stateAlert100'>{props.hasError}</UniTypography>
        </Box>
      )}
    </StyledProgramDataCard>
  );
};
