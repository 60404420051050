import { Box } from '@material-ui/core';
import { useProgramFilters } from 'api';
import { UniButton, UniIconWrapper, UniTypography } from 'components';
import { useTranslation } from 'react-i18next';
import { BrowserRoutes } from 'constants/browserRoutes';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme';
import { ReactComponent as PhoneIcon } from '../../../../assets/svg/Icons/PhoneIcon.svg';
import { ReactComponent as RightIcon } from '../../../../assets/svg/Icons/Arrow/ArrowRight-new.svg';
import { Colors, Shadows, Shape } from '../../../../constants';
import countriesWithRegions from '../../../../constants/countriesWithRegions.json';
import { ContinentSelect } from '../../../../components/continentSelect/ContinentSelect';
import { ContinentTileProps } from '../../../../components/continentSelect/ContinentTile';

interface Props {}

export const StyledContinentsWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.spacing(4)}px;
  max-width: ${({ theme }) => theme.sizes.authMaxPageWidth}px;
`;

const StyledTooltip = styled.div`
  padding: ${props => props.theme.spacing(1, 2)};
  background-color: rgba(209, 46, 113, 0.8);
  border-radius: ${Shape.BorderRadius.md}px;
  box-shadow: ${Shadows.shadow2};
  position: relative;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid rgba(209, 46, 113, 0.8);
  }
`;

const RightIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
`;

const continentDetails: ContinentTileProps[] = [
  {
    title: 'United States of America',
    description: "Explore the USA's fusion of innovation and academic tradition.",
    image: 'usa',
    continentPageUrl: '/continent/north-america/country/united-states/about',
  },
  {
    title: 'Europe',
    description: "Dive into Europe's rich academic legacy and diverse culture.",
    image: 'europe',
    continentPageUrl: '/continent/europe',
  },
  {
    title: 'Canada',
    description: "Discover Canada's globally recognized and welcoming academic scene.",
    image: 'canada',
    continentPageUrl: '/continent/north-america/country/canada/about',
  },
  {
    title: 'Australia',
    description: "Experience Australia's mix of research excellence and vibrant campus life.",
    image: 'australia',
    continentPageUrl: '/continent/oceania/country/australia/about',
  },
];

const ContinentsList: React.FC<Props> = ({}) => {
  const { t } = useTranslation('landingHome');
  const { data } = useProgramFilters();
  const countries = data?.countries;
  const history = useHistory();
  const onClickGeography = (geo: any) => {
    history.push(BrowserRoutes.Default.Continent(geo.properties.path));
  };

  const countriesByCode = countries?.reduce((acc, country) => {
    acc[country.code] = country;
    return acc;
  }, {} as Record<string, any>);

  const countriesInRegion = countriesWithRegions.reduce((acc, country) => {
    if (country.region in acc) {
      acc[country.region].push(country['alpha-2']);
    } else {
      acc[country.region] = [country['alpha-2']];
    }
    return acc;
  }, {} as Record<string, any[]>);

  const sumCountryResults = (countries: string[]) => {
    if (!countriesByCode) return 0;
    const sum = countries.reduce((acc, country) => {
      if (country in countriesByCode) {
        acc += 1;
      }
      return acc;
    }, 0);
    return sum;
  };

  const resultsPerContinent = useMemo(() => {
    const continents = {
      africa: sumCountryResults(countriesInRegion.Africa),
      asia: sumCountryResults(countriesInRegion.Asia),
      europe: sumCountryResults(countriesInRegion.Europe),
      'north-america': sumCountryResults(countriesInRegion.Americas),
      'south-america': sumCountryResults(countriesInRegion.Americas),
      oceania: sumCountryResults(countriesInRegion.Oceania),
    };

    return continents;
  }, [countries]);

  const getGeoTooltip = (geo: any) => {
    const tooltipTitle = geo?.properties?.continent;
    const continentPath = geo?.properties?.path as keyof typeof resultsPerContinent;

    return (
      <StyledTooltip>
        <Box display='flex' flexDirection='column'>
          <UniTypography variant='body1' weight='bold' color='white100'>
            {tooltipTitle}
          </UniTypography>
          <UniTypography variant='footnote' color='white100'>
            {resultsPerContinent[continentPath]} countries
          </UniTypography>
        </Box>
        <RightIconWrapper>
          <UniIconWrapper size='main' color='primary100'>
            <RightIcon />
          </UniIconWrapper>
        </RightIconWrapper>
      </StyledTooltip>
    );
  };
  return (
    <StyledContinentsWrapper id='continents-section'>
      <Box display='flex' justifyContent='space-between' flexWrap='wrap' style={{ gap: `${theme.spacing(2)}px` }}>
        <UniTypography variant='header1' color='secondary100'>
          {t('newtext.section_2.part_1')}
          <br />
          <b>{t('newtext.section_2.part_2')}</b>
        </UniTypography>
        <a href='https://meetings.hubspot.com/daniel-posdarascu' style={{ textDecoration: 'none' }}>
          <UniButton
            startIcon={
              <UniIconWrapper size='main' color='white100'>
                <PhoneIcon />
              </UniIconWrapper>
            }
            color='primary'
          >
            {t('buttons.call')}
          </UniButton>
        </a>
      </Box>
      <ContinentSelect continentList={continentDetails} />
    </StyledContinentsWrapper>
  );
};

export default ContinentsList;
