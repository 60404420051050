import { Box } from '@material-ui/core';
import { useStudentOrders } from 'api';
import { Components } from 'client/UniClient';
import { UniButton, UniPageLoader, UniPageTitle, UniTypography } from 'components';
import ServiceOrder from 'components/serviceOrder/ServiceOrder';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';

interface Props {
  student: Components.Schemas.UserDto;
}

const StudentOrders: React.FC<Props> = ({ children, student, ...rest }) => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawers();
  const { data: orders, isLoading } = useStudentOrders({ studentId: student?.id! });

  if (!orders || isLoading) {
    return <UniPageLoader />;
  }

  return (
    <>
      <UniButton onClick={() => openDrawer('create-service-order', { studentId: student?.id! })}>Add new order</UniButton>
      <Box mt={3} />
      <UniPageTitle title='Received orders' size='M' />
      <Box mt={3} mb={3}>
        <UniTypography variant='subheader' weight='medium' color='typographyGray100'>
          These are the orders for the services you suggested to your candidate.
        </UniTypography>
      </Box>

      {orders.map((order, index) => (
        <>
          <ServiceOrder {...order} key={index} />
          <Box mt={3} />
        </>
      ))}
    </>
  );
};

export default StudentOrders;
