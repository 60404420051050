import { Box } from '@material-ui/core';
import { useFile } from 'api';
import React from 'react';
import { Link } from 'react-router-dom';
import { Components } from '../../client/UniClient';
import { UniAvatar, UniCheckbox, UniTypography } from '../../components';
import { BrowserRoutes } from '../../constants';
import { StyledAgentsListItem } from './AgentsListItem.style';

interface AgentWithStarred extends Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse> {
  starred?: boolean;
}

type AgentProps = {
  agent: AgentWithStarred & Partial<Components.Schemas.UserDto & Components.Schemas.UserResponse>;
  onClick: Function;
  selectAgent?: Function;
  selected?: boolean;
};

const AgentsListItem: React.FC<AgentProps> = React.memo(({ agent, onClick, selectAgent, selected }) => {
  const { profileImageFile, email, firstName, lastName, agentMetadata, id } = agent || {};
  const profileImageSrc = useFile({ id: profileImageFile?.id || '' }, { enabled: !!profileImageFile });

  return (
    <StyledAgentsListItem onClick={e => onClick(id)}>
      <UniCheckbox
        checked={selected}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          e.stopPropagation();
          selectAgent && selectAgent(agent);
        }}
      />
      <Box display='flex' alignItems='center'>
        <UniAvatar online={false} label={firstName + ' ' + lastName} src={profileImageSrc.data?.url} size='S' />
        <Box ml={1}>
          <Link to={BrowserRoutes.Agent.Agent(agent.agentProfile?.agency.id || '', agent.id || '')}>
            <UniTypography color='primary100'>{firstName + ' ' + lastName}</UniTypography>
          </Link>

          <UniTypography variant='footnote' color='typographyGray100'>
            {email && email.substring(0, 15)}...
          </UniTypography>
        </Box>
      </Box>
      <UniTypography variant='subheader'>{agentMetadata?.studentCount || '-'}</UniTypography>
      <UniTypography variant='subheader'>{agentMetadata?.applicationCount || '-'}</UniTypography>
      <UniTypography variant='subheader'>{agentMetadata?.acceptedApplicationCount || '-'}</UniTypography>
      <UniTypography variant='subheader'>{agentMetadata?.conversionRate || '-'}</UniTypography>
    </StyledAgentsListItem>
  );
});

export default React.memo(AgentsListItem);
