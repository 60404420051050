import { Fade } from '@material-ui/core';
import usePatchEvent from 'api/events/usePatchEvent';
import usePostEvent from 'api/events/usePostEvent';
import usePostQRAchievement from 'api/qr-tokens/usePostQRAchievement';
import { Components, Paths } from 'client/UniClient';
import { AnimatedTabPanel, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { Error, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDrawers } from 'store';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import ActionsFooter from './_components/ActionsFooter';
import Step1 from './_steps/Step1';
import Step1b from './_steps/Step1b';
import Step2 from './_steps/Step2';
import Step3 from './_steps/Step3';
import Step4 from './_steps/Step4';
import Step5 from './_steps/Step5';
import Step6 from './_steps/Step6';

type Form = Paths.EventApiAddEvent.RequestBody;

export interface CreateEventProps {
  event?: Components.Schemas.EventResponse;
}

const CreateEvent: React.FC<CreateEventProps> = ({ event }) => {
  const { control, register, handleSubmit, reset, watch, errors } = useForm<Form>({ defaultValues: event, shouldUnregister: false });
  const [formState, setFormState] = useState<Partial<Form>>();
  const [QRTokenDto, setQRTokenDto] = useState<Components.Schemas.QRTokenDto>();
  const [postQRAchievement, { isLoading: postQRIsLoading }] = usePostQRAchievement();
  const [postEvent, { isLoading: postEventIsLoading, error: postEventError }] = usePostEvent();
  const [patchEvent, { isLoading: patchEventIsLoading, error: patchEventError }] = usePatchEvent();
  const [step, setStep] = useState(0);
  const { setProgress } = useDrawers();
  const formType = watch()?.type;
  const addressTypes = ['OFFLINE', 'HYBRID'];
  const isOffline = !!((formType && addressTypes.includes(formType)) || (formState?.type && addressTypes.includes(formState?.type)));
  const isLoading = postQRIsLoading || postEventIsLoading || patchEventIsLoading;

  const prevStep = (formData: Form) => {
    setStep(prev => {
      if (prev === 2 && !isOffline) return prev - 2;
      return prev - 1;
    });
    onSaveForm(formData);
  };

  const nextStep = (formData: Form) => {
    if (step === 5) {
      onSubmit(formData);
      return;
    }
    setStep(prev => {
      if (prev === 0 && !isOffline) return prev + 2;
      return prev + 1;
    });
    onSaveForm(formData);
  };

  const onSaveForm = (formData: Form) => {
    setFormState(prev => {
      const newFormState = { ...prev, ...formData };
      reset({ ...newFormState, ...formData });
      return newFormState;
    });
  };

  const onPostSuccesfull = (event: Form, eventId: string) => {
    const qrAchievementData: Components.Schemas.AddAchievementRequest = {
      ...event,
      category: event.category as any,
      title: event.name,
      description: event.description,
    };

    postQRAchievement(
      { payload: { qrAchievementData, type: 'ATTEND_EVENT', eventId } },
      {
        onSuccess({ data }) {
          setStep(6);
          setQRTokenDto(data);
        },
      },
    );
  };

  const onSubmit = (formData: Form) => {
    const postData = { ...formState, ...formData };
    if (!postData) return;

    if (!!event) {
      const patchData = { ...event, ...postData };
      patchEvent({ eventId: event?.id!, ...patchData } as any, { onSuccess: () => setStep(6) });
      return;
    }
    postEvent(postData as any, {
      onSuccess: ({ data: event }) => onPostSuccesfull(postData, event?.id || ''),
    });
  };

  useEffect(() => {
    if (!!event) {
      const agendas = event.agendas.map(agenda => {
        const date = moment(agenda.startDate).format('YYYY-DD-MM');
        const startTime = moment(agenda.startDate).format('HH:mm');
        const endTime = moment(agenda.endDate).format('HH:mm');

        return {
          ...agenda,
          date,
          startTime,
          endTime,
        };
      });
      console.log(agendas);
      reset({ ...event, agendas });
    }
  }, [event]);

  useEffect(() => {
    const progress = step * 15 + 25;
    setProgress(progress);
  }, [step]);

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel Content={<Step1 control={control} errors={errors} />} index={0} key={0} currentItem={step} />
          <AnimatedTabPanel
            Content={<Step1b control={control} register={register} errors={errors} />}
            index={1}
            key={1}
            currentItem={step}
          />
          <AnimatedTabPanel
            Content={<Step2 control={control} register={register} errors={errors} />}
            index={2}
            key={2}
            currentItem={step}
          />
          <AnimatedTabPanel
            Content={<Step3 control={control} register={register} errors={errors} />}
            index={3}
            key={3}
            currentItem={step}
          />
          <AnimatedTabPanel
            Content={<Step4 control={control} register={register} errors={errors} />}
            index={4}
            key={4}
            currentItem={step}
          />
          <AnimatedTabPanel Content={<Step5 control={control} register={register} />} index={5} key={5} currentItem={step} />
          <AnimatedTabPanel Content={<Step6 data={QRTokenDto} />} index={6} key={6} currentItem={step} />
        </AnimatePresence>
      </UniModalDrawerContent>
      <Fade in={!!postEventError?.response?.data?.message} unmountOnExit>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {postEventError?.response?.data?.message}
          </UniTypography>
        </Error>
      </Fade>
      <ActionsFooter
        loading={isLoading}
        step={step}
        nextStep={handleSubmit(nextStep)}
        prevStep={handleSubmit(prevStep)}
        isOffline={isOffline}
      />
    </>
  );
};

export default CreateEvent;
