import { Box } from '@material-ui/core';
import { UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Checked } from '../../assets/svg/Icons/CheckRound.svg';
import { ReactComponent as EmptyIconEducation } from '../../assets/svg/Illustrations/emptyStateHat.svg';
import { ReactComponent as EmptyIconDocuments } from '../../assets/svg/Illustrations/emptyStatePaper.svg';
import { ReactComponent as EmptyIconActivities } from '../../assets/svg/Illustrations/Tasks.svg';
import { GuidingIllustration, GuidingStateWrapper, StyledChecked } from './GuidingState.style';

interface Props {
  type?: 'education' | 'documents' | 'activities';
  rows?: number;
}

export const GuidingState = ({ type = 'education', rows }: Props) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation('emptyState');

  const Icon = useMemo(() => {
    switch (type) {
      case 'education':
        return <EmptyIconEducation style={{ width: 'inherit', height: 'inherit' }} />;
      case 'documents':
        return <EmptyIconDocuments style={{ width: 'inherit', height: 'inherit' }} />;
      case 'activities':
        return <EmptyIconActivities style={{ width: 'inherit', height: 'inherit' }} />;
    }
  }, [type]);

  return (
    <>
      <GuidingStateWrapper isMobile={isMobile}>
        <GuidingIllustration isMobile={isMobile}>{Icon}</GuidingIllustration>
        <Box display='grid' gridRowGap={isMobile ? '8px' : '16px'} maxWidth='600px'>
          <UniTypography
            variant={isMobile ? 'body2' : 'header3'}
            weight={isMobile ? 'medium' : 'semi-bold'}
            color='secondary100'
            style={!isMobile ? { display: 'flex', alignItems: 'center' } : undefined}
          >
            {t(`header.${type}`)}
          </UniTypography>
          {_.times(rows || 4, i => (
            <Box display='flex' gridColumnGap='8px'>
              <StyledChecked>
                <Checked />
              </StyledChecked>
              <UniTypography variant='subheader' weight='regular' color='typographyGray100'>
                {t(`body.${type}.${i}`)}
              </UniTypography>
            </Box>
          ))}
        </Box>
      </GuidingStateWrapper>
    </>
  );
};
