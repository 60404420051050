import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledApplicationSuccessIllustration } from '../ApplicationDrawer.style';

interface Props {
  universityName: string;
}

const Step4: React.FC<Props> = ({ universityName }) => {
  const { t } = useTranslation('applicationDrawer');

  return (
    <>
      <UniTypography variant='body1' weight='medium' color='secondary100'>
        {t('texts.congrats').replace('$university_name', universityName)}
      </UniTypography>
      <StyledApplicationSuccessIllustration />
      <UniTypography variant='body2' weight='regular' color='typographyBlack100'>
        {t('texts.willBeContacted')}
      </UniTypography>
    </>
  );
};

export default Step4;
