import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style'
import UniModalDrawerHeader from 'components/_common/uniModalDrawer/UniModalDrawerHeader'
import { useNotifications } from 'hooks';
import { Notification } from './Notification';
import React, { useState } from 'react'
import styled from 'styled-components'
import {StyledNotificationDrawer} from './AuthDropdown.style'
import { Box } from '@material-ui/core';
import { UniTypography } from 'components';


interface Props {
    handleClose : Function;
}

export const NotificationDrawerMobile =( { handleClose} : Props) => {
    const { oldNotifications, newNotifications, count, markAsRead, clearCount } = useNotifications();
    const handleClickNotification = (id: string) => {
        handleClose();
        markAsRead(id);
    };
    
    return (
        <>
            { newNotifications.length > 0 || oldNotifications.length > 0 ?
                <StyledNotificationDrawer>
                    {newNotifications.map((item, index) => (
                    <Notification isVisible={true} {...item}  key={`${index}-new`} handleClick={handleClickNotification} />
                    ))}

                    {oldNotifications.map((item, index) => (
                    <Notification isVisible={true} {...item}  key={`${index}-old`} handleClick={handleClickNotification} />
                    ))}
                </StyledNotificationDrawer>
            :
                <Box pt={3} pb={2} pl={2} pr={3}>
                    <UniTypography color='typographyGray100' variant='body1' weight='regular'>
                        {'There are no notifications yet'}
                    </UniTypography>
                </Box>
            }
        </>
    )
}