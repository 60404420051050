import React from 'react';
import YouniBounceLoader from '../youniBounceLoader/YouniBounceLoader';
import { StyledPageLoader } from './UniListLoader.style';

const UniListLoader = () => {
  return (
    <StyledPageLoader>
      <YouniBounceLoader />
    </StyledPageLoader>
  );
};
export default UniListLoader;
