import { Box, Fade } from '@material-ui/core';
import { UniTypography } from 'components';
import { Container, Error, Label, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import { GroupBase } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import creatableSelectStyles from './UniCreatableSelect.style';

interface Props {
  optional?: boolean;
  label?: string;
  backgroundColor?: any;
  topRightArea?: React.ReactNode;
  error?: string;
}

function UniCreatableSelect<OptionType, IsMulti extends boolean = false, GroupType extends GroupBase<OptionType> = GroupBase<OptionType>>({
  label,
  error,
  optional,
  backgroundColor,
  topRightArea,
  ...props
}: CreatableProps<OptionType, IsMulti, GroupType> & Props) {
  return (
    <Container backgroundColor={backgroundColor} hasLabel={!!label}>
      {!!label && (
        <Label>
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            {label}
          </UniTypography>
          {optional && (
            <UniTypography variant='footnote' color='typographyGray70'>
              Optional
            </UniTypography>
          )}
          {topRightArea && (
            <Box display='flex' alignItems='center'>
              {topRightArea}
            </Box>
          )}
        </Label>
      )}
      <CreatableSelect styles={creatableSelectStyles as any} {...props} />
      <Fade in={!!error} unmountOnExit>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {error}
          </UniTypography>
        </Error>
      </Fade>
    </Container>
  );
}

export default UniCreatableSelect;
