import styled from 'styled-components';
import { Colors } from '../../../constants';

export const StyledContainer = styled.div<{ mobile: boolean; size: 'small' | 'large' }>`
  display: ${({ mobile }) => (mobile ? 'grid' : 'flex')};
  flex-wrap: wrap;
  gap: ${({ theme, mobile, size }) => (!mobile && size === 'large' ? theme.spacing(4) : theme.spacing(2))}px;
  align-items: center;
`;

export const StyledIconWrapperr = styled.div<{ size: 'large' | 'small' }>`
  display: flex;
  justify-content: center;
  width: ${({ theme, size }) => (size === 'large' ? theme.spacing(6) : theme.spacing(5))}px;
  height: ${({ theme, size }) => (size === 'large' ? theme.spacing(6) : theme.spacing(5))}px;
  background-color: ${Colors.Secondary._10};
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  path {
    fill: ${Colors.Secondary._100};
  }
`;
