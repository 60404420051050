import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniTypography } from 'components';
import StickySideNavigation from 'components/stickySideNavigation/StickySideNavigation';
import { MediaType } from 'components/_common/UniMediaCarousel/UniMediaCarousel';
import { useBreakpoints } from 'hooks';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledDataCard, StyledDivider, StyledUniPageGeneralWrapper } from './UniversityPage.style';
import UniversityPageGeneralAbout from './UniversityPageGeneralAbout';
import UniversityPageGeneralDisciplines from './UniversityPageGeneralDisciplines';
import UniversityPageGeneralLocation from './UniversityPageGeneralLocation';
import UniversityPageGeneralMedia from './UniversityPageGeneralMedia';

interface Props {
  university: Components.Schemas.UniversityResponse;
}

export default function UniversityPageGeneral({ university }: Props): ReactElement {
  const { t } = useTranslation('universityPage');
  const [visibleSection, setVisibleSection] = useState('Media');
  const mediaRef = useRef(null);
  const aboutRef = useRef(null);
  const disciplinesRef = useRef(null);
  const locationRef = useRef(null);
  const headerRef = useRef(null);
  const [mediaArr, setMediaArr] = useState<MediaType[]>([]);
  useEffect(() => {
    const imgArr = (university?.images as Array<Components.Schemas.MediaResourceDto>).map(
      item => ({ mediaSrc: item?.readUrl, mediaType: 'PHOTO' } as MediaType),
    );
    const vidArr = (university?.videos as Array<Components.Schemas.UrlResourceDto>).map(
      item => ({ mediaSrc: item.url, mediaType: 'VIDEO' } as MediaType),
    );
    setMediaArr([...imgArr, ...vidArr]);
  }, []);
  const { isTablet } = useBreakpoints();

  //side navigation logic
  const sectionRefs = [
    {
      section: 'Media',
      ref: mediaRef,
    },
    {
      section: 'About',
      ref: aboutRef,
    },
    {
      section: 'Disciplines',
      ref: disciplinesRef,
    },
    {
      section: 'Location',
      ref: locationRef,
    },
  ];

  const sideNavigationSections = [
    { ref: mediaRef, label: t('general.navbar.media') },
    { ref: aboutRef, label: t('general.navbar.about') },
    { ref: disciplinesRef, label: t('general.navbar.disciplines') },
    { ref: locationRef, label: t('general.navbar.location') },
  ];
  const getDimensions = (el: any) => {
    const { height } = el.getBoundingClientRect();
    const offsetTop = el.offsetTop;
    const offsetBottom = offsetTop + height;
    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 0.6 * window.innerHeight;
      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2) {
            setVisibleSection(sectionRefs[sectionRefs.length - 1].section);
          } else {
            const { offsetBottom, offsetTop } = getDimensions(ele);
            return scrollPosition > offsetTop && scrollPosition < offsetBottom;
          }
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visibleSection]);
  return (
    <>
      <StyledUniPageGeneralWrapper>
        {!isTablet && (
          <StickySideNavigation navbarRef={headerRef} visibleSection={visibleSection} navbarItemsProps={sideNavigationSections} />
        )}

        <Box width='100%' overflow='hidden'>
          <StyledDataCard ref={mediaRef}>
            <UniTypography variant='header3' weight='semi-bold' color='typographyBlack100'>
              {t('general.sections.media')}
            </UniTypography>
            <StyledDivider />
            <UniversityPageGeneralMedia university={university} carouselItems={mediaArr} noContentFound={t('general.sections.no_media')} />
          </StyledDataCard>

          <StyledDataCard ref={aboutRef}>
            <UniTypography variant='header3' weight='semi-bold' color='typographyBlack100'>
              {t('general.sections.about')}
            </UniTypography>
            <StyledDivider />
            <UniversityPageGeneralAbout university={university} />
          </StyledDataCard>

          <StyledDataCard ref={disciplinesRef}>
            <UniTypography variant='header3' weight='semi-bold' color='typographyBlack100'>
              {t('general.sections.disciplines')}
            </UniTypography>
            <StyledDivider />
            <UniversityPageGeneralDisciplines university={university} />
          </StyledDataCard>

          <StyledDataCard ref={locationRef}>
            <UniTypography variant='header3' weight='semi-bold' color='typographyBlack100'>
              {t('general.sections.location')}
            </UniTypography>
            <StyledDivider />
            <UniversityPageGeneralLocation university={university} />
          </StyledDataCard>
        </Box>
      </StyledUniPageGeneralWrapper>
    </>
  );
}
