import { Box } from '@material-ui/core';
import { UniDivider, UniLink } from 'components';
import UniDropdown from 'components/_common/uniDropdown/UniDropdown';
import { Variants } from 'framer-motion';
import { useBreakpoints, useScroll } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthUser } from './AuthUser';

type AuthDropdownProps = {
  logout: () => void;
  gotoProfile: () => void;
};

const AuthDropdown: React.FC<AuthDropdownProps> = React.memo(({ logout, gotoProfile }) => {
  const { t } = useTranslation('authDropdown');
  const { isMobile } = useBreakpoints();
  const { scrollDirection, scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const variants: Variants = {
    show: { opacity: 1, y: 0, transitionDuration: '.2s' },
    hidden: { opacity: 0, y: '-100%', transitionDuration: '.2s' },
  };

  useEffect(() => {
    if (isMobile && scrollY > 100) {
      if (scrollDirection === 'down') {
        setHidden(true);
      } else {
        setHidden(false);
      }
    } else {
      setHidden(false);
    }
  }, [scrollDirection, scrollY]);

  return (
    <Box width='100%' display='flex' justifyContent='flex-end'>
      <UniDropdown handleOpen={() => setOpenMenu(true)} handleClose={() => setOpenMenu(false)} open={openMenu} ActionButton={<AuthUser />}>
        <UniLink
          size='main'
          label={t('to_profile')}
          onClick={() => {
            gotoProfile();
            setOpenMenu(false);
          }}
        ></UniLink>
        <UniDivider />
        <UniLink size='main' label={t('log_out')} onClick={logout} alert></UniLink>
      </UniDropdown>
    </Box>
  );
});

export default AuthDropdown;
