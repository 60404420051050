import { Components } from 'client/UniClient';
import { UniStatus } from 'components';
import React, { ReactElement } from 'react';

interface Props {
  program?: Components.Schemas.ProgramResponse;
  application?: Components.Schemas.ApplicationDto;
  candidate?: Components.Schemas.UserDto;
  fileIds?: Components.Schemas.AttachRequiredDocumentRequest[];
}

export default function ProgramsListItemAdminActions({ program, application, candidate }: Props): ReactElement {
  // const { openDrawer } = useDrawers();

  if (!application?.status) {
    return <UniStatus>Not applied</UniStatus>;
  }
  // else if (application) {
  //   return (
  //     <>
  //       <UniStatus application={application} />
  //       <UniButton
  //         fullWidth={false}
  //         color='primary'
  //         onClick={e => {
  //           openDrawer('reply-to-student', { candidate: candidate as Components.Schemas.UserResponse, application });
  //         }}
  //         startIcon={
  //           <UniIconWrapper color='white100'>
  //             <CheckIcon />
  //           </UniIconWrapper>
  //         }
  //       >
  //         Reply
  //       </UniButton>
  //     </>
  //   );
  // }
  return <UniStatus application={application} />;
}
