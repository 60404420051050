import { AxiosError } from 'axios';
import { UniversitiesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/UniversitiesFilters';
import { QueryConfig, useInfiniteQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Params extends Partial<UniversitiesFiltersQueries> {
  filters?: UniversitiesFiltersQueries;
  sortType?: number;
  pageSize?: number;
}

export default function useSearchUniversities(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  return useInfiniteQuery(
    [CacheKeys.Search.GroupedPrograms, params],
    async (_key: string, { filters, pageSize = ApiKeys.PageSize.Universities, sortType = 1 }: Params, page: number = 1) => {
      const { data } = await client!.SearchApi_searchUniversities({
        ...filters,
        page,
        pageSize,
      });

      return data;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Universities || lastPage.page * ApiKeys.PageSize.Universities === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
