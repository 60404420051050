import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { PersonalForm, UniButton } from 'components';
import { PersonalFormInputs } from 'components/_forms/PersonalForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledUniCardFooter } from '../StudentOnboard.style';

export type Step2FormData = PersonalFormInputs;

type Props = {
  defaultValues: Partial<Step2FormData>;
  nextStep: (value: Step2FormData) => void;
};

const Step2: React.FC<Props> = ({ defaultValues, nextStep }) => {
  const { register, control, errors, handleSubmit, watch } = useForm<Step2FormData>({ defaultValues });
  const { t: tButtons } = useTranslation('buttons');

  useEffect(() => {
    localStorage.setItem('onboardFormDataStep2', JSON.stringify(watch()));
  }, [watch()]);

  return (
    <>
      <Box mb={4}>
        <PersonalForm control={control} register={register} errors={errors} />
      </Box>
      <StyledUniCardFooter
        variants='M'
        type='labeled'
        PrimaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            size={'large'}
            color='primary'
            fullWidth={false}
            onClick={handleSubmit(nextStep)}
          >
            {tButtons('next_step')}
          </UniButton>
        }
      />
    </>
  );
};

export default Step2;
