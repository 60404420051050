import { Box } from '@material-ui/core';
import UniCreatableSelect from 'components/_common/uniCreatableSelect/UniCreatableSelect';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import UniCheckbox from 'components/_inputs/uniCheckbox/UniCheckbox';
import { englishExams } from 'constants/options/englishExams';
import { universityExams } from 'constants/options/universityExams';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'types';

export interface AccomplishmentsFormInputs {
  englishExams: OptionType[];
  universityExams: OptionType[];
}

interface Props {
  control: any;
  errors: any;
}

const AccomplishmentsForm: React.FC<Props> = ({ control, errors }) => {
  const [hideEnglishExams, setHideEnglishExams] = useState(false);
  const [hideUniversityExams, setHideUniversityExams] = useState(false);
  const { t } = useTranslation('forms');

  return (
    <>
      <Controller
        control={control}
        name='englishExams'
        render={({ value, onChange }) => (
          <>
            <UniCreatableSelect
              label='Have you taken any English exams?'
              value={value}
              onChange={value => onChange(value)}
              options={englishExams}
              isDisabled={hideEnglishExams}
              isMulti
            />
            <Box display='flex' alignItems='center' mt={-2.5} mb={1}>
              <UniCheckbox
                checked={hideEnglishExams}
                onChange={({ target }) => {
                  if (target.checked) onChange(undefined);
                  setHideEnglishExams(target.checked);
                }}
              />
              <UniTypography color='typographyBlack100'>Not yet</UniTypography>
            </Box>
          </>
        )}
      />

      <Controller
        control={control}
        name='universityExams'
        render={({ value, onChange }) => (
          <>
            <UniCreatableSelect
              label='Have you taken any other exams?'
              value={value}
              onChange={value => onChange(value)}
              options={universityExams}
              isDisabled={hideUniversityExams}
              isMulti
            />
            <Box display='flex' alignItems='center' mt={-2.5} mb={1}>
              <UniCheckbox
                checked={hideUniversityExams}
                onChange={({ target }) => {
                  if (target.checked) onChange(undefined);
                  setHideUniversityExams(target.checked);
                }}
              />
              <UniTypography color='typographyBlack100'>Not yet</UniTypography>
            </Box>
          </>
        )}
      />
    </>
  );
};

export default AccomplishmentsForm;
