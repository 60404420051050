import { AxiosError } from 'axios';
import { Components, Paths } from 'client/UniClient';
import { InfiniteQueryConfig, useInfiniteQuery } from 'react-query';
import { useClient } from 'store';
import { ApiKeys, CacheKeys } from '../../constants';

type Params = Omit<
  Paths.AchievementApiSearchStudentAchievements.PathParameters & Paths.AchievementApiSearchStudentAchievements.QueryParameters,
  'page'
>;
const achievementsStatuses = ['IN_REVIEW', 'VALIDATED'];
const useAchievements = (params: Params, config?: InfiniteQueryConfig<Components.Schemas.AchievementResultPageDto>) => {
  const { client } = useClient();

  return useInfiniteQuery(
    [CacheKeys.Achievement.Default, params],
    async (
      _key: string,
      { pageSize = ApiKeys.PageSize.ActivitiesCards, creatorId, studentId, agentId, type, category, status: queryStatus }: Params,
      page: number = 1,
    ) => {
      const status =
        queryStatus && [queryStatus].flat(1)?.some(item => achievementsStatuses.includes(item))
          ? [queryStatus].flat(1)?.filter(item => achievementsStatuses.includes(item))
          : undefined;

      if (!status && !!queryStatus) {
        return {
          page: 1,
          pageSize: 8,
          results: [],
          total: 0,
        };
      }

      const { data } = await client!.AchievementApi_searchStudentAchievements({
        pageSize,
        creatorId,
        studentId,
        agentId,
        type,
        category,
        status,
        page,
      });

      return data;
    },
    {
      ...config,
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (
          lastPage.results.length < ApiKeys.PageSize.ActivitiesCards ||
          lastPage.page * ApiKeys.PageSize.ActivitiesCards === lastPage.total
        ) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
};

export default useAchievements;
