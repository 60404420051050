import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniAvatar, UniButton, UniCardWrapper, UniChip, UniIconWrapper, UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import React, { ReactEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { ReactComponent as RightArrow } from '../../assets/svg/Icons/Arrow/ArrowRight.svg';
import { BrowserRoutes } from '../../constants';
import { StyledIconWrapper } from './UniversityCard.style';

interface Props {
  university: Components.Schemas.UniversityResponse;
  seeMore?: 'general' | 'programs' | 'applications';
  fit?: boolean;
  metricsArr: { Icon?: React.ReactElement; label: string; value: string }[];
}

const UniversityCard = ({ university, fit, seeMore = 'programs', metricsArr }: Props) => {
  const { t } = useTranslation('universityPage');
  const { isMobile } = useBreakpoints();
  const { role } = useAuth(state => state.profile);
  const { isStudent, isAgency, isMaster, isAdmin } = getMetaRole(role!);
  const history = useHistory();

  const goToPage = (where: 'general' | 'programs' | 'applications') => {
    if (where === 'general') {
      if (isAgency) history.push(BrowserRoutes.Agency.UniversityProfile.replace(':id', university.id as string));
      else if (isStudent) history.push(BrowserRoutes.Student.UniversityProfile.replace(':id', university.id as string));
      else if (isMaster || isAdmin) history.push(BrowserRoutes.Admin.University.Applications.replace(':id', university.id as string));
      else history.push(BrowserRoutes.Agent.UniversityProfile.replace(':id', university.id as string));
    } else if (where === 'applications') {
      if (isMaster || isAdmin) history.push(BrowserRoutes.Admin.University.Applications.replace(':id', university?.id as string));
    } else {
      if (isAgency) history.push(BrowserRoutes.Agency.University.Program.replace(':universityId', university?.id as string));
      else if (isStudent) history.push(BrowserRoutes.Student.University.Program.replace(':universityId', university?.id as string));
      else if (isMaster || isAdmin) history.push(BrowserRoutes.Admin.University.Programmes.replace(':id', university?.id as string));
      else history.push(BrowserRoutes.Agent.University.Program.replace(':universityId', university?.id as string));
    }
  };

  const uniTagClickHandler: ReactEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    console.log('this clicks');
  };

  return (
    <UniCardWrapper size='L' fit={fit} border hoverable onClick={() => goToPage('general')}>
      <Box display='grid' pt={5} pb={7} px={isMobile ? 2 : 3}>
        <Box justifyContent='center' display='grid' gridRowGap={16} mb={isMobile ? 3 : 4}>
          <Box display='flex' justifyContent='center'>
            <UniAvatar online={false} size='L' variant='rounded' src={university?.logoFile?.location} label={university?.name} />
          </Box>
          <UniTypography
            weight='semi-bold'
            variant='body2'
            color='secondary100'
            style={{
              height: '48px',
              textAlign: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
            }}
          >
            {university.name}
          </UniTypography>
        </Box>
        {metricsArr?.map(item => (
          <>
            <Box display='flex' mb={2}>
              {!isMobile && <StyledIconWrapper size='small'>{item?.Icon}</StyledIconWrapper>}
              <Box display='grid' ml={1}>
                <UniTypography color='typographyGray100' variant='footnote' weight='regular'>
                  {item?.label}
                </UniTypography>
                <UniTypography
                  color='typographyBlack100'
                  weight='medium'
                  variant='subheader'
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  {item?.value}
                </UniTypography>
              </Box>
            </Box>
          </>
        ))}

        <Box display='flex' flexWrap='wrap' gridRowGap='12px' gridColumnGap='6px'>
          {university &&
            university.tags &&
            university.tags.map(tag => (
              <div onClick={uniTagClickHandler} key={tag.id}>
                <UniChip label={tag.name} />
              </div>
            ))}
        </Box>

        <div style={{ position: 'absolute', bottom: '16px', left: '24px', right: '24px' }}>
          <UniButton
            variant='text'
            size='small'
            endIcon={
              !isStudent && (
                <UniIconWrapper size='main' color='primary100'>
                  <RightArrow />
                </UniIconWrapper>
              )
            }
            onClick={() => goToPage(seeMore)}
          >
            {isMaster ? t('buttons.see_applications') : `See ${university?.programCount ? university.programCount - 1 : ''} more programs`}
          </UniButton>
        </div>
      </Box>
    </UniCardWrapper>
  );
};

export default UniversityCard;
