import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UniSortButton, UniTypography } from '../../components';
import UniCheckbox from '../_inputs/uniCheckbox/UniCheckbox';
import { AgentsQueryParams, OnAgentsSort } from './AgentsListForAdmin';

const StyledAgentsListHeader = styled.div`
  display: grid;
  grid-template-columns: 0.5fr repeat(7, 2fr) 2fr;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;

type HeaderProps = {
  params?: AgentsQueryParams;
  onSort?: OnAgentsSort;

  selectAllAgents?: Function;
  allSelected?: boolean;
};

const AgentsListHeaderForAdmin: React.FC<HeaderProps> = ({ params, onSort, selectAllAgents, allSelected }) => {
  const { t } = useTranslation('superAdminAgency');
  const order = params?._order ? params?._order : 'ASC';
  const byUsername = params?._sort === 'username';
  // const byNationality = params._sort === "nationality";
  // const byStudy = params._sort === "levelOfStudy";

  return (
    <StyledAgentsListHeader>
      <UniCheckbox
        checked={allSelected}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          e.stopPropagation();
          selectAllAgents && selectAllAgents(e.target.checked);
        }}
      />
      {onSort ? (
        <UniSortButton onClick={() => onSort('username')} active={byUsername} order={order}>
          {t('list_header.name')}
        </UniSortButton>
      ) : (
        <UniTypography weight='medium' variant='subheader'>
          {t('list_header.name')}
        </UniTypography>
      )}
      <UniTypography weight='medium' variant='subheader'>
        {t('list_header.role')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        {t('list_header.candidates')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        {t('list_header.unprocessed')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        {t('list_header.applicants')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        {t('list_header.enrolled')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        {t('list_header.phone_number')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        {t('list_header.actions')}
      </UniTypography>
    </StyledAgentsListHeader>
  );
};

export default AgentsListHeaderForAdmin;
