import { Box } from '@material-ui/core';
import useAchievementPleas from 'api/achievements/useAchievementPleas';
import useTodoPleas from 'api/todos/useTodoPleas';
import { Components } from 'client/UniClient';
import { UniBounceLoader, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';

interface Props {
  achievement?: Components.Schemas.AchievementDto;
  todo?: Components.Schemas.ToDoDto;
}

const RequiredUpdatesPreview: React.FC<Props> = ({ achievement, todo }) => {
  const { t: tButtons } = useTranslation('buttons');
  const { firstName, lastName } = achievement?.agent || todo?.agent!;
  const agentName = `${firstName} ${lastName}`;
  const { data: achievementPleas, isLoading: isLoadingAchievementPleas } = useAchievementPleas(
    { achievementId: achievement?.id || '' },
    { enabled: !!achievement },
  );
  const { data: todoPleas, isLoading: isLoadingTodoPleas } = useTodoPleas({ toDoId: todo?.id || '' }, { enabled: !!todo });
  const isLoading = isLoadingAchievementPleas || isLoadingTodoPleas;
  const data = achievementPleas || todoPleas;
  const { closeDrawer } = useDrawers();

  return (
    <>
      <UniModalDrawerContent>
        <UniTypography variant='body2' weight='medium'>
          Messages from your consultant:
        </UniTypography>
        <Box mt={3} />
        {isLoading && <UniBounceLoader />}
        {data?.map(({ note }, index) => (
          <UniTypography variant='subheader' weight='regular' key={index}>
            {note}
          </UniTypography>
        ))}
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          onClick: closeDrawer,
          label: tButtons('got_it'),
        }}
      />
    </>
  );
};

export default RequiredUpdatesPreview;
