import { Box } from '@material-ui/core';
import { useFile, useUploadProfileImage } from 'api';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useSnackbar } from 'store';
import { UniAvatar, UniTypography } from '..';
import { ReactComponent as Illustration } from '../../assets/svg/Illustrations/candidateHeaderIllustration.svg';
import { Components } from '../../client/UniClient';
import { getWithCurrencySymbol } from '../../utils/getWithCurrencySymbol';
import { AgentActions, AgentHeader, AgentIdentity, AgentStatus } from './AgentHeader.style';
import AgentHeaderMeta from './AgentHeaderMeta';

interface Props {
  isOwner: boolean;
  agent: Partial<Components.Schemas.UserDto>;
  inputRef?: React.Ref<any>;
  BackButton: React.ReactNode;
  TopActions?: React.ReactNode;
  MiddleActions?: React.ReactNode;
  title?: string;
  changeNavbar?: boolean;
  isEditing: boolean;
}
export default function AgentProfileHeader({
  isOwner,
  agent,
  inputRef,
  BackButton,
  TopActions,
  MiddleActions,
  title,
  changeNavbar,
  isEditing,
}: Props): ReactElement {
  const { profile, setProfile } = useAuth();
  const { t } = useTranslation('agencyProfile');
  const fee = agent?.agentProfile?.fee;
  const feeText = fee ? getWithCurrencySymbol(fee.amount, fee.currencyIso) : '_';
  const profileImageSrc = useFile({ id: agent.profileImageFile?.id || '' }, { enabled: !agent.profileImageFile });
  const [uploadProfileImage] = useUploadProfileImage();
  const { openSnackbar } = useSnackbar();

  const uploadHandler = (file?: File) => {
    const profileImage = new FormData();
    profileImage.append('file', file!);
    profileImage.append('documentType', 'PROFILE_IMAGE');
    uploadProfileImage(profileImage, {
      onSuccess: image => {
        let updatedProfile = { ...profile };
        updatedProfile.profileImageFile = image.data;
        setProfile(updatedProfile);
        openSnackbar('profile-image-uploaded');
      },
    });
  };

  return (
    <AgentHeader changeNavbar={changeNavbar}>
      <Box mt={3} display='flex' justifyContent='space-between'>
        <Box display='flex' justifyContent='space-between' alignItems='flex-start' flexDirection='column'>
          {BackButton}
          <UniTypography variant='header3' weight='medium'>
            {title}
          </UniTypography>
          <Box display='flex' alignItems='center'>
            {isEditing ? (
              <UniAvatar
                size='M'
                label={`${agent?.firstName} ${agent?.lastName}`}
                src={profileImageSrc.data?.url}
                onUpload={uploadHandler}
              />
            ) : (
              <UniAvatar size='M' label={`${agent?.firstName} ${agent?.lastName}`} src={profileImageSrc.data?.url} />
            )}
            <Box ml={2}>
              <UniTypography variant='header3' weight='semi-bold' color='primary100'>
                {`${agent?.firstName} ${agent?.lastName}`}
              </UniTypography>
              <AgentStatus>
                {/* <UniStatus candidate={agent} /> */}
                <UniTypography variant='footnote' color='typographyGray70'>
                  {/* {"0"} */}
                </UniTypography>
              </AgentStatus>
            </Box>
          </Box>
        </Box>
        <Box style={{ width: '40%' }}>
          <Box style={{ marginLeft: '80%' }}>
            <Illustration />
          </Box>
          {agent?.agentProfile && <AgentHeaderMeta {...agent.agentProfile}></AgentHeaderMeta>}
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='flex-end' flexDirection='column'>
          <Box display='flex' maxHeight={12} mt={2}>
            <UniTypography variant='footnote' color='typographyGray70'>
              {t('labels.header.date_added')} &nbsp;
            </UniTypography>
            <UniTypography weight='semi-bold' variant='footnote' color='typographyGray70'>
              {agent?.createdAt || '12.10.2020'}
            </UniTypography>
          </Box>
          <AgentActions>{TopActions}</AgentActions>
          <AgentIdentity>
            <AgentActions>{MiddleActions}</AgentActions>
          </AgentIdentity>
        </Box>
      </Box>
    </AgentHeader>
  );
}
