import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useAuth, useClient } from '../../store';

type Payload = {
  password: string;
  email: string;
  newsletter: boolean;
};

const useSignup = () => {
  const { client } = useClient();
  const {
    profile: { role },
  } = useAuth();

  return useMutation(
    async (payload: Payload) => {
      return client!.AuthApi_signUp(undefined, { ...payload, role: 'STUDENT' });
    },
    {
      onSuccess: async () => {},
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
           const { message } = error?.response?.data || {};
           const generalMessage =
             "Something went wrong, please try again in a little while";
           Snackbar(message || generalMessage, {
             variant: "error"
           }); 
           */
      },
    },
  );
};
export default useSignup;
