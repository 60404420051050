import React, { ReactElement } from 'react';
import { ReactComponent as GraduationHatIcon } from '../../../../assets/svg/Icons/GraduationHat.svg';
import { ReactComponent as MessagesIcon } from '../../../../assets/svg/Icons/Messages.svg';
import { ReactComponent as ProfileIcon } from '../../../../assets/svg/Icons/Profile.svg';
import { UniIconWrapper, UniTypography } from '../../../../components';
import { CustomTab, CustomTabs } from '../Onboard.style';

export default function AgencyOnboardTabs(props: {
  maxTabIndex: number;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
}): ReactElement {
  const getColor = (index: number) => {
    return props.currentIndex === index ? 'primary100' : props.maxTabIndex >= index ? 'secondary100' : 'typographyGray70';
  };

  const handleClick = (index: number) => {
    if (props.maxTabIndex >= index) {
      props.setCurrentIndex(index);
    }
  };

  return (
    <CustomTabs>
      <CustomTab onClick={() => handleClick(1)}>
        <UniIconWrapper color={getColor(1)} withBackground>
          <ProfileIcon />
        </UniIconWrapper>
        <UniTypography variant='header3' color={getColor(1)} weight='semi-bold'>
          Partner information
        </UniTypography>
      </CustomTab>
      <CustomTab onClick={() => handleClick(2)}>
        <UniIconWrapper color={getColor(2)} withBackground>
          <MessagesIcon />
        </UniIconWrapper>
        <UniTypography variant='header3' color={getColor(2)} weight='semi-bold'>
          Personal information
        </UniTypography>
      </CustomTab>
      <CustomTab onClick={() => handleClick(3)}>
        <UniIconWrapper color={getColor(3)} withBackground>
          <GraduationHatIcon />
        </UniIconWrapper>
        <UniTypography variant='header3' color={getColor(3)} weight='semi-bold'>
          Finish
        </UniTypography>
      </CustomTab>
    </CustomTabs>
  );
}
