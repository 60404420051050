import { useProgram } from 'api';
import { ProgramsListItemAgentActions, UniHeader, UniIconWrapper, UniPage, UniPageLoader } from 'components';
import { UniHerosection } from 'components/_common/uniHerosection/UniHerosection';
import ProgramGeneralInfo from 'components/_templates/programGeneralInfo/ProgramGeneralInfo';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useHeader } from 'store';
import { ReactComponent as HourglassIcon } from '../../../assets/svg/Icons/Hourglass.svg';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location.svg';
import { BrowserRoutes } from '../../../constants';

const AgencyProgramPage: React.FC = () => {
  const { t } = useTranslation('programPage');
  const { programId } = useParams<{ programId: string }>();
  const { url } = useRouteMatch();
  const { goBack } = useHistory();
  const { setHeader, clearHeader } = useHeader();
  const generalPath = BrowserRoutes.Student.Program.General.replace(':programId', programId);
  const statusPath = BrowserRoutes.Student.Program.Status.replace(':programId', programId);
  const currentRoute = url === generalPath ? 0 : 1;

  const {
    data: program,
    status: programStatus,
    error: programsError,
  } = useProgram({
    id: programId,
  });

  useEffect(() => {
    setHeader({
      primaryButton: { onClick: () => goBack() },
    });
    return clearHeader;
  }, []);

  if (!program && programStatus === 'loading') {
    return <UniPageLoader />;
  }

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='base'
          title={program?.name || ''}
          universityName={program?.universityName}
          universityId={program?.universityId}
          Actions={<ProgramsListItemAgentActions program={program!} />}
          metrics={[
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <LocationIcon />
                </UniIconWrapper>
              ),
              label: t('header.location'),
              value: `${program?.address.city || '-'}, ${program?.address.country || '-'}`,
            },
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <HourglassIcon />
                </UniIconWrapper>
              ),
              label: t('header.lengthOfStudy'),
              value: program?.lengthOfStudy ? `${program?.lengthOfStudy?.value} ${program?.lengthOfStudy?.unit}` : 'Unspecified',
            },
          ]}
        />
      </UniHeader>
      <UniPage>
        <ProgramGeneralInfo program={program} />
      </UniPage>
    </>
  );
};

export default AgencyProgramPage;
