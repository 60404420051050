import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ContactImg from 'assets/css-check/contact.png';
import { BrowserRoutes } from '../../../../constants';
import './contact.css';

const Contact = () => {
  const history = useHistory();
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);

  const [parent1IncomeFile, setParent1IncomeFile] = useState('');
  const [parent2IncomeFile, setParent2IncomeFile] = useState('');
  const [clothesSpend, setClothesSpend] = useState('');
  const [foodSpend, setFoodSpend] = useState('');
  const [utilitiesSpend, setUtilitiesSpend] = useState('');
  const [mortgageSpend, setMortgageSpend] = useState('');
  const [transportSpend, setTransportSpend] = useState('');
  const [healthSpend, setHealthSpend] = useState('');
  const [educationSpend, setEducationSpend] = useState('');
  const [otherSpend, setOtherSpend] = useState('');
  const [valueOfProperties, setValueOfProperties] = useState('');
  const [numberOfProperties, setNumberOfProperties] = useState('');
  const [amountOwedOnProperties, setAmountOwedOnProperties] = useState('');
  const [credits, setCredits] = useState('');

  const isEmpty = !parent1IncomeFile || !parent2IncomeFile;

  const handleSubmit = (e) => {
    setLoader(true);
    setError(null);
    e.preventDefault();

    const formData = new FormData();
    formData.append('parent_1_income_file', parent1IncomeFile);
    formData.append('parent_2_income_file', parent2IncomeFile);
    if (clothesSpend) formData.append('clothes', clothesSpend);
    if (foodSpend) formData.append('food', foodSpend);
    if (utilitiesSpend) formData.append('utilities', utilitiesSpend);
    if (mortgageSpend) formData.append('mortgage', mortgageSpend);
    if (transportSpend) formData.append('transport', transportSpend);
    if (healthSpend) formData.append('health', healthSpend);
    if (educationSpend) formData.append('education', educationSpend);
    if (otherSpend) formData.append('other', otherSpend);
    if (valueOfProperties) formData.append('value_of_properties', valueOfProperties);
    if (numberOfProperties) formData.append('number_of_properties', numberOfProperties);
    if (amountOwedOnProperties) formData.append('amount_owed_on_properties', amountOwedOnProperties);
    if (credits) formData.append('credits', credits);

    axios
      .post(
        `https://api.admissionminds.com/income-bracket/`,
        formData,
        {
          headers: {
            'secret-key': 'U9B6DC3UuNutj5YXM4vfrTWA',
          },
        },
      )
      .then((response) => {
        // Handle the response from the server
        setLoader(false);
        history.push(BrowserRoutes.Default.CssCheck.ThankYou, response.data);
      })
      .catch((error) => {
        // Handle the error
        setError(error?.response?.data?.detail);
        setLoader(false);

        console.error(error);
      });
  };
  return (
    <>

      {/* -----------------------------contact form----------------------------- */}
      <div className="contact_container">
        <div className="image" style={{ display: 'inline-block '}}>
          <img src={ContactImg} alt="contact"/>
        </div>
        <div className="contact_form" style={{ display: 'inline-block '}}>
          <form>
            <div className="inputfeilds">
              <label>Income Parent 1 *</label>
              <input
                type="file"
                onChange={(e) => setParent1IncomeFile(e.target.files[0])}
              />
            </div>
            <div className="inputfeilds">
              <label>Income Parent 2 *</label>
              <input
                type="file"
                onChange={(e) => setParent2IncomeFile(e.target.files[0])}
              />
            </div>
            <div className="inputfeilds">
              <label>How much do you spend on clothes monthly?</label>
              <input
                type="text"
                value={clothesSpend}
                onChange={(e) => setClothesSpend(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>How much do you spend on food monthly?</label>
              <input
                type="text"
                value={foodSpend}
                onChange={(e) => setFoodSpend(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>How much do you spend on utilities monthly?</label>
              <input
                type="text"
                value={utilitiesSpend}
                onChange={(e) => setUtilitiesSpend(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>How much do you spend on mortgage or rental monthly?</label>
              <input
                type="text"
                value={mortgageSpend}
                onChange={(e) => setMortgageSpend(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>How much do you spend on transport monthly?</label>
              <input
                type="text"
                value={transportSpend}
                onChange={(e) => setTransportSpend(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>How much do you spend on health monthly?</label>
              <input
                type="text"
                value={healthSpend}
                onChange={(e) => setHealthSpend(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>How much do you spend on education monthly?</label>
              <input
                type="text"
                value={educationSpend}
                onChange={(e) => setEducationSpend(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>Other costs?</label>
              <input
                type="text"
                value={otherSpend}
                onChange={(e) => setOtherSpend(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>The value of your properties</label>
              <input
                type="text"
                value={valueOfProperties}
                onChange={(e) => setValueOfProperties(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>Number of properties</label>
              <input
                type="text"
                value={numberOfProperties}
                onChange={(e) => setNumberOfProperties(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>Amount owed on the properties</label>
              <input
                type="text"
                value={amountOwedOnProperties}
                onChange={(e) => setAmountOwedOnProperties(e.target.value)}
              />
            </div>
            <div className="inputfeilds">
              <label>Any credits and their total value</label>
              <input
                type="text"
                value={credits}
                onChange={(e) => setCredits(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className={`btn ${isEmpty ? 'btn_secondary' : 'btn_primary'}`}
              disabled={isEmpty || loader}
              onClick={handleSubmit}
            >
              {loader ? 'Loading....' : 'Submit'}
            </button>
          </form>
          <p className="error_message">{error}</p>
        </div>
      </div>
    </>
  );
};

export default Contact;
