import React from 'react';
import { UniIconWrapper, UniTypography } from '../index';
import {
  StyledAchievement,
  StyledDivider,
  StyledTutorCard,
  StyledTutorImage,
  StyledTutorImageWrapper,
} from './UniTutorCard.style';

interface Props {
  imagePath: string;
  name: string;
  title: string;
  achievements: {
    Icon: React.FC;
    subtitle: string;
    title: string;
  }[];
}

export const UniTutorCard: React.FC<Props> = ({ imagePath, name, title, achievements }) => {
  return (
    <StyledTutorCard size='L' border>
      <StyledTutorImageWrapper>
        <StyledTutorImage src={imagePath} alt={name} />
      </StyledTutorImageWrapper>
      <UniTypography variant='header3' weight='bold' color='secondary100'>
        {name}
      </UniTypography>
      <UniTypography variant='body2' color='secondary100'>
        {title}
      </UniTypography>
      <StyledDivider />
      {achievements.map(({ Icon, subtitle, title }, index) => (
        <StyledAchievement key={index}>
          <UniIconWrapper size='large' withBackground>
            {<Icon />}
          </UniIconWrapper>
          <div>
            <UniTypography variant='body1' weight='bold' color='secondary100'>
              {title}
            </UniTypography>
            <UniTypography variant='body2' color='typographyGray100'>
              {subtitle}
            </UniTypography>
          </div>
        </StyledAchievement>
      ))}
    </StyledTutorCard>
  );
};

export default UniTutorCard;
