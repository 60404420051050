import { TRole } from 'constants/role';
import { BrowserRoutes } from '../constants';

export const getUserHomeScreen = (role?: TRole) => {
  switch (role) {
    case 'ADMIN':
      return BrowserRoutes.Admin.CardList.Programs;
    case 'MASTER':
      return BrowserRoutes.Admin.Default;
    case 'AGENT':
      return BrowserRoutes.Agent.Default;
    case 'AGENCY':
      return BrowserRoutes.Agency.Default;
    case 'AGENCY_HOLDING':
      return BrowserRoutes.Agency.Default;
    case 'STUDENT':
      return BrowserRoutes.Student.Default;
    case 'UNIVERSITY':
      return BrowserRoutes.University.Default;
    case 'HIGH_SCHOOL':
      return BrowserRoutes.HighSchool.Default;
    default:
      return BrowserRoutes.Default.Homepage;
  }
};

export const getUserToProfile = (role?: TRole) => {
  switch (role) {
    case 'ADMIN':
      return BrowserRoutes.Admin.Profile;
    case 'AGENT':
      return BrowserRoutes.Agent.Profile;
    case 'AGENCY':
      return BrowserRoutes.Agency.Profile;
    case 'AGENCY_HOLDING':
      return BrowserRoutes.Agency.Profile;
    case 'STUDENT':
      return BrowserRoutes.Student.Profile.Default.replace(':tab', 'info');
    case 'UNIVERSITY':
      return BrowserRoutes.University.Profile.Default;
    default:
      return BrowserRoutes.Default.Homepage;
  }
};
