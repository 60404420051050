import { UniCard, UniCardWrapper } from 'components';
import styled from 'styled-components';
import { theme } from 'theme';
import { Colors, Shadows, Shape } from '../../constants';

export const ApplicationHistoryItemWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-column-gap: ${theme.spacing(2)}px;
  margin-bottom: ${theme.spacing(3)}px;
  align-items: flex-start;

  ${({ theme }) => `${theme.breakpoints.down('sm')}{\
    grid-template-columns: 1fr;
    grid-row-gap: ${theme.spacing(1)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }`}
`;

export const StyledDecisionsDateWrapper = styled(UniCardWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${Colors.Typography.Gray_10};
  /* box-shadow: ${Shadows.shadow1}; */
  padding: ${({ theme }) => theme.spacing(1, 2)};
  border-radius: ${Shape.BorderRadius.md / 2}px;

  ${({ theme }) => `${theme.breakpoints.down('sm')}{\
    flex-direction: row;
    max-width: 112px;
    height:26px;
    padding: ${theme.spacing(0)};
  }`}
`;

export const StyledUniCard = styled(UniCard)`
  margin-bottom: ${theme.spacing(3)}px;
  ${({ theme }) => `${theme.breakpoints.down('sm')}{
  margin-bottom:${theme.spacing(2)}px;
}`}
`;

export const StyledOverflowText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
`;
