import { AxiosError } from 'axios';
import { queryCache, useMutation } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Payload {
  studentId: string;
  studentNoteId: string;
  content: string;
}

const usePatchNotes = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.StudentApi_updateStudentNote(payload, payload);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(CacheKeys.Student.Notes);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default usePatchNotes;
