import React, { useState } from 'react';
import { useLocation } from 'react-router';
import usePostFeedback from 'api/feedback/usePostFeedback';
import { UniButton, UniTextField } from '../index';

export const FeedbackForm: React.FC = () => {
  const [content, setContent] = useState('');
  const { pathname } = useLocation();
  const [postFeedback] = usePostFeedback();

  function onSubmit() {
    const body = {
      content,
      referralPage: pathname,
    };
    console.log(body);
    postFeedback(body, {
      onSuccess() {
        setContent('');
      }
    }).then();
  }

  return (
    <div style={{ maxWidth: '1280px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
      <UniTextField
        label='Send feedback'
        fullWidth
        multiline
        placeholder='Enter your feedback here...'
        value={content}
        onChange={event => setContent(event.target.value)}
      />
      <UniButton onClick={onSubmit} style={{ width: '160px', margin: '20px 0 30px' }}>Submit feedback</UniButton>
    </div>
  );
};

export default FeedbackForm;
