import { InfoOutlined } from '@material-ui/icons';
import { ReactComponent as CalendarIcon } from '../../assets/svg/Icons/Calendar.svg';
import { ReactComponent as HighSchoolIcon } from '../../assets/svg/Icons/GraduationHat.svg';
import { ReactComponent as StudentIcon } from '../../assets/svg/Icons/Nav/Admin.svg';
import { ReactComponent as ApplicationsIcon } from '../../assets/svg/Icons/Nav/Applications.svg';
import { ReactComponent as ProfileIcon } from '../../assets/svg/Icons/Nav/Candidates.svg';
import { ReactComponent as HomeIcon } from '../../assets/svg/Icons/Nav/Home.svg';
import { ReactComponent as ProgramIcon } from '../../assets/svg/Icons/Nav/Programs.svg';
import { ReactComponent as SchoolsIcon } from '../../assets/svg/Icons/Nav/Schools.svg';
import { ReactComponent as TeamIcon } from '../../assets/svg/Icons/Nav/Team.svg';
import { ReactComponent as ServicesIcon } from '../../assets/svg/Icons/Services2.svg';
import { ReactComponent as OngoingIcon } from '../../assets/svg/Icons/Ongoing.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/Icons/Search.svg';
import { ReactComponent as PencilRulerIcon } from '../../assets/svg/Icons/Pencilruler.svg';
import { BrowserRoutes } from '../../constants';

const { Agency, Agent, Student, Admin, University, Default, HighSchool } = BrowserRoutes;

export const links = [
  {
    path: Agency.Default,
    name: 'Home',
    isMobile: true,
    icon: <HomeIcon />,
  },
];

export const agencyLinks = [
  {
    defaultPath: Agency.Default,
    path: Agency.Default,
    name: 'Home',
    isMobile: true,
    icon: <HomeIcon />,
  },
  {
    defaultPath: Agency.Team,
    path: Agency.Team,
    name: 'Agents',
    isMobile: true,
    icon: <TeamIcon />,
  },
  {
    defaultPath: Agency.Students.Default,
    path: Agency.Students.All,
    name: 'Candidates',
    isMobile: true,
    icon: <StudentIcon />,
  },
  {
    defaultPath: Agency.Programs,
    path: Agency.Programs,
    name: 'Programs',
    isMobile: true,
    icon: <ProgramIcon />,
  },
  {
    defaultPath: Agency.Universities,
    path: Agency.Universities,
    name: 'Universities',
    isMobile: true,
    icon: <SchoolsIcon />,
  },
  {
    defaultPath: Agency.Profile,
    path: Agency.Profile,
    name: 'Profile',
    icon: <ProfileIcon />,
  },
  {
    defaultPath: Agent.Services.Default,
    path: Agent.Services.Default.replace(':view', 'our-services'),
    isMobile: true,
    name: 'Services',
    icon: <ServicesIcon />,
  },
];

export const agentLinks = [
  {
    path: Agency.Default,
    name: 'Home',
    icon: <HomeIcon />,
  },
  {
    path: Agency.Students.My,
    name: 'Candidates',
    icon: <StudentIcon />,
  },
  {
    path: Agency.Universities,
    name: 'Universities',
    icon: <SchoolsIcon />,
  },
  {
    path: Agency.Programs,
    name: 'Programs',
    icon: <ProgramIcon />,
  },
  {
    path: Agency.Profile,
    name: 'Profile',
    icon: <ProfileIcon />,
  },
  {
    path: Agent.Services.Default.replace(':view', 'my-services'),
    isMobile: true,
    name: 'Services',
    icon: <ServicesIcon />,
  },
];

export const studentLinks = [
  {
    defaultPath: Student.Default,
    path: Student.Default,
    name: 'Dashboard',
    isMobile: true,
    icon: <HomeIcon />,
  },
  {
    defaultPath: Student.AiSearch,
    path: Student.AiSearch,
    name: 'AI Search',
    isMobile: true,
    icon: <SearchIcon />,
  },
  {
    defaultPath: Student.CardList.Default,
    path: Student.CardList.Grouped,
    name: 'Programs',
    isMobile: true,
    icon: <SchoolsIcon />,
  },
  {
    defaultPath: Student.Consultancy.Default,
    path: Student.Consultancy.Consultant,
    name: 'Consultancy',
    isMobile: true,
    icon: <ServicesIcon />,
  },
  {
    defaultPath: Student.AppTracker.Default,
    path: Student.AppTracker.Workshops,
    name: 'App. Tracker',
    isMobile: true,
    icon: <OngoingIcon />,
  },
  {
    defaultPath: Student.Applications.Default,
    path: Student.Applications.AllPrograms,
    name: 'My Applications',
    isMobile: true,
    icon: <ApplicationsIcon />,
  },
  {
    defaultPath: Student.PrepCenter.Default,
    path: Student.PrepCenter.Dashboard,
    name: 'Exams',
    isMobile: true,
    icon: <PencilRulerIcon />,
  },
  {
    defaultPath: Student.Profile.Default,
    path: Student.Profile.Default.replace(':tab', 'info'),
    name: 'Profile',
    icon: <ProfileIcon />,
  },
  {
    path: Student.Profile.Default,
    isAvatar: true,
    isMobile: true,
  },
  {
    defaultPath: Student.Events.All,
    path: Student.Events.All,
    name: 'Events',
    icon: <CalendarIcon />,
  },
  // {
  //   path: Student.Tutorials,
  //   name: 'Tutorials',
  //   isMobile: true,
  //   icon: <HighSchoolIcon />,
  // },
];

export const highSchoolLinks = [
  {
    path: HighSchool.Students.All,
    name: 'Candidates',
    icon: <StudentIcon />,
  },
  {
    path: HighSchool.Profile,
    name: 'Profile',
    isMobile: true,
    icon: <ProfileIcon />,
  },
  // {
  //   path: HighSchool.Tutorials,
  //   name: 'Tutorials',
  //   isMobile: true,
  //   icon: <HighSchoolIcon />,
  // },
];

export const adminLinks = [
  {
    defaultPath: Admin.CardList.Default,
    path: Admin.CardList.Default.replace(':view', 'grouped'),
    name: 'Programs',
    icon: <ProgramIcon />,
  },
  {
    defaultPath: Admin.Students.Default,
    path: Admin.Students.All,
    name: 'Candidates',
    icon: <StudentIcon />,
  },
  {
    defaultPath: Admin.Applications,
    path: Admin.Applications,
    name: 'Applications',
    icon: <ApplicationsIcon />,
  },
  {
    defaultPath: Admin.PrepCenter.Default,
    path: Admin.PrepCenter.Products,
    name: 'Exams',
    isMobile: true,
    icon: <PencilRulerIcon />,
  },
];

export const masterLinks = [
  {
    defaultPath: Admin.Default,
    path: Admin.Default,
    name: 'Home',
    icon: <HomeIcon />,
  },
  {
    defaultPath: Admin.Services.Default,
    path: Admin.Services.AllServices,
    name: 'Services',
    icon: <ServicesIcon />,
  },
  {
    defaultPath: Admin.AgenciesForAdmin,
    path: Admin.AgenciesForAdmin,
    name: 'Partners',
    icon: <TeamIcon />,
  },
  {
    defaultPath: Admin.Students.Default,
    path: Admin.Students.All,
    name: 'Candidates',
    icon: <StudentIcon />,
  },
  {
    defaultPath: Admin.CardList.Default,
    path: Admin.CardList.Default.replace(':view', 'grouped'),
    name: 'Programs',
    icon: <ProgramIcon />,
  },
  {
    defaultPath: Admin.HighSchools,
    path: Admin.HighSchools,
    name: 'High Schools',
    icon: <HighSchoolIcon />,
  },
  {
    path: Admin.Events.All,
    name: 'Events',
    icon: <CalendarIcon />,
  },
  {
    defaultPath: Admin.PrepCenter.Default,
    path: Admin.PrepCenter.Products,
    name: 'Exams',
    isMobile: true,
    icon: <PencilRulerIcon />,
  },
];

export const universityLinks = [
  {
    defaultPath: University.Default,
    path: University.Default,
    name: 'Home',
    icon: <HomeIcon />,
    isMobile: true,
  },
  // {
  //   path: University.Faculties,
  //   name: 'Faculties',
  //   icon: <ProgramIcon />,
  //   isMobile: true,
  // },
  {
    defaultPath: University.Programs,
    path: University.Programs,
    name: 'Programs',
    icon: <ProgramIcon />,
    isMobile: true,
  },
  {
    defaultPath: University.Applications,
    path: University.Applications,
    name: 'Applications',
    icon: <ApplicationsIcon />,
    isMobile: true,
  },
  // {
  //   path: University.Candidates,
  //   name: 'Applications',
  //   icon: <StudentIcon />,
  // },
];

export const defaultLinks = [
  {
    path: Default.Homepage,
    name: 'Home',
    isMobile: true,
    icon: <HomeIcon />,
  },
  {
    path: Default.Programs,
    name: 'Programs',
    isMobile: true,
    icon: <ProgramIcon />,
  },
  // {
  //   path: `${Default.Homepage}#get-started-section`,
  //   name: 'Services',
  //   isMobile: true,
  //   icon: <ProfileIcon />,
  // },
  {
    path: Default.About,
    name: 'About us',
    isMobile: true,
    icon: <InfoOutlined />,
  },
  {
    path: Default.Auth.Login,
    name: 'Login',
    isMobile: true,
    icon: <ProfileIcon />,
  },
];

export const defaultDrawerLinks = [
  {
    path: Default.Programs,
    name: 'Programs',
    isMobile: true,
  },
  {
    path: Default.Events.All,
    name: 'Events',
    isMobile: true,
  },
  {
    path: 'https://community.uniapply.org',
    name: 'Forum',
    external: true,
    isMobile: true,
  },
  {
    path: 'https://blog.uniapply.org',
    name: 'Blog',
    external: true,
    isMobile: true,
  },
  // {
  //   path: `${Default.Homepage}#get-started-section`,
  //   name: 'Services',
  //   isMobile: true,
  // },
  {
    path: Default.About,
    name: 'About us',
    isMobile: true,
  },
  {
    path: '',
    name: 'Contact',
    isMobile: true,
  },
];
