import styled from 'styled-components';

const lengthMap = [0, 0, 10, 15, 20];

export const AvatarGroupWrapper = styled.div<{ length: number }>`
  display: flex;
  & > * {
    margin-left: ${({ length }) => `-${lengthMap[length < 4 ? length : 4]}px`};
    &:first-child {
      margin-left: 0;
    }
  }
`;
