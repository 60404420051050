import { Box } from '@material-ui/core';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { useBreakpoints, useFiltersCount } from 'hooks';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'store';
import { arrayEquals, getMetaRole } from 'utils';
import { ReactComponent as CloseFiltersIcon } from '../../assets/svg/Icons/CloseFilters.svg';
import { ReactComponent as FilterIcon } from '../../assets/svg/Icons/Filter.svg';
import {
  ClearFiltersButton,
  UniButton,
  UniIconWrapper,
  UniSearch,
  UniTooltip,
  UniTypography,
  UpdatePreferencesPopup,
} from '../../components';
import { ListActions } from '../../views/_agent/home_old/AgencyHome.style';
import { StyledStickyBar } from './StickyListFilterBar.style';

interface Props {
  isLandingPage?: boolean;
  listTitle: string;
  searchHint?: string;
  handleSearch?: (value: string) => void;
  totalResultsLabel?: string;
  popup?: boolean;
  options?: string[];
  handleClosePopup?: () => void;
  onClickFilter?: any;
  loading?: boolean;
  ActionButton?: React.ReactNode;
  tooltip?: string;
  wrapForMobile?: boolean;
}

const StickyListFilterBar = ({
  isLandingPage,
  listTitle,
  searchHint,
  handleSearch,
  totalResultsLabel,
  loading = false,
  handleClosePopup,
  onClickFilter,
  options,
  ActionButton,
  tooltip,
  wrapForMobile,
}: Props): ReactElement => {
  const { t } = useTranslation('studentHome');
  const history = useHistory();
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const { isMobile } = useBreakpoints();
  const filtersCount = useFiltersCount();
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile.role);
  const [popup, setPopup] = useState(false);
  const { queries } = useQueryStringParser<ProgramsFiltersQueries>();

  const studentPreferences = profile?.studentProfile?.preferences;

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRect.y < 1);
      },
      { threshold: [1] },
    );

    window.addEventListener('scroll', () => setScrollY(window.pageYOffset));
    observer.observe(cachedRef!);

    return function () {
      observer.unobserve(cachedRef!);
      window.removeEventListener('scroll', () => setScrollY(window.pageYOffset));
    };
  }, [scrollY]);

  useEffect(() => {
    if (!isStudent) return;
    const formDataCountry = [queries.country].flat(1);
    const formDataSubjects = [queries.discipline].flat(1);
    if (
      (!!queries.country && formDataCountry?.length !== 0 && !arrayEquals(formDataCountry, studentPreferences?.countries)) ||
      (!!queries.discipline && formDataSubjects?.length !== 0 && !arrayEquals(formDataSubjects, studentPreferences?.subjects))
    ) {
      setPopup(true);
    }
  }, []);

  return (
    <StyledStickyBar ref={ref} isSticky={isSticky} isLandingPage={isLandingPage}>
      <Box display='flex' alignItems='baseline'>
        <Box display='flex' alignItems='center' justifyContent={isMobile && 'space-between'} flex='auto' flexWrap='wrap'>
          <div style={{ display: 'inline-block' }}>
            {tooltip ? (
              <UniTooltip title={tooltip!}>
                <UniTypography weight='semi-bold' variant='header3'>
                  {listTitle}
                </UniTypography>
              </UniTooltip>
            ) : (
              <UniTypography weight='semi-bold' variant='header3'>
                {listTitle}
              </UniTypography>
            )}
            {isMobile && filtersCount! > 0 && (
              <UniTypography weight='medium' variant='body2' color='typographyGray100'>
                {totalResultsLabel}
              </UniTypography>
            )}
          </div>
          {!isMobile && (
            <Box ml={2} flex={1}>
              {isLandingPage ? (
                <Box flex={10} minWidth={wrapForMobile ? '100%' : 'auto'} order={wrapForMobile ? 1 : 0}>
                  <UniSearch placeholder={searchHint} loading={loading} options={options} oldSearchVal='' handleSearch={handleSearch!} />
                </Box>
              ) : (
                <UniSearch placeholder={searchHint} loading={loading} options={options} oldSearchVal='' handleSearch={handleSearch!} />
              )}
            </Box>
          )}
          <ListActions>
            {(!isMobile || filtersCount! === 0) && (
              <UniTypography weight='medium' variant='body2' color='typographyGray100'>
                {totalResultsLabel}
              </UniTypography>
            )}
            {popup && <UpdatePreferencesPopup handleClose={() => setPopup(false)} />}
            {!isMobile && !!onClickFilter && (
              <UniButton
                variant='text'
                fullWidth={false}
                color='neutral'
                onClick={onClickFilter}
                startIcon={
                  <UniIconWrapper color='primary100'>
                    <FilterIcon />
                  </UniIconWrapper>
                }
              >
                {t('buttons.filter')}
              </UniButton>
            )}
          </ListActions>
        </Box>

        {filtersCount! > 0 && !wrapForMobile && (
          <Box mt={popup ? 1.5 : 1.2}>
            <ClearFiltersButton
              variant='text'
              fullWidth={false}
              color='neutral'
              onClick={onClickFilter}
              endIcon={
                <UniIconWrapper
                  opacity={0.7}
                  marginClearFilter={true}
                  color='typographyBlack100'
                  onClick={e => {
                    e.stopPropagation();
                    history.push({ pathname: location.pathname, search: '' });
                    handleClosePopup && handleClosePopup();
                  }}
                >
                  <CloseFiltersIcon />
                </UniIconWrapper>
              }
            >
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <UniTypography color='typographyGray100' weight='medium'>
                  {filtersCount} {t('buttons.number_filters')}
                </UniTypography>
                <Box
                  ml={2}
                  onClick={e => {
                    e.stopPropagation();
                    history.push({ pathname: location.pathname, search: '' });
                    handleClosePopup && handleClosePopup();
                  }}
                >
                  <UniTypography color='typographyGray100'>{t('buttons.clear_filter')}</UniTypography>
                </Box>
              </Box>
            </ClearFiltersButton>
          </Box>
        )}
        {ActionButton && (
          <Box mt={1.2} ml={filtersCount! > 0 ? 0 : 3}>
            {ActionButton}
          </Box>
        )}
      </Box>
      <Box display='flex'>
        {isMobile &&
          (isLandingPage ? (
            <Box flex={10} minWidth={wrapForMobile ? '100%' : 'auto'} order={wrapForMobile ? 1 : 0}>
              <UniSearch placeholder={searchHint} loading={loading} options={options} oldSearchVal='' handleSearch={handleSearch!} />
            </Box>
          ) : (
            <UniSearch placeholder={searchHint} loading={loading} options={options} oldSearchVal='' handleSearch={handleSearch!} />
          ))}
        {isMobile && !!onClickFilter && (
          <UniIconWrapper color='primary100' onClick={onClickFilter}>
            <FilterIcon />
          </UniIconWrapper>
        )}
      </Box>
    </StyledStickyBar>
  );
};

export default React.memo(StickyListFilterBar);
