import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniProgramFilterBar, UniTaskFilterBar, UniMetrics, UniNavigationTab } from 'components';
import { useBreakpoints } from 'hooks';
import { useHistory } from 'react-router';
import { UniMetricsBaseProps } from '../uniMetricsBase/UniMetricsBase';
import { HerosectionActions } from './HerosectionActions';
import { HerosectionHeader } from './HerosectionHeader';
import { StyledMedia, StyledTabs } from './UniHerosection.style';

export interface TabProps {
  label: string;
  path: string;
  disabled?: boolean;
}
interface Props {
  media?: string;
  withActions?: boolean;
  onClickFilters?: Function;
  options?: string[];
  withFilterBar?: boolean;
  headerVariant: 'base' | 'baseNoAvatar' | 'title' | 'avatarTitle' | 'consultant';
  title: string;
  universityName?: string;
  metrics?: Partial<UniMetricsBaseProps>[];
  noCountryFilterBar?: boolean;
  actionsChipLabel?: string;
  Actions?: React.ReactElement;
  MetricsComponent?: React.ReactElement;
  tabs?: TabProps[];
  universityId?: string;
  candidate?: Components.Schemas.UserDto;
  agents?: Components.Schemas.UserResponse[];
  profileImageSrc?: string;
  filterbarHeadline?: string;
  filterbarVariant?: 'programs' | 'tasks';
  avatarVariant?: string;
  overflow?: 'hidden' | 'auto' | 'visible' | 'none';
  onProfileImageUpload?: (file?: File) => void;
  withCandidateFilter?: boolean;
}

export const UniHerosection = ({
  withActions = false,
  onClickFilters,
  options,
  withFilterBar,
  headerVariant,
  title,
  media,
  universityName,
  actionsChipLabel,
  Actions,
  metrics,
  noCountryFilterBar = false,
  tabs,
  MetricsComponent,
  universityId,
  candidate,
  agents,
  overflow,
  profileImageSrc,
  avatarVariant = 'circle',
  filterbarHeadline,
  filterbarVariant = 'programs',
  onProfileImageUpload,
  withCandidateFilter = false,
}: Props) => {
  const { isMobile } = useBreakpoints();
  const history = useHistory();
  const { pathname, search } = history.location;
  const handleChange = (event: any, value: string) => {
    history.replace(value + search);
  };
  const withMetrics = !!metrics || !!MetricsComponent;

  return (
    <>
      {media && (
        <StyledMedia>
          <img src={media} alt={title} />
        </StyledMedia>
      )}
      <Box width='100%'>
        <HerosectionHeader
          title={title}
          studentName={candidate ? `${candidate?.firstName} ${candidate?.lastName}` : undefined}
          variant={headerVariant}
          universityName={universityName}
          universityId={universityId}
          agents={agents}
          studentId={candidate?.id}
          avatarVariant={avatarVariant}
          profileImageSrc={profileImageSrc}
          onProfileImageUpload={onProfileImageUpload}
        />
        <Box display='flex' justifyContent={!withMetrics ? 'flex-end' : 'space-between'}>
          {withMetrics && (
            <Box mb={isMobile ? 3 : 4}>
              {MetricsComponent
                ? MetricsComponent
                : metrics && <UniMetrics variant='iconed' size={isMobile ? 'small' : 'large'} values={metrics} />}
            </Box>
          )}
          {!isMobile && !!Actions ? Actions : withActions && <HerosectionActions chipLabel={actionsChipLabel || '-'} />}
        </Box>
      </Box>
      {withFilterBar && (
        <>
          {filterbarVariant === 'tasks' ? (
            <UniTaskFilterBar
              onClickFilter={onClickFilters}
              options={options}
              overflow={overflow}
              title={filterbarHeadline}
              withCandidateFilter={withCandidateFilter}
            />
          ) : (
            <UniProgramFilterBar
              onClickFilter={onClickFilters}
              noCountry={noCountryFilterBar}
              options={options}
              overflow={overflow}
              title={filterbarHeadline}
            />
          )}
          <Box pb={isMobile && !!tabs ? 3 : isMobile ? 3 : !!tabs ? 4 : 5} />
        </>
      )}
      <StyledTabs value={pathname} onChange={handleChange} indicatorColor='primary' textColor='primary'>
        {tabs?.map((item: TabProps, index: any) => (
          <UniNavigationTab key={index} label={item.label} value={item.path} disabled={!!item.disabled} />
        ))}
      </StyledTabs>
    </>
  );
};
