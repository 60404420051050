import { AxiosError } from 'axios';
import { queryCache, useMutation } from 'react-query';
import { getMetaRole } from 'utils';
import { Components } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';
//forced -> when we want to save student preferences even if no programs match the student preferences
interface Payload {
  studentPreferences: Components.Schemas.UpdateStudentPreferencesRequest;
  forced?: boolean;
  student?: Partial<Components.Schemas.UserDto>;
}
// interface Payload {
//   params: {
//     studentId: string;
//   };
//   body:{
//       studentPreferences: Components.Schemas.StudentPreferencesDto;
//       forced?: boolean;}
// }
const usePostPreferences = () => {
  const { client } = useClient();
  const { profile, setProfile } = useAuth();
  const { isStudent } = getMetaRole(profile.role!);
  return useMutation(
    async (payload: Payload) => {
      let updatedProfile = {};
      if (isStudent) {
        updatedProfile = {
          ...profile,
          studentProfile: {
            ...profile.studentProfile,
            studentPreferences: payload.studentPreferences,
          },
        };
      } else {
        updatedProfile = {
          ...payload.student,
          studentProfile: {
            ...payload.student?.studentProfile,
            studentPreferences: payload.studentPreferences,
          },
        };
      }
      return client!
        .SearchApi_searchPrograms({
          country: payload.studentPreferences.countries,
          // specialization: payload.studentPreferences.subjects,
          // tuitionFee: payload.studentPreferences.fee,
          // monthly_living_costs: payload.studentPreferences.livingExpenses,
        })
        .then(({ data }) => {
          if (data.results.length > 0) {
            if (isStudent) {
              return client!.StudentApi_updateStudent(profile.id!, updatedProfile as Components.Schemas.UpdateStudentRequest);
            } else {
              return client!.StudentApi_updateStudent(payload.student?.id!, updatedProfile as Components.Schemas.UpdateStudentRequest);
            }
          } else {
            if (payload.forced) {
              if (isStudent) {
                return client!.StudentApi_updateStudent(profile.id!, updatedProfile as Components.Schemas.UpdateStudentRequest);
              } else {
                return client!.StudentApi_updateStudent(payload.student?.id!, updatedProfile as Components.Schemas.UpdateStudentRequest);
              }
            } else {
              throw new Error('No programs found');
            }
          }
        });
    },
    {
      onSuccess: response => {
        if (isStudent) {
          setProfile(response.data);
        } else {
          queryCache.invalidateQueries(CacheKeys.Agency.Candidate);
        }
      },
      onError: (error: AxiosError) => {


      },
    },
  );
};

export default usePostPreferences;
