interface Question {
  question: string;
  answers?: string[];
  optional?: boolean;
}

const questionSet1: Question[] = [
  {
    question: '1. What\'s your dream superpower or TV series that fits your career perspective?',
    answers: [
      'a) Building gadgets to save the world or "Silicon Valley"',
      'b) Time traveling to experience epic tales or "Grey\'s Anatomy"',
      'c) Connecting with nature and animals or "Planet Earth"',
      'd) Capturing memories in a movie reel or "Law & Order"',
      'e) Persuading anyone with a speech or "The Office"',
      'f) Flying (or sprinting) faster than light',
    ],
  },
  {
    question: '2. Imagine you can start a new project in school or a new club. What excites you most?',
    answers: [
      'a) Creating a new app, computer program, or Tech & Innovations Club',
      'b) Publishing your own book, staging a drama, or Book Club/Drama Society',
      'c) Organizing an eco-friendly school event or Environmental Club/Creative Arts',
      'd) Shooting a video, starting a podcast, or Media & Film Club',
      'e) Leading a group to help the community or Leadership & Community Service',
      'f) Setting up a sports challenge, dance-off, or Sports & Fitness Club/Dance Crew',
    ],
  },
  {
    question: '3. What type of city would fit your dream?',
    answers: [
      'a) Bustling metropolis',
      'b) Quiet suburban town',
      'c) Historical and cultural city',
      'd) Coastal beach town',
      'e) Mountainous and nature-centric',
      'f) A city with a mix of everything',
    ],
  },
  {
    question: '4. How do you react when faced with a complex problem or celebrate a big win?',
    answers: [
      'a) Analyze with logic, doodles, or explore a new gadget',
      'b) Reflect with a diary, chat with a friend, or have a quiet evening reading',
      'c) Daydream a solution under a tree or take a day trip to a nature spo',
      'd) Collaborate, draw inspiration from films, or binge movies',
      'e) Gather friends to tackle it together or organize a get-together',
      'f) Train, adapt, do a victory dance, or play a sports match',
    ],
  },
  {
    question: '5. What is your favorite subject in school or your relationship with math?',
    answers: [
      'a) Mathematics and best friends with math',
      'b) Literature and acquaintances with math',
      'c) Science and it\'s complicated with math',
      'd) History and not on speaking terms with math',
      'e) Arts and Music',
      'f) Physical Education or "Math who?"',
    ],
  },
  {
    question: '6. What is your dream study destination area or proficiency in English?',
    answers: [
      'a) North America and great English knowledge',
      'b) Europe and could be improved in English',
      'c) Asia and more comfortable with another language',
      'd) Australia/New Zealand and bilingual/multilingual',
      'e) South America',
      'f) Africa or prefer non-verbal communication',
    ],
  },
  {
    question: '7. How much are you willing to spend on your University Experience (including tuition, late-night pizza, and those "essential" dorm decorations)?',
    answers: [
      'a) $10,000 or below (I\'m here for the free Wi-Fi)',
      'b) $10,001 - $30,000 (Maybe a textbook or two... and a lot of instant noodles)',
      'c) $30,001 - $50,000 (I\'ll splurge on some fancy pens)',
      'd) $50,001 - $70,000 (Living the college dream, one coffee at a time)',
      'e) Above $70,000 (I\'m basically the university\'s VIP)',
    ],
  },
  {
    question: '8. If a genie offered you a learning experience or a challenge that excites you, what would you choose?',
    answers: [
      'a) An advanced tech workshop in Silicon Valley or inventing the next big tech solution',
      'b) A literature retreat in the English countryside or writing a captivating novel/script',
      'c) An eco-adventure in the Amazon or planning a sustainable event for the city',
      'd) A film-making course in Hollywood or producing a video showcasing local talent',
      'e) A leadership camp at the United Nations or launching a community-benefiting project',
      'f) A training session with Olympic champions or setting a national record in sports/dance',
    ],
  },
  {
    question: '9. What grade are you in currently?',
    answers: [
      'a) High school (Grade 9)',
      'b) High school (Grade 10)',
      'c) High school (Grade 11)',
      'd) High school (Grade 12)',
      'e) Currently enrolled in uni',
      'f) Taking a gap year or other',
    ],
  },
  {
    question: '10. What\'s your favorite hobby and why?',
  },
];

const questionSet2: Question[] = [
  {
    question: '1. If you could have any animal as your sidekick, which would you choose?',
    answers: [
      'a) A persuasive parrot',
      'b) A tech-savvy monkey',
      'c) A caring dolphin',
      'd) A wise owl',
      'e) A creative chameleon',
    ],
  },
  {
    question: '2. Pick your dream superpower for a day:',
    answers: [
      'a) Mind reading',
      'b) Flying',
      'c) Time travel',
      'd) Super strength',
      'e) Invisibility',
    ],
  },
  {
    question: '3. What\'s your favorite way to relax after a long day?',
    answers: [
      'a) Chatting with friends',
      'b) Tinkering with gadgets',
      'c) Watching documentaries',
      'd) Creating art or music',
      'e) Planning your next adventure',
    ],
  },
  {
    question: '4. If you could invent something, what would it be?',
    answers: [
      'a) A universal language translator',
      'b) A time-saving machine',
      'c) An eco-friendly city',
      'd) A virtual reality classroom',
      'e) A teleportation device',
    ],
  },
  {
    question: '5. Choose a movie genre that best describes your life:',
    answers: [
      'a) Romantic comedy',
      'b) Sci-fi thriller',
      'c) Action adventure',
      'd) Historical drama',
      'e) Fantasy',
    ],
  },
  {
    question: '6. What’s your favorite school subject?',
    answers: [
      'a) Languages',
      'b) Science',
      'c) Physical Education',
      'd) Art',
      'e) History',
    ],
  },
  {
    question: '7. If you could live in any historical era, when would it be?',
    answers: [
      'a) The roaring twenties',
      'b) Ancient Egypt',
      'c) The futuristic world of 2100',
      'd) The medieval period',
      'e) The Renaissance',
    ],
  },
  {
    question: '8. What type of TV show would you host?',
    answers: [
      'a) A travel adventure show',
      'b) A DIY home renovation series',
      'c) A mystery detective series',
      'd) A cooking competition',
      'e) A talk show on current events',
    ],
  },
  {
    question: '9. If you opened a business, what would it be?',
    answers: [
      'a) A trendy café',
      'b) A tech startup',
      'c) A wellness retreat',
      'd) An art gallery',
      'e) A bookshop with a twist',
    ],
  },
  {
    question: '10. Which would you rather do on a Saturday?',
    answers: [
      'a) Plan a community event',
      'b) Build a robot',
      'c) Volunteer at a local shelter',
      'd) Read a book on quantum physics',
      'e) Compose a song',
    ],
  },
  {
    question: '11. Describe your ideal work environment in three words.',
  },
  {
    question: '12. Name a person you admire and why in one sentence.',
  },
];

const questionSet3: Question[] = [
  ...questionSet2,
  {
    question: 'Email of a parent or legal guardian',
  },
  {
    question: 'Phone of a parent or legal guardian',
  },
  {
    question: 'First name of a parent or legal guardian',
  },
  {
    question: 'Last name of a parent or legal guardian',
  },
];

export function getQuestionSet(variant?: string): Question[] {
  switch (variant) {
    case '2':
      return questionSet2;
    case '3':
      return questionSet3;
    default:
      return questionSet1;
  }
}
