export interface ParamsObj {
  [key: string]: string | number | string[] | void;
}

const isObject = (value: any) => typeof value === "object";

export default (obj: any): object => {
  const newObject = {} as ParamsObj;

  for (let key in obj) {
    const currentVal = obj[key];

    if (Array.isArray(currentVal)) {
      if (currentVal.length) newObject[key] = currentVal;
      continue;
    }

    if (isObject(currentVal)) continue; // we don't handle inner objects

    if (currentVal || currentVal === 0) {
      newObject[key] = currentVal;
    }
  }

  return newObject;
};
