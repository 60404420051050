import { Box } from '@material-ui/core';
import FiltersDrawerClearFilters from 'components/_drawers/filtersDrawer/FiltersDrawerClearFilters';
import { useBreakpoints } from 'hooks';
import React, { ReactElement } from 'react';
import { ReactComponent as BackDashIcon } from '../../../assets/svg/Icons/Arrow/RightWithDash.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/Close.svg';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import UniTypography from '../uniTypography/UniTypography';
import { UniModalDrawerHeaderWrapper } from './UniModalDrawer.style';

interface Props {
  title?: string;
  onClose?: Function;
  onBack?: Function;
  TitleComponent?: React.ReactElement;
  isFilters?: boolean;
}

export default function UniModalDrawerHeader({ onBack, isFilters, onClose, title, TitleComponent }: Props): ReactElement {
  const { isMobile } = useBreakpoints();

  return (
    <UniModalDrawerHeaderWrapper>
      {onBack && (
        <UniIconWrapper marginClearFilter size='medium' color='typographyGray100' onClick={() => onBack()}>
          {/* {isMobile ? <BackIcon /> : } */}
          <BackDashIcon />
        </UniIconWrapper>
      )}
      <Box display='flex' height={isMobile ? '27px' : '36px'} alignItems='center' justifyContent='space-between'>
        {TitleComponent ? (
          TitleComponent
        ) : (
          <UniTypography variant={isMobile ? 'body1' : 'header2'} weight='semi-bold'>
            {title}
          </UniTypography>
        )}

        <Box display='flex' alignItems='center' justifyContent='space-between'>
          {isFilters && <FiltersDrawerClearFilters />}
          {onClose && (
            <UniIconWrapper marginClearFilter size='medium' color='typographyGray100' onClick={() => onClose()}>
              <CloseIcon />
            </UniIconWrapper>
          )}
        </Box>
      </Box>
    </UniModalDrawerHeaderWrapper>
  );
}
