import { Box, Fade } from '@material-ui/core';
import { UniAutocomplete, UniTypography } from 'components';
import { Days, Months } from 'constants/dates';
import { useBreakpoints } from 'hooks';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'types';
import { generateYears } from 'utils';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import { Error, Label, StyledAlertIcon } from '../uniTextField/UniTextField.style';

interface UniDateProps {
  optional?: boolean;
  onChange: Function;
  value: string;
  label?: string;
  unlabeled?: boolean;
  disabledDay?: boolean;
  disabledMonth?: boolean;
  disabledYear?: boolean;
  error?: boolean;
  name?: string;
  helperText?: string;
  fullWidth?: boolean;
  showFutureYears?: boolean;
}

export const UniDate = ({
  disabledDay,
  disabledMonth,
  onChange,
  value,
  name,
  disabledYear,
  label,
  optional = false,
  error,
  helperText,
  fullWidth,
  showFutureYears = false,
}: UniDateProps) => {
  const date = value ? moment(value, 'YYYY-DD-MM') : undefined;
  const [day, setDay] = useState(date ? date.date().toString() : '');
  const [month, setmonth] = useState(date ? (date.month() + 1).toString() : '');
  const [year, setYear] = useState(date ? date.year().toString() : '');

  const { years } = generateYears({ showFutureYears });
  const { t } = useTranslation('uniForms');
  const { isMobile } = useBreakpoints();
  return (
    <Box display='grid' width={fullWidth ? '100%' : 'fit-content'}>
      {label && (
        <Label style={{ marginBottom: '-21px' }}>
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            {label}
          </UniTypography>
          {optional && (
            <UniTypography variant='footnote' color='typographyGray70'>
              Optional
            </UniTypography>
          )}
        </Label>
      )}
      <Box display='flex'>
        <Box pr={1.5} flex='1'>
          <UniAutocomplete
            disableClearable
            getOptionLabel={option => option.label}
            error={error}
            fullWidth
            placeholder={isMobile ? t('date.mobile.day') : t('date.desktop.day')}
            name={name}
            disabled={disabledDay}
            borderColor={error ? 'alert' : undefined}
            value={Days.find(item => item.value === day)}
            options={Days}
            handleChange={(e: any, { value }: OptionType) => {
              setDay(value);
              onChange(`${year}-${month}-${value}`);
            }}
          />
        </Box>
        <Box pr={1.5} flex='1'>
          <UniAutocomplete
            disableClearable
            getOptionLabel={option => option.label}
            error={error}
            fullWidth
            placeholder={isMobile ? t('date.mobile.month') : t('date.desktop.month')}
            disabled={disabledMonth}
            borderColor={error ? 'alert' : undefined}
            value={Months.find(item => item.value === month)}
            options={Months}
            handleChange={(e: any, { value }: OptionType) => {
              setmonth(value);
              onChange(`${year}-${value}-${day}`);
            }}
          />
        </Box>
        <Box flex='1'>
          <UniAutocomplete
            disableClearable
            getOptionLabel={option => option.label}
            error={error}
            fullWidth
            placeholder={isMobile ? t('date.mobile.year') : t('date.desktop.year')}
            optionsSortType='asc'
            value={years.find(item => item.value === year)}
            disabled={disabledYear}
            borderColor={error ? 'alert' : undefined}
            options={years}
            optional={optional}
            handleChange={(e: any, { value }: OptionType) => {
              setYear(value);
              onChange(`${value}-${month}-${day}`);
            }}
          />
        </Box>
      </Box>
      <Fade in={!!helperText} unmountOnExit style={{ marginTop: '-18px' }}>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {helperText}
          </UniTypography>
        </Error>
      </Fade>
    </Box>
  );
};
