import styled from 'styled-components';
import { Colors, Shape } from '../../constants';

export const StyledContainer = styled.div<{ isMobile?: boolean }>`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  border: 1px solid ${Colors.Typography.Gray_30};
  padding: ${({ isMobile, theme }) => (isMobile ? theme.spacing(3, 2) : theme.spacing(5, 5))};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
`;
