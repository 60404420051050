import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { UniButton, UniTextField } from 'components';
import { UniDate } from 'components/_inputs/uniDate/UniDate';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import { GraduationLevel, graduationLevel } from 'constants/levelOfStudy';
import { motion } from 'framer-motion';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Nationality } from 'types';
import highSchoolYear from 'constants/options/highschoolYear';
import { countriesMap } from 'constants/countriesMap';
import { StyledUniCardFooter } from '../StudentOnboard.style';

export type Step4FormData = {
  levelOfStudy: GraduationLevel;
  educationCountry: Nationality;
  educationCity: string;
  graduationDate: string;
  schoolName: string;
  highSchoolYear: 'YEAR_9' | 'YEAR_10' | 'YEAR_11' | 'YEAR_12' | 'YEAR_13';
};

type Props = {
  defaultValues: Partial<Step4FormData>;
  nextStep: (formData: Step4FormData) => void;
  prevStep: () => void;
};

const Step4: React.FC<Props> = ({ defaultValues, nextStep, prevStep }) => {
  const { t } = useTranslation('forms');
  const { t: tButtons } = useTranslation('buttons');
  const { control, register, errors, watch, handleSubmit } = useForm<Step4FormData>({ defaultValues });
  const showInstitutionName = watch('levelOfStudy');

  useEffect(() => {
    localStorage.setItem('onboardFormDataStep4', JSON.stringify(watch()));
  }, [watch()]);

  return (
    <>
      <Box mb={4}>
        <Controller
          control={control}
          name='levelOfStudy'
          rules={{ required: `${t('form.errors.level_of_study')}` }}
          render={({ value, onChange }) => (
            <UniSelect
              label='What is your current level of study?'
              value={graduationLevel.find(item => item.value === value)}
              onChange={value => onChange(value?.value)}
              options={graduationLevel}
              error={errors?.levelOfStudy?.message}
            />
          )}
        />
        {!!showInstitutionName && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Controller
              control={control}
              name='graduationDate'
              rules={{
                required: `${t('form.errors.graduation_date')}`,
                validate: (value: string) =>
                  value.split('-').every(Boolean)
                    ? moment(value, 'YYYY-M-D').isValid() || `${t('form.errors.graduation_date')}`
                    : `${t('form.errors.graduation_date_2')}`,
              }}
              render={({ value, onChange }) => (
                <UniDate
                  label='When do you expect to graduate?'
                  value={value}
                  onChange={(val: string) => {
                    onChange(val);
                  }}
                  error={!!errors.graduationDate?.message}
                  helperText={errors.graduationDate?.message}
                  showFutureYears
                  fullWidth
                />
              )}
            />
          </motion.div>
        )}
        {showInstitutionName === 'HIGH_SCHOOL' && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Controller
              control={control}
              name='highSchoolYear'
              render={({ value, onChange }) => (
                <UniSelect
                  label={t('form.highSchoolYear')}
                  value={highSchoolYear.find(item => item.value === value)}
                  onChange={value => onChange(value?.value)}
                  options={highSchoolYear}
                  error={errors.highSchoolYear?.message}
                />
              )}
            />
          </motion.div>
        )}
        <UniTextField
          label='Institution name'
          inputRef={register({
            required: `${t('form.errors.institution_name')}`,
            validate: (value: string) => value.trim() !== '' || `${t('form.errors.institution_name')}`,
          })}
          name='schoolName'
          error={!!errors?.schoolName?.message}
          helperText={errors?.schoolName?.message}
          fullWidth
        />
        <Controller
          control={control}
          name='educationCountry'
          rules={{ required: `${t('form.errors.institution_country')}` }}
          render={({ value, onChange }) => (
            <UniSelect
              label='In which country is your institution located?'
              value={countriesMap.find(({ value: v }) => v === value)}
              onChange={value => onChange(value?.value)}
              options={countriesMap}
              error={errors?.educationCountry?.message}
            />
          )}
        />
        <UniTextField
          label='In which city is your institution located?'
          inputRef={register({
            required: `${t('form.errors.institution_city')}`,
            validate: (value: string) => value.trim() !== '' || `${t('form.errors.institution_city')}`,
          })}
          name='educationCity'
          error={!!errors?.educationCity?.message}
          helperText={errors?.educationCity?.message}
          fullWidth
        />
      </Box>
      <StyledUniCardFooter
        variants='M'
        type='labeled'
        SecondaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            variant='text'
            size={'large'}
            color='neutral'
            fullWidth={false}
            onClick={prevStep}
          >
            {tButtons('previous')}
          </UniButton>
        }
        PrimaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            size={'large'}
            color='primary'
            fullWidth={false}
            onClick={handleSubmit(nextStep)}
          >
            {tButtons('next_step')}
          </UniButton>
        }
      />
    </>
  );
};

export default Step4;
