import { Box } from '@material-ui/core';
import { useMagicLinkLogin } from 'api';
import { UniButton, UniCheckbox, UniIconWrapper, UniOAuth, UniTextField, UniTypography } from 'components';
import { AuthForm, StyledChildrenWrapperTop } from 'components/_layouts/authLayout/AuthLayout.style';
import { useBreakpoints } from 'hooks';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import termsDoc from '../../../assets/docs/T&Cs Uniapply Platform.pdf';
import { ReactComponent as EmailIcon } from '../../../assets/svg/Icons/Candidates.svg';
import { BrowserRoutes } from '../../../constants';

interface Inputs {
  email: string;
  terms: boolean;
}

const MagicLinkSignup = () => {
  const { isMobile } = useBreakpoints();
  const { register, handleSubmit, control, errors } = useForm<Inputs>();
  const [magicSignup, { isLoading }] = useMagicLinkLogin();

  const history = useHistory();

  const onSubmit = (formData: Inputs) => magicSignup(formData);

  const onBackToSignup = () => history.push(BrowserRoutes.Default.Auth.SignUp);
  const onSignup = handleSubmit(onSubmit);
  const onGoToLogin = () => history.push(BrowserRoutes.Default.Auth.MagicLinkLogin);

  return (
    <>
      <StyledChildrenWrapperTop>
        <UniOAuth google facebook signup />
        <AuthForm onSubmit={onSignup}>
          <UniTextField
            startAdornment={
              <UniIconWrapper size='medium' color='typographyGray100'>
                <EmailIcon />
              </UniIconWrapper>
            }
            inputRef={register({ required: 'Email is required!' })}
            error={!!errors?.email?.message}
            helperText={errors?.email?.message}
            name='email'
            label={!isMobile ? 'Email' : undefined}
            placeholder='Enter email'
            fullWidth
          />
          <Box display='flex' alignItems='center'>
            <Controller
              control={control}
              name='terms'
              rules={{
                required: 'You have to agree with our terms of service.',
              }}
              render={({ onChange, value }) => <UniCheckbox onChange={e => onChange(e.target.checked)} checked={value} />}
            />
            <Link to={termsDoc} target='_blank'>
              <UniTypography variant='subheader' color='typographyGray70'>
                I agree with the general terms and conditions of Youni
              </UniTypography>
            </Link>
          </Box>
          {!!errors.terms?.message && (
            <UniTypography variant='subheader' color='stateAlert100'>
              {errors.terms?.message}
            </UniTypography>
          )}
        </AuthForm>
        <Box mb={3}>
          <UniButton disablePadding variant='text' fullWidth={false} onClick={onBackToSignup}>
            Use password instead
          </UniButton>
        </Box>
        {!isMobile && (
          <UniButton variant='main' size='large' fullWidth={isMobile} onClick={onSignup}>
            {isMobile ? 'Sign Up' : 'Create Account'}
          </UniButton>
        )}
      </StyledChildrenWrapperTop>
      <Box display='flex' flexDirection='column' alignItems='center'>
        {isMobile && (
          <UniButton variant='main' size='large' fullWidth={isMobile} onClick={onSignup} loading={isLoading}>
            {isMobile ? 'Sign Up' : 'Create Account'}
          </UniButton>
        )}
        <Box display='flex' alignItems='center' mt={2}>
          <Box mr={1}>
            <UniTypography color='typographyGray100' weight='regular' variant='body2'>
              Already have an account?
            </UniTypography>
          </Box>
          <UniButton disablePadding variant='text' fullWidth={false} onClick={onGoToLogin}>
            Log In
          </UniButton>
        </Box>
      </Box>
    </>
  );
};

export default MagicLinkSignup;
