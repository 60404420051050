import { LinearProgress } from '@material-ui/core';
import { Colors } from 'constants/uiConfig';
import styled from 'styled-components';
import { Size } from './UniProgressBar';

export const StyledProgressBar = styled(LinearProgress)<{ size?: Size }>`
  width: 100%;
  height: ${({ size }) => (size === 's' ? '4px' : '8px')};
  background-color: ${Colors.Secondary._10};
  .MuiLinearProgress-bar1Determinate {
    background-color: ${Colors.Secondary._100};
  }
`;
