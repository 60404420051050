import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../constants';

export const StyledApplicationsListItem = styled.div`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  box-shadow: ${Shadows.shadow1};

  display: grid;
  grid-template-columns: repeat(4, 2fr) 1fr;
  column-gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
  min-height: ${({ theme }) => theme.typography.pxToRem(73)};
  padding: ${({ theme }) => theme.spacing(0, 2)};

  cursor: pointer;
`;

export const StyledApplicationsList = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: ${({ theme }) => theme.spacing(3)}px; */
`;

export const StyledApplicationsListHeader = styled.div<{ stretch: boolean }>`
  padding: ${({ theme }) => theme.spacing(0, 2)};
  display: grid;
  grid-template-columns: repeat(4, 2fr) 1fr;
  column-gap: ${({ theme }) => theme.spacing(1)}px;

  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`;

export const StyledApplicationsListItemHeader = styled.div<{ stretch: boolean }>`
  padding: ${({ theme }) => theme.spacing(0, 4)};
  display: grid;

  grid-template-columns: ${({ stretch }) => (!stretch ? '2fr repeat(2, 1fr) 1fr 1.5fr' : '2fr repeat(2, 1fr) 2fr')};
  column-gap: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledApplicationsListItemBody = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 4)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0, 3)};
`;

export const StyledApplicationMetaItem = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledApplicationMetaItemContent = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledApplicationsListItemDivider = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${Colors.Gray1._100};
  margin: ${({ theme }) => theme.spacing(1, 0, 2, 0)};
`;

export const StyledSubmissionDatesHeader = styled.div`
  background-color: ${Colors.Gray1._100};

  padding: ${({ theme }) => theme.spacing(1, 4)};
  display: grid;

  grid-template-columns: 1.5fr repeat(2, 2fr);
  column-gap: ${({ theme }) => theme.spacing(4)}px;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;
export const StyledSubmissionDatesBody = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 4)};
  display: grid;

  grid-template-columns: 1.5fr repeat(2, 2fr);
  column-gap: ${({ theme }) => theme.spacing(4)}px;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDataCard = styled.div`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  box-shadow: ${Shadows.shadow2};
  padding: ${({ theme }) => theme.spacing(3, 4)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const SelectedApplicationsWrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 30px;
  left: 50%;

  transform: translateX(-50%);
  z-index: 999;

  background-color: ${Colors.Primary._100};
  padding: ${({ theme }) => theme.spacing(1.5, 3)};
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;

  white-space: nowrap;
  max-width: 415px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)}px;

  transition: 0.2s;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  & > * {
    flex: 1;
  }
`;
