import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UniTextWithIcon, UniTypography } from '..';
import useGetAgency from '../../api/agency/useGetAgency';
import { ReactComponent as LocationIcon } from '../../assets/svg/Icons/Location.svg';
import { ReactComponent as HourglassIcon } from '../../assets/svg/Icons/Program/Hourglass.svg';
import { useErrorHandler } from '../../hooks';
import { StyledDivider, StyledProgramDataCard } from '../programExtended/ProgramExtended.style';
import UniBounceLoader from '../_common/uniBounceLoader/UniBounceLoader';

export const AgentDetailsAgency = (props: { agencyId: string }) => {
  const { t } = useTranslation('agencyProfile');
  const {
    data: agency,
    status: agencyStatus,
    error: agencyError,
  } = useGetAgency({
    agencyId: props.agencyId,
  });
  const agencyDetailsError = useErrorHandler(agencyError!);
  if (agencyStatus === 'loading') {
    return <UniBounceLoader />;
  }

  return (
    <StyledProgramDataCard>
      <Box mb={3}>
        <UniTypography color='primary100' weight='medium'>
          {t('labels.agency_details.title')}
        </UniTypography>
      </Box>
      {agencyDetailsError.message && (
        <Box mb={2}>
          <UniTypography color='stateAlert100'>{agencyDetailsError.message}</UniTypography>
        </Box>
      )}

      <Box display='flex' flexDirection='row' mb={2}>
        <Box flex={2}>
          <UniTextWithIcon
            iconColor='secondary100'
            Icon={<LocationIcon />}
            label={t('labels.agency_details.address')}
            value={agency?.address?.addressLine1 ?? '-'}
          />
        </Box>
        <Box flex={1}>
          <UniTextWithIcon
            iconColor='secondary100'
            Icon={<HourglassIcon />}
            label={t('labels.agency_details.tax_code/vat')}
            value={agency?.taxCode ?? '-'}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' mb={2}>
        <Box flex={1}>
          <UniTextWithIcon
            iconColor='secondary100'
            Icon={<LocationIcon />}
            label={t('labels.agency_details.state/province')}
            value={agency?.address?.state ?? '-'}
          />
        </Box>
        <Box flex={1}>
          <UniTextWithIcon label={t('labels.agency_details.city')} value='Brasov' />
        </Box>
        <Box flex={1}>
          <UniTextWithIcon
            iconColor='secondary100'
            Icon={<HourglassIcon />}
            label={t('labels.agency_details.country')}
            value={agency?.address?.country ?? '-'}
          />
        </Box>
      </Box>
      <StyledDivider />
      <Box display='flex' flexDirection='row' mb={2}>
        <Box flex={2}>
          <UniTextWithIcon
            iconColor='typographyBlack100'
            Icon={<HourglassIcon />}
            label={t('labels.agency_details.email')}
            value={agency?.virtualAddress?.email ?? '-'}
          />
        </Box>
        <Box flex={1}>
          <UniTextWithIcon
            iconColor='typographyBlack100'
            Icon={<HourglassIcon />}
            label={t('labels.agency_details.phone_number')}
            value={agency?.virtualAddress?.phone ?? '-'}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' mb={2}>
        <Box flex={2}>
          <UniTextWithIcon
            iconColor='typographyBlack100'
            Icon={<HourglassIcon />}
            label={t('labels.agency_details.whatsapp_id')}
            value={agency?.whatsAppId ?? '-'}
          />
        </Box>
        <Box flex={1}>
          <UniTextWithIcon
            iconColor='typographyBlack100'
            Icon={<HourglassIcon />}
            label={t('labels.agency_details.website')}
            value={agency?.virtualAddress?.website ?? '-'}
          />
        </Box>
      </Box>
    </StyledProgramDataCard>
  );
};
