import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UniTypography } from '../../..';
import { SuccessIllustration } from '../UniversityReplyForm.style';

interface Props {}

export default function UniversityReplyStep5({}: Props): ReactElement {
  const { t } = useTranslation('universityReplyForm');

  return (
    <>
      <SuccessIllustration />
      <UniTypography variant='header1' color='secondary100' weight='semi-bold'>
        {t('step5.title')}
      </UniTypography>
      <UniTypography variant='body2' color='typographyGray100'>
        {t('step5.subtitle1')}
      </UniTypography>
      <UniTypography variant='body2' color='typographyGray100'>
        {t('step5.subtitle2')}
      </UniTypography>
    </>
  );
}
