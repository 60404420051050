import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Colors, Shadows } from '../../constants';
import UniCardWrapper from '../_common/uniCardWrapper/UniCardWrapper';

export const StepperWrapper = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.spacing(-4.5)}px;
  z-index: 3;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: relative;
    top: 0;
    left: 0;
    width: calc(100% + 32px);
    transform: translate(0);
  }
`;

export const StepsWrapper = styled(motion.div)`
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0, 2, 0)};
  height: ${({ theme }) => theme.spacing(9)}px;
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  background-color: ${Colors.Secondary._50};
  box-shadow: ${Shadows.shadow2};
  overflow: visible;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    border-radius: 0;
    overflow-x: auto;
    box-shadow: none;
    padding-right: ${({ theme }) => theme.spacing(5)}px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  & > * {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const StepContainer = styled(motion.div)<{ completed: boolean; active: boolean; collapsed: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  cursor: ${({ collapsed }) => (collapsed ? 'initial' : 'pointer')};
  user-select: none;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
  width: ${({ theme }) => theme.spacing(20)}px;
  height: ${({ theme }) => theme.spacing(7)}px;
  border-radius: ${({ theme }) => theme.spacing(0.75)}px;
  background-color: ${({ completed }) => (completed ? `${Colors.Secondary._100}` : `${Colors.Secondary._70}`)};
`;

export const StyledCircle = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${Colors.White._100};
  width: ${({ theme }) => theme.spacing(4)}px;
  height: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledInfoWrapper = styled(UniCardWrapper)`
  width: ${({ theme }) => theme.spacing(30)}px;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 50%;
  bottom: ${({ theme }) => theme.spacing(9)}px;
  transform: translateX(-50%);
  cursor: pointer;
  background-color: ${Colors.Secondary._100};
  padding: ${({ theme }) => theme.spacing(3, 1, 2)};
  z-index: 100;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    top: calc(100% + 4px);
    left: 50%;
    bottom: auto;
    transform: translateX(-56.5%);
  }
`;

export const StyledList = styled.ul`
  list-style: outside disc;
  margin: ${({ theme }) => theme.spacing(0, 2.5, 2.5, 2.5)};
  padding: 0;
`;

export const StyledListItem = styled.li`
  color: ${Colors.White._100};
`;

export const MovingIconWrapper = styled.div`
  position: static;
  background-color: white;
  border-radius: 50%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute;
    right: -12px;
    top: -12px;
  }
`;

export const StyledStepWrapper = styled.div`
  position: relative;
  overflow: visible;
`;
