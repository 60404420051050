import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export default function useServiceOptions(config?: QueryConfig<Error>) {
  //   const agencyId = useAuth((state) => state.profile.agentProfile?.agency?.id);
  const { client } = useClient();

  return useQuery(
    [CacheKeys.ServiceOptions.All],
    async (_key: string) => client!.ServiceOptionApi_getServiceOptions().then(({ data }) => data),
    {},
  );
}
