import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

export const useStudentNames = () => {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Students.Names],
    async (_key: string) => {
      const { data } = await client!.StudentApi_getStudentNames();
      return data;
    },
    {
      onError(error: AxiosError) {
        console.error(error);
      }
    },
  );
};

export default useStudentNames;
