import { Box, MenuItem, Select } from '@material-ui/core';
import { UniAutocomplete, UniPageTitle, UniTextField, UniTypography } from 'components';
import { currencyIso } from 'constants/currencyIso';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { lengthOfStudyOptions } from '../../../../constants';

interface Props {
  register: any;
  control: any;
}

const Step3: React.FC<Props> = ({ register, control }) => {
  const { t } = useTranslation('createProgram');

  return (
    <>
      <UniPageTitle title={t('texts.step3title')} />
      <Box mb={3} />
      <Controller
        name='tuitionFee'
        control={control}
        render={({ value, onChange }) => (
          <>
            <UniTextField
              fullWidth
              label={t('labels.tuitionFee')}
              placeholder={t('placeholders.tuitionFee')}
              type='number'
              value={value?.currency?.amount}
              onChange={e => onChange({ ...value, currency: { ...value?.currency, amount: parseInt(e.target.value) } })}
              endComponent={
                <Select
                  disableUnderline
                  value={value?.currency?.currencyIso}
                  renderValue={(selected: any) => {
                    if (!selected) {
                      return (
                        <UniTypography variant='body2' color='typographyGray100'>
                          {t('placeholders.unit')}
                        </UniTypography>
                      );
                    }

                    return (
                      <UniTypography variant='body2' color='typographyGray100'>
                        {currencyIso.find(item => item?.value === selected)?.text}
                      </UniTypography>
                    );
                  }}
                  displayEmpty
                  onChange={e => onChange({ ...value, currency: { ...value.currency, currencyIso: e.target.value } })}
                >
                  {currencyIso.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <UniTypography variant='body2' color='typographyGray100'>
                        {item.text}
                      </UniTypography>
                    </MenuItem>
                  ))}
                </Select>
              }
            />
            <UniAutocomplete
              fullWidth
              value={lengthOfStudyOptions.find(item => item.value === value?.lecturePeriod)}
              handleChange={(e: any, item: any) => onChange({ ...value, lecturePeriod: item.value })}
              getOptionLabel={item => item.label}
              label={t('labels.tuitionFeePeriod')}
              placeholder={t('placeholders.tuitionFeePeriod')}
              options={lengthOfStudyOptions}
            />
          </>
        )}
      />
      <Box py={3} />
      <Controller
        name='applicationFee'
        control={control}
        render={({ value, onChange }) => (
          <>
            <UniTextField
              fullWidth
              label={t('labels.applicationFee')}
              placeholder={t('placeholders.applicationFee')}
              type='number'
              value={value?.currency?.amount}
              onChange={e => onChange({ ...value, currency: { ...value?.currency, amount: parseInt(e.target.value) } })}
              endComponent={
                <Select
                  disableUnderline
                  value={value?.currency?.currencyIso}
                  renderValue={(selected: any) => {
                    if (!selected) {
                      return (
                        <UniTypography variant='body2' color='typographyGray100'>
                          {t('placeholders.unit')}
                        </UniTypography>
                      );
                    }

                    return (
                      <UniTypography variant='body2' color='typographyGray100'>
                        {currencyIso.find(item => item?.value === selected)?.text}
                      </UniTypography>
                    );
                  }}
                  displayEmpty
                  onChange={e => onChange({ ...value, currency: { ...value.currency, currencyIso: e.target.value } })}
                >
                  {currencyIso.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <UniTypography variant='body2' color='typographyGray100'>
                        {item.text}
                      </UniTypography>
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </>
        )}
      />
    </>
  );
};

export default Step3;
