import { Box } from '@material-ui/core';
import { UniAvatar, UniNavigationTab, UniTypography } from 'components';
import { Navigation } from './UniCountryHeaderNavigation';
import { useBreakpoints } from 'hooks';
import { useHistory } from 'react-router';
import { StyledGradientWrapper, StyledWrapper, StyledInnerWrapper, StyledHeader, StyledTabs } from './UniCountryHeader.style';

export interface TabProps {
  label: string;
  path: string;
  disabled?: boolean;
}

interface Props {
  title: string;
  tabs?: TabProps[];
  profileImageSrc?: string;
  thirdBtnLink?: string;
  thirdBtnLabel?: string;
  fourthBtnLabel?: string;
  iconSrc?: string;
}

export const UniCountryHeader = ({ title, tabs, profileImageSrc, thirdBtnLink = '', thirdBtnLabel, fourthBtnLabel, iconSrc }: Props) => {
  const { isMobile } = useBreakpoints();
  const history = useHistory();
  const { pathname, search } = history.location;
  const handleChange = (event: any, value: string) => {
    history.replace(value + search);
  };

  return (
    <>
      <StyledGradientWrapper>
        <Box width='100%'>
          <StyledHeader mobile={isMobile}>
            <Box display='flex' style={{ gap: '16px' }}>
              <Box display='flex' justifyContent='center' style={{ backgroundColor: '#a891ae', padding: '0.6rem', borderRadius: '15px' }}>
                <UniAvatar online={false} size='M' src={profileImageSrc} />
              </Box>
              <Box display='grid' alignItems='center' justifyItems={isMobile && 'start'}>
                <UniTypography color='white100' weight='bold' variant='header1'>
                  {title}
                </UniTypography>
                <UniTypography color='white100' variant='header2' weight='medium'>
                  all you need to know:
                </UniTypography>
              </Box>
            </Box>
            <Navigation thirdBtnLink={thirdBtnLink} thirdBtnLabel={thirdBtnLabel} fourthBtnLabel={fourthBtnLabel} iconSrc={iconSrc} />
          </StyledHeader>
        </Box>
      </StyledGradientWrapper>
      <StyledWrapper>
        <StyledInnerWrapper>
          <StyledTabs value={pathname} onChange={handleChange} indicatorColor='primary' textColor='primary'>
            {tabs?.map((item: TabProps, index: any) => (
              <UniNavigationTab key={index} label={item.label} value={item.path} disabled={!!item.disabled} />
            ))}
          </StyledTabs>
        </StyledInnerWrapper>
      </StyledWrapper>
    </>
  );
};
