import { Box } from '@material-ui/core';
import { ReactComponent as EditIcon } from 'assets/svg/Icons/Edit.svg';
import { ReactComponent as MenuDots } from 'assets/svg/Icons/MenuDots.svg';
import { Components } from 'client/UniClient';
import { AnimatedTabPanel, UniBounceLoader, UniHeader, UniHerosection, UniIconWrapper, UniLink, UniPage, UniStatus } from 'components';
import StudentDocuments from 'components/candidateDocuments/StudentDocuments';
import StudentEducation from 'components/candidateEducation/StudentEducation';
import StudentPersonalInfo from 'components/candidatePersonalInfo/StudentPersonalInfo';
import UniDropdown from 'components/_common/uniDropdown/UniDropdown';
import AgencyStudentApplications from 'components/_templates/agencyStudentApplications/AgencyStudentApplications';
import { countries } from 'constants/countries';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { useAuth, useDrawers, useSnackbar } from 'store';
import { getMetaRole } from 'utils';
import { useCandidate, useFile, useUploadStudentProfileImage } from '../../../api';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location24x24.svg';
import { ReactComponent as MailIcon } from '../../../assets/svg/Icons/Mail.svg';
import { ReactComponent as AgentIcon } from '../../../assets/svg/Icons/Nav/Candidates.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/svg/Icons/PhoneNumber.svg';
import { BrowserRoutes } from '../../../constants';
import { useBreakpoints } from '../../../hooks';
import StudentActivityPlan from './_tabs/StudentActivityPlan';
import StudentOrders from './_tabs/StudentOrders';

export type AgencyCandidateProfileParams = {
  studentId: string;
  tab: 'info' | 'education' | 'documents' | 'orders';
};

const AgencyCandidateProfile: React.FC = () => {
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const { studentId } = useParams<AgencyCandidateProfileParams>();
  const { openDrawer } = useDrawers();
  const { data: candidate, status: candidateStatus, error: candidateError } = useCandidate({ studentId: studentId });
  const location = useLocation();
  const { t } = useTranslation('studentProfile');
  const { isMobile } = useBreakpoints();
  const [uploadStudentProfileImage] = useUploadStudentProfileImage();
  const { openSnackbar } = useSnackbar();
  const { role, agentProfile } = useAuth(state => state.profile);
  const [profileImageSrc, setProfileImageSrc] = useState<string | undefined>();
  const { isAgency } = getMetaRole(role);

  const { data } = useFile({ id: candidate?.studentProfile?.passportDocument?.id ? candidate.studentProfile?.passportDocument?.id : '' });
  const country = countries.filter(country => country.code === candidate?.studentProfile?.address?.country).map(item => item.name)[0];
  const phoneNumber = candidate?.phone ? (candidate?.phonePrefix ? candidate?.phonePrefix + candidate?.phone : candidate?.phone) : '-';
  const initialImage = useFile({ id: candidate?.profileImageFile?.id || '' }, { enabled: !!candidate?.profileImageFile }).data?.url;
  const agentName = candidate?.studentProfile.agent
    ? `${candidate?.studentProfile.agent.firstName || ''} ${candidate?.studentProfile.agent.firstName || ''}`
    : '-';

  const uploadHandler = (file?: File) => {
    const profileImage = new FormData();
    profileImage.append('file', file!);
    profileImage.append('documentType', 'PROFILE_IMAGE');
    uploadStudentProfileImage(
      { image: profileImage, studentId: studentId },
      {
        onSuccess: response => {
          setProfileImageSrc(useFile({ id: response.data.id }, { enabled: !!response }).data?.url);
          openSnackbar('profile-image-uploaded');
        },
      },
    );
  };

  useEffect(() => {
    setProfileImageSrc(initialImage);
  }, [initialImage]);

  if (candidateError?.response && candidateError.response.status === 403) {
    history.push(BrowserRoutes.Agent.Default);
  }

  if (!candidate || candidateStatus === 'loading') {
    return (
      <UniPage>
        <UniBounceLoader />
      </UniPage>
    );
  }

  const profilePath = BrowserRoutes.Agent.Student.Personal(studentId);
  const applicationsPath = BrowserRoutes.Agent.Student.Applications(studentId);
  const educationPath = BrowserRoutes.Agent.Student.Education(studentId);
  const documentsPath = BrowserRoutes.Agent.Student.Documents(studentId);
  const ordersPath = BrowserRoutes.Agent.Student.Orders(studentId);
  const activitiesPath = BrowserRoutes.Agent.Student.ActivityPlan(studentId);

  const currentTabIndex = location.pathname.includes('applications')
    ? 0
    : location.pathname.includes('personal')
    ? 1
    : location.pathname.includes('education')
    ? 2
    : 3;

  return (
    <>
      <UniHeader>
        <UniHerosection
          title={`${candidate?.firstName + ' ' + candidate?.lastName}`}
          universityName={`${candidate?.firstName + ' ' + candidate?.lastName}`}
          headerVariant='avatarTitle'
          profileImageSrc={profileImageSrc}
          onProfileImageUpload={uploadHandler}
          Actions={
            <Box display='flex' alignItems='center' justifyContent='flex-end'>
              <Box
                style={{ cursor: 'pointer' }}
                onClick={() => openDrawer('update-candidate-status', { candidate: candidate as Components.Schemas.UserResponse })}
              >
                <UniStatus
                  candidate={candidate}
                  RightIcon={
                    <UniIconWrapper size='main' color='typographyGray100'>
                      <EditIcon />
                    </UniIconWrapper>
                  }
                />
              </Box>
              <Box ml={2} />
              <UniDropdown
                handleOpen={() => setOpenMenu(true)}
                handleClose={() => setOpenMenu(false)}
                open={openMenu}
                ActionButton={
                  <UniIconWrapper color='typographyGray100'>
                    <MenuDots />
                  </UniIconWrapper>
                }
              >
                <UniLink
                  size='main'
                  label='Notes →'
                  fullWidth
                  onClick={() => {
                    setOpenMenu(false);
                    openDrawer('notes', { size: 'main', studentId });
                  }}
                />

                <UniLink
                  size='main'
                  label='Resend invitation →'
                  fullWidth
                  onClick={() => {
                    setOpenMenu(false);
                    openDrawer('resend-candidate', { size: 'main', candidate: candidate as Components.Schemas.UserResponse });
                  }}
                />

                {isAgency && (
                  <UniLink
                    size='main'
                    label='Change agent'
                    fullWidth
                    onClick={() => {
                      setOpenMenu(false);
                      openDrawer('assign-agent', { size: 'main', candidate: candidate as Components.Schemas.UserResponse });
                    }}
                  />
                )}
              </UniDropdown>
            </Box>
          }
          metrics={
            isMobile
              ? []
              : [
                  {
                    label: t('labels.header.country'),
                    value: country || '-',
                    Icon: (
                      <UniIconWrapper color='secondary100'>
                        <LocationIcon />
                      </UniIconWrapper>
                    ),
                  },
                  {
                    label: t('labels.header.phone_number'),
                    value: phoneNumber,
                    Icon: (
                      <UniIconWrapper color='secondary100'>
                        <PhoneIcon />
                      </UniIconWrapper>
                    ),
                  },
                  {
                    label: t('labels.header.email'),
                    value: candidate?.email || '-',
                    Icon: (
                      <UniIconWrapper color='secondary100'>
                        <MailIcon />
                      </UniIconWrapper>
                    ),
                  },
                  {
                    label: t('labels.header.agent'),
                    value: agentName,
                    Icon: (
                      <UniIconWrapper color='secondary100'>
                        <AgentIcon />
                      </UniIconWrapper>
                    ),
                    link: BrowserRoutes.Agent.Agent(agentProfile?.agency.id, candidate.studentProfile.agent.id),
                  },
                ]
          }
          tabs={[
            {
              label: t('tabs.applications'),
              path: applicationsPath,
            },
            {
              label: t('tabs.profile_info'),
              path: profilePath,
            },
            {
              label: t('tabs.education'),
              path: educationPath,
            },
            {
              label: t('tabs.documents'),
              path: documentsPath,
            },
            {
              label: t('tabs.orders'),
              path: ordersPath,
            },
            {
              label: t('tabs.activity_plan'),
              path: activitiesPath,
            },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route exact path={applicationsPath}>
              <AnimatedTabPanel Content={<AgencyStudentApplications />} index={0} currentItem={currentTabIndex} absoluteDirection={1} />
            </Route>
            <Route path={profilePath}>
              <AnimatedTabPanel
                Content={<StudentPersonalInfo student={candidate as Components.Schemas.UserResponse} />}
                index={1}
                currentItem={currentTabIndex}
                absoluteDirection={1}
              />
            </Route>
            <Route path={educationPath}>
              <AnimatedTabPanel
                Content={<StudentEducation candidate={candidate} editable />}
                index={2}
                currentItem={currentTabIndex}
                absoluteDirection={1}
              />
            </Route>
            <Route path={documentsPath}>
              <AnimatedTabPanel
                Content={<StudentDocuments candidate={candidate} editable />}
                index={3}
                currentItem={currentTabIndex}
                absoluteDirection={1}
              />
            </Route>
            <Route path={ordersPath}>
              <AnimatedTabPanel
                Content={<StudentOrders student={candidate} />}
                index={3}
                currentItem={currentTabIndex}
                absoluteDirection={1}
              />
            </Route>
            <Route path={activitiesPath}>
              <AnimatedTabPanel
                Content={<StudentActivityPlan student={candidate} />}
                index={3}
                currentItem={currentTabIndex}
                absoluteDirection={1}
              />
            </Route>
            <Route>
              <Redirect to={applicationsPath} />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default AgencyCandidateProfile;
