import React from 'react';
import ContinentTile, { ContinentTileProps } from './ContinentTile';
import { StyledContinentSelect } from './ContinentSelect.style';

interface Props {
  continentList: ContinentTileProps[];
}

export const ContinentSelect: React.FC<Props> = ({ continentList }) => {
  return (
    <StyledContinentSelect>
      {continentList.map(continentProps => (
        <ContinentTile {...continentProps} />
      ))}
    </StyledContinentSelect>
  );
};
