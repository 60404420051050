import { Components } from 'client/UniClient';
import { useState } from 'react';
import ChangeEmailForm from './change-email/ChangeEmailForm';
import ConfirmationEmailSent from './change-email/ConfirmationEmailSent';
import ChangePasswordForm from './change-password/ChangePasswordForm';
import NotificationEmailSent from './change-password/NotificationEmailSent';
import ContactFormDrawer from './contact-form/ContactFormDrawer';

interface Props {
  student?: Components.Schemas.UserResponse;
  onboard: boolean;
}

const ContactDetailsDrawer: React.FC<Props> = ({ student, onboard, children }) => {
  const [drawer, setDrawer] = useState('contact-form');

  const drawerHandler = (type: string) => {
    setDrawer(type);
  };

  switch (drawer) {
    case 'change-email':
      return <ChangeEmailForm changeDrawer={drawerHandler} />;
    case 'confirmation-email-sent':
      return <ConfirmationEmailSent changeDrawer={drawerHandler} />;
    case 'change-password':
      return <ChangePasswordForm changeDrawer={drawerHandler} />;
    case 'notification-email-sent':
      return <NotificationEmailSent changeDrawer={drawerHandler} />;
    default:
      //AKA 'contact-form' case
      return <ContactFormDrawer student={student} onboard={onboard} changeDrawer={drawerHandler} />;
  }
};

export default ContactDetailsDrawer;
