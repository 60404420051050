import { Box } from '@material-ui/core';
import useDeleteAchievement from 'api/achievements/useDeleteAchievement';
import useDeleteTodo from 'api/todos/useDeleteTodo';
import { Components } from 'client/UniClient';
import { UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers, useSnackbar } from 'store';

interface Props {
  todo?: Components.Schemas.ToDoDto;
  achievement?: Components.Schemas.AchievementDto;
}

const DeleteActivityItem: React.FC<Props> = ({ achievement, todo }) => {
  const activityId = achievement?.id || todo?.id;
  const { t: tButtons } = useTranslation('buttons');
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const [deleteAchievement, { isLoading: deleteAchievementIsLoading }] = useDeleteAchievement();
  const [deleteTodo, { isLoading: deleteTodoIsLoading }] = useDeleteTodo();
  const isLoading = deleteAchievementIsLoading || deleteTodoIsLoading;
  const title = achievement?.title || todo?.title;
  const type = !!achievement ? 'ACHIEVEMENT' : 'TODO';

  const onSuccess = () => {
    openSnackbar('delete-activity');
    closeDrawer();
  };

  const onDeleteActivity = () => {
    if (!activityId) return;
    if (type === 'TODO') {
      deleteTodo(
        {
          toDoId: activityId,
        },
        {
          onSuccess,
        },
      );
    }
    if (type === 'ACHIEVEMENT') {
      deleteAchievement(
        {
          achievementId: activityId,
        },
        {
          onSuccess,
        },
      );
    }
  };

  return (
    <>
      <UniModalDrawerContent>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          You are about to delete the action item:
        </UniTypography>
        <Box mb={1.25} />
        <UniTypography variant='body2' color='typographyGray100' weight='semi-bold'>
          {title}
        </UniTypography>
        <Box mb={1.25} />
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          Are you sure you want to delete it?
        </UniTypography>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: closeDrawer, label: tButtons('cancel') }}
        PrimaryButton={{
          onClick: onDeleteActivity,
          label: tButtons('yes_delete'),
          loading: isLoading,
        }}
      />
    </>
  );
};

export default DeleteActivityItem;
