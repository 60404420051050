import { AxiosError } from 'axios';
import { useMutation, queryCache } from 'react-query';
import { Components } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Payload {
  params: {
    agencyId: string;
    studentId: string;
    extracurricularItemId: string;
  };
}

const useDeleteExtracurricularHistoryItem = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AgencyApi_deleteExtracurricularHistoryItem(payload.params).then(response => {
        return client!.UserApi_getMyUser();
      });
    },
    {
      onSuccess: async response => {
        queryCache.invalidateQueries(CacheKeys.Profile.Extended);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default useDeleteExtracurricularHistoryItem;
