import { UniAvatar } from 'components';
import { AvatarGroupWrapper } from './UniAvatarGroup.style';

interface Props {
  avatars: {
    label?: string;
    src?: string;
  }[];
}

const UniAvatarGroup: React.FC<Props> = ({ children, avatars, ...rest }) => {
  return (
    <AvatarGroupWrapper length={avatars.length}>
      {avatars.slice(0, 3).map(avatar => (
        <UniAvatar label={avatar.label} src={avatar.src} size='S' />
      ))}
      {avatars.length > 3 && <UniAvatar customLabel={`+${avatars.length - 3}`} size='S' />}
    </AvatarGroupWrapper>
  );
};

export default UniAvatarGroup;
