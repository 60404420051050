import { Box } from '@material-ui/core';
import { AgentsSort } from 'components/agentsList/AgentsList';
import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAgents } from '../../../api';
import { ReactComponent as AddIcon } from '../../../assets/svg/Icons/Add.svg';
import { Components } from '../../../client/UniClient';
import { AgentsList, StickyListFilterBar, UniButton, UniIconWrapper, UniPage, UniTypography } from '../../../components';
import AgentsListItem from '../../../components/agentsList/AgentsListItem';
import { BrowserRoutes } from '../../../constants';
import { useErrorHandler } from '../../../hooks';
import { useAuth, useDrawers } from '../../../store';
import { ListContainer } from './Team.style';

const AgencyTeam = () => {
  const history = useHistory();
  const { profile } = useAuth();
  const [selectedAgents, setSelectedAgents] = useState<Components.Schemas.UserDto[]>([]);
  const [order, setOrder] = useState<OrderType>();
  const [sort, setSort] = useState<AgentsSort>();
  const { openDrawer } = useDrawers();

  const {
    data: agents,
    status: agentsStatus,
    fetchMore,
    canFetchMore,
    error: agentsError,
  } = useAgents({
    order: order,
    sort: sort,
  });
  const fetchAgentsError = useErrorHandler(agentsError!);
  const agentsLoading = agentsStatus === 'loading';
  const agentsFlat = agents?.map(agentPage => agentPage.results).flat() || [];
  const agentsTotal = agents ? agents[0].total : 0;

  const selectAgent = (agent: Partial<Components.Schemas.UserDto>) => {
    setSelectedAgents(prevSelectedAgents => {
      const index = prevSelectedAgents.findIndex(item => item.id === agent.id);
      if (index === -1) {
        return [...prevSelectedAgents, agent as Components.Schemas.UserDto];
      } else {
        return prevSelectedAgents.filter(item => item.id !== agent.id);
      }
    });
  };

  const selectAllAgents = (value: boolean) => {
    if (value) {
      setSelectedAgents(agentsFlat);
    } else {
      setSelectedAgents([]);
    }
  };

  const handleSort = (newSortParam: AgentsSort) => {
    const sortType = newSortParam === sort ? (order === 'ASC' ? 'DESC' : 'ASC') : 'ASC';

    setOrder(sortType);
    setSort(newSortParam);
  };

  const openFiltersAgentsDrawer = () => openDrawer('filter-agents', { size: 'small' });

  const openInviteAgentDrawer = () => openDrawer('invite-agent', { size: 'main' });

  return (
    <UniPage>
      <ListContainer>
        <StickyListFilterBar
          listTitle='Agents'
          // onClickFilter={openFiltersAgentsDrawer}
          ActionButton={
            <Box>
              <UniButton
                onClick={openInviteAgentDrawer}
                fullWidth={false}
                color='primaryFade'
                startIcon={
                  <UniIconWrapper color='primary100'>
                    <AddIcon />
                  </UniIconWrapper>
                }
              >
                Add New Agent
              </UniButton>
            </Box>
          }
        />
        {fetchAgentsError.message && (
          <Box>
            <UniTypography color='stateAlert100'>{fetchAgentsError.message}</UniTypography>
          </Box>
        )}
        <AgentsList
          fetchMore={fetchMore}
          hasMore={!!canFetchMore}
          selectAllAgents={selectAllAgents}
          selectedAgents={selectedAgents.length}
          length={agentsTotal}
          isLoading={agentsLoading}
          sortType={sort}
          order={order}
          onSort={handleSort}
        >
          {agentsFlat?.map((agent: any, index: any) => (
            <React.Fragment key={index}>
              <AgentsListItem
                selected={selectedAgents.includes(agent)}
                selectAgent={selectAgent}
                agent={agent}
                onClick={(id: string) => {
                  history.push(BrowserRoutes.Agent.Agent(profile.id || '', agent.id || ''));
                }}
              />
            </React.Fragment>
          ))}
        </AgentsList>
      </ListContainer>
    </UniPage>
  );
};

export default AgencyTeam;
