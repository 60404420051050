import { Document, Link, Page, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types/style';
import { useAuthUser, useExtendedProfile } from 'api';
import useAchievements from 'api/achievements/useAchievements';
import { levelOfStudyMap } from 'constants/programMaps';
import moment from 'moment';
import { useMemo } from 'react';
import { humanReadableWord } from 'utils';

interface ResumeStyles {
  [key: string]: Style;
}

const styles: ResumeStyles = {
  leftSide: { backgroundColor: 'rgb(73, 39, 92)', color: 'white', flex: 1, padding: 8 },
  rightSide: { flex: 3, padding: 8 },
  title: { fontSize: 20, marginBottom: 12 },
  header: { fontSize: 16, marginBottom: 6 },
  text: { fontSize: 12, marginBottom: 4 },
  mailLink: { color: 'white', textDecoration: 'none' },
  achievementsTitle: { fontSize: 16, marginBottom: 6, marginTop: 6 },
  achievementDescription: { fontSize: 12, marginBottom: 8 },
};

const ResumeTemplate: React.FC = () => {
  const { data: user } = useAuthUser();
  const studentId = user?.id || '';
  const { data: achievements } = useAchievements({ studentId }, { enabled: !!studentId });
  const { data: extendedProfile } = useExtendedProfile({ id: studentId }, { enabled: !!studentId });
  const education = extendedProfile?.educationHistory || [];
  const achievementsFlat = useMemo(() => achievements?.map(programPage => programPage.results).flat() || [], [achievements]);

  return (
    <Document>
      <Page size='A4' style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={styles.leftSide}>
          <Text style={styles.header}>Contact:</Text>
          <Text style={styles.text}>
            {/* The component doesn't support href prop */
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link style={styles.mailLink} src={`mailto:${user?.email}`}>
              {user?.email}
            </Link>
          </Text>
          <Text style={styles.text}>{`(${user?.phonePrefix}) ${user?.phone}`}</Text>

          {/* repeat these two tags for more info on the left side */}
          <Text style={styles.header}>Nationality:</Text>
          <Text style={styles.text}>{user?.studentProfile.nationality}</Text>
        </View>

        <View style={styles.rightSide}>
          <Text style={styles.title}>{`${user?.firstName} ${user?.lastName}`}</Text>

          {education && (
            <>
              <Text style={styles.header}>Education:</Text>
              {education.map(educationItem => (
                <Text style={styles.text}>
                  {`${levelOfStudyMap[educationItem.levelOfStudy]}, ${
                    educationItem.didGraduate
                      ? `will graduate on ${moment(educationItem.graduationDate).format('MMMM d, YYYY')}`
                      : `graduated on ${moment(educationItem.graduationDate).format('MMMM d, YYYY')}`
                  }`}
                </Text>
              ))}
            </>
          )}

          {achievementsFlat?.some(achievement => achievement.status === 'VALIDATED') && (
            <>
              <Text style={styles.achievementsTitle}>Achievements:</Text>
              {achievementsFlat.map(achievement => {
                if (achievement.status === 'VALIDATED') {
                  return (
                    <>
                      <Text style={styles.text}>{`${achievement.title} - ${humanReadableWord(achievement.category)}`}</Text>
                      <Text style={styles.text}>{moment(achievement.completedAt).format('MMMM d, YYYY')}</Text>
                      <Text style={styles.achievementDescription}>{achievement.description}</Text>
                    </>
                  );
                }
                return null;
              })}
            </>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default ResumeTemplate;
