import { Box, FormControl, Mark } from '@material-ui/core';
import React from 'react';
import { TComponentColors } from '../../../constants/componentColors';
import UniTypography from '../../_common/uniTypography/UniTypography';
import { StyledSlider } from './UniSlider.style';

type SliderProps = {
  handleOnChange: (value: number[]) => void;
  name?: string;
  label?: string;
  labelColor?: TComponentColors;
  fullWidth?: boolean;
  required?: boolean;
  helperText?: string;
  optional?: boolean;
  placeholder?: string;
  withBorder?: boolean;
  marks: Mark[];
  defaultValue?: number[];
};

function valuetext(value: number) {
  return `${value}°C`;
}

const UniSlider: React.FC<SliderProps> = ({
  handleOnChange,
  marks,
  name,
  label,
  labelColor = 'typographyBlack100',
  required,
  helperText = '',
  fullWidth,
  withBorder = false,
  optional = false,
  placeholder,
  defaultValue,
  ...props
}) => {
  // const [value, setValue] = React.useState<number[]>([
  //   marks[0].value,
  //   marks[1].value,
  // ]);

  const defaultState = defaultValue || [marks[0].value, marks[1].value];
  const [value, setValue] = React.useState<number[]>(defaultState);

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number[]);
    handleOnChange(newValue as number[]);
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <UniTypography color={labelColor} variant='subheader'>
        {label}
      </UniTypography>
      <Box mx={2}>
        <StyledSlider
          min={marks[0].value}
          max={marks[1].value}
          defaultValue={[marks[0].value, marks[1].value]}
          aria-required={required}
          withBorder={withBorder}
          value={value}
          onChange={handleChange}
          valueLabelDisplay='auto'
          aria-labelledby='range-slider'
          getAriaValueText={valuetext}
          marks={marks}
        />
      </Box>
    </FormControl>
  );
};

export default UniSlider;
