import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import React, { ReactElement } from 'react';
import { StyledAddEducationDrawerItem } from './AddEducationDrawer.style';

interface Props {
  active: boolean;
  currentItem: number;
  Content: React.ReactNode;
  direction: number;
}

export default function AddEducationDrawerItem({ active, direction, currentItem, Content }: Props): ReactElement {
  const animateVariant = {
    initial: {
      opacity: 0,
      x: direction * 200,
    },
    animate: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: -direction * 200,
    },
  };

  return active ? (
    <StyledAddEducationDrawerItem key={currentItem} {...animateVariant}>
      {Content}
    </StyledAddEducationDrawerItem>
  ) : (
    <></>
  );
}
