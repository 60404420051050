import React, { useState } from 'react';
import { ReactComponent as ChevronDown } from '../../../assets/svg/Icons/Arrow/ChevronDown.svg';
import { UniIconWrapper, UniTypography } from 'components';
import { Box, ClickAwayListener } from '@material-ui/core';
import { Colors } from 'constants/uiConfig';
import styled from 'styled-components';

const StyledContentWrapper = styled.div`
  background-color: ${Colors.White._100};
  border: 2px solid ${Colors.Typography.Gray_30};
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  z-index: 10;
  width: 100%;
  max-width: 600px;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    padding: ${({ theme }) => theme.spacing(1)}px;
  }

  // @media (max-width: 992px) {
  //   width: 32rem;
  //   padding: ${({ theme }) => theme.spacing(0.5)}px;
  // }
  //
  // @media (max-width: 768px) {
  //   width: 100%;
  //   padding: ${({ theme }) => theme.spacing(0.5)}px;
  // }
`;

const StyledLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: ${Colors.Secondary._100};
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  width: 100%;
  max-width: 600px;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    padding: ${({ theme }) => theme.spacing(1)}px;
  }
  //
  // @media (max-width: 992px) {
  //   width: 100%;
  //   max-width: 100%;
  //   padding: ${({ theme }) => theme.spacing(0.5)}px;
  // }

  // @media (max-width: 768px) {
  //   width: 100%;
  //   max-width: 100%;
  //   padding: ${({ theme }) => theme.spacing(0.5)}px;
  // }
`;

interface Props {
  label: string;
  startIcon: React.ReactNode;
  children: React.ReactNode;
}

export const UniAboutDropdown: React.FC<Props> = ({ label, startIcon, children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    if (open) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  return (
    <Box style={{ cursor: 'pointer' }}>
      <StyledLabelWrapper onClick={handleClick}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <span style={{ padding: '5px 12px 0 5px' }}>{startIcon}</span>
          <UniTypography weight='regular' color='white100'>
            {label}
          </UniTypography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <UniIconWrapper color='white100' size='medium2'>
            <ChevronDown />
          </UniIconWrapper>
        </Box>
      </StyledLabelWrapper>
      {open && (
        <StyledContentWrapper>
          <ClickAwayListener onClickAway={handleClose}>
            <Box display='flex' flexDirection='column'>
              {children}
            </Box>
          </ClickAwayListener>
        </StyledContentWrapper>
      )}
    </Box>
  );
};
