import { AxiosError } from 'axios';
import { getBaseURL } from 'constants/browserRoutes';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Components } from '../../client/UniClient';
import { useAuth, useClient } from '../../store';

type Payload = Partial<Components.Schemas.UpdateStudentRequest>;

const useCompleteStudentOnboardingPatch = () => {
  const history = useHistory();
  const { client, session } = useClient();
  const { afterAuthPath, setAfterAuthPath } = useAuth();

  return useMutation(
    async (payload: Payload) =>
      client!.StudentApi_updateStudent(session?.user.id!, payload).then(() => {
        if (afterAuthPath) {
          history.push(afterAuthPath.replace(':base', getBaseURL(session?.user?.role)));
          setAfterAuthPath(undefined);
        }
        return client!.UserApi_getMyUser();
      }),
    {
      onSuccess: () => {
        // setSession(response.data);
        //
        // const { data } = await client!.UserApi_getMyUser();
        //
        // setProfile(data);
      },
      onError: (error: AxiosError) => {
        // history.push(BrowserRoutes.Default.InvalidToken);
      },
    },
  );
};
export default useCompleteStudentOnboardingPatch;
