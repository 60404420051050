import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/Close.svg';
import ComponentColors from '../../../constants/componentColors';

export const StyledTagItem = styled.div<{ color: 'grey' | 'green' | 'red' | 'primary' }>`
  position: relative;
  display: flex;
  height: ${({ theme }) => theme.spacing(4.25)}px;
  width: fit-content;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;

  *:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ color }) =>
      color === 'grey'
        ? ComponentColors['typographyGray10']
        : color === 'red'
        ? ComponentColors.stateAlert10
        : color === 'primary'
        ? ComponentColors.primary10
        : ComponentColors['secondary10']};
    color: ${({ color }) =>
      color === 'grey'
        ? ComponentColors['typographyGray70']
        : color === 'red'
        ? ComponentColors.stateAlert100
        : ComponentColors['secondary70']};
  }
`;

export const DisciplineItem = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spacing(5.625)}px;
  width: fit-content;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1.5, 2)};
  border-radius: 24px;
  border: 1px solid #848383;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  padding: 10px;
  margin-right: ${({ theme }) => theme.spacing(-2)}px;
  border-radius: 50%;
  cursor: pointer;
  width: 100%;
  height: 100%;

  &:hover {
    background-color: ${ComponentColors['otherHover05']};
  }

  path {
    fill: ${({ color }) => (color === 'grey' ? ComponentColors['typographyGray70'] : ComponentColors['secondary100'])};
  }
`;
