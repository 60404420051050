// import React from "react";
import styled from 'styled-components';
import { Colors } from '../../constants';

export const StyledFilters = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
`;

export const StyledFilterForm = styled.div`
  flex: 1;
  overflow-y: auto;
  background-color: ${Colors.Gray2._40};
  padding: ${({ theme }) => theme.spacing(2, 3)};
  padding-bottom: ${({ theme }) => theme.spacing(10)}px;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    display: none;
  }
`;
