import { Box } from '@material-ui/core';
import { UniButton, UniIconWrapper, UniPageTitle, UniTextField } from 'components';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/Icons/Close.svg';

interface Props {
  register: any;
  control: any;
}

const Step5: React.FC<Props> = ({ register, control }) => {
  const { t } = useTranslation('createProgram');
  const { fields, append, remove } = useFieldArray({
    name: 'requiredDocuments',
    control,
  });
  return (
    <>
      <UniPageTitle title={t('texts.step5title')} />
      <Box mb={3} />
      {fields.map((field, index) => {
        return (
          <>
            <UniTextField
              fullWidth
              label={t('labels.requiredDocumentsName')}
              placeholder={t('placeholders.requiredDocumentsName')}
              key={field.id}
              name={`requiredDocuments[${index}].name`}
              inputRef={register()}
              defaultValue={field.name}
              endAdornment={
                fields.length > index + 1 && (
                  <UniIconWrapper onClick={() => remove(index)}>
                    <CloseIcon />
                  </UniIconWrapper>
                )
              }
            />
            <Box mt={-5}>
              <UniTextField
                fullWidth
                placeholder={t('placeholders.requiredDocumentsDescription')}
                key={field.id}
                name={`requiredDocuments[${index}].description`}
                inputRef={register()}
                defaultValue={field.description}
              />
            </Box>
          </>
        );
      })}
      <Box mt={-2}>
        <UniButton variant='text' onClick={() => append({ name: '', description: '' })}>
          {t('buttons.addRequiredDocuments')}
        </UniButton>
      </Box>

      <Box py={3} />
    </>
  );
};

export default Step5;
