import { Paths } from 'client/UniClient';
import { useMutation } from 'react-query';
import { useClient } from '../../store';

const usePostRequestsUniversity = () => {
  const { client } = useClient();

  return useMutation(async (payload: Paths.UniversityApiRequestAdoptUniversity.RequestBody) => {
    return client!.UniversityApi_requestAdoptUniversity(undefined, payload);
  });
};

export default usePostRequestsUniversity;
