import { useEffect } from 'react';
import { useAuth } from 'store';
import usePermissions from 'store/permissions';

interface Props {}

const PermissionsProvider: React.FC<Props> = ({ children }) => {
  const { role } = useAuth(state => state.profile);
  const {} = usePermissions();

  useEffect(() => {}, [role]);

  return <>{children}</>;
};

export default PermissionsProvider;
