import { AxiosError } from 'axios';
import { UniversitiesFiltersOptions } from 'components/_drawers/filtersDrawer/_types/UniversitiesFilters';
import { countries } from 'constants/countries';
import { GraduationLevel, graduationLevelMap } from 'constants/levelOfStudy';
import { Subjects, subjectsMap } from 'constants/subjects';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys, languageOptions } from '../../constants';
import { useClient } from '../../store';
import { Nationality, OptionType } from '../../types';

const parseCountries = (filterCountries: string[]): OptionType[] =>
  countries.filter(item => filterCountries.includes(item.code)).map(item => ({ label: item.name, value: item.code }));

const parseLevelOfStudy = (filterGradutionLevel: GraduationLevel[]): OptionType[] =>
  filterGradutionLevel.map(item => ({ label: graduationLevelMap[item], value: item }));

const parseDiscipline = (filterDiscipline: Subjects[]): OptionType[] =>
  filterDiscipline.map(item => ({ label: subjectsMap[item], value: item }));

const parseLanguage = (filterLanguage: string[]): OptionType[] =>
  languageOptions.filter(item => filterLanguage.includes(item.code)).map(item => ({ label: item.name, value: item.code }));

type CitiesMap = { [index in Nationality]: OptionType[] };
type Cities = { country: Nationality; cities: string[] };
const parseCities = (filterCities: Cities[]): CitiesMap => {
  let citiesMap: any = {};
  filterCities.forEach(item => {
    if (item.country! in citiesMap) {
      citiesMap[item.country] = [];
    }
    citiesMap[item.country] = item.cities
      ?.filter((city, index) => index === item.cities.indexOf(city.trim()))
      .map(item => ({ label: item.trim(), value: item.trim() }));
  });
  return citiesMap;
};

export default function useUniversitiesFilters(config?: QueryConfig<Error>) {
  const { client } = useClient();

  return useQuery(
    CacheKeys.Filters.Universities,
    async (_key: string) => {
      let filters: Partial<UniversitiesFiltersOptions>;

      const { data } = await client!.SearchApi_getProgramFilters();
      const { data: languages } = await client!.SearchApi_getLanguageFilters();
      const { data: tags } = await client!.UniversityApi_getUniversityTags();

      filters = {
        country: parseCountries(data.cities.map(item => item.country)),
        city: Object.values(parseCities(data.cities)).flat(1),
        cityMap: parseCities(data.cities),
        levelOfStudy: parseLevelOfStudy(data.levelsOfStudy as GraduationLevel[]),
        subject: parseDiscipline(data.subjects),
        languageOfStudy: parseLanguage(languages),
        tags: tags.map(item => ({ label: item.name, value: item.id })),
      };

      return filters;
    },
    {
      refetchOnWindowFocus: false,

      onError(error: AxiosError) {},
    },
  );
}
