export const generateYears = ({ showFutureYears }: { showFutureYears: boolean }) => {
  let currentYear = new Date().getFullYear();
  let pastYearsArr = Array.from(new Array(50), (item, index) => currentYear - index);
  let futureYearsArr = Array.from(new Array(50), (item, index) => currentYear + index);

  if (showFutureYears) {
    let futureYears = futureYearsArr.map(item => ({ value: item.toString(), label: item.toString() }));
    return { years: futureYears };
  }

  let pastYears = pastYearsArr.map(item => ({ value: item.toString(), label: item.toString() }));
  return { years: pastYears };
};
