import { Box } from '@material-ui/core';
import usePostDocumentForStudent from 'api/files/usePostDocumentForStudent';
import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router';
import { getMetaRole } from 'utils';
import { UniAutocomplete, UniDragNDrop, UniFileViewer, UniTextWithIcon, UniTypography } from '..';
import { useDeleteProfileDocuments, usePostApplicationDocument } from '../../api';
import { Components } from '../../client/UniClient';
import { useErrorHandler } from '../../hooks';
import { useAuth } from '../../store';
import { theme } from '../../theme';

interface Props {
  program?: Components.Schemas.ProgramResponse;
  saveFileIds?: Function;
  studentProfile?: Components.Schemas.StudentProfileDto;
  multiple?: boolean;
}

interface DocumentProps {
  studentProfile?: Components.Schemas.StudentProfileDto;
  document?: Partial<Components.Schemas.ApplicationDocumentDto>;
  handleSaveFiles: Function;
  handleUploadDocuments?: (value: File[]) => void;
  handleUploadedDocuments?: (id: string[]) => void;
  multiple?: boolean;
}

export function RequiredDocument({
  document,
  handleSaveFiles,
  studentProfile,
  multiple = false,
  handleUploadDocuments,
  handleUploadedDocuments,
}: DocumentProps) {
  const { role } = useAuth(state => state.profile);
  const params = useParams<{ id?: string }>();
  const { isStudent } = getMetaRole(role!);
  const [upload, { error: uploadDocsError }] = usePostApplicationDocument();
  const [uploadForStudent, { error: uploadForStudentDocsError }] = usePostDocumentForStudent();
  const [deleteFile] = useDeleteProfileDocuments();

  const postError = useErrorHandler(uploadDocsError! || uploadForStudentDocsError!);

  return (
    <Box display='flex' style={{ gap: `${theme.spacing(10)}px` }} mt={4}>
      {document ? (
        document.file ? (
          <UniFileViewer label={document.name!} file={document.file} deleteFile={id => deleteFile(id)} />
        ) : (
          <>
            <Box>
              <UniDragNDrop
                style={{ width: '310px' }}
                label={document.name}
                accept='application/pdf, image/jpg, image/png'
                multiple={multiple}
                handleChange={(files: File[]) => {
                  if (files.length > 0) {
                    const formData = new FormData();
                    formData.append('label', document.name!);
                    formData.append('documentType', 'OTHER');
                    formData.append('file', files[0]);
                    if (isStudent) {
                      upload(formData, {
                        onSuccess({ data }) {
                          handleSaveFiles(document.id, data.id);
                        },
                      });
                    } else {
                      formData.append('studentId', params.id!);
                      uploadForStudent(formData, {
                        onSuccess({ data }) {
                          handleSaveFiles(document.id, data.id);
                        },
                      });
                    }
                  }
                }}
              >
                {/* {profile.studentProfile.d} */}
                <UniTypography color='typographyGray100'>Drag & Drop or</UniTypography>
                <UniTypography color='primary100'>Upload JPG, PDF or PNG file</UniTypography>
              </UniDragNDrop>
              {postError.message && (
                <Box>
                  <UniTypography color='stateAlert100'>{postError.message}</UniTypography>
                </Box>
              )}
            </Box>
            <Box mt={4}>
              <UniAutocomplete
                label='or select file from your documents'
                labelColor='typographyGray100'
                name='required-doc'
                multiple={multiple}
                placeholder='Select file from additional materials'
                handleChange={(event: any, value: Components.Schemas.FileDto) => {
                  value?.id && handleSaveFiles(document.id, value.id);
                }}
                options={studentProfile?.documents || []}
                getOptionLabel={option => option.label}
                width='400px'
              />
            </Box>
          </>
        )
      ) : (
        <>
          <Box>
            <UniDragNDrop
              style={{ width: '310px' }}
              label='Documents'
              multiple={multiple}
              handleChange={(files: File[]) => {
                if (files.length > 0) {
                  handleUploadDocuments && handleUploadDocuments(files);
                }
              }}
            >
              {/* {profile.studentProfile.d} */}
              <UniTypography color='typographyGray100'>Drag & Drop or</UniTypography>
              <UniTypography color='primary100'>Upload JPG, PDF or PNG file</UniTypography>
            </UniDragNDrop>
            {postError.message && (
              <Box>
                <UniTypography color='stateAlert100'>{postError.message}</UniTypography>
              </Box>
            )}
          </Box>
          <Box mt={4}>
            <UniAutocomplete
              label='or select file from your documents'
              labelColor='typographyGray100'
              name='required-doc'
              multiple={multiple}
              placeholder='Select file from additional materials'
              handleChange={(event: any, value: Components.Schemas.FileDto[]) => {
                value && handleUploadedDocuments && handleUploadedDocuments(value.map(item => item.id));
              }}
              options={studentProfile?.documents || []}
              getOptionLabel={option => option.label}
              width='400px'
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default function ProgramExtendedRequiredDocument({ program, saveFileIds, studentProfile }: Props): ReactElement {
  const [files, setFiles] = useState<Components.Schemas.AttachRequiredDocumentRequest[]>([]);

  const handleFileIds = (requiredDocId: string, fileId: string) => {
    setFiles(prevFiles => {
      const fileIndex = prevFiles.findIndex(item => item.requiredDocumentId === requiredDocId);
      if (fileIndex === -1) {
        const newFiles = [...prevFiles, { requiredDocumentId: requiredDocId, fileId: fileId }];
        saveFileIds && saveFileIds(newFiles);
        return newFiles;
      } else {
        let newFiles = prevFiles;
        newFiles[fileIndex] = { requiredDocumentId: requiredDocId, fileId: fileId };
        saveFileIds && saveFileIds(newFiles);
        return newFiles;
      }
    });
  };

  if (!program?.application?.status) {
    if (studentProfile) {
      return (
        <>
          {program?.requiredDocuments.map((item, index) => (
            <RequiredDocument document={item} handleSaveFiles={handleFileIds} studentProfile={studentProfile} key={index} />
          ))}
        </>
      );
    } else {
      return (
        <>
          {program?.requiredDocuments.map((item, index) => (
            <Box mb={2} key={index}>
              <UniTextWithIcon label={item.name} value={item.description || '-'} />
            </Box>
          ))}
        </>
      );
    }
  }

  return (
    <>
      {program.application?.applicationDocuments?.map(document => (
        <Box mb={2}>
          <UniTextWithIcon label={document.name} value={'Uploaded!'} />
        </Box>
      ))}
      {/* {program.application.applicationDocuments.map((document, index) => (
        <RequiredDocument document={document} handleSaveFiles={handleFileIds} />
      ))} */}
    </>
  );
}
