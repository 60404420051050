import { Box } from '@material-ui/core';
import { UniButton } from 'components';
import { default as React, ReactElement, useEffect, useState } from 'react';
import { StyledNavbar } from './StickySideNavigation.style';

export type SideNavigationItem = {
  ref: React.MutableRefObject<null>;
  label: string;
};

interface Props {
  navbarRef: React.MutableRefObject<null>;
  visibleSection: string;
  navbarItemsProps: Array<SideNavigationItem>;
}

export default function StickySideNavigation({ navbarRef, visibleSection, navbarItemsProps }: Props): ReactElement {
  const [clickedName, setClickedName] = useState(visibleSection);

  const scrollTo = (el: any) => {
    el.scrollIntoView({
      behavior: 'auto',
      block: 'center',
    });
  };

  useEffect(() => {
    setClickedName(visibleSection);
  }, [visibleSection]);

  return (
    <StyledNavbar ref={navbarRef}>
      {navbarItemsProps.map(item => (
        <Box mb={2}>
          <UniButton
            className={item.label}
            onClick={(e: any) => {
              scrollTo(item.ref.current);
              setClickedName(item.label);
            }}
            color={clickedName === item.label ? 'primaryFade' : 'neutral'}
            variant={clickedName === item.label ? 'main' : 'text'}
          >
            {item.label}
          </UniButton>
        </Box>
      ))}
    </StyledNavbar>
  );
}
