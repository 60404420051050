import styled from 'styled-components';

export const StyledPrepCenterProductWrapper = styled.div`
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-self: auto;
  align-items: stretch;
  padding: 60px;
  display: flex;
`;

export const StyledPrepCenterProductHeading = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: .5fr 1fr;
  justify-items: start;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

export const StyledPrepCenterProductTitleSection = styled.div`
  grid-area: span 1 / span 1 / span 1 / span 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
`;

export const StyledPrepCenterProductDetailsWrapper = styled.div`
`;

export const StyledPrepCenterProductViewLogo = styled.img`
  grid-area: span 1 / span 1 / span 1 / span 1;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
`;

export const StyledPrepCenterProductViewTitle = styled.div`
  color: #49275c;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
`;

export const StyledPrepCenterProductViewPrice = styled.div`
  color: #49275c;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

export const StyledPrepCenterProductDropdownLabel = styled.div`
  color: #373737;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;

export const StyledNavigationSection = styled.div`
  padding: 10px;
  display: inline-block;
  width: 240px;
  vertical-align: top;
`;

export const StyledContentSection = styled.div`
  padding: 10px;
  display: inline-block;
  width: auto;
  vertical-align: top;
  min-width: 400px;
`;

export const StyledDescription = styled.div`
  text-align: justify;
  font-family: Poppins, sans-serif;
  font-size: 16px;
`;

export const StyledCurriculumItem = styled.div`
  margin-right: 10px;
  padding: 15px 25px;
  background-color: rgba(73, 39, 92, .1);
  color: #4f235f;
  border-radius: 24px;
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
`;
