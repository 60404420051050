import { UniCardFooter } from 'components/_common/uniCardFooter/UniCardFooter';
import UniCardWrapper from 'components/_common/uniCardWrapper/UniCardWrapper';
import styled from 'styled-components';

export const ApplicationCardWrapper = styled(UniCardWrapper)<{
  variant: 'programmePage' | 'universityPage' | 'applicationsPage' | 'candidateApplicationsPage' | 'candidatesPage';
}>`
  overflow: hidden;
  position: relative;
  padding: ${({ theme }) => theme.spacing(4, 3, 0)};
  height: fit-content;
  max-height: ${({ theme }) => theme.spacing(41.25)}px;
`;

export const ApplicationCardFooter = styled(UniCardFooter)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

export const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)}px;
`;
