import { Box, Menu, MenuProps, withStyles } from '@material-ui/core';
import UniModalDrawerHeader from 'components/_common/uniModalDrawer/UniModalDrawerHeader';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { UniIconWrapper } from '../../..';
import { ReactComponent as NotificationIcon } from '../../../../assets/svg/Icons/NotificationBell.svg';
import { Shadows } from '../../../../constants';
import { useBreakpoints, useNotifications } from '../../../../hooks';
import { Profile } from '../../../../types';
import { ScrollableList } from './AuthDropdown.style';
import { Notification } from './Notification';
import { NotificationCount } from './NotificationCount';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '8px',
    boxShadow: Shadows.shadow2,
    width: '468px',
    overflowY: 'hidden',
    maxHeight: 'calc(100vh - 126px)',
  },
})((props: MenuProps) => (
  <Menu
    style={{ display: 'flex', flexDirection: 'column' }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const Notifications = (props: { profile: Partial<Profile>; onClick?: Function }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { isMobile } = useBreakpoints();
  const { allNotifications, count, markAsRead, clearCount } = useNotifications();
  const handleClickNotification = (id: string) => {
    setAnchorEl(null);
    markAsRead(id);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    clearCount();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UniIconWrapper
        size='medium'
        color='typographyGray100'
        withBackground
        hasBorderRadius
        onClick={e => (isMobile ? props.onClick && props.onClick() : handleClick(e))}
      >
        <NotificationIcon />
        <NotificationCount count={count} />
      </UniIconWrapper>
      {!isMobile && (
        <StyledMenu id='customized-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <UniModalDrawerHeader title='Notifications' onClose={() => handleClose()} />
          <ScrollableList>
            {allNotifications.length > 0 ? (
              <>
                {allNotifications.map((item, index) => (
                  <Notification isVisible={Boolean(anchorEl)} {...item} key={`${index}-new`} handleClick={handleClickNotification} />
                ))}
              </>
            ) : (
              <Box pt={3} pb={2} pl={3} pr={3}>
                <UniTypography color='typographyGray100' variant='body1' weight='regular'>
                  {'There are no notifications yet'}
                </UniTypography>
              </Box>
            )}
          </ScrollableList>
        </StyledMenu>
      )}
    </>
  );
};
