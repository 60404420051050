import styled from 'styled-components';
import Slider from 'react-slick';
import { Colors } from '../../../constants';

export const StyledCarouselBig = styled(Slider)<{ listLength?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  margin-top: ${({ listLength }) => (listLength === 1 ? '6%' : '1.5%')};
  padding-left: 28%;
  max-height: 78%;
  .slick-list {
    max-height: 100%;
    width: 100%;
    order: -1;
  }
`;
export const StyledCarouselSmall = styled(Slider)<{ hasOnClick?: boolean; isFullScreen?: boolean; listLength?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  margin-left: ${({ isFullScreen, listLength }) => isFullScreen && (listLength === 2 ? '26%' : '21%')};
  padding-left: ${({ theme }) => theme.spacing(7)}px;
  padding-right: ${({ theme }) => theme.spacing(5)}px;
  max-height: 20%;
  width: ${({ isFullScreen, listLength }) => isFullScreen && (listLength === 2 ? '55%' : '70%')};

  .slick-list {
    max-height: 100%;
    width: ${({ isFullScreen }) => (isFullScreen ? '80%' : '100%')};
    order: -1;
    margin: 0 -${({ theme }) => theme.spacing(1)}px;
  }
  .slick-slide {
    padding: 0 ${({ theme }) => theme.spacing(1)}px;
    cursor: ${({ hasOnClick }) => hasOnClick && 'pointer'};
  }
`;
export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  width: ${({ isOpen }) => (isOpen ? '100vw' : '100%')};
  height: ${({ isOpen }) => (isOpen ? '100vh' : '100%')};

  transition: 0.2s;

  background-color: transparent;
  z-index: 9999;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ isOpen }) => isOpen && `background-color: ${Colors.Gray1._100}99;`}
`;
