import React, { useState } from 'react';
import styled from 'styled-components';
import CountrySelect from 'components/countrySelect/CountrySelect';
import { TContinent } from 'components/uniWorldMap/UniWorldMap';
import { useParams } from 'react-router-dom';
import { UniPage, UniSearch } from '../../../components';
import UniContinentHeader from '../../../components/_common/uniContinentHeader/UniContinentHeader';
import { BrowserRoutes, countries } from '../../../constants';
import countriesWithRegions from '../../../constants/countriesWithRegions.json';
import { useProgramFilters } from '../../../api';

const continentNameMap: { [key in TContinent]: string } = {
  world: 'World',
  europe: 'Europe',
  asia: 'Asia',
  africa: 'Africa',
  'north-america': 'North America',
  'south-america': 'South America',
  oceania: 'Oceania',
};

const StyledCountriesWrapper = styled.div`
  width: 100%;
`;

interface Props {}

export const ContinentPage: React.FC<Props> = () => {
  const { data } = useProgramFilters();
  const countriesWithPrograms = data?.countries ?? [];

  const allCountries = countries
    .filter(country => country.name !== 'England')
    .filter(country => countriesWithPrograms.some(c => c.code === country.code))
    .map(country => {
      const countryDetails = countriesWithRegions.find(c => c['alpha-2'] === country.code);
      let region: string;
      if (countryDetails?.region === 'Americas') {
        if (countryDetails?.['sub-region'] === 'Northern America') {
          region = 'North America';
        } else if (countryDetails?.['intermediate-region'] === 'South America') {
          region = 'South America';
        } else {
          region = 'Caribbean';
        }
      } else {
        region = countryDetails?.region ?? '';
      }
      return {
        ...country,
        region,
      };
    });
  const { continent } =
    useParams<{
      continent: TContinent;
    }>();
  const continentPath = BrowserRoutes.Default.Continent(continent);
  const countriesInContinent = allCountries.filter(c => {
    return c.region === continentNameMap[continent];
  });

  const [countryFilter, setCountryFilter] = useState('');

  const filteredCountries = countriesInContinent.filter(
    c => countryFilter == null || c.name.toLowerCase().includes(countryFilter.toLowerCase()),
  );

  return (
    <>
      <UniContinentHeader
        title={continentNameMap[continent]}
        thirdBtnLabel={continentNameMap[continent]}
        thirdBtnLink={`${continentPath}`}
      />
      <UniPage>
        <UniSearch handleSearch={setCountryFilter} stayFocused oldSearchVal='' />
        <StyledCountriesWrapper>
          <CountrySelect countries={filteredCountries.map(c => ({ ...c, continent }))} />
        </StyledCountriesWrapper>
      </UniPage>
    </>
  );
};

export default ContinentPage;
