import useMyPrograms from 'api/university/useMyPrograms';
import { Components } from 'client/UniClient';
import { InfiniteWindowedGrid, UniProgramCard } from 'components';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { parseLevelOfStudy } from 'utils/programUtils';

interface Props {
  query?: string;
  id?: string;
}

const Programs: React.FC<Props> = ({ query, id }) => {
  const { queries } = useQueryStringParser<ProgramsFiltersQueries>();
  const { t } = useTranslation('programsList');
  const {
    data: programs,
    status: programsStatus,
    isFetching: isFetchingPrograms,
    fetchMore,
  } = useMyPrograms({
    query,
    filters: queries,
    universityId: id,
  });

  const programsFlat = useMemo(() => {
    return programs?.map((programPage: Components.Schemas.ProgramResultPageDto) => programPage.results).flat() || [];
  }, [programs]);
  const programsLoading = programsStatus === 'loading';
  const programsTotal = useMemo(() => (programs ? programs[0].total : 0), [programs]);
  const programsLeft = programsTotal - programsFlat?.length;

  return (
    <InfiniteWindowedGrid
      isFetching={isFetchingPrograms}
      emptyStateType='programs'
      fetchMore={fetchMore}
      isLoading={programsLoading}
      hasMore={programsLeft > 0}
      totalResults={programsTotal}
      remainingResults={programsLeft}
      sortOptions={[
        { value: 0, label: 'By price' },
        { value: 1, label: 'By name' },
      ]}
      results={programsFlat?.map((program: any, index: any) => (
        <UniProgramCard
          key={`${index}-program`}
          fit
          showStatus
          program={program}
          flexGrouped
          metricsArr={[
            {
              label: t('headers.level_of_study'),
              value: parseLevelOfStudy(program.levelOfStudy),
            },
            {
              label: t('program.tuition_fee'),
              value: program?.tuitionFee?.currency
                ? `${program.tuitionFee?.currency?.amount} ${program.tuitionFee?.currency?.currencyIso} / ${program.tuitionFee?.lecturePeriod}`
                : 'Unspecified',
            },
            {
              label: t('program.application_fee'),
              value: 'Unspecified',
            },
          ]}
        />
      ))}
    />
  );
};

export default Programs;
