import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { useClient } from '../../store';
import { CacheKeys } from '../../constants';
import { Components } from '../../client/UniClient';

const useResendAgencyInvite = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: string) => {
      return client!.AgencyApi_resendAgencyInvite(payload);
    },
    {
      onSuccess: response => {
        // queryCache.invalidateQueries(CacheKeys.Agency.All);
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default useResendAgencyInvite;
