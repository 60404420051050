import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useClient } from '../../store';

type Payload = {
  token?: string;
};

const useResendConfirmationEmail = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AuthApi_resendSignUpInvite(undefined, payload);
    },
    {
      onSuccess: async () => {},
      onError: (error: AxiosError) => {},
    },
  );
};
export default useResendConfirmationEmail;
