import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  studentId: string;
};

const usePublicProfile = (params: Params, config?: QueryConfig<Components.Schemas.StudentPublicProfileDto>) => {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Student.Notes, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.StudentApi_getStudentPublicProfile(params.studentId);
      return data;
    },
    {
      onError(error: AxiosError) {},
    },
  );
};

export default usePublicProfile;
