import React from 'react';
import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { StyledPurchasedPrepPacksListItem } from './PurchasedPrepPacksListItem.style';
import { UniButton, UniTypography } from '../index';

type PurchasedPrepPackDto = Components.Schemas.PurchasedPrepPackDto;

interface Props {
  purchasedPrepPack: PurchasedPrepPackDto;
  studentName?: string;
  onCheckIn?: () => any;
}

export const UniAdminPrepCenterPurchasedPrepPacksListItem: React.FC<Props> = ({ purchasedPrepPack, studentName, onCheckIn }) => {
  return (
    <StyledPurchasedPrepPacksListItem>
      <Box />
      <UniTypography variant='subheader'>{studentName ?? purchasedPrepPack.studentId}</UniTypography>
      <UniTypography variant='subheader'>
        {purchasedPrepPack.prepPack.sessions}x {purchasedPrepPack.prepPack.product.name}
      </UniTypography>
      <UniTypography variant='subheader'>{purchasedPrepPack.remainingSessions}</UniTypography>
      {onCheckIn && purchasedPrepPack.remainingSessions > 0 && (
        <UniButton color='primary' onClick={onCheckIn}>Check in student</UniButton>
      )}
    </StyledPurchasedPrepPacksListItem>
  );
};

export default UniAdminPrepCenterPurchasedPrepPacksListItem;
