import { Box } from '@material-ui/core';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UniDragNDrop } from '../..';
import { usePostAgentMultiple } from '../../../api';
import multipleAgentsCsv from '../../../assets/docs/multipleAgents.csv';
import { useErrorHandler } from '../../../hooks';
import { CurrencyIso } from '../../../types';
import UniTypography from '../../_common/uniTypography/UniTypography';

interface Props {
  handleClose: Function;
  handleSuccess: Function;
}

interface Inputs {
  feeAmount: number;
  feeCurrency: CurrencyIso;
  feeType: 'UPFRONT' | 'FIFTY_FIFTY' | 'AFTER';
}

export default function InviteCandidateMultiple({ handleClose, handleSuccess }: Props): ReactElement {
  const { t } = useTranslation('addAgentPage');
  const [uploadCsv, { status: uploadCsvStatus, error: uploadCsvError }] = usePostAgentMultiple();
  const postError = useErrorHandler(uploadCsvError!);
  const [csvFiles, setCsvFiles] = useState<File[]>([]);
  const handleUpload = () => {
    const formData = new FormData();
    if (csvFiles?.length > 0) {
      formData.append('file', csvFiles[0], 'agents.csv');
      uploadCsv(formData, {
        onSuccess(response) {
          handleSuccess(response);
        },
      });
    }
  };

  return (
    <>
      <UniModalDrawerContent>
        <Box marginTop='10px'>
          <UniDragNDrop
            label='Upload CSV file'
            handleChange={(files: any) => setCsvFiles(files)}
            variant='subheader'
            color='typographyGray30'
            accept='text/csv'
          >
            <Box display='flex' justifyContent='center'>
              <UniTypography color='typographyGray100'>
                Drag & Drop or{' '}
                <span style={{ color: '#D12E71' }}>
                  Upload <b>CSV</b> file
                </span>
              </UniTypography>
            </Box>
          </UniDragNDrop>
          <Box display='flex' flexDirection='column'>
            {postError.message && <UniTypography color='stateAlert100'>{postError.message}</UniTypography>}
            <UniTypography color='typographyGray30' variant='subheader'>
              CSV must contain the first name, last name and email address.
            </UniTypography>
            <Box
              style={{ cursor: 'pointer' }}
              mb={8}
              onClick={() => {
                window.open(multipleAgentsCsv, '_blank');
              }}
            >
              <UniTypography color='primary100' weight='medium' variant='subheader'>
                Download template.
              </UniTypography>
            </Box>
          </Box>
        </Box>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: handleClose, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: handleUpload,
          label: t('buttons.send_invitation'),
        }}
      />
    </>
  );
}
