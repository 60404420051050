import styled from 'styled-components';
import { ReactComponent as LogoIconSvg } from '../../../assets/svg/LogoIcon.svg';
import { Colors } from '../../../constants';

export const StyledPageLoader = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  padding: ${({ theme }) => theme.spacing(3, 2)};
`;

export const StyledLogoIcon = styled(LogoIconSvg)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  height: 80px;
  width: 80px;
  animation: pulse-black 1.5s infinite;

  @keyframes pulse-black {
    0% {
      transform: scale(0.95);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  }
`;

export const StyledPulseAnimation = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -38px;
  margin-left: -38px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  border-radius: 26px;
  height: 76px;
  width: 78px;
  transform: scale(1);
  animation: pulse-black-shadow 1.5s infinite;

  @keyframes pulse-black-shadow {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${Colors.Primary._100};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;
