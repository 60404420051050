import React, { ReactElement } from 'react';
import { StyledTabPanel } from './UniTabPanel.style';

interface Props {
  isActive: boolean;
  children: React.ReactNode;
}

export default function UniTabPanel({ isActive, children }: Props): ReactElement {
  return <StyledTabPanel isActive={isActive}>{children}</StyledTabPanel>;
}
