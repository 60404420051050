import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as Illustration } from '../../../assets/svg/Illustrations/invitationSentIllustration.svg';
import { ReactComponent as JumpingChickIllustration } from '../../../assets/svg/Illustrations/jumpingChick.svg';
import { ReactComponent as QueueIllustration } from '../../../assets/svg/Illustrations/queueIllustration.svg';
import { Colors } from '../../../constants';

export const StyledStudentEnrollmentDrawerWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${Colors.White._100};
`;

export const StyledStudentEnrollmentDrawerTabWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 7, 15, 7)};
  overflow-x: hidden;
  height: 100%;
`;

export const StyledStudentEnrollmentDrawerStepItemWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

export const SuccessIllustration = styled(Illustration)`
  width: 100%;
  max-width: 420px;
  height: 60%;
  max-height: 300px;
  margin-bottom: ${({ theme }) => theme.spacing(10)}px;
`;

export const StyledProgramName = styled.span`
  color: ${Colors.Primary._100};
`;

export const StyledUniversityName = styled.span`
  color: ${Colors.Secondary._100};
`;

export const StyledQueueIllustration = styled(QueueIllustration)`
  width: 100%;
  max-width: 420px;
  height: 60%;
  max-height: 300px;
  margin-bottom: ${({ theme }) => theme.spacing(10)}px;
`;
export const StyledJumpingChickIllustration = styled(JumpingChickIllustration)`
  width: 100%;
  max-width: 420px;
  height: 60%;
  max-height: 300px;
  margin-bottom: ${({ theme }) => theme.spacing(10)}px;
`;
