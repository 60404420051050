import UniButton from 'components/_common/uniButton/UniButton';
import styled from 'styled-components';
import { Colors } from '../../constants';

export const StyledOAuthWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ isMobile, theme }) => (isMobile ? theme.spacing(1) : theme.spacing(3))}px;
  width: 100%;
  ${({ theme }) => `${theme.breakpoints.down('sm')}{
    grid-template-columns: 1fr;
  }`}
`;

export const StyledOAuthButton = styled(UniButton)<{ google?: boolean; facebook?: boolean; isMobile: boolean }>`
  ${({ isMobile }) => !isMobile && 'flex: 1;'}
  ${({ google, facebook }) =>
    facebook
      ? `border-color: ${Colors.Other.Facebook_100}; p {color: ${Colors.Other.Facebook_100}}`
      : google && `border-color: ${Colors.Other.Google_100}; p {color: ${Colors.Other.Google_100}}`}
`;
