import { UniModalDrawer } from 'components';
import UniSnackbar from 'components/_common/uniSnackbar/UniSnackbar';
import { Route, RouteProps } from 'react-router';

interface Props extends RouteProps {
  component: any;
}

const LayoutNoNavRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <UniSnackbar />
          <Component {...props} />
          <UniModalDrawer />
        </>
      )}
    />
  );
};

export default LayoutNoNavRoute;
