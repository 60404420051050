import { Tab } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../../constants';
import { Size } from './UniNavigationTab';

export const StyledTab = styled(Tab)<{ isMobile?: boolean; size?: Size }>`
  min-width: ${({ theme, size }) => (size === 'S' ? theme.spacing(8.75) : theme.spacing(10.125))}px;
  min-height: ${({ theme, size }) => (size === 'L' ? theme.spacing(6.25) : theme.spacing(4.5))}px;
  padding: ${({ theme, size }) => (size === 'S' ? theme.spacing(0, 2, 1) : theme.spacing(0, 2, 2))};
  font-weight: 500;
  font-size: ${({ size }) => (size === 'S' ? 14 : 18)}px;
  :hover {
    background-color: ${({ isMobile }) => !isMobile && Colors.Typography.Gray_10};
  }
  .MuiTab-wrapper {
    min-width: max-content;
  }
`;
