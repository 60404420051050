import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { useMutation } from 'react-query';
import { useClient } from 'store';

type Payload = Components.Schemas.InviteHighSchoolRequest;

const useInviteHighSchool = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      if (!client) throw new Error('Client is not loaded');
      const { data } = await client.HighSchoolApi_inviteHighSchool(undefined, payload);
      return data;
    },
    {
      onError(error: AxiosError) {},
    },
  );
};

export default useInviteHighSchool;
