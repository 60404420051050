import { Box } from '@material-ui/core';
import { useFile, useUploadProfileImage } from 'api';
import { useBreakpoints } from 'hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useDrawers, useSnackbar } from 'store';
import { getMetaRole } from 'utils';
import { UniAvatar, UniButton, UniStatus, UniTypography } from '..';
import { ReactComponent as CandidateHeaderIllustration } from '../../assets/svg/Illustrations/candidateHeaderIllustration.svg';
import { Candidate } from '../../types';
import { CandidateActions, CandidateIdentity } from './CandidateHeader.style';
import CandidateHeaderMeta from './CandidateHeaderMeta';

interface Props {
  candidate: Candidate;
  BackButton: React.ReactNode;
  TopActions?: React.ReactNode;
  MiddleActions?: React.ReactNode;
  title?: string;
}

export default function CandidateProfileHeader({ BackButton, TopActions, MiddleActions, title, candidate }: Props): ReactElement {
  const { t } = useTranslation('studentProfile');
  const { isMobile } = useBreakpoints();
  const { role } = useAuth(state => state.profile);
  const { isMaster, isAgency, isAgent } = getMetaRole(role);
  const { openDrawer } = useDrawers();
  const [uploadProfileImage, { data: profileImage }] = useUploadProfileImage();
  const { openSnackbar } = useSnackbar();

  const profileImageSrc = useFile({ id: candidate?.profileImageFile?.id || '' }, { enabled: !!candidate?.profileImageFile });

  const uploadHandler = (file?: File) => {
    const profileImage = new FormData();
    profileImage.append('file', file!);
    profileImage.append('documentType', 'PROFILE_IMAGE');
    uploadProfileImage(profileImage, {
      onSuccess: () => openSnackbar('profile-image-uploaded'),
    });
  };

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems={isMobile ? 'center' : 'flex-start'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
          flexDirection='column'
          width={isMobile ? '100%' : 'auto'}
        >
          <Box alignSelf='flex-start' width='100%' display='flex' justifyContent='space-between' alignItems='center'>
            {BackButton}
          </Box>

          <UniTypography variant='header3' weight='medium'>
            {title}
          </UniTypography>
          <Box
            display='flex'
            flexDirection={isMobile ? 'column' : 'row'}
            textAlign={isMobile ? 'center' : 'left'}
            alignItems={'center'}
            alignSelf={isMobile ? 'center' : 'flex-start'}
            justifyContent={isMobile ? 'center' : 'flex-start'}
            mt={2}
          >
            <UniAvatar
              onUpload={uploadHandler}
              size='XL'
              src={profileImageSrc.data?.url}
              label={`${candidate?.firstName} ${candidate?.lastName}`}
            />
            <Box ml={isMobile ? 0 : 2}>
              <UniTypography variant='header3' weight='semi-bold' color='primary100'>
                {`${candidate?.firstName} ${candidate?.lastName}`}
              </UniTypography>
              <UniStatus candidate={candidate} />
            </Box>
          </Box>
        </Box>
        {!isMobile && (
          <Box>
            <CandidateHeaderIllustration />
          </Box>
        )}
        <Box display='flex' justifyContent='space-between' alignItems='flex-end' flexDirection='column'>
          {!isMobile && (
            <Box display='flex' flexDirection='column' maxHeight={12}>
              <UniTypography variant='footnote' color='typographyGray70'>
                {t('labels.header.date_added')} &nbsp;
              </UniTypography>
              <UniTypography weight='semi-bold' variant='footnote' color='typographyGray70'>
                {candidate?.createdAt || '12.10.2020'}
              </UniTypography>
              {(isMaster || isAgency || isAgent) && (
                <UniButton
                  onClick={() => openDrawer('notes', { size: 'main', studentId: candidate.id })}
                  size='small'
                  variant='text'
                  fullWidth={false}
                >
                  Notes →
                </UniButton>
              )}
            </Box>
          )}
          <CandidateActions>{TopActions}</CandidateActions>
          <CandidateIdentity>
            <CandidateActions>{MiddleActions}</CandidateActions>
          </CandidateIdentity>
        </Box>
      </Box>
      {candidate?.studentProfile && !isMobile && <CandidateHeaderMeta {...candidate.studentProfile} />}
    </>
  );
}
