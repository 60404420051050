import { Box } from '@material-ui/core';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import FiltersDrawerActions from 'components/_drawers/filtersDrawer/FiltersDrawerActions';
import { ApplicationsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ApplicationsFilters';
import { appStatus } from 'constants/appStatus';
import { DefaultFormValuesApplications } from 'constants/defaultFilters';
import { Subjects } from 'constants/subjects';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { default as React, ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getMetaRole } from 'utils';
import { UniAutocomplete } from '../../../..';
import { useApplicationFilters } from '../../../../../api';
import { useAuth } from '../../../../../store';
import { ProgramLanguage, Status } from '../../_types/ApplicationsFilters';
interface Props {
  close: Function;
  universityId?: string;
}

export default function FilterApplicationsForm({ close, universityId }: Props): ReactElement {
  const { t } = useTranslation('filterApplicationsPage');
  const { profile } = useAuth();
  const { replaceQueriesToUrl, queries } = useQueryStringParser<ApplicationsFiltersQueries>();
  const { isUniversity } = getMetaRole(profile?.role);
  const { data: filterApps, status: filterAppsStatus } = useApplicationFilters({
    universityId: (isUniversity ? profile.facultyMemberProfile?.universityId : universityId) || '',
  });
  const [queriesChange, setQueriesChange] = useState(false);
  const { search } = useLocation();
  const isLoading = filterAppsStatus === 'loading';
  const { handleSubmit, control, reset } = useForm<ApplicationsFiltersQueries>({
    defaultValues: { ...DefaultFormValuesApplications, ...queries },
  });
  const onSubmit = (formData: ApplicationsFiltersQueries) => {
    close();
    replaceQueriesToUrl(formData);
  };

  useEffect(() => {
    setQueriesChange(prevQueriesChange => !prevQueriesChange);
    if (search === '' || !!queries.clear) {
      reset({ programId: [], facultyId: [], programLanguage: [], status: [], levelOfStudy: [], discipline: [] });
      replaceQueriesToUrl({});
    } else {
      reset(queries);
    }
  }, [isLoading]);

  return (
    <>
      <UniModalDrawerContent>
        <Box mb={1}>
          {filterApps?.programId?.length && (
            <Controller
              control={control}
              multiple
              name='programId'
              render={({ onChange, value }) => (
                <UniAutocomplete
                  fullWidth
                  multiple
                  width='350px'
                  key={`programId- ${queriesChange}`}
                  isLoading={isLoading}
                  value={filterApps?.programId?.filter(item => value?.includes(item.value))}
                  handleChange={(event: any, value: any[]) => {
                    onChange(value?.map((item: any) => item.value) || []);
                  }}
                  name='programId'
                  options={filterApps?.programId || []}
                  getOptionLabel={option => option.label}
                  label={t('labels.by_program')}
                  labelColor='typographyBlack100'
                />
              )}
            />
          )}
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='levelOfStudy'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                key={`levelOfStudy-${queriesChange}`}
                isLoading={isLoading}
                value={filterApps?.levelOfStudy?.filter(item => value?.includes(item.value))}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map(item => item.value) || []);
                }}
                name='levelOfStudy'
                options={filterApps?.levelOfStudy || []}
                getOptionLabel={option => option.label}
                label={t('labels.level_of_study.title')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='status'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                key={`status-${queriesChange}`}
                isLoading={isLoading}
                value={appStatus.filter(item => value?.includes(item.value as Status))}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map(item => item.value) || []);
                }}
                name='status'
                options={appStatus || []}
                getOptionLabel={option => option.label}
                label={t('labels.by_appStatus')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            multiple
            name='discipline'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                key={`discipline-${queriesChange}`}
                isLoading={isLoading}
                value={filterApps?.discipline?.filter(item => value?.includes(item.value as Subjects))}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.value) || []);
                }}
                name='discipline'
                options={filterApps?.discipline || []}
                getOptionLabel={option => option.label}
                label={t('labels.discipline')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            multiple
            control={control}
            name='programLanguage'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                key={`programLanguage-${queriesChange}`}
                isLoading={isLoading}
                value={filterApps?.programLanguage?.filter(item => value?.includes(item.value as ProgramLanguage))}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.value) || []);
                }}
                name='programLanguage'
                options={filterApps?.programLanguage || []}
                getOptionLabel={option => option.label}
                label={t('labels.language')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
      </UniModalDrawerContent>
      <FiltersDrawerActions handleSubmit={handleSubmit(onSubmit)} />
    </>
  );
}
