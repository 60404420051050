import { useAppliedPrograms } from 'api';
import { Components } from 'client/UniClient';
import { ApplicationCard, InfiniteWindowedGrid } from 'components';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { BrowserRoutes } from '../../../../constants';

interface Props {}

const OngoingPrograms: React.FC<Props> = ({ children, ...rest }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const studentId = pathname.split('/')[4];
  const {
    data: ongoingPrograms,
    status: ongoingProgramsStatus,
    fetchMore: fetchMoreOngoingPrograms,
    isFetching,
  } = useAppliedPrograms({ studentId });

  const ongoingProgramsLoading = ongoingProgramsStatus === 'loading';
  const ongoingProgramsFlat = useMemo(() => {
    return ongoingPrograms?.map(progs => progs.results).flat() || [];
  }, [ongoingPrograms, ongoingProgramsStatus]);
  const ongoingProgramsTotal = useMemo(() => (ongoingPrograms ? ongoingPrograms[0].total : 0), [ongoingPrograms]);
  const ongoingProgramsLeft = useMemo(() => ongoingProgramsTotal - ongoingProgramsFlat.length, [ongoingProgramsFlat, ongoingProgramsTotal]);
  const goToProgramPage = (program: Components.Schemas.ProgramResponse) => {
    if (program?.application?.id) {
      return BrowserRoutes.Agent.Application.General(program.application.id);
    } else {
      return BrowserRoutes.Agent.StudentProgram.General.replace(':programId', program.id!).replace(':studentId', studentId);
    }
  };
  return (
    <InfiniteWindowedGrid
      fetchMore={fetchMoreOngoingPrograms}
      emptyStateType='applications'
      isFetching={isFetching}
      isLoading={ongoingProgramsLoading}
      hasMore={ongoingProgramsLeft > 0}
      remainingResults={ongoingProgramsLeft}
      minHeight={250}
      results={ongoingProgramsFlat?.map((program, index) => (
        <ApplicationCard
          key={`${index}-program`}
          variant='candidateApplicationsPage'
          studentName={`${program.application?.student?.firstName || ''} ${program.application?.student?.lastName || ''}`}
          studentAddress={program.application?.student?.studentProfile.address}
          programName={program.name}
          universityName={program.universityName}
          programAddress={program.address}
          status={program.application?.status}
          path={goToProgramPage(program)}
        />
      ))}
    />
  );
};

export default OngoingPrograms;
