import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import useInternships from 'api/internships/useInternships';
import { UniPageTitle } from 'components';
import { StyledGrid } from 'components/infiniteWindowedGrid/InfiniteWindowedGrid.style';
import UniInternshipCard from 'components/_common/uniInternshipCard/UniInternshipCard';

const InternshipsAndVolunteering: React.FC = () => {
  const { t } = useTranslation('studentAppTracker');
  const { data: internships } = useInternships();

  return (
    <>
      <Box mb={3}>
        <UniPageTitle title={t('tabs.internships_and_volunteering')} />
        <StyledGrid style={{ width: '100%', marginTop: '20px' }}>
          {internships && internships.map(internship => (
            <UniInternshipCard
              title={internship.name}
              description={internship.description}
              steps={[
                { title: t('internships.cards.steps.attend_cv_workshop'), checked: true, component: <p>CV</p> },
                { title: t('internships.cards.steps.one_on_one_consultant'), checked: false, component: <></> },
              ]}
              deadline={internship.applicationDeadline}
              link={internship.link}
            />
          ))}
        </StyledGrid>
      </Box>
    </>
  );
};

export default InternshipsAndVolunteering;
