import { Box } from '@material-ui/core';
import useUpdateProgram from 'api/programs/useUpdateProgram';
import { UniModalDrawerButtons, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers, useSnackbar } from 'store';

interface Props {
  // program?: Partial<Components.Schemas.ProgramResponse>;
  program?: any;
}

export default function ToggleApplicationPeriod({ program }: Props): ReactElement {
  const { t: tButtons } = useTranslation('buttons');
  const { openSnackbar } = useSnackbar();
  const { closeDrawer } = useDrawers();
  const applicationPeriodStatusString = !program?.blockAdmission ? 'close' : 'open';
  const [update, { isLoading }] = useUpdateProgram();

  const onClose = () => closeDrawer();

  const onToggle = () => {
    const value = !program?.blockAdmission;
    update(
      { payload: { blockAdmission: value }, pathParams: { programId: program.id } },
      {
        onSuccess() {
          closeDrawer();
          openSnackbar(`${applicationPeriodStatusString}-application-period`);
        },
      },
    );
  };

  return (
    <>
      <UniModalDrawerContent>
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          You are about to {applicationPeriodStatusString} the application period for {program?.name}
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          Are you sure you want to {applicationPeriodStatusString} it?
        </UniTypography>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: onClose, label: tButtons('cancel') }}
        PrimaryButton={{
          onClick: onToggle,
          loading: isLoading,
          label: program?.blockAdmission ? tButtons('yes_open') : tButtons('yes_close'),
        }}
      />
    </>
  );
}
