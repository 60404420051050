import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { AnimatedTabPanel, UniHeader, UniHerosection, UniIconWrapper, UniPage } from 'components';
import { ReactComponent as ProfileIcon } from '../../../assets/svg/Icons/Profile.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/svg/Icons/PhoneIcon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/svg/Icons/Mail.svg';
import { ReactComponent as HomeIcon } from '../../../assets/svg/Icons/Home.svg';
import { BrowserRoutes } from '../../../constants';
import Workshops from './_tabs/Workshops';
import SummerSchools from './_tabs/SummerSchools';
import InternshipsAndVolunteering from './_tabs/InternshipsAndVolunteering';
import PassionProject from './_tabs/PassionProject';

export default function StudentAppTracker(): ReactElement {
  const { t } = useTranslation('studentAppTracker');
  const { url } = useRouteMatch();
  const workshopsPath = BrowserRoutes.Student.AppTracker.Workshops;
  const summerSchoolsPath = BrowserRoutes.Student.AppTracker.SummerSchools;
  const internshipsAndVolunteeringPath = BrowserRoutes.Student.AppTracker.InternshipsAndVolunteering;
  const passionProjectPath = BrowserRoutes.Student.AppTracker.PassionProject;

  const tabsMap = [workshopsPath, summerSchoolsPath, internshipsAndVolunteeringPath, passionProjectPath];
  const currentIndex = tabsMap.findIndex(item => item === url);

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='title'
          title={t('title')}
          metrics={[
            {
              label: t('status'),
              value: 'Status placeholder',
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <ProfileIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: t('phone'),
              value: '+40 756 xxx xxx',
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <PhoneIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: t('email'),
              value: 'email@placeholder.com',
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <EmailIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: t('address'),
              value: 'Address placeholder',
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <HomeIcon />
                </UniIconWrapper>
              ),
            },
          ]}
          tabs={[
            { label: t('tabs.workshops'), path: workshopsPath },
            { label: t('tabs.summer_schools'), path: summerSchoolsPath },
            { label: t('tabs.internships_and_volunteering'), path: internshipsAndVolunteeringPath },
            { label: t('tabs.passion_project'), path: passionProjectPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={workshopsPath}>
              <AnimatedTabPanel currentItem={currentIndex} index={0} Content={<Workshops />} />
            </Route>
            <Route path={summerSchoolsPath}>
              <AnimatedTabPanel currentItem={currentIndex} index={1} Content={<SummerSchools />} />
            </Route>
            <Route path={internshipsAndVolunteeringPath}>
              <AnimatedTabPanel currentItem={currentIndex} index={2} Content={<InternshipsAndVolunteering />} />
            </Route>
            <Route path={passionProjectPath}>
              <AnimatedTabPanel currentItem={currentIndex} index={3} Content={<PassionProject />} />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
}
