import { OptionType } from 'types';

export type LevelOfStudy =
  | 'ASSOCIATE'
  | 'BACHELOR'
  | 'MASTER'
  | 'DOCTORATE'
  | 'FOUNDATION'
  | 'UNDERGRADUATE'
  | 'SHORT_COURSE'
  | 'VOCATIONAL'
  | 'OTHER';
export type GraduationLevel = 'HIGH_SCHOOL' | 'ASSOCIATE' | 'BACHELOR' | 'MASTER' | 'DOCTORATE';

export const levelOfStudy: OptionType[] = [
  { label: 'Associate', value: 'ASSOCIATE' },
  { label: 'Bachelor', value: 'BACHELOR' },
  { label: 'Master', value: 'MASTER' },
  { label: 'Doctorate', value: 'DOCTORATE' },
];

export const graduationLevel: OptionType[] = [
  { label: 'Highschool', value: 'HIGH_SCHOOL' },
  { label: 'Associate', value: 'ASSOCIATE' },
  { label: 'Bachelor', value: 'BACHELOR' },
  { label: 'Master', value: 'MASTER' },
  { label: 'Doctorate', value: 'DOCTORATE' },
];

export const levelOfStudyMap = {
  ASSOCIATE: 'Associate',
  BACHELOR: 'Bachelor',
  MASTER: 'Master',
  DOCTORATE: 'Doctorate',
  FOUNDATION: 'Foundation',
  UNDERGRADUATE: 'Undergraduate',
  SHORT_COURSE: 'Short course',
  VOCATIONAL: 'Vocational',
  OTHER: 'Other',
};

export const graduationLevelMap = {
  HIGH_SCHOOL: 'Highschool',
  ASSOCIATE: 'Associate',
  BACHELOR: 'Bachelor',
  MASTER: 'Master',
  DOCTORATE: 'Doctorate',
};

export const diplomaTypes = [
  { text: 'Highschool Diploma', value: 'HIGH_SCHOOL' },
  { text: 'Associate Degree', value: 'ASSOCIATE' },
  { text: 'Bachelors Degree', value: 'BACHELOR' },
  { text: 'Masters Degree', value: 'MASTER' },
  { text: 'Doctoral Degree', value: 'DOCTORATE' },
];
