import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { UniPageTitle } from 'components';
import { StyledGrid } from 'components/infiniteWindowedGrid/InfiniteWindowedGrid.style';
import UniInternshipCard from 'components/_common/uniInternshipCard/UniInternshipCard';

const SummerSchools: React.FC = () => {
  const { t } = useTranslation('studentAppTracker');
  const summerSchools = [
    { title: 'Summer School 1', description: 'An example of a summer school' },
  ];

  return (
    <>
      <Box mb={3}>
        <UniPageTitle title={t('tabs.summer_schools')} />
        <StyledGrid style={{ width: '100%', marginTop: '20px' }}>
          {summerSchools.map(school => (
            <UniInternshipCard
              title={school.title}
              description={school.description}
              steps={[
                { title: t('summer_schools.cards.steps.recommendation_letter'), checked: true, component: <p>Recommendation letter</p> },
                { title: t('summer_schools.cards.steps.write_essay'), checked: true, component: <p>Essay 1</p> },
                { title: t('summer_schools.cards.steps.financial_documents'), checked: false, component: <></> },
              ]}
            />
          ))}
        </StyledGrid>
      </Box>
    </>
  );
};

export default SummerSchools;
