import { Box } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { UniAutocomplete, UniButton, UniIconWrapper, UniTooltip } from '../../';
import { ReactComponent as EditIcon } from '../../../assets/svg/Icons/Edit.svg';
import { ReactComponent as GarbageIcon } from '../../../assets/svg/Icons/Garbage.svg';
import { StyledOptionActions } from './UniDocumentFieldCreator.style';
import UniDocumentFieldCreatorPopup, { TDocument } from './UniDocumentFieldCreatorPopup';

interface Props {
  options: TDocument[];
}
type Anchor = {
  el: HTMLElement | null;
  width: number;
};

export default function UniDocumentFieldCreator({ options }: Props): ReactElement {
  const [anchor, setAnchor] = useState<Anchor>({ el: null, width: 0 });
  const [optionsState, setOptionsState] = useState<TDocument[]>(options);
  const [itemToEdit, setItemToEdit] = useState<TDocument>();
  const [values, setValues] = useState<TDocument[]>([]);
  const [createFormOpen, setCreateFormOpen] = useState(false);

  const addDocument = (item: TDocument) => {
    setOptionsState(prevOptionsState => [...prevOptionsState, { ...item, id: optionsState.length }]);
    setValues(prevValues => [...prevValues, item]);
  };

  const editDocument = (item: TDocument) => {
    setOptionsState(prevOptionsState => {
      const index = prevOptionsState.findIndex(({ id }) => id === item.id);
      if (index !== -1) {
        let newOptionsState = [...prevOptionsState];
        newOptionsState[index] = item;
        return newOptionsState;
      } else {
        return prevOptionsState;
      }
    });
  };

  const removeDocument = (index: number) => setOptionsState(prevOptionsState => [...prevOptionsState.filter(item => item.id !== index)]);

  useEffect(() => {}, [values]);

  return (
    <Box position='relative'>
      <UniAutocomplete
        value={values}
        fullWidth
        width='350px'
        multiple
        handleChange={(event: any, value: any) => {
          setValues(value);
        }}
        name='documents'
        options={optionsState}
        OptionActions={option => (
          <StyledOptionActions>
            <UniTooltip title='Edit document'>
              <UniIconWrapper
                size='main'
                onClick={e => {
                  e.stopPropagation();
                  setItemToEdit(option);
                  setCreateFormOpen(true);
                }}
              >
                <EditIcon />
              </UniIconWrapper>
            </UniTooltip>

            <UniTooltip title='Delete document'>
              <UniIconWrapper
                size='main'
                color='stateAlert100'
                onClick={e => {
                  e.stopPropagation();
                  removeDocument(option.id!);
                }}
              >
                <GarbageIcon />
              </UniIconWrapper>
            </UniTooltip>
          </StyledOptionActions>
        )}
        getOptionLabel={option => option.title}
        NoOptionsAction={
          <>
            <UniButton
              fullWidth={false}
              color='primaryFade'
              onMouseDown={e => {
                e.stopPropagation();
                setCreateFormOpen(true);
              }}
            >
              Create document
            </UniButton>
          </>
        }
        label='Documents'
        labelColor='typographyGray70'
      />
      {createFormOpen && (
        <UniDocumentFieldCreatorPopup
          addDocument={addDocument}
          editDocument={editDocument}
          itemToEdit={itemToEdit}
          handleClose={() => setCreateFormOpen(false)}
        />
      )}
    </Box>
  );
}
