import { Box } from '@material-ui/core';
import { UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyIconPrograms } from '../../assets/svg/Illustrations/emptyState.svg';
import { ReactComponent as EmptyIconApplications } from '../../assets/svg/Illustrations/emptyStateApplication.svg';
import { ReactComponent as EmptyIconStatus } from '../../assets/svg/Illustrations/emptyStateStatus.svg';
import { EmptyIllustration, EmptyStateWrapper } from './EmptyState.style';

interface Props {
  type?: 'programs' | 'suggested' | 'saved' | 'applications' | 'status' | 'students' | 'tasks' | 'credentials';
  variant?: 'M' | 'L';
  mobileVariant?: boolean;
  differentMessage?: any;
}

export const EmptyState = ({ type = 'programs', variant = 'L', mobileVariant = false, differentMessage }: Props) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation('emptyState');
  const mobileVersion = isMobile || mobileVariant;
  return (
    <>
      <EmptyStateWrapper isMobile={isMobile} variant={variant}>
        <EmptyIllustration isMobile={isMobile} variant={variant}>
          {type === 'programs' ? (
            <EmptyIconPrograms style={{ width: 'inherit', height: 'inherit' }} />
          ) : type !== 'status' ? (
            <EmptyIconApplications style={{ width: 'inherit', height: 'inherit' }} />
          ) : (
            <EmptyIconStatus style={{ width: 'inherit', height: 'inherit' }} />
          )}
        </EmptyIllustration>
        <Box display='grid' gridRowGap={isMobile ? '8px' : undefined}>
          <UniTypography
            variant={mobileVersion ? 'body2' : 'header3'}
            weight={mobileVersion ? 'medium' : 'semi-bold'}
            color='secondary100'
            style={!isMobile ? { display: 'flex', alignItems: 'center' } : undefined}
          >
            {t(`header.${type}`)}
          </UniTypography>
          <UniTypography variant='subheader' weight='regular' color='typographyGray100' style={{ display: 'flex', alignItems: 'center' }}>
            {!!differentMessage ? differentMessage : t(`body.${type}`)}
          </UniTypography>
        </Box>
      </EmptyStateWrapper>
    </>
  );
};
