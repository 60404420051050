import { Box, MenuItem, Select } from '@material-ui/core';
import useStripeCurrencies from 'api/payments/useStripeCurrencies';
import { UniButton, UniIconWrapper, UniTextField, UniTypography } from 'components';
import { Controller, useFieldArray } from 'react-hook-form';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/Icons/Close.svg';
import { currencyIso } from '../../../../constants';

interface Props {
  register: any;
  control: any;
  errors: any;
}

const Step1: React.FC<Props> = ({ register, control, errors }) => {
  const { data: currencies, isLoading } = useStripeCurrencies();
  const { fields, append, remove } = useFieldArray({
    name: 'attendancePackages',
    control,
  });

  console.log(errors);

  const onNewLink = () => append({ name: '', description: '' });

  return (
    <>
      {fields.map((field, index) => {
        return (
          <Box key={`${field.id}-${index}`}>
            <UniTextField
              fullWidth
              label='Package name'
              placeholder='Enter your package name'
              defaultValue={field.name}
              name={`attendancePackages[${index}].name`}
              inputRef={register({
                required: 'Field must not be empty',
              })}
              error={!!errors?.attendancePackages?.[index]?.name?.message}
              helperText={errors?.attendancePackages?.[index]?.name?.message}
              endAdornment={
                <UniIconWrapper onClick={() => remove(index)}>
                  <CloseIcon />
                </UniIconWrapper>
              }
            />
            <UniTextField
              fullWidth
              label='Package description'
              placeholder='Enter your package description'
              defaultValue={field.description}
              name={`attendancePackages[${index}].description`}
              error={!!errors?.attendancePackages?.[index]?.description?.message}
              helperText={errors?.attendancePackages?.[index]?.description?.message}
              inputRef={register({
                required: 'Field must not be empty',
              })}
            />
            <Controller
              name={`attendancePackages[${index}].currency`}
              control={control}
              rules={{
                required: 'Field must not be empty',
              }}
              render={({ value, onChange, name }) => {
                return (
                  <>
                    <UniTextField
                      fullWidth
                      label='Set package price'
                      placeholder='Amount'
                      type='number'
                      value={value?.amount}
                      error={!!errors?.attendancePackages?.[index]?.currency?.message}
                      helperText={errors?.attendancePackages?.[index]?.currency?.message}
                      onChange={e => onChange({ ...value, amount: parseInt(e.target.value) })}
                      endComponent={
                        <Select
                          disableUnderline
                          value={value?.currencyIso}
                          renderValue={(selected: any) => {
                            if (!selected) {
                              return (
                                <UniTypography variant='body2' color='typographyGray100'>
                                  Unit
                                </UniTypography>
                              );
                            }

                            return (
                              <UniTypography variant='body2' color='typographyGray100'>
                                {currencies?.find(item => item.value === selected)?.text}
                              </UniTypography>
                            );
                          }}
                          displayEmpty
                          onChange={e => onChange({ ...value, currencyIso: e.target.value })}
                        >
                          {currencyIso.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              <UniTypography variant='body2' color='typographyGray100'>
                                {item.text}
                              </UniTypography>
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    />
                  </>
                );
              }}
            />
          </Box>
        );
      })}
      <UniButton size='medium' variant='text' color='primary' onClick={onNewLink}>
        Add pricing package
      </UniButton>
    </>
  );
};

export default Step1;
