import useRequestAchievementUpdate from 'api/achievements/useRequestAchievementUpdate';
import useRequestTodoUpdate from 'api/todos/useRequestTodoUpdate';
import { Components } from 'client/UniClient';
import { UniTextArea } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers, useSnackbar } from 'store';

interface Form {
  comment: string;
}
interface Props {
  achievement?: Components.Schemas.AchievementDto;
  todo?: Components.Schemas.ToDoDto;
}

const RequestActivityUpdate: React.FC<Props> = ({ achievement, todo }) => {
  const { t: tButtons } = useTranslation('buttons');
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const { control, errors, handleSubmit } = useForm<Form>();
  const [requestAchievementUpdate, { isLoading: requestAchievementUpdateIsLoading }] = useRequestAchievementUpdate();
  const [requestTodoUpdate, { isLoading: requestTodoUpdateIsLoading }] = useRequestTodoUpdate();
  const isLoading = requestAchievementUpdateIsLoading || requestTodoUpdateIsLoading;
  const type = !!achievement ? 'ACHIEVEMENT' : 'TODO';

  const onSubmit = (formData: Form) => {
    if (type === 'ACHIEVEMENT') {
      if (!achievement) return;
      requestAchievementUpdate(
        { achievementId: achievement.id, note: formData.comment },
        {
          onSuccess() {
            openSnackbar('update-requested');
            closeDrawer();
          },
        },
      );
    }
    if (type === 'TODO') {
      if (!todo) return;
      requestTodoUpdate(
        { toDoId: todo.id, note: formData.comment },
        {
          onSuccess() {
            openSnackbar('update-requested');
            closeDrawer();
          },
        },
      );
    }
  };

  return (
    <>
      <UniModalDrawerContent>
        <Controller
          control={control}
          name='comment'
          rules={{ required: 'Field must not be empty.' }}
          render={({ onChange, value }) => (
            <UniTextArea
              label='Comment'
              helperText={errors.comment?.message}
              placeholder='Send a message to your candidate...'
              withBorder
              rowsMin={4}
              onChange={e => onChange(e.target.value)}
              value={value}
            />
          )}
        />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: close, label: tButtons('cancel') }}
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: tButtons('save'),
          loading: isLoading,
        }}
      />
    </>
  );
};

export default RequestActivityUpdate;
