import { useAnsweredPrograms } from 'api';
import { InfiniteWindowedGrid, UniProgramCard } from 'components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import { parseLevelOfStudy } from 'utils/programUtils';
interface Props {}

const AnsweredPrograms: React.FC<Props> = ({ children, ...rest }) => {
  const { profile } = useAuth();
  const { t } = useTranslation('programsList');
  const {
    data: answeredPrograms,
    status: answeredProgramsStatus,
    fetchMore: fetchMoreansweredPrograms,
    isFetching,
  } = useAnsweredPrograms({ studentId: profile?.id! });

  const answeredProgramsLoading = answeredProgramsStatus === 'loading';
  const answeredProgramsFlat = useMemo(() => {
    return answeredPrograms?.map(progs => progs.results).flat() || [];
  }, [answeredPrograms, answeredProgramsStatus]);
  const answeredProgramsTotal = useMemo(() => (answeredPrograms ? answeredPrograms[0].total : 0), [answeredPrograms]);
  const answeredProgramsLeft = useMemo(
    () => answeredProgramsTotal - answeredProgramsFlat.length,
    [answeredProgramsFlat, answeredProgramsTotal],
  );

  return (
    <InfiniteWindowedGrid
      fetchMore={fetchMoreansweredPrograms}
      emptyStateType='programs'
      isFetching={isFetching}
      isLoading={answeredProgramsLoading}
      hasMore={answeredProgramsLeft > 0}
      remainingResults={answeredProgramsLeft}
      results={answeredProgramsFlat?.map((program: any, index: any) => (
        <UniProgramCard
          showUniName
          key={`${index}-program`}
          fit
          showStatus
          program={program}
          metricsArr={[
            {
              label: t('headers.level_of_study'),
              value: parseLevelOfStudy(program.levelOfStudy),
            },
            {
              label: t('program.tuition_fee'),
              value: program?.tuitionFee?.currency
                ? `${program.tuitionFee?.currency?.amount} ${program.tuitionFee?.currency?.currencyIso} / ${program.tuitionFee?.lecturePeriod}`
                : 'Unspecified',
            },
            {
              label: t('program.application_fee'),
              value: 'Unspecified',
            },
          ]}
        />
      ))}
    />
  );
};

export default AnsweredPrograms;
