import { Box } from '@material-ui/core';
import { ReactComponent as EditIcon } from 'assets/svg/Icons/Edit.svg';
import { Components } from 'client/UniClient';
import { UniAutocomplete, UniIconWrapper, UniTextField } from 'components';
import UniPhoneInput from 'components/_inputs/uniPhoneInput/uniPhoneInput';
import { countries, CountriesType } from 'constants/countries';
import { useBreakpoints } from 'hooks';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledSubtitle } from './Form.style';

export interface ContactFormInputs {
  email: string;
  phonePrefix: string;
  phone: string;
  password: string;
  address: Components.Schemas.AddressDto;
}
interface Props {
  register: any; //type is RegisterOptions
  control: Control;
  errors: FieldErrors;
  setValue: Function; //type is SetValueConfig
  onboard?: boolean;
  isInvite?: boolean;
  student?: Components.Schemas.UserResponse;
  changeDrawer?: Function;
}

const ContactForm: React.FC<Props> = ({ register, control, errors, setValue, onboard, isInvite, student, changeDrawer }) => {
  const { t } = useTranslation('forms');

  const handleEmailClick = () => {
    !!changeDrawer && changeDrawer('change-email');
  };
  const handlePasswordClick = () => {
    !!changeDrawer && changeDrawer('change-password');
  };

  const { isMobile } = useBreakpoints();
  return (
    <Box display='flex' flexDirection='column' gridColumnGap={isMobile ? '8px' : '16px'}>
      <StyledSubtitle variant='body1' color='secondary100' weight='medium'>
        {t('titles.address')}
      </StyledSubtitle>
      <UniTextField
        label={t('form.street')}
        inputRef={register({
          required: `${t('form.errors.street')}`,
          validate: (value: string) => value.trim() !== '' || `${t('form.errors.street')}`,
        })}
        name='address.addressLine1'
        error={!!errors.address?.addressLine1?.message}
        helperText={errors.address?.addressLine1?.message}
        fullWidth
      />
      <UniTextField
        label={t('form.number_and_info')}
        inputRef={register({
          required: `${t('form.errors.number_and_info')}`,
          validate: (value: string) => value.trim() !== '' || `${t('form.errors.number_and_info')}`,
        })}
        name='address.addressLine2'
        error={!!errors.address?.addressLine2?.message}
        helperText={errors.address?.addressLine2?.message}
        fullWidth
      />
      <UniTextField
        label={t('form.postcode')}
        inputRef={register({
          required: `${t('form.errors.postcode')}`,
        })}
        name='address.postalCode'
        error={!!errors.address?.postalCode?.message}
        helperText={errors.address?.postalCode?.message}
        fullWidth
      />
      <UniTextField
        label={t('form.town_city')}
        inputRef={register({
          required: `${t('form.errors.town_city')}`,
          validate: (value: string) => value.trim() !== '' || `${t('form.errors.town_city')}`,
        })}
        name='address.city'
        error={!!errors.address?.city?.message}
        helperText={errors.address?.city?.message}
        fullWidth
      />
      <Controller
        control={control}
        name='address.country'
        rules={{ required: `${t('form.errors.country')}` }}
        render={({ value, onChange }) => (
          <UniAutocomplete
            getOptionLabel={({ name }) => name}
            label={t('form.country')}
            disableClearable
            value={countries.find(item => item.code === value)}
            handleChange={(e: any, { code }: CountriesType) => onChange(code)}
            options={countries}
            helperText={errors.address?.country?.message}
            fullWidth
          />
        )}
      />
      <StyledSubtitle variant='body1' color='secondary100' weight='medium'>
        {t('titles.contact')}
      </StyledSubtitle>
      <UniPhoneInput
        name='phone'
        label={t('form.phone')}
        control={control}
        setValue={setValue}
        phonePrefix={student?.phonePrefix}
        inputRef={register({
          required: `${t('form.errors.phone_1')}`,
          minLength: { value: 4, message: t('form.errors.phone_3') },
          maxLength: { value: 15, message: t('form.errors.phone_3') },
        })}
        error={!!errors.phone?.message || !!errors.phonePrefix?.message}
        helperText={errors.phone?.message || errors.phonePrefix?.message}
        fullWidth
      />
      <UniTextField
        label={t('form.email')}
        name='email'
        value={student?.email}
        inputRef={register({
          required: `Email is required`,
        })}
        // disabled={!isInviteLink}
        fullWidth
        endAdornment={
          !onboard && (
            <UniIconWrapper size='medium' color='primary100' onClick={handleEmailClick}>
              <EditIcon />
            </UniIconWrapper>
          )
        }
      />
      {onboard ? (
        isInvite ? (
          <UniTextField
            label={t('form.password')}
            inputRef={register({
              required: `${t('form.errors.password_1')}`,
              minLength: { value: 8, message: t('form.errors.password_2') },
            })}
            name='password'
            type='password'
            error={!!errors.password?.message}
            helperText={errors.password?.message}
            fullWidth
            endAdornment={
              <UniIconWrapper size='medium' color='primary100' onClick={handlePasswordClick}>
                <EditIcon />
              </UniIconWrapper>
            }
          />
        ) : null
      ) : (
        <UniTextField
          label={t('form.password')}
          name='password'
          value='********'
          disabled
          fullWidth
          endAdornment={
            <UniIconWrapper size='medium' color='primary100' onClick={handlePasswordClick}>
              <EditIcon />
            </UniIconWrapper>
          }
        />
      )}
    </Box>
  );
};

export default ContactForm;
