import React from 'react';
import styled from 'styled-components';
import { UniButton } from '..';
import { Colors, Shadows, Shape } from '../../constants';

export const ProfileTabs = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const ProfileTab = styled.div`
  display: inline-block;
  background-color: ${Colors.White._100};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(1.5, 3)};
  border-radius: ${Shape.BorderRadius.md}px;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.White._100};
    box-shadow: ${Shadows.shadow2};
  }
  .MuiTypography-root {
    white-space: nowrap;
  }
`;

export const StyledHeader = styled.div`
  background-color: #fff;
  width: calc(100% + 48px);
  margin: -40px -24px 0 -24px;
  padding: 0 24px;
  box-shadow: ${Shadows.shadow2};
`;

export const StyledInnerHeader = styled.div`
  margin: 0 auto;
  padding: 0 40px;
  padding-left: 80px;
`;

export const ApplicationsFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const FilterButton = styled(props => <UniButton {...props} />)`
  background-color: ${Colors.White._100};

  &:hover {
    background-color: ${Colors.White._100};
    box-shadow: ${Shadows.shadow2};
  }
`;

export const StyledStudentProfileTabs = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex-direction: row;
`;

export const StyledStudentProfileWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledFormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  grid-gap: ${({ theme }) => theme.spacing(2)}px;
`;
