import { useCreateSubmitApplication, useSendApplication } from 'api';
import { Components } from 'client/UniClient';
import AnimatedTabPanel from 'components/_common/animatedTabPanel/AnimatedTabPanel';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import TrackingEvents from 'constants/trackingEvents';
import { AnimatePresence } from 'framer-motion';
import useAnalytics from 'hooks/useAnalytics';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth, useDrawers } from 'store';
import { getMetaRole } from 'utils';
import Step1 from './_steps/Step1';
import Step2, { TField } from './_steps/Step2';
import Step3 from './_steps/Step3';
import Step4 from './_steps/Step4';

interface Props {
  program?: Components.Schemas.ProgramResponse;
  studentId?: string;
}

const ApplicationDrawer: React.FC<Props> = ({ program, studentId }) => {
  const [step, setStep] = useState(0);
  const { track } = useAnalytics();
  const { setProgress, closeDrawer } = useDrawers();
  const { control, register, errors, handleSubmit } = useForm<any>();
  const { profile } = useAuth();
  const { t } = useTranslation('applicationDrawer');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [files, setFiles] = useState<any>();
  const [sendApplication, { status: sendApplicationStatus }] = useSendApplication();
  const [sendApplicationAgency, { status: sendApplicationAgencyStatus }] = useCreateSubmitApplication();
  const { isStudent, isAgent, isAgency } = getMetaRole(profile?.role);

  const isLoading = sendApplicationStatus === 'loading' || sendApplicationAgencyStatus === 'loading';

  const nextStep = () => {
    track(TrackingEvents.categories.ApplicationProcess, TrackingEvents.actions.completedStep(step + 1), `Application to ${program?.name}`);
    setStep(prevStep => prevStep + 1);
  };

  const prevStep = () => setStep(prevStep => prevStep - 1);

  const onSubmit = (formData: any) => {
    setFiles(formData);
    nextStep();
  };

  const submitApplication = () => {
    const requiredFiles = program?.requiredDocuments?.map(item => ({ requiredDocumentId: item.id, fileId: files[item.name] })) || [];
    if (program?.id) {
      if (isStudent) {
        sendApplication(
          { programId: program?.id, studentId: profile?.id || '' },
          {
            onSuccess() {
              track(TrackingEvents.categories.ApplicationProcess, TrackingEvents.actions.finalized, `Application to ${program?.name}`);
              nextStep();
            },
          },
        );
      }
      if (isAgent || isAgency) {
        sendApplicationAgency(
          { programId: program?.id, studentId: studentId! },
          {
            onSuccess() {
              nextStep();
            },
          },
        );
      }
    }
  };

  const options = profile.studentProfile?.documents?.map(doc => ({ label: doc.label, value: doc.id })) || [];

  const formFields: TField[] =
    program?.requiredDocuments?.map(document => ({
      label: document.name,
      placeholder: 'Select document',
      name: document.name,
      type: 'singleselect',
      options: options,
      required: 'This field is required!',
    })) || [];

  const buttonTexts = [
    {
      secondaryCallback: () => {
        closeDrawer();
        track(TrackingEvents.categories.ApplicationProcess, TrackingEvents.actions.canceled, `Application to ${program?.name}`);
      },
      primaryCallback: formFields.length > 0 ? nextStep : () => setStep(2),
      secondary: t('buttons.backToPrograms'),
      primary: t('buttons.iUnderstand'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: () => {
        handleSubmit(onSubmit)();
      },
      secondary: t('buttons.back'),
      primary: t('buttons.nextStep'),
    },
    {
      secondaryCallback: formFields.length > 0 ? prevStep : () => setStep(0),
      primaryCallback: submitApplication,
      secondary: t('buttons.cancel'),
      primary: t('buttons.submitApplication'),
      disabled: !acceptTerms,
      loading: isLoading,
    },
    {
      secondary: '',
      secondaryCallback: () => {},
      primaryCallback: closeDrawer,
      primary: t('buttons.backToPrograms'),
    },
  ];

  useEffect(() => {
    track(TrackingEvents.categories.ApplicationProcess, TrackingEvents.actions.initialized, `Application to ${program?.name}`);
  }, []);

  useEffect(() => setProgress(step * 30 + 10), [step]);

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel
            Content={<Step1 programName={program?.name} univeristyName={program?.universityName} />}
            index={0}
            currentItem={step}
          />
          <AnimatedTabPanel
            Content={<Step2 formFields={formFields} control={control} register={register} errors={errors} />}
            index={1}
            currentItem={step}
          />
          <AnimatedTabPanel
            Content={<Step3 setAcceptTerms={setAcceptTerms} universityName={program?.universityName} />}
            index={2}
            currentItem={step}
          />
          <AnimatedTabPanel Content={<Step4 universityName={program?.universityName || 'University'} />} index={3} currentItem={step} />
        </AnimatePresence>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={
          buttonTexts[step]?.secondary
            ? {
                onClick: () => buttonTexts[step].secondaryCallback(),
                label: buttonTexts[step].secondary,
              }
            : undefined
        }
        PrimaryButton={{
          onClick: buttonTexts[step]?.primaryCallback,
          label: buttonTexts[step]?.primary,
          disabled: !!buttonTexts[step]?.disabled,
          loading: !!buttonTexts[step]?.loading,
        }}
      />
    </>
  );
};

export default ApplicationDrawer;
