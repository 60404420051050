import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useAuth, useClient, useSnackbar } from '../../store';

const usePostDocument = () => {
  const queryCache = useQueryCache();
  const { setProfile } = useAuth();
  const { client } = useClient();
  const { openSnackbar } = useSnackbar();

  return useMutation(
    async (payload: FormData) => {
      return client!
        .FileApi_uploadDocument(undefined, payload as unknown as Components.Schemas.UploadDocumentRequest)
        .then(() => client!.UserApi_getMyUser());
    },
    {
      onSuccess: ({ data }) => {
        queryCache.invalidateQueries(CacheKeys.Profile.Extended);
        setProfile(data);
        openSnackbar('add-document');
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         }); 
         */
      },
    },
  );
};

export default usePostDocument;
