import { Theme } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../../../constants';
import { Color, LoaderProps } from './UniBounceLoader';

type BounceProps = {
  delay?: number;
};

const getColor = (theme: Theme, color: Color | string) => {
  if (color === 'white') return Colors.White._100;
  if (color === 'primary') return Colors.Primary._100;
  if (color === 'secondary') return Colors.Secondary._100;
  if (color === 'typographyGray70') return Colors.Typography.Gray_70;
  return color;
};

const bounceAnimation = keyframes`
    0%, 80%, 100% {
      transform: scale(0);
   } 40% {
      transform: scale(1.0);
   }
  `;

export const Bounce = styled.div<BounceProps>`
  border-radius: 100%;
  display: inline-block;
  animation: ${bounceAnimation} 1s infinite ease-in-out both;
  animation-delay: ${({ delay }) => (delay ? -delay : 0)}s;
`;

export const Container = styled.div<LoaderProps>`
  display: flex;
  height: 100%;

  & > ${Bounce} {
    background-color: ${({ theme, color }) => getColor(theme, color)};
    animation-duration: ${({ pulseSpeed }) => (pulseSpeed ? pulseSpeed : 1)}s;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  }
`;
