import { Box } from '@material-ui/core';
import { AnimatePresence } from 'framer-motion';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UniButton, UniTypography } from '../../../components';
import { StyledSuccessWrapper, SuccessIllustration } from './AdminCreateAgency.style';

interface Props {
  isVisible: boolean;
  handleClose: Function;
  invitationResent?: boolean;
}

export default function AdminCreateAgencySuccess({ isVisible, handleClose, invitationResent }: Props): ReactElement {
  const { t } = useTranslation('addAgencyPage');

  return (
    <AnimatePresence>
      {isVisible && (
        <StyledSuccessWrapper>
          <SuccessIllustration />
          <UniTypography variant='header1' color='secondary100' weight='semi-bold'>
            {invitationResent ? t('labels.succes.title_resend') : t('labels.succes.title')}
          </UniTypography>
          <UniTypography variant='body2' color='typographyGray100'>
            {t('labels.succes.subtitle')}
          </UniTypography>

          <Box display='flex' flexDirection='row-reverse' marginTop='250px'>
            <UniButton color='primary' fullWidth={false} onClick={() => handleClose()}>
              {t('buttons.back')}
            </UniButton>
          </Box>
        </StyledSuccessWrapper>
      )}
    </AnimatePresence>
  );
}
