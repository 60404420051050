import React from 'react';
import { useTranslation } from 'react-i18next';
import UniPrepCenterDashboard from 'components/prepCenterDashboard/UniPrepCenterDashboard';
import { ReactComponent as MathIcon } from 'assets/svg/Icons/Plus.svg';
import { ReactComponent as ScienceIcon } from 'assets/svg/Icons/Rocket.svg';
import { ReactComponent as LanguageIcon } from 'assets/svg/Icons/Language.svg';
import { ReactComponent as SessionsIcon } from 'assets/svg/Icons/CheckRound.svg';
import { ReactComponent as ProfessorsIcon } from 'assets/svg/Icons/Profile.svg';
import { UniIconWrapper } from 'components';
import { BrowserRoutes } from '../../../constants';

export default function StudentPrepCenter() {
  const { t } = useTranslation('prepCenter');

  const items = [
    {
      title: t('topics.math.name'),
      description: t('topics.math.description'),
      icon: (
        <UniIconWrapper size='large' color='white100'>
          <MathIcon />
        </UniIconWrapper>
      ),
      link: `${BrowserRoutes.Student.PrepCenter.Products}?category=Mathematics`,
      linkName: t('topics.view_courses'),
      textColor: 'white',
      background: 'linear-gradient(200deg, #4f235f, #49a3d2)',
    },
    {
      title: t('topics.science.name'),
      description: t('topics.science.description'),
      icon: (
        <UniIconWrapper size='large' color='white100'>
          <ScienceIcon />
        </UniIconWrapper>
      ),
      link: `${BrowserRoutes.Student.PrepCenter.Products}?category=Science`,
      linkName: t('topics.view_courses'),
      textColor: 'white',
      background: 'linear-gradient(200deg, #3980a2, #40a370)',
    },
    {
      title: t('topics.languages.name'),
      description: t('topics.languages.description'),
      icon: (
        <UniIconWrapper size='large' color='white100'>
          <LanguageIcon />
        </UniIconWrapper>
      ),
      link: `${BrowserRoutes.Student.PrepCenter.Products}?category=Languages`,
      linkName: t('topics.view_courses'),
      textColor: 'white',
      background: 'linear-gradient(200deg, #4f235f, #cb1b80)',
    },
    {
      title: t('sessions.name'),
      description: t('sessions.description'),
      icon: (
        <UniIconWrapper size='large' color='white100'>
          <SessionsIcon />
        </UniIconWrapper>
      ),
      link: BrowserRoutes.Student.PrepCenter.Sessions.Paid,
      linkName: t('sessions.view'),
      textColor: 'white',
      background: 'linear-gradient(200deg, #d8671b, #cb1b80)',
    },
    {
      title: t('professors.name'),
      description: t('professors.description'),
      icon: (
        <UniIconWrapper size='large' color='secondary100'>
          <ProfessorsIcon />
        </UniIconWrapper>
      ),
      link: BrowserRoutes.Student.PrepCenter.Professors.Math,
      linkName: t('professors.view'),
    },
  ];

  return (
    <>
      <UniPrepCenterDashboard items={items} />
    </>
  );
}
