import { Components } from 'client/UniClient';
import { BrowserRoutes } from '../constants';

export const createNotificationAction = (notification: Components.Schemas.NotificationDto) => {
  switch (notification.type) {
    case 'STUDENT_ACTIVATED_ACCOUNT':
      return {
        url: BrowserRoutes.Notifications.Agent.Candidate.replace(':id', notification.entityId),
        text: 'View Agency Profile',
      };
    case 'STUDENT_STARTED_APPLICATION':
      return {
        url: BrowserRoutes.Notifications.Agent.CandidateProgramsOngoing.replace(':id', notification.entityId),
        text: 'Review Application',
      };
    case 'AGENT_VALIDATED_PERSONAL_INFO':
      return {
        url: BrowserRoutes.Notifications.Candidate.PersonalInfo.replace(':id', notification.entityId),
        text: 'View Profile',
      };
    case 'AGENT_VALIDATED_APPLICATION':
      return {
        url: BrowserRoutes.Notifications.Candidate.ProgramsOngoing,
        text: 'View Application',
      };
    case 'AGENT_SUGGESTED_PROGRAM':
      return {
        url: BrowserRoutes.Notifications.Candidate.Suggested,
        text: 'View Suggested Application',
      };
    case 'UNIVERSITY_UPDATED_APPLICATION':
      return {
        url: BrowserRoutes.Student.Program.Status.replace(':programId', notification.entityId as string),
        text: 'Student receives reply from University',
      };
    case 'UNIVERSITY_ACCEPTED_APPLICATION':
      return {
        url: BrowserRoutes.Student.Program.Status.replace(':programId', notification.entityId as string),
        text: 'Student accepted to University',
      };
    case 'UNIVERSITY_ADDED':
      return {
        url: BrowserRoutes.Student.University.Program.replace(':universityId', notification.entityId as string),
        text: 'University Added',
      };
    case 'STUDENT_ACTIVATED_NO_APPLICATION':
      return {
        url: BrowserRoutes.Student.CardList.Grouped,
        text: 'Student activated no account',
      };
    case 'PROGRAM_DEADLINE_APPROACHING':
      return {
        url: BrowserRoutes.Student.Program.General.replace(':programId', notification.entityId as string),
        text: 'Deadline is coming',
      };
    case 'UNAPPLIED_FAVORITE_PROGRAM':
      return {
        url: BrowserRoutes.Student.Program.General.replace(':programId', notification.entityId as string),
        text: 'Student did not apply to a saved',
      };
    default:
      return { url: '', text: '' };
  }
};
