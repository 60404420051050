import { Box } from '@material-ui/core';
import { UniButton } from 'components';
import { useHistory } from 'react-router';
import { useDrawers } from 'store';
import styled from 'styled-components';
import { BrowserRoutes, Colors, Sizes } from '../../../constants';

interface Props {
  isPreview: boolean;
  setIsPreview: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledActionsBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${Colors.Primary._10};
  width: 100%;
`;

const EditPublicProfileActions: React.FC<Props> = ({ isPreview, setIsPreview }) => {
  const history = useHistory();
  const { openDrawer } = useDrawers();

  const publishProfile = () => {
    openDrawer('publish-public-profile');
  };

  return (
    <StyledActionsBar>
      <Box
        py={3}
        mx='auto'
        maxWidth={`${Sizes.MaxWidthWrapper}px`}
        width='100%'
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
      >
        <UniButton
          variant='text'
          onClick={() => (isPreview ? setIsPreview(false) : history.push(BrowserRoutes.Student.Profile.PersonalInfo))}
        >
          {isPreview ? 'Back to Edit' : 'Cancel'}
        </UniButton>
        <UniButton color='primary' onClick={() => (isPreview ? publishProfile() : setIsPreview(true))}>
          {isPreview ? 'Publish' : 'Preview'}
        </UniButton>
      </Box>
    </StyledActionsBar>
  );
};

export default EditPublicProfileActions;
