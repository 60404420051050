import { useBreakpoints } from 'hooks';
import { ReactElement } from 'react';
import UniButton, { ButtonColor } from '../uniButton/UniButton';
import { StyledModalDrawerActions } from './UniModalDrawer.style';

export type FooterButtonProps = {
  onClick: Function;
  label: string;
  color?: ButtonColor;
  disabled?: boolean;
  loading?: boolean;
};

interface Props {
  SecondaryButton?: Partial<FooterButtonProps>;
  PrimaryButton: FooterButtonProps;
}

export default function UniModalDrawerButtons({ SecondaryButton, PrimaryButton }: Props): ReactElement {
  const { isMobile } = useBreakpoints();

  return (
    <StyledModalDrawerActions
      type='labeled'
      align='left'
      variants={isMobile ? 'M' : 'L'}
      SecondaryButton={
        SecondaryButton ? (
          <UniButton
            size={isMobile ? 'medium' : 'large'}
            color='neutral'
            variant='text'
            fullWidth={false}
            disabled={SecondaryButton?.disabled}
            onClick={() => SecondaryButton?.onClick && SecondaryButton?.onClick()}
          >
            {SecondaryButton.label}
          </UniButton>
        ) : undefined
      }
      PrimaryButton={
        <UniButton
          color={PrimaryButton?.color}
          size={isMobile ? 'medium' : 'large'}
          onClick={() => PrimaryButton.onClick()}
          fullWidth={false}
          loading={PrimaryButton?.loading}
          disabled={PrimaryButton?.disabled}
        >
          {PrimaryButton.label}
        </UniButton>
      }
    />
  );
}
