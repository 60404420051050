import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Components } from '../../client/UniClient';
import UniBounceLoader from '../_common/uniBounceLoader/UniBounceLoader';
import UniTypography from '../_common/uniTypography/UniTypography';
import { StyledDocumentsList } from './AdditionalDocuments.style';
import AdditionalDocumentsListItem from './AdditionalDocumentsListItem';
import AdditionalDocumentsHeader from './AdditionalDocumentsHeader';
import { useTranslation } from 'react-i18next';

interface Props {
  data: Components.Schemas.FileDto[] | undefined;
  isLoading: boolean;
  candidate: Partial<Components.Schemas.UserDto>;
}

export default function AditionalDocuments({ data, isLoading, candidate }: Props): ReactElement {
  const { t } = useTranslation('additionalDocuments');
  return (
    <>
      <AdditionalDocumentsHeader />
      {data && data.length === 0 && (
        <Box mx={4}>
          <UniTypography color='primary100' weight='medium'>
            {t('labels.no_documents')}
          </UniTypography>
        </Box>
      )}
      <StyledDocumentsList>
        {isLoading ? (
          <UniBounceLoader />
        ) : (
          <>
            {data?.map((doc, index) => (
              <AdditionalDocumentsListItem key={`additional-document-${index}`} doc={doc} candidate={candidate} />
            ))}
          </>
        )}
      </StyledDocumentsList>
    </>
  );
}
