import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../constants';

export const ApplicationsFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  overflow-x: auto;
`;

export const FilterButton = styled.div`
  display: inline-block;
  background-color: ${Colors.White._100};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(1.5, 3)};
  border-radius: ${Shape.BorderRadius.md}px;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.White._100};
    box-shadow: ${Shadows.shadow2};
  }
  .MuiTypography-root {
    white-space: nowrap;
  }
`;
