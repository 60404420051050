import { UniTypography } from 'components';
import { TComponentColors } from 'constants/componentColors';
import React from 'react';
import { StyledBar } from './UniPageTitle.style';

export type Size = 'XL' | 'L' | 'M' | 'S' | 'XS';

interface Props {
  size?: Size;
  title: string;
  color?: TComponentColors;
  disableBar?: boolean;
}

const SizeMap = (size: Size) => {
  switch (size) {
    case 'XL':
      return 'xl';
    case 'L':
      return 'header1';
    case 'M':
      return 'header2';
    case 'S':
      return 'header3';
    case 'XS':
      return 'body2';
  }
};
const UniPageTitle: React.FC<Props> = ({ size = 'M', title, color = 'secondary100', disableBar, ...rest }) => {
  return (
    <div {...rest}>
      <UniTypography weight='semi-bold' color={color} variant={SizeMap(size)}>
        {title}
      </UniTypography>
      {!disableBar && <StyledBar size={size} />}
    </div>
  );
};
export default UniPageTitle;
