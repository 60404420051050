import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getUserHomeScreen } from 'utils/getHomeForRole';
import { useAuth, useClient } from '../../store';

const useConfirmEmail = () => {
  const { setProfile } = useAuth();
  const { client, setSession } = useClient();
  const history = useHistory();

  return useMutation(
    async (token: string) => {
      const { data } = await client!.AuthApi_confirmEmail({ token });
      setSession(data);
      setProfile(data.user);
      return data;
    },

    {
      onSuccess: data => history.push(getUserHomeScreen(data.user.role)),
      onError: (error: AxiosError) => {},
    },
  );
};

export default useConfirmEmail;
