import { AxiosError } from 'axios';
import { queryCache, useMutation } from 'react-query';
import { useClient } from '../../store';
import { CacheKeys } from '../../constants';
import { Components } from '../../client/UniClient';

type Payload = Components.Schemas.UpdateCandidateStatusRequest;

type Params = { studentId: string };

const useUpdateCandidateStatus = (params: Params) => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.StudentApi_updateCandidateStatus(params, payload);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries([CacheKeys.Agency.Candidate, params]);
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default useUpdateCandidateStatus;
