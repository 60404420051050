import { Box, Fade, InputAdornment } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { UniIconWrapper } from 'components';
import { matchSorter } from 'match-sorter';
import React, { ReactNode, useEffect, useState } from 'react';
import { UniChip } from '../..';
import { ReactComponent as DownArrowIcon } from '../../../assets/svg/Icons/Arrow/Down.svg';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import { Colors } from '../../../constants';
import ComponentColors, { TComponentColors } from '../../../constants/componentColors';
import UniTypography from '../../_common/uniTypography/UniTypography';
import UniCheckbox from '../uniCheckbox/UniCheckbox';
import { StyledAlertIcon } from '../uniTextField/UniTextField.style';
import { Container, Error, Label, StyledAutocompleteTagsWrapper, StyledTextField } from './UniAutocomplete.style';
interface AutocompleteInjectedProps<OptionType> {
  multiple: boolean;
  disableClearable: boolean;
  disableCloseOnSelect: boolean;
  autoComplete: boolean;
  autoHighlight: boolean;
  value: OptionType[];
  options: OptionType[];
  getOptionLabel: (option: OptionType) => string;
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
  onChange: (event: object, value: OptionType | OptionType[] | null, reason: string) => void;
}

export interface UniMultiSelectProps<OptionType> {
  name?: string;
  fullWidth?: boolean;
  label?: string;
  labelColor?: TComponentColors;
  handleChange: Function;
  handleAbort?: Function;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disableClearable?: boolean;
  options?: Array<OptionType>;
  OptionActions?: (option: OptionType) => React.ReactNode;
  value?: Array<OptionType> | OptionType;
  defaultValue?: Array<OptionType> | OptionType;
  helperText?: string;
  borderColor?: 'primary' | 'alert';
  error?: boolean;
  chipColor?: 'grey' | 'green';
  multiple?: boolean;
  inputRef?: () => void;
  getOptionLabel: (option: OptionType) => string;
  borderRadius?: Array<number>;
  backgroundColor?: TComponentColors;
  width?: string;
  placeholder?: string;
  chipShown?: number;
  NoOptionsAction?: React.ReactNode;
  inputValue?: string;
  optional?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  startAdornment?: React.ReactNode;
  renderOption?: (option: OptionType) => React.ReactNode;
  hasOptionsLimit?: boolean;
  optionsSortType?: 'asc' | 'desc';
  inputId?: string;
}

function UniAutocomplete<OptionType>({
  name,
  label,
  labelColor,
  value,
  disabled = false,
  options,
  chipShown = 2,
  OptionActions,
  fullWidth = true,
  handleChange,
  handleInputChange,
  defaultValue,
  borderColor,
  chipColor = 'grey',
  inputRef,
  multiple = false,
  getOptionLabel,
  borderRadius,
  backgroundColor = 'white100',
  width,
  disableClearable,
  error,
  helperText,
  isLoading,
  placeholder,
  handleAbort,
  NoOptionsAction,
  optional,
  inputValue,
  startAdornment,
  renderOption,
  hasOptionsLimit = false,
  optionsSortType = 'asc',
  inputId,
  ...props
}: UniMultiSelectProps<OptionType>) {
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [showHolder, setShowHolder] = useState(true);
  const [isFocus, setIsFocus] = useState(false);
  const filterOptions = (options: OptionType[], { inputValue }: { inputValue: string }) =>
    matchSorter(options, inputValue, {
      keys: ['name', 'firstName', 'lastName', 'middleName', 'email', 'text', 'code', 'label'],
      baseSort: (a, b) => (optionsSortType === 'asc' ? a.index - b.index : b.index - a.index),
    }).splice(0, hasOptionsLimit ? 20 : options?.length);
  useEffect(() => {
    if ((defaultValue as OptionType[])?.length && (defaultValue as OptionType[])?.length > 0) setShowHolder(false);
    else setShowHolder(true);
  }, [(defaultValue as OptionType[])?.length]);

  useEffect(() => {
    if (!!helperText) {
      setErrorMsg(() => helperText);
    }
  }, [helperText]);

  return (
    <Container hasLabel={!!label} backgroundColor={backgroundColor} key={`${defaultValue}`}>
      {label && (
        <Label>
          <UniTypography color={labelColor} variant='subheader' weight='medium'>
            {label}
          </UniTypography>
          {optional && (
            <UniTypography variant='footnote' color='typographyGray70'>
              Optional
            </UniTypography>
          )}
        </Label>
      )}
      <Autocomplete
        value={value}
        defaultValue={defaultValue}
        multiple={multiple}
        loading={isLoading}
        disabled={disabled}
        options={options || []}
        fullWidth={fullWidth}
        disableClearable={disableClearable}
        filterOptions={filterOptions}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        getOptionLabel={getOptionLabel}
        onChange={(event, value) => {
          handleChange(event, value);
          if (multiple && (value as OptionType[])?.length > 0) setShowHolder(false);
          else setShowHolder(true);
        }}
        style={{ justifyContent: 'center' }}
        popupIcon={
          <UniIconWrapper color='typographyGray50' size='medium'>
            <DownArrowIcon />
          </UniIconWrapper>
        }
        renderTags={(value: OptionType[], getTagProps: Function) => {
          let label: Array<string> = [];
          for (var i = 0; i < value?.length; i++) {
            label[i] = getOptionLabel(value[i])?.length > 6 ? getOptionLabel(value[i]).substring(0, 4) + '...' : getOptionLabel(value[i]);
          }
          return (
            <StyledAutocompleteTagsWrapper>
              {!isFocus && (
                <>
                  {label.map(
                    (item: any, index: any) =>
                      index < chipShown && (
                        <UniChip color={chipColor} label={item} onDelete={() => getTagProps({ index: index }).onDelete()} />
                      ),
                  )}
                </>
              )}
              {isFocus ? (
                <UniChip color={chipColor} label={`+ ${value?.length}`} />
              ) : (
                value?.length > chipShown && <UniChip color={chipColor} label={`+ ${value?.length - chipShown}`} />
              )}
            </StyledAutocompleteTagsWrapper>
          );
        }}
        disableCloseOnSelect={multiple}
        noOptionsText={NoOptionsAction}
        onAbort={e => handleAbort && handleAbort()}
        renderOption={(option, { selected }) => {
          if (renderOption) {
            return renderOption(option);
          } else if (multiple) {
            return (
              <Box width='100%' display='flex' justifyContent='space-between' style={{ pointerEvents: 'none' }}>
                <Box>
                  <UniCheckbox style={{ marginRight: 8, pointerEvents: 'none' }} checked={selected} />
                  {getOptionLabel(option)}
                </Box>
                {!!OptionActions && OptionActions(option)}
              </Box>
            );
          } else {
            return getOptionLabel(option);
          }
        }}
        renderInput={params => (
          <StyledTextField
            {...params}
            // id
            itemID={inputId}
            placeholder={showHolder ? placeholder : undefined}
            error={error}
            name={name}
            // value={inputValue}
            variant='outlined'
            onChange={handleInputChange}
            style={{
              border: `1px solid ${!!borderColor ? Colors.State.Alert_100 : isFocus ? Colors.Secondary._100 : Colors.Typography.Gray_50}`,
              borderRadius: '8px',
            }}
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {startAdornment && <InputAdornment position='start'>{startAdornment}</InputAdornment>}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            backgroundColor={ComponentColors[backgroundColor]}
          />
        )}
        {...props}
      />
      <Fade in={!!helperText} unmountOnExit>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {errorMsg}
          </UniTypography>
        </Error>
      </Fade>
    </Container>
  );
}

export default UniAutocomplete;
