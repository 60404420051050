import { AxiosResponse } from 'axios';
import { Components } from 'client/UniClient';
import { MutationConfig, useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Payload = Components.Schemas.AddServiceOfferingRequest;
type Params = {
  serviceOptionId: string;
};

export default function useAddServiceOffering(
  params: Params,
  config?: MutationConfig<AxiosResponse<Components.Schemas.ServiceOfferingDto>>,
) {
  const { client } = useClient();
  const queryCache = useQueryCache();

  return useMutation(async (payload: Payload) => client!.ServiceOptionApi_addServiceOffering(params, payload), {
    ...config,
    onSuccess() {
      queryCache.invalidateQueries(CacheKeys.Agency.Services);
      queryCache.invalidateQueries(CacheKeys.ServiceOptions.All);
    },
  });
}
