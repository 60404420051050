import React from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { FaCheck } from "react-icons/fa";
import { BrowserRoutes } from '../../../../constants';
import "./thankyou.css";

const Thankyou = () => {
  const history = useHistory();
  const { state } = useLocation();
  return (
    <div className="thankyou_container">
      <FaCheck className="check-icon"></FaCheck>
      <h1 className="thankyou_title">Here is your feedback:</h1>
      <p className="thankyou_description">Bracket: {state?.bracket}</p>
      <p className="thankyou_description">Eligibility: {state?.eligibility}</p>
      <button
        className="btn btn_primary"
        onClick={() => history.push(BrowserRoutes.Default.CssCheck.Default)}
      >
        Start again
      </button>
    </div>
  );
};

export default Thankyou;
