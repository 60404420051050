import styled from 'styled-components';
import { Colors } from '../../constants';

export const StyledSnackbox = styled.div<{ mobile: boolean }>`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(0.75)}px;
  width: fit-content;
  ${({ mobile }) => (!mobile ? `max-width: 440px` : `width: 100%`)};
  background-color: ${Colors.Typography.Black_100};
  gap: ${({ theme }) => theme.spacing(1.5)}px;
  padding: ${({ theme }) => theme.spacing(1.5, 3)};
`;
