import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useLogin } from '../../../api';
import { UniButton, UniLogo, UniTypography } from '../../../components';
import { Colors } from '../../../constants';
import { useErrorHandler } from '../../../hooks';
import { StyledOnboardIllustration } from './Onboard.style';
import { AgencyOnboardForm } from './_agency-steps/AgencyOnboard';
import { AgentOnboardForm } from './_agent-steps/AgentOnboard';

interface Props {
  onboardForm: AgentOnboardForm | AgencyOnboardForm;
}

export default function AgentOnboardStep3({ onboardForm }: Props): ReactElement {
  const history = useHistory();
  const [login, { status: loginStatus, error: loginError }] = useLogin();
  const agentLoginError = useErrorHandler(loginError!);

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      width='100%'
      style={{ backgroundColor: Colors.White._100 }}
    >
      <UniLogo size='L' />
      <Box my={3}>
        <StyledOnboardIllustration />
      </Box>
      <UniTypography variant='header1' color='secondary100' weight='semi-bold'>
        Your account is now activated
      </UniTypography>
      <Box maxWidth='375px' mt={2} mb={7}>
        <UniTypography>Congratulations for starting your Youni journey!</UniTypography>
      </Box>
      <UniButton
        color='primary'
        style={{ maxWidth: '200px' }}
        loading={loginStatus === 'loading'}
        onClick={() => {
          login({
            email: onboardForm.username,
            password: onboardForm.password,
          });
        }}
      >
        View Dashboard
      </UniButton>
      {agentLoginError.error && (
        <Box>
          <UniTypography color='stateAlert100'>{agentLoginError.message}</UniTypography>
        </Box>
      )}
    </Box>
  );
}
