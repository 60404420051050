import { UniButton, UniCardFooter, UniCardWrapper } from 'components';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import styled from 'styled-components';
import { Shadows } from '../../constants/uiConfig';

export const StyledFormWrapper = styled(UniCardWrapper)<{ onboard: boolean; isMobile: boolean }>`
  max-width: ${({ onboard }) => (onboard ? '782px' : '960px')};
  padding: ${({ isMobile, theme, onboard }) =>
    isMobile ? (onboard ? theme.spacing(3, 2, 8) : theme.spacing(3, 2, 0)) : onboard ? theme.spacing(6, 5, 0) : theme.spacing(5, 5, 1)};
  box-shadow: ${Shadows.shadow2};
  ${({ isMobile }) => isMobile && 'border-radius:0px;'}
  width: ${({ isMobile, theme, onboard }) => (isMobile ? (onboard ? `calc(100% + ${theme.spacing(4)}px)` : '100%') : '100%')};
  margin: ${({ isMobile, theme, onboard }) => (isMobile ? (onboard ? theme.spacing(3, -2, 0) : '0 auto 36px') : '24px auto 0')};
  overflow: hidden;
`;

export const StyledSubtitle = styled(UniTypography)`
  margin-bottom: 16px;
`;

export const FooterButton = styled(UniButton)`
  margin: ${({ theme }) => theme.spacing(2, 2, 2, 0)};
`;

export const StyledFooter = styled(UniCardFooter)`
  position: relative;
  padding: 0;
  margin-left: -${({ theme }) => theme.spacing(5)}px;
  margin-top: ${({ theme }) => theme.spacing(5)}px;
  width: calc(100% + 80px);
  height: ${({ theme }) => theme.spacing(13)}px;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: fixed;
    width: 100%;
    height: ${({ theme }) => theme.spacing(10)}px;
    bottom: 0;
    left: ${({ theme }) => theme.spacing(5)}px;
    box-shadow: ${Shadows.shadow2};
  }
`;
