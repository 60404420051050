import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { Box } from '@material-ui/core';
import { useProgramFilters } from 'api';
import { UniSearch, UniTypography } from 'components/index';
import { useBreakpoints } from 'hooks';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import UniSelect from '../../_inputs/uniSelect/UniSelect';
import { Nationality, OptionType } from '../../../types';
import { ProgramsFiltersQueries } from '../../_drawers/filtersDrawer/_types/ProgramsFilters';
import { FilterBarActions } from '../uniFilterBar/FilterBarActions';

interface Props {
  onClickFilter?: Function;
  options?: string[];
  noCountry?: boolean;
  showDiscipline?: boolean;
}

export const UniProgramFilterBarForm: React.FC<Props> = ({ onClickFilter, options, noCountry, showDiscipline }) => {
  const { isMobile } = useBreakpoints();
  const { t: tInputs } = useTranslation('inputs');
  const { t } = useTranslation('filterBar');

  const { queries, pushQueriesToUrl } = useQueryStringParser<ProgramsFiltersQueries>();
  const [searchQuery, setSearchQuery] = useState<string>(queries?.query || '');

  const { data: filters, isLoading } = useProgramFilters();

  // Handle countries filter field props
  const countryOptions = filters?.countries.map(({ code, name }) => ({ label: name, value: code }));
  const countryValue = countryOptions?.filter(({ value }) => value && queries?.country?.includes(value as Nationality));
  const onChangeCountry = (options: MultiValue<OptionType>) => {
    const country = options.map(option => option.value);
    pushQueriesToUrl({ country });
  };
  // Handle levelOfStudy filter field props
  const levelOfStudyOptions = filters?.levelsOfStudy;
  const levelOfStudyValue = levelOfStudyOptions?.filter(({ value }) => value && queries?.levelOfStudy?.includes(value));
  const onChangeLevelOfStudy = (options: MultiValue<OptionType>) => {
    const levelOfStudy = options.map(option => option.value);
    pushQueriesToUrl({ levelOfStudy });
  };
  // Handle discipline filter field props
  const disciplineOptions = filters?.subjects.map(({ value, text }) => ({ label: text, value }));
  const disciplineValue = disciplineOptions?.filter(({ value }) => value && queries?.discipline?.includes(value as any));
  const onChangeDiscipline = (options: MultiValue<OptionType>) => {
    const discipline = options.map(option => option.value);
    pushQueriesToUrl({ discipline });
  };

  const onChangeSearch = (value: string) => setSearchQuery(value);

  const onSearch = () => {
    pushQueriesToUrl({ query: !!searchQuery ? searchQuery : undefined });
  };


  return (
    <>
      <Box
        flex={1}
        minWidth={isMobile ? 'auto' : 450}
        display='flex'
        flexWrap='wrap'
        px={isMobile ? 2 : 0}
        pr={isMobile ? 0 : 3}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {!noCountry && (
          <Box flex={1} mr={isMobile ? 0 : 1}>
            <UniSelect
              isMulti
              options={countryOptions}
              value={countryValue}
              onChange={onChangeCountry}
              placeholder={tInputs('placeholders.country')}
              label={tInputs('labels.country')}
            />
          </Box>
        )}
        <Box flex={1} mr={isMobile ? 0 : noCountry ? 0 : 1}>
          <UniSelect
            isMulti
            options={levelOfStudyOptions}
            value={levelOfStudyValue}
            onChange={onChangeLevelOfStudy}
            placeholder={tInputs('placeholders.levelOfStudy')}
            label={tInputs('labels.levelOfStudy')}
          />
        </Box>
        {showDiscipline && (
          <Box flex={1} mr={isMobile ? 0 : 1} ml={isMobile ? 0 : 1}>
            <UniSelect
              isMulti
              options={disciplineOptions}
              value={disciplineValue}
              onChange={onChangeDiscipline}
              placeholder={tInputs('placeholders.discipline')}
              label={tInputs('labels.discipline')}
            />
          </Box>
        )}
        <Box width='100%' mt={isMobile ? 2 : 0}>
          <UniTypography style={{ marginBottom: '4px' }} color='typographyBlack100' variant='subheader' weight='medium'>
            {t('labels.searchByName')}
          </UniTypography>
          <UniSearch
            placeholder={t('labels.defaultSearch')}
            options={options}
            oldSearchVal={queries.query}
            handleSearch={onChangeSearch}
            stayFocused
            searchOnEnter={onSearch}
          />
        </Box>
      </Box>
      <FilterBarActions onClickFilter={() => onClickFilter && onClickFilter()} onClickSearch={onSearch} />
    </>
  );
};

export default UniProgramFilterBarForm;
