import styled from 'styled-components';

export const CenteredPageLayout = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;
