import useUpdateStudent from 'api/students/useUpdateStudent';
import { Components } from 'client/UniClient';
import StudentPersonalInfo from 'components/candidatePersonalInfo/StudentPersonalInfo';
import ShowableItem from 'components/showableItem/ShowableItem';

interface Props {
  isPreview: boolean;
  user: Components.Schemas.UserDto;
  setShowPersonal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PersonalInfo: React.FC<Props> = ({ user, isPreview, setShowPersonal }) => {
  const [postPersoalInfo, { status: personalInfoStatus }] = useUpdateStudent();
  const studentProfile = user.studentProfile;

  const personalProfileStatus = studentProfile?.publicProfilePreferences?.publishPersonalProfile;

  const togglePersonalProfile = (value: boolean) => {
    if (!studentProfile) return;
    const publicProfilePreferences = { ...studentProfile.publicProfilePreferences, publishPersonalProfile: value };
    setShowPersonal(value);
    postPersoalInfo({ studentProfile: { publicProfilePreferences } });
  };

  return (
    <ShowableItem disabled={isPreview} show={personalProfileStatus} onChange={togglePersonalProfile}>
      <StudentPersonalInfo student={user as Components.Schemas.UserResponse} />
    </ShowableItem>
  );
};

export default PersonalInfo;
