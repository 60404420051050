import { AxiosResponse } from 'axios';
import { Components } from 'client/UniClient';
import { MutationConfig, useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export default function usePostServiceOptions(config?: MutationConfig<AxiosResponse<Components.Schemas.ServiceOptionDto>>) {
  //   const agencyId = useAuth((state) => state.profile.agentProfile?.agency?.id);
  const { client } = useClient();
  const queryCache = useQueryCache();

  return useMutation(
    async (payload: Components.Schemas.AddServiceOptionRequest) => client!.ServiceOptionApi_addServiceOption(undefined, payload),
    {
      ...config,
      onSuccess() {
        queryCache.invalidateQueries(CacheKeys.ServiceOptions.All);
      },
    },
  );
}
