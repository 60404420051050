import { AxiosResponse } from 'axios';
import { Components } from 'client/UniClient';
import { MutationConfig, useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export default function useRequestService(config?: MutationConfig<AxiosResponse<Components.Schemas.RequestServiceOptionRequest>>) {
  const { client } = useClient();
  const queryCache = useQueryCache();

  return useMutation(
    async (payload: Components.Schemas.RequestServiceOptionRequest) => client!.ServiceOptionApi_requestServiceOption(undefined, payload),
    {
      ...config,
      onSuccess() {},
    },
  );
}
