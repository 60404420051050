import styled from 'styled-components';
import { theme } from 'theme';
import { Colors } from '../../constants';

export const StyledNotificationWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`;

export const StyledNotificationInnerWrapper = styled.div`
  padding: ${theme.spacing(4, 5)};
  position: absolute;
  bottom: 32px;
  width: calc(100% - 32px);
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  z-index: 999;

  display: grid;
  align-items: center;
  grid-template-columns: auto 120px;
  grid-gap: 24px;

  background-color: ${Colors.Secondary._100};
  border: 1px solid ${Colors.White._100};

  ${({ theme }) => theme.breakpoints.down('md')} {
    justify-items: center;
    text-align: center;
    grid-template-columns: 1fr;
  }
`;
