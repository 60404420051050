import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

const useOwnOrders = () => {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Orders.Own],
    async (_key: string) => {
      const { data } = await client!.OrderApi_getMyOrders();
      return data;
    },
    {
      onError(error: AxiosError) {
        console.log(error);
      }
    }
  );
};

export default useOwnOrders;
