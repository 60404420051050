import { Box } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import backgroundImage from '../../../assets/images/walk-in-the-city.png';
import { UniButton, UniModal, UniTypography } from '../../../components';
import { CacheKeys } from '../../../constants';
import { BrowserRoutes } from '../../../constants/browserRoutes';
import { setLocalStorage } from '../../../utils';

export const EmptyAgencyModal = (props: { show: boolean; handleClose: () => void }) => {
  const history = useHistory();
  const addAgent = () => {
    setLocalStorage(CacheKeys.General.NewSession, false);
    history.push({
      pathname: BrowserRoutes.Agency.Team,
      state: {
        addAgent: true,
      },
    });
  };

  return (
    <UniModal maxWidth='md' handleClose={props.handleClose} open={props.show}>
      <Box display='flex'>
        <Box m={3}>
          <UniTypography variant='header2' color='secondary100' weight='semi-bold'>
            Congrats, you've created your agency. Now it's time to add your first agent!
          </UniTypography>
          <Box my={2}>
            <UniButton onClick={addAgent} fullWidth={false} color='primary'>
              Add Agent
            </UniButton>
          </Box>
        </Box>
        <img src={backgroundImage} alt='' />
      </Box>
    </UniModal>
  );
};
