import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Components } from 'client/UniClient';
import ListHeader from './ListHeader';
import { StickyListFilterBar } from '../index';
import { StyledPurchasedPrepPacks } from './PurchasedPrepPacksList.style';
import UniAdminPrepCenterPurchasedPrepPacksListItem from './UniAdminPrepCenterPurchasedPrepPacksListItem';

type PurchasedPrepPackDto = Components.Schemas.PurchasedPrepPackDto;
type FilterDto = Components.Schemas.FilterDto;

interface Props {
  purchasedPrepPacks: PurchasedPrepPackDto[];
  studentNames: FilterDto[];
  headerForm?: ReactElement;
  onCheckIn?: (productId: string, studentId: string) => any;
}

export const UniAdminPrepCenterPurchasedPrepPacksList: React.FC<Props> = ({ purchasedPrepPacks, studentNames, headerForm, onCheckIn }) => {
  const { t } = useTranslation('prepCenter');

  return (
    <>
      <StickyListFilterBar
        listTitle={t('tabs.purchased_prep_packs')}
      />
      {headerForm}
      <ListHeader />
      <StyledPurchasedPrepPacks>
        {purchasedPrepPacks.map(purchasedPrepPack => (
          <UniAdminPrepCenterPurchasedPrepPacksListItem
            purchasedPrepPack={purchasedPrepPack}
            studentName={studentNames.find(sn => sn.value === purchasedPrepPack.studentId)?.label}
            onCheckIn={onCheckIn && (() => onCheckIn(purchasedPrepPack.prepPack.id!, purchasedPrepPack.studentId))}
          />
        ))}
      </StyledPurchasedPrepPacks>
    </>
  );
};

export default UniAdminPrepCenterPurchasedPrepPacksList;
