import { AnimatedTabPanel, UniHeader, UniIconWrapper, UniPage } from 'components';
import { UniHerosection } from 'components/_common/uniHerosection/UniHerosection';
import { AnimatePresence } from 'framer-motion';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAuth, useHeader } from 'store';
import { ReactComponent as OngoingIcon } from '../../../assets/svg/Icons/Ongoing.svg';
import { ReactComponent as SavedIcon } from '../../../assets/svg/Icons/Saved.svg';
import { BrowserRoutes } from '../../../constants/browserRoutes';
import AllPrograms from './_tabs/AllPrograms';
import Answered from './_tabs/Answered';
import Ongoing from './_tabs/Ongoing';
import Saved from './_tabs/Saved';
import Suggested from './_tabs/Suggested';

export default function StudentApplications(): ReactElement {
  const { t } = useTranslation('studentApplications');
  const candidate = useAuth(state => state.profile);
  const { setHeader, clearHeader } = useHeader();
  const { url } = useRouteMatch();
  const savedProgsCount = candidate?.studentMetadata?.favoriteProgramsCount;
  const ongoingProgsCount = candidate?.studentMetadata?.ongoingApplicationsCount;
  useEffect(() => {
    setHeader({});
    return clearHeader;
  }, []);

  const allProgsPath = BrowserRoutes.Student.Applications.AllPrograms;
  const savedPath = BrowserRoutes.Student.Applications.Saved;
  const ongoingPath = BrowserRoutes.Student.Applications.Ongoing;
  const answeredPath = BrowserRoutes.Student.Applications.Answered;
  const suggestedPath = BrowserRoutes.Student.Applications.Suggested;

  const tabsMap = [allProgsPath, savedPath, ongoingPath, answeredPath, suggestedPath];
  const currentIndex = tabsMap.findIndex(item => item === url);

  return (
    <>
      <UniHeader>
        <UniHerosection
          title={t('title')}
          headerVariant='title'
          metrics={[
            {
              label: t('tabs.saved'),
              value: `${savedProgsCount + ' '}${savedProgsCount === 1 ? t('program') : t('programs')}`,
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <SavedIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: t('tabs.ongoing'),
              value: `${ongoingProgsCount + ' '}${ongoingProgsCount === 1 ? t('program') : t('programs')}`,
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <OngoingIcon />
                </UniIconWrapper>
              ),
            },
          ]}
          tabs={[
            { label: t('tabs.all_progs'), path: allProgsPath },
            { label: t('tabs.saved'), path: savedPath },
            { label: t('tabs.ongoing'), path: ongoingPath },
            { label: t('tabs.answered'), path: answeredPath },
            { label: t('tabs.suggested'), path: suggestedPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={allProgsPath}>
              <AnimatedTabPanel Content={<AllPrograms />} index={0} currentItem={currentIndex} />
            </Route>
            <Route path={savedPath}>
              <AnimatedTabPanel Content={<Saved />} index={1} currentItem={currentIndex} />
            </Route>
            <Route path={ongoingPath}>
              <AnimatedTabPanel Content={<Ongoing />} index={2} currentItem={currentIndex} />
          </Route>
            <Route path={answeredPath}>
              <AnimatedTabPanel Content={<Answered />} index={4} currentItem={currentIndex} />
            </Route>
            <Route path={suggestedPath}>
              <AnimatedTabPanel Content={<Suggested />} index={5} currentItem={currentIndex} />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
}
