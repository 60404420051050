import React from 'react';
import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { StyledProductsListItem } from './ProductsListItem.style';
import { UniTypography } from '../index';

type ProductDto = Components.Schemas.ProductDto;

interface Props {
  product: ProductDto;
}

export const UniAdminPrepCenterProductsListItem: React.FC<Props> = ({ product }) => {
  return (
    <StyledProductsListItem>
      <Box />
      <UniTypography variant='subheader'>{product.name}</UniTypography>
      <UniTypography variant='subheader'>{product.category}</UniTypography>
    </StyledProductsListItem>
  );
};

export default UniAdminPrepCenterProductsListItem;
