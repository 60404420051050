import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { getMetaRole } from 'utils';
import { Components } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

type Payload = Components.Schemas.CreateApplicationRequest;

const useSendApplication = () => {
  const { profile, setStudentMetadata } = useAuth();
  const queryCache = useQueryCache();
  const { isStudent } = getMetaRole(profile?.role!);
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.ProgramApi_applyToProgram(payload.programId, {
        // requiredDocuments: payload.requiredDocuments,
      });
    },
    {
      onMutate: (application: Payload) => {
        const previousPrograms = queryCache.getQueries(CacheKeys.General.Programs);
        const previousApps = queryCache.getQueries(CacheKeys.Applications.All);
        const previousProg = queryCache.getQueryData([CacheKeys.General.Program, application?.programId]);

        isStudent &&
          setStudentMetadata(metadata => {
            let aux = metadata;
            aux.ongoingApplicationsCount += 1;
            return aux;
          });
        return () => {
          if (previousPrograms && previousPrograms.length > 0) queryCache.setQueryData(previousPrograms[0].queryKey, previousPrograms[0]);
          if (previousApps && previousApps.length > 0) queryCache.setQueryData(previousApps[0].queryKey, previousApps[0]);
          if (previousProg) queryCache.setQueryData(CacheKeys.General.Program, previousProg);
        };
      },
      onError: (error: AxiosError, application, rollback: Function) => {
        rollback();
        isStudent &&
          setStudentMetadata(metadata => {
            return {
              ...metadata,
              ongoingApplicationsCount: profile?.studentMetadata?.ongoingApplicationsCount! - 1,
            };
          });
      },
      onSettled: async response => {
        queryCache.invalidateQueries(CacheKeys.Applications.All);
        queryCache.invalidateQueries(CacheKeys.General.Programs);
        queryCache.invalidateQueries(CacheKeys.General.Program);
      },
    },
  );
};

export default useSendApplication;
