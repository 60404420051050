import { Grid } from '@material-ui/core';
import useSearchPrograms from 'api/search/useSearchPrograms';
import { InfiniteWindowedGrid, UniProgramCard } from 'components';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAuth, useDrawers } from 'store';
import { parseLevelOfStudy } from 'utils/programUtils';
import { useGetProgramsNames } from '../../../api';
import { UniHeader, UniHerosection, UniPage } from '../../../components';
import { BrowserRoutes } from '../../../constants';

const AdminPrograms = () => {
  const { url } = useRouteMatch();
  const { openDrawer } = useDrawers();
  const { queries } = useQueryStringParser<ProgramsFiltersQueries>();
  const { role, facultyMemberProfile } = useAuth(state => state.profile);
  const groupedPath = BrowserRoutes.Admin.CardList.Grouped;
  const programsPath = BrowserRoutes.Admin.CardList.Programs;
  const currentRoute = url === groupedPath ? 0 : url === programsPath ? 1 : 2;
  const universityId = facultyMemberProfile?.universityId ? [facultyMemberProfile.universityId] : undefined;

  const {
    data: programs,
    status: programsStatus,
    isFetching: isFetchingPrograms,
    fetchMore: fetchMorePrograms,
    error: programsError,
  } = useSearchPrograms({
    filters: { ...queries, universityId, query: queries.query || undefined },
  });
  //   const fetchError = useErrorHandler(programsError!);
  const programsLoading = programsStatus === 'loading';
  const programsFlat = useMemo(() => {
    return programs?.map(programPage => programPage.results).flat() || [];
  }, [programs]);
  const programsTotal = useMemo(() => (programs ? programs[0].total : 0), [programs]);
  const programsLeft = programsTotal - programsFlat.length;
  const { data: programNames } = useGetProgramsNames();

  const openProgramFilters = () => openDrawer('filter-programs', { size: 'small' });
  const openUniversityFilters = () => openDrawer('filter-universities', { size: 'small' });

  return (
    <>
      <UniHeader>
        <UniHerosection
          key={currentRoute}
          headerVariant='title'
          title='Programs'
          onClickFilters={currentRoute === 2 ? openUniversityFilters : openProgramFilters}
          overflow='none'
          options={programNames?.map(item => item.label) || []}
          withFilterBar
        />
      </UniHeader>
      <UniPage>
        <Grid item xs={12}>
          <InfiniteWindowedGrid
            isFetching={isFetchingPrograms}
            emptyStateType='programs'
            totalResults={programsTotal}
            fetchMore={fetchMorePrograms}
            isLoading={programsLoading}
            hasMore={programsLeft > 0}
            remainingResults={programsLeft}
            sortOptions={[
              { value: 0, label: 'By price' },
              { value: 1, label: 'By name' },
            ]}
            // LeftComponent={isMaster ? <UniButton onClick={openAddProgramDrawer}>Add Programme</UniButton> : undefined}
            results={programsFlat?.map((program, index: any) => (
              <UniProgramCard
                showUniName
                key={`${index}-program`}
                fit
                showStatus
                program={program}
                metricsArr={[
                  {
                    label: 'Level of Study',
                    value: parseLevelOfStudy(program.levelOfStudy),
                  },
                  {
                    label: 'Applications',
                    value: program.applicationCount ? program.applicationCount.toString() : '0',
                  },
                  {
                    label: 'Updated applications',
                    value: program.updatedApplicationCount ? program.updatedApplicationCount.toString() : '0',
                  },
                ]}
              />
            ))}
          />
        </Grid>
      </UniPage>
    </>
  );
};
export default AdminPrograms;
