import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

export default function useProduct(productId: string) {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Products.Product, productId],
    async (_key: string) => {
      const response = await client!.ProductApi_getProduct({ productId });
      return response.data;
    },
    {
      onError(err: AxiosError) {
        console.error(err);
      }
    },
  );
}
