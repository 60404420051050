import { Box } from '@material-ui/core';
import { AuthForm, StyledChildrenWrapperTop } from 'components/_layouts/authLayout/AuthLayout.style';
import { default as React, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForgotPassword } from '../../../api/auth/useForgotPassword';
import { ReactComponent as EmailIcon } from '../../../assets/svg/Icons/Candidates.svg';
import { UniButton, UniIconWrapper, UniTextField, UniTypography } from '../../../components';
import { BrowserRoutes } from '../../../constants';
import { useBreakpoints, useErrorHandler } from '../../../hooks';

type Inputs = {
  email: string;
  password: string;
};

const ForgotPassword = () => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation('forgotPassword');
  const history = useHistory();
  const [login, { status, error: forgotPasswordError }] = useForgotPassword();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const forgottenPassError = useErrorHandler(forgotPasswordError!);

  const [requestSent, setRequestSent] = useState(false);

  const isLoading = status === 'loading';

  const onSubmit = (formData: Inputs) => {
    login(formData, {
      onSuccess() {
        setRequestSent(true);
      },
      onError() {
        reset();
      },
    });
  };

  const emailMessage = errors?.email?.message ?? '';

  const onBackToLogin = () => history.push(BrowserRoutes.Default.Auth.Login);

  if (requestSent) {
    return (
      <>
        <StyledChildrenWrapperTop>
          <UniTypography color='secondary70' variant='header1' weight='regular'>
            {t('texts.response')}
          </UniTypography>
          <Box mt={2}>
            <UniButton variant='main' color='primary' fullWidth={false} onClick={onBackToLogin}>
              {t('buttons.backToLogin')}
            </UniButton>
          </Box>
        </StyledChildrenWrapperTop>
      </>
    );
  }

  return (
    <>
      <StyledChildrenWrapperTop>
        <AuthForm onSubmit={handleSubmit(onSubmit)} noValidate>
          <UniTextField
            fullWidth
            startAdornment={
              <UniIconWrapper color='typographyGray100' size='medium'>
                <EmailIcon />
              </UniIconWrapper>
            }
            helperText={emailMessage}
            name='email'
            error={!!emailMessage}
            placeholder={t('placeholders.email')}
            inputRef={register({ required: 'Email is required' })}
            label={t('labels.email')}
            type='email'
          />
        </AuthForm>
        {forgottenPassError.message && <UniTypography color='stateAlert100'>{forgottenPassError.message}</UniTypography>}
        <Box width={isMobile ? 'auto' : '100%'} mb={isMobile ? 1 : 5}>
          <UniButton disablePadding variant='text' color='neutral' fullWidth={false} onClick={onBackToLogin}>
            {t('buttons.rememberPassword')}
          </UniButton>
        </Box>
        {!isMobile && (
          <UniButton variant='main' size='large' fullWidth={isMobile} onClick={handleSubmit(onSubmit)} loading={isLoading}>
            {t('buttons.sendLink')}
          </UniButton>
        )}
      </StyledChildrenWrapperTop>
      {isMobile && (
        <UniButton variant='main' size='large' fullWidth={isMobile} onClick={handleSubmit(onSubmit)} loading={isLoading}>
          {t('buttons.sendLink')}
        </UniButton>
      )}
    </>
  );
};
export default ForgotPassword;
