import { Box } from '@material-ui/core';
import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { ReactComponent as MessageIcon } from '../../assets/svg/Icons/Messages.svg';
import { UniBounceLoader, UniIconWrapper } from '../../components';
import { useIntersectionObserver } from '../../hooks';
import UniButton from '../_common/uniButton/UniButton';
import UniTypography from '../_common/uniTypography/UniTypography';
import { SelectedAgentsWrapper, StyledAgents } from './AgentsList.style';
import AgentsListHeader from './AgentsListHeader';

export type AgentsSort = 'NAME' | 'DATE_ADDED';

export type AgentsQueryParams = {
  _page: number;
  _sort: AgentsSort;
  _order: 'ASC' | 'DESC' | null;
  q: string;
};

export type OnAgentsSort = (value: AgentsSort) => void;

// TODO wait for new designer so we know if this will be a template for a list project wide,
// if so make this into a generic list

interface Props {
  allSelected?: boolean;
  selectAllAgents?: Function;
  params?: AgentsQueryParams;
  onSort?: OnAgentsSort;
  length: number;
  hasMore: boolean;
  fetchMore: Function;
  selectedAgents?: number;
  isLoading?: boolean;
  children?: ReactElement[] | ReactElement;
  order?: OrderType;
  sortType?: AgentsSort;
}

const AgentsList = ({
  fetchMore,
  hasMore,
  allSelected,
  selectAllAgents,
  params,
  onSort,
  length,
  selectedAgents,
  isLoading,
  children,
  sortType,
  order,
}: Props) => {
  const targetRef = useRef(null);
  const [showSelectedAgents, setShowSelectedAgents] = useState(selectedAgents! > 0);

  useEffect(() => {
    setShowSelectedAgents(selectedAgents! > 0);
  }, [selectedAgents]);

  useEffect(() => {
    if (showSelectedAgents) {
      const timeout = setTimeout(() => {
        setShowSelectedAgents(false);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showSelectedAgents]);

  useIntersectionObserver({
    target: targetRef,
    onIntersect: fetchMore,
    enabled: hasMore,
  });

  const showEndOfList = length > 4 && !hasMore && !isLoading;
  const showEmptyList = length === 0 && !isLoading;
  return (
    <>
      <AgentsListHeader
        allSelected={allSelected}
        onSort={onSort}
        params={params}
        selectAllAgents={selectAllAgents}
        order={order}
        sortType={sortType}
      />

      <StyledAgents>
        {children}
        {showEmptyList && (
          <Box mx={4}>
            <UniTypography color='primary100' weight='medium'>
              No agents found.
            </UniTypography>
          </Box>
        )}
        {isLoading && <UniBounceLoader />}
        {showEndOfList && (
          <Box>
            <UniTypography color='typographyGray70'>You have reached the end of the list.</UniTypography>
          </Box>
        )}
        <div ref={targetRef} />
      </StyledAgents>
      <SelectedAgentsWrapper isVisible={showSelectedAgents}>
        <UniTypography color='white100'>
          {selectedAgents === 1 ? `${selectedAgents} agent selected` : `${selectedAgents} agents selected`}
        </UniTypography>
        <UniButton
          variant='text'
          fullWidth={false}
          color='primary'
          onClick={() => {}}
          startIcon={
            <UniIconWrapper size='large' color='white100'>
              <MessageIcon />
            </UniIconWrapper>
          }
        >
          Send message
        </UniButton>
      </SelectedAgentsWrapper>
    </>
  );
};

export default AgentsList;
