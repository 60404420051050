import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export type UsePostAchievementPayload = Components.Schemas.CreateAchievementRequest & { documents?: File[] };

const usePostAchievement = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: UsePostAchievementPayload) => {
      const links = payload.links?.filter(item => item.trim() !== '');
      const { data: achievement } = await client!.AchievementApi_addAchievement(undefined, { ...payload, links });
      if (!payload.documents) return achievement;
      const documents = [];

      for (let i = 0; i < payload.documents.length; i++) {
        let formData = new FormData();
        formData.append('documentType', 'ACHIEVEMENT_DOCUMENT');
        formData.append('file', payload.documents[i]);
        const { data } = await client!.AchievementApi_uploadDocument({ achievementId: achievement.id }, formData);
        documents.push(data);
      }

      return { ...achievement, documents };
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Achievement.Default);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default usePostAchievement;
