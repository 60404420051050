import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import usePrepPacks from 'api/preppacks/usePrepPacks';
import usePostPurchasedPrepPack from 'api/preppacks/usePostPurchasedPrepPack';
import usePurchasedPrepPacks from 'api/preppacks/usePurchasedPrepPacks';
import useStudentNames from 'api/students/useStudentNames';
import { UniButton } from 'components';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import UniAdminPrepCenterPurchasedPrepPacksList from 'components/adminPrepCenterPurchasedPrepPacksList/UniAdminPrepCenterPurchasedPrepPacksList';
import { BrowserRoutes } from '../../../../constants';

interface Props {}

interface FormType {
  studentId: string | null;
  prepPackId: string | null;
}

const defaultFormState: FormType = {
  studentId: null,
  prepPackId: null,
};

export const PurchasedPrepPacks: React.FC<Props> = () => {
  const history = useHistory();
  const { data: prepPacks } = usePrepPacks();
  const { data: purchasedPrepPacks } = usePurchasedPrepPacks();
  const { data: rawStudentNames } = useStudentNames();
  const studentNames = rawStudentNames?.map(studentName => ({
    value: studentName.value,
    label: `${studentName.label || 'unnamed'} (${studentName.value})`,
  }));
  if (studentNames != null) {
    studentNames.sort((l, r) => {
      if (l.label < r.label) return -1;
      if (l.label > r.label) return 1;
      return 0;
    });
  }

  const [addPurchasedPrepPack] = usePostPurchasedPrepPack();
  const [isStudentIdFilled, setIsStudentIdFilled] = useState(false);
  const [isPrepPackIdFilled, setIsPrepPackIdFilled] = useState(false);
  const submitDisabled = !isStudentIdFilled || !isPrepPackIdFilled;

  const prepPackOptions = prepPacks?.map(prepPack => ({
    label: `${prepPack.sessions}x ${prepPack.product.name}`,
    value: prepPack.id!,
  }));

  const { control, errors, handleSubmit } = useForm<FormType>({
    defaultValues: defaultFormState,
  });

  const assignPrepPackToStudent = ({ prepPackId, studentId }: FormType) => {
    if (prepPackId != null && studentId != null) {
      addPurchasedPrepPack({ prepPackId, studentId }, {
        onSuccess() {
          window.location.reload();
        }
      }).then();
    }
  };

  const onSubmit = () => {
    handleSubmit(formData => {
      assignPrepPackToStudent(formData);
    })();
  };

  const onCheckIn = (prepPackId: string, studentId: string) => {
    history.push(BrowserRoutes.Admin.PrepCenter.StudentCheckIn
      .replace(':prepPackId', prepPackId)
      .replace(':studentId', studentId));
  };

  return (
    <>
      {prepPacks && prepPackOptions && purchasedPrepPacks && studentNames && (
        <>
          <UniAdminPrepCenterPurchasedPrepPacksList
            purchasedPrepPacks={purchasedPrepPacks}
            studentNames={rawStudentNames!}
            onCheckIn={onCheckIn}
            headerForm={
              <div>
                <Controller
                  control={control}
                  name='studentId'
                  rules={{ required: 'Student is required', validate: (value: string) => value != null }}
                  render={({ value, onChange }) => (
                    <UniSelect
                      label='Student'
                      placeholder='Select student'
                      options={studentNames}
                      value={studentNames.find(item => item.value === value)}
                      onChange={option => {
                        onChange(option?.value);
                        setIsStudentIdFilled(option?.value != null);
                      }}
                      error={errors.studentId?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='prepPackId'
                  rules={{ required: 'Package is required', validate: (value: string) => value != null }}
                  render={({ value, onChange }) => (
                    <UniSelect
                      label='Package'
                      placeholder='Select package'
                      options={prepPackOptions}
                      value={prepPackOptions.find(item => item.value === value)}
                      onChange={option => {
                        onChange(option?.value);
                        setIsPrepPackIdFilled(option?.value != null);
                      }}
                      error={errors.studentId?.message}
                    />
                  )}
                />
                <UniButton
                  color='primary'
                  onClick={onSubmit}
                  disabled={submitDisabled}
                >
                  Assign package
                </UniButton>
              </div>
            }
          />
        </>
      )}
    </>
  );
};

export default PurchasedPrepPacks;
