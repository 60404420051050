import { Components } from 'client/UniClient';
import { default as React, ReactElement } from 'react';
import UniMediaCarousel, { MediaType } from 'components/_common/UniMediaCarousel/UniMediaCarousel';

interface Props {
  university: Components.Schemas.UniversityResponse;
  carouselItems: Array<MediaType>;
  noContentFound: string;
}

export default function UniversityPageGeneralMedia({ university, carouselItems, noContentFound }: Props): ReactElement {
  return <UniMediaCarousel carouselItems={carouselItems} emptyCarouselLabeL={noContentFound} />;
}
