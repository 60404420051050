import styled from 'styled-components';
import { theme } from 'theme';
import { Colors, ComponentColors, Shadows } from '../../../constants';
import { TComponentColors } from '../../../constants/componentColors';

export const StyledWrapper = styled.div<{
  grey?: boolean;
  opac: boolean;
  fullSection: boolean;
  size: any;
  border: any;
  hoverable: boolean;
  shadow?: 'shadow1' | 'shadow2' | 'shadow3';
  onboard?: boolean;
  fit?: boolean;
  isPromoted?: boolean;
  backgroundColor?: TComponentColors;
  overflow?: 'auto' | 'hidden' | 'visible' | 'none';
}>`
  border-radius: ${({ size }) => (size === 'L' ? theme.spacing(1.5) : theme.spacing(1))}px;
  background-color: ${({ grey, isPromoted }) => (isPromoted ? Colors.Promoted.Uni_10 : !!grey ? Colors.Gray1._70 : Colors.White._100)};
  ${({ backgroundColor }) => !!backgroundColor && `background-color: ${ComponentColors[backgroundColor]};`}
  border: ${({ border, opac }) => (border ? `1px solid  ${!opac ? Colors.Border.Gray_100 : Colors.Border.Gray_70}` : undefined)};
  transition: all 0.2s;
  position: relative;
  height: inherit;
  width: inherit;
  ${({ shadow }) => !!shadow && `box-shadow: ${Shadows[shadow]};`}
  ${({ hoverable }) =>
    hoverable &&
    `&:hover {
      box-shadow: ${Shadows.shadow2};
      cursor:pointer;
  }`}
  ${({ onboard }) => onboard && 'max-width:782px'}
  ${({ fit }) =>
    fit &&
    `
    width:100%;
    height:100%;
  `}
  ${({ fullSection, theme }) =>
    fullSection &&
    `
    margin-left: ${theme.spacing(-2)}px;
    margin-right: ${theme.spacing(-2)}px;
    border-radius: 0;
    border:none;
  `}
  ${({ overflow }) => overflow && `overflow: ${overflow};`};

  iframe {
    width: 100%;
    height: 500px;
    object-fit: contain;
    ${({ theme }) => `${theme.breakpoints.down('sm')}{
     height: 300px;
  }`}
  }
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;
