import { createMuiTheme, MuiThemeProvider, useTheme } from '@material-ui/core';
import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';
import { Colors } from '../../../constants';
import UniBounceLoader from '../uniBounceLoader/UniBounceLoader';
import UniTypography from '../uniTypography/UniTypography';
import { Content, Loader, StyledButton, StyledToolTip } from './ClearFiltersButton.style';

type Color = 'primary' | 'secondary' | 'danger' | 'neutral' | 'primaryFade';
type Variant = 'main' | 'secondary' | 'text';
export type Size = 'medium' | 'large';

interface UniButtonProps {
  hidden: boolean;
  color: Color;
  loading: boolean;
  variant: Variant;
  disabled: boolean;
  fullWidth: boolean;
  endIcon: React.ReactNode;
  startIcon: React.ReactNode;
  toolTip?: string;
  size: Size;
  ref?: React.MutableRefObject<any>;
  tooltipColor?: any;
  keepTooltip?: boolean;
}

const getLoaderSize = (size: Size) => (size === 'large' ? 18 : 15);
const getLoaderColor = (color: Color, variant: Variant) => {
  if (color === 'danger') {
    return variant === 'main' ? Colors.White._100 : Colors.State.Alert_100;
  }
  if (color === 'neutral') {
    return variant === 'main' ? Colors.White._100 : Colors.Typography.Gray_100;
  }
  return variant === 'main' ? Colors.White._100 : Colors.Primary._100;
};

const variantMap = {
  main: 'contained',
  secondary: 'outlined',
  text: 'text',
} as const;

const UniButton: React.FC<Partial<UniButtonProps> & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  loading = false,
  color = 'primary',
  variant = 'main',
  disabled = false,
  fullWidth = true,
  size = 'large',
  toolTip,
  tooltipColor,
  keepTooltip = false,
  ref,
  endIcon,
  startIcon,
  hidden = false,

  onClick,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const theme = useTheme();

  const makeTheme = useCallback(
    (color: string) =>
      createMuiTheme({
        ...theme,
        palette: {
          primary: { main: color, contrastText: Colors.Primary._100 },
        },
      }),
    [theme],
  );

  const mappedTheme = useMemo(() => {
    if (color === 'neutral') return makeTheme(Colors.Typography.Gray_100);
    if (color === 'danger') return makeTheme(Colors.State.Alert_100);
    if (color === 'secondary') return makeTheme(Colors.Secondary._100);
    if (color === 'primary') return makeTheme(Colors.Primary._100);
    if (color === 'primaryFade') return makeTheme(Colors.Primary._10);
    return theme;
  }, [color, makeTheme, theme]);

  return (
    <MuiThemeProvider theme={mappedTheme}>
      <StyledButton
        hidden={hidden}
        loading={loading ? 1 : 0}
        onMouseEnter={() => toolTip && setShowTooltip(true)}
        onMouseLeave={() => toolTip && setShowTooltip(false)}
        innerRef={ref}
        size={size}
        start={!!startIcon ? 1 : 0}
        end={!!endIcon ? 1 : 0}
        color={disabled ? 'default' : 'primary'}
        variant={variantMap[variant]}
        fullWidth={fullWidth}
        onClick={e => {
          if (!disabled && onClick) {
            e.stopPropagation();
            onClick(e);
          }
        }}
        {...props}
      >
        {toolTip && (
          <AnimatePresence>
            {showTooltip && (
              <StyledToolTip initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} backgroundColor={tooltipColor}>
                <UniTypography color='white100'>{toolTip}</UniTypography>
              </StyledToolTip>
            )}
          </AnimatePresence>
        )}
        <>
          {startIcon && startIcon}
          <Content loading={loading}> {children}</Content>
          {endIcon && endIcon}
        </>

        {loading && (
          <Loader>
            <UniBounceLoader size={getLoaderSize(size)} color={getLoaderColor(color, variant)} />
          </Loader>
        )}
      </StyledButton>
    </MuiThemeProvider>
  );
};

export default UniButton;
