import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { InfiniteQueryConfig, useInfiniteQuery } from 'react-query';
import { ProgramsFiltersQueries } from '../../components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { ApiKeys, CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  filters?: ProgramsFiltersQueries;
  pageSize?: number;
};

export default function useSearchPrograms(params?: Params, config?: InfiniteQueryConfig<Components.Schemas.ProgramResultPageDto>) {
  const { client } = useClient();

  return useInfiniteQuery(
    [CacheKeys.Search.Programs, params],
    (_key: string, { filters, pageSize = ApiKeys.PageSize.Programs }: Params, page: number = 1) =>
      client!
        .SearchApi_searchPrograms({
          ...filters,
          page,
          pageSize,
          // sortType,
        })
        .then(({ data }) => data),
    {
      ...config,
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Programs || lastPage.page * ApiKeys.PageSize.Programs === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
