import { Fade, TextareaAutosizeProps } from '@material-ui/core';
import { TComponentColors } from 'constants/componentColors';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import { Colors } from '../../../constants';
import UniTypography from '../../_common/uniTypography/UniTypography';
import { StyledAlertIcon } from '../uniTextField/UniTextField.style';
import { Container, Error, Label, StyledTextareaAutosize } from './UniTextArea.style';

interface UniTextAreaBaseProps extends Omit<TextareaAutosizeProps, 'color'> {
  optional?: boolean;
  label?: string;
  helperText?: string;
  labelColor?: TComponentColors;
  withBorder?: boolean;
  borderRadius?: Array<number>;
  backgroundColor?: any;
  variant?: 'small' | 'footnote' | 'subheader' | 'body2' | 'body1' | 'header3' | 'header2' | 'header1' | 'xl' | undefined;
  type?: string;
}

const UniTextArea: React.FC<UniTextAreaBaseProps> = ({
  label,
  helperText,
  optional,
  labelColor = 'typographyBlack100',
  withBorder,
  borderRadius,
  backgroundColor,
  variant,
  type = 'text',

  ...props
}) => {
  const [errorMsg, setErrorMsg] = useState<any>('');

  useEffect(() => {
    if (!!helperText) {
      setErrorMsg(() => helperText);
    }
  }, [helperText]);

  return (
    <Container hasLabel={!!label} backgroundColor={backgroundColor}>
      {label && (
        <Label>
          <UniTypography variant='subheader' color={labelColor} weight='medium'>
            {label}
          </UniTypography>
          {optional && (
            <UniTypography variant='body2' color='typographyGray30'>
              optional
            </UniTypography>
          )}
        </Label>
      )}
      <StyledTextareaAutosize
        style={{
          border: withBorder ? (!!helperText ? `1px solid ${Colors.State.Alert_100}` : `1px solid ${Colors.Border.Gray_100}`) : 'none',
          borderRadius: borderRadius ? `${borderRadius[0]}px ${borderRadius[1]}px ${borderRadius[2]}px ${borderRadius[3]}px` : '12px',
        }}
        {...props}
      />

      <Fade in={!!helperText} unmountOnExit>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {errorMsg}
          </UniTypography>
        </Error>
      </Fade>
    </Container>
  );
};

export default UniTextArea;
