import { StyledContainer } from 'components/_common/uniMetricsBase/UniMetrics.style';
import { UniMetricsBase, UniMetricsBaseProps } from 'components/_common/uniMetricsBase/UniMetricsBase';
import { useBreakpoints } from 'hooks';

interface Props {
  values: Partial<UniMetricsBaseProps>[];
  variant: 'iconed' | 'text';
  size: 'small' | 'large';
  vertical?: boolean;
}

export const UniMetrics = ({ values, variant, size, vertical = false }: Props) => {
  const { isMobile } = useBreakpoints();
  return (
    <StyledContainer mobile={isMobile || vertical} size={size}>
      {values.map(item => (
        <UniMetricsBase Icon={item.Icon} label={item.label!} value={item.value!} link={item?.link} variant={variant} size={size} />
      ))}
    </StyledContainer>
  );
};
