import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniAutocomplete, UniAvatar, UniButton } from 'components';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import { StyledHeaderAvatar } from '../ReplyToUniversity.style';

type Inputs = {
  uploaded: string[];
  toUpload: File[];
};
interface Props {
  programName?: string;
  universityName?: string;
  control: Control<Inputs>;
  openAddDocumentDrawer: (value: string) => void;
}

const Step1: React.FC<Props> = ({ programName = '', openAddDocumentDrawer, control, universityName = 'University' }) => {
  const {
    profile: { studentProfile },
  } = useAuth();
  const { t } = useTranslation('replyToUniversity');
  const multiple = true;

  return (
    <>
      <Box mb={2}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          {t('texts.youAreAboutToReply')}
        </UniTypography>
      </Box>
      <StyledHeaderAvatar>
        <UniAvatar size='S' label={universityName} />
        <Box>
          <UniTypography variant='subheader' color='typographyBlack100' weight='semi-bold'>
            {programName}
          </UniTypography>
          <UniTypography variant='subheader' color='secondary100' weight='medium'>
            {universityName}
          </UniTypography>
        </Box>
      </StyledHeaderAvatar>
      <Box mb={2}>
        <UniTypography variant='body1' color='secondary100' weight='medium'>
          {t('texts.uploadRequirements')}
        </UniTypography>
      </Box>

      <Controller
        control={control}
        name='uploaded'
        render={({ onChange, value }) => (
          <UniAutocomplete
            inputId='upload-documents'
            label={t('labels.selectDocuments')}
            value={studentProfile?.documents.filter(item => value?.includes(item.id))}
            labelColor='typographyGray100'
            name='required-doc'
            multiple={multiple}
            placeholder={t('placeholders.selectDocuments')}
            handleChange={(event: any, value: Components.Schemas.FileDto[]) => onChange(value.map(({ id }) => id))}
            options={studentProfile?.documents || []}
            getOptionLabel={option => option.label}
            NoOptionsAction={
              <UniButton
                variant='text'
                onMouseDown={e => {
                  e.stopPropagation();
                  const target = document.querySelector('input[name="required-doc"]') as any;
                  const value = target?.value;
                  openAddDocumentDrawer(value);
                }}
              >
                Add new document
              </UniButton>
            }
            width='400px'
          />
        )}
      />
    </>
  );
};

export default Step1;
