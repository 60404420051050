import { Components } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export default function useServiceOptionsRequests(config?: QueryConfig<Components.Schemas.ServiceOptionRequestDto[]>) {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.ServiceOptions.Requests],
    async (_key: string) => client!.ServiceOptionApi_getServiceOptionRequests().then(({ data }) => data),
    {
      ...config,
      refetchOnWindowFocus: false,
    },
  );
}
