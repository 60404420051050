import { Box } from '@material-ui/core';
import { useLogout } from 'api';
import { UniLink } from 'components/_common/uniLink/UniLink';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth, useDrawers } from 'store';
import { defaultDrawerLinks } from '../links';
import { StyledListItem, StyledUniModalDrawerContent } from './MobileDrawerNavigation.style';

interface Props {}

const ModalDrawerNavigation = (props: Props) => {
  const [logout] = useLogout();
  const { closeDrawer } = useDrawers();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const isLoggedIn = useAuth(({ profile }) => !!profile?.id);

  return (
    <StyledUniModalDrawerContent>
      <Box>
        {isLoggedIn && <UniTypography color='stateAlert100'>This drawer will be removed, currently used for testing.</UniTypography>}
        {defaultDrawerLinks.map(({ name, path, external }, index) => {
          var selected = pathname === path;
          return (
            <StyledListItem
              component='a'
              key={index}
              selected={selected}
              button
              onClick={() => {
                closeDrawer();
                external ? window.open(path, 'blank') : push(path);
              }}
              open={true}
            >
              <UniLink color='' size='main' label={name} selected={selected} shouldGrow={true} />
            </StyledListItem>
          );
        })}
      </Box>
      {isLoggedIn && (
        <StyledListItem component='a' button onClick={() => logout()} open={true}>
          <UniLink color='' size='main' label={'Sign out'} alert shouldGrow={true} />
        </StyledListItem>
      )}
    </StyledUniModalDrawerContent>
  );
};

export default ModalDrawerNavigation;
