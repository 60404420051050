import React, { ReactElement } from 'react';
import UniPrepCenterDashboardItem from './UniPrepCenterDashboardItem';
import { StyledPrepCenterDashboard } from './UniPrepCenterDashboard.style';

interface Props {
  items: {
    title: string;
    description: string;
    icon: ReactElement;
    link: string;
    linkName: string;
  }[];
}

export const UniPrepCenterDashboard: React.FC<Props> = ({ items }) => {
  return (
    <StyledPrepCenterDashboard>
      {items.map(item => (
        <UniPrepCenterDashboardItem {...item} />
      ))}
    </StyledPrepCenterDashboard>
  );
};

export default UniPrepCenterDashboard;
