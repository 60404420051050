import { UniCardWrapper } from 'components';
import { Shadows } from '../../constants';
import styled from 'styled-components';

export const PartnerCardWrapper = styled(UniCardWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${Shadows.shadow1};
  gap: ${({ theme }) => theme.spacing(4)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  max-width: ${({ theme }) => theme.spacing(67.5)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-height: ${({ theme }) => theme.spacing(46.25)}px;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)}px;
  }
`;

export const InfoWrapper = styled.div<{ avatar?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 134px;
  align-items: ${({ avatar }) => (avatar ? 'center' : 'flex-start')};
  gap: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: ${({ avatar }) => (avatar ? 'row' : 'column')};
  }
`;

export const MetricWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;
