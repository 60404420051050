import { UniButton, UniTypography } from 'components';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'store';
import { getUserHomeScreen } from 'utils/getHomeForRole';
import { CenteredPageLayout } from './ErrorPages.style';

export const NotFound = () => {
  const {
    profile: { role },
  } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (role) {
      // history.push(getUserHomeScreen(role)!);
    }
  }, [role]);

  return (
    <CenteredPageLayout>
      <UniTypography color='typographyGray70' variant='xl' weight='semi-bold'>
        Oops!
      </UniTypography>
      <UniTypography color='typographyGray100' variant='header3'>
        Page was not found.
      </UniTypography>
      <UniButton fullWidth={false} onClick={() => history.push(getUserHomeScreen(role)!)}>
        Go back
      </UniButton>
    </CenteredPageLayout>
  );
};
