import { Box, Fade } from '@material-ui/core';
import { ReactComponent as ErrorSvg } from 'assets/svg/Icons/Error.svg';
import { Components } from 'client/UniClient';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { StyledSubtitle } from 'components/_forms/Form.style';
import UniPhoneInput from 'components/_inputs/uniPhoneInput/uniPhoneInput';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import UniTextField from 'components/_inputs/uniTextField/UniTextField';
import { Error, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import { useErrorHandler } from 'hooks';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import useInviteHighSchool from '../../../api/highSchools/useInviteHighSchool';
import { countries } from '../../../constants';
import { UniModalDrawerContent } from '../../_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from '../../_common/uniModalDrawer/UniModalDrawerActions';
import InviteHighSchoolSuccess from './InviteHighSchoolSuccess';
interface Props {}

type Inputs = Components.Schemas.InviteHighSchoolRequest;

const InviteHighSchool: React.FC<Props> = ({}) => {
  const { closeDrawer } = useDrawers();
  const { t } = useTranslation('forms');

  const [invite, { isLoading, isSuccess, data, error }] = useInviteHighSchool();
  const inviteError = useErrorHandler(error || undefined);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<Inputs>();

  const onSubmit = (formData: Inputs) => {
    invite(formData);
  };

  if (isSuccess && data)
    return (
      <>
        <UniModalDrawerContent>
          <InviteHighSchoolSuccess highSchoolName={data.name} />
        </UniModalDrawerContent>
        <UniModalDrawerButtons
          PrimaryButton={{
            label: 'Back to highschools',
            onClick: closeDrawer,
          }}
        />
      </>
    );

  return (
    <>
      <UniModalDrawerContent>
        <UniTextField
          fullWidth
          name='name'
          label='High School Name'
          placeholder='High School Name'
          inputRef={register({ required: 'High School name is required!' })}
          helperText={errors?.name?.message}
          error={!!errors?.name?.message}
        />
        {/* Address details */}
        <StyledSubtitle variant='body1' color='secondary100' weight='medium'>
          {t('titles.address')}
        </StyledSubtitle>
        <UniTextField
          fullWidth
          label={t('form.street')}
          placeholder={t('form.street')}
          inputRef={register({
            required: `${t('form.errors.street')}`,
            validate: (value: string) => value.trim() !== '' || `${t('form.errors.street')}`,
          })}
          name='address.addressLine1'
          error={!!errors.address?.addressLine1?.message}
          helperText={errors.address?.addressLine1?.message}
        />
        <UniTextField
          fullWidth
          label={t('form.number_and_info')}
          placeholder={t('form.number_and_info')}
          inputRef={register({
            required: `${t('form.errors.number_and_info')}`,
            validate: (value: string) => value.trim() !== '' || `${t('form.errors.number_and_info')}`,
          })}
          name='address.addressLine2'
          error={!!errors.address?.addressLine2?.message}
          helperText={errors.address?.addressLine2?.message}
        />
        <UniTextField
          label={t('form.postcode')}
          placeholder={t('form.postcode')}
          inputRef={register({
            required: `${t('form.errors.postcode')}`,
          })}
          name='address.postalCode'
          error={!!errors.address?.postalCode?.message}
          helperText={errors.address?.postalCode?.message}
          fullWidth
        />
        <UniTextField
          fullWidth
          label={t('form.town_city')}
          placeholder={t('form.town_city')}
          inputRef={register({
            required: `${t('form.errors.town_city')}`,
            validate: (value: string) => value.trim() !== '' || `${t('form.errors.town_city')}`,
          })}
          name='address.city'
          error={!!errors.address?.city?.message}
          helperText={errors.address?.city?.message}
        />
        <Controller
          control={control}
          name='address.country'
          rules={{ required: `${t('form.errors.country')}` }}
          render={({ value, onChange }) => (
            <UniSelect
              getOptionLabel={({ name }) => name}
              label={t('form.country')}
              placeholder={t('form.country')}
              value={countries.find(item => item.code === value)}
              onChange={value => onChange(value?.code)}
              options={countries}
              error={errors.address?.country?.message}
            />
          )}
        />
        {/* Admin details */}
        <StyledSubtitle variant='body1' color='secondary100' weight='medium'>
          {t('titles.adminDetails')}
        </StyledSubtitle>
        <UniTextField
          fullWidth
          name='admin.firstName'
          label={t('form.first_name')}
          placeholder={t('form.first_name')}
          inputRef={register({ required: 'Admin first name is required!' })}
          helperText={errors?.name?.message}
          error={!!errors?.name?.message}
        />
        <UniTextField
          fullWidth
          name='admin.lastName'
          label={t('form.last_name')}
          placeholder={t('form.last_name')}
          inputRef={register({ required: 'Admin last name is required!' })}
          helperText={errors?.name?.message}
          error={!!errors?.name?.message}
        />
        <UniPhoneInput
          name='admin.phone'
          label={t('form.phone')}
          placeholder={t('form.phone')}
          control={control}
          setValue={setValue}
          inputRef={register({
            required: `${t('form.errors.phone_1')}`,
            minLength: { value: 4, message: t('form.errors.phone_3') },
            maxLength: { value: 15, message: t('form.errors.phone_3') },
          })}
          error={!!errors?.admin?.phone?.message || !!errors?.admin?.phonePrefix?.message}
          helperText={errors?.admin?.phone?.message || errors?.admin?.phonePrefix?.message}
          fullWidth
        />
        <UniTextField
          fullWidth
          label={t('form.email')}
          placeholder={t('form.email')}
          name='admin.email'
          inputRef={register({
            required: `Email is required`,
          })}
        />
      </UniModalDrawerContent>
      <Box ml={3} mb={2}>
        <Fade in={!!inviteError?.message} unmountOnExit>
          <Error>
            <StyledAlertIcon>
              <ErrorSvg />
            </StyledAlertIcon>
            <UniTypography variant='footnote' component='span' color='stateAlert100'>
              {inviteError?.message}
            </UniTypography>
          </Error>
        </Fade>
      </Box>
      <UniModalDrawerButtons
        PrimaryButton={{
          label: 'Invite High School',
          loading: isLoading,
          onClick: handleSubmit(onSubmit),
        }}
        SecondaryButton={{ label: 'Cancel', onClick: closeDrawer }}
      />
    </>
  );
};
export default InviteHighSchool;
