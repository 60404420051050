import styled from 'styled-components';
import { theme } from 'theme';
import { Colors } from '../../constants';

export const StyledTypeHeadline = styled.div`
  padding: ${theme.spacing(2)}px;
  background: ${Colors.Secondary._100};
  border-radius: 8px;
  margin-bottom: 16px;
`;
