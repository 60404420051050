import { Box } from '@material-ui/core';
import { useFile } from 'api';
import UniAvatar from 'components/_common/uniAvatar/UniAvatar';
import UniButton from 'components/_common/uniButton/UniButton';
import { UniCardFooter } from 'components/_common/uniCardFooter/UniCardFooter';
import { useBreakpoints } from 'hooks';
import { ReactElement } from 'react';
import { matchPath } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth, useFooterbar } from 'store';
import { NavigationLink } from '..';
import { UniIconWrapper, UniTypography } from '../..';
import { BrowserRoutes } from '../../../constants';
import { StyledList, StyledListItem, StyledMobileWrapper } from './FooterBarNavigation.style';

interface Props {
  links: NavigationLink[];
  variant?: 'main' | 'predefined';
}

export default function FooterBarNavigation({ variant, links }: Props): ReactElement {
  const { profile } = useAuth();
  const { pathname } = useLocation();
  const { isMobile } = useBreakpoints();
  const { footer, secondaryButton, primaryButton, ...footerProps } = useFooterbar();
  const history = useHistory();
  const isLoggedIn = !!profile?.id;
  const profileImageSrc = useFile({ id: profile.profileImageFile?.id || '' }, { enabled: !!profile.profileImageFile });

  const handleClickLink = (path: string) => {
    history.push(path);
  };

  const variants = {
    show: { opacity: 1, y: 0, transitionDuration: '.2s' },
    hidden: { opacity: 0, y: '100%', transitionDuration: '.2s' },
  };

  if (footer === 'predefined') {
    return (
      <StyledMobileWrapper animate='show' variants={variants} transition={{ duration: 0.1 }}>
        <UniCardFooter
          variants={isMobile ? 'M' : 'L'}
          SecondaryButton={
            secondaryButton ? (
              <UniButton
                size={isMobile ? 'medium' : 'large'}
                color='neutral'
                variant='text'
                fullWidth={false}
                disabled={secondaryButton?.disabled}
                onClick={() => secondaryButton.onClick()}
              >
                {secondaryButton.label}
              </UniButton>
            ) : undefined
          }
          PrimaryButton={
            primaryButton ? (
              <UniButton
                color={primaryButton?.color}
                size={isMobile ? 'medium' : 'large'}
                onClick={() => primaryButton.onClick()}
                fullWidth={false}
                loading={primaryButton?.loading}
                disabled={primaryButton?.disabled}
              >
                {primaryButton.label}
              </UniButton>
            ) : (
              <></>
            )
          }
          {...footerProps}
        />
      </StyledMobileWrapper>
    );
  }
  return (
    <StyledMobileWrapper animate='show' variants={variants} transition={{ duration: 0.1 }}>
      <StyledList component='nav'>
        {links
          .filter(link => link?.isMobile)
          .map(({ icon, name, path, isAvatar }, index) => {
            var selected = pathname === path;
            if (path === BrowserRoutes.Student.Profile.Default) {
              const paths = [
                BrowserRoutes.Student.Profile.Default.replace(':tab', 'programs'),
                BrowserRoutes.Student.Profile.Default.replace(':tab', 'info') + '/personal',
                BrowserRoutes.Student.Profile.Default.replace(':tab', 'info') + '/education',
                BrowserRoutes.Student.Profile.Default.replace(':tab', 'info') + '/extracurricular',
                BrowserRoutes.Student.Profile.Default.replace(':tab', 'info') + '/certifications',
                BrowserRoutes.Student.Profile.Default.replace(':tab', 'info') + '/documents',
                BrowserRoutes.Student.Profile.Default.replace(':tab', 'info'),
              ];
              selected = paths.includes(pathname);
              path = BrowserRoutes.Student.Profile.Default.replace(':tab', 'info');
            }
            if (path === BrowserRoutes.Student.CardList.Default) {
              selected = !!matchPath(pathname, {
                path: path,
                exact: true,
                strict: true,
              });
              path = BrowserRoutes.Student.CardList.Default.replace(':view', 'grouped');
            }
            if (path === BrowserRoutes.Student.Applications.Default) {
              selected = !!matchPath(pathname, {
                path: path,
                exact: true,
                strict: true,
              });

              path = BrowserRoutes.Student.Applications.Default.replace(':tab', 'all-programs');
            }
            if (isAvatar && isLoggedIn) {
              return (
                <StyledListItem disableGutters component='a' key={name} button onClick={() => handleClickLink(path)}>
                  <UniAvatar
                    size='XS'
                    color='secondary100'
                    src={profileImageSrc.data?.url}
                    alt={profile.username}
                    label={`${profile.firstName} ${profile.lastName}`}
                  />
                </StyledListItem>
              );
            }
            return (
              <StyledListItem disableGutters component='a' key={name} button onClick={() => handleClickLink(path)}>
                <UniIconWrapper size={variant === 'main' ? 'main' : 'medium'} color={selected ? 'primary100' : 'typographyGray100'}>
                  {icon}
                </UniIconWrapper>
                {variant === 'main' && (
                  <Box mt={0.5} textAlign='center'>
                    <UniTypography style={{ fontSize: 10 }} variant='footnote' color={selected ? 'primary100' : 'typographyGray100'}>
                      {name}
                    </UniTypography>
                  </Box>
                )}
              </StyledListItem>
            );
          })}
      </StyledList>
      <Box />
    </StyledMobileWrapper>
  );
}
