import React from 'react';
import { Box } from '@material-ui/core';
import { UniChip } from 'components';
import { StyledFooterBox } from './UniWorkshopCardFooter.style';

export interface UniCardFooterProps {
  PrimaryButton: React.ReactElement;
  variants?: 'XS' | 'S' | 'M' | 'L';
  status?: string;
  statusColor?: 'green' | 'grey' | 'red' | 'primary' | undefined;
  type?: 'base' | 'baseUnlabeled' | 'baseStatus' | 'baseUnlabeledStatus' | 'labeled' | 'basic';
  style?: any;
  isPromoted?: boolean;
  align?: 'left' | 'right';
}

export const UniWorkshopCardFooter: React.FC<UniCardFooterProps> = ({
  variants = 'L',
  type = 'base',
  PrimaryButton,
  status,
  statusColor = 'green',
  isPromoted,
  align = 'right',
  ...rest
}) => {
  return (
    <StyledFooterBox size={type} variants={variants} isPromoted={isPromoted} {...rest}>
      <Box display='flex' style={{ alignItems: 'center', justifyContent: align === 'right' ? 'flex-end' : 'flex-start' }}>
        {status && <UniChip color={statusColor} label={status} />}
      </Box>
      <Box display='flex' style={{ alignItems: 'center' }}>
        {PrimaryButton}
      </Box>
    </StyledFooterBox>
  );
};
