import { Box } from '@material-ui/core';
import { UniButton, UniIconWrapper, UniPageTitle, UniTextField } from 'components';
import { UniDate } from 'components/_inputs/uniDate/UniDate';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/Icons/Close.svg';

interface Props {
  register: any;
  control: any;
}

const Step4: React.FC<Props> = ({ register, control }) => {
  const { t } = useTranslation('createProgram');
  const { fields, append, remove } = useFieldArray({
    name: 'admissionRequirements',
    control,
  });

  return (
    <>
      <UniPageTitle title={t('texts.step4title')} />
      <Box mb={3} />
      <Controller
        name='admissionStartDate'
        control={control}
        render={({ value, onChange }) => (
          <UniDate fullWidth value={value} onChange={(value: string) => onChange(value)} label={t('labels.admissionStartDate')} />
        )}
      />
      <Controller
        name='admissionEndDate'
        control={control}
        render={({ value, onChange }) => (
          <UniDate
            fullWidth
            showFutureYears
            value={value}
            onChange={(value: string) => onChange(value)}
            label={t('labels.admissionEndDate')}
          />
        )}
      />
      {fields.map((field, index) => (
        <UniTextField
          fullWidth
          label={t('labels.admissionRequirements')}
          placeholder={t('placeholders.admissionRequirements')}
          key={field.id}
          name={`admissionRequirements[${index}].name`}
          inputRef={register()}
          defaultValue={field.name}
          endAdornment={
            fields.length > index + 1 && (
              <UniIconWrapper onClick={() => remove(index)}>
                <CloseIcon />
              </UniIconWrapper>
            )
          }
        />
      ))}
      <Box mt={-2}>
        <UniButton variant='text' onClick={() => append({})}>
          {t('buttons.addAdmissionRequirements')}
        </UniButton>
      </Box>
      <Box py={3} />
    </>
  );
};

export default Step4;
