import { Box } from '@material-ui/core';
import { UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import { useTranslation } from 'react-i18next';
import { StyledSnackbox } from './Snackbar.style';

interface Props {
  action?: any;
  message?: string;
  actionButtonName?: string;
  undoIt: any;
}

export const SnackbarPro = ({ action, message, actionButtonName = 'Undo', undoIt }: Props) => {
  const { t } = useTranslation('studentProfile');
  const { isMobile } = useBreakpoints();
  return (
    <StyledSnackbox mobile={isMobile}>
      <UniTypography variant='subheader' weight='medium' color='white100'>
        {message}
      </UniTypography>
      {(!!action || actionButtonName) && (
        <Box
          onClick={() => {
            action && action();
            undoIt(true);
          }}
          style={{ cursor: 'pointer' }}
        >
          <UniTypography variant='subheader' weight='medium' color='primary100' style={{ width: 'max-content' }}>
            {actionButtonName}
          </UniTypography>
        </Box>
      )}
    </StyledSnackbox>
  );
};
