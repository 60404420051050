import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import UniTypography from '../../_common/uniTypography/UniTypography';
import { SuccessIllustration } from './InviteHighSchool.style';

interface Props {
  highSchoolName: string;
}

export default function InviteHighSchoolSuccess({ highSchoolName }: Props): ReactElement {
  const { t } = useTranslation('addCandidatePage');
  return (
    <>
      <SuccessIllustration />
      <UniTypography variant='header1' color='secondary100' weight='semi-bold'>
        {`Invitation sent!`}
      </UniTypography>
      <UniTypography variant='body2' color='typographyGray100'>
        {`The admin of ${highSchoolName} has been notified. He/She will receive an email with instructions on how to complete the invitation process.`}
      </UniTypography>
    </>
  );
}
