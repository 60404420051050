import { queryCache, useMutation } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

interface Params {
  serviceOrderId: string;
  serviceOffering: {
    quantity: number;
    serviceOfferingId: string;
  };
}

const useUpdateServiceOrderItem = () => {
  const { client } = useClient();

  return useMutation(
    async ({ serviceOrderId, serviceOffering }: Params) => {
      const serviceOrderResponse = await client?.ServiceOrderApi_setServiceOrderItem({ serviceOrderId }, serviceOffering);

      return serviceOrderResponse;
    },
    {
      onSuccess() {
        queryCache.invalidateQueries(CacheKeys.Student.Orders);
      },
    },
  );
};

export default useUpdateServiceOrderItem;
