import UniCardWrapper from 'components/_common/uniCardWrapper/UniCardWrapper';
import styled from 'styled-components';
import { Colors } from '../../constants';

export const ServiceCardWrapper = styled(UniCardWrapper)<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(3)}px;
  max-width: ${({ theme }) => theme.spacing(100)}px;
  gap: ${({ theme }) => theme.spacing(3)}px;
  ${({ selected }) => selected && `border 1px solid ${Colors.Secondary._100}`};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    user-select: none;
  }
`;

export const CardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
export const ServiceInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;
export const StyledHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledDataArea = styled.div``;

export const StyledActionsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;
export const StyledDescriptionArea = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;
