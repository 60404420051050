import styled from 'styled-components';

export const StyledActionsWrapper = styled.div<{ mobile: boolean }>`
  display: flex;
  ${({ mobile, theme }) =>
    mobile
      ? `   
            width: 100%;
            text-align:center;
            padding-bottom: ${theme.spacing(3)}px;
            gap: ${theme.spacing(1)}px;

        `
      : `
            flex-direction: column;
            justify-content: space-between;
            padding-top: ${theme.spacing(3.125)}px;
        `}
`;
export const StyledHeaderWrapper = styled.div<{ mobile: boolean }>`
  padding: ${({ theme, mobile }) => (mobile ? theme.spacing(2, 2, 2, 2) : theme.spacing(4, 3))};

  justify-content: 'flex-start';
  ${({ mobile }) => !mobile && `display:grid`};
`;
