import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { ApplicationsFiltersOptions } from 'components/_drawers/filtersDrawer/_types/ApplicationsFilters';
import { QueryConfig, useQuery } from 'react-query';
import { OptionType } from 'types';
import { CacheKeys } from '../../constants';
import { languageOptions } from '../../constants/languages';
import { levelOfStudy } from '../../constants/levelOfStudy';
import { useClient } from '../../store';
import { disciplines } from './useProgramFilters';

type Params = {
  _page?: number;
  universityId: string;
};

const parseFaculties = (faculty: Components.Schemas.FacultyDto[]): OptionType[] =>
  faculty.filter(item => faculty.includes(item)).map(item => ({ label: item.name, value: item.id }));

const parsePrograms = (programs: Components.Schemas.ProgramDto[]): OptionType[] =>
  programs.filter(item => programs.includes(item)).map(item => ({ label: item.name, value: item.id }));

export default function useApplicationFilters(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Universities.ApplicationFilters, params],
    async (_key: string, params: Params) => {
      let filters: Partial<ApplicationsFiltersOptions>;
      const { data: missingFilters } = await client!.SearchApi_getProgramFilters();
      const { data: languages } = await client!.SearchApi_getLanguageFilters();

      filters = {
        discipline: disciplines.map(item => ({ value: item.value, label: item.text })),
        levelOfStudy: levelOfStudy
          .filter(item => missingFilters.levelsOfStudy.includes(item.value))
          .map(item => ({ label: item.label, value: item.value })),
        programLanguage: languageOptions
          .filter(item => languages?.includes(item.code))
          .map(item => ({ label: item.name, value: item.code })),
      };

      if (params.universityId) {
        const { data } = await client!.UniversityApi_getUniversityApplicationFilters({ universityId: params.universityId });
        filters.facultyId = parseFaculties(data.facultyNames);
        filters.programId = parsePrograms(data.programNames);
      }

      return filters;
    },
    { refetchOnWindowFocus: false, onError(error: AxiosError) {} },
  );
}
