import styled from 'styled-components';

export const StyledPrepCenterDashboardItem = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
  background: ${({ background }) => background};
`;

export const StyledPrepCenterDashboardItemContentWrapper = styled.div<{ textColor: string }>`
  display: flex;
  padding: 32px;
  flex-direction: row;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  color: ${({ textColor }) => textColor};
`;

export const StyledPrepCenterDashboardItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const StyledPrepCenterDashboardItemIconWrapper = styled.div`
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  background-color: rgba(255, 255, 255, .25);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
`;

export const StyledPrepCenterDashboardItemTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 600;
`;

export const StyledPrepCenterDashboardItemDescription = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
`;
