import styled from 'styled-components';

export const StyledWr = styled.div<{ withHeader: boolean; isMobile: boolean }>`
  width: ${({theme, isMobile}) => isMobile ? `calc(100% + ${theme.spacing(4)}px)` : '100%'};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  grid-gap: ${({ theme }) => theme.spacing(3, 3)};
  
  ${({ theme }) => `${theme.breakpoints.down('sm')}{
    margin-right: ${theme.spacing(-2)}px;
    margin-left: ${theme.spacing(-2)}px;
    padding-left: ${theme.spacing(2)}px;
    display: flex;
    max-width: calc(100% + ${theme.spacing(4)}px);
    grid-gap: ${theme.spacing(1.5, 1.5)};
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }`}
`;
