import { AxiosError } from 'axios';
import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import { QueryConfig, useInfiniteQuery, useQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';

import { useAuth, useClient } from '../../store';

type Params = {
  _page?: number;
  order?: OrderType;
  sort?: 'NAME' | 'DATE_ADDED';
};

export default function useAgents(params?: Params, config?: QueryConfig<Error>) {
  var agencyId = useAuth(state => state.profile.agentProfile?.agency?.id);
  const { client } = useClient();
  return useInfiniteQuery(
    [CacheKeys.Agency.Agents, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.AgencyApi_getAgents({ agencyId: agencyId!, page, pageSize: ApiKeys.PageSize.Candidates, ...params });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Candidates || lastPage.page * ApiKeys.PageSize.Candidates === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
