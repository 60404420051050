import { useFile, useGetUniversity } from 'api';
import { ReactComponent as LinkIcon } from 'assets/svg/Icons/Link.svg';
import { ReactComponent as LocationIcon } from 'assets/svg/Icons/Location.svg';
import { ReactComponent as CreditCardIcon } from 'assets/svg/Icons/Payments.svg';
import { AnimatedTabPanel, UniHeader, UniHerosection, UniIconWrapper, UniPage, UniPageLoader } from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useAuth, useDrawers, useHeader } from 'store';
import { BrowserRoutes } from '../../../constants';
import General from './_tabs/General';
import Programs from './_tabs/Programs';

const StudentUniversityPage: React.FC = () => {
  const { profile } = useAuth();
  const { t } = useTranslation('universityPage');
  const [query] = useState<string>('');
  const { url } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const { data: university, status } = useGetUniversity({ id: id });
  const { openDrawer } = useDrawers();
  const { setHeader, clearHeader } = useHeader();
  const openFilters = () => openDrawer('filter-universitiy-programs', { size: 'small' });

  const generalPath = BrowserRoutes.Student.University.General.replace(':universityId', id);
  const programsPath = BrowserRoutes.Student.University.Program.replace(':universityId', id);
  const profileImageSrc = useFile({ id: profile.profileImageFile?.id || 'id' }, { enabled: !!profile.profileImageFile });

  useEffect(() => {
    setHeader({
      primaryButton: { onClick: () => goBack() },
    });
    return clearHeader;
  }, []);

  if (status === 'loading') return <UniPageLoader />;

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='avatarTitle'
          title={university?.name || ''}
          universityName={university?.name}
          universityId={id}
          profileImageSrc={university?.logoFile?.location}
          avatarVariant='rounded'
          onClickFilters={openFilters}
          withFilterBar
          metrics={[
            {
              label: t('header.meta_items.location'),
              value: `${university?.address.city}, ${university?.address.country}`,
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <LocationIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: t('header.meta_items.tuition_range'),
              value: !!university?.averageTuitionFee.currency.amount
                ? `${university?.averageTuitionFee.currency.amount} eur`
                : t('unspecified'),
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <CreditCardIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: t('header.meta_items.living_cost'),
              value: t('unspecified'),
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <CreditCardIcon />
                </UniIconWrapper>
              ),
            },
            {
              label: t('header.meta_items.website'),
              value: !!university?.virtualAddress?.website ? 'Visit website' : t('unspecified'),
              link: university?.virtualAddress?.website,
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <LinkIcon />
                </UniIconWrapper>
              ),
            },
          ]}
          tabs={[
            { label: t('tabs.general'), path: generalPath },
            { label: t('tabs.programs'), path: programsPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <Switch>
          <Redirect exact path={url} to={`${url}${BrowserRoutes.University.Profile.General}`} />
          <Route
            path={`${url}${BrowserRoutes.University.Profile.General}`}
            render={() => <AnimatedTabPanel index={0} currentItem={0} Content={<General university={university} />} />}
          />
          <Route
            path={`${url}${BrowserRoutes.University.Profile.Programs}`}
            render={() => <AnimatedTabPanel index={1} currentItem={1} Content={<Programs query={query} id={id} />} />}
          />
        </Switch>
      </UniPage>
    </>
  );
};

export default StudentUniversityPage;
