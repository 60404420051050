import { Box } from '@material-ui/core';
import { useProgram } from 'api';
import { AnimatedTabPanel, UniButton, UniHeader, UniIconWrapper, UniPage, UniPageLoader } from 'components';
import { UniHerosection } from 'components/_common/uniHerosection/UniHerosection';
import ProgramGeneralInfo from 'components/_templates/programGeneralInfo/ProgramGeneralInfo';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import { useDrawers, useHeader } from 'store';
import { parseAddress } from 'utils/programUtils';
import { ReactComponent as HourglassIcon } from '../../../assets/svg/Icons/Hourglass.svg';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location.svg';
import { BrowserRoutes } from '../../../constants';
import Applications from './_tabs/Applications';

const ProgramPage: React.FC = () => {
  const { t } = useTranslation('programPage');
  const { programId } = useParams<{ programId: string }>();
  const { url } = useRouteMatch();
  const { goBack } = useHistory();
  const { openDrawer } = useDrawers();
  // const { openDrawer } = useDrawers();
  const { setHeader, clearHeader } = useHeader();
  const applicationsPath = BrowserRoutes.University.Program.Applications.replace(':programId', programId);
  const infoPath = BrowserRoutes.University.Program.ProgramInfo.replace(':programId', programId);
  const currentRoute = url === applicationsPath ? 0 : 1;

  const {
    data: program,
    status: programStatus,
    error: programsError,
  } = useProgram({
    id: programId,
  });

  useEffect(() => {
    setHeader({
      primaryButton: { onClick: () => goBack() },
    });
    return clearHeader;
  }, []);

  const getProgramActions = () => {
    // if (isMaster) {
    //   return <UniButton onClick={() => openDrawer('create-program', { program })}>Edit Program</UniButton>;
    // }
    return (
      <>
        <Box ml={2} style={{ cursor: 'pointer' }} onClick={() => openDrawer('toggle-application-period', { program })}>
          <div style={{ pointerEvents: 'none', display: 'flex' }}>
            <UniButton size='small' variant={program?.blockAdmission ? 'secondary' : 'main'} borderRadius={[16, 0, 0, 16]}>
              Open for applications
            </UniButton>
            <UniButton size='small' variant={program?.blockAdmission ? 'main' : 'secondary'} borderRadius={[0, 16, 16, 0]}>
              Closed for applications
            </UniButton>
          </div>
        </Box>
      </>
    );
  };

  if (!program && programStatus === 'loading') {
    return <UniPageLoader />;
  }

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='base'
          title={program?.name || ''}
          universityName={program?.universityName}
          universityId={program?.universityId}
          Actions={getProgramActions()}
          metrics={[
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <LocationIcon />
                </UniIconWrapper>
              ),
              label: t('header.location'),
              value: parseAddress(program?.address),
            },
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <HourglassIcon />
                </UniIconWrapper>
              ),
              label: t('header.lengthOfStudy'),
              value: program?.lengthOfStudy ? `${program?.lengthOfStudy?.value} ${program?.lengthOfStudy?.unit}` : 'Unspecified',
            },
          ]}
          tabs={[
            { label: t('tabs.applications'), path: applicationsPath },
            { label: t('tabs.program_info'), path: infoPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={BrowserRoutes.University.Program.Applications}>
              <AnimatedTabPanel Content={<Applications program={program!} />} index={0} currentItem={currentRoute} absoluteDirection={-1} />
            </Route>
            <Route path={BrowserRoutes.University.Program.ProgramInfo}>
              <AnimatedTabPanel
                Content={<ProgramGeneralInfo program={program} />}
                index={1}
                currentItem={currentRoute}
                absoluteDirection={1}
              />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default ProgramPage;
