import { devUrl, prodUrl } from 'constants/envConfig';
import { OpenAPIClientAxios } from 'openapi-client-axios';
import create from 'zustand';
import { UniClient } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { getLocalStorage, setLocalStorage } from '../../utils';
import { ClientState } from './types';

const useClient = create<ClientState>((set, get) => {
  return {
    session: getLocalStorage(CacheKeys.Profile.Session) || undefined,
    baseUrl: process.env.NODE_ENV === 'production' ? prodUrl : devUrl,

    client: undefined,

    isClientLoaded: false,

    refreshSession: false,

    initClient: async () => {
      if (get().client === undefined) {
        const api: OpenAPIClientAxios = new OpenAPIClientAxios({
          definition: process.env.NODE_ENV === 'production' ? `${prodUrl}/api/v1/swagger-json` : `${devUrl}/api/v1/swagger-json`,
          withServer: {
            url: process.env.NODE_ENV === 'production' ? prodUrl : devUrl,
          },
        });

        const clientInstance = await api.getClient<UniClient>();
        clientInstance.interceptors.request.use(request => {
          const session = get().session;
          const canIntercept = !(
            request.url?.includes('login') ||
            request.url?.includes('register') ||
            (request.url?.includes('onboarding') && !request.url?.includes('social') && !request.url?.includes('patch'))
          );

          if (session?.token && canIntercept) {
            !request.url?.includes('social');
            request.headers['Authorization'] = `Bearer ${session.token}`;
          }
          return request;
        });
        clientInstance.interceptors.response.use(
          response => {
            return response;
          },
          async error => {
            let sessionValid = true;
            const originalRequest = error.config;
            if (error.response?.status === 401 && error.response?.data?.message !== 'Invalid password' && !originalRequest._retry) {
              // sessionValid = false;
              // set({
              //   refreshSession: !sessionValid,
              // });
            }
            return Promise.reject(error);
          },
        );
        set({
          client: clientInstance,
          isClientLoaded: true,
        });
      }
    },

    resetSession: () => {
      localStorage.removeItem(CacheKeys.Profile.Session);
      localStorage.removeItem(CacheKeys.General.NewSession);
      return set(state => ({ ...state, session: undefined }));
    },

    setSession: session =>
      set(state => {
        setLocalStorage(CacheKeys.General.NewSession, true);
        setLocalStorage(CacheKeys.Profile.Session, session);
        return { ...state, session };
      }),
  };
});

export default useClient;
