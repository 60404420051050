import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { UniButton, UniTypography } from 'components';
import GuardianForm, { GuardianFormInputs } from 'components/_forms/GuardianForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledUniCardFooter } from '../StudentOnboard.style';

export type Step7FormData = GuardianFormInputs;

type Props = {
  defaultValues: Partial<Step7FormData>;
  prevStep: () => void;
  nextStep: (formData: Step7FormData) => void;
  isOver18: boolean;
};

const Step7: React.FC<Props> = ({ defaultValues, isOver18, nextStep, prevStep }) => {
  const { t } = useTranslation('forms');
  const { t: tButtons } = useTranslation('buttons');
  const { register, control, errors, setValue, watch, handleSubmit } = useForm<Step7FormData>({ defaultValues });

  useEffect(() => {
    localStorage.setItem('onboardFormDataStep7', JSON.stringify(watch()));
  }, [watch()]);

  return (
    <>
      <Box mb={4}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          Please enter your guardians contact details bellow:
        </UniTypography>
        <Box mb={2} />
        <GuardianForm register={register} control={control} errors={errors} setValue={setValue} required={!isOver18} />
      </Box>
      <StyledUniCardFooter
        variants='M'
        type='labeled'
        SecondaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            variant='text'
            size={'large'}
            color='neutral'
            fullWidth={false}
            onClick={prevStep}
          >
            {tButtons('previous')}
          </UniButton>
        }
        PrimaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            size={'large'}
            color='primary'
            fullWidth={false}
            onClick={handleSubmit(nextStep)}
          >
            {tButtons('next_step')}
          </UniButton>
        }
      />
    </>
  );
};

export default Step7;
