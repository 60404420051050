import { Drawer, ListItem, ListItemProps } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Colors, Shadows } from '../../../constants';

const drawerOpen = css`
  transition: ${({ theme }) =>
    theme.transitions.create('width', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    })};
`;

const drawerClosed = css`
  transition: ${({ theme }) =>
    theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    })};
`;

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorLeft {
    ${({ open }) => (open ? drawerOpen : drawerClosed)};
    background: ${({ theme }) => Colors.White._100};
    color: ${({ theme }) => Colors.Typography.Gray_100};
    z-index: 200;
    box-shadow: ${Shadows.shadow1};
    overflow-x: hidden;
    width: ${({ theme, open, variant }) => {
      if (variant === 'permanent') {
        return open ? theme.sizes.drawerWidth : theme.sizes.drawerWidthCollapsed;
      }
      return theme.sizes.drawerWidth;
    }}px;
  }
`;

type HeaderProps = {
  open: boolean;
  isMobile?: boolean;
};

export const DrawerHeader = styled.div<HeaderProps>`
  display: grid;
  padding: ${({ theme, open }) => theme.spacing(4, open ? 3 : 2, 2, open ? 3 : 2)};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme, open }) => theme.spacing(4, 0, 3, 0)};
  }
`;

export const StyledLogoHeader = styled.div<{ open: boolean }>`
  margin-left: ${({ theme }) => theme.spacing(3)}px;
  margin-right: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(5)}px !important;
`;

export const StyledMenuHeader = styled.div<{ open: boolean }>`
  margin-right: ${({ theme, open }) => (open ? theme.spacing(1.5) : theme.spacing(2.5))}px;
  margin-left: auto;
`;

interface StyledListItemProps extends ListItemProps {
  component?: React.ElementType;
  open: boolean;
}

export const StyledListItem = styled(ListItem)<StyledListItemProps>`
  position: relative;
  justify-items: start;
  border-left: 1px solid transparent;
  transition: background 200ms ease-in-out;
  padding: ${({ theme }) => theme.spacing(0, 0, 0, 0)};
  min-width: ${({ theme }) => theme.sizes.drawerWidth}px;
  border-left: 1px solid ${({ theme, selected }) => (selected ? Colors.White._100 : 'transparent')};

  &::after {
    content: '';

    transition: opacity 200ms ease-in-out;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: ${Colors.White._100};
    opacity: ${({ selected }) => (selected ? 0.1 : 0)};
  }

  &:hover {
    border-left: 1px solid ${({ theme }) => Colors.White._100};
    background: ${Colors.Gray2._40} !important;
  }
`;
