import { Box } from '@material-ui/core'
import { UniDivider, UniShowMore } from 'components'
import react from 'react'

interface Props {
    nrPrograms: number;
    onClickMore:Function;
    isLoading: boolean;
}

export const ListFooter = ({ nrPrograms, onClickMore, isLoading } : Props ) => {
    return (
        <Box width='100%' display='grid' gridRowGap='20px'>
            <UniDivider />
            <UniShowMore
                isLoading={isLoading}
                variant='showMore'
                nrPrograms={nrPrograms}
                onClickMore={onClickMore}
            />
        </Box>
    )
}