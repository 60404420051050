import { usePatchCandidate } from 'api';
import useUpdateStudent from 'api/students/useUpdateStudent';
import { Components } from 'client/UniClient';
import { PersonalForm, UniModalDrawerButtons } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { PersonalFormInputs } from 'components/_forms/PersonalForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth, useDrawers, useSnackbar } from 'store';
import { getMetaRole } from 'utils';
import { OnboardFormState } from 'views/_student/onboard/StudentOnboard';

interface Props {
  student?: Components.Schemas.UserResponse;
}

const PersonalFormDrawer: React.FC<Props> = ({ student }) => {
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const profile = useAuth(state => state.profile);
  const { t } = useTranslation('forms');
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PersonalFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { ...student, ...student?.studentProfile },
  });
  const { pathname } = useLocation();
  const [updateStudent, { status: updateStudentStatus, error: updateStudentError }] = useUpdateStudent();
  const [updateStudentAgency, { status: updateStudentAgencyStatus, error: updateStudentAgencyError }] = usePatchCandidate();
  const { isAgency, isAgent, isStudent } = getMetaRole(profile?.role);

  const isLoading = updateStudentStatus === 'loading' || updateStudentAgencyStatus === 'loading';

  const onSubmit = (formData: OnboardFormState) => {
    const personalInfo = {
      ...formData,
      studentProfile: {
        dateOfBirth: formData.dateOfBirth,
        countryOfBirth: formData.countryOfBirth!,
        nationality: formData.nationality,
        secondNationality: formData.secondNationality,
        gender: formData.gender,
      },
    };

    if (isStudent) {
      updateStudent(personalInfo, {
        onSuccess() {
          closeDrawer();
          openSnackbar('personal-details');
        },
        onError() {},
      });
    }
    if (isAgency) {
      const studentId = pathname.split('/')[4];
      updateStudentAgency(
        { ...personalInfo, studentId },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('personal-details');
          },
          onError() {},
        },
      );
    }
    if (isAgent) {
      const studentId = pathname.split('/')[4];
      updateStudentAgency(
        { ...personalInfo, studentId },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('personal-details');
          },
          onError() {},
        },
      );
    }
  };

  return (
    <>
      <UniModalDrawerContent>
        <PersonalForm register={register} control={control} errors={errors} />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: t('buttons.save_changes'),
          loading: isLoading,
        }}
        SecondaryButton={{ onClick: closeDrawer, label: t('buttons.cancel') }}
      />
    </>
  );
};

export default PersonalFormDrawer;
