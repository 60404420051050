import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { ReactComponent as KeyboardArrowDownIcon } from '../../assets/svg/Icons/Arrow/Down.svg';
import { ReactComponent as KeyboardArrowUpIcon } from '../../assets/svg/Icons/Arrow/Up.svg';
import { MetaData, StyledCollapsibleRow } from '../../views/_agent/candidateProfile/AgencyCandidateProfile.style';
import UniTypography from '../_common/uniTypography/UniTypography';

type Program = {
  name: string;
  location: string;
  levelOfStudy: string;
  status: string;
  added: string;
};

const useRowStyles = makeStyles({
  root: {
    marginTop: '20px',
    borderBottom: '10px solid transparent',
    '& > *': {},
  },
});

function createData(name: string, location: string, levelOfStudy: string, status: string, added: string) {
  return {
    name,
    location,
    levelOfStudy,
    status,
    added,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}

function Row(props: { row: Program }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align='left'>{row.name}</TableCell>
        <TableCell align='left'>{row.location}</TableCell>
        <TableCell align='left'>{row.levelOfStudy}</TableCell>
        <TableCell align='left'>{row.status}</TableCell>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(prevOpenState => !prevOpenState)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <StyledCollapsibleRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <MetaData>
              <Box>
                <UniTypography>Length</UniTypography>
              </Box>
              <Box>
                <UniTypography>Discipline</UniTypography>
              </Box>
              <Box>
                <UniTypography>Tuition fee</UniTypography>
              </Box>
              <Box>
                <UniTypography>Application fee</UniTypography>
              </Box>
              <Box>
                <UniTypography>Cost of living</UniTypography>
              </Box>
            </MetaData>
          </Collapse>
        </TableCell>
      </StyledCollapsibleRow>
    </React.Fragment>
  );
}

const rows = [
  createData(
    'Bachelor of Design - Enviromental Design Interior Design Specialisation',
    'Toronto, Ontario, Canada',
    'Bachelors, 4 years',
    'Application Ongoing',
    '1',
  ),
  createData(
    'Bachelor of Design - Enviromental Design Interior Design Specialisation',
    'Toronto, Ontario, Canada',
    'Bachelors, 4 years',
    'Application Ongoing',
    '1',
  ),
  createData(
    'Bachelor of Design - Enviromental Design Interior Design Specialisation',
    'Toronto, Ontario, Canada',
    'Bachelors, 4 years',
    'Application Ongoing',
    '1',
  ),
];

export default function CollapsibleTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='left'>Location</TableCell>
            <TableCell align='left'>Level of Study</TableCell>
            <TableCell align='left'>Status</TableCell>
            <TableCell align='left'>Added</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
