import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getUserHomeScreen } from 'utils/getHomeForRole';
import { BrowserRoutes } from '../../constants';
import { useAuth, useClient } from '../../store';

type Payload = {
  token: string;
};

const useRequestSession = () => {
  const { client, setSession } = useClient();
  const { setProfile } = useAuth();
  const history = useHistory();

  return useMutation(
    async ({ token }: Payload) => {
      return client!.AuthApi_getMySession(undefined, undefined, { headers: { Authorization: `Bearer ${token}` } });
    },
    {
      onSuccess: async ({ data }) => {
        setSession(data);
        if (!data.user.studentProfile?.didCompleteOnboarding) {
          history.push(BrowserRoutes.Default.Onboard.Student.replace(':token', data.token).replace(':type', 'social'));
        } else {
          setProfile(data.user);
          history.push(getUserHomeScreen(data.user.role));
        }
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default useRequestSession;
