import { Box } from '@material-ui/core';
import { useSearchGroupedPrograms } from 'api';
import { EmptyState, ListFooter, UniListHeader, UniProgramCard } from 'components';
import WindowedWrapper from 'components/infiniteWindowedGrid/WindowedWrapper';
import { UniCardGroup } from 'components/_common/uniCardGroup/UniCardGroup';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { useProgramsSort } from 'hooks/useProgramsSort';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { parseLevelOfStudy } from 'utils/programUtils';

interface Props {}

const Grouped: React.FC<Props> = ({ children, ...rest }) => {
  const { t } = useTranslation('programsList');
  const { queries, replaceQueriesToUrl } = useQueryStringParser<ProgramsFiltersQueries>();

  const {
    data: groupedPrograms,
    status: groupedProgramsStatus,
    fetchMore: fetchMoreGroupedPrograms,
  } = useSearchGroupedPrograms({
    filters: { ...queries, query: queries.query || undefined },
    sort: queries.sort || undefined,
  });

  const groupedProgramsLoading = groupedProgramsStatus === 'loading';
  const groupedProgramsFlat = useMemo(
    () => groupedPrograms?.map(groupedProgramPage => groupedProgramPage.results).flat() || [],
    [groupedPrograms, groupedProgramsStatus],
  );
  const groupedProgramsTotal = useMemo(() => (groupedPrograms ? groupedPrograms[0].total : 0), [groupedPrograms]);
  const groupedProgramsLeft = useMemo(() => groupedProgramsTotal - groupedProgramsFlat.length, [groupedProgramsFlat, groupedProgramsTotal]);

  const { sortedData, setSortedData, sort } = useProgramsSort(groupedProgramsFlat);

  const handleSort = (value: number) => {
    const sortType = value === 1 ? 'NAME' : 'CHRONOLOGICAL';
    replaceQueriesToUrl({ ...queries, sort: sortType });

    if (sortType === 'NAME') {
      sort((a, b) => {
        const aPrice = a.programs?.[0]?.tuitionFee?.currency?.amount || Infinity;
        const bPrice = b.programs?.[0]?.tuitionFee?.currency?.amount || Infinity;
        return aPrice - bPrice;
      });
    } else if (sortType === 'CHRONOLOGICAL') {
      sort((a, b) => {
        const aName = a.university?.name.toLowerCase() || '';
        const bName = b.university?.name.toLowerCase() || '';
        return aName.localeCompare(bName);
      });
    }
  };

  useEffect(() => {
    if (!queries.sort) {
      setSortedData(groupedProgramsFlat);
    }
  }, [groupedProgramsFlat, queries.sort]);

  return (
    <>
      {groupedProgramsTotal > 0 && (
        <UniListHeader
          count={groupedProgramsTotal}
          options={[
            { value: 0, label: 'By price' },
            { value: 1, label: 'By name' },
          ]}
          onChange={handleSort}
        />
      )}
      <Box mt={2} />
      {sortedData.map((university, index) => (
        <Box mb={5} key={index}>
          <UniCardGroup university={university.university} withHeader>
            {university.programs?.map((program, index) => (
              <WindowedWrapper key={index}>
                <UniProgramCard
                  key={`${index}-program`}
                  program={program}
                  fit
                  flexGrouped
                  noStatus={!!university}
                  metricsArr={[
                    {
                      label: t('headers.level_of_study'),
                      value: parseLevelOfStudy(program.levelOfStudy),
                    },
                    {
                      label: t('program.tuition_fee'),
                      value: program?.tuitionFee?.currency
                        ? `${program.tuitionFee?.currency?.amount} ${program.tuitionFee?.currency?.currencyIso} / ${program.tuitionFee?.lecturePeriod}`
                        : 'Unspecified',
                    },
                    {
                      label: t('program.application_fee'),
                      value: 'Unspecified',
                    },
                  ]}
                />
              </WindowedWrapper>
            ))}
            {university.programs?.length < 4 &&
              Array(4 - university.programs.length)
                .fill(0)
                .map((_, index) => <Box key={index} />)}
          </UniCardGroup>
        </Box>
      ))}
      {groupedProgramsLoading && <UniListLoader />}
      {!groupedProgramsLoading && groupedProgramsTotal > 0 ? (
        <ListFooter isLoading={groupedProgramsLoading} onClickMore={fetchMoreGroupedPrograms} nrPrograms={groupedProgramsLeft} />
      ) : (
        !groupedProgramsLoading && <EmptyState type='programs' />
      )}
    </>
  );
};

export default Grouped;
