import { Box } from '@material-ui/core';
import { useDeleteNotes, useNotes, usePatchNotes, usePostNotes } from 'api';
import { UniBounceLoader, UniTextArea, UniTypography } from 'components';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Note from '../../candidateHeader/Note';
import { UniModalDrawerContent } from '../../_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from '../../_common/uniModalDrawer/UniModalDrawerActions';

interface Props {
  studentId: string;
}

interface NotesForm {
  content: string;
}

const NotesDrawer: React.FC<Props> = ({ studentId }) => {
  const [drawer, setDrawer] = useState('notes');
  const [noteId, setNoteId] = useState('');
  const { data, isLoading: notesLoading, error: notesError } = useNotes({ studentId: studentId });
  const [postNote, { isLoading: postLoading, error: postError }] = usePostNotes();
  const [patchNote, { isLoading: patchLoading, error: patchError }] = usePatchNotes();
  const [deleteNote, { isLoading: deleteLoading, error: deleteErorr }] = useDeleteNotes();

  const isLoading = notesLoading || postLoading || patchLoading || deleteLoading;
  const error = notesError || postError || patchError || deleteErorr;

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<NotesForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const addHandler = () => {
    setDrawer('add-note');
    setValue('content', '');
  };
  const editHandler = (note: any) => {
    setDrawer('edit-note');
    setNoteId(note.id);
    setValue('content', note.content);
  };
  const deleteHandler = (id: string) => {
    deleteNote({ studentId: studentId, studentNoteId: id });
  };

  const backHandler = () => {
    setDrawer('notes');
  };

  const onSubmit = (formData: NotesForm) => {
    drawer === 'add-note'
      ? postNote({ studentId: studentId, content: formData.content })
      : patchNote({ studentId: studentId, studentNoteId: noteId, content: formData.content });
    backHandler();
  };

  return (
    <>
      <UniModalDrawerContent>
        {isLoading ? (
          <UniBounceLoader />
        ) : drawer === 'notes' ? (
          <>
            {!data?.length && !isLoading && (
              <Box mx={4}>
                <UniTypography color='typographyGray70' weight='medium'>
                  No notes created.
                </UniTypography>
              </Box>
            )}
            {data?.map(note => (
              <Note
                id={note.id}
                key={note.id}
                author={note.author}
                createdAt={note.createdAt}
                content={note.content}
                onEdit={editHandler}
                onDelete={deleteHandler}
              />
            ))}
          </>
        ) : (
          <Controller
            name='content'
            control={control}
            rules={{ required: 'Please enter a note', validate: value => value.trim() !== '' || 'Please enter a note' }}
            render={({ value, onChange }) => (
              <UniTextArea
                rowsMin={10}
                value={value}
                onChange={onChange}
                label={drawer === 'edit-note' ? 'Edit note' : 'Add new note'}
                helperText={errors.content?.message}
                withBorder
                borderRadius={[8, 8, 8, 8]}
                variant='body2'
              />
            )}
          />
        )}
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          label: 'Add note',
          onClick: drawer === 'notes' ? addHandler : handleSubmit(onSubmit),
        }}
        SecondaryButton={drawer === 'notes' ? undefined : { label: 'Cancel', onClick: backHandler }}
      />
    </>
  );
};
export default NotesDrawer;
