import React from 'react';
import { Dialog } from '@material-ui/core';

import { Close, Footer, ModalContainer } from './UniModal.style';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/Close.svg';
import { Colors, Shape } from '../../../constants';

export type MaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type UniModal = {
  open: boolean;
  maxWidth?: MaxWidth;
  keepOpen?: boolean;
  handleClose: (e?: any) => void;
};

const UniModal: React.FC<UniModal> = ({ open, handleClose, children, keepOpen = false, maxWidth = 'lg' }) => {
  return (
    <Dialog
      style={{ backgroundColor: Colors.Gray2._100 }}
      scroll='body'
      onClose={() => !keepOpen && handleClose()}
      open={open}
      aria-labelledby='uni modal'
      maxWidth={maxWidth}
      fullWidth
    >
      <Close>
        <UniIconWrapper
          color='typographyGray70'
          onClick={e => {
            e.stopPropagation();
            handleClose(e);
          }}
        >
          <CloseIcon />
        </UniIconWrapper>
      </Close>
      <ModalContainer style={{ color: 'red' }} maxWidth={maxWidth}>
        {children}
      </ModalContainer>
    </Dialog>
  );
};

export default UniModal;

export const UniModalFooter: React.FC = ({ children }) => {
  return <Footer>{children}</Footer>;
};
