import styled from 'styled-components';
import { UniCard } from '../index';
import { Colors } from '../../constants';

export const StyledTutorCard = styled(UniCard)`
  width: 360px;
  height: 580px;
  text-align: center;
`;

export const StyledTutorImageWrapper = styled.div`
  width: 100px;
  max-width: 100px;
  height: 100px;
  max-height: 100px;
  overflow: hidden;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
`;

export const StyledTutorImage = styled.img`
  min-width: 100px;
  min-height: 100px;
`;

export const StyledAchievement = styled.div`
  text-align: left;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledDivider = styled.div`
  width: 40px;
  height: 4px;
  background: ${Colors.Other.Yellow_100};
  margin: ${({ theme }) => theme.spacing(2)}px auto;
`;
