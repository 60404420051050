import { Components } from 'client/UniClient';
import { UniButton, UniIconWrapper, UniLink } from 'components';
import UniDropdown from 'components/_common/uniDropdown/UniDropdown';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useDrawers, useSnackbar } from 'store';
import useCopy from 'use-copy';
import { ReactComponent as ChevronRight } from '../../../assets/svg/Icons/ChevronRight.svg';
import { BrowserRoutes } from '../../../constants';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ResumeTemplate from './ResumeTemplate';

interface Props {
  studentProfile?: Components.Schemas.StudentProfileDto;
  studentId?: string;
  agencyId?: string;
}

const StudentProfileActions: React.FC<Props> = ({ studentProfile, agencyId, studentId }) => {
  const publicProfileLink = `${window.location.origin}${BrowserRoutes.Default.Public.StudentProfile(studentId, 'info')}`;
  const [_, copy, setCopied] = useCopy(publicProfileLink);
  const { openDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const history = useHistory();
  const [dropdown, setDropdown] = useState(false);

  const copyPublicProfileLink = () => {
    copy();
    setDropdown(false);
    openSnackbar('public-profile-link');
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const onClickEditPublicProfile = () => history.push(BrowserRoutes.Student.Profile.PublicProfile('info'));

  const onClickViewPublicProfile = () => history.push(BrowserRoutes.Default.Public.StudentProfile(studentId, 'info'));

  const onClickUnpublishProfile = () => {
    openDrawer('unpublish-public-profile');
    setDropdown(false);
  };

  const onClickDownloadResume = () => {
    // download();
  };

  if (!studentProfile || !studentId || !agencyId) return <></>;

  return (
    <>
      {studentProfile?.publicProfilePreferences?.isPublished ? (
        <UniDropdown
          open={dropdown}
          handleOpen={() => setDropdown(true)}
          handleClose={() => setDropdown(false)}
          ActionButton={
            <UniButton
              variant='secondary'
              endIcon={
                <UniIconWrapper size='medium' color='primary100'>
                  <ChevronRight />
                </UniIconWrapper>
              }
            >
              Public Profile
            </UniButton>
          }
        >
          <UniLink size='medium' label='Edit Public Profile' fullWidth onClick={onClickEditPublicProfile} />

          <UniLink size='medium' label='Go to Public Profile' fullWidth onClick={onClickViewPublicProfile} />
          <UniLink size='medium' label='Copy Public Profile Link' fullWidth onClick={copyPublicProfileLink} />
          <PDFDownloadLink document={<ResumeTemplate />} fileName='YouniProfile'>
            <UniLink size='medium' label='Export Public Profile as PDF' fullWidth onClick={onClickDownloadResume} />
          </PDFDownloadLink>
          <UniLink size='medium' label='Unpublish Public Profile' alert fullWidth onClick={onClickUnpublishProfile} />
        </UniDropdown>
      ) : (
        <UniButton variant='secondary' onClick={onClickEditPublicProfile}>
          Public Profile
        </UniButton>
      )}
    </>
  );
};

export default StudentProfileActions;
