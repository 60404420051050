import { Box } from '@material-ui/core';
import { UniAvatar, UniDragNDrop, UniTextArea } from 'components';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { ReplyToStudentInputs } from '../ReplyToStudent';
import { StyledHeaderAvatar } from '../ReplyToStudent.style';

interface Props {
  control: Control<ReplyToStudentInputs>;
  universityName?: string;
  programName?: string;
  studentName?: string;
}

const Step3: React.FC<Props> = ({ control, universityName = '', studentName, programName = '' }) => {
  return (
    <>
      <Box mb={2}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          {`You are about to reply to ${studentName}’s application for:`}
        </UniTypography>
      </Box>
      <StyledHeaderAvatar>
        <UniAvatar size='S' label={universityName} />
        <Box>
          <UniTypography variant='subheader' color='typographyBlack100' weight='semi-bold'>
            {programName}
          </UniTypography>
          <UniTypography variant='subheader' color='secondary100' weight='medium'>
            {universityName}
          </UniTypography>
        </Box>
      </StyledHeaderAvatar>
      <Controller
        control={control}
        name='message'
        render={({ value, onChange }) => (
          <UniTextArea withBorder defaultValue={value} rowsMin={6} onChange={(e: any) => onChange(e.target.value)} />
        )}
      />
      <Box mt={3} />
      <Controller
        control={control}
        name='documents'
        render={({ value, onChange }) => (
          <UniDragNDrop
            label='Upload Documents'
            fullWidth
            multiple
            accept='application/pdf, image/jpg, image/png'
            value={value}
            onDelete={newFiles => onChange(newFiles)}
            handleChange={(files: File[]) => {
              if (files.length > 0) {
                onChange(files);
              }
            }}
          >
            <UniTypography color='typographyGray100'>Drag & Drop or</UniTypography>
            <UniTypography color='primary100'>Upload JPG, PDF or PNG file</UniTypography>
          </UniDragNDrop>
        )}
      />
    </>
  );
};

export default Step3;
