import styled from 'styled-components';

export const StyledPrepCenterOrders = styled.div`
`;

export const StyledPrepCenterOrdersHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
  margin: ${({ theme }) => theme.spacing(2, 1)};
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;
