import { AuthLayout } from 'components';
import { useBreakpoints } from 'hooks';
import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { BrowserRoutes } from '../../constants';
import ConfirmEmail from './confirm-email/ConfirmEmail';
import EmailSent from './email-sent/EmailSent';
import ForgotPassword from './forgot-password/ForgotPassword';
import Login from './login/Login';
import MagicLinkLogin from './magic-link/MagicLinkLogin';
import MagicLinkSignup from './magic-link/MagicLinkSignup';
import ResetPassword from './reset-password/ResetPassword';
import Signup from './signup/Signup';
import TokenConnect from './token-connect/TokenConnect';

interface QueryProps {
  view:
    | 'login'
    | 'magic-login'
    | 'signup'
    | 'magic-signup'
    | 'forgot-password'
    | 'reset-password'
    | 'magic-link'
    | 'social'
    | 'email-sent'
    | 'verify-email';
}

const Authentication = () => {
  const { view } = useParams<QueryProps>();
  const { isMobile } = useBreakpoints();

  const parseTitle = () => {
    switch (view) {
      case 'magic-login':
        return 'Log In';
      case 'login':
        return 'Log In';
      case 'magic-signup':
        return isMobile ? 'Sign Up' : 'Create Account';
      case 'signup':
        return isMobile ? 'Sign Up' : 'Create Account';
      case 'reset-password':
        return 'Reset Password';
      case 'forgot-password':
        return 'Forgot Password';
      case 'email-sent':
        return 'Email sent';
      case 'verify-email':
        return 'Verifying your email';
      default:
        return 'Connect';
    }
  };

  if (view === 'social' || view === 'magic-link') {
    return <TokenConnect />;
  }

  return (
    <AuthLayout title={parseTitle()}>
      <Switch>
        <Route path={BrowserRoutes.Default.Auth.Login} component={Login} />
        <Route path={BrowserRoutes.Default.Auth.SignUp} component={Signup} />
        <Route path={BrowserRoutes.Default.Auth.MagicLinkLogin} component={MagicLinkLogin} />
        <Route path={BrowserRoutes.Default.Auth.MagicLinkSignup} component={MagicLinkSignup} />
        <Route path={BrowserRoutes.Default.Auth.ForgotPassword} component={ForgotPassword} />
        <Route path={BrowserRoutes.Default.Auth.ResetPassword} component={ResetPassword} />
        <Route path={BrowserRoutes.Default.Auth.EmailSent} component={EmailSent} />
        <Route path={BrowserRoutes.Default.Auth.VerifyEmail} component={ConfirmEmail} />
      </Switch>
    </AuthLayout>
  );
};
export default Authentication;
