import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { LeftArrow, RightArrow } from './Arrows';
import { StyledElement } from './UniCardsCarousel.style';
import useDrag from './UseDrag';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
interface Props {
  name: string;
  cardWidth: number;
  cardHeight?: number;
  elements: React.ReactNode[];
}

const YouniCarousel: React.FC<Props> = ({ elements, name, cardWidth, cardHeight }) => {
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }: any) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, posDiff => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });
  return (
    <>
      <div style={{ width: '100%' }} onMouseLeave={dragStop}>
        <ScrollMenu
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
          LeftArrow={<LeftArrow id={`${name}-left`} />}
          RightArrow={<RightArrow id={`${name}-right`} />}
          // onWheel={onWheel}
        >
          {elements.map((element, index) => {
            return (
              <StyledElement dragging={dragging} itemId={index.toString()} cardWidth={cardWidth} cardHeight={cardHeight}>
                {element}
              </StyledElement>
            );
          })}
        </ScrollMenu>
      </div>
    </>
  );
};
export default YouniCarousel;
