import { useEffect, useState } from 'react';

const useScroll = () => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>();

  const listener = (e: any) => {
    setScrollY(e.target?.scrollTop || 0);
    setScrollX(e.target?.scrollLeft || 0);
    setScrollDirection(lastScrollTop > e.target?.scrollTop ? 'up' : 'down');
    setLastScrollTop(e.target?.scrollTop);
  };

  useEffect(() => {
    let uniPage = document.getElementById('uni-page-container');
    if (uniPage) {
      uniPage.onscroll = listener;
    }
    return () => {
      // uniPage.removeEventListener('scroll', listener);
    };
  });

  return {
    scrollY,
    scrollX,
    scrollDirection,
  };
};

export default useScroll;
