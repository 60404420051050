import styled from 'styled-components';
import { ReactComponent as LogoIconSvg } from '../../../assets/svg/LogoIcon.svg';
import { ReactComponent as LogoNameDarkSvg } from '../../../assets/svg/LogoNameDark.svg';
import { ReactComponent as LogoNameLightSvg } from '../../../assets/svg/LogoNameLight.svg';

export const LogoStyle = styled.div`
  display: flex;
  align-items: center;
  margin-left: -16px;
`;

export const StyledLogoIcon = styled(LogoIconSvg)<{ size: any; stretched: boolean }>`
  margin-right: 4px;
  width: ${({ theme, size }) => (size === 'S' ? theme.spacing(6) : size === 'M' ? theme.spacing(4.5) : theme.spacing(5))}px;
  height: ${({ theme, size }) => (size === 'S' ? theme.spacing(7) : size === 'M' ? theme.spacing(5.5) : theme.spacing(5))}px;
`;

export const StyledDarkIcon = styled(LogoNameDarkSvg)<{ size: any }>`
  height: ${({ theme, size }) =>
    size === 'S' ? theme.spacing(3.5) : size === 'L' ? theme.spacing(5) : size === 'M' ? theme.spacing(4) : '80px'};
  width: ${({ size }) => (size === 'L' ? '175px' : size === 'XL' ? '225px' : size === 'M' ? '140px' : '90px')};
`;

export const StyledLightIcon = styled(LogoNameLightSvg)<{ size: any }>`
  height: ${({ theme, size }) =>
    size === 'S' ? theme.spacing(3.5) : size === 'L' ? theme.spacing(5) : size === 'M' ? theme.spacing(4) : '80px'};
  width: ${({ size }) => (size === 'L' ? '175px' : size === 'XL' ? '225px' : size === 'M' ? '140px' : '90px')};
`;

export const StyledTitleWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: ${({ theme }) => theme.spacing(10, 0, 7, 0)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: ${({ theme }) => theme.spacing(5, 0, 3)};
  }
`;
