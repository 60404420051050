import { ReactElement } from 'react';
import UniTypography from '../../_common/uniTypography/UniTypography';
import { SuccessIllustration } from './InviteHighSchoolStudent.style';

interface Props {
  studentName: string;
}

export default function InviteCandidateSuccess({ studentName }: Props): ReactElement {
  return (
    <>
      <SuccessIllustration />
      <UniTypography variant='header1' color='secondary100' weight='semi-bold'>
        {`Invitation sent!`}
      </UniTypography>
      <UniTypography variant='body2' color='typographyGray100'>
        {`${studentName} has been notified. He/She will receive an email with instructions on how to complete the invitation process.`}
      </UniTypography>
    </>
  );
}
