import { Link } from 'react-router-dom';
import { Popper } from '@material-ui/core';
import { Colors, Shadows } from 'constants/uiConfig';
import styled from 'styled-components';

export const StyledDropdown = styled(Popper)`
  background-color: ${Colors.Primary._100};
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  margin-left: ${({ theme }) => theme.spacing(-1)}px;
  padding: ${({ theme }) => theme.spacing(1, 1)};
  box-shadow: ${Shadows.shadow2};
  border-radius: ${({ theme }) => theme.spacing(0.75)}px;

  top: 100%;
  left: -5px;
  z-index: 999;
`;

export const StyledAuthNavbar = styled.div<{ isSticky: boolean[]; isLoggedIn: boolean }>`
  display: flex;
  justify-content: space-between;
  height: ${({ theme }) => theme.sizes.authNavbarHeight}px;
  align-items: center;
  z-index: 99;
  position: sticky;
  top: 0;
  background: ${({ isLoggedIn }) => (!isLoggedIn ? Colors.Secondary._100 : Colors.White._100)};
  ${({ isSticky }) => isSticky?.[1] && `box-shadow: ${Shadows.shadow1};`}
  ${({ isSticky }) => !isSticky?.[0] && `transform: translateY(-100%);`}
  transition: .2s all;

  width: calc(100% + ${({ theme }) => theme.spacing(4)}px);
  margin: ${({ theme }) => theme.spacing(0, -2)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: calc(100% + ${({ theme }) => theme.spacing(6)}px);
    margin: ${({ theme }) => theme.spacing(0, -3)};
    padding: ${({ theme }) => theme.spacing(0, 3)};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: calc(100% + ${({ theme }) => theme.spacing(12)}px);
    margin: ${({ theme }) => theme.spacing(0, -6)};
    padding: ${({ theme }) => theme.spacing(0, 6)};
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  :hover {
    text-decoration: none;
  }
`;

export const SpecialCaseWrapper = styled.div<{ isSticky: boolean[] }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  max-height: ${({ theme }) => theme.spacing(5)}px;
  position: sticky;
  top: 0;
  background: ${Colors.White._100};
  ${({ isSticky }) => isSticky?.[1] && `box-shadow: ${Shadows.shadow1};`}
  ${({ isSticky }) => !isSticky?.[0] && `transform: translateY(-100%);`}
  transition: .2s all;

  width: calc(100% + ${({ theme }) => theme.spacing(4)}px);
  margin: ${({ theme }) => theme.spacing(0, -2)};
  padding: ${({ theme }) => theme.spacing(2, 2, 0, 2)};
`;
