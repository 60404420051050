import { AxiosError } from 'axios';
import { QueryConfig, useQuery } from 'react-query';
import { getMetaRole } from 'utils';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

type Params = {
  query?: string;
  _page?: number;
};

export default function useSearchCandidates(params: Params, config?: QueryConfig<Error>) {
  const agencyId = useAuth(state => state.profile.agentProfile?.agency?.id);
  const agentId = useAuth(state => state.profile.id);
  const role = useAuth(state => state.profile?.role);
  const { client } = useClient();
  const { isAgency, isAgent } = getMetaRole(role!);

  return useQuery(
    [CacheKeys.Agency.SearchCandidates, params],
    async (_key: string, params: Params) => {
      const { data } = isAgency
        ? await client!.AgencyApi_getAgencyStudentNames({ agencyId: agencyId! })
        : isAgent
        ? await client!.AgencyApi_getAgentStudentNames({
            agencyId: agencyId || '',
            agentId: agentId || '',
          })
        : { data: [] };
      return data;
    },
    { enabled: role !== 'ADMIN', refetchOnWindowFocus: false, onError(error: AxiosError) {} },
  );
}
