import React from 'react';
import { Box } from '@material-ui/core';
import { StyledSessionHistoryHeader } from './SessionHistory.style';
import { UniTypography } from '../index';

interface Props {}

export const SessionHistoryHeader: React.FC<Props> = () => {
  return (
    <StyledSessionHistoryHeader>
      <Box />
      <UniTypography weight='medium' variant='subheader'>
        Product
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Date
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Count
      </UniTypography>
    </StyledSessionHistoryHeader>
  );
};

export default SessionHistoryHeader;
