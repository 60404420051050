import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { useCandidateFilters } from 'api';
import UniBounceLoader from 'components/_common/uniBounceLoader/UniBounceLoader';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import FiltersDrawerActions from 'components/_drawers/filtersDrawer/FiltersDrawerActions';
import { useErrorHandler } from 'hooks';
import QueryString from 'query-string';
import React, { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CandidateFilters } from 'types/candidateFilters';
import UniTypography from '../../../../_common/uniTypography/UniTypography';
import UniCheckbox from '../../../../_inputs/uniCheckbox/UniCheckbox';

export default function FilterAgentsForm(): ReactElement {
  const [fieldReset, setFieldReset] = useState(false);
  const history = useHistory();
  const { data: filters, status: candidatesFiltersStatus, error: candidatesFiltersError } = useCandidateFilters();
  const filterError = useErrorHandler(candidatesFiltersError!);
  const { register, handleSubmit, setValue, errors, control, reset } = useForm<CandidateFilters>({ defaultValues: {} });

  const onSubmit = (formData: CandidateFilters) => {
    history.push({ pathname: history.location.pathname, search: QueryString.stringify(formData) });
  };

  if (candidatesFiltersStatus === 'loading') {
    return <UniBounceLoader />;
  }

  return (
    <>
      <UniModalDrawerContent>
        <Box mb={2}>
          {/* <Controller
          control={control}
          name='applicationStatus'
          render={() => (
            <UniAutocomplete
              fullWidth
              multiple
              width='350px'
              key={`applicationStatus-${fieldReset}`}
              handleChange={(event: any, value: any[]) => {
                setValue('applicationStatus', value?.map((item: any) => item.value) || []);
              }}
              name='schoolName'
              options={filters!.applicationStatuses?.map(item => ({
                text: applicationStatusMap[item],
                value: item,
              }))}
              getOptionLabel={option => option.text}
              label='Application status'
              labelColor='primary100'
            />
          )}
        /> */}
        </Box>
        <Box mb={2}>
          {/* <Controller
          control={control}
          name='nationality'
          render={() => (
            <UniAutocomplete
              fullWidth
              multiple
              width='350px'
              key={`nationality-${fieldReset}`}
              handleChange={(event: any, value: any[]) => {
                setValue('nationality', value?.map((item: any) => item.code) || []);
              }}
              name='nationality'
              options={countries.filter(item => filters?.nationality.includes(item.code as Nationality))}
              getOptionLabel={option => option.name}
              label='Nationality'
              labelColor='primary100'
            />
          )}
        /> */}
        </Box>

        <Box>
          <Box>
            <UniTypography color='primary100'>By level of study</UniTypography>
          </Box>
          <FormControlLabel
            value='BACHELOR'
            name='levelOfStudy'
            key={`bachelor-${fieldReset}`}
            inputRef={register()}
            control={<Checkbox />}
            label='Bachelor'
          />
          <FormControlLabel
            value='MASTER'
            name='levelOfStudy'
            key={`master-${fieldReset}`}
            inputRef={register()}
            control={<Checkbox />}
            label='Master'
          />
          <FormControlLabel
            value='DOCTOR'
            name='levelOfStudy'
            key={`doctor-${fieldReset}`}
            inputRef={register()}
            control={<Checkbox />}
            label='Doctor'
          />
        </Box>
        <Box display='flex' alignItems='center'>
          <UniTypography color='primary100'>Starred</UniTypography>
          <Controller
            control={control}
            name='starred'
            render={({ onChange, onBlur, value }) => (
              <UniCheckbox key={`starred-${fieldReset}`} onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={value} />
            )}
          />
        </Box>
        <Box m={10} />
      </UniModalDrawerContent>
      <FiltersDrawerActions handleSubmit={handleSubmit(onSubmit)} />
    </>
  );
}
