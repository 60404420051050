import usePostRequestsProgram from 'api/programs/usePostRequests';
import usePostRequestsUniversity from 'api/university/usePostRequests';
import { UniAutocomplete, UniButton, UniTextArea, UniTextField, UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'types';
import { Colors } from '../../constants';
import { ActionWrapper, InputWrapper, SuggestProgramWrapper } from './SuggestProgram.style';

interface Inputs {
  name: string;
  link: string;
  note: string;
}

type SuggestType = 'PROGRAM' | 'UNIVERSITY';

const SuggestProgram: React.FC = () => {
  const [type, setType] = useState<SuggestType>('PROGRAM');
  const { register, handleSubmit, control } = useForm<Inputs>({ mode: 'onSubmit' });
  const [requestProgram, { isLoading: isLoadingProgram }] = usePostRequestsProgram();
  const [requestUniversity, { isLoading: isLoadingUniversity }] = usePostRequestsUniversity();
  const { t } = useTranslation('forms');
  const { isMobile } = useBreakpoints();
  const [suggestionSent, setSuggestionSent] = useState(false);
  const isLoading = isLoadingProgram || isLoadingUniversity;

  const onSubmit = (formData: Inputs) => {
    switch (type) {
      case 'PROGRAM':
        requestProgram(
          {
            programName: formData.name,
            url: formData.link,
            note: formData.note,
          },
          {
            onSuccess() {
              setSuggestionSent(true);
            },
          },
        );
        break;
      case 'UNIVERSITY':
        requestUniversity(
          {
            universityName: formData.name,
            url: formData.link,
            note: formData.note,
          },
          {
            onSuccess() {
              setSuggestionSent(true);
            },
          },
        );
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    setSuggestionSent(false);
  };

  const typeOptions = [
    { label: 'Program', value: 'PROGRAM' },
    { label: 'University', value: 'UNIVERSITY' },
  ];

  return (
    <SuggestProgramWrapper size='L' backgroundColor='secondary10' shadow='shadow1' border>
      <UniTypography variant='header3' weight='semi-bold' color='secondary100' style={{ textAlign: 'center' }}>
        {!suggestionSent ? t('titles.program.suggest') : t('titles.program.suggetion_sent.1')}
      </UniTypography>
      <InputWrapper>
        {!suggestionSent && (
          <>
            <UniTextField
              name='name'
              label='Name'
              inputRef={register({ minLength: 1 })}
              placeholder='Type the name of the program'
              backgroundColor={Colors.White._100}
              fullWidth
              multiline
            />
            <UniAutocomplete
              disableClearable
              label='Type'
              getOptionLabel={option => option.label}
              fullWidth
              placeholder={isMobile ? t('date.mobile.year') : t('date.desktop.year')}
              optionsSortType='asc'
              value={typeOptions.find(item => item.value === type)}
              options={typeOptions}
              handleChange={(e: any, { value }: OptionType<SuggestType>) => setType(value)}
            />
            <UniTextField
              name='link'
              label='Link'
              inputRef={register({ minLength: 1 })}
              placeholder='Paste the link of the program or university'
              backgroundColor={Colors.White._100}
              fullWidth
              multiline
            />
            <Controller
              name='note'
              control={control}
              render={({ value, onChange }) => (
                <UniTextArea
                  rowsMin={2}
                  rowsMax={10}
                  value={value}
                  placeholder='Any optional notes you might have...'
                  onChange={onChange}
                  label='Note'
                  withBorder
                  borderRadius={[8, 8, 8, 8]}
                  variant='subheader'
                />
              )}
            />
          </>
        )}
        <ActionWrapper sent={suggestionSent}>
          <UniTypography variant='subheader'>
            You will receive a notification after we review and add the suggested program to Youni
          </UniTypography>
          <UniButton
            size='medium'
            onClick={suggestionSent ? handleClick : handleSubmit(onSubmit)}
            loading={isLoading}
            style={{ minWidth: 184 }}
          >
            {!suggestionSent ? t('buttons.send_suggestion') : t('buttons.send_another')}
          </UniButton>
        </ActionWrapper>
      </InputWrapper>
    </SuggestProgramWrapper>
  );
};

export default SuggestProgram;
