import React from 'react'
import styled from 'styled-components'

export const GuidingIllustration = styled.div<{isMobile:boolean}>`
${({isMobile, theme})=> isMobile && 
    `
    height: ${theme.spacing(6)}px; 
    margin: 0 auto 24px auto;`
}`


export const GuidingStateWrapper = styled.div<{isMobile:boolean}>`
${({isMobile,theme}) => isMobile ? 
    `
    display:grid;
    text-align:start;`
    : 
    `
    display:flex;
    gap:${theme.spacing(3)}px;
    `
}`

export const StyledChecked = styled.div`
    height: ${({theme})=> theme.spacing(2)}px;
    width: ${({theme})=> theme.spacing(2)}px;
`