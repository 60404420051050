import React from 'react';
import UniTypography from '../uniTypography/UniTypography';
import { StyledProgressCircle, StyledProgressLabel } from './UniProgressCircle.style';

export type Size = 's' | 'm' | 'l' | 'xl' | 'xxl';
type ProgressProps = {
  size: Size;
  index: number;
  label?: string;
  selected?: boolean;
  passed?: boolean;
};

const UniProgressCircle: React.FC<ProgressProps> = ({ size, index, label, selected, passed }) => {
  return (
    <div>
      <StyledProgressCircle size={size} selected={selected} passed={passed}>
        <UniTypography
          color={selected || passed ? 'white100' : 'primary100'}
          variant={size === 'xxl' ? 'body1' : size === 'xl' ? 'subheader' : 'footnote'}
        >
          {index}
        </UniTypography>
      </StyledProgressCircle>
      <StyledProgressLabel>
        <UniTypography
          variant={size === 's' || size === 'm' ? 'subheader' : 'body2'}
          color={selected ? 'typographyBlack100' : 'typographyGray100'}
          weight='regular'
        >
          {label}
        </UniTypography>
      </StyledProgressLabel>
    </div>
  );
};

export default UniProgressCircle;
