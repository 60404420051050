import { UniModalDrawer, UniStepper } from 'components';
import UniSnackbar from 'components/_common/uniSnackbar/UniSnackbar';
import { useBreakpoints, useStudentStepperData } from 'hooks';
import useInitAppData from 'hooks/useInitAppData';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth, useSnackbar } from '../../../store';
import { getMetaRole } from '../../../utils';
import { EmptyAgencyModal } from '../../../views/_agency/home/EmptyAgencyModal';
import { UniNavigation } from '../../_navigation';
import { Content, StepperWrapper, StyledLayout } from './Layout.style';

const Layout: React.FC<any> = ({ children }) => {
  const isLoggedIn = useAuth(state => !!state?.profile?.id);
  const { role, isFirstLogin, studentProfile } = useAuth(state => state.profile);
  const [stretchContent, setStretchContent] = useState(false);
  const [showEmptyAgencyModal, setShowEmptyAgencyModal] = useState(!!isFirstLogin);
  const { isAgency } = getMetaRole(role);
  const { isStudent } = getMetaRole(role);
  const { isMobile } = useBreakpoints();
  const { pathname } = useLocation();
  const { openSnackbar } = useSnackbar();
  const steps = useStudentStepperData();
  const notAuthPage =
    !pathname.includes('/auth/') &&
    !pathname.includes('app/invite') &&
    !pathname.includes('app/onboard') &&
    !pathname.includes('invalid-token');
  useInitAppData();

  const [showStepper, setShowStepper] = useState(isStudent ? !studentProfile?.completedPostOnboarding : false);
  const [completedAllSteps, setCompletedAllSteps] = useState(!steps.some(step => step.completed === false));

  const stretchHandler = (state: boolean) => {
    setStretchContent(state);
  };

  const closeHandler = () => {
    completedAllSteps && openSnackbar('completed-stepper');
    setShowStepper(false);
  };

  useEffect(() => {
    setCompletedAllSteps(!steps.some(step => step.completed === false));
  }, [steps]);

  useEffect(() => {
    setShowStepper(isStudent ? !studentProfile?.completedPostOnboarding : false);
  }, [isStudent, studentProfile]);

  return (
    <>
      <UniSnackbar />
      <StyledLayout>
        <Content id='uni-page-container' stretchCotent={stretchContent && !isMobile} isLoggedIn={isLoggedIn}>
          {notAuthPage && <UniNavigation setStretchContent={stretchHandler} />}
          {showStepper && (
            <StepperWrapper>
              <UniStepper
                steps={steps}
                onClose={closeHandler}
                completedPostOnboarding={studentProfile?.completedPostOnboarding}
                allStepsCompleted={completedAllSteps}
              />
            </StepperWrapper>
          )}
          {children}
        </Content>
        {isAgency && (
          <EmptyAgencyModal
            show={showEmptyAgencyModal}
            handleClose={() => {
              setShowEmptyAgencyModal(false);
            }}
          />
        )}
      </StyledLayout>
      <UniModalDrawer />
    </>
  );
};

export default Layout;
