import UniCookieNotification from 'components/uniCookieNotification/UniCookieNotification';
import useInitAppData from 'hooks/useInitAppData';
import { UniPageLoader } from './components';
import Router from './router';

const App = () => {
  const { initialized } = useInitAppData();

  return (
    <>
      <UniCookieNotification />
      {initialized ? <Router /> : <UniPageLoader />}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </>
  );
};

export default App;
