import { TRole } from './role';

export type StudentProfileTabsParam = 'info' | 'status' | 'education' | 'documents' | 'activity' | ':view';

export const getBaseURL = (role?: TRole): string => {
  if (role === 'MASTER' || role === 'ADMIN') return '/app/admin';
  if (role === 'AGENCY_HOLDING' || role === 'AGENCY' || role === 'AGENT') return '/app/agency';
  if (role === 'UNIVERSITY' || role === 'FACULTY_MEMBER') return '/app/university';
  if (role === 'STUDENT') return '/app/student';
  if (role === 'HIGH_SCHOOL') return '/app/high-school';
  return '';
};

export const UniversalRoutes = (base: string = '') =>
  ({
    Default: `${base}/`,
    // Tutorials: `${base}/tutorials`,
    Profile: `${base}/profile`,
    Programs: `${base}/programs`,
    Applications: `${base}/applications`,
    Public: {
      StudentProfile: (studentId: string = ':studentId', view: StudentProfileTabsParam = ':view') =>
        `/app/public/student/${studentId}/${view}`,
    },
    Program: {
      Default: `${base}/programs/:programId/:view`,
      General: `${base}/programs/:programId/general`,
      Status: `${base}/programs/:programId/status`,
      Credentials: `${base}/programs/:programId/credentials`,
      Tasks: `${base}/programs/:programId/tasks`,
    },
    University: {
      Default: `${base}/universities/:universityId/:view`,
      General: `${base}/universities/:universityId/general`,
      Program: `${base}/universities/:universityId/programs`,
    },
    CardList: {
      Default: `${base}/programs/:view`,
      Grouped: `${base}/programs/grouped`,
      Programs: `${base}/programs/programs`,
      Universities: `${base}/programs/universities`,
    },
    Application: {
      Default: `${base}/applications/:id/:view`,
      General: (applicationId: string) => `${base}/applications/${applicationId}/info`,
      Status: (applicationId: string) => `${base}/applications/${applicationId}/status`,
      Tasks: (applicationId: string) => `${base}/applications/${applicationId}/tasks`,
      Credentials: (applicationId: string) => `${base}/applications/${applicationId}/credentials`,
      Student: (applicationId: string) => `${base}/applications/${applicationId}/student`,
      Documents: (applicationId: string) => `${base}/applications/${applicationId}/documents`,
    },
    StudentProgram: {
      Default: `${base}/programs/:programId/:studentId/:view`,
      General: `${base}/programs/:programId/:studentId/info`,
      Status: `${base}/programs/:programId/:studentId/status`,
      Student: `${base}/programs/:programId/:studentId/student`,
      Documents: `${base}/programs/:programId/:studentId/documents`,
    },
    Students: {
      Default: `${base}/candidates/:tab`,
      My: `${base}/candidates/my`,
      All: `${base}/candidates/all`,
    },
    Student: {
      Default: `${base}/candidate/:studentId/:tab`,
      Personal: (studentId: string) => `${base}/candidate/${studentId}/personal`,
      Education: (studentId: string) => `${base}/candidate/${studentId}/education`,
      Documents: (studentId: string) => `${base}/candidate/${studentId}/documents`,
      Applications: (studentId: string) => `${base}/candidate/${studentId}/applications`,
      Orders: (studentId: string) => `${base}/candidate/${studentId}/orders`,
      ActivityPlan: (studentId: string) => `${base}/candidate/${studentId}/activity-plan`,
    },
    AgencyStudent: {
      Default: `${base}/:agencyId/candidate/:studentId/:tab`,
      Personal: (studentId: string, agencyId?: string) => `${base}/${agencyId}/candidate/${studentId}/personal`,
      Education: (studentId: string, agencyId?: string) => `${base}/${agencyId}/candidate/${studentId}/education`,
      Documents: (studentId: string, agencyId?: string) => `${base}/${agencyId}/candidate/${studentId}/documents`,
      Applications: (studentId: string, agencyId?: string) => `${base}/${agencyId}/candidate/${studentId}/applications`,
      Orders: (studentId: string, agencyId?: string) => `${base}/${agencyId}/candidate/${studentId}/orders`,
    },
    EmailUpdateVerifyNew: `/app/change/email/:token`,
    QRCode: {
      Create: `${base}/qr/create`,
      Achievement: (token: string = ':token') => `/app/qr/achievement/${token}`,
    },
    Event: {
      Default: (eventId: string = ':eventId', view: string = ':view') => `${base}/events/${eventId}/${view}`,
      Info: (eventId: string = ':eventId') => `${base}/events/${eventId}/info`,
      Timeline: (eventId: string = ':eventId') => `${base}/events/${eventId}/timeline`,
      Participants: (eventId: string = ':eventId') => `${base}/events/${eventId}/participants`,
    },
    Events: {
      Default: (view: string = ':view') => `${base}/events/${view}`,
      All: `${base}/events/all`,
      Interested: `${base}/events/interested`,
      Going: `${base}/events/going`,
    },
  } as const);

export const BrowserRoutes = {
  Admin: {
    ...UniversalRoutes(getBaseURL('ADMIN')),
    AgenciesForAdmin: '/app/admin/agencies',
    AgentsForAgencyAdmin: '/app/admin/agencies/:id',
    Universities: '/app/admin/universities',
    Services: {
      Default: '/app/admin/services/:tab',
      AllServices: '/app/admin/services/allServices',
      Suggestions: '/app/admin/services/suggestions',
    },
    Requests: '/app/admin/programs/requests',
    ProgramForAdmin: '/app/admin/program/:programId',
    Program: {
      Default: `/app/admin/programs/:programId/:view`,
      Applications: `/app/admin/programs/:programId/applications`,
      ProgramInfo: `/app/admin/programs/:programId/info`,
    },
    PrepCenter: {
      Default: '/app/admin/prep-center/:tab',
      Products: '/app/admin/prep-center/products',
      Product: '/app/admin/prep-center/products/:productId',
      Packages: '/app/admin/prep-center/packages',
      Package: '/app/admin/prep-center/packages/:packageId',
      PurchasedPrepPacks: '/app/admin/prep-center/purchased-packs',
      PurchasedPrepPack: '/app/admin/prep-center/purchased-packs/:purchasedPrepPackId',
      StudentCheckIn: '/app/admin/student-check-in/:prepPackId/:studentId',
    },
    University: {
      Default: '/app/admin/universities/:id/:view',
      Applications: '/app/admin/universities/:id/applications',
      Programmes: '/app/admin/universities/:id/programmes',
      UniversityInfo: '/app/admin/universities/:id/info',
    },
    HighSchools: '/app/admin/high-schools',
    HighSchool: {
      Default: '/app/admin/high-schools/:id/:view',
    },
  },
  Agent: {
    ...UniversalRoutes(getBaseURL('AGENCY')),
    Applications: '/app/agency/applications',
    Agent: (agencyId: string = ':agencyId', agentId: string = ':agentId') => `/app/agency/${agencyId}/agent/${agentId}`,
    Profile: '/app/agency/agent/profile',
    Schools: '/app/agency/schools',
    Payments: '/app/agency/payments',
    Statistics: '/app/agency/statistics',
    Universities: '/app/agency/universities',
    UniversityProfile: '/app/agent/universities/:id',
    Team: '/app/agency/team',
    Admin: '/app/agency/admin',
    Services: {
      Default: '/app/agency/services/:view',
      OurServices: '/app/agency/services/our-services',
      AllServices: '/app/agency/services/all-services',
    },
  },
  Agency: {
    ...UniversalRoutes(getBaseURL('AGENCY')),
    Applications: '/app/agency/applications',
    Agent: '/app/agency/:agencyId/agent/:agentId',
    Profile: '/app/agency/agent/profile',
    Schools: '/app/agency/schools',
    Payments: '/app/agency/payments',
    Universities: '/app/agency/universities',
    UniversityProfile: '/app/agency/universities/:id',
    Statistics: '/app/agency/statistics',
    Team: '/app/agency/team',
    Admin: '/app/agency/admin',
  },
  Student: {
    ...UniversalRoutes(getBaseURL('STUDENT')),
    Profile: {
      Default: '/app/student/profile/:tab',
      PersonalInfo: '/app/student/profile/info',
      PublicProfile: (view: string = ':view') => `/app/student/public/profile/${view}`,
      Education: '/app/student/profile/education',
      Documents: '/app/student/profile/documents',
      Orders: '/app/student/profile/orders',
      ActivityPlan: `/app/student/profile/activity-plan`,
      Programs: {
        Default: 'programs', //programs
        SubRoutes: {
          Answered: '/answered',
          Ongoing: '/ongoing',
          Suggested: '/suggested',
          Selected: '/selected',
        },
      },
      Info: {
        Default: 'info',
        SubRoutes: {
          PersonalInfo: '/personal',
          Preferences: '/preferences',
          EducationHistory: '/education',
          ExtracurricularHistory: '/extracurricular',
          CertificationsHistory: '/certifications',
          MyDocuments: '/documents',
        },
      },
    },
    AiSearch: '/app/student/ai-search',
    Universities: '/app/student/universities',
    UniversityProfile: '/app/student/universities/:id',
    // Applications: '/app/student/applications',
    Consultancy: {
      Default: '/app/student/consultant/:tab',
      Services: '/app/student/consultant/services',
      Orders: '/app/student/consultant/orders',
      Consultant: '/app/student/consultant/counselor',
    },
    AppTracker: {
      Default: '/app/student/app-tracker/:tab',
      Workshops: '/app/student/app-tracker/workshops',
      SummerSchools: '/app/student/app-tracker/summer-schools',
      InternshipsAndVolunteering: '/app/student/app-tracker/internships-and-volunteering',
      PassionProject: '/app/student/app-tracker/passion-project',
    },
    PrepCenter: {
      Default: '/app/student/prep-center/:tab',
      Dashboard: '/app/student/prep-center/dashboard',
      Products: '/app/student/prep-center/products',
      Product: '/app/student/prep-center/products/:productId',
      CheckOut: '/app/student/prep-center/check-out',
      CheckIn: '/app/student/prep-center/check-in/:prepPackId',
      Sessions: {
        Default: '/app/student/prep-center/sessions/:tab',
        Paid: '/app/student/prep-center/sessions/paid',
        History: '/app/student/prep-center/sessions/history',
        Orders: '/app/student/prep-center/sessions/orders',
      },
      Professors: {
        Default: '/app/student/prep-center/professors/:field',
        Math: '/app/student/prep-center/professors/math',
        Science: '/app/student/prep-center/professors/science',
        Languages: '/app/student/prep-center/professors/languages',
      }
    },
    Applications: {
      Default: '/app/student/applications/:tab',
      AllPrograms: '/app/student/applications/all-programs',
      Saved: '/app/student/applications/saved',
      Ongoing: '/app/student/applications/ongoing',
      Answered: '/app/student/applications/answered',
      Suggested: '/app/student/applications/suggested',
    },
    Agents: '/app/student/agents',
    Agent: '/app/student/agency/:agencyId/agent/:agentId',
    Payments: '/app/student/payments',
    Education: '/app/student/education',
    Documents: '/app/student/documents',
  },
  HighSchool: {
    ...UniversalRoutes(getBaseURL('HIGH_SCHOOL')),
  },
  University: {
    ...UniversalRoutes(getBaseURL('UNIVERSITY')),
    Default: '/app/university',
    Candidates: '/app/university/candidates',
    Applications: '/app/university/applications',
    Programs: '/app/university/programs',
    Program: {
      Default: `/app/university/programs/:programId/:view`,
      Applications: `/app/university/programs/:programId/applications`,
      ProgramInfo: `/app/university/programs/:programId/info`,
    },
    Profile: {
      Default: 'app/university/profile',
      Programs: '/programs',
      General: '/general',
    },
    Faculties: '/app/university/faculties',
    Faculty: {
      Programs: '/app/university/faculties/:facultyId/programs',
      General: '/app/university/faculties/:facultyId/general',
    },
  },
  Default: {
    ...UniversalRoutes(),
    Continent: (continent: string = ':continent') => `/continent/${continent}`,
    Country: {
      Country: (continent: string = ':continent', country: string = ':country') => `/continent/${continent}/country/${country}`,
      About: (continent: string = ':continent', country: string = ':country') => `/continent/${continent}/country/${country}/about`,
      Counsellors: (continent: string = ':continent', country: string = ':country') =>
        `/continent/${continent}/country/${country}/counsellors`,
    },
    Homepage: '/',
    CssCheck: {
      Default: '/css-check',
      ThankYou: '/css-check/thank-you'
    },
    Student: '/student',
    Agency: '/agency',
    University: '/university',
    About: '/about-us',
    AssessmentQuiz: '/assessment-quiz',
    InvalidToken: '/app/invalid-token',
    Onboard: {
      Student: '/app/onboard/student/:type/:token',
      HighSchool: '/app/onboard/highschool/:type/:token',
    },
    InviteByLink: '/app/invite-link/:token',
    AgentInvite: '/app/agent-invite/:token',
    AgencyInvite: '/app/agency-invite/:token',
    Unauthorized: '/app/unauthorized',
    Auth: {
      Base: '/auth/:view',
      Login: '/auth/login',
      MagicLinkLogin: '/auth/magic-login',
      SignUp: '/auth/signup',
      MagicLinkSignup: '/auth/magic-signup',
      ForgotPassword: '/auth/forgot-password',
      EmailSent: '/auth/email-sent/:type/:email/:token',
      VerifyEmail: '/auth/verify-email',
      ResetPassword: '/auth/reset-password/:token',
      Social: '/auth/social/:connect-token',
      MagicLinkConnect: '/auth/magic-link/:connect-token',
    },
    NotFound: '/app/not-found',
  },
  Partners: {
    THE: {
      Default: '/partner/times-higher-education/:tab',
      Home: '/partner/times-higher-education/home',
      Programs: '/partner/times-higher-education/programs',
    },
  },
  Notifications: {
    Admin: {
      AgencyProfile: '/app/admin/agencies/:id',
    },
    Agency: {
      Agent: '/app/agency/:agencyId/agent/:agentId',
    },
    Agent: {
      Candidate: '/app/agency/candidate/:id/info',
      ProgramsByPreferences: '/app/agency/programs?candidateId=:id',
      CandidateProgramsSelection: '/app/agency/candidate/:id?addedByCandidate=1',
      CandidateProgramsOngoing: '/app/agency/candidate/:id/programs?filterBy=ONGOING',
      CandidatePersonalInfo: '/app/agency/candidate/:id?personalInfo=1',
      CandidateEducation: '/app/agency/candidate/:id?educationHistory=1',
    },
    Candidate: {
      PersonalInfo: '/app/student/profile/info/personal',
      Education: '/app/student/profile?educationHistory=1',
      ProgramsOngoing: '/app/student/profile/programs?filterBy=ONGOING',
      ProgramsSugested: '/app/student/profile?suggestedPrograms=1',
      AplicationPage: '/app/student/program/:programId',
      Suggested: '/app/student/applications/suggested',
    },
    University: {},
  },
} as const;

export type BrowserRoutesKeys = keyof typeof BrowserRoutes;
