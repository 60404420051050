import { Box } from '@material-ui/core';
import { useAgentNames, useCandidateFilters, useGetAgencyNames } from 'api';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import FiltersDrawerActions from 'components/_drawers/filtersDrawer/FiltersDrawerActions';
import { CandidatesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/CandidatesFilters';
import { DefaultFormValuesCandidates } from 'constants/defaultFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import UniAutocomplete from '../../../../_inputs/uniAutocomplete/UniAutocomplete';

interface Props {
  close: Function;
}

export default function FilterCandidatesForm({ close }: Props): ReactElement {
  const { t } = useTranslation('filterCandidatesPage');
  const { replaceQueriesToUrl, queries } = useQueryStringParser<CandidatesFiltersQueries>();
  const { role, agentProfile } = useAuth(state => state.profile);
  const { isAdmin, isAgency } = getMetaRole(role);
  const [queriesChange, setQueriesChange] = useState(false);
  const { handleSubmit, control, reset, watch } = useForm<CandidatesFiltersQueries>({
    defaultValues: { ...DefaultFormValuesCandidates, ...queries, agencyId: isAgency ? [agentProfile?.agency.id] : queries.agencyId },
    shouldUnregister: false,
  });
  let formAgencyId = watch().agencyId;
  formAgencyId = formAgencyId ? [formAgencyId].flat() : undefined;

  const { data: candidatesFilters, status: candidatesFiltersStatus } = useCandidateFilters();
  const { data: agencyOptions, status: agencyOptionsStatus } = useGetAgencyNames({
    enabled: isAdmin,
  });
  const { data: agentOptions, status: agentOptionsStatus } = useAgentNames(
    { agencyId: isAgency ? agentProfile?.agency.id || '' : formAgencyId?.[0] || '' },
    {
      enabled: isAgency ? true : formAgencyId && formAgencyId.length === 1,
    },
  );
  const isLoading = candidatesFiltersStatus === 'loading' || agencyOptionsStatus === 'loading' || agentOptionsStatus === 'loading';

  const onSubmit = (formData: CandidatesFiltersQueries) => {
    close();
    var formData1: Partial<CandidatesFiltersQueries> = {};
    if (formData.starred == false) {
      formData1 = {
        ...formData,
        starred: undefined,
      };
    } else {
      formData1 = {
        ...formData,
      };
    }
    replaceQueriesToUrl(formData1);
  };

  useEffect(() => {
    if (queries?.clear) {
      setQueriesChange(prev => !prev);
      reset(DefaultFormValuesCandidates);
    }
  }, [queries?.clear]);

  if (isLoading) {
    return <UniListLoader />;
  }

  return (
    <>
      <UniModalDrawerContent>
        {isAdmin && (
          <Box mb={1}>
            <Controller
              control={control}
              name='agencyId'
              render={({ onChange, value }) => (
                <UniAutocomplete
                  fullWidth
                  multiple
                  width='350px'
                  value={agencyOptions?.filter(item => value?.includes(item.value))}
                  handleChange={(event: any, value: any[]) => {
                    onChange(value?.map((item: any) => item.value) || []);
                  }}
                  name='status'
                  options={agencyOptions}
                  getOptionLabel={option => option!.label}
                  label={t('labels.agency')}
                  labelColor='typographyBlack100'
                />
              )}
            />
          </Box>
        )}
        {formAgencyId && formAgencyId.length === 1 && (
          <Box mb={1}>
            <Controller
              control={control}
              name='agentId'
              render={({ onChange, value }) => (
                <UniAutocomplete
                  fullWidth
                  width='350px'
                  value={agentOptions?.find(item => item.value === value)}
                  handleChange={(event: any, option: any) => {
                    onChange(option?.value);
                  }}
                  name='status'
                  options={agentOptions}
                  getOptionLabel={option => option!.label}
                  label={t('labels.agent')}
                  labelColor='typographyBlack100'
                />
              )}
            />
          </Box>
        )}
        <Box mb={1}>
          <Controller
            control={control}
            name='status'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                value={candidatesFilters?.status?.filter(item => value?.includes(item.value))}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.value) || []);
                }}
                name='status'
                options={candidatesFilters?.status}
                getOptionLabel={option => option!.label}
                label={t('labels.candidate_status')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='nationality'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                isLoading={isLoading}
                key={`nationality-${queriesChange}`}
                value={candidatesFilters?.nationality?.filter(item => value?.includes(item.value))}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.value) || []);
                }}
                name='nationality'
                options={candidatesFilters?.nationality}
                getOptionLabel={option => option.label}
                label={t('labels.nationality')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='country'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                key={`country-${queriesChange}`}
                value={candidatesFilters?.country?.filter(item => value?.includes(item.value))}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.value) || []);
                }}
                name='country'
                options={candidatesFilters?.country}
                getOptionLabel={option => option.label}
                label={t('labels.residency')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='levelOfStudy'
            render={({ value, onChange }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                isLoading={isLoading}
                key={`levelOfStudy-${queriesChange}`}
                value={candidatesFilters?.graduationLevel?.filter(item => value?.includes(item.value))}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map(item => item.value) || []);
                }}
                name='levelOfStudy'
                options={candidatesFilters?.graduationLevel}
                getOptionLabel={option => option.label}
                label={t('labels.level_of_study.title')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='countryOfStudy'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                key={`countryOfStudy-${queriesChange}`}
                value={candidatesFilters?.countryOfStudy?.filter(item => value?.includes(item.value))}
                handleChange={(event: any, value: any[]) => {
                  onChange(value?.map((item: any) => item.value) || []);
                }}
                name='countryOfStudy'
                options={candidatesFilters?.countryOfStudy}
                getOptionLabel={option => option.label}
                label={t('labels.country_of_study')}
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box m={10} />
      </UniModalDrawerContent>
      <FiltersDrawerActions handleSubmit={handleSubmit(onSubmit)} />
    </>
  );
}
