import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useClient } from "../../store";


type Payload = {
    email: string;
};
export const useForgotPassword = () => {
    const { client } = useClient();

    return useMutation(
        async (payload: Payload) => {
            return client!.AuthApi_requestResetPassword(undefined, payload)
        },
        {
            onError: (error: AxiosError) => {
                console.error(error.message)
            },
        }
    );
};
