import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import UniTextField from 'components/_inputs/uniTextField/UniTextField';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { countries } from '../../../../constants';

interface Props {
  control: any;
  register: any;
  errors: any;
}

const Step1b: React.FC<Props> = ({ control, register, errors }) => {
  const { t } = useTranslation('forms');

  return (
    <>
      <UniTextField
        label={t('form.street')}
        inputRef={register({ required: 'Field is required' })}
        name='address.addressLine1'
        error={!!errors.address?.addressLine1?.message}
        helperText={errors.address?.addressLine1?.message}
        fullWidth
      />
      <UniTextField
        label={t('form.postcode')}
        inputRef={register()}
        name='address.postalCode'
        error={!!errors.address?.postalCode?.message}
        helperText={errors.address?.postalCode?.message}
        fullWidth
      />
      <UniTextField
        label={t('form.town_city')}
        inputRef={register({ required: 'Field is required' })}
        name='address.city'
        error={!!errors.address?.city?.message}
        helperText={errors.address?.city?.message}
        fullWidth
      />
      <Controller
        control={control}
        name='address.country'
        rules={{ required: 'Field is required' }}
        render={({ value, onChange }) => (
          <UniSelect
            label={t('form.country')}
            placeholder='Give a category to your item'
            options={countries}
            error={errors?.address?.country?.message}
            getOptionLabel={option => option.name}
            value={countries.find(item => item.code === value)}
            onChange={option => onChange(option?.code)}
          />
        )}
      />
    </>
  );
};

export default Step1b;
