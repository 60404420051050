import React from 'react';
import { useAuth } from 'store';
import { StyledHeader, StyledInnerHeader } from './UniHeader.style';

export const UniHeader: React.FC = ({ children, ...props }) => {
  const isLoggedIn = useAuth(state => !!state.profile?.id);

  return (
    <StyledHeader hasDrawer={isLoggedIn} {...props}>
      <StyledInnerHeader>{children}</StyledInnerHeader>
    </StyledHeader>
  );
};
