import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useClient } from '../../store';
import { CurrencyIso } from '../../types';

type Payload = {
  firstName: string;
  lastName: string;
  feeAmount: number;
  feeCurrency: CurrencyIso;
  feeType: 'UPFRONT' | 'FIFTY_FIFTY' | 'AFTER';
  username: string;
  password: string;
  email: string;
  token: string;
  terms: boolean;
  phone: string;
  phonePrefix: string;
};

const useCompleteAgentOnboarding = () => {
  const history = useHistory();
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AuthApi_completeAgentOnboarding(undefined, {
        fee: {
          amount: +payload.feeAmount,
          currencyIso: payload.feeCurrency,
        },
        feeType: payload.feeType,
        password: payload.password,
        phone: payload.phone,
        phonePrefix: payload.phonePrefix,
        token: payload.token,
      });
    },

    {
      onSuccess: async response => {
        // setSession(response.data);
        // const { data } = await client!.UserApi_getMyUser();
        // setProfile(data);
      },
      onError: (error: AxiosError) => {
        // history.push(BrowserRoutes.Default.InvalidToken);
      },
    },
  );
};
export default useCompleteAgentOnboarding;
