import styled from "styled-components";
import {Colors} from '../../../constants/uiConfig'

type CardProps = {
    background?: string;
}

export const Card = styled.div<CardProps>`
  background: ${({ theme, background }) => background ?? Colors.Gray1._100};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 100%;
  border: none;
`;

export const CardMetric = styled(Card)`
  box-shadow: 0 10px 18px #9A9EBD26;
  padding: 1rem 1.2rem;
  display: flex;
  place-content: left;
  text-align: left;
  min-height: 87px;
  min-width: 200px;
  position: relative;
  background: ${({ background }) => background};
`;

export const Trending = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.7)}px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const CardLoader = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  background: rgba(255, 255, 255, 0.96);
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
