import React from 'react';
import { useTranslation } from 'react-i18next';
import { UniTypography } from '../../components';
import { StyledDocumentsListHeader } from './AdditionalDocuments.style';

const DocumentsListHeader: React.FC = () => {
  const { t } = useTranslation('additionalDocuments');
  return (
    <StyledDocumentsListHeader>
      <UniTypography weight='medium' variant='subheader'>
        {t('labels.header.name')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        {t('labels.header.date_added')}
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        {t('labels.header.actions')}
      </UniTypography>
    </StyledDocumentsListHeader>
  );
};

export default DocumentsListHeader;
