import { Box } from '@material-ui/core';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../constants';

export const StyledHeader = styled.div`
  background-color: #fff;
  width: calc(100% + 48px);
  margin: -${({ theme }) => theme.sizes.authNavbarHeight + 40}px -24px 0 -24px;
  padding: ${({ theme }) => theme.spacing(7)}px 24px 0 24px;
  box-shadow: ${Shadows.shadow2};
`;

export const StyledInnerHeader = styled.div`
  margin: 0 auto;
  padding: 0 40px;
  padding-left: 80px;
`;
export const UniversityMeta = styled.div<{ onScrollCollapse?: boolean }>`
  display: flex;
  justify-content: space-between;
  transition: 0.2s;
  overflow-y: hidden;
  margin: ${({ theme }) => theme.spacing(5, 0, 0, 0)};
  gap: ${({ theme }) => theme.spacing(3)}px;
`;
export const MetaItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;
export const UniversityHeader = styled.div<{
  isSticky?: boolean;
  changeNavbar?: boolean;
}>``;
export const UniPageStyled = styled.main`
  margin: -50px auto;
  padding: 40px 20px;
  /* max-width: ${({ theme }) => theme.sizes.authMaxPageWidth}px; */
  //max-width: 1280px;
  min-height: ${({ theme }) => `calc(100vh - ${theme.sizes.authNavbarHeight}px - ${theme.spacing(5)}px)`};
`;

export const StyledUniPageGeneralWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(2)}px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const StyledDataCard = styled.div`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  box-shadow: ${Shadows.shadow2};
  padding: ${({ theme }) => theme.spacing(3, 4)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;
export const StyledDivider = styled.div`
  height: 3px;
  width: 100%;
  background-color: ${Colors.Gray1._100};
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const StyledDisciplineCard = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(3)}px;
  background-color: ${Colors.Typography.Gray_10};
  justify-content: center;
  border-radius: ${Shape.BorderRadius.xl}px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: 0.2s;

  background-color: transparent;
  z-index: 9999;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ isOpen }) => (isOpen ? 'pointer-events: initial;' : 'pointer-events: none;')}
  ${({ isOpen }) => isOpen && `background-color: ${Colors.Gray1._100}99;`}
`;

export const StyledCarousel = styled(Slider)<{ hasOnClick?: boolean }>`
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // flex-wrap: wrap;
  // height: 100%;
  // padding-left: ${({ theme }) => theme.spacing(7)}px;
  // padding-right: ${({ theme }) => theme.spacing(5)}px;
  z-index: 0;

  .slick-list {
    // height: 90%;
    // width: 70%;
    order: -1;
    //margin: 0 -${({ theme }) => theme.spacing(10)}px;
  }
  .slick-slide {
    // margin-left: 50%;
    //cursor: ${({ hasOnClick }) => (hasOnClick ? 'pointer' : 'none')};
  }
`;
