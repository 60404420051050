import { Box } from '@material-ui/core';
import { UniAutocomplete, UniPageTitle, UniTextField } from 'components';
import UniPhoneInput from 'components/_inputs/uniPhoneInput/uniPhoneInput';
import { countries } from 'constants/countries';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
  register: any;
  control: any;
  errors: any;
  setValue: Function;
}

const Step2: React.FC<Props> = ({ register, control, setValue, errors }) => {
  const { t } = useTranslation('createProgram');

  return (
    <>
      <UniPageTitle title={t('texts.step2title')} />
      <Box mb={3} />
      <Controller
        name='country'
        control={control}
        render={({ value, onChange }) => (
          <UniAutocomplete
            fullWidth
            value={countries.find(item => item?.code === value)}
            handleChange={(e: any, item: any) => onChange(item?.code)}
            getOptionLabel={item => item.name}
            label={t('labels.country')}
            placeholder={t('placeholders.country')}
            options={countries}
          />
        )}
      />
      <UniTextField fullWidth label={t('labels.city')} placeholder={t('placeholders.city')} name='city' inputRef={register()} />
      <UniTextField fullWidth label={t('labels.website')} placeholder={t('placeholders.website')} name='website' inputRef={register()} />
      <UniTextField fullWidth label={t('labels.email')} placeholder={t('placeholders.email')} name='email' inputRef={register()} />
      <UniPhoneInput
        name='phone'
        label={t('labels.phone')}
        control={control}
        setValue={setValue}
        inputRef={register()}
        fullWidth
        error={!!errors.phone?.message || !!errors.phonePrefix?.message}
        helperText={errors.phone?.message || errors.phonePrefix?.message}
      />
      <Box py={3} />
    </>
  );
};

export default Step2;
