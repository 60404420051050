import { Layout } from 'components';
import { Route, RouteProps } from 'react-router';

interface Props extends RouteProps {
  component: any;
}

const LayoutRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default LayoutRoute;
