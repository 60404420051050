export const applicationStatusMap = {
  ENROLLED: 'Enrolled',
  NEW: 'New',
  VALIDATED: 'Verified',
  SUBMITTED: 'Submitted',
  IN_REVIEW: 'In review',
  INCOMPLETE: 'Incomplete',
  PASSED: 'Enrolled',
  FAILED: 'Rejected',
  CONDITIONAL_ADMISSION: 'Conditional',
  PENDING_PAYMENT: 'Await payment',
  PENDING_APPLICATION_FEE: 'Applied',
};

export const candidateStatusMap = {
  ENROLLED: 'Enrolled',
  INVITATION_SENT: 'Invitation sent',
  ACCOUNT_ACTIVATED: 'Account activated',
  PREFERENCES_COMPLETED: 'Preferences completed',
  PROFILE_COMPLETED: 'Profile completed',
  APPLICATIONS_ONGOING: 'Applications ongoing',
};
