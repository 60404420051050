import { Box, useMediaQuery } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { useIntersectionObserver } from 'hooks';
import { default as React, ReactElement, useRef } from 'react';
import UniBounceLoader from '../_common/uniBounceLoader/UniBounceLoader';
import UniButton from '../_common/uniButton/UniButton';
import UniIconWrapper from '../_common/uniIconWrapper/UniIconWrapper';
import { SelectedProgramsWrapper, StyledProgramsList } from './ProgramsList.style';
import ProgramsListHeader from './ProgramsListHeader';

interface Props {
  hideTableHeader?: boolean;
  length: number;
  fetchMore: Function;
  hasMore: boolean;
  stretch?: boolean;
  isLoading: boolean;
  onSort?: (type: number) => void;
  hasStatus: boolean;
  selectAllPrograms?: Function;
  selectedPrograms?: number;
  children?: ReactElement[] | ReactElement;
}

export default function ProgramsList({
  hideTableHeader = false,
  stretch = false,
  isLoading,
  fetchMore,
  hasMore,
  length,
  onSort,
  children,
  hasStatus,
  selectedPrograms,
  selectAllPrograms,
}: Props): ReactElement {
  const hideHeader = useMediaQuery('(max-width: 1000px)') || hideTableHeader;
  const targetRef = useRef(null);

  useIntersectionObserver({
    target: targetRef,
    onIntersect: fetchMore,
    enabled: hasMore,
  });

  const showEndOfList = length > 4 && !hasMore && !isLoading;
  const showEmptyList = length === 0 && !isLoading;
  return (
    <>
      {!hideHeader && <ProgramsListHeader onSort={onSort} stretch={stretch} hasStatus={hasStatus} selectAllPrograms={selectAllPrograms} />}

      <StyledProgramsList>
        {children}
        {isLoading && <UniBounceLoader />}
        {showEmptyList && (
          <Box mx={4}>
            <UniTypography color='primary100' weight='medium'>
              No programs found.
            </UniTypography>
          </Box>
        )}
        {showEndOfList && (
          <Box>
            <UniTypography color='typographyGray70'>You have reached the end of the list.</UniTypography>
          </Box>
        )}
        <div ref={targetRef} />
      </StyledProgramsList>
      <SelectedProgramsWrapper isVisible={!!selectedPrograms}>
        <UniTypography color='white100'>{selectedPrograms} programs selected</UniTypography>
        <UniButton
          variant='text'
          fullWidth={false}
          color='primary'
          onClick={() => {}}
          startIcon={
            <UniIconWrapper size='large' color='white100'>
              <EditOutlinedIcon />
            </UniIconWrapper>
          }
        >
          Edit programs
        </UniButton>
      </SelectedProgramsWrapper>
    </>
  );
}
