import { Backdrop, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../../constants/uiConfig';

export const SearchContainer = styled.div`
  //position: absolute;
  // display:inline-block;
  flex: auto;
`;

type ResultsProps = {
  focused: boolean;
};
export const Results = styled.div<ResultsProps>`
  content: '';
  position: absolute;
  top: ${({ theme }) => theme.spacing(7.7)}px;
  left: 0;
  right: 0;
  background: white;
  z-index: ${({ theme }) => theme.zIndex.drawer + 2};
  border-radius: ${({ theme }) => `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`};
  border: 1px solid ${Colors.Border.Gray_100};
  border-top: 0;
`;

export const ResultsHeader = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 3)};
`;
export const ResultsBody = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  padding: ${({ theme }) => theme.spacing(2)}px;

  gap: ${({ theme }) => theme.spacing(2)}px;

  max-height: 400px;
  min-height: 240px;
`;

export const StyledSearchInput = styled(TextField)<{stayFocused?: boolean}>`
  .MuiInputBase-root {
    background: ${({ focused, stayFocused }) => (focused || !!stayFocused ? 'white' : 'transparent')};
    height: ${({ theme }) => theme.spacing(7)}px;
    z-index: ${({ theme, focused }) => focused && theme.zIndex.drawer + 2};
    border-radius: ${({ theme, focused }) => focused && `${theme.spacing(1)}px`};
    ${({stayFocused, theme})=> stayFocused && `border: 1px solid ${Colors.Typography.Gray_50}`};
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      ${({ theme, stayFocused }) => !(!!stayFocused) && `border: 1px solid ${Colors.Typography.Gray_30}`};
    }
  }
  ${({ focused }) => focused && Results}
`;

export const StyledBackdrop = styled(Backdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;
