import { AxiosResponse } from 'axios';
import { Components } from 'client/UniClient';
import { MutationConfig, useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient, useSnackbar } from '../../store';

type Payload = Components.Schemas.UpdateServiceOfferingRequest;
type Params = { serviceOfferingId: string };

export default function useEditServiceOffering(
  params: Params,
  config?: MutationConfig<AxiosResponse<Components.Schemas.ServiceOfferingDto>>,
) {
  const { client } = useClient();
  const queryCache = useQueryCache();
  const { openSnackbar } = useSnackbar();

  return useMutation(async (payload: Payload) => client!.ServiceOptionApi_updateServiceOffering(params, payload), {
    ...config,
    onSuccess() {
      openSnackbar('edit-service-offering');
      queryCache.invalidateQueries(CacheKeys.Agency.Services);
    },
  });
}
