import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components, Paths } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = Paths.AchievementApiPublishAchievement.PathParameters & Components.Schemas.PublishRequest;

export default function usePublishAchievement() {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async ({ achievementId, ...payload }: Params) => {
      return client!.AchievementApi_publishAchievement({ achievementId }, payload);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Achievement.Default);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
                 const { message } = error?.response?.data || {};
                 const generalMessage =
                   "Something went wrong, please try again in a little while";
                 Snackbar(message || generalMessage, {
                   variant: "error"
                 }); 
                 */
      },
    },
  );
}
