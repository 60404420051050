import { UniTypography } from 'components';
import { UniDate } from 'components/_inputs/uniDate/UniDate';
import { Controller } from 'react-hook-form';
import { useAuth } from 'store';
import { ActivitiesType } from 'types/activities';
import { getMetaRole } from 'utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface Props {
  type?: ActivitiesType;
  control: any;
  errors: any;
}

const Step3: React.FC<Props> = ({ type, control, errors }) => {
  const { t } = useTranslation('activityItemDrawer');
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile.role);

  const achievementDateLabel = isStudent ? 'When did you complete this achievement?' : 'When has candidate completed this achievement?';

  if (type === 'ACHIEVEMENT') {
    return (
      <Controller
        name='ended'
        control={control}
        rules={{
          required: `${t('activity_item.errors.date_of_completion')}`,
          validate: (value: string) =>
            value.split('-').every(Boolean)
              ? moment(value, 'YYYY-M-D').isValid() || `${t('activity_item.errors.date_of_completion')}`
              : `${t('activity_item.errors.date_of_completion_2')}`,
        }}
        render={field => (
          <UniDate fullWidth label={achievementDateLabel} error={!!errors.ended?.message} helperText={errors.ended?.message} {...field} />
        )}
      />
    );
  }

  if (type === 'TODO') {
    return (
      <Controller
        name='deadline'
        control={control}
        rules={{
          required: `${t('activity_item.errors.deadline')}`,
          validate: (value: string) =>
            value.split('-').every(Boolean)
              ? moment(value, 'YYYY-M-D').isValid() || `${t('activity_item.errors.deadline')}`
              : `${t('activity_item.errors.deadline_2')}`,
        }}
        render={field => (
          <UniDate
            fullWidth
            label='Select a deadline for this item'
            error={!!errors.deadline?.message}
            helperText={errors.deadline?.message}
            showFutureYears
            {...field}
          />
        )}
      />
    );
  }

  return (
    <>
      <UniTypography variant='body2' color='stateAlert100'>
        Something went wrong, please go back and try again
      </UniTypography>
    </>
  );
};

export default Step3;
