import React from 'react';
import { Box } from '@material-ui/core';
import { UniTypography } from '../index';
import { StyledPurchasedPrepPacksHeader } from './PurchasedPrepPacksList.style';

interface Props {}

export const ListHeader: React.FC<Props> = () => {
  return (
    <StyledPurchasedPrepPacksHeader>
      <Box />
      <UniTypography weight='medium' variant='subheader'>
        Student
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Purchased Package
      </UniTypography>
      <UniTypography weight='medium' variant='subheader'>
        Remaining sessions
      </UniTypography>
    </StyledPurchasedPrepPacksHeader>
  );
};

export default ListHeader;
