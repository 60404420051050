import { AxiosError } from 'axios';
import { queryCache, useMutation } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Payload {
  params: {
    agencyId: string;
    studentId: string;
    certificationId: string;
  };
}

const useDeleteCertificationsHistoryItem = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AgencyApi_deleteCertificationItem(payload.params);
    },

    {
      onSuccess: async response => {
        queryCache.invalidateQueries(CacheKeys.Profile.Extended);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default useDeleteCertificationsHistoryItem;
