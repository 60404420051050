import { Box } from '@material-ui/core';
import { UniButton, UniCard, UniIconWrapper, UniTypography } from 'components';
import { UniMetricsBase } from 'components/_common/uniMetricsBase/UniMetricsBase';
import { useHistory } from 'react-router';
import { ReactComponent as LocationIcon } from '../../../../../assets/svg/Icons/Location.svg';
import { BrowserRoutes, countries } from '../../../../../constants';

interface Props {
  program: any;
}

const AiSearchSectionProgram: React.FC<Props> = ({ program, children, ...rest }) => {
  const history = useHistory();

  return (
    <UniCard size='S' fit border onClick={() => history.push(BrowserRoutes.Default.Auth.SignUp)}>
      <Box height='100%' display='flex' flexDirection='column' justifyContent='space-between'>
        <Box>
          <UniTypography variant='header3' color='secondary100'>
            {program?.name}
          </UniTypography>
          <UniTypography variant='body2' color='secondary70'>
            {program?.universityName}
          </UniTypography>

          <Box mt={2} />
          <UniMetricsBase
            size='small'
            Icon={
              <UniIconWrapper>
                <LocationIcon />
              </UniIconWrapper>
            }
            variant='iconed'
            label='Country'
            value={countries.find(item => item.code === program.address_country)?.name || 'Unspecified'}
          />
          <Box mt={1} />
          <UniMetricsBase
            size='small'
            Icon={
              <UniIconWrapper>
                <LocationIcon />
              </UniIconWrapper>
            }
            variant='iconed'
            label='City'
            value={program.address_city || 'Unspecified'}
          />
        </Box>
        <Box flex={1} />

        <Box mt={2} mb={-2} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
          <UniButton size='small' variant='secondary' onClick={() => history.push(BrowserRoutes.Default.Auth.SignUp)}>
            Create account
          </UniButton>
        </Box>
      </Box>
    </UniCard>
  );
};

export default AiSearchSectionProgram;
