import { useMutation } from 'react-query';
import { Components } from 'client/UniClient';
import { useClient } from 'store';

type Payload = Components.Schemas.CreateOrderRequest;

export default function usePostOrder() {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      const { data } = await client!.OrderApi_createOrder(null, payload);
      return data;
    },
  );
}
