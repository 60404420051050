import { useMutation } from 'react-query';
import { Paths } from 'client/UniClient';
import { useClient } from 'store';

type Payload = Paths.TaskApiUpdateTask.RequestBody & { taskId: string };

export default function usePatchTask() {
  const { client } = useClient();

  return useMutation(async ({ taskId, ...body }: Payload) => client!.TaskApi_updateTask({ taskId }, body));
}
