import React from 'react';
import { Components } from 'client/UniClient';
import { StyledPrepCenterProductsList } from './UniPrepCenterProductsList.style';
import UniPrepCenterProductCard from './UniPrepCenterProductCard';

type ProductDto = Components.Schemas.ProductDto;
type PrepPackDto = Components.Schemas.PrepPackDto;

interface Props {
  products: ProductDto[];
  prepPacks: PrepPackDto[];
}

export const UniPrepCenterProductsList: React.FC<Props> = ({ products, prepPacks }) => {
  return (
    <StyledPrepCenterProductsList>
      {products.map(product => (
        <UniPrepCenterProductCard
          product={product}
          prepPacks={prepPacks.filter(prepPack => prepPack.product.id === product.id)}
        />
      ))}
    </StyledPrepCenterProductsList>
  );
};

export default UniPrepCenterProductsList;
