export type Subjects =
  | 'ENGINEERING_TECHNOLOGIES'
  | 'SCIENCES'
  | 'ARTS'
  | 'BUSINESS_MANAGEMENT_ECONOMICS'
  | 'LAW_POLITICS_SOCIAL'
  | 'HEALTH_SCIENCES_MEDICINE';

export const subjects = [
  { value: 'AGRICULTURE_FORESTRY', text: 'Agriculture forestry' },
  { value: 'APPLIED_SCIENCES', text: 'Applied sciences' },
  { value: 'ARTS_DESIGN_ARCHITECTURE', text: 'Arts design architecture' },
  { value: 'BUSINESS_MANAGEMENT', text: 'Business management' },
  { value: 'COMPUTER_SCIENCE_IT', text: 'Computer science it' },
  { value: 'EDUCATION_TRAINING', text: 'Education training' },
  { value: 'ENGINEERING_TECHNOLOGY', text: 'Engineering technology' },
  { value: 'ENVIRONMENTAL_EARTH_SCIENCES', text: 'Environmental earth sciences' },
  { value: 'HOSPITALITY_LEISURE_SPORTS', text: 'Hospitality leisure sports' },
  { value: 'HUMANITIES', text: 'Humanities' },
  { value: 'JOURNALISM_MEDIA', text: 'Journalism media' },
  { value: 'LAW', text: 'Law' },
  { value: 'MEDICINE_HEALTH', text: 'Medicine health' },
  { value: 'NATURAL_SCIENCES_MATHS', text: 'Natural sciences maths' },
  { value: 'SOCIAL_SCIENCES', text: 'Social sciences' },
  { value: 'OTHER', text: 'Other' },
];

export const subjectsMap = {
  ENGINEERING_TECHNOLOGIES: 'Engineering & Technologies',
  SCIENCES: 'Sciences',
  ARTS: 'Arts',
  BUSINESS_MANAGEMENT_ECONOMICS: 'Business, Management & Economics',
  LAW_POLITICS_SOCIAL: 'Law, Politics, Social, Community Service & Teaching',
  HEALTH_SCIENCES_MEDICINE: 'Health Sciences, Medicine, Nursing, Paramedic and Kinesiology',
};
