import { Box } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UniDragNDrop, UniTypography } from '../../..';
import { StyledApplicationIcon, StyledDeleteIcon } from '../UniversityReplyForm.style';

interface Props {
  documents?: File[];
  setDocuments: Function;
}

export default function UniversityReplyStep2({ documents, setDocuments }: Props): ReactElement {
  const { t } = useTranslation('universityReplyForm');

  const removeDocument = (file: File) => {
    setDocuments(documents?.filter(item => item !== file));
  };

  return (
    <>
      <UniTypography variant='header3'>{t('step2.title')}</UniTypography>
      <UniTypography color='typographyGray70'>{t('step2.subtitle')}</UniTypography>
      <UniDragNDrop
        style={{ width: '310px' }}
        showFileName={false}
        accept='application/pdf, image/jpg, image/png'
        handleChange={(files: File[]) => {
          if (files.length > 0) {
            setDocuments(files);
          }
        }}
      >
        {/* {profile.studentProfile.d} */}
        <UniTypography color='typographyGray100'>Drag & Drop or</UniTypography>
        <UniTypography color='primary100'>Upload JPG, PDF or PNG file</UniTypography>
      </UniDragNDrop>
      <AnimatePresence>
        {document &&
          documents?.map(doc => (
            <motion.div initial={{ opacity: 0, translateY: '-100%' }} animate={{ opacity: 1, translateY: '0%' }} exit={{ opacity: 0 }}>
              <Box display='flex' alignItems='center' mt={3}>
                <StyledApplicationIcon />
                <Box ml={1} mr={3}>
                  <UniTypography color='typographyGray70'>{`${doc?.name?.substr(0, 20)}${doc?.name?.length > 19 && '...'}`}</UniTypography>
                </Box>
                <StyledDeleteIcon onClick={() => removeDocument(doc)} />
              </Box>
            </motion.div>
          ))}
      </AnimatePresence>
    </>
  );
}
