import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import useWorkshops from 'api/workshops/useWorkshops';
import { UniPageTitle } from 'components';
import { StyledGrid } from 'components/infiniteWindowedGrid/InfiniteWindowedGrid.style';
import UniWorkshopCard from 'components/_common/uniWorkshopCard/UniWorkshopCard';

const Workshops: React.FC = () => {
  const { t } = useTranslation('studentAppTracker');
  const { data: workshops } = useWorkshops();

  return (
    <>
      <Box mb={3}>
        <UniPageTitle title={t('tabs.workshops')} />
        <StyledGrid style={{ width: '100%', marginTop: '20px' }}>
          {workshops && workshops.map(workshop => (
            <UniWorkshopCard
              title={workshop.name}
              date={workshop.startDate}
              type={workshop.type}
              link={workshop.link}
              actionTitle={t('workshops.cards.view')}
              onClick={() => {
                if (workshop.link != null) {
                  window.open(workshop.link, '_blank');
                }
              }}
            />
          ))}
        </StyledGrid>
      </Box>
    </>
  );
};

export default Workshops;
