import { Components } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

export default function useAuthUser(config?: QueryConfig<Components.Schemas.UserResponse>) {
  const { client } = useClient();
  const { setProfile } = useAuth();

  return useQuery(
    CacheKeys.Profile.Default,
    async (_key: string) => {
      console.log(client);
      if (!client) throw new Error('Client not initialized');
      const { data } = await client!.UserApi_getMyUser();
      return data;
    },
    {
      ...config,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        data && setProfile(data);
      },
    },
  );
}
