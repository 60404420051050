import styled from 'styled-components';
import { theme } from 'theme';
import { ReactComponent as ApplicationSuccessIllustration } from '../../../assets/svg/Illustrations/ApplicationSuccessIllustration.svg';

export const StyledApplicationSuccessIllustration = styled(ApplicationSuccessIllustration)`
  max-height: 300px;
  margin: 16px 0;
  width: 100%;
  max-width: 400px;
`;

export const StyledRequiredFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)}px;
`;

export const StyledConsentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StyledConsentDataWrapper = styled.div`
  position: relative;
  margin-top: ${theme.spacing(1)}px;
  margin-bottom: ${theme.spacing(2)}px;
  flex: 1;

  & > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;
