import React from 'react';
import { Components } from 'client/UniClient';
import { UniButton, UniTypography } from '../index';
import { StyledPrepCenterOrdersItem } from './UniPrepCenterOrdersItem.style';

type OrderDto = Components.Schemas.OrderDto;

interface Props {
  order: OrderDto;
  enableActions: boolean;
}

export const UniPrepCenterOrdersItem: React.FC<Props> = ({ order, enableActions }) => {
  return (
    <StyledPrepCenterOrdersItem>
      <UniTypography variant='subheader'>{order.createdAt?.slice(0, 10)}</UniTypography>
      <UniTypography variant='subheader'>
        <ul>
          {order.prepPacks.map(prepPack => (
            <li>{prepPack.sessions}x {prepPack.product?.name}</li>
          ))}
        </ul>
      </UniTypography>
      <UniTypography variant='subheader'>{order.totalPrice / 100} RON</UniTypography>
      <UniTypography variant='subheader'>{order.status}</UniTypography>
      {enableActions && order.status === 'PENDING_PAYMENT' && order.paymentLink && (
        <UniButton
          color='primary'
          onClick={() => {
            window.open(order.paymentLink);
          }}
        >
          Complete purchase
        </UniButton>
      )}
    </StyledPrepCenterOrdersItem>
  );
};

export default UniPrepCenterOrdersItem;
