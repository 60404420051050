import { Box } from '@material-ui/core';
import React from 'react';
import UniTypography from '../_common/uniTypography/UniTypography';

interface Props {
  universityName?: string;
}

const DataConsent: React.FC<Props> = ({ universityName }) => {
  return (
    <Box
      style={{
        border: '1px solid #D0D0D0',
        borderRadius: '12px',
        paddingLeft: '20px',
        paddingTop: '10px',
        paddingBottom: '10px',
        height: '100%',
      }}
    >
      <Box style={{ overflowY: 'auto', maxHeight: '100%' }}>
        <UniTypography color='typographyBlack100' variant='footnote' weight='medium'>
          Data protection and declaration
        </UniTypography>

        <UniTypography variant='footnote' color='typographyGray100' weight='regular' style={{ marginTop: '20px' }}>
          I consent to the university's use of my personal data, including sensitive data, to allow {universityName} to fulfill its
          administrative obligations and to process my application.
        </UniTypography>

        <UniTypography variant='footnote' color='typographyGray100' weight='regular' style={{ marginTop: '20px' }}>
          {universityName} shall duly observe its obligations under the General Data Protection Regulations and any resulting legislation
          which arises from this application. The information I provide on the application form will be used for the following purposes:
        </UniTypography>

        <UniTypography variant='footnote' color='typographyGray100' weight='regular' style={{ marginTop: '20px' }}>
          <ul>
            <li>to determine my eligibility for entry to {universityName};</li>
            <li>to provide me with relevant information to assist me in joining {universityName};</li>
            <li>to enable them to compile statistical reports;</li>
            <li>to enable them to initiate my student record;</li>
          </ul>
        </UniTypography>

        <UniTypography variant='footnote' color='typographyGray100' weight='regular' style={{ marginTop: '20px' }}>
          I consent to {universityName} sharing this information with:
        </UniTypography>

        <UniTypography variant='footnote' color='typographyGray100' weight='regular'>
          <ul>
            <li>the relevant government departments;</li>
            <li>Public Sector regulatory bodies;</li>
            <li>bodies which are responsible for professional accreditation of my course, if applicable;</li>
            <li>the Police or other authority in relation to crime prevention or investigation;</li>
            <li>accommodation providers (where applicable) who are engaged through the university's approved accommodation system;</li>
            <li>
              any partner of {universityName} which is delivering the programme or whose premises are used for the delivery of a programme, for
              which I have registered on with {universityName}
            </li>
          </ul>
        </UniTypography>

        <UniTypography variant='footnote' color='typographyGray100' weight='regular' style={{ marginTop: '20px' }}>
          I hereby certify that I wish to apply for admission as a student of {universityName}. I also declare that, if admitted I shall
          conform to all the Rules and Regulations of them. I understand that the submission of any misleading information during the
          admission process could lead to the immediate cancellation of my application and the withdrawal of any offer made. In the event
          that I register as a student of them, I hereby undertake to pay, as and when due, all University fees.
        </UniTypography>
      </Box>
    </Box>
  );
};

export default DataConsent;
