import { CheckboxProps } from '@material-ui/core';
import { ReactComponent as CheckmarkOff } from 'assets/svg/Icons/Checkbox-off.svg';
import { ReactComponent as CheckmarkOn } from 'assets/svg/Icons/Checkbox-on.svg';
import React, { ReactElement } from 'react';
import { StyledCheckbox } from './UniCheckbox.style';

interface UniCheckboxBaseProps extends Omit<CheckboxProps, 'color'> {
  optional?: boolean;
  label?: string;
  helperText?: string;
}
export default function UniCheckbox({ label, helperText, optional, ...props }: UniCheckboxBaseProps): ReactElement {
  return <StyledCheckbox {...props} checkedIcon={<CheckmarkOn />} icon={<CheckmarkOff />} />;
}
