import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { BrowserRoutes, CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  id: string;
};

export default function useApplication(params: Params, config?: QueryConfig<Components.Schemas.ApplicationDto>) {
  const history = useHistory();
  const { client } = useClient();

  return useQuery(
    [CacheKeys.General.Application, params],
    async (_key: string, params: Params) => {
      //Swap promise with getSingleStudent
      const { data } = await client!.ApplicationApi_getApplication({
        applicationId: params.id,
      });
      return data;
    },
    {
      ...config,
      onError(err: AxiosError) {
        if (err.response?.status === 404) {
          history.push(BrowserRoutes.Default.Unauthorized);
        }
      },
    },
  );
}
