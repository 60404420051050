import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';
// import {Components} from "../../client/UniClient"
// import Schemas = Components.Schemas;

type Payload = {
  applicationId: string;
};

const usePostEnrollment = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.ApplicationApi_enroll(payload);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Applications.All);
        queryCache.invalidateQueries(CacheKeys.General.Program);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         });
         */
      },
    },
  );
};

export default usePostEnrollment;
