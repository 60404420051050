import { Box } from '@material-ui/core';
import useMarkAchievementForReview from 'api/achievements/useMarkAchievementForReview';
import useValidateAchievement from 'api/achievements/useValidateAchievement';
import useMarkTodoForReview from 'api/todos/useMarkTodoForReview';
import useValidateTodo from 'api/todos/useValidateTodo';
import { Components } from 'client/UniClient';
import { UniFileViewer, UniIconWrapper, UniTypography } from 'components';
import { UniMetricsBase } from 'components/_common/uniMetricsBase/UniMetricsBase';
import { activitiesCategoryMap, activitiesStatusMap, activitiesTypeMap } from 'constants/activities';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useAuth, useDrawers } from 'store';
import { getMetaRole } from 'utils';
import { ReactComponent as CandidateIcon } from '../../../assets/svg/Icons/Candidates.svg';
import { ReactComponent as HourglassIcon } from '../../../assets/svg/Icons/Hourglass.svg';
import { ReactComponent as LinkIcon } from '../../../assets/svg/Icons/Link.svg';
import { ReactComponent as OngoingIcon } from '../../../assets/svg/Icons/Ongoing.svg';
import { UniModalDrawerContent } from '../../_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from '../../_common/uniModalDrawer/UniModalDrawerActions';

interface Props {
  achievement?: Components.Schemas.AchievementDto;
  todo?: Components.Schemas.ToDoDto;
}

const ActivityItemPreview: React.FC<Props> = ({ achievement, todo }) => {
  const { profile } = useAuth();
  const { isStudent, isConsultant } = getMetaRole(profile.role);
  const { openDrawer, closeDrawer } = useDrawers();

  const { title, category, creator, description, status, showBottomActions, showTopActions, type, links, documents, parsedDate } =
    useMemo(() => {
      const activity = achievement || todo!;
      const date = !!achievement ? moment(achievement?.completedAt).format('DD.MM.YYYY') : moment(todo?.deadline).format('DD.MM.YYYY');
      const dateLabel = !!achievement ? 'Completed at' : 'Deadline';
      const creatorId = achievement?.creator || todo?.creator;

      let showBottomActions = true;
      let showTopActions = true;
      if (isStudent && creatorId?.id !== profile.id) {
        showBottomActions = false;
        showTopActions = false;
      }
      if (isStudent && activity.status === 'IN_REVIEW') showBottomActions = false;
      if (activity.status === 'VALIDATED') {
        showBottomActions = false;
        showTopActions = false;
      }

      return {
        ...activity,
        type: (!!achievement ? 'ACHIEVEMENT' : 'TODO') as 'ACHIEVEMENT' | 'TODO',
        parsedDate: {
          label: dateLabel,
          value: date,
        },
        showBottomActions,
        showTopActions,
      };
    }, [todo, achievement]);
  const parsedCreator = creator.id === profile.id ? 'Me' : `${creator.firstName} ${creator.lastName}`;

  const [markAchievementForReview, { isLoading: isLoadingMarkAchievementForReview }] = useMarkAchievementForReview();
  const [markTodoForReview, { isLoading: isLoadingMarkTodoForReview }] = useMarkTodoForReview();
  const [validateTodo, { isLoading: isLoadingValidateTodo }] = useValidateTodo();
  const [validateAchievement, { isLoading: isLoadingValidateAchievement }] = useValidateAchievement();
  const isLoading =
    isLoadingMarkAchievementForReview || isLoadingMarkTodoForReview || isLoadingValidateTodo || isLoadingValidateAchievement;

  const onValidateActivity = () => {
    if (type === 'ACHIEVEMENT') {
      if (!achievement?.id) return;
      validateAchievement(
        { achievementId: achievement?.id },
        {
          onSuccess() {
            closeDrawer();
          },
        },
      );
    }
    if (type === 'TODO') {
      if (!todo?.id) return;
      validateTodo(
        { toDoId: todo?.id },
        {
          onSuccess() {
            closeDrawer();
          },
        },
      );
    }
  };

  const onMarkForReview = () => {
    if (type === 'ACHIEVEMENT') {
      if (!achievement?.id) return;
      markAchievementForReview(
        { achievementId: achievement?.id },
        {
          onSuccess() {
            closeDrawer();
          },
        },
      );
    }
    if (type === 'TODO') {
      if (!todo?.id) return;
      markTodoForReview(
        { toDoId: todo?.id },
        {
          onSuccess() {
            closeDrawer();
          },
        },
      );
    }
  };

  const onAddProof = () => {
    closeDrawer();
    openDrawer('add-proof-of-completion', { achievement, todo });
  };

  if (!achievement && !todo) return <></>;

  return (
    <>
      <UniModalDrawerContent>
        <UniTypography variant='body2' weight='semi-bold'>
          {title}
        </UniTypography>
        <Box mb={4} />
        <Box display='grid' gridTemplateColumns='1fr 1fr' gridGap={16}>
          <UniMetricsBase variant='iconed' Icon={<OngoingIcon />} label='Category' value={activitiesCategoryMap[category]} />
          <UniMetricsBase variant='iconed' Icon={<CandidateIcon />} label='Added by' value={parsedCreator} />
          <UniMetricsBase variant='iconed' Icon={<HourglassIcon />} label='Status' value={activitiesStatusMap[status]} />
          <UniMetricsBase variant='iconed' Icon={<HourglassIcon />} {...parsedDate} />
          <UniMetricsBase variant='iconed' Icon={<OngoingIcon />} label='Type' value={activitiesTypeMap[type]} />
        </Box>
        <Box mb={4} />
        <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
          Description
        </UniTypography>
        <UniTypography variant='subheader' color='typographyBlack100'>
          {description}
        </UniTypography>

        {!!links?.length && (
          <>
            <Box mb={3} />
            <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
              Links
            </UniTypography>
            {links.map((link, index) => (
              <Box mb={1} display='flex' onClick={() => window.open(link, '_blank')} key={index}>
                <UniIconWrapper>
                  <LinkIcon />
                </UniIconWrapper>
                <Box ml={1} />
                <UniTypography variant='subheader'>{link}</UniTypography>
              </Box>
            ))}
          </>
        )}

        {!!documents?.length && (
          <>
            <Box mb={3} />
            <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
              Documents
            </UniTypography>
            {documents.map((document, index) => (
              <UniFileViewer label={document.originalName} file={document} disableDelete key={index} />
            ))}
          </>
        )}
      </UniModalDrawerContent>
      {showBottomActions && (
        <UniModalDrawerButtons
          SecondaryButton={{
            label: 'Add Proof',
            onClick: onAddProof,
          }}
          PrimaryButton={
            isConsultant
              ? { label: 'Validate', onClick: onValidateActivity, loading: isLoading }
              : { label: 'Mark for Review', onClick: onMarkForReview, loading: isLoading }
          }
        />
      )}
    </>
  );
};
export default ActivityItemPreview;
