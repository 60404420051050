import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components, Paths } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export type UsePatchAchievementPayload = Components.Schemas.UpdateAchievementRequest &
  Paths.AchievementApiUpdateAchievement.PathParameters & { documents?: File[] };

const usePatchAchievement = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async ({ achievementId, documents, ...body }: UsePatchAchievementPayload) => {
      const links = body.links?.filter(item => item.trim() !== '');
      const { data: achievement } = await client!.AchievementApi_updateAchievement({ achievementId }, { ...body, links });
      if (!documents) return achievement;
      const docs = [];

      for (let i = 0; i < documents.length; i++) {
        let formData = new FormData();
        formData.append('documentType', 'ACHIEVEMENT_DOCUMENT');
        formData.append('file', documents[i]);
        const { data } = await client!.AchievementApi_uploadDocument({ achievementId: achievement.id }, formData);
        docs.push(data);
      }

      return { ...achievement, documents: docs };
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Achievement.Default);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default usePatchAchievement;
