export const studyInterests = [
  { value: 'PHYSICS', label: 'Physics' },
  { value: 'BIOLOGY', label: 'Biology' },
  { value: 'EARTH_SCIENCES', label: 'Earth Sciences' },
  { value: 'HEALTH_SCIENCES', label: 'Health Sciences' },
  { value: 'MATHEMATICS', label: 'Mathematics' },
  { value: 'COMPUTER_SCIENCE', label: 'Computer Science' },
  { value: 'CHEMISTRY', label: 'Chemistry' },
  { value: 'ENGINEERING', label: 'Engineering' },
  { value: 'ASTRONOMY', label: 'Astronomy' },
  { value: 'INFORMATION_TECHNOLOGY', label: 'Information Technology' },
  { value: 'BUSINESS', label: 'Business' },
  { value: 'PSYCHOLOGY', label: 'Psychology' },
  { value: 'SOCIOLOGY', label: 'Sociology' },
  { value: 'POLITICAL_SCIENCE', label: 'Political Science' },
  { value: 'ECONOMY', label: 'Economy' },
  { value: 'HOSPITALITY', label: 'Hospitality' },
  { value: 'SPORTS', label: 'Sports' },
  { value: 'ART', label: 'Art' },
  { value: 'AERONAUTICS_AVIATION_SCIENCE', label: 'Aeronautics Aviation Science' },
  { value: 'COMMUNICATIONS', label: 'Communications' },
  { value: 'AREA_ETHNIC_MULTIDISCIPLINARY_STUDIES', label: 'Area Ethnic Multidisciplinary Studies' },
  { value: 'AGRICULTURE_NATURAL_RESOURCES_CONSERVATION', label: 'Agriculture Natural Resources Conservation' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'PHILOSOPHY_RELIGION_THEOLOGY', label: 'Philosophy Religion Theology' },
  { value: 'REPAIR_PRODUCTION_CONSTRUCTION', label: 'Repair Production Construction' },
  { value: 'ENGLISH_FOREIGN_LANGUAGES', label: 'English Foreign Languages' },
  { value: 'LOGISTICS', label: 'Logistics' },
];
