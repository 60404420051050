import { AxiosError } from 'axios';
import { AgenciesFiltersOptions } from 'components/_drawers/filtersDrawer/_types/AgenciesFilters';
import { countries } from 'constants/countries';
import { QueryConfig, useQuery } from 'react-query';
import { OptionType } from 'types';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';
type Params = {
  _page?: number;
};

const agencyStatus = [
  { value: 'INVITED', label: 'Invitation Sent' },
  { value: 'ACTIVATED', label: 'Account Activated' },
];

const parseCountries = (filterCountries: string[]): OptionType[] =>
  countries.filter(item => filterCountries.includes(item.code)).map(item => ({ label: item.name, value: item.code }));

export default function useAgencyFilters(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Admin.AgencyFilters, params],
    async (_key: string, params: Params) => {
      let filters: Partial<AgenciesFiltersOptions>;
      const { data } = await client!.SearchApi_getAgencyFilters();

      filters = {
        country: parseCountries(data.countries),
        status: agencyStatus,
        maxAgents: data.maxAgents,
        minAgents: data.minAgents,
        maxStudents: data.maxStudents,
        minStudents: data.minStudents,
      };

      return filters;
    },
    {
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
