import { useFile } from 'api';
import AccomplishmentsFormDrawer from 'components/_drawers/accomplishments-form-drawer/AccomplishmentsFormDrawer';
import ActivityItemPreview from 'components/_drawers/activityItemPreview/ActivityItemPreview';
import AddActivityItemDrawer from 'components/_drawers/addActivityItemDrawer/AddActivityItemDrawer';
import AddEditProgram from 'components/_drawers/addEditProgram/AddEditProgram';
import AddNewService from 'components/_drawers/addNewService/AddNewService';
import AddProofOfCompletion from 'components/_drawers/addProofOfCompletion/AddProofOfCompletion';
import AddTaskDrawer from 'components/_drawers/addTaskDrawer/AddTaskDrawer';
import ApplicationDrawer from 'components/_drawers/applicationDrawer/ApplicationDrawer';
import ApplicationFee from 'components/_drawers/applicationFee/ApplicationFee';
import AssignAgentDrawer from 'components/_drawers/assignAgent/AssignAgent';
import ContactDetailsDrawer from 'components/_drawers/contact-form-drawer/index';
import CreateEvent from 'components/_drawers/createEvent/CreateEvent';
import CreateServiceOrder from 'components/_drawers/createServiceOrder/CreateServiceOrder';
import DeleteActivityItem from 'components/_drawers/deleteActivityItem/DeleteActivityItem';
import EditActivityItemDrawer from 'components/_drawers/editActivityItemDrawer/EditActivityItemDrawer';
import EditServiceRequest from 'components/_drawers/editServiceRequest/EditServiceRequest';
import EditTaskDrawer from 'components/_drawers/editTaskDrawer/EditTaskDrawer';
import EventRegistration from 'components/_drawers/eventRegistration/EventRegistration';
import GuardianFormDrawer from 'components/_drawers/guardian-form-drawer/GuardianFormDrawer';
import InterestsQuiz from 'components/_drawers/interestsQuiz/InterestsQuiz';
import InviteCandidate from 'components/_drawers/inviteCandidate/InviteCandidate';
import InviteHighSchool from 'components/_drawers/inviteHighSchool/InviteHighSchool';
import InviteHighSchoolStudent from 'components/_drawers/inviteHighSchoolStudent/InviteHighSchoolStudent';
import NotesDrawer from 'components/_drawers/notes/NotesDrawer';
import AddServicePartner from 'components/_drawers/partnerServicesDrawers/addServiceOffering/AddServiceOffering';
import DeleteServiceOffering from 'components/_drawers/partnerServicesDrawers/deleteServiceOffering/DeleteServiceOffering';
import EditServicePartner from 'components/_drawers/partnerServicesDrawers/editServiceOffering/EditServiceOffering';
import RequestService from 'components/_drawers/partnerServicesDrawers/requestService/RequestService';
import PersonalFormDrawer from 'components/_drawers/personal-form-drawer/PersonalFormDrawer';
import PreferencesFormDrawer from 'components/_drawers/preferences-form-drawer/PreferencesFormDrawer';
import PublishPublicProfile from 'components/_drawers/publishPublicProfile/PublishPublicProfile';
import RejectService from 'components/_drawers/rejectService/RejectService';
import RemoveEvent from 'components/_drawers/removeEvent/RemoveEvent';
import RemoveService from 'components/_drawers/removeService/RemoveService';
import ReplyToStudent from 'components/_drawers/replyToStudent/ReplyToStudent';
import ReplyToUniversity from 'components/_drawers/replyToUniversity/ReplyToUniversity';
import RequestActivityUpdate from 'components/_drawers/requestActivityUpdate/RequestActivityUpdate';
import RequiredUpdatesPreview from 'components/_drawers/requiredUpdatesPreview/RequiredUpdatesPreview';
import SuggestProgram from 'components/_drawers/suggestProgram/SuggestProgram';
import ToggleApplicationPeriod from 'components/_drawers/toggleApplicationPeriod/ToggleApplicationPeriod';
import UnpublishPublicProfile from 'components/_drawers/unpublishPublicProfile/UnpublishPublicProfile';
import UpdateCandidateStatus from 'components/_drawers/updateCandidateStatus/UpdateCandidateStatus';
import UpdateProgramInfoRequest from 'components/_drawers/updateProgramInfoRequest/UpdateProgramInfoRequest';
import { NotificationDrawerMobile } from 'components/_navigation/headerNavigation/auth/NotificationDrawerMobile';
import ModalDrawerNavigation from 'components/_navigation/modalDrawerNavigation/ModalDrawerNavigation';
import useTidioChat from 'hooks/useTidioChat';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useDrawers } from 'store';
import {
  AddEducationDrawer,
  AddNewDocument,
  AdminCreateAgency,
  DeleteDocument,
  DeleteEducationDrawer,
  EditEducationDrawer,
  FilterAgenciesForm,
  FilterAgentsForm,
  FilterApplicationsForm,
  FilterCandidatesForm,
  FilterProgramsForm,
  FilterUniversitiesForm,
  FilterUniversityProgramsFormForFacultyPage,
  FilterUniversityProgramsFormForProgramsPage,
  InviteAgent,
  StudentEnrollmentDrawer,
  UniversityReplyForm,
} from '../..';
import UniAvatar from '../uniAvatar/UniAvatar';
import UniBounceLoader from '../uniBounceLoader/UniBounceLoader';
import UniLogo from '../uniLogo/uniLogo';
import UniProgressBar from '../uniProgressBar/UniProgressBar';
import { StyledDrawerWrapper, StyledFixedDrawer } from './UniModalDrawer.style';
import UniModalDrawerHeader from './UniModalDrawerHeader';

const UniModalDrawer = (): ReactElement => {
  const { drawer, closeDrawer, props, title, setTitle, progress } = useDrawers();
  const { hide, show } = useTidioChat();
  const [currentDrawer, setCurrentDrawer] = useState<React.ReactElement>();
  const { profile } = useAuth();
  const [isFilters, setIsFilters] = useState(false);
  const isLoggedIn = !!profile?.id;
  const { t } = useTranslation('forms');
  const [hasBackButton, setHasBackButton] = useState(false);
  const profileImageSrc = useFile({ id: profile.profileImageFile?.id || '' }, { enabled: !!profile.profileImageFile });

  useEffect(() => {
    setHasBackButton(false);
    if (!drawer) {
      // show();
      setCurrentDrawer(undefined);
      setIsFilters(false);
      setTitle('');
    } else if (drawer?.includes('filter')) {
      let aux;
      // hide();
      setTitle('Filters');
      setIsFilters(true);

      switch (drawer) {
        case 'filter-agencies':
          aux = <FilterAgenciesForm close={closeDrawer} />;
          break;
        case 'filter-agents':
          aux = <FilterAgentsForm />;
          break;
        case 'filter-candidates':
          aux = <FilterCandidatesForm close={closeDrawer} />;
          break;
        case 'filter-programs':
          aux = <FilterProgramsForm />;
          break;
        case 'filter-faculty-programs':
          aux = <FilterUniversityProgramsFormForFacultyPage handleClose={closeDrawer} />;
          break;
        case 'filter-universities':
          aux = <FilterUniversitiesForm handleClose={closeDrawer} />;
          break;
        case 'filter-applications':
          aux = <FilterApplicationsForm close={closeDrawer} universityId={props?.universityId} />;
          break;
        case 'filter-universitiy-programs':
          aux = <FilterUniversityProgramsFormForProgramsPage close={closeDrawer} universityId={props?.universityId} />;
          break;
      }

      setCurrentDrawer(aux);
    } else {
      // hide();
      switch (drawer) {
        case 'invite-agency':
          setTitle('Invite Partner');
          setCurrentDrawer(<AdminCreateAgency key={`invite-agency-drawer-${!!drawer}`} close={closeDrawer} />);
          break;
        case 'invite-agent':
          setTitle('Invite Partner');
          setCurrentDrawer(<InviteAgent key={`invite-agent-drawer-${!!drawer}`} close={closeDrawer} />);
          break;
        case 'invite-candidate':
          setTitle('Invite Candidate');
          setCurrentDrawer(<InviteCandidate key={`invite-candidate-drawer-${!!drawer}`} close={closeDrawer} />);
          break;
        case 'invite-high-school':
          setTitle('Invite High School');
          setCurrentDrawer(<InviteHighSchool key={`invite-highschool-drawer-${!!drawer}`} />);
          break;
        case 'invite-high-school-student':
          setTitle('Invite Student');
          setCurrentDrawer(<InviteHighSchoolStudent key={`invite-highschool-candidate-drawer-${!!drawer}`} />);
          break;
        case 'resend-candidate':
          setTitle('Resend Invitation');
          setCurrentDrawer(
            <InviteCandidate key={`invite-candidate-drawer-${!!drawer}`} candidateForResend={props?.candidate} close={closeDrawer} />,
          );
          break;
        case 'resend-agency':
          setTitle('Resend Invitation');
          setCurrentDrawer(
            <AdminCreateAgency key={`resend-agency-drawer-${!!drawer}`} agencyForResend={props?.agency} close={closeDrawer} />,
          );
          break;
        case 'resend-agent':
          setTitle('Resend Invitation');
          setCurrentDrawer(<InviteAgent key={`resend-agent-drawer-${!!drawer}`} agentForResend={props?.agent} close={closeDrawer} />);
          break;
        case 'student-enroll':
          setTitle('Enroll to University');
          setCurrentDrawer(<StudentEnrollmentDrawer handleClose={closeDrawer} program={props?.program} />);
          break;
        case 'create-program':
          setTitle(props?.program ? 'Edit Program' : 'Add Program');
          setCurrentDrawer(<AddEditProgram program={props?.program} />);
          setHasBackButton(true);
          break;
        case 'university-reply':
          setTitle('Reply to Candidate');
          setCurrentDrawer(
            <UniversityReplyForm key={`reply-drawer-${!!drawer}`} application={props?.application} handleClose={closeDrawer} />,
          );
          break;
        case 'notes':
          setTitle('Notes');
          setCurrentDrawer(<NotesDrawer studentId={props.studentId!} key={`notes-drawer-${!!drawer}`} />);
          break;
        case 'mobile-navigation':
          setCurrentDrawer(<ModalDrawerNavigation />);
          break;
        case 'add-education':
          setTitle('Add Education');
          setCurrentDrawer(<AddEducationDrawer close={closeDrawer} />);
          setHasBackButton(true);
          break;
        case 'edit-education':
          setTitle('Edit Education');
          setCurrentDrawer(<EditEducationDrawer close={closeDrawer} educationItem={props?.educationItem} />);
          setHasBackButton(true);
          break;
        case 'delete-education':
          setTitle('Delete Education');
          setCurrentDrawer(<DeleteEducationDrawer close={closeDrawer} educationItem={props?.educationItem} />);
          setHasBackButton(true);
          break;
        case 'application-drawer':
          setTitle('Submit Application');
          setCurrentDrawer(<ApplicationDrawer program={props?.program} studentId={props.studentId} />);
          break;
        case 'personal-form':
          setTitle(t('titles.edit_personal_details'));
          setCurrentDrawer(<PersonalFormDrawer student={props?.candidate} key={`personal-drawer-${!!drawer}`} />);
          break;
        case 'preferences-form':
          setTitle(t('titles.edit_preferences_details'));
          setCurrentDrawer(<PreferencesFormDrawer student={props?.candidate} key={`preferences-drawer-${!!drawer}`} />);
          break;
        case 'accomplishments-form':
          setTitle(t('titles.edit_accomplishments_details'));
          setCurrentDrawer(<AccomplishmentsFormDrawer student={props?.candidate} key={`accomplishments-drawer-${!!drawer}`} />);
          break;
        case 'guardian-form':
          setTitle(t('titles.edit_guardian_details'));
          setCurrentDrawer(<GuardianFormDrawer student={props?.candidate} key={`guardian-drawer-${!!drawer}`} />);
          break;
        case 'contact-form':
          setTitle(t('titles.edit_contact_details'));
          setCurrentDrawer(<ContactDetailsDrawer student={props?.candidate} onboard={props.onboard!} key={`contact-drawer-${!!drawer}`} />);
          break;
        case 'mobile-notifications':
          setTitle('Notifications');
          setCurrentDrawer(<NotificationDrawerMobile handleClose={closeDrawer} />);
          break;
        case 'add-document':
          setTitle('Add new document');
          setCurrentDrawer(<AddNewDocument close={closeDrawer} />);
          setHasBackButton(true);
          break;
        case 'delete-document':
          setTitle('Delete document');
          setCurrentDrawer(<DeleteDocument close={closeDrawer} document={props?.document!} />);
          setHasBackButton(true);
          break;
        case 'reply-to-university':
          setTitle('Reply to University');
          setCurrentDrawer(<ReplyToUniversity application={props.application} />);
          setHasBackButton(true);
          break;
        case 'reply-to-student':
          setTitle('Reply to Student');
          setCurrentDrawer(<ReplyToStudent application={props.application} />);
          setHasBackButton(true);
          break;
        case 'application-fee':
          setTitle('Set application fee');
          setCurrentDrawer(<ApplicationFee application={props.application} />);
          setHasBackButton(true);
          break;
        case 'add-new-service':
          setTitle('Add new Service');
          setCurrentDrawer(<AddNewService />);
          setHasBackButton(true);
          break;
        case 'edit-service':
          setTitle('Edit Service');
          setCurrentDrawer(<AddNewService {...props} />);
          setHasBackButton(true);
          break;
        case 'delete-service':
          setTitle('Delete Service');
          setCurrentDrawer(<RemoveService service={props?.service} />);
          setHasBackButton(true);
          break;
        case 'reject-service':
          setTitle('Reject Service');
          setCurrentDrawer(<RejectService service={props?.serviceRequest} />);
          break;
        case 'create-service-order':
          setTitle('Create order');
          setCurrentDrawer(<CreateServiceOrder studentId={props?.studentId} onSuccess={props?.onSuccess} />);
          setHasBackButton(true);
          break;
        case 'edit-service-request':
          setTitle('Edit Request');
          setCurrentDrawer(<EditServiceRequest service={props?.serviceRequest} />);
          break;
        case 'add-service-offering':
          setTitle('Add Service');
          setCurrentDrawer(<AddServicePartner service={props?.service} />);
          setHasBackButton(true);
          break;
        case 'edit-service-offering':
          setTitle('Edit Service');
          setCurrentDrawer(<EditServicePartner service={props?.serviceOffering} />);
          setHasBackButton(true);
          break;
        case 'request-service':
          setTitle('Request Service');
          setCurrentDrawer(<RequestService />);
          setHasBackButton(true);
          break;
        case 'delete-service-offering':
          setTitle('Remove Service');
          setCurrentDrawer(<DeleteServiceOffering service={props?.serviceOffering} />);
          break;
        case 'update-candidate-status':
          setTitle('Update Status');
          setCurrentDrawer(<UpdateCandidateStatus close={closeDrawer} candidate={props?.candidate!} />);
          setHasBackButton(true);
          break;
        case 'suggest-programme':
          setTitle('Suggest Programme');
          setCurrentDrawer(<SuggestProgram program={props?.program} />);
          setHasBackButton(true);
          break;
        case 'update-program-info-request':
          setTitle('Mark as updated');
          setCurrentDrawer(<UpdateProgramInfoRequest programRequest={props?.programRequest!} close={closeDrawer} />);
          setHasBackButton(true);
          break;
        case 'assign-agent':
          setTitle('Assign Candidate');
          setCurrentDrawer(<AssignAgentDrawer student={props?.candidate} />);
          break;
        case 'add-activity-item':
          setTitle('Add Activity Item');
          setCurrentDrawer(<AddActivityItemDrawer studentId={props?.studentId} />);
          break;
        case 'edit-activity-item':
          setTitle('Edit Activity Item');
          setCurrentDrawer(<EditActivityItemDrawer achievement={props?.achievement} todo={props?.todo} />);
          break;
        case 'add-task':
          setTitle('Add task');
          setCurrentDrawer(<AddTaskDrawer userId={props?.studentId!} applicationId={props?.applicationId!} />);
          break;
        case 'edit-task':
          setTitle('Edit task');
          setCurrentDrawer(<EditTaskDrawer taskId={props?.taskId!} userType={props?.userType!} />);
          break;
        case 'toggle-application-period':
          setTitle('Toggle application period');
          setCurrentDrawer(<ToggleApplicationPeriod program={props?.program} />);
          break;

        case 'add-proof-of-completion':
          setTitle('Proof of Completion');
          setCurrentDrawer(<AddProofOfCompletion achievement={props?.achievement} todo={props?.todo} />);
          break;

        case 'delete-activity-item':
          setTitle('Delete activity item');
          setCurrentDrawer(<DeleteActivityItem achievement={props?.achievement} todo={props?.todo} />);
          break;
        case 'request-activity-update':
          setTitle('Request updates');
          setCurrentDrawer(<RequestActivityUpdate achievement={props?.achievement} todo={props?.todo} />);
          break;
        case 'activity-item-preview':
          setTitle('Activity Item');
          setCurrentDrawer(<ActivityItemPreview achievement={props?.achievement} todo={props?.todo} />);
          break;
        case 'required-updates-preview':
          setTitle('Update required');
          setCurrentDrawer(<RequiredUpdatesPreview achievement={props?.achievement} todo={props?.todo} />);
          break;
        case 'publish-public-profile':
          setTitle('Public profile');
          console.log(drawer);
          setCurrentDrawer(<PublishPublicProfile />);
          break;
        case 'unpublish-public-profile':
          setTitle('Public profile');
          console.log(drawer);
          setCurrentDrawer(<UnpublishPublicProfile />);
          break;
        case 'create-event':
          setTitle('Create Event');
          setCurrentDrawer(<CreateEvent />);
          break;
        case 'edit-event':
          setTitle('Edit Event');
          setCurrentDrawer(<CreateEvent event={props?.event} />);
          break;
        case 'event-registration':
          setTitle('Join our event');
          setCurrentDrawer(<EventRegistration event={props?.event} feePaid={props?.feePaid} />);
          break;
        case 'remove-event':
          setTitle('Delete event');
          setCurrentDrawer(<RemoveEvent event={props?.event} />);
          break;
        case 'interests-quiz':
          setTitle(props?.quiz?.title || 'Interests Quiz');
          setCurrentDrawer(<InterestsQuiz quiz={props?.quiz} />);
          break;
      }
    }
  }, [drawer]);

  return (
    <StyledDrawerWrapper isOpen={!!drawer}>
      <StyledFixedDrawer side={props?.side || 'right'} isOpen={!!drawer} onClick={e => e.stopPropagation()}>
        <UniModalDrawerHeader
          title={title}
          isFilters={isFilters}
          onClose={!isFilters && !hasBackButton ? closeDrawer : undefined}
          onBack={isFilters || hasBackButton ? closeDrawer : undefined}
          TitleComponent={
            drawer === 'mobile-navigation' ? (
              isLoggedIn ? (
                <UniAvatar
                  size='S'
                  src={profileImageSrc.data?.url}
                  alt={profile.username}
                  label={`${profile.firstName} ${profile.lastName}`}
                />
              ) : (
                <UniLogo />
              )
            ) : undefined
          }
        />
        <DrawerProgress />
        {!!currentDrawer ? currentDrawer : <UniBounceLoader />}
      </StyledFixedDrawer>
    </StyledDrawerWrapper>
  );
};

const DrawerProgress = () => {
  const { progress } = useDrawers();

  if (!progress) {
    return <></>;
  }

  return <UniProgressBar size='s' progress={progress} />;
};

export default UniModalDrawer;
