import styled from 'styled-components';
import { Colors, Shape } from '../../constants';

export const StyledDocumentsListHeader = styled.div`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1, 2)};
`;

export const StyledDocumentsList = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 0)};
`;

export const StyledDocumentsListItem = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1, 2)};
`;
