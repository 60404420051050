import { Box } from '@material-ui/core';
import useDeleteServiceOptions from 'api/service-options/useDeleteServiceOptions';
import { Components } from 'client/UniClient';
import { UniModalDrawerButtons, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers, useSnackbar } from 'store';

interface Props {
  service?: Components.Schemas.ServiceOptionDto;
}

export default function RemoveService({ service }: Props): ReactElement {
  const { t: tButtons } = useTranslation('buttons');
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const [deleteService, { isLoading }] = useDeleteServiceOptions();

  const onClose = () => closeDrawer();

  const onDelete = () => {
    closeDrawer();
    openSnackbar('delete-service-offering', {
      hide: service?.id,
      action: () => deleteService({ serviceOptionId: service?.id || '' }),
    });
  };

  return (
    <>
      <UniModalDrawerContent>
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          You are about to remove from your offerings the following service:
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='body1' color='secondary100' weight='medium'>
          {service?.name}
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          Are you sure you want to delete it?
        </UniTypography>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: onClose, label: tButtons('cancel') }}
        PrimaryButton={{ onClick: onDelete, loading: isLoading, label: tButtons('yes_delete') }}
      />
    </>
  );
}
