import { useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  _page?: number;
  universityIds?: string[];
};

export default function useProgramFiltersFaculties(params?: Params) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.General.Programs, params],
    async (_key: string, params: Params) => {
      if (params?.universityIds && params?.universityIds.length > 0) {
        const promises = [params.universityIds]
          .flat(1)
          .map(item =>
            client!
              .FacultyApi_getUniversityFaculties(item)
              .then(res => res.data.map(faculty => ({ label: faculty.name as string, value: faculty.id as string }))),
          );
        return (await Promise.all(promises)).flat(1);
      }
    },
    { refetchOnWindowFocus: false },
  );
}
