import { useMutation } from 'react-query';
import { Paths } from 'client/UniClient';
import { useClient } from 'store';

type Params = Paths.TaskApiDeleteTaskDocument.PathParameters;

export default function useDeleteTaskDocument() {
  const { client } = useClient();

  return useMutation(
    async (params: Params) => {
      await client!.TaskApi_deleteTaskDocument(params);
    },
  );
}
