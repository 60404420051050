import { useAllStudentPrograms } from 'api';
import { Components } from 'client/UniClient';
import { ApplicationCard, InfiniteWindowedGrid } from 'components';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { BrowserRoutes } from '../../../../constants';
interface Props {}

const AllPrograms: React.FC<Props> = ({ children, ...rest }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const studentId = pathname.split('/')[4];
  const {
    data: allPrograms,
    status: allProgramsStatus,
    fetchMore: fetchMoreAllPrograms,
    isFetching,
  } = useAllStudentPrograms({ studentId });

  const allProgramsLoading = allProgramsStatus === 'loading';
  const allProgramsFlat = useMemo(() => {
    return allPrograms?.map(progs => progs.results).flat() || [];
  }, [allPrograms, allProgramsStatus]);
  const allProgramsTotal = useMemo(() => (allPrograms ? allPrograms[0].total : 0), [allPrograms]);
  const allProgramsLeft = useMemo(() => allProgramsTotal - allProgramsFlat.length, [allProgramsFlat, allProgramsTotal]);

  const goToProgramPage = (program: Components.Schemas.ProgramResponse) => {
    if (program?.application?.id) {
      return BrowserRoutes.Agent.Application.General(program.application.id);
    } else {
      return BrowserRoutes.Agent.StudentProgram.General.replace(':programId', program.id!).replace(':studentId', studentId);
    }
  };

  return (
    <InfiniteWindowedGrid
      fetchMore={fetchMoreAllPrograms}
      emptyStateType='programs'
      isFetching={isFetching}
      isLoading={allProgramsLoading}
      hasMore={allProgramsLeft > 0}
      remainingResults={allProgramsLeft}
      minHeight={250}
      results={allProgramsFlat?.map((program, index: any) => (
        <ApplicationCard
          key={`${index}-program`}
          variant='candidateApplicationsPage'
          studentName={`${program.application?.student?.firstName || ''} ${program.application?.student?.lastName || ''}`}
          studentAddress={program.application?.student?.studentProfile.address}
          programName={program.name}
          universityName={program.universityName}
          programAddress={program.address}
          status={program.application?.status}
          path={goToProgramPage(program)}
        />
      ))}
    />
  );
};

export default AllPrograms;
