import { UniTextArea, UniTextField } from 'components';
import { Controller } from 'react-hook-form';

interface Props {
  register: any;
  control: any;
}

export interface EditServiceFormInputs {
  name: string;
  description: string;
}

const EditServiceForm: React.FC<Props> = ({ register, control, children, ...rest }) => {
  return (
    <>
      <Controller
        name='name'
        control={control}
        rules={{ required: 'Please enter a note', validate: value => value.trim() !== '' || 'Please enter a note' }}
        render={({ value, onChange }) => (
          <UniTextField fullWidth name='name' label='Name' placeholder='ex: Admission letter' value={value} onChange={onChange} />
        )}
      />
      <Controller
        name='description'
        control={control}
        rules={{ required: 'Please enter a note', validate: value => value.trim() !== '' || 'Please enter a note' }}
        render={({ value, onChange }) => (
          <UniTextArea
            rowsMin={2}
            rowsMax={10}
            value={value}
            placeholder='Describe in a sentence or two what you’ll teach the student to do, what are the guidance steps and the final result.'
            onChange={onChange}
            label='Description'
            withBorder
            borderRadius={[8, 8, 8, 8]}
            variant='body2'
          />
        )}
      />
    </>
  );
};

export default EditServiceForm;
