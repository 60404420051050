import { Box } from '@material-ui/core';
import { TComponentColors } from 'constants/componentColors';
import { useBreakpoints } from 'hooks';
import { ReactComponent as EyeIcon } from '../../../assets/svg/Icons/Eye.svg';
import { ReactComponent as GarbageIcon } from '../../../assets/svg/Icons/Garbagev2.svg';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import { Weight } from '../uniTypography/types';
import UniTypography from '../uniTypography/UniTypography';
import { StyledDiv, StyledUniListItem } from './UniListItem.style';

interface Props {
  type: 'label' | 'twoLabels' | 'iconed';
  size?: 'L' | 'M' | 'S';
  weight?: Weight;
  autoHeight?: boolean;
  wordBreak?: boolean;
  label1: string;
  onDelete?: Function;
  onShow?: Function;
  label2?: string;
  withBackground?: boolean;
  backgroundColor?: TComponentColors;
  singleIcon?: React.ReactElement;
}

const getSizeOfLabel = (size: 'L' | 'M' | 'S') => {
  if (size === 'L') return 'body1';
  if (size === 'M') return 'body2';
  return 'subheader';
};

export const UniListItem = ({
  type,
  size = 'M',
  weight = 'regular',
  autoHeight,
  wordBreak = true,
  withBackground = false,
  backgroundColor,
  label1,
  label2,
  onShow,
  onDelete,
  singleIcon,
}: Props) => {
  const { isMobile } = useBreakpoints();
  return (
    <StyledUniListItem type={type} size={size} withBackground={withBackground} backgroundColor={backgroundColor} autoHeight={autoHeight}>
      <Box width='100%' maxWidth={isMobile ? '104px' : '50%'}>
        <UniTypography
          style={{ wordBreak: 'break-word' }}
          variant={getSizeOfLabel(size)}
          color={backgroundColor === 'secondary10' ? 'secondary100' : 'typographyBlack100'}
          weight={weight}
        >
          {label1}
        </UniTypography>
      </Box>
      <Box>
        {!!label2 && type === 'twoLabels' && (
          <UniTypography style={{ wordBreak: wordBreak ? 'break-word' : 'unset' }} variant={getSizeOfLabel(size)} color='typographyGray100'>
            {label2}
          </UniTypography>
        )}
      </Box>
      {type === 'iconed' && (
        <StyledDiv>
          <UniIconWrapper size={size === 'S' ? 'main' : 'medium'} color='typographyGray100' onClick={() => onShow && onShow()}>
            <EyeIcon />
          </UniIconWrapper>
          {!!onDelete && (
            <UniIconWrapper size={size === 'S' ? 'main' : 'medium'} color='typographyGray100' onClick={() => onDelete && onDelete()}>
              <GarbageIcon />
            </UniIconWrapper>
          )}
        </StyledDiv>
      )}
      {!!singleIcon && <StyledDiv>{singleIcon}</StyledDiv>}
    </StyledUniListItem>
  );
};
