import { usePatchCandidate } from 'api';
import useUpdateStudent from 'api/students/useUpdateStudent';
import { Components } from 'client/UniClient';
import { UniModalDrawerButtons } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import PreferencesForm, { PreferencesFormInputs } from 'components/_forms/PreferencesForm';
import { serviceDomains } from 'constants/options/serviceDomains';
import { studyInterests } from 'constants/options/studyInterests';
import { yearlyBudget } from 'constants/options/yearlyBudget';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth, useDrawers, useSnackbar } from 'store';
import { Nationality } from 'types';
import { getMetaRole } from 'utils';
import { countries } from '../../../constants';

interface Props {
  student?: Components.Schemas.UserResponse;
}

const PreferencesFormDrawer: React.FC<Props> = ({ student }) => {
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const profile = useAuth(state => state.profile);
  const { t } = useTranslation('forms');
  const studyInterestsArray = [
    ...(student?.studentProfile?.preferences?.studyInterests || []),
    ...(student?.studentProfile?.preferences?.otherStudyInterests || []),
  ];
  const interestsOfStudy = studyInterests.filter(item => studyInterestsArray.includes(item.value));
  const countryInterests = countries.filter(item => student?.studentProfile?.preferences?.countries?.includes(item.code as Nationality));
  const serviceInterestsArray = [
    ...(student?.studentProfile?.preferences?.stagesRequiringAssistance || []),
    ...(student?.studentProfile?.preferences?.otherStagesRequiringAssistance || []),
  ];
  const serviceInterests = serviceDomains.filter(item => serviceInterestsArray?.includes(item.value as Nationality));

  const defaultValues: Partial<PreferencesFormInputs> = {
    countryInterests,
    particularSchool: student?.studentProfile.preferences?.interestedSchoolName,
    serviceInterests,
    studyInterests: interestsOfStudy,
    yearlyBudget: yearlyBudget.find(item => item.value === student?.studentProfile.preferences?.yearlyBudget),
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PreferencesFormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const { pathname } = useLocation();
  const [updateStudent, { status: updateStudentStatus, error: updateStudentError }] = useUpdateStudent();
  const [updateStudentAgency, { status: updateStudentAgencyStatus, error: updateStudentAgencyError }] = usePatchCandidate();
  const { isAgency, isAgent, isStudent } = getMetaRole(profile?.role);

  const isLoading = updateStudentStatus === 'loading' || updateStudentAgencyStatus === 'loading';

  const onSubmit = (formData: PreferencesFormInputs) => {
    const studyInterestArrayCheck = studyInterests.map(item => item.value);
    const studyInterestsRequest = formData?.studyInterests
      ?.map(({ value }) => value)
      .filter(item => studyInterestArrayCheck.includes(item));
    const otherStudyInterestsRequest = formData?.studyInterests
      ?.map(({ value }) => value)
      .filter(item => !studyInterestArrayCheck.includes(item));

    const serviceDomainsArrayCheck = serviceDomains.map(item => item.value);
    const stagesRequiringAssistance = formData?.serviceInterests
      ?.map(({ value }) => value)
      .filter(item => serviceDomainsArrayCheck.includes(item));
    const otherStagesRequiringAssistance = formData?.serviceInterests
      ?.map(({ value }) => value)
      .filter(item => !serviceDomainsArrayCheck.includes(item));

    const requestForm: Components.Schemas.UpdateStudentRequest = {
      studentProfile: {
        studentPreferences: {
          countries: formData.countryInterests.map(item => item.code as Nationality),
          interestedSchoolName: formData.particularSchool,
          studyInterests: studyInterestsRequest,
          otherStudyInterests: otherStudyInterestsRequest,
          stagesRequiringAssistance,
          otherStagesRequiringAssistance,
        },
      },
    };
    if (isStudent) {
      updateStudent(requestForm, {
        onSuccess() {
          closeDrawer();
          openSnackbar('personal-details');
        },
        onError() {},
      });
    }
    if (isAgency) {
      const studentId = pathname.split('/')[4];
      updateStudentAgency(
        { ...requestForm, studentId },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('personal-details');
          },
          onError() {},
        },
      );
    }
    if (isAgent) {
      const studentId = pathname.split('/')[4];
      updateStudentAgency(
        { ...requestForm, studentId },
        {
          onSuccess() {
            closeDrawer();
            openSnackbar('personal-details');
          },
          onError() {},
        },
      );
    }
  };

  return (
    <>
      <UniModalDrawerContent>
        <PreferencesForm control={control} errors={errors} register={register} />
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: t('buttons.save_changes'),
          loading: isLoading,
        }}
        SecondaryButton={{ onClick: closeDrawer, label: t('buttons.cancel') }}
      />
    </>
  );
};

export default PreferencesFormDrawer;
