import useCreateProgram from 'api/programs/useCreateProgram';
import useUpdateProgram from 'api/programs/useUpdateProgram';
import { Components } from 'client/UniClient';
import { AnimatedTabPanel, UniModalDrawerButtons } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import Step1 from './_steps/Step1';
import Step2 from './_steps/Step2';
import Step3 from './_steps/Step3';
import Step4 from './_steps/Step4';
import Step5 from './_steps/Step5';
import Step6 from './_steps/Step6';

interface Props {
  program?: Components.Schemas.ProgramResponse;
}

type ProgramType = Partial<Components.Schemas.CreateProgramRequest & Components.Schemas.UpdateProgramRequest>;

type FormInputs = Pick<ProgramType, Exclude<keyof ProgramType, 'admissionRequirements'>> & { admissionRequirements: { name: string }[] };

const AddEditProgram: React.FC<Props> = ({ program }) => {
  const [step, setStep] = useState(0);
  const { closeDrawer, setProgress } = useDrawers();
  const { t } = useTranslation('createProgram');
  const defaultProgram = program
    ? {
        ...program,
        ...program.address,
        ...program.virtualAddress,
        admissionRequirements: program?.admissionRequirements.map(item => ({ name: item })),
      }
    : undefined;

  const [programData, setProgramData] = useState<FormInputs | undefined>(defaultProgram);
  const { control, register, handleSubmit, setValue, errors, watch, reset } = useForm<FormInputs>({
    defaultValues: {
      ...programData,
      admissionRequirements: programData?.admissionRequirements || [],
      requiredDocuments: programData?.requiredDocuments || [
        {
          name: '',
          description: '',
        },
      ],
    },
    shouldUnregister: false,
  });
  const [create, { isLoading: isLoadingCreate, data: createProgramData }] = useCreateProgram();
  const [update, { isLoading: isLoadingUpdate, data: updateProgramData }] = useUpdateProgram();
  const programName = createProgramData?.data.name || updateProgramData?.data.name || '';

  const prevStep = () => {
    reset(programData);
    setStep(prev => prev - 1);
  };

  const nextStep = (formData: FormInputs) => {
    setProgramData(prev => ({ ...prev, ...formData }));
    setStep(prev => prev + 1);
  };

  const onSubmit = (formData: FormInputs) => {
    setProgramData(prev => ({ ...prev, ...formData }));
    const programToBeCreated: ProgramType = {
      ...programData,
      ...formData,
      admissionRequirements: programData?.admissionRequirements?.filter(item => !!item.name).map(item => item.name) || [],
      requiredDocuments: programData?.requiredDocuments?.filter(item => !!item.name) || [],
    };

    if (!!program?.id) {
      update({
        pathParams: {
          programId: program.id,
        },
        payload: programToBeCreated,
      });
    } else {
      create(programToBeCreated);
    }
    setStep(prev => prev + 1);
  };

  const buttonTexts = [
    {
      secondaryCallback: closeDrawer,
      primaryCallback: handleSubmit(nextStep),
      secondary: t('buttons.cancel'),
      primary: t('buttons.nextStep'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: handleSubmit(nextStep),
      secondary: t('buttons.goBack'),
      primary: t('buttons.nextStep'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: handleSubmit(nextStep),
      secondary: t('buttons.goBack'),
      primary: t('buttons.nextStep'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: handleSubmit(nextStep),
      secondary: t('buttons.goBack'),
      primary: t('buttons.nextStep'),
    },
    {
      secondaryCallback: prevStep,
      primaryCallback: handleSubmit(onSubmit),
      secondary: t('buttons.goBack'),
      primary: !!program ? t('buttons.editProgramme') : t('buttons.addProgramme'),
      loading: isLoadingCreate || isLoadingUpdate,
    },
    {
      secondaryCallback: () => {},
      primaryCallback: closeDrawer,
      primary: t('buttons.goBack'),
      loading: isLoadingCreate || isLoadingUpdate,
    },
  ];

  useEffect(() => setProgress(step * 15 + 25), [step]);

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel Content={<Step1 control={control} register={register} errors={errors} />} index={0} currentItem={step} />
          <AnimatedTabPanel
            Content={<Step2 control={control} register={register} errors={errors} setValue={setValue} />}
            index={1}
            currentItem={step}
          />
          <AnimatedTabPanel Content={<Step3 control={control} register={register} />} index={2} currentItem={step} />
          <AnimatedTabPanel Content={<Step4 control={control} register={register} />} index={3} currentItem={step} />
          <AnimatedTabPanel Content={<Step5 control={control} register={register} />} index={4} currentItem={step} />
          <AnimatedTabPanel Content={<Step6 programName={programName} />} index={5} currentItem={step} />
        </AnimatePresence>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={
          buttonTexts[step]?.secondary
            ? {
                onClick: () => buttonTexts[step].secondaryCallback(),
                label: buttonTexts[step].secondary,
              }
            : undefined
        }
        PrimaryButton={{
          onClick: buttonTexts[step]?.primaryCallback,
          label: buttonTexts[step]?.primary,
          loading: !!buttonTexts[step]?.loading,
        }}
      />
    </>
  );
};

export default AddEditProgram;
