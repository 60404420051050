import React from 'react';
import { Components } from 'client/UniClient';
import { useTranslation } from 'react-i18next';
import { StickyListFilterBar } from '../index';
import ListHeader from './ListHeader';
import { StyledPackages } from './PackagesList.style';
import UniAdminPrepCenterPackagesListItem from './UniAdminPrepCenterPackagesListItem';

type PrepPackDto = Components.Schemas.PrepPackDto;

interface Props {
  prepPacks: PrepPackDto[];
}

export const UniAdminPrepCenterPackagesList: React.FC<Props> = ({ prepPacks }) => {
  const { t } = useTranslation('prepCenter');

  return (
    <>
      <StickyListFilterBar
        listTitle={t('tabs.packages')}
      />
      <ListHeader />
      <StyledPackages>
        {prepPacks.map(prepPack => (
          <UniAdminPrepCenterPackagesListItem prepPack={prepPack} />
        ))}
      </StyledPackages>
    </>
  );
};

export default UniAdminPrepCenterPackagesList;
