import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useClient } from "../../store";


type Payload = {
    token: string;
    password: string;
};
export const useResetPassword = () => {
    const { client } = useClient();

    return useMutation(
        async (payload: Payload) => {
            return client!.AuthApi_resetPassword(undefined, payload)
        },
        {
            onError: (error: AxiosError) => {
                console.error(error.message)
            },
        }
    );
};
