import { Box, Fade, InputAdornment, InputProps, OutlinedInput } from '@material-ui/core';
import { VisibilityOffOutlined } from '@material-ui/icons';
import { useBreakpoints } from 'hooks';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import { ReactComponent as EyeIcon } from '../../../assets/svg/Icons/Eye.svg';
import { Colors } from '../../../constants';
import { TComponentColors } from '../../../constants/componentColors';
import UniIconWrapper from '../../_common/uniIconWrapper/UniIconWrapper';
import UniTypography from '../../_common/uniTypography/UniTypography';
import { Container, Error, Label, StyledAlertIcon, StyledIcon } from './UniTextField.style';

interface UniTextFieldBaseProps extends Omit<InputProps, 'color'> {
  optional?: boolean;
  optionalLabelSize?: 'small' | 'footnote' | 'subheader' | 'body2' | 'body1' | 'header3' | 'header2' | 'header1' | 'xl' | undefined;
  label?: string;
  helperText?: string;
  labelColor?: TComponentColors;
  borderRadius?: Array<number>;
  borderColor?: 'primary' | 'alert';
  backgroundColor?: any;
  variant?: 'small' | 'footnote' | 'subheader' | 'body2' | 'body1' | 'header3' | 'header2' | 'header1' | 'xl' | undefined;
  type?: string;
  topRightArea?: React.ReactNode;
  adornmentColor?: TComponentColors;
  noBorder?: boolean;
  multiline?: boolean;
  endComponent?: React.ReactElement;
}

const UniTextField: React.FC<UniTextFieldBaseProps> = ({
  label,
  helperText,
  endAdornment,
  startAdornment,
  optional,
  endComponent,
  optionalLabelSize = 'footnote',
  labelColor = 'typographyBlack100',
  borderRadius,
  backgroundColor,
  noBorder = false,
  borderColor,
  variant,
  topRightArea,
  type = 'text',
  adornmentColor,
  multiline = false,
  placeholder,
  ...props
}) => {
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [showPass, setShowPass] = useState(false);
  const [border, setBorder] = useState<'primary' | 'alert' | undefined>(borderColor);
  const { isMobile } = useBreakpoints();
  useEffect(() => {
    if (!!helperText) {
      setBorder('alert');
      setErrorMsg(() => helperText);
    } else setBorder(undefined);
  }, [helperText]);
  return (
    <Container backgroundColor={backgroundColor} hasLabel={!!label}>
      {!!label && (
        <Label>
          <UniTypography variant='subheader' color={labelColor} weight='medium'>
            {label}
          </UniTypography>
          {optional && (
            <UniTypography variant={optionalLabelSize} color='typographyGray70'>
              Optional
            </UniTypography>
          )}
          {topRightArea && (
            <Box display='flex' alignItems='center'>
              {topRightArea}
            </Box>
          )}
        </Label>
      )}
      <OutlinedInput
        {...props}
        multiline={multiline}
        placeholder={placeholder}
        type={showPass ? 'text' : type}
        style={{
          border:
            noBorder === true
              ? 'none'
              : `1px solid ${
                  borderColor === 'primary'
                    ? Colors.Secondary._100
                    : border === 'alert'
                    ? Colors.State.Alert_100
                    : Colors.Typography.Gray_50
                }`,
          height: multiline ? 'auto' : '56px',
          //minWidth: isMobile ? '80px' : type === 'date' ? '152px' : type === 'ranged' ? '216px' : type === 'fee' ? '118px' : '280px',
          minWidth: 'fit-content',
          backgroundColor: backgroundColor,
        }}
        startAdornment={
          startAdornment && (
            <InputAdornment position='start'>
              <StyledIcon>{startAdornment}</StyledIcon>
            </InputAdornment>
          )
        }
        endAdornment={
          endAdornment ? (
            <InputAdornment position='end'>
              {type === 'password' ? (
                <UniIconWrapper
                  size='medium'
                  color='typographyGray100'
                  onClick={() => {
                    setShowPass(prevShowPass => !prevShowPass);
                  }}
                >
                  {showPass ? <VisibilityOffOutlined /> : <EyeIcon />}
                </UniIconWrapper>
              ) : (
                <StyledIcon color={adornmentColor}>{endAdornment}</StyledIcon>
              )}
            </InputAdornment>
          ) : (
            endComponent && endComponent
          )
        }
      />

      <Fade in={!!helperText} unmountOnExit>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {errorMsg}
          </UniTypography>
        </Error>
      </Fade>
    </Container>
  );
};

export default UniTextField;
