import React from 'react';
import { TComponentColors } from '../../../constants/componentColors';
import { CircularButton } from './UniButtonCircular.style';

export const UniButtonCircular = (props: {
  Icon: React.ReactElement;
  iconColor: TComponentColors;
  hoverColor: TComponentColors;
  hasSize?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactElement;
}) => {
  return (
    <CircularButton color='inherit' ccolor={props.iconColor} hoverColor={props.hoverColor} onClick={props.onClick} hasSize={props.hasSize}>
      {props.Icon}
    </CircularButton>
  );
};
