import { SnackbarPro } from 'components/snackbar/Snackbar';
import { useBreakpoints } from 'hooks';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'store';
import { BrowserRoutes } from '../../../constants';
import { StyledSnackbar } from './UniSnackbar.style';

const UniSnackbar = (): ReactElement => {
  const { snackbar, closeSnackbar, props, setHide } = useSnackbar();
  const history = useHistory();
  const { isMobile } = useBreakpoints();
  const [currentMessage, setCurrentMessage] = useState('');
  const [action, setAction] = useState<Function>();
  const [actionButtonName, setActionButtonName] = useState('');
  const { t } = useTranslation('snackbar');
  const [undo, setUndo] = useState(false);

  const goToEducation = () => {
    history.push(BrowserRoutes.Student.Profile.Default.replace(':tab', 'education'));
    closeSnackbar();
  };

  useEffect(() => {
    if (!snackbar) {
      setActionButtonName('');
      setUndo(false);
      setAction(undefined);
    } else {
      switch (snackbar) {
        case 'onboarding':
          setCurrentMessage(t('onboarding'));
          break;
        case 'add-document':
          setCurrentMessage(t('profile.documents.add'));
          break;
        case 'add-education':
          setCurrentMessage(t('profile.education.add'));
          break;
        case 'add-program':
          setCurrentMessage(t('programs.add'));
          break;
        case 'personal-details':
          setCurrentMessage(t('profile.profile.personal'));
          break;
        case 'contact-details':
          setCurrentMessage(t('profile.profile.contact'));
          break;
        case 'profile-email':
          setCurrentMessage(t('profile.profile.email'));
          break;
        case 'delete-document':
          setCurrentMessage(t('profile.documents.delete'));
          setActionButtonName(t('undo'));
          break;
        case 'delete-education':
          setCurrentMessage(t('profile.education.delete'));
          setActionButtonName(t('undo'));
          break;
        case 'delete-program':
          setCurrentMessage(t('programs.remove'));
          setActionButtonName(t('undo'));
          break;
        case 'edit-education':
          setCurrentMessage(t('profile.education.edit'));
          break;
        case 'apply-program':
          setAction(() => goToEducation);
          setCurrentMessage(t('programs.apply'));
          setActionButtonName('ADD EDUCATION');
          break;
        case 'completed-stepper':
          setCurrentMessage(t('stepper'));
          break;
        case 'edit-service-offering':
          setCurrentMessage(t('services.edit'));
          break;
        case 'delete-service-offering':
          setCurrentMessage(t('services.delete'));
          setActionButtonName(t('undo'));
          break;
        case 'profile-image-uploaded':
          setCurrentMessage(t('profile.profile.image'));
          break;
        case 'open-application-period':
          setCurrentMessage(t('application.period.open'));
          break;
        case 'close-application-period':
          setCurrentMessage(t('application.period.close'));
          break;
        case 'update-requested':
          setCurrentMessage(t('activities.updates-requested'));
          break;
        case 'delete-activity':
          setCurrentMessage(t('activities.delete-activity'));
          break;
        case 'create-service':
          setCurrentMessage(t('services.create'));
          break;
        case 'edit-service':
          setCurrentMessage(t('services.edit'));
          break;
        case 'service-added-to-list':
          setCurrentMessage(t('services.added_to_list'));
          break;
        case 'reject-service':
          setCurrentMessage(t('services.reject_service'));
          setActionButtonName(t('undo'));
          break;
        case 'public-profile-link':
          setCurrentMessage(t('public_profile.link'));
          break;
        case 'link-copied':
          setCurrentMessage(t('link_copied'));
          break;
        case 'achievement-already-added':
          setCurrentMessage(t('activities.already_added'));
          break;
      }
    }
  }, [snackbar]);

  const handleUndo = (value: boolean) => {
    if (props?.onUndo) {
      props.onUndo();
      closeSnackbar();
    } else {
      setHide(undefined);
      setUndo(value);
      closeSnackbar();
    }
  };

  return (
    <StyledSnackbar
      position={props?.mobileOver}
      mobile={isMobile}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      disableWindowBlurListener
      open={!!snackbar}
      autoHideDuration={3000}
      onClose={() => {
        if (!undo) {
          props.action && props.action();
        }
        closeSnackbar();
      }}
    >
      <SnackbarPro action={action} undoIt={handleUndo} message={currentMessage} actionButtonName={actionButtonName} />
    </StyledSnackbar>
  );
};
export default UniSnackbar;
