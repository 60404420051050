import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationStatus } from 'types';
import { UniStatus, UniTypography } from '../../..';
import { StyledApplicationIcon } from '../UniversityReplyForm.style';

interface Props {
  status?: ApplicationStatus;
  documents?: File[];
  message?: string;
}

export default function UniversityReplyStep4({ status, documents, message }: Props): ReactElement {
  const { t } = useTranslation('universityReplyForm');

  return (
    <>
      <UniTypography variant='header3'>{t('step4.title')}</UniTypography>
      <Box mb={5} mt={4}>
        <Box mb={2}>
          <UniTypography weight='medium'>{t('general.status_title')}</UniTypography>
        </Box>
        <UniStatus status={status} />
      </Box>
      <Box mb={5}>
        <Box mb={2}>
          <UniTypography weight='medium'>{t('general.docs_title')}</UniTypography>
        </Box>
        {documents?.map(document => (
          <Box display='flex' alignItems='center' mt={2}>
            <StyledApplicationIcon />
            <Box ml={1} mr={3}>
              <UniTypography color='typographyGray70'>{`${document.name.substr(0, 30)}${
                document.name.length > 19 && '...'
              }`}</UniTypography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box mb={5}>
        <Box mb={2}>
          <UniTypography weight='medium'>{t('general.message_title')}</UniTypography>
        </Box>
        <UniTypography color='typographyGray70'>{message}</UniTypography>
      </Box>
    </>
  );
}
