import { Box } from '@material-ui/core';
import { AuthForm, StyledChildrenWrapperTop } from 'components/_layouts/authLayout/AuthLayout.style';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useResetPassword } from '../../../api/auth/useResetPassword';
import { ReactComponent as LockIcon } from '../../../assets/svg/Icons/Lock.svg';
import { UniButton, UniIconWrapper, UniTextField, UniTypography } from '../../../components';
import { BrowserRoutes } from '../../../constants';
import { useBreakpoints, useErrorHandler } from '../../../hooks';

type Inputs = {
  newPassword: string;
  confirmPassword: string;
};

const ResetPassword = (props: {}) => {
  const { isMobile } = useBreakpoints();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { t } = useTranslation('resetPassword');

  const [mismatch, setMismatch] = useState(false);

  const [resetPassword, { status, error: resetPasswordError }] = useResetPassword();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });

  const resetError = useErrorHandler(resetPasswordError!);

  const isLoading = status === 'loading';

  const onSubmit = (formData: Inputs) => {
    if (formData.newPassword !== formData.confirmPassword) {
      setMismatch(true);
      return;
    }
    setMismatch(false);
    resetPassword(
      { token: token, password: formData.newPassword },
      {
        onSuccess() {
          history.push(BrowserRoutes.Default.Auth.Login);
        },
        onError() {
          reset();
        },
      },
    );
  };

  const newPasswordMessage = errors?.newPassword?.message ?? '';

  return (
    <>
      <StyledChildrenWrapperTop>
        <AuthForm onSubmit={handleSubmit(onSubmit)} noValidate>
          <UniTextField
            placeholder={t('placeholders.password')}
            fullWidth
            startAdornment={
              <UniIconWrapper color='typographyGray100' size='medium'>
                <LockIcon />
              </UniIconWrapper>
            }
            helperText={newPasswordMessage}
            name='newPassword'
            error={!!newPasswordMessage}
            inputRef={register({
              required: {
                value: true,
                message: t('errors.requiredPassword'),
              },
            })}
            label={t('labels.password')}
            type='password'
          />
          <UniTextField
            placeholder={t('placeholders.confirmPassword')}
            fullWidth
            startAdornment={
              <UniIconWrapper color='typographyGray100' size='medium'>
                <LockIcon />
              </UniIconWrapper>
            }
            helperText={mismatch ? t('errors.matchPassword') : undefined}
            name='confirmPassword'
            error={mismatch}
            inputRef={register()}
            label={t('labels.confirmPassword')}
            type='password'
          />
        </AuthForm>
        {resetError.message && <UniTypography color='stateAlert100'>{resetError.message}</UniTypography>}
        <Box width='100%' mb={3}>
          <UniButton
            disablePadding
            fullWidth={false}
            variant='text'
            onClick={() => {
              history.push(BrowserRoutes.Default.Auth.Login);
            }}
          >
            {t('buttons.rememberPassword')}
          </UniButton>
        </Box>
        {!isMobile && (
          <UniButton onClick={handleSubmit(onSubmit)} fullWidth={false} loading={isLoading} variant='main'>
            {t('buttons.resetPassword')}
          </UniButton>
        )}
      </StyledChildrenWrapperTop>

      {isMobile && (
        <UniButton onClick={handleSubmit(onSubmit)} fullWidth={false} loading={isLoading} variant='main'>
          {t('buttons.resetPassword')}
        </UniButton>
      )}
    </>
  );
};
export default ResetPassword;
