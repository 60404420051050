import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../constants';

export const ProfileDataCardWrapper = styled.div`
  background-color: ${Colors.White._100};
  box-shadow: ${Shadows.shadow1};
  padding: ${({ theme }) => theme.spacing(3, 2)};
  border-radius: ${Shape.BorderRadius.md}px;
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledSeenMark = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;
