import { createMuiTheme, MuiThemeProvider, useTheme } from '@material-ui/core';
import { TComponentColors } from 'constants/componentColors';
import { AnimatePresence } from 'framer-motion';
import useAnalytics from 'hooks/useAnalytics';
import React, { useCallback, useMemo, useState } from 'react';
import { Colors } from '../../../constants';
import UniBounceLoader from '../uniBounceLoader/UniBounceLoader';
import { Variant as TypographyVariants } from '../uniTypography/types';
import UniTypography from '../uniTypography/UniTypography';
import { Content, Loader, StyledButton, StyledCounter, StyledToolTip } from './UniButton.style';

export type ButtonColor = 'primary' | 'secondary' | 'danger' | 'neutral' | 'primaryFade' | 'fadeGray' | 'white';
type Variant = 'main' | 'secondary' | 'text';
export type Size = 'xsmall' | 'small' | 'medium' | 'large';

interface UniButtonProps {
  hidden: boolean;
  color: ButtonColor;
  loading: boolean;
  variant: Variant;
  disabled: boolean;
  fullWidth: boolean;
  filter: React.ReactNode;
  endIcon: React.ReactNode;
  startIcon: React.ReactNode;
  toolTip?: string;
  size: Size;
  ref?: React.MutableRefObject<any>;
  tooltipColor?: any;
  keepTooltip?: boolean;
  disablePadding?: boolean;
  borderRadius?: [number, number, number, number];
  children?: string;
  thin?: boolean;
}

const getLoaderSize = (size: Size) => (size === 'large' ? 21 : size === 'medium' ? 18 : 15);
const getTypographyProps = (size: Size): TypographyVariants => {
  switch (size) {
    case 'xsmall':
      return 'footnote';
    case 'small':
      return 'subheader';
    case 'medium':
      return 'body2';
    case 'large':
      return 'body1';
  }
};
const getTypographyColor = (color: ButtonColor, variant: Variant): TComponentColors => {
  switch (true) {
    case color === 'fadeGray':
      return 'typographyGray100';
    case (color === 'primary' && variant !== 'main') || color === 'primaryFade':
      return 'primary100';
    case color === 'neutral' && variant !== 'main':
      return 'typographyGray50';
    case color === 'danger' && variant !== 'main':
      return 'stateAlert100';
    case color === 'secondary' && variant !== 'main':
      return 'secondary100';
    case color === 'white' && variant === 'main':
      return 'typographyGray100';
    default:
      return 'white100';
  }
};
const getLoaderColor = (color: ButtonColor, variant: Variant) => {
  if (color === 'white') {
    return Colors.Primary._100;
  }
  if (color === 'danger') {
    return variant === 'main' ? Colors.White._100 : Colors.State.Alert_100;
  }
  if (color === 'neutral') {
    return variant === 'main' ? Colors.White._100 : Colors.Typography.Gray_100;
  }
  if (color === 'primaryFade') {
    return Colors.Primary._100;
  }
  return variant === 'main' ? Colors.White._100 : Colors.Primary._100;
};

const variantMap = {
  main: 'contained',
  secondary: 'outlined',
  text: 'text',
} as const;

const UniButton: React.FC<Partial<UniButtonProps> & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  filter,
  loading = false,
  color = 'primary',
  variant = 'main',
  disabled = false,
  fullWidth = false,
  size = 'medium',
  toolTip,
  tooltipColor,
  keepTooltip = false,
  ref,
  endIcon,
  startIcon,
  hidden = false,
  disablePadding,
  thin = false,
  borderRadius,
  onClick,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { track } = useAnalytics();
  const theme = useTheme();

  const makeTheme = useCallback(
    (color: string) =>
      createMuiTheme({
        ...theme,
        palette: {
          primary: { main: color, contrastText: Colors.Primary._100 },
        },
      }),
    [theme],
  );

  const mappedTheme = useMemo(() => {
    if (color === 'neutral') return makeTheme(Colors.Typography.Gray_100);
    if (color === 'fadeGray') return makeTheme(Colors.Typography.Gray_10);
    if (color === 'danger') return makeTheme(Colors.State.Alert_100);
    if (color === 'secondary') return makeTheme(Colors.Secondary._100);
    if (color === 'primary') return makeTheme(Colors.Primary._100);
    if (color === 'primaryFade') return makeTheme(Colors.Primary._10);
    if (color === 'white') return makeTheme(Colors.White._100);
    return theme;
  }, [color, makeTheme, theme]);
  return (
    <MuiThemeProvider theme={mappedTheme}>
      <StyledButton
        existsChild={!!children}
        disablePadding={disablePadding}
        hidden={hidden}
        loading={loading ? 1 : 0}
        onMouseEnter={() => toolTip && setShowTooltip(true)}
        onMouseLeave={() => toolTip && setShowTooltip(false)}
        innerRef={ref}
        size={size}
        start={!!startIcon ? 1 : 0}
        end={!!endIcon ? 1 : 0}
        color={disabled ? 'default' : 'primary'}
        variant={variantMap[variant]}
        fullWidth={fullWidth}
        borderRadius={borderRadius}
        onClick={e => {
          if (!disabled && onClick) {
            e.stopPropagation();
            if (children) track('Interaction', 'Button Click', children);
            onClick(e);
          }
        }}
        {...props}
      >
        {toolTip && (
          <AnimatePresence>
            {showTooltip && (
              <StyledToolTip initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} backgroundColor={tooltipColor}>
                <UniTypography color='white100'>{toolTip}</UniTypography>
              </StyledToolTip>
            )}
          </AnimatePresence>
        )}
        <React.Fragment>
          {startIcon && startIcon}
          {!!children && (
            <Content loading={loading} size={size} end={!!endIcon ? 1 : 0} start={!!startIcon ? 1 : 0}>
              <UniTypography
                weight={thin ? 'regular' : 'medium'}
                color={disabled ? 'typographyGray100' : getTypographyColor(color, variant)}
                variant={getTypographyProps(size)}
                noSpaceWrap
              >
                {children}
              </UniTypography>
            </Content>
          )}
          {endIcon && !filter && endIcon}
          {!!filter && (
            <StyledCounter size={size} variant={variant} color={color}>
              {' '}
              {filter}{' '}
            </StyledCounter>
          )}
        </React.Fragment>

        {loading && (
          <Loader>
            <UniBounceLoader size={getLoaderSize(size)} color={getLoaderColor(color, variant)} />
          </Loader>
        )}
      </StyledButton>
    </MuiThemeProvider>
  );
};

export default UniButton;
