import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

type Params = {
  agencyId: string;
};

export default function useAgentNames(params?: Params, config?: QueryConfig<Components.Schemas.FilterDto[]>) {
  const { profile } = useAuth();
  const { client } = useClient();

  const reqParams = profile.agentProfile?.agency.id || params?.agencyId;

  return useQuery(
    [CacheKeys.Agency.Agent, reqParams],
    async (_key: string, params: Params) => {
      const { data } = await client!.AgencyApi_getAgentNames(params);
      return data;
    },
    { ...config, onError(error: AxiosError) {} },
  );
}
