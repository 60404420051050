import { useStudentSuggestedPrograms } from 'api';
import { InfiniteWindowedGrid, UniProgramCard } from 'components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import { parseLevelOfStudy } from 'utils/programUtils';
interface Props {}

const SuggestedPrograms: React.FC<Props> = ({ children, ...rest }) => {
  const { profile } = useAuth();
  const { t } = useTranslation('programsList');
  const {
    data: suggestedPrograms,
    status: suggestedProgramsStatus,
    fetchMore: fetchMoreSuggestedPrograms,
    isFetching,
  } = useStudentSuggestedPrograms({ studentId: profile?.id!, agencyId: profile.studentProfile?.agent?.agentProfile?.agency?.id! });

  const suggestedProgramsLoading = suggestedProgramsStatus === 'loading';
  const suggestedProgramsFlat = useMemo(() => {
    return suggestedPrograms?.map(progs => progs.results).flat() || [];
  }, [suggestedPrograms, suggestedProgramsStatus]);
  const suggestedProgramsTotal = useMemo(() => (suggestedPrograms ? suggestedPrograms[0].total : 0), [suggestedPrograms]);
  const suggestedProgramsLeft = useMemo(
    () => suggestedProgramsTotal - suggestedProgramsFlat.length,
    [suggestedProgramsFlat, suggestedProgramsTotal],
  );

  return (
    <InfiniteWindowedGrid
      fetchMore={fetchMoreSuggestedPrograms}
      emptyStateType='suggested'
      isFetching={isFetching}
      isLoading={suggestedProgramsLoading}
      hasMore={suggestedProgramsLeft > 0}
      remainingResults={suggestedProgramsLeft}
      results={suggestedProgramsFlat?.map((program: any, index: any) => (
        <UniProgramCard
          showUniName
          key={`${index}-program`}
          fit
          showStatus
          program={program}
          metricsArr={[
            {
              label: t('headers.level_of_study'),
              value: parseLevelOfStudy(program.levelOfStudy),
            },
            {
              label: t('program.tuition_fee'),
              value: program?.tuitionFee?.currency
                ? `${program.tuitionFee?.currency?.amount} ${program.tuitionFee?.currency?.currencyIso} / ${program.tuitionFee?.lecturePeriod}`
                : 'Unspecified',
            },
            {
              label: t('program.application_fee'),
              value: 'Unspecified',
            },
          ]}
        />
      ))}
    />
  );
};

export default SuggestedPrograms;
