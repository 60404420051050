import { Box } from '@material-ui/core';
import { useBreakpoints } from 'hooks';
import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import UniTypography from '../../uniTypography/UniTypography';
import { StyledUniCarouselSlideWrapper } from '../UniCarousel.style';

interface Props {
  title?: string;
  content?: string;
  icon?: any;
}

export default function UniCarouselSlide({ title, content, icon }: Props) {
  const { isTablet } = useBreakpoints();

  return (
    <StyledUniCarouselSlideWrapper>
      <Box display='flex' justifyContent='center' maxHeight='100%'>
        {icon}
      </Box>
      <Box mt={3} alignSelf='flex-start'>
        <UniTypography variant={isTablet ? 'header2' : 'header1'} color='white100' weight='medium'>
          {title}
        </UniTypography>
        <UniTypography color='white100'>{content}</UniTypography>
      </Box>
    </StyledUniCarouselSlideWrapper>
  );
}
