import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components, Paths } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

interface Payload extends Omit<Paths.EventApiAddEvent.RequestBody, 'images' | 'speakers' | 'agendas'> {
  images: File[];
  speakers: Omit<Components.Schemas.EventSpeakerDto, 'imageFile'> & { imageFile: File }[];
  agendas: (Components.Schemas.EventAgendaDto & { date: string; startTime: string; endTime: string })[];
}

interface Params extends Payload {}

const usePostEvent = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: Params) => {
      const requestBody: any = { ...payload };
      console.log(payload);
      if (payload?.images?.length) {
        const mediaPromises = payload?.images?.map(image => {
          let formData = new FormData();
          formData.append('documentType', 'EVENT_IMAGE');
          formData.append('file', image);
          return client!.FileApi_uploadEventImage(undefined, formData);
        });
        const images = await Promise.all(mediaPromises);
        requestBody.images = images.map(item => ({ fileId: item.data.id }));
      }

      if (payload?.speakers?.length) {
        const speakersPromises = payload.speakers.map(({ imageFile }: any) => {
          let formData = new FormData();
          formData.append('documentType', 'EVENT_SPEAKER_IMAGE');
          formData.append('file', imageFile);
          return client!.FileApi_uploadSpeakerImage(undefined, formData);
        });
        const images = await Promise.all(speakersPromises);
        requestBody.speakers = images.map(item => {
          const speaker = payload.speakers.find(speaker => speaker.imageFile.name === item.data.originalName);
          return { ...speaker, imageFileId: item.data.id };
        });
      }

      if (payload?.agendas?.length) {
        const agendas = payload.agendas.map(agenda => {
          return {
            ...agenda,
            startDate: `${(payload as any).date} ${agenda.startTime}`,
            endDate: `${(payload as any).date} ${agenda.endTime}`,
          };
        });
        requestBody.agendas = agendas;
      }

      return client!.EventApi_addEvent(undefined, requestBody);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Events.All);
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default usePostEvent;
