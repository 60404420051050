import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { useExtendedProfile, useFavoriteProgram } from 'api';
import { ReactComponent as LinkIcon } from 'assets/svg/Icons/Link.svg';
import { Components } from 'client/UniClient';
import { UniIconWrapper, UniMetrics, UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useDrawers, useFooterbar, useSnackbar } from 'store';
import { parseApplicationFee, parseTuitionFee, parseVirtualAddress } from 'utils/programUtils';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location.svg';
import { ReactComponent as PaymentsIcon } from '../../../assets/svg/Icons/Payments.svg';
import { ReactComponent as SchoolsIcon } from '../../../assets/svg/Icons/Schools.svg';
import { ReactComponent as TimeIcon } from '../../../assets/svg/Icons/Time.svg';
import { attendanceTypeMap } from '../../../constants';
import { StyledUniCard, StyledUniPageTitle, StyledWrapper } from './ProgramGeneralInfo.style';

interface Props {
  program?: Components.Schemas.ProgramResponse;
}

const ProgramGeneralInfo: React.FC<Props> = ({ program, ...rest }) => {
  const { t } = useTranslation('programGeneralInfo');
  const { setFooter, clearFooter } = useFooterbar();
  const { openSnackbar } = useSnackbar();
  const { isMobile } = useBreakpoints();
  const { openDrawer } = useDrawers();
  const [favoriteProgram, { status: favoriteProgramStatus }] = useFavoriteProgram();
  const hasApplied = !!program?.application?.status;
  const verticalMetrics = useMediaQuery('(min-width:1088px)');
  const user = useAuth(state => state.profile);
  const { data: extendedProfile } = useExtendedProfile(user);
  const existsHistory = useMemo(() => (extendedProfile ? extendedProfile.educationHistory.length : 0), [extendedProfile]);
  const onStartApplication = () => {
    openDrawer('application-drawer', { size: 'small', program: program });
  };

  const onFavoriteProgram = () => program?.id && favoriteProgram({ isFavorite: !!program?.isFavorite, programId: program?.id });

  useEffect(() => {
    if (program) {
      setFooter({
        primaryButton: {
          label: hasApplied ? 'Applied' : 'Apply',
          onClick: existsHistory > 0 ? () => onStartApplication() : () => openSnackbar('apply-program', { mobileOver: 'footerProgram' }),
          disabled: hasApplied,
        },
        isFavorite: favoriteProgramStatus === 'loading' ? !program?.isFavorite : !!program?.isFavorite,
        onFavorite: onFavoriteProgram,
        program,
      });
    }
    return clearFooter;
  }, [program, favoriteProgramStatus]);

  if (!program) return <></>;

  return (
    <Grid xs={12} {...rest}>
      <StyledWrapper>
        <Box>
          {!!program?.description && (
            <StyledUniCard border size='S'>
              <StyledUniPageTitle size={isMobile ? 'XS' : 'S'} title={t('about')} />
              <UniTypography
                variant='subheader'
                color='typographyBlack100'
                weight='regular'
                dangerouslySetInnerHTML={program?.description}
              />
            </StyledUniCard>
          )}
          {!isEmpty(program?.admissionRequirements) && (
            <StyledUniCard border size='S'>
              <StyledUniPageTitle size={isMobile ? 'XS' : 'S'} title={t('admissionRequirements')} />
              {program?.admissionRequirements.map((requirement, index) => (
                <UniTypography
                  variant='subheader'
                  color='typographyBlack100'
                  weight='regular'
                  key={index}
                  dangerouslySetInnerHTML={requirement}
                />
              ))}
            </StyledUniCard>
          )}
          {!isEmpty(program?.requiredDocuments) && (
            <StyledUniCard border size='S'>
              <StyledUniPageTitle size={isMobile ? 'XS' : 'S'} title={t('requiredDocuments')} />
              {program?.requiredDocuments.map(item => (
                <Box mb={2}>
                  <UniTypography variant='subheader' color='typographyBlack100' weight='regular'>
                    -{item.name}
                  </UniTypography>
                </Box>
              ))}
            </StyledUniCard>
          )}
        </Box>
        <StyledUniCard border size='S'>
          <StyledUniPageTitle size={isMobile ? 'XS' : 'S'} title={t('details.title')} />
          <Box display='grid' gridTemplateColumns={verticalMetrics || isMobile ? '1fr' : '1fr 1fr'} gridRowGap={16} alignItems='start'>
            <UniMetrics
              size={isMobile ? 'small' : 'large'}
              variant={isMobile ? 'text' : 'iconed'}
              vertical
              values={[
                {
                  Icon: (
                    <UniIconWrapper color='secondary100'>
                      <SchoolsIcon />
                    </UniIconWrapper>
                  ),
                  label: t('details.attendance'),
                  value: program?.attendanceType ? attendanceTypeMap[program?.attendanceType] : t('unspecified'),
                },
                {
                  Icon: (
                    <UniIconWrapper color='secondary100'>
                      <PaymentsIcon />
                    </UniIconWrapper>
                  ),
                  label: t('details.applicationFee'),
                  value: parseApplicationFee(program?.applicationFee),
                },
                {
                  Icon: (
                    <UniIconWrapper color='secondary100'>
                      <PaymentsIcon />
                    </UniIconWrapper>
                  ),
                  label: t('details.tuitionFee'),
                  value: parseTuitionFee(program?.tuitionFee),
                },
              ]}
            />
            <UniMetrics
              size={isMobile ? 'small' : 'large'}
              variant={isMobile ? 'text' : 'iconed'}
              vertical
              values={[
                {
                  Icon: (
                    <UniIconWrapper color='secondary100'>
                      <LocationIcon />
                    </UniIconWrapper>
                  ),
                  label: t('details.contact'),
                  value: parseVirtualAddress(program.virtualAddress),
                },
                {
                  Icon: (
                    <UniIconWrapper color='secondary100'>
                      <TimeIcon />
                    </UniIconWrapper>
                  ),
                  label: t('details.deadline'),
                  value: program?.admissionEndDate || t('unspecified'),
                },
                {
                  label: t('details.website'),
                  value: !!program?.virtualAddress?.website ? 'Visit website' : t('unspecified'),
                  link: program?.virtualAddress?.website || t('unspecified'),
                  Icon: (
                    <UniIconWrapper color='secondary100'>
                      <LinkIcon />
                    </UniIconWrapper>
                  ),
                },
              ]}
            />
          </Box>
        </StyledUniCard>
      </StyledWrapper>
    </Grid>
  );
};

export default ProgramGeneralInfo;
