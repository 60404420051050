import React from 'react';
import styled from 'styled-components';
import { UniCard } from '../../../components';

export const ListContainer = styled(({ ...props }) => <UniCard {...props} />)`
  padding: ${({ theme }) => theme.spacing(3)}px;
`;

export const ListTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ListActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
  align-items: center;
`;
