import { Box } from '@material-ui/core';
import axios from 'axios';
import { EmptyState, UniButton, UniCard, UniHeader, UniPage, UniTextField, UniTypography } from 'components';
import WindowedWrapper from 'components/infiniteWindowedGrid/WindowedWrapper';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { useMemo, useState } from 'react';
import { StyledUniWrapper } from './WebSummitSearch.style';

interface Props {}

const WebSummitSearch: React.FC<Props> = ({ children, ...rest }) => {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [unis, setUnis] = useState<any[]>([]);

  useMemo(() => {
    setLoading(true);
    axios
      .get('http://49.12.205.248:3000', {
        params: { query: search ? `"${search}"` : undefined },
      })
      .then(data => {
        setUnis(data?.data);
        setLoading(false);
      })
      .catch(err => setLoading(false));
  }, [search]);

  return (
    <>
      <UniHeader>
        <Box py={3}>
          <UniTypography color='primary100' variant='header1' weight='bold'>
            Search algorithm for Programmes
          </UniTypography>
          <UniTypography color='secondary100' variant='header3'>
            Powered by Artificial Intelligence
          </UniTypography>
          <Box width='100%' display='flex' alignItems='center'>
            <UniTextField
              fullWidth
              placeholder='Search universities'
              onChange={e => setQuery(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  setSearch(query);
                }
              }}
            />
            <Box ml={2}>
              <UniButton size='large' fullWidth={false} onClick={() => setSearch(query)}>
                Search
              </UniButton>
            </Box>
          </Box>
        </Box>
      </UniHeader>
      <UniPage>
        {unis.length === 0 && !loading && <EmptyState type='programs' />}
        {loading && <UniListLoader />}
        <StyledUniWrapper>
          {!loading &&
            unis
              ?.sort((itema, itemb) => (itema?.id < itemb?.id ? -1 : 1))
              ?.map((item: any) => (
                <WindowedWrapper minHeight={125}>
                  <UniCard size='S' shadow='shadow2'>
                    <UniTypography variant='header2' color='primary100'>
                      {item?.program}
                    </UniTypography>
                    <UniTypography variant='body2' color='secondary70'>
                      {item?.university}
                    </UniTypography>
                  </UniCard>
                </WindowedWrapper>
              ))}
        </StyledUniWrapper>
      </UniPage>
    </>
  );
};

export default WebSummitSearch;
