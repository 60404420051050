import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { ComponentColors } from '../../../constants';
import { TComponentColors } from './../../../constants/componentColors';
import { Transform, Weight } from './types';

interface Props {
  weight: Weight;
  unicolor: TComponentColors;
  transform: Transform;
  noSpaceWrap?: boolean;
}

const getWeight = (weight: Weight) => {
  if (weight === 'regular') return 400;
  if (weight === 'medium') return 500;
  if (weight === 'semi-bold') return 600;
  if (weight === 'bold') return 700;
  return 500;
};

export const StyledTypography = styled(Typography)<Props>`
  font-weight: ${({ weight }) => getWeight(weight)};
  color: ${({ unicolor }) => ComponentColors[unicolor]};
  text-transform: ${({ transform }) => transform};
  white-space: ${({ noSpaceWrap }) => (noSpaceWrap ? 'nowrap' : 'pre-line')};
`;

export const StyledSpan = styled.span`
  font-family: Poppins, sans-serif;
  p {
    font-size: ${({ theme }) => `${theme.typography.body2}px`};
    line-height: auto;
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
  h4 {
    font-size: ${({ theme }) => `${theme.typography.h3}px`};
    line-height: auto;
    margin: 0;
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
  h5 {
    font-size: ${({ theme }) => `${theme.typography.h4}px`};
    line-height: auto;
    margin: 0;
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;
