import { List } from '@material-ui/core';
import React, { useState } from 'react';
import { matchPath } from 'react-router';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { NavigationLink } from '..';
import { UniIconWrapper } from '../..';
import { ReactComponent as UnfoldSVG } from '../../../assets/svg/Icons/Unfold.svg';
import { BrowserRoutes } from '../../../constants/browserRoutes';
import { UniLink } from '../../_common/uniLink/UniLink';
import UniLogo from '../../_common/uniLogo/uniLogo';
import { DrawerHeader, StyledDrawer, StyledListItem, StyledLogoHeader, StyledMenuHeader } from './FixedDrawerNavigation.style';

type FixedDrawerNavigationProps = {
  links: NavigationLink[];
  setStretchContent: Function;
};
const FixedDrawerNavigation: React.FC<FixedDrawerNavigationProps> = ({ links, setStretchContent }) => {
  const [isPinned, setIsPinned] = useState(false);
  const { push } = useHistory();
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile.role);
  const [open, setOpen] = useState(false);
  const onToggle = () => {
    setOpen(prevState => !prevState);
  };

  const handleClickHamburger = () => {
    if (isPinned) {
      setOpen(false);
      setIsPinned(false);
      setStretchContent(false);
    } else {
      setOpen(true);
      setIsPinned(true);
      setStretchContent(true);
    }
  };

  const handleMouseEnter = () => {
    if (!open && !isPinned) onToggle();
  };

  const handleMouseLeave = () => {
    if (open && !isPinned) onToggle();
  };
  return (
    <StyledDrawer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClose={onToggle} open={open} variant={'permanent'}>
      <DrawerHeader open={open}>
        <StyledLogoHeader open={open}>
          <UniLogo stretched={!open} size='M' />
        </StyledLogoHeader>
        <StyledMenuHeader open={open}>
          <UniIconWrapper size='main' onClick={handleClickHamburger} color='typographyGray100'>
            <UnfoldSVG title={isPinned ? 'Click to unpin' : 'Click to pin'} />
          </UniIconWrapper>
        </StyledMenuHeader>
      </DrawerHeader>

      <List component='nav'>
        {links.map(({ icon, name, path: linkpath, defaultPath }, index) => {
          var selected = !!matchPath(pathname, {
            path: defaultPath,
            exact: true,
            strict: true,
          });
          if (name)
            return (
              <>
                <StyledListItem component='a' key={name} selected={selected} button onClick={() => push(linkpath)} open={open}>
                  <UniLink IconLeft={icon} size='main' label={name} selected={selected} shouldGrow={open} />
                </StyledListItem>
              </>
            );
        })}
      </List>
    </StyledDrawer>
  );
};

export default React.memo(FixedDrawerNavigation);
