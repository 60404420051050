import { AxiosError } from 'axios';
import { QueryConfig, useQuery } from 'react-query';
import { getMetaRole } from 'utils';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

export default function useStudentNames(config?: QueryConfig<Error>) {
  const agencyId = useAuth(state => state.profile.agentProfile?.agency?.id);
  const agentId = useAuth(state => state.profile.id);
  const role = useAuth(state => state.profile?.role);
  const { isAgency, isAgent, isAdmin } = getMetaRole(role);
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Agency.CandidateNames],
    async (_key: string) => {
      if (isAgent) {
        const { data } = await client!.AgencyApi_getAgentStudentNames({
          agencyId: agencyId as string,
          agentId: agentId as string,
        });
        return data;
      }
      if (isAgency) {
        const { data } = await client!.AgencyApi_getAgencyStudentNames({
          agencyId: agencyId as string,
        });
        return data;
      }
      if (isAdmin) {
        const { data } = await client!.StudentApi_getStudentNames();
        return data;
      }
    },
    { enabled: role !== 'ADMIN', refetchOnWindowFocus: false, onError(error: AxiosError) {} },
  );
}
