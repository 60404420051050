import { UniButton, UniIconWrapper, UniTextField, UniTypography } from 'components';
import { UniAboutDropdown } from 'components/_common/uniAboutDropdown/UniAboutDropdown';
import { ReactComponent as PaperplaneIcon } from '../../../../assets/svg/Icons/Paperplane2.svg';
import { ReactComponent as EmailIcon } from '../../../../assets/svg/Icons/Mail.svg';
import { ReactComponent as LanguageIcon } from '../../../../assets/svg/Icons/Language.svg';
import { ReactComponent as MoneyIcon } from '../../../../assets/svg/Icons/Moneysack.svg';
import { ReactComponent as TrophyIcon } from '../../../../assets/svg/Icons/Trophy.svg';
import { ReactComponent as MugIcon } from '../../../../assets/svg/Icons/Mug.svg';
import { ReactComponent as PassportIcon } from '../../../../assets/svg/Icons/Passport.svg';
import { ReactComponent as GraduationHatIcon } from '../../../../assets/svg/Icons/GraduationHat.svg';
import { ReactComponent as SchoolsIcon } from '../../../../assets/svg/Icons/Schools.svg';
import { Box } from '@material-ui/core';
import { Colors } from 'constants/uiConfig';
import styled from 'styled-components';
import { countryDetails } from './CountryDetailsText';

const StyledLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  //grid-template-columns: fit-content(70%) 30%;
`;

const StyledVocabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 32rem;
  gap: 1rem;
  background-color: ${Colors.Secondary._100};
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
  }
`;

interface Props {
  country: string;
  countryName: string;
}

const About: React.FC<Props> = ({ country, countryName, children, ...rest }) => {
  const details = countryDetails[country] ?? countryDetails.fallback;
  const loadNotAvailableText = details === countryDetails.fallback;

  function displayIcon(iconName?: string) {
    switch (iconName) {
      case 'language':
        return (
          <UniIconWrapper color='white100' size='medium2'>
            <LanguageIcon />
          </UniIconWrapper>
        );
      case 'trophy':
        return (
          <UniIconWrapper color='white100' size='medium'>
            <TrophyIcon />
          </UniIconWrapper>
        );
      case 'money':
        return (
          <UniIconWrapper color='white100' size='medium'>
            <MoneyIcon />
          </UniIconWrapper>
        );
      case 'passport':
        return (
          <UniIconWrapper color='white100' size='medium'>
            <PassportIcon />
          </UniIconWrapper>
        );
      case 'school':
        return (
          <UniIconWrapper color='white100' size='medium'>
            <SchoolsIcon />
          </UniIconWrapper>
        );
      case 'graduation-hat':
        return (
          <UniIconWrapper color='white100' size='medium'>
            <GraduationHatIcon />
          </UniIconWrapper>
        );
      case 'mug':
      default:
        return (
          <UniIconWrapper color='white100' size='medium'>
            <MugIcon />
          </UniIconWrapper>
        );
    }
  }

  return (
    <>
      <StyledLayout>
        {loadNotAvailableText && (
          <>
            <Box>
              <UniTypography variant='body2' color='secondary100'>
                The booklet for this country is not ready yet.
              </UniTypography>
            </Box>
            <Box />
          </>
        )}
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '14px', marginTop: '4rem', marginRight: '2rem' }}>
          <UniTypography variant='body2' color='secondary100' weight='semi-bold'>
            About living in {countryName}
          </UniTypography>
          {details.paragraphs.map(paragraph => (
            <UniAboutDropdown label={paragraph.label} startIcon={displayIcon(paragraph.icon)}>
              {paragraph.content.split('\n').map(p => (
                <p>{p}</p>
              ))}
            </UniAboutDropdown>
          ))}
        </Box>
        <Box style={{ marginTop: '6.2rem' }}>
          <StyledVocabWrapper>
            <Box display='flex' justifyContent='space-between' width='12rem'>
              {displayIcon('mug')}
              <UniTypography variant='body2' color='white100' weight='bold'>
                Useful Vocabulary:
              </UniTypography>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {details.usefulVocabulary.map(item => (
                <UniTypography variant='body2' color='white100'>
                  <b>{item.word}:</b> {item.description}
                </UniTypography>
              ))}
            </Box>
          </StyledVocabWrapper>
        </Box>
      </StyledLayout>
    </>
  );
};

export default About;
