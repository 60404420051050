import { useMutation } from 'react-query';
import { Components } from 'client/UniClient';
import { useClient } from 'store';

type Payload = Components.Schemas.CreateTaskRequest;

const usePostTask = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      const { data } = await client!.TaskApi_createTask(null, payload);
      return data;
    },
  );
};

export default usePostTask;
