import { Box } from '@material-ui/core';
import { UniIconWrapper, UniTextField } from 'components';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useSnackbar } from 'store';
import useCopy from 'use-copy';
import { ReactComponent as CheckIcon } from '../../../../assets/svg/Icons/Check.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/svg/Icons/Copy.svg';
import { BrowserRoutes } from '../../../../constants/browserRoutes';
import { StyledJumpingChickIllustration } from '../PublishPublicProfile.style';

interface Props {}

export default function Step2({}: Props): ReactElement {
  const { t } = useTranslation('studentEnrollmentDrawer');
  const { openSnackbar } = useSnackbar();
  const { profile } = useAuth();
  const publicProfileLink = `${window.location.origin}${BrowserRoutes.Default.Public.StudentProfile(profile.id, 'info')}`;
  const [copied, copy, setCopied] = useCopy(publicProfileLink);

  const copyPublicProfileLink = () => {
    copy();
    openSnackbar('public-profile-link');
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <Box mb={3}>
        <UniTypography variant='header3' color='secondary100' weight='medium'>
          Yeeey! Your public profile is online!
        </UniTypography>
        <UniTypography variant='subheader' color='secondary100' weight='medium'>
          All information on your profile you decided to show will be visible by anyone with the link. Are you sure you want to publish?
        </UniTypography>
        <UniTextField
          fullWidth
          label='You can now share your link:'
          value={publicProfileLink}
          disabled
          endAdornment={
            <UniIconWrapper size='medium' color='primary100' onClick={copyPublicProfileLink}>
              {copied ? <CheckIcon /> : <CopyIcon />}
            </UniIconWrapper>
          }
        />
      </Box>
      <Box display='flex' justifyContent='center'>
        <StyledJumpingChickIllustration />
      </Box>
    </>
  );
}
