import UniPhoneInput from 'components/_inputs/uniPhoneInput/uniPhoneInput';
import UniTextField from 'components/_inputs/uniTextField/UniTextField';
import { isValidEmailRegex } from 'utils/validators';
import { useTranslation } from 'react-i18next';

export interface GuardianFormInputs {
  guardian: { email?: string; name?: string; phone?: string; phonePrefix?: string };
}

interface Props {
  control: any;
  register: any;
  setValue: any;
  errors: any;
  required?: boolean;
}

const GuardianForm: React.FC<Props> = ({ control, register, setValue, errors, required }) => {
  const { t } = useTranslation('forms');

  return (
    <>
      <UniPhoneInput
        name='guardian.phone'
        control={control}
        inputRef={register({
          required: `${t('form.errors.phone_1')}`,
          minLength: { value: 4, message: t('form.errors.phone_3') },
          maxLength: { value: 15, message: t('form.errors.phone_3') },
        })}
        error={!!errors?.guardian?.phone?.message || !!errors?.guardian?.phonePrefix?.message}
        helperText={errors?.guardian?.phone?.message || errors?.guardian?.phonePrefix?.message}
        setValue={setValue}
        label={t('form.guardian_phone')}
        fullWidth
      />
      <UniTextField
        name='guardian.email'
        inputRef={register({
          required: { value: true, message: t('form.errors.email') },
          pattern: {
            value: isValidEmailRegex,
            message: t('form.errors.email_2'),
          },
        })}
        error={!!errors?.guardian?.email?.message}
        helperText={errors?.guardian?.email?.message}
        fullWidth
        label={t('form.guardian_email')}
        placeholder='johndoe@email.com'
      />
      <UniTextField
        name='guardian.name'
        inputRef={register({ required: `${t('form.errors.guardian_name')}` })}
        helperText={errors?.guardian?.name?.message}
        fullWidth
        label={t('form.guardian_name')}
        placeholder='John Doe'
      />
    </>
  );
};

export default GuardianForm;
