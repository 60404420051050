import { QueryConfig, useQuery } from "react-query";
import { CacheKeys } from "../../constants";
import { useClient } from "../../store";

interface Params {
    universityId:string;
}

export default function useGetUniversityStudentNames(
    params:Params,
    config?: QueryConfig<Error>
) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Admin.Candidates],
    async (_key: string) => {
      const { data } = await client!.UniversityApi_getUniversityStudentNames({
          universityId: params.universityId
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
