import { Box, MenuItem, Select } from '@material-ui/core';
import { useUniversityNames } from 'api';
import { disciplines } from 'api/search/useProgramFilters';
import { UniAutocomplete, UniPageTitle, UniTextField, UniTypography } from 'components';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { UniDate } from 'components/_inputs/uniDate/UniDate';
import { levelOfStudyMap } from 'constants/programMaps';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { attendanceTypeMap, languageOptions, lengthOfStudyOptions } from '../../../../constants';

interface Props {
  register: any;
  control: any;
  errors: any;
}

const levelOfStudyOptions = Object.keys(levelOfStudyMap).map(key => ({ value: key, label: (levelOfStudyMap as any)[key] }));

const attendanceTypes = Object.keys(attendanceTypeMap).map(key => ({ value: key, label: (attendanceTypeMap as any)[key] }));

const Step1: React.FC<Props> = ({ register, control, errors }) => {
  const { t } = useTranslation('createProgram');
  const { data: universities, status } = useUniversityNames();

  const rules = { required: t('errors.required') };

  if (status === 'loading') {
    return <UniListLoader />;
  }

  return (
    <>
      <UniPageTitle title={t('texts.step1title')} />
      <Box mb={3} />
      <UniTextField
        fullWidth
        label={t('labels.programName')}
        placeholder={t('placeholders.programName')}
        name='name'
        helperText={errors?.name?.message}
        error={!!errors?.name?.message}
        inputRef={register(rules)}
      />
      <Controller
        name='universityId'
        control={control}
        rules={rules}
        render={({ value, onChange }) => (
          <UniAutocomplete
            fullWidth
            value={universities?.find(item => item?.value === value)}
            handleChange={(e: any, item: any) => onChange(item?.value)}
            getOptionLabel={item => item.label}
            label={t('labels.university')}
            placeholder={t('placeholders.university')}
            helperText={errors?.universityId?.message}
            error={!!errors?.universityId?.message}
            options={universities}
          />
        )}
      />
      <Controller
        name='levelOfStudy'
        control={control}
        render={({ value, onChange }) => (
          <UniAutocomplete
            fullWidth
            value={levelOfStudyOptions.find(item => item?.value === value)}
            handleChange={(e: any, item: any) => onChange(item?.value)}
            getOptionLabel={item => item.label}
            label={t('labels.levelOfStudy')}
            placeholder={t('placeholders.levelOfStudy')}
            options={levelOfStudyOptions}
          />
        )}
      />
      <Controller
        name='disciplines'
        control={control}
        render={({ value, onChange }) => (
          <UniAutocomplete
            fullWidth
            value={disciplines.filter(item => value?.includes(item.value))}
            multiple
            handleChange={(e: any, item: any) => onChange(item.map((disc: any) => disc.value))}
            getOptionLabel={item => item.text}
            label={t('labels.disciplines')}
            placeholder={t('placeholders.disciplines')}
            options={disciplines}
          />
        )}
      />
      <Controller
        name='attendanceType'
        control={control}
        render={({ value, onChange }) => (
          <UniAutocomplete
            fullWidth
            value={attendanceTypes.find(item => item?.value === value)}
            handleChange={(e: any, item: any) => onChange(item?.value)}
            getOptionLabel={item => item.label}
            label={t('labels.attendanceType')}
            placeholder={t('placeholders.attendanceType')}
            options={attendanceTypes}
          />
        )}
      />
      <Controller
        name='lengthOfStudy'
        control={control}
        render={({ value, onChange }) => (
          <UniTextField
            fullWidth
            label={t('labels.lengthOfStudy')}
            placeholder={t('placeholders.lengthOfStudy')}
            type='number'
            value={value?.value}
            onChange={e => onChange({ ...value, value: parseInt(e.target.value) })}
            endComponent={
              <Select
                disableUnderline
                value={value?.unit}
                renderValue={(selected: any) => {
                  if (!selected) {
                    return (
                      <UniTypography variant='body2' color='typographyGray100'>
                        {t('placeholders.unit')}
                      </UniTypography>
                    );
                  }

                  return (
                    <UniTypography variant='body2' color='typographyGray100'>
                      {lengthOfStudyOptions.find(item => item?.value === selected)?.label}
                    </UniTypography>
                  );
                }}
                displayEmpty
                onChange={e => onChange({ ...value, unit: e.target.value })}
              >
                {lengthOfStudyOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <UniTypography variant='body2' color='typographyGray100'>
                      {item.label}
                    </UniTypography>
                  </MenuItem>
                ))}
              </Select>
            }
          />
        )}
      />
      <Controller
        name='languageOfInstruction'
        control={control}
        render={({ value, onChange }) => (
          <UniAutocomplete
            fullWidth
            value={languageOptions.find(item => item.code === value)}
            handleChange={(e: any, item: any) => onChange(item.code)}
            getOptionLabel={item => item.name}
            label={t('labels.languageOfInstruction')}
            placeholder={t('placeholders.languageOfInstruction')}
            options={languageOptions}
          />
        )}
      />
      <Controller
        name='lectureStartDate'
        control={control}
        render={({ value, onChange }) => (
          <UniDate fullWidth value={value} onChange={(value: string) => onChange(value)} label={t('labels.lectureStartDate')} />
        )}
      />
      <Controller
        name='description'
        control={control}
        render={({ value, onChange }) => (
          <UniTextField
            fullWidth
            label={t('labels.description')}
            placeholder={t('placeholders.description')}
            value={value}
            multiline
            onChange={e => onChange(e.target.value)}
          />
        )}
      />
      <Box py={3} />
    </>
  );
};

export default Step1;
