import { Box, MenuItem, Select } from '@material-ui/core';
import useStripeCurrencies from 'api/payments/useStripeCurrencies';
import { UniAvatar, UniTextField } from 'components';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { currencyIso } from '../../../../constants';
import { StyledHeaderAvatar } from '../ApplicationFee.style';

interface Inputs {}

interface Props {
  programName?: string;
  universityName?: string;
  studentName?: string;
  control: Control<Inputs>;
  error?: string;
}

const Step1: React.FC<Props> = ({ programName, control, studentName, universityName, error }) => {
  const { data: currencies, isLoading } = useStripeCurrencies();

  if (isLoading || !currencies) {
    return <UniListLoader />;
  }

  return (
    <>
      <Box mb={2}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          {`You are about to set the application fee to ${studentName}’s application for:`}
        </UniTypography>
      </Box>
      <StyledHeaderAvatar>
        <UniAvatar size='S' label={universityName} />
        <Box>
          <UniTypography variant='subheader' color='typographyBlack100' weight='semi-bold'>
            {programName}
          </UniTypography>
          <UniTypography variant='subheader' color='secondary100' weight='medium'>
            {universityName}
          </UniTypography>
        </Box>
      </StyledHeaderAvatar>
      <Controller
        name='applicationFee'
        control={control}
        rules={{
          required: 'Application fee is required',
          validate: value => {
            if (!value.amount) return 'Application fee must not be empty!';
            else if (!value.currencyIso) return 'You must select fee currency!';
          },
        }}
        render={({ value, onChange }) => (
          <>
            <UniTextField
              fullWidth
              label='Set application fee'
              placeholder='Amount'
              type='number'
              value={value?.amount}
              error={!!error}
              helperText={error}
              onChange={e => onChange({ ...value, amount: parseInt(e.target.value) })}
              endComponent={
                <Select
                  disableUnderline
                  value={value?.currencyIso}
                  renderValue={(selected: any) => {
                    if (!selected) {
                      return (
                        <UniTypography variant='body2' color='typographyGray100'>
                          Unit
                        </UniTypography>
                      );
                    }

                    return (
                      <UniTypography variant='body2' color='typographyGray100'>
                        {currencies.find(item => item.value === selected)?.text}
                      </UniTypography>
                    );
                  }}
                  displayEmpty
                  onChange={e => onChange({ ...value, currencyIso: e.target.value })}
                >
                  {currencyIso.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <UniTypography variant='body2' color='typographyGray100'>
                        {item.text}
                      </UniTypography>
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </>
        )}
      />
    </>
  );
};

export default Step1;
