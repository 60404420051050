import { Box, Fade } from '@material-ui/core';
import { useBreakpoints } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as ApplicationIcon } from '../../../assets/svg/Icons/Applications.svg';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import { ReactComponent as EyeIcon } from '../../../assets/svg/Icons/Eye.svg';
import { ReactComponent as GarbageIcon } from '../../../assets/svg/Icons/Garbagev2.svg';
import { TComponentColors } from '../../../constants/componentColors';
import UniIconWrapper from '../../_common/uniIconWrapper/UniIconWrapper';
import { Variant } from '../../_common/uniTypography/types';
import UniTypography from '../../_common/uniTypography/UniTypography';
import { Error, StyledAlertIcon } from '../uniTextField/UniTextField.style';
import { StyledDropArea } from './UniDragNDrop.style';

interface Props {
  label?: string;
  fullWidth?: boolean;
  value?: File[];
  handleChange: Function;
  multiple?: boolean;
  color?: TComponentColors;
  showFileName?: boolean;
  variant?: Variant;
  children: React.ReactNode;
  style?: any;
  weight?: 'medium' | 'semi-bold';
  optional?: boolean;
  alert?: string;
  error?: boolean;
  accept?: any;
  onDelete?: (newFiles: File[]) => void;
}

const arraysEqual = (a: File[], b: File[]) => JSON.stringify(a) === JSON.stringify(b);

export default function UniDragNDrop({
  label,
  style,
  value,
  handleChange,
  variant = 'subheader',
  color,
  multiple = false,
  weight = 'medium',
  children,
  showFileName = true,
  optional,
  alert,
  error,
  accept,
  onDelete,
}: Props): ReactElement {
  const { isMobile } = useBreakpoints();
  const [previousFiles, setPreviousFiles] = useState<File[]>([]);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({ accept: accept });
  const [currentFiles, setCurrentFiles] = useState(value || acceptedFiles);
  const [hideBar, setHideBar] = useState(false);
  const rootProps = getRootProps();
  const inputProps = getInputProps();
  const singleDoc = acceptedFiles[0];

  useEffect(() => {
    if (!arraysEqual(previousFiles, acceptedFiles)) {
      if (multiple) {
        setCurrentFiles(acceptedFiles);
        handleChange(acceptedFiles);
      } else {
        setCurrentFiles([singleDoc]);
        handleChange([singleDoc]);
        showFileName && setHideBar(true);
      }
      setPreviousFiles(acceptedFiles);
    }
  }, [acceptedFiles, handleChange, previousFiles, currentFiles]);

  return (
    <Box style={style} width='100%'>
      <Box mb={0.5} display='flex' justifyContent='space-between'>
        <UniTypography variant={variant} color={color} weight={weight}>
          {label}
        </UniTypography>
        {optional && (
          <UniTypography variant='footnote' color='typographyGray30'>
            Optional
          </UniTypography>
        )}
      </Box>
      {!hideBar && (
        <StyledDropArea {...rootProps}>
          <input multiple={multiple} accept={accept} {...inputProps} />
          {isDragActive ? <UniTypography color='primary100'>Drop file here...</UniTypography> : children}
        </StyledDropArea>
      )}

      <Fade in={!!alert} unmountOnExit>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {alert}
          </UniTypography>
        </Error>
      </Fade>
      {showFileName && (
        <Box m={1}>
          {currentFiles.map((file, index) => (
            <Box display='flex' alignItems='center' mb={2} key={index} justifyContent='space-between'>
              <Box display='flex'>
                <UniIconWrapper color='typographyGray100' size='medium'>
                  <ApplicationIcon />
                </UniIconWrapper>
                <Box ml={1.5}>
                  <UniTypography color='typographyGray100' variant='subheader'>
                    {isMobile ? `${file?.name.substring(0, 15)}${file?.name.length > 14 ? '...' : ''}` : file?.name}
                  </UniTypography>
                </Box>
              </Box>
              <Box display='flex'>
                <UniIconWrapper
                  color='typographyGray100'
                  size='medium'
                  disableHover
                  clearMargins
                  onClick={() => {
                    if (file) {
                      const preview = URL.createObjectURL(file);
                      window.open(preview, '_blank');
                    }
                  }}
                >
                  <EyeIcon />
                </UniIconWrapper>
                <Box ml={1} />
                <UniIconWrapper
                  color='typographyGray100'
                  size='medium'
                  disableHover
                  clearMargins
                  onClick={() => {
                    const clearedFiles = currentFiles.filter(item => item !== file);
                    setCurrentFiles(multiple ? clearedFiles : []);
                    setHideBar(false);
                    onDelete && onDelete(clearedFiles);
                  }}
                >
                  <GarbageIcon />
                </UniIconWrapper>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
