import React from 'react';
import { UniButton, UniCardWrapper } from 'components';
import { Box } from '@material-ui/core';
import { useBreakpoints } from 'hooks';
import UniTypography from '../uniTypography/UniTypography';
import { StyledUniCardFooter } from './UniWorkshopCard.style';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  description?: string;
  date: string;
  type: string;
  actionTitle: string;
  link?: string;
  status?: string;
  onClick?: () => void;
}

export const UniWorkshopCard: React.FC<Props> = ({ title, description, date, type, link, status, actionTitle, onClick }) => {
  const { t } = useTranslation('studentAppTracker');
  const { isMobile } = useBreakpoints();

  return (
    <UniCardWrapper size='L' border hoverable onClick={onClick}>
      <Box
        pt={4}
        pl={3}
        pr={3}
        pb={10}
        display='grid'
        alignItems='start'
        width={isMobile ? 'calc(100vw - 102px)' : 'inherit'}
      >
        <UniTypography
          variant='body2'
          color='typographyBlack100'
          weight='semi-bold'
          style={{
            height: '48px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box',
          }}
        >
          {title}
        </UniTypography>
        {description && (
          <Box mt={1}>
            <UniTypography
              variant='subheader'
              color='secondary100'
              weight='medium'
              style={{
                height: '40px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
              }}
            >
              {description}
            </UniTypography>
          </Box>
        )}
        <Box display='grid' mb={2}>
          <UniTypography variant='footnote' color='typographyGray100' weight='regular'>
            {t('workshops.cards.date')}
          </UniTypography>
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            {date}
          </UniTypography>
        </Box>
        <Box display='grid' mb={2}>
          <UniTypography variant='footnote' color='typographyGray100' weight='regular'>
            {t('workshops.cards.type')}
          </UniTypography>
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            {type}
          </UniTypography>
        </Box>
        {link && (
          <Box display='grid' mb={2}>
            <UniTypography variant='footnote' color='typographyGray100' weight='regular'>
              {t('workshops.cards.link')}
            </UniTypography>
            <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
              {link}
            </UniTypography>
          </Box>
        )}
      </Box>
      <StyledUniCardFooter
        type='baseUnlabeledStatus'
        variants='XS'
        status={status && t(`workshops.cards.status.${status}`)}
        PrimaryButton={
          <UniButton variant='main' color='primaryFade' size='small' onClick={onClick}>
            {actionTitle}
          </UniButton>
        }
      />
    </UniCardWrapper>
  );
}

export default UniWorkshopCard;
