import { tidioPublicKey } from 'constants/envConfig';
import { useEffect, useState } from 'react';
import { useAuth } from 'store';

const useTidioChat = () => {
  const { profile } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const tidioChatApi = (window as any)?.tidioChatApi;
  const isLoggedIn = !!profile?.id;

  const load = () => {
    const tidioScript = document.createElement('script');
    tidioScript.src = `//code.tidio.co/${tidioPublicKey}.js`;
    document.body.appendChild(tidioScript);
  };

  const open = () => {
    const tidioChatApi = (window as any)?.tidioChatApi;
    if (!tidioChatApi) return;
    tidioChatApi.open();
  };

  const hide = () => {
    const tidioChatApi = (window as any)?.tidioChatApi;
    if (!tidioChatApi) return;
    tidioChatApi.hide();
  };
  const show = () => {
    const tidioChatApi = (window as any)?.tidioChatApi;
    if (!tidioChatApi) return;
    tidioChatApi.show();
  };

  useEffect(() => {
    if (tidioChatApi) {
      setLoaded(true);
      (document as any).tidioIdentify = {
        email: profile.email,
        name: `${profile.firstName} ${profile.lastName}`,
        phone: `${profile.phonePrefix} ${profile.phone}`,
      };
    } else {
      setLoaded(false);
    }
  }, [tidioChatApi]);

  useEffect(() => {
    if (isLoggedIn && tidioChatApi) {
      tidioChatApi.setVisitorData({
        email: profile.email,
        name: `${profile.firstName} ${profile.lastName}`,
        phone: `${profile.phonePrefix} ${profile.phone}`,
      });
    }
  }, [isLoggedIn, tidioChatApi]);

  return {
    load,
    open,
    loaded,
    hide,
    show,
  };
};

export default useTidioChat;
