import { Box } from '@material-ui/core';
import { useIntersectionObserver } from 'hooks';
import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UniBounceLoader } from '../../components';
import UniTypography from '../_common/uniTypography/UniTypography';
import { StyledUniversities } from './UniversitiesList.style';
export type CandidatesSort = 'username' | 'nationality' | 'levelOfStudy' | 'profileCompletion' | null;

interface Props {
  length: number;
  hasMore: boolean;
  isLoading?: boolean;
  fetchMore: () => void;
  children?: ReactElement[] | ReactElement;
}

const UniversitiesList = ({ length, hasMore, fetchMore, isLoading, children }: Props) => {
  const { t } = useTranslation('agencyAdminUniversities');
  const targetRef = useRef(null);

  useIntersectionObserver({
    target: targetRef,
    onIntersect: fetchMore,
    enabled: hasMore,
  });
  const showEndOfList = length > 4 && !hasMore && !isLoading;
  const showEmptyList = length === 0 && !isLoading;

  return (
    <StyledUniversities>
      {children}
      {showEmptyList && (
        <Box mx={4}>
          <UniTypography color='primary100' weight='medium'>
            {t('list.no_universities')}
          </UniTypography>
        </Box>
      )}
      {isLoading && <UniBounceLoader />}
      {showEndOfList && (
        <Box>
          <UniTypography color='typographyGray70'>You have reached the end of the list.</UniTypography>
        </Box>
      )}
      <div ref={targetRef} />
    </StyledUniversities>
  );
};

export default UniversitiesList;
