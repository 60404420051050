import React from 'react';
import useProducts from 'api/products/useProducts';
import UniAdminPrepCenterProductsList from 'components/adminPrepCenterProductsList/UniAdminPrepCenterProductsList';

interface Props {}

export const Products: React.FC<Props> = () => {
  const { data: products } = useProducts();

  return (
    <>
      {products && <UniAdminPrepCenterProductsList products={products} />}
    </>
  );
};

export default Products;
