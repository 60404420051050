import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { useClient } from '../../store';
import { CacheKeys } from '../../constants';
import { Components } from '../../client/UniClient';

type Params = {
  agencyId: string;
  agentId: string;
};

const useResendAgentInvite = () => {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: Params) => {
      return client!.AgencyApi_resendAgentInvite(payload);
    },
    {
      onSuccess: response => {
        // queryCache.invalidateQueries(CacheKeys.Agency.All);
      },
      onError: (error: AxiosError) => {},
    },
  );
};

export default useResendAgentInvite;
