import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRoutes } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import privacyDoc from 'assets/docs/Youni Privacy Policy.pdf';
import termsDoc from 'assets/docs/T&Cs Uniapply Platform.pdf';
import { ReactComponent as DiscordIcon } from 'assets/svg/Icons/DiscordIcon.svg';
import { ReactComponent as FacebookIcon } from 'assets/svg/Icons/FacebookIcon.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/Icons/InstagramIcon.svg';
import { ReactComponent as LinkedinIcon } from 'assets/svg/Icons/LinkedinIcon.svg';
import { ReactComponent as PaperplaneIcon } from 'assets/svg/Icons/Paperplane2.svg';
import { ReactComponent as EmailIcon } from 'assets/svg/Icons/Mail.svg';
import { ReactComponent as WhatsappIcon } from 'assets/svg/Icons/WhatsAppIcon.svg';
import { UniButton, UniLogo, UniTextField, UniIconWrapper, UniTypography } from 'components';
import { StyledBottomFooter, StyledFooterContainer, StyledUpperFooter, StyledDivider } from './HomepageFooter.style';
import { FooterLink } from './FooterLink';

interface Props {}

export default function NewHomepageFooter({ ...props }: Props): ReactElement {
  const { t } = useTranslation('homepageFooter');
  return (
    <StyledFooterContainer id='footer-section' {...props}>
      <StyledUpperFooter>
        <Box display='flex' mr={8} flexDirection='column'>
          <Link to={BrowserRoutes.Default.Homepage}>
            <UniLogo light text size='XL' />
          </Link>
          <Box mt={4} />
          <UniTypography variant='header2' color='white100'>
            {t('text.part_1')}
            <br />
            <b>{t('text.part_2')}</b>
            <br />
            {t('text.part_3')}
          </UniTypography>
          <Box mt={1} mb={1} ml={-2}>
            <UniIconWrapper
              size='medium2'
              color='white100'
              withBackground
              onClick={() => window.open('https://www.instagram.com/younichoice/', '_blank')}
            >
              <InstagramIcon />
            </UniIconWrapper>
            <UniIconWrapper
              size='medium2'
              color='white100'
              withBackground
              onClick={() => window.open('https://www.facebook.com/younichoice/', '_blank')}
            >
              <FacebookIcon />
            </UniIconWrapper>
            <UniIconWrapper
              size='medium2'
              color='white100'
              withBackground
              onClick={() => window.open('https://www.linkedin.com/company/younichoice/mycompany/', '_blank')}
            >
              <LinkedinIcon />
            </UniIconWrapper>
            <UniIconWrapper
              size='medium2'
              color='white100'
              withBackground
              onClick={() => window.open('https://discord.gg/vdpQ2rN7VJ', '_blank')}
            >
              <DiscordIcon />
            </UniIconWrapper>
            <UniIconWrapper
              size='medium2'
              color='white100'
              withBackground
              onClick={() => window.open('https://wa.me/40774051652', '_blank')}
            >
              <WhatsappIcon />
            </UniIconWrapper>
          </Box>
          <Box ml={-1}>
            <FooterLink link={termsDoc} linkLabel={t('links.terms')} />
            <FooterLink link={privacyDoc} linkLabel={t('links.privacy')} />
            <FooterLink link={BrowserRoutes.Default.Auth.Login} linkLabel={t('links.log_in')} />
          </Box>
        </Box>
        <Box>
          <UniTypography variant='header3' weight='bold' color='white100'>
            {t('newsletter.newsletter')}
          </UniTypography>
          <Box display='flex' alignItems='center' height='5rem'>
            <UniTextField
              startAdornment={
                <UniIconWrapper size='medium2' color='typographyGray50'>
                  <EmailIcon />
                </UniIconWrapper>
              }
              name='email'
              placeholder={t('newsletter.email')}
              backgroundColor='#fff'
              fullWidth
            />
            <UniButton
              startIcon={
                <UniIconWrapper size='main' color='white100'>
                  <PaperplaneIcon />
                </UniIconWrapper>
              }
              style={{ marginLeft: '0.5rem', height: '56px' }}
            >
              {t('newsletter.subscribe')}
            </UniButton>
          </Box>
          <Box display='flex'>
            <Box mr={10}>
              <UniTypography variant='header3' weight='bold' color='white100'>
                {t('links.programs')}
              </UniTypography>
              <StyledDivider />
              <Box ml={-1} mb={2}>
                <FooterLink link={BrowserRoutes.Default.Programs} linkLabel={t('links.search')} />
                <FooterLink link='/#continents-section' linkLabel={t('links.continents')} />
              </Box>
            </Box>
            <Box mr={10}>
              <UniTypography variant='header3' weight='bold' color='white100'>
                {t('links.services')}
              </UniTypography>
              <StyledDivider />
              <Box ml={-1}>
                <FooterLink link={BrowserRoutes.Default.About} linkLabel={t('links.about_us')} />
                <FooterLink link={BrowserRoutes.Default.Programs} linkLabel={t('links.platform')} />
                <FooterLink link='/#tutors' linkLabel={t('links.tutoring')} />
                {/*<FooterLink link={BrowserRoutes.Default.Homepage} linkLabel={t('links.essay_review')} />*/}
              </Box>
              {/*<UniTypography variant='header3' weight='bold' color='white100'>*/}
              {/*  {t('links.about_us')}*/}
              {/*</UniTypography>*/}
              {/*<StyledDivider />*/}
              {/*<Box ml={-1}>*/}
              {/*  <FooterLink link={BrowserRoutes.Default.Homepage} linkLabel={t('links.who_we_are')} />*/}
              {/*  <FooterLink link={BrowserRoutes.Default.Homepage} linkLabel={t('links.youni_news')} />*/}
              {/*  <FooterLink link={BrowserRoutes.Default.Homepage} linkLabel={t('links.experts')} />*/}
              {/*  <FooterLink link={BrowserRoutes.Default.Homepage} linkLabel={t('links.blog')} />*/}
              {/*  <FooterLink link={BrowserRoutes.Default.Homepage} linkLabel={t('links.contact')} />*/}
              {/*  <FooterLink link={BrowserRoutes.Default.Homepage} linkLabel={t('links.investors')} />*/}
              {/*</Box>*/}
            </Box>
            {/*<Box>*/}
            {/*  <UniTypography variant='header3' weight='bold' color='white100'>*/}
            {/*    {t('links.resources')}*/}
            {/*  </UniTypography>*/}
            {/*  <StyledDivider />*/}
            {/*  <Box ml={-1}>*/}
            {/*    <FooterLink link='https://docs.google.com/forms/d/e/1FAIpQLSc7KUmePPgvH6SNYPlTpXQlmgg3NqgSSFJaezw3mmTwFw3hvA/viewform' linkLabel={t('links.ambassador')} />*/}
            {/*    <FooterLink link={BrowserRoutes.Default.Homepage} linkLabel={t('links.podcast')} />*/}
            {/*    <FooterLink link={BrowserRoutes.Default.Homepage} linkLabel={t('links.youni_youth')} />*/}
            {/*    <FooterLink link={BrowserRoutes.Default.Events.All} linkLabel={t('links.events')} />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
          </Box>
        </Box>
      </StyledUpperFooter>
      <StyledBottomFooter>
        <UniTypography weight='regular' variant='subheader' color='white100'>
          Youni &#169; 2024 Made with Love for Education
        </UniTypography>
      </StyledBottomFooter>
    </StyledFooterContainer>
  );
}
