import React from 'react';
import { useTranslation } from 'react-i18next';
import { Components } from 'client/UniClient';
import { StickyListFilterBar } from '../index';
import ListHeader from './ListHeader';
import { StyledProducts } from './ProductsList.style';
import UniAdminPrepCenterProductsListItem from './UniAdminPrepCenterProductsListItem';

type ProductDto = Components.Schemas.ProductDto;

interface Props {
  products: ProductDto[];
}

export const UniAdminPrepCenterProductsList: React.FC<Props> = ({ products }) => {
  const { t } = useTranslation('prepCenter');

  return (
    <>
      <StickyListFilterBar
        listTitle={t('tabs.products')}
      />
      <ListHeader />
      <StyledProducts>
        {products.map(product => (
          <UniAdminPrepCenterProductsListItem product={product} />
        ))}
      </StyledProducts>
    </>
  );
};

export default UniAdminPrepCenterProductsList;
