import { ReactComponent as ApplicationsIcon } from 'assets/svg/Icons/Nav/Applications.svg';
import { ReactComponent as ReplyIcon } from 'assets/svg/Icons/Reply.svg';
import { Components } from 'client/UniClient';
import { UniButton, UniIconWrapper, UniMetrics, UniStudentAvatar, UniTypography } from 'components';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationStatus } from 'types';
import { countries } from '../../constants';
import { AddressWrapper, ApplicationCardFooter, ApplicationCardWrapper, ContentWrapper } from './ApplicationCard.style';

interface Props {
  variant: 'programmePage' | 'universityPage' | 'applicationsPage' | 'candidateApplicationsPage' | 'candidatesPage';
  studentName?: string;
  studentAddress?: Partial<Components.Schemas.AddressDto>;
  avatarSrc?: string;
  programName?: string;
  universityName?: string;
  programAddress?: Partial<Components.Schemas.AddressDto>;
  metrics?: {
    numberOfApplications: number;
    unansweredApplications?: number;
  };
  status?: ApplicationStatus;
  path: string;
}

const ApplicationCard: React.FC<Props> = ({
  children,
  variant,
  studentName,
  studentAddress,
  avatarSrc,
  programName,
  universityName,
  programAddress,
  metrics,
  status,
  path,
  ...rest
}) => {
  const history = useHistory();
  const { t } = useTranslation('applicationCard');

  const onOpenPage = () => history.push(path);

  const withAvatar = variant !== 'candidateApplicationsPage';
  const isApplying = variant === 'universityPage' || variant === 'applicationsPage';
  const withProgram = variant === 'universityPage' || variant === 'applicationsPage' || variant === 'candidateApplicationsPage';
  const withUniversity = variant === 'applicationsPage' || variant === 'candidateApplicationsPage';
  const withAddress = withProgram;

  if (variant === 'candidatesPage') {
    return (
      <ApplicationCardWrapper size='L' shadow='shadow1' variant={variant} hoverable>
        <Link to={path}>
          <ContentWrapper>
            <UniStudentAvatar studentName={studentName || ''} studentInfo={studentAddress} avatarSrc={avatarSrc} />
            <UniMetrics
              variant='iconed'
              size='large'
              vertical
              values={[
                {
                  Icon: <ApplicationsIcon />,
                  label: t('metrics.numberOfApplications'),
                  value: `${metrics?.numberOfApplications || 0}`,
                },
                {
                  Icon: <ReplyIcon />,
                  label: t('metrics.unansweredApplications'),
                  value: `${metrics?.unansweredApplications || 'Not measured'}`,
                },
              ]}
            />
          </ContentWrapper>
        </Link>
        <ApplicationCardFooter
          PrimaryButton={
            <UniButton variant='main' color='primaryFade' size='small' onClick={onOpenPage}>
              {t('button.viewProfile')}
            </UniButton>
          }
          variants='XS'
          type='basic'
        />
      </ApplicationCardWrapper>
    );
  } else
    return (
      <ApplicationCardWrapper size='L' shadow='shadow1' variant={variant} hoverable>
        <Link to={path} style={{ textDecoration: 'none' }}>
          <ContentWrapper>
            {withAvatar && <UniStudentAvatar studentName={studentName || ''} studentInfo={studentAddress} avatarSrc={avatarSrc} />}
            {isApplying && (
              <UniTypography variant='subheader' weight='medium' color='typographyGray100'>
                {t('text.isApplyingFor')}
              </UniTypography>
            )}
            {(withProgram || withUniversity) && (
              <div>
                {withProgram && (
                  <UniTypography variant='body2' weight='semi-bold'>
                    {programName || '-'}
                  </UniTypography>
                )}
                {withUniversity && (
                  <UniTypography variant='subheader' weight='medium' color='secondary100'>
                    {universityName || '-'}
                  </UniTypography>
                )}
              </div>
            )}
            {withAddress && (
              <AddressWrapper>
                <UniIconWrapper>
                  <ReactCountryFlag
                    countryCode={programAddress?.country}
                    svg
                    style={{
                      width: '22px',
                      height: '22px',
                    }}
                  />
                </UniIconWrapper>
                <UniTypography variant='subheader' weight='medium' color='typographyBlack100'>
                  {programAddress?.city + ', ' + countries.find(country => country.code === programAddress?.country)?.name}
                </UniTypography>
              </AddressWrapper>
            )}
          </ContentWrapper>
        </Link>
        <ApplicationCardFooter
          PrimaryButton={
            <UniButton variant='main' color='primaryFade' size='small' onClick={onOpenPage}>
              {t('button.viewApplication')}
            </UniButton>
          }
          variants='XS'
          type='baseStatus'
          status={status}
        />
      </ApplicationCardWrapper>
    );
};

export default ApplicationCard;
