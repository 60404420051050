import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useCompleteAgencyOnboarding from '../../../../api/auth/useCompleteAgencyOnboarding';
import { UniButton, UniCheckbox, UniTextField, UniTypography } from '../../../../components';
import { Colors } from '../../../../constants';
import { useErrorHandler } from '../../../../hooks';
import { CurrencyIso } from '../../../../types';
import { StyledFormRow } from '../Onboard.style';
import { AgencyOnboardForm } from './AgencyOnboard';

interface Props {
  show: boolean;
  data: { firstName: string; lastName: string };
  nextStep: Function;
  onCancel: Function;
  previousStep: Function;
  setFormData: Function;
  onboardForm: AgencyOnboardForm;
}

export interface OnboardFormStep2 {
  firstName: string;
  lastName: string;
  phone: string;
  username: string;
  wappId: string;
  password: string;
  feeAmount: number;
  feeCurrency: CurrencyIso;
  feeType: 'UPFRONT' | 'FIFTY_FIFTY' | 'AFTER';
  terms: boolean;
}

export default function AgencyOnboardStep2({ show, nextStep, onboardForm, previousStep, setFormData, data }: Props): ReactElement | null {
  const { register, handleSubmit, errors, control } = useForm<OnboardFormStep2>({
    defaultValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      feeType: 'AFTER',
      feeCurrency: 'EUR',
    },
  });

  const [completeOnboarding, { status: completeOnboardingStatus, error: completeOnboardingError }] = useCompleteAgencyOnboarding();

  const onboardingError = useErrorHandler(completeOnboardingError!);

  const onSubmit = (formData: OnboardFormStep2) => {
    setFormData({ ...formData, username: onboardForm.email });

    completeOnboarding(
      {
        ...onboardForm,
        ...formData,
      },
      {
        onSuccess() {
          nextStep();
        },
      },
    );
  };

  const firstNameError = errors?.firstName?.message ?? '';
  const lastNameError = errors?.lastName?.message ?? '';
  const usernameError = errors?.username?.message ?? '';
  const phoneError = errors?.phone?.message ?? '';
  const wappIdError = errors?.wappId?.message ?? '';
  const passwordError = errors?.password?.message ?? '';
  const termsError = errors?.terms?.message ?? '';

  return (
    <>
      <Box display={show ? 'inline' : 'none'} alignSelf='flex-start' width='100%' maxWidth='800px'>
        <Box mb={5}>
          <UniTypography variant='header1' weight='medium'>
            Personal information
          </UniTypography>
        </Box>
        {onboardingError.message && (
          <Box mb={2} mt={-2}>
            <UniTypography color='stateAlert100'>{onboardingError.message}</UniTypography>
          </Box>
        )}
        <StyledFormRow style={{ marginTop: '5px' }}>
          <UniTextField
            fullWidth
            helperText={firstNameError}
            error={!!firstNameError}
            defaultValue={data.firstName}
            name='firstName'
            label='Legal first name'
            placeholder='First name!'
            inputRef={register({ required: 'First name must not be empty!' })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
          <UniTextField
            fullWidth
            helperText={lastNameError}
            error={!!lastNameError}
            name='lastName'
            defaultValue={data.lastName}
            label='Legal last name'
            placeholder='Last name!'
            inputRef={register({ required: 'Last name must not be empty!' })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
        </StyledFormRow>
        <StyledFormRow style={{ marginTop: '5px' }}>
          <UniTextField
            fullWidth
            helperText={phoneError}
            error={!!phoneError}
            name='phone'
            label='Personal phone'
            placeholder='Enter phone number'
            type='tel'
            inputRef={register({ required: 'Phone must not be empty!' })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
          <Box />
        </StyledFormRow>
        <StyledFormRow style={{ marginTop: '5px' }}>
          <UniTextField
            helperText={usernameError}
            error={!!usernameError}
            name='username'
            fullWidth
            label='Personal email'
            placeholder='Enter company email'
            type='username'
            inputRef={register({
              required: 'Personal email must not be empty!',
            })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
          <UniTextField
            helperText={wappIdError}
            error={!!wappIdError}
            name='wappId'
            fullWidth
            label='Whatsapp id'
            placeholder='Enter Whatsapp id'
            inputRef={register({ required: 'Whatsapp id must not be empty!' })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
        </StyledFormRow>
        <StyledFormRow style={{ marginTop: '5px' }}>
          <UniTextField
            helperText={passwordError}
            error={!!passwordError}
            name='password'
            fullWidth
            type='password'
            placeholder='Enter password'
            label='Account password'
            inputRef={register({
              required: 'Password must not be empty!',
              minLength: {
                value: 8,
                message: 'Password must contain at least 8 characters!',
              },
            })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
            endAdornment
          />
          <Box />
        </StyledFormRow>
        <Box display='flex' alignItems='center'>
          <Controller
            control={control}
            name='terms'
            rules={{
              required: 'You have to agree with our terms and conditions!',
            }}
            render={({ onChange, onBlur, value }) => (
              <UniCheckbox onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={value} />
            )}
          />
          <UniTypography>Accept Terms & Conditions</UniTypography>
        </Box>
        {termsError && <UniTypography color='stateAlert100'>{termsError}</UniTypography>}
      </Box>
      <Box display={show ? 'flex' : 'none'} justifyContent='space-between' mt={4} width='100%'>
        <UniButton
          variant='text'
          fullWidth={false}
          onClick={() => {
            previousStep();
          }}
        >
          Previous step
        </UniButton>
        <UniButton
          color='primary'
          loading={completeOnboardingStatus === 'loading'}
          style={{ maxWidth: '200px' }}
          onClick={handleSubmit(onSubmit)}
        >
          Create Account
        </UniButton>
      </Box>
    </>
  );
}
