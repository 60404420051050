import { Box, Fade } from '@material-ui/core';
import { UniTypography } from 'components';
import { useBreakpoints } from 'hooks';
import styled from 'styled-components';
import { OptionType } from 'types';
import { ReactComponent as ErrorSvg } from '../../../assets/svg/Icons/Error.svg';
import { Error, StyledAlertIcon } from '../uniTextField/UniTextField.style';
import { UniOptions } from './UniOptions';

export const StyledContainer = styled.div`
  display: flex;
`;

interface Props {
  options: Partial<OptionType>[];
  label: string;
  errorMsg?: string;
  onChange: Function;
  value: string;
}

export const UniOptionsField = ({ options, label, errorMsg, onChange, value }: Props) => {
  const { isMobile } = useBreakpoints();
  return (
    <Box display='grid' gridGap='4px' height={isMobile ? 'fit-content' : '103px'} gridTemplateRows='21px 1fr 18px'>
      <UniTypography variant='subheader' weight='medium'>
        {label}
      </UniTypography>
      <Box display={isMobile ? 'grid' : 'flex'}>
        {options.map(item => (
          <UniOptions elementChecked={item.value === value} setElementChecked={onChange} label={item.label!} value={item.value} />
        ))}
      </Box>
      <Fade in={!!errorMsg} unmountOnExit>
        <Error>
          <StyledAlertIcon>
            <ErrorSvg />
          </StyledAlertIcon>
          <UniTypography variant='footnote' component='span' color='stateAlert100'>
            {errorMsg}
          </UniTypography>
        </Error>
      </Fade>
    </Box>
  );
};
