import { Box, Fade } from '@material-ui/core';
import { useUniversitiesFilters } from 'api';
import UniBounceLoader from 'components/_common/uniBounceLoader/UniBounceLoader';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import FiltersDrawerActions from 'components/_drawers/filtersDrawer/FiltersDrawerActions';
import { UniversitiesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/UniversitiesFilters';
import { DefaultFormValues } from 'constants/defaultFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { OptionType } from 'types';
import { UniAutocomplete, UniCheckbox } from '../../../..';

interface Props {
  handleClose: Function;
}

export default function FilterUniversitiesForm({ handleClose }: Props): ReactElement {
  const { t } = useTranslation('filterProgramsPage');
  const { replaceQueriesToUrl, queries } = useQueryStringParser<UniversitiesFiltersQueries>();
  const { data: universitiesFilters, status: universitiesFiltersStatus } = useUniversitiesFilters();
  const { search } = useLocation();
  const [queriesChange, setQueriesChange] = useState(false);
  const isLoading = universitiesFiltersStatus === 'loading';

  const { handleSubmit, control, reset, watch } = useForm<UniversitiesFiltersQueries>({
    defaultValues: { ...DefaultFormValues, ...queries },
  });

  const onSubmit = (formData: UniversitiesFiltersQueries) => {
    handleClose();
    replaceQueriesToUrl(formData);
  };

  useEffect(() => {
    if (search === '' || !!queries.clear) {
      reset({ city: [], country: [], subject: [], languageOfStudy: [], levelOfStudy: [] });
      replaceQueriesToUrl({});
    } else {
      reset(queries);
    }
    setQueriesChange(prevQueriesChange => !prevQueriesChange);
  }, [isLoading]);

  const selectedCountries = [watch('country')].flat(1);

  return (
    <>
      <UniModalDrawerContent>
        {isLoading && (
          <Fade in={isLoading} unmountOnExit>
            <Box display='flex' alignItems='center' mb={1}>
              <Box mr={2}>
                <UniBounceLoader color='typographyGray70' size={10} />
              </Box>
              <UniTypography variant='subheader' component='span' color='typographyGray70'>
                {t('loaders.all_filters')}
              </UniTypography>
            </Box>
          </Fade>
        )}
        <Box mb={1} ml={-1}>
          <Controller
            control={control}
            name='isPartner'
            render={({ onChange, value }) => (
              <Box display='flex' alignItems='center'>
                <UniCheckbox checked={value} onChange={({ target }) => onChange(target.checked)} />
                <UniTypography>Only partner universities</UniTypography>
              </Box>
            )}
          />
        </Box>
        <Box mb={2} pt={1}>
          <UniTypography weight='medium' color='secondary100'>
            {t('labels.subtitles.by_location')}
          </UniTypography>
        </Box>

        <Box mb={1}>
          <Controller
            control={control}
            name='country'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                handleChange={(event: any, value: OptionType[]) => {
                  onChange(value?.map((item: OptionType) => item.value) || []);
                }}
                name='country'
                key={`country-${queriesChange}`}
                isLoading={isLoading}
                value={universitiesFilters?.country?.filter(item => value?.includes(item.value))}
                options={universitiesFilters?.country}
                getOptionLabel={option => option.label}
                label='Country'
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='city'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                handleChange={(event: any, value: OptionType[]) => {
                  onChange(value?.map((item: OptionType) => item.value) || []);
                }}
                name='city'
                key={`city-${queriesChange}`}
                isLoading={isLoading}
                value={
                  !!selectedCountries && selectedCountries.length > 0 && !!universitiesFilters?.cityMap
                    ? selectedCountries
                        ?.map(country => universitiesFilters?.cityMap![country!])
                        .flat(1)
                        .filter(item => value?.includes(item?.value))
                    : universitiesFilters?.city?.filter(item => value?.includes(item?.value))
                }
                options={
                  !!selectedCountries && selectedCountries.length > 0 && !!universitiesFilters?.cityMap
                    ? selectedCountries?.map(country => universitiesFilters?.cityMap![country!]).flat(1)
                    : universitiesFilters?.city
                }
                getOptionLabel={option => option?.label || ''}
                label='City'
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={2} pt={1}>
          <UniTypography weight='medium' color='secondary100'>
            {t('labels.subtitles.by_programs')}
          </UniTypography>
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='levelOfStudy'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                handleChange={(event: any, value: OptionType[]) => {
                  onChange(value?.map((item: OptionType) => item.value) || []);
                }}
                name='levelOfStudy'
                key={`city-${queriesChange}`}
                isLoading={isLoading}
                value={universitiesFilters?.levelOfStudy?.filter(item => value?.includes(item.value))}
                options={universitiesFilters?.levelOfStudy}
                getOptionLabel={option => option.label}
                label='Level of Study'
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='subject'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                handleChange={(event: any, value: OptionType[]) => {
                  onChange(value?.map((item: OptionType) => item.value) || []);
                }}
                name='subject'
                key={`city-${queriesChange}`}
                isLoading={isLoading}
                value={universitiesFilters?.subject?.filter(item => value?.includes(item.value))}
                options={universitiesFilters?.subject}
                getOptionLabel={option => option.label}
                label='Discipline'
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='languageOfStudy'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                multiple
                width='350px'
                handleChange={(event: any, value: OptionType[]) => {
                  onChange(value?.map((item: OptionType) => item.value) || []);
                }}
                name='languageOfStudy'
                key={`city-${queriesChange}`}
                isLoading={isLoading}
                value={universitiesFilters?.languageOfStudy?.filter(item => value?.includes(item.value))}
                options={universitiesFilters?.languageOfStudy}
                getOptionLabel={option => option.label}
                label='Language of Study'
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Controller
            control={control}
            name='tagId'
            render={({ onChange, value }) => (
              <UniAutocomplete
                fullWidth
                width='350px'
                handleChange={(event: any, value: OptionType) => {
                  onChange(value?.value || []);
                }}
                name='tagId'
                key={`tag-${queriesChange}`}
                isLoading={isLoading}
                value={universitiesFilters?.tags?.find(item => value === item.value)}
                options={universitiesFilters?.tags}
                getOptionLabel={option => option.label}
                label='Tag'
                labelColor='typographyBlack100'
              />
            )}
          />
        </Box>
      </UniModalDrawerContent>
      <FiltersDrawerActions handleSubmit={handleSubmit(onSubmit)} />
    </>
  );
}
