import { Box } from '@material-ui/core';
import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import { useIntersectionObserver } from 'hooks';
import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MessageIcon } from '../../assets/svg/Icons/Messages.svg';
import { UniBounceLoader, UniIconWrapper } from '../../components';
import UniButton from '../_common/uniButton/UniButton';
import UniTypography from '../_common/uniTypography/UniTypography';
import { SelectedCandidatesWrapper, StyledCandidates } from './CandidatesList.style';
import CandidatesListHeader from './CandidatesListHeader';

export type CandidatesSort = 'NAME' | 'DATE_ADDED';

export type CandidatesQueryParams = {
  page: number;
  pageSize: number;
  sort: CandidatesSort;
  order: 'ASC' | 'DESC' | null;
  query: string;
};

export type OnCandidatesSort = (value: CandidatesSort) => void;

interface Props {
  hasMore: boolean;
  fetchMore: () => void;
  allSelected?: boolean;
  selectAllCandidates?: Function;
  onSort?: OnCandidatesSort;
  length: number;
  selectedCandidates?: number;
  isLoading?: boolean;
  children?: ReactElement[] | ReactElement;
  order?: OrderType;
  sortType?: CandidatesSort;
}

const CandidatesList = ({
  hasMore,
  fetchMore,
  allSelected,
  selectAllCandidates,
  onSort,
  length,
  selectedCandidates,
  isLoading,
  children,
  order,
  sortType,
}: Props) => {
  const { t } = useTranslation('agencyAdminCandidates');
  const targetRef = useRef(null);

  useIntersectionObserver({
    target: targetRef,
    onIntersect: fetchMore,
    enabled: hasMore,
  });
  const showEndOfList = length > 4 && !hasMore && !isLoading;
  const showEmptyList = length === 0 && !isLoading;

  return (
    <>
      <CandidatesListHeader
        allSelected={allSelected}
        onSort={onSort}
        selectAllCandidates={selectAllCandidates}
        order={order}
        sortType={sortType}
      />
      <StyledCandidates>
        {children}
        {showEmptyList && (
          <Box mx={4}>
            <UniTypography color='primary100' weight='medium'>
              {t('no_candidates')}
            </UniTypography>
          </Box>
        )}
        {isLoading && <UniBounceLoader />}
        {showEndOfList && (
          <Box>
            <UniTypography color='typographyGray70'>You have reached the end of the list.</UniTypography>
          </Box>
        )}
        <div ref={targetRef} />
      </StyledCandidates>

      <SelectedCandidatesWrapper isVisible={selectedCandidates! > 0}>
        <UniTypography color='white100'>{selectedCandidates + ' ' + t('candidates_selected')}</UniTypography>
        <UniButton
          variant='text'
          fullWidth={false}
          color='primary'
          onClick={() => {}}
          startIcon={
            <UniIconWrapper size='large' color='white100'>
              <MessageIcon />
            </UniIconWrapper>
          }
        >
          {t('buttons.send_message')}
        </UniButton>
      </SelectedCandidatesWrapper>
    </>
  );
};

export default CandidatesList;
