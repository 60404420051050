import { HomepageFooter, UniHeader } from 'components';
import styled from 'styled-components';
import { theme } from 'theme';
import { ReactComponent as HeroIllustration } from '../../../assets/landing/hero-illustration.svg';
import containerFluidBackground from '../../../assets/landing/world-dots.png';
import { Colors } from '../../../constants/uiConfig';

export const StyledFluidContainer = styled.div`
  background: url(${containerFluidBackground});
  background-size: contain;

  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme }) => theme.spacing(-5)}px;
  }
`;

export const StyledUnderline = styled.span`
  position: relative;

  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 15px;
    width: 100%;
    background-color: #d12e71;
    z-index: -1;
  }
`;

export const StyledHeroSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  /* overflow: hidden; */

  max-width: 1280px;
  margin: auto;
  padding: ${({ theme }) => theme.spacing(0, 6)}px;

  ${({ theme }) => `${theme.breakpoints.down('md')}{
    padding-left: 0;
    padding-right: 0;
  }`}
`;

export const StyledAiSearchSections = styled(UniHeader)`
  padding-top: ${theme.spacing(6)}px;
  padding-bottom: ${theme.spacing(6)}px;
  background: ${Colors.White._100};
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    background: ${Colors.Primary._5};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  ${({ theme }) => `${theme.breakpoints.down('md')}{
    padding-left: ${theme.spacing(1)}px;
    padding-right: ${theme.spacing(1)}px;
  }`}
`;

export const StyledCountriesWrapper = styled.div`
  padding-top: ${theme.spacing(2)}px;
  padding-bottom: ${theme.spacing(1)}px;
  margin-top: ${theme.spacing(-2)}px;
  margin-bottom: ${theme.spacing(2)}px;
  display: flex;
  overflow: auto;
  scrollbar-color: ${Colors.Primary._100};
  scrollbar-width: thin;
  max-width: 100%;
  overflow: auto;
`;

export const StyledHeroIllustration = styled(HeroIllustration)`
  max-height: 448px;
  min-width: 300px;
  height: 100%;
  width: 100%;
`;

export const StyledHomepageFooter = styled(HomepageFooter)`
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: -48px;
`;
