import { Box } from '@material-ui/core';
import { UniTextField } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { FormSection } from 'components/_drawers/inviteCandidate/InviteCandidate.style';
import { UniDate } from 'components/_inputs/uniDate/UniDate';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getMetaRole } from 'utils';
import { useAddEducationHistoryItem } from '../../../../api';
import { useAuth } from '../../../../store';
import { EducationDrawerState } from '../AddEducationDrawer';

export type AddEdItemStep3Inputs = {
  graduationDate: string;
  schoolName: string;
};

interface Props {
  prevStep: Function;
  drawerState: EducationDrawerState;
  setDrawerState: Function;
  close: Function;
}

export default function AddEducationItemStep3({ prevStep, drawerState, setDrawerState, close }: Props): ReactElement {
  const { t } = useTranslation('addEducationDrawer');
  const { profile } = useAuth();
  const { pathname } = useLocation();
  const { isStudent, isAgency, isAgent } = getMetaRole(profile.role!);
  const [addEducation, { status: addEducationStatus }] = useAddEducationHistoryItem();
  const { handleSubmit, control, errors, register } = useForm<AddEdItemStep3Inputs>();
  const onSubmit = (formData: AddEdItemStep3Inputs) => {
    setDrawerState(formData);
    const body = {
      ...drawerState,
      ...formData,
      schoolAddress: { country: drawerState?.country },
    };
    if (isStudent) {
      addEducation(
        {
          body,
          params: {
            agencyId: profile.studentProfile?.agent?.agentProfile?.agency.id!,
            studentId: profile.id!,
          },
        },
        {
          onSuccess() {
            close();
          },
        },
      );
    }
    if (isAgency) {
      const studentId = pathname.split('/')[4];
      addEducation(
        {
          body,
          params: {
            agencyId: profile.agentProfile?.agency.id!,
            studentId,
          },
        },
        {
          onSuccess() {
            close();
          },
        },
      );
    }
    if (isAgent) {
      const studentId = pathname.split('/')[4];
      addEducation(
        {
          body,
          params: {
            agencyId: profile.agentProfile?.agency.id!,
            studentId,
          },
        },
        {
          onSuccess() {
            close();
          },
        },
      );
    }
    close();
  };
  return (
    <>
      <UniModalDrawerContent>
        <Controller
          control={control}
          name='graduationDate'
          rules={{
            required: `${t('errors.date')}`,
            validate: (value: string) => moment(value, 'YYYY-M-D').isValid() || `${t('errors.valid_date')}`,
          }}
          render={({ onChange, value }) => (
            <UniDate
              showFutureYears={!drawerState?.didGraduate}
              fullWidth
              value={value}
              onChange={onChange}
              label={drawerState?.didGraduate ? t('step3.has_diploma_date') : t('step3.graduation_date')}
              error={!!errors?.graduationDate?.message}
              helperText={errors?.graduationDate?.message}
            ></UniDate>
          )}
        ></Controller>
        <Box mt={3.75}>
          <FormSection>
            <UniTextField
              helperText={errors?.schoolName?.message}
              error={!!errors?.schoolName?.message}
              name='schoolName'
              label={t('step3.school_name')}
              placeholder={t('placeholders.school_name')}
              fullWidth
              inputRef={register({ required: `${t('errors.school_name')}` })}
            />
          </FormSection>
        </Box>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: () => prevStep(), label: t('buttons.back') }}
        PrimaryButton={{ onClick: handleSubmit(onSubmit), label: t('buttons.add') }}
      ></UniModalDrawerButtons>
    </>
  );
}
