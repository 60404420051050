import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UniButton, UniLogo, UniHeader, UniTypography, UniIconWrapper, UniChip } from 'components';
import { ReactComponent as PaperplaneIcon } from '../../../../assets/svg/Icons/Paperplane2.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/svg/Icons/Magnifyingglass.svg';
import { Gradients } from 'constants/uiConfig';
import { BrowserRoutes } from 'constants/browserRoutes';
import YouniCarousel from 'components/_common/uniCardsCarousel/YouniCarousel';
import UniCreatableSelect from 'components/_common/uniCreatableSelect/UniCreatableSelect';
import { countries as countryIso } from '../../../../constants/countries';
import { countries } from '../../../../constants/options/countries';
import AiSearchSectionProgram from '../../home/sections/aiSearch/AiSearchSectionProgram';
import useAnalytics from '../../../../hooks/useAnalytics';
import { useBreakpoints } from '../../../../hooks';
import TrackingEvents from '../../../../constants/trackingEvents';
import { StyledCountriesWrapper } from '../../home/LandingHome.style';
import { Box } from '@material-ui/core';

interface Props {}

const StyledHerosectionWrapper = styled(UniHeader)`
  position: relative;
  background: ${Gradients.Primary._180d};
  min-height: 75vh;
  padding-top: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  margin-top: 0 !important;

  * {
    z-index: 10;
  }
`;

const StyledHerosectionBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: url('https://images.unsplash.com/photo-1492538368677-f6e0afe31dcc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80');
  background-attachment: fixed;
  background-position: top center;
  opacity: 0.15;
  z-index: 0;
`;

const StyledHerosectionInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const Herosection: React.FC<Props> = () => {
  const countryFilters = countryIso.filter(itemIso => countries.find(item => item.value === itemIso.code));
  const { t } = useTranslation('landingHome');
  const { isMobile } = useBreakpoints();
  const history = useHistory();
  const { track } = useAnalytics();
  const [programs, setPrograms] = useState<any[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [query, setQuery] = useState('');

  const handleSearch = async () => {
    let countryQuery = '';
    if (selectedCountries.length) {
      countryQuery = selectedCountries.map(item => `country=${item}`).join('&');
    }
    const searchQuery = `query=${query}`;

    track(TrackingEvents.categories.AiSearch, TrackingEvents.actions.searchCountries, selectedCountries.join(', '));
    track(TrackingEvents.categories.AiSearch, TrackingEvents.actions.searchKeys, query);

    let URLParams: string;
    if (countryQuery && searchQuery) URLParams = `${searchQuery}&${countryQuery}`;
    else URLParams = `${searchQuery}${countryQuery}`;

    try {
      const response = await fetch(`https://ai-search.younichoice.com/search?query=${URLParams}`, {
        method: 'GET',
        mode: 'cors',
      });
      const data = await response.json();
      const parsedPrograms = data
        .map((item: any) => item.programs)
        .flat(1)
        .filter((item: any) => (selectedCountries.length ? selectedCountries.includes(item.address_country) : true))
        .slice(0, 5);
      setPrograms(parsedPrograms);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledHerosectionWrapper>
      <StyledHerosectionBackground />
      <StyledHerosectionInnerWrapper>
        <UniLogo light text size='XL' />
        <UniTypography variant='xl' color='white100'>
          {t('newtext.section_1.part_1')}
          <b> {t('newtext.section_1.part_2')}</b>
          <br />
          {t('newtext.section_1.part_3')}
        </UniTypography>
        <UniCreatableSelect
          isMulti
          placeholder={t('newtext.section_1.text_field')}
          options={[
            { value: 'Social Media', label: 'Social Media' },
            { value: 'Marketing', label: 'Marketing' },
            {
              value: 'I like computers',
              label: 'I like computers',
            },
          ]}
          getOptionLabel={option => option.label}
          value={
            !!query
              ? query.split(',').map(item => ({
                value: item,
                label: item,
              }))
              : []
          }
          onChange={options => setQuery(options.map(({ value }) => value).join(','))}
        />
        {isMobile && (
          <StyledCountriesWrapper>
            {countryFilters.map((item, index) => (
              <Box
                style={{ cursor: 'pointer' }}
                mr={1}
                onClick={() =>
                  setSelectedCountries(prev =>
                    prev.includes(item.code) ? prev.filter(prevItem => prevItem !== item.code) : [...prev, item.code],
                  )
                }
              >
                <UniChip label={item.name} key={index} color={selectedCountries.includes(item.code) ? 'green' : 'grey'} />
              </Box>
            ))}
          </StyledCountriesWrapper>
        )}
        <StyledActionsWrapper>
          <UniButton
            startIcon={
              <UniIconWrapper size='main' color='white100'>
                <SearchIcon />
              </UniIconWrapper>
            }
            onClick={() => {
              handleSearch().then();
            }}
          >
            {t('buttons.search')}
          </UniButton>
          <UniButton
            startIcon={
              <UniIconWrapper size='main' color='white100'>
                <PaperplaneIcon />
              </UniIconWrapper>
            }
            variant='secondary'
            color='white'
            onClick={() => {
              history.push(BrowserRoutes.Default.About);
            }}
          >
            {t('buttons.learn')}
          </UniButton>
        </StyledActionsWrapper>
      </StyledHerosectionInnerWrapper>
      <YouniCarousel
        cardWidth={isMobile ? 300 : 400}
        cardHeight={isMobile ? 320 : 300}
        name='success-stories'
        elements={programs?.map((program, index) => (
          <AiSearchSectionProgram program={program} key={index} />
        ))}
      />
    </StyledHerosectionWrapper>
  );
};

export default Herosection;
