import { Box, Divider } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { QueryStatus } from 'react-query';
import { ReactComponent as Check } from '../../assets/svg/Icons/Check.svg';
import { Components } from '../../client/UniClient';
import UniIconWrapper from '../_common/uniIconWrapper/UniIconWrapper';
import UniTypography from '../_common/uniTypography/UniTypography';
import { ProfileDataCardWrapper, StyledSeenMark } from './ProfileDataCard.style';
type UserDto = Components.Schemas.UserDto;

interface Props {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  markOption: string;
  studentId: string;
  isSeen: boolean;
  saveStudentProfile?: Function;
  saveStudentProfileStatus?: QueryStatus;
  Icon?: React.ReactNode;
}

export default function ProfileDataCard({ children, title, subtitle, isSeen, Icon }: Props): ReactElement {
  return (
    <ProfileDataCardWrapper>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <Box display='flex' alignItems='center' style={{ gap: '8px' }}>
            <UniIconWrapper size='medium' color='primary100'>
              {Icon}
            </UniIconWrapper>
            <UniTypography variant='header2' color='primary100' weight='medium'>
              {title}
            </UniTypography>
            {isSeen && (
              <StyledSeenMark>
                <UniIconWrapper withBackground size='medium' color='secondary100'>
                  <Check />
                </UniIconWrapper>
              </StyledSeenMark>
            )}
          </Box>
          <UniTypography variant='header3' color='primary100'>
            {subtitle}
          </UniTypography>
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        <Divider />
      </Box>
      <Box px={3}>{children}</Box>
    </ProfileDataCardWrapper>
  );
}
