import styled from 'styled-components';
import { theme } from 'theme';
import { Colors } from '../../constants';

export const StyledOutterWrapper = styled.div``;

export const StyledFiltersBar = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: ${theme.spacing(2)}px;

  > * {
    flex: 1;
    min-width: 250px;
  }
`;

export const StyledCategoryItem = styled.div`
  padding: ${theme.spacing(2)}px;
  background: ${Colors.Secondary._100};
`;
