import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { CacheKeys } from "../../constants";
import { useClient } from "../../store";

type Params = {
  agencyId: string;
  agentId: string;
};

export default function useGetAgent(params: Params) {
  const { client } = useClient();
  return useQuery(
    [CacheKeys.Agency.Agent, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.AgencyApi_getAgent(params);
      return data;
    },
    { onError(error: AxiosError) {} }
  );
}
