import styled from 'styled-components';
import { Colors, Shadows } from '../../../constants';
import { Size } from '../uniIconWrapper/UniIconWrapper';

export const StyledTabWrapper = styled.div<{
  direction: string;
  active: boolean;
  size: Size;
  disabled: boolean;
}>`
  display: flex;
  justify-content: ${({ direction }) => (direction === 'row' ? 'flex-start' : 'center')};
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  flex-direction: ${({ direction }) => direction};
  min-width: 180px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  background: ${({ active, size, disabled }) =>
    disabled ? Colors.Typography.Gray_10 : active ? Colors.Primary._100 : size === 'large' ? Colors.White._100 : 'transparent'};

  padding: ${({ theme, size }) => theme.spacing(size === 'large' ? 3.5 : 1, 4)};

  box-shadow: ${Shadows.shadow2};

  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  transition: 0.2s;
`;
