import useCreateAchievement from 'api/achievements/useCreateAchievement';
import usePostAchievement from 'api/achievements/usePostAchievement';
import useAddTodo from 'api/todos/useAddTodo';
import usePostTodo from 'api/todos/usePostTodo';
import { AnimatedTabPanel } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth, useDrawers } from 'store';
import { ActivitiesCategory, ActivitiesType } from 'types/activities';
import { getMetaRole } from 'utils';
import ActionsFooter from './_components/ActionsFooter';
import Step1 from './_steps/Step1';
import Step2 from './_steps/Step2';
import Step3 from './_steps/Step3';
import Step4 from './_steps/Step4';
import Step5 from './_steps/Step5';
import Step6 from './_steps/Step6';

type Form = {
  title: string;
  description: string;
  category: ActivitiesCategory;
  deadline?: string;
  ended?: string;
  links?: { value: string }[];
  documents?: File[];
};

export interface AddActivityItemDrawerProps {
  studentId?: string;
}

const defalutFormState: Partial<Form> = {
  links: [{ value: '' }],
};

const AddActivityItemDrawer: React.FC<AddActivityItemDrawerProps> = ({ studentId }) => {
  const [formState, setFormState] = useState<Partial<Form>>(defalutFormState);
  const { control, register, handleSubmit, reset, errors } = useForm<Form>({
    defaultValues: defalutFormState,
  });
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile.role);
  const [postAchievement, { isLoading: achievementIsPosting }] = usePostAchievement();
  const [createAchievement, { isLoading: achievementIsCreating }] = useCreateAchievement();
  const [postTodo, { isLoading: todoIsPosting }] = usePostTodo();
  const [addTodo, { isLoading: todoIsAdding }] = useAddTodo();
  const isLoading = todoIsPosting || achievementIsPosting || achievementIsCreating || todoIsAdding;
  const [step, setStep] = useState(0);
  const [type, setType] = useState<ActivitiesType | undefined>();
  const { setProgress } = useDrawers();
  const { t } = useTranslation('activityItemDrawer');

  const onClickAchievement = () => {
    setType('ACHIEVEMENT');
    setStep(1);
  };

  const onClickTodo = () => {
    setType('TODO');
    setStep(1);
  };

  const prevStep = () => {
    setStep(prev => prev - 1);
    handleSubmit(onSaveForm)();
  };

  const onSaveForm = (formData: Form) => {
    setFormState(prev => ({ ...prev, ...formData }));
    reset({ ...formState, ...formData });
  };

  const onSubmit = (formData: Form) => {
    const auxData = { ...formState, ...formData };
    if (!type || !studentId) return;
    const fullFormData = {
      ...auxData,
      studentId,
      type,
      deadline: auxData?.deadline || '',
      completedAt: auxData?.ended || '',
      links: auxData?.links?.map(item => item.value) || [],
      documents: auxData.documents,
    };

    if (type === 'ACHIEVEMENT')
      if (isStudent) {
        postAchievement(fullFormData, {
          onSuccess() {
            setStep(5);
          },
        });
      } else {
        createAchievement(fullFormData, {
          onSuccess() {
            setStep(5);
          },
        });
      }
    if (type === 'TODO')
      if (isStudent) {
        addTodo(fullFormData, {
          onSuccess() {
            setStep(5);
          },
        });
      } else {
        postTodo(fullFormData, {
          onSuccess() {
            setStep(5);
          },
        });
      }
  };

  const nextStep = () => {
    handleSubmit(formData => {
      if (Object.keys(errors).length === 0) {
        if (type === 'TODO') {
          if (step === 2) {
            onSubmit(formData);
            return;
          }
        } else if (type === 'ACHIEVEMENT') {
          if (step === 4) {
            onSubmit(formData);
            return;
          }
        }
        setStep(prev => prev + 1);
        onSaveForm(formData);
      }
    })();
  };

  useEffect(() => {
    const progress = step === 5 ? 100 : step * (type === 'ACHIEVEMENT' ? 15 : 25) + 25;
    setProgress(progress);
  }, [step, type]);

  return (
    <>
      <UniModalDrawerContent>
        <AnimatePresence>
          <AnimatedTabPanel
            Content={<Step1 onClickAchievement={onClickAchievement} onClickTodo={onClickTodo} />}
            index={0}
            currentItem={step}
          />
          <AnimatedTabPanel Content={<Step2 control={control} register={register} errors={errors} />} index={1} currentItem={step} />
          <AnimatedTabPanel Content={<Step3 type={type} control={control} errors={errors} />} index={2} currentItem={step} />
          <AnimatedTabPanel Content={<Step4 control={control} register={register} errors={errors.links} />} index={3} currentItem={step} />
          <AnimatedTabPanel Content={<Step5 control={control} />} index={4} currentItem={step} />
          <AnimatedTabPanel Content={<Step6 />} index={5} currentItem={step} />
        </AnimatePresence>
      </UniModalDrawerContent>
      {step !== 0 && <ActionsFooter loading={isLoading} step={step - 1} nextStep={nextStep} prevStep={prevStep} />}
    </>
  );
};

export default AddActivityItemDrawer;
