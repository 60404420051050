import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationStatus } from 'types';
import { UniTypography } from '../../..';
import { StyledStatusSelector, StyledUniStatus } from '../UniversityReplyForm.style';

interface Props {
  status?: ApplicationStatus;
  setStatus: (status: ApplicationStatus) => void;
}

export default function UniversityReplyStep1({ status, setStatus }: Props): ReactElement {
  const { t } = useTranslation('universityReplyForm');

  return (
    <>
      <UniTypography variant='header3'>{t('step1.title')}</UniTypography>
      <UniTypography color='typographyGray70'>{t('step1.subtitle')}</UniTypography>
      <StyledStatusSelector>
        <Box my={2} onClick={() => setStatus('PASSED')}>
          <StyledUniStatus specificColor={status === 'PASSED' ? 'primary100' : undefined}>Admitted</StyledUniStatus>
        </Box>
        <Box my={2} onClick={() => setStatus('CONDITIONAL_ADMISSION')}>
          <StyledUniStatus specificColor={status === 'CONDITIONAL_ADMISSION' ? 'primary100' : undefined}>Conditional offer</StyledUniStatus>
        </Box>
        <Box my={2} onClick={() => setStatus('INCOMPLETE')}>
          <StyledUniStatus specificColor={status === 'INCOMPLETE' ? 'primary100' : undefined}>Incomplete</StyledUniStatus>
        </Box>
        <Box my={2} onClick={() => setStatus('FAILED')}>
          <StyledUniStatus specificColor={status === 'FAILED' ? 'primary100' : undefined}>Rejected</StyledUniStatus>
        </Box>
      </StyledStatusSelector>
    </>
  );
}
