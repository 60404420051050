export const lengthOfStudyOptions = [
  { value: 'HOUR', label: 'Hour' },
  { value: 'DAY', label: 'Day' },
  { value: 'WEEK', label: 'Week' },
  { value: 'MONTH', label: 'Month' },
  { value: 'TRIMESTER', label: 'Trimester' },
  { value: 'SEMESTER', label: 'Semester' },
  { value: 'YEAR', label: 'Year' },
  { value: 'TOTAL', label: 'Total' },
];
