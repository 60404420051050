export const DefaultFormValues: any = {
  page: undefined,
  pageSize: undefined,
  query: undefined,
  universityId: undefined,
  facultyId: undefined,
  studentId: undefined,
  country: undefined,
  city: undefined,
  language: undefined,
  levelOfStudy: undefined,
  discipline: undefined,
  endDate: undefined,
  tuitionFee: undefined,
  sortType: undefined,
};

export const DefaultFormValuesCandidates: any = {
  applicationStatus: [],
  status: [],
  nationality: [],
  levelOfStudy: [],
  country: [],
  agencyId: [],
  starred: undefined,
  favorites: undefined,
  countryOfStudy: [],
  livingCost: undefined,
  tuitionFee: undefined,
};

export const DefaultFormValuesAgencies: any = {
  minAgents: undefined,
  maxAgents: undefined,
  minStudents: undefined,
  maxStudents: undefined,
  status: undefined,
  country: undefined,
};

export const DefaultFormValuesApplications: any = {
  status: undefined,
  facultyId: undefined,
  levelOfStudy: undefined,
  programId: undefined,
  specialization: undefined,
  programLanguage: undefined,
};
