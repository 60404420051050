import styled from 'styled-components';

export const StyledPurchasedPrepPacksListWrapper = styled.div`
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  color: #373737;
  flex-wrap: wrap;
  flex: 0 auto;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
`;
