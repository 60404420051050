import { Box } from '@material-ui/core';
import { useBreakpoints } from 'hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LocationIcon } from '../../assets/svg/Icons/Location.svg';
import { Components } from '../../client/UniClient';
import { countries } from '../../constants/countries';
import { levelOfStudy } from '../../constants/levelOfStudy';
import { theme } from '../../theme';
import UniIconWrapper from '../_common/uniIconWrapper/UniIconWrapper';
import UniTypography from '../_common/uniTypography/UniTypography';
import { CandidateMeta, MetaItem } from './CandidateHeader.style';

// interface Props = any;

export default function CandidateHeaderMeta({
  nationality,
  graduationLevel,
  countryOfStudy,
  agent,
  agentFee,
}: Components.Schemas.StudentProfileDto): ReactElement {
  const { t } = useTranslation('studentProfile');
  const { isMobile } = useBreakpoints();

  return (
    //TODO add react-sticky
    <CandidateMeta isMobile={isMobile}>
      <Box display='flex' justifyContent='flex-start' style={{ gap: `${theme.spacing(5)}px` }}>
        <MetaItem isMobile={isMobile}>
          {!isMobile && (
            <UniIconWrapper color='primary100' withBackground>
              <LocationIcon />
            </UniIconWrapper>
          )}
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('labels.header.nationality')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              {countries.find(item => item.code === nationality)?.name || '-'}
            </UniTypography>
          </Box>
        </MetaItem>
        <MetaItem isMobile={isMobile}>
          {!isMobile && (
            <UniIconWrapper color='primary100' withBackground>
              <LocationIcon />
            </UniIconWrapper>
          )}
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('labels.header.residency')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              {nationality || '-'}
            </UniTypography>
          </Box>
        </MetaItem>
      </Box>
      <Box display='flex' justifyContent='flex-start' style={{ gap: `${theme.spacing(5)}px` }}>
        <MetaItem isMobile={isMobile}>
          {!isMobile && (
            <UniIconWrapper color='secondary100' withBackground>
              <LocationIcon />
            </UniIconWrapper>
          )}
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('labels.header.level_of_study')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              {levelOfStudy.find(item => item.value === graduationLevel)?.label || '-'}
            </UniTypography>
          </Box>
        </MetaItem>
        <MetaItem isMobile={isMobile}>
          {!isMobile && (
            <UniIconWrapper color='secondary100' withBackground>
              <LocationIcon />
            </UniIconWrapper>
          )}
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {t('labels.header.country_of_study')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              {countries.find(item => item.code === countryOfStudy)?.name || '-'}
            </UniTypography>
          </Box>
        </MetaItem>
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <MetaItem isMobile={isMobile}>
          {!isMobile && (
            <UniIconWrapper color='secondary100' withBackground>
              <LocationIcon />
            </UniIconWrapper>
          )}
          <Box>
            <UniTypography color='typographyGray70' variant='footnote'>
              {agent?.firstName ? t('labels.header.agent_name') : t('labels.header.agency_name')}
            </UniTypography>
            <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
              {agent?.firstName || agent?.agentProfile?.agency?.name}
            </UniTypography>
          </Box>
        </MetaItem>
      </Box>
    </CandidateMeta>
  );
}
