import styled from 'styled-components';

export const ConsultancyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledServiceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;
