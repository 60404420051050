import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';
import { createNotificationAction } from '../../utils';

type Params = {
  page?: number;
  pageSize?: number;
};

export default function useOldNotifications(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  const { profile } = useAuth();

  const isLoggedIn = !!profile?.id;

  return useQuery(
    [CacheKeys.General.Notifications, params],
    async (_key: string, params: Params) => {
      if (isLoggedIn) {
        const { data } = await client!.NotificationApi_getMyNotifications(params);
        return data.map(item => ({ ...item, action: createNotificationAction(item) }));
      }
      return;
    },
    { refetchOnWindowFocus: false },
  );
}
