import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniButton, UniStatus } from 'components';
import { applicationStatusMap } from 'constants/applicaiton';
import { useDrawers } from 'store';

interface Props {
  application?: Components.Schemas.ApplicationDto;
}

const ApplicationPageActions: React.FC<Props> = ({ application }) => {
  const { openDrawer } = useDrawers();
  const openReplyToUniversityDrawer = () => {
    openDrawer('reply-to-student', { size: 'small', application });
  };
  const openApplicationFeeDrawer = () => {
    openDrawer('application-fee', { size: 'small', application });
  };

  if (!application?.status) {
    return <UniStatus>Not applied</UniStatus>;
  } else {
    return (
      <Box display='flex' alignItems='center'>
        <UniStatus>{applicationStatusMap[application.status]}</UniStatus>
        {/* <Box ml={2} />
        {!application.feeInvoice ? (
          <UniButton variant='secondary' fullWidth={false} onClick={openApplicationFeeDrawer}>
            Application fee
          </UniButton>
        ) : application?.feeInvoice?.isPaid ? (
          <UniStatus specificColor='otherGreen100'>{`Paid (${parseApplicationFee(application?.feeInvoice)})`}</UniStatus>
        ) : (
          <UniStatus specificColor='otherYellow100'>{`Awaiting payment (${parseApplicationFee(application?.feeInvoice)})`}</UniStatus>
        )} */}
        <Box ml={2} />
        <UniButton fullWidth={false} onClick={openReplyToUniversityDrawer}>
          Reply
        </UniButton>
      </Box>
    );
  }
};

export default ApplicationPageActions;
