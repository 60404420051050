import { UniHeader, UniTypography } from 'components';
import styled from 'styled-components';
import { StyledTitleWrapper } from '../LandingHome.style';
import { useTranslation } from 'react-i18next';

interface Props {}

const StyledPrepsWrapper = styled(UniHeader)`
  box-shadow: none;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-top: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const StyledPartnersWrapper = styled.div`
  position: relative;
  overflow: hidden;

  display: flex;
  width: 100%;

  &::before {
    content: '';
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &::after {
    content: '';
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  & > img {
    animation: slide 20s linear infinite;
  }

  @keyframes slide {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-1500px, 0, 0); /* The image width */
    }
  }
`;

const StyledPartnerImage = styled.img`
  max-width: 300px;
  height: 100px;
  object-fit: contain;
  margin: 0 20px;
`;

const Partners: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation('landingHome');
  const partners = require.context('assets/landing/universities', true, /\.(svg|png)$/);
  const partnerImages = Array.from(partners.keys(), key => partners(key).default);
  const numDuplicates = 3;
  const duplicatedImages = Array(numDuplicates).fill(partnerImages).flat();

  return (
    <StyledPrepsWrapper>
      <StyledTitleWrapper>
        <UniTypography variant='header1' color='secondary100' weight='bold'>
          {t('newtext.section_4.part_1')}
        </UniTypography>
        <UniTypography variant='header3' color='typographyGray100'>
          {t('newtext.section_4.part_2')}
        </UniTypography>
      </StyledTitleWrapper>
      <StyledPartnersWrapper>
        {duplicatedImages.map((image, index) => (
          <StyledPartnerImage src={image} key={index} alt='university' />
        ))}
      </StyledPartnersWrapper>
    </StyledPrepsWrapper>
  );
};

export default Partners;
