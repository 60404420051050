import { Box } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import backgroundImage from '../../../assets/images/walk-in-the-city.png';
import { ReactComponent as AddIcon } from '../../../assets/svg/Icons/Add.svg';
import { UniButton, UniIconWrapper, UniPage, UniSearch, UniTypography } from '../../../components';
import { useAuth, useDrawers } from '../../../store';

const AdminHome = () => {
  const { t } = useTranslation('superAdminHome');
  const { profile } = useAuth();
  const { openDrawer } = useDrawers();

  const [loading, setIsLoading] = useState(false);

  const handleFakeSearch = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  }, []);

  const openInvitationDrawer = () => {
    openDrawer('invite-agency', {
      size: 'main',
    });
  };

  return (
    <>
      <UniPage>
        <div
          style={{
            width: '100%',
            background: `url(${backgroundImage}) no-repeat`,
            backgroundPositionX: 'max(500px, 100%)',
            backgroundPositionY: '30px',
          }}
        >
          <Box mb={2}>
            <UniTypography color='typographyBlack100' variant='header2' component='h1'>
              {t('greeting')}, <strong>{profile.username}</strong>
            </UniTypography>
          </Box>
        </div>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center' flex='auto'>
            <div style={{ display: 'inline-block' }}>
              <UniTypography weight='semi-bold' variant='header3'>
                Agencies
              </UniTypography>
            </div>

            <UniSearch placeholder='Search candidates' loading={loading} oldSearchVal='' handleSearch={handleFakeSearch} />
          </Box>
          <UniButton
            onClick={openInvitationDrawer}
            fullWidth={false}
            color='primaryFade'
            startIcon={
              <UniIconWrapper color='primary100'>
                <AddIcon />
              </UniIconWrapper>
            }
          >
            Add New Agency
          </UniButton>
        </Box>
      </UniPage>
    </>
  );
};

export default AdminHome;
