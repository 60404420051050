import { useCandidate, useProgram } from 'api';
import { AnimatedTabPanel, EmptyState, UniHeader, UniIconWrapper, UniPage, UniPageLoader } from 'components';
import ProgramsListItemAgentActions from 'components/programsList/ProgramsListItemAgentActions';
import { UniHerosection } from 'components/_common/uniHerosection/UniHerosection';
import ProgramGeneralInfo from 'components/_templates/programGeneralInfo/ProgramGeneralInfo';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import { useHeader } from 'store';
import { ReactComponent as HourglassIcon } from '../../../assets/svg/Icons/Hourglass.svg';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location.svg';
import { BrowserRoutes } from '../../../constants';

const StudentProgramPage: React.FC = () => {
  const { t } = useTranslation('programPage');
  const { programId, studentId } = useParams<{ studentId: string; programId: string }>();
  const { url } = useRouteMatch();
  const { goBack } = useHistory();
  const { setHeader, clearHeader } = useHeader();
  const { data: candidate, status } = useCandidate({ studentId });
  const generalPath = BrowserRoutes.Agent.StudentProgram.General.replace(':programId', programId).replace(':studentId', studentId);
  const statusPath = BrowserRoutes.Agent.StudentProgram.Status.replace(':programId', programId).replace(':studentId', studentId);
  const currentRoute = url === generalPath ? 0 : 1;

  const { data: program, status: programStatus } = useProgram({
    id: programId,
  });

  const { title, universityName, universityId, address, lengthOfStudy } = useMemo(() => {
    if (program)
      return {
        title: program.name,
        universityName: program.universityName,
        universityId: program.universityId,
        address: program.address,
        lengthOfStudy: program.lengthOfStudy,
      };

    return { title: '', universityName: '', universityId: '', address: undefined, lengthOfStudy: undefined };
  }, [program]);

  useEffect(() => {
    setHeader({
      primaryButton: { onClick: () => goBack() },
    });
    return clearHeader;
  }, []);

  if (programStatus === 'loading' || !candidate || status === 'loading') {
    return <UniPageLoader />;
  }

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='base'
          title={title}
          universityName={universityName}
          universityId={universityId}
          Actions={<ProgramsListItemAgentActions program={program} candidate={candidate} />}
          metrics={[
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <LocationIcon />
                </UniIconWrapper>
              ),
              label: t('header.location'),
              value: `${address?.city || '-'}, ${address?.country || '-'}`,
            },
            {
              Icon: (
                <UniIconWrapper color='secondary100'>
                  <HourglassIcon />
                </UniIconWrapper>
              ),
              label: t('header.lengthOfStudy'),
              value: lengthOfStudy ? `${lengthOfStudy?.value} ${lengthOfStudy?.unit}` : 'Unspecified',
            },
          ]}
          tabs={[
            { label: t('tabs.status'), path: statusPath },
            { label: t('tabs.general'), path: generalPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={generalPath}>
              <AnimatedTabPanel
                Content={<ProgramGeneralInfo program={program} />}
                index={0}
                currentItem={currentRoute}
                absoluteDirection={-1}
              />
            </Route>
            <Route path={statusPath}>
              <AnimatedTabPanel Content={<EmptyState type='status' />} index={1} currentItem={currentRoute} absoluteDirection={1} />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default StudentProgramPage;
