import { Box } from '@material-ui/core';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import UniPhoneInput from 'components/_inputs/uniPhoneInput/uniPhoneInput';
import { default as React, ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useResendCandidateInvite } from '../../../api';
import usePostCandidateInvite from '../../../api/agency/usePostCandidateInvite';
import { Components } from '../../../client/UniClient';
import { currencyIso } from '../../../constants';
import { useErrorHandler } from '../../../hooks';
import { useAuth } from '../../../store';
import { CurrencyIso } from '../../../types';
import { validateEmail } from '../../../utils';
import UniTypography from '../../_common/uniTypography/UniTypography';
import UniTextField from '../../_inputs/uniTextField/UniTextField';
import { FormSection } from './InviteCandidate.style';
interface Props {
  handleClose: Function;
  handleSuccess: Function;
  handleResendSuccess?: Function;
  candidateForResend?: Components.Schemas.UserResponse;
}

interface Inputs {
  email: string;
  firstName: string;
  lastName: string;
  feeAmount: number;
  feeCurrency: CurrencyIso;
  feeType: 'UPFRONT' | 'FIFTY_FIFTY' | 'AFTER';
  phone: string;
  phonePrefix: string;
}

export default function InviteCandidateSingle({
  handleClose,
  handleSuccess,
  handleResendSuccess,
  candidateForResend,
}: Props): ReactElement {
  const { t } = useTranslation('addCandidatePage');
  const { profile } = useAuth();
  const [resendInvite, { data: resendResponse, isLoading: isLoadingResend, status, error: postCandidateError }] =
    useResendCandidateInvite();
  const { handleSubmit, register, setValue, control, errors, reset } = useForm<Inputs>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      feeAmount: profile.agentProfile?.fee.amount,
      feeType: profile.agentProfile?.feeType,
      feeCurrency: currencyIso[0].value as CurrencyIso,
    },
  });

  const [addCandidate, { isLoading, error: addCandidateError }] = usePostCandidateInvite();
  const postError = useErrorHandler(addCandidateError!);

  const onSubmit = (payload: Inputs) => {
    if (candidateForResend != undefined) {
      resendInvite(
        {
          agencyId: candidateForResend.studentProfile.agent.agentProfile.agency.id || '',
          studentId: candidateForResend.id || '',
        },
        {
          onSuccess(response) {
            if (handleResendSuccess != undefined) handleResendSuccess();
          },
        },
      );
    } else {
      payload.feeAmount = 0;
      payload.feeCurrency = 'EUR';
      payload.feeType = profile.agentProfile?.feeType || 'AFTER';

      addCandidate(
        payload, //TODO ADD FEE
        {
          onSuccess(response) {
            handleSuccess(response.data.id);
          },
          onSettled() {
            reset();
          },
        },
      );
    }
  };
  const firstNameError = errors?.firstName?.message ?? '';
  const lastNameError = errors?.lastName?.message ?? '';
  const emailError = errors?.email?.message ?? '';
  const phoneError = errors?.phone?.message ?? '';
  const feeAmountError = errors?.feeAmount?.message ?? '';
  useEffect(() => {
    if (candidateForResend != undefined) {
      setValue('firstName', candidateForResend.firstName);
      setValue('lastName', candidateForResend.lastName);
      setValue('email', candidateForResend.email);
      setValue('phone', candidateForResend.phone);
      setValue('phonePrefix', candidateForResend.phonePrefix);
    } else {
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('email', '');
      setValue('phone', '');
    }
  }, [candidateForResend]);
  return (
    <>
      <UniModalDrawerContent>
        <Box width='100%' marginTop='10px'>
          <UniTypography variant='header3' weight='semi-bold'>
            {t('labels.one.basic_information.title')}
          </UniTypography>
        </Box>
        <FormSection>
          <UniTextField
            helperText={firstNameError}
            error={!!firstNameError}
            readOnly={candidateForResend != undefined}
            name='firstName'
            placeholder={t('labels.one.basic_information.first_name.hint')}
            inputRef={register({
              required: 'First name is required',
            })}
            fullWidth
            label={t('labels.one.basic_information.first_name.title')}
            labelColor='typographyGray30'
            variant='subheader'
          />
          <UniTextField
            helperText={lastNameError}
            error={!!lastNameError}
            name='lastName'
            readOnly={candidateForResend != undefined}
            placeholder={t('labels.one.basic_information.last_name.hint')}
            inputRef={register({
              required: 'Last name is required',
            })}
            fullWidth
            label={t('labels.one.basic_information.last_name.title')}
            labelColor='typographyGray30'
            variant='subheader'
          />
        </FormSection>
        <Box width='100%' marginTop='10px'>
          <UniTypography variant='header3' weight='semi-bold'>
            {t('labels.one.contact_details.title')}
          </UniTypography>
        </Box>
        <FormSection>
          <UniTextField
            helperText={emailError}
            error={!!emailError}
            name='email'
            readOnly={candidateForResend != undefined}
            inputRef={register(validateEmail())}
            fullWidth
            placeholder={t('labels.one.contact_details.email.hint')}
            label={t('labels.one.contact_details.email.title')}
            labelColor='typographyGray30'
            variant='subheader'
          />
        </FormSection>
        <FormSection>
          <UniPhoneInput
            control={control}
            setValue={setValue}
            fixed='medium'
            helperText={phoneError}
            error={!!phoneError}
            name='phone'
            readOnly={candidateForResend != undefined}
            inputRef={register({ required: 'Phone number is required' })}
            fullWidth
            type='tel'
            placeholder={t('labels.one.contact_details.phone.hint')}
            label={t('labels.one.contact_details.phone.title')}
            labelColor='typographyGray30'
            variant='subheader'
          />
        </FormSection>
        <FormSection>{postError.message && <UniTypography color='stateAlert100'>{postError.message}</UniTypography>}</FormSection>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: handleClose, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: candidateForResend != undefined ? t('buttons.resend_invitation') : t('buttons.send_invitation'),
        }}
      />
    </>
  );
}
