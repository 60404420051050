import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { UniButton, UniTextField } from '../../';
import { StyledDocumentCreatorPopup } from './UniDocumentFieldCreator.style';

export type TDocument = {
  id?: number;
  title: string;
  description: string;
  selected?: boolean;
};

interface Props {
  addDocument: (value: TDocument) => void;
  editDocument: (value: TDocument) => void;
  itemToEdit?: TDocument;
  handleClose: () => void;
}

export default function UniDocumentFieldCreatorPopup({ addDocument, handleClose, itemToEdit, editDocument }: Props): ReactElement {
  const { register, handleSubmit, errors } = useForm<TDocument>({ defaultValues: { ...itemToEdit } });

  const onSubmit = (formData: TDocument) => {
    itemToEdit ? editDocument({ ...itemToEdit, ...formData }) : addDocument(formData);
    handleClose();
  };

  return (
    <StyledDocumentCreatorPopup>
      <UniTextField
        name='title'
        label='Document name'
        autoFocus
        inputRef={register({ required: 'Document name is required!' })}
        helperText={errors?.title?.message}
        error={!!errors?.title?.message}
      />
      <UniTextField
        name='description'
        label='Document description'
        inputRef={register({ required: 'A short description is required!' })}
        helperText={errors?.description?.message}
        error={!!errors?.description?.message}
      />
      <Box display='flex' justifyContent='space-between'>
        <UniButton fullWidth={false} variant='text' onClick={() => handleClose()}>
          Cancel
        </UniButton>
        <UniButton fullWidth={false} color='primaryFade' onClick={handleSubmit(onSubmit)}>
          {!!itemToEdit ? 'Save' : 'Create'}
        </UniButton>
      </Box>
    </StyledDocumentCreatorPopup>
  );
}
