import { Box } from '@material-ui/core';
import { UniAvatar, UniStatus } from 'components';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { applicationStatusMap } from 'constants/applicaiton';
import React from 'react';
import { ReplyToStudentInputs } from '../ReplyToStudent';
import { StyledHeaderAvatar } from '../ReplyToStudent.style';

interface Props {
  programName?: string;
  universityName?: string;
  studentName?: string;
  formValues: Partial<ReplyToStudentInputs>;
}

const Step4: React.FC<Props> = ({ programName = '', universityName = '', studentName, formValues }) => {
  const form = formValues;

  return (
    <>
      <Box mb={2}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          {`You are about to reply to ${studentName}’s application for:`}
        </UniTypography>
      </Box>
      <StyledHeaderAvatar>
        <UniAvatar size='S' label={universityName} />
        <Box>
          <UniTypography variant='subheader' color='typographyBlack100' weight='semi-bold'>
            {programName}
          </UniTypography>
          <UniTypography variant='subheader' color='secondary100' weight='medium'>
            {universityName}
          </UniTypography>
        </Box>
      </StyledHeaderAvatar>
      {form?.applicationStatus && (
        <Box mb={5}>
          <Box mb={2}>
            <UniTypography weight='medium'>Application status</UniTypography>
          </Box>
          <UniStatus>{applicationStatusMap[form.applicationStatus]}</UniStatus>
        </Box>
      )}
      {/* {form.applicationFee?.amount && form.applicationFee?.currencyIso && (
        <Box mb={5}>
          <Box mb={2}>
            <UniTypography weight='medium'>Application fee</UniTypography>
          </Box>
          <UniTypography color='typographyGray70'>{`${form.applicationFee?.amount} ${form.applicationFee?.currencyIso}`}</UniTypography>
        </Box>
      )} */}
      {form.message && (
        <Box mb={5}>
          <Box mb={2}>
            <UniTypography weight='medium'>Message</UniTypography>
          </Box>
          <UniTypography color='typographyGray70'>{form.message}</UniTypography>
        </Box>
      )}
      {form.documents && (
        <Box mb={5}>
          <Box mb={2}>
            <UniTypography weight='medium'>Uploaded documents</UniTypography>
          </Box>
          {form.documents?.map(item => (
            <UniTypography color='typographyGray70'>{item.name}</UniTypography>
          ))}
        </Box>
      )}
    </>
  );
};

export default Step4;
