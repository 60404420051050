import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UniAutocomplete, UniButton, UniTextField, UniTypography } from '../../../../components';
import { Colors } from '../../../../constants';
import { countries } from '../../../../constants/countries';
import { StyledFormRow } from '../Onboard.style';

interface Props {
  show: boolean;
  data: {
    companyName: string;
    email: string;
  };
  nextStep: Function;
  onCancel: Function;
  setFormData: Function;
}

export interface OnboardFormStep1 {
  companyName: string;
  taxCode: string;
  email: string;
  website: string;
  address: string;
  postalCode: string;
  country: string;
  state: string;
  city: string;
}

export default function AgencyOnboardStep1({ show, nextStep, onCancel, setFormData, data }: Props): ReactElement | null {
  const { register, handleSubmit, errors, control, setValue } = useForm<OnboardFormStep1>({
    defaultValues: {
      companyName: data.companyName,
      email: data.email,
    },
  });

  const onSubmit = (formData: OnboardFormStep1) => {
    setFormData({ ...formData, email: data.email });
    nextStep();
  };

  const companyNameError = errors?.companyName?.message ?? '';
  const websiteError = errors?.website?.message ?? '';
  const addressError = errors?.address?.message ?? '';
  const postalCodeError = errors?.postalCode?.message ?? '';
  const stateError = errors?.state?.message ?? '';
  const cityError = errors?.city?.message ?? '';
  const taxCodeError = errors?.taxCode?.message ?? '';
  const emailError = errors?.email?.message ?? '';
  const countryError = errors?.country?.message ?? '';

  return (
    <>
      <Box display={show ? 'inline' : 'none'} alignSelf='flex-start' width='100%' maxWidth='800px'>
        <Box mb={5}>
          <UniTypography variant='header1' weight='medium'>
            Partner information
          </UniTypography>
          {/* <UniTypography>Loreum ipsum situm dolores</UniTypography> */}
        </Box>
        <StyledFormRow style={{ marginTop: '5px' }}>
          <UniTextField
            fullWidth
            helperText={companyNameError}
            error={!!companyNameError}
            defaultValue={data.companyName}
            name='companyName'
            label='Company name'
            placeholder='Enter company name'
            inputRef={register({ required: 'Company name must not be empty!' })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
          <UniTextField
            fullWidth
            helperText={taxCodeError}
            error={!!taxCodeError}
            name='taxCode'
            label='Tax code/VAT'
            placeholder='Enter tax code or VAT'
            inputRef={register({ required: 'Tax code/VAT must not be empty!' })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
        </StyledFormRow>

        <StyledFormRow style={{ marginTop: '5px' }}>
          <UniTextField
            disabled
            helperText={emailError}
            error={!!emailError}
            name='email'
            defaultValue={data.email}
            fullWidth
            label='Company email'
            placeholder='Enter company email'
            inputRef={register({
              required: 'Company email must not be empty!',
            })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
          <UniTextField
            helperText={websiteError}
            error={!!websiteError}
            name='website'
            fullWidth
            label='Company website'
            placeholder='Enter company website'
            inputRef={register({
              required: 'Company website must not be empty!',
            })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
        </StyledFormRow>

        <StyledFormRow style={{ marginTop: '5px' }}>
          <Box>
            <UniTextField
              helperText={addressError}
              error={!!addressError}
              name='address'
              fullWidth
              label='Company address'
              placeholder='Enter company address'
              inputRef={register({
                required: 'Company address must not be empty!',
              })}
              backgroundColor={Colors.Gray1._100}
              labelColor='typographyGray70'
            />
          </Box>
          <UniTextField
            helperText={postalCodeError}
            error={!!postalCodeError}
            name='postalCode'
            fullWidth
            label='Postal code'
            placeholder='Enter postal code'
            inputRef={register({ required: 'Postal code must not be empty!' })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
        </StyledFormRow>

        <StyledFormRow style={{ marginTop: '5px' }}>
          <Controller
            control={control}
            name='country'
            rules={{
              required: 'Country must not be empty!',
            }}
            render={() => (
              <UniAutocomplete
                helperText={countryError}
                error={!!countryError}
                handleChange={(event: any, value: any) => {
                  setValue('country', value?.code || '');
                }}
                label='Country'
                name='country'
                options={countries}
                getOptionLabel={option => option.name}
                backgroundColor='gray1_100'
                labelColor='typographyGray70'
              />
            )}
          />
          <UniTextField
            helperText={stateError}
            error={!!stateError}
            name='state'
            fullWidth
            label='State/Province'
            placeholder='Enter state or province'
            inputRef={register({
              required: 'State/Province must not be empty!',
            })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
          <UniTextField
            helperText={cityError}
            error={!!cityError}
            name='city'
            fullWidth
            label='City'
            placeholder='Enter city'
            inputRef={register({ required: 'City must not be empty!' })}
            backgroundColor={Colors.Gray1._100}
            labelColor='typographyGray70'
          />
        </StyledFormRow>
      </Box>
      <Box display={show ? 'flex' : 'none'} justifyContent='space-between' mt={4} width='100%'>
        <UniButton
          variant='text'
          style={{ maxWidth: '140px' }}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </UniButton>
        <UniButton color='primary' style={{ maxWidth: '160px' }} onClick={handleSubmit(onSubmit)}>
          Next Step
        </UniButton>
      </Box>
    </>
  );
}
