import React from 'react';
import { CountryName, Flag, StyledCountryTile } from './CountryTile.style';
import { BrowserRoutes } from '../../constants';

interface Props {
  name: string;
  code: string;
  continent: string;
}

export const CountryTile: React.FC<Props> = ({ name, code, continent }) => {
  const flagUrl = `https://flagcdn.com/w160/${code.toLowerCase()}.png`;

  return (
    <StyledCountryTile href={`${BrowserRoutes.Default.Country.Country(continent, name.toLowerCase().replaceAll(' ', '-'))}/about`}>
      <div>
        <Flag src={flagUrl} alt={code} />
      </div>
      <CountryName>{name}</CountryName>
    </StyledCountryTile>
  );
};

export default CountryTile;
