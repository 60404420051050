import { Grid } from '@material-ui/core';
import { useSearchPrograms } from 'api';
import { InfiniteWindowedGrid, UniProgramCard } from 'components';
import HomepageFooter from 'components/_common/homepageFooter/HomepageFooter';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { UniversalRoutes } from 'constants/browserRoutes';
import { useBreakpoints } from 'hooks';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth, useDrawers, useHeader } from 'store';
import { parseApplicationFee, parseLevelOfStudy } from 'utils/programUtils';
import { useGetProgramsNames } from '../../../api';
import { UniHeader, UniHerosection, UniPage } from '../../../components';
import { BrowserRoutes } from '../../../constants';
import '../landingStyle.css';

interface Props {
  hideNavigation?: boolean;
}

export default function LandingPrograms({ hideNavigation = false }: Props): ReactElement {
  const { t } = useTranslation('programsList');
  const history = useHistory();
  const { isMobile } = useBreakpoints();
  const { openDrawer } = useDrawers();
  const { setAfterAuthPath } = useAuth();
  const { queries } = useQueryStringParser<ProgramsFiltersQueries>();
  const { setHeader, clearHeader } = useHeader();
  const { data: programsNames } = useGetProgramsNames();
  const sort = (queries as any)?.sortBy || undefined;
  const {
    data: programs,
    isLoading,
    isFetching,
    fetchMore,
    canFetchMore,
    error: programsError,
  } = useSearchPrograms({
    filters: { ...queries, sort },
  });
  const programsFlat = useMemo(() => {
    return programs?.map(programPage => programPage.results).flat() || [];
  }, [programs]);
  const programsTotal = useMemo(() => (programs ? programs[0].total : 0), [programs]);
  const programsLeft = programsTotal - programsFlat.length;

  const openProgramFilters = () => {
    openDrawer('filter-programs');
  }
  const onClickApply = (programId: string) => {
    history.push(BrowserRoutes.Default.Auth.Login);
    setAfterAuthPath(UniversalRoutes(':base').Program.General.replace(':programId', programId));
  };

  useEffect(() => {
    setHeader({});
    return clearHeader;
  }, []);

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='title'
          title='Programs'
          onClickFilters={openProgramFilters}
          overflow='visible'
          options={programsNames?.map(item => item.label) || []}
          withFilterBar
        />
      </UniHeader>
      <UniPage>
        <Grid item xs={12}>
          <InfiniteWindowedGrid
            isFetching={isFetching}
            emptyStateType='programs'
            totalResults={programsTotal}
            fetchMore={fetchMore}
            isLoading={isLoading}
            hasMore={canFetchMore}
            remainingResults={programsLeft}
            sortOptions={[
              { value: 'NAME', label: 'By name' },
              { value: 'CHRONOLOGICAL', label: 'By date added' },
            ]}
            results={programsFlat?.map((program, index) => (
              <UniProgramCard
                showUniName
                key={`${index}-${program.id}`}
                fit
                showStatus
                program={program}
                metricsArr={[
                  {
                    label: t('headers.level_of_study'),
                    value: parseLevelOfStudy(program.levelOfStudy),
                  },
                  {
                    label: t('program.tuition_fee'),
                    value: program?.tuitionFee?.currency
                      ? `${program.tuitionFee?.currency?.amount} ${program.tuitionFee?.currency?.currencyIso} / ${program.tuitionFee?.lecturePeriod}`
                      : 'Unspecified',
                  },
                  {
                    label: t('program.application_fee'),
                    value: parseApplicationFee(program?.applicationFee),
                  },
                ]}
                onClick={() => onClickApply(program?.id || '')}
              />
            ))}
          />
        </Grid>
      </UniPage>
      {isMobile && <HomepageFooter />}
    </>
  );
}
