import styled from 'styled-components';
import { Colors, Shadows, Shape } from '../../constants';

export const StyledPrepCenterOrdersItem = styled.div`
  background-color: ${Colors.White._100};
  border-radius: ${Shape.BorderRadius.md}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
  padding-right: ${({ theme }) => theme.spacing(1)}px;
  box-shadow: ${Shadows.shadow1};

  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
  min-height: ${({ theme }) => theme.typography.pxToRem(73)};
`;
