import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniChip, UniIconWrapper, UniTypography } from 'components';
import { activitiesCategoryMap, activitiesStatusMap } from 'constants/activities';
import moment from 'moment';
import { useMemo } from 'react';
import { useAuth, useDrawers } from 'store';
import { getMetaRole } from 'utils';
import { ReactComponent as EditIcon } from '../../assets/svg/Icons/Edit.svg';
import { ReactComponent as GarbageIcon } from '../../assets/svg/Icons/Garbage.svg';
import { StyledActions, StyledItemWrapper } from './ActivityPlanListItem.style';

type Props = {
  achievement?: Components.Schemas.AchievementDto;
  todo?: Components.Schemas.ToDoDto;
  odd?: boolean;
};

const ActivityPlanListItem: React.FC<Props> = ({ odd, achievement, todo }) => {
  const { openDrawer } = useDrawers();
  const { profile } = useAuth();
  const { isAgency, isAgent, isStudent } = getMetaRole(profile.role);
  const isConsultant = isAgency || isAgent;

  const { title, status, category, showActions } = useMemo(() => {
    const activity = achievement || todo!;
    const date = !!achievement ? moment(achievement?.completedAt).format('DD.MM.YYYY') : moment(todo?.deadline).format('DD.MM.YYYY');
    const dateLabel = !!achievement ? 'Completed at' : 'Deadline';
    const creator = achievement?.creator || todo?.creator;

    let showActions = true;
    if (isStudent && creator?.id !== profile.id) showActions = false;
    if (activity.status === 'VALIDATED') showActions = false;

    return {
      ...activity,
      type: (!!achievement ? 'ACHIEVEMENT' : 'TODO') as 'ACHIEVEMENT' | 'TODO',
      parsedDate: {
        label: dateLabel,
        value: date,
      },
      showActions,
    };
  }, [todo, achievement]);

  return (
    <StyledItemWrapper odd={odd}>
      <Box
        onClick={() => {
          openDrawer('activity-item-preview', { achievement, todo });
        }}
      >
        <UniTypography variant='body2' color='primary100' weight='medium'>
          {title}
        </UniTypography>
      </Box>
      <UniChip
        label={activitiesStatusMap[status]}
        color={status === 'UPDATES_REQUIRED' && isStudent ? 'primary' : 'green'}
        arrow={status === 'UPDATES_REQUIRED' && isStudent}
      />
      <UniTypography variant='body2'>{activitiesCategoryMap[category]}</UniTypography>
      <StyledActions>
        {(showActions || isConsultant) && (
          <>
            {showActions && (
              <UniIconWrapper
                color='typographyGray100'
                size='medium'
                clearMargins
                onClick={() => {
                  openDrawer('edit-activity-item', { achievement, todo });
                }}
              >
                <EditIcon />
              </UniIconWrapper>
            )}
            <UniIconWrapper
              color='typographyGray100'
              size='medium'
              clearMargins
              onClick={() => {
                openDrawer('delete-activity-item', { achievement, todo });
              }}
            >
              <GarbageIcon />
            </UniIconWrapper>
          </>
        )}
      </StyledActions>
    </StyledItemWrapper>
  );
};

export default ActivityPlanListItem;
