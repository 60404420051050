import { AxiosError } from 'axios';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

export default function useUniversityNames(config?: QueryConfig<Error>) {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.Agency.University],
    async (_key: string) => {
      const { data } = await client!.SearchApi_searchUniversityNames();
      return data.map(item => ({ label: item.name, value: item.id }));
    },
    {
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
