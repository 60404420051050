import { Box, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GraduationHatIcon } from '../../assets/svg/Icons/GraduationHat.svg';
import { ReactComponent as HourglassIcon } from '../../assets/svg/Icons/Program/Hourglass.svg';
import { Components } from '../../client/UniClient';
import { UniTextWithIcon } from '../_common/uniMetaData/UniMetaData';
import UniTypography from '../_common/uniTypography/UniTypography';
import {
  StyledProgramDataCard,
  StyledProgramExtendedData,
  StyledProgramExtendedRequirements,
  StyledProgramExtendedWrapper,
  StyledRow,
} from './ProgramExtended.style';
import ProgramExtendedReply from './ProgramExtendedReply';

interface Props {
  program: Components.Schemas.ProgramResponse;
  handleFileIds?: Function;
  studentProfile?: Components.Schemas.StudentProfileDto;
}

export default function ProgramExtended({ program, handleFileIds, studentProfile }: Props): ReactElement {
  const { t } = useTranslation('programPage');

  const discipline = program?.discipline?.replaceAll('_', ' ').toLocaleLowerCase();

  return (
    <Grid xs={12} item>
      <ProgramExtendedReply application={program?.application} studentProfile={studentProfile} />
      <StyledProgramExtendedWrapper>
        <StyledProgramExtendedData>
          <StyledProgramDataCard>
            <Box mb={3}>
              <UniTypography color='primary100' weight='medium' variant='header3'>
                {t('labels.program_details.title')}
              </UniTypography>
            </Box>
            <StyledRow>
              <UniTextWithIcon
                iconColor='secondary100'
                Icon={<HourglassIcon />}
                label={t('labels.program_details.length')}
                value={`${program?.lengthOfStudy?.value ? `${program?.lengthOfStudy.value} ${program?.lengthOfStudy.unit}` : '-'}`}
              />
              <UniTextWithIcon
                iconColor='secondary100'
                Icon={<GraduationHatIcon />}
                label={t('labels.program_details.discipline')}
                value={discipline || '-'}
              />
              {/* <UniTextWithIcon iconColor='secondary100' Icon={<GraduationHatIcon />} label='Discipline' value={program?.specialization || '-'} /> */}
            </StyledRow>
            <StyledRow>
              <Box display='flex' flexDirection='column'>
                {!!program?.tuitionFee?.currency?.amount ? (
                  <UniTextWithIcon
                    iconColor='secondary100'
                    Icon={<HourglassIcon />}
                    label='Tuition fee'
                    value={`${program?.tuitionFee?.currency.amount} ${program?.tuitionFee?.currency.currencyIso}`}
                  />
                ) : program?.tuitionFeeDescription?.includes('http') ? (
                  <>
                    <UniTypography color='typographyGray70' variant='footnote'>
                      {t('labels.program_details.tuition_fee')}
                    </UniTypography>
                    <a href={program?.tuitionFeeDescription} target='_blank'>
                      See here
                    </a>
                  </>
                ) : (
                  program?.tuitionFeeDescription
                    ?.split('**')
                    .map((item, index) => <UniTextWithIcon label={index === 0 ? 'Tuition fee' : '-'} value={item} />) || '-'
                )}
              </Box>

              <UniTextWithIcon label={t('labels.program_details.language_of_instruction')} value={program?.languageOfInstruction || '-'} />
            </StyledRow>
          </StyledProgramDataCard>
          <StyledProgramDataCard>
            <Box mb={3}>
              <UniTypography color='primary100' weight='medium' variant='header3'>
                {t('labels.starting_dates.title')}
              </UniTypography>
            </Box>
            <StyledRow>
              <UniTextWithIcon label={t('labels.starting_dates.starting_date')} value={program?.admissionStartDate} />

              <Box display='flex' flexDirection='column'>
                {program?.lectureEndDate
                  ?.split('**')
                  .map((item, index) => (
                    <UniTextWithIcon label={index === 0 ? t('labels.starting_dates.deadlines') : '-'} value={item} />
                  )) || '-'}
              </Box>
            </StyledRow>
          </StyledProgramDataCard>
          {program?.requiredDocuments.length > 0 && (
            <StyledProgramDataCard>
              <Box mb={3}>
                <UniTypography color='secondary100' weight='medium' variant='header3'>
                  Required documents
                </UniTypography>
              </Box>
              {/* <ProgramExtendedRequiredDocument program={program} saveFileIds={handleFileIds} studentProfile={studentProfile} /> */}
            </StyledProgramDataCard>
          )}
        </StyledProgramExtendedData>
        <StyledProgramExtendedRequirements>
          <StyledProgramDataCard>
            <Box mb={3}>
              <UniTypography color='secondary100' weight='medium' variant='header3'>
                {t('labels.program_description.title')}
              </UniTypography>
            </Box>
            <UniTypography color='typographyGray100' dangerouslySetInnerHTML={program?.description} />
          </StyledProgramDataCard>
        </StyledProgramExtendedRequirements>
      </StyledProgramExtendedWrapper>
    </Grid>
  );
}
