import { TableRow } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../constants';

export const CandidateHeader = styled.div<{
  isSticky: boolean;
  changeNavbar?: boolean;
}>`
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')}
  background-color: ${Colors.White._100};
  border-radius: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
  padding: ${({ theme, changeNavbar }) => (changeNavbar ? theme.spacing(9, 2) : theme.spacing(3, 2))};
margin-top: -${({ theme, changeNavbar }) => (changeNavbar ? theme.sizes.authNavbarHeight + 40 : 0)}px;
 
`;

export const MetaData = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(10)}px;
`;

export const MetaItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledCollapsibleRow = styled(TableRow)`
  margin-bottom: 10px;
`;

export const CandidateMeta = styled.div<{ onScrollCollapse?: boolean; isMobile: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: 0.2s;
  overflow-y: hidden;
  margin: ${({ theme }) => theme.spacing(5, 0, 0, 0)};
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const CandidateIdentity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const CandidateActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const CandidateStatus = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;
