import UniButton from 'components/_common/uniButton/UniButton';
import { UniCardFooter } from 'components/_common/uniCardFooter/UniCardFooter';
import styled from 'styled-components';
import { Colors, Shadows } from '../../../constants';

export const StyledFilters = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
`;

export const StyledFilterForm = styled.div`
  flex: 1;
  overflow-y: auto;
  background-color: ${Colors.White._100};
  padding: ${({ theme }) => theme.spacing(2, 3)};
  padding-bottom: ${({ theme }) => theme.spacing(10)}px;

  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledFiltersDrawerActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(360deg, #ffffff 80.9%, rgba(255, 255, 255, 0) 100%);
`;

export const StyledFiltersButton = styled(UniButton)`
  padding: 0;
`;

export const StyledFilterFooter = styled(UniCardFooter)`
  box-shadow: ${Shadows.shadow3};
`;
