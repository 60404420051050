import { Box } from '@material-ui/core';
import { useAuthUser } from 'api';
import { StudentProfileTabsParam } from 'constants/browserRoutes';
import { AnimatePresence } from 'framer-motion';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams } from 'react-router';
import { useAuth } from 'store';
import { AnimatedTabPanel, UniHeader, UniHerosection, UniPage } from '../../../components';
import { BrowserRoutes } from '../../../constants';
import EditPublicProfileActions from './EditPublicProfileActions';
import EditPublicProfileMetrics from './EditPublicProfileMetrics';
import Achievements from './_tabs/Achievements';
import Education from './_tabs/Education';
import PersonalInfo from './_tabs/PersonalInfo';

type Params = {
  studentId?: string;
  view: StudentProfileTabsParam;
};

const EditPublicProfile = () => {
  const history = useHistory();
  const [isPreview, setIsPreview] = useState(false);
  const { view } = useParams<Params>();
  const { profile } = useAuth();
  const { data: authUser } = useAuthUser();
  const { t } = useTranslation('studentProfile');
  const [showPersonal, setShowPersonal] = useState(!!authUser?.studentProfile?.publicProfilePreferences?.publishPersonalProfile);
  const [showEducation, setShowEducation] = useState(true);
  const [showActivity, setShowActivity] = useState(true);

  const agencyId = authUser?.studentProfile?.agent?.agentProfile?.agency?.id;
  const studentId = profile.id || '';
  const tabs: StudentProfileTabsParam[] = ['info', 'education', 'activity'];
  const currentTabIndex = tabs.findIndex(item => item === view);
  const infoPathname = BrowserRoutes.Student.Profile.PublicProfile('info');
  const educationPathname = BrowserRoutes.Student.Profile.PublicProfile('education');
  const activityPathname = BrowserRoutes.Student.Profile.PublicProfile('activity');

  const heroTabs = useMemo(() => {
    let auxTabs = [];
    if (isPreview) {
      if (showPersonal)
        auxTabs.push({
          label: t('tabs.profile_info'),
          path: infoPathname,
        });
      if (showEducation)
        auxTabs.push({
          label: t('tabs.education'),
          path: educationPathname,
        });
      if (showActivity)
        auxTabs.push({
          label: t('tabs.activity_plan'),
          path: activityPathname,
        });
    } else {
      auxTabs = [
        {
          label: t('tabs.profile_info'),
          path: infoPathname,
        },
        {
          label: t('tabs.education'),
          path: educationPathname,
        },
        {
          label: t('tabs.activity_plan'),
          path: activityPathname,
        },
      ];
    }

    if (isPreview) {
      if (showPersonal) history.push(infoPathname);
      else if (showEducation) history.push(educationPathname);
      else if (showActivity) history.push(activityPathname);
    }

    return auxTabs;
  }, [showPersonal, showEducation, showActivity, isPreview]);

  if (!authUser) return <></>;

  return (
    <>
      <EditPublicProfileActions isPreview={isPreview} setIsPreview={setIsPreview} />
      <UniHeader>
        <Box height='96px' />
        <UniHerosection
          title={`${authUser?.firstName + ' ' + authUser?.lastName}`}
          universityName={`${authUser?.firstName + ' ' + authUser?.lastName}`}
          headerVariant='avatarTitle'
          profileImageSrc={authUser?.profileImageFile?.location}
          MetricsComponent={<EditPublicProfileMetrics isPreview={isPreview} user={authUser} />}
          tabs={heroTabs}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={infoPathname}>
              <AnimatedTabPanel
                Content={<PersonalInfo isPreview={isPreview} user={authUser} setShowPersonal={setShowPersonal} />}
                index={0}
                currentItem={currentTabIndex}
                absoluteDirection={-1}
              />
            </Route>
            <Route path={educationPathname}>
              <AnimatedTabPanel
                Content={<Education studentId={studentId} isPreview={isPreview} agencyId={agencyId} setShowEducation={setShowEducation} />}
                index={1}
                currentItem={currentTabIndex}
              />
            </Route>
            <Route path={activityPathname}>
              <AnimatedTabPanel
                Content={<Achievements studentId={studentId} isPreview={isPreview} setShowActivity={setShowActivity} />}
                index={2}
                currentItem={currentTabIndex}
                absoluteDirection={1}
              />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default EditPublicProfile;
