import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledProgramName, StyledUniversityName, SuccessIllustration } from '../StudentEnrollmentDrawer.style';

interface Props {
  program: Components.Schemas.ProgramResponse;
}

export default function StudentEnrollmentDrawerStep2({ program }: Props): ReactElement {
  const { t } = useTranslation('studentEnrollmentDrawer');

  return (
    <Box height='100%' display='flex' flexDirection='column' justifyContent='center'>
      <SuccessIllustration />
      <Box mb={3}>
        <UniTypography variant='header1' color='secondary100' weight='medium'>
          {t('congrats_enrolled')}
        </UniTypography>
      </Box>
      <UniTypography color='typographyGray100'>
        {t('congrats_message.part1')}
        <StyledProgramName>{program.name}</StyledProgramName>
        {t('congrats_message.part2')}
        <StyledUniversityName>{program.universityName}</StyledUniversityName>
        {t('congrats_message.part3')}
      </UniTypography>
    </Box>
  );
}
