import React from 'react';
import { Circle, Shadow, StyledLogoName, StyledWrapper } from './YouniBounceLoader.style';

export type Color = 'white' | 'primary' | 'secondary' | 'typographyGray70';

export type LoaderProps = {
  size: number;
  color: string | Color;
  pulseSpeed: number;
};

const YouniBounceLoader: React.FC<Partial<LoaderProps>> = ({ color = 'primary', size = 20, pulseSpeed = 1 }) => {
  return (
    <StyledWrapper>
      <Circle />
      <Circle />
      <Circle />
      <Shadow />
      <Shadow />
      <Shadow />
      <StyledLogoName />
    </StyledWrapper>
  );
};

export default YouniBounceLoader;
