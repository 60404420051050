import { FormControl, FormControlProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface StyledFormControlProps {
  backgroundColor?: any;
  hasLabel?: boolean;
}

export const Container = styled(
  ({ backgroundColor, hasLabel, ...rest }: StyledFormControlProps & Omit<FormControlProps, keyof StyledFormControlProps>) => (
    <FormControl {...rest} />
  ),
)`
  display: grid;
  justify-items: start;
  width: inherit;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  min-height: 103px;
  grid-template-rows: ${({ hasLabel }) => (!hasLabel ? `56px 1fr` : '21px 1fr')};
  ${({ hasLabel }) => !hasLabel && `padding-top: 25px;`}
`;

export const Label = styled.div`
  max-height: 21px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const Error = styled.div`
  display: flex;
  max-height: 18px;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const StyledIcon = styled.div<{
  color?: string;
}>`
  display: flex;
  justify-items: center;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing(5)}px;
  width: ${({ theme }) => theme.spacing(3)}px;
  height: ${({ theme }) => theme.spacing(3)}px;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
`;

export const StyledAlertIcon = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  width: ${({ theme }) => theme.spacing(2)}px;
  height: ${({ theme }) => theme.spacing(2)}px;
`;
