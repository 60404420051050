import { NewHomepageFooter } from 'components';
import { Gradients } from 'constants/uiConfig';
import { ReactElement } from 'react';
import styled from 'styled-components';
import Ambassadors from './sections/Ambassadors';
import Articles from './sections/Articles';
import Herosection from './sections/Herosection';
import Newsletter from './sections/Newsletter';
import Partners from './sections/Partners';
import Preps from './sections/Preps';
import ContinentsList from './sections/ContinentsList';

const StyledCombinedWrapper = styled.div`
  background: ${Gradients.Primary._0d};
  padding-top: ${({ theme }) => theme.spacing(4)}px;

  width: calc(100% + ${({ theme }) => theme.spacing(4)}px);
  margin: 0 ${({ theme }) => theme.spacing(-2)}px;
  padding: 0 ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: calc(100% + ${({ theme }) => theme.spacing(6)}px);
    margin: 0 ${({ theme }) => theme.spacing(-3)}px;
    padding: 0 ${({ theme }) => theme.spacing(3)};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: calc(100% + ${({ theme }) => theme.spacing(12)}px);
    margin: 0 ${({ theme }) => theme.spacing(-6)}px;
    padding: 0 ${({ theme }) => theme.spacing(6)};
  }
`;

export default function LandingHome(): ReactElement {
  return (
    <>
      <Herosection />
      <ContinentsList />
      <Preps />
      <Partners />
      {/*<StyledCombinedWrapper>*/}
      {/*  <Articles />*/}
      {/*  <Ambassadors />*/}
      {/*</StyledCombinedWrapper>*/}
      <Newsletter />
      <NewHomepageFooter />
    </>
  );
}
