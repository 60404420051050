import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { BrowserRoutes } from '../../../constants';
import { AnimatedTabPanel, UniHeader, UniHerosection, UniPage } from 'components';
import { AnimatePresence } from 'framer-motion';
import Products from './_tabs/Products';
import Packages from './_tabs/Packages';
import PurchasedPrepPacks from './_tabs/PurchasedPrepPacks';

interface Props {}

export const AdminPrepCenter: React.FC<Props> = () => {
  const { t } = useTranslation('prepCenter');
  const { url } = useRouteMatch();
  const productsPath = BrowserRoutes.Admin.PrepCenter.Products;
  const packagesPath = BrowserRoutes.Admin.PrepCenter.Packages;
  const purchasedPrepPacksPath = BrowserRoutes.Admin.PrepCenter.PurchasedPrepPacks;

  const tabsMap = [productsPath, packagesPath, purchasedPrepPacksPath];
  const currentIndex = tabsMap.findIndex(item => item === url);

  return (
    <>
      <UniHeader>
        <UniHerosection
          headerVariant='title'
          title={t('title')}
          metrics={[]}
          tabs={[
            { label: t('tabs.products'), path: productsPath },
            { label: t('tabs.packages'), path: packagesPath },
            { label: t('tabs.purchased_prep_packs'), path: purchasedPrepPacksPath },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={productsPath}>
              <AnimatedTabPanel currentItem={currentIndex} index={0} Content={<Products />} />
            </Route>
            <Route path={packagesPath}>
              <AnimatedTabPanel currentItem={currentIndex} index={1} Content={<Packages />} />
            </Route>
            <Route path={purchasedPrepPacksPath}>
              <AnimatedTabPanel currentItem={currentIndex} index={2} Content={<PurchasedPrepPacks />} />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default AdminPrepCenter;
