import { UniCardFooter, UniTypography } from 'components';
import styled from 'styled-components';
import { Colors } from '../../../constants';

export const StyledOnboardTypography = styled(UniTypography)`
  margin: ${({ theme }) => theme.spacing(5, 0, 3)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: ${({ theme }) => theme.spacing(3, 0, 2)};
  }
`;

export const CustomTab = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)}px;
`;

export const CustomTabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)}px;
`;

export const OnboardWrapper = styled.div`
  background-color: ${Colors.White._100};
  margin: ${({ theme }) => theme.spacing(3)}px auto;
  max-width: 782px;
  /* padding: ${({ theme }) => theme.spacing(6, 5)}; */
  border: ${Colors.Gray1._70};
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(3, 2)};
    border-radius: 6px;
  }
`;

export const StyledUniCardFooter = styled(UniCardFooter)`
  margin-left: -${({ theme }) => theme.spacing(3)}px;
  margin-bottom: -${({ theme }) => theme.spacing(4)}px;
  width: calc(100% + 48px);
`;
