import { Colors } from '../../../constants';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Size } from './UniProgressSteps';
export const StyledProgressBarWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(3, 7, 2, 7)};
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
`;

export const StyledProgressBarItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  text-align: center;
`;

export const StyledProgressBarNumber = styled.div<{ active: boolean }>`
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing(5)}px;
  height: ${({ theme }) => theme.spacing(5)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ active }) => (active ? Colors.Primary._100 : Colors.Primary._10)};
  transition: background-color 0.2s;
`;

export const StyledProgressBarText = styled.div`
  position: absolute;
  bottom: calc(-100%);
`;

export const StyledProgressBarDivider = styled.div<{ stepsNumber: number; size: Size }>`
  width: ${({ stepsNumber }) => 50 / stepsNumber}%;
  margin-top: ${({ theme, size }) => (size === 'xl' || size === 'xxl' ? theme.spacing(3.8) : theme.spacing(2.5))}px;
  height: ${({ theme }) => theme.spacing(0.25)}px;
  background-color: ${Colors.Typography.Gray_30};
`;
