import styled from 'styled-components';
import { UniWorkshopCardFooter } from './UniWorkshopCardFooter';

export const StyledUniCardFooter = styled(UniWorkshopCardFooter)`
  border-bottom-left-radius: ${({ theme }) => theme.spacing(1.5)}px;
  border-bottom-right-radius: ${({ theme }) => theme.spacing(1.5)}px;
  position: absolute;
  bottom: 0;
  left: 0;
`;
