import styled from 'styled-components';
import { ReactComponent as Illustration } from '../../../assets/landing/illustrations/Asset 6.svg';
// import {ReactComponent as StyledBadgeIcon} from ""
import { ReactComponent as YouniLogoBadge } from '../../../assets/svg/youniLogoBadge.svg';
import { Colors, Sizes } from '../../../constants/uiConfig';

export const StyledFooterContainer = styled.div<{ stretch?: boolean; isLoggedIn?: boolean }>`
  display: flex;
  flex-direction: column;
  width: calc(100% + ${({ theme }) => theme.spacing(12)}px);
  margin-left: -${({ theme }) => theme.spacing(6)}px;
  padding-left: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: -${({ theme }) => theme.spacing(5)}px;
  background-color: ${Colors.White._100};
  z-index: 15;

  ${({ theme }) => `${theme.breakpoints.down('md')}{
  width: calc(100% + ${theme.spacing(6)}px);
  margin-left: -${theme.spacing(2)}px};
  padding-left:${theme.spacing(2)}px};
  margin-bottom: -${theme.spacing(6)}px};}`}
`;

export const StyledLink = styled.a`
  :hover {
    text-decoration: none;
  }
`;

export const StyledUpperFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* padding: ${({ theme }) => theme.spacing(6)}px; */
  width: 100%;
  max-width: ${Sizes.MaxWidthWrapper}px;
  margin: auto;
  padding-top: ${({ theme }) => theme.spacing(6)}px;
  padding-bottom: ${({ theme }) => theme.spacing(6)}px;

  ${({ theme }) => `${theme.breakpoints.down('md')}{
    flex-direction:column;
    align-items: center;
  }`};
`;

export const StyledBottomFooter = styled.div`
  background-color: ${Colors.Secondary._100};
  box-shadow: none;
  border-radius: 24px 0px 0px 0px;

  padding: ${({ theme }) => theme.spacing(2, 3)};

  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${({ theme }) => `${theme.breakpoints.down('md')}{
    flex-direction: column;
    }`}
`;

export const StyledYouniLogoBadge = styled(YouniLogoBadge)`
  ${({ theme }) => `${theme.breakpoints.down('md')}{
    margin-top: ${theme.spacing(5)}px;
    max-width: 110px;
    max-height: 220px;
    }`}
`;

export const StyledIllustration = styled(Illustration)`
  width: 100%;
  max-width: 450px;
  min-width: 300px;
  max-height: 300px;

  ${({ theme }) => `${theme.breakpoints.down('md')}{
    display:none;
    max-height: 220px;
  }`}
`;
