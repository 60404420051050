import { Box } from '@material-ui/core';
import useAddEducationHistoryItem from 'api/students/useAddEducationHistoryItem';
import useSubscribeToMarketing from 'api/students/useSubscribeToMarketing';
import { Components } from 'client/UniClient';
import { UniCard, UniLogoWithTitle, UniPageLoader, UniPageTitle } from 'components';
import UniProgressBar from 'components/_common/uniProgressBar/UniProgressBar';
import { motion } from 'framer-motion';
import moment from 'moment';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useNotOnboardedUser from '../../../api/auth/useNotOnboardedUser';
import { BrowserRoutes } from '../../../constants';
import Step1 from './_steps/Step1';
import Step2, { Step2FormData } from './_steps/Step2';
import Step3, { Step3FormData } from './_steps/Step3';
import Step4, { Step4FormData } from './_steps/Step4';
import Step5, { Step5FormData } from './_steps/Step5';
import Step6, { Step6FormData } from './_steps/Step6';
import Step7, { Step7FormData } from './_steps/Step7';
import Step8 from './_steps/Step8';
import Step9 from './_steps/Step9';

export type OnboardForm = Partial<
  Components.Schemas.CompleteStudentOnboardingRequest & {
    passport: File[];
    passportURL: string;
    email: string;
    hasPassword: boolean;
    newsletter?: boolean;
  }
>;

export type OnboardParams = {
  type: 'social' | 'regular' | 'invite' | 'patch' | 'link';
  token: string;
};

export type OnboardFormState = Step2FormData &
  Step3FormData &
  Step4FormData &
  Step5FormData &
  Step6FormData &
  Step7FormData & { token?: string };

type OnboardFormNextType = Step2FormData | Step3FormData | Step4FormData | Step5FormData | Step6FormData | Step7FormData;

export default function StudentOnboard(): ReactElement {
  const { t } = useTranslation('forms');
  const history = useHistory();
  const [formState, setFormState] = useState<Partial<OnboardFormState>>({});
  const [user, setUser] = useState<Components.Schemas.UserResponse>();
  const [subscribeToMarketing] = useSubscribeToMarketing();
  const [postEducationHistoryItem] = useAddEducationHistoryItem();

  const steps = [
    '',
    'Personal info',
    'Contact',
    'Educational Background',
    'Educational Preferences',
    'Accomplishments',
    'Parents',
    'Summary',
  ];

  const [tabValue, setTabValue] = useState(0);

  const { token, type } = useParams<OnboardParams>();

  const showProgressSteps = tabValue > 0 && tabValue <= 7;
  const isSocial = type === 'social';
  const isInvite = type === 'invite' || type === 'link';
  const isPatch = type === 'patch';
  const isByLink = type === 'link';
  const isRegular = type === 'regular';

  const hasSession = isSocial || isPatch || isByLink;

  const {
    data: onboardingUser,
    status: onboardingUserStatus,
    error,
  } = useNotOnboardedUser(
    { token },
    {
      enabled: !hasSession,
    },
  );
  const notOnboardedUserErrorCode = error?.response?.data?.message;

  let uniPage = document.getElementById('uni-page-container');
  const scrollToTop = () => {
    uniPage?.scrollTo(0, 0);
  };

  const nextStep = (formData?: OnboardFormNextType) => {
    setFormState(prev => ({ ...prev, ...formData }));
    setTabValue(prevTabValue => prevTabValue + 1);
    scrollToTop();
  };

  const prevStep = () => {
    setTabValue(prevTabValue => prevTabValue - 1);
    scrollToTop();
  };

  const onSave = (userData?: Components.Schemas.UserResponse, newsletter?: boolean) => {
    if (userData) {
      setUser(userData);
      if (newsletter) subscribeToMarketing({ studentId: userData?.id! });
      const agencyId = userData?.studentProfile?.agent?.agentProfile?.agency?.id || '';
      const studentId = userData?.id || '';
      const didGraduate = moment(formState.graduationDate).isBefore(moment());
      postEducationHistoryItem({
        body: {
          schoolName: formState.schoolName,
          graduationDate: formState.graduationDate,
          graduationLevel: formState.levelOfStudy,
          schoolAddress: { country: formState.educationCountry },
          didGraduate,
        },
        params: { agencyId, studentId },
      });
    }
    setTabValue(8);
  };

  const setNotOnboarderUser = () => {
    if (onboardingUserStatus !== 'success') return;
    setFormState(prevOnboardForm => {
      return {
        ...prevOnboardForm,
        firstName: onboardingUser?.firstName,
        lastName: onboardingUser?.lastName,
        phone: onboardingUser?.phone,
        phonePrefix: onboardingUser?.phonePrefix,
        highSchoolYear: onboardingUser?.studentProfile?.highSchoolYear,
        email: onboardingUser?.email,
        levelOfStudy: onboardingUser?.studentProfile?.highSchool?.name ? 'HIGH_SCHOOL' : undefined,
        educationCountry: onboardingUser?.studentProfile?.highSchool?.address.country,
        educationCity: onboardingUser?.studentProfile?.highSchool?.address.city,
        schoolName: onboardingUser?.studentProfile?.highSchool?.name,
        hasPassword: isInvite,
        token,
      };
    });
  };

  const isOver18 = useMemo(() => {
    if (!formState.dateOfBirth) return false;
    const eighteenYearsAgo = moment().subtract(18, 'years');
    return moment(formState.dateOfBirth).isBefore(eighteenYearsAgo);
  }, [formState.dateOfBirth]);

  useEffect(() => setNotOnboarderUser(), [onboardingUserStatus]);

  if (onboardingUserStatus === 'loading') {
    return <UniPageLoader />;
  }

  if (onboardingUserStatus === 'error' || notOnboardedUserErrorCode === 400) {
    history.push(BrowserRoutes.Default.InvalidToken);
  }

  return (
    <Box mt={3}>
      <UniLogoWithTitle title={t('titles.onboard_title')} />

      <Box maxWidth='680px' mx='auto' mt={10}>
        <UniCard border size='L' overflow='none'>
          {showProgressSteps && (
            <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }}>
              {tabValue && (
                <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }}>
                  <UniPageTitle title={steps[tabValue]} size='M' disableBar />
                </motion.div>
              )}
              <Box mt={1} mb={2}>
                <UniProgressBar size='s' progress={tabValue * 10 + 30} />
              </Box>
            </motion.div>
          )}
          {tabValue === 0 && <Step1 isReferral={isByLink} nextStep={nextStep} />}
          {tabValue === 1 && (
            <Step2
              defaultValues={{ ...formState, ...JSON.parse(localStorage.getItem('onboardFormDataStep2') || '{}') }}
              nextStep={nextStep}
            />
          )}
          {tabValue === 2 && (
            <Step3
              defaultValues={{ ...formState, ...JSON.parse(localStorage.getItem('onboardFormDataStep3') || '{}') }}
              prevStep={prevStep}
              nextStep={nextStep}
              isByLink={isByLink}
              hasPassword={isInvite || isByLink}
            />
          )}
          {tabValue === 3 && (
            <Step4
              defaultValues={{ ...formState, ...JSON.parse(localStorage.getItem('onboardFormDataStep4') || '{}') }}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          )}
          {tabValue === 4 && (
            <Step5
              defaultValues={{ ...formState, ...JSON.parse(localStorage.getItem('onboardFormDataStep5') || '{}') }}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          )}
          {tabValue === 5 && (
            <Step6
              defaultValues={{ ...formState, ...JSON.parse(localStorage.getItem('onboardFormDataStep6') || '{}') }}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          )}
          {tabValue === 6 && (
            <Step7
              defaultValues={{ ...formState, ...JSON.parse(localStorage.getItem('onboardFormDataStep7') || '{}') }}
              prevStep={prevStep}
              nextStep={nextStep}
              isOver18={isOver18}
            />
          )}
          {tabValue === 7 && <Step8 formState={formState} prevStep={prevStep} onSave={onSave} showTerms={!isRegular} />}
          {tabValue === 8 && <Step9 isInviteByLink={isByLink} user={user} />}
        </UniCard>
      </Box>
    </Box>
  );
}
