import { Box } from '@material-ui/core';
import React from 'react';
import UniTypography from '../uniTypography/UniTypography';
import { StyledIconWrapperr } from './UniMetrics.style';

export interface UniMetricsBaseProps {
  variant: 'iconed' | 'text';
  label: string;
  Icon: React.ReactNode;
  value: string;
  link?: string;
  size?: 'small' | 'large';
}

export const UniMetricsBase = ({ variant, label, Icon, value, link, size = 'large' }: UniMetricsBaseProps) => {
  return (
    <Box display='flex' alignItems='center'>
      {variant === 'iconed' && <StyledIconWrapperr size={size}>{Icon}</StyledIconWrapperr>}
      <Box ml={variant === 'text' ? 0 : size === 'large' ? 1.5 : 1}>
        <UniTypography variant={size === 'large' ? 'subheader' : 'footnote'} color='typographyGray100' weight='regular'>
          {label}
        </UniTypography>
        {!!link ? (
          <a href={link} target='_blank'>
            <UniTypography variant='subheader' color='primary100' weight='medium'>
              {value}
            </UniTypography>
          </a>
        ) : (
          <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
            {value}
          </UniTypography>
        )}
      </Box>
    </Box>
  );
};
