import { Components, Paths } from 'client/UniClient';
import { useMutation, useQueryCache } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

interface UseUpdateStudentAgentParams {
  body: Components.Schemas.UpdateStudentAgentRequest;
  params: Paths.StudentApiUpdateStudentAgent.PathParameters;
}

const useUpdateStudentAgent = () => {
  const { client } = useClient();
  const queryCache = useQueryCache();

  return useMutation(({ body, params }: UseUpdateStudentAgentParams) => client!.StudentApi_updateStudentAgent(params, body), {
    onSuccess() {
      queryCache.invalidateQueries(CacheKeys.Admin.Candidates);
    },
  });
};

export default useUpdateStudentAgent;
