import { AxiosError } from 'axios';
import { queryCache, useMutation } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

const useDeleteFile = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: string) => {
      return client!.FileApi_deleteDocument({ fileId: payload });
    },
    {
      onSuccess: async response => {
        queryCache.invalidateQueries(CacheKeys.Profile.Extended);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default useDeleteFile;
