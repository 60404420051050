import styled from 'styled-components';
import UsaImage from 'assets/landing/continents/usa.jpg';
import EuropeImage from 'assets/landing/continents/europe.jpg';
import CanadaImage from 'assets/landing/continents/canada.jpg';
import AustraliaImage from 'assets/landing/continents/australia.jpg';

const backgrounds: { [key: string]: any } = {
  usa: UsaImage,
  europe: EuropeImage,
  canada: CanadaImage,
  australia: AustraliaImage,
};

export const StyledContinentTile = styled.a<{ continentImage: string }>`
  text-decoration: none !important;
  cursor: pointer;
  font-weight: 600;
  grid-area: span 1 / span 1 / span 1 / span 1;
  grid-column-gap: 75px;
  grid-row-gap: 75px;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  transition: all .25s;
  display: flex;
  box-shadow: inset 0 0 0 10rem rgba(79, 35, 95, 0);
  background-color: #4f235f;
  background-image: linear-gradient(rgba(79, 35, 95, .85), rgba(198, 27, 117, .5)), url(${({ continentImage }) => backgrounds[continentImage]});
  background-position: 0 0, 50%;
  background-size: auto, cover;

  :hover {
    box-shadow: inset 0 0 0 10rem rgba(79, 35, 95, .5);
  }
`;
