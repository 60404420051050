import { AxiosError } from 'axios';
import { getBaseURL } from 'constants/browserRoutes';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Components } from '../../client/UniClient';
import { useAuth, useClient } from '../../store';

type Payload = Partial<Components.Schemas.CompleteStudentOnboardingRequest>;

const useCompleteStudentOnboardingSocial = () => {
  const history = useHistory();
  const { client } = useClient();
  const { afterAuthPath, setAfterAuthPath } = useAuth();
  return useMutation(
    async (payload: Payload) => {
      return client!
        .AuthApi_completeSocialOnboarding(undefined, payload as Components.Schemas.CompleteStudentOnboardingRequest)
        .then(({ data }) => {
          if (afterAuthPath) {
            history.push(afterAuthPath.replace(':base', getBaseURL(data?.role)));
            setAfterAuthPath(undefined);
          }
          return client!.UserApi_getMyUser();
        });
    },

    {
      onSuccess: async response => {
        // setSession(response.data);
        //
        // const { data } = await client!.UserApi_getMyUser();
        //
        // setProfile(data);
      },
      onError: (error: AxiosError) => {
        // history.push(BrowserRoutes.Default.InvalidToken);
      },
    },
  );
};
export default useCompleteStudentOnboardingSocial;
