import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { UniPageTitle } from 'components';

const PassionProject: React.FC = () => {
  const { t } = useTranslation('studentAppTracker');

  return (
    <>
      <Box mb={3}>
        <UniPageTitle title={t('tabs.passion_project')} />
      </Box>
    </>
  );
};

export default PassionProject;
