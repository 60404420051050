import { queryCache, useMutation } from 'react-query';
import { useClient } from 'store';
import { CacheKeys } from '../../constants';

interface Params {
  studentId: string;
  serviceOfferings: {
    quantity: number;
    serviceOfferingId: string;
  }[];
}

const usePostServiceOrder = () => {
  const { client } = useClient();

  return useMutation(
    async ({ studentId, serviceOfferings }: Params) => {
      const serviceOrderResponse = await client?.ServiceOrderApi_createServiceOrder(undefined, { studentId });
      const serviceOrderId = serviceOrderResponse?.data.id || '';

      let responses = [];
      for (let i = 0; i < serviceOfferings.length; i++) {
        const serviceOfferingResponse = await client?.ServiceOrderApi_setServiceOrderItem({ serviceOrderId }, serviceOfferings[i]);
        responses.push(serviceOfferingResponse);
      }

      return responses.pop()?.data;
    },
    {
      onSuccess() {
        queryCache.invalidateQueries(CacheKeys.Student.Orders);
      },
    },
  );
};

export default usePostServiceOrder;
