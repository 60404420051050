import { FormControl, FormControlProps, OutlinedInput, TextField } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export const StyledInput = styled(OutlinedInput)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
