import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniButton, UniCard, UniDivider, UniPageTitle, UniTypography } from 'components';
import ActivityPlanListItem from 'components/activityPlanListItem/ActivityPlanListItem';
import { GuidingState } from 'components/emptyState/GuidingState';
import UniCreatableSelect from 'components/_common/uniCreatableSelect/UniCreatableSelect';
import { ActivitiesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ActivitiesFilters';
import { activitiesOptions, applicationStatusOptions } from 'constants/activities';
import { useBreakpoints } from 'hooks';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAuth, useDrawers } from 'store';
import { OptionType } from 'types';
import { getMetaRole } from 'utils';
import { ReactComponent as View1Icon } from '../../assets/svg/Icons/View-1.svg';
import { ReactComponent as View2Icon } from '../../assets/svg/Icons/View-2.svg';
import { StyledCategoryItem, StyledFiltersBar } from './ActivityPlanFilterBox.style';

export type ActivitiesListType = 'list' | 'cards';

interface Props {
  studentId: string;
  achievements: Components.Schemas.AchievementDto[];
  fetchMoreAchievements: () => void;
  todos: Components.Schemas.ToDoDto[];
  fetchMoreTodos: () => void;
  achievementsLeft: number;
  achievementsIsFetching: boolean;
  todosLeft: number;
  todosIsFetching: boolean;
  onChangeListType: (value: ActivitiesListType) => void;
}

const ActivityPlanFilterBox: React.FC<Props> = ({
  studentId,
  achievementsIsFetching,
  achievementsLeft,
  fetchMoreAchievements,
  fetchMoreTodos,
  todosIsFetching,
  todosLeft,
  children,
  achievements,
  todos,
  onChangeListType,
  ...rest
}) => {
  const { openDrawer } = useDrawers();
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile.role);
  const { isMobile } = useBreakpoints();
  const [viewType, setViewType] = useState<ActivitiesListType>('cards');
  const { control, watch } = useForm<ActivitiesFiltersQueries>();
  const { pushQueriesToUrl } = useQueryStringParser<ActivitiesFiltersQueries>();
  const hasActivities = true;

  const { status, type, creatorId, category } = watch();

  const featureDescription = isStudent
    ? 'Here you can create your activity plan, to improve your chances of getting admitted to your dream university.'
    : 'Here you can create the activity plan for your candidate, to improve their chances of getting admitted to their dream university.';

  const onToggleListView = (value: ActivitiesListType) => () => {
    setViewType(value);
    onChangeListType(value);
  };

  const onClickAddActivity = () => openDrawer('add-activity-item', { studentId });

  const types: OptionType[] = [
    { label: 'To Dos', value: 'TODO' },
    { label: 'Achievements', value: 'ACHIEVEMENT' },
  ];

  const otherCreator = isStudent
    ? { label: 'Added by Agent', value: profile.studentProfile?.agent?.id }
    : { label: 'Added by Student', value: studentId };

  const creators: OptionType[] = [{ label: 'Added by Me', value: profile.id }, { ...otherCreator }];

  const addAllOption = (arr: OptionType[]) => [{ label: 'All', value: undefined }, ...arr];

  const showAchievements = !type?.includes('TODO') && !!achievements.length;
  const showTodos = !type?.includes('ACHIEVEMENT') && !!todos.length;

  useEffect(() => {
    pushQueriesToUrl({ type, status, creatorId, category });
  }, [type, status, creatorId, category]);

  return (
    <UniCard size='S' border overflow='none' {...rest}>
      <UniPageTitle size={isMobile ? 'S' : 'M'} title='Activity Plan' />
      <Box mt={3} />
      <UniTypography variant='subheader' weight='medium' color='typographyGray100'>
        {featureDescription}
      </UniTypography>
      <Box mt={3} />
      <UniButton onClick={onClickAddActivity}>Add new Activity Item</UniButton>
      <UniDivider height={9} />

      {!hasActivities && (
        <Box mt={isMobile ? 2 : 3}>
          <GuidingState type='activities' rows={3} />
        </Box>
      )}
      {hasActivities && (
        <>
          <Box display='flex' alignItems='center' justifyContent='space-between' gridColumnGap={16}>
            <UniTypography variant='header3' weight='semi-bold' color='typographyBlack100'>
              Action Items
            </UniTypography>
            <Box display='flex'>
              <UniButton
                size='xsmall'
                color={viewType === 'cards' ? 'primaryFade' : 'fadeGray'}
                startIcon={<View1Icon />}
                onClick={onToggleListView('cards')}
                borderRadius={[1, 0, 0, 1]}
              />
              <UniButton
                size='xsmall'
                color={viewType === 'list' ? 'primaryFade' : 'fadeGray'}
                onClick={onToggleListView('list')}
                startIcon={<View2Icon />}
                borderRadius={[0, 1, 1, 0]}
              />
            </Box>
          </Box>
          <StyledFiltersBar>
            <Controller
              control={control}
              name='category'
              render={({ value, onChange }) => (
                <UniCreatableSelect
                  label='Category'
                  placeholder='Select category'
                  options={addAllOption(activitiesOptions)}
                  getOptionLabel={option => option.label}
                  value={activitiesOptions.find(item => item.value === value)}
                  onChange={option => onChange(option?.value)}
                />
              )}
            />
            <Controller
              control={control}
              name='type'
              render={({ value, onChange }) => (
                <UniCreatableSelect
                  label='Item type'
                  placeholder='Select type'
                  options={addAllOption(types)}
                  getOptionLabel={option => option.label}
                  value={types.find(item => item.value === value)}
                  onChange={option => onChange(option?.value)}
                />
              )}
            />
            <Controller
              control={control}
              name='creatorId'
              render={({ value, onChange }) => (
                <UniCreatableSelect
                  label='Added by'
                  placeholder='Select creator'
                  options={addAllOption(creators)}
                  getOptionLabel={option => option.label}
                  value={creators.find(item => item.value === value)}
                  onChange={option => onChange(option?.value)}
                />
              )}
            />
            <Controller
              control={control}
              name='status'
              render={({ value, onChange }) => (
                <UniCreatableSelect
                  label='Status'
                  placeholder='Select status'
                  options={addAllOption(applicationStatusOptions)}
                  getOptionLabel={option => option.label}
                  value={applicationStatusOptions.find(item => item.value === value)}
                  onChange={option => onChange(option?.value)}
                />
              )}
            />
          </StyledFiltersBar>
        </>
      )}
      {viewType === 'list' && (
        <Box mb={3}>
          <UniDivider height={3} />
        </Box>
      )}
      {viewType === 'list' && (
        <>
          <StyledCategoryItem>
            <UniTypography variant='body1' weight='medium' color='white100'>
              Achievements
            </UniTypography>
          </StyledCategoryItem>
          {achievements.map((achievement, index) => (
            <ActivityPlanListItem achievement={achievement} odd={!!(index % 2)} key={achievement?.id} />
          ))}
          {showAchievements && achievementsLeft > 0 && (
            <Box width='100%' display='flex' justifyContent='center'>
              <UniButton variant='secondary' color='fadeGray' loading={achievementsIsFetching} onClick={() => fetchMoreAchievements()}>
                Show more
              </UniButton>
            </Box>
          )}
          <StyledCategoryItem>
            <UniTypography variant='body1' weight='medium' color='white100'>
              Todos
            </UniTypography>
          </StyledCategoryItem>
          {todos.map((todo, index) => (
            <ActivityPlanListItem todo={todo} odd={!!(index % 2)} key={todo?.id} />
          ))}
          {showTodos && todosLeft > 0 && (
            <Box width='100%' display='flex' justifyContent='center'>
              <UniButton variant='secondary' color='fadeGray' loading={todosIsFetching} onClick={() => fetchMoreTodos()}>
                Show more
              </UniButton>
            </Box>
          )}
        </>
      )}
    </UniCard>
  );
};

export default ActivityPlanFilterBox;
