import React from 'react';
import { useHistory } from 'react-router-dom';
import { Components } from 'client/UniClient';
import { ReactComponent as TrophyIcon } from 'assets/svg/Icons/Trophy.svg';
import { ReactComponent as ShoppingIcon } from 'assets/svg/Icons/Payments.svg';
import { BrowserRoutes } from '../../constants';
import { UniButton, UniIconWrapper } from '../index';
import {
  StyledPurchasedPrepPackCardActionsWrapper,
  StyledPurchasedPrepPackCardCategoryLabel,
  StyledPurchasedPrepPackCardCategoryLabelWrapper,
  StyledPurchasedPrepPackCardContent,
  StyledPurchasedPrepPackCardContentWrapper,
  StyledPurchasedPrepPackCardImage,
  StyledPurchasedPrepPackCardSeparator,
  StyledPurchasedPrepPackCardSessionCountContentCounter,
  StyledPurchasedPrepPackCardSessionCountContentTitle,
  StyledPurchasedPrepPackCardSessionCountContentWrapper,
  StyledPurchasedPrepPackCardSessionCountIconWrapper,
  StyledPurchasedPrepPackCardSessionCountWrapper,
  StyledPurchasedPrepPackCardTitle,
  StyledPurchasedPrepPackCardTitleWrapper,
  StyledPurchasedPrepPackCardWrapper,
} from './UniPurchasedPrepPackCard.style';

type PurchasedPrepPackDto = Components.Schemas.PurchasedPrepPackDto;

interface Props {
  purchasedPrepPack: PurchasedPrepPackDto;
}

export const UniPurchasedPrepPackCard: React.FC<Props> = ({ purchasedPrepPack }) => {
  const history = useHistory();

  return (
    <StyledPurchasedPrepPackCardWrapper>
      <StyledPurchasedPrepPackCardImage
        src={purchasedPrepPack.prepPack.product.logoFile?.location}
        alt={`Product ${purchasedPrepPack.prepPack.product.id} logo`}
      />
      <StyledPurchasedPrepPackCardContentWrapper>
        <StyledPurchasedPrepPackCardContent>
          <StyledPurchasedPrepPackCardCategoryLabelWrapper>
            <StyledPurchasedPrepPackCardCategoryLabel>
              {purchasedPrepPack.prepPack.product.category}
            </StyledPurchasedPrepPackCardCategoryLabel>
          </StyledPurchasedPrepPackCardCategoryLabelWrapper>
          <StyledPurchasedPrepPackCardTitleWrapper>
            <StyledPurchasedPrepPackCardTitle>
              {purchasedPrepPack.prepPack.description}
            </StyledPurchasedPrepPackCardTitle>
            <StyledPurchasedPrepPackCardSeparator>–</StyledPurchasedPrepPackCardSeparator>
            <StyledPurchasedPrepPackCardSessionCountWrapper>
              <StyledPurchasedPrepPackCardSessionCountIconWrapper>
                <UniIconWrapper color='secondary100' size='medium'>
                  <TrophyIcon />
                </UniIconWrapper>
              </StyledPurchasedPrepPackCardSessionCountIconWrapper>
              <StyledPurchasedPrepPackCardSessionCountContentWrapper>
                <StyledPurchasedPrepPackCardSessionCountContentTitle>
                  Sessions left
                </StyledPurchasedPrepPackCardSessionCountContentTitle>
                <StyledPurchasedPrepPackCardSessionCountContentCounter>
                  {purchasedPrepPack.remainingSessions}/{purchasedPrepPack.prepPack.sessions}
                </StyledPurchasedPrepPackCardSessionCountContentCounter>
              </StyledPurchasedPrepPackCardSessionCountContentWrapper>
            </StyledPurchasedPrepPackCardSessionCountWrapper>
          </StyledPurchasedPrepPackCardTitleWrapper>
          <StyledPurchasedPrepPackCardActionsWrapper>
            <UniButton
              color='primary'
              onClick={() => {
                history.push(BrowserRoutes.Student.PrepCenter.CheckIn.replace(
                  ':prepPackId',
                  purchasedPrepPack.prepPack.id!,
                ))
              }}
            >
              Check-in
            </UniButton>
            <UniButton
              color='primary'
              onClick={() => {
                history.push(BrowserRoutes.Student.PrepCenter.Product.replace(
                  ':productId',
                  purchasedPrepPack.prepPack.product.id!,
                ));
              }}
              startIcon={(
                <UniIconWrapper color='white100' size='medium'>
                  <ShoppingIcon />
                </UniIconWrapper>
              )}
            >
              Buy more
            </UniButton>
            <UniButton
              color='primaryFade'
              onClick={() => {
                history.push(BrowserRoutes.Student.PrepCenter.Product.replace(
                  ':productId',
                  purchasedPrepPack.prepPack.product.id!,
                ));
              }}
            >
              View curriculum
            </UniButton>
          </StyledPurchasedPrepPackCardActionsWrapper>
        </StyledPurchasedPrepPackCardContent>
      </StyledPurchasedPrepPackCardContentWrapper>
    </StyledPurchasedPrepPackCardWrapper>
  );
};

export default UniPurchasedPrepPackCard;
