import styled from 'styled-components';
import { ReactComponent as ApplicationSuccessIllustration } from '../../../assets/svg/Illustrations/ApplicationSuccessIllustration.svg';

export const StyledApplicationSuccessIllustration = styled(ApplicationSuccessIllustration)`
  width: 100%;
  height: auto;
`;

export const StyledQRCodeImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: contain;
`;
