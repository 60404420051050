import { motion } from 'framer-motion';
import React, { ReactElement } from 'react';

interface Props {
  currentItem: number;
  index: number;
  Content: React.ReactNode;
  absoluteDirection?: 1 | -1;
}

export default function AnimatedTabPanel({ index, currentItem, Content, absoluteDirection }: Props): ReactElement {
  const active = index === currentItem;
  const direction = absoluteDirection ? absoluteDirection : index > currentItem ? 1 : -1;
  const animateVariant = {
    initial: {
      opacity: 0,
      x: direction * 200,
    },
    animate: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: -direction * 200,
    },
  };

  return active ? (
    <motion.div key={index} {...animateVariant} style={{ height: '100%', width: '100%', overflow: 'visible', position: 'relative' }}>
      {Content}
    </motion.div>
  ) : (
    <></>
  );
}
