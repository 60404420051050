import { Box } from '@material-ui/core';
import usePostQRAchievementScan from 'api/qr-tokens/usePostQRAchievementScan';
import { UniButton, UniCard, UniDivider, UniIconWrapper, UniMetrics, UniPageLoader, UniPageTitle, UniTypography } from 'components';
import { activitiesCategoryMap } from 'constants/activities';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useAuth, useSnackbar } from 'store';
import styled from 'styled-components';
import { ReactComponent as HourglassIcon } from '../../assets/svg/Icons/Hourglass.svg';
import { ReactComponent as LinkIcon } from '../../assets/svg/Icons/Link.svg';
import { ReactComponent as OngoingIcon } from '../../assets/svg/Icons/Ongoing.svg';
import { BrowserRoutes } from '../../constants';

type Props = {};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
`;

const StyledUniCard = styled(UniCard)`
  width: auto;
  width: 100%;
  max-width: 600px;
  height: auto;
  position: relative;
`;

const mockData = {
  title: `Sample achievement template`,
  description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also th`,
  links: ['https://youth.younichoice.com/event/cs-summer-camp/'],
  category: 'INTERNSHIPS_WORK',
  studentId: '',
};

const QRScanAchievement = (props: Props) => {
  const [postQRScan, { isLoading, data, error }] = usePostQRAchievementScan();
  const { openSnackbar } = useSnackbar();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const { pathname } = useLocation();
  const { profile, setAfterAuthPath } = useAuth();
  const isLoggedIn = profile.id;

  const onGoToActivity = () => {
    history.push(BrowserRoutes.Student.Profile.ActivityPlan);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      setAfterAuthPath(pathname);
      history.push(BrowserRoutes.Default.Auth.Login);
    } else {
      postQRScan({ params: { qrToken: token } });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (error?.response?.data?.message === 'Achievement already exists') {
      openSnackbar('achievement-already-added');
      history.push(BrowserRoutes.Student.Profile.ActivityPlan);
    }
  }, [error]);

  if (isLoading || !data?.data) return <UniPageLoader />;
  const { description, title, links, category } = data?.data;

  return (
    <StyledWrapper>
      <StyledUniCard size='L' border>
        <Box pt={6} />
        <UniPageTitle title='Activity added to public profile' />

        <Box mt={3} display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <UniTypography variant='body1' color='typographyBlack100' weight='semi-bold'>
              {title}
            </UniTypography>
          </Box>
        </Box>
        <Box mb={5} />
        <UniMetrics
          variant='iconed'
          size='large'
          values={[
            { Icon: <OngoingIcon />, label: 'Category', value: activitiesCategoryMap[category as keyof typeof activitiesCategoryMap] },
            { Icon: <OngoingIcon />, label: 'Type', value: 'Achievement' },
            { Icon: <HourglassIcon />, value: moment(new Date()).format('MMMM d, YYYY') },
          ]}
        />
        <Box mb={5} />
        <UniTypography variant='subheader' color='typographyBlack100'>
          {description}
        </UniTypography>

        <Box my={2}>
          <UniDivider height={3} />
        </Box>

        {!!links?.length && (
          <>
            <UniTypography variant='subheader' color='typographyBlack100' weight='medium'>
              Links
            </UniTypography>
            {links.map((link, index) => (
              <Box mb={1} display='flex' onClick={() => window.open(link, '_blank')} key={index}>
                <UniIconWrapper>
                  <LinkIcon />
                </UniIconWrapper>
                <Box ml={1} />
                <UniTypography variant='subheader'>{link}</UniTypography>
              </Box>
            ))}
          </>
        )}
        <Box my={2}>
          <UniDivider height={3} />
        </Box>
        <Box display='flex' width='100%' justifyContent='flex-end'>
          <UniButton size='medium' color='primaryFade' loading={isLoading} onClick={onGoToActivity}>
            Go to Activity plan
          </UniButton>
        </Box>
      </StyledUniCard>
    </StyledWrapper>
  );
};

export default QRScanAchievement;
