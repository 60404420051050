import styled from 'styled-components';

export const StyledOrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: flex-start;
  }
`;

export const StyledOrderFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  ${({ theme }) => `${theme.breakpoints.up('sm')}{
    margin-top: ${theme.spacing(3)}px;
  }`};
  ${({ theme }) => `${theme.breakpoints.down('sm')} {
  justify-content: center;
  }`}
`;

export const StyledActionsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: right;

  ${({ theme }) => `${theme.breakpoints.down('sm')} {
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    text-align: left;
    margin-top: ${theme.spacing(2)}px;
  }`}
`;
