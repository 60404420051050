import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniModalDrawerButtons, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniFeeInput from 'components/_inputs/uniFeeInput/UniFeeInput';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import { theme } from 'theme';
import useEditServiceOffering from '../../../../api/agency/useEditServiceOffering';

interface Props {
  service?: Components.Schemas.ServiceOfferingDto;
}

export default function EditServiceOffering({ service }: Props): ReactElement {
  const { t } = useTranslation('partnerServicesDrawers');
  const { closeDrawer } = useDrawers();
  const [editService, { isLoading }] = useEditServiceOffering({ serviceOfferingId: service?.id! });
  const { register, control, handleSubmit, setValue, errors } = useForm<Components.Schemas.CurrencyRequest>({
    defaultValues: service?.price,
  });

  const onClose = () => closeDrawer();

  const onEdit = (formData: Components.Schemas.CurrencyRequest) => {
    editService(
      { price: { amount: Number(formData?.amount), currencyIso: formData?.currencyIso } },
      {
        onSuccess() {
          onClose();
        },
      },
    );
  };

  const onSubmit = () => handleSubmit(onEdit)();

  return (
    <>
      <UniModalDrawerContent>
        <Box display='flex' flexDirection='column' style={{ gap: theme.spacing(2) }}>
          <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
            {t('add_service.set_fee')}
          </UniTypography>
          <UniFeeInput
            name='amount'
            control={control}
            label={t('add_service.fee_input_label')}
            defaultCurrency={service?.price?.currencyIso}
            setValue={setValue}
            error={!!errors?.amount?.message}
            helperText={errors?.amount?.message}
            inputRef={register({ required: `${t('add_service.amount_error')}` })}
          />
        </Box>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: onClose, label: t('buttons.cancel') }}
        PrimaryButton={{ onClick: onSubmit, loading: isLoading, label: t('buttons.edit') }}
      />
    </>
  );
}
