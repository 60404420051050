import { UniTypography } from 'components';
import { Wrapper } from './ItemTypeCard.style';

interface Props {
  label: string;
  description: string;
  onClick: () => void;
}

const ItemTypeCard: React.FC<Props> = ({ label, description, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <UniTypography variant='body1' weight='semi-bold'>
        {label}
      </UniTypography>
      <UniTypography variant='body2'>{description}</UniTypography>
    </Wrapper>
  );
};

export default ItemTypeCard;
