import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniTypography } from 'components';
import React from 'react';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';

interface Props {
  service: Components.Schemas.ServiceOptionDto;
}

const Step1: React.FC<Props> = ({ service }) => {
  const { t } = useTranslation('partnerServicesDrawers');
  return (
    <Box display='flex' flexDirection='column' style={{ gap: theme.spacing(2) }}>
      <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
        {t('add_service.description')}
      </UniTypography>
      <UniTypography variant='body1' color='secondary100' weight='medium'>
        {service?.name}
      </UniTypography>
      <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
        {t('add_service.proceed')}
      </UniTypography>
    </Box>
  );
};

export default Step1;
