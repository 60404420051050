import { Components } from 'client/UniClient';
import React from 'react';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { UniAutocomplete } from 'components';
import { candidateStatus, CandidateStatusNew } from '../../../constants/candidateStatus';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useUpdateCandidateStatus from 'api/agency/useUpdateCandidateStatus';

interface Props {
  candidate: Components.Schemas.UserResponse;
  close: Function;
}

type UpdateStatusInputs = {
  status: CandidateStatusNew;
};

const UpdateCandidateStatus: React.FC<Props> = ({ candidate, close }) => {
  const { t } = useTranslation('updateCandidateStatus');
  const { control, handleSubmit, errors } = useForm<UpdateStatusInputs>({});
  const [updateStatus] = useUpdateCandidateStatus({ studentId: candidate?.id! });
  const studentStatus = candidate?.studentProfile?.candidateStatus || 'NEW_LEAD';
  const onSubmit = (formData: UpdateStatusInputs) => {
    updateStatus(formData, {
      onSuccess() {
        close();
      },
    });
  };
  return (
    <>
      <UniModalDrawerContent>
        <Controller
          name='status'
          control={control}
          defaultValue={studentStatus}
          rules={{ required: `${t('required_status')}` }}
          render={({ value, onChange }) => (
            <UniAutocomplete
              label={t('label')}
              handleChange={(event: any, value: any) => onChange(value?.value || '')}
              defaultValue={candidateStatus.find(item => item?.value === studentStatus)}
              options={candidateStatus}
              getOptionLabel={option => option?.text}
              error={!!errors?.status?.message}
              helperText={errors?.status?.message}
            />
          )}
        ></Controller>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: close, label: t('buttons.cancel') }}
        PrimaryButton={{
          onClick: handleSubmit(onSubmit),
          label: t('buttons.update'),
        }}
      />
    </>
  );
};

export default UpdateCandidateStatus;
