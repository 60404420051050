import { AgentsSort } from 'components/agentsList/AgentsList';
import { OrderType } from 'components/_common/uniSortButton/UniSortButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAgents } from 'api';
import { ReactComponent as AddIcon } from 'assets/svg/Icons/Add.svg';
import { Components } from 'client/UniClient';
import { StickyListFilterBar, UniButton, UniIconWrapper, UniPage } from 'components';
import { useAuth, useDrawers } from 'store';
import { BrowserRoutes } from '../../../constants';
import MyAgentsListItem from '../agents/myAgentsList/MyAgentsListItem';
import { ListContainer } from './AdminAgentsList.style';
import { MyAgentsList } from './myAgentsList/MyAgentsList';

export const AgencyAdminAgents = () => {
  const { t } = useTranslation('agencyAdminAgents');
  const history = useHistory();
  const { profile } = useAuth();
  const [selectedAgents, setSelectedAgents] = useState<Components.Schemas.UserDto[]>([]);
  const [order, setOrder] = useState<OrderType>();
  const [sort, setSort] = useState<AgentsSort>();
  const { openDrawer } = useDrawers();

  const {
    data: agents,
    status: agentsStatus,
    fetchMore,
    canFetchMore,
    error: agentsError,
  } = useAgents({
    order: order,
    sort: sort,
  });
  const agentsLoading = agentsStatus === 'loading';
  const agentsFlat = agents?.map(agentPage => agentPage.results).flat() || [];
  const agentsTotal = agents ? agents[0].total : 0;
  const totalResultsLabel = agentsTotal === 1 ? agentsTotal + ' ' + t('result.ifOne') : agentsTotal + ' ' + t('result.ifMore');

  const selectAgent = (agent: Partial<Components.Schemas.UserDto>) => {
    setSelectedAgents(prevSelectedAgents => {
      const index = prevSelectedAgents.findIndex(item => item.id === agent.id);
      if (index === -1) {
        return [...prevSelectedAgents, agent as Components.Schemas.UserDto];
      } else {
        return prevSelectedAgents.filter(item => item.id !== agent.id);
      }
    });
  };

  const selectAllAgents = (value: boolean) => {
    if (value) {
      setSelectedAgents(agentsFlat);
    } else {
      setSelectedAgents([]);
    }
  };

  const handleSort = (newSortParam: AgentsSort) => {
    const sortType = newSortParam === sort ? (order === 'ASC' ? 'DESC' : 'ASC') : 'ASC';

    setOrder(sortType);
    setSort(newSortParam);
  };

  const openFiltersAgentsDrawer = () => openDrawer('filter-agents', { size: 'small' });

  const openInviteAgentDrawer = () => openDrawer('invite-agent', { size: 'main' });

  const openResendAgentDrawer = (agent: Components.Schemas.UserResponse) => openDrawer('resend-agent', { size: 'main', agent });

  return (
    <UniPage>
      <ListContainer>
        <StickyListFilterBar
          listTitle={t('list.title')}
          totalResultsLabel={totalResultsLabel}
          searchHint={t('list.search_hint')}
          // onClickFilter={openFiltersAgentsDrawer}
          ActionButton={
            <UniButton
              onClick={openInviteAgentDrawer}
              fullWidth={false}
              color='primary'
              startIcon={
                <UniIconWrapper color='white100'>
                  <AddIcon />
                </UniIconWrapper>
              }
            >
              {t('buttons.add_agent')}
            </UniButton>
          }
        />
        <MyAgentsList
          hasMore={!!canFetchMore}
          fetchMore={fetchMore}
          selectAllAgents={selectAllAgents}
          selectedAgents={selectedAgents.length}
          length={agentsTotal}
          isLoading={agentsLoading}
          sortType={sort}
          order={order}
          onSort={handleSort}
        >
          {agentsFlat.map((agent: any, index: any) => (
            <MyAgentsListItem
              key={index}
              selected={selectedAgents.includes(agent)}
              selectAgent={selectAgent}
              agent={agent}
              setAgentForResend={openResendAgentDrawer}
              onClick={(id: string) => {
                history.push(BrowserRoutes.Agent.Agent(profile.agentProfile?.agency?.id!, id));
              }}
            />
          ))}
        </MyAgentsList>
      </ListContainer>
    </UniPage>
  );
};
