import { Badge, BadgeProps, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import ComponentColors, { TComponentColors } from '../../../constants/componentColors';
import { Colors } from '../../../constants/uiConfig';
import { Size } from './UniIconWrapper';

const SizeMap = {
  xsmall: 8,
  small: 12,
  main: 16,
  medium: 24,
  medium2: 32,
  large: 48,
} as const;

const DotPositionMap = {
  top: {
    xsmall: 3,
    small: 4,
    main: 5,
    medium: 5,
    medium2: 5,
    large: 7,
  },
  left: {
    xsmall: -3,
    small: -4,
    main: -4,
    medium: -5,
    medium2: -5,
    large: -5,
  },
  size: {
    xsmall: 4,
    small: 6,
    main: 8,
    medium: 11,
    medium2: 11,
    large: 13,
  },
} as const;

type IconProps = {
  size: Size;
  color: TComponentColors;
  opacity: number;
};

export const Icon = styled.div<IconProps>`
  width: ${({ size }) => SizeMap[size]}px;
  height: ${({ size }) => SizeMap[size]}px;
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
  path {
    fill: ${({ color }) => ComponentColors[color]};
  }
`;

interface StyledBadgeProps {
  size: Size;
  withBackground: boolean;
  ccolor: TComponentColors;
}

export const StyledBadge = styled(
  ({ size, withBackground, ccolor, ...rest }: StyledBadgeProps & Omit<BadgeProps, keyof StyledBadgeProps>) => <Badge {...rest} />,
)`
  background-color: ${({ withBackground, ccolor }) => (withBackground ? `${ComponentColors[ccolor]}22` : 'transparent')};
  padding: ${({ withBackground, theme }) => (withBackground ? theme.spacing(1, 1) : 0)};
  border-radius: ${({ withBackground, theme, size }) => (withBackground ? (size === 'large' ? theme.spacing(2) : theme.spacing(1)) : 0)}px;

  .MuiBadge-dot {
    border-radius: 50%;
    background: ${Colors.Primary._100};
    width: ${({ size }) => DotPositionMap.size[size]}px;
    height: ${({ size }) => DotPositionMap.size[size]}px;
    top: ${({ size }) => DotPositionMap.top[size]}px;
    left: ${({ size }) => DotPositionMap.left[size]}px;
  }

  .MuiBadge-badge {
    border: 2px solid ${Colors.White._100};
  }
`;

export const StyledIconButton = styled(IconButton)<{ disableHover?: boolean; marginClearFilter?: boolean; clearMargins?: boolean }>`
  margin-left: ${({ marginClearFilter }) => marginClearFilter && '-8'}px;
  margin-right: ${({ marginClearFilter }) => marginClearFilter && '-8'}px;
  ${({ clearMargins }) => clearMargins && `margin: -12px;`}
  &:hover {
    background-color: ${({ disableHover }) => disableHover && 'transparent'};
  }
`;
