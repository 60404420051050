import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniTypography } from 'components';
import { default as React, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  university: Components.Schemas.UniversityResponse;
}

export default function UniversityPageGeneralAbout({ university }: Props): ReactElement {
  const { t } = useTranslation('universityPage');
  //to be replaced with university.about
  const hasDescription = false;
  return (
    <>
      {university?.description ? (
        <UniTypography color='typographyGray100' dangerouslySetInnerHTML={university?.description} />
      ) : (
        <Box height='151px' pt={7.5} textAlign='center'>
          <UniTypography color='typographyGray100' variant='header3'>
            {t('general.sections.no_description')}
          </UniTypography>
        </Box>
      )}{' '}
    </>
  );
}
