import { Box } from '@material-ui/core';
import { AnimatedTabPanel, UniButton, UniPageTitle } from 'components';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useHeader } from 'store';
import { StyledApplicationsHeader } from './AgencyStudentApplications.style';
import AllPrograms from './_tabs/AllPrograms';
import AnsweredPrograms from './_tabs/Answered';
import OngoingPrograms from './_tabs/Ongoing';
import SavedPrograms from './_tabs/Saved';
import SuggestedPrograms from './_tabs/Suggested';

interface Props {}

const AgencyStudentApplications = (props: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const { setHeader, clearHeader } = useHeader();
  useEffect(() => {
    setHeader({});
    return clearHeader;
  }, []);

  const tabsMap = [
    { label: 'All', onClick: () => setCurrentIndex(0) },
    { label: 'Saved', onClick: () => setCurrentIndex(1) },
    { label: 'Ongoing', onClick: () => setCurrentIndex(2) },
    { label: 'Answered', onClick: () => setCurrentIndex(3) },
    { label: 'Suggested', onClick: () => setCurrentIndex(4) },
  ];

  return (
    <>
      <StyledApplicationsHeader>
        <UniPageTitle title='All applications' />
        <Box display='flex'>
          {tabsMap.map((item, index) => (
            <UniButton
              borderRadius={[index === 0 ? 1 : 0, index === 4 ? 1 : 0, index === 4 ? 1 : 0, index === 0 ? 1 : 0]}
              variant='main'
              color={currentIndex === index ? 'primary' : 'primaryFade'}
              onClick={item.onClick}
            >
              {item.label}
            </UniButton>
          ))}
        </Box>
      </StyledApplicationsHeader>
      <AnimatePresence>
        <AnimatedTabPanel Content={<AllPrograms />} index={0} currentItem={currentIndex} />
        <AnimatedTabPanel Content={<SavedPrograms />} index={1} currentItem={currentIndex} />
        <AnimatedTabPanel Content={<OngoingPrograms />} index={2} currentItem={currentIndex} />
        <AnimatedTabPanel Content={<AnsweredPrograms />} index={3} currentItem={currentIndex} />
        <AnimatedTabPanel Content={<SuggestedPrograms />} index={4} currentItem={currentIndex} />
      </AnimatePresence>
    </>
  );
};

export default AgencyStudentApplications;
