import styled from 'styled-components';
import { theme } from 'theme';
import { Colors, Shadows } from '../../../constants';
import { UniCardFooter } from '../uniCardFooter/UniCardFooter';

export const StyledDrawerWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: 0.2s;

  background-color: transparent;
  z-index: 999;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ isOpen }) => (isOpen ? 'pointer-events: initial;' : 'pointer-events: none;')}
  ${({ isOpen }) => isOpen && `background-color: ${Colors.Typography.Gray_70};`}
`;

export const StyledFixedDrawer = styled.div<{ isOpen: boolean; side?: 'left' | 'right' }>`
  position: fixed;
  top: 0;
  ${({ side }) => (side === 'left' ? 'left: 0;' : 'right: 0;')}
  overflow: hidden;
  background-color: ${Colors.White._100};

  transition: 0.2s;

  width: 100%;
  height: 100%;
  max-width: 0;

  display: flex;
  flex-direction: column;
  box-shadow: ${Shadows.shadow2};

  ${({ isOpen }) => isOpen && 'max-width: 480px'};
  ${theme.breakpoints.down('sm')} {
    ${({ isOpen }) => isOpen && 'max-width: 100%'};
  }
`;

export const StyledModalDrawerActions = styled(UniCardFooter)`
  box-shadow: ${Shadows.shadow4};
  justify-content: flex-start;
`;

export const UniModalDrawerHeaderWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 3, 2, 3)};
  background-color: ${Colors.Gray2._40};

  ${theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(3, 2, 2, 2)};
  }
`;

export const UniModalDrawerContent = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${theme.spacing(4, 3)};
  display: flex;
  flex-direction: column;
  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(3, 2)};
  }
`;
