import { Grid } from '@material-ui/core';
import { useAuth, useDrawers } from 'store';
import { Nationality } from 'types';
import { useSearchPrograms } from 'api';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { BrowserRoutes } from 'constants/browserRoutes';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { countries, CountriesType } from 'constants/countries';
import { TContinent } from 'components/uniWorldMap/UniWorldMap';
import { ProgramsFiltersQueries } from 'components/_drawers/filtersDrawer/_types/ProgramsFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { parseLevelOfStudy, parseApplicationFee } from 'utils/programUtils';
import { AnimatedTabPanel, InfiniteWindowedGrid, UniProgramFilterBar, UniCountryHeader, UniPage, UniProgramCard } from 'components';
import About from './_tabs/About';
import Counsellors from './_tabs/Counsellors';

const countryFromName = (country: string) =>
  countries.find(c => c.name.toLowerCase().replaceAll(/[-\s]/g, '') === country.replaceAll(/[-\s]/g, '')) as CountriesType | undefined;

const continentNameMap: { [key in TContinent]: string } = {
  world: 'World',
  europe: 'Europe',
  asia: 'Asia',
  africa: 'Africa',
  'north-america': 'North America',
  'south-america': 'South America',
  oceania: 'Oceania',
};

const CountryPage = () => {
  const { t } = useTranslation('programsList');
  const { url } = useRouteMatch();
  const { country, continent } = useParams<{ country: string; continent: TContinent }>();
  const { queries } = useQueryStringParser<ProgramsFiltersQueries>();
  const { openDrawer } = useDrawers();
  const { setAfterAuthPath } = useAuth();
  const history = useHistory();

  const [flag, setFlag] = useState('');

  const sort = (queries as any)?.sortBy || undefined;
  const countryName = countryFromName(country)?.name || '';
  const countryCode = (countryFromName(country)?.code as Nationality) || undefined;
  const continentPath = BrowserRoutes.Default.Continent(continent);
  const countryPath = BrowserRoutes.Default.Country.Country(continent, country);
  const currentRoute = url === countryPath ? 0 : url === `${countryPath}/about` ? 1 : 2;

  const {
    data: programs,
    isLoading,
    isFetching,
    fetchMore,
    canFetchMore,
  } = useSearchPrograms({
    filters: {
      ...queries,
      country: countryCode ? [countryCode] : undefined,
      sort,
    },
  });

  const programsFlat = useMemo(() => {
    return programs?.map(programPage => programPage.results).flat() || [];
  }, [programs]);
  const programsTotal = useMemo(() => (programs ? programs[0].total : 0), [programs]);
  const programsLeft = programsTotal - programsFlat.length;

  const openProgramFilters = () => openDrawer('filter-programs', { size: 'small' });

  const getFlag = async () => {
    const code = countryFromName(country)?.code;
    if (code) {
      setFlag(`https://flagcdn.com/w160/${code.toLowerCase()}.png`);
    }
  };

  useEffect(() => {
    getFlag();
  }, [country]);

  return (
    <>
      <UniCountryHeader
        profileImageSrc={flag}
        iconSrc={flag}
        title={countryName}
        thirdBtnLink={`${continentPath}`}
        thirdBtnLabel={continentNameMap[continent]}
        fourthBtnLabel={countryName}
        tabs={[
          { label: 'Programs', path: `${countryPath}` },
          { label: 'About', path: `${countryPath}/about` },
          { label: 'Counsellors', path: `${countryPath}/counsellors` },
        ]}
      />
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route exact path={countryPath}>
              <AnimatedTabPanel
                Content={
                  <>
                    <UniProgramFilterBar onClickFilter={openProgramFilters} noCountry showDiscipline />
                    <Grid item xs={12}>
                      <InfiniteWindowedGrid
                        isFetching={isFetching}
                        emptyStateType='programs'
                        fetchMore={fetchMore}
                        isLoading={isLoading}
                        hasMore={canFetchMore}
                        remainingResults={programsLeft}
                        results={programsFlat?.map((program, index) => (
                          <UniProgramCard
                            showUniName
                            key={`${index}-${program.id}`}
                            fit
                            showStatus
                            program={program}
                            metricsArr={[
                              {
                                label: t('headers.level_of_study'),
                                value: parseLevelOfStudy(program.levelOfStudy),
                              },
                              {
                                label: t('program.tuition_fee'),
                                value: program?.tuitionFee?.currency
                                  ? `${program.tuitionFee?.currency?.amount} ${program.tuitionFee?.currency?.currencyIso} / ${program.tuitionFee?.lecturePeriod}`
                                  : 'Unspecified',
                              },
                              {
                                label: t('program.application_fee'),
                                value: parseApplicationFee(program?.applicationFee),
                              },
                            ]}
                            onClick={() => {
                              history.push(BrowserRoutes.Default.Auth.Login);
                              setAfterAuthPath(BrowserRoutes.Student.Program.General.replace(':programId', program.id!));
                            }}
                          />
                        ))}
                      />
                    </Grid>
                  </>
                }
                index={0}
                currentItem={currentRoute}
                absoluteDirection={-1}
              />
            </Route>
            <Route path={`${countryPath}/about`}>
              <AnimatedTabPanel
                Content={<About country={country} countryName={countryName} />}
                index={1}
                currentItem={currentRoute}
                absoluteDirection={1}
              />
            </Route>
            <Route path={`${countryPath}/counsellors`}>
              <AnimatedTabPanel Content={<Counsellors />} index={2} currentItem={2} absoluteDirection={-1} />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default CountryPage;
