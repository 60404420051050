import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import useDeleteTaskDocument from 'api/tasks/useDeleteTaskDocument';
import usePatchTask from 'api/tasks/usePatchTask';
import usePostTaskDocument from 'api/tasks/usePostTaskDocument';
import useTask from 'api/tasks/useTask';
import { useDrawers } from 'store';
import { UniModalDrawerContent } from '../../_common/uniModalDrawer/UniModalDrawer.style';
import { UniTextField } from '../../index';
import UniSelect from '../../_inputs/uniSelect/UniSelect';

interface Props {
  userType: 'student' | 'agent';
  taskId: string;
}

interface FormType {
  status: 'NOT_STARTED' | 'IN_PROGRESS' | 'IN_REVIEW' | 'DONE';
  title: string;
  description: string;
  deadline: string;
  link: string;
}

export const EditTaskDrawer: React.FC<Props> = ({ userType, taskId }) => {
  const { data: task, refetch } = useTask({ taskId });
  const { control, register, handleSubmit, errors, setValue } = useForm<FormType>({
    defaultValues: {
      status: task?.status ?? 'NOT_STARTED',
      title: task?.title ?? '',
      description: task?.description ?? '',
      deadline: task?.deadline ?? '',
      link: task?.links?.[0] ?? '',
    },
  });

  const statusOptions = [
    { value: 'NOT_STARTED', label: 'NOT STARTED' },
    { value: 'IN_PROGRESS', label: 'IN PROGRESS' },
    { value: 'IN_REVIEW', label: 'IN REVIEW' },
    { value: 'DONE', label: 'DONE' },
  ];

  const [patchTask] = usePatchTask();
  const [postDocument] = usePostTaskDocument();
  const [deleteDocument] = useDeleteTaskDocument();
  const { setProgress, closeDrawer } = useDrawers();
  const documentUploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (task != null) {
      setValue('status', task.status);
      setValue('title', task.title);
      setValue('description', task.description);
      setValue('deadline', task.deadline);
      setValue('link', task.links?.[0]);
    }
  }, [task]);

  const updateTask = ({ title, description, deadline, link, status }: FormType) => {
    const fullFormData = {
      taskId,
      title,
      deadline,
      description,
      ...(status ? { status } : {}),
      applicationId: task?.applicationId!,
      links: [link],
    };
    patchTask(fullFormData, {
      onSuccess() {
        setProgress(100);
        closeDrawer();
      },
    }).then();
  };

  const onSubmit = () => {
    handleSubmit(formData => {
      updateTask(formData);
    })();
  };

  const onSelectFile = (file: File) => {
    postDocument({ taskId, file }, {
      onSuccess() {
        refetch().then();
      },
      onError(err) {
        console.log('Error uploading document', err);
      },
    }).then();
  };

  const onDeleteFile = (fileId: string) => {
    deleteDocument({ taskId, fileId }, {
      onSuccess() {
        refetch().then();
      },
      onError(err) {
        console.log('Error deleting document', err);
      },
    }).then();
  };

  return task == null ? (
    <></>
  ) : (
    <>
      <UniModalDrawerContent>
        <UniTextField
          label='Task title'
          placeholder='Add a title for the task'
          inputRef={register({
            required: 'Title is required',
            validate: (value: string) => value.trim() !== '',
          })}
          name='title'
          error={!!errors.title?.message}
          helperText={errors.title?.message}
          fullWidth
        />
        <UniTextField
          label='Task description'
          placeholder='Add a description for the task'
          inputRef={register()}
          name='description'
          error={!!errors.description?.message}
          helperText={errors.description?.message}
          fullWidth
        />
        <UniTextField
          label='Deadline'
          placeholder='YYYY-MM-DD'
          inputRef={register({
            required: 'Deadline must be a valid date',
            validate: (value: string) => value.length === 10 && value[4] === '-' && value[7] === '-',
          })}
          name='deadline'
          error={!!errors.deadline?.message}
          helperText={errors.deadline?.message}
          fullWidth
        />
        <UniTextField
          label='Link'
          placeholder='www.example.com'
          inputRef={register()}
          name='link'
          error={!!errors.link?.message}
          helperText={errors.link?.message}
          fullWidth
        />
        {userType === 'agent' && (
          <Controller
            control={control}
            name='status'
            render={({ value, onChange }) => (
              <UniSelect
                label='Status'
                placeholder='Select status'
                options={statusOptions}
                value={statusOptions.find(item => item.value === value)}
                onChange={option => onChange(option?.value)}
              />
            )}
          />
        )}
        <ul>
          {task.documents?.map(document => (
            <li key={document.id}>
              {document.originalName}
              <Button onClick={() => {
                onDeleteFile(document.id);
              }}>
                Delete
              </Button>
            </li>
          ))}
        </ul>
        <input type='file' hidden ref={documentUploadRef} onChange={e => e.target?.files?.length && onSelectFile(e.target.files[0])} />
        <Button onClick={() => {
          documentUploadRef.current?.click();
        }}>
          Upload document
        </Button>
      </UniModalDrawerContent>
      <Button onClick={onSubmit}>Save changes</Button>
    </>
  );
};

export default EditTaskDrawer;
