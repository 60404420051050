import { AxiosError } from "axios";
import { useMutation, useQueryCache } from "react-query";
import { Components } from "../../client/UniClient";
import { CacheKeys } from "../../constants";
import { useClient } from "../../store";

type Params = {
  agencyId: string;
  agentId: string;
};
type Payload = Components.Schemas.UpdateAgentRequest;

export default function usePutAgent(params: Params) {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AgencyApi_updateAgent(params, payload);
    },
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries([CacheKeys.Agency.Agent, params]);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
                 const { message } = error?.response?.data || {};
                 const generalMessage =
                   "Something went wrong, please try again in a little while";
                 Snackbar(message || generalMessage, {
                   variant: "error"
                 }); 
                 */
      },
    }
  );
}
