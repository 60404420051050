import { Box, Fade } from '@material-ui/core';
import { useSignup } from 'api';
import privacyDoc from 'assets/docs/Youni Privacy Policy.pdf';
import termsDoc from 'assets/docs/T&Cs Uniapply Platform.pdf';
import { ReactComponent as ErrorSvg } from 'assets/svg/Icons/Error.svg';
import { UniButton, UniCheckbox, UniIconWrapper, UniOAuth, UniTextField, UniTypography } from 'components';
import { Error, StyledAlertIcon } from 'components/_inputs/uniTextField/UniTextField.style';
import { AuthForm, StyledChildrenWrapperTop } from 'components/_layouts/authLayout/AuthLayout.style';
import { useBreakpoints, useErrorHandler } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isValidEmailRegex } from 'utils/validators';
import { ReactComponent as EmailIcon } from '../../../assets/svg/Icons/Candidates.svg';
import { ReactComponent as LockIcon } from '../../../assets/svg/Icons/Lock.svg';
import { BrowserRoutes, Colors } from '../../../constants';

interface Inputs {
  email: string;
  password: string;
  terms: boolean;
  newsletter: boolean;
}

const Signup = () => {
  const { isMobile } = useBreakpoints();
  const { register, control, handleSubmit, errors } = useForm<Inputs>();
  const { t } = useTranslation('authSignup');
  const history = useHistory();
  const [signup, { isLoading, error }] = useSignup();
  const signupError = useErrorHandler(error || undefined);

  const onSubmit = (formData: Inputs) => {
    signup(formData, {
      onSuccess(response) {
        history.push(
          BrowserRoutes.Default.Auth.EmailSent.replace(':type', 'signup')
            .replace(':email', formData.email)
            .replace(':token', response.data.token),
        );
      },
    });
  };

  // const onBackToMagicLink = () => history.push(BrowserRoutes.Default.Auth.MagicLinkSignup);
  const onSignUp = handleSubmit(onSubmit);
  const onGoToLogin = () => history.push(BrowserRoutes.Default.Auth.Login);

  return (
    <>
      <StyledChildrenWrapperTop>
        <UniOAuth google facebook signup />
        <AuthForm onSubmit={onSignUp}>
          <UniTextField
            inputRef={register({
              required: { value: true, message: t('errors.requiredEmail') },
              pattern: {
                value: isValidEmailRegex,
                message: t('errors.validEmail'),
              },
            })}
            startAdornment={
              <UniIconWrapper size='medium' color='typographyGray100'>
                <EmailIcon />
              </UniIconWrapper>
            }
            name='email'
            error={!!errors?.email?.message}
            helperText={errors?.email?.message}
            label={t('labels.email')}
            placeholder={t('placeholders.email')}
            fullWidth
          />
          <UniTextField
            inputRef={register({
              required: { value: true, message: t('errors.requiredPassword') },
              minLength: { value: 8, message: t('errors.minCharacters') },
            })}
            startAdornment={
              <UniIconWrapper size='medium' color='typographyGray100'>
                <LockIcon />
              </UniIconWrapper>
            }
            name='password'
            error={!!errors?.password?.message}
            helperText={errors?.password?.message}
            label={t('labels.password')}
            placeholder={t('placeholders.password')}
            type='password'
            fullWidth
            endAdornment
          />
          <Box display='flex' flexDirection='column'>
            <Box display='flex' alignItems='flex-start' ml={-1} mt={isMobile ? -1 : 1}>
              <Controller
                control={control}
                name='terms'
                rules={{
                  required: { value: true, message: t('errors.requiredTerms') },
                }}
                render={({ onChange, value }) => <UniCheckbox onChange={e => onChange(e.target.checked)} checked={value} />}
              />

              <UniTypography variant='subheader' color='typographyGray70' style={{ marginTop: '7px' }}>
                <span>{t('labels.terms.1')}</span>
                <a href={termsDoc} target='_blank' style={{ color: Colors.Primary._100, textDecoration: 'none' }}>
                  {t('labels.terms.2')}
                </a>
                <span>{t('labels.terms.3')}</span>
                <a href={privacyDoc} target='_blank' style={{ color: Colors.Primary._100, textDecoration: 'none' }}>
                  {t('labels.terms.4')}
                </a>
              </UniTypography>
            </Box>
            <Fade style={{ marginBottom: '16px' }} in={!!errors.terms?.message} unmountOnExit>
              <Error>
                <StyledAlertIcon>
                  <ErrorSvg />
                </StyledAlertIcon>
                <UniTypography variant='footnote' component='span' color='stateAlert100'>
                  {errors.terms?.message}
                </UniTypography>
              </Error>
            </Fade>
          </Box>
        </AuthForm>
        {signupError.message && (
          <Box mb={2} textAlign='center' width='100%'>
            <UniTypography color='stateAlert100'>{signupError.message}</UniTypography>
          </Box>
        )}
        {!isMobile && (
          <Box mt={3}>
            <UniButton variant='main' size='large' fullWidth={isMobile} onClick={onSignUp} loading={isLoading}>
              {isMobile ? t('buttons.signup1') : t('buttons.signup2')}
            </UniButton>
          </Box>
        )}
      </StyledChildrenWrapperTop>
      <Box display='flex' flexDirection='column' alignItems='center' mt={2}>
        {isMobile && (
          <UniButton variant='main' size='large' fullWidth={isMobile} onClick={onSignUp}>
            {isMobile ? t('buttons.signup1') : t('buttons.signup2')}
          </UniButton>
        )}
        <Box display='flex' alignItems='center' mt={2} mb={2} flexWrap='wrap'>
          <Box mr={3}>
            <UniTypography color='typographyGray100' weight='regular' variant='body2'>
              {t('texts.haveAccount')}
            </UniTypography>
          </Box>
          <UniButton disablePadding variant='text' fullWidth={false} onClick={onGoToLogin}>
            {t('buttons.login')}
          </UniButton>
        </Box>
      </Box>
    </>
  );
};

export default Signup;
