import { Box } from '@material-ui/core';
import usePublicProfile from 'api/students/usePublicProfile';
import ActivityPlanItem from 'components/activityPlanItem/ActivityPlanItem';
import StudentEducationItem from 'components/candidateEducation/StudentEducationItem';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { StudentProfileTabsParam } from 'constants/browserRoutes';
import { AnimatePresence } from 'framer-motion';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams } from 'react-router';
import { useAuth } from 'store';
import styled from 'styled-components';
import { getUserHomeScreen } from 'utils/getHomeForRole';
import { parseAddress } from 'utils/programUtils';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location24x24.svg';
import { ReactComponent as MailIcon } from '../../../assets/svg/Icons/Mail.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/svg/Icons/PhoneNumber.svg';
import {
  AnimatedTabPanel,
  UniCard,
  UniHeader,
  UniHerosection,
  UniIconWrapper,
  UniLogo,
  UniMetrics,
  UniPage,
  UniPageTitle,
  UniTypography,
} from '../../../components';
import { BrowserRoutes, Colors } from '../../../constants';

type Params = {
  studentId?: string;
  view: StudentProfileTabsParam;
};

const StyledActionsBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${Colors.Primary._10};
  width: 100%;
`;

const PublicStudentProfile = () => {
  const history = useHistory();
  const { profile } = useAuth();
  const { studentId, view } = useParams<Params>();
  const { t } = useTranslation('studentProfile');
  const tabs: StudentProfileTabsParam[] = ['info', 'education', 'activity'];
  const currentTabIndex = tabs.findIndex(item => item === view);
  const { data: publicUser, isLoading: publicUserIsLoading } = usePublicProfile({ studentId: studentId || '' });

  const educationHistory = publicUser?.educationHistoryItems;
  const achievements = publicUser?.achievements;

  const infoPathname = BrowserRoutes.Default.Public.StudentProfile(studentId, 'info');
  const educationPathname = BrowserRoutes.Default.Public.StudentProfile(studentId, 'education');
  const activityPathname = BrowserRoutes.Default.Public.StudentProfile(studentId, 'activity');

  const showMetrics = publicUser?.address?.country || publicUser?.address?.city || publicUser?.phone || publicUser?.email;
  const showPersonal =
    publicUser?.gender ||
    publicUser?.firstLanguage ||
    publicUser?.graduationLevel ||
    publicUser?.nationality ||
    publicUser?.dateOfBirth ||
    publicUser?.countryOfBirth;
  const showAchievements = !!achievements?.length;
  const showEducation = !!educationHistory?.length;

  const metrics = [
    {
      label: t('labels.header.country'),
      value: parseAddress(publicUser?.address),
      Icon: (
        <UniIconWrapper color='secondary100'>
          <LocationIcon />
        </UniIconWrapper>
      ),
    },
    {
      label: t('labels.header.phone_number'),
      value: publicUser?.phone || '-',
      Icon: (
        <UniIconWrapper color='secondary100'>
          <PhoneIcon />
        </UniIconWrapper>
      ),
    },
    {
      label: t('labels.header.email'),
      value: publicUser?.email || '-',
      Icon: (
        <UniIconWrapper color='secondary100'>
          <MailIcon />
        </UniIconWrapper>
      ),
    },
  ];

  const heroTabs = useMemo(() => {
    let auxTabs = [];
    if (showPersonal)
      auxTabs.push({
        label: t('tabs.profile_info'),
        path: infoPathname,
      });
    if (showEducation)
      auxTabs.push({
        label: t('tabs.education'),
        path: educationPathname,
      });
    if (showAchievements)
      auxTabs.push({
        label: t('tabs.activity_plan'),
        path: activityPathname,
      });
    return auxTabs;
  }, [showPersonal, showEducation, showAchievements]);

  if (!publicUser) return <></>;

  return (
    <>
      <UniHeader>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          mt={3}
          onClick={() => history.push(getUserHomeScreen(profile?.role))}
        >
          <UniTypography variant='subheader'>Powered by</UniTypography>
          <Box ml={2} />
          <UniLogo />
        </Box>
        <UniHerosection
          title={`${publicUser?.firstName + ' ' + publicUser?.lastName}`}
          universityName={`${publicUser?.firstName + ' ' + publicUser?.lastName}`}
          headerVariant='avatarTitle'
          profileImageSrc={publicUser?.profileImageFile?.location}
          MetricsComponent={showMetrics ? <UniMetrics variant='iconed' size='large' values={metrics} /> : undefined}
          tabs={heroTabs}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            {/* {showPersonal && (
              <Route path={infoPathname}>
                <AnimatedTabPanel
                  Content={<StudentPersonalInfo student={student} />}
                  index={0}
                  currentItem={currentTabIndex}
                  absoluteDirection={-1}
                />
              </Route>
            )} */}
            {showEducation && (
              <Route path={educationPathname}>
                <AnimatedTabPanel
                  Content={
                    <UniCard size='L' border>
                      <UniPageTitle size='M' title='Education'></UniPageTitle>
                      <Box mt={2} />
                      {!educationHistory && <UniListLoader />}
                      {educationHistory?.map((item, index) => (
                        <StudentEducationItem educationHistoryItem={item} isMobile={false} key={index} />
                      ))}
                    </UniCard>
                  }
                  index={1}
                  currentItem={currentTabIndex}
                />
              </Route>
            )}
            {showAchievements && (
              <Route path={activityPathname}>
                <AnimatedTabPanel
                  Content={
                    <>
                      {publicUserIsLoading && <UniListLoader />}
                      {achievements?.map((achievement, index) => (
                        <>
                          <ActivityPlanItem achievement={achievement} key={index} disabled />
                          <Box mb={3} />
                        </>
                      ))}
                    </>
                  }
                  index={2}
                  currentItem={currentTabIndex}
                  absoluteDirection={1}
                />
              </Route>
            )}
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default PublicStudentProfile;
