import { Box } from '@material-ui/core';
import { useLogin } from 'api';
import { UniButton, UniIconWrapper, UniOAuth, UniTextField, UniTypography } from 'components';
import { AuthForm, StyledChildrenWrapperTop } from 'components/_layouts/authLayout/AuthLayout.style';
import { useBreakpoints, useErrorHandler } from 'hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EmailIcon } from '../../../assets/svg/Icons/Candidates.svg';
import { ReactComponent as LockIcon } from '../../../assets/svg/Icons/Lock.svg';
import { BrowserRoutes } from '../../../constants';

type Inputs = {
  email: string;
  password: string;
};

const Login = () => {
  const { isMobile } = useBreakpoints();
  const { register, handleSubmit } = useForm<Inputs>();
  const { t } = useTranslation('authLogin');
  const history = useHistory();

  const [login, { data, isLoading, error }] = useLogin();
  const emailNotVerified = !isLoading && !!data && !data?.emailConfirmed ? t('errors.mustConfirmEmail') : false;
  const loginError = useErrorHandler(error || undefined);

  const onSubmit = (formData: Inputs) => {
    login(formData);
  };

  const onBackToMagicLink = () => history.push(BrowserRoutes.Default.Auth.MagicLinkLogin);
  const onForgotPassword = () => history.push(BrowserRoutes.Default.Auth.ForgotPassword);
  const onLogin = handleSubmit(onSubmit);
  const onCreateAccount = () => history.push(BrowserRoutes.Default.Auth.SignUp);

  return (
    <>
      <StyledChildrenWrapperTop>
        <UniOAuth google facebook login />
        <AuthForm onSubmit={onLogin}>
          <UniTextField
            inputRef={register({
              required: {
                value: true,
                message: t('errors.requiredEmail'),
              },
            })}
            startAdornment={
              <UniIconWrapper size='medium' color='typographyGray100'>
                <EmailIcon />
              </UniIconWrapper>
            }
            name='email'
            label={t('labels.email')}
            placeholder={t('placeholders.email')}
            fullWidth
          />
          <UniTextField
            inputRef={register({
              required: {
                value: true,
                message: t('errors.requiredPassword'),
              },
            })}
            startAdornment={
              <UniIconWrapper size='medium' color='typographyGray100'>
                <LockIcon />
              </UniIconWrapper>
            }
            name='password'
            label={t('labels.password')}
            placeholder={t('placeholders.password')}
            type='password'
            fullWidth
            endAdornment
          />
        </AuthForm>

        <Box width={isMobile ? 'auto' : '100%'} mb={isMobile ? 1 : 5}>
          <UniButton disablePadding variant='text' color='neutral' fullWidth={false} onClick={onForgotPassword}>
            {t('buttons.forgotPassword')}
          </UniButton>
        </Box>
        {loginError.message && (
          <Box mb={2} textAlign='center' width='100%'>
            <UniTypography color='stateAlert100'>{loginError.message}</UniTypography>
          </Box>
        )}
        {emailNotVerified && (
          <Box mb={2} textAlign='center' width='100%'>
            <UniTypography color='stateAlert100'>{emailNotVerified}</UniTypography>
          </Box>
        )}
        {/* <Box mb={3}>
          <UniButton disablePadding variant='text' fullWidth={false} onClick={onBackToMagicLink}>
            <UniTypography color='primary70' weight='regular' variant='subheader'>
              {t('buttons.gotoMagicLink')}
            </UniTypography>
          </UniButton>
        </Box> */}
        {!isMobile && (
          <UniButton variant='main' size='large' fullWidth={isMobile} onClick={onLogin} loading={isLoading}>
            {t('buttons.login')}
          </UniButton>
        )}
      </StyledChildrenWrapperTop>
      <Box display='flex' flexDirection='column' alignItems='center'>
        {isMobile && (
          <UniButton variant='main' size='large' fullWidth={isMobile} onClick={onLogin}>
            {t('buttons.login')}
          </UniButton>
        )}
        <Box display='flex' alignItems='center' mt={2} mb={2} flexWrap='wrap'>
          <Box mr={3}>
            <UniTypography color='typographyGray100' weight='regular' variant='body2'>
              {t('texts.noAccount')}
            </UniTypography>
          </Box>
          <UniButton disablePadding variant='text' fullWidth={false} onClick={onCreateAccount}>
            {t('buttons.createAccount')}
          </UniButton>
        </Box>
      </Box>
    </>
  );
};

export default Login;
