import { Box } from '@material-ui/core';
import useDeleteServiceOptionsRequests from 'api/service-options/useDeleteServiceOptionsRequest';
import { Components } from 'client/UniClient';
import { UniModalDrawerButtons, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers, useSnackbar } from 'store';

interface Props {
  service?: Partial<Components.Schemas.ServiceOptionRequestDto>;
}

export default function RejectService({ service }: Props): ReactElement {
  const { t: tButtons } = useTranslation('buttons');
  const { closeDrawer } = useDrawers();
  const { openSnackbar } = useSnackbar();
  const [reject, { isLoading }] = useDeleteServiceOptionsRequests();

  const onClose = () => closeDrawer();

  const onDelete = () => {
    closeDrawer();
    openSnackbar('reject-service', { hide: service?.id, action: () => reject({ serviceOptionRequestId: service?.id || '' }) });
  };

  return (
    <>
      <UniModalDrawerContent>
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          You are about to reject this suggestion from {`${service?.agent?.firstName} ${service?.agent?.lastName}`}:
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='body1' color='secondary100' weight='medium'>
          {service?.name}
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          Are you sure you want to reject it?
        </UniTypography>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: onClose, label: tButtons('cancel') }}
        PrimaryButton={{ onClick: onDelete, loading: isLoading, label: tButtons('yes_reject') }}
      />
    </>
  );
}
