import { Tabs } from '@material-ui/core';
import styled from 'styled-components';
import { theme } from 'theme';

export const StyledHeader = styled.div<{ mobile: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  align-items: center;
  ${({ mobile, theme }) =>
    mobile
      ? `
        display:grid;
        margin-bottom: ${theme.spacing(3)}px;
        justify-content: center;
        text-align: center;
        gap: ${theme.spacing(1)}px;
    `
      : `
        display:flex;
        justify-content:space-between;
        margin-bottom: ${theme.spacing(4)}px;
    `}
`;

export const StyledTabs = styled(Tabs)`
  min-height: 0;
  margin-top: 0;
  ${({ theme }) => `${theme.breakpoints.down('sm')}{
    margin-right: ${theme.spacing(-2)}px;
    margin-left: ${theme.spacing(-2)}px;
    margin-top:${-theme.spacing(1)}px;
  }`}
`;

export const ConsultantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const StyledMedia = styled.div`
  width: 100%;
  height: 240px;
  overflow: hidden;
  border-radius: ${theme.spacing(4, 4, 4, 4)};
  margin: 0;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
