import styled from 'styled-components';
import { Colors, Sizes } from '../../../constants/uiConfig';

export const StyledFooterContainer = styled.div<{ stretch?: boolean; isLoggedIn?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: visible;
  z-index: 15;
  margin-left: -${({ theme }) => theme.spacing(6)}px;
  margin-right: -${({ theme }) => theme.spacing(6)}px;
  margin-bottom: -${({ theme }) => theme.spacing(5)}px;
`;

export const StyledUpperFooter = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 35px 35px 0 0;
  flex-wrap: wrap;
  background-color: ${Colors.Secondary._100};
  padding-top: ${({ theme }) => theme.spacing(8)}px;
  padding-bottom: ${({ theme }) => theme.spacing(8)}px;
  padding-left: ${({ theme }) => theme.spacing(6)}px;
  padding-right: ${({ theme }) => theme.spacing(6)}px;
  ${({ theme }) => `
    box-shadow: 0px 0px 14px 0px ${theme.palette.secondary.main};
  `}
`;

export const StyledBottomFooter = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Colors.Primary._100};
  padding: ${({ theme }) => theme.spacing(1.5, 2)};
`;

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  :hover {
    text-decoration: none;
  }
`;

export const StyledDivider = styled.div`
  width: 25px;
  height: 2px;
  background: ${Colors.Other.Yellow_100};
  margin: ${({ theme }) => theme.spacing(1)}px 0;
`;
