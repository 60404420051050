import QueryString from 'query-string';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

export const useQueryStringParser = <T>() => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const queries = QueryString.parse(search) as unknown as T;

  const replaceQueriesToUrl = (data: T) => {
    history.push({ pathname, search: QueryString.stringify(data) });
  };

  const pushQueriesToUrl = (data: T) => {
    history.push({ pathname, search: QueryString.stringify({ ...queries, ...data }) });
  };

  const createUrlWithQueries = (url: string, data: T) => {
    return `${url}?${QueryString.stringify(data)}`;
  };

  return { replaceQueriesToUrl, pushQueriesToUrl, queries, createUrlWithQueries };
};
