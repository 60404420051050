import { useExtendedProfile } from 'api';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'store';
import { getMetaRole } from 'utils';
import { BrowserRoutes } from '../../constants';

const useStudentStepperData = () => {
  const { profile } = useAuth();
  const { isStudent } = getMetaRole(profile?.role);
  const isLoggedIn = !!profile?.id;
  const { data } = useExtendedProfile({ id: profile.id }, { enabled: isLoggedIn && isStudent });
  const { studentMetadata } = profile;
  const { studentProfile } = profile;
  const documents = studentProfile?.documents;
  const { t } = useTranslation('stepper');

  return [
    {
      title: t('steps.create_account.title'),
      completed: true,
      texts: [t('steps.create_account.info.1'), t('steps.create_account.info.2')],
      buttonText: t('steps.create_account.button'),
      route: BrowserRoutes.Student.Profile.Default.replace(':tab', 'info'),
    },
    {
      title: t('steps.add_education.title'),
      completed: !!data?.educationHistory?.length,
      texts: [t('steps.add_education.info.1'), t('steps.add_education.info.2'), t('steps.add_education.info.3')],
      buttonText: t('steps.add_education.button'),
      route: BrowserRoutes.Student.Profile.Default.replace(':tab', 'education'),
    },
    {
      title: t('steps.add_documents.title'),
      completed: !!documents?.length,
      texts: [t('steps.add_documents.info.1'), t('steps.add_documents.info.2'), t('steps.add_documents.info.3')],
      buttonText: t('steps.add_documents.button'),
      route: BrowserRoutes.Student.Profile.Default.replace(':tab', 'documents'),
    },
    {
      title: t('steps.find_programmes.title'),
      completed: !!studentMetadata?.favoriteProgramsCount,
      texts: [
        t('steps.find_programmes.info.1'),
        t('steps.find_programmes.info.2'),
        t('steps.find_programmes.info.3'),
        t('steps.find_programmes.info.4'),
      ],
      buttonText: t('steps.find_programmes.button'),
      route: BrowserRoutes.Student.CardList.Default.replace(':view', 'grouped'),
    },
    {
      title: t('steps.apply.title'),
      completed: !!studentMetadata?.ongoingApplicationsCount,
      texts: [t('steps.apply.info.1'), t('steps.apply.info.2'), t('steps.apply.info.3')],
      buttonText: t('steps.apply.button'),
      route: BrowserRoutes.Student.Applications.AllPrograms,
    },
  ];
};
export default useStudentStepperData;
