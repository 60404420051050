import styled from 'styled-components';
import { Shadows } from '../../../../constants';

export const StyledHeader = styled.div`
  background-color: #fff;
  width: calc(100% + 48px);
  margin: -40px -24px 0 -24px;
  padding: 0 24px;
  box-shadow: ${Shadows.shadow2};
`;

export const StyledInnerHeader = styled.div`
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(0, 5, 0, 10)};
`;
