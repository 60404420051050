import { Container } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export const Content = styled(({ stretchCotent, isLoggedIn, ...props }) => <Container maxWidth {...props} />)`
  padding: ${({ theme }) => theme.spacing(0, 2, 5, 2)};
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(0, 3, 5, 3)};
    padding-left: ${({ stretchCotent, isLoggedIn, theme }) =>
      stretchCotent
        ? `${theme.sizes.drawerWidth + theme.spacing(3)}px`
        : `${(isLoggedIn ? theme.sizes.drawerWidthCollapsed : 0) + theme.spacing(3)}px`};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }) => theme.spacing(0, 6, 5, 6)};
    padding-left: ${({ stretchCotent, isLoggedIn, theme }) =>
      stretchCotent
        ? `${theme.sizes.drawerWidth + theme.spacing(6)}px`
        : `${(isLoggedIn ? theme.sizes.drawerWidthCollapsed : 0) + theme.spacing(6)}px`};
  }
  transition: 0.2s ease-in-out;

  &::-webkit-scrollbar {
    width: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const StyledLayout = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

export const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: calc(100% + 32px);
  }
`;
