import { AxiosError } from 'axios';
import { Components } from 'client/UniClient';
import { QueryConfig, useQuery } from 'react-query';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  id: string;
  _page?: number;
};

export default function usePrograms(params?: Params, config?: QueryConfig<Components.Schemas.ProgramResponse>) {
  //   const agencyId = useAuth((state) => state.profile.agentProfile?.agency?.id);
  const { client } = useClient();

  return useQuery(
    [CacheKeys.General.Program, params?.id],
    async (_key: string, programId: string) => {
      const { data } = await client!.ProgramApi_getProgram(programId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      onError(error: AxiosError) {},
    },
  );
}
