import { UniCardFooter, UniCardWrapper } from 'components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme';
import { Colors } from '../../../constants/uiConfig';

export const StyledUniCardWrapper = styled(UniCardWrapper)`
  display: flex;
  flex-direction: column;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  flex: 1;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledMedia = styled.div`
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: ${theme.spacing(1.5, 1.5, 0, 0)};

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledUniCardFooter = styled(UniCardFooter)`
  border-bottom-left-radius: ${({ theme }) => theme.spacing(1.5)}px;
  border-bottom-right-radius: ${({ theme }) => theme.spacing(1.5)}px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const StyledFooterBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 3)};
  background-color: ${Colors.White._100};
  border-radius: ${theme.spacing(0, 0, 1.5, 1.5)};
  width: 100%;
  cursor: initial;
  &::before {
    content: '';
    border-radius: inherit;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${Colors.Gray2._40};
  }
`;
