import { Fade } from '@material-ui/core';
import React from 'react';
import { UniBounceLoader } from '../..';
import { ReactComponent as MetricsAccepted } from '../../../assets/svg/Icons/Metrics/Accepted.svg';
import { ReactComponent as MetricsApplications } from '../../../assets/svg/Icons/Metrics/Applications.svg';
import { ReactComponent as MetricsStudents } from '../../../assets/svg/Icons/Metrics/Students.svg';
import { ReactComponent as TrendingDownIcon } from '../../../assets/svg/Icons/Trending/DownArrow.svg';
import { ReactComponent as TrendingFlatIcon } from '../../../assets/svg/Icons/Trending/Normal.svg';
import { ReactComponent as TrendingUpIcon } from '../../../assets/svg/Icons/Trending/UpArrow.svg';
import ComponentColors, { TComponentColors } from '../../../constants/componentColors';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import UniTypography from '../uniTypography/UniTypography';
import { Card, CardLoader, CardMetric, Trending } from './UniCardMetric.style';

const UniCardContainer: React.FC = ({ children, ...props }) => {
  return <Card {...props}>{children}</Card>;
};

//---------- Metric Card -----------------
type Trending = 'up' | 'down' | 'flat';

const metricsIconMap = {
  applications: <MetricsApplications />,
  favorites: <MetricsApplications />,
  students: <MetricsStudents />,
  accepted: <MetricsAccepted />,
};
type MetricsIcon = keyof typeof metricsIconMap;

type UniCardMetricProps = {
  value: string;
  title: string;
  percent: number;
  trending: Trending;
  subTitle?: string;
  loading?: boolean;
  background?: string;
  mainTextColor?: TComponentColors;
  secondaryTextColor?: TComponentColors;
  trendingColor?: TComponentColors;
  leftIcon?: MetricsIcon;
  rightIcon?: MetricsIcon;
};

const iconMap = {
  up: {
    icon: <TrendingUpIcon />,
    color: 'success',
  },
  down: {
    icon: <TrendingDownIcon />,
    color: 'alert100',
  },
  flat: {
    icon: <TrendingFlatIcon />,
    color: 'typographyGray50',
  },
} as const;

const UniCardMetric: React.FC<UniCardMetricProps> = ({
  value,
  title,
  percent,
  trending,
  subTitle,
  loading = false,
  background,
  mainTextColor,
  secondaryTextColor,
  trendingColor,
  leftIcon,
  rightIcon,
}) => {
  return (
    <CardMetric background={background}>
      {leftIcon && (
        <div
          style={{
            display: 'inline-block',
            maxWidth: '40px',
            margin: '1.2rem 0.8rem 0 0',
          }}
        >
          {metricsIconMap[leftIcon]}
        </div>
      )}
      <div style={{ display: 'inline-block', maxWidth: '120px' }}>
        <UniTypography variant='footnote' color={secondaryTextColor}>
          {title}
        </UniTypography>
        <UniTypography variant='header1' weight='semi-bold' color={mainTextColor}>
          {value}
        </UniTypography>
        {subTitle && (
          <UniTypography variant='footnote' color={secondaryTextColor}>
            {subTitle}
          </UniTypography>
        )}
      </div>
      {rightIcon && (
        <div
          style={{
            display: 'inline-block',
            maxWidth: '40px',
            margin: '1.2rem 0 0 0.8rem',
          }}
        >
          {metricsIconMap[rightIcon]}
        </div>
      )}
      <Trending>
        <div
          style={{
            backgroundColor: ComponentColors[trendingColor ?? 'typographyGray100'] + '20', // Horrible hack, adds opacity to existing color
            borderRadius: '10px',
            padding: '1px 5px',
          }}
        >
          <UniIconWrapper color={trendingColor} size='main'>
            {iconMap[trending].icon}
          </UniIconWrapper>
        </div>
        <UniTypography weight='semi-bold' variant='footnote' color={trendingColor}>
          {percent}%
        </UniTypography>
      </Trending>
      <Fade in={loading}>
        <CardLoader>
          <UniBounceLoader pulseSpeed={0.8} size={15} />
        </CardLoader>
      </Fade>
    </CardMetric>
  );
};

export { UniCardMetric, UniCardContainer };
