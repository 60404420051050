import { Box } from '@material-ui/core';
import useDeleteEvent from 'api/events/useDeleteEvent';
import { Components } from 'client/UniClient';
import { UniModalDrawerButtons, UniTypography } from 'components';
import { UniModalDrawerContent } from 'components/_common/uniModalDrawer/UniModalDrawer.style';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDrawers, useSnackbar } from 'store';
import { BrowserRoutes } from '../../../constants';

interface Props {
  event?: Components.Schemas.EventResponse;
}

export default function RemoveEvent({ event }: Props): ReactElement {
  const { t: tButtons } = useTranslation('buttons');
  const { closeDrawer } = useDrawers();
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const [deleteEvent, { isLoading }] = useDeleteEvent();

  const onClose = () => closeDrawer();

  const onDelete = () => {
    closeDrawer();
    deleteEvent({ eventId: event?.id || '' }, { onSuccess: () => history.push(BrowserRoutes.Admin.Events.All) });
    // openSnackbar('delete-activity', {
    //   hide: event?.id,
    //   action: () => ),
    // });
  };

  return (
    <>
      <UniModalDrawerContent>
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          You are about to remove from the events list the following event:
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='body1' color='secondary100' weight='medium'>
          {event?.name}
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='body2' color='typographyBlack100' weight='regular'>
          Are you sure you want to delete it?
        </UniTypography>
      </UniModalDrawerContent>
      <UniModalDrawerButtons
        SecondaryButton={{ onClick: onClose, label: tButtons('cancel') }}
        PrimaryButton={{ onClick: onDelete, loading: isLoading, label: tButtons('yes_delete') }}
      />
    </>
  );
}
