import { Box, Grid } from '@material-ui/core';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useGetAgent from '../../../api/agency/useGetAgent';
import usePutAgent from '../../../api/agency/usePutAgent';
import { ReactComponent as LeftArrowIcon } from '../../../assets/svg/Icons/Arrow/Left.svg';
import { UniButton, UniHeader, UniIconWrapper, UniPage, UniTypography } from '../../../components';
import AgentDetails from '../../../components/agentDetails/AgentDetails';
import AgentHeader from '../../../components/agentHeader/AgentHeader';
import { BrowserRoutes } from '../../../constants';
import { useErrorHandler } from '../../../hooks';
import useAuth from '../../../store/auth';

type Inputs = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phonePrefix: string;
  fee: string;
};

const AgentProfile = () => {
  const { t } = useTranslation('agencyProfile');
  let { profile } = useAuth();
  const params = useParams<{ agencyId: string; agentId: string }>();

  const agentId = params.agentId || profile.id!;
  const agencyId = params.agencyId || profile.agentProfile?.agency?.id!;

  const isOwner = agentId === profile.id;
  const {
    data: agent,
    status: agentStatus,
    error: getAgentError,
  } = useGetAgent({
    agencyId: agencyId,
    agentId: agentId,
  });

  const fetchError = useErrorHandler(getAgentError!);

  const [isEditing, setIsEditing] = useState(false);
  const { register, handleSubmit, setValue, control } = useForm<Inputs>({ defaultValues: {} });

  const [putAgent, { error: putAgentError }] = usePutAgent({
    agencyId: agencyId,
    agentId: agentId,
  });

  const postError = useErrorHandler(putAgentError!);

  const onFormSubmit = (data: Inputs) => {
    if (!agent) return;
    const currentAgent = agent.agentProfile;
    const payload = {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
      phonePrefix: data.phonePrefix,
      agentProfile: {
        fee: {
          amount: Number(data?.fee?.substring(1) || '0'),
          currencyIso: currentAgent.fee.currencyIso || 'EUR',
        },
        agentCode: currentAgent.agentCode,
        feeType: currentAgent.feeType || 'AFTER',
      },
    };
    putAgent(payload, {
      onSuccess() {
        setIsEditing(false);
      },
    });
  };

  const history = useHistory();

  if (agentStatus === 'loading' || !agent) {
    return <UniListLoader />;
  }

  return (
    <>
      <UniHeader>
        <AgentHeader
          changeNavbar
          agent={agent}
          isOwner={isOwner}
          isEditing={isEditing}
          inputRef={register}
          BackButton={
            <UniButton
              fullWidth={false}
              variant='text'
              color='neutral'
              onClick={() => history.push(BrowserRoutes.Agent.Default)}
              startIcon={
                <UniIconWrapper color='typographyBlack100'>
                  <LeftArrowIcon />
                </UniIconWrapper>
              }
            >
              {t('buttons.back')}
            </UniButton>
          }
          MiddleActions={
            isEditing ? (
              <UniButton
                fullWidth={false}
                style={{ width: '180px' }}
                variant='main'
                color='primary'
                type='submit'
                onClick={handleSubmit(onFormSubmit)}
              >
                {t('buttons.save')}
              </UniButton>
            ) : (
              <UniButton
                fullWidth={false}
                style={{ width: '180px' }}
                variant='main'
                color='primary'
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                {t('buttons.edit')}
              </UniButton>
            )
          }
        />
      </UniHeader>
      <UniPage>
        <Grid xs={12} item>
          {fetchError.message ? (
            <Box>
              <UniTypography color='stateAlert100'>{fetchError.message}</UniTypography>
            </Box>
          ) : (
            <AgentDetails
              isOwner={isOwner}
              agentProfile={agent}
              isEditing={isEditing}
              inputRef={register}
              setValue={setValue}
              control={control}
              hasError={postError.message}
            />
          )}
        </Grid>
      </UniPage>
    </>
    // </form>
  );
};

export default AgentProfile;
