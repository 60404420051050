import { UniTextArea, UniTextField } from 'components';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import { activitiesOptions } from 'constants/activities';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
  register: any;
  control: any;
  errors: any;
}

const Step2: React.FC<Props> = ({ register, control, errors }) => {
  const { t } = useTranslation('activityItemDrawer');

  return (
    <>
      <UniTextField
        label='Task Title'
        placeholder='Give a title to your item'
        inputRef={register({
          required: `${t('activity_item.errors.title')}`,
          validate: (value: any) => value.trim() !== '' || `${t('activity_item.errors.title')}`,
        })}
        name='title'
        error={!!errors.title?.message}
        helperText={errors.title?.message}
        fullWidth
      />

      <Controller
        control={control}
        name='category'
        rules={{ required: `${t('activity_item.errors.category')}` }}
        render={({ value, onChange }) => (
          <UniSelect
            label='Task category'
            placeholder='Give a title to your item'
            options={activitiesOptions}
            getOptionLabel={option => option.label}
            value={activitiesOptions.find(item => item.value === value)}
            onChange={option => onChange(option?.value)}
            error={errors.category?.message}
          />
        )}
      />

      <Controller
        control={control}
        name='description'
        rules={{ required: `${t('activity_item.errors.description')}` }}
        render={field => (
          <UniTextArea
            label='Description'
            placeholder='Write a couple of sentences about this item'
            helperText={errors.description?.message}
            withBorder
            {...field}
          />
        )}
      />
    </>
  );
};

export default Step2;
