import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ReactComponent as LocationIcon } from '../../assets/svg/Icons/Location.svg';
import { Components } from '../../client/UniClient';
import { AgentMeta, MetaItem } from './AgentHeader.style';
import UniIconWrapper from '../_common/uniIconWrapper/UniIconWrapper';
import UniTypography from '../_common/uniTypography/UniTypography';
import { useTranslation } from 'react-i18next';

export default function AgentHeaderMeta({ agency, fee }: Components.Schemas.AgentProfileDto): ReactElement {
  const { t } = useTranslation('agencyProfile');
  return (
    <AgentMeta onScrollCollapse={false}>
      {/* <Box
        display="flex"
        justifyContent="space-between"
        style={{ gap: `${theme.spacing(5)}px` }}
      > */}
      <MetaItem>
        <UniIconWrapper color='primary100' withBackground>
          <LocationIcon />
        </UniIconWrapper>
        <Box>
          <UniTypography color='typographyGray70' variant='footnote'>
            {t('labels.header.agency')}
          </UniTypography>
          <UniTypography color='typographyGray70' weight='semi-bold' variant='body2'>
            {agency.name}
          </UniTypography>
        </Box>
      </MetaItem>
      <MetaItem>{/* HERE SHOULD BE DISPLAYED FEE*/}</MetaItem>
      <Box />
    </AgentMeta>
  );
}
