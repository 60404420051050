import { Components } from 'client/UniClient';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import { Controller } from 'react-hook-form';

interface Props {
  questions?: Components.Schemas.QuizQuestionDto[];
  control: any;
  errors: any;
}

const InterestsQuizForm: React.FC<Props> = ({ questions, control, errors }) => {
  return (
    <>
      {questions?.map(({ options, description }, index) => {
        const optionsArray = options?.map(option => ({ value: option, label: option }));
        const label = description;
        const name = `${description}`;

        return (
          <Controller
            control={control}
            name={name}
            key={description}
            render={({ value, onChange }) => (
              <UniSelect
                label={label}
                value={optionsArray.find(option => option?.value === value)}
                onChange={option => onChange(option?.value)}
                options={optionsArray}
                error={errors[name]?.message}
                menuPlacement={index === questions.length - 1 ? 'top' : 'auto'}
              />
            )}
          />
        );
      })}
    </>
  );
};

export default InterestsQuizForm;
