import styled from 'styled-components';
import { ReactComponent as DownArrow } from '../../../assets/svg/Icons/Arrow/Down.svg';

type StyledUniSortButtonProps = {
  active: boolean;
  onClick: () => void;
};

export const StyledUniSortButton = styled.button<StyledUniSortButtonProps>`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  border: none;
  background: none;
  &:focus {
    outline: none;
  }
`;
// TODO: add down arrow on hover state

export const Arrow = styled.span`
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;
