/* eslint-disable no-nested-ternary */
import { useFile, useLogout, useUploadProfileImage } from 'api';
import { Components } from 'client/UniClient';
import { AnimatedTabPanel, UniHeader, UniIconWrapper, UniPage } from 'components';
import StudentActivityPlan from 'components/candidateActivityPlan/StudentActivityPlan';
import StudentEducation from 'components/candidateEducation/StudentEducation';
import StudentPersonalInfo from 'components/candidatePersonalInfo/StudentPersonalInfo';
import { UniHerosection } from 'components/_common/uniHerosection/UniHerosection';
import { countries } from 'constants/countries';
import { AnimatePresence } from 'framer-motion';
import { useBreakpoints } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useAuth, useHeader, useSnackbar } from 'store';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location24x24.svg';
import { ReactComponent as MailIcon } from '../../../assets/svg/Icons/Mail.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/svg/Icons/PhoneNumber.svg';
import StudentDocuments from '../../../components/candidateDocuments/StudentDocuments';
import { BrowserRoutes } from '../../../constants/browserRoutes';
import StudentProfileActions from './StudentProfileActions';

type TabsParams = {
  tab: 'info' | 'education' | 'documents';
};

const StudentProfile: React.FC = () => {
  const user = useAuth(state => state.profile);
  const { setProfile, resetProfile } = useAuth(state => state);
  const history = useHistory();
  const [logoutCall] = useLogout();
  const logout = useCallback(() => {
    // cleanup
    logoutCall();
    resetProfile();
  }, [history, resetProfile, logoutCall]);
  const { t } = useTranslation('studentProfile');
  const { isMobile } = useBreakpoints();
  const { data } = useFile({ id: user.studentProfile?.passportDocument?.id ? user.studentProfile?.passportDocument?.id : '' });
  const { tab } = useParams<TabsParams>();
  const { setHeader, clearHeader } = useHeader();
  const [uploadProfileImage] = useUploadProfileImage();
  const { openSnackbar } = useSnackbar();
  const currentTabIndex = tab === 'info' ? 0 : tab === 'education' ? 1 : 2;
  const country = countries.filter(item => item.code === user?.studentProfile?.address?.country).map(item => item.name)[0];
  const phoneNumber = user?.phone ? (user?.phonePrefix ? user?.phonePrefix + user?.phone : user?.phone) : '-';
  const profileImageSrc = useFile({ id: user.profileImageFile?.id || '' }, { enabled: !!user.profileImageFile });
  const fillData = {
    ...user,
    ...user.studentProfile,
    guardian: user.studentProfile?.guardian as any,
    passportURL: data?.url,
  };

  const uploadHandler = (file?: File) => {
    const profileImage = new FormData();
    profileImage.append('file', file!);
    profileImage.append('documentType', 'PROFILE_IMAGE');
    uploadProfileImage(profileImage, {
      onSuccess: image => {
        const updatedProfile = { ...user };
        updatedProfile.profileImageFile = image.data;
        setProfile(updatedProfile);
        openSnackbar('profile-image-uploaded');
      },
    });
  };

  useEffect(() => {
    setHeader({
      secondaryButton: {
        options: [
          {
            label: t('buttons.logout'),
            onClick: logout,
            labelColor: 'stateAlert100',
          },
        ],
      },
    });
    return clearHeader;
  }, []);

  return (
    <>
      <UniHeader>
        <UniHerosection
          title={`${user?.firstName} ${user?.lastName}`}
          universityName={`${user?.firstName} ${user?.lastName}`}
          headerVariant='avatarTitle'
          profileImageSrc={profileImageSrc.data?.url}
          onProfileImageUpload={uploadHandler}
          Actions={
            <StudentProfileActions
              studentProfile={user.studentProfile}
              studentId={user.id}
              agencyId={user.studentProfile?.agent?.agentProfile?.agency?.id}
            />
          }
          metrics={
            isMobile
              ? []
              : [
                  {
                    label: t('labels.header.country'),
                    value: country || '-',
                    Icon: (
                      <UniIconWrapper color='secondary100'>
                        <LocationIcon />
                      </UniIconWrapper>
                    ),
                  },
                  {
                    label: t('labels.header.phone_number'),
                    value: phoneNumber,
                    Icon: (
                      <UniIconWrapper color='secondary100'>
                        <PhoneIcon />
                      </UniIconWrapper>
                    ),
                  },
                  {
                    label: t('labels.header.email'),
                    value: user?.email || '-',
                    Icon: (
                      <UniIconWrapper color='secondary100'>
                        <MailIcon />
                      </UniIconWrapper>
                    ),
                  },
                ]
          }
          tabs={[
            {
              label: t('tabs.profile_info'),
              path: BrowserRoutes.Student.Profile.PersonalInfo,
            },
            {
              label: t('tabs.education'),
              path: BrowserRoutes.Student.Profile.Education,
            },
            {
              label: t('tabs.documents'),
              path: BrowserRoutes.Student.Profile.Documents,
            },
            {
              label: t('tabs.activity_plan'),
              path: BrowserRoutes.Student.Profile.ActivityPlan,
            },
          ]}
        />
      </UniHeader>
      <UniPage>
        <AnimatePresence>
          <Switch>
            <Route path={BrowserRoutes.Student.Profile.PersonalInfo}>
              <AnimatedTabPanel
                Content={<StudentPersonalInfo student={user as Components.Schemas.UserResponse} editable />}
                index={0}
                currentItem={currentTabIndex}
                absoluteDirection={-1}
              />
            </Route>
            <Route path={BrowserRoutes.Student.Profile.Education}>
              <AnimatedTabPanel Content={<StudentEducation candidate={user} editable />} index={1} currentItem={currentTabIndex} />
            </Route>
            <Route path={BrowserRoutes.Student.Profile.Documents}>
              <AnimatedTabPanel
                Content={<StudentDocuments candidate={user} editable />}
                index={2}
                currentItem={currentTabIndex}
                absoluteDirection={1}
              />
            </Route>
            <Route path={BrowserRoutes.Student.Profile.ActivityPlan}>
              <AnimatedTabPanel
                Content={<StudentActivityPlan studentId={user.id!} editable />}
                index={2}
                currentItem={currentTabIndex}
                absoluteDirection={1}
              />
            </Route>
          </Switch>
        </AnimatePresence>
      </UniPage>
    </>
  );
};

export default StudentProfile;
