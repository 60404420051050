import { Box, ClickAwayListener } from '@material-ui/core';
import React, { useState } from 'react';
import { StyledDropdown } from './UniDropdown.style';

interface Props {
  ActionButton: React.ReactNode;
  open: boolean;
  children?: React.ReactNode;
  handleOpen: () => void;
  handleClose: () => void;
}

export default function UniDropdown({ ActionButton, children, open, handleOpen, handleClose, ...props }: Props) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const handleSetAnchor = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const { currentTarget } = event;
    setAnchor(currentTarget);
  };

  return (
    <>
      <Box
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          handleSetAnchor(e);
          handleOpen();
        }}
      >
        {ActionButton}
      </Box>
      <StyledDropdown open={open} anchorEl={anchor} placement='bottom' {...props}>
        <ClickAwayListener onClickAway={handleClose}>
          <Box display='flex' flexDirection='column'>
            {children}
          </Box>
        </ClickAwayListener>
      </StyledDropdown>
    </>
  );
}
