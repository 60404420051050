import { usePatchApplicationStatus } from 'api';
import { Components } from 'client/UniClient';
import UniModalDrawerButtons from 'components/_common/uniModalDrawer/UniModalDrawerActions';
import { AnimatePresence } from 'framer-motion';
import { useErrorHandler } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawers } from 'store';
import { ApplicationStatus } from 'types';
import { UniTypography } from '../..';
import UniversityReplyStepItem from './UniversityReplyItem';
import UniversityReplyStep1 from './_steps/UniversityReplyStep1';
import UniversityReplyStep2 from './_steps/UniversityReplyStep2';
import UniversityReplyStep3 from './_steps/UniversityReplyStep3';
import UniversityReplyStep4 from './_steps/UniversityReplyStep4';
import UniversityReplyStep5 from './_steps/UniversityReplyStep5';

interface Props {
  application?: Components.Schemas.ApplicationDto;
  handleClose: Function;
}

export default function UniversityReplyForm({ application, handleClose }: Props): ReactElement {
  const { t } = useTranslation('universityReplyForm');
  const [animateDirection, setAnimateDirection] = useState(0);
  const [status, setStatus] = useState<ApplicationStatus>(application?.status!);
  const [message, setMessage] = useState<string>();
  const [documents, setDocuments] = useState<File[]>();
  const [currentTab, setCurrentTab] = useState(0);
  const { setProgress } = useDrawers();
  const steps = ['Status', 'Documents', 'Message', 'Overview'];

  const [patchStatus, { error: patchStatusError, status: patchStatusState }] = usePatchApplicationStatus();
  const parsedPatchStatusError = useErrorHandler(patchStatusError || undefined);
  const isLoading = patchStatusState === 'loading';

  const handleStep = (step: number) => {
    if (step < currentTab) {
      setAnimateDirection(-1);
    } else {
      setAnimateDirection(1);
    }
    setCurrentTab(step);
  };

  const handleSubmit = () => {
    patchStatus(
      {
        message: message || '',
        applicationId: application?.id!,
        status: status,
        requestedDocumentId: '',
        files: documents || [],
      },
      {
        onSuccess() {
          setCurrentTab(4);
        },
      },
    );
  };

  useEffect(() => {
    setProgress(currentTab * 20 + 20);
  }, [currentTab]);

  return (
    <>
      <AnimatePresence>
        <UniversityReplyStepItem
          currentItem={0}
          direction={animateDirection}
          active={currentTab === 0}
          Content={<UniversityReplyStep1 status={status} setStatus={(value: ApplicationStatus) => setStatus(value)} />}
        />
        <UniversityReplyStepItem
          currentItem={1}
          direction={animateDirection}
          active={currentTab === 1}
          Content={<UniversityReplyStep2 documents={documents} setDocuments={(files: File[]) => setDocuments(files)} />}
        />
        <UniversityReplyStepItem
          currentItem={2}
          direction={animateDirection}
          active={currentTab === 2}
          Content={<UniversityReplyStep3 message={message} setMessage={(value: string) => setMessage(value)} />}
        />
        <UniversityReplyStepItem
          currentItem={3}
          direction={animateDirection}
          active={currentTab === 3}
          Content={<UniversityReplyStep4 documents={documents} message={message} status={status} />}
        />
        <UniversityReplyStepItem
          currentItem={4}
          direction={animateDirection}
          active={currentTab === 4}
          Content={<UniversityReplyStep5 />}
        />
      </AnimatePresence>
      <UniTypography color='stateAlert100' weight='medium'>
        {parsedPatchStatusError.message}
      </UniTypography>
      <UniModalDrawerButtons
        SecondaryButton={
          currentTab < 4 && currentTab > 0
            ? { onClick: () => handleStep(currentTab - 1), label: t('buttons.prev_step') }
            : { onClick: handleClose, label: t('buttons.close_btn') }
        }
        PrimaryButton={
          currentTab < 4
            ? currentTab < steps.length - 1
              ? { onClick: () => handleStep(currentTab + 1), label: t('buttons.next_step') }
              : { onClick: handleSubmit, label: t('buttons.finalise') }
            : { onClick: handleClose, label: t('buttons.back_to') }
        }
      />
    </>
  );
}
