import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import { UniCard, UniMediaCarousel, UniTypography } from 'components';
import DiscplineChip from 'components/_common/uniChip/DisciplineChip';
import { MediaType } from 'components/_common/UniMediaCarousel/UniMediaCarousel';
import { disciplinesMap } from 'constants/programMaps';
import { useBreakpoints } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UniversityPageGeneralLocation from 'views/universityPage/UniversityPageGeneralLocation';
import { ChipsWrapper, StyledSectionTitle } from '../StudentUniversityPage.style';

interface Props {
  university?: Components.Schemas.UniversityResponse;
}

const General: React.FC<Props> = ({ university }) => {
  const { t } = useTranslation('universityPage');
  const { isMobile } = useBreakpoints();
  const [mediaArr, setMediaArr] = useState<MediaType[]>([]);
  useEffect(() => {
    const imgArr = (university?.images as Array<Components.Schemas.MediaResourceDto>)?.map(
      item => ({ mediaSrc: item?.readUrl, mediaType: 'PHOTO' } as MediaType),
    );
    const vidArr = (university?.videos as Array<Components.Schemas.UrlResourceDto>)?.map(
      item => ({ mediaSrc: item.url, mediaType: 'VIDEO' } as MediaType),
    );
    setMediaArr([...(imgArr || []), ...(vidArr || [])]);
  }, []);

  return (
    <Box display='flex' flexDirection='column' gridRowGap={isMobile ? '16px' : '24px'}>
      <UniCard size='L' border>
        <StyledSectionTitle title={t('general.sections.media')} size={isMobile ? 'XS' : 'S'} />
        <UniMediaCarousel carouselItems={mediaArr} emptyCarouselLabeL={t('general.sections.no_media')} />
      </UniCard>

      <UniCard size='L' border>
        <StyledSectionTitle title={t('general.sections.about')} size={isMobile ? 'XS' : 'S'} />
        {!!university?.description ? (
          <UniTypography variant='subheader' color='typographyBlack100' dangerouslySetInnerHTML={university.description} />
        ) : (
          <UniTypography color='typographyGray100' variant={isMobile ? 'body2' : 'body1'} style={{ textAlign: 'center' }}>
            {t('general.sections.no_description')}
          </UniTypography>
        )}
      </UniCard>

      <UniCard size='L' border>
        <StyledSectionTitle title={t('general.sections.disciplines')} size={isMobile ? 'XS' : 'S'} />
        {!!university?.disciplines.length ? (
          <ChipsWrapper>
            {university.disciplines.map(discipline => (
              <DiscplineChip label={disciplinesMap[discipline]} />
            ))}
          </ChipsWrapper>
        ) : (
          <UniTypography color='typographyGray100' variant={isMobile ? 'body2' : 'body1'} style={{ textAlign: 'center' }}>
            {t('general.sections.no_disciplines')}
          </UniTypography>
        )}
      </UniCard>

      <UniCard size='L' border>
        <StyledSectionTitle title={t('general.sections.location')} size={isMobile ? 'XS' : 'S'} />
        <UniversityPageGeneralLocation university={university} />
      </UniCard>
    </Box>
  );
};

export default General;
