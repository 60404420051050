import styled from 'styled-components';
import { Colors } from '../../../constants';

export const StyledDivider = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ theme, height }) => theme.spacing(height)}px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 100%;
    height: ${({ theme }) => theme.spacing(0.125)}px;
    background-color: ${Colors.Border.Gray_100};
  }
`;
