import useSearchAgencyStudents from 'api/search/useSearchAgencyStudents';
import { ApplicationCard, InfiniteWindowedGrid, UniButton, UniIconWrapper } from 'components';
import { CandidatesFiltersQueries } from 'components/_drawers/filtersDrawer/_types/CandidatesFilters';
import { useQueryStringParser } from 'hooks/useQueryStringParser';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDrawers } from 'store';
import { ReactComponent as AddIcon } from '../../../../assets/svg/Icons/Add.svg';
import { BrowserRoutes } from '../../../../constants';
import { useErrorHandler } from '../../../../hooks';

interface Props {}

const AllStudents: React.FC<Props> = ({ children, ...rest }) => {
  const { t } = useTranslation('agencyCandidates');
  const { queries } = useQueryStringParser<CandidatesFiltersQueries>();
  const history = useHistory();
  const { openDrawer } = useDrawers();
  const { data: students, fetchMore, isLoading, isFetching, error: studentsError } = useSearchAgencyStudents(queries);

  const fetchCandidatesError = useErrorHandler(studentsError!);
  const studentsFlat = students?.map(candidatePage => candidatePage.results).flat() || [];
  const studentsTotal = students ? students[0].total : 0;
  const studentsLeft = studentsTotal - studentsFlat.length;

  const openInviteCandidateDrawer = () => openDrawer('invite-candidate', { size: 'main' });

  return (
    <InfiniteWindowedGrid
      fetchMore={fetchMore}
      emptyStateType='students'
      isLoading={isLoading}
      isFetching={isFetching}
      hasMore={studentsLeft > 0}
      totalResults={studentsTotal}
      remainingResults={studentsLeft}
      minHeight={330}
      LeftComponent={
        <UniButton
          onClick={openInviteCandidateDrawer}
          fullWidth={false}
          color='primary'
          startIcon={
            <UniIconWrapper color='white100'>
              <AddIcon />
            </UniIconWrapper>
          }
        >
          {t('buttons.add_candidate')}
        </UniButton>
      }
      results={studentsFlat?.map((student, index) => (
        <ApplicationCard
          key={index}
          variant='candidatesPage'
          studentName={`${student?.firstName + ' ' + student?.lastName}`}
          studentAddress={student?.studentProfile?.address}
          metrics={{
            numberOfApplications: student.studentMetadata.ongoingApplicationsCount,
            // unansweredApplications: student.studentMetadata.unasweredApplications,
          }}
          path={BrowserRoutes.Agency.Student.Personal(student.id!)}
        />
      ))}
    />
  );
};

export default AllStudents;
