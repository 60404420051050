import { Box } from '@material-ui/core';
import useServiceOptions from 'api/service-options/useServiceOptions';
import { Components } from 'client/UniClient';
import { UniButton, UniTypography } from 'components';
import ServiceCard from 'components/serviceCard/ServiceCard';
import UniListLoader from 'components/_common/uniListLoader/UniListLoader';
import { useBreakpoints } from 'hooks';
import { useDrawers } from 'store';
import { StyledServicesList } from '../Services.style';

interface Props {}

const AllServices: React.FC<Props> = ({ children, ...rest }) => {
  const { isMobile } = useBreakpoints();
  const { data: services, isLoading } = useServiceOptions();
  const { openDrawer } = useDrawers();

  const openAddServiceDrawer = () => openDrawer('add-new-service', { size: 'small' });

  const onEditService = (service: Components.Schemas.ServiceOptionDto) =>
    openDrawer('edit-service', { size: 'small', service, editMode: true });

  const onRemoveService = (service: Components.Schemas.ServiceOptionDto) => openDrawer('delete-service', { size: 'small', service });

  return (
    <>
      <Box mb={4}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          Your list of services that every partner can choose from
        </UniTypography>
      </Box>
      {!isMobile && (
        <Box mb={5}>
          <UniButton color='primaryFade' onClick={openAddServiceDrawer}>
            Add Service
          </UniButton>
        </Box>
      )}
      <StyledServicesList>
        {isLoading && <UniListLoader />}
        {isMobile
          ? services?.map(item => (
              <ServiceCard
                id={item?.id}
                name={item.name}
                description={item.description}
                usedBy={item.offeringCount}
                onEdit={() => onEditService(item)}
                onDelete={() => onRemoveService(item)}
              />
            ))
          : services?.map(item => (
              <ServiceCard
                id={item?.id}
                name={item.name}
                description={item.description}
                usedBy={item.offeringCount}
                onEdit={() => onEditService(item)}
                onRemove={() => onRemoveService(item)}
              />
            ))}
      </StyledServicesList>
    </>
  );
};

export default AllServices;
