import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { Components, Paths } from '../../client/UniClient';
import { CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = Paths.AgencyApiPublishEducationHistoryItem.PathParameters & Components.Schemas.PublishRequest;

export default function usePublishEducationHistoryItem() {
  const queryCache = useQueryCache();
  const { client } = useClient();

  return useMutation(
    async ({ agencyId, educationHistoryItemId, studentId, ...payload }: Params) => {
      return client!.AgencyApi_publishEducationHistoryItem({ agencyId, educationHistoryItemId, studentId }, payload);
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Profile.Extended);
      },
      onError: (error: AxiosError) => {},
    },
  );
}
