import { AxiosError } from "axios";
import { useMutation, useQueryCache } from "react-query";
import { CreateAgentRequest } from "../../types";
import { useAuth, useClient } from "../../store";
import { CacheKeys } from "../../constants";
// import {Components} from "../../client/UniClient"
// import Schemas = Components.Schemas;

type Payload = CreateAgentRequest;

const usePostAgent = () => {
  const queryCache = useQueryCache();
  const agencyId = useAuth((state) => {
    return state.profile.agentProfile?.agency?.id!;
  });
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AgencyApi_inviteAgent({ agencyId }, {
        ...payload,
        agencyId: agencyId as string,
      });
    },
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(CacheKeys.Agency.Agents);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         });
         */
      },
    }
  );
};

export default usePostAgent;
