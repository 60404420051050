import { Box, ClickAwayListener, InputAdornment, useMediaQuery } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UniIconWrapper } from '../..';
import { ReactComponent as SearchIcon } from '../../../assets/svg/Icons/Search.svg';
import { SearchContainer, StyledSearchInput } from './UniSearch.style';

type SearchProps = {
  handleSearch: (value: string) => void;
  oldSearchVal?: string;
  placeholder?: string;
  buttonText?: string;
  searchData?: React.ReactNode;
  loading?: boolean;
  options?: string[];
  stayFocused?: boolean;
  searchOnEnter?: (value: string) => void;
};

const SHOW_MOBILE_SEARCH = '(max-width:600px)';

const UniSearchInput: React.FC<SearchProps> = React.memo(
  ({ handleSearch, oldSearchVal, placeholder = 'Search...', options = [], loading = false, stayFocused, searchOnEnter }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [currentSearchVal, setCurrentSearchVal] = useState(oldSearchVal || '');
    const searchInputRef = useRef<null | HTMLInputElement>(null);
    const showMobile = useMediaQuery(SHOW_MOBILE_SEARCH);

    const resetFocus = () => {
      setIsFocused(() => false);
      searchInputRef?.current?.blur();
    };

    const onHandleSearch = () => {
      setIsFocused(() => true);
      handleSearch(currentSearchVal);
      currentSearchVal && searchOnEnter && searchOnEnter(currentSearchVal);
    };

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (!isFocused) return;
        if (event.key === 'Escape') {
          resetFocus();
        }
        if (event.key === 'Enter' && typeof handleSearch === 'function') {
          setIsFocused(() => true);
          setCurrentSearchVal(oldCurrentValue => {
            if (oldCurrentValue && oldSearchVal !== oldCurrentValue) {
              handleSearch(oldCurrentValue);
              searchOnEnter && searchOnEnter(oldCurrentValue);
            }
            return oldCurrentValue;
          });
        }
      },
      [oldSearchVal, handleSearch, isFocused],
    );

    useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown]);

    const filterOptions = (options: string[], { inputValue }: { inputValue: string }) => matchSorter(options, inputValue).splice(0, 20);

    if (!handleSearch) return <Box />;

    return (
      <SearchContainer>
        <ClickAwayListener onClickAway={resetFocus}>
          <Autocomplete
            options={options}
            freeSolo
            fullWidth
            loading={loading}
            filterOptions={filterOptions}
            disableClearable={false}
            onInputChange={(event, option) => {
              let value = option || '';
              setCurrentSearchVal(value);
              handleSearch(value);
            }}
            renderInput={params => (
              <StyledSearchInput
                {...params}
                value={currentSearchVal}
                inputRef={searchInputRef}
                focused={isFocused}
                stayFocused={stayFocused}
                onChange={({ target: { value } }) => handleSearch(value)}
                onKeyDown={e => e.key === 'Enter' && onHandleSearch()}
                onFocus={() => setIsFocused(true)}
                placeholder={placeholder}
                variant='outlined'
                fullWidth
                InputProps={{
                  ...params.InputProps,

                  startAdornment: !showMobile && (
                    <InputAdornment disablePointerEvents position='start'>
                      <UniIconWrapper color={isFocused ? 'primary100' : 'typographyGray30'}>
                        <SearchIcon />
                      </UniIconWrapper>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </ClickAwayListener>
      </SearchContainer>
    );
  },
);

export default UniSearchInput;
