import { Box } from '@material-ui/core';
import { Components } from 'client/UniClient';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import React from 'react';
import { parseApplicationFeeInvoice } from 'utils/programUtils';

interface Inputs {}

interface Props {
  feeInvoice?: Components.Schemas.ApplicationFeeInvoiceDto;
}

const Step4: React.FC<Props> = ({ feeInvoice }) => {
  return (
    <>
      <Box mb={2}>
        <UniTypography variant='body1' color='secondary100' weight='semi-bold'>
          Your registration is almost complete!
        </UniTypography>
        <Box mt={2} />
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          The fee for this event is {parseApplicationFeeInvoice(feeInvoice)}. <br />
          After you make the payment, your seat at the event will be confirmed.
        </UniTypography>
      </Box>
    </>
  );
};

export default Step4;
