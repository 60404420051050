import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { Paths } from '../../client/UniClient';
import { useClient } from '../../store';

interface Params {
  params: Paths.QRTokenApiScanQRAchievementToken.PathParameters;
}

const usePostQRAchievementScan = () => {
  const { client } = useClient();

  return useMutation(
    async ({ params }: Params) => {
      return client!.QRTokenApi_scanQRAchievementToken(params);
    },
    {
      onSuccess: response => {},
      onError: (error: AxiosError) => {},
    },
  );
};

export default usePostQRAchievementScan;
