import LayoutRoute from 'components/_layouts/layoutRoute/LayoutRoute';
import React, { lazy } from 'react';
import { BrowserRoutes } from '../constants';

const AdminUniversityPage = lazy(() => import('views/_admin/university/UniversityPage'));
const AdminApplicationPage = lazy(() => import('views/_admin/application/ApplicationPage'));
const AdminCandidateProfile = lazy(() => import('views/_admin/candidateProfile/AdminCandidateProfile'));
const AdminUniversities = lazy(() => import('views/_admin/universities/AdminUniversities'));
const AdminCandidates = lazy(() => import('../views/_admin/candidates/AdminCandidates'));
const AdminPrograms = lazy(() => import('../views/_admin/programs/adminPrograms'));
const AdminProgramPage = lazy(() => import('../views/_admin/program-page/ProgramPage'));
const AdminApplications = lazy(() => import('../views/_admin/applications/AdminApplications'));

const AdminRoutes = () => {
  return [
    <LayoutRoute exact path={BrowserRoutes.Admin.Students.All} key={BrowserRoutes.Admin.Students.All} component={AdminCandidates} />,
    <LayoutRoute
      path={BrowserRoutes.Admin.AgencyStudent.Default}
      key={BrowserRoutes.Admin.AgencyStudent.Default}
      component={AdminCandidateProfile}
    />,
    <LayoutRoute exact path={BrowserRoutes.Admin.CardList.Default} key={BrowserRoutes.Admin.CardList.Default} component={AdminPrograms} />,
    <LayoutRoute exact path={BrowserRoutes.Admin.Program.Default} key={BrowserRoutes.Admin.Program.Default} component={AdminProgramPage} />,
    <LayoutRoute exact path={BrowserRoutes.Admin.Universities} key={BrowserRoutes.Admin.Universities} component={AdminUniversities} />,
    <LayoutRoute
      path={BrowserRoutes.Admin.University.Default}
      key={BrowserRoutes.Admin.University.Default}
      component={AdminUniversityPage}
    />,
    <LayoutRoute
      path={BrowserRoutes.Admin.Application.Default}
      key={BrowserRoutes.Admin.Application.Default}
      component={AdminApplicationPage}
    />,
    <LayoutRoute path={BrowserRoutes.Admin.Applications} key={BrowserRoutes.Admin.Applications} component={AdminApplications} />,
  ];
};

export default AdminRoutes;
