import { AxiosError } from 'axios';
import { QueryConfig, useInfiniteQuery, useQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  id?: string;
};

export default function useGetUniversity(params: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();

  return useQuery(
    [CacheKeys.General.University, params],
    async (_key: string, params: Params) => {
      const { data } = await client!.UniversityApi_getUniversity(params.id!);
      return data;
    },
    { refetchOnWindowFocus: false, onError(error: AxiosError) {} },
  );
}
