import { Box } from '@material-ui/core';
import UniCreatableSelect from 'components/_common/uniCreatableSelect/UniCreatableSelect';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import UniCheckbox from 'components/_inputs/uniCheckbox/UniCheckbox';
import UniSelect from 'components/_inputs/uniSelect/UniSelect';
import UniTextField from 'components/_inputs/uniTextField/UniTextField';
import { CountriesType } from 'constants/countries';
import { serviceDomains } from 'constants/options/serviceDomains';
import { studyInterests } from 'constants/options/studyInterests';
import { yearlyBudget } from 'constants/options/yearlyBudget';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'types';
import { countries } from '../../constants';

export interface PreferencesFormInputs {
  studyInterests: OptionType[];
  countryInterests: CountriesType[];
  particularSchool: string;
  yearlyBudget: OptionType<string>;
  serviceInterests: OptionType[];
}

interface Props {
  control: any;
  register: any;
  errors: any;
}

const PreferencesForm: React.FC<Props> = ({ control, register, errors }) => {
  const [hideInterests, setHideInterests] = useState(false);
  const [hideCountry, setHideCountry] = useState(false);
  const [hideBudget, setHideBudget] = useState(false);
  const { t } = useTranslation('forms');

  return (
    <>
      <Controller
        control={control}
        name='studyInterests'
        render={({ value, onChange }) => (
          <>
            <UniCreatableSelect
              label='What would you like to study?'
              placeholder='Select your preffered domains'
              options={studyInterests}
              value={value}
              onChange={option => onChange(option)}
              isDisabled={hideInterests}
              isMulti
            />
            <Box display='flex' alignItems='center' mt={-2.5} mb={1}>
              <UniCheckbox
                checked={hideInterests}
                onChange={({ target }) => {
                  if (target.checked) onChange(undefined);
                  setHideInterests(target.checked);
                }}
              />
              <UniTypography color='typographyBlack100'>I don't know yet</UniTypography>
            </Box>
          </>
        )}
      />
      <Controller
        control={control}
        name='countryInterests'
        render={({ value, onChange }) => (
          <>
            {' '}
            <UniSelect
              getOptionLabel={({ name }) => name}
              label='Where would you like to study?'
              value={value}
              onChange={value => onChange(value)}
              options={countries}
              isDisabled={hideCountry}
              isMulti
            />
            <Box display='flex' alignItems='center' mt={-2.5} mb={1}>
              <UniCheckbox
                checked={hideCountry}
                onChange={({ target }) => {
                  if (target.checked) onChange(undefined);
                  setHideCountry(target.checked);
                }}
              />
              <UniTypography color='typographyBlack100'>I don't know yet</UniTypography>
            </Box>
          </>
        )}
      />
      <Controller
        control={control}
        name='particularSchool'
        render={({ value, onChange }) => (
          <UniTextField
            label='Any particular school you would like to study at?'
            value={value}
            onChange={onChange}
            inputRef={register}
            error={!!errors.particularSchool}
            helperText={errors.particularSchool?.message}
            fullWidth
          />
        )}
      />
      <Controller
        control={control}
        name='yearlyBudget'
        render={({ value, onChange }) => (
          <>
            <UniSelect
              label='What is your yearly budget?'
              value={value}
              onChange={value => onChange(value)}
              options={yearlyBudget}
              isDisabled={hideBudget}
            />
            <Box display='flex' alignItems='center' mt={-2.5} mb={1}>
              <UniCheckbox checked={hideBudget} onChange={({ target }) => setHideBudget(target.checked)} />
              <UniTypography color='typographyBlack100'>I don't know yet</UniTypography>
            </Box>
          </>
        )}
      />

      <Controller
        control={control}
        name='serviceInterests'
        render={({ value, onChange }) => (
          <UniCreatableSelect
            label='What do you need help with?'
            options={serviceDomains}
            value={value}
            onChange={option => onChange(option)}
            isMulti
          />
        )}
      />
    </>
  );
};

export default PreferencesForm;
