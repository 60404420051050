import React from 'react';
import { Components } from 'client/UniClient';
import UniPrepCenterPurchaseOrder from 'components/prepCenterPurchaseOrder/UniPrepCenterPurchaseOrder';
import { useLocation } from 'react-router-dom';

type PrepPackDto = Components.Schemas.PrepPackDto;

interface Props {}

export const StudentPrepCenterCheckOutPage: React.FC<Props> = () => {
  const { state } = useLocation<{ prepPack: PrepPackDto }>();
  const prepPack = state.prepPack;

  return (
    <UniPrepCenterPurchaseOrder
      prepPacks={[prepPack]}
    />
  );
};

export default StudentPrepCenterCheckOutPage;
