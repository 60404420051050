import { AxiosError } from 'axios';
import { QueryConfig, useInfiniteQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';
import { ApplicationFilter } from '../../types';

type Params = {
  studentId?: string;
  agencyId?: string;
  type?: ApplicationFilter;
};

export default function useStudentApplications(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  const { profile } = useAuth();
  const studentId = profile.role === 'STUDENT' ? profile.id : params?.studentId;
  const agencyId =
    profile.role === 'STUDENT'
      ? profile.studentProfile!.agent?.agentProfile.agency?.id
      : profile.role === 'ADMIN'
      ? params?.agencyId
      : profile.agentProfile?.agency?.id;
  const pageSize = ApiKeys.PageSize.Programs;

  return useInfiniteQuery(
    [CacheKeys.Applications.All, params],
    async (_key: string, params: Params, page: number = 1) => {
      // const { data } = await client!.ProgramApi_getFavoritePrograms();
      if (params.type === 'saved') {
        const { data } = await client!.AgencyApi_getFavoritePrograms({
          agencyId: agencyId as string,
          studentId: studentId as string,
          pageSize,
          page,
        });
        return data;
      } else if (params.type === 'ongoing') {
        const { data } = await client!.StudentApi_getStudentAppliedPrograms({
          studentId: studentId!,
          pageSize,
          page,
        });
        return data;
      } else if (params.type === 'suggested') {
        const { data } = await client!.AgencyApi_getStudentRecommendedPrograms({
          agencyId: agencyId || '',
          studentId: studentId || '',
          pageSize,
          page,
        });
        return data;
      } else if (params.type === 'answered') {
        const { data } = await client!.StudentApi_getStudentAnsweredApplicationPrograms({
          studentId: studentId as string,
          pageSize,
          page,
        });
        return data;
      } else {
        const { data } = await client!.StudentApi_getStudentPrograms({
          studentId: studentId as string,
          pageSize,
          page,
        });
        return data;
      }
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Programs || lastPage.page * ApiKeys.PageSize.Programs === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
