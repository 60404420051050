import { Box } from '@material-ui/core';
import Slider from 'react-slick';
import { UniIconWrapper, UniTypography } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactElement, useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from '../../../assets/svg/Icons/Arrow/Left.svg';
import { ReactComponent as RightArrow } from '../../../assets/svg/Icons/Arrow/Right.svg';
import { StyledCarouselBig, StyledCarouselSmall, Wrapper } from './UniMediaCarousel.style';
import { useBreakpoints } from 'hooks';

export type MediaType = {
  mediaSrc: string;
  mediaType: 'PHOTO' | 'VIDEO';
};

interface Props {
  carouselItems: Array<MediaType>;
  emptyCarouselLabeL?: string;
}

export default function UniMediaCarousel({ carouselItems, emptyCarouselLabeL }: Props): ReactElement {
  const [nav1, setNav1] = useState<Slider>();
  const [nav2, setNav2] = useState<Slider>();
  const [slider1, setSlider1] = useState<Slider>();
  const [slider2, setSlider2] = useState<Slider>();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState();
  const { isMobile } = useBreakpoints();
  const initialSlide = carouselItems.filter(item => item.mediaSrc === currentSlide)[0];
  const initialIndex = carouselItems.indexOf(initialSlide);
  const listLength = carouselItems.length;
  const hideSmallCarousel = listLength === 1 && isFullScreen;

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const SampleNextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <Box position='absolute' top='37%' left={isFullScreen ? '78%' : '97%'}>
        <UniIconWrapper onClick={onClick} color='typographyGray100' size='large' disableHover={!isFullScreen} disableRipple={!isFullScreen}>
          <RightArrow />
        </UniIconWrapper>
      </Box>
    );
  };

  const SamplePrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <Box position='absolute' top='37%' left='-1.2%'>
        <UniIconWrapper onClick={onClick} color='typographyGray100' size='large' disableHover={!isFullScreen} disableRipple={!isFullScreen}>
          <LeftArrow />
        </UniIconWrapper>
      </Box>
    );
  };

  const handleClickOutside = (e: any) => {
    if (e.target.tagName === 'IMG' || e.target.tagName === 'VIDEO') return;
    setIsFullScreen(false);
  };

  return listLength !== 0 ? (
    <Wrapper isOpen={isFullScreen} onClick={handleClickOutside}>
      <AnimatePresence>
        {isFullScreen && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <StyledCarouselBig
              listLength={listLength}
              arrows={false}
              asNavFor={nav2}
              initialSlide={initialIndex}
              ref={(slider: Slider) => setSlider1(slider)}
            >
              {carouselItems.map(item =>
                item.mediaType === 'PHOTO' ? (
                  <Box>
                    <img src={item.mediaSrc} />
                  </Box>
                ) : (
                  <Box>
                    <video src={item.mediaSrc} controls width='60%' />
                  </Box>
                ),
              )}
            </StyledCarouselBig>
          </motion.div>
        )}
      </AnimatePresence>

      <Box>
        {!hideSmallCarousel && (
          <StyledCarouselSmall
            listLength={listLength}
            slidesToShow={listLength === 3 ? 2.99 : listLength === 2 && isFullScreen ? 1.99 : 3}
            prevArrow={<SamplePrevArrow />}
            nextArrow={<SampleNextArrow />}
            focusOnSelect
            asNavFor={nav1}
            hasOnClick={!!listLength}
            ref={(slider: Slider) => setSlider2(slider)}
            isFullScreen={isFullScreen}
          >
            {carouselItems.map(item =>
              item.mediaType === 'PHOTO' ? (
                <img
                  src={item.mediaSrc}
                  onClick={(e: any) => {
                    setIsFullScreen(true);
                    setCurrentSlide(e.target.src);
                  }}
                />
              ) : (
                <video
                  src={item.mediaSrc}
                  onClick={(e: any) => {
                    setIsFullScreen(true);
                    setCurrentSlide(e.target.src);
                  }}
                />
              ),
            )}
            {listLength < 2 && <Box />}
            {listLength < 3 && !isFullScreen && <Box />}
          </StyledCarouselSmall>
        )}
      </Box>
    </Wrapper>
  ) : (
    <UniTypography color='typographyGray100' variant={isMobile ? 'body2' : 'body1'} style={{ textAlign: 'center' }}>
      {emptyCarouselLabeL}
    </UniTypography>
  );
}
