import LayoutRoute from 'components/_layouts/layoutRoute/LayoutRoute';
import React, { lazy } from 'react';
import UniversityPage from 'views/universityPage/UniversityPage';
import { AgencyAdminUniversities } from 'views/_agency/universities/AgencyAdminUniversities';
import { BrowserRoutes } from '../constants';
import { AgencyAdminAgents } from '../views/_agency/agents/AdminAgentsList';
import AgentProfile from '../views/_agent/profile/AgentProfile';

const AgencyApplications = lazy(() => import('../views').then(({ AgencyApplications }) => ({ default: AgencyApplications })));
const ApplicationPage = lazy(() => import('../views').then(({ AgencyApplication }) => ({ default: AgencyApplication })));
const StudentProgramPage = lazy(() => import('../views').then(({ AgencyStudentProgramPage }) => ({ default: AgencyStudentProgramPage })));
const AgencyCandidateProfile = lazy(() => import('../views').then(({ AgencyCandidateProfile }) => ({ default: AgencyCandidateProfile })));
const AgencyCandidates = lazy(() => import('../views').then(({ AgencyCandidates }) => ({ default: AgencyCandidates })));
const AgentHome = lazy(() => import('../views').then(({ AgentHome }) => ({ default: AgentHome })));
const AgencyPrograms = lazy(() => import('../views').then(({ AgencyPrograms }) => ({ default: AgencyPrograms })));
const PartnerMemberServices = lazy(() => import('../views').then(({ PartnerMemberServices }) => ({ default: PartnerMemberServices })));
const AgencyProgramPage = lazy(() => import('../views').then(({ AgencyProgramPage }) => ({ default: AgencyProgramPage })));

const AgencyRoutes = () => {
  return [
    <LayoutRoute exact path={BrowserRoutes.Agent.Default} key={BrowserRoutes.Agent.Default} component={AgentHome} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.Application.Default}
      key={BrowserRoutes.Agent.Application.Default}
      component={ApplicationPage}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.StudentProgram.Default}
      key={BrowserRoutes.Agent.StudentProgram.Default}
      component={StudentProgramPage}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.Student.Default}
      key={BrowserRoutes.Agent.Student.Default}
      component={AgencyCandidateProfile}
    />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.Students.Default}
      key={BrowserRoutes.Agent.Students.Default}
      component={AgencyCandidates}
    />,
    <LayoutRoute exact path={BrowserRoutes.Agent.Applications} key={BrowserRoutes.Agent.Applications} component={AgencyApplications} />,
    // this is for The universities Page
    <LayoutRoute path={BrowserRoutes.Agent.Universities} key={BrowserRoutes.Agent.Universities} component={AgencyAdminUniversities} />,
    <LayoutRoute path={BrowserRoutes.Agency.Profile} key={BrowserRoutes.Agency.Profile} component={AgentProfile} />,
    <LayoutRoute path={BrowserRoutes.Agent.Agent()} key={BrowserRoutes.Agent.Agent()} component={AgentProfile} />,
    <LayoutRoute path={BrowserRoutes.Agent.Programs} key={BrowserRoutes.Agent.Programs} exact component={AgencyPrograms} />,
    <LayoutRoute path={BrowserRoutes.Agent.Team} key={BrowserRoutes.Agent.Team} component={AgencyAdminAgents} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.Program.General}
      key={BrowserRoutes.Agent.Program.General}
      component={AgencyProgramPage}
    />,
    <LayoutRoute path={BrowserRoutes.Agent.UniversityProfile} key={BrowserRoutes.Agent.UniversityProfile} component={UniversityPage} />,
    <LayoutRoute
      exact
      path={BrowserRoutes.Agent.Services.Default}
      key={BrowserRoutes.Agent.Services.Default}
      component={PartnerMemberServices}
    />,
  ];
};

export default AgencyRoutes;
