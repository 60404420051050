import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { Components } from '../../client/UniClient';
import { useAuth, useClient } from '../../store';

const useUpdateStudent = () => {
  const { profile, setProfile } = useAuth();
  const { client } = useClient();

  return useMutation(
    async (payload: Components.Schemas.UpdateStudentRequest) => {
      return client!.StudentApi_updateStudent(profile.id!, {
        ...payload,
      });
    },
    {
      onSuccess: async response => {
        setProfile(response.data);
      },
      onError: (error: AxiosError) => {},
    },
  );
};
export default useUpdateStudent;
