import { AxiosError } from 'axios';
import { QueryConfig, useInfiniteQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';
import { useClient } from '../../store';

type Params = {
  studentId: string;
};

export default function useAnsweredPrograms(params?: Params, config?: QueryConfig<Error>) {
  const { client } = useClient();
  const pageSize = ApiKeys.PageSize.Programs;

  return useInfiniteQuery(
    [CacheKeys.Applications.Answered, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.StudentApi_getStudentAnsweredApplicationPrograms({
        studentId: params.studentId as string,
        pageSize,
        page,
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Programs || lastPage.page * ApiKeys.PageSize.Programs === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
