import { Box } from '@material-ui/core';
import { UniAvatar } from 'components';
import creatableSelectStyles from 'components/_common/uniCreatableSelect/UniCreatableSelect.style';
import UniTypography from 'components/_common/uniTypography/UniTypography';
import { appStatus } from 'constants/appStatus';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { ReplyToStudentInputs } from '../ReplyToStudent';
import { StyledHeaderAvatar } from '../ReplyToStudent.style';

interface Props {
  programName?: string;
  universityName?: string;
  studentName?: string;
  control: Control<ReplyToStudentInputs>;
}

const Step1: React.FC<Props> = ({ programName = '', control, studentName, universityName = 'University' }) => {
  return (
    <>
      <Box mb={2}>
        <UniTypography variant='subheader' color='typographyGray100' weight='regular'>
          {`You are about to reply to ${studentName}’s application for:`}
        </UniTypography>
      </Box>
      <StyledHeaderAvatar>
        <UniAvatar size='S' label={universityName} />
        <Box>
          <UniTypography variant='subheader' color='typographyBlack100' weight='semi-bold'>
            {programName}
          </UniTypography>
          <UniTypography variant='subheader' color='secondary100' weight='medium'>
            {universityName}
          </UniTypography>
        </Box>
      </StyledHeaderAvatar>
      <Box mb={2}>
        <UniTypography variant='body1' color='secondary100' weight='medium'>
          Set application status
        </UniTypography>
        <UniTypography variant='subheader' color='typographyGray100'>
          The candidate will receive your application answer once you send it. You can always change the status of an application later.
        </UniTypography>
      </Box>

      <Controller
        control={control}
        name='applicationStatus'
        render={({ onChange, value }) => {
          return (
            <CreatableSelect
              styles={creatableSelectStyles}
              onChange={({ value }) => onChange(value)}
              placeholder={value?.label || 'Select application status'}
              value={appStatus.find(item => item.value === value)}
              options={appStatus}
              getOptionLabel={option => option.label}
            />
          );
        }}
      />
    </>
  );
};

export default Step1;
