import { Box } from '@material-ui/core';
import React from 'react';
import { UniTypography } from '../..';
import { TComponentColors } from '../../../constants/componentColors';
import { MetaItem } from '../../../views/_agent/candidateProfile/AgencyCandidateProfile.style';
import UniPhoneInput from '../../_inputs/uniPhoneInput/uniPhoneInput';
import { UniTextWithIcon } from '../uniMetaData/UniMetaData';

export const EditableUniTextForPhone = (props: {
  isEditing: boolean;
  inputRef: React.Ref<any>;
  setValue: Function;
  control: any;
  valuePrefix: string;
  name: string;
  label: string;
  value: string;
  iconColor?: TComponentColors;
  Icon?: React.ReactNode;
  fullWidth?: boolean;
}) => {
  if (props.isEditing) {
    return (
      <MetaItem>
        <Box width='80%'>
          <UniTypography color='typographyGray70' variant='footnote'>
            {props.label}
          </UniTypography>
          <UniPhoneInput
            control={props.control}
            setValue={props.setValue}
            name={props.name}
            phonePrefix={props.valuePrefix}
            defaultValue={props.value}
            error={false}
            inputRef={props.inputRef}
            fullWidth={props.fullWidth}
          />
        </Box>
      </MetaItem>
    );
  }
  return (
    <UniTextWithIcon iconColor={props.iconColor} Icon={props.Icon} label={props.label} value={`${props.valuePrefix} ${props.value}`} />
  );
};
