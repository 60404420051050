import LayoutRoute from 'components/_layouts/layoutRoute/LayoutRoute';
import { useBreakpoints } from 'hooks';
import { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { getUserHomeScreen } from 'utils/getHomeForRole';
import { NotFound, PublicStudentProfile, SmallResolutions, Unauthorized, VerifyNewEmail } from 'views';
import QRScanAchievement from 'views/QRScanAchievement/QRScanAchievement';
import WebSummitSearch from 'views/webSummitSearch/WebSummitSearch';
import { UniPageLoader } from '../components';
import { BrowserRoutes } from '../constants';
import { useAuth } from '../store';
import { getMetaRole } from '../utils';
import AdminRoutes from './Admin';
import AgencyRoutes from './Agency';
import AgencyAdminRoutes from './AgencyAdmin';
import DefaultRoutes from './Default';
import HighSchoolRoutes from './HighSchool';
import MasterRoutes from './Master';
import StudentRoutes from './Student';
import UniversityRoutes from './University';

const Router = () => {
  const history = useHistory();
  const role = useAuth(state => state.profile.role);
  const { isMaster, isAdmin, isAgency, isHighSchool, isAgent, isStudent, isUniversity } = getMetaRole(role);
  const isLoggedIn = useAuth(state => !!state?.profile?.id);
  const [routes, setRoutes] = useState(DefaultRoutes);
  const { isMobile } = useBreakpoints();

  history.listen(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (isMaster) {
      setRoutes(MasterRoutes);
    } else if (isAdmin) {
      setRoutes(AdminRoutes);
    } else if (isHighSchool) {
      setRoutes(HighSchoolRoutes);
    } else if (isAgency) {
      setRoutes(AgencyAdminRoutes);
    } else if (isAgent) {
      setRoutes(AgencyRoutes);
    } else if (isUniversity) {
      setRoutes(UniversityRoutes);
    } else if (isStudent) {
      setRoutes(StudentRoutes);
    } else {
      setRoutes(DefaultRoutes);
    }
  }, [isMaster, isAdmin, isHighSchool, isAgency, isAgent, isUniversity, isStudent]);

  return (
    <Suspense fallback={<UniPageLoader />}>
      <Switch>
        {routes}
        <LayoutRoute exact path='/web-summit-demo-page' component={WebSummitSearch} />
        <LayoutRoute exact path={BrowserRoutes.Default.EmailUpdateVerifyNew} component={VerifyNewEmail} />
        <Route exact path={BrowserRoutes.Default.Public.StudentProfile()} component={PublicStudentProfile} />
        <Route exact path={BrowserRoutes.Default.QRCode.Achievement()} component={QRScanAchievement} />
        <LayoutRoute exact path={BrowserRoutes.Default.Unauthorized} strict component={Unauthorized} />
        {/* {isMobile && <Redirect to={BrowserRoutes.Default.Homepage} />} */}
        <Redirect exact path='/' to={getUserHomeScreen(role)} />
        <LayoutRoute key='NotFound' component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Router;
