import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useAuth, useClient } from "../../store";

const usePostDocument = () => {
  const { profile, setProfile } = useAuth();
  const { client } = useClient();

  return useMutation(
    async (payload: FormData) => {
      return client!.FileApi_uploadPassport(undefined, payload);
    },
    {
      onSuccess: (response) => {
        let updatedProfile = { ...profile };
        updatedProfile.studentProfile!.passportDocument = response.data;
        setProfile(updatedProfile);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         }); 
         */
      },
    }
  );
};

export default usePostDocument;
