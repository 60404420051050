import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { Components } from '../../client/UniClient';
import { useClient } from '../../store';

type Payload = Partial<Components.Schemas.CreateStudentFromLinkRequest>;

const useCompleteStudentOnboardingFromLink = () => {
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AuthApi_createStudentFromLink(undefined, payload as Components.Schemas.CreateStudentFromLinkRequest);
    },

    {
      onSuccess: async response => {},
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         });
         */
      },
    },
  );
};
export default useCompleteStudentOnboardingFromLink;
