import React from 'react';
import { UniPageTitle } from 'components/index';
import { useBreakpoints } from 'hooks';
import { useTranslation } from 'react-i18next';
import { StyledHeaderWrapper } from './UniFilterBar.style';

interface Props {
  title: string;
}

export const UniFilterBarHeader: React.FC<Props> = ({ title }) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation('filterBar');

  return (
    <StyledHeaderWrapper mobile={isMobile}>
      <UniPageTitle size={isMobile ? 'XS' : 'S'} title={title} />
    </StyledHeaderWrapper>
  );
};
