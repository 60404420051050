import { AxiosError } from 'axios';
import { Paths } from 'client/UniClient';
import { QueryConfig, useInfiniteQuery } from 'react-query';
import { ApiKeys, CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';

type Params = Paths.SearchApiSearchAgencyStudents.QueryParameters;

export default function useSearchAgencyStudents(params: Params, config?: QueryConfig<Error>) {
  const agencyId = useAuth(state => state.profile.agentProfile?.agency?.id);
  const { client } = useClient();

  return useInfiniteQuery(
    [CacheKeys.Agency.Candidates, params],
    async (_key: string, params: Params, page: number = 1) => {
      const { data } = await client!.SearchApi_searchAgencyStudents({
        ...params,
        agencyId: agencyId as string,
        pageSize: ApiKeys.PageSize.Candidates,
        page,
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Candidates || lastPage.page * ApiKeys.PageSize.Candidates === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
