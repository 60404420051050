import create from 'zustand';
import { HeaderState } from './types';

const useHeader = create<HeaderState>(set => ({
  header: 'default',
  setHeader: props => set(({ setHeader, clearHeader }) => ({ ...props, setHeader, clearHeader, header: 'predefined' })),
  clearHeader: () => set(state => ({ ...state, primaryButton: undefined, secondaryButton: undefined, header: 'default' })),
}));

export default useHeader;
