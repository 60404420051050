import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { UniButton } from 'components';
import AccomplishmentsForm, { AccomplishmentsFormInputs } from 'components/_forms/AccomplishmentsForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledUniCardFooter } from '../StudentOnboard.style';

export type Step6FormData = AccomplishmentsFormInputs;

type Props = {
  defaultValues: Partial<Step6FormData>;
  prevStep: () => void;
  nextStep: (formData: Step6FormData) => void;
};

const Step6: React.FC<Props> = ({ defaultValues, nextStep, prevStep }) => {
  const { t: tButtons } = useTranslation('buttons');
  const { control, errors, watch, handleSubmit } = useForm<Step6FormData>({ defaultValues });

  useEffect(() => {
    localStorage.setItem('onboardFormDataStep6', JSON.stringify(watch()));
  }, [watch()]);

  return (
    <>
      <Box mb={4}>
        <AccomplishmentsForm control={control} errors={errors} />
      </Box>
      <StyledUniCardFooter
        variants='M'
        type='labeled'
        SecondaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            variant='text'
            size={'large'}
            color='neutral'
            fullWidth={false}
            onClick={prevStep}
          >
            {tButtons('previous')}
          </UniButton>
        }
        PrimaryButton={
          <UniButton
            style={{ margin: '16px 16px 16px auto' }}
            title='next'
            size={'large'}
            color='primary'
            fullWidth={false}
            onClick={handleSubmit(nextStep)}
          >
            {tButtons('next_step')}
          </UniButton>
        }
      />
    </>
  );
};

export default Step6;
