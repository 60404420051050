import { UniListItem } from 'components';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'store';
import { useFile } from '../../api';
import { Components } from '../../client/UniClient';

interface Props {
  document: Components.Schemas.FileDto;
  index: number;
  isMobile?: boolean;
  onDelete?: Function;
}

export default function StudentDocumentsListItem({ document, index, isMobile, onDelete }: Props): ReactElement {
  const { t } = useTranslation('studentDocuments');
  const { data: openFileData, error: fileError } = useFile({
    id: document?.id!,
  });
  const {
    props: { hide },
  } = useSnackbar();

  if (hide === document?.id) return <> </>;
  const docLabel = document?.documentType === 'PASSPORT' ? t('passport') : document?.label;
  return (
    <UniListItem
      type='iconed'
      size={isMobile ? 'S' : 'M'}
      label1={isMobile ? `${docLabel.substring(0, 15)}${docLabel.length > 14 ? '...' : ''}` : docLabel}
      withBackground={index % 2 !== 0}
      onShow={() => {
        window.open(openFileData?.url, '_blank');
      }}
      onDelete={onDelete}
    ></UniListItem>
  );
}
