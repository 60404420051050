import { AxiosError } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { CacheKeys } from '../../constants';
import { useAuth, useClient } from '../../store';
import { CurrencyIso } from '../../types';

type Payload = {
  email: string;
  firstName: string;
  lastName: string;
  feeAmount: number;
  feeCurrency: CurrencyIso;
  feeType: 'UPFRONT' | 'FIFTY_FIFTY' | 'AFTER';
  phone: string;
  phonePrefix: string;
};

const usePostCandidate = () => {
  const queryCache = useQueryCache();
  const agencyId = useAuth(state => state.profile.agentProfile?.agency?.id!);
  const { client } = useClient();

  return useMutation(
    async (payload: Payload) => {
      return client!.AgencyApi_inviteStudent({ agencyId }, {
        email: payload.email,
        phone: payload.phone,
        phonePrefix: payload.phonePrefix,
        firstName: payload.firstName,
        password: '',
        lastName: payload.lastName,
        fee: {
          amount: +payload.feeAmount,
          currencyIso: payload.feeCurrency,
        },
        feeType: payload.feeType,
      });
    },
    {
      onSuccess: response => {
        queryCache.invalidateQueries(CacheKeys.Agency.Candidates);
        queryCache.invalidateQueries(CacheKeys.Agency.CandidateNames);
      },
      onError: (error: AxiosError) => {
        /* Note: Possible specific error handler
         const { message } = error?.response?.data || {};
         const generalMessage =
           "Something went wrong, please try again in a little while";
         Snackbar(message || generalMessage, {
           variant: "error"
         });
         */
      },
    },
  );
};

export default usePostCandidate;
