import { UniTextArea, UniTextField } from 'components';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
interface Props {
  register: any;
  control: any;
}

export interface RequestServiceFormInputs {
  name: string;
  description: string;
}

const Step1: React.FC<Props> = ({ register, control, children, ...rest }) => {
  const { t } = useTranslation('partnerServicesDrawers');
  return (
    <>
      <Controller
        name='name'
        control={control}
        rules={{ required: 'Please enter a note', validate: value => value?.trim() !== '' || 'Please enter a note' }}
        render={({ value, onChange }) => (
          <UniTextField
            fullWidth
            name='name'
            label={t('request_service.name')}
            placeholder='ex: Admission letter'
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name='description'
        control={control}
        rules={{ required: 'Please enter a note', validate: value => value?.trim() !== '' || 'Please enter a note' }}
        render={({ value, onChange }) => (
          <UniTextArea
            rowsMin={2}
            rowsMax={10}
            value={value}
            placeholder='Describe in a sentence or two what you’ll teach the student to do, what are the guidance steps and the final result.'
            onChange={onChange}
            label={t('request_service.description')}
            withBorder
            borderRadius={[8, 8, 8, 8]}
            variant='subheader'
          />
        )}
      />
    </>
  );
};

export default Step1;
