import { AxiosError } from 'axios';
import { QueryConfig, useInfiniteQuery } from 'react-query';
import { getMetaRole } from 'utils';
import { ApiKeys, CacheKeys } from '../../constants';
import { LevelOfStudy } from '../../constants/levelOfStudy';
import { useAuth, useClient } from '../../store';
import { ApplicationStatus, Nationality } from '../../types';

type Params = {
  query?: string;
  nationality?: Nationality[];
  countryOfStudy?: Nationality[];
  levelOfStudy?: LevelOfStudy[];
  applicationStatus?: ApplicationStatus[];
  favorites?: boolean;
  order?: 'ASC' | 'DESC';
  sort?: 'NAME' | 'DATE_ADDED';
};

export default function useCandidates(params: Params, config?: QueryConfig<Error>) {
  const agencyId = useAuth(state => state.profile.agentProfile?.agency?.id);
  const agentId = useAuth(state => state.profile.id);
  const { role } = useAuth(state => state.profile);
  const { isAgency } = getMetaRole(role!);
  const { client } = useClient();
  return useInfiniteQuery(
    [CacheKeys.Agency.Candidates, params],
    async (_key: string, params: Params, page: number = 1) => {
      if (isAgency) {
        const { data } = await client!.SearchApi_searchAgencyStudents({
          ...params,
          query: params?.query || undefined,
          page,
          pageSize: ApiKeys.PageSize.Candidates,
          agencyId: agencyId as string,
        });
        return data;
      } else {
        const { data } = await client!.SearchApi_searchAgentStudents({
          ...params,
          query: params?.query || undefined,
          page,
          pageSize: ApiKeys.PageSize.Candidates,
          agentId: agentId as string,
        });
        return data;
      }
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastPage => {
        if (lastPage.results.length < ApiKeys.PageSize.Candidates || lastPage.page * ApiKeys.PageSize.Candidates === lastPage.total) {
          return false;
        }
        return lastPage.page + 1;
      },
      onError(error: AxiosError) {},
    },
  );
}
