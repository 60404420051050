import { UniTypography } from 'components';
import { DisciplineItem } from './uniChip.style';

interface ChipProps {
  label: any;
}

const DiscplineChip: React.FC<ChipProps> = ({ label }) => {
  return (
    <DisciplineItem>
      <UniTypography variant='subheader' weight='regular' color='typographyGray100'>
        {label}
      </UniTypography>
    </DisciplineItem>
  );
};

export default DiscplineChip;
