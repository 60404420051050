import { UniButton } from 'components';
import { useState } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import { Colors } from '../../constants';

interface Props {
  show: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

const StyledHiddenItemWrapper = styled.div<{ visible: boolean }>`
  position: relative;
  padding: ${theme.spacing(0)}px;
  transition: 0.2s all;
  ${({ visible }) =>
    visible &&
    `
    opacity: 1;
    padding: ${theme.spacing(2)}px;
  `}

  &::after {
    content: '';
    background-color: ${Colors.Secondary._10};
    border: 2px dashed ${Colors.Secondary._100};
    border-radius: 6px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    opacity: 0;
    transition: 0.2s all;

    ${({ visible }) => visible && 'opacity: 1;'}
  }
`;

const StyledShowHideButtonWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${Colors.White._100};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.Primary._100};
  border-radius: 8px;
  overflow: hidden;
  opacity: 0;
  transition: 0.2s all;
  z-index: 100;

  ${({ visible }) => visible && 'opacity: 1;'}

  &>* {
    pointer-events: none;
  }
`;

const StyledChildrenWrapper = styled.div<{ visible: boolean }>`
  transition: 0.2s all;

  ${({ visible }) => (visible ? 'opacity: 1;' : 'opacity: .6;')}
`;

const ShowableItem: React.FC<Props> = ({ disabled, show, onChange, children }) => {
  const [visible, setVisible] = useState(false);
  const [localShow, setLocalShow] = useState(show);

  const getColor = (value: boolean) => (value ? 'primaryFade' : 'white');

  const onToggleShow = () => {
    setLocalShow(prev => {
      onChange(!prev);
      return !prev;
    });
  };

  if (disabled && !localShow) return <></>;

  return (
    <StyledHiddenItemWrapper
      visible={disabled ? false : visible}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <StyledChildrenWrapper visible={localShow}>{children}</StyledChildrenWrapper>
      <StyledShowHideButtonWrapper visible={disabled ? false : visible} onClick={onToggleShow}>
        <UniButton color={getColor(!localShow)} borderRadius={[0, 0, 0, 0]}>
          Hide
        </UniButton>
        <UniButton color={getColor(localShow)} borderRadius={[0, 0, 0, 0]}>
          Show
        </UniButton>
      </StyledShowHideButtonWrapper>
    </StyledHiddenItemWrapper>
  );
};

export default ShowableItem;
